<div class="container-fluid graybg">
  <div class="whiteboxwrap">
  <div class="settings-container">
    <div class="section">
      <h5 class="page-hdr btm-15">Site Settings</h5>
    </div>
    <div class="section col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Email Settings</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-lg-5">
              <mat-label class="setting-item" >Allow Hissa to send notification emails to Employees and Admins</mat-label>
              <mat-button-toggle-group id="emailToBeSent" class="top-10" [(ngModel)]="updatedSettings.emailToBeSent">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="col-lg-3">
              <mat-label class="setting-item">Email sender name address</mat-label>
              <mat-form-field floatLabel="auto" appearance="outline">
                <input class="adjust-input-height" name="Email Sender Address" placeholder="Email Address" matInput
                  [(ngModel)]="updatedSettings.communicationEmailId" type="email" [disabled]="!updatedSettings.emailToBeSent" />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-label class="setting-item">Email sender name</mat-label>
              <mat-form-field appearance="outline">
                <input matInput class="adjust-input-height" name="Sender Name" placeholder="Sender's Name"
                  [(ngModel)]="updatedSettings.senderNameForEmail" [disabled]="!updatedSettings.emailToBeSent" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-label class="setting-item">Reminder Emails</mat-label>
              <mat-button-toggle-group class="top-10" id="reminderEmailsToBeSent"
                [(ngModel)]="updatedSettings.reminderEmailsToBeSent">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div *ngIf="updatedSettings.reminderEmailsToBeSent" class="col-md-4">
              <mat-label class="setting-item required">Start Reminder Emails from(in Days)</mat-label>
              <mat-form-field appearance="outline" >
                <input class="adjust-input-height" id="reminder" name="Reminder interval" matInput type="number" placeholder="Number of days"
                  (keypress)="onlyNumber($event)" min=1 #remainder="ngModel" [(ngModel)]="updatedSettings.reminderEmailsStartFrom" />
              </mat-form-field>
            </div>
            <div *ngIf="updatedSettings.reminderEmailsToBeSent" class="col-md-4">
              <mat-label class="setting-item required">Reminder Emails interval(in Days)</mat-label>
              <mat-form-field appearance="outline">
                <input class="adjust-input-height" name="Reminder interval" #remainderInterval="ngModel" matInput type="number" placeholder="Number of days" (keypress)="onlyNumber($event)" min="1" [(ngModel)]="updatedSettings.reminderEmailsInterval" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <mat-label class="setting-item" >Allow admin to deactivate inactive employee accounts</mat-label>
              <mat-button-toggle-group id="employeeInactivityLogout" class="top-10" [(ngModel)]="updatedSettings.employeeInactivityLogout">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="col-lg-5" *ngIf="updatedSettings.employeeInactivityLogout">
              <mat-label class="setting-item" >Should employee be notified</mat-label>
              <mat-button-toggle-group id="shouldEmployeeBeIntimidated" class="top-10" [(ngModel)]="updatedSettings.shouldEmployeeBeIntimidated">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <mat-label class="setting-item">Allow admin to receive all approver Emails</mat-label>
              <mat-button-toggle-group id="approverMailToAdmin" class="top-10" [(ngModel)]="updatedSettings.approverMailToAdmin">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <mat-label class="setting-item">Allow employees to receive offer letter</mat-label>
              <mat-button-toggle-group id="isAttachmentEnabled" class="top-10" [(ngModel)]="updatedSettings.isAttachmentEnabled">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="section col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Employee Portal Settings</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Display Price per Share on Employee Portal</mat-label>
              <mat-button-toggle-group class="top-10" id="showPPSToEmployees"
                [(ngModel)]="updatedSettings.showPPSToEmployees">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="col-md-6">
              <mat-label class="setting-item">Price Per Share</mat-label>
              <mat-form-field class="top-10" appearance="outline">
                <input class="adjust-input-height" matInput type="number" [(ngModel)]="updatedSettings.ppsForEmployee"
                  [disabled]="!updatedSettings.showPPSToEmployees" />
                <span matPrefix>
                  {{userCompanyCurrency}}
                </span>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Display Forcast Values in Employee Portal</mat-label>
              <mat-button-toggle-group class="top-10" id="showEmpPortalValuesForecast"
                [(ngModel)]="updatedSettings.showEmpPortalValuesForecast">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="col-md-6">
              <mat-label class="setting-item">Show Plan to Employee</mat-label>
              <mat-button-toggle-group class="top-10" id="showPlanToEmployees"
                [(ngModel)]="updatedSettings.showPlanToEmployees">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="section col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Grant Letter Settings</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-md-4">
              <mat-label class="setting-item">Window for Acceptance of Grant offer (in Days)</mat-label>
              <mat-form-field appearance="outline">
                <input class="adjust-input-height" name="Grant Letter Acceptance" matInput type="number" [(ngModel)]="updatedSettings.acceptanceNumber" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-label class="setting-item">Authorised signature to be used on Grant letter</mat-label>
              <div>
                <table>
                  <tr>
                    <td>
                      <button mat-raised-button id="viewGrantSignature" (click)="downloadObject('grantSignature')" [hidden]="!updatedSettings.grantSignature">Grant Signature.jpg</button>
                      <button (click)=grantSignature.click() class="cursor-hand" ngbTooltip="Upload" style="padding-left: 10px;" mat-icon-button><mat-icon [svgIcon]="'action_upload'"></mat-icon></button>
                      <button (click)="downloadObject('grantSignature',true)" class="cursor-hand" ngbTooltip="Download" style="padding-left: 10px;" [disabled]="!updatedSettings.grantSignature" mat-icon-button><mat-icon [svgIcon]="'action_download'" style="zoom:120%"></mat-icon></button>
                    </td>
                  </tr>
                </table>
              </div>

              <input id="grantSignature" type="file" hidden #grantSignature (change)="fileChangeEvent($event, 'grantSignature')" accept="image/*">
            </div>
            <!-- <div class="col-md-4">
              <mat-label class="setting-item">Override default Grant Letter Template</mat-label>
              <div>
                <table>
                  <tr>
                    <td>
                      <button mat-raised-button id="viewGrantLetter" (click)="downloadObject('grantTemplate',true)" [hidden]="!updatedSettings.companyLevelGrantTemplate">Grant template.docx</button>
                      <button (click)=grantLetterUpload.click() ngbTooltip="Upload" style="padding-left: 10px;" mat-icon-button><mat-icon [svgIcon]="'action_upload'"></mat-icon></button>
                      <button (click)="downloadObject('grantTemplate',true)" ngbTooltip="Download" style="padding-left: 10px;" [disabled]="!updatedSettings.companyLevelGrantTemplate" mat-icon-button><mat-icon [svgIcon]="'action_download'" style="zoom:120%"></mat-icon></button>
                    </td>
                  </tr>
                </table>
              </div>
              <input type="file" hidden accept=".docx" value="Grant Template.dcx" #grantLetterUpload name="" id=""
                (change)="fileChangeEvent($event, 'grantLetter')">
            </div> -->
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Override Default Grant Letter Template</mat-label>
              <mat-button-toggle-group class="top-10" id="isCustomizedPlanEnabled"
                [(ngModel)]="updatedSettings.isCustomizedPlanEnabled">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row" *ngIf="updatedSettings.isCustomizedPlanEnabled">
            <div class="col-lg-10 col-sm-12 col-xs-12">
              <table class="table-common top-20">
                <thead>
                  <tr>
                    <th width="60%" class="text-align-left">Select a Plan</th>
                    <th width="40%"> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let listPlan of updatedSettings.planLevelGrantTemplate; let i= index">
                    <td>
                      <select class="form-control input-fields top-10 cursor-hand select-plan" id="esopManagement-allplans" [(ngModel)]="listPlan.planName" disabled>
                        <option *ngFor="let plans of updatedSettings.planLevelGrantTemplate" value="{{plans.planName}}">
                          {{plans.planName}}</option>
                      </select>
                    </td>
                    <td>
                      <div>
                        <table>
                          <tr>
                            <td>
                              <button mat-raised-button id="{{'viewGrantLetter-planLevelGrantTemplate' + i}}" (click)="downloadPlanLetter(i, 'grantTemplate',true, 'planLevelGrantTemplate')" [hidden]="!listPlan.planLevelGrantTemplate">Grant template.docx</button>
                              <button (click)=grantLetterUpload.click() ngbTooltip="Upload" style="padding-left: 10px;" mat-icon-button><mat-icon [svgIcon]="'action_upload'"></mat-icon></button>
                              <button (click)="downloadPlanLetter(i,'grantTemplate',true, 'planLevelGrantTemplate')" ngbTooltip="Download" style="padding-left: 10px;" [disabled]="!listPlan.planLevelGrantTemplate" mat-icon-button><mat-icon [svgIcon]="'action_download'" style="zoom:120%"></mat-icon></button>
                              <input type="file" hidden accept=".docx" value="Grant Template.dcx" #grantLetterUpload name="" id=""
                              (change)="uploadGrantLetter(i, $event, 'grantLetter', 'planLevelGrantTemplate')">
                              <img class="left-10" src="../../assets/trash-bin.svg" (click)="deletePlanLetter(i, 'planLevelGrantTemplate')" ngbTooltip="Delete" [hidden]="!listPlan.planLevelGrantTemplate"/>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let listPlan of updatedSettings.listOfPlansWithoutTemplates; let i= index">
                    <td *ngIf="listPlan.isAddPlan">
                      <select class="form-control input-fields top-10 cursor-hand select-plan" id="esopManagement-allplans" [ngModel]="listPlan.planName" [disabled]="listPlan.planLevelGrantTemplate != null && listPlan.planLevelGrantTemplate != ''"
                        (change)="changeByplanNames($event.target.value, i)">
                        <option *ngFor="let plans of updatedSettings.listOfPlansWithoutTemplates" value="{{plans.planName}}">
                          {{plans.planName}}</option>
                      </select>
                    </td>
                    <td *ngIf="listPlan.isAddPlan">
                      <div>
                        <table>
                          <tr>
                            <td>
                              <button mat-raised-button id="{{'viewGrantLetter-listOfPlansWithoutTemplates'+i}}" (click)="downloadPlanLetter(i, 'grantTemplate',true, 'listOfPlansWithoutTemplates')" [hidden]="!listPlan.planLevelGrantTemplate">Grant template.docx</button>
                              <button (click)=grantLetterUpload.click() ngbTooltip="Upload" style="padding-left: 10px;" mat-icon-button><mat-icon [svgIcon]="'action_upload'"></mat-icon></button>
                              <button (click)="downloadPlanLetter(i,'grantTemplate',true, 'listOfPlansWithoutTemplates')" ngbTooltip="Download" style="padding-left: 10px;" [disabled]="!listPlan.planLevelGrantTemplate" mat-icon-button><mat-icon [svgIcon]="'action_download'" style="zoom:120%"></mat-icon></button>
                              <input type="file" hidden accept=".docx" value="Grant Template.dcx" #grantLetterUpload name="" id=""
                              (change)="uploadGrantLetter(i, $event, 'grantLetter', 'listOfPlansWithoutTemplates')">
                              <img class="left-10" src="../../assets/trash-bin.svg" (click)="deletePlanLetter(i, 'listOfPlansWithoutTemplates')" ngbTooltip="Delete" [hidden]="!listPlan.planLevelGrantTemplate"/>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="updatedSettings.planLevelGrantTemplate.length==0">
                    <td colspan="6" class="text-align-center">
                      <p>Click on the 'Add Plan' button to add plan</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="isAllowAddPlan">
                <button mat-raised-button id="addPlan" color="success" class="add-plan-btn top-10" ngbTooltip="Add Plan"
                (click)=addPlan()>Add Plan</button>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Override the Grant Template for a specific Plan</mat-label>
            </div>
            <div class="col-md-6">
              <mat-button-toggle-group class="float-right" id="emailToBeSent" [(ngModel)]="updatedSettings.emailToBeSent">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div> -->
        </mat-card-content>
      </mat-card>
    </div>
    <div class="section col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Leegality Settings</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Employees should be allowed to do E-sign</mat-label>
              <mat-button-toggle-group class="top-10" id="isEsignEnabled"
                [(ngModel)]="updatedSettings.isEsignEnabled">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="section col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Security Settings</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Two Factor Authentication</mat-label>
              <mat-button-toggle-group class="top-10" id="isTwoFactorAuthEnabled"
                [(ngModel)]="updatedSettings.isEnabledTwoFactorAuthentication">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="section col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Email Template Settings</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Select an Email Template</mat-label>
              <select class="form-control input-fields top-10 cursor-hand" id="esopManagement-allplans"
                (change)="changeByEmailTemplate($event.target.value)" [(ngModel)]="emailTemplateType">
                <option value="">Select an Email Template</option>
                <option *ngFor="let temp of emailTemp" value="{{temp}}">
                  {{temp}}</option>
              </select>
            </div>
            <div *ngIf="selectedEmail">
              <button (click)="editEmailTemp()" ngbTooltip="Edit Email Template" triggers="hover" mat-icon-button
                color="primary" style="color: #cd4927; top: 2em; padding-left: 20px;">
                <mat-icon aria-label="Example icon-button with a heart icon"><i class="fa fa-pencil-square-o fa-2x"
                    aria-hidden="true"></i></mat-icon>
              </button>
            </div>
          </div>
          <div #emailTemplate>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12">
              <button mat-raised-button id="saveChanges" color="success" class="left-20 float-right" ngbTooltip="Save"
        (click)=saveSettings()>Save
        Changes</button>
      <button mat-raised-button id="discardChanges" color="warn" class="float-right" ngbTooltip="Cancel" (click)=discardSettings()>Discard
        Changes</button>
      </div>

    </div>
  </div>
  </div>
</div>
