<div class="container-fluid graybg">
  <div class="whiteboxwrap">
  <div class="row btm-15">
      <div class="col-md-9">
          <h5 class="page-hdr">Buy-back Overview</h5>
      </div>
      <div *appHideIf="['admin-without-proforma']" class="col-md-3"> 
          <button mat-raised-button id="createBuy-back" [disableRipple]="disableripple" [color]="disableBuyBack === false ? 'success' : 'disabled' " class="pull-right" (click)="navigate()" 
          ngbTooltip="Create New Buy-Back" [disabled]="disableBuyBack" ><img src="../../assets/CreateRound.svg" alt="Create New Buy-Back"/>&nbsp;&nbsp;
              Create Buy-back
          </button>
          <span class="round-tooltip" *ngIf="disableBuyBack && toolTipDataLoaded"  placement="left" [ngbTooltip]="disbaledToolTipContent" ></span>
      </div>
      <ng-template #disbaledToolTipContent>Please finish partial {{partialTransaction}}!</ng-template>
      <div class="col-md-12">
          <p class="content-pg-1 top-20 btm-20">
              Here is a summary of the series of buybacks the company has done so far. 
              Click on the name of the buy-back in the table to view the details of the buy-back.
          </p>
      </div>
  </div>
  <div *ngIf="dataSource.length == 0">
    <dx-data-grid id="gridContainer" class="grant-table" [hoverStateEnabled]="true" [dataSource]="dataSource" keyExpr="id" caption="Buy-back table"
               [showBorders]="true" [wordWrapEnabled]="true"
               [columnAutoWidth]="true" [allowColumnReordering]="false" noDataText="Click on the 'Create Buy-Back' button to add a Buy-back"
              [allowColumnResizing]="true" (onRowClick)="redirectToBuybackDetails($event)">

    <dxo-scrolling [useNative]="true" showScrollbar="true"> </dxo-scrolling>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxi-column dataField="buyBackName" [allowEditing]="false" [allowFiltering]="true" caption="Buy-back Name" ></dxi-column>
    <dxi-column dataField="className" [allowEditing]="false" caption="Security Class"></dxi-column>
    <dxi-column dataField="startDate" [allowSorting]="true" [allowEditing]="false" [allowFiltering]="true" caption="Date"></dxi-column>
    <dxi-column dataField="pricePerShare" [allowEditing]="false" caption="Price per share"></dxi-column>
    <dxi-column dataField="buyBackAmountActual" caption="Amount Raised" [allowEditing]="false" style="cursor: pointer;" ></dxi-column>
    <dxi-column dataField="postBuybackConsolidation" [allowFiltering]="false"  [allowEditing]="false" caption="Dilution (fortification)"></dxi-column>
  </dx-data-grid>
</div>
  <app-buy-back-overview-table [dataSource]="dataSource" [displayCols]="displayCols"></app-buy-back-overview-table>
</div> 
</div>