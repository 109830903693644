<div class="container-fluid graybg" *ngIf="employeeDetails">
    <div id="overlay"></div>
    <div class="whiteboxwrap">
        <div class="row btm-20" *ngIf="isEmployee">
        <div class="col-5">
            <h5 class="page-hdr employee_name">{{ employeeDetails?.employeeName }}</h5>
        </div>
    </div>
    <div *ngIf="!isEmployee" class="row btm-20">
        <div class="employee-mat-form-field col-3" style="margin-top: 6.5px;">
                <mat-form-field class="employee-mat-form-field" floatLabel="always" appearance="outline">
                  <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedEmployee">
                  <mat-icon class="aggregator-arrow-selector" matSuffix>
                    <div class="mat-select-arrow-wrapper">
                      <div class="mat-select-arrow">
                      </div>
                    </div>
                  </mat-icon>
                  <mat-autocomplete [showPanel]="true" #auto="matAutocomplete" (optionSelected)="changeEmployee(myControl.value)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
        </div>
        <div class="edit-icon col-3">
            <button (click)="openEditEmpPopup()" ngbTooltip="Edit Employee" [disabled]="hrmsIntegrationStatusIsActive"
                mat-icon-button color="primary" style="color: #cd4927; top: 5px;">
                <mat-icon aria-label="Example icon-button with a heart icon"><i class="fa fa-pencil-square-o fa-2x "
                    aria-hidden="true"></i></mat-icon>
            </button>
        </div>
        <div class="employee_status col-3" style="margin-top: 6.5px;">
            <mat-form-field style=" font-size: 12.5px;" appearance="outline" >
              <mat-label>Status </mat-label>
              <mat-select [(ngModel)]="employeeDetails.status" (selectionChange)="openResignationPopup(employeeDetails.status)" [disabled]="employeeDetails.status != 'Employed'">
                <mat-option *ngFor="let item of employeeDetails.statusValuesForDropdown" [value]="item"> <span>
                    {{item}}
                  </span></mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="generate_credentials_btn col-3">
            <button *appHideIf="['emp-view']" mat-raised-button color="success" ngbTooltip="Generate Credentials" [disableRipple]="true"  class="float-right"
            (click)="generateCred()"><img src="../../../../assets/esop/AddEmployee.svg"
              alt="Generate Credentials" />&nbsp;&nbsp;Generate Credentials</button>
        </div>
    </div>
    <div *ngIf="!employee" class="row">
        <div class="col-md-12">
            <p class="content-pg-1 btm-20">
                This section allows you to manage the options granted to the selected employee. You can update the employment status, make and approve grants, exercise the vested options and administer ESOP here.<br><br> 

                Click on <b>‘Generate Credentials’</b> to create a separate login to the employee. This will allow the employee to log into the ESOP section on hissa and view the stage of their stock options along with their employment summary. 
                 </p>
        </div>
    </div>
    <div *ngIf="employee" class="row">
        <div class="col-md-12">
            <p class="content-pg-1 btm-20">
                You can view the entire timeline of the options granted in accordance to the terms of vesting as a graph or as a table below.
            </p>
        </div>
    </div>
    <div class="row btm-20" (mouseleave)="hideHelp()">
        <div class="col-md-12 col-lg-9">
            <mat-card id="EsopDetails" (mouseover)="showHelp()">
                <mat-card-header>
                    <mat-card-title>ESOP Details</mat-card-title>
                </mat-card-header>
                <mat-divider class="divider-line"></mat-divider>
                <div class="row top-20">
                    <div *ngIf="!employee" class="col-xs-12 col-sm-12 col-md-12">
                      <div class="content-pg-1 btm-20">
                        The details of the employee to whom the options have been granted and the stage at which the options stand as on date are summarized below.
                        From the status drop down, select the appropriate option if there is an update in the employment status.
                        The employment status decides the manner in which the vested and unvested options are treated.
                      </div>
                    </div>
                </div>
                <div *ngIf="employee" class="row top-20">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                      <div class="content-pg-1 btm-20">
                        This is the summary of your employment in the company along with the stage wise details of the options grant. Pay attention to your employment status. The status decides the manner in which the options will vest and exercise.
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="card-lyot">
                            <div class="text-align-center ">
                                <!-- <span class="parent-container"> -->
                                    <img *ngIf="employeeDetails.imageURL.includes('https:')" class="profile-picture" [src]="employeeDetails.imageURL" />
                                    <div *ngIf="!employeeDetails.imageURL.includes('https:')" class="circle-with-text">{{ employeeDetails.imageURL }}</div>
                                <!-- </span> -->

                                <div class="top-10 btm-10 card-hdr-container">
                                    <mat-label class="card-hdr text-align-center text-ellipsis">
                                        {{ employeeDetails.employeeName }}
                                    </mat-label>
                                </div>

                                <div class="flex-box">
                                    <div class="flex-item">
                                        <mat-label class="card-lbl">Granted</mat-label>
                                        <div class="top-10">
                                            <mat-label container="body" class="card-value"
                                                ngbTooltip="{{ employeeDetails.grantedOptions | localeFormatter: 'tooltip-number'}}">
                                                {{ employeeDetails.grantedOptions | localeFormatter: 'number'}}
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="flex-item">
                                        <mat-label class="card-lbl">Vested</mat-label>
                                        <div class="top-10">
                                            <mat-label container="body" class="card-value"
                                                ngbTooltip="{{ employeeDetails.vestedOptions | localeFormatter: 'tooltip-number'}}">
                                                {{ employeeDetails.vestedOptions | localeFormatter: 'number'}}
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="flex-item">
                                        <mat-label class="card-lbl">Exercised</mat-label>
                                        <div class="top-10">
                                            <mat-label class="card-value" container="body"
                                                ngbTooltip="{{ employeeDetails.exercisedOptions | localeFormatter: 'tooltip-number'}}">
                                                {{ employeeDetails.exercisedOptions | localeFormatter: 'number'}}
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-md-4">
                                <div>
                                    <mat-label class="card-lbl">Designation</mat-label>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="card-value">
                                        {{ employeeDetails.designation == "" || employeeDetails.designation == "undefined" ? '-' : employeeDetails.designation}}</mat-label>
                                </div>
                                <div>
                                    <mat-label class="card-lbl">Employee ID</mat-label>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="card-value">
                                        {{ employeeDetails.employeeId }}</mat-label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div>
                                    <mat-label class="card-lbl">Resident Status</mat-label>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="card-value">
                                        {{ employeeDetails.residentStatus }}</mat-label>
                                </div>
                                <div>
                                    <mat-label class="card-lbl">Nationality</mat-label>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="card-value">
                                        {{ employeeDetails.nationality }}</mat-label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div>
                                    <mat-label class="card-lbl">Department</mat-label>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="card-value">
                                        {{ employeeDetails.department }}</mat-label>
                                </div>
                               
                            </div>
                        </div>
                        <div *ngIf="shouldShowPPS" class="row">
                            <div class="col-md-4">
                                <mat-label class="card-lbl">Per Share Price *</mat-label>
                                <div ngbTooltip="{{ppsValue  | localeFormatter: 'tooltip-currency'}}" class="top-10 card-value">
                                    <mat-label class="card-value" container="body">
                                        {{ppsValue | localeFormatter: 'currency'}}
                                    </mat-label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <mat-label class="card-lbl">Total Options Value *</mat-label>
                                <div ngbTooltip="{{totalShareValue  | localeFormatter: 'tooltip-currency'}}" class="top-10">
                                    <mat-label class="card-value" container="body">
                                        {{totalShareValue | localeFormatter: 'currency'}}
                                    </mat-label>
                                </div>
                            </div>
                            <div class="col-md-4"><mat-label class="card-lbl">Total Options Cost</mat-label>
                                <div ngbTooltip="{{totalShareCost  | localeFormatter: 'tooltip-currency'}}" class="top-10">
                                    <mat-label class="card-value" container="body">
                                        {{totalShareCost | localeFormatter: 'currency'}}
                                    </mat-label>
                                </div></div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <!-- Helper sections -->
        <div class="col-md-3">
            <div class="helper-container">
                <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
                    [hidden]="helper == ''">
                    <mat-card-header class="help-hdr">
                    <mat-card-title><label class="help-hdr-txt">{{
                            helpSections?.name
                            }}</label></mat-card-title>
                    </mat-card-header>
                    <hr />
                    <mat-card-content class="card-help-content">
                        <div [innerHTML]="helpSections?.description"></div>
                    </mat-card-content>
                </mat-card>
            </div>
      </div>
    </div>
    <div *ngIf="shouldShowPPS" class="row termsandconditions">
        <div class="col-md-12 col-lg-9">
            * The price per share is historical and is based on most recent concluded financing round. Past performance is no assurance or indicator of future returns and the price may increase or decrease based on market, buyer, seller and share type. This price per share doesn’t include tax likely in the hands of the receiver.
        </div>
    </div>
    <div *ngIf="!employee" class="row">
        <div class="col-md-12">
            <p class="content-pg-1">
                The table displays the stage wise summary of ESOP granted to the employee.
                You can grant or amend the terms of the grant, confirm vesting, allow exercise etc., in this section.
                To vest, exercise, amend or delete the record of an employee, check the box adjacent to the employee’s Grant ID.
            </p>
        </div>
    </div>
    <div *ngIf="employee" class="row">
        <div class="col-md-12">
            <p class="content-pg-1">
                The table displays the stage wise breakup of ESOP granted to you. You can view the details of your grant by clicking on <b>‘View Grant Letter’</b>. To answer the grant offer, check the box adjacent to your grant Id and name in the table. An <b>‘Accept Grant’</b> icon will appear. Click on the icon and select <b>‘Accept’</b> or <b>‘Reject’</b> in the <b>‘ Grant Response’</b> window. 
            </p>
        </div>
    </div>



    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 topPadding">
        <div class="row">
          <div class="col-sm-8 col-md-8 col-xs-8 col-lg-8">
            <div class="coldgridOPerations">
            </div>
          </div>
        </div>
        </div>
        <div class="col-md-12 btm-20">
        <div id="gridDeleteSelected" style ="margin-right: 37px;">    
                <!-- <img *appShowIf="['add-grant']" class="table-action-toolbar cursor-hand" title="Grant New Option" (click)="addNewRow()"
              src="../../../../assets/action/addnew.svg" style="height: 24px; margin-top: 5px; margin-right: 4px;" />
  
          <ng-container *ngIf="selectedItemKeys.length>0">
            <img *appShowIf="['delete-grant']" class="table-action-toolbar cursor-hand" ngbTooltip="Delete"
              (click)="deleteRecords()" style="height: 20px; margin-top: 8px; width: 30px;"
              src="../../../assets/trash-bin.svg" />
          </ng-container>
          <ng-container *ngIf="selectedItemKeys.length==1">
            <img class="table-action-toolbar cursor-hand" *appShowIf="['emp-view']" ngbTooltip="Exercise"
              (click)="doExercise(false)" src="../../../assets/esop/exercise.svg"
              style="height: 20px; margin-top: 8px;" />
          </ng-container>
  
          <ng-container *ngIf="selectedItemKeys.length==1">
            <img class="table-action-toolbar cursor-hand" *appShowIf="['vest-request']" ngbTooltip="Vest"
              (click)="doVest(false)" src="../../../assets/esop/vest.svg" style="height: 20px; margin-top: 8px;" />
          </ng-container>
          <ng-container *ngIf="bulkStateChange">
            <img class="table-action-toolbar cursor-hand" *appShowIf="['vest-request']" ngbTooltip="Option State Change" (click)="bulkChangeGrantStatus()"
            src="../../../assets/esop/stateChange.svg" style="height: 22px; margin-top: 6px;" />
          </ng-container>  -->
          <!-- <img *appShowIf="['add-grant']" class="table-action-toolbar cursor-hand" title="Upload ESOP File" (click)="fileInputForEsops.click()" 
          src="../../../../assets/cloud-upload.svg" style=" margin-top: 3px;"  /> -->
          <!-- <img src="../../../../assets/cloud-upload.svg" />
          <img src="../../../../assets/cloud-download.svg" /> -->
  
          <input #fileInputForEsops type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            (change)="uploadFile($event)" style="display:none;" />
        </div>
    <div *ngIf="dataSource"  class="row btm-20">
        <div class="col-md-12 emp-detail-table">
            <!-- <div id="gridDeleteSelected"> -->
                <!-- <span *appShowIf="['delete-grant']">
                    <img class="table-action-toolbar" ngbTooltip="Delete" *ngIf="selectedItemKeys.length>0"
                    (click)="deleteRecords()" style="height: 20px; width: 30px; margin-top:-1px;" src="../../../assets/trash-bin.svg" />
                </span>

                <span *appShowIf="['emp-view']">
                    <img class="table-action-toolbar" *ngIf="selectedItemKeys.length==1 && !employee" ngbTooltip="Exercise" (click)="doExercise(false)" src="../../../assets/esop/exercise.svg" style="height: 20px; margin-top:-1px;" />
                </span>

                <span  *appShowIf="['vest-request']">
                    <img class="table-action-toolbar" *ngIf="selectedItemKeys.length==1" ngbTooltip="Vest" (click)="doVest(false)" src="../../../assets/esop/vest.svg" style="height: 20px; margin-top:-1px;" />
                </span>
                <span>
                    <img class="table-action-toolbar" *ngIf="selectedItemKeys.length==1" ngbTooltip="Accept Grant" (click)="changeGrantStatus()" src="../../../assets/esop/stateChange.svg" style="height: 20px; margin-top:-1px;" />
                </span> -->
            <!-- </div> -->
            <dx-data-grid class="grant-table" (onContextMenuPreparing)="contextMenu($event)" keyExpr="optionHolderId" [hoverStateEnabled]="true"
            [dataSource]="dataSource" [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false" (onCellClick)="onCellClick($event)"
            [allowColumnReordering]="false" [allowColumnResizing]="false" noDataText=" "
            (onEditingStart)="logEvent('EditingStart', $event)" (onInitNewRow)="logEvent('InitNewRow', $event)"
            (onRowInserted)="logEvent('RowInserted', $event)" (onRowRemoved)="logEvent('RowRemoved', $event)"
            (onSelectionChanged)="selectionChanged($event)" (onCellHoverChanged)="onCellHoverChanged($event)">

            <dxo-filter-row [visible]="false"></dxo-filter-row>

            <!-- <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel> -->

            <dxo-paging [enabled]="true" [pageSize]="20"></dxo-paging>

            <dxo-header-filter [visible]="isShowHeaderFilter" [allowSearch]="false"> </dxo-header-filter>

            <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
<!-- 
            <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="allowEdit" [allowDeleting]="this.permService.authorizeUser(['delete-grant'])"
                [allowAdding]="false">
            </dxo-editing> -->

            <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

            <dxi-column dataField="grantIdentifier" [allowHiding]="false" [showInColumnChooser]="false" [minWidth]="90" [allowEditing]="false" [fixed]="true" caption="Grant Id"
                alignment="left">
            </dxi-column>

            <!-- <dxi-column dataField="imageURL" [minWidth]="30" [allowFiltering]="false" [allowHiding]="false" [showInColumnChooser]="false" [fixed]="true" caption=" "
                [allowSorting]="false" [allowEditing]="false" cellTemplate="pictureTemplate"></dxi-column> -->
            <div *dxTemplate="let data of 'pictureTemplate'">
                <img *ngIf="data.value != null" [src]="data.value" style="height: 30px; width: 30px; border-radius: 50%;"  />
            </div>

            <!-- <dxi-column [allowSorting]="true" [allowHiding]="false" [showInColumnChooser]="false" placeholder="Name of Holder" dataField="employeeId"
            [minWidth]="140" [fixed]="true" caption="Name of Holder" [allowEditing]="false" [allowFiltering]="false" cellTemplate="cellTemplate" > -->
            <!-- <dxo-header-filter [dataSource]="empNameFilter"></dxo-header-filter> -->
            <!-- <dxo-lookup [dataSource]="empList" [displayExpr]="getDisplayExpr" valueExpr="employeeId">
                <div *dxTemplate="let data of 'dropDownButton'">
                    <img src="../../../../assets/select-dropdown.svg" class="custom-icon"/>
                </div>
            </dxo-lookup> -->
            <!-- </dxi-column> -->

            <div *dxTemplate="let cell of 'cellTemplate'">
                <div class="text-align-right">{{ cell.data.employeeName }}</div>
            </div>
            <dxi-column [allowFiltering]="true" dataField="optionHolderState" [minWidth]="120" caption="Grant Status"  cellTemplate="statusTemplate" [fixed]="true" alignment="left"></dxi-column>
            <div *dxTemplate="let cellData of 'statusTemplate'">
                <!-- <span class="status-label" [ngClass]="(cellData.value == 'Granted') ? 'granted' : 'pending'" (click)="triggerChangeGrantStatus(cellData)">
                    {{ remove_(cellData.value) }}
                </span> -->
                <mat-chip-list selectable="false">
                    <mat-chip   [ngStyle]="grantStatusColorStyles[cellData.value.toLowerCase()]" disableRipple="false">{{ remove_(cellData.value) }} </mat-chip>
                  </mat-chip-list>
            </div>

            <dxi-column [allowSorting]="true" placeholder="Granted" dataField="optionsGranted"
             caption="No of Options" alignment="right" [minWidth]="125" [allowEditing]="false"
            [allowHiding]="false" [showInColumnChooser]="false">
            </dxi-column>

            <dxi-column [allowSorting]="true" placeholder="Grant Price" dataField="grantPrice" [allowEditing]="false"
             alignment="right" [minWidth]="110" [allowHiding]="false" [showInColumnChooser]="false" cellTemplate="grantPriceTemplate"
            caption="Grant Price">
            </dxi-column>
            <div *dxTemplate="let cellData of 'grantPriceTemplate'">
                {{(cellData.data.grantPrice)  | localeFormatter: 'currency'}}
            </div>

            <dxi-column [allowSorting]="true" placeholder="Grant Date" dataField="grantDate" [allowEditing]="false" format="dd-MMM-yyyy"
            caption="Grant Date" dataType="date" [minWidth]="105" [allowHiding]="false" [showInColumnChooser]="false" alignment="right">
            </dxi-column>

            <dxi-column [allowSorting]="true" placeholder="Vested" dataField="optionsVested"
            [allowEditing]="false"  caption="Vested" alignment="right"
            [minWidth]="83" [allowHiding]="false" [showInColumnChooser]="false">
            </dxi-column>

            <dxi-column [allowSorting]="true" placeholder="Stock Exercise" dataField="optionsExercised"
            [allowEditing]="false"  alignment="right" caption="Stock Exercise" [minWidth]="150"
            [allowHiding]="false" [showInColumnChooser]="false"></dxi-column>

            <dxi-column [allowSorting]="true" placeholder="Cash Exercise" dataField="optionsExercisedWithCash"
            [allowEditing]="false" alignment="right" caption="Cashed Out" [minWidth]="150"
            [allowHiding]="false" [showInColumnChooser]="false"></dxi-column>
            

           <dxi-column [allowSorting]="true" placeholder="Exercised with cash" dataField="optionsLapsed" 
           [allowEditing]="false"  alignment="right" caption="Forfeited" [minWidth]="150"
           [showInColumnChooser]="true">
           
           </dxi-column>


            <dxi-column [allowSorting]="true" placeholder="Vesting Schedule" dataField="vesting.id" cellTemplate="vestingTemplate"
            caption="Vesting Schedule" [allowEditing]="false"  [allowHiding]="false" [showInColumnChooser]="false" [minWidth]="180" >
            <dxo-header-filter [dataSource]="vestingTempFilter"></dxo-header-filter>
            <!-- <dxo-lookup [dataSource]="esopVestingTemplateList" displayExpr="vestingTemplateName" valueExpr="id">
            </dxo-lookup> -->
            </dxi-column>
            <div *dxTemplate="let cell of 'vestingTemplate'">
                <div ngbTooltip="{{ cell.data.vesting?.vestingTemplateName }}" class="text-align-right">{{ cell.data.vesting?.vestingTemplateName }}</div>
            </div>

            <dxi-column [allowSorting]="true" placeholder="Plan-Name" dataField="planId" caption="Plan Name"
             [allowHiding]="false" [showInColumnChooser]="false" [allowEditing]="false" [minWidth]="200" cellTemplate="planTemplate" >
            <dxo-header-filter [dataSource]="planNameFilter"></dxo-header-filter>
            <!-- <dxo-lookup [dataSource]="esopPlansTable" value="esopPlansTable[0].planId" displayExpr="esopPlanName" valueExpr="planId">
            </dxo-lookup> -->
            </dxi-column>
            <div *dxTemplate="let cell of 'planTemplate'">
                <div ngbTooltip="{{ cell.data.esopPlanName }}"  *ngIf="cell.data.hasPlanLetter" class="view-grant-letter" (click)="viewPlanLetter(cell)">{{ cell.data.esopPlanName }}</div>
                <div ngbTooltip="{{ cell.data.esopPlanName }}" *ngIf="!cell.data.hasPlanLetter">{{ cell.data.esopPlanName }}</div>
            </div>

            <!-- <dxi-column [allowSorting]="true" placeholder="Grant Letter"  cellTemplate="grantLetterCell"
            [allowEditing]="false" caption="Grant Letter" [allowHiding]="false" [showInColumnChooser]="false"  alignment="center" [minWidth]="200">

            </dxi-column>
            <div *dxTemplate="let cellData of 'grantLetterCell'">
                <div *ngIf="cellData.data.hasGrantLetter" (click)="viewGrantLetter(cellData)" class="view-grant-letter">View Grant Letter</div>
                </div> -->

            <dxi-column dataField="optionId" [allowEditing]="false" alignment="center" fixedPosition="left"
            caption="ID" [minWidth]="80" [visible]="false"></dxi-column>

            <dxi-column [allowSorting]="true" placeholder="Holding" dataField="holdingPercentage" cellTemplate="holdingCell"
            [allowEditing]="false" [visible]="false" caption="Holding">
            </dxi-column>
            <div *dxTemplate="let cellData of 'holdingCell'">
            {{cellData.value*1000 | percent:'2.2'}}
            </div>

            <!-- // start -->
            
        <dxi-column [fixed]="true" [minWidth]="70"  alignment = 'center' fixedPosition="right" type="button" caption="Actions" cellTemplate="actionButtonCellTemplate">
        </dxi-column>
        <div *dxTemplate="let cellInfo of 'actionButtonCellTemplate'">
          <img id="contextMenu" src="../../../../assets/three-dots.svg" alt="">
        </div>
        <div [style.color]="cellInfo['text'] == 'DELETE GRANT' ? 'red' : null" style="padding-left:21px;" *dxTemplate="let cellInfo of 'contextMenuTemplate'">
          <div [ngClass]="cellInfo['properties'] && cellInfo['properties']['disabled'] ? 'disabled' : null"  id="contextmenutooltiptarget">
            <span *ngIf="cellInfo['properties'] && cellInfo['properties']['disabled']; else displayPlainText" [ngbTooltip]="cellInfo['properties']['noPermissionText']"> <em>{{ cellInfo['text'] }}</em></span>
            <ng-template #displayPlainText> {{ cellInfo['text'] }}  </ng-template>
          </div>
        </div>


            </dx-data-grid>

            <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                <div *dxTemplate="let data of 'content'">
                  <div class="tooltip-content">{{toolTipText}}</div>
                </div>
            </dx-tooltip>
        </div>
        </div>
    </div>

    <div *ngIf="vestingChartData" class="row btm-20">
        <div class="col-md-12">
            <mat-label class="subhdr-text btm-10">
                Vesting Schedule
            </mat-label>
            <div class="card-lyot-dv">
                <div class="row btm-10">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                        <img class="cursor-hand float-right" ngbTooltip="List View" *ngIf="isChartView" src="../../assets/list.svg"
                                (click)="changeView('list')" />
                        <img class="cursor-hand float-right" ngbTooltip="Chart View" *ngIf="!isChartView" src="../../assets/chart.svg"
                                (click)="changeView('chart')" />
                    </div>
                </div>
                <div class="row" *ngIf="isChartView">
                    <div class="col-md-12">
                        <dx-chart [dataSource]="vestingChartData">
                            <dxi-series valueField="accumulatedGrantedOptionsForEmployee" name="Granted Options" color="#89C4F4"></dxi-series>
                            <dxi-series valueField="accumulatedVestedOptionsForEmployee" name="Vested Options" color="#4B77BE"></dxi-series>
                            <dxi-series valueField="accumulatedExercisedOptionsForEmployee" name="Exercised Options" color="#1F4788"></dxi-series>
                            <dxo-common-series-settings type="steparea" argumentField="date">
                            </dxo-common-series-settings>
                            <dxo-common-axis-settings [visible]="true" color="#000000">
                              <dxo-grid [visible]="false"></dxo-grid>
                            </dxo-common-axis-settings>
                            <dxi-annotation *ngFor="let cliff of cliffPointers; let i = index;" type="text" [allowDragging]="true" [text]="customCliffText(i)" [argument]="cliff.cliffDate" series="Vested Options" arrowLength="8"
                              arrowWidth="8">
                            </dxi-annotation>
                            <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" opacity="1" [border]="{
                                        opacity: '0',
                                        visible: false
                                    }" zIndex="1000">
                            </dxo-tooltip>
                            <dxo-argument-axis [axisDivisionFactor]="20"
                                argumentType="datetime">
                                <dxo-tick [visible]="true"></dxo-tick>
                              <!-- <dxo-tick-interval [years]="1"></dxo-tick-interval> -->
                              <dxo-title text="Time">
                                <dxo-font color="#000000" weight="800"></dxo-font>
                              </dxo-title>
                              <dxo-label
                                overlappingBehavior="hide"
                                displayMode="rotate"
                                rotationAngle="270">
                                    <dxo-format type="dd-MMM-yyyy"></dxo-format>
                            </dxo-label>
                            <dxi-constant-line [visible]="true" [width]="2"
                                [value]="todayDate" color="black" dashStyle="dash">
                                <dxo-label position="outside" [format]="todayDateText">
                                    <dxo-font color="black" size="13" weight="bold"></dxo-font>
                                </dxo-label>
                            </dxi-constant-line>
                            </dxo-argument-axis>
                            <dxi-value-axis>
                              <dxo-label [format]="axisForm"></dxo-label>
                              <dxo-title text="Options Vesting">
                                <dxo-font color="#000000" weight="800"></dxo-font>
                              </dxo-title>
                            </dxi-value-axis>
                            <dxo-legend [visible]="true" orientation="vertical"></dxo-legend>
                        </dx-chart>
                    </div>
                </div>
                <div class="row" *ngIf="!isChartView">
                    <div class="col-md-12">
                        <dx-data-grid [hoverStateEnabled]="true"
                        [dataSource]="vestOrExerciseTable" [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false"
                        [allowColumnReordering]="false" [allowColumnResizing]="false">

                        <dxo-filter-row [visible]="false"></dxo-filter-row>

                        <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>

                        <dxo-header-filter [visible]="false" [allowSearch]="false"> </dxo-header-filter>

                        <dxo-editing [allowUpdating]="false" [allowDeleting]="false"
                            [allowAdding]="false">
                        </dxo-editing>

                        <dxi-column [allowSorting]="true" dataField="date" [allowEditing]="false" format="dd-MMM-yyyy"
                        caption="Date" dataType="date" >
                        </dxi-column>

                        <dxi-column [allowSorting]="false" placeholder="Granted" dataField="accumulatedGrantedOptionsForEmployee"
                         caption="Granted" alignment="center" [allowEditing]="false">
                        </dxi-column>

                        <dxi-column [allowSorting]="true" placeholder="Vested" dataField="accumulatedVestedOptionsForEmployee"
                        [allowEditing]="false" caption="Vested" alignment="center"
                        >
                        </dxi-column>

                        <dxi-column [allowSorting]="true" placeholder="Stock Exercise" dataField="accumulatedStockExercisedOptionsForEmployee"
                        [customizeText]="customVal" [allowEditing]="false" alignment="center" caption="Stock Exercise" 
                        ></dxi-column>

                        <dxi-column [allowSorting]="true" placeholder="Cash Exercised" dataField="accumulatedCashExercisedOptionsForEmployee"
                        [allowEditing]="false" alignment="center" caption="Cashed Out"
                        ></dxi-column>

                        </dx-data-grid>

                        <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                            <div *dxTemplate="let data of 'content'">
                            <div class="tooltip-content">{{toolTipText}}</div>
                            </div>
                        </dx-tooltip>
                    </div>
                </div>
            </div>

        </div>
    </div>
    </div>  

    <app-esop-exercise *ngIf="exerciseDialog.show" [(exerciseDialog)]="exerciseDialog" [(esopPlanList)]="esopPlansTable" [(employeesList)]="exerciseEmpDtls" (visibleChange)="getExercise($event)"  (ngOnInitParent)="ngOnInit()"></app-esop-exercise>

    <app-esop-vest *ngIf="isShowVest" [(isShowVest)]="isShowVest" [(esopPlanList)]="esopPlansTable"
    [(employeesList)]="vestingEmpDtls" (visibleChange)="getVest($event)" [vestDialog]="vestDialog" (ngOnInitParent)="ngOnInit()"></app-esop-vest>

    <app-add-employee *ngIf="showDialogEmp" [editMode]="true" [employeeId]="employeeId" [(showDialogEmp)]="showDialogEmp" (visibleChange)="getEventEmp($event)">
    </app-add-employee>

    <app-resignation-popup class="dialog-back" *ngIf="showDialog" [(showDialog)]="showDialog" (visibleChange)="getEvent()" [employmentStatus]="emplStatus" [employeeId]="employeeId"></app-resignation-popup>

    <app-edit-esop-emp *ngIf="editRow" [(showDialog)]="editRow" [mode]="mode" [editRowData]="editRowData" [empList]="empList" [employmentStatus]="employmentStatus"
    [esopPlansTable]="esopPlansTable" [table]="table" [empId]="employeeId" [esopVestingTemplateList]="esopVestingTemplateList" (visibleChange)="getEditedRow()"></app-edit-esop-emp>

    <app-update-status *ngIf="showGrantStatusDialog" [bulkStateChangeIds]="bulkStateChangeIds" [(showDialog)]="showGrantStatusDialog" [nextGrantStatus]="nextStatusForGrant" (visibleChange)="getStatus()"></app-update-status>

    <app-answer-grant *ngIf="showAnswerGrantDialog" [(showDialog)]="showAnswerGrantDialog" [grantId]="grantId" [nextGrantStatus]="nextStatusForGrant" [viewOnly]="viewOnly" (visibleChange)="getStatus()"></app-answer-grant>

    <app-update-nominees *ngIf="!hasConfirmedNominees" [(showDialog)]="!hasConfirmedNominees" (visibleChange)="getStatus()"></app-update-nominees>

    <app-view-plan-template *ngIf="viewPlanTemplate" [(showDialog)]="viewPlanTemplate" [plan]="plan" (visibleChange)="getStatus()"></app-view-plan-template>

    
    

</div>
<!-- <div class="col-md-12 btm-20" *ngIf="employeeDetails==null">
    <div class="card-lyot text-align-center txt-nodata">No Data</div>
</div> -->