import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentEditorContainerComponent, DocumentEditor, DocumentEditorKeyDownEventArgs } from '@syncfusion/ej2-angular-documenteditor';
import { AuthenticationService } from 'src/app/authentication.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { readyException } from 'jquery';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { ConfirmDeleteComponent } from 'src/app/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-answer-grant',
  templateUrl: './answer-grant.component.html',
  styleUrls: ['./answer-grant.component.scss']
})
export class AnswerGrantComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() nextGrantStatus;
  @Input() grantId;
  @Input() viewOnly;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('documenteditor_default') public container: DocumentEditorContainerComponent;
  @ViewChild('document_frame') public documentFrame: ElementRef;
  statusChangeForm: FormGroup;
  showNotes: boolean = false;
  isSubmitted: boolean = false;
  termsAccepted: boolean = false;
  showSignButton: boolean = false;
  showRedirectDialog: boolean = false;
  url: string = "";
  ipAddress = '';
  service: string;
  fileEditingObject = {
    sfdtFormat: '',
    currentFileName: "",

  }

  isDocxFile = false;
  isOtherFile = false;
  grantLetterFile: any = null;
  fileType: string = "";
  redirectUrl: string = "";
  constructor(private authService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private readonly dialog: MatDialog) {
    this.service = this.authService.getSFDTformatForNewDocument();
  }

  ngOnInit() {
    this.url = this.authService.baseURL;
    if(this.nextGrantStatus && this.nextGrantStatus["availableStates"])
    this.showSignButton = this.nextGrantStatus["availableStates"][0] === "SIGNED_BY_HOLDER";
    if (this.nextGrantStatus != null) {
      this.statusChangeForm = this.fb.group({
        optionHolderIds: [[this.grantId], Validators.required],
        optionHolderState: [null, Validators.required],
        note: [null],
        dateOfTransaction: [null, Validators.required],
        ip: [null],
        sendGrantletter: true
      });
    }
    this.getGrantLetter();
  }

  remove_(str) {
    return str.replace(/_/g, ' ');
  }

  changeStatus() {
    const date = new Date;
    this.isSubmitted = true;
    this.statusChangeForm.get('dateOfTransaction').setValue(date.toISOString().replace("Z", "+0000"));
    if (this.termsAccepted) {
      this.ngxLoader.start();
      if (this.nextGrantStatus != null) {
        this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
          this.ipAddress = res.ip;
        });
        this.statusChangeForm.get('ip').setValue(this.ipAddress)
        this.statusChangeForm.get('optionHolderState').setValue(this.nextGrantStatus["availableStates"][0])
        this.authService.updateBulkGrantStatus(this.statusChangeForm.value).subscribe((response) => {
          this.showDialog = false;
          this.visibleChange.emit();
          this.ngxLoader.stop();
          this.toastr.success("Status updated successfully.", "Success!");
        },
          (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.error.reason, 'Error');
          })
      }
    }
  }

  confirmReject() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { header: "Confirm Reject?", text: "Are you sure you want to Reject?" },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.rejectGrant()
      }
    });
  }

  rejectGrant() {
    const date = new Date;
    this.isSubmitted = true;
    this.statusChangeForm.get('dateOfTransaction').setValue(date.toISOString().replace("Z", "+0000"));
    if (this.termsAccepted) {
      this.ngxLoader.start();
      if (this.nextGrantStatus != null) {
        this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
          this.ipAddress = res.ip;
        });
        this.statusChangeForm.get('ip').setValue(this.ipAddress)
        this.statusChangeForm.get('optionHolderState').setValue(this.nextGrantStatus["availableStates"][1])
        this.authService.updateBulkGrantStatus(this.statusChangeForm.value).subscribe((response) => {
          this.showDialog = false;
          this.visibleChange.emit();
          this.ngxLoader.stop();
          this.toastr.success("Status updated successfully.", "Success!");
        },
          (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.error.reason, 'Error');
          })
      }
    }
  }

  isTermsSelected(val) {

    this.termsAccepted = val;
  }

  onCreate(): void {

    this.container.documentEditor.fitPage('FitPageWidth');
    this.container.documentEditor.isReadOnly;
    this.container.documentEditor.open(this.fileEditingObject.sfdtFormat);
    this.container.documentEditor.documentName = this.fileEditingObject.currentFileName;
    this.container.restrictEditing = true;
    this.container.headers = [{
      'x-user-token': localStorage.getItem('currentUser')
    }];
    // let self = this;
    this.container.documentEditor.keyDown = (args: DocumentEditorKeyDownEventArgs) => {
      this.onKeyDown(args);
    }
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit();
  }

  getGrantLetter() {
    this.ngxLoader.start();
    this.authService.getSFDTformatofGrantLetter(this.grantId).subscribe((res: HttpResponse<any>) => {
      this.fileType = res.headers.get("fileType");
      if (this.fileType == 'text/plain' || this.fileType == 'application/json') {
        const blb = new Blob([res.body], { type: this.fileType });
        var reader = new FileReader();
        // var self = this;
        reader.onload = () => {
          this.fileEditingObject.sfdtFormat = reader.result.toString();
          this.fileEditingObject.currentFileName = "Grant-Letter.docx"
          this.isDocxFile = true;
          this.ngxLoader.stop();
        }
        reader.readAsText(blb);
      }
      else {
        let file = new Blob([res.body], { type: this.fileType });
        let url = URL.createObjectURL(file);
        this.grantLetterFile = this.sanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0&zoom=100');
        this.isOtherFile = true;
        this.ngxLoader.stop();
      }
    },
      (e) => {
        this.ngxLoader.stop();
        this.fileEditingObject.sfdtFormat = '';
        this.toastr.error(e.error.reason, "Failure!");
      });
  }

  public onKeyDown(args: DocumentEditorKeyDownEventArgs): void {

    let keyCode: number = args.event.which || args.event.keyCode;
    let isCtrlKey: boolean = (args.event.ctrlKey || args.event.metaKey) ? true : ((keyCode === 17) ? true : false);
    let isAltKey: boolean = args.event.altKey ? args.event.altKey : ((keyCode === 18) ? true : false);

    if (isCtrlKey && !isAltKey && keyCode === 83) {
      args.isHandled = true;
      args.event.preventDefault();
    } else if (isCtrlKey && isAltKey && keyCode === 83) {
      args.isHandled = true;
      args.event.preventDefault();
    } else if (isCtrlKey && keyCode === 80) {
      args.isHandled = true;
      args.event.preventDefault();
    }

  }
  signGrant() {
    this.showRedirectDialog = true;
    this.redirectUrl = this.nextGrantStatus["employeeSignUrl"]
  }
}
