import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../authentication.service';
import { DataService } from '../../services/new-round-notify.service';
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from '../../services/helper.service';
declare var bootbox: any;
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from '../../services/cache-provider.service';
import { ThrowStmt } from '@angular/compiler';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDeleteComponent } from 'src/app/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-current-investors-tab',
  templateUrl: './current-investors-tab.component.html',
  styleUrls: ['./current-investors-tab.component.scss']
})

export class CurrentInvestorsTabComponent implements OnInit {

  outstandingConv = [];
  existingShareholders: any = [];
  editOutstanding;
  shareholdersData;
  newInvestors = [
    {
      "investorName": '',
      "postRound": 0,
      "amountInvested": 0
    }
  ];
  isRoundNotSaved: boolean = false;
  newInvActualPercentage = 0;
  existingShareholderNamesForDropdown = [];
  isValid = false;
  isOutStandingValid = false;
  public errorFlags;
  public instrumentClasses;
  public shareHolderType;
  public groupNames;
  public isProfileInfo: boolean = false;
  public convIndex;
  public isRightToMaintainSectionValid: boolean;
  existingShareholder;
  exchangeRate = 1;
  companyCurrency;
  roundCurrency;
  onLoading: boolean = true;
  public disableripple: boolean = true;
  public helpTitle: any;
  public helpMessage: any;
  public isConvetiblehelp: boolean = false;
  public isExistInvtorhelp: boolean = false;
  public roundSize: any;
  totalExistingShareholdersInvestment = 0;
  isDisableExistingInv: any;
  public isInvestmentMet: boolean = false;
  public amountRaised = 0;
  public amountLefttoRaised;
  public basicamountRaised = 0;
  public basicLefttoAmtRaise = 0;
  public amountRaisedFromNewInvestors = 0;
  public totalAmountInvested = 0;
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  public isindividualValid: boolean = false;
  public isindividualconvertiValid: boolean = false;
  showDialog = false;
  securityId;
  securityIsValuationSet;
  public rights = [{name:"Pro Rata Investment", value:"Right To Pro Rata Investment"},
                    {name:"Maintain Capital", value:"Right To Maintain Capital"},
                    {name:"None", value:"none"}];
  public esName:boolean = false;
  public esRight:boolean = false;
  public esIA:boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router, private dataService: DataService,
    private utilityService: UtilityService,
    private helperService: HelperService,
    private changeDetection: ChangeDetectorRef,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private readonly dialog: MatDialog
    
  ) { }

  addNewInvestor() {
    let newInvestorObj = {
      "investorName": '',
      "postRound": 0,
      "amountInvested": 0
    };
    this.newInvestors.push(newInvestorObj);

    let newInvPostRoundTotal = 0;
    this.newInvestors.forEach(investor => {
      newInvPostRoundTotal = newInvPostRoundTotal + investor['postRound'];
    });
    this.newInvActualPercentage = newInvPostRoundTotal;
  }

  getExistingInvAmount(index, val) {
    let investorObj = {
      "name": this.existingShareholders[index].name,
      "rightToMaintainType": val,
      "preRoundPercentage": "",
      "postRoundPercentage": ""
    };
    let exsitinginvType = this.existingShareholders[index].rightToMaintainType;
    let existingInvestAmount = this.existingShareholders[index].investmentAmount;
    this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
      (res) => {
        if (res) {
          let type = res['rightToMaintainType'];
          let exinv = res['investmentAmount'];
          this.existingShareholders[index].investmentAmount = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, exinv);
          this.totalExistingShareholdersInvestment = 0;
          if (this.existingShareholders.length > 0) {
            this.existingShareholders.forEach(element => {
              if (typeof element.investmentAmount === "string") {
                this.totalExistingShareholdersInvestment += element.investmentAmount.replace(/,/g, "") / 1;
              }
              else {
                if (element.investmentAmount === '0') {
                  this.totalExistingShareholdersInvestment += Number(element.investmentAmount);
                }
                else {
                  this.totalExistingShareholdersInvestment += element.investmentAmount;
                }
              }
            });
          }
          //Added
          this.amountRaised = this.totalExistingShareholdersInvestment;
          this.totalAmountInvested = (this.totalExistingShareholdersInvestment + this.amountRaisedFromNewInvestors);

          if (this.totalAmountInvested < this.roundSize) {
            let remain = this.roundSize - this.totalAmountInvested;
            this.amountLefttoRaised = remain;
            this.isInvestmentMet = false;
          }
          else if(this.totalAmountInvested == this.roundSize){
            let remain = this.roundSize - this.totalAmountInvested;
            this.amountLefttoRaised = remain;
            this.isInvestmentMet = true;
          }
          else if (type != 'none') {
            this.toastr.error("This Investor  " + type + "  Will Exceed the investment required,.", "Error");
            setTimeout(()=>{                           //<<<---using ()=> syntax
              this.existingShareholders[index].rightToMaintainType = exsitinginvType;
            }, 500);
            this.changeDetection.detectChanges();
            this.existingShareholders[index].investmentAmount = existingInvestAmount;
            // let currentAmount = this.existingShareholders[index].investmentAmount.replace(/,/g, "") / 1;
            // this.amountRaised = (this.totalExistingShareholdersInvestment - currentAmount);
            this.totalExistingShareholdersInvestment = 0;
            this.existingShareholders.forEach(element => {
              if (typeof element.investmentAmount === "string") {
                this.totalExistingShareholdersInvestment += element.investmentAmount.replace(/,/g, "") / 1;
              }
              else {
                if (element.investmentAmount === '0') {
                  this.totalExistingShareholdersInvestment += Number(element.investmentAmount);
                }
                else {
                  this.totalExistingShareholdersInvestment += element.investmentAmount;
                }
              }
            });
            this.amountRaised = this.totalExistingShareholdersInvestment;
            this.totalAmountInvested = (this.amountRaised + this.amountRaisedFromNewInvestors);
            this.amountLefttoRaised = this.roundSize - this.totalAmountInvested;
            this.isInvestmentMet = false;
          }
          else {
            let remain = this.roundSize - this.totalAmountInvested;
            this.existingShareholders[index].investmentAmount = 0;
            this.amountLefttoRaised = remain;
            this.isInvestmentMet = false;
          }
        }
      },
      (e) => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }

  goToNewInvs() {
    this.ngxLoader.start();
    this.isRoundNotSaved = false;
    this.utilityService.formValidation();
    this.validation();
    let saveExistingShareHolder = [];
    let compnyCurrency = 0;
    this.existingShareholders.forEach(element => {
      if (element.investmentAmount != 0) {
        compnyCurrency = element.investmentAmount.replace(/,/g, "") / 1;
      }
      let extngShrHldr = {
        "investorName": element.name,
        "rightToMaintainType": element.rightToMaintainType,
        "investmentAmount": compnyCurrency
      };
      saveExistingShareHolder.push(extngShrHldr);
    });
    if (saveExistingShareHolder.length >= 0 ) {
      this.authService.saveExistingShareHolderInfo(saveExistingShareHolder).subscribe((result) => {
        if (result) {
          this.ngxLoader.stop();
          this.cacheService.set('curntInvstorSavRespns', JSON.stringify(result));
          this.removeDataFromCache()
          this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'newInvestors']);
        } else {
          this.ngxLoader.stop();
          this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'newInvestors']);
        }
      },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e.error.reason, "Error");
        });
    }
    else{
      this.ngxLoader.stop();
    }
  }

  goToRound() {
    if (this.existingShareholders && this.existingShareholders.length) {
      this.cacheService.set('existingShareholdersDetail', JSON.stringify(this.existingShareholders))
    }
    if (this.outstandingConv) {
      this.cacheService.set('outstandingConvDetail', JSON.stringify(this.outstandingConv))
    }
    this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'round']);
  }

  // Validator for Outstanding Conversion
  outStndgValidator(data) {
    if (data.length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  addExistingShareholder() {
    let existingShareObj = {
      "investmentAmount": 0,
      "investmentPercentage": 0,
      "numberOfShares": 0,
      "postMoneyValuation": 0,
      "name": '',
      "rightToMaintainType": '',
      "preMoneyValuation": 0
    }
    this.totalExistingShareholdersInvestment = 0;
    if (this.existingShareholders.length > 0) {
      this.existingShareholders.forEach(element => {
        if (element.investmentAmount)
          this.totalExistingShareholdersInvestment += element.investmentAmount.replace(/,/g, "") / 1;
      });
    }
    this.totalAmountInvested = (this.totalExistingShareholdersInvestment + this.amountRaisedFromNewInvestors);
    if (this.totalAmountInvested < this.roundSize) {
      if (this.existingShareholders.length > 0) {
        if (this.existingShareholders[this.existingShareholders.length - 1]['name'] === "" ||
          this.existingShareholders[this.existingShareholders.length - 1]['rightToMaintainType'] === "" ||
          this.existingShareholders[this.existingShareholders.length - 1]['investmentAmount'] === ""
        ) {
          this.toastr.error("Please fill previous investor details!", "Failure");
          return;
        }
      }

      let remain = this.roundSize - this.totalAmountInvested;
      this.amountLefttoRaised = remain;
      this.isInvestmentMet = false;
      this.existingShareholders.push(existingShareObj);
    }
    else if (this.totalAmountInvested === this.roundSize) {
      this.isInvestmentMet = true;
      this.toastr.error("Investment met, can not add more investors.", "Error");
    }
    else {
      this.isInvestmentMet = true;
      this.toastr.error("Investment excedded the round size", "Error");
    }
    this.isDisableExistingInv = this.existingShareholders.length - 1;
    this.isindividualValid = false;
  }

  deleteRTMRow(index) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    this.existingShareholders.splice(index, 1);
    this.totalExistingShareholdersInvestment = 0;
    if (this.existingShareholders.length > 0) {
      this.existingShareholders.forEach(element => {
        this.totalExistingShareholdersInvestment += element.investmentAmount.replace(/,/g, "") / 1;
        this.amountRaised = this.totalExistingShareholdersInvestment;
      });
      this.totalAmountInvested = (this.totalExistingShareholdersInvestment + this.amountRaisedFromNewInvestors);
      let remain = this.roundSize - this.totalAmountInvested;
      this.amountLefttoRaised = remain;
      this.isInvestmentMet = false;
      this.esName = false;
      this.esRight = false;
      this.esIA = false;
    }
    else {
      this.amountRaised = 0;
      this.amountLefttoRaised = this.basicLefttoAmtRaise;
      this.esName = false;
      this.esRight = false;
      this.esIA = false;
    }
    this.ngxLoader.stop();

      }
    });

  }

  /** Edit Existing investor Amout **/
  changeExistingAmout(index) {
    this.existingShareholders[index].rightToMaintainType = "none";
  }

  onChangeval(index, val, e) {
    var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    let re = val.replace(/,/g, "") / 1;
    this.totalExistingShareholdersInvestment = 0;
    if (this.existingShareholders.length > 0) {
      this.existingShareholders.forEach(element => {
        this.totalExistingShareholdersInvestment += element.investmentAmount.replace(/,/g, "") / 1;
      });
    }
    this.totalAmountInvested = (this.totalExistingShareholdersInvestment + this.amountRaisedFromNewInvestors);
    if (this.totalAmountInvested <= this.roundSize) {
      let cc = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, val);
      if(e) {
        if(e.target.value) {
          let CSVLength = cc.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = cc;
              this.existingShareholders[index].investmentAmount = cc;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
            e.target.value = cc;
            if(start != 0 || end != 0){
                let difference = valueLength - CSVLength;
                start = start - difference;
                end = end - difference;
            }
            else{
                start = start;
                end = end;
            }  
          } 
          else {
            e.target.value = cc;
          }
        }
       // restore from variables...
       e.target.setSelectionRange(start, end);
      } 
      else {
        this.existingShareholders[index].investmentAmount = cc;
      }
      this.amountRaised = this.totalExistingShareholdersInvestment;
      let remain = this.roundSize - this.totalAmountInvested;
      this.amountLefttoRaised = remain;
      this.isInvestmentMet = false;
      if (this.totalAmountInvested === this.roundSize) {
        this.isInvestmentMet = true;
      }
    }
    else {
      let currentAmount = this.existingShareholders[index].investmentAmount.replace(/,/g, "") / 1;
      this.amountRaised = (this.totalExistingShareholdersInvestment - currentAmount);
      this.totalAmountInvested = (this.amountRaised + this.amountRaisedFromNewInvestors);
      let remain = this.roundSize - this.totalAmountInvested;
      this.amountLefttoRaised = remain;
      this.toastr.error("Investment exceeded the round size", "Error");
      this.existingShareholders[index].investmentAmount = 0;
      this.totalExistingShareholdersInvestment = this.totalExistingShareholdersInvestment - re;
    }
  }

  exInvAmountchange(index, val) {
    let re = val.replace(/,/g, "") / 1;
    let investorObj = {
      "name": null,
      "rightToMaintainType": null,
      "preRoundPercentage": 0,
      "postRoundPercentage": null,
      "investmentAmount": re
    };
    
    this.totalExistingShareholdersInvestment = 0;
    if (this.existingShareholders.length > 0) {
      this.existingShareholders.forEach(element => {
        this.totalExistingShareholdersInvestment += element.investmentAmount.replace(/,/g, "") / 1;
      });
    }
    this.totalAmountInvested = (this.totalExistingShareholdersInvestment + this.amountRaisedFromNewInvestors);
    if (this.totalAmountInvested <= this.roundSize) {
      this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
        (res) => {
          if(res['investmentAmount'] > investorObj.investmentAmount || res['investmentAmount'] < investorObj.investmentAmount){
            this.existingShareholders[index].investmentAmount = res['investmentAmount'];
            this.toastr.info("Minor adjustments in investment amount made to make it a multiple of share price");
          }
          this.existingShareholders[index].investmentAmount = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency,this.existingShareholders[index].investmentAmount);
          this.totalExistingShareholdersInvestment = 0;
          this.existingShareholders.forEach(element => {
            this.totalExistingShareholdersInvestment += element.investmentAmount.replace(/,/g, "") / 1;
          });
          this.amountRaised = this.totalExistingShareholdersInvestment;
          this.amountLefttoRaised = this.roundSize - (this.totalExistingShareholdersInvestment + this.amountRaisedFromNewInvestors);
          this.isInvestmentMet = false;
          if (this.totalAmountInvested === this.roundSize) {
            this.isInvestmentMet = true;
          }
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
      );
    }
    else {
      let currentAmount = this.existingShareholders[index].investmentAmount.replace(/,/g, "") / 1;
      this.amountRaised = (this.totalExistingShareholdersInvestment - currentAmount);
      this.totalAmountInvested = (this.amountRaised + this.amountRaisedFromNewInvestors);
      let remain = this.roundSize - this.totalAmountInvested;
      this.amountLefttoRaised = remain;
      this.toastr.error("Investment exceeded the round size", "Error");
      this.existingShareholders[index].investmentAmount = 0;
      this.totalExistingShareholdersInvestment = this.totalExistingShareholdersInvestment - re;
    }
  }


  getPercent(postVal) {
    return (postVal * 100).toFixed(2);
  }

  //Refesh existing shareholder section
  refreshRights() {
    this.authService.roundCreationrefreshRTM().subscribe((response) => {
      if (response) {
        let currentInvtotalInvestment = 0;
        let es = response['rightToMaintainShareholders'];
        let newInvtotalInvestment = response['amountRaisedFromNewInvestors'];
        es.forEach((element, index) => {
          currentInvtotalInvestment += element.investmentAmount;
          let inv = element.investmentAmount;
          es[index]['investmentAmount'] = this.utilityService.inputCSVformatCurrencyRound(this.companyCurrency, inv);
        });
        this.existingShareholders = es;
        this.isRightToMaintainSectionValid = true;
        this.amountRaised = currentInvtotalInvestment;
        this.amountLefttoRaised = this.roundSize - (currentInvtotalInvestment + newInvtotalInvestment);
        this.totalAmountInvested  = currentInvtotalInvestment + newInvtotalInvestment;
        if (this.totalAmountInvested === this.roundSize) {
          this.isInvestmentMet = true;
        }
      } else {
        this.toastr.error("Error: Response as null!", "Error");
      }
    }, (e) => {
      this.toastr.error("Error: " + e.error.reason, "Error");
    })
  }

  fieldEdited($event) {
    this.isRoundNotSaved = true;
  }

  getBasicDetails() {
    this.ngxLoader.start();
    this.authService.getBasicCurrentInvestorData().subscribe((response) => {
      if (response) {
        this.roundSize = response['roundSize'];
        this.existingShareholderNamesForDropdown = response['namesForDropdownOfExistingShareholders'];
        this.outstandingConv = response['convertible'];
        this.existingShareholders = response['rightToMaintainShareholders'];
        this.instrumentClasses = response['instrumentClassNamesForDropdown'];
        this.shareHolderType = response['shareholderTypesForDropdown'];
        this.groupNames = response['groupNamesForDropdown'];
        this.exchangeRate = response['exchangeRate'];
        this.companyCurrency = response['companyCurrency'];
        this.roundCurrency = response['currencyForRound'];
        this.errorFlags = response['errorFlags'];
        this.basicamountRaised = response['amountRaised'];
        this.amountRaisedFromNewInvestors = response['amountRaisedFromNewInvestors'];

        //For Basic Details
        let basicData = {
          isBasicDetailsFilled: true,
          roundName: response['roundName'],
          roundSize: response['roundSize'],
          roundValuation: response['roundValuation'],
          amountRaised: response['amountRaised'],
          amountLeftToRaise: response['amountLeftToRaise'],
          leadInvestorName: response['leadInvestorName'], 
          sharePrice: response['pricePerShare'],
          roundCurrency: response['currencyForRound'],
          isRoundNotSaved: this.isRoundNotSaved,
        }
        this.dataService.updateBasicData(basicData);
        this.dataService.updateData(this.errorFlags);
        this.isRightToMaintainSectionValid = this.errorFlags['isRightToMaintainSectionValid'];
        this.existingShareholders.forEach(element => {
          if (element.investmentAmount != 0) {
            let re = element.investmentAmount;
            element['investmentAmount'] = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, re);
            this.amountRaised += element['investmentAmount'].replace(/,/g, "") / 1;
          }
        });

        this.totalAmountInvested = this.amountRaised + this.amountRaisedFromNewInvestors;
        if (this.amountRaised == 0) {
          this.amountRaised = 0;
          this.amountLefttoRaised = response['amountLeftToRaise'];
          this.isInvestmentMet = false;
        }
        else if (this.totalAmountInvested < this.roundSize) {
          let remain = this.roundSize - this.totalAmountInvested;
          this.amountLefttoRaised = remain;
          this.isInvestmentMet = false;
        }
        else if (this.totalAmountInvested === this.roundSize) {
          this.isInvestmentMet = true;
        }
        else if (this.totalAmountInvested > this.roundSize) {
          let remain = this.roundSize - this.totalAmountInvested;
          this.amountLefttoRaised = remain;
          this.isInvestmentMet = false;
        }
        this.ngxLoader.stop();
        // get data from cache
        this.getDataFromCache()
      } else {
        // get data from cache
        this.getDataFromCache()
        this.toastr.error("Response is Null!!", "Error");
      }
    }, (e) => {
      // get data from cache
      this.getDataFromCache()
      this.toastr.error("Error: " + e.error.reason, "Error");
    })
  }

  openValuationPopup(id, isValuationSet) {
      this.showDialog = true;
      this.securityId = id;
      this.securityIsValuationSet = isValuationSet;
  }

  getEvent() {
      this.showDialog = false;
      this.getBasicDetails();
  }

  identifierCSS(identifier) {
    switch (identifier) {
      case "FF":
        return identifier;
      case "SF":
        return identifier;
      case "AF":
        return identifier;
      case "ES":
        return identifier;
      case "SA":
        return identifier;
      case "SB":
        return identifier;
      case "SC":
        return identifier;
      case "AC":
        return identifier;
      case "SD":
        return identifier;
      case "SE":
        return identifier;
      case "AF1":
        return "Def1";
      case "AF2":
        return "Def2";
      case "AF3":
        return "Def3";
      case "SP":
        return "Def4";
      case "SG":
        return "Def5";
      case "SH":
        return "Def6";
      case "SA1":
        return "Def7";
      case "SA2":
        return "Def8";
      case "SA3":
        return "Def1";
      case "SB1":
        return "Def2";
      case "SB2":
        return "Def3";
      case "SP1":
        return "Def4";
      default:
        return "defaultClass";
    }
  }

  getDataFromCache() {
    let cachedShareHldrsData = this.cacheService.get('existingShareholdersDetail')
    if (cachedShareHldrsData) {
      this.existingShareholders = JSON.parse(cachedShareHldrsData)
    }

    let cachedConvertibleData = this.cacheService.get('outstandingConvDetail')
    if (cachedConvertibleData) {
      this.outstandingConv = JSON.parse(cachedConvertibleData)
    }
  }

  removeDataFromCache() {
    let cachedShareHldrsData = this.cacheService.get('existingShareholdersDetail')
    if (cachedShareHldrsData) {
      this.cacheService.remove('existingShareholdersDetail')
    }

    let cachedConvertibleData = this.cacheService.get('outstandingConvDetail')
    if (cachedConvertibleData) {
      this.cacheService.remove('outstandingConvDetail')
    }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  // help section show
  showHelp(section) {
    
    if(section == this.helper){
        return;
    }
    const targetElement = document.getElementById(section);
    if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
    }
    
    this.helpSections = this.helperService.getHelpContent(
        "CurrentInvestorsTabComponent",
        section
    );
    this.helper = section;
  }

  // help section hide
  hideHelp() {
    this.helper = "";
  }

  // input field olny number and comma
  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
      return false;
    }
    else {
      return true;
    }
  }

  formatCurrency(value) {
    if (value > 0) {
        if (value.toString().length > 5) {
            return this.utilityService.convertedFormatCurrency(this.companyCurrency, value);
        }
        else {
            return this.utilityService.formatCurrency(this.companyCurrency, value);
        }
    }
    else {
        return 0;
    }
  }

  formatNoOfShares(value) {
    return this.utilityService.formatCurrencyDeci(this.roundCurrency, value);
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, value);
      }
    }
    else {
      let sign = "-";
      if (value.toString().length > 5) {
        return sign + this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, value);
      }
    }
  }

  showCurrency(amount) {
    return this.utilityService.calculateCurrency(this.roundCurrency, this.companyCurrency, this.exchangeRate, amount)
  }

  skip() {
    this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'newInvestors']);
  }

  /** Edit Existing investor **/
  editExistingInvestorRow(index) {
    this.isDisableExistingInv = index;
    this.isindividualValid = false;
  }

  individualSave(index){
    this.utilityService.formValidation();
    this.existingShareholders.forEach((element, i )=> {
      if(i === index){
        if (element.name === "" || (element.investmentAmount === 0 || element.investmentAmount === "") || element.rightToMaintainType === "" ) {
          this.isindividualValid = false;
          if (element.name === "") {
            this.esName = true;
            this.toastr.error("Please choose existing investor..", "Error");
          }
          if (element.rightToMaintainType === "") {
            this.esRight = true;
            this.toastr.error("Please choose" + element.name + "  Right to maintain type", "Error");
          }
          if (element.investmentAmount === 0 || element.investmentAmount === "") {
            this.esIA = true;
            this.toastr.error("Please enter Investment Amount..", "Error");
          }
          return;
        }
        else {
          this.isindividualValid = true;
        }
      }
    });
  }

  validation(){
    this.utilityService.formValidation();
    this.existingShareholders.forEach((element, i )=> {
      if (element.name === "" || (element.investmentAmount === 0 || element.investmentAmount === "") || element.rightToMaintainType === "" ) {
        this.isindividualValid = false;
        if (element.name === "") {
          this.esName = true;
          this.toastr.error("Please choose existing investor..", "Error");
        }
        if (element.rightToMaintainType === "") {
          this.esRight = true;
          this.toastr.error("Please choose" + element.name + "  Right to maintain type", "Error");
        }
        if (element.investmentAmount === 0 || element.investmentAmount === "") {
          this.esIA = true;
          this.toastr.error("Please enter Investment Amount..", "Error");
        }
        return;
      }
      else {
        this.isindividualValid = true;
      }
    });
  }
  
  ngOnInit() {
    this.getBasicDetails();
  }
}