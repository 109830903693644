/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-template-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@tinymce/tinymce-angular/tinymce-tinymce-angular.ngfactory";
import * as i3 from "@tinymce/tinymce-angular";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@angular/common";
import * as i12 from "./email-template-editor.component";
var styles_EmailTemplateEditorComponent = [i0.styles];
var RenderType_EmailTemplateEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailTemplateEditorComponent, data: {} });
export { RenderType_EmailTemplateEditorComponent as RenderType_EmailTemplateEditorComponent };
function View_EmailTemplateEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["emailTemplatePreview", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_EmailTemplateEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "overflow:hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "editor", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onSaveContent"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSaveContent" === en)) {
        var pd_0 = (_co.setPreview() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.body = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EditorComponent_0, i2.RenderType_EditorComponent)), i1.ɵdid(2, 4374528, null, 0, i3.EditorComponent, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID, [2, i3.TINYMCE_SCRIPT_SRC]], { init: [0, "init"] }, { onSaveContent: "onSaveContent" }), i1.ɵpad(3, 3), i1.ɵpod(4, { height: 0, menubar: 1, plugins: 2, toolbar: 3 }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.EditorComponent]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _ck(_v, 4, 0, 400, false, _ck(_v, 3, 0, "advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"), "undo redo | formatselect | bold italic backcolor | \n            alignleft aligncenter alignright alignjustify | \n            bullist numlist outdent indent | removeformat | help"); _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.body; _ck(_v, 6, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_EmailTemplateEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["color", "success"], ["id", "saveChanges"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(2, 606208, null, 0, i10.MatDialogClose, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "success"; _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.emailTemplateData; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).ariaLabel || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_EmailTemplateEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { emailTempPrevDiv: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i10.MatDialogTitle, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Edit Email Template"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i10.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailTemplateEditorComponent_1)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailTemplateEditorComponent_2)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["align", "end"], ["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i10.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["id", "editChanges"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(14, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailTemplateEditorComponent_3)), i1.ɵdid(16, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["color", "warn"], ["id", "discardChanges"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dialogRef.close(i1.ɵnov(_v, 19).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(19, 606208, null, 0, i10.MatDialogClose, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Discard"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = (_co.data.action == "view"); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.data.action == "edit"); _ck(_v, 9, 0, currVal_2); var currVal_5 = ((_co.data.action == "view") ? "warn" : "success"); _ck(_v, 13, 0, currVal_5); var currVal_7 = (_co.data.action == "view"); _ck(_v, 16, 0, currVal_7); var currVal_11 = "warn"; _ck(_v, 18, 0, currVal_11); var currVal_12 = undefined; _ck(_v, 19, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_3 = (i1.ɵnov(_v, 13).disabled || null); var currVal_4 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = ((_co.data.action == "view") ? "Edit" : "Preview"); _ck(_v, 14, 0, currVal_6); var currVal_8 = (i1.ɵnov(_v, 18).disabled || null); var currVal_9 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); var currVal_10 = (i1.ɵnov(_v, 19).ariaLabel || null); _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10); }); }
export function View_EmailTemplateEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-template-editor", [], null, null, null, View_EmailTemplateEditorComponent_0, RenderType_EmailTemplateEditorComponent)), i1.ɵdid(1, 114688, null, 0, i12.EmailTemplateEditorComponent, [i10.MatDialogRef, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailTemplateEditorComponentNgFactory = i1.ɵccf("app-email-template-editor", i12.EmailTemplateEditorComponent, View_EmailTemplateEditorComponent_Host_0, {}, {}, []);
export { EmailTemplateEditorComponentNgFactory as EmailTemplateEditorComponentNgFactory };
