<div class="row" (mouseleave)="hideHelp()">
    <div class="col-md-6 col-lg-8 col-xl-9">
        <mat-card id="Round_Tab">
            <mat-card-header>
                <mat-card-title>Round</mat-card-title>
            </mat-card-header>
            <mat-divider class="divider-line"></mat-divider>
            <mat-card-content>
                <form class="needs-validation" novalidate>
                    <div (mouseover)="showHelp('Round_Tab')">
                        <div class="row">
                            <div class="col-md-12">
                                <p class="content-pg-1 top-20 btm-20">
                                    Enter the basic details of the round you wish to create. 
                                    Specify the values in line with the funding terms and conditions decided.
                                </p>

                                <div *ngIf="this.currencyForRound != this.companyCurrency && this.currencyForRound != null"
                                    class="alert alert-info alert-labeled alrt-mrgn top-20 btm-20">
                                    <P type="button" class="close-help" data-dismiss="alert">
                                        <img src="../../../assets/message-close.png" />
                                    </P>
                                    <div class="alert-labeled-row">
                                        <div class="alert-label alert-label-left alert-labelled-cell">
                                            <img src="../../../assets/message-info.svg" />
                                        </div>
                                        <p class="content-pg-1 alert-body alert-body-right alert-labelled-cell">
                                            The Round currency you have selected ( {{this.currencyForRound}} ) is
                                            different from Company currency ( {{this.companyCurrency}} )
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="btm-20">
                                    <mat-label class="required">Round Name</mat-label>
                                    <input type="text" [(ngModel)]="roundName" #roundNameErr="ngModel" (change)="fieldEdited($event)"
                                        class="form-control inputs top-10" placeholder="Round Name" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="getNameNewRnd(roundName)"
                                         title="" required />
                                    <span *ngIf="roundNameErr.errors">
                                        <span *ngIf="(roundNameErr.dirty || roundNameErr.touched) && !rName"
                                            class="alert alert-danger">
                                            Round Name is required!
                                        </span>
                                        <span *ngIf="roundNameErr.errors?.required && rName" class="alert alert-danger">
                                            Round Name is required!
                                        </span>
                                    </span>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="required">Round Description</mat-label>
                                    <textarea [(ngModel)]="roundDescription" class="form-control inputs top-10" [ngModelOptions]="{standalone: true}"
                                        #roundDescriptionErr="ngModel" rows="6" placeholder="Round Description" title="" (change)="fieldEdited($event)"
                                        required></textarea>
                                    <span *ngIf="roundDescriptionErr.errors">
                                        <span class="alert alert-danger"
                                            *ngIf="roundDescriptionErr.errors?.required && rDescription">
                                            Round Description is required!
                                        </span>
                                        <span class="alert alert-danger"
                                            *ngIf="(roundDescriptionErr.dirty || roundDescriptionErr.touched) && !rDescription">
                                            Round Description is required!
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="btm-20">
                                    <mat-label class="required">Identifier</mat-label>
                                    <input type="text" [(ngModel)]="roundIdentifier" #roundIdentifierErr="ngModel" (change)="fieldEdited($event)"
                                        class="form-control inputs top-10" placeholder="Round Identifier" [ngModelOptions]="{standalone: true}" title=""
                                        required />
                                    <span *ngIf="roundIdentifierErr.errors">
                                        <span class="alert alert-danger"
                                            *ngIf="(roundIdentifierErr.dirty || roundIdentifierErr.touched) && !ridentifier">
                                            Round Identifier is required!
                                        </span>
                                        <span class="alert alert-danger"
                                            *ngIf="roundIdentifierErr.errors?.required && ridentifier">
                                            Round Identifier is required!
                                        </span>
                                    </span>
                                </div>
                                <!-- <div class="btm-20">
                                    <mat-label>Bridge Round</mat-label>
                                    <div class="top-10">
                                        <input class="alert-status-bridge" id="bridgeRnd" type="checkbox"
                                            [(ngModel)]="bridgeRound" data-toggle="switch" checked
                                            data-off-color="default" title="" />
                                    </div>
                                </div> -->
                                <div class="btm-20">
                                    <mat-label class="required">Start Date</mat-label><br>
                                    <div class="input-eyePlaceholder">
                                        <input matInput [matDatepicker]="dp" [(ngModel)]="issueDate" #issueDateErr="ngModel" max="9999-12-31" (change)="fieldEdited($event)"
                                            class="form-control top-10" title="" placeholder="dd-mmm-yyyy" [ngModelOptions]="{standalone: true}" required />
                                        <mat-datepicker-toggle matSuffix [for]="dp" matRippleDisabled="disabled" matRippleColor="red">
                                            <mat-icon matDatepickerToggleIcon matRippleDisabled="disabled"></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dp></mat-datepicker>
                                    </div>
                                    <span *ngIf="issueDateErr.errors">
                                        <span class="alert alert-danger"
                                            *ngIf="(issueDateErr.dirty || issueDateErr.touched) && !rissueDate">
                                            Start Date is required!
                                        </span>
                                        <span class="alert alert-danger"
                                            *ngIf="issueDateErr.errors?.required && rissueDate">
                                            Start Date is required!
                                        </span>
                                    </span>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="required">Number Of Decimals</mat-label>
                                    <div class="top-10">
                                        <select class="form-control cursor-hand" [(ngModel)]="numberOfDecimals" (change)="fieldEdited($event)" #nofDecimalErr="ngModel" [ngModelOptions]="{standalone: true}"  required>
                                            <option value="null" selected disabled>Select Decimal</option>
                                            <option *ngFor="let deci of deciList">{{ deci }}</option>
                                        </select>
                                        <span *ngIf="nofDecimalErr.errors">
                                            <span class="alert alert-danger"
                                                *ngIf="nofDecimalErr.errors?.required && noDecimals">
                                                Decimal is required!
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="btm-20">
                                    <mat-label class="required">Currency for round</mat-label>
                                    <div class="top-10">
                                        <select class="form-control cursor-hand" [(ngModel)]="currencyForRound" #currencyRnd        
                                            (change)="selectedCurrency($event.target.value); updateCurrencyFormat(); fieldEdited($event)" [ngModelOptions]="{standalone: true}">
                                            <option *ngIf="companyCurrency === null" disabled selected [value]="null">
                                                Select Currency</option>
                                            <!-- <option *ngIf="companyCurrency != null || companyCurrency === 'INR - ₹' "
                                                disabled selected [value]="null">{{companyCurrency}}</option> -->
                                            <option *ngFor="let cur of currencyList; let i = index" value="{{ cur }}">
                                                {{ cur }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="btm-20">
                                    <mat-label class="required">Exchange Rate</mat-label>
                                    <input [readOnly]="!isExchangeRteEditable" min="0" type="number" #exRateErr="ngModel"
                                        [(ngModel)]='exchangeRate' class="form-control inputs top-10"
                                        (change)="updateCurrency(); fieldEdited($event)" placeholder="Exchange Rate"
                                        (keydown)=onKeydownEvent($event) [ngModelOptions]="{standalone: true}" title="" required />
                                    <span *ngIf="exRateErr.errors">
                                        <span class="alert alert-danger"
                                            *ngIf="(exRateErr.dirty || exRateErr.touched) && !rexchange">
                                            Exchange Rate is required!
                                        </span>
                                        <span class="alert alert-danger"
                                            *ngIf="(exRateErr.errors?.required) && rexchange">
                                            Exchange Rate is required!
                                        </span>
                                    </span>
                                </div>
                                <!-- <div class="btm-20">
                                    <mat-label class="required">Rounding Mechanisam</mat-label>
                                    <div class="top-10">
                                        <select class="form-control cursor-hand" [(ngModel)]="roundMechanisam" #rndMechErr="ngModel" required>
                                            <option value="null" selected disabled>Select Round Mechanisam</option>
                                            <option *ngFor="let rndMechnanism of numberRoundDropdownvalues">{{ rndMechnanism }}</option>
                                        </select>
                                        <span *ngIf="rndMechErr.errors">
                                            <span class="alert alert-danger"
                                                *ngIf="rndMechErr.errors?.required && roundMechanis">
                                                Round Mechanisam is required!
                                            </span>
                                        </span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="content-pg-1 btm-20">
                                Enter details of the round size and the type of round you want to raise. 
                                Specify if there is any adjustment to ESOP as part of this round.<br>
                                Review the post money and dilution %. These are used to create the cap table.
                            </p>
                        </div>
                    </div>

                    <mat-card id="PricedRound_Tab" (mouseover)="showHelp('PricedRound_Tab')">
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-label class="required">Priced Round</mat-label><br>
                                    <div class="top-10">
                                        <input class="alert-status-price" id="prcRnd" type="checkbox"
                                            [(ngModel)]="pricedRound" (change)="updateSliders(); fieldEdited($event)" data-toggle="switch"
                                            checked data-off-color="default" [ngModelOptions]="{standalone: true}" title="" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-label class="required">Auto Dilute ESOP (%)</mat-label><br>
                                    <div class="top-10">
                                        <input class="alert-status-eosp" id="diluEsop" type="checkbox"
                                            [(ngModel)]="diluteEsop"  data-toggle="switch" (change)="fieldEdited($event)"
                                            checked data-off-color="default" [ngModelOptions]="{standalone: true}" title="" />
                                    </div>
                                </div>
                                <div id="sample" class="col-md-4">
                                    <mat-label class="required">Post Round ESOP(%)</mat-label>
                                    <input type="text" appDecimalNumber decimals="2" min="0" maxlength="5" id="custom-percent"
                                        class="form-control top-10 text-align-right" placeholder="Post Round Esop"
                                        #esopErr="ngModel" (ngModel)="esop" [ngModel]="esop | percent: '2.2'" (change)="fieldEdited($event)"
                                        (keypress)="onlyNumberKey($event)" (blur)="onChangeesop($event.target.value)"
                                        [readonly]="diluteEsop" title="" [ngModelOptions]="{standalone: true}" required />
                                    <span *ngIf="esopErr.errors">
                                        <span *ngIf="(esopErr.dirty || esopErr.touched) && resop"
                                            class="alert alert-danger">
                                            Valid ESOP is required!
                                        </span>
                                        <span *ngIf="(esopErr.errors?.required) && resop" class="alert alert-danger">
                                            Valid ESOP is required!
                                        </span>
                                    </span>
                                </div>
                                <!-- <div id="sample" class="col-md-4" *ngIf="!pricedRound">
                                    <mat-label class="required">Discount to Next Round(%)</mat-label>
                                    <input class="form-control top-10 text-align-right" appDecimalNumber decimals="2"
                                        min="0" maxlength="5" placeholder="Discount to Next Round" (ngModel)="discount"
                                        [ngModel]="discount | percent: '2.2'" #discountErr="ngModel"
                                        (keypress)="onlyNumberKey($event)"
                                        (blur)="onChangeDiscount($event.target.value)" title="" required />
                                    <span *ngIf="discountErr.errors?.required && rDiscount" class="alert alert-danger">
                                        Valid Discount is required!
                                    </span>
                                </div> -->
                            </div>
                            <div class="row top-20">
                                <div class="col-md-4">
                                    <mat-label class="required">Round Size</mat-label>
                                    <label *ngIf="this.currencyForRound != null"
                                        class="CurrencySymbol">{{currencyForRound.substr(currencyForRound.length-2)}}</label>
                                    <label *ngIf="this.currencyForRound === null && companyCurrency != null"
                                        class="CurrencySymbol">{{companyCurrency.substr(companyCurrency.length-2)}}</label>
                                    <input class="form-control top-10 text-align-right" type="text" id="rndSize" #rndSize="ngModel"
                                        [max]="roundSizeMax" [min]="roundSizeMin" [(ngModel)]="roundSizeText"
                                        (input)="onChangeRoundSize('ip', $event)" placeholder="Round Size" (change)="fieldEdited($event)"
                                        (keypress)="onlyNumberKey($event)" [ngModelOptions]="{standalone: true}"  title=""
                                        required />
                                        <span *ngIf="rndSize.errors">
                                            <span *ngIf="(rndSize.dirty || rndSize.touched) && !rRoundSize" class="alert alert-danger">
                                                Round Size is required!
                                            </span>
                                            <span *ngIf="rndSize.errors?.required && rRoundSize" class="alert alert-danger">
                                                Round Size is required!
                                            </span>
                                        </span>
                                    <!-- <span *ngIf="rndSize.errors && (rndSize.dirty || rndSize.touched)"  class="alert alert-danger">
                                        <span>
                                            Round Size is required!
                                        </span>
                                    </span> -->
                                </div>
                                <div class="col-lg-5 col-md-7 col-sm-7 top-35">
                                    <!-- <div class="margin-top-min10">
                                        <mat-slider style="width:100%" [max]="roundSizeMax" [min]="roundSizeMin"
                                            [step]="step" [(ngModel)]="roundSize" [thumbLabel]="thumbLabel"
                                            [vertical]="vertical" (input)="onChangeRoundSize('slider', null)" (change)="val()">
                                        </mat-slider>
                                        <div class="row margin-top-min10">
                                            <div class="col-md-4 text-align-left">
                                                <span class="slider-val-txt">{{getTotalValueTble(roundSizeMin)}}</span>
                                            </div>
                                            <div class="col-md-4 text-align-center">
                                                <span
                                                    class="slider-val-txt">{{getTotalValueTble(roundSizeMax/2)}}</span>
                                            </div>
                                            <div class="col-md-4 text-align-right">
                                                <span class="slider-val-txt">{{getTotalValueTble(roundSizeMax)}}</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row top-20" *ngIf="!pricedRound">
                                <div class="col-md-4">
                                    <mat-label class="required">Base Valuation</mat-label>
                                    <img class="left-2" src="../../../assets/esop/information.svg" ngbTooltip="Post-Money valuation of the previous round: {{ basicRndDetails.previousRoundPostMoneyValuation | CustomeCurrency: '2' }}" />
                                    <label *ngIf="this.currencyForRound != null"
                                        class="CurrencySymbol">{{currencyForRound.substr(currencyForRound.length-2)}}</label>
                                    <label *ngIf="this.currencyForRound === null && companyCurrency != null"
                                        class="CurrencySymbol">{{companyCurrency.substr(currencyForRound.length-2)}}</label>
                                    <input class="form-control top-10 text-align-right" #valCapErr="ngModel" (change)="fieldEdited($event)"
                                        [max]="preMoneyMax" [min]="preMoneyMin" (keypress)="onlyNumberKey($event)"
                                        (input)="onChangeValCap('ip', $event)" placeholder="Base Valuation"
                                        [(ngModel)]="valuationCapText" type="text" [ngModelOptions]="{standalone: true}" title=""
                                        required />
                                    <span *ngIf="valCapErr.errors && (valCapErr.dirty || valCapErr.touched)
                                            " class="alert alert-danger">
                                        <span>
                                            Base Valuation is required!
                                        </span>
                                    </span>
                                </div>
                                <div class="col-lg-5 col-md-7 col-sm-7 top-35">
                                    <!-- <div class="margin-top-min10">
                                        <mat-slider (input)="onChangeValCap('slider', null)" style="width:100%"
                                            [disabled]="disabled" [invert]="invert" [max]="preMoneyMax"
                                            [min]="preMoneyMin" [(ngModel)]="valuationCap" [step]="step"
                                            [thumbLabel]="thumbLabel" [tickInterval]="tickInterval"
                                            [vertical]="vertical" (change)="val()">
                                        </mat-slider>
                                        <div class="row margin-top-min10">
                                            <div class="col-md-4 text-align-left">
                                                <span class="slider-val-txt">{{getTotalValueTble(preMoneyMin)}}</span>
                                            </div>
                                            <div class="col-md-4 text-align-center">
                                                <span class="slider-val-txt">{{getTotalValueTble(preMoneyMax/2)}}</span>
                                            </div>
                                            <div class="col-md-4 text-align-right">
                                                <span class="slider-val-txt">{{getTotalValueTble(preMoneyMax)}}</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row top-20" *ngIf="pricedRound">
                                <div class="col-md-4">
                                    <mat-label class="required">Pre-Money Valuation</mat-label>
                                    <label *ngIf="this.currencyForRound != null"
                                        class="CurrencySymbol">{{currencyForRound.substr(currencyForRound.length-2)}}</label>
                                    <label *ngIf="this.currencyForRound === null && companyCurrency != null"
                                        class="CurrencySymbol">{{companyCurrency.substr(currencyForRound.length-2)}}</label>
                                    <input class="form-control top-10 text-align-right" type="text"
                                        (input)="onChangePreMoneyVal('ip', $event)" #preMoneyErr="ngModel" [max]="preMoneyMax"
                                        [min]="preMoneyMin" placeholder="Pre-Money Valuation" (change)="fieldEdited($event)"
                                        [(ngModel)]="preMoneyValText" [ngModelOptions]="{standalone: true}"
                                        (keypress)="onlyNumberKey($event)" title="" required />
                                    <span *ngIf="preMoneyErr.errors && (preMoneyErr.dirty || preMoneyErr.touched)
                                        " class="alert alert-danger">
                                        <span>
                                            Pre-Money Value is required!
                                        </span>
                                    </span>
                                </div>
                                <div class="col-lg-5 col-md-7 col-sm-7 top-35">
                                    <!-- <div class="margin-top-min10">
                                        <mat-slider (input)="onChangePreMoneyVal('slider', null)" style="width:100%"
                                            [disabled]="disabled" [thumbLabel]="thumbLabel" [invert]="invert"
                                            [step]="step" [max]="preMoneyMax" [min]="preMoneyMin"
                                            [tickInterval]="tickInterval" [(ngModel)]="preMoneyVal"
                                            [vertical]="vertical" (change)="val()">
                                        </mat-slider>
                                        <div class="row margin-top-min10">
                                            <div class="col-md-4 text-align-left">
                                                <span class="slider-val-txt">{{getTotalValueTble(preMoneyMin)}}</span>
                                            </div>
                                            <div class="col-md-4 text-align-center">
                                                <span class="slider-val-txt">{{getTotalValueTble(preMoneyMax/2)}}</span>
                                            </div>
                                            <div class="col-md-4 text-align-right">
                                                <span class="slider-val-txt">{{getTotalValueTble(preMoneyMax)}}</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <mat-label>Post-Money Valuation :
                                        <label class="value-txt btm-0" ngbTooltip="{{formatRoundCurrency(postMoneyVal)}}">
                                            {{ getTotalValue(postMoneyVal) }}
                                        </label>
                                    </mat-label>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <mat-label>Dilution(%) :
                                        <label class="value-txt btm-0">{{dilution | percent: '2.2' }}</label>
                                    </mat-label>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <div class="row">
                        <div class="col-md-12 top-20">
                            <button mat-raised-button id="saveAndContinue" [disableRipple]="disableripple" color="success"
                                class=" pull-right" (click)="goToCurrentInvs()" ngbTooltip="Save and Continue">Save and Continue</button>
                            <button mat-raised-button id="discard" [disableRipple]="disableripple" color="warn"
                                class=" pull-right margin-right-20" (click)="discardRound()"
                                ngbTooltip="Discard Round">Discard</button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Helper section -->
    <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="helper-container">
            <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
              <mat-card-header class="help-hdr">
                <mat-card-title><label class="help-hdr-txt">{{
                    helpSections?.name
                  }}</label></mat-card-title>
              </mat-card-header>
              <hr />
              <mat-card-content class="card-help-content">
                <div [innerHTML]="helpSections?.description"></div>
              </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>