<div id="overlay" *ngIf="showDialog" [ngClass]="{'display-on': showDialog}"></div>
<div *ngIf="showDialog" class="dialog">
    <div class="header">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header">
                <label class="dialogue-title">Upload Photo</label>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span class="close">X</span>
                </button>
            </div>
        </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <div class="container-fluid ">
        <div class="row  content-row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <div class="top-20">
                            <mat-label>Crop Image</mat-label>
                        </div>
                        <div class="upload">
                            <input hidden type="file" (change)="fileChangeEvent($event)" accept="image/*"
                                #uploadImage />
                            <button mat-raised-button id="upload" color="success" class="float-right cmn-button"
                                (click)="uploadImage.click()" [disableRipple]="true" ngbTooltip="Upload"> Upload </button>
                        </div>
                        <mat-card class="image-card top-20">
                            <div class="img-container">
                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                    [aspectRatio]="1 / 1" [resizeToWidth]="0" roundCropper=true format="jpeg"
                                    (imageCropped)="imageCropped($event)" [imageBase64]="base64String"
                                    (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                            </div>
                        </mat-card>
                        <div *ngIf="croppedImage">
                            <img [src]="croppedImage" class="img-preview" />
                        </div>
                    </div>
                </div>
                <div class="row btm-20 top-20">
                    <div class="col-sm-12 col-xs-12 col-xl-12 col-md-12">
                        <button mat-raised-button id="save" color="success"
                        class="col-xs-2 col-sm-2 col-md-2 right-20 float-right" [disableRipple]="true"
                        (click)="saveUploadedImage()" ngbTooltip="Save">
                        Save
                    </button>
                        <button mat-raised-button id="cancel" color="warn" class="float-right  margin-right-20"
                            [disableRipple]="true" (click)="close()" ngbTooltip="Cancel">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>