import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from "../../../authentication.service";
import { UtilityService } from "../../../services/utilities.service"
import { ToastrService } from 'ngx-toastr';
import { Exercise } from "../../model/exercise";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from '../../../services/cache-provider.service';
declare var $: any;

@Component({
  selector: 'app-esop-exercise',
  templateUrl: './esop-exercise.component.html',
  styleUrls: ['./esop-exercise.component.scss']
})
export class EsopExerciseComponent implements OnInit {
  @Input() exerciseDialog;
  @Input() employeesList;
  @Input() esopPlanList;
  @Input() rowData;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ngOnInitParent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public exercise: Exercise;
  public exercises = [];
  isShowNote: boolean = false;
  exerciseDate: boolean = false;
  numberOfShares: boolean = false;
  elementLoaded: boolean = false;
  public usrCompanyCurrency;
  public userDetails;
  public maxDateValidation: string;

  constructor(private utilityService: UtilityService, private authService: AuthenticationService,
    private toastr: ToastrService, private ngxLoader: NgxUiLoaderService,
    private cacheService: CacheProviderService, ) { }

  ngOnInit() {
    this.exercise = new Exercise();
    this.maxDateValidation = new Date().toISOString().replace("Z", "+0000");
    // console.log(this.maxDateValidation, this.rowData.grantDate);
    if(this.exerciseDialog.isApprover){
      this.exercise.dateOfExercise = this.employeesList.dateOfExercise;
      this.exercise.numberOfShares = this.employeesList.numberOfShares;
      this.exercise.note = this.employeesList.note;
      this.exercise.isCashExercise = this.employeesList.isCashExercise;
      this.isShowNote = true;
    }
    this.loadJsStyles();
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
      localStorage.setItem("comCur", this.usrCompanyCurrency);
    }
    else {
      this.usrCompanyCurrency = "INR - ₹";
    }
  }

  ngDoCheck() {
    if (!this.elementLoaded && $('[data-toggle="switch"]').length > 0) {
      this.elementLoaded = true;
      this.loadJsStyles();
    }
  }

  loadJsStyles() {
    $('[data-toggle="switch"]').bootstrapSwitch({
      onText: "Yes",
      offText: "No",
    });
    $('#cash-exercise').bootstrapSwitch('state', this.exercise.isCashExercise);

    if(this.exerciseDialog.isApprover){
      $('#cash-exercise').bootstrapSwitch('disabled', true);
    }
    else{
      $('#cash-exercise').bootstrapSwitch('disabled', false);
    }

    $("#cash-exercise").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
      this.exercise.isCashExercise = state;
    });

    $('input.number').keyup(function (event) {
      // skip for arrow keys
      if (event.which >= 37 && event.which <= 40) return;
      // format number
      $(this).val(function (index, value) {
        let tmpValue = value.toString().replace(/\D/g, "");
        var lastThree = tmpValue.substring(tmpValue.length - 3);
        var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res;
      });
    });
  }

  saveExercise() {
    if (this.exercise.dateOfExercise == undefined ||
      this.exercise.numberOfShares == undefined) {

      if (this.exercise.dateOfExercise == undefined) {
        this.exerciseDate = true;
      }
      if (this.exercise.numberOfShares == undefined) {
        this.numberOfShares = true;
      }
      return;
    }

    this.exercises = [];
    let dt = new Date(this.exercise.dateOfExercise);
    this.exercise.optionHolderId = this.employeesList.optionHolderId;
    this.exercise.optionHolderName = this.employeesList.optionHolderName;
    this.exercise.esopPlanName = this.employeesList.esopPlanName;
    this.exercise.exercisePrice = this.employeesList.exercisePrice;
    this.exercise.dateOfExercise = dt.toISOString().replace("Z", "+0000");
    let noOfShares: any;
    noOfShares = +this.exercise.numberOfShares.toString().replace(/,/g, "") / 1;
    this.exercise.numberOfShares =noOfShares;
    if(this.exerciseDialog.isApprover){
      this.exercise.optionState = "Exercised";
    }
    this.exercises.push(this.exercise);
    this.ngxLoader.start();
    const payLoad = this.exerciseDialog.isApprover ? this.exercise : this.exercises;
    this.authService.doExercise(payLoad, this.exerciseDialog.isApprover).subscribe((response) => {
      if (response) {
        this.ngxLoader.stop();
        this.exerciseDialog.show = false;
        this.visibleChange.emit(true);
        this.ngOnInitParent.emit();
      } else {
        this.ngxLoader.stop();
        this.toastr.error("Null response!", "Failure");
      }
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!")
    })
  }

  formatCurrency(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  close() {
    this.exerciseDialog.show = false;
    this.visibleChange.emit(this.exerciseDialog.show);
  }
  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  addNotes(data) {
    if (data) {
      this.isShowNote = true;
    } else {
      this.isShowNote = false;
    }
  }

}
