<div class="content">
  <div id="overlay"></div>
  <div class="background-gradient">

    <div class="row content-row-padding">
      <div class="col-xl-2 col-lg-1 "></div>

      <div class="col-xl-8 col-lg-10 col-sm-12 col-xs-12">
        <div class="row">
          <div class="card col-sm-12 col-md-12 col-xl-12 col-xl-12">
            <div class="row">
              <div class="col-md-6 capTbleCard">
                <div class="row header-row">
                  <p class="heading">
                    <img src="../../../assets/logo/logo.svg" />
                  </p>
                  <div class="subheading">
                    <p>Equity Management</p>
                  </div>
                </div>
                <div class="row image-row">
                  <div class="col-md-12 col-sm-12 col-lg-12">
                    <img class="ahu_icon" [attr.src]="src$ | async">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div>
                  <div class="form-card">
                    <div class="subheading top-20">
                      <p>Employee Login</p>
                    </div>
                    <!-- Login form-->
                    <form class="form" novalidate autocomplete="off">
                      <div class="form-group margin-top-lg">
                        <mat-label class="required">User Name</mat-label>
                        <input type="email" class="form-control top-10" autofocus (onfocus)="emailFocused($event)"  #userId  autocomplete="off" #userNameErr="ngModel"
                          [(ngModel)]="emailId"  (blur)="getCompanies(userId.value)"  [ngModelOptions]="{standalone: true}" 
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" placeholder="Email ID" required/>
                        <div class="alert alert-danger" *ngIf="(userNameErr.touched || userNameErr.dirty) || emailErr">
                          <div *ngIf="userNameErr.errors?.required || emailErr">
                            Email is required
                          </div>
                          <div *ngIf="userNameErr.errors?.pattern">
                            Invalid Email
                          </div>
                        </div>
                      </div>
                     
                      <div class="form-group">
                        <mat-label class="required">Password</mat-label>
                        <div class="input-eyePlaceholder">
                          <input type="password" class="form-control top-10"  [(ngModel)]="password" [ngModelOptions]="{standalone: true}"
                             [type]="logHide ? 'password' : 'text'" placeholder="Enter password" autocomplete="off" #passwordErr="ngModel"  required/>
                            <i [ngClass]="logHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="logpasswordHide()" class="eye-placeholder"></i>
                        </div>
                        <div class="alert alert-danger" *ngIf="passwordErr.errors?.required && passwordEr">
                          Password is required
                        </div>
                        <div class="alert alert-danger" *ngIf="passwordErr.errors?.touched || passwordErr.errors?.dirty">
                          Password is required
                        </div>
                      </div>
                      <div class="form-group margin-top-lg">
                        <mat-label class="required">Company</mat-label>
                        <select class="form-control top-10" title="" [(ngModel)]="companyName"  #companyNameErr="ngModel"
                            (ngModelChange)="selectCompany($event)" [ngModelOptions]="{standalone: true}" [disabled]="!companyList" required>
                          <option disabled [selected]="true" value="">Select Company</option>
                          <option *ngFor='let company of companyList' [ngValue]="company">{{company.nameOfTheCompany}}
                          </option>
                        </select>
                        <div class="alert alert-danger">
                          <div *ngIf="companyNameErr.errors?.required && cidErr">
                            Choose associated company
                          </div>
                          <div *ngIf="companyNameErr.errors?.touched || companyNameErr.errors?.dirty">
                            Choose associated company
                          </div>
                        </div>
                      </div>
                      <div class="form-group margin-top-lg">
                        <button mat-raised-button id="login" color="success" type="submit"
                          class="width-100 submit-button" 
                          [disableRipple]="true" (click)="checkValidation()">Log In</button>
                      </div>
                      <div class="margin-top-lg">
                        <a class="link-hover cursor-pointer" (click)="forgetPassword()">
                          <mat-label class="forgot-password"> Forgot Password? </mat-label>
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-2 col-lg-10"></div>

    </div>
  </div>
</div>
