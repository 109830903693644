import { HistoricalRoundObject } from './historical-round.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "ngx-ui-loader";
import * as i3 from "../../authentication.service";
var HistoricalRoundService = /** @class */ (function () {
    function HistoricalRoundService(toastr, ngxLoader, authService) {
        this.toastr = toastr;
        this.ngxLoader = ngxLoader;
        this.authService = authService;
        this.roundDetails = new HistoricalRoundObject();
        this.historicalRoundSummary = null;
    }
    HistoricalRoundService.prototype.setRoundDetails = function (roundDetails) {
        return this.roundDetails = roundDetails;
    };
    HistoricalRoundService.prototype.setRoundSummary = function (summary) {
        return this.historicalRoundSummary = summary;
    };
    HistoricalRoundService.prototype.getRoundSummary = function () {
        return this.historicalRoundSummary;
    };
    HistoricalRoundService.prototype.resetRoundData = function () {
        this.roundDetails = new HistoricalRoundObject();
        this.historicalRoundSummary = {};
    };
    HistoricalRoundService.ngInjectableDef = i0.defineInjectable({ factory: function HistoricalRoundService_Factory() { return new HistoricalRoundService(i0.inject(i1.ToastrService), i0.inject(i2.NgxUiLoaderService), i0.inject(i3.AuthenticationService)); }, token: HistoricalRoundService, providedIn: "root" });
    return HistoricalRoundService;
}());
export { HistoricalRoundService };
