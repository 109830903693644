import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tags-edit',
  templateUrl: './tags-edit.component.html',
  styleUrls: ['./tags-edit.component.scss']
})
export class TagsEditComponent implements OnInit {
    @Input() isShowTags: boolean;
    @Input() tagsString: String;
    @Output() visibleChange: EventEmitter<String> = new EventEmitter<String>();

  constructor() { }

  ngOnInit() {
      console.log('received tags: ', this.tagsString);
  }

  saveTags() {
    this.isShowTags = false;
    this.visibleChange.emit(this.tagsString);   
  }

  close() {
    this.isShowTags = false;
    this.visibleChange.emit('false');
  }

}
