import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
declare var $: any;
import { DatePipe } from "@angular/common";
import { DataService } from "../../services/new-round-notify.service";
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from "../../services/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { CacheProviderService } from "../../services/cache-provider.service";
import { BuyBackDetails } from "./model/buyBackDetails";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HelpContent } from "./model/helpContent";

@Component({
  selector: "app-buy-back-tab",
  templateUrl: "./buy-back-tab.component.html",
  styleUrls: ["./buy-back-tab.component.scss"],
})
export class BuyBackTabComponent implements OnInit {



  bName: boolean = false;
  bDescription: boolean = false;
  bIdentifier: boolean = false;
  bissueDate: boolean = false;
  bBuyBackAmount: boolean = false;
  bBuyBackPricePerShare: boolean = false;
  bNoOfSharesBoughtBack: boolean = false;
  bSecurityInstrument: boolean = false;
  bSecurityType: boolean = false;
  public disableripple: boolean = true;

  startDate: any;
  buyBackDetails: BuyBackDetails = new BuyBackDetails();
  instruments = [];
  noOfSharesBoughtBack: number = 0;
  postBuyBackConsolidation: number = 0;

  public isBuyBackTabhelp: boolean = false;
  public helpTitle: string = "";
  public helpMessage: string = "";
  helperTopPosition: number;
  helper: string = "";
  helpSections: HelpContent;

  public userDetails;
  public usrCompanyCurrency = "";
  buyBackAmount: any;
  pricePerShare: any;
  noOfSharesBoughtBackText: any;
  instrumentsFromResponse = [];
  buyBackId: number;
  totalFDBShares: number = 0;
  currencySymbol = "";

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private utility: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private _formBuilder: FormBuilder
  ) {}

  loadJsStyles() {
    $('input.number').keyup(function (event) {

        // skip for arrow keys
        if (event.which >= 37 && event.which <= 40) return;

        // format number
        $(this).val(function (index, value) {
            let tmpValue = value.toString().replace(/\D/g, "");
            var lastThree = tmpValue.substring(tmpValue.length - 3);
            var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            return res;
        });
    });
  }

  totalSharesInSecurity: number = 0;

  ngOnInit() {
    this.ngxLoader.start();
    this.activatedRoute.queryParams
      .filter(params => params.buyBackId)
      .subscribe(params => {
        this.buyBackId = params.buyBackId;
      }
    );
    this.buyBackDetails.buyBackAmount = 0;
    this.authService.getTotalFDBShares().subscribe((res:number) => {
      this.totalFDBShares = res;
      
    }, (e) => {
          this.toastr.error("Error: Unable to get total FDB shares.");
        });
    
    if (this.buyBackId != null && this.buyBackId > 0){
      this.authService.getProformaBuyBackDetails(this.buyBackId).subscribe((res: BuyBackDetails) => {
      this.buyBackDetails = res;
      // this.getInstrumentTypes();
      if (this.buyBackDetails.state === "BASIC_DETAILS"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":null,
          "isReviewTabValid":null,
          "isSummaryTabValid":null,
          "isConfirmTabValid":null
        });
      }
      else if (this.buyBackDetails.state === "SHAREHOLDER_DETAILS"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":null,
          "isConfirmTabValid":null
        });
      }
      else if (this.buyBackDetails.state === "CAPTABLE_SUMMARY"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":true,
          "isConfirmTabValid":null
        });
      }
      if (this.buyBackDetails.startDate != null && (this.buyBackDetails.state === "BASIC_DETAILS" || this.buyBackDetails.state === "SHAREHOLDER_DETAILS" || this.buyBackDetails.state === "CAPTABLE_SUMMARY")) {
        this.authService.getInstrumentsForBuyBack().subscribe((instrs:[]) => {
          this.instrumentsFromResponse = instrs.filter(i => i['numberOfShares'] > 0);
          // this.instrumentsFromResponse = res;
          this.instrumentsFromResponse.forEach(element =>{
            element['securityClass'] = (element['securityClass']).toUpperCase();
            if (!(this.instruments.includes(element['securityClass']))){
              this.instruments.push(element['securityClass']);
            }
          });
          if(this.buyBackDetails && !this.buyBackDetails.securityClass){
            this.buyBackDetails.securityClass = null
            this.buyBackDetails.className = null;
          }
          else{
            this.securityClass = [];
            this.instrumentsFromResponse.forEach(element =>{
              if (element['securityClass'].trim().toUpperCase() == this.buyBackDetails.securityClass.trim().toUpperCase()){
                this.securityClass.push((element['className']));
              }
              if (element['className'].trim().toUpperCase() == this.buyBackDetails.className.trim().toUpperCase()){
                this.totalSharesInSecurity = element['numberOfShares'];
              }
            });
            this.calculateNoOfSharesBought();
          }

        }, (e) => {
              this.toastr.error("Error: Unable to get Instrument data", e);
          });
          this.ngxLoader.stop();

        this.startDate = new Date(this.buyBackDetails.startDate.substring(0, 10));
        this.pricePerShare = this.utility.convertToCompanyCurrency(this.buyBackDetails.pricePerShare);
        this.buyBackAmount = this.utility.convertToCompanyCurrency(this.buyBackDetails.buyBackAmount)
      }
      else{
        this.ngxLoader.stop();
        this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page");
        this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      }
        
      }, (e) => {
        this.ngxLoader.stop();
            this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page", e);
            this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
        });
    }

    else{
      this.getInstrumentTypes();
      this.dataService.updateData({
        "isbuyBackDetailsTabValid":null,
        "isShareholdersTabValid":null,
        "isReviewTabValid":null,
        "isSummaryTabValid":null,
        "isConfirmTabValid":null
      });
    }
    
    this.loadJsStyles();
    
    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
    this.ngxLoader.stop();

  }


  securityClass = [];
  
  changeInstrument(instrument: any) {
    this.securityClass = [];
    this.instrumentsFromResponse.forEach(element =>{
      if (element['securityClass'].trim().toUpperCase() == instrument.trim().toUpperCase()){
        this.securityClass.push((element['className']));
      }
    });
    this.buyBackDetails.className = null;
    this.totalSharesInSecurity = 0;
    this.calculateNoOfSharesBought();
  }

  changeSecurity(security: any){
    this.instrumentsFromResponse.forEach(element =>{
      if ((element['className']).trim() == security.trim()){
        this.totalSharesInSecurity = element['numberOfShares'];
      }
    });
    this.calculateNoOfSharesBought();
  }

  getInstrumentTypes(){
    this.ngxLoader.start();
    this.authService.getInstrumentsForBuyBack().subscribe((res:[]) => {
      this.instrumentsFromResponse = res.filter(i => i['numberOfShares'] > 0);
      this.instrumentsFromResponse.forEach(element =>{
        element['securityClass'] = (element['securityClass']).toUpperCase();
        if (!(this.instruments.includes(element['securityClass']))){
          this.instruments.push(element['securityClass']);
        }
      });
      if(this.buyBackDetails && !this.buyBackDetails.securityClass){
        this.buyBackDetails.securityClass = null
        this.buyBackDetails.className = null;
      }
    }, (e) => {
          this.toastr.error("Error: Unable to get Instrument data");
      });
      this.ngxLoader.stop();
  }



  calculateNoOfSharesBought(){
    if ((this.buyBackDetails.buyBackAmount && this.buyBackDetails.buyBackAmount != null && this.buyBackDetails.buyBackAmount != 0) && (this.buyBackDetails.pricePerShare && this.buyBackDetails.pricePerShare != null && this.buyBackDetails.pricePerShare != 0) && (this.totalFDBShares != 0)){
      this.noOfSharesBoughtBack = Math.floor(this.buyBackDetails.buyBackAmount / this.buyBackDetails.pricePerShare);
      this.postBuyBackConsolidation = this.noOfSharesBoughtBack * 100 / this.totalFDBShares;
      this.postBuyBackConsolidation = Math.round(this.postBuyBackConsolidation*100) / 100;
      this.noOfSharesBoughtBackText = this.getTotalValueTble(this.noOfSharesBoughtBack);
    }
    else{
      this.noOfSharesBoughtBackText = '0';
      this.noOfSharesBoughtBack = 0;
      this.postBuyBackConsolidation = 0;
    }
  }

  goToShareholders() {
    this.ngxLoader.start();
    if (this.startDate != null){
      try{
        this.buyBackDetails.startDate = (this.startDate.toISOString()).substring(0, 19);
        this.buyBackDetails.startDate = this.buyBackDetails.startDate + ".000+0000";
        this.instrumentsFromResponse.forEach(element =>{
          if (element['securityClass'].trim() == this.buyBackDetails.securityClass.trim()){
            this.buyBackDetails.securityClass = element['securityClass'];
            this.buyBackDetails.securityClass = this.buyBackDetails.securityClass.toUpperCase();
          }
          if (element['className'].trim() == this.buyBackDetails.className.trim()){
            this.buyBackDetails.className = element['className'];
            this.buyBackDetails.id = this.buyBackId;
          }
        });
        this.buyBackDetails.numberOfSharesBoughtBackTarget = this.noOfSharesBoughtBack;
        this.buyBackDetails.buyBackAmount = this.buyBackDetails.pricePerShare * this.buyBackDetails.numberOfSharesBoughtBackTarget;
      }
      catch(err){
        this.ngxLoader.stop();
        this.toastr.error("Error: Some fields are invalid", err);
      }
    }
    if (this.validateBuyBackDetails(this.buyBackDetails)) {
      this.ngxLoader.stop();
      this.authService.saveBuyBackDetails(this.buyBackDetails).subscribe((response) => {
          if (response) {
            if( response["id"] != null && response["id"] > 0 && response['state'] == "BASIC_DETAILS"){
              this.dataService.updateData({
              "isbuyBackDetailsTabValid":true,
              "isShareholdersTabValid":null,
              "isReviewTabValid":null,
              "isSummaryTabValid":null,
              "isConfirmTabValid":null
            });
              this.ngxLoader.stop();
              this.router.navigate(
                ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'shareholders'],
                { queryParams: { 'buyBackId': response['id'] } }
                );
            }
          } 
            else {
              this.ngxLoader.stop();
              this.toastr.error("Error: Unable to initiate buy-back");
          }
      }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e.error.reason);
      });

  } else {
      if (this.buyBackDetails.buyBackName == null) {
          this.bName = true;
      }
      if (this.buyBackDetails.buyBackIdentifier == null) {
          this.bIdentifier = true;
      }
      if (this.buyBackDetails.buyBackDescription == null) {
          this.bDescription = true;
      }
      if (this.buyBackDetails.pricePerShare == null ) {
          this.bBuyBackPricePerShare = true;
      }
      if (this.buyBackDetails.buyBackAmount == null) {
          this.bBuyBackAmount = true;
      }
      if (this.buyBackDetails.startDate == null) {
        this.bissueDate = true;
      }
      if (this.buyBackDetails.securityClass == null) {
        this.bSecurityType = true;
      }
      if (this.buyBackDetails.securityClass == null) {
        this.bSecurityInstrument = true;
      } 
      
      if(this.buyBackDetails.buyBackName == null || this.buyBackDetails.buyBackIdentifier == null || this.buyBackDetails.buyBackDescription == null
          || this.buyBackDetails.numberOfSharesBoughtBackTarget == null || (this.buyBackDetails.securityClass == null || this.buyBackDetails.buyBackAmount == null) || this.buyBackDetails.securityClass == null) {
          let buyBack = document.getElementById("BuyBack_Tab");
          this.utility.focusSection(buyBack);
      }

      this.ngxLoader.stop();
      return;
    }
  }

  getNameNewBuyBack(buyBackName){
    this.buyBackDetails.buyBackName = buyBackName.replace(/ \t/g,'').replace(/ +(?= )/g,'').trim();
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utility.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utility.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }

  validateBuyBackDetails(buyBackDetails: BuyBackDetails) {
    this.utility.formValidation();
    if (!buyBackDetails.buyBackName || !buyBackDetails.buyBackIdentifier || !buyBackDetails.buyBackDescription || !buyBackDetails.buyBackAmount || 
        !buyBackDetails.securityClass || !buyBackDetails.securityClass || !buyBackDetails.pricePerShare || !buyBackDetails.numberOfSharesBoughtBackTarget){
          return false;
        }
    if (this.buyBackDetails.numberOfSharesBoughtBackTarget > this.totalSharesInSecurity){
      this.toastr.error("Number of shares to be bought back is greater than total shares in the security");
      return false;
    }
    return true;
  }

  discardBuyBack() {
    this.ngxLoader.start();
    if(confirm('Are you sure you want to discard the Buy-Back?')) {
      if (this.buyBackDetails.id === undefined){
        this.ngxLoader.stop();
        this.toastr.success("Buy-Back Discarded successfully!", "Success");
        this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      }
      else if (this.buyBackDetails.state != "COMPLETED"){
        this.authService.discardBuyBackTransaction(this.buyBackDetails.id).subscribe((response) => {
          if (response['resultCode'] == 'SUCCESS') {
              this.ngxLoader.stop();
              this.toastr.success("Buy-Back Discarded successfully!", "Success");
              this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Error discarding Buy-back", "Failure");
          }
      }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error discarding Buy-back" );
      })
      }
    } else {
        this.ngxLoader.stop();
        return;
    }
  }

  showHelp(section) {
    this.isBuyBackTabhelp = true;
    if(section == this.helper){
        return;
      }
      const targetElement = document.getElementById(section);
      if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
      }
      
        this.helpSections = this.helperService.getHelpContent(
          "BuyBackTabComponent",
          section
        );
      this.helper = section;
}

// help section hide
  hideHelp() {
    this.helper = "";
  }

onlyNumberKey(event) {
  if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
      return false;
  }
  else {
      return true;
  }
}

onChangeBuyBackAmount(type, e) {
  if (type === 'ip') {
    let rst;
    if(e) {
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let val = this.buyBackAmount;
    this.buyBackDetails.buyBackAmount = this.buyBackAmount.replace(/,/g, "") / 1;
    rst = this.utility.inputCSVformatCurrencyRound(this.usrCompanyCurrency, val);

    if(e) {
        if(e.target.value) {
            let CSVLength = rst.length;
            let valueLength = e.target.value.length;
            if(CSVLength > valueLength) {
                e.target.value = rst;
                let difference = CSVLength - valueLength;
                start = start + difference;
                end = end + difference;
            }
            else if(CSVLength < valueLength){
                e.target.value = rst;
                if(start != 0 || end != 0){
                    let difference = valueLength - CSVLength;
                    start = start - difference;
                    end = end - difference;
                }
                else{
                    start = start;
                    end = end;
                }  
            }
            else {
                e.target.value = rst;
            }
        }
    
        e.target.setSelectionRange(start, end);
    } else {
        this.buyBackAmount = rst;
    }
    this.calculateNoOfSharesBought();
  }
}

onChangePricePerShare(type, e) {
  if (type === 'ip') {
    let rst;
    if(e) {
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let val = this.pricePerShare;
    this.buyBackDetails.pricePerShare = this.pricePerShare.replace(/,/g, "") / 1;
    rst = this.utility.inputCSVformatCurrencyRound(this.usrCompanyCurrency, val);

    if(e) {
        if(e.target.value) {
            let CSVLength = rst.length;
            let valueLength = e.target.value.length;
            if(CSVLength > valueLength) {
                e.target.value = rst;
                let difference = CSVLength - valueLength;
                start = start + difference;
                end = end + difference;
            }
            else if(CSVLength < valueLength){
                e.target.value = rst;
                if(start != 0 || end != 0){
                    let difference = valueLength - CSVLength;
                    start = start - difference;
                    end = end - difference;
                }
                else{
                    start = start;
                    end = end;
                }  
            }
            else {
                e.target.value = rst;
            }
        }
    
        e.target.setSelectionRange(start, end);
    } else {
        this.pricePerShare = rst;
    }
    this.calculateNoOfSharesBought();
  }
}

}
