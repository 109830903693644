import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../authentication.service";
declare var $: any;
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UtilityService } from "../../services/utilities.service";

@Component({
  selector: "app-profile-info",
  templateUrl: "./profile-info.component.html",
  styleUrls: ["./profile-info.component.scss"],
})
export class ProfileInfoComponent implements OnInit {
  @Input() showdigPrfle: boolean;
  @Input() shareHolderType: any;
  @Input() groupNames: any;
  @Input() selectedIndex: any;
  @Input() outstandingConv: any;
  @Input() shareholderId: any;
  @Input() beneficiaryId: any;
  @Input() beneficiaryProfile: any;
  @Input() shareholderName: any;
  @Input() isNewProfile: boolean;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSecoundaryBuyer: boolean;
  @Output("parentInit") parentInit: EventEmitter<any> = new EventEmitter();
  @Input() newInvestorProfile: any;
  @Input() investorsArray: any;
  @Input() fromNewInv: any;
  @Input() fromHistoricalRound: boolean;
  @Input() newInvestorProfileindex: any;
  public newGroupErr: boolean = false;

  public profileInfObj = {
    typeOfShareholder: "",
    shareholderSubType: null,
    isRelativeOfDirector: false,
    groupName: {
      groupName: "",
    },
    registeredAddress: "",
    corporateAddress: "",
    residentStatus: "",
    occupation: "",
    nationality: "",
    contactNumber: "",
    emailId: "",
    legalName: "",
    dpId: "",
    clientId: "",
    depositoryName: "",
    isPromoter: false,
    imageBase64String: null,
    imageName: "",
    contenttype: "",
    documentPath: {},
    imageUrl: null,
  };
  public countryList: [];
  public showNewGrpMdl: boolean = false;
  shareholderSubTypesList;
  mShareholderType: boolean = false;
  mShareholderSubType: boolean = false;
  mGroup: boolean = false;
  mEmail: boolean = false;
  valid: boolean = false;
  mNationality: boolean = false;
  mResidence: boolean = false;
  mlegalName: boolean = false;
  public selectedResidentType = "India";
  public selectedCountry = "India";
  public croppedImage = null;
  public shouldShowDialog = false;
  public profilePicValid: boolean = false;
  public profilePicture = null;
  public content = "image/jpeg";
  public name;
  public initObj;
  public docPath: any;
  public profileImage: any;
  public groupMandatory: boolean = false;
  subTypeMandatory: boolean = false;
  myForm: FormGroup;
  public selGroupName = null;
  public disableripple: boolean = true;
  public maxLength: number = 9;
  public emailPattern: any = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$";

  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private utility: UtilityService
  ) {}

  validatorMethod(data) {
    let groupname = null;
    if (data.groupName != null) {
      groupname = data.groupName["groupName"];
    }
    if (this.subTypeMandatory) {
      if (
        (data.emailId && !data.emailId.match(this.emailPattern)) ||
        !data.typeOfShareholder ||
        !data.legalName ||
        !data.residentStatus ||
        !data.nationality ||
        !groupname ||
        groupname == undefined ||
        groupname === "newGroup" ||
        !data.shareholderSubType
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.groupMandatory) {
      if (
        (data.emailId && !data.emailId.match(this.emailPattern)) ||
        !data.typeOfShareholder ||
        !data.legalName ||
        !data.residentStatus ||
        !data.nationality ||
        !groupname ||
        groupname == undefined ||
        groupname === "newGroup"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        (data.emailId && !data.emailId.match(this.emailPattern)) ||
        !data.typeOfShareholder ||
        !data.legalName ||
        !data.residentStatus ||
        !data.nationality
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  validatorMethodNogroup(data) {
    if (!data.emailId || !data.typeOfShareholder || !data.legalName) {
      return false;
    } else {
      return true;
    }
  }

  saveUserProfile() {
    this.utility.formValidation();
    this.valid = this.validatorMethod(this.profileInfObj);
    let groupname = null;
    if (this.profileInfObj.groupName != null) {
      groupname = this.profileInfObj.groupName["groupName"];
    }
    if (!this.valid) {
      if (
        this.profileInfObj.typeOfShareholder === "" ||
        this.profileInfObj.typeOfShareholder == null
      ) {
        this.mShareholderType = true;
      }
      if (
        this.profileInfObj.emailId &&
        !this.profileInfObj.emailId.match(this.emailPattern)
      ) {
        this.mEmail = true;
      }
      if (
        this.profileInfObj.legalName === "" ||
        this.profileInfObj.legalName == null
      ) {
        this.mlegalName = true;
      }
      if (
        this.profileInfObj.residentStatus === "" ||
        this.profileInfObj.residentStatus == null
      ) {
        this.mResidence = true;
      }
      if (
        this.profileInfObj.nationality === "" ||
        this.profileInfObj.nationality == null
      ) {
        this.mNationality = true;
      }
      if (
        this.profileInfObj.typeOfShareholder === "VC" ||
        this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
        this.profileInfObj.typeOfShareholder === "PE"
      ) {
        if (
          groupname == undefined ||
          groupname === "" ||
          groupname === "newGroup"
        ) {
          if (groupname == "newGroup") {
            this.newGroupErr = true;
          }
          this.mGroup = true;
        }
      } else {
        this.mGroup = false;
      }
      if(this.profileInfObj.typeOfShareholder === "FinancialInstitution") {
        if(this.profileInfObj.shareholderSubType == undefined || 
            this.profileInfObj.shareholderSubType === "" ||
            this.profileInfObj.shareholderSubType == null) {
              this.mShareholderSubType = true;
        }
      }
      return;
    } else {
      this.profileInfObj.legalName = this.profileInfObj.legalName
        .replace(/ \t/g, "")
        .replace(/ +(?= )/g, "")
        .trim();
      if (this.shareholderId) {
        this.ngxLoader.start();
        this.profileInfObj["id"] = +this.shareholderId;
        this.authService
          .updateShareholderProfile(this.shareholderId, this.profileInfObj)
          .subscribe(
            (res) => {
              if (res) {
                this.ngxLoader.stop();
                this.toastr.success("Shareholder profile updated sucessfully");
                this.showdigPrfle = false;
                this.visibleChange.emit({ showMdl: this.showdigPrfle });
                this.parentInit.emit();
              }
            },
            (e) => {
              this.toastr.error(e.error.reason, "Error");
              this.ngxLoader.stop();
            }
          );
      } else if (this.isSecoundaryBuyer) {
        this.ngxLoader.start();
        this.showdigPrfle = false;
        this.isSecoundaryBuyer = false;
        this.visibleChange.emit({
          showMdl: this.showdigPrfle,
          profileInfo: this.profileInfObj,
          isSecoundaryBuyer: this.isSecoundaryBuyer,
        });
        this.ngxLoader.stop();
      } else if(this.isNewProfile || this.beneficiaryId) {
        this.ngxLoader.start();
        if (
          this.profileInfObj.emailId === "" ||
          this.profileInfObj.emailId == null
        ) {
          this.toastr.warning("Email not entered", "Warning");
        }
        this.showdigPrfle = false;

        this.visibleChange.emit({ profileInfo: this.profileInfObj });
        return this.ngxLoader.stop();
      } else if (this.fromHistoricalRound) {
        this.ngxLoader.start();
        if (
          this.profileInfObj.emailId === "" ||
          this.profileInfObj.emailId == null
        ) {
          this.toastr.warning("Email not entered", "Warning");
        }

        for (const investor of this.investorsArray) {
          if (investor.investorName == this.newInvestorProfile.investorName) {
            investor.investorProfile = this.profileInfObj;
          }
        }
        this.showdigPrfle = false;

        this.visibleChange.emit({ showMdl: this.showdigPrfle });
        return this.ngxLoader.stop();
      } else if (this.newInvestorProfile) {
        this.ngxLoader.start();
        if (
          this.profileInfObj.emailId === "" ||
          this.profileInfObj.emailId == null
        ) {
          this.toastr.warning("Email not entered", "Warning");
        }
        this.showdigPrfle = false;
        this.fromNewInv = false;
        this.visibleChange.emit({
          showMdl: this.showdigPrfle,
          profileInfo: this.profileInfObj,
          fromNewInv: this.fromNewInv,
          index: this.newInvestorProfileindex,
        });
        this.ngxLoader.stop();
      } else {
        this.showdigPrfle = false;
        this.visibleChange.emit({
          index: this.selectedIndex,
          profileInfo: this.profileInfObj,
          showMdl: this.showdigPrfle,
        });
      }
    }
  }

  emailValid(email) {
    if (email && !email.match(this.emailPattern)) {
      this.mEmail = true;
    } else {
      this.mEmail = false;
    }
  }

  shareHoderTypeChange(shrHldrType) {
    let shareholderType = shrHldrType;
    this.mShareholderType = false;
    this.groupMandatory = false;
    this.profileInfObj.typeOfShareholder = shrHldrType;
    this.mGroup = false;
    let groupName = null;
    if (this.profileInfObj.groupName != null) {
      groupName = this.profileInfObj.groupName["groupName"];
    }
    if (
      (shareholderType === "VC" ||
        shareholderType === "AngelNetwork" ||
        shareholderType === "PE" ||
        shareholderType === "FinancialInstitution") &&
      (groupName === "" || groupName != undefined)
    ) {
      this.groupMandatory = true;
    } else {
      this.mGroup = false;
      this.groupMandatory = false;
    }
    if(shareholderType === "FinancialInstitution") {
      if(!this.profileInfObj.shareholderSubType) {
        this.subTypeMandatory = true;
      } else {
        this.subTypeMandatory = false;
      }
    }
  }

  onSubTypeSelect(shareholderSubType) {
    this.profileInfObj.shareholderSubType = shareholderSubType;
  }

  gropSelectionChange(val) {
    if (val == "newGroup") {
      this.profileInfObj.groupName = null;
      this.showNewGrpMdl = true;
    } else {
      this.mGroup = false;
      this.newGroupErr = false;
      this.profileInfObj.groupName = { groupName: val };
      this.showNewGrpMdl = false;
    }
  }

  close() {
    if (this.fromNewInv) {
      this.showdigPrfle = false;
      this.fromNewInv = false;
      this.visibleChange.emit({
        showMdl: this.showdigPrfle,
        fromNewInv: this.fromNewInv,
      });
    } else {
      this.showdigPrfle = false;
      this.visibleChange.emit({ showMdl: this.showdigPrfle });
    }
  }

  getEvent() {
    this.shouldShowDialog = false;
    this.utility.hideOverlay();
  }

  getNewGroupInfo(e) {
    this.showNewGrpMdl = e.showMdl;
    let ng = e.newGroup;
    if (this.isSecoundaryBuyer) {
      this.authService.getBuyerDropdown().subscribe(
        (response) => {
          this.shareHolderType = response["shareholderTypes"];
          this.groupNames = response["groupName"];
          if (ng) {
            this.selGroupName = this.groupNames[this.groupNames.length - 1];
            this.profileInfObj.groupName = { groupName: this.selGroupName };
          } else {
            this.selGroupName = null;
          }
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason);
        }
      );
    } else {
      this.authService.getNwInstrShrHldrGroupInfo().subscribe(
        (response) => {
          this.shareHolderType = response["shareholderTypes"];
          this.groupNames = response["groupName"];
          if (ng) {
            this.selGroupName = this.groupNames[this.groupNames.length - 1];
            this.profileInfObj.groupName = { groupName: this.selGroupName };
          } else {
            this.selGroupName = null;
          }
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason);
        }
      );
    }
  }

  getSharholderGroupInfo() {
    if (this.isSecoundaryBuyer) {
      this.authService.getBuyerDropdown().subscribe(
        (res) => {
          this.shareHolderType = res["shareholderTypes"];
          this.shareholderSubTypesList = res["shareholderSubTypes"];
          this.groupNames = res["groupName"];
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason);
        }
      );
    } else {
      this.authService.getNwInstrShrHldrGroupInfo().subscribe(
        (response) => {
          this.shareHolderType = response["shareholderTypes"];
          this.shareholderSubTypesList = response["shareholderSubTypes"];
          this.groupNames = response["groupName"];
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason);
        }
      );
    }
  }

  legalNameChange(legalName) {
    if (legalName != "") {
      this.mlegalName = false;
    }
  }

  onChangeResidentSelect(event) {
    this.profileInfObj.residentStatus = event;
  }

  onChangeCountrySelect(event) {
    this.profileInfObj.nationality = event;
  }

  openUploadPicturePopup() {
    this.utility.showOverlay()
    this.shouldShowDialog = true;
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  onImageSaved(imageDetails) {
    this.shouldShowDialog = false;
    this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
    this.profileInfObj.imageName = imageDetails.fileName;
    this.croppedImage = imageDetails.croppedImage;
    this.profileInfObj.imageBase64String = this.croppedImage;
    this.profileInfObj.contenttype = this.content;
  }

  changeCroppedImage() {
    this.profileInfObj.imageBase64String = null;
    this.profileInfObj.imageUrl = null;
    this.profileInfObj.documentPath = null;
  }

  async uploadAvatarImage(event) {
    this.croppedImage = event.target.src;
    let tempImage = event.target.src;
    this.profileInfObj.imageName = tempImage.split("/").pop();
    this.profileInfObj.contenttype = this.content;
    this.profilePicture = await fetch(tempImage).then((res) => res.blob());
    this.handleFileSelect(this.profilePicture);
  }

  handleFileSelect(evt: any) {
    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(evt);
  }

  handleReaderLoaded(e) {
    this.croppedImage = "data:image/png;base64," + btoa(e.target.result);
    this.profileInfObj.imageBase64String = this.croppedImage;
  }

  maxNumber(val) {
    if (val.length > this.maxLength) {
      this.profileInfObj.contactNumber = val.slice(0, this.maxLength);
    }
  }

  // ngAfterViewInit() {
  //   this.loadJSstyles();
  // }

  ngAfterViewChecked() {
    this.loadJSstyles();
  }

  loadJSstyles() {
    $('[data-toggle="switch"]').bootstrapSwitch({
        onText: "Yes",
        offText: "No",
    });
    $("#promoter").bootstrapSwitch('state', this.profileInfObj.isPromoter);
    $("#promoter").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        this.profileInfObj.isPromoter = state;
    });
    $("#isRelative").bootstrapSwitch('state', this.profileInfObj.isRelativeOfDirector);
    $("#isRelative").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
      this.profileInfObj.isRelativeOfDirector = state;
    });
  }

  ngOnInit() {
    if (this.shareholderId) {
      this.ngxLoader.start();
      const { getCode, getNames } = require("country-list");
      this.countryList = getNames();
      this.getSharholderGroupInfo();
      this.authService.getShareholderProfile(this.shareholderId).subscribe(
        (res) => {
          if (res) {
            this.name = this.shareholderName;
            this.profileInfObj.typeOfShareholder = res["typeOfShareholder"];
            this.profileInfObj.shareholderSubType = res["shareholderSubType"] ? res["shareholderSubType"] : null;
            this.profileInfObj.isRelativeOfDirector = res["isRelativeOfDirector"];
            this.profileInfObj.registeredAddress = res["registeredAddress"];
            this.profileInfObj.dpId = res["dpId"];
            this.profileInfObj.clientId = res["clientId"];
            this.profileInfObj.depositoryName = res["depositoryName"];
            this.profileInfObj.isPromoter = res["isPromoter"];
            if (res["residentStatus"] == null || res["residentStatus"] === "") {
              this.profileInfObj.residentStatus = this.selectedResidentType;
            } else {
              this.profileInfObj.residentStatus = res["residentStatus"];
            }
            if (res["nationality"] == null || res["nationality"] === "") {
              this.profileInfObj.nationality = this.selectedCountry;
            } else {
              this.profileInfObj.nationality = res["nationality"];
            }
            this.profileInfObj.occupation = res["occupation"];
            this.profileInfObj.emailId = res["emailId"];
            this.profileInfObj.corporateAddress = res["corporateAddress"];
            this.profileInfObj.contactNumber = res["contactNumber"];
            this.profileInfObj.legalName = res["legalName"];
            let group = res["groupName"];
            if (group != undefined) {
              this.selGroupName = group["groupName"];
              this.profileInfObj.groupName = { groupName: this.selGroupName };
            }
            if (
              this.profileInfObj.typeOfShareholder === "VC" ||
              this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
              this.profileInfObj.typeOfShareholder === "PE" ||
              this.profileInfObj.typeOfShareholder === "FinancialInstitution"
            ) {
              this.groupMandatory = true;
            }
            this.profileInfObj.imageUrl = res["imageUrl"];
            this.docPath = res["documentPath"];
            this.profileInfObj.documentPath = this.docPath;
            // this.loadJSstyles();
            this.ngxLoader.stop();
          }
        },
        (e) => {
          this.toastr.error(e.error.reason, "Error");
          this.ngxLoader.stop();
        }
      );
    } else if(this.beneficiaryId) {
      this.name = this.beneficiaryProfile.name;
      this.ngxLoader.start();
      const { getCode, getNames } = require("country-list");
      this.countryList = getNames();
      this.getSharholderGroupInfo();
      this.profileInfObj = this.beneficiaryProfile;
      let group = this.beneficiaryProfile["groupName"];
      if (group != undefined) {
        this.selGroupName = group["groupName"];
        this.profileInfObj.groupName = { groupName: this.selGroupName };
      }
      if (
        this.profileInfObj.typeOfShareholder === "VC" ||
        this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
        this.profileInfObj.typeOfShareholder === "PE" ||
        this.profileInfObj.typeOfShareholder === "FinancialInstitution"
      ) {
        this.groupMandatory = true;
      }
      this.ngxLoader.stop();

    } else if (this.isSecoundaryBuyer) {
      const { getCode, getNames } = require("country-list");
      this.countryList = getNames();
      this.profileInfObj.residentStatus = this.selectedResidentType;
      this.profileInfObj.nationality = this.selectedCountry;
      this.getSharholderGroupInfo();
    } else if(this.isNewProfile) {
      const { getCode, getNames } = require("country-list");
      this.countryList = getNames();
      this.profileInfObj.residentStatus = this.selectedResidentType;
      this.profileInfObj.nationality = this.selectedCountry;
      this.getSharholderGroupInfo();
    } else if (this.fromNewInv) {
      this.name = this.newInvestorProfile["investorName"];
      const { getCode, getNames } = require("country-list");
      this.countryList = getNames();
      this.getSharholderGroupInfo();
      if (this.newInvestorProfile["residentStatus"]) {
        this.profileInfObj.residentStatus =
          this.newInvestorProfile["residentStatus"];
      } else {
        this.profileInfObj.residentStatus = this.selectedResidentType;
      }
      if (this.newInvestorProfile["nationality"]) {
        this.profileInfObj.nationality = this.newInvestorProfile["nationality"];
      } else {
        this.profileInfObj.nationality = this.selectedCountry;
      }
      this.profileInfObj.typeOfShareholder =
        this.newInvestorProfile["typeOfInvestor"];
      this.profileInfObj.registeredAddress =
        this.newInvestorProfile["registeredAddress"];
      this.profileInfObj.residentStatus =
        this.newInvestorProfile["residentStatus"];
      this.profileInfObj.shareholderSubType = this.newInvestorProfile["shareholderSubType"] ? this.newInvestorProfile["shareholderSubType"] : null;
      this.profileInfObj.isRelativeOfDirector = this.newInvestorProfile["isRelativeOfDirector"];
      this.profileInfObj.dpId = this.newInvestorProfile["dpId"];
      this.profileInfObj.clientId = this.newInvestorProfile["clientId"];
      this.profileInfObj.depositoryName = this.newInvestorProfile["depositoryName"];
      this.profileInfObj.isPromoter = this.newInvestorProfile["isPromoter"];
      this.profileInfObj.nationality = this.newInvestorProfile["nationality"];
      this.profileInfObj.occupation = this.newInvestorProfile["occupation"];
      this.profileInfObj.emailId = this.newInvestorProfile["emailId"];
      this.profileInfObj.corporateAddress =
        this.newInvestorProfile["corporateAddress"];
      this.profileInfObj.contactNumber =
        this.newInvestorProfile["contactNumber"];
      if (this.newInvestorProfile["legalName"] == "") {
        this.profileInfObj.legalName = this.newInvestorProfile["investorName"];
      } else {
        this.profileInfObj.legalName = this.newInvestorProfile["legalName"];
      }
      let group = this.newInvestorProfile["groupName"];
      if (group != undefined) {
        this.selGroupName = group["groupName"];
        if (this.selGroupName != "") {
          this.profileInfObj.groupName = { groupName: this.selGroupName };
        } else {
          this.selGroupName = "";
        }
      }
      if (
        this.profileInfObj.typeOfShareholder === "VC" ||
        this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
        this.profileInfObj.typeOfShareholder === "PE" ||
        this.profileInfObj.typeOfShareholder === "FinancialInstitution"
      ) {
        this.groupMandatory = true;
      }
      this.profileInfObj.imageUrl = this.newInvestorProfile["imageUrl"];
      this.docPath = this.newInvestorProfile["documentPath"];
      this.profileInfObj.documentPath = this.docPath;
    } else if (this.fromHistoricalRound) {
      if(this.newInvestorProfile["investorName"]) {
        this.name = this.newInvestorProfile["investorName"];
        this.profileInfObj.legalName = this.newInvestorProfile["investorName"];
      } else if(this.newInvestorProfile["name"]) {
        this.name = this.newInvestorProfile["name"];
        this.profileInfObj.legalName = this.newInvestorProfile["name"];
      } else {
        this.name = this.newInvestorProfile["buyer"];
        this.profileInfObj.legalName = this.newInvestorProfile["buyer"];
      }
      const { getCode, getNames } = require("country-list");
      this.countryList = getNames();
      this.getSharholderGroupInfo();
      if (this.newInvestorProfile.investorProfile != null) {
        if (this.newInvestorProfile.investorProfile["residentStatus"]) {
          this.profileInfObj.residentStatus =
            this.newInvestorProfile.investorProfile["residentStatus"];
        } else {
          this.profileInfObj.residentStatus = this.selectedResidentType;
        }
        if (this.newInvestorProfile.investorProfile["nationality"]) {
          this.profileInfObj.nationality =
            this.newInvestorProfile.investorProfile["nationality"];
        } else {
          this.profileInfObj.nationality = this.selectedCountry;
        }
        this.profileInfObj.typeOfShareholder =
          this.newInvestorProfile.investorProfile["typeOfShareholder"];
        this.profileInfObj.registeredAddress =
          this.newInvestorProfile.investorProfile["registeredAddress"];
        this.profileInfObj.shareholderSubType = this.newInvestorProfile.investorProfile["shareholderSubType"] ? this.newInvestorProfile.investorProfile["shareholderSubType"] : null;
        this.profileInfObj.isRelativeOfDirector = this.newInvestorProfile.investorProfile["isRelativeOfDirector"];
        this.profileInfObj.dpId = this.newInvestorProfile.investorProfile["dpId"];
        this.profileInfObj.clientId = this.newInvestorProfile.investorProfile["clientId"];
        this.profileInfObj.depositoryName = this.newInvestorProfile.investorProfile["depositoryName"];
        this.profileInfObj.isPromoter = this.newInvestorProfile.investorProfile["isPromoter"];
        this.profileInfObj.occupation =
          this.newInvestorProfile.investorProfile["occupation"];
        this.profileInfObj.emailId =
          this.newInvestorProfile.investorProfile["emailId"];
        this.profileInfObj.corporateAddress =
          this.newInvestorProfile.investorProfile["corporateAddress"];
        this.profileInfObj.contactNumber =
          this.newInvestorProfile.investorProfile["contactNumber"];
        if (
          this.newInvestorProfile.investorProfile["legalName"] == "" ||
          this.newInvestorProfile.investorProfile["legalName"] == null
        ) {
          this.profileInfObj.legalName =
            this.newInvestorProfile.investorProfile["investorName"];
        } else {
          this.profileInfObj.legalName =
            this.newInvestorProfile.investorProfile["legalName"];
        }
        let group = this.newInvestorProfile.investorProfile["groupName"];
        if (group != undefined) {
          this.selGroupName =
            group["groupName"] == "" ? null : group["groupName"];
          if (this.selGroupName != null) {
            this.profileInfObj.groupName = { groupName: this.selGroupName };
          } else {
            this.selGroupName = null;
          }
        } else {
          this.selGroupName = null;
        }
        this.profileInfObj.imageUrl =
          this.newInvestorProfile.investorProfile["imageUrl"];
        this.docPath = this.newInvestorProfile.investorProfile["documentPath"];
      } else {
        this.profileInfObj.nationality = this.selectedCountry;
        this.profileInfObj.residentStatus = this.selectedResidentType;
        this.profileInfObj.shareholderSubType = null;
      }
      if (
        this.profileInfObj.typeOfShareholder === "VC" ||
        this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
        this.profileInfObj.typeOfShareholder === "PE" ||
        this.profileInfObj.typeOfShareholder === "FinancialInstitution"
      ) {
        this.groupMandatory = true;
      }
      this.profileInfObj.documentPath = this.docPath;
    } else {
      const { getCode, getNames } = require("country-list");
      this.countryList = getNames();
      this.profileInfObj.residentStatus = this.selectedResidentType;
      this.profileInfObj.nationality = this.selectedCountry;
      this.outstandingConv.forEach((element, index) => {
        if (this.selectedIndex === index) {
          this.name = element["name"];
          this.initObj = element["profileDetails"];
          this.valid = this.validatorMethodNogroup(this.initObj);
          if (this.valid) {
            this.profileInfObj.typeOfShareholder =
              element["profileDetails"]["typeOfShareholder"];
            this.profileInfObj.registeredAddress =
              element["profileDetails"]["registeredAddress"];
            this.profileInfObj.residentStatus =
              element["profileDetails"]["residentStatus"];
            this.profileInfObj.shareholderSubType = element["profileDetails"]["shareholderSubType"] ? element["profileDetails"]["shareholderSubType"] : null;
            this.profileInfObj.isRelativeOfDirector = element["profileDetails"]["isRelativeOfDirector"];
            this.profileInfObj.dpId = element["profileDetails"]["dpId"];
            this.profileInfObj.clientId = element["profileDetails"]["clientId"];
            this.profileInfObj.depositoryName = element["profileDetails"]["depositoryName"];
            this.profileInfObj.isPromoter = element["profileDetails"]["isPromoter"];
            this.profileInfObj.nationality =
              element["profileDetails"]["nationality"];
            this.profileInfObj.occupation =
              element["profileDetails"]["occupation"];
            this.profileInfObj.emailId = element["profileDetails"]["emailId"];
            this.profileInfObj.corporateAddress =
              element["profileDetails"]["corporateAddress"];
            this.profileInfObj.contactNumber =
              element["profileDetails"]["contactNumber"];
            this.profileInfObj.legalName =
              element["profileDetails"]["legalName"];
            let group = element["profileDetails"]["groupName"];
            if (group != undefined) {
              this.selGroupName = group["groupName"];
              this.profileInfObj.groupName = { groupName: this.selGroupName };
            }
            if (
              this.profileInfObj.typeOfShareholder === "VC" ||
              this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
              this.profileInfObj.typeOfShareholder === "PE" ||
              this.profileInfObj.typeOfShareholder === "FinancialInstitution"
            ) {
              this.groupMandatory = true;
            }
            this.profileInfObj.imageUrl = element["profileDetails"]["imageUrl"];
            this.docPath = element["profileDetails"]["documentPath"];
            this.profileInfObj.documentPath = this.docPath;
          }
        }
      });
    }
  }
}
