<div *ngIf="showDialogEmp" class="dialog">
    <div class="header">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header">
                <label class="dialogue-title" *ngIf="!this.editMode">Add Employee</label>
                <label class="dialogue-title" *ngIf="this.editMode">Edit Employee</label>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
                <button ngbTooltip="Close" type="button" class="close top-close-btn" (click)="close()"
                    aria-label="Close">
                    <span class="close">X</span>
                </button>
            </div>
        </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>

    <div *ngIf="invalidForm" class="alert alert-danger">
        <div style="display:none">
            Please fill all the details.
        </div>
    </div>

    <form class="content-row needs-validation" novalidate>
        <div class="col-xs-12 col-sm-12 col-md-12 top-20 btm-20">
            <div id="first-half">
            <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label class="required">Employee Name</mat-label>
                    <input type="text" title="" class="form-control top-10" placeholder="Employee Name"
                        [(ngModel)]="newEmployee.employeeName" (keypress)="alphabetic($event)" #nameErr="ngModel" [ngModelOptions]="{standalone: true}" required />
                    <div  *ngIf="nameErr.errors">
                        <div class="alert alert-danger" *ngIf="(nameErr.dirty || nameErr.touched) && !empName">
                            Employee Name is required!
                        </div>
                        <div class="alert alert-danger" *ngIf="nameErr.errors?.required && empName">
                            Employee Name is required!
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label>Employee Designation</mat-label>
                    <input type="text" class="form-control top-10" [(ngModel)]="newEmployee.employeeDesignation"
                        placeholder="Employee Designation" #empDesiErr="ngModel" title="" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="row  top-20">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label class="required">Employee Id</mat-label>
                    <input type="text" title="" class="form-control top-10" placeholder="Employee Id"
                        [(ngModel)]="newEmployee.employeeId" #empIdErr="ngModel" (keypress)="alphaNumeric($event)"
                        [ngModelOptions]="{standalone: true}" [readonly]="editMode" required />

                    <div *ngIf="empIdErr.errors">
                        <div class="alert alert-danger" *ngIf="(empIdErr.dirty || empIdErr.touched) && !empId">
                            Employee Id is required!
                        </div>
                        <div class="alert alert-danger" *ngIf="empIdErr.errors?.required && empId">
                            Employee Id is required!
                        </div>
                    </div>
                </div>

                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label>Contact Number</mat-label>
                    <input title="" type="text" class="form-control top-10"
                        [(ngModel)]="newEmployee.contactNumber" #phoneErr="ngModel" placeholder="Contact Number"
                        (keypress)="maxNumber($event.target.value)" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>

            <div class="row top-20">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label class="required">Email Id</mat-label>
                    <input type="email" class="form-control top-10" [(ngModel)]="newEmployee.email" #mailErr="ngModel"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" required placeholder="me@example.com"
                        title="" [ngModelOptions]="{standalone: true}" [readonly]="editMode" />
                    <div class="alert alert-danger" *ngIf="mailErr.errors">
                        <div *ngIf="(mailErr.errors?.required && empEmail) || ((mailErr.touched || mailErr.dirty) && !mailErr.errors?.pattern)">
                            E-mail Id is required!
                        </div>
                        <!-- <div *ngIf="(mailErr.touched || mailErr.dirty) && !mailErr.errors?.pattern">
                            E-mail Id is required!
                        </div> -->
                        <div id="emailValid" [hidden]="!mailErr.errors?.pattern">
                            Invalid E-mail!
                        </div>
                    </div>
                </div>


                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label>Manager Name</mat-label>
                    <input type="text" title="" class="form-control top-10" placeholder="Manager Name"
                        [(ngModel)]="newEmployee.manager" #manaErr="ngModel" [ngModelOptions]="{standalone: true}"/>
                </div>

            </div>
            </div>
            <div id="second-half">
            <div class="row top-20">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <div>
                        <mat-label class="required card-lbl">Department</mat-label>
                        <input class="form-control top-10" [ngModelOptions]="{standalone: true}" (keypress)="alphaNumericAndSpace($event)"
                            (change)="changeDept($event.target.value)" #deptErr="ngModel" [(ngModel)]="newEmployee.department" required>
                        <div *ngIf="deptErr.errors">
                            <div class="alert alert-danger" *ngIf="(deptErr.dirty || deptErr.touched) && !empDept">
                                Department is required!
                            </div>
                            <div class="alert alert-danger" *ngIf="deptErr.errors?.required && empDept">
                                Department is required!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label class="required card-lbl">Date of Joining</mat-label>
                    <div class="input-eyePlaceholder">
                        <input matInput [matDatepicker]="dp" class="form-control top-10" title="" [min]="companyIncorporationDate" max="9999-12-31" #dojErr="ngModel"
                            [(ngModel)]="newEmployee.dateOfJoin" placeholder="DD-MMM-YYYY" [ngModelOptions]="{standalone: true}" required/>
                        <mat-datepicker-toggle matSuffix [for]="dp">
                            <mat-icon matDatepickerToggleIcon ></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </div>
                    <div *ngIf="dojErr.errors">
                        <div class="alert alert-danger" *ngIf="(dojErr.dirty || dojErr.touched) && !empJoinDt && !newEmployee.dateOfJoin">
                            Date of Joining is required!
                        </div>
                        <div class="alert alert-danger" *ngIf="newEmployee.dateOfJoin && newEmployee.dateOfJoin < companyIncorporationDate">
                             Joining date must be after or equal to {{companyIncorporationDate.toDateString()}}
                        </div>
                        <div class="alert alert-danger" *ngIf="dojErr.errors?.required  && empJoinDt">
                            Date of Joining is required!
                        </div>
                    </div>
                </div>

            </div>

            <div class="row top-20">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <div>
                        <mat-label class="required card-lbl">Country of Residence</mat-label>
                        <select class="form-control top-10 cursor-hand" [(ngModel)]='newEmployee.residentialStatus' [ngModelOptions]="{standalone: true}">
                            <option *ngFor='let status of residentialStatus; let k = index'
                            value="{{status}}">{{status}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label class="card-lbl">Grade</mat-label>
                    <select class="form-control top-10 cursor-hand" [(ngModel)]="newEmployee.grade"
                        [ngModelOptions]="{standalone: true}" #gradeErr="ngModel" >
                        <option disabled selected value="" >Select Grade</option>
                        <option *ngFor="let grade of gradeList">{{ grade }}</option>
                    </select>
                    <div *ngIf="gradeErr.errors">
                        <div class="alert alert-danger" *ngIf="(gradeErr.dirty || gradeErr.touched) && !empGrade">
                            Grade is required!
                        </div>
                        <div class="alert alert-danger" *ngIf="gradeErr.errors?.required && empGrade">
                            Grade is required!
                        </div>
                    </div>
                </div>
            </div>
            <div class="row top-20">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <div>
                        <mat-label class="required card-lbl">Nationality</mat-label>
                        <select class="form-control top-10 cursor-hand" [(ngModel)]="newEmployee.nationality" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let country of countryList">{{ country }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xs-5 col-sm-5 col-md-5">
                    <mat-label class="card-lbl">Upload Photo</mat-label>
                    <div>
                        <img class="profile-image top-10" *ngIf="newEmployee.imageURL" [src]="newEmployee.imageURL" />
                        <input type="text" [ngClass]="{ 'uploadFileNameWidth': editMode == true }" title="" #fileErr="ngModel" [(ngModel)]="fileName"
                        class="form-control top-10" placeholder="Upload Photo" [ngModelOptions]="{standalone: true}" />
                    </div>

                    <app-upload-image *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog" [croppedImage]="croppedImage" (visibleChange)="getEvent()" (imageSaved)="onImageSaved($event)">
                    </app-upload-image>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 ">
                    <mat-label></mat-label>
                    <img (click)="openUploadImage()" class="top-40 cursor-hand" ngbTooltip="Upload"
                        src="../../../assets/upload.svg" />
                </div>
            </div>
            <div class="row top-20">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label class="required card-lbl">Status</mat-label>
                    <select class="form-control top-10 cursor-hand" [(ngModel)]="newEmployee.employmentStatus"  #statusErr="ngModel"
                             [ngModelOptions]="{standalone: true}" required [disabled]="editMode">
                        <option disabled selected value="">Select Status</option>
                        <option *ngFor="let status of employmentStatus" [ngValue]="status">{{ status }}</option>
                    </select>
                  <div *ngIf="statusErr.errors">
                       <div class="alert alert-danger" *ngIf="(statusErr.dirty || statusErr.touched) && !empStatus">
                         Status is required!
                        </div>
                        <div class="alert alert-danger" *ngIf="statusErr.errors?.required && empStatus">
                         Status is required!
                        </div>
                   </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label class="required">Date of Resignation</mat-label>
                    <div class="input-eyePlaceholder">
                        <input matInput [min]="newEmployee.dateOfJoin" [matDatepicker]="rdp" class="form-control top-10" title=""
                            max="9999-12-31" #dorErr="ngModel" [(ngModel)]="newEmployee.lastDay" placeholder="DD-MMM-YYYY"
                            [ngModelOptions]="{standalone: true}" required [disabled]="newEmployee.employmentStatus === 'Employed'" />
                        <mat-datepicker-toggle matSuffix [for]="rdp">
                            <mat-icon matDatepickerToggleIcon></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #rdp></mat-datepicker>
                    </div>
                    <div *ngIf="dorErr.errors">
                        <div class="alert alert-danger" *ngIf="!empResignDt">
                            Resignation date must be later than date of joining!
                        </div>
                        <div class="alert alert-danger" *ngIf="(dorErr.dirty || dorErr.touched)">
                            Date of Resignation is required!
                        </div>
                        <div class="alert alert-danger" *ngIf="dorErr.errors?.required && empResignDt">
                            Date of Resignation is required!
                        </div>
                    </div>
                </div>
            </div>
            <div class="row top-20">
                <div class="col-xs-6 col-sm-6 col-md-6">
                    <mat-label>Registered Address</mat-label>
                    <textarea class="form-control top-10" col="8" row="15" placeholder="Registered Address"
                        [(ngModel)]="newEmployee.address" #addErr="ngModel" title="" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
            </div>
            </div>

            <div class="row top-20 float-right">
                <div class="col-xs-12 col-sm-12 col-md-12 btm-20">
                    <button mat-raised-button id="cancel" color="warn" (click)="close()" ngbTooltip="Cancel"
                        [disableRipple]="disableripple">
                        Cancel
                    </button>&nbsp;&nbsp;
                    <button mat-raised-button id="saveAnExit" color="success" (click)="checkValidation()" ngbTooltip="Save and Exit"
                        [disableRipple]="disableripple">
                        Save and Exit
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
