import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Employee } from "../../model/add-employee";
import { AuthenticationService } from "../../../authentication.service";
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilityService } from '../../../services/utilities.service';
import { DatePipe } from '@angular/common';
import * as moment from "moment";
import { CacheProviderService } from "src/app/services/cache-provider.service";

interface EditEmployee extends Employee{
  employeeIdentificationString : string;
}
@Component({
  selector: "app-add-employee",
  templateUrl: "./add-employee.component.html",
  styleUrls: ["./add-employee.component.scss"]
})
export class AddEmployeeComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() employeeId;
  @Input() showDialogEmp: boolean;
  @Input() isAdmin: boolean;
  @Input() isOptionApprover: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() existingEmployees: Array<EditEmployee>
  public newEmployee: Employee;
  public employeeDept = [];
  public countryList = [];
  public employmentStatus = null;
  public residentialStatus = [];
  public invalidForm: boolean;
  public disableripple: boolean = true;

  public empId: boolean = false;
  public empEmail: boolean = false;
  public empName: boolean = false;
  public empDept: boolean = false;
  public empLegal: boolean = false;
  public empManager: boolean = false;
  public empJoinDt: boolean = false;
  public empResignDt: boolean = false;
  public empGrade:boolean = false;
  public empStatus:boolean = false;
  public shouldShowDialog = false;
  public profilePicture = null;
  public croppedImage = null;
  public fileName: any;
  public emailValid: boolean = false;
  public maxLength: number = 9;
  public gradeList = ["G1","G2","G3","G4","G5","G6","G7","G8","G9","G10"];
  userDetails
  companyIncorporationDate
  existingEmployeeId: Employee["employeeId"];
  constructor(
    private authService: AuthenticationService, 
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private cacheService: CacheProviderService,
    private utility: UtilityService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.newEmployee = new Employee();
    if(this.newEmployee.department === undefined){
      this.newEmployee.department = "";
    }
    if(this.newEmployee.employeeDesignation === undefined){
      this.newEmployee.employeeDesignation = "";
    }
    if(this.newEmployee.address === undefined){
      this.newEmployee.address = "";
    }
    if(this.newEmployee.contactNumber === undefined){
      this.newEmployee.contactNumber = "";
    }
    if(this.newEmployee.manager === undefined){
      this.newEmployee.manager = "";
    }
    if(this.newEmployee.grade === undefined){
      this.newEmployee.grade = "";
    }
    if(this.newEmployee.employmentStatus === undefined){
      this.newEmployee.employmentStatus = "";
    }
    const { getCode, getNames } = require("country-list");
    this.countryList = getNames();
    this.countryList.sort((a , b)  =>  {
      return ( b > a)? -1 : 1;
    });
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.companyIncorporationDate = new Date(this.userDetails.company.dateOfIncorporation);
    }

    this.residentialStatus = getNames();
    this.residentialStatus.sort((a , b)  =>  {
      return ( b > a)? -1 : 1;
    });
    this.newEmployee.nationality = "India";
    this.newEmployee.residentialStatus = "India";
    this.newEmployee.employmentStatus = "Employed";
    this.authService.getEsopMgmtDropdown().subscribe(res => {
        
      if (res) {
        this.employmentStatus = res["employmentStatus"];
        
        this.ngxLoader.stop();
      } else {
        this.toastr.error("Null response", "Failure!");
        this.ngxLoader.stop();
      }
    },
    e => { 
      this.toastr.error(e.error.reason, "Failure!");
      this.ngxLoader.stop(); 
    });
    if(this.editMode) {
        this.authService.getEmpPopupDetails(this.employeeId).subscribe(
            (res) => {
                this.newEmployee = JSON.parse(JSON.stringify(res));
                this.newEmployee.employeeId = res['employeeIdentification'];
                this.existingEmployeeId = res['employeeIdentification'];
                this.ngxLoader.stop();
            },
            (e) => {
              this.toastr.error(e.error.reason, "Error");
              this.ngxLoader.stop();
            }
        );
    }
    else{
      this.ngxLoader.stop();
    }  
    this.isAdmin = localStorage.getItem("userRole") == "Admin";
    this.isOptionApprover = localStorage.getItem("userRole") == "Options Approver";
  }

  close() {
    this.showDialogEmp = false;
    this.newEmployee = new Employee();
    this.empDept = false;
    this.empName = false;
    this.empId = false;
    this.empEmail = false;
    this.empLegal = false;
    this.empManager = false;
    this.fileName = "";
    this.newEmployee.nationality = "India";
    this.newEmployee.residentialStatus = this.residentialStatus[0];
    this.visibleChange.emit(this.showDialogEmp);
  }

  getEvent() {
    this.shouldShowDialog = false;
    document.getElementById("overlay").style.display = "none";
  }

  changeDept(value) {
    if (value != "undefined") {
      this.empDept = false;
    }
    else {
      this.empDept = true;
    }
  }

  checkValidation() {
    this.utility.formValidation();
    if (typeof this.newEmployee.department === "undefined" || this.newEmployee.department === "" ||
      typeof this.newEmployee.employeeId === "undefined" || this.newEmployee.employeeId === "" ||
      typeof this.newEmployee.employeeName === "undefined" || this.newEmployee.employeeName === "" ||
      typeof this.newEmployee.email === "undefined" || this.newEmployee.email === "" ||
      typeof this.newEmployee.residentialStatus === "undefined" || this.newEmployee.residentialStatus === "" ||
      typeof this.newEmployee.nationality === "undefined" || this.newEmployee.nationality === "" ||
      typeof this.newEmployee.dateOfJoin === "undefined"||   !this.newEmployee.dateOfJoin ||
      typeof this.newEmployee.employmentStatus === "undefined" || this.newEmployee.employmentStatus === ""
    ) {
      if( typeof this.newEmployee.employeeId === "undefined" || this.newEmployee.employeeId === "" ||
          typeof this.newEmployee.employeeName === "undefined" || this.newEmployee.employeeName === "" ||
          typeof this.newEmployee.email === "undefined" || this.newEmployee.email === ""){
        let fh = document.getElementById("first-half");
        this.utility.focusSection(fh);
      }
      if( typeof this.newEmployee.department === "undefined" || this.newEmployee.department === "" ||
          typeof this.newEmployee.residentialStatus === "undefined" || this.newEmployee.residentialStatus === "" ||
          typeof this.newEmployee.nationality === "undefined" || this.newEmployee.nationality === "" ||
          typeof this.newEmployee.dateOfJoin === "undefined"||   !this.newEmployee.dateOfJoin ||
          typeof this.newEmployee.grade === "undefined" || this.newEmployee.grade === "" ||
          typeof this.newEmployee.employmentStatus === "undefined" || this.newEmployee.employmentStatus === "" 
          ){
        let sh = document.getElementById("second-half");
        this.utility.focusSection(sh);
      }
      this.invalidForm = true;
      if (typeof this.newEmployee.department === "undefined" || this.newEmployee.department === "") {
        this.empDept = true;
      }
      if (typeof this.newEmployee.employeeId === "undefined" || this.newEmployee.employeeId === "") {
        this.empId = true;
      }
      if (typeof this.newEmployee.employeeName === "undefined" || this.newEmployee.employeeName === "") {
        this.empName = true;
      }
      if (typeof this.newEmployee.email === "undefined" || this.newEmployee.email === "") {
        this.empEmail = true;
      }
      if (typeof this.newEmployee.dateOfJoin === "undefined"||   !this.newEmployee.dateOfJoin ) {
          this.empJoinDt = true;
      }
      if (typeof this.newEmployee.lastDay === "undefined"||   !this.newEmployee.lastDay ) {
        this.empResignDt = true;
    }
    if (typeof this.newEmployee.employmentStatus === "undefined"||  this.newEmployee.employmentStatus === "" ) {
      this.empStatus = true;
  }
      return;
    } else {
      if (this.emailValid === true) {
        console.log(document.getElementById("emailValid"));
        return;
      }
      if (this.newEmployee.dateOfJoin < this.companyIncorporationDate){
        this.toastr.error("Date of Joining must be after Company Incorporation Date","Error");
        return;
      }
      this.invalidForm = false;
      if (this.newEmployee.employmentStatus != "Employed") {
        if (this.newEmployee.lastDay != null) {
          if (this.validateResignationDate()) {
            this.addEmployee();
          }
        }
        else {
          this.toastr.error("Date of Resignation is required.", "Error");
        }
      }
      else {
        this.addEmployee();
      }
    }
  }

  validateResignationDate(){
    const dateOfJoin = moment(this.newEmployee.dateOfJoin)
    const lastDay = moment(this.newEmployee.lastDay)
    if(lastDay.isBefore(dateOfJoin)){
      this.toastr.error("Resignation date must be later than date of joining","Error");
      return false;
    }
    return true
  }
  
  // checkEmail(data) {
  //   if (document.getElementById("emailValid") == null) {
  //     this.emailValid = false;
  //   }
  //   else {
  //     this.emailValid = true;
  //   }
  // }


  alphaNumeric(event: any) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }
    event.preventDefault();
    return false;
  }

  alphaNumericAndSpace(event: any) {
    var regex = new RegExp("^[a-zA-Z0-9._-\\s]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }
    event.preventDefault();
    return false;
  }

  alphabetic(event : any){
    var regex = new RegExp("^[a-zA-Z.\\s]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
  }
  event.preventDefault();
  return false;
  }



  openUploadImage() {
    this.shouldShowDialog = true;
  }

  onImageSaved(imageDetails) {
    this.shouldShowDialog = false;
    this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
    this.croppedImage = imageDetails.croppedImage;
    this.fileName = imageDetails.fileName;
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  addEmployee() {
    if(this.existingEmployeeId != this.newEmployee.employeeId){
      let employeeFound = false;
      this.existingEmployees.forEach(employee=>{
        if( this.isAdmin && employee.employeeIdentificationString == this.newEmployee.employeeId){
          this.toastr.error("ID already exists", "error");
          employeeFound = true;
        }
      })
      if (employeeFound) return;
    }
    const formData: FormData = new FormData();
    if(this.profilePicture != null){
      formData.append('file', (new File([this.profilePicture], this.fileName, { type: 'image/jpeg' })));
    }
    formData.append('employeeName', this.newEmployee.employeeName);
    formData.append('employeeDesignation', this.newEmployee.employeeDesignation);
    formData.append('address', this.newEmployee.address);
    formData.append('contactNumber', this.newEmployee.contactNumber);
    formData.append('department', this.newEmployee.department);
    formData.append('email', this.newEmployee.email);
    formData.append('manager', this.newEmployee.manager);
    formData.append('residentialStatus', this.newEmployee.residentialStatus);
    formData.append('nationality', this.newEmployee.nationality);
    formData.append('legalName', this.newEmployee.employeeName);
    formData.append('employeeIdentification', this.newEmployee.employeeId);
    formData.append('dateOfJoin',this.transformDate(this.newEmployee.dateOfJoin));
    if(this.newEmployee.employmentStatus != 'Employed'){
      formData.append('lastDay',this.datePipe.transform(this.newEmployee.lastDay));
    }
    if (this.newEmployee.grade == "null"){
      formData.append('grade',"");
    }
    else{
      formData.append('grade', this.newEmployee.grade);
    }
    
    formData.append('employmentStatus', this.newEmployee.employmentStatus);
    new Response(formData).text().then(console.log);
    this.ngxLoader.start();
    if(this.editMode) {
        formData.append('id', this.newEmployee['id']);
        this.toastr.success("Employee edited succesfully!","Success")
    } 
    else
     this.toastr.success("Employee added succesfully!","Success")
    this.authService.addNewEmployee(formData).subscribe(
        res => {
            this.ngxLoader.stop();
            this.newEmployee = new Employee();
            this.showDialogEmp = false;
            this.empDept = false;
            this.empName = false;
            this.empId = false;
            this.empEmail = false;
            this.visibleChange.emit(this.showDialogEmp);
        },
        e => {
            this.ngxLoader.stop();
            this.toastr.error(e.error.reason, "Failure!");
        }
    );
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'shortDate');
  }

  maxNumber(val){
    if (val.length > this.maxLength) {
      this.newEmployee.contactNumber = val.slice(0, this.maxLength)
    }
  }
}
