<!-- <div class="container-fluid"> -->
    <div id="overlay"></div>
    <!-- <div class="row">
        <div class="col-md-12">
            <h5 class="page-hdr btm-15">Documents</h5>
            <p class="content-pg-1 btm-20">Access and maintain your company documents here. The folders contain all documents uploaded thus far. 
                You can also create new folders and upload additional documents in the respective sections as and when needed.</p>
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-12">
            <ejs-filemanager id='default-filemanager' 
                [detailsViewSettings]='detailsViewSettings' 
                [allowDragAndDrop]='true' 
                (beforeSend)="beforeSend($event)"
                [ajaxSettings]='ajaxSettings'
                [contextMenuSettings]='contextMenuSettings'
                (menuOpen)='menuOpen($event)'
                (menuClick)='menuClick($event)'>
            </ejs-filemanager>
        </div>
    </div>
<!-- </div> -->

<app-tags-edit *ngIf="showTagsPopup" [(isShowTags)]="showTagsPopup" [tagsString]="tagsString" (visibleChange)="getTagsString($event)"></app-tags-edit>