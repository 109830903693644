
import { Injectable } from "@angular/core";
@Injectable()
export class CacheProviderService {

  private storageType: Storage = sessionStorage;

  set(key: string, value: any): Boolean {
    try {
      this.storageType.setItem(key, value);
      return true;
    } catch (error) {
      console.log(`CacheProviderService error in set: ${error}`);
      return false;
    }
  }

  get(key: string): any {
    try {
      return this.storageType.getItem(key);
    } catch (error) {
      console.log(`CacheProviderService error in get: ${error}`);
      return undefined;
    }
  }

  remove(key: string): Boolean {
    try {
      this.storageType.removeItem(key);
      return true;
    } catch (error) {
      console.log(`CacheProviderService error in remove: ${error}`);
      return false;
    }
  }

  clear(): Boolean {
    try {
      this.storageType.clear();
      return true;
    } catch (error) {
      console.log(`CacheProviderService error in clear: ${error}`);
      return false;
    }
  }
}
