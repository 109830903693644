import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utilities.service';
import { CacheProviderService } from '../../services/cache-provider.service';
import { ColumnList } from 'src/app/dialogues/dev-ex-cap-table/columns-list';

@Component({
    selector: 'app-rounds-overview',
    templateUrl: './rounds-overview.component.html',
    styleUrls: ['./rounds-overview.component.scss']
})

export class RoundsOverviewComponent implements OnInit {
    roundOverview;
    overviewCaptable;
    columnList: ColumnList;
    roundDataArray = [];
    chartScroll = false;
    countriesInfo;
    stackChartData = [];
    isCreateRound = false;
    countrySources = [];
    public disableripple: boolean = true;
    public userDetails;
    public usrCompanyCurrency = "INR - ₹";

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private ngxLoader: NgxUiLoaderService,
        private toastr: ToastrService,
        private utilityService: UtilityService,
        private cacheService: CacheProviderService,
    ) { }

    customizeTooltip = (arg) => {
        if(arg.percentText){
            return {
                text: arg.argumentText + "\n" + arg.seriesName + ": " + arg.percentText,
                fontColor: "#ffffff",
                color: "#000000"
            };
        }
        else{
            return {
                text: arg.argumentText + "\n" + arg.seriesName + ": " + this.getAmount(this.usrCompanyCurrency, arg.value),
                fontColor: "#ffffff",
                color: "#000000"
            };
        }
    }
    
    getAmount(usrCompanyCurrency, amt){
        let val = this.utilityService.convertedFormatCurrency(usrCompanyCurrency, amt);
        return val;
    }

    onPointClick(e, roundState) {
        this.getRoundDetailsbyName(e.target.data['roundName'], roundState);
    }

    navigate() {
        if (this.isCreateRound === false) {
            this.router.navigateByUrl('/round', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'round']);
            });
        }
        else {
            this.toastr.error("Round already is in progress, please complete!", "Error");
        }

    }

    navigateToQuickRound() {
        this.router.navigate(['/landing', 'dashboard', 'transactions', 'quick-round']);
    }

    formatData() {
        let roundObj = {
            'roundName': '',
            'primaryAmt': 0,
            'postVal': 0
        };
        this.roundOverview.forEach(round => {
            roundObj = {
                'roundName': round.roundName,
                'primaryAmt': round.roundSize,
                'postVal': round.postMoneyValuation
            };
            this.roundDataArray.push(roundObj);
        });
    }

    redirectToRoundDetails(seriesDet) {
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'round-details', seriesDet.target.argument]);
    }

    getRoundDetailsbyName(roundName, roundState) {
        let rs = roundState;
        if (rs === "PROFORMA" || rs === "CONSTRUCTED") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'round']);
        }
        else if (rs === "ISSUE_IN_PROGRESS") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'summary']);
        }
        else if (rs === "PARTIALLY_ISSUED") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'capTable']);
        }
        else if (rs === "COMPLETED") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'round-details', roundName]);
        }
    }

    getTotalValueTble(value) {
        if (value > 0) {
            if (value.toString().length > 5) {
                return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
            }
            else {
                return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
            }
        }
        else {
            return 0;
        }
    }

    formatCurrency(value) {
        return this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, value);
    }

    //Mouse hover scroll
    scrollVisiblity(val) {
        this.chartScroll = val;
    }

    //Full stacked chart data formating
    formatChartData(data) {
        data.forEach(element => {
            let stackObj = {};

            // stackObj["RoundName"] = element['roundName'];
            stackObj["RoundName"] = element['roundName'] + "";
            element['roundHoldingDtos'].forEach(elements => {
                let srcObj = { val: "", name: "" };
                stackObj[elements['investorName']] = elements['percentage'] * 100;
                srcObj.val = elements['investorName'];
                srcObj.name = elements['investorName'];
                this.countrySources.push(srcObj);
            });
            stackObj["preMoney"] = element['preMoneyValuation'];
            stackObj["postMoney"] = element['postMoneyValuation'];
            stackObj["amtRaised"] = element['amountRaised'];
            this.stackChartData.push(stackObj);
            this.uniqueInvestor(this.countrySources);
        });
    }

    //Unique Investor
    uniqueInvestor(cntrySrc) {
        const filteredArr = cntrySrc.reduce((acc, current) => {
            const x = acc.find(item => item.val === current.val);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        this.countrySources = filteredArr;
    }

    // Create round handler
    createRound() {
        this.roundOverview.forEach(element => {
            if (element['roundState'] === "PARTIALLY_ISSUED" || element['roundState'] === "ISSUE_IN_PROGRESS" ||
                element['roundState'] === "PROFORMA" || element['roundState'] === "CONSTRUCTED") {
                this.isCreateRound = true;
            }
        });
    }

    ngOnInit() {
        this.ngxLoader.start();
        if (this.cacheService.get("userDetails")) {
            this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
            this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
        }
        else {
            this.usrCompanyCurrency = "INR - ₹";
        }
        let isOverView =  true;
        let includeProforma = true;
        this.authService.getRoundDevEx(isOverView, includeProforma).subscribe(
            (res) => {
                this.overviewCaptable = res;
                this.columnList = new ColumnList();
                this.columnList = {
                    shareholder: false,
                    roundName: true,
                    closingDate: true,
                    roundSize: true,
                    pricePerShare: true,
                    postMoneyValuation: true,
                    dilutionPercentage: true,
                    leadInvestor: true,
                    netWorth: false,
                    currentValue: false,
                    amtInvested: false,
                    common: false,
                    debentures: false,
                    fdbShares: false,
                    percentageOfGain: false,
                    preference: false,
                    preHolding: false,
                    postHolding: false,
                    preMoneyValuation: false,
                    rounds: false,
                    warrants: false,
                    securityType: false,
                    holding: false,
                    noOfShares: false,
                    parValue: false,
                    capitalValue: false,
                    totalInvestment: false,
                    document: true,
                    preSplitCommon: false,
                    postSplitCommon: false,
                    preSplitPreference: false,
                    postSplitPreference: false,
                    preSplitFdbShares: false,
                    postSplitFdbShares: false,
                    preRoundShares: false,
                    postRoundShares: false,
                    preHoldingRound: false,
                    postHoldingRound: false,
                    amtInvestedInRound: false,
                    newAntidDilutionShares: false
                };
            },
            (e) => {}
        );
        this.authService.getRoundDetails(true).subscribe(
            (res) => {
                this.roundOverview = res;
                this.formatData();
                this.createRound();

                this.roundOverview.forEach(round => {
                    let investors = round['investors'];
                    if (investors) {
                        for (const investor of investors) {
                            if (investor['isLeadInvestor'] == true) {
                                round['leadInvestor'] = investor['investorName'];
                                break;
                            }
                        }
                    } else {

                    }
                });
                this.ngxLoader.stop();
            },
            (e) => {
                this.toastr.error("Error: " + e.error.reason, "Error");
            }
        );

        this.authService.getRoundOverviewChart().subscribe((response) => {
            if (response) {
                this.countriesInfo = response;
                this.formatChartData(this.countriesInfo);
                this.ngxLoader.stop();
            } else {
                this.toastr.error("Error: Chart data Response is Null", "Error")
            }

        }, (e) => {
            this.toastr.error("Error: " + e.error.reason, "Error");
        })

    }
}
