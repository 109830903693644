<section class="content">
  <div class="background-gradient">
    <div class="row header-row">
      <div class="col-md-2 col-lg-3"></div>
      <div class="col-md-8 col-lg-6">
        <p class="heading">
          <img src="../../../assets/logo/logo.svg" />
        </p>
        <p class="subheading">Equity Management</p>
      </div>
      <div class="col-md-2 col-lg-3"></div>
    </div>
    <div class="row content-row">
      <div class="col-md-2 col-lg-3"></div>
      <div class="col-md-8 col-lg-6">
        <mat-card class="card">
          <mat-card-header>
            <mat-card-title class="text-align-center">Forgot Password</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="form-card text-align-center">
              <mat-label>Enter your registered email address to receive the temporary password.</mat-label>
              <div *ngIf="!isEmployee" style="padding-top:10px ;" >
                <mat-label >Are you an Employee trying to reset your Password? <a href="javascript:void(0)" (click)="switchToEmployee()" >Click here</a> </mat-label>
              </div>
              <form class="form">
                <div class="form-group margin-top-lg text-align-left">
                  <mat-label class="required">Email ID</mat-label>
                  <input type="email" id="forgot-password-emailId" class="form-control top-10" [(ngModel)]="emailId" name="emailId" #email="ngModel" 
                  #userId autocomplete="off"  (blur)="getCompanies(userId.value)" placeholder="Email ID"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" required>
                  <div class="alert alert-danger" *ngIf="email.errors?.required && (email.dirty || email.touched)">
                    Email ID is required
                  </div>
                  <div class="alert alert-danger" [hidden]="!email.errors?.pattern">
                    Invalid E-mail!
                  </div>
                </div>
                <div class="form-group margin-top-lg text-align-left" *ngIf="isEmployee">
                  <mat-label class="required">Associated Companies</mat-label>
                  <select class="form-control top-10" title="" [(ngModel)]="companyName"  #companyNameErr="ngModel"
                      (ngModelChange)="selectCompany($event)" [ngModelOptions]="{standalone: true}" [disabled]="!companyList" required>
                    <option disabled [selected]="true" value="">Select Company</option>
                    <option *ngFor='let company of companyList' [ngValue]="company">{{company.nameOfTheCompany}}
                    </option>
                  </select>
                  <div class="alert alert-danger">
                    <div *ngIf="companyNameErr.errors?.required">
                      Choose associated company
                    </div>
                    <div *ngIf="companyNameErr.errors?.touched || companyNameErr.errors?.dirty">
                      Choose associated company
                    </div>
                  </div>
                </div>
                <div class="form-group margin-top-lg">
                  <button mat-raised-button id="send" color="success" type="submit" (click)="sendEmail()" [disableRipple]="true"
                  [disabled]="email.invalid"
                   class="width-100 submit-button">Send</button>
                </div>
              </form>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-md-2 col-lg-3"></div>
  </div>
</section>
<app-footer-component></app-footer-component>
