import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../authentication.service";
import { UtilityService } from "../services/utilities.service";

@Injectable()
export class CanShowFaq implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if(JSON.parse(sessionStorage.getItem('userDetails')).company.showFaq){
        return true
    }
    else {
        this.router.navigateByUrl("/landing/dashboard/401");
        return false
    }
  }
}

@Injectable()
export class ShouldShowPage implements CanActivate{
  constructor(private router: Router, private readonly utilityService: UtilityService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const queryParam = route.params
    let loggedInEmployeeId = this.utilityService.getValue('empId')
    loggedInEmployeeId = loggedInEmployeeId == undefined ? localStorage.getItem('empId') : loggedInEmployeeId
    const isUserEmployee = localStorage.getItem('userRole') == "Employee"
    // const isUserAdmin = localStorage.getItem('userRole') == "Admin"
    if(!isUserEmployee || queryParam && queryParam['id'] == loggedInEmployeeId){
        return true
    }
    else {
        this.router.navigateByUrl("/landing/dashboard/401");
        return false
    }
  }
}