import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IncorporationDetailsModel } from './incorporation-details.model';
import { ShareholderDetailsModel } from './shareholder-details.model';
import { AuthenticationService } from '../../../../src/app/authentication.service';
import { HelperService } from '../../services/helper.service';
import { UtilityService } from '../../services/utilities.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../services/new-round-notify.service';
import { CacheProviderService } from '../../services/cache-provider.service';
import { NavbarService } from '../../services/navbar-reload';
import { ConfirmDeleteComponent } from 'src/app/confirm-delete/confirm-delete.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-incorporation-details',
  templateUrl: './incorporation-details.component.html',
  styleUrls: ['./incorporation-details.component.scss']
})

export class IncorporationDetailsComponent implements OnInit {

  public errorFlags;
  public model: IncorporationDetailsModel = new IncorporationDetailsModel();
  public areAllShareholdersSelected = false;

  public helper: string = "";
  public helpSections;
  public helperTopPosition = null;
  public openUploadDialog: boolean = false;

  public currencyList: any;
  public currentEditableRow = 0;

  public authCapitalValid: boolean = false;
  public parValueValid: boolean = false;
  public companyCurrencyValid: boolean = false;

  public isValid: boolean = false;
  public userDetails;
  public mParValue: any = 0;
  public mAuthCapital: any = 0;
  public noOfShares: any = 0;
  public incorporationDocs = null;
  public selectedDocumentsId = [];
  public selectAll:boolean = false;
  public sName:boolean = false;
  public nShare:boolean = false;

  constructor(private utilityService: UtilityService,
    private helperService: HelperService,
    private router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private dataService: DataService,
    private cacheService: CacheProviderService,
    private navbarService: NavbarService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadJSStyles();
    this.initializeDetails();
    this.currencyList = this.utilityService.currencyList();

    this.getIncorporationDocuments();

    this.authService.getIncorporationDetails().subscribe(
      (res: any) => {
        if (res) {
          this.model = res;
          if (this.model) {
            if (this.model.companyCurrency == null) {
              this.model.companyCurrency = "INR - ₹";
            } else {
              this.model.companyCurrency = res['companyCurrency'];
            }
          }
          else {
            this.model = new IncorporationDetailsModel()
            this.model.companyCurrency = "INR - ₹";
          }

          if (this.model.shareholders == null) {
            this.model.shareholders = [];
          }
          this.model.parValue = this.utilityService.formatCurrency(this.model.companyCurrency, res['parValue']);
          this.model.authorizedShareCapital = this.utilityService.formatCurrency(this.model.companyCurrency, res['authorizedShareCapital']);

          this.model.shareholders.forEach(element => {
            let re = element.numberOfShares;
            element['numberOfShares'] = this.utilityService.formatCurrency(this.model.companyCurrency, re);
          });
        } else {
          this.toastr.error("Null response!.", "Failure!");
        }
      }, (e) => {
        if (e.error.reason === "Login Token Expired.") {
          this.cacheService.clear();
          this.router.navigate(['']);
          return;
        } else {
          this.toastr.error(e.error.reason, "Failure!");
        }
      });
  }

  uploadIncorporationDocuments(){
    this.openUploadDialog = false;
    document.getElementById("overlay").style.display = "none";
    this.getIncorporationDocuments();
  }

  uploadDialog(){
    this.openUploadDialog = true;
    document.getElementById("overlay").style.display = "block";
  }

  selectAllDocuments(event){
    this.selectAll = event.checked;
    this.selectedDocumentsId = [];
    if(event.checked){
      this.incorporationDocs.forEach((item)=>{
        item.isSelected = true;
        this.selectedDocumentsId.push(item.id);
      });
    }
    else{
      this.incorporationDocs.forEach((item)=>{
        item.isSelected = false;
      });
    }
  }

  selectDocument(event, id){
      this.incorporationDocs.forEach((item)=>{
        if(item.id === id){
          if(event.checked){
            this.selectedDocumentsId.push(id);
          }
          else{
            const idx = this.selectedDocumentsId.indexOf(item.id);
            if(idx != -1){
              this.selectedDocumentsId.splice(idx, 1);
            }
          }
        }
      });
  }

  deleteSelectedDocuments(){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    if(this.selectedDocumentsId.length == 0){
      return;
    }
    this.ngxLoader.start();
    this.authService.deleteSelectedIncorporationDocs(this.selectedDocumentsId).subscribe(()=>{
      this.ngxLoader.stop();
      this.selectAllDocuments({checked: false});
      this.getIncorporationDocuments();
      this.selectAll = false;
      this.toastr.success("Successfully deleted.", "Success!");
    },(e)=>{
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!");
    });
    this.ngxLoader.stop();

      }
    });
  }

  getIncorporationDocuments(){
    this.ngxLoader.start();
    this.authService.getIncorporationDocuments().subscribe((response)=>{
      if(response != null){
        this.incorporationDocs = response;
        this.incorporationDocs.forEach((item)=>{
          item.isSelected = false;
        });
      }
      this.ngxLoader.stop();
    },(e)=>{
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!");
    });
  }

  initializeDetails() {
    this.model.authorizedShareCapital = null;
    this.model.companyCurrency = '';
    this.model.parValue = null;
    this.model.shareholders = [];
    //For Stepper Intialization
    let errorStatus = {
      adminProfileTab: "COMPLETED",
      companyDetailsTab: "COMPLETED",
      inCorporationTab: "INPROGRESS",
    }
    this.errorFlags = errorStatus;
    this.dataService.updateOnboarding(this.errorFlags);
  }

  addShareholder() {
    let length = this.model.shareholders.length;
    if (length > 0) {
      if (this.model.shareholders[length - 1].name === "" ||
        this.model.shareholders[length - 1].numberOfShares === 0) {
        this.toastr.error("Please fill previous row details", "Error!");
        return;
      }
    }
    let newShareholder = new ShareholderDetailsModel();
    newShareholder.name = '';
    newShareholder.numberOfShares = 0;
    newShareholder.totalInvestment = 0;
    newShareholder.holdingPercentage = 0;
    newShareholder.isSelected = false;
    newShareholder.isTotalInvestmentValid = true;
    this.model.shareholders.push(newShareholder);
    this.updateSelection();
    this.currentEditableRow = this.model.shareholders.length - 1;
  }

  selectAllShareholders() {
    if (this.areAllShareholdersSelected) {
      this.model.shareholders.forEach(shareholder => {
        shareholder.isSelected = true;
      });
    } else {
      this.model.shareholders.forEach(shareholder => {
        shareholder.isSelected = false;
      });
    }
    this.updateSelection();
  }

  selectShareholder() {
    this.updateSelection();
  }

  updateSelection() {
    let found = this.model.shareholders.find(shareholder => shareholder.isSelected == false);
    if (found || this.model.shareholders.length == 0) {
      this.areAllShareholdersSelected = false;
    } else {
      this.areAllShareholdersSelected = true;
    }
  }

  checkValidation() {
    this.utilityService.formValidation(); 
    if (this.model.authorizedShareCapital == null || this.model.parValue == null || this.model.companyCurrency == "") {
      if (this.model.authorizedShareCapital == null) {
        this.authCapitalValid = true;
      }
      if (this.model.parValue == null) {
        this.parValueValid = true;
      }
      if (this.model.companyCurrency == null) {
        this.companyCurrencyValid = true;
      }
      let incorporation = document.getElementById("Incorporation_Details_Card");
      this.utilityService.focusSection(incorporation);
      return false;
    }
    else {
      return true;
    }
  }

  proceedToDashboard() {
    this.isValid = this.checkValidation();
    if (this.isValid == false) {
      this.toastr.error("Please fill all the required fields!", "Error!");
      return;
    }
    if (this.model.shareholders.length == 0) {
      this.toastr.error("Please fill shareholder details!", "Error!");
      return;
    }
    else if (this.model.shareholders.length > 0) {
      let k = 0;
      for (k = 0; k < this.model.shareholders.length; k++) {
        if(this.model.shareholders[k].name === "" || this.model.shareholders[k].name == null ||
          this.model.shareholders[k].numberOfShares === 0 || this.model.shareholders[k].name == null){
            if(this.model.shareholders[k].name === "" || this.model.shareholders[k].name == null){
              this.sName = true;
            }
            if(this.model.shareholders[k].numberOfShares === 0 || this.model.shareholders[k].name == null){
              this.nShare = true;
            }
            let incorporation = document.getElementById("Incorporation_Details_Card");
            this.utilityService.focusSection(incorporation);
          return;
        }
      }
    }
    let foundInvalid = this.model.shareholders.find(shareholder => shareholder.isTotalInvestmentValid == false);
    if (foundInvalid) {
      this.toastr.error("Total Investment should be below or equal to Authorized Capital", "Error!");
    } else {
      this.ngxLoader.start();
      let authCapital = this.model.authorizedShareCapital;
      this.model.authorizedShareCapital = +authCapital.toString().replace(/,/g, "") / 1;
      this.model.parValue = +this.model.parValue.toString().replace(/,/g, "") / 1;

      this.model.shareholders.forEach(shareholder => {
        shareholder.numberOfShares = +shareholder.numberOfShares.toString().replace(/,/g, "") / 1;
      })

      this.authService.saveIncorporationDetails(this.model).subscribe(
        (res) => {
          if (res) {
            // this.cacheService.set("userDetails", JSON.stringify(res));
            this.getUserDetails();
            // this.router.navigate(['/landing', 'dashboard', 'capTable']);
            // this.navbarService.sendClickEvent();
            this.ngxLoader.stop();
          } else {
            this.ngxLoader.stop();
            this.toastr.error("Null response!.", "Failure!");
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      );
    }
  }


  deleteAllSelected() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    if (this.areAllShareholdersSelected) {
      this.model.shareholders = [];
    }
    else {
      let newArray = this.model.shareholders.filter(shareholder => shareholder.isSelected == false);
      this.model.shareholders = newArray;
    }
    this.updateSelection();
    this.calculateHoldingPercentage();
    this.ngxLoader.stop();

      }
    });
  }

  deleteIndividualSelected(index) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    this.model.shareholders.splice(index, 1);
    this.updateSelection();
    this.calculateHoldingPercentage();
    this.sName = false;
    this.nShare = false;
    this.ngxLoader.stop();

      }
    });
  }

  calculateHoldingPercentage() {
    if (this.model.parValue && this.model.authorizedShareCapital) {
      this.mParValue = this.model.parValue;
      let parValue = this.mParValue.toString().replace(/,/g, "") / 1;
      this.mAuthCapital = this.model.authorizedShareCapital;
      let authShareCapital = this.mAuthCapital.toString().replace(/,/g, "") / 1;

      this.model.shareholders.forEach(shareholder => {
        this.noOfShares = shareholder.numberOfShares;
        let numberOfShares = this.noOfShares.toString().replace(/,/g, "") / 1;
        if (parValue && numberOfShares) {
          shareholder.totalInvestment = parValue * numberOfShares;
          if (shareholder.totalInvestment > authShareCapital) {
            shareholder.isTotalInvestmentValid = false;
            this.toastr.error("Total Investment should be below or equal to Authorized Capital", "Error");
          }
          else {
            shareholder.isTotalInvestmentValid = true;
          }
          let tempList = this.model.shareholders;
          this.model.shareholders.forEach(shareholder => {
            let finalInvestment = 0;
            for (let index = 0; index < tempList.length; index++) {
              finalInvestment = finalInvestment + tempList[index].totalInvestment;
            }
            shareholder.holdingPercentage = (shareholder.totalInvestment / finalInvestment) * 100;
          });
        }
      });
    }
  }

  showHelp(section) {
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);

    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "IncorporationDetailsComponent",
      section
    );
    this.helper = section;
  }

  hideHelp() {
    this.helper = ""
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 101) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  editRow(rowIndex) {
    this.currentEditableRow = rowIndex;
  }

  saveRow(rowIndex) {
    if(this.model.shareholders[rowIndex].name === "" || this.model.shareholders[rowIndex].name == null ||
      this.model.shareholders[rowIndex].numberOfShares === 0 || this.model.shareholders[rowIndex].name == null){
        if(this.model.shareholders[rowIndex].name === "" || this.model.shareholders[rowIndex].name == null){
          this.sName = true;
        }
        if(this.model.shareholders[rowIndex].numberOfShares === 0 || this.model.shareholders[rowIndex].name == null){
          this.nShare = true;
        }
      return;
    }
    else{
      this.currentEditableRow = undefined;
      this.sName = false;
      this.nShare = false;
    }
  }

  loadJSStyles() {
    $('input.number').keyup(function (event) {
      // skip for arrow keys
      if (event.which >= 37 && event.which <= 40) return;
      // format number
      $(this).val(function (index, value) {
        let tmpValue = value.toString().replace(/\D/g, "");
        var lastThree = tmpValue.substring(tmpValue.length - 3);
        var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res;
      });
    });
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.model.companyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.model.companyCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  formatCurrency(value) {
    return this.utilityService.formatCurrencyDeci(this.model.companyCurrency, value);
  }

  // on input of Shares
  onChangeShare(index, val) {
    let re = val.replace(/,/g, "") / 1;
    if (this.model.companyCurrency === "INR - ₹") {
      this.model.shareholders[index]['numberOfShares'] = this.utilityService.convertToCompanyCurrency(re);
    }
    else {
      this.model.shareholders[index]['numberOfShares'] = this.utilityService.convertToRoundCurrency(re);
    }
  }

  authCapitalCSV(e){
    let rst;
    if(e) {
        // store current positions in variables
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let amt = this.model.authorizedShareCapital;
    rst = this.utilityService.formatCurrency(this.model.companyCurrency, amt);

    if(e) {
      if(e.target.value) {
          let CSVLength = rst.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = rst;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
              e.target.value = rst;
              if(start != 0 || end != 0){
                  let difference = valueLength - CSVLength;
                  start = start - difference;
                  end = end - difference;
              }
              else{
                  start = start;
                  end = end;
              }  
          }
          else {
              e.target.value = rst;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
    else{
      this.model.authorizedShareCapital = rst;
    }
  }

  parCSV(e){
    let rst;
    if(e) {
        // store current positions in variables
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let amt = this.model.parValue;
    rst = this.utilityService.formatCurrency(this.model.companyCurrency, amt);

    if(e) {
      if(e.target.value) {
          let CSVLength = rst.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = rst;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
              e.target.value = rst;
              if(start != 0 || end != 0){
                  let difference = valueLength - CSVLength;
                  start = start - difference;
                  end = end - difference;
              }
              else{
                  start = start;
                  end = end;
              }  
          }
          else {
              e.target.value = rst;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
    else{
      this.model.parValue = rst;
    }
  }

  updateCurrencyFormat(){
    this.model.authorizedShareCapital =  this.utilityService.formatCurrency(this.model.companyCurrency, this.model.authorizedShareCapital);
    this.model.parValue =  this.utilityService.formatCurrency(this.model.companyCurrency, this.model.parValue);
  }

  getUserDetails(){
    this.authService.getUserDetails().subscribe(
      (res) => {
        this.cacheService.set("userDetails", JSON.stringify(res));
        this.navbarService.sendClickEvent();
        setTimeout(() => {
          this.router.navigate(['/landing', 'dashboard', 'capTable']);
        }, 300);
      },
      e => {
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }
  
}
