import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.scss']
})
export class AccountConfirmComponent implements OnInit {

  public codes = "";
  public list:any;
  public userName:string = "";
  public emailAddress:string = "";
  public companyName:string = "";
  public isLinkExpired:boolean;
  public isCompleted:boolean = false;
  public varified:boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.codes = this.activatedRoute.snapshot.paramMap.get("code");
    console.log(this.codes);
    this.gotoCompanyDetails();

  }

  gotoCompanyDetails() {
    this.ngxLoader.start();
    this.authService.userVerification(this.codes).subscribe(
      res => {      
        this.list = res;
        this.varified = this.list.isVerified;
        this.isCompleted = true;
        if (this.varified) {
          this.companyName = this.list.companyName;
          this.userName = this.list.fullName;
          this.emailAddress = this.list.emailAddress;
          this.toastr.success("Verification is success","Success");      
          this.ngxLoader.stop();
        }
        else {
          this.isLinkExpired = true;
          this.toastr.error("Verification link is expired","Failure"); 
          this.ngxLoader.stop();
        }
      },(e) =>{
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason,"Failure"); 
        this.router.navigate(['/landing', 'onboarding', 'adminProfile']);
      })
  }



}
