
import { Component, OnInit , Inject , Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelperService } from '../services/helper.service';



@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    public helperService : HelperService,
    @Inject(MAT_DIALOG_DATA) 
    public data )
     {
  
    }


  
    useCase: string;
    ngOnInit():void
    {
      // this.useCase=this.helperService.useCase;

    }
  
}
