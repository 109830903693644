import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { MatCardModule, MatButtonModule, MatFormFieldModule, MatDividerModule, MatDatepickerModule, MatIconModule, MatButtonToggleModule, MatAutocompleteModule, MatInputModule } from '@angular/material';
import { DocumentEditorAllModule, DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxChartModule, DxTemplateModule } from 'devextreme-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonDialogsModule } from '../../dialogues/common-dialogs.module';

import { EsopVestComponent } from './esop-vest/esop-vest.component';
import { UpdateStatusComponent } from './update-status/update-status.component';
import { AnswerGrantComponent } from './answer-grant/answer-grant.component';
import { UpdateEsopVestComponent } from './update-esop-vest/update-esop-vest.component';
import { VestingTemplateCreationComponent } from './vesting-template-creation/vesting-template-creation.component';
import { EditEsopEmpComponent } from './edit-esop-emp/edit-esop-emp.component';
import { EsopExerciseComponent } from './esop-exercise/esop-exercise.component';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { ResignationPopupComponent } from '../../employees/resignation-popup/resignation-popup.component';
import { HttpClientModule } from '@angular/common/http';
import { UpdateNomineesComponent } from './update-nominees/update-nominees.component';
import { ViewPlanTemplateComponent } from './view-plan-template/view-plan-template.component';
import { ConfirmRedirectDialogComponent } from './confirm-redirect-dialog/confirm-redirect-dialog.component';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    EsopVestComponent,
    UpdateStatusComponent,
    AnswerGrantComponent,
    UpdateEsopVestComponent,
    VestingTemplateCreationComponent,
    EditEsopEmpComponent,
    EsopExerciseComponent,
    AddEmployeeComponent,
    ResignationPopupComponent,
    UpdateNomineesComponent,
    ViewPlanTemplateComponent,
    ConfirmRedirectDialogComponent,
  ],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDividerModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatInputModule,
    NgbModule,
    DxChartModule,
    ImageCropperModule,
    DxTemplateModule,
    CommonDialogsModule,
    DocumentEditorAllModule,
    DocumentEditorContainerModule
  ],
  exports:[
    EsopVestComponent,
    UpdateStatusComponent,
    AnswerGrantComponent,
    ViewPlanTemplateComponent,
    UpdateNomineesComponent,
    UpdateEsopVestComponent,
    VestingTemplateCreationComponent,
    MatCheckboxModule,
    EditEsopEmpComponent,
    EsopExerciseComponent,
    AddEmployeeComponent,
    ResignationPopupComponent,
    ConfirmRedirectDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmployeesAndEsopDialogsModule { }
