import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from '../authentication.service';
import { UtilityService } from '../services/utilities.service';

interface ReportDto {
  base64File: string;
  fileType: string;
  fileName: string;
}
@Component({
  selector: "app-financial-report",
  templateUrl: "./financial-report.component.html",
  styleUrls: ["./financial-report.component.scss"],
})
export class FinancialReportComponent implements OnInit {
  withVestingSchedule;
  vDateErr: Boolean = false;
  vestingDate;
  blobFinancialReportWithVesting: Promise<Blob>;
  blobFinancialReport: Promise<Blob>;

  constructor(
    public datepipe: DatePipe,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private dialogRef: MatDialogRef<FinancialReportComponent>
  ) {}

  ngOnInit() {
    this.withVestingSchedule = false;
  }

  checkValidation(withVestingSchedule, vestingDate) {
    if (vestingDate == undefined) {
      this.vDateErr = true;
      return;
    } else {
      this.downloadReport(withVestingSchedule, vestingDate);
    }
  }

  downloadReport(withVestingSchedule, vestingDate) {
    this.ngxLoader.start();
    let vesting_date = this.datepipe.transform(vestingDate, "yyy-MM-dd");
    if (withVestingSchedule) {
      this.authService.downloadFinancialReport(vesting_date).subscribe(
        (res: ReportDto) => {
          const blobFinancialReportWithVesting = this.utilityService.b64toBlob(
            res.base64File,
            res.fileType
          );
          this.utilityService.downloadBlobFile(
            blobFinancialReportWithVesting,
            res.fileName
          );

          this.dialogRef.close();
          this.ngxLoader.stop();
        },
        (e) => {
          this.dialogRef.close();
          this.ngxLoader.stop();
          this.toastr.error(e.reason, "Warning");
        }
      );
    } else {
      this.authService
        .downloadFinancialReportWithOutSchedule(vesting_date)
        .subscribe(
          (res: ReportDto) => {
            const blobFinancialReport = this.utilityService.b64toBlob(
              res.base64File,
              res.fileType
            );
            this.utilityService.downloadBlobFile(
              blobFinancialReport,
              res.fileName
            );
            this.dialogRef.close();
            this.ngxLoader.stop();
          },
          (e) => {
            this.dialogRef.close();
            this.ngxLoader.stop();
            this.toastr.error(e.reason, "Warning");
          }
        );
    }
  }
}
