import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utilities.service';
import { CacheProviderService } from '../../services/cache-provider.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss']
})
export class OtpVerifyComponent implements OnInit {

  public otp:string = "";
  xUserToken;
  counter;

  constructor(
    private authService:AuthenticationService, 
    private router:Router,
    private toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    private readonly utilityService: UtilityService,
    private cacheService: CacheProviderService,
    private permService: PermissionsService,
    private ngxLoader: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    this.startCountdown(60);
  }

  resendOtp() {
    if(this.counter != 0 ) {
      return 
    }
    this.startCountdown(60)
    this.authService.login(localStorage.getItem("emailId"), localStorage.getItem("password")).subscribe(
      (res) => {
        if(!res){
          this.toastr.error("Unable to login due to some technical issues.","Failure")
          return
        }
      }, 
      (e) => {
          this.toastr.error(e.error.reason, "Error");
      })
  }

  submitOtp() {
      let reqObj = {
        "otp" : this.otp,
        "emailId" : localStorage.getItem("emailId"),
        "password" : localStorage.getItem("password")
      }
      this.ngxLoader.start();
      this.authService.verifyOtp(reqObj).subscribe(
        (res) => {
          this.ngxLoader.stop();
          if(!res){
            this.toastr.error("Unable to login due to some technical issues.","Failure")
            return
          }
          this.xUserToken = res.headers.get('x-user-token');
          localStorage.setItem('currentUser', this.xUserToken);
          localStorage.setItem('emailId', res.body["emailId"]);
          localStorage.setItem('signintype', 'normal');
          res.body['authorities'] && res.body['authorities'][0] && res.body['authorities'][0]['name'] && localStorage.setItem('userRole', res.body['authorities'][0]['name']);
          if(res && res.body['company'] && res.body['company']['integration']){
            localStorage.setItem('hrmsIntegrationStatus', res.body['company']['integration']['status']);
          }
          this.redirectToNext();
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error, "Error");
        }
      );
  }

  redirectToNext() {
    this.authService.getUserDetails().subscribe(
      (res) => {
        this.cacheService.set("userDetails", JSON.stringify(res));
        if (res['company'] != null && res['incorporationDetails'] != null) {  
            localStorage.setItem('companyId', res['company']['id']);
            this.permService.navigateBasedOnPermission();
        }
        else if(res['company'] && res['company']['isEsopOnlyCompany']){
          this.router.navigate(['/landing', 'dashboard', 'options', 'overviewEsop']);
        }
        else {
            // onboarding navigation
            if( res['companiesAssociatedToUser'].length > 0 && res['isAllowedAccess'] == null) {
                this.router.navigate(['landing/onboarding'], { queryParams: { company: res['companiesAssociatedToUser'][0]['nameOfTheCompany'] } })
            } else
             if(res['authenticatorType'] != null && res['isVerified'] == false) {
                this.router.navigate(['/landing', 'onboarding', 'adminProfile']);
            } else if(res['authenticatorType'] != null && res['isVerified'] == true && res['company'] == null) {
                this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
            } else if (res['company'] != null && res['incorporationDetails'] == null) {
                this.router.navigate(['/landing', 'onboarding', 'create-historical-round']);
            } else {
                this.router.navigate(['landing/onboarding']);
            }
        }
      },
      e => {
        localStorage.clear();
        this.router.navigate(['']);
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  startCountdown(seconds) {
    this.counter = seconds;
      
    const interval = setInterval(() => {
      this.counter--;
        
      if (this.counter <= 0 ) {
        clearInterval(interval);
      }
    }, 1000);
  }

}
