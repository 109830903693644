import { PercentPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxTooltipComponent, DxTreeListComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { ColumnList } from './columns-list';
import { PermissionsService } from 'src/app/services/permissions.service';
import { HttpResponse } from '@angular/common/http';
import {MatMenuModule} from '@angular/material/menu';
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
  selector: 'app-dev-ex-cap-table',
  templateUrl: './dev-ex-cap-table.component.html',
  styleUrls: ['./dev-ex-cap-table.component.scss']
})
export class DevExCapTableComponent implements OnInit, OnChanges {
  @ViewChild('devExCapTable') dataGrid: DevExCapTableComponent;
  @Input() dataSource = [];
  @Input() displayCols: ColumnList;
  @Input() includeProforma: Boolean;
  @Input() incorporationDate: Date;
  @Input() pageType: string;
  @Input() pageData: string;
  @Input() isSubsidiary: Boolean
  @Input() subsidiaryRelation: string;
  @Input() subsidiaryName: string;
  @Input() subsidiaryCurrency: string;
  @Input() subsidiaryCompanyId: string;
  @Input() subsidiaryCurrentCompanyHoldingPercentage: number;
  @Input() subsidiaryHasAccess: Boolean;

  // @Input() overrideDefaultActionToolbar = false
  @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  expandAll = false;
  toolTipText: string = '';
  toolTipTarget: any;
  usrCompanyCurrency;
  shouldShowDialog: boolean;
  isPlan: boolean=false;
  selectedSecurity: any;
  currentDate : Date =new Date();
  noOfSharesFilter = [];
  parValueFilter = [];
  capitalValueFilter = [];
  ppShareFilter = [];
  netWorthFilter = [];
  perGainFilter = [];
  dilutionPerFilter = [];
  postMoneyFilter = [];
  preHoldingFilter = [];
  amountInvestedFilter = [];
  totalInvFilter = [];
  holdingFilter = [];
  roundsFilter = [];
  commonFilter = [];
  preferenceFilter = [];
  debentureFilter = [];
  warrantsFilter = [];
  fdbFilter = [];
  dt = null;
  searchOptions: Observable<string[]>;
  totalSearchOptions = [
    "For_Approval",
    "Approved",
    "Created",
    "Offered",
    "Granted",
    "Rejected",
  ];
  searchFormControl = new FormControl();
  grantDateFilter: {
    fromDate: moment.Moment | string;
    toDate: moment.Moment | string;
  } = { fromDate: undefined, toDate: undefined };

  constructor(
    private utilityService: UtilityService,
    private pp: PercentPipe,
    private router: Router,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private permService: PermissionsService,
    private activeRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService      
  ) { }

  ngOnInit() {
    // console.log(this.overrideDefaultActionToolbar)
    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.usrCompanyCurrency = userDetails['company']['companyCurrency'];
    this.searchOptions = this.searchFormControl.valueChanges.pipe(
      map((value) => this._filter(value))
    );
  }

  switchCompany(){   
    if(this.subsidiaryHasAccess) {
      this.eventEmitterService.onSwitchCompanyButtonClick(this.subsidiaryCompanyId);    
    } 
  }

  exportFile(reportType?) {

    if(this.pageType == 'RoundOverview') {
        // this.exportRoundOverview();
        if(reportType == 'round') {
          this.exportRoundBasedReport();
        }
    }

    if(this.pageType == 'ShareholderOVerview') {
        this.exportShareholdersOverview();
    }

    if(this.pageType == 'SecuritiesOverview') {
      if(reportType == 'security') {
        this.exportSecurityBasedReport();
      } else {
        this.exportSecuritiesOVerview();
      }
    }

    if(this.pageType == 'RoundDetails') {
      if(reportType == 'round') {
        this.exportRoundBasedReport();
      } else{
        this.exportIndividualRoundReport();
      }
    }

    else if(this.pageType == "Split Summary") {
      this.exportSplitSummary();
    }

    else if(this.pageType == "Securities") {
      this.exportSecurityReport();
    }
    else if(this.pageType == "Dashboard") {
      // this.exportShareholderReport();
      if(reportType == 'round') {
        this.exportRoundBasedReport();
      } else if(reportType == 'security') {
        if(this.isSubsidiary) {
          this.exportSubsidiarySecurityBasedReport();
        } else {  
          this.exportSecurityBasedReport();
        }
      }
    }
  }
  searchEntered(e) {
    this.treeList.instance.searchByText(e);
  }
  expandColumns() {
    this.expandAllItem()
  }
  exportCaptableReport(reportType) {
    this.exportFile(reportType)
  }

  exportQuickRoundReport() {
    this.authService.exportQuickRoundReport().subscribe(
      (res) => {
        this.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Error');
      }
    );
  }

  exportQuickRoundPAS4() {
    this.authService.exportQuickRoundPAS4().subscribe(
      (res) => {
        this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Error');
      }
    );
  }

  exportRoundBasedReport() {
    if(this.isSubsidiary) {
      this.authService.exportSubsidiaryRoundBasedReport(this.dt, this.pageData, this.subsidiaryCompanyId).subscribe(
        (res) => {
          this.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
          this.ngxLoader.stop();
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.reason, 'Error');
        }
      );      
    } else {
      this.authService.exportRoundBasedReport(this.dt, this.pageData).subscribe(
        (res) => {
          this.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
          this.ngxLoader.stop();
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.reason, 'Error');
        }
      );
    }
  }

  exportSubsidiarySecurityBasedReport() {
    this.authService.exportSubsidiarySecurityBasedReport(this.dt, this.subsidiaryCompanyId).subscribe(
      (res) => {
        this.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Error');
      }
    );
  }

  exportSecurityBasedReport() {
    this.authService.exportSecurityBasedReport(this.dt).subscribe(
      (res) => {
        this.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Error');
      }
    );
  }

  exportIndividualRoundReport() {
    this.authService.exportIndividualRoundReport(this.pageData).subscribe((res: HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    }, e => {
      this.ngxLoader.stop();
      this.toastr.error(e.reason, 'Error');
    });
  }

  exportRoundOverview() {
    this.ngxLoader.start();
    this.authService.exportRoundOverview().subscribe(
        (res: HttpResponse<any>) => {
            this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
            this.ngxLoader.stop();
        },
        (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.reason, 'Error');
        }
    );
  }

  exportShareholdersOverview() {
    this.ngxLoader.start();
    this.authService.exportShareholdersOverview().subscribe(
        (res: HttpResponse<any>) => {
            this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
            this.ngxLoader.stop();
        },
        (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.reason, 'Error');
        }
    );
  }

  exportSecuritiesOVerview() {
    this.ngxLoader.start();
    this.authService.exportSecuritiesOverview().subscribe(
        (res: HttpResponse<any>) => {
            this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
            this.ngxLoader.stop();
        },
        (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.reason, 'Error');
        }
    );
  }

  exportSplitSummary() {
    this.authService.exportSplitTransactionReport(this.dataSource).subscribe((res: HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    }, e => {
      this.ngxLoader.stop();
      this.toastr.error(e.reason, 'Error');
    });
  }

  exportSecurityReport() {
    this.authService.exportSecurityReport(this.pageData).subscribe((res: HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    }, e => {
      this.ngxLoader.stop();
      this.toastr.error(e.reason, 'Error');
    });
  }

  exportShareholderReport() {
    if(this.isSubsidiary) {
      this.authService.downloadSubsidiaryExportFile(this.includeProforma, this.dt, this.subsidiaryCompanyId).subscribe((res: HttpResponse<any>) => {
        this.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      }, e => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Error');
      });
    } else  {
      this.authService.downloadExportFile(this.includeProforma, this.dt).subscribe((res: HttpResponse<any>) => {
        this.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      }, e => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Error');
      });
    }
  }

  exportPAS4File() {
    this.ngxLoader.start();
    this.authService.exportPAS4File(this.pageData).subscribe((res: HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    }, e => {
      this.ngxLoader.stop();
      this.toastr.error(e.reason, 'Error');
    });
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.setHeaderFilters();
  }

  refreshCapTable(dateEvent) {
    let dt = dateEvent.value.format('YYYY-MM-DD');
    this.dt = dt;
    if(this.isSubsidiary) {
      this.authService.getSubsidiaryDevExSource(this.includeProforma, dt, this.subsidiaryCompanyId).subscribe(
        (res) => {
            this.dataSource = JSON.parse(JSON.stringify(res));
            this.treeList.instance.refresh();
        },
        (e) => {
            this.toastr.error(e.error.reason, "Failure!");
        }
      );
    } else {
      this.authService.getDevExSource(this.includeProforma, dt).subscribe(
        (res) => {
            this.dataSource = JSON.parse(JSON.stringify(res));
            this.treeList.instance.refresh();
        },
        (e) => {
            this.toastr.error(e.error.reason, "Failure!");
        }
      );
    }
  }

  showDebt(cellData) {
      if(cellData.data['isDebtShareholder'] == true && this.incorporationDate) {
        return true;
      } else {
        return false;
      }
  }

  downloadFile(fileObj, fileName, contentType) {

    
    let downloadLink: HTMLAnchorElement = document.createElementNS('http://www.w3.org/1999/xhtml', 'a') as HTMLAnchorElement;
    downloadLink.download = fileName;
    let blob = new Blob([fileObj], {type: contentType});
    let dataUrl: string = window.URL.createObjectURL(blob);
    downloadLink.href = dataUrl;
    let event: MouseEvent = document.createEvent('MouseEvent');
    event.initEvent('click', true, true);
    downloadLink.dispatchEvent(event);
    setTimeout((): void => {
        window.URL.revokeObjectURL(dataUrl);
        dataUrl = undefined;
    });
  }

  setHeaderFilters() {
    this.noOfSharesFilter = [];
    this.parValueFilter = []
    this.capitalValueFilter = [];
    this.ppShareFilter = [];
    this.netWorthFilter = [];
    this.perGainFilter = [];
    this.dilutionPerFilter = [];
    this.amountInvestedFilter = [];
    this.totalInvFilter = [];
    this.postMoneyFilter = [];
    this.preHoldingFilter = [];
    this.holdingFilter = [];
    this.roundsFilter = [];
    this.commonFilter = [];
    this.preferenceFilter = [];
    this.debentureFilter = [];
    this.warrantsFilter = [];
    this.fdbFilter = [];
    let dataSourceCopy = this.dataSource;
    let flatHolders = _.flattenDeep(dataSourceCopy);
    let noOfSharesArray = [];
    let parValueArray = [];
    let capitalValueArray = [];
    let ppShareArray = [];
    let netWorthArray = [];
    let postMoneyArray = [];
    let amountInvestedArray = [];
    let totalInvArray = []
    let perGainArray = [];
    let holdingArray = [];
    let roundsArray = [];
    let commonArray = [];
    let preferenceArray = [];
    let debenturesArray = [];
    let warrantsArray = [];
    let fdbArray = [];
    flatHolders.forEach(element => {
        noOfSharesArray.push(element['totalShares']);
        parValueArray.push(element['parValue']);
        capitalValueArray.push(element['capitalValue']);
        ppShareArray.push(element['pricePerShare']);
        netWorthArray.push(element['netWorth']);
        postMoneyArray.push(element['postMoneyValuation']);
        perGainArray.push(element['gainPercenatage']);
        holdingArray.push(element['holdingPercentage']);
        roundsArray.push(element['roundDetails']);
        commonArray.push(element['commonShares']);
        preferenceArray.push(element['preferenceShares']);
        debenturesArray.push(element['debentureShares']);
        warrantsArray.push(element['warrants']);
        fdbArray.push(element['totalDilutedShares']);
        amountInvestedArray.push(element['amountInvested']);
        totalInvArray.push(element['totalInvestment']);
    });
    this.createNoOfSharesFilter(noOfSharesArray);
    this.createPARValueFilter(parValueArray);
    this.createCapitalValueFilter(capitalValueArray);
    this.createPPShareFilter(ppShareArray);
    this.createNetWorthFilter(netWorthArray);
    this.createPostMoneyFilter(postMoneyArray);
    this.createPerGainFilter(perGainArray);
    this.createHoldingFilter(holdingArray);
    this.createDilutionPerFilter();
    this.createPreHoldingFilter();
    this.createAmtInvestedFilter(amountInvestedArray);
    this.createTotalInvFilter(totalInvArray);
    this.createRoundsFilter(roundsArray);
    this.createCommonFilter(commonArray);
    this.createPreferenceFilter(preferenceArray);
    this.createDebentureFilter(debenturesArray);
    this.createWarrantsFilter(warrantsArray);
    this.createFdbFilter(fdbArray);
  }

  getIntervals(min, max, nInt) {
    let round = this.range(Math.abs(min) > Math.abs(max) ? min : max);
    let max1 = max/round;
    let max2 = Math.ceil(max1)*round;
    
    let min1 = min/round;
    let min2 = Math.floor(min1)*round;

    return this.dividing_into_parts(max2,min2);
  }

  range(d) {
    let i = 1;
    while(Math.floor(d) != 0) {
        d = d/10;
        i = i*10;
    }
    return i/10;
  }

  dividing_into_parts(max0, min0) {
    let diff = (max0 - min0)/5;
    let min = min0;
    let max = min0 + diff;
    let intervals = [];
    while(max <= max0) {
        if(min == max){
            // console.log("Both are in same range");
            break;
        }
        else if(max < 0) {
            intervals.push([min, (max-1)]);
            // console.log(min + " <-> " + (max-1));
        }
        else if(max >= 0 && min <= 0){
            intervals.push([min, max]);
            // console.log(min + " <-> " + max);
        }
        else {
            intervals.push([(min+1), max]);
            // console.log(min+1 + " <-> " + max);
        }
        min = max;
        max = max + diff;
    }
    return intervals;
  }

  createNoOfSharesFilter(noOfSharesArray) {
    let noOfSharesMax = Math.max(...noOfSharesArray);
    let noOfSharesMin = Math.min(...noOfSharesArray);
    let noOfSharesFilterArray;
    if(noOfSharesMax > noOfSharesMin) {
        noOfSharesFilterArray = this.getIntervals(noOfSharesMin, noOfSharesMax, 5);
    } else {
        noOfSharesFilterArray = [[noOfSharesMin, noOfSharesMax]];
    }
    noOfSharesFilterArray.forEach(element => {
        let noOfSharesFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['totalShares', '>=', element[0]],
                ['totalShares', '<=', element[1]]
            ]
        }
        this.noOfSharesFilter.push(noOfSharesFilterObj);
    });
  }

  createPARValueFilter(parValueArray) {
    let parValueMax = Math.max(...parValueArray);
    let parValueMin = Math.min(...parValueArray);
    let parValueFilterArray;
    if(parValueMax > parValueMin) {
        parValueFilterArray = this.getIntervals(parValueMin, parValueMax, 5);
    } else {
        parValueFilterArray = [[parValueMin, parValueMax]];
    }
    parValueFilterArray.forEach(element => {
        let parValueFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['parValue', '>=', element[0]],
                ['parValue', '<=', element[1]]
            ]
        }
        this.parValueFilter.push(parValueFilterObj);
    });
  }

  createCapitalValueFilter(capitalValueArray) {
    let capitalValueMax = Math.max(...capitalValueArray);
    let capitalValueMin = Math.min(...capitalValueArray);
    let capitalValueFilterArray;
    if(capitalValueMax > capitalValueMin) {
        capitalValueFilterArray = this.getIntervals(capitalValueMin, capitalValueMax, 5);
    } else {
        capitalValueFilterArray = [[capitalValueMin, capitalValueMax]];
    }
    capitalValueFilterArray.forEach(element => {
        let capitalValueFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['capitalValue', '>=', element[0]],
                ['capitalValue', '<=', element[1]]
            ]
        }
        this.capitalValueFilter.push(capitalValueFilterObj);
    });
  }

  createPPShareFilter(ppShareArray) {
    let ppShareMax = Math.max(...ppShareArray);
    let ppShareMin = Math.min(...ppShareArray);
    let ppShareFilterArray;
    if(ppShareMax > ppShareMin) {
        ppShareFilterArray = this.getIntervals(ppShareMin, ppShareMax, 5);
    } else {
        ppShareFilterArray = [[ppShareMin, ppShareMax]];
    }
    ppShareFilterArray.forEach(element => {
        let ppShareFilterObj = {
            text: element[0] + '-' + element[1],
            value: [
                ['pricePerShare', '>=', element[0]],
                ['pricePerShare', '<=', element[1]]
            ]
        }
        this.ppShareFilter.push(ppShareFilterObj);
    });
  }

  createNetWorthFilter(netWorthArray) {
    let netWorthMax = Math.max(...netWorthArray);
    let netWorthMin = Math.min(...netWorthArray);
    let netWorthFilterArray;
    if(netWorthMax > netWorthMin) {
        netWorthFilterArray = this.getIntervals(netWorthMin, netWorthMax, 5);
    } else {
        netWorthFilterArray = [[netWorthMin, netWorthMax]];
    }
    netWorthFilterArray.forEach(element => {
        let netWorthFilterObj = {
            text: this.getTotalValueTble(element[0]) + '-' + this.getTotalValueTble(element[1]),
            value: [
                ['netWorth', '>=', element[0]],
                ['netWorth', '<=', element[1]]
            ]
        }
        this.netWorthFilter.push(netWorthFilterObj);
    });
  }

  createPostMoneyFilter(postMoneyArray) {
    let postMoneyMax = Math.max(...postMoneyArray);
    let postMoneyMin = Math.min(...postMoneyArray);
    let postMoneyFilterArray;
    if(postMoneyMax > postMoneyMin) {
        postMoneyFilterArray = this.getIntervals(postMoneyMin, postMoneyMax, 5);
    } else {
        postMoneyFilterArray = [[postMoneyMin, postMoneyMax]];
    }
    postMoneyFilterArray.forEach(element => {
        let postMoneyFilterObj = {
            text: this.getTotalValueTble(element[0]) + '-' + this.getTotalValueTble(element[1]),
            value: [
                ['postMoneyValuation', '>=', element[0]],
                ['postMoneyValuation', '<=', element[1]]
            ]
        }
        this.postMoneyFilter.push(postMoneyFilterObj);
    });
  }

  createPerGainFilter(perGainArray) {
    let perGainMax = Math.max(...perGainArray);
    let perGainMin = Math.min(...perGainArray);
    let perGainFilterArray;
    if(perGainMax > perGainMin) {
        perGainFilterArray = this.getIntervals(perGainMin, perGainMax, 5);
    } else {
        perGainFilterArray = [[perGainMin, perGainMax]];
    }
    perGainFilterArray.forEach(element => {
        let perGainFilterObj = {
            text: this.pp.transform(element[0], '2.2-2') + '-' + this.pp.transform(element[1], '2.2-2'),
            value: [
                ['gainPercenatage', '>=', element[0]],
                ['gainPercenatage', '<=', element[1]]
            ]
        }
        this.perGainFilter.push(perGainFilterObj);
    });
  }

  createPreHoldingFilter() {
    let preHoldingFilterArray = [
        [0, 5],
        [5, 10],
        [10, 20],
        [20, 30],
        [30, 50]
    ]
    preHoldingFilterArray.forEach(element => {
        let holdingFilterObj = {
            text: element[0] + '%' + ' - ' + element[1] + '%',
            value: [
                ['preHoldingPercentage', '>', element[0]/100],
                ['preHoldingPercentage', '<=', element[1]/100]
            ]
        }
        this.preHoldingFilter.push(holdingFilterObj);
    });
    let greaterThan50 = {
        text: '> 50%',
        value: [
            ['preHoldingPercentage', '>', 50/100]
        ]
    }
    this.preHoldingFilter.push(greaterThan50);
  }

  createDilutionPerFilter() {
    let dilutionPerFilterArray = [
        [0, 5],
        [5, 10],
        [10, 20],
        [20, 30],
        [30, 50]
    ]
    dilutionPerFilterArray.forEach(element => {
        let dilutionPerFilterObj = {
            text: element[0] + '%' + ' - ' + element[1] + '%',
            value: [
                ['dilutionPercentage', '>', element[0]/100],
                ['dilutionPercentage', '<=', element[1]/100]
            ]
        }
        this.dilutionPerFilter.push(dilutionPerFilterObj);
    });
    let greaterThan50 = {
        text: '> 50%',
        value: [
            ['dilutionPercentage', '>', 50/100]
        ]
    }
    this.dilutionPerFilter.push(greaterThan50);
  }

  createAmtInvestedFilter(amountInvestedArray) {
    let amtInvMax = Math.max(...amountInvestedArray);
    let amtInvMin = Math.min(...amountInvestedArray);
    let amtInvFilterArray;
    if(amtInvMax > amtInvMin) {
        amtInvFilterArray = this.getIntervals(amtInvMin, amtInvMax, 5);
    } else {
        amtInvFilterArray = [[amtInvMin, amtInvMax]];
    }
    amtInvFilterArray.forEach(element => {
        let amtInvFilterObj = {
            text: this.getTotalValueTble(element[0]) + '-' + this.getTotalValueTble(element[1]),
            value: [
                ['amountInvested', '>=', element[0]],
                ['amountInvested', '<=', element[1]]
            ]
        }
        this.amountInvestedFilter.push(amtInvFilterObj);
    });
  }

  createTotalInvFilter(totalInvArray) {
    let totInvMax = Math.max(...totalInvArray);
    let totInvMin = Math.min(...totalInvArray);
    let totInvFilterArray;
    if(totInvMax > totInvMin) {
        totInvFilterArray = this.getIntervals(totInvMin, totInvMax, 5);
    } else {
        totInvFilterArray = [[totInvMin, totInvMax]];
    }
    totInvFilterArray.forEach(element => {
        let totInvFilterObj = {
            text: this.getTotalValueTble(element[0]) + '-' + this.getTotalValueTble(element[1]),
            value: [
                ['totalInvestment', '>=', element[0]],
                ['totalInvestment', '<=', element[1]]
            ]
        }
        this.totalInvFilter.push(totInvFilterObj);
    });
  }

  createHoldingFilter(holdingArray) {
    let holdingFilterArray = [
        [0, 5],
        [5, 10],
        [10, 20],
        [20, 30],
        [30, 50]
    ]
    holdingFilterArray.forEach(element => {
        let holdingFilterObj = {
            text: element[0] + '%' + ' - ' + element[1] + '%',
            value: [
                ['holdingPercentage', '>', element[0]/100],
                ['holdingPercentage', '<=', element[1]/100]
            ]
        }
        this.holdingFilter.push(holdingFilterObj);
    });
    let greaterThan50 = {
        text: '> 50%',
        value: [
            ['holdingPercentage', '>', 50/100]
        ]
    }
    this.holdingFilter.push(greaterThan50);
  }

  createRoundsFilter(roundsArray) {
    if(roundsArray[roundsArray.length-1] == null) {
      roundsArray.pop();
    }
    let flatRounds = _.flattenDeep(roundsArray);
    let uniqueFlatRounds = _.uniqBy(flatRounds, 'roundName');
    uniqueFlatRounds.forEach(element => {
        let roundsFilterObj = {
            text: element['roundName'] ,
            value: element['roundIdentifier']
        }
        this.roundsFilter.push(roundsFilterObj);
    });
  }

  createCommonFilter(commonArray) {
    let commonMax = Math.max(...commonArray);
    let commonMin = Math.min(...commonArray);
    let commonFilterArray;
    if(commonMax > commonMin) {
        commonFilterArray = this.getIntervals(commonMin, commonMax, 5);
    } else {
        commonFilterArray = [[commonMin, commonMax]];
    }
    commonFilterArray.forEach(element => {
        let commonFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['commonShares', '>=', element[0]],
                ['commonShares', '<=', element[1]]
            ]
        }
        this.commonFilter.push(commonFilterObj);
    });
  }

  createPreferenceFilter(preferenceArray) {
    let preferenceMax = Math.max(...preferenceArray);
    let preferenceMin = Math.min(...preferenceArray);
    let preferenceFilterArray;
    if(preferenceMax > preferenceMin) {
        preferenceFilterArray = this.getIntervals(preferenceMin, preferenceMax, 5);
    } else {
        preferenceFilterArray = [[preferenceMin, preferenceMax]];
    }
    preferenceFilterArray.forEach(element => {
        let preferenceFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['preferenceShares', '>=', element[0]],
                ['preferenceShares', '<=', element[1]]
            ]
        }
        this.preferenceFilter.push(preferenceFilterObj);
    });
  }

  createDebentureFilter(debenturesArray) {
    let debenturesMax = Math.max(...debenturesArray);
    let debenturesMin = Math.min(...debenturesArray);
    let debenturesFilterArray;
    if(debenturesMax > debenturesMin) {
        debenturesFilterArray = this.getIntervals(debenturesMin, debenturesMax, 5);
    } else {
        debenturesFilterArray = [[debenturesMin, debenturesMax]];
    }
    debenturesFilterArray.forEach(element => {
        let debenturesFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['debentureShares', '>=', element[0]],
                ['debentureShares', '<=', element[1]]
            ]
        }
        this.debentureFilter.push(debenturesFilterObj);
    });
  }

  createWarrantsFilter(warrantsArray) {
    let warrantsMax = Math.max(...warrantsArray);
    let warrantsMin = Math.min(...warrantsArray);
    let warrantsFilterArray;
    if(warrantsMax > warrantsMin) {
        warrantsFilterArray = this.getIntervals(warrantsMin, warrantsMax, 5);
    } else {
        warrantsFilterArray = [[warrantsMin, warrantsMax]];
    }
    warrantsFilterArray.forEach(element => {
        let warrantsFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['warrants', '>=', element[0]],
                ['warrants', '<=', element[1]]
            ]
        }
        this.warrantsFilter.push(warrantsFilterObj);
    });
  }

  createFdbFilter(fdbArray) {
    let fdbMax = Math.max(...fdbArray);
    let fdbMin = Math.min(...fdbArray);
    let fdbFilterArray;
    if(fdbMax > fdbMin) {
        fdbFilterArray = this.getIntervals(fdbMin, fdbMax, 5);
    } else {
        fdbFilterArray = [[fdbMin, fdbMax]];
    }
    fdbFilterArray.forEach(element => {
        let fdbFilterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                ['totalDilutedShares', '>=', element[0]],
                ['totalDilutedShares', '<=', element[1]]
            ]
        }
        this.fdbFilter.push(fdbFilterObj);
    });
  }

  expandAllItem() {   
    if(this.expandAll == false) {
      this.treeList.instance.forEachNode((node) => {
          this.treeList.instance.expandRow(node.key);
      });
      this.expandAll = true;
    } else if(this.expandAll == true) {
      this.treeList.instance.forEachNode((node) => {
          this.treeList.instance.collapseRow(node.key);
      });
      this.expandAll = false;
    }
  }

  getTotalValueTble(value) {
    // value = 165455.17;
    // console.log('value.toString().length', value.toString().length);
    if(value > 0){
      if(value.toString().length > 5){
        if(value.toString().indexOf(".") > 0 && value.toString().length <= 8) {
          return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
        } else {
          return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
      }
      else{
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else{
      return 0;
    }
  }

  displayDetails(data) {
    if(!this.isSubsidiary) {
      if(this.permService.authorizeUser(['captable-summary','!strict'])  && data.individualDetailsURL){
        return this.toastr.error("You do not have the permission to access this page.","Failure!");
      }
      console.log('shareholder cell data: ', data);
      if (data.isPlan == true) {
          this.shouldShowDialog = true;
          this.utilityService.showOverlay()
          this.isPlan = true;
          let selectedInstCls = {
              'className': data.name,
              'gainPercentage': data.gainPercenatage,
              'isPlan': data.isPlan,
              'netWorth': data.netWorth,
              'numberOfShares': data.totalShares,
              'percentageOfHolding': data.holdingPercentage
          };
          this.selectedSecurity = selectedInstCls;
      } else if(data.isPlan == null && data.individualDetailsURL) {
        this.router.navigate(["/landing", "dashboard", "shareholders", "details", data.shareholderId]);
      } else if (!data.isPlan && data.isSecurityClass == true) {
        this.authService.getInstrumentClassDataByName(data.name)
          .subscribe(response => {
              console.log(response);
              if (response['instrument'] == "Equity") {
                this.shouldShowDialog = true;
                this.utilityService.showOverlay()
                this.isPlan = false;
                this.selectedSecurity = response;
              } else {
                this.shouldShowDialog = true;
                this.utilityService.showOverlay()
                this.isPlan = null;
                this.selectedSecurity = response;
              }
              this.ngxLoader.stop();
          }, (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.error.reason, "Failure!");
          })
      } else if(data.isSecurityType == true) {
          this.router.navigate(["/landing/dashboard/securities/instrumentClass", { className: data.name }]);
      }
    }
  }

  displayRoundDetails(data) {
      console.log('round name data: ', data);
      let rs = data.roundState;
        if (rs === "PROFORMA" || rs === "CONSTRUCTED") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'round']);
        }
        else if (rs === "ISSUE_IN_PROGRESS") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'summary']);
        }
        else if (rs === "PARTIALLY_ISSUED") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'capTable']);
        }
        else if (rs === "COMPLETED") {
            this.router.navigate(['/landing', 'dashboard', 'rounds', 'round-details', data.name]);
        }
  }

  getEvent(event) {
    this.shouldShowDialog = false;
    this.utilityService.hideOverlay()
    document.getElementById('overlay').style.display = 'none';
  }

  goToRoundDetails(roundIdentifier, rounds) {
    if(this.permService.authorizeUser(['captable-summary','!strict'])){
      return this.toastr.error("You do not have the permission to access this page.","Failure!");
    }
    let round = _.find(rounds, {'roundIdentifier': roundIdentifier});
    if(round.roundName.startsWith("Bonus") && round.roundIdentifier.startsWith("B")) {
      this.router.navigateByUrl("/landing/dashboard/bonus/overview");
    } else if(round.isOptionsType == false && round.roundIdentifier != 'BO' && round.roundIdentifier != 'ES') {
      this.router.navigate(["/landing", "dashboard", "rounds", "round-details", round.roundName]);
    }
  }

  goToDocument(data) {
      let obj = {
        "path": data.folderName
      }
      this.authService.checkIfPathExists(obj).subscribe(
        (res) => {
          this.router.navigate(["/landing", "dashboard", "documents", { folderName: data.folderName }]);
        },
        (e) => {
          this.router.navigate(["/landing", "dashboard", "documents"]);
        }
      );
      
  }

  showRoundTooltip(roundIdentifier, rounds, cellData) {
    if(this.incorporationDate) {
        let tooltip = '';
        let round = _.find(rounds, {'roundIdentifier': roundIdentifier});
        if(round['shareBreakdown']) {
            round['shareBreakdown'].forEach(element => {
                if(element['value'] != 0) {
                    tooltip = tooltip + element['key'] + ': ' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element['value']) + '\n';
                }
            });
        }
        return tooltip;
    } else {
        let round = _.find(rounds, {'roundIdentifier': roundIdentifier});
        return round.roundName;
    }
  }

  showSharesBreakdown(data: any, dataField) {
    let breakdownArray = [];
    let totalValue;
    let tooltip = '';
    if(dataField == 'commonShares') {
        breakdownArray = data['commonShareBreakdown'];
        totalValue = this.utilityService.formatCurrency(this.usrCompanyCurrency, data['commonShares']);
    } else if(dataField == 'preferenceShares') {
        breakdownArray = data['preferenceShareBreakdown'];
        totalValue = this.utilityService.formatCurrency(this.usrCompanyCurrency, data['preferenceShares']);
    } else if(dataField == 'debentureShares') {
        breakdownArray = data['debentureBreakdown'];
        totalValue = this.utilityService.formatCurrency(this.usrCompanyCurrency, data['debentureShares']);
    } else if(dataField == 'warrants') {
        breakdownArray = data['warrantsBreakdown'];
        totalValue = this.utilityService.formatCurrency(this.usrCompanyCurrency, data['warrants']);
    }
    if(breakdownArray) {
        breakdownArray.forEach(element => {
            if(element['value'] != 0) {
                tooltip = tooltip + element['key'] + ': ' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element['value']) + '\n';
            }
        });
        if(tooltip == '') {
            tooltip = '0';
        }
        return tooltip;
    } else {
        return totalValue;
    }
  }

  getRoundName(roundIdentifier, rounds) {
    let round = _.find(rounds, {'roundIdentifier': roundIdentifier});
    return round.roundName;
  }

  calculateRoundFilterExpression(value) {
    var column = this;
    console.log(value);
    if (value) {
        var selector = function (data) {
            var values = column.calculateCellValue(data);
            return values && values.indexOf(value) >= 0;
        };
        return [selector, "=", true];
    }
  }

  calculateCellValue(data) {
    return $.map(data['roundDetails'], function (o) {
        return o.roundIdentifier;
    });
  }

  identifierCSS(identifier) {
    switch (identifier) {
      case "FF":
        return identifier;
      case "SF":
        return identifier;
      case "AF":
        return identifier;
      case "ES":
        return identifier;
      case "SA":
        return identifier;
      case "SB":
        return identifier;
      case "SC":
        return identifier;
      case "AC":
        return identifier;
      case "SD":
        return identifier;
      case "SE":
        return identifier;
      case "AF1":
        return "Def1";
      case "AF2":
        return "Def2";
      case "AF3":
        return "Def3";
      case "SP":
        return "Def4";
      case "SG":
        return "Def5";
      case "SH":
        return "Def6";
      case "SA1":
        return "Def7";
      case "SA2":
        return "Def8";
      case "SA3":
        return "Def1";
      case "SB1":
        return "Def2";
      case "SB2":
        return "Def3";
      case "SP1":
        return "Def4";
      default:
        return "defaultClass";
    }
  }

  onCellHoverChanged(e: any) {
    if(e.value == null){
      return;
    }
    if (e.rowType === "data" && (e.column.dataField === "holdingPercentage" || e.column.dataField === "dilutionPercentage" || e.column.dataField === "preHoldingPercentage" || e.column.dataField === "gainPercenatage") && e.eventType === "mouseover") {
      this.toolTipText = this.pp.transform(e.text, '2.2-2');
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && (e.column.dataField === "totalShares" || e.column.dataField === "debentureShares" ||
         e.column.dataField === "preferenceShares" || e.column.dataField === "commonShares" || e.column.dataField === "preTotalShares" ||
         e.column.dataField === "warrants" || e.column.dataField === "totalDilutedShares" || 
         e.column.dataField == "amountInvested" || e.column.dataField == "postMoneyValuation" || 
         e.column.dataField == "totalInvestment" || e.column.dataField == "commonSharesPost" ||
         e.column.dataField == "preferenceSharesPost" || e.column.dataField == "totalSharesPost" ||
         e.column.dataField === "pricePerShare" || e.column.dataField === "netWorth") && e.eventType === "mouseover") {
        if(this.incorporationDate && (e.column.dataField === "debentureShares" ||
            e.column.dataField === "preferenceShares" || e.column.dataField === "commonShares" ||
            e.column.dataField === "warrants")) {
            this.toolTipText = this.showSharesBreakdown(e.data, e.column.dataField);
        } else if (e.displayValue === 0) {
            this.toolTipText = e.text;
        }
        else if(e.displayValue == null){
          this.toolTipText = "0";
        }
        else {
            this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, e.displayValue);
        }
        this.toolTipTarget = e.cellElement;
        this.tooltip.instance.show();
    }
    if(e.rowType === "data" && (e.column.dataField === "closingDate" || e.column.dataField === "leadInvestorName" || e.column.dataField === "name") && e.eventType === "mouseover"){
      this.toolTipText = e.text;
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && e.eventType === "mouseout") {
      this.tooltip.instance.hide();
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return []
    if (this.searchFormControl.value.length < 1) return [];
    return this.totalSearchOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

}
