<div>
  <mat-label>Plan Document</mat-label>
  <div>
    <table>
      <tr>
        <td>
          <span>
            Choose file:
          </span>
          <button (click)=planDocument.click() class="cursor-hand" ngbTooltip="Upload" style="padding-left: 10px;" mat-icon-button><mat-icon [svgIcon]="'action_upload'"></mat-icon></button>
          <span *ngIf="fileName">
            {{fileName}}
          </span>
        </td>
      </tr>
    </table>
  </div>
  <input id="planDocument" type="file" hidden #planDocument (change)="fileChangeEvent($event)" accept="application/pdf">
</div>
<div>
  <div mat-dialog-actions align="end">
    <button mat-raised-button style="background-color:#CD4927" color="primary" value="export" (click)="Upload()" cdkFocusInitial>Upload</button>
    <button mat-raised-button color="secondary" [mat-dialog-close]="'no'">Cancel</button>
  </div>

</div>