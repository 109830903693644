<div class="container-fluid">
    <div id="overlay"></div>
    <div class="row">
        <div class="col-md-12">
            <h5 class="page-hdr btm-15">File Converter</h5>
            <p class="content-pg-1 btm-20">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book.</p>
        </div>
    </div>
    <div class="row btm-20">
        <div class="col-md-6">
            <mat-label>Select PDF</mat-label>
            <div class="display-grid">
                <input class="form-control top-10 width-70" type="file" accept="application/pdf" (change)="handleFileInput($event)">
                <button mat-raised-button id="convertToXml" color="success" class="float-right cmn-button top-10"
                                    (click)="convertToXML()" [disableRipple]="true" ngbTooltip="Convert"> Convert to XML </button>
            </div>
        </div>
    </div>
    <div class="row btm-20">
        <div class="col-md-6">
            <mat-label>Select excel</mat-label>
            <div class="display-grid">
                <input class="form-control top-10 width-70" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" (change)="handleEXCELFileInput($event)" required>
                <button mat-raised-button id="generate" color="success" class="float-right cmn-button top-10"
                        (click)="convertToPDF()" [disableRipple]="true" ngbTooltip="Generate"> Generate </button>
            </div>
        </div>
    </div>
</div>