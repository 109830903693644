import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;
import { AuthenticationService } from "../../../authentication.service";
import { UtilityService } from "../../../services/utilities.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { Vesting } from "../../model/exercise";

@Component({
  selector: 'app-esop-vest',
  templateUrl: './esop-vest.component.html',
  styleUrls: ['./esop-vest.component.scss']
})
export class EsopVestComponent implements OnInit {
  @Input() vestDialog;
  @Input() esopPlanList;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ngOnInitParent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public vesting: Vesting;
  public vestings = [];
  public isShowNotes: boolean = false;
  isApplytoAll = false;
  noOfHolders;
  totalOptions;
  percentages;
  evntDt: boolean = false;
  elementLoaded: boolean = false;
  public vestedShares: any = "0";
  public unvestedShares: any = "0";


  constructor(private utilityService: UtilityService, private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService, private toastr: ToastrService) { }

  ngOnInit() {
    this.vesting = new Vesting();
    this.vesting.percentageOfSharesToVest = "0"
    if(this.vestDialog.isApprover){
      this.isShowNotes = true;
      let dt = new Date(this.vestDialog.details.eventDate);
      this.vesting.id = this.vestDialog.details.id;
      this.vesting.employeeName = this.vestDialog.details.employeeName;
      this.vesting.planName = this.vestDialog.details.planName;
      this.vesting.grantPrice = this.vestDialog.details.grantPrice;
      this.vesting.eventDate = dt.toISOString().replace("Z", "+0000");
      this.vesting.numberOfSharesToVest = this.vestedShares;
      this.vesting.eventName = this.vestDialog.details.eventName;
      this.vesting.isApplyToAllHolders = this.isApplytoAll;
      this.vesting.percentageOfSharesToVest = this.vestDialog.details.percentageOfSharesToVest;
    }
  }

  ngDoCheck() {
    if (!this.elementLoaded && $('[data-toggle="switch"]').length > 0) {
      this.elementLoaded = true;
      this.loadJSstyles();
    }
  }

  onChangeInput(ele) {
    let tmpVal = ele.value.replace(/%$/, '');
    let tmp = tmpVal / 1;
    if (tmp != null && tmp <= 100) {
      this.getNoShares(tmp/100);
      return tmp / 100;
    }
    else {
        return ele.value = 0;
    }
  }

  getNoShares(value) {
    this.percentages = value;
    if (value) {
    this.authService.getNoOfSharestoVest(this.vestDialog.details.id, value , this.vestDialog.details.eventName).subscribe((response) => {
        if (response != null) {
        this.vestedShares = response['numbeOfSharesVested'];
        this.unvestedShares = response['numberOfSharesUnvested'];
          if (this.isApplytoAll) {
              this.getAppliedtoAllHolders();
          }
        }
        else {
          this.toastr.error("Null response!", "Failure");
        }
    }, (e) => {
        this.toastr.error(e.error.reason, "Failure!")
    })
    } else {
    this.vestedShares = 0;
    }
  }

  // when return unvested options to the pool tggled to yes
  getAppliedtoAllHolders() {
    if (this.isApplytoAll) {
      if (this.percentages == undefined) {
        this.toastr.warning("Please enter % of options to be vested", "Warning !");
        return;
      }
      else {
        this.authService.getOptionsAllHolders(this.vestDialog.details.id, this.percentages).subscribe((response) => {
          if (response) {
            this.noOfHolders = response['numberOfHolders'];
            this.totalOptions = response['numberOfOptions'];
          } else {
            this.toastr.error("Null response!", "Failure");
          }
        }, (e) => {
          this.toastr.error(e.error.reason, "Failure!")
        })
      }
    }
    else {
      this.noOfHolders = 0;
      this.totalOptions = 0;
    }
  }

  loadJSstyles() {
    $('[data-toggle="switch"]').bootstrapSwitch({
      onText: "Yes",
      offText: "No",
    });
    $('.alert-status-allHolders').bootstrapSwitch('state', this.isApplytoAll);
    $("#allHolders").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
      this.isApplytoAll = state;
      this.getAppliedtoAllHolders();
    });
  }


  saveExercise() {
    if (this.vesting.percentageOfSharesToVest == null ||
        (this.vesting.percentageOfSharesToVest != null &&
          (typeof this.vesting.percentageOfSharesToVest == "string" && (this.vesting.percentageOfSharesToVest == "0" || this.vesting.percentageOfSharesToVest == "0.0")) ||
          (typeof this.vesting.percentageOfSharesToVest == "number" && (this.vesting.percentageOfSharesToVest == 0 || this.vesting.percentageOfSharesToVest == 0.0))
        )
      ){

        if (this.vesting.eventDate == undefined) {
          this.evntDt = true;
        }

        this.toastr.warning("Please fill all Mandatory Fields", "Warning !");
        return;
    }
    if(this.vestedShares <= 0 && !this.vestDialog.isApprover){
      this.toastr.warning("Vesting share should not be zero", "Warning !");
      return;
    }
    this.vestings = [];
    let dt = new Date(this.vestDialog.details.eventDate);
    this.vesting.id = this.vestDialog.details.id;
    this.vesting.employeeName = this.vestDialog.details.employeeName;
    this.vesting.planName = this.vestDialog.details.planName;
    this.vesting.grantPrice = this.vestDialog.details.grantPrice;
    this.vesting.eventDate = dt.toISOString().replace("Z", "+0000");
    this.vesting.numberOfSharesToVest = this.vestedShares;
    this.vesting.eventName = this.vestDialog.details.eventName;
    this.vesting.isApplyToAllHolders = this.isApplytoAll;
    if(this.vestDialog.isApprover){
      this.vesting.optionState = "Vested";
    }

    let data = {
      "id": this.vesting.id,
      "employeeName": this.vesting.employeeName,
      "planName": this.vesting.planName,
      "grantPrice": this.vesting.grantPrice,
      "eventDate": this.vesting.eventDate,
      "numberOfSharesToVest": this.vesting.numberOfSharesToVest,
      "percentageOfSharesToVest": this.vesting.percentageOfSharesToVest,
      "isResetUnvestedShares": this.vesting.isApplyToAllHolders,
      "eventName": this.vesting.eventName,
      "note": this.vesting.note
    }

    this.ngxLoader.start();
    this.authService.doVesting(data, this.vestDialog.isApprover).subscribe((response) => {
      if (response) {
        this.ngxLoader.stop();
        this.vestDialog.show = false;
        this.visibleChange.emit(this.vestDialog.show);
        this.ngOnInitParent.emit();
      } else {
        this.ngxLoader.stop();
        this.toastr.error("Null response!", "Failure");
      }
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!")
    })
  }

  close() {
    this.vestDialog.show = false;
    this.visibleChange.emit(this.vestDialog.show);
  }
  addNotes() {
    this.isShowNotes = true;
  }
  removeNotes() {
    this.isShowNotes = false;
  }
  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }
  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 101) ? null : ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46);
  }
}
