<h1 mat-dialog-title>Confirm Approve?</h1>
<div mat-dialog-content>
  <p>Please verify that the following details are correct before approving</p>
  <div>
<table style="text-align:left;">
    <tr>
      <th hidden></th>
    <th hidden></th>
    </tr>
    <tr>
      <td> <mat-label class="card-hdr">Total Authorized</mat-label></td>
      <td class="data"><mat-label [ngbTooltip]="(data.total_auth | localeFormatter: 'tooltip-currency')" class="card-value">
        {{data.total_auth | localeFormatter: 'currency'}}
      </mat-label></td>
    </tr>
    <tr>
      <td><mat-label class="card-hdr">Total Paid Up</mat-label></td>
      <td class="data"> <mat-label [ngbTooltip]="(data.total_paid_up | localeFormatter: 'tooltip-currency')" class="card-value">
        {{data.total_paid_up | localeFormatter: 'currency'}}
      </mat-label></td>
    </tr>
    <tr>
      <td><mat-label class="card-hdr">Amount Raised</mat-label></td>
      <td class="data"><mat-label  [ngbTooltip]="(data.amt_raised | localeFormatter: 'tooltip-currency')" class="card-value">
        {{data.amt_raised | localeFormatter: 'currency'}}
      </mat-label></td>
    </tr>
    <tr>
      <td> <mat-label class="card-hdr">Total Shares</mat-label></td>
      <td class="data"><mat-label  [ngbTooltip]="(data.total_shares | localeFormatter: 'tooltip-number')" class="card-value">
        {{data.total_shares | localeFormatter: 'number'}}
      </mat-label></td>
    </tr>
    <tr>
      <td><mat-label class="card-hdr">No. of Shareholders</mat-label></td>
      <td class="data"><mat-label [ngbTooltip]="(data.shareholderCount | localeFormatter: 'tooltip-number')" class="card-value">
        {{data.shareholderCount | localeFormatter: 'number'}}
      </mat-label></td>
    </tr>
  </table>
  </div>

</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button id="cancel" color="secondary" [mat-dialog-close]="'no'">Cancel</button>
    <button mat-raised-button id="confirm" style="background-color:#CD4927" color="primary" [mat-dialog-close]="'confirm'" cdkFocusInitial>Confirm</button>
</div>