/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./base.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./base.component";
import * as i6 from "../../../authentication.service";
var styles_BaseComponent = [i0.styles];
var RenderType_BaseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BaseComponent, data: {} });
export { RenderType_BaseComponent as RenderType_BaseComponent };
function View_BaseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No account found for this user. "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please SignUp to create an account. "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 16777216, null, null, 2, "button", [["class", "cmn-button"], ["ngbTooltip", "Go Back"]], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i4.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i4.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵted(-1, null, ["Go Back"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 8, 0, "/"); _ck(_v, 7, 0, currVal_2); var currVal_3 = "Go Back"; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).target; var currVal_1 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); }); }
function View_BaseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" User account already exists. "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please login with your credentials. "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 16777216, null, null, 2, "button", [["class", "cmn-button"], ["ngbTooltip", "Go Back"]], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i4.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i4.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵted(-1, null, ["Go Back"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 8, 0, "/"); _ck(_v, 7, 0, currVal_2); var currVal_3 = "Go Back"; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).target; var currVal_1 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_BaseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "centered"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaseComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaseComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.failureMsg == "signinfailure"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.failureMsg == "signupfailure"); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BaseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-base", [], null, null, null, View_BaseComponent_0, RenderType_BaseComponent)), i1.ɵdid(1, 114688, null, 0, i5.BaseComponent, [i2.Router, i2.ActivatedRoute, i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaseComponentNgFactory = i1.ɵccf("app-base", i5.BaseComponent, View_BaseComponent_Host_0, {}, {}, []);
export { BaseComponentNgFactory as BaseComponentNgFactory };
