<div class="add-profile-info" aria-labelledby="addProfileInfo" aria-hidden="true">
    <div >
        <div class="shrholder-fund">
            <div mat-dialog-title class="dialogue-title">
                Shareholder Name : {{ shareholderDetails.shareholderDetailsDto.name }}
                <span align="end" style="float: right;">
                    <button style="color: black;" mat-button id="cancel" [mat-dialog-close]="'no'">X</button>
                </span>
            </div>
            <div class="">
                <div  mat-dialog-content>
                    <div class="row zui-wrapper">
                        <div class="col-md-3 pad-0">
                            <table class="table-fund">
                                <tbody>
                                    <tr>
                                        <td class="th-name">Security Name</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Round Name</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Number of shares</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Price per shares</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Amount Invested</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Relevant Shareholders Agreement</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Date of allotment</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Par value per share</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Premium per share</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Share distinctive numbers</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Share certificate number</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Allotment no.</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Folio No.</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Mode of payment</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Amount Received</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Transaction Id / Reference Id</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Proof of Reciept</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Date of Reciept</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Company's Bank Account</td>
                                    </tr>
                                    <tr>
                                        <td class="th-name">Investor Bank Account</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="column-scroller col-md-9 pad-0">
                            <table class="table-fund">
                                <tbody id="fundTbl">
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="th-header-bold">
                                            {{data.instrumentName == null ? '-' : data.instrumentName }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.roundName == null ? '-' : data.roundName }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.numberOfShares == null ? '-' : getTotalValueTble(data.numberOfShares) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.pricePerShare == null ? '-' : getTotalValueTble(data.pricePerShare)}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.amountInvested == null ? '-' : getTotalValueTble(data.amountInvested)}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.shareholderAgreement == null ? '-' : data.shareholderAgreement}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.dateOfAllotment == null ? '-' : data.dateOfAllotment  | date}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.parValuePerShare == null ? '-' : getTotalValueTble(data.parValuePerShare)}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.premiumPerShare == null ? '-' : getTotalValueTble(data.premiumPerShare)}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.shareDistinctiveNumberDto == null ? '-' : data.shareDistinctiveNumberDto}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.shareCertificateNumber == null ? '-' : data.shareCertificateNumber}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.allotmentNumber == null ? '-' : data.allotmentNumber}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.folioNumber == null ? '-' : data.folioNumber}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.modeOfPayment == null ? '-' : data.modeOfPayment}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.amountReceived == null ? '-' : getTotalValueTble(data.amountReceived)}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.transactionReferenceId == null ? '-' : data.transactionReferenceId}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.proofOfReceipt == null ? '-' : data.proofOfReceipt}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.dateOfReceipt == null ? '-' : data.dateOfReceipt | date}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.companyBankAccount == null ? '-' : data.companyBankAccount}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let data of shareHolderFundDetails;let i = index; let odd = odd;"
                                            [ngClass]="odd ? 'odd' : 'even'" class="td-name">
                                            {{data.investorBankAccount == null ? '-' : data.investorBankAccount}}</td>
                                    </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>