<div class="container-fluid graybg">
  <div class="whiteboxwrap">
  <div class="row top-20">
    <div class="col-md-12">
      <h5 class="page-hdr btm-15">User Profile</h5>
      <p class="content-pg-1 btm-20">
        Your basic profile information is pre-filled in this section as per the records of the company.
      </p>
    </div>
  </div>

  <div class="row" (mouseleave)="hideHelp()">
    <div class="col-xl-9 col-lg-12 col-sm-12">
      <form class="needs-validation" novalidate>
      <mat-card >
        <mat-card-header>
          <mat-card-title>User Details</mat-card-title>
        </mat-card-header>

        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
        <div id="User_Details_Card" (mouseover)="showHelp('User_Details_Card')">
          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1 btm-20">
                Confirm/Update additional profile details below.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">User Name</mat-label>
              <input type="text" class="form-control top-10" [(ngModel)]="user.fullName" [ngModelOptions]="{standalone: true}" title="" readonly>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">Email Id</mat-label>
              <input type="email" class="form-control top-10" [(ngModel)]="user.emailAddress" [ngModelOptions]="{standalone: true}" title="" readonly/>
            </div>
          </div>

          <div class="row  top-20">
            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">Company Name</mat-label>
              <input [disabled]="authorizeUser(['emp-view', '!strict'])" type="text" class="form-control top-10" placeholder="Company Name"
                [(ngModel)]="user.companyName" #cnameErr="ngModel" [ngModelOptions]="{standalone: true}" title="" required/>
              <div *ngIf="cnameErr.errors">
                <div class="alert alert-danger" *ngIf="(cnameErr.dirty || cnameErr.touched) && !uCompany">
                  Company Name is required!
                </div>
                <div class="alert alert-danger" *ngIf="cnameErr.errors?.required && uCompany">
                  Company Name is required!
                </div>
              </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label>Designation</mat-label>
              <div class="top-10">
                <input [disabled]="authorizeUser(['emp-view', '!strict'])" class="form-control cursor-hand" id="role" [(ngModel)]="user.designation" 
                [ngModelOptions]="{standalone: true}" required />
                  
              </div>
            </div>
          </div>

          <div class="row  top-20">
            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label>PAN</mat-label>
              <input [disabled]="authorizeUser(['emp-view', '!strict'])" type="text" title="" class="form-control top-10" [(ngModel)]="user.pan"
                (keypress)="alphaNumeric($event)" placeholder="PAN Number" [ngModelOptions]="{standalone: true}"/>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">Contact Number</mat-label>
              <input title="" type="number" class="form-control top-10" [(ngModel)]="user.phoneNumber" [ngModelOptions]="{standalone: true}"
                #phoneErr="ngModel" placeholder="Contact Number" (keypress)="maxNumber($event.target.value)" onwheel="this.blur()" required/>
              <div *ngIf="phoneErr.errors">
                <div class="alert alert-danger" *ngIf="(phoneErr.dirty || phoneErr.touched) && !uContact">
                  Contact Number is required!
                </div>
                <div class="alert alert-danger" *ngIf="phoneErr.errors?.required && uContact">
                  Contact Number is required!
                </div>
              </div>
            </div>
          </div>

          <div class="row top-20">
            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">Country of Residence</mat-label>
              <select [disabled]="authorizeUser(['emp-view', '!strict'])" class="form-control top-10 cursor-hand" [(ngModel)]='user.countryOfResidence' #corErr="ngModel" 
              [ngModelOptions]="{standalone: true}" required>
                <option value=null [selected]="true" disabled>Select Residence</option>
                <option *ngFor='let status of residentialStatus; let k = index' value="{{status}}">{{status}}
                </option>
              </select>
              <div *ngIf="corErr.errors">
                <div class="alert alert-danger" *ngIf="(corErr.dirty || corErr.touched) && !uCor">
                  Residence is required!
                </div>
                <div class="alert alert-danger" *ngIf="corErr.errors?.required && uCor">
                  Residence Id is required!
                </div>
              </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">LinkedIn</mat-label>
              <input  type="text" class="form-control top-10" placeholder="LinkedIn profile id"
                [(ngModel)]="user.linkedInProfile" #addErr="ngModel" [ngModelOptions]="{standalone: true}" title="">
            </div>
          </div>

          <div class="row top-20">
            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">Nationality</mat-label>
              <select [disabled]="authorizeUser(['emp-view', '!strict'])" class="form-control top-10 cursor-hand" [(ngModel)]="user.nationality" #nationErr="ngModel" 
              [ngModelOptions]="{standalone: true}" required>
                <option value=null [selected]="true" disabled>Select Nationality </option>
                <option *ngFor="let country of countryList">{{ country }}</option>
              </select>
              <div *ngIf="nationErr.errors">
                <div class="alert alert-danger" *ngIf="(nationErr.dirty || nationErr.touched) && !uNation">
                  Nationality is required!
                </div>
                <div class="alert alert-danger" *ngIf="nationErr.errors?.required && uNation">
                  Nationality Id is required!
                </div>
              </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-5">
              <mat-label class="required">Gender</mat-label>
              <div class="top-10">
                <select class="form-control cursor-hand" [(ngModel)]="user.gender" (change)="onChangeGender(gender.value)" 
                [ngModelOptions]="{standalone: true}" #gender>
                  <option value="" selected disabled>Select your Gender</option>
                  <option *ngFor="let gender of genders" value="{{gender}}">{{gender}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row top-20">
            <div class="col-xs-5 col-sm-5 col-md-4">
              <mat-label class="required">Upload Photo</mat-label>
              <div>
                <img class="profile-image" *ngIf="user.profilePic" [src]="user.profilePic" />
                <input type="text" title="" #fileErr="ngModel"
                  [(ngModel)]="fileName" class="form-control top-10" placeholder="Upload Photo" [ngModelOptions]="{standalone: true}"/>
              </div>

              <app-upload-image *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog" [croppedImage]="croppedImage"
                (visibleChange)="getEvent()" (imageSaved)="onImageSaved($event)">
              </app-upload-image>
            </div>

            <div class="col-xs-1 col-sm-1 col-md-1">
              <mat-label></mat-label>
              <img (click)="openUploadImage()" class="top-40 cursor-hand" ngbTooltip="Upload"
                src="../../../assets/upload.svg" />
            </div>
          </div>
        </div>
          <div class="col-md-9 top-20" *ngIf="employee" >
            <mat-card-header>
              <mat-card-title>Nominees
              </mat-card-title>
            </mat-card-header>
            <div class="row top-20">
              <div class="col-md-12">
                <p class="content-pg-1 btm-20">
                  Provide details of individuals who would be receiving the shares/ economic benefits of the stock options granted to you.
                </p>
              </div>
            </div>
            <div id="Nominee_Table" (mouseover)="showHelp('Nominee_Table')" (mouseleave)="hideHelp()">
              <table class="table-common top-10" >
                <thead>
                  <tr>
                    <th class="text-align-left width-30">Name of Nominee
                    </th>
                    
                    <th class="text-align-left width-30">Percentage ( % )
                    
                    </th>
                    
                    <th class="text-align-left width-30">Relation to Nominee
                      
                    </th>

                    <th class="width-9"><img (click)="addnewRowEvent()" ngbTooltip="Add" class="add-row-right cursor-hand" src="../../../assets/action/addnew.svg" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  
                  <tr *ngFor="let nominee of nomineeList; let i=index;">
                    <td>
                      <input type="text" [readOnly]="editNominee!=i"
                        title="name"  
                        [ngClass]="(stPeri.errors?.required && stPeriod) ? 'is-invalid' : 'is-valid'"
                        class="form-control" [(ngModel)]="nominee.name" #stPeri="ngModel"
                        [ngModelOptions]="{standalone: true}" required/>
                        <div class="alert alert-danger  text-align-left span-block" *ngIf="stPeri.errors">
                          <span *ngIf="stPeri.errors?.required && stPeriod">
                              Name is required!
                          </span>
                        </div>
                    </td>
                    <td>
                      <input type="text" title="percentage" [readOnly]="editNominee!=i"
                        class="form-control text-align-right number" 
                        [ngClass]="(sePercent.errors?.required && sePercentage) ? 'is-invalid' : 'is-valid'"
                        (blur)="onChangeInput($event.target, i)" (ngModelChange)="nominee.percentageAlloted"
                        [ngModel]="nominee.percentageAlloted | percent: '2.2'" (keydown)=onKeydownEvent($event) #sePercent="ngModel"
                        (keypress)="onlyNumberKey($event)" [ngModelOptions]="{standalone: true}" required/>
                        <div class="alert alert-danger  text-align-left span-block" *ngIf="sePercent.errors">
                          <span *ngIf="sePercent.errors?.required && sePercentage">
                              Percentage is required!
                          </span>
                        </div>
                    </td>
                    <td>
                      <select class="form-control" id="employeeRelationship" [(ngModel)]="nominee.employeeRelationship" [disabled]="editNominee!=i"
                    title="" #typeOfTheCompany="ngModel" [ngModelOptions]="{standalone: true}" required>
                    <option value="" selected disabled>Relation to nominee</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Father">Father</option>
                    <option value="Husband">Husband</option>
                    <option value="Mother">Mother</option>
                    <option value="Son">Son</option>
                    <option value="Wife">Wife</option>
                    <option value="Other">Other</option>
                  </select>
                      </td>
                      <td class="width-80">
                        <img  class="cursor-hand" ngbTooltip="Edit" *ngIf="editNominee != i" src="../../../assets/action/edit.svg" (click)="editEvent(i)" />
                        <img  class="cursor-hand" ngbTooltip="Save" *ngIf="editNominee == i" src="../../../assets/save.svg" (click)="saveEvent(i)" />
                        <img  class="left-10 cursor-hand" ngbTooltip="Delete" (click)="removeRowEvent(i)" src="../../../assets/trash-bin.svg">
                      </td>
                </tr>

                <tr *ngIf="nomineeList.length==0">
                  <td colspan="4" class="pl-xs-20">
                    <p class="cursor-hand">Click on the '+' to add a nominee
                    </p>
                  </td>
                </tr>

               
                </tbody>
              </table>
            </div>
          </div>

          <!-- Change password -->
          <div class="row">
            <div class="col-md-12 top-20">
              <mat-label (click)="changePassword()" class="change-password">Change Password
                <span *ngIf="!ischangePassword">
                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                </span>
                <span *ngIf="ischangePassword">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
              </mat-label>
            </div>
            
            <div class="card-lyot col-md-6" *ngIf="ischangePassword">
              <div class="row top-20">
                <div class="col-md-12">
                  <p class="content-pg-1 btm-20">
                    You can change the password to your login anytime, anywhere. Ensure to provide a password that is at least 8 characters long and alphanumeric.
                  </p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="text-align-left">
                  <mat-label class="required">Current Password</mat-label>
                  <div class="input-eyePlaceholder">
                    <input type="password" class="form-control top-10" [(ngModel)]="currentPassword"
                      #cPassword="ngModel" autocomplete="off" title="" [type]="currHide ? 'password' : 'text'"
                      placeholder="Enter Current password"  [ngModelOptions]="{standalone: true}" required>
                    <i [ngClass]=" currHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="currpasswordHide()"
                      class="eye-placeholder"></i>
                  </div>
                  <span *ngIf="cPassword.errors">
                    <span class="alert alert-danger"
                      *ngIf="(cPassword.dirty || cPassword.touched) && !cPasswordInvalid">
                      Current Password is required!
                    </span>
                    <span class="alert alert-danger" *ngIf="cPassword.errors?.required  && cPasswordInvalid">
                      Current Password is required!
                    </span>
                  </span>
                </div>

                <div class="margin-top-lg text-align-left">
                  <mat-label class="required">New Password</mat-label>
                  <div class="input-eyePlaceholder">
                    <input type="password" class="form-control top-10" [(ngModel)]="newPassword" #nPassword="ngModel"
                      autocomplete="off" [type]="newHide ? 'password' : 'text'" placeholder="Enter New password"
                      title=""  [ngModelOptions]="{standalone: true}" placement="right" (keyup)="checkPasswordStrength()" [ngbTooltip]="passwordrequirements" required>
                    <i [ngClass]=" newHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="newpasswordHide()"
                      class="eye-placeholder"></i>
                  </div>
                  <app-password-strength *ngIf="newPassword" [passwordToCheck]="newPassword" [barLabel]="barLabel"></app-password-strength>
                  <ng-template #passwordrequirements>
                    Your Password must include:
                    <ul style="text-align: start;">
                      <li>
                        Atleast 8 characters
                      </li>
                      <li>
                        Atleast 1 special character
                      </li>
                      <li>
                        Atleast 1 uppercase character
                      </li>
                      <li>
                        Atleast 1 number
                      </li>
                    </ul>
                  </ng-template>
                  <span *ngIf="(nPassword.dirty || nPassword.touched) && newPassword && !passwordStrength" class="alert alert-danger">
                    New Password doesn't meet the minimum requirements !
                  </span>
                  <span *ngIf="nPassword.errors">
                    <span class="alert alert-danger"
                      *ngIf="(nPassword.dirty || nPassword.touched) && !nPasswordInvalid">
                      New Password is required!
                    </span>
                    <span class="alert alert-danger" *ngIf="nPassword.errors?.required  && nPasswordInvalid">
                      New Password is required!
                    </span>
                  </span>
                </div>

                <div class="margin-top-lg text-align-left">
                  <mat-label class="required">Confirm Password</mat-label>
                  <div class="input-eyePlaceholder">
                    <input type="password" class="form-control top-10" [(ngModel)]="confirmPassword"
                      #ccPassword="ngModel" [type]="conHide ? 'password' : 'text'" placeholder="Confirm New password"
                      autocomplete="off" title=""  [ngModelOptions]="{standalone: true}" required>
                    <i [ngClass]=" conHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="conpasswordHide()"
                      class="eye-placeholder"></i>
                  </div>
                  <span *ngIf="ccPassword.errors">
                    <span class="alert alert-danger"
                      *ngIf="(ccPassword.dirty || ccPassword.touched) && !ccPasswordInvalid">
                      Confirm New Password!
                    </span>
                    <span class="alert alert-danger" *ngIf="ccPassword.errors?.required  && ccPasswordInvalid">
                      Confirm New Password!
                    </span>
                  </span>
                </div>
              </div>

              <div class="top-20">
                <button mat-raised-button id="changePassword" color="success" [disableRipple]="true" class="float-right"
                  (click)="resetPassword()" [disabled]="!passwordStrength" ngbTooltip="Change Password">Change Password</button>
                <button mat-raised-button id="cancel" [disableRipple]="disableripple" color="warn"
                  class="float-right margin-right-20" (click)="cancel()" ngbTooltip="Cancel">Cancel</button>
              </div>

            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="employee==false">
        <mat-card-header>
          <mat-card-title>Security Settings</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6">
              <mat-label class="setting-item">Two Factor Authentication</mat-label>
              <mat-button-toggle-group [ngModelOptions]="{standalone: true}"  class="top-10" id="isTwoFactorAuthEnabled"
                [(ngModel)]="settings.isEnabledTwoFactorAuthentication">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <div class="row">
        <div class="col-md-12 top-20 ">
          <button [disabled]="ischangePassword" class="float-right margin-left-lg" mat-raised-button id="save" color="success" (click)="checkValidation()" 
          ngbTooltip="Save and Exit" [disableRipple]="true">Save
          </button>
          <button type="button" [disabled]="ischangePassword" class="float-right" mat-raised-button id="cancel" color="warn" (click)="close()" 
          ngbTooltip="Cancel" [disableRipple]="true">Cancel
          </button>
        </div>
      </div>
    </form>
    </div>

    <!-- Helper Section -->
    <div class="col-md-3">
      <div class="helper-container">
        <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
          [hidden]="helper == ''">
          <mat-card-header class="help-hdr">
            <mat-card-title><label class="help-hdr-txt">{{helpSections?.name}}</label></mat-card-title>
          </mat-card-header>
          <hr />
          <mat-card-content class="card-help-content">
            <div [innerHTML]="helpSections?.description"></div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
</div>
