import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../services/helper.service';
import { AuthenticationService } from "../../authentication.service";
import { PermissionsService } from 'src/app/services/permissions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent, DxTooltipComponent } from 'devextreme-angular';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { trigger } from "devextreme/events";
import { Observable } from "rxjs";
import * as _ from 'lodash';
import * as moment from "moment";
import {map, startWith} from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import * as Excel from 'exceljs/dist/exceljs';
import { exportDataGrid } from "devextreme/excel_exporter";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDeleteComponent } from "src/app/confirm-delete/confirm-delete.component";

interface EmployeeGrant{
  optionsGranted?:number
  grantPrice?:number
}
@Component({
  selector: "app-employee-details",
  templateUrl: "./employee-details.component.html",
  styleUrls: ["./employee-details.component.scss"],
})
export class EmployeeDetailsComponent implements OnInit {
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  showDialog: Boolean = false;
  employeeId;
  employeeDetails;
  employeeVestingTable;
  vestOrExerciseTable;
  graphPoints;
  emplStatus;
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  editStatus = false;
  usrCompanyCurrency;
  bulkOperationList: string[] = [];
  userDetails;
  viewOnly = false;
  public vestDialog = {
    show: false,
    details: null,
    isApprover: false
  }
  public bulkStateChangeIds = [];
  public esopPlan;

  toolTipText: string = "";
  toolTipTarget: any;
  empNameFilter = [];
  vestingTempFilter = [];
  planNameFilter = [];
  statusFilter = [];
  esopPlansTable = [];
  esopVestingTemplateList = [];
  empList = [];
  isShowHeaderFilter: boolean;
  selectedItemKeys = [];
  optionHolderId;
  exerciseDialog = {
    show: false,
    isApprover: false,
  };
  exerciseEmpDtls;
  vestingEmpDtls;
  isShowVest: boolean = false;
  dataSource;
  plan;
  dataTable;
  totalValueInTable = 0;
  // isNameEdit: boolean = false;
  isEmpStatusEdit: boolean = true;
  isVestingTemplateEdit: boolean = false;
  isEditingStarted: boolean = false;
  employmentStatus;
  editOutstanding;
  edit: boolean = false;
  totalOptionValue = 0;
  minVal = 1;
  showDialogEmp: boolean;
  vestingChartData;
  //vestingTableData = [];
  isChartView = true;
  cliffPointers;
  todayDate;
  editRow: boolean = false;
  editRowData;
  mode;
  table;
  url: string = "";
  allowEdit: boolean = false;
  showGrantStatusDialog = false;
  showAnswerGrantDialog = false;
  viewPlanTemplate = false;
  nextStatusForGrant = null;
  selectedGrantId: any;
  grantId: any;
  hasConfirmedNominees: boolean = true;
  employee: boolean = false;
  planId: any;
  shouldShowPPS = false;
  ppsValue = 0
  totalShareValue = 0
  totalShareCost = 0;
  canAddGrant = false;
  isEmployee = ["Employee", "employee"].includes(localStorage.getItem("userRole"))
  grantStatusColorStyles = {};
  
  contextMenuUserPermissions: {
    editGrant?: boolean;
    vestRequest?: boolean;
    canExcercise?: boolean;
    canDelete?: boolean;
  } = {};
  dataSourceDuplicate;
  totalSearchOptions = [
    "For_Approval",
    "Approved",
    "Created",
    "Offered",
    "Granted",
    "Rejected",
  ];
  grantDateFilter: {
    fromDate: moment.Moment | string;
    toDate: moment.Moment | string;
  } = { fromDate: undefined, toDate: undefined };
  searchOptions: Observable<string[]>;
  userRole = localStorage.getItem("userRole");
  searchFormControl = new FormControl();
  bulkActionValue = new FormControl();
  employeeDropdownData = []
  actualOptions = [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>
  selectedEmployee = "";
  isUserAdmin =false;
  hrmsIntegrationStatusIsActive;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public permService: PermissionsService,
    public route: ActivatedRoute,
    private cacheService: CacheProviderService,
    private helperService: HelperService,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private readonly dialog: MatDialog

  ) {
    this.customVal = this.customVal.bind(this);
  }
  

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filters(value))
    );
    this.fillColorPallete();
    this.utilityService.dataEmitter.subscribe(dataEmit=>{
      if(dataEmit && dataEmit['userRole'] && !this.isEmployee){
        this.isEmployee = localStorage.getItem('userRole')=='Employee' || localStorage.getItem('userRole')=='employee'
      }
    })
    this.bulkActionValue.disable()
    this.url = this.authService.baseURL;
    if (this.userRole == "Admin") {
      this.searchOptions = this.searchFormControl.valueChanges.pipe(
        map((value) => this._filter(value))
      );
    }
    this.allowEdit = this.permService.authorizeUser(["edit-grant"]);
    if (this.activatedRoute.snapshot.paramMap.get("plan") != "null") {
      this.plan = this.activatedRoute.snapshot.paramMap.get("plan");
    }
    const isEmployee = localStorage.getItem("userRole") == "Employee"
    this.isUserAdmin = localStorage.getItem("userRole") == "Admin";
    this.hrmsIntegrationStatusIsActive = localStorage.getItem("hrmsIntegrationStatus") == "Active"
    let loggedInEmployeeId = this.utilityService.getValue('empId')
    loggedInEmployeeId = loggedInEmployeeId == undefined ? localStorage.getItem('empId') : loggedInEmployeeId
    this.route.params.subscribe((params) => {
      this.employeeId = params.id;
    });
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency =
        this.userDetails["company"]["companyCurrency"];
      localStorage.setItem("comCur", this.usrCompanyCurrency);
    } else {
      this.usrCompanyCurrency = "INR - ₹";
    }
   // const isUserAdmin  = localStorage.getItem("userRole") == "Admin"
    
    if (!isEmployee) {
      this.authService.getAllEmployees().subscribe((allEmployelist) => {
        if (allEmployelist) {
          this.employeeDropdownData = allEmployelist["employees"];
          this.employeeDropdownData=this.employeeDropdownData.sort((a,b) => a.employeeName.toLowerCase() > b.employeeName.toLowerCase() ? 1:-1);

          this.actualOptions = this.employeeDropdownData.map(a=>a.employeeName);

          const allEmployeeFiltered = allEmployelist["employees"].filter(
            (employee) => employee.id == this.employeeId
          );
          if ((allEmployeeFiltered as []).length == 0) {
            this.toastr.error("Employee Not Found", "Error");
            return
            // this.router.navigateByUrl("/landing/dashboard/401");
          } else {
            this.getUserDetails();
          }
        }
        // this.employeeId
      });
    } else {
      if (this.employeeId != loggedInEmployeeId && isEmployee) {
        this.router.navigateByUrl("/landing/dashboard/401");
        return
      }
      this.getUserDetails()
    }
    // this.getTableDropdownData();
    this.populateUserPermissions();

  }

  private _filters(value: string=''): string[] {
    const filterValue = value.toLowerCase();

    return this.actualOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  

  changeEmployee(employee){
    var data = this.employeeDropdownData.filter(emp => {
      return emp.employeeName == employee
    })[0]
    data['employeeId'] = data['id']
    this.goToEmpDetails(data)
  }

  
  populateUserPermissions() {
    this.contextMenuUserPermissions.editGrant = this.permService.authorizeUser([
      "edit-grant",
    ]);
    this.contextMenuUserPermissions.vestRequest = 
      this.permService.authorizeUser(["vest-request"]);
    this.contextMenuUserPermissions.canExcercise =
      this.permService.authorizeUser(["emp-view"]);
    this.contextMenuUserPermissions.canDelete = this.permService.authorizeUser([
      "delete-grant",
    ]);
    if (
      this.permService.authorizeUser(["delete-grant"]) &&
      !this.bulkOperationList.includes("Delete")
    ) {
      this.bulkOperationList.push("Delete");
    }
    this.canAddGrant = this.permService.authorizeUser(["add-grant"]);
  }
  getUserDetails(){
    this.authService.getUserDetails().subscribe(
      (res) => {
        if (res["authorities"][0]["name"] == "Employee") this.employee = true;
        this.getEmployeeDetails();
        this.getEmployeeVestingTable();
      },
      (e) => {
        if (e.error.reason === "Login Token Expired.") {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate([""]);
          return;
        } else {
          this.toastr.error(e.error.reason, "Failure!");
        }
      }
    );
  }
  ngOnDestroy(): void {
    if (this.router.routerState.snapshot.url.indexOf("manageEsop") > -1) {
      this.router.navigate([
        "/landing/dashboard/options/manageEsop",
        { planName: this.plan.esopPlanName },
      ]);
    }
  }

  remove_(str) {
    return str.replace(/_/g, " ");
  }

  changeView(view) {
    if (view == "list") {
      this.isChartView = false;
    } else if (view == "chart") {
      this.isChartView = true;
    }
  }

  generateCred() {
    this.ngxLoader.start();
    this.authService.getCredentials(this.employeeId).subscribe(
      (res) => {
        // let blob = new Blob([JSON.stringify(res, null, 2)], {type: 'text/xml'});
        if(this.userDetails["company"]["isDownloadEnabled"]){
          var downloadURL = window.URL.createObjectURL(res);
          var link = document.createElement("a");
          link.href = downloadURL;
          link.download = "Credentials.txt";
          this.ngxLoader.stop();
          this.employeeDetails['isLoginProvided']=true
          link.click();
          this.toastr.success("Credentials generated Successfully","Success");}
        else{
          this.ngxLoader.stop();
          this.employeeDetails['isLoginProvided']=true
          this.toastr.success("Credentials generated Successfully","Success");
        }
        
      },
      (e) => {
        this.ngxLoader.stop();
        var reader = new FileReader();
        reader.onloadend = (e) => {
          this.toastr.error(
            JSON.parse((<any>e.target).result)["reason"],
            "Error"
          );
        };
        reader.readAsText(e.error);
      }
    );
  }
  calculateEmployeeShares(datasource:EmployeeGrant[]){
    this.totalShareCost = 0
    this.totalShareValue = 0
    datasource.forEach(data=>{
      if(!data.grantPrice) return
      if(!data.optionsGranted) return
      this.totalShareCost+=(data.grantPrice * data.optionsGranted)
      this.totalShareValue+=(data.optionsGranted * this.ppsValue)
    })
  }
  getEmployeeDetails() {
    this.ngxLoader.start();
    this.authService.getEmployeeDetails(this.employeeId).subscribe(
      (res) => {
        if (res) {
          this.ngxLoader.stop();
          this.employeeDetails = res;
          this.selectedEmployee = res['employeeName']
          if (this.employeeDetails.status == "Employed") {
            this.editStatus = true;
          }
          this.dataTable = this.employeeDetails["optionsDetails"];
          this.dataSource = this.employeeDetails["optionsDetails"];
          this.dataSourceDuplicate = JSON.parse(
            JSON.stringify(this.dataSource)
          );
          this.shouldShowPPS = res["showPPSToEmployees"]
          if(this.shouldShowPPS){
            this.ppsValue = res["ppsforEmployee"]
          }
          this.getTableDropdownData();
          if (this.dataSource) {
            this.populateTotalValueInTable();
            if(this.shouldShowPPS && this.dataSource){
              this.calculateEmployeeShares(this.dataSource)
            }
            this.dataSource.forEach(element => {
              element["optionsExercised"]-= element["optionsExercisedWithCash"]
          })
        }
          //this.vestingTableData = this.employeeDetails["tableDetails"];
         this.todayDate =  new Date();
          if (
            this.employeeDetails["imageURL"] ===
            "https://s3-us-west-2.amazonaws.com/equion-dev/6fb8c41b3cdc499084cbbc684f3e751a" ||
            this.employeeDetails["imageURL"] ===
            "https://s3-us-west-2.amazonaws.com/equion-dev/13d197f728fe48d69321137576460108" ||
            this.employeeDetails["imageURL"] ===
            "https://s3-us-west-2.amazonaws.com/equion-dev/e31a006ee5184eba9a53a05a67d23d1c" ||
            this.employeeDetails["imageURL"] === "" ||
            this.employeeDetails["imageURL"] == null
          ) {
            this.employeeDetails["imageURL"] = "";
            const initials = this.employeeDetails["employeeName"]
              .split(" ")
              .map((name) => name[0])
              .join("")
              .toUpperCase();
            this.employeeDetails["imageURL"] = initials;
          }
          if (!this.employeeDetails["hasConfirmedNominees"] && this.employee) {
            this.hasConfirmedNominees =
              this.employeeDetails["hasConfirmedNominees"];
          }
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!", "Failure!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  getEmployeeVestingTable() {
    this.ngxLoader.start();
    this.authService.getEmployeeVestingTable(this.employeeId).subscribe(
      (res) => {
        if (res) {
          if(this.employeeDetails != null){
            this.ngxLoader.stop();
          }
          this.employeeVestingTable = res;
          this.vestOrExerciseTable = this.employeeVestingTable
          // .filter(
          //   (item) => item.isVestDate || item.isExerciseDate
          // );
          if (this.vestOrExerciseTable) {
            this.vestOrExerciseTable.forEach(element => {
              element["accumulatedStockExercisedOptionsForEmployee"] = element["accumulatedExercisedOptionsForEmployee"]- element["accumulatedCashExercisedOptionsForEmployee"]
          })
        }
          this.cliffPointers = this.getCliffPointers(this.vestOrExerciseTable);
          this.generateVestingGraphData();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Failed to get employee vesting table!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  getCliffPointers(vestings) {
    let vestingsCopy: any = JSON.parse(JSON.stringify(vestings));
    let cliffPointers = [];
    for (let vesting of vestingsCopy) {
      if (vesting.isCliffDate) {
        let cliffPointer: any = {};
        cliffPointer.cliff = 365;
        cliffPointer.cliffDate = vesting.date;
        cliffPointer.grantNumber = vesting.grantId;
        cliffPointers.push(cliffPointer);
      }
    }
    return cliffPointers;
  }

  generateVestingGraphData() {
    let graphData = JSON.parse(JSON.stringify(this.employeeVestingTable.slice())
    );
    let graphDataCopy = JSON.parse(
      JSON.stringify(this.employeeVestingTable.slice())
    );
    let currentDate: Date = new Date(graphDataCopy[graphDataCopy.length-1].date);
      currentDate.setDate(currentDate.getDate() + 180);
      const newDataPointForGraph = JSON.parse(JSON.stringify(graphData[graphData.length - 1]));
      newDataPointForGraph.date = new Date(currentDate.toString());
      graphData.push(JSON.parse(JSON.stringify(newDataPointForGraph)));
    this.vestingChartData = graphData;
  }

  openResignationPopup(status) {
    this.emplStatus = status;
    this.utilityService.showOverlay()
    this.showDialog = true;
  }

  getEvent() {
    this.showDialog = false;
    this.utilityService.hideOverlay()
    this.getEmployeeDetails();
  }

  showHelp() {
    let section = "";
    if (!this.employee) {
      section = "EsopDetails";
    } else {
      section = "EsopEmployeeDetails";
    }
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);

    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "EmployeeDetailsComponent",
      section
    );
    this.helper = section;
  }
  hideHelp() {
    this.helper = "";
  }
  
  goToEmpDetails(data) {
    this.ngxLoader.start();
    this.authService.getEmployeeDetails(data.employeeId).subscribe(
      () => {
        this.router.navigate([
          "/landing/dashboard/options/employee/employeeDetails",
          data.employeeId,
          {
            plan: this.plan
              ? this.plan
              : this.activatedRoute.snapshot.paramMap.get("plan"),
          },
        ]);
        if (!this.isEmployee) {
          this.authService.getAllEmployees().subscribe((allEmployelist) => {
            if (allEmployelist) {
              this.employeeDropdownData = allEmployelist["employees"].sort((a, b) => a.employeeName.localeCompare(b.employeeName));
              const allEmployeeFiltered = allEmployelist["employees"].filter(
                (employee) => employee.id == this.employeeId
              );
              if ((allEmployeeFiltered as []).length == 0) {
                this.toastr.error("Employee Not Found", "Error");
                return;
                // this.router.navigateByUrl("/landing/dashboard/401");
              } else {
                this.getUserDetails();
              }
            }
            // this.employeeId
          });
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }
  
  bulkChangeGrantStatus() {
    this.ngxLoader.start();
    this.authService
      .getNextStatusForGrant(this.bulkStateChangeIds[0])
      .subscribe(
        (response) => {
          this.ngxLoader.stop();
          if (response != null) {
            this.showGrantStatusDialog = true;
            this.nextStatusForGrant = response;
            this.utilityService.showOverlay()
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.showGrantStatusDialog = false;
          this.nextStatusForGrant = null;
          this.toastr.error(e.error.reason, "Failure!");
        }
      );
  }

 


  
  bulkActionChanged(value) {
    
    if (value == "Delete") {
      const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
     
        width: "400px",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result === "confirm") {
          this.ngxLoader.start();
      this.deleteRecords();
    
    }else{
      this.bulkActionValue.setValue(undefined)
    }
  })
    this.ngxLoader.stop();
     } 
     if (value == "Option State Change") {
      this.bulkChangeGrantStatus();
    }
    
}
  searchEntered(e) {
    this.dataGrid.instance.searchByText(e);
  }
  getGrantStatuscolor(value) {
    if (value && typeof value == "string") {
      return this.grantStatusColorStyles[value.toLowerCase()];
    }
  }
  fillColorPallete() {
    this.grantStatusColorStyles = {};
    Object.keys(this.utilityService.colorPalatteforGrants).forEach(
      (colorKey) => {
        this.grantStatusColorStyles[colorKey] = {
          background: this.utilityService.colorPalatteforGrants[colorKey]["background"],
          color : this.utilityService.colorPalatteforGrants[colorKey]["text"],
        };
      }
    );
  }
  dateSelected(source, event) {
    const filteredList = [];
    const dateFormat = "DD-MMM-YYYY";
    this.dataSource = JSON.parse(JSON.stringify(this.dataSourceDuplicate));
    let momentDate = moment(event.value);
    // let formattedDate = (momentDate.format(dateFormat))
    this.grantDateFilter[source] = momentDate;
    if (this.grantDateFilter.fromDate && this.grantDateFilter.toDate) {
      const datasource = this.dataSource;
      datasource.forEach((data) => {
        if (data) {
          if (data["grantDate"]) {
            const grantDate = moment(data["grantDate"]);
            if (
              grantDate.isSameOrAfter(this.grantDateFilter.fromDate) &&
              grantDate.isSameOrBefore(this.grantDateFilter.toDate)
            ) {
              filteredList.push(data);
            }
          }
        }
      });
    } else if (this.grantDateFilter.fromDate && !this.grantDateFilter.toDate) {
      const datasource = this.dataSource;
      datasource.forEach((data) => {
        if (data) {
          if (data["grantDate"]) {
            const grantDate = moment(data["grantDate"]);
            if (grantDate.isSameOrAfter(this.grantDateFilter.fromDate)) {
              filteredList.push(data);
            }
          }
        }
      });
    } else if (!this.grantDateFilter.fromDate && this.grantDateFilter.toDate) {
      const datasource = this.dataSource;
      datasource.forEach((data) => {
        if (data) {
          if (data["grantDate"]) {
            const grantDate = moment(data["grantDate"]);
            if (grantDate.isSameOrBefore(this.grantDateFilter.toDate)) {
              filteredList.push(data);
            }
          }
        }
      });
    } else if (!this.grantDateFilter.fromDate && !this.grantDateFilter.toDate) {
      this.dataSource = JSON.parse(JSON.stringify(this.dataSourceDuplicate));
    }
    this.dataSource = filteredList;
  }

  uploadFile(event) {
    const file: FormData = new FormData();
    const supportedFileExtensions = ["csv", "xls", "xlsx"];
    const formFile = event.target.files[0];
    if (formFile) {
      let file_extension = formFile.name.split(".");
      if (file_extension.length < 2) {
        this.toastr.error("Unsupported file format", "Failure!");
        return;
      }
      file_extension = file_extension[file_extension.length - 1];
      if (!supportedFileExtensions.includes(file_extension)) {
        this.toastr.error("Unsupported file format", "Failure!");
        return;
      }
    }
    file.append("file", new File([event.target.files[0]], "file.xlsx"));
    this.ngxLoader.start();
    this.authService.createEsopGrantsInBulk(file).subscribe(
      (res) => {
        this.ngxLoader.stop();
        this.toastr.success("File uploaded!", "Success!");
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }
  onExporting(e) {
    e.component.beginUpdate();
    e.component.columnOption("imageURL", "visible", false);
    e.component.columnOption("grantPrice", "customizeText", "");
    e.component.columnOption("empList", "displayExpr", "getDisplayExpr2");
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Employees");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 4, column: 1 },
    })
      .then((cellRange) => {
        // header
        const headerRow = worksheet.getRow(1);

        headerRow.height = 30;
        worksheet.mergeCells(1, 1, 1, 12);
        headerRow.getCell(1).value = "ESOP Grants -" + this.esopPlan;
        headerRow.getCell(1).font = {
          name: "Segoe UI Light",
          size: 22,
          color: { argb: "FFFFFF" },
        };
        headerRow.getCell(1).alignment = { horizontal: "center" };
        headerRow.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF101144" },
        };
        // ,bgColor:{argb:'FF101144'}};
        // footer
        // const image = workbook.addImage({
        //   filename: '../../../../assets/action/LoGo.png',
        //   extension: 'png',
        // });
        const footerRowIndex = cellRange.to.row + 1;
        // footerrow1 begin
        const footerRow = worksheet.getRow(footerRowIndex);
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

        footerRow.getCell(1).value = "Disclaimer ";
        footerRow.getCell(1).font = {
          color: { argb: "000000" },
          italic: true,
          bold: true,
        };
        footerRow.getCell(1).alignment = { horizontal: "left" };
        footerRow.height = 20;
        //footerrow1 end

        // footerrow2 begin
        const footerRow2 = worksheet.getRow(footerRowIndex + 1);
        worksheet.mergeCells(footerRowIndex + 1, 1, footerRowIndex + 7, 8);

        footerRow2.getCell(1).value =
          "This report has been generated on hissa (www.hissa.com) and contains proprietary and confidential information of the" +
          this.userDetails["company"]["nameOfTheCompany"] +
          ". This report is intended for the use and benefit of the" +
          this.userDetails["company"]["nameOfTheCompany"] +
          ". Hissa or its agents shall not be responsible for any decision made or actions taken in reliance on the information in this site.\nIf you are not an intended recipient of this report, do not read, copy, use, forward or disclose the email or any of its attachments to others. Instead, immediately notify the sender by replying to this email and then delete it from your system. We strictly prohibit any unauthorized disclosure, copying, distribution or use of emails or attachments sent by us. \nHissa is a registered trademark of Rulezero Technology Solutions Private Limited";
        footerRow2.getCell(1).font = {
          color: { argb: "000000" },
          italic: true,
        };
        footerRow2.getCell(1).alignment = {
          horizontal: "left",
          wrapText: true,
        };
        footerRow2.height = 20;
        //footerrow2 end

        // footerrow3 begin
        const footerRow3 = worksheet.getRow(footerRowIndex + 8);
        worksheet.mergeCells(footerRowIndex + 8, 1, footerRowIndex + 8, 8);

        footerRow3.getCell(1).value =
          " Copyright © 2021, Rulezero Technology Solutions Private Limited. All rights reserved.";
        footerRow3.getCell(1).font = {
          color: { argb: "000000" },
          italic: true,
          bold: true,
        };
        footerRow3.getCell(1).alignment = { horizontal: "left" };
        footerRow3.height = 20;
        //footerrow3 end

        //   worksheet.addImage(image, {
        //     // @ts-expect-error Issue with ExcelJs types.
        //   tl: { col: 24, row:24 },
        //   // @ts-expect-error Issue with ExcelJs types.
        //   br: { col:25, row: 25 },
        // });
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "ESOP Grants.xlsx"
          );
        });
      })
      .then(function () {
        e.component.columnOption("imageURL", "visible", true);
        e.component.columnOption("grantPrice", "customizeText", "customVal");
        e.component.columnOption("empList  ", "displayExpr", "getDisplayExpr");

        e.component.endUpdate();
      });

    e.cancel = true;
  }
  fileExport(exportSelected = false) {
    this.dataGrid.instance.exportToExcel(exportSelected);
  }

  clearDate() {
    this.grantDateFilter.fromDate = undefined;
    this.grantDateFilter.toDate = undefined;
    this.dataSource = this.dataSourceDuplicate;
  }
  showColumnChooser() {
    this.dataGrid.instance.showColumnChooser();
  }
  hideColumnChooser() {
    this.dataGrid.instance.hideColumnChooser();
  }
  columnChooserClicked() {
    this.showColumnChooser();
  }

  openEditEmpPopup() {
    this.showDialogEmp = true;
    this.utilityService.showOverlay()
  }

  //   onInitialized(e) {
  //     e.component.columnOption("optionsGranted", {
  //       editorOptions: {
  //         format: "#,##,##0",
  //         showClearButton: false
  //       }
  //     });
  //     e.component.columnOption("grantPrice", {
  //       editorOptions: {
  //         format: "#,##,##0",
  //         showClearButton: false
  //       }
  //     });
  //     e.component.columnOption("grantDate", {
  //       editorOptions: {S
  //         //format: "shortDate",
  //         showClearButton: false,
  //       }
  //     });
  //   }

  contextMenu(e) {

    if (e.event && e.event.button == 0) {
      if (e.row.rowType === "data") {
        e.hoverStateEnabled = false;
        const noPermissionText =
          "You're not authorized to perform this action.";
        const items = [];
        const planState = e.row.data.optionHolderState;
        const userPermissions = this.contextMenuUserPermissions;
        const isGrantAvailable = e.row.data.isGrantLetterAvailable;
        
        if(!this.isEmployee){
        // items.push({
        //   text: "EDIT GRANT",
        //   template: "contextMenuTemplate",
        //   properties: userPermissions.editGrant
        //     ? null
        //     : { disabled: true, noPermissionText },
        //   onItemClick: userPermissions.editGrant
        //     ? function () {
        //         e.component.editRow(e.row.rowIndex);
        //       }
        //     : null,
        // });
        
        items.push({
          text: "OPTION STATE CHANGE",
          template: "contextMenuTemplate",
          properties: userPermissions.vestRequest
          ? null
            : { disabled: true, noPermissionText },
          onItemClick: userPermissions.vestRequest
            ? () => this.triggerChangeGrantStatus(e.row)
            : null,
        });
        // items.push({
        //   text: "VEST THE SHARES",
        //   template: "contextMenuTemplate",
        //   properties: userPermissions.vestRequest
        //     ? null
        //     : { disabled: true, noPermissionText },
        //   onItemClick: userPermissions.vestRequest
        //     ? () => this.doVest(false, e.row.data.optionHolderId)
        //     : null,
        // });

        // items.push({
        //   text: "EXERCISE THE SHARES",
        //   template: "contextMenuTemplate",
        //   properties: userPermissions.canExcercise
        //     ? null
        //     : { disabled: true, noPermissionText },
        //   onItemClick: userPermissions.canExcercise
        //     ? () => this.doExercise(false, e.row.data.optionHolderId)
        //     : null,
        // });

       
      
      
      }
      if (this.isEmployee) {
        items.push({
          text: "ACCEPT GRANT",
          template: "contextMenuTemplate",
          properties: userPermissions.canExcercise
            ?  planState === "Granted" ? {
              disabled: true,
              noPermissionText: "The state of the option is  granted.",
            }
              : null
              : { disabled: true, noPermissionText },
          onItemClick: userPermissions.canExcercise
            ? () => this.triggerChangeGrantStatus(e.row)
            : null,
        });
      }

        items.push({
          text: "VIEW GRANT LETTER",
          template: "contextMenuTemplate",
          properties:isGrantAvailable ? {} : {disabled:true, noPermissionText:'Grant letter unavailable'},
          onItemClick: isGrantAvailable ? () => this.viewGrantLetter(e.row) : null
        });

        if(!this.isEmployee){
        items.push({
          text: "DELETE GRANT",
          template: "contextMenuTemplate",
          properties: userPermissions.canDelete
            ? (planState === "Granted" || planState === 'Offered')
              ? {
                  disabled: true,
                  noPermissionText: "Delete not allowed in this plan state.",
                }
              : null
            : { disabled: true, noPermissionText },
          onItemClick: userPermissions.canDelete
            ? ()=>this.deleteRecords(e.row.data.optionHolderId)
            : null,
        });
      }
        e.items = items;
      }
    
    }
    
  
  }


  onCellHoverChanged(e: any) {
    // dx-datagrid-addrow-button
    if (
      e.rowType === "data" &&
      e.row.isEditing === true &&
      e.column.type === "buttons" &&
      e.eventType === "mouseover"
    ) {
      if (
        e.event &&
        e.event.fromElement &&
        e.event.fromElement.title == "Save"
      ) {
        this.toolTipText = "Save";
        e.element.title = "";
        this.toolTipTarget = e.cellElement;
        this.tooltip.instance.show();
      }
      if (
        e.event &&
        e.event.fromElement &&
        e.event.fromElement.title == "Cancel"
      ) {
        this.toolTipText = "Cancel";
        e.element.title = "";
        this.toolTipTarget = e.cellElement;
        this.tooltip.instance.show();
      }
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.type === "buttons" &&
      e.eventType === "mouseover"
    ) {
      if (
        e.event &&
        e.event.fromElement &&
        e.event.fromElement.title == "Edit"
      ) {
        this.toolTipText = "Edit";
        e.element.title = "";
        this.toolTipTarget = e.cellElement;
        this.tooltip.instance.show();
      }
      if (
        e.event &&
        e.event.fromElement &&
        e.event.fromElement.title == "Delete"
      ) {
        this.toolTipText = "Delete";
        e.element.title = "";
        this.toolTipTarget = e.cellElement;
        this.tooltip.instance.show();
      }
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.dataField === "employeeId" &&
      e.eventType === "mouseover"
    ) {
      this.toolTipText = e.text;
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.dataField === "optionsGranted" &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.dataField === "grantPrice" &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.dataField === "optionsVested" &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.dataField === "optionsExercised" &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.dataField === "optionsLapsed" &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      e.row.isEditing === undefined &&
      e.column.dataField === "holdingPercentage" &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && e.eventType === "mouseout") {
      this.tooltip.instance.hide();
    }
  }

  getTableDropdownData() {
    this.ngxLoader.start();
    this.authService.getEsopMgmtTableData().subscribe(
      (res) => {
        if (res) {
          this.ngxLoader.stop();
          this.employmentStatus = new Array();
          this.employmentStatus = res["employmentStatus"];
          this.employmentStatus.forEach((status) => {
            let statusFilterObj = {
              text: status.employmentStatus,
              value: status.statusId,
            };
            this.statusFilter.push(statusFilterObj);
          });
          this.esopPlansTable = new Array();
          this.esopPlansTable = res["esopPlans"];
          this.esopPlansTable.forEach((plan) => {
            if (
              this.dataSource.find((option) => option.planId == plan.planId)
            ) {
              let planNameFilterObj = {
                text: plan.esopPlanName,
                value: plan.planId,
              };
              this.planNameFilter.push(planNameFilterObj);
            }
          });
          this.esopVestingTemplateList = new Array();
          this.esopVestingTemplateList = res["vestingList"];
          this.esopVestingTemplateList.forEach((template) => {
            if (
              this.dataSource.find((option) => option.vesting.id == template.id)
            ) {
              let templateFilterObj = {
                text: template.vestingTemplateName,
                value: template.id,
              };
              this.vestingTempFilter.push(templateFilterObj);
            }
          });
          this.empList = new Array();
          this.empList = res["employeeNames"];
          this.empList.forEach((emp) => {
            let filterObj = {
              text: emp.employeeName + " ( " + emp.employeeId + " ) ",
              value: emp.employeeId,
            };
            this.empNameFilter.push(filterObj);
          });
          this.isShowHeaderFilter = true;
          this.ngxLoader.stop();
        }
        this.ngxLoader.stop();
      },
      (e) => {
        this.toastr.error(e.error.reason, "Failure!");
        this.ngxLoader.stop();
      }
    );
  }

  selectionChanged(data: any) {
    if (data.selectedRowsData.length == 1) {
      this.selectedGrantId = data.selectedRowKeys[0];
    }
    this.selectedItemKeys = data.selectedRowKeys;
    if(this.selectedItemKeys){
      if(this.selectedItemKeys.length > 0){
        this.bulkActionValue.enable()
      }
      else{
        this.bulkActionValue.disable()
      }
    }

  }

  addNewRow() {
    if (this.dataGrid.instance.hasEditData()) {
      this.toastr.error("Please save the current row!", "Failure!");
    } else {
      this.dataGrid.instance.addRow();
    }
  }

  triggerChangeGrantStatus(e){
    this.selectedGrantId= e.data.optionHolderId;
    this.changeGrantStatus();
  }
  onCellClick(e) {
    if (e.row && e.row.isNewRow) {
      // returning as it's a new row
      return;
    }
    if (e.column && e.column.caption === "Actions" && e.rowType === "data") {
      if (this.selectedItemKeys.length > 1) {
        this.toastr.error("More than 1 row selected", "Error");
        return;
      }
      var newEvent = Object.assign({}, e.event, { type: "dxcontextmenu" });
      e.event.stopPropagation();
      trigger(e.cellElement, newEvent);
    }
  }
  changeGrantStatus() {
    this.ngxLoader.start();
    this.authService.getNextStatusForGrant(this.selectedGrantId).subscribe(
      (response) => {
        if (response != null) {
          this.ngxLoader.stop();
          if (localStorage.getItem("userRole") == "Employee") {
            this.grantId = this.selectedGrantId;
            this.showAnswerGrantDialog = true;
          } else {
            this.bulkStateChangeIds = [this.selectedGrantId]
            // if (!this.bulkStateChangeIds.includes(this.selectedGrantId)) {
            //   this.bulkStateChangeIds.push(this.selectedGrantId);
            // }
            this.showGrantStatusDialog = true;
          }

          this.nextStatusForGrant = response;
          this.utilityService.showOverlay()
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.showGrantStatusDialog = false;
        this.nextStatusForGrant = null;
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  getStatus() {
    this.showAnswerGrantDialog = false;
    this.showGrantStatusDialog = false;
    this.viewPlanTemplate = false;
    this.viewOnly = false;
    this.nextStatusForGrant = null;
    this.getEsopDefaultData();
    this.utilityService.hideOverlay()
  }

  doExercise(isApprover: boolean , optionHolderId?) {
   
    this.optionHolderId = this.selectedItemKeys[0];
    this.ngxLoader.start();
    this.authService
      .getExerciseDetails(this.optionHolderId, isApprover)
      .subscribe(
        (response) => {
          if (response) {
            this.exerciseDialog.show = true;
            this.exerciseDialog.isApprover = isApprover;
            this.exerciseEmpDtls = response;
            this.utilityService.showOverlay()
            this.ngxLoader.stop();
          } else {
            this.ngxLoader.stop();
            this.toastr.error("Null response!", "Failure");
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      );
  }

  getExercise(event) {
    this.exerciseDialog.show = false;
    this.exerciseDialog.isApprover = false;
    this.utilityService.hideOverlay()
  }

  viewGrantLetter(cellData) {
    this.viewOnly = true;
    this.grantId = cellData.data.optionHolderId;
    this.showAnswerGrantDialog = true;
    this.utilityService.showOverlay()
  }

  viewPlanLetter(cell) {
    this.plan = cell.data;
    this.viewPlanTemplate = true;
  }

  doVest(isApprover: boolean , optionHolderId?) {
    if (optionHolderId != null) {
      this.optionHolderId = optionHolderId;
    } else {
      this.optionHolderId = this.selectedItemKeys[0];
    }
    this.ngxLoader.start()
    this.optionHolderId = this.selectedItemKeys[0];
    this.authService
      .getVestingDetails(this.optionHolderId, isApprover)
      .subscribe(
        (response) => {
          if (response) {
            this.vestingEmpDtls = response;
            this.isShowVest = true;
            this.vestDialog.show = true;
            this.vestDialog.details = response;
            this.vestDialog.isApprover = isApprover;
            this.utilityService.showOverlay()
            this.ngxLoader.stop();
          } else {
            this.ngxLoader.stop();
            this.toastr.error("Null response!", "Failure");
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      );
  }

  getVest(event) {
    this.isShowVest = event;
    this.utilityService.hideOverlay()
  }

  deleteRecords(recordId?) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    let ids;
    this.selectedItemKeys.forEach((key) => {
      if (ids) {
        ids = ids + key + ",";
      } else {
        ids = key + ",";
      }
    });
    if(recordId){
      this.selectedItemKeys.push(recordId)
    }
    this.authService.deleteEmployeePlan(this.selectedItemKeys).subscribe(
      (response) => {
        if (response) {
          this.toastr.success("Record Deleted Successfully!", "Success!");
          this.getEsopDefaultData();
          this.bulkActionValue.setValue(undefined);
        }
      },
      (e) => {
        this.ngxLoader.stop()
        this.toastr.error(e.error.reason, "Failure!");
        this.getEsopDefaultData();
        this.bulkActionValue.setValue(undefined);
      }
    );
    }
});

  }

  getEsopDefaultData() {
    this.getEmployeeDetails();
  }

  populateTotalValueInTable() {
    let totalGrantedOption = 0;
    this.dataSource.forEach((eachValue) => {
      totalGrantedOption = totalGrantedOption + eachValue.optionsGranted;
    });
    this.totalValueInTable = totalGrantedOption;
  }

  logEvent(eventName, e) {
    if (eventName == "RowRemoved") {
      let optionHolders = [];
      optionHolders.push(e.key);
      this.authService.deleteEmployeePlan(e.key).subscribe(
        (response) => {
          if (response) {
            this.toastr.success("Record Deleted Successfully!", "Success!");
          }
        },
        (e) => {
          this.toastr.error(e.error.reason, "Failure!");
          this.getEsopDefaultData();
        }
      );
    } else if (eventName == "InitNewRow") {
      this.ngxLoader.start();
      this.mode = "add";
      this.editRow = true;
      let allRows = e.component.getVisibleRows();
      let rowIndex = e.component.getRowIndexByKey(e.key);
      this.editRowData = allRows[rowIndex];
      this.table = "employee";
      e.data.employeeId = this.employeeId;
      this.utilityService.showOverlay()
      this.ngxLoader.stop();
      e.data.imageURL = null;
      e.data.employeeName = this.employeeDetails["employeeName"];
    } else if (eventName == "EditingStart") {
      this.ngxLoader.start();
      this.editRow = true;
      this.mode = "edit";
      let allRows = e.component.getVisibleRows();
      let rowIndex = e.component.getRowIndexByKey(e.key);
      this.editRowData = allRows[rowIndex];
      this.table = "employee";
      this.utilityService.showOverlay()
      this.ngxLoader.stop();
      this.isEditingStarted = true;
    }
  }

  openEditPopup(index, key) {
  }

  getEditedRow() {
    this.editRow = false;
    this.utilityService.hideOverlay()
    this.getEsopDefaultData();
    this.ngxLoader.stop();
  }

  getEsopTemplate(i) {
    return this.esopVestingTemplateList.filter(
      (esop) =>
        esop.vestingTemplateName ==
        this.dataSource[i].vesting.vestingTemplateName
    );
  }

  getDisplayExpr(item) {
    if (!item) {
      return "";
    }
    return item.employeeName + " ( " + item.employeeId + " )";
  }

  customVal(cellInfo) {
    let amount = typeof(cellInfo) === "number" ? cellInfo : cellInfo.valueText
    if (amount > 0) {
      if (amount.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(
          this.usrCompanyCurrency,
          amount
        );
      } else {
        return this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          amount
        );
      }
    } else {
      return 0;
    }

    // let amount = cellInfo.valueText;
    // let currency = localStorage.getItem("comCur");
    // if (currency && amount && amount.toString().length > 5) {
    //   if (currency === "INR - ₹") {
    //     return Math.abs(Number(amount)) >= 1.0e7
    //       ? (Math.abs(Number(amount)) / 1.0e7).toFixed(2) + "Cr"
    //       : // Six Zeroes for Millions
    //       Math.abs(Number(amount)) >= 1.0e5
    //         ? (Math.abs(Number(amount)) / 1.0e5).toFixed(2) + "L"
    //         : // Three Zeroes for Thousands
    //         Math.trunc(Math.abs(Number(amount)));
    //   } else {
    //     return Math.abs(Number(amount)) >= 1.0e+9

    //       ? (Math.abs(Number(amount)) / 1.0e+9).toFixed(2) + "B"
    //       // Six Zeroes for Millions
    //       : Math.abs(Number(amount)) > 1.0e+6

    //         ? (Math.abs(Number(amount)) / 1.0e+6).toFixed(2) + "M"

    //         : Math.abs(Number(amount)) > 1.0e+3

    //           ? (Math.abs(Number(amount)) / 1.0e+3).toFixed(2) + "K"

    //           : Math.trunc(Math.abs(Number(amount)));
    //   }
    // } else {
    //   if (currency === "INR - ₹") {
    //     var x = amount.toString();
    //     var afterPoint = '';
    //     if (x.indexOf('.') > 0)
    //       afterPoint = x.substring(x.indexOf('.'), x.length);
    //     x = Math.floor(x);
    //     x = x.toString();
    //     var lastThree = x.substring(x.length - 3);
    //     var otherNumbers = x.substring(0, x.length - 3);
    //     if (otherNumbers != '')
    //       lastThree = ',' + lastThree;
    //     var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint.substring(0, 3);
    //     return res;
    //   }
    //   else {
    //     var x = amount.toString();
    //     var afterPoint = '';
    //     if (x.indexOf('.') > 0)
    //       afterPoint = x.substring(x.indexOf('.'), x.length);
    //     x = Math.floor(x);
    //     x = x.toString();
    //     var lastThree = x.substring(x.length - 3);
    //     var otherNumbers = x.substring(0, x.length - 3);
    //     if (otherNumbers != '')
    //       lastThree = ',' + lastThree;
    //     var res = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree + afterPoint.substring(0, 3);
    //     return res;
    //   }
    // }
    // // return cellInfo.valueText;
  }

  onKeyPress(e) {
    if (e.event.key == "-") {
      e.event.preventDefault();
    }
  }

  getEventEmp(e) {
    this.showDialogEmp = e;
    this.utilityService.hideOverlay()
    this.getEmployeeDetails();
  }

  todayDateText() {
    return "today";
  }

  customizeTooltip(args) {
    const now = moment();
    const vestingDate = moment(args.point.data["date"]);
    const actualVestedOptions = (vestingDate.isSameOrBefore(now))?("Total Actual Vested: " +
    args.point.data["accumulatedVestedOptionsForEmployee"] +
    "\n"):"";
    let tooltip = "";
    tooltip =
      "Total Granted: " +
      args.point.data["accumulatedGrantedOptionsForEmployee"] +
      "\n" +
      "Total Projected Vested: " +
      args.point.data["accumulatedProjectedVestedOptionsForEmployee"] +
      "\n" +
      actualVestedOptions +
      "Total Exercised: " +
      args.point.data["accumulatedExercisedOptionsForEmployee"];

    return {
      text: tooltip,
      fontColor: "#ffffff",
      color: "#000000",
    };
  }

  customCliffText(i) {
    return "Cliff " + ++i;
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if(this.searchFormControl.value.length < 1) return [];
    return this.totalSearchOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
