<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10">
        <label class="dialogue-title">Conversion</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <form [formGroup]="conversionDetails" novalidate>
    <div style="max-height:550px; overflow: auto;">
      <div class="section-padding">
          <div class="row btm-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the terms basis which the convertibles have converted to Equity/CCPS. Share Price is the price at which the conversion has happened, date is date of conversion, FDB is the total number of shares received on conversion. 
              </p>
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-12">
              <mat-card>
                <div class="row btm-20">
                  <div class="col-md-3">
                    <mat-label>
                        {{rowData.rowInfo.investorName ? 'Investor Name' : 'Buyer Name'}}
                    </mat-label>
                    <p>
                      <mat-label>
                        {{rowData.rowInfo.investorName ? rowData.rowInfo.investorName : rowData.buyerName}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Security
                    </mat-label>
                    <p>
                      <mat-label>
                      {{rowData.rowInfo.securityType}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      PAR
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.par ? (rowData.rowInfo.par | localeFormatter: 'tooltip-currency') : '-'}}">
                        {{rowData.rowInfo.par ? (rowData.rowInfo.par | localeFormatter: 'currency') : '-'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Premium
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.premium ? (rowData.rowInfo.premium | localeFormatter: 'tooltip-currency') : '-'}}">
                        {{rowData.rowInfo.premium ? (rowData.rowInfo.premium | localeFormatter: 'currency') : '-'}}
                      </mat-label>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <mat-label>
                      No. of Shares
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.numberOfShares | localeFormatter: 'tooltip-number'}}">
                        {{rowData.rowInfo.numberOfShares | localeFormatter: 'number'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Amount
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.amount | localeFormatter: 'tooltip-currency'}}">
                        {{rowData.rowInfo.amount | localeFormatter: 'currency'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Date
                    </mat-label>
                    <p>
                      <mat-label>
                      {{rowData.rowInfo.date | date: 'dd-MMM-yyyy'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Round
                    </mat-label>
                    <p>
                      <mat-label>
                        {{rowData.rowInfo.roundName}}
                      </mat-label>
                    </p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-6">
              <mat-label class="required"> Conversion Share Price ({{currencySymbol}})</mat-label>
              <input class="form-control top-10 text-align-right" type="text" (keydown)="onKeydownEvent($event)" (input)="csvVal($event.target.value, $event)" (keypress)="onlyNumberKey($event)" formControlName="conversionValue" placeholder="Enter Conversion Value" required/>
              <div class="alert alert-danger" *ngIf="conversionDetails.get('conversionValue').errors?.required && (conversionDetails.get('conversionValue').dirty || conversionDetails.get('conversionValue').touched || isSubmitted)">
                Conversion Share Price is required!
              </div>
            </div>
            <div class="col-md-6">
              <mat-label class="required"> Conversion Date </mat-label>
              <div style="position: relative;">
                <input matInput [matDatepicker]="vd1" formControlName="conversionDate" class="form-control top-10" title="" max="9999-12-31" placeholder="DD-MMM-YYYY" required />
                <mat-datepicker-toggle matSuffix [for]="vd1">
                  <mat-icon matDatepickerToggleIcon ></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #vd1></mat-datepicker>
              </div>
              <div class="alert alert-danger" *ngIf="conversionDetails.get('conversionDate').errors?.required && (conversionDetails.get('conversionDate').dirty || conversionDetails.get('conversionDate').touched || isSubmitted)">
                Conversion Date is required!
              </div>
            </div>
          </div>
          <div class="row btm-20">
            <!-- <div class="col-md-6">
              <mat-label class="required">Updated Conversion Ratio</mat-label>
              <div class="top-10">
                <input id="updateConversionRatio" type="checkbox" formControlName="isUpdateConversionRatio"
                  data-toggle="switch" checked data-inverse="false" data-off-color="default" title="" />
              </div>
            </div> -->
            <div class="col-md-6">
              <mat-label class="required"> Conversion Security </mat-label>
              <select class="form-control top-10" formControlName="conversionSecurity">
                  <option value=null disabled>Select Conversion Security</option>
                  <option value="Equity">Equity</option>
                  <option value="CCPS">CCPS</option>
              </select>
              <div class="alert alert-danger" *ngIf="conversionDetails.get('conversionSecurity').errors?.required && (conversionDetails.get('conversionSecurity').dirty || conversionDetails.get('conversionSecurity').touched || isSubmitted)">
                Conversion Security is required!
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row btm-20">
            <div class="col-md-6">
              <mat-label class="required"> FDB Shares </mat-label>
              <input class="form-control top-10 text-align-right" type="text" (keydown)="onKeydownEvent($event)" (input)="csvVal($event.target.value, $event)" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" formControlName="numberofSharesPostConversion" placeholder="FDB Shares" required/>
              <div class="alert alert-danger" *ngIf="conversionDetails.get('numberofSharesPostConversion').errors?.required && (conversionDetails.get('numberofSharesPostConversion').dirty || conversionDetails.get('numberofSharesPostConversion').touched || isSubmitted)">
                FDB Shares required!
              </div>
            </div>
            <div class="col-md-6">
                <mat-label class="required">Number of Shares to be Converted</mat-label>
                <input class="form-control top-10 text-align-right" type="text" (keydown)="onKeydownEvent($event)" (input)="csvVal($event.target.value, $event)" [min]="1" [max]="rowData.rowInfo.numberOfShares"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" formControlName="numberOfSharesToConvert" placeholder="Number of shares to be converted" required />
                <div class="alert alert-danger" *ngIf="conversionDetails.get('numberOfSharesToConvert').errors?.required && (conversionDetails.get('numberOfSharesToConvert').dirty || conversionDetails.get('numberOfSharesToConvert').touched || isSubmitted)">
                    No of Shares to be converted required!
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div *ngIf="!isShowNote" (click)="isShowNote = !isShowNote;">
                  <span>
                    <mat-label>Add Notes</mat-label><img class="left-10 cursor-hand" src="../../../../../assets/action/addnotes.svg" alt="add Notes" />
                  </span>
              </div>
              <div *ngIf="isShowNote" (click)="isShowNote = !isShowNote;">
                  <span>
                    <mat-label>Remove Notes</mat-label><img class="left-10 cursor-hand" src="../../../../../assets/action/removenotes.svg" alt="remove Notes" />
                  </span>
              </div>
                <textarea class="form-control top-10" *ngIf="isShowNote" formControlName="notes" placeholder="Add Notes"></textarea>
            </div>
          </div>
        </div>
        <div class="section-padding bottom-padding">
          <div class="row btm-20">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <button mat-raised-button id="save" color="success" type="button" [disableRipple]="true" ngbTooltip="Save" class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="saveConversion()">
                Save
              </button>
              <button mat-raised-button id="clear" color="warn" type="button" [disableRipple]="true" ngbTooltip="Rest form" class="col-xs-2 col-sm-2 col-md-2 margin-left-lg float-right" (click)="resetForm()">
                Clear
              </button>
              <button mat-raised-button id="cancel" color="warn" type="button" [disableRipple]="true" ngbTooltip="Cancel" class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>