import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-sheets',
  templateUrl: './term-sheets.component.html',
  styleUrls: ['./term-sheets.component.scss']
})
export class TermSheetsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
