<div class="">
 <div class="row " *ngIf="dataSource?.length > 0 && displayCols != undefined">

    <div class="col-md-12 " >
        <div class="btm-20">



            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 topPadding">
                <div class="row">

                    <span style="padding-top: 15px;font-weight: 900;font-size: 19px;" *ngIf="pageType == 'Dashboard' && !isSubsidiary">Cap Table</span>
                    <span style="padding-top: 15px;font-weight: 900;font-size: 19px;" *ngIf="isSubsidiary"><span [ngClass]="subsidiaryHasAccess ? 'subsidiary-company-name' : ''" (click)="switchCompany()">{{subsidiaryName}} </span>({{subsidiaryRelation == 'Parent' ? 'Holding' : subsidiaryRelation == 'Child' ? 'Subsidiary' : 'NA'}}) ({{subsidiaryCurrentCompanyHoldingPercentage != null && subsidiaryCurrentCompanyHoldingPercentage != undefined ? (subsidiaryCurrentCompanyHoldingPercentage | percent:'2.2') : 'NA'}})</span>
                    </div>
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4" >
                    <div class="coldgridOPerations">
                        <mat-form-field appearance="outline" >
                            <mat-label>
                              Search..
                            </mat-label>
                            <input [disabled]="!dataSource" type="text" [formControl]="searchFormControl"
                              (keyup)="searchEntered($event.target.value)" matInput [matAutocomplete]="auto" />
                            <mat-autocomplete (optionSelected)="searchEntered(searchFormControl.value)" #auto="matAutocomplete">
                              <mat-option *ngFor="let option of searchOptions | async" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-autocomplete>
                            <span matPrefix><i class="fa fa-search" aria-hidden="true"></i></span>
                          </mat-form-field>


                      <!-- <div style="display: inline-block; margin-left: 0.5em;">
                        <mat-form-field style="width: 40%;" ngbTooltip="Show Grants after this date" triggers="hover" (click)="picker.open()" appearance="outline">
                          <mat-label>Grant start date</mat-label>
                          <input [(ngModel)]="this.grantDateFilter.fromDate" matInput [max]="this.grantDateFilter.toDate"
                            (dateChange)="dateSelected('fromDate', $event)" readonly [matDatepicker]="picker">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field style="width: 40%;" ngbTooltip="Show Grants before this date" triggers="hover" (click)="pickerTo.open()" appearance="outline">
                          <mat-label>Grant end date</mat-label>
                          <input matInput [(ngModel)]="this.grantDateFilter.toDate" [min]="this.grantDateFilter.fromDate"
                            (dateChange)="dateSelected('toDate', $event)" readonly [matDatepicker]="pickerTo">
                          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                          <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                        <mat-icon class="column-chooser" *ngIf="this.grantDateFilter.fromDate || this.grantDateFilter.toDate"
                          (click)="clearDate()" ngbTooltip="Clear the Grant Date filter"><i class="fa fa-times"
                            aria-hidden="true"></i>
                        </mat-icon>
                        <mat-icon (click)="columnChooserClicked()" class="column-chooser" ngbTooltip="Column Chooser"><i
                            class="fa fa-columns fa-lg" aria-hidden="true"></i></mat-icon>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-sm-8 col-md-8 col-xs-8 col-lg-8 align-right"  >
                    <div class="coldgridOPerations align-right">
                        <mat-icon (click)="expandColumns()" ngbTooltip="Expand All/Collapse All"><img  style="height: 28px;margin-top: 15px;margin-left: -18px;" src="../../assets/expandcollbox.svg"><i
                            class=" fa-lg" aria-hidden="true"></i></mat-icon>

                         <mat-form-field style="width: 40%;" triggers="hover" (click)="picker.open()" appearance="outline"  *ngIf="incorporationDate">
                            <mat-label>Captable as on</mat-label>
                            <input min="{{incorporationDate|date:'yyyy-MM-dd'}}" max="{{currentDate|date:'yyyy-MM-dd'}}" [(ngModel)]="this.grantDateFilter.fromDate" matInput [max]="this.grantDateFilter.toDate"
                              (dateChange)="refreshCapTable($event)" readonly [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>

                         <!-- <span *ngIf="incorporationDate">
                <input matInput [matDatepicker]="dp" style="width: 200px; float: right; margin-left: 15px;" class="form-control"
                    placeholder="dd-mmm-yyyy" (dateChange)="refreshCapTable($event)" min="{{incorporationDate|date:'yyyy-MM-dd'}}" max="{{currentDate|date:'yyyy-MM-dd'}}" />
                <mat-datepicker-toggle matSuffix [for]="dp" matRippleDisabled="disabled" matRippleColor="red">
                    <mat-icon matDatepickerToggleIcon matRippleDisabled="disabled"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </span>
                         -->


                      <mat-form-field appearance="outline">
                        <mat-label>Export</mat-label>
                        <mat-select>
                            <mat-option *ngIf="pageType != 'Dashboard' && pageType != 'RoundOverview' && pageType != 'TransactionPreview' &&
                            pageType != 'RoundDetails' && pageType != 'SecuritiesOverview'" (click)="exportFile()" >Export
                            </mat-option>

                          <!-- Dashboard -->
                          <mat-option *ngIf="pageType == 'Dashboard'"  (click)="exportCaptableReport('round')" value="export">Export Round Based Report</mat-option>
                          <mat-option *ngIf="pageType == 'Dashboard'" (click)="exportCaptableReport('security')"
                            value="exportSelected">Export Security Based Report</mat-option>
                          <mat-option *ngIf="pageType == 'Dashboard'" (click)="exportShareholderReport()">Export Summary Report</mat-option>

                          <!-- Round Overview -->
                          <mat-option *ngIf="pageType == 'RoundOverview'" (click)="exportRoundOverview()">Export Summary Report</mat-option>
                          <mat-option *ngIf="pageType == 'RoundOverview'" (click)="exportCaptableReport('round')">Export Detailed Report</mat-option>

                          <!-- Round Details -->
                          <mat-option *ngIf="pageType == 'RoundDetails'"  (click)="exportIndividualRoundReport()">Export Summary Report</mat-option>
                          <mat-option *ngIf="pageType == 'RoundDetails'" (click)="exportCaptableReport('round')">Export Round Details Report</mat-option>
                          <mat-option *ngIf="pageType == 'RoundDetails'" (click)="exportPAS4File()">Export PAS4 Report</mat-option>

                          <!-- Security -->
                          <mat-option *ngIf="pageType == 'SecuritiesOverview'" (click)="exportSecuritiesOVerview()">Export Summary Report</mat-option>
                          <mat-option *ngIf="pageType == 'SecuritiesOverview'" (click)="exportCaptableReport('security')">Export Detailed Report</mat-option>

                          <!-- TransactionPreview -->
                          <mat-option *ngIf="pageType == 'TransactionPreview'" (click)="exportQuickRoundReport()">Export</mat-option>
                          <mat-option *ngIf="pageType == 'TransactionPreview'" (click)="exportQuickRoundPAS4()">Export PAS4 Report</mat-option>

                        </mat-select>
                      </mat-form-field>



                    </div>

                  </div>
                </div>
              </div>
            <!-- <div id="treeListGrid">
                <span  *ngIf="incorporationDate">
                    <input matInput [matDatepicker]="dp" style="width: 200px; float: right; margin-left: 15px;" class="form-control"
                        placeholder="dd-mmm-yyyy" (dateChange)="refreshCapTable($event)" min="{{incorporationDate|date:'yyyy-MM-dd'}}" max="{{currentDate|date:'yyyy-MM-dd'}}" />
                    <mat-datepicker-toggle matSuffix [for]="dp" matRippleDisabled="disabled" matRippleColor="red">
                        <mat-icon matDatepickerToggleIcon matRippleDisabled="disabled"></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </span>
                <img class="table-action-toolbar" title="Export" (click)="exportFile()" *ngIf="pageType != 'Dashboard'"
                    src="../../../assets/download.svg" style="height: 35px; margin-top: 10px;" />
                <img  class="table-action-toolbar" title="Export Round Based Report" (click)="exportFile('round')" *ngIf="pageType == 'Dashboard'"
                    src="../../../assets/download.svg" style="height: 35px; margin-top: 10px;" />
                <img  class="table-action-toolbar" title="Export Security Based Report" (click)="exportFile('security')" *ngIf="pageType == 'Dashboard'"
                    src="../../../assets/download.svg" style="height: 35px; margin-top: 10px;" />
                <img  class="table-action-toolbar" title="Expand All/Collapse All" (click)="expandAllItem()"
                    src="../../assets/expandcollbox.svg" style="height: 28px; margin-top: 17px;" />
            </div> -->
            <div class="">
            <dx-tree-list
                id="dev-ex-treelist"
                [dataSource]="dataSource"
                itemsExpr="shareholders"
                dataStructure="tree"
                [showRowLines]="true"
                [wordWrapEnabled]="true"
                [showBorders]="true"
                [columnAutoWidth]="true"
                [autoExpandAll]="expandAll"
                (onCellHoverChanged)="onCellHoverChanged($event)"
                >

                <dxo-search-panel  [visible]="false" [width]="240" placeholder="Search..."></dxo-search-panel>
                <dxo-header-filter [visible]="true" [allowSearch]="false"> </dxo-header-filter>

                <dxi-column  [width]="200" dataField="name" caption="Shareholder" cellTemplate="namesTemplate" [visible]="displayCols.shareholder"></dxi-column>
                <div *dxTemplate="let cell of 'namesTemplate'">
                    <span (click)="isSubsidiary ? '' : displayDetails(cell.data)" [ngClass]="{'cursor-hand' : cell.data.name != 'ESOP Pool Yet To Be Assigned'}">{{ cell.data.name }}</span>
                </div>

                <dxi-column dataField="name" [minWidth]="250" caption="Round Name" cellTemplate="roundNameTemplate" [visible]="displayCols.roundName"></dxi-column>
                <div *dxTemplate="let cell of 'roundNameTemplate'">
                    <span data-toggle="tooltip" data-placement="top" container="body" >
                        <img
                            *ngIf="cell.data.roundState === 'PARTIALLY_ISSUED'"
                            src="../../../assets/overview/PARTIALLY_ISSUED.svg"
                            ngbTooltip ="Partially Issued"
                        />
                        <img
                            *ngIf="cell.data.roundState === 'PROFORMA'"
                            src="../../../assets/overview/PROFORMA.svg"
                            ngbTooltip="Proforma"
                        />
                        <img
                            *ngIf="cell.data.roundState === 'CONSTRUCTED'"
                            src="../../../assets/overview/CONSTRUCTED.svg"
                            ngbTooltip ="Constructed"
                        />
                        <img
                            *ngIf="cell.data.roundState === 'ISSUE_IN_PROGRESS'"
                            src="../../../assets/overview/ISSUE_IN_PROGRESS.svg"
                            ngbTooltip="Issue in Progress"
                        />
                        <img
                            *ngIf="cell.data.roundState === 'COMPLETED'"
                            src="../../../assets/overview/COMPLETED.svg"
                            ngbTooltip="Completed"
                        />
                    </span>
                    <span (click)="displayRoundDetails(cell.data)" class="link-style cursor-hand">{{ cell.data.name }}</span>
                    <div *ngIf="!cell.data.isPricedRound"
                            class="speech-bubble-red-bottom bubble-txt new-inv-label left-10 top-5">
                            Non-Priced Round</div>
                </div>

                <dxi-column dataField="closingDate" [width]="128" format="dd-MMM-yyyy" caption="Closing Date" dataType="date" [visible]="displayCols.closingDate"></dxi-column>

                <dxi-column dataField="name" caption="Security Type" cellTemplate="namesTemplate" [visible]="displayCols.securityType"></dxi-column>
                <div *dxTemplate="let cell of 'namesTemplate'">
                    <span (click)="displayDetails(cell.data)" [ngClass]="{'cursor-hand' : cell.data.name != 'ESOP Pool Yet To Be Assigned', 'total-style' : cell.data.name == 'Total'}">{{ cell.data.name }}</span>
                </div>

                <dxi-column [width]="200" dataField="roundDetails" caption="Rounds" cellTemplate="roundsTemplate" [visible]="displayCols.rounds"
                    [calculateCellValue]="calculateCellValue"
                    [calculateFilterExpression]="calculateRoundFilterExpression">
                    <dxo-header-filter [dataSource]="roundsFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'roundsTemplate'">
                    <span *ngIf="cellData.value.length >= 4">
                        <span *ngFor="let round of cellData.value; let i=index" >
                            <span *ngIf="i<3" class="datalist-rnd-identifier" (click)="isSubsidiary ? '' : goToRoundDetails(round, cellData.data.roundDetails)" tooltipClass="tooltip-spacing" data-container="body"
                            [ngbTooltip]="showRoundTooltip(round, cellData.data.roundDetails, cellData)" [ngClass]="identifierCSS(round)"
                            [ngStyle]="round != 'ES' ? {'cursor': 'pointer'} : {'cursor': 'context-menu'}">
                                {{ round }}
                            </span>
                        </span>
                        <span [matMenuTriggerFor]="menu" class="expand-indicator" > +{{ cellData.value.length - 3 }}</span>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <div *ngFor="let round of cellData.value; let i=index" class="flex-container" >
                                <span class="datalist-rnd-identifier" (click)="isSubsidiary ? '' : goToRoundDetails(round, cellData.data.roundDetails)" tooltipClass="tooltip-spacing"
                                    data-container="body" [ngClass]="identifierCSS(round)" style="width: fit-content;" [ngbTooltip]="showRoundTooltip(round, cellData.data.roundDetails, cellData)"
                                    [ngStyle]="round != 'ES' ? {'cursor': 'pointer'} : {'cursor': 'context-menu'}">
                                    {{ round }}
                                </span>
                                <span style="width: max-content;">{{ getRoundName(round, cellData.data.roundDetails) }}</span>
                            </div>
                        </mat-menu>
                    </span>
                    <span *ngIf="cellData.value.length < 4">
                        <span *ngFor="let round of cellData.value" class="datalist-rnd-identifier" (click)="isSubsidiary ? '' : goToRoundDetails(round, cellData.data.roundDetails)" tooltipClass="tooltip-spacing" data-container="body"
                            [ngbTooltip]="showRoundTooltip(round, cellData.data.roundDetails, cellData)" [ngClass]="identifierCSS(round)"
                            [ngStyle]="round != 'ES' ? {'cursor': 'pointer'} : {'cursor': 'context-menu'}">
                            {{ round }}
                        </span>
                    </span>
                </div>

                <dxi-column dataField="commonShares" caption="Pre Split Common" cellTemplate="preCommonTemplate" [visible]="displayCols.preSplitCommon">
                    <dxo-header-filter [dataSource]="commonFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'preCommonTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="commonSharesPost" caption="Post Split Common" cellTemplate="postCommonTemplate" [visible]="displayCols.postSplitCommon">
                    <dxo-header-filter [dataSource]="commonFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'postCommonTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="commonShares" caption="Common" cellTemplate="commonTemplate" [visible]="displayCols.common">
                    <dxo-header-filter [dataSource]="commonFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'commonTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="preferenceShares" caption="Pre Split Preference" cellTemplate="prePreferenceTemplate" [visible]="displayCols.preSplitPreference">
                    <dxo-header-filter [dataSource]="preferenceFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'prePreferenceTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="preferenceSharesPost" caption="Post Split Preference" cellTemplate="postPreferenceTemplate" [visible]="displayCols.postSplitPreference">
                    <dxo-header-filter [dataSource]="preferenceFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'postPreferenceTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="preferenceShares" caption="Preference" cellTemplate="preferenceTemplate" [visible]="displayCols.preference">
                    <dxo-header-filter [dataSource]="preferenceFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'preferenceTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="debentureShares" caption="Debentures" cellTemplate="debentureTemplate" [visible]="displayCols.debentures">
                    <dxo-header-filter [dataSource]="debentureFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'debentureTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="warrants" caption="Warrants" cellTemplate="warrantsTemplate" [visible]="displayCols.warrants">
                    <dxo-header-filter [dataSource]="warrantsFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'warrantsTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="preTotalDilutedShares" caption="Pre Split FDB Shares" cellTemplate="preFdbTemplate" [visible]="displayCols.preSplitFdbShares">
                    <dxo-header-filter [dataSource]="fdbFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'preFdbTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="postTotalDilutedShares" caption="Post Split FDB Shares" cellTemplate="postFdbTemplate" [visible]="displayCols.postSplitFdbShares">
                    <dxo-header-filter [dataSource]="fdbFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'postFdbTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="totalDilutedShares" caption="FDB Shares" cellTemplate="fdbTemplate" [visible]="displayCols.fdbShares">
                    <dxo-header-filter [dataSource]="fdbFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'fdbTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="preHoldingPercentage" caption="Pre-Round Holding %" cellTemplate="preRoundHoldingTemplate" [visible]="displayCols.preHoldingRound">
                    <dxo-header-filter [dataSource]="preHoldingFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'preRoundHoldingTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ cellData.value | percent:'2.2' }}</span>
                </div>

                <dxi-column dataField="preHoldingPercentage" caption="Pre Holding %" cellTemplate="preHoldingTemplate" [visible]="displayCols.preHolding">
                    <dxo-header-filter [dataSource]="preHoldingFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'preHoldingTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ cellData.value | percent:'2.2' }}</span>
                </div>

                <dxi-column dataField="holdingPercentage" caption="Post-Round Holding %" cellTemplate="postRoundHoldingTemplate" [visible]="displayCols.postHoldingRound">
                    <dxo-header-filter [dataSource]="holdingFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'postRoundHoldingTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ cellData.value | percent:'2.2' }}</span>
                </div>

                <dxi-column dataField="holdingPercentage" caption="Post Holding %" cellTemplate="postHoldingTemplate" [visible]="displayCols.postHolding">
                    <dxo-header-filter [dataSource]="holdingFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'postHoldingTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ cellData.value | percent:'2.2' }}</span>
                </div>

                <dxi-column dataField="holdingPercentage" caption="Holding %" cellTemplate="holdingPerTemplate" [visible]="displayCols.holding">
                    <dxo-header-filter [dataSource]="holdingFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'holdingPerTemplate'">
                    <mat-label *ngIf="cellData.data['isDebtShareholder'] && incorporationDate"
                        class="speech-bubble-right bubble-txt custom-bubble">
                        Debt</mat-label>
                    <mat-label *ngIf="cellData.data['isPartlyPaidShare'] && incorporationDate"
                        class="speech-bubble-right-orange bubble-txt custom-bubble">
                        Partly Paid
                    </mat-label>
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ cellData.value | percent:'2.2' }}</span>
                </div>

                <dxi-column dataField="preTotalShares" caption="Pre-Round Holding Shares" cellTemplate="preRoundSharesTemplate" [visible]="displayCols.preRoundShares">
                    <dxo-header-filter [dataSource]="noOfSharesFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'preRoundSharesTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="totalShares" caption="Post-Round Holding Shares" cellTemplate="postRoundSharesTemplate" [visible]="displayCols.postRoundShares">
                    <dxo-header-filter [dataSource]="noOfSharesFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'postRoundSharesTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="totalShares" caption="Number of Shares" cellTemplate="noOfSharesTemplate" [visible]="displayCols.noOfShares">
                    <dxo-header-filter [dataSource]="noOfSharesFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'noOfSharesTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="parValue" caption="PAR Value" cellTemplate="parValueTemplate" [visible]="displayCols.parValue">
                    <dxo-header-filter [dataSource]="parValueFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'parValueTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="capitalValue" caption="Capital Value" cellTemplate="capitalValueTemplate" [visible]="displayCols.capitalValue">
                    <dxo-header-filter [dataSource]="capitalValueFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'capitalValueTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="pricePerShare" [width]="145" caption="Price per Share" cellTemplate="ppShareTemplate" [visible]="displayCols.pricePerShare">
                    <dxo-header-filter [dataSource]="ppShareFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'ppShareTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="newAntidDilutionShares" [width]="145" caption="New Antidilution Shares" cellTemplate="newAntiDilutionSharesTemplate" [visible]="displayCols.newAntidDilutionShares">
                    <dxo-header-filter [dataSource]="noOfSharesFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'newAntiDilutionSharesTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="amountInvested" [width]="135" caption="Amount Raised" cellTemplate="amountInvestedTemplate" [visible]="displayCols.roundSize">
                    <dxo-header-filter [dataSource]="amountInvestedFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'amountInvestedTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="amountInvested" [width]="135" caption="Amount Invested in this Round" cellTemplate="amountInvestedTemplate" [visible]="displayCols.amtInvestedInRound">
                    <dxo-header-filter [dataSource]="amountInvestedFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'amountInvestedTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="amountInvested" [width]="135" caption="Amount Invested" cellTemplate="amountInvestedTemplate" [visible]="displayCols.amtInvested">
                    <dxo-header-filter [dataSource]="amountInvestedFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'amountInvestedTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="totalInvestment" [width]="135" caption="Total Investment" cellTemplate="totalInvTemplate" [visible]="displayCols.totalInvestment">
                    <dxo-header-filter [dataSource]="totalInvFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'totalInvTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="netWorth" caption="Net Worth" cellTemplate="netWorthTemplate" [visible]="displayCols.netWorth">
                    <dxo-header-filter [dataSource]="netWorthFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'netWorthTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="netWorth" caption="Current Value" cellTemplate="currValueTemplate" [visible]="displayCols.currentValue">
                    <dxo-header-filter [dataSource]="netWorthFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'currValueTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="gainPercenatage" caption="Gain %" cellTemplate="gainPerTemplate" [visible]="displayCols.percentageOfGain">
                    <dxo-header-filter [dataSource]="perGainFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'gainPerTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{cellData.value | percent:'2.2'}}</span>
                </div>

                <dxi-column dataField="postMoneyValuation" [width]="140" caption="Valuation (Post)" cellTemplate="postMoneyTemplate" [visible]="displayCols.postMoneyValuation">
                    <dxo-header-filter [dataSource]="postMoneyFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'postMoneyTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{ getTotalValueTble(cellData.value) }}</span>
                </div>

                <dxi-column dataField="dilutionPercentage" [minWidth]="90" caption="Dilution %" cellTemplate="dilutionPerTemplate" [visible]="displayCols.dilutionPercentage">
                    <dxo-header-filter [dataSource]="dilutionPerFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'dilutionPerTemplate'">
                    <span [ngClass]="{'total-style' : cellData.data.name == 'Total'}">{{cellData.value | percent:'2.2'}}</span>
                </div>

                <dxi-column dataField="leadInvestorName" caption="Lead Investor" [visible]="displayCols.leadInvestor"></dxi-column>

                <dxi-column dataField="pictureURL" caption=" " [allowFiltering]="false" [visible]="displayCols.document" cellTemplate="documentTemplate">
                    <div *dxTemplate="let cellData of 'documentTemplate'">
                        <span (click)="goToDocument(cellData.data)" ngbTooltip="View Document" placement="left" container="body"><img src="../../../assets/new-document.svg" /></span>
                    </div>
                </dxi-column>
            </dx-tree-list>
            </div>
            <dx-tooltip [(target)]="toolTipTarget" position="right" opacity="0.8">
                <div *dxTemplate="let data of 'content'">
                  <div class="tooltip-content">{{toolTipText}}</div>
                </div>
            </dx-tooltip>
        </div>
    </div>

    <div class="col-md-12 text-align-right notes-tbl btm-20">
        <span>All numbers are in {{subsidiaryCurrency ? subsidiaryCurrency : usrCompanyCurrency}} unless explicitly mentioned</span>
    </div>
    <app-esop-plan-popup *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog" [(isPlan)]="isPlan"
            [(selectedSecurity)]="selectedSecurity" (visibleChange)="getEvent($event)"></app-esop-plan-popup>
</div>
</div>
