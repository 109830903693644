import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { UtilityService } from '../../services/utilities.service';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(
    public  utilityService: UtilityService,
    public eventEmitterService : EventEmitterService
  ) { }

  ngOnInit() {
  }

}
