<h1 mat-dialog-title>Confirm Rejection of Plan ?</h1>
<div mat-dialog-content>
  <p class="required">Reason for rejection</p>
  <mat-form-field style="width: 100%;">
    <textarea  matInput rows="5" style=" border: 1px solid #ced4da; border-radius: 0.25rem;" [(ngModel)]="note" #reasonErr="ngModel" required></textarea>
      <div *ngIf="(reasonErr.dirty || reasonErr.touched) && !note" class="alert alert-danger">
        Reason is required
      </div>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button style="background-color:#CD4927" color="primary"  (click)="checkValidation()"  cdkFocusInitial>Confirm</button>
</div>
