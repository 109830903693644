import { Component, OnInit,  Input, OnChanges } from '@angular/core';
import { UtilityService } from '../services/utilities.service';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {
  
  @Input() public passwordToCheck: string;
  @Input() public barLabel: string; 
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;

  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];

  constructor(private readonly utilityService: UtilityService) { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    const password = this.passwordToCheck;
    this.setBarColors(4, '#DDD');
    if (password) {
      const c = this.getColor(this.checkStrength(password));
      this.setBarColors(c.index, c.color);
    }
  }

  private getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index]
    };
  }
  
  private setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }
  
  checkStrength(p) {
    return this.utilityService.checkStrength(p)
  }

}
