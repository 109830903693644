export class Investors {
    investorName: string;
    amount: number;
    premium: number;
    date: Date;
    numberOfShares: number;
    par?: number;
    securityType: string;
    roundName : string;
    roundIdentifier?: string;
    isValueEnabled?: boolean;
    investorProfile?:any;
    conversionDetails?: ConversionDetails
    redemptionDetails?: RedemptionDetails
}

export class Secondaries {
    buyer: string;
    seller: string;
    numberOfShares: number;
    pricePerShare: number;
    amount: number;
    securityType: string;
    date: Date;
}

export class SplitDetails {
    splitNumber: number;
    securityType: string;
    date: Date;
}

export class ConversionDetails {
    conversionValue: number;
    conversionDate: Date;
    isUpdateConversionRatio: boolean;
    conversionSecurity?: string;
    notes?: string
}

export class RedemptionDetails {
    redeemedAmount: number;
    amountLeft: number;
    redeemedDate: Date;
    notes?: string
}

export class CompanyCapitalDetails{
    equityAuthorizedShareCapital: number = null;
    preferenceAuthorizedShareCapital: number = null;
    equityPaidUpShareCapital: number = null;
    preferencePaidUpShareCapital: number = null;
    equityIssuedShareCapital?: number = null;
    preferenceIssueShareCapital?: number = null;
}

export class HistoricalRoundObject {
    investors: Array<Investors> = [];
    secondaries: Array<Secondaries> = [];
    esopPool: Array<any> = [];
    securitiesDropdown: Array<string> = [];
    investorsDropdown: Array<string> = [];
    splitDetails: Array<SplitDetails> = [];
    companyCapitalDetails: CompanyCapitalDetails;
    historicalRoundPopupDetails: Array<any> = [];
}