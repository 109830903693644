import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeeDetailsComponent } from '../employees/employee-details/employee-details.component';
import { FaqComponent } from './faq/faq.component';
import { CanShowFaq, ShouldShowPage } from './employee-route-guard'

const routes: Routes = [
    { path: "employeeDetails/:id", canActivate:[ShouldShowPage], component: EmployeeDetailsComponent },
    {path:"faq", component:FaqComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeesRoutingModule { 

}

