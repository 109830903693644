<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h5 class="page-hdr btm-15"><span *ngIf="resolutionName">{{resolutionName}} - </span> Document Previewer</h5>
      <p class="content-pg-1 btm-20">The Document Generator allows you to create board and share holder resolutions, ESOP grant letters and any other legal agreements. Upload a template in the hissa format to create list of questions and tags to assemble a complete document.</p>
        <div>
          <button mat-raised-button is="downloadAllDocuments" type="button" color="success" [disableRipple]="true" class="float-right left-10 btm-15" (click)="downloadAllDocuments()">
            Download All Documents
          </button>
          <button mat-raised-button id="re-build" type="button" color="success" [disableRipple]="true" class="float-right left-10 btm-15" (click)="onRebuild()">
            Re-Build
          </button>
          <button mat-raised-button id="back" type="button" color="warn" [disableRipple]="true" class="float-right left-10 btm-15" (click)="onBack()">
            Back
          </button>

        </div>
    </div>
  </div>
  <div class="row documents-container">
    <div class="col-4 documents-list">
      <div *ngFor="let document of documentList; let i = index" class="document-name" [ngClass]="{'selected-document': i == selectedDocumentIndex}" (click)="onChangeDocument(i, $event)">
        <div> 
          {{document.templateName}} 
          <span (click)="downloadResolutionDocument(document)">
            <img src="../../../assets/download_white.svg" *ngIf="i == selectedDocumentIndex"/>
            <img src="../../../assets/download.svg" *ngIf="i != selectedDocumentIndex"/>
          </span>
        </div>
      </div>
    </div>
    <div class="col-8 preview">
      <iframe #document_frame class="document-frame" [src]="pdfFile" *ngIf="isPdfFile"></iframe>
        <ejs-documenteditorcontainer *ngIf="isWordFile" #documenteditor_default [enableToolbar]="false" [isReadOnly]= "true" [enablePrint]="false" (created)="onCreate()" width="100%"  height="100%" [showPropertiesPane]="false" [zoomFactor]=1.25 style="display:block; overflow: hidden;"></ejs-documenteditorcontainer>
    </div>
  </div>
</div>