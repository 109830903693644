<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">Add Round Name</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div style="max-height: 80vh; overflow-y: scroll;">
    <form [formGroup]="roundDetails" novalidate>
      <div class="section-padding">
          <div class="row btm-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the name of the round in sequence of the funding rounds raised till date.
              </p>
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-6">
              <mat-label class="required">Round Name</mat-label>

              <input type="text" formControlName="roundName" (change)="onRoundChanged($event)" list="rounds" class="form-control top-10" />
              <datalist id="rounds">
                <option style="background-color: #ffffff;" *ngFor="let item of roundData['existingRoundDetails']; let i = index" [value]="item.roundName">{{ item.roundName }}</option>
              </datalist>

              <!-- <input class="form-control top-10" type="text" formControlName="roundName" placeholder="Enter Round Name" required/> -->
              <div class="alert alert-danger" *ngIf=" (roundDetails?.get('roundName').errors?.required || roundDetails?.get('roundName').value.trim().length == 0) && (roundDetails?.get('roundName').dirty || roundDetails?.get('roundName').touched || isSubmitted)">
                Round Name is required!
              </div>
            </div>
            <div class="col-md-6">
              <mat-label class="required">Round Identifier</mat-label>
              <input class="form-control top-10" type="text" maxlength="4" formControlName="roundIdentifier" placeholder="Enter Round Identifier" required/>
              <div class="alert alert-danger" *ngIf=" (roundDetails?.get('roundIdentifier').errors?.required || roundDetails?.get('roundIdentifier').value.trim().length == 0) && (roundDetails?.get('roundIdentifier').dirty || roundDetails?.get('roundIdentifier').touched || isSubmitted)">
                Round Identifier is required!
              </div>
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-4">
                <mat-label>Priced Round</mat-label>
                <div class="top-10">
                    <input id="pricedRound" type="checkbox" formControlName="isPricedRound"
                    data-toggle="switch" checked data-inverse="false" data-off-color="default" title="" />
                </div>
            </div>
            <div class="col-md-4">
                <mat-label>Conversion Ratio</mat-label>
                <input formControlName="roundConversionRatio" class="form-control text-align-right top-10" [readonly]="editConversionTable.length > 0"
                    type="text" placeholder="Conversion Ratio" (blur)="checkRatioVal($event.target.value, 'convRatio')"
                    min="0" (keydown)=onKeydownEvent($event) />
                <div class="alert alert-danger" *ngIf="convRatioInvalid">
                    <div>Conversion ratio invalid.</div>
                </div>
            </div>
            <div class="col-md-4">
                <mat-label>Voting Rights Ratio</mat-label>
                <input formControlName="roundVotingRightsRatio" class="form-control text-align-right top-10"
                    type="text" placeholder="Voting Rights Ratio" (blur)="checkRatioVal($event.target.value, 'votingRights')"
                    min="0" (keydown)=onKeydownEvent($event) />
                <div class="alert alert-danger" *ngIf="votingRatioInvalid">
                    <div>Voting rights ratio invalid.</div>
                </div>
            </div>
          </div>
      </div>
    </form>
    <div class="section-padding">
      <div class="row">
        <div class="col-md-12">
          <table id="dataTable" class="table-common">
            <thead>
              <th>Date</th>
              <th>Is Valuation Available</th>
              <th>Valuation</th>
              <th>Conversion Ratio</th>
              <th class="width-10"><img (click)="addNewConversion()" class="add-row-right cursor-hand" src="../../../../assets/action/addnew.svg" /></th>
            </thead>
            <tbody>
              <tr *ngFor="let item of editConversionTable; let i = index;">
                <td>
                  <div style="position: relative; width: 200px;">
                    <input matInput [matDatepicker]="vd1" [(ngModel)]="item.date" class="form-control" title="" 
                      max="9999-12-31" [readonly]="!item.isEditable || editConversionRow != i" placeholder="DD-MMM-YYYY" />
                    <mat-datepicker-toggle matSuffix [for]="vd1">
                        <mat-icon matDatepickerToggleIcon ></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #vd1 [disabled]="!item.isEditable || editConversionRow != i"></mat-datepicker>
                  </div>
                </td>
                <td>
                  <input [id]="i" [(ngModel)]="item.isValuationAvailable" type="checkbox" data-toggle="switch" checked
                      data-inverse="false" data-off-color="default" title=""/>
                </td>
                <td>
                  <input type="text" class="form-control" [value]="item.isValuationAvailable ? getAmount(usrCompanyCurrency, item.valuation) : '-NA-'" [(ngModel)]="item.valuation" [readonly]="!item.isValuationAvailable || !item.isEditable || editConversionRow != i" />
                </td>
                <td>
                  <input type="text" class="form-control" [value]="item.isValuationAvailable ? '-NA-' : item.updatedConversionRatio" [(ngModel)]="item.updatedConversionRatio" [readonly]="item.isValuationAvailable || !item.isEditable || editConversionRow != i" />
                </td>
                <td style="min-width: 70px">
                  <img class="cursor-hand" *ngIf="editConversionRow != i && item.isEditable" src="../../../../assets/action/edit.svg" (click)="editConversion(i)" />
                  <img class="cursor-hand" *ngIf="editConversionRow == i && item.isEditable" src="../../../../assets/save.svg" (click)="saveConversion(i)" />
                  <img class="left-10 cursor-hand" *ngIf="item.isEditable" (click)="removeConversionRow(i)" src="../../../../assets/action/trash-bin.svg">
                </td>
              </tr>
              <!-- <tr *ngFor="let item of roundData['existingRoundDetails']; let i = index;">
                <td>
                  {{ item.date | date: 'dd-MMM-yyyy' }}
                </td>
                <td>
                  {{ item.isValuationAvailable == true ? 'Yes' : 'No' }}
                </td>
                <td>
                  <span *ngIf="item.isValuationAvailable; else NA1_template">{{ item.valuation }}</span>
                  <ng-template #NA1_template>
                    -
                  </ng-template>
                </td>
                <td>
                  <span *ngIf="item.isValuationAvailable == false; else NA2_template">{{ item.updatedConversionRatio }}</span>
                  <ng-template #NA2_template>
                    -
                  </ng-template>
                </td>
                <td></td>
              </tr> -->
              <tr *ngIf="editConversionTable.length == 0">
                <td colspan="5" class="pl-xs-20">
                    <p>Click on '+' to add a new row</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="section-padding bottom-padding">
      <div class="row btm-20">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <button mat-raised-button id="save" color="success" type="button" [disableRipple]="true" ngbTooltip="Save" class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="saveRoundDetails()">
            Save
          </button>
          <button mat-raised-button id="cancel" color="warn" type="button" [disableRipple]="true" ngbTooltip="Cancel" class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </form> -->
</div>