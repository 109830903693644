import { ToastrService } from 'ngx-toastr';
import { Injectable, Output, EventEmitter } from "@angular/core";
import { Observable, Subject } from "rxjs";
import jwtDecode from 'jwt-decode';
import { environment } from "src/environments/environment";
// import * as CryptoJS from 'crypto-js';
@Injectable()
export class UtilityService {
  private dataObs$ = new Subject();
  constructor(private readonly toast: ToastrService){}
  colorPalatteforGrants = {
    granted: { background: " #E9F9E6", text: "#2BC30D" },
    offered: { background: " #EAF5FD", text: "#2D9BF0" },
    approved: { background: " #E7FAFB", text: "#0DA789" },
    created: { background: " #E7EFF7", text: "#1261B3" },
    for_approval: { background: " #FEF9E7", text: "#FBC710" },
    not_approved: { background: " #F4E7F7", text: "#652CB3" },
    offer_rejected: { background: " #FEECE9 ", text: "#F24726" },
  };
  colorPaletteforPlanState = {
    constructed: { background: "#E7EFF7", text: "#1261B3" },
    for_approval: { background: "#FEF9E7", text: "#FBC710" },
    amendment_rejected: { background: "#F4E7F7", text: "#652CB3" },
    rejected: { background: "#FEECE9", text: "#F24726" },
    amendment: { background: "#E7FAFB", text: "#0DA789" },
    retired: { background: "#EAF5FD", text: "#2D9BF0" },
    active: { background: "#E9F9E6", text: "#2BC30D" },
  };
  navMenu = {
    capTable: {
      children: [
        {
          name: "Cap Table",
          path: "capTable",
          redirectPath: "./dashboard/capTable",
        },
      ],
    },
    transactions: {
      children: [
        {
          name: "Rounds",
          path: "rounds",
          redirectPath: "./dashboard/rounds/overview",
        },
        {
          name: "Buy-backs",
          path: "buy-back",
          redirectPath: "./dashboard/buy-back/overview",
        },
        {
          name: "Secondary",
          path: "secondary",
          redirectPath: "./dashboard/secondary/overview",
        },
        {
          name: "Splits",
          path: "splits",
          redirectPath: "./dashboard/splits/overview",
        },
        {
          name: "Bonus",
          path: "bonus",
          redirectPath: "./dashboard/bonus/overview",
        },
        {
          name: "All-Transactions",
          path: "all-transactions",
          redirectPath: "./dashboard/transactions/overview",
        },
        {
          name: "Term Sheets",
          path: "term-sheets",
          redirectPath: "./dashboard/term-sheets/overview",
        },
      ],
    },
    shareholders: {
      children: [
        {
          name: "Shareholders",
          path: "shareholders",
          redirectPath: "./shareholders/overview",
        },
      ],
    },
    options: {
      children: [
        {
          name: "ESOP Overview",
          path: "overviewEsop",
          redirectPath: "./dashboard/options/overviewEsop",
        },
        {
          name: "Employees Overview",
          path: "overviewEmployees",
          redirectPath: "./dashboard/options/overviewEmployees",
        },
        {
          name: "ESOP Management",
          path: "manageEsop",
          redirectPath: "./dashboard/options/manageEsop",
        },
        {
          name: "Vesting Schedules",
          path: "vestingSchedule",
          redirectPath: "./dashboard/options/vestingSchedule",
        },
      ],
    },

    modeling: {
      children: [
        {
          name: "Round Modeling",
          path: "roundModelingTab",
          redirectPath: "./dashboard/modeling/roundModelingTab",
        },
        {
          name: "Esop Modeling",
          path: "esopModellingTab",
          redirectPath: "./dashboard/modeling/esopModellingTab",
        },
        {
          name: "Exit Modeling",
          path: "exitModelling",
          redirectPath: "./dashboard/modeling/exitModelling",
        },
      ],
    },
    settings: {
      children: [
        {
          name: "Company Profile",
          path: "companyProfile",
          redirectPath: "./dashboard/settings/companyProfile",
        },
        {
          name: "Site Settings",
          path: "siteSettings",
          redirectPath: "./dashboard/settings/siteSettings",
        },
      ],
    },
  };

  navmenu = [
    {
      name: "Cap Table",
      path: "capTable",
      redirectPath: "./dashboard/capTable",
    },
    {
      name: "Transaction",
      path: "transactions",
      redirectPath: "./dashboard/rounds/overview",
      children: [
        {
          name: "Rounds",
          path: "rounds",
          redirectPath: "./dashboard/rounds/overview",
        },
        {
          name: "Buy backs",
          path: "buy-back",
          redirectPath: "./dashboard/buy-back/overview",
        },
        {
          name: "Buy back details",
          path: "buy-back/details",
          redirectPath: "./dashboard/buy-back/details",
          shouldIgnore: true,
          activeLink: "./dashboard/buy-back/overview",
          shouldHide: true,
        },
        {
          name: "Secondary",
          path: "secondary",
          redirectPath: "./dashboard/secondary/overview",
        },
        {
          name: "secondary seller",
          path: "secondary-seller",
          redirectPath: "./dashboard/secondary/secondary-summary",
          shouldIgnore: true,
          activeLink: "./dashboard/secondary/overview",
          shouldHide: true,
        },
        {
          name: "secondary buyer",
          path: "secondary-buyer",
          redirectPath: "./dashboard/secondary/secondary-buyer",
          shouldIgnore: true,
          activeLink: "./dashboard/secondary/overview",
          shouldHide: true,
        },
        // {
        //   name:"documents",
        //   path:"documents",
        //   redirectPath:"./dashboard/documents",
        //   shouldIgnore: true,
        //   activeLink: "./dashboard/secondary/overview",
        //   shouldHide: true,
        // },
        {
          name: "Splits",
          path: "splits",
          redirectPath: "./dashboard/splits/overview",
        },
        {
          name: "Bonus",
          path: "bonus",
          redirectPath: "./dashboard/bonus/overview",
        },
        {
          name: "All-Transactions",
          path: "all-transactions",
          redirectPath: "./dashboard/transactions/overview",
        },
        {
          name: "Term Sheets",
          path: "term-sheets",
          redirectPath: "./dashboard/term-sheets/overview",
        },
        {
          name: "Secondary Seller",
          path: "secondary-seller",
          redirectPath: "./dashboard/secondary/secondary-seller",
          shouldIgnore: true,
          activeLink: "./dashboard/secondary/overview",
          shouldHide: true,
        },
        {
          name: "Quick Round",
          path: "quick-round",
          redirectPath: "./dashboard/transactions/quick-round",
          shouldIgnore: true,
          activeLink: "./dashboard/rounds/overview",
          shouldHide: true,
        },
        {
          name: "Round Details",
          path: "round-details",
          redirectPath: "./dashboard/rounds/round-details",
          shouldIgnore: true,
          activeLink: "./dashboard/rounds/overview",
          shouldHide: true,
        },
        {
          name: "New Split",
          path: "new-split",
          redirectPath: "./dashboard/splits",
          shouldIgnore: true,
          activeLink: "./dashboard/splits/overview",
          shouldHide: true,
        },
        {
          name: "New Bonus",
          path: "new-bonus",
          redirectPath: "./dashboard/bonus",
          shouldIgnore: true,
          activeLink: "./dashboard/bonus/overview",
          shouldHide: true,
        },
        {
          name: "New BuyBack",
          path: "newBuyBack",
          redirectPath: "./dashboard/buy-back/newBuyBack",
          shouldIgnore: true,
          activeLink: "./dashboard/buy-back/overview",
          shouldHide: true,
        },
        {
          name: "New Round",
          path: "newRound",
          redirectPath: "./dashboard/rounds/newRound",
          shouldIgnore: true,
          activeLink: "./dashboard/rounds/overview",
          shouldHide: true,
        },
      ],
    },
    {
      name: "Shareholders",
      path: "shareholders",
      redirectPath: "./dashboard/shareholders/overview",
    },
    {
      name: "Securities",
      path: "securities",
      redirectPath: "./dashboard/securities/overview",
    },
    {
      name: "Options",
      path: "options",
      redirectPath: "./dashboard/options/overviewEsop",
      children: [
        {
          name: "ESOP Overview",
          path: "overviewEsop",
          redirectPath: "./dashboard/options/overviewEsop",
        },
        {
          name: "Employees Overview",
          path: "overviewEmployees",
          redirectPath: "./dashboard/options/overviewEmployees",
        },
        {
          name: "ESOP Management",
          path: "manageEsop",
          redirectPath: "./dashboard/options/manageEsop",
        },
        {
          name: "Vesting Schedules",
          path: "vestingSchedule",
          redirectPath: "./dashboard/options/vestingSchedule",
        },
        {
          name: "Options Buy-back",
          path: "optionsBuyback/overview",
          redirectPath: "./dashboard/options/optionsBuyback/overview",
        },
        {
          name: "Add Pool",
          path: "addPool",
          redirectPath: "./dashboard/options/addPool/true",
          shouldIgnore: true,
          activeLink: "./dashboard/options/overviewEsop",
          shouldHide: true,
        },
        {
          name: "Create Esop",
          path: "createEsop",
          redirectPath: "./dashboard/options/createEsop",
          shouldIgnore: true,
          activeLink: "./dashboard/options/overviewEsop",
          shouldHide: true,
        },
        {
          name: "Create Esop",
          path: "employee",
          redirectPath: "./dashboard/options/employee/employeeDetails",
          shouldIgnore: true,
          activeLink: "./dashboard/options/manageEsop",
          shouldHide: true,
        },
        {
          name: "Create Option Buyback",
          path: "optionsBuyback",
          redirectPath: "./dashboard/options/optionsBuyback",
          shouldIgnore: true,
          activeLink: "./dashboard/options/optionsBuyback/overview",
          shouldHide: true,
        },
        {
          name: "Option Buyback Summary",
          path: "optionsBuyback/summary",
          redirectPath: "./dashboard/options/optionsBuyback/summary",
          shouldIgnore: true,
          activeLink: "./dashboard/options/optionsBuyback/overview",
          shouldHide: true,
        },
      ],
    },
    {
      name: "Modeling",
      path: "modeling",
      redirectPath: "./dashboard/modeling/roundModelingTab",
      children: [
        {
          name: "Round Modeling",
          path: "roundModelingTab",
          redirectPath: "./dashboard/modeling/roundModelingTab",
        },
        {
          name: "ESOP Modeling",
          path: "esopModellingTab",
          redirectPath: "./dashboard/modeling/esopModellingTab",
        },
        {
          name: "Exit Modeling",
          path: "exitModelling",
          redirectPath: "./dashboard/modeling/exitModelling",
        },
      ],
    },
    {
      name: "Settings",
      path: "settings",
      redirectPath: "./dashboard/settings",
      children: [
        {
          name: "Company Profile",
          path: "companyProfile",
          redirectPath: "./dashboard/settings/companyProfile",
        },
        {
          name: "Site Settings",
          path: "siteSettings",
          redirectPath: "./dashboard/settings/siteSettings",
        },
        {
          name: "Integrations",
          path: "integrations",
          shouldIgnore: true,
          redirectPath: "./dashboard/settings/integrations",
          shouldHide: true,
        },
        {
          name: "FAQ Settings",
          path: "faq",
          redirectPath: "./dashboard/employees/faq",
        },
      ],
    },
  ];
  showMatLoader: boolean = false;
  private dataService = {};
  dataEmitter = new EventEmitter();

  setValue(key, value) {
    this.dataService[key] = value;
    this.dataEmitter.emit(this.dataService);
  }
  getValue(key) {
    return this.dataService[key];
  }
  getMinMax(val) {
    let min = 0;
    let max = 0;
    min = val - (30 / 100) * val;
    max = val * 5;
    return { minValue: min, maxValue: max };
  }

  // function format round and company currencies on hover tooltip
  calculateCurrency(rndCur, cmpnyCur, exRate, amt) {
    // let cc = amt * exRate;
    // let rc = amt;
    if (amt > 0) {
      if (cmpnyCur === "INR - ₹" && rndCur === "INR - ₹") {
        let Cc = this.convertToCompanyCurrency(amt);
        let Rc = this.convertToCompanyCurrency(amt);
        let res1 = "Company Currency: " + Cc;
        let res2 = "Round Currency: " + Rc;
        return res1 + "\n" + res2;
      } else if (cmpnyCur === "INR - ₹" && rndCur != "INR - ₹") {
        let Cc = this.convertToCompanyCurrency(amt * exRate);
        let Rc = this.convertToRoundCurrency(amt);
        let res1 = "Company Currency: " + Cc;
        let res2 = "Round Currency: " + Rc;
        return res1 + "\n" + res2;
      } else if (cmpnyCur != "INR - ₹" && rndCur === "INR - ₹") {
        let Cc = this.convertToRoundCurrency(amt);
        let Rc = this.convertToCompanyCurrency(amt);
        let res1 = "Company Currency: " + Cc;
        let res2 = "Round Currency: " + Rc;
        return res1 + "\n" + res2;
      } else if (cmpnyCur != "INR - ₹" && rndCur != "INR - ₹") {
        let Cc = this.convertToRoundCurrency(amt);
        let Rc = this.convertToRoundCurrency(amt);
        let res1 = "Company Currency: " + Cc;
        let res2 = "Round Currency: " + Rc;
        return res1 + "\n" + res2;
      }
      // else {
      //   let Cc = this.convertToCompanyCurrency(cc);
      //   let Rc = this.convertToRoundCurrency(rc);
      //   let res1 = "Company Currency: " + Cc;
      //   let res2 = "Round Currency: " + Rc;
      //   return res1 + "\n" + res2;
      // }
    } else {
      return;
    }
  }

  calculateRoundCurrency(exRate, amt) {
    return amt / exRate;
  }

  calculateCompanyCurrency(exRate, amt) {
    return amt * exRate;
  }

  //Auto Scroll focus
  focusSection(section) {
    return section.scrollIntoView();
  }

  //Restrict negative input entering
  restrictMinus(data) {
    var inputKeyCode = data.keyCode ? data.keyCode : data.which;
    if (inputKeyCode != null) {
      if (inputKeyCode == 189) data.preventDefault();
    }
  }

  //Restrict decimal
  restrictDecimal(data) {
    var inputKeyCode = data.keyCode ? data.keyCode : data.which;
    if (inputKeyCode != null) {
      if (inputKeyCode == 190) data.preventDefault();
    }
  }

  convertCurrencyFormat(value) {
    // Nine Zeroes for Billions
    return Math.abs(Number(value)) >= 1.0e7
      ? (Math.abs(Number(value)) / 1.0e7).toFixed(2) + "Cr"
      : // Six Zeroes for Millions
      Math.abs(Number(value)) > 1.0e5
      ? (Math.abs(Number(value)) / 1.0e5).toFixed(2) + "L"
      : // Three Zeroes for Thousands
        Math.trunc(Math.abs(Number(value)));
  }

  convertRoundFormat(value) {
    // Nine Zeroes for Billions
    return Math.abs(Number(value)) >= 1.0e9
      ? (Math.abs(Number(value)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(value)) > 1.0e6
      ? (Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
      : Math.abs(Number(value)) > 1.0e3
      ? (Math.abs(Number(value)) / 1.0e3).toFixed(2) + "K"
      : Math.trunc(Math.abs(Number(value)));
  }

  getNavMenu() {
    return this.navmenu;
  }
  getMenu(): Array<any> {
    const menu = [
      {
        name: "Home",
        path: "dashboard",
        redirectPath: "./capTable",
        children: [
          { name: "Cap Table", path: "capTable", redirectPath: "./capTable" },
          {
            name: "Rounds",
            path: "rounds",
            redirectPath: "./rounds/overview",
            children: [
              {
                name: "Round Overview",
                path: "overview",
                redirectPath: "./rounds/overview",
              },
              {
                name: "Create New Round",
                path: "newRound",
                redirectPath: "./rounds/newRound/round",
                children: [
                  {
                    name: "New Round",
                    path: "round",
                    redirectPath: "./rounds/newRound/round",
                  },
                  {
                    name: "Current Investors",
                    path: "currentInvestors",
                    redirectPath: "./rounds/newRound/currentInvestors",
                  },
                  {
                    name: "New Investors",
                    path: "newInvestors",
                    redirectPath: "./rounds/newRound/newInvestors",
                  },
                  {
                    name: "Securities",
                    path: "instrument",
                    redirectPath: "./rounds/newRound/instrument",
                  },
                  {
                    name: "Tranches",
                    path: "capTable",
                    redirectPath: "./rounds/newRound/capTable",
                  },
                  {
                    name: "Summary",
                    path: "summary",
                    redirectPath: "./rounds/newRound/summary",
                  },
                ],
              },
              {
                name: "Round Details",
                path: "round-details",
                redirectPath: "./rounds/round-details/:roundName",
              },
            ],
          },
          {
            name: "Buy-backs",
            path: "buy-back",
            redirectPath: "./buy-back/overview",
            children: [
              {
                name: "Buy-back Overview",
                path: "overview",
                redirectPath: "./buy-back/overview",
              },
              {
                name: "Create New Buy-back",
                path: "newBuyBack",
                redirectPath: "./buy-back/overview",
                children: [
                  {
                    name: "New Buy-back",
                    path: "buyBack",
                    redirectPath: "./buy-back/newBuyBack/buyBack",
                  },
                  {
                    name: "Shareholders",
                    path: "shareholders",
                    redirectPath: "./buy-back/newBuyBack/shareholders",
                  },
                  {
                    name: "Review",
                    path: "review",
                    redirectPath: "./buy-back/newBuyBack/review",
                  },
                  {
                    name: "Summary",
                    path: "summary",
                    redirectPath: "./buy-back/newBuyBack/summary",
                  },
                  {
                    name: "Confirm",
                    path: "confirm",
                    redirectPath: "./buy-back/newBuyBack/confirm",
                  },
                ],
              },
              {
                name: "Buy-back Details",
                path: "details",
                redirectPath: "./buyBack/details",
              },
            ],
          },
          {
            name: "Shareholders",
            path: "shareholders",
            redirectPath: "./shareholders/overview",
            children: [
              {
                name: "Shareholders Overview",
                path: "overview",
                redirectPath: "./shareholders/overview",
              },
              {
                name: "Shareholders Details",
                path: "details",
                redirectPath: "./shareholders/details/:id",
              },
            ],
          },
          {
            name: "Securities",
            path: "securities",
            redirectPath: "./securities/overview",
            children: [
              {
                name: "Securities Overview",
                path: "overview",
                redirectPath: "./securities/overview",
              },
              {
                name: "New Security Class",
                path: "addNewInstrumentclass",
                redirectPath: "./securities/addNewInstrumentclass",
              },
              {
                name: "Security Classes",
                path: "instrumentClass",
                redirectPath: "./securities/instrumentClass",
              },
            ],
          },
          // {
          //   name: "Secondary Overview",
          //   path: "overview",
          //   redirectPath: "./overview",
          // },
          {
            name: "Secondary",
            path: "secondary",
            redirectPath: "./secondary/overview",
            children: [
              {
                name: "Secondary Overview",
                path: "overview",
                redirectPath: "./secondary/overview",
              },
              {
                name: "Seller",
                path: "secondary-seller",
                redirectPath: "./secondary/secondary-seller",
              },
              {
                name: "Buyer",
                path: "secondary-buyer",
                redirectPath: "./secondary/secondary-buyer",
              },
              {
                name: "Summary",
                path: "secondary-summary",
                redirectPath: "./secondary/secondary-summary",
              },
            ],
          },
          {
            name: "Splits",
            path: "splits",
            redirectPath: "./splits/overview",
            children: [
              {
                name: "Overview",
                path: "overview",
                redirectPath: "./splits/overview",
              },
              {
                name: "New Split",
                path: "new-split",
                redirectPath: "./splits/new-split",
              },
              {
                name: "Preview",
                path: "preview",
                redirectPath: "./splits/preview",
              },
            ],
          },
          {
            name: "Bonus",
            path: "bonus",
            redirectPath: "./bonus/overview",
            children: [
              {
                name: "Overview",
                path: "overview",
                redirectPath: "./bonus/overview",
              },
              {
                name: "New Bonus",
                path: "new-bonus",
                redirectPath: "./bonus/new-bonus",
              },
              {
                name: "Preview",
                path: "preview",
                redirectPath: "./bonus/preview",
              },
            ],
          },
          {
            name: "All-Transactions",
            path: "all-transactions",
            redirectPath: "./transactions/overview",
            children: [
              {
                name: "Overview",
                path: "overview",
                redirectPath: "./transactions/overview",
              },
              {
                name: "Quick Round",
                path: "quick-round",
                redirectPath: "./transactions/quick-round",
              },
            ],
          },
          {
            name: "Term-Sheets",
            path: "term-sheets",
            redirectPath: "./term-sheets/overview",
            children: [
              {
                name: "Overview",
                path: "overview",
                redirectPath: "./term-sheets/overview",
              },
              {
                name: "Details",
                path: "details",
                redirectPath: "./term-sheets/details/:id",
              },
            ],
          },
          {
            name: "Options",
            path: "options",
            redirectPath: "./options/overviewEsop",
            children: [
              {
                name: "Add-Edit Pool",
                path: "addPool",
                redirectPath: "./options/addPool",
              },
              {
                name: "ESOP Overview",
                path: "overviewEsop",
                redirectPath: "./options/overviewEsop",
              },
              {
                name: "ESOP Management",
                path: "manageEsop",
                redirectPath: "./options/manageEsop",
                children: [
                  {
                    name: "Employee",
                    path: "employeeDetails",
                    redirectPath:
                      "./options/employee/employeeDetails/:id/:planName",
                  },
                ],
              },
              {
                name: "Vesting Schedules",
                path: "vestingSchedule",
                redirectPath: "./options/vestingSchedule",
              },
              {
                name: "Create - Amend ESOP",
                path: "createEsop",
                redirectPath: "./options/createEsop",
              },
              {
                name: "Edit ESOP",
                path: "createEsop/:name",
                redirectPath: "./options/createEsop/:name",
              },
              // {
              //   name: "Employees Overview",
              //   path: "overviewEmployees",
              //   redirectPath: "./options/overviewEmployees",
              // },
            ],
          },
          {
            name: "Settings",
            path: "settings",
            redirectPath: "./settings",
            children: [
              {
                path: "companyProfile",
                name: "Company Profile",
                redirectPath: "./settings/companyProfile",
              },
              {
                path: "siteSettings",
                name: "Site Settings",
                redirectPath: "./settings/siteSettings",
              },
              {
                path: "integrations",
                name: "Integrations",
                redirectPath: "./settings/integrations",
              },
            ],
          },
          {
            // name: "Modeling",
            path: "modeling",
            redirectPath: "./modeling/roundModelingTab",
            children: [
              {
                name: "Round Modeling",
                path: "roundModelingTab",
                redirectPath: "./modeling/roundModelingTab",
              },
              {
                name: "Exit Modeling",
                path: "exitModelling",
                redirectPath: "./modeling/exitModelling",
              },
              {
                name: "Esop Modeling",
                path: "esopModellingTab",
                redirectPath: "./modeling/esopModellingTab",
              },
            ],
          },
          {
            name: "Company Profile",
            path: "companyProfile",
            redirectPath: "./companyProfile",
          },
          {
            name: "Users and Roles",
            path: "usersRoles",
            redirectPath: "./usersRoles",
          },
          {
            name: "Share Capitals",
            path: "shareCapital",
            redirectPath: "./shareCapital",
          },
          {
            name: "User Profile",
            path: "userProfile",
            redirectPath: "./userProfile",
          },
        ],
      },
    ];

    return menu;
  }

  convertToCompanyCurrency(num) {
    let number = num;
    let decimal = number.toString().split(".");
    let deciVal = decimal[1];
    if (deciVal && deciVal.length > 10) {
      deciVal = deciVal.toString().substring(0, 10);
    }
    number = decimal[0];
    number = number / 1;
    number = number.toFixed().toString();
    var lastThree = number.substring(number.length - 3);
    var otherNumbers = number.substring(0, number.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    if (deciVal || deciVal == "") {
      return res + "." + deciVal;
    } else {
      return res;
    }
  }

  convertToRoundCurrency(num) {
    let number = num;
    let decimal = number.toString().split(".");
    let deciVal = decimal[1];
    if (deciVal && deciVal.length > 10) {
      deciVal = deciVal.toString().substring(0, 10);
    }
    number = decimal[0];
    number = number / 1;
    number = number.toFixed().toString();
    var lastThree = number.substring(number.length - 3);
    var otherNumbers = number.substring(0, number.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
    if (deciVal || deciVal == "") {
      return res + "." + deciVal;
    } else {
      return res;
    }
  }
  // Comma seperation value for the amount
  formatCurrency(currency, amount) {
    if (amount) {
      if (
        currency === "INR - ₹" ||
        currency == "undefined" ||
        currency == "null"
      ) {
        if (isNaN(amount)) {
          console.log("aaa", isNaN(amount));
          if (amount.indexOf(",") > -1) {
            amount = amount.replace(/,/g, "") / 1;
          } else {
            amount = amount / 1;
          }
        }
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        if (deciVal && deciVal.length > 2) {
          deciVal = deciVal.toString().substring(0, 2);
        }
        amount = decimal[0];
        amount = amount / 1;
        amount = amount.toFixed().toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      } else {
        if (isNaN(amount)) {
          console.log("aaa", isNaN(amount));
          if (amount.indexOf(",") > -1) {
            amount = amount.replace(/,/g, "") / 1;
          } else {
            amount = amount / 1;
          }
        }
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        amount = decimal[0];
        amount = amount / 1;
        amount = amount.toFixed().toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      }
    } else {
      return amount;
    }
  }

  inputCSVformatCurrencyRound(currency, amount) {
    if (amount) {
      if (
        currency === "INR - ₹" ||
        currency == "undefined" ||
        currency == "null"
      ) {
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        if (deciVal && deciVal.length > 10) {
          deciVal = deciVal.toString().substring(0, 10);
        }
        amount = decimal[0];
        amount = amount.replace(/,/g, "");
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        var oNumbers = otherNumbers.replace(/,/g, "");
        if (oNumbers != "") lastThree = "," + lastThree;
        var res = oNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      } else {
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        if (deciVal && deciVal.length > 10) {
          deciVal = deciVal.toString().substring(0, 10);
        }
        amount = decimal[0];
        amount = amount.replace(/,/g, "");
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        var oNumbers = otherNumbers.replace(/,/g, "");
        if (oNumbers != "") lastThree = "," + lastThree;
        var res = oNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      }
    } else {
      return amount;
    }
  }

  //for input fileds only
  inputCSVformatCurrency(currency, amount) {
    if (amount) {
      if (
        currency === "INR - ₹" ||
        currency == "undefined" ||
        currency == "null"
      ) {
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        if (deciVal && deciVal.length > 2) {
          deciVal = deciVal.toString().substring(0, 2);
        }
        amount = decimal[0];
        amount = amount.replace(/,/g, "");
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        var oNumbers = otherNumbers.replace(/,/g, "");
        if (oNumbers != "") lastThree = "," + lastThree;
        var res = oNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      } else {
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        if (deciVal && deciVal.length > 2) {
          deciVal = deciVal.toString().substring(0, 2);
        }
        amount = decimal[0];
        amount = amount.replace(/,/g, "");
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        var oNumbers = otherNumbers.replace(/,/g, "");
        if (oNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      }
    } else {
      return amount;
    }
  }

  // fr decimal upto
  inputCSVformatCurrency1(currency, amount) {
    if (amount) {
      if (
        currency === "INR - ₹" ||
        currency == "undefined" ||
        currency == "null"
      ) {
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        if (deciVal && deciVal.length > 10) {
          deciVal = deciVal.toString().substring(0, 10);
        }
        amount = decimal[0];
        amount = amount.replace(/,/g, "");
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        var oNumbers = otherNumbers.replace(/,/g, "");
        if (oNumbers != "") lastThree = "," + lastThree;
        var res = oNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      } else {
        let decimal = amount.toString().split(".");
        let deciVal = decimal[1];
        amount = decimal[0];
        amount = amount.toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        var oNumbers = otherNumbers.replace(/,/g, "");
        if (oNumbers != "") lastThree = "," + lastThree;
        var res = oNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
        if (deciVal || deciVal == "") {
          return res + "." + deciVal;
        } else {
          return res;
        }
      }
    } else {
      return amount;
    }
  }

  // converting amount to Million, Billion, Cr, Lakhs
  convertedFormatCurrency(currency, amount) {
    if (amount) {
      if (
        currency === "INR - ₹" ||
        currency == "undefined" ||
        currency == "null"
      ) {
        return Math.abs(Number(amount)) >= 1.0e7
          ? (Math.abs(Number(amount)) / 1.0e7).toFixed(2) + "Cr"
          : // Six Zeroes for Millions
          Math.abs(Number(amount)) >= 1.0e5
          ? (Math.abs(Number(amount)) / 1.0e5).toFixed(2) + "L"
          : // Three Zeroes for Thousands
            Math.trunc(Math.abs(Number(amount)));
      } else {
        return Math.abs(Number(amount)) >= 1.0e9
          ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + "B"
          : // Six Zeroes for Millions
          Math.abs(Number(amount)) > 1.0e6
          ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + "M"
          : Math.abs(Number(amount)) > 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + "K"
          : Math.trunc(Math.abs(Number(amount)));
      }
    }
  }

  // Comma seperation value for the amount with decimal
  formatCurrencyDeci(currency, amount) {
    if (amount && currency) {
      if (currency === "INR - ₹") {
        var x = amount.toString();
        var afterPoint = "";
        if (x.indexOf(".") > 0)
          afterPoint = x.substring(x.indexOf("."), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          lastThree +
          afterPoint.substring(0, 3);
        return res;
      } else {
        var x = amount.toString();
        var afterPoint = "";
        if (x.indexOf(".") > 0)
          afterPoint = x.substring(x.indexOf("."), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          lastThree +
          afterPoint.substring(0, 3);
        return res;
      }
    }
  }

  // Currencies dropdownlist
  currencyList() {
    var currencies: any[];
    currencies = [
      "AED - د.إ",
      "AUD - A$",
      "CNY - ¥",
      "EUR - €",
      "GBP - £",
      "INR - ₹",
      "JPY - ¥",
      "SGD - S$",
      "USD - $",
    ];
    return currencies;
  }

  //Remove commas and convert to number
  removeCommaFromNumber(value: string): number | string {
    let input: any = value;
    input = input.replace(/,/g, "") / 1;
    if (input == NaN) {
      return value;
    } else {
      return input;
    }
  }

  //Input number typing comma seperation
  typerCommaForNumber(event) {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) return;
    // format number
    $(this).val(function (index, value) {
      let decimal = value.split(".");
      let deciVal = decimal[1];
      value = decimal[0];
      let tmpValue = value.toString().replace(/\D/g, "");
      var lastThree = tmpValue.substring(tmpValue.length - 3);
      var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
      if (otherNumbers != "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      if (deciVal || deciVal == "") {
        return res + "." + deciVal;
      } else {
        return res;
      }
    });
  }

  // industries list
  // getIndustries(){
  //   var industries: any[];
  //   industries = [
  //     "EdTech","Energy","FinTech","HealthTech", "Media/Entertainment","MedTech","Mobility",
  //     "Online Market Places","Retail","SpaceTech","Telecom","TravelTech","Other"
  //   ];
  //   industries = [
  //     "AGRICULTURE AND ALLIED INDUSTRIES","AUTOMOBILES","AUTO COMPONENTS","BANKING", "CEMENT","CONSUMER DURABLES","ECOMMERCE",
  //     "EDUCATION AND TRAINING","ENGINEERING AND CAPITAL GOODS","FINANCIAL SERVICES","FMCG","GEMS AND JEWELLERY","HEALTHCARE",
  //     "INFRASTRUCTURE","INSURANCE","IT & ITES","MANUFACTURING","MEDIA AND ENTERTAINMENT","METALS AND MINING","OIL AND GAS",
  //     "PHARMACEUTICALS","PORTS","POWER","RAILWAYS","REAL ESTATE","RENEWABLE ENERGY","RETAIL","ROADS","SCIENCE AND TECHNOLOGY",
  //     "SERVICES","STEEL","TELECOMMUNICATIONS","TEXTILES","TOURISM AND HOSPITALITY"
  //   ];
  //   return industries;
  // }

  // Business list
  // getBusinesslist(){
  //   var businessList: any[];
  //   businessList = [
  //     "Artificial Intelligence/Machine Learning","Augmented/Virtual/Mixed Reality","Cloud Offerings","Distributed Ledger Technologies",
  //     "Developer Tools","Enterprise SaaS","Hardware",
  //     "Industrial IoT","Robotics","Robotic Process Automation Software","FWearablesMCG","Other"
  //   ];
  //   return businessList;
  // }

  formValidation() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }

  encryptPassword(password: string) {
    /*
    encrypts the given password
    */
    const encryptSecretKey = "hissabyrulezero";
    try {
      return btoa(password + "::" + encryptSecretKey);
    } catch (e) {
      console.log(e);
    }
  }
  checkStrength(p) {
    let force = 0;
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;
    force = p.length <= 6 ? Math.min(force, 10) : force;
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    return force;
  }
  numberFormatter(rawNumber: string | number, args = 2) {
    var exp,
      rounded,
      suffixes = ["K", "M", "G", "T", "P", "E"];
    if (typeof rawNumber == "number") {
      return rawNumber;
    }
    let convertedNumber;
    let format = "";
    if (rawNumber.endsWith("Cr")) {
      convertedNumber = rawNumber.replace("Cr", "");
      convertedNumber = parseFloat(convertedNumber);
      format = "Cr";
    } else {
      format = rawNumber[rawNumber.length - 1];
      convertedNumber = rawNumber.replace(format, "");
      convertedNumber = parseFloat(convertedNumber);
    }
    if (convertedNumber < 1000) {
      return rawNumber;
    }
    if (Number.isNaN(convertedNumber)) {
      return null;
    }

    exp = Math.floor(Math.log(convertedNumber) / Math.log(1000));

    convertedNumber =
      (convertedNumber / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    return convertedNumber.toString() + " " + format;
  }
  blob2Base64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = (error) => reject(error);
    });
  }
  b64toBlob = (base64, type = "application/octet-stream") =>
    fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

  downloadFile(fileObj, fileName, contentType) {
    let downloadLink: HTMLAnchorElement = document.createElementNS(
      "http://www.w3.org/1999/xhtml",
      "a"
    ) as HTMLAnchorElement;
    downloadLink.download = fileName;
    let blob = new Blob([fileObj], { type: contentType });
    let dataUrl: string = window.URL.createObjectURL(blob);
    downloadLink.href = dataUrl;
    let event: MouseEvent = document.createEvent("MouseEvent");
    event.initEvent("click", true, true);
    downloadLink.dispatchEvent(event);
    setTimeout((): void => {
      window.URL.revokeObjectURL(dataUrl);
      dataUrl = undefined;
    });
  }

  downloadBlobFile(blob, fileName) {
    const anchor = document.createElement("a");
    blob.then((response) => {
      const blobURL = URL.createObjectURL(response);
      anchor.href = blobURL;
      anchor.download = fileName;
      anchor.click();
    });
  }
  showOverlay() {
    const allOverlays = document.querySelectorAll("#overlay");
    if (allOverlays) {
      allOverlays.forEach((overlay:HTMLElement)=>{
      overlay.style.display = "block";
      overlay.click();
      })
    }
  }
  hideOverlay() {
    const allOverlays = document.querySelectorAll("#overlay");
    if (allOverlays) {
      allOverlays.forEach((overlay:HTMLElement)=>{
      overlay.style.display = "none";
      overlay.click();
      })
    }
  }

  getTotalValueTble(value, cardName = "Share Price") {
    const companyCurrency = localStorage.getItem("comCur") || "INR - ₹";
    if (value > 0 && cardName != "Share Price") {
      if (value.toString().length > 5) {
        return this.convertedFormatCurrency(companyCurrency, value);
      } else {
        return this.formatCurrency(companyCurrency, value);
      }
    } else {
      // console.log("s234567")
      if (value > 0) {
        return value.toFixed(2);
      }
      return 0;
    }
  }
  checkJwtValidity(accessToken:string){
    let jwtExpired = false
    const decoded = jwtDecode(accessToken) as { exp: number };
    const expiry = decoded.exp * 1000
    if(new Date(expiry) < new Date()) jwtExpired = true
    return jwtExpired
  }
  navigateToNewOptions(targetUrl?){
    const jwt = localStorage.getItem('x-access-token')
    if(this.checkJwtValidity(jwt)){
      this.toast.warning("Looks like your session has expired, please relogin and try again", "Alert")
      return
    }
    let optionsUrl = environment.optionsUrl
    if(targetUrl) optionsUrl = `${optionsUrl}/${targetUrl}`
    const xUserToken = localStorage.getItem('currentUser')
    const companyId = localStorage.getItem('companyId');
    if(!jwt){
      console.debug('jwt unavailable, falling back to x-usr-token')
    }
    const redirectURI =  `${optionsUrl}?${jwt ? `logintoken=${jwt}` : `x-usr-token=${xUserToken}`}&companyId=${companyId}&redirectURL=${window.location.origin}`
    window.location.href = redirectURI
  }
}
