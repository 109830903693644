import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../src/app/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../services/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NavbarService } from '../services/navbar-reload';
import { CacheProviderService } from '../../app/services/cache-provider.service';
declare var bootbox: any;

@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss']
})
export class UsersRolesComponent implements OnInit {

  public usersList: any[];
  public users = [];
  public designationlist = [];
  public roleslist = [];
  editUsers;
  editPermission;

  public permissionlist: any[];
  public permissions = [];
  public newPermlist = [];
  public disableripple: boolean = true;
  public isShowRole: boolean = false;
  public id;
  public rolename;
  public mname: boolean = false;
  public roleId;
  public isEnabled: boolean = false;
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  public organisationName;
  mUser: boolean = false;
  mrollName: boolean = false;
  mDesignation: boolean = false;
  mEmail: boolean = false;
  public loggedUserId;

  constructor(private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private helperService: HelperService,
    private navbarService: NavbarService,
    private cacheService: CacheProviderService,
    ) { }

  ngOnInit() {
    if (this.cacheService.get("userDetails")) {
      let loggedUser = JSON.parse(this.cacheService.get("userDetails"));
      this.loggedUserId = loggedUser.userId;
    }
    this.getUsersList();
  }

  getUsersList() {
    this.ngxLoader.start();
    this.authService.getUsersList().subscribe((res: any) => {
      this.usersList = res.users;
      this.organisationName = this.usersList[0]['organisationName'];
      this.permissions = res.roles;
      this.designationlist = res.designationNamesForDropdown;
      this.roleslist = res.roleNamesForDropdown;
      this.roleId = "Admin";
      this.populatePermission("Admin");
      this.ngxLoader.stop();
    },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      });
  }

  getPermissionList(name) {
    setTimeout(() => {
      this.authService.getUsersList().subscribe((res: any) => {
        this.permissions = res.roles;
        this.roleslist = res.roleNamesForDropdown;
        this.showRole(name); 
      },
        (e) => {
          this.toastr.error(e.error.reason, "Error");
        });
    }, 150);
  }

  addNewUsers() {
    let length = this.users.length;
    if (length > 0) {
      if (this.users[length - 1].userName === "" ||
        this.users[length - 1].roleName === "" ||
        this.users[length - 1].organisationName === "" ||
        this.users[length - 1].userDesignation === "" ||
        this.users[length - 1].email === "") {
        this.toastr.warning("Please fill previous row details", "Warning!");
        return;
      }
    }
    let newUsers = {
      "userName": "",
      "roleName": "",
      "organizationName": "",
      "userDesignation": "",
      "email": ""
    };
    this.users.push(newUsers);
    this.users[0].organisationName = this.organisationName;
  }

  saveNewUser(j) {
    let saveUsers = {
      "userName": this.users[j]["userName"],
      "roleName": this.users[j]["roleName"],
      "organisationName": this.users[j].organisationName,
      "userDesignation": this.users[j].userDesignation,
      "email": this.users[j].email,
      "isAllowAccess": true
    }
    let valid = this.newUserValidation(saveUsers);
    if(valid){
      this.ngxLoader.start();
      this.authService.addUsers(saveUsers).subscribe(() => {
        this.editUsers = null;
        this.getUsersList();
        this.deleteUsersRow(j);
        this.ngxLoader.stop();
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      });
    }
  }

  newUserValidation(data){
    if(data.userName === "" || data.roleName === "" || data.organisationName === ""  
        || data.email === ""){  
        if(data.userName === ""){
          this.mUser = true;
        } 
        if(data.roleName === ""){
          this.mrollName = true;
        } 
        if(data.email === ""){
          this.mEmail = true;
        }
        this.toastr.error("Please fill new user details", "Error!");
        return false;
    }
    else{
      this.mUser = false;
      this.mrollName = false;
      this.mEmail = false;
      return true;
    }
  }

  editUser(i, id) {
    let updateUsers = {
      "id": id,
      "userName": this.usersList[i]["userName"],
      "roleName": this.usersList[i]["roleName"],
      "organisationName": this.usersList[i].organisationName,
      "userDesignation": this.usersList[i].userDesignation,
      "email": this.usersList[i].email,
      "isAllowAccess": this.usersList[i].isAllowAccess
    }
    let valid = this.newUserValidation(updateUsers);
    if(valid){
      this.ngxLoader.start();
      this.authService.updateUsers(updateUsers).subscribe(() => {
        this.editUsers = null;
        this.navbarService.sendClickEvent();
        this.getUsersList();
        this.ngxLoader.stop();
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      });
    }
  }

  activateEditmode(i) {
    this.editUsers = i;
  }

  deactivateEditmode() {
    this.getUsersList();
    this.editUsers = null;
  }

  permissionGrant(i, id, access) {
    let updateUsers = {
      "id": id,
      "userName": this.usersList[i].userName,
      "roleName": this.usersList[i].roleName,
      "organisationName": this.usersList[i].organisationName,
      "userDesignation": this.usersList[i].userDesignation,
      "email": this.usersList[i].email,
      "isAllowAccess": access
    }
    this.ngxLoader.start();
    this.authService.updateUsers(updateUsers).subscribe(() => {
      this.editUsers = null;
      this.getUsersList();
      this.ngxLoader.stop();
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Error");
    });
  }

  deleteUsersRow(index) {
    this.users.splice(index, 1);
    this.mUser = false;
    this.mrollName = false;
    this.mDesignation = false;
    this.mEmail = false;
  }

  deletePermissionRow(index) {
    this.permissions.splice(index, 1);
  }

  //for permissions
  activatePermissions(j) {
    this.editPermission = j;
  }

  addNewPermissions() {
    this.newPermlist = [];
    let newPermissions = {
      "id": 0,
      "name": "",
      "isEditable": true,
      "permissions": null,
      "permissionValues": [
        {
          "moduleName": "Captable",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Company Profile",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Contracts",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Deal Room",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Employees",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Issuances",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Modeling",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Options",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": null
        },
        {
          "moduleName": "Transactions",
          "none": false,
          "summary": false,
          "viewer": false,
          "editor": false,
          "full": false,
          "permissions": [
            {
              "moduleName": "Primary Transactions",
              "none": false,
              "summary": false,
              "viewer": false,
              "editor": false,
              "full": false,
              "permissions": null
            },
            {
              "moduleName": "Secondary Transactions",
              "none": false,
              "summary": false,
              "viewer": false,
              "editor": false,
              "full": false,
              "permissions": null
            }
          ]
        }
      ]
    };
    let list = newPermissions['permissionValues'];
    let i = 0;
    for (i = 0; i < list.length; i++) {
      this.newPermlist.push(list[i]);
    }
  }

  savePermissions() {
    //Add   
    if (this.id == 0) {
      if (this.rolename == undefined || this.rolename == "" || this.rolename == null) {
        this.mname = true;
        return;
      }
      let k = 0
      for (k = 0; k < this.newPermlist.length; k++) {
        let perm = this.newPermlist[k];
        if ((perm.permissions == null) && (perm.none == false && perm.summary == false && perm.viewer == false && perm.editor == false && perm.full == false)) {
          this.toastr.error("Select atleast one value!", perm.moduleName)
          return;
        }
        else if ((perm.permissions != null)) {
          let m = 0;
          for (m = 0; m < perm.permissions.length; m++) {
            if (perm.permissions[m].none == false && perm.permissions[m].summary == false && perm.permissions[m].viewer == false && perm.permissions[m].editor == false && perm.permissions[m].full == false) {
              this.toastr.error("Select atleast one value!", perm.permissions[m].moduleName)
              return;
            }
          }
        }
      }

      let i = 0;
      for (i = 0; i < this.newPermlist.length; i++) {
        this.newPermlist[i].moduleName = this.newPermlist[i].moduleName.toLowerCase();
      }
      let data = {
        "name": this.rolename,
        "permissionValues": this.newPermlist
      }
     this.ngxLoader.start();
      this.authService.addPermissions(data).subscribe((res) => { 
        this.ngxLoader.stop(); 
        this.toastr.success("Saved Successfully!","Success");
        let name = res['name'];
        this.getPermissionList(name);
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      });
    } else if (this.id > 0) {
      let k = 0
      for (k = 0; k < this.permissionlist.length; k++) {
        let perm = this.permissionlist[k];
        if ((perm.permissions == null) && (perm.none == false && perm.summary == false && perm.viewer == false && perm.editor == false && perm.full == false)) {
          this.toastr.error("Select atleast one value!", perm.moduleName)
          return;
        }
        else if ((perm.permissions != null)) {
          let m = 0;
          for (m = 0; m < perm.permissions.length; m++) {
            if (perm.permissions[m].none == false && perm.permissions[m].summary == false && perm.permissions[m].viewer == false && perm.permissions[m].editor == false && perm.permissions[m].full == false) {
              this.toastr.error("Select atleast one value!", perm.permissions[m].moduleName)
              return;
            }
          }
        }
      }

      let i = 0;
      for (i = 0; i < this.permissionlist.length; i++) {
        this.permissionlist[i].moduleName = this.permissionlist[i].moduleName.toLowerCase();
      }
      let data = {
        "id": this.id,
        "name": this.rolename,
        "permissionValues": this.permissionlist
      }
      this.ngxLoader.start();
      this.authService.addPermissions(data).subscribe((res) => {
        this.ngxLoader.stop();
        this.toastr.success("Saved Successfully!","Success");
        let name = res['name'];
        this.getPermissionList(name); 
        
              
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      });
    }
  }
  
  /** Show Contextual help **/
  showHelp(section) {
    if(section == this.helper){
        return;
      }
      const targetElement = document.getElementById(section);
      if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
      }
      
        this.helpSections = this.helperService.getHelpContent(
          "UsersRolesComponent",
          section
        );
      this.helper = section;
  }

  hideHelp() {
    this.helper = "";
  }
  /** Show Contextual help **/

  showRole(event) {
    if (event === "CreateNew") {
      this.isShowRole = true;
      this.id = 0;
      this.rolename = "";
      this.isEnabled = true;
      this.addNewPermissions();
    }
    else {
      this.isShowRole = false;
      this.populatePermission(event);
    }
  }

  populatePermission(name) {
    let list;
    list = this.permissions.filter(a => a.name == name);  
    this.isEnabled = list[0].isEditable;
    this.id = list[0].id;
    this.rolename = list[0].name;
    this.roleId = list[0].name;
    this.permissionlist = list[0].permissionValues;   
  }

  changePermGroup(m, value, data) {
    if (data == "None") {
      if (value == true) {
        this.newPermlist[m].none = true;
        this.newPermlist[m].summary = false;
        this.newPermlist[m].viewer = false;
        this.newPermlist[m].editor = false;
        this.newPermlist[m].full = false;
      } else {
        this.toastr.error("Please Select atleast one!", "Error!");
        this.newPermlist[m].none = true;
      }
    }
    else if (data == "Summary") {
      if (value == true) {
        this.newPermlist[m].none = false;
        this.newPermlist[m].summary = true;
        this.newPermlist[m].viewer = false;
        this.newPermlist[m].editor = false;
        this.newPermlist[m].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
        this.newPermlist[m].summary = true;
      }
    }
    else if (data == "Viewer") {
      if (value == true) {
        this.newPermlist[m].none = false;
        this.newPermlist[m].summary = false;
        this.newPermlist[m].viewer = true;
        this.newPermlist[m].editor = false;
        this.newPermlist[m].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
        this.newPermlist[m].viewer = true;
      }
    }
    else if (data == "Editor") {
      if (value == true) {
        this.newPermlist[m].none = false;
        this.newPermlist[m].summary = false;
        this.newPermlist[m].viewer = false;
        this.newPermlist[m].editor = true;
        this.newPermlist[m].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
        this.newPermlist[m].editor = true;
      }
    }
    else if (data == "Full") {
      if (value == true) {
        this.newPermlist[m].none = false;
        this.newPermlist[m].summary = false;
        this.newPermlist[m].viewer = false;
        this.newPermlist[m].editor = false;
        this.newPermlist[m].full = true;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
        this.newPermlist[m].full = true;
      }
    }
  }

  changePermIndividual(m, n, value, data) {
    if (data == "None") {
      if (value == true) {
        this.newPermlist[m].permissions[n].none = true;
        this.newPermlist[m].permissions[n].summary = false;
        this.newPermlist[m].permissions[n].viewer = false;
        this.newPermlist[m].permissions[n].editor = false;
        this.newPermlist[m].permissions[n].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Summary") {
      if (value == true) {
        this.newPermlist[m].permissions[n].none = false;
        this.newPermlist[m].permissions[n].summary = true;
        this.newPermlist[m].permissions[n].viewer = false;
        this.newPermlist[m].permissions[n].editor = false;
        this.newPermlist[m].permissions[n].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Viewer") {
      if (value == true) {
        this.newPermlist[m].permissions[n].none = false;
        this.newPermlist[m].permissions[n].summary = false;
        this.newPermlist[m].permissions[n].viewer = true;
        this.newPermlist[m].permissions[n].editor = false;
        this.newPermlist[m].permissions[n].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Editor") {
      if (value == true) {
        this.newPermlist[m].permissions[n].none = false;
        this.newPermlist[m].permissions[n].summary = false;
        this.newPermlist[m].permissions[n].viewer = false;
        this.newPermlist[m].permissions[n].editor = true;
        this.newPermlist[m].permissions[n].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Full") {
      if (value == true) {
        this.newPermlist[m].permissions[n].none = false;
        this.newPermlist[m].permissions[n].summary = false;
        this.newPermlist[m].permissions[n].viewer = false;
        this.newPermlist[m].permissions[n].editor = false;
        this.newPermlist[m].permissions[n].full = true;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
  }


  onChangeValue(j, k, value, data) {
    if (data == "None") {
      if (value == true) {
        this.permissionlist[j].permissions[k].none = true;
        this.permissionlist[j].permissions[k].summary = false;
        this.permissionlist[j].permissions[k].viewer = false;
        this.permissionlist[j].permissions[k].editor = false;
        this.permissionlist[j].permissions[k].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Summary") {
      if (value == true) {
        this.permissionlist[j].permissions[k].none = false;
        this.permissionlist[j].permissions[k].summary = true;
        this.permissionlist[j].permissions[k].viewer = false;
        this.permissionlist[j].permissions[k].editor = false;
        this.permissionlist[j].permissions[k].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Viewer") {
      if (value == true) {
        this.permissionlist[j].permissions[k].none = false;
        this.permissionlist[j].permissions[k].summary = false;
        this.permissionlist[j].permissions[k].viewer = true;
        this.permissionlist[j].permissions[k].editor = false;
        this.permissionlist[j].permissions[k].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Editor") {
      if (value == true) {
        this.permissionlist[j].permissions[k].none = false;
        this.permissionlist[j].permissions[k].summary = false;
        this.permissionlist[j].permissions[k].viewer = false;
        this.permissionlist[j].permissions[k].editor = true;
        this.permissionlist[j].permissions[k].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Full") {
      if (value == true) {
        this.permissionlist[j].permissions[k].none = false;
        this.permissionlist[j].permissions[k].summary = false;
        this.permissionlist[j].permissions[k].viewer = false;
        this.permissionlist[j].permissions[k].editor = false;
        this.permissionlist[j].permissions[k].full = true;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
  }

  onChangeModule(j, value, data) {
    if (data == "None") {
      if (value == true) {
        this.permissionlist[j].none = true;
        this.permissionlist[j].summary = false;
        this.permissionlist[j].viewer = false;
        this.permissionlist[j].editor = false;
        this.permissionlist[j].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Summary") {
      if (value == true) {
        this.permissionlist[j].none = false;
        this.permissionlist[j].summary = true;
        this.permissionlist[j].viewer = false;
        this.permissionlist[j].editor = false;
        this.permissionlist[j].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Viewer") {
      if (value == true) {
        this.permissionlist[j].none = false;
        this.permissionlist[j].summary = false;
        this.permissionlist[j].viewer = true;
        this.permissionlist[j].editor = false;
        this.permissionlist[j].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Editor") {
      if (value == true) {
        this.permissionlist[j].none = false;
        this.permissionlist[j].summary = false;
        this.permissionlist[j].viewer = false;
        this.permissionlist[j].editor = true;
        this.permissionlist[j].full = false;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
    else if (data == "Full") {
      if (value == true) {
        this.permissionlist[j].none = false;
        this.permissionlist[j].summary = false;
        this.permissionlist[j].viewer = false;
        this.permissionlist[j].editor = false;
        this.permissionlist[j].full = true;
      }
      else {
        this.toastr.error("Please Select atleast one!", "Error!");
      }
    }
  }

  close() {
    this.roleId="Admin"
    this.showRole(this.roleId);
  }

  deleteUser(id, name) {
    if (this.usersList.length > 1) {
      bootbox.confirm({
        title: "Delete",
        message: "Are you sure you want to delete user: <b>" + name + "</b>?",
        buttons: {
          confirm: {
            label: 'Yes',
            className: 'mat-success'
          },
          cancel: {
            label: 'No',
            className: 'mat-warn'
          }
        },
        callback: (result) => {
          if (result === true) {
            this.deleteUserPermission(id);
          }
        }
      });
    } 
    else {
      this.toastr.error("Permission Deletion Not Allowed For This User!", "Error!");
    }
  }

  deleteUserPermission(id){
    if(this.usersList.length > 1){
      this.authService.deleteUserPermission(id).subscribe((response) => {
        if (response['resultCode'] === "SUCCESS") {
          this.toastr.success("User Deleted Successfully!", "Success!");
          this.getUsersList();
        }
      }, (e) => {
        this.toastr.error(e.error.reason, "Failure!");
      })
    }
    else{
      this.toastr.error("Permission Deletion Not Allowed For This User!", "Error!");
    }
  }

}


