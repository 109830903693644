/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-results.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./search-results.component";
var styles_SearchResultsComponent = [i0.styles];
var RenderType_SearchResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchResultsComponent, data: {} });
export { RenderType_SearchResultsComponent as RenderType_SearchResultsComponent };
export function View_SearchResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["height", "500"], ["id", "if1"], ["name", "I1"], ["src", "http://54.226.255.69/"], ["style", "border: 0"], ["width", "100%"]], null, null, null, null, null))], null, null); }
export function View_SearchResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-results", [], null, null, null, View_SearchResultsComponent_0, RenderType_SearchResultsComponent)), i1.ɵdid(1, 114688, null, 0, i2.SearchResultsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchResultsComponentNgFactory = i1.ɵccf("app-search-results", i2.SearchResultsComponent, View_SearchResultsComponent_Host_0, {}, {}, []);
export { SearchResultsComponentNgFactory as SearchResultsComponentNgFactory };
