<h1 mat-dialog-title>{{data.header}}</h1>
<div mat-dialog-content>
  <p> {{data.text}}</p>
  <!-- <div [innerHTML]="data.text"  > -->
<!-- 
  </div> -->
    
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button id="cancel" color="secondary" [mat-dialog-close]="'no'">Cancel</button>
    <button mat-raised-button id="confirm" style="background-color:#CD4927" color="primary" [mat-dialog-close]="'confirm'" cdkFocusInitial>Confirm</button>
</div>