import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatCardModule, MatButtonModule, MatFormFieldModule, MatDividerModule, MatDatepickerModule, MatIconModule, MatButtonToggleModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../../pipes/pipes.module';

import { CertificateComponentComponent } from './certificate-component/certificate-component.component';
import { AddNewBuyerComponent } from './add-new-buyer/add-new-buyer.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NewGroupComponent } from './new-group/new-group.component';
import { TransactionSummaryComponent } from './transaction-summary/transaction-summary.component';
import { DocumentEditorAllModule, DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';


@NgModule({
  declarations: [
    CertificateComponentComponent,
    AddNewBuyerComponent,
    ConfirmDialogComponent,
    NewGroupComponent,
    TransactionSummaryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule, 
    MatButtonModule, 
    MatFormFieldModule, 
    MatDividerModule, 
    MatDatepickerModule, 
    MatIconModule, 
    MatButtonToggleModule,
    NgbModule,
    PipesModule,
    DocumentEditorAllModule,
    DocumentEditorContainerModule
  ],
  exports:[
    CertificateComponentComponent,
    AddNewBuyerComponent,
    ConfirmDialogComponent,
    NewGroupComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SecondaryDialogsModule { }
