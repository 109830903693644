<div *ngIf="isDigShow" class="dialog" id="currencyModal">
  <div class="header">
    <div class="row">
      <div class="col-lg-8 col-sm-10 col-md-10 custom-modal-header">
        <label class="dialogue-title">Currency Convertor</label>
      </div>
      <div class="col-lg-4 col-sm-2 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
         <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container-fluid content-row">
      <div class="row">
        <div class="col-md-12">
          <p class="content-pg-1 top-20">
            Currency Converter helps forex conversion if the investment currency is different from the currency of the round. 
            From the drop down choose the investment currency. Enter the agreed forex rate or the exchange rate between the 
            investment currency and the round currency on the date of transfer. The tool will accordingly 
            calculate the units of investor currency that needs to be invested to meet the investment amount in the round currency.
            <br>
            <br>
            For example, if the investment currency is Singapore Dollars and the round currency is US Dollars, 
            mention the agreed forex rate or the exchange rate between the Singapore Dollars and US dollars on the date of transfer. 
            The tool will then calculate the units of Singapore currency that converts to the investment amount in US Dollars   
          </p>
        </div>
      </div>
      <div class="row btm-20">
        <div class="col-md-12">
          <mat-label>Home Currency is - </mat-label><label>{{homeCurrency}}</label>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="row top-20">
        <div class="col-md-8">
          <mat-label>Choose Currency of Investment</mat-label>
          <div class="top-10">
            <select class="form-control" [(value)]="currecyConObj.currencyOfInvestment" #currencyRnd (change)="selectedCurrency($event.target.value)">
              <option *ngFor="let cur of currencyList; let i = index" value="{{ cur }}"> {{ cur }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row top-20">
        <div class="col-md-8">
          <mat-label>Investment Amount</mat-label>
          <input disabled type="text" title="" class="form-control top-10 text-align-right" placeholder="Enter Investment amount" [ngModel]="getCurrencyconvert(currecyConObj.investmentAmount)" />
        </div>
      </div>
      <div class="row top-20 btm-20">
        <div class="col-md-8">
          <mat-label>Exchange Rate</mat-label>
          <input [readOnly]="isExchangeRteEditable" min="1" title="" type="number" class="form-control top-10" [(ngModel)]="currecyConObj.exchangeRate" (keydown)=onKeydownEvent($event) />
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="row top-20 btm-20">
        <div class="col-md-12">
          <mat-label >You will have to invest</mat-label>
          <mat-label class="mat-label-value left-20">{{currecyConObj.currencyOfInvestment}}</mat-label>
          <mat-label class="mat-label-value left-20">{{getAmt(currecyConObj.investmentAmount / currecyConObj.exchangeRate)}}</mat-label>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="row top-20 btm-20">
        <div class="col-md-12">
          <button mat-raised-button id="save" [disableRipple]="true" color="success" class="float-right" (click)='saveCurrencyInvestment()' ngbTooltip="Save and Exit">Save</button>
          <button mat-raised-button id="cancel" [disableRipple]="true" color="warn" class="float-right margin-right-20" (click)="close()" ngbTooltip="Cancel">Cancel</button>
        </div>
      </div>
    </div>
</div>