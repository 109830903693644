<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10">
        <label class="dialogue-title">Redemption</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <form [formGroup]="redemptionDetails" novalidate>
    <div style="height:550px; overflow: auto;">
      <div class="section-padding">
          <div class="row btm-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the amount at which the shares have been redeemed by the company, the date on which the redemption has happened and the amount available to redeem the same.
              </p>
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-12">
              <mat-card>
                <div class="row btm-20">
                  <div class="col-md-3">
                    <mat-label>
                      Investor Name
                    </mat-label>
                    <p>
                      <mat-label>
                        {{rowData.rowInfo.investorName}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Security
                    </mat-label>
                    <p>
                      <mat-label>
                      {{rowData.rowInfo.securityType}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      PAR
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.par | localeFormatter: 'tooltip-currency'}}">
                      {{rowData.rowInfo.par | localeFormatter: 'currency'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Premium
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.premium | localeFormatter: 'tooltip-currency'}}">
                        {{rowData.rowInfo.premium | localeFormatter: 'currency'}}
                      </mat-label>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <mat-label>
                      No. of Shares
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.numberOfShares | localeFormatter: 'tooltip-number'}}">
                        {{rowData.rowInfo.numberOfShares | localeFormatter: 'number'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Amount
                    </mat-label>
                    <p>
                      <mat-label ngbTooltip="{{rowData.rowInfo.amount | localeFormatter: 'tooltip-currency'}}">
                        {{rowData.rowInfo.amount | localeFormatter: 'currency'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Date
                    </mat-label>
                    <p>
                      <mat-label>
                      {{rowData.rowInfo.date | date: 'dd-MMM-yyyy'}}
                      </mat-label>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <mat-label>
                      Round
                    </mat-label>
                    <p>
                      <mat-label>
                        {{rowData.rowInfo.roundName}}
                      </mat-label>
                    </p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-6">
              <mat-label class="required"> Redeemed Amount ({{currencySymbol}})</mat-label>
              <input class="form-control top-10 text-align-right" type="text" (keydown)="onKeydownEvent($event)" (input)="csvVal($event.target.value, $event)" (keypress)="onlyNumberKey($event)" formControlName="redeemedAmount" placeholder="Enter Redeemed Value" required/>
              <div class="alert alert-danger" *ngIf="redemptionDetails.get('redeemedAmount').errors?.required && (redemptionDetails.get('redeemedAmount').dirty || redemptionDetails.get('redeemedAmount').touched || isSubmitted)">
                Redeemed Amount is required!
              </div>
            </div>
            <div class="col-md-6">
              <mat-label class="required"> Amount Available ({{currencySymbol}})</mat-label>
              <input class="form-control top-10 text-align-right" type="text" (keydown)="onKeydownEvent($event)" (input)="csvVal($event.target.value, $event)" (keypress)="onlyNumberKey($event)" formControlName="amountLeft" placeholder="Enter Amount Available" required/>
              <div class="alert alert-danger" *ngIf="redemptionDetails.get('amountLeft').errors?.required && (redemptionDetails.get('amountLeft').dirty || redemptionDetails.get('amountLeft').touched || isSubmitted)">
                Amount Available is required!
              </div>
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-6">
              <mat-label class="required"> Redeemed Date </mat-label>
              <div style="position: relative;">
                <input matInput [matDatepicker]="vd1" formControlName="redeemedDate" class="form-control top-10" title="" max="9999-12-31" placeholder="DD-MMM-YYYY" required />
                <mat-datepicker-toggle matSuffix [for]="vd1">
                  <mat-icon matDatepickerToggleIcon ></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #vd1></mat-datepicker>
              </div>
              <div class="alert alert-danger" *ngIf="redemptionDetails.get('redeemedDate').errors?.required && (redemptionDetails.get('redeemedDate').dirty || redemptionDetails.get('redeemedDate').touched || isSubmitted)">
                Redeemed Date is required!
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div *ngIf="!isShowNote" (click)="isShowNote = !isShowNote;">
                  <span>
                    <mat-label>Add Notes</mat-label><img class="left-10 cursor-hand" src="../../../../../assets/action/addnotes.svg" alt="add Notes" />
                  </span>
              </div>
              <div *ngIf="isShowNote" (click)="isShowNote = !isShowNote;">
                  <span>
                    <mat-label>Remove Notes</mat-label><img class="left-10 cursor-hand" src="../../../../../assets/action/removenotes.svg" alt="remove Notes" />
                  </span>
              </div>
                <textarea class="form-control top-10" *ngIf="isShowNote" formControlName="notes" placeholder="Add Notes"></textarea>
            </div>
          </div>
        </div>
        <div class="section-padding bottom-padding">
          <div class="row btm-20">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <button mat-raised-button id="save" color="success" type="button" [disableRipple]="true" ngbTooltip="Save" class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="saveRedemption()">
                Save
              </button>
              <button mat-raised-button id="clear" color="warn" type="button" [disableRipple]="true" ngbTooltip="Rest form" class="col-xs-2 col-sm-2 col-md-2 margin-left-lg float-right" (click)="resetForm()">
                Clear
              </button>
              <button mat-raised-button id="cancel" color="warn" type="button" [disableRipple]="true" ngbTooltip="Cancel" class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>