export class Exercise {
		optionHolderId: string;
		optionHolderName:string;
		esopPlanName:string;
		numberOfShares: string;
		dateOfExercise:string;
		exercisePrice:string;
		note: string;
        optionState?:string;
        isCashExercise: boolean = false;
}

export class Vesting{
	id:string;
    employeeName: string;
    planName: string;
    grantPrice: string;
    eventDate: string;
    numberOfSharesToVest:string;
    percentageOfSharesToVest:string;
    isApplyToAllHolders: boolean;
    eventName: string;
    note: string;
    optionState?: string;
}