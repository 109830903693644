/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-conversion.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/form-field";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "@angular/common";
import * as i10 from "./file-conversion.component";
import * as i11 from "../authentication.service";
import * as i12 from "ngx-ui-loader";
import * as i13 from "ngx-toastr";
var styles_FileConversionComponent = [i0.styles];
var RenderType_FileConversionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileConversionComponent, data: {} });
export { RenderType_FileConversionComponent as RenderType_FileConversionComponent };
export function View_FileConversionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [["class", "page-hdr btm-15"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File Converter"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "content-pg-1 btm-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."])), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "row btm-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "mat-label", [], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i2.MatLabel, [], null, null), (_l()(), i1.ɵted(-1, null, ["Select PDF"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "display-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["accept", "application/pdf"], ["class", "form-control top-10 width-70"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 16777216, null, null, 3, "button", [["class", "float-right cmn-button top-10"], ["color", "success"], ["id", "convertToXml"], ["mat-raised-button", ""], ["ngbTooltip", "Convert"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.convertToXML() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"], color: [1, "color"] }, null), i1.ɵdid(17, 212992, null, 0, i8.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i8.NgbTooltipConfig, i1.NgZone, i9.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵted(-1, 0, [" Convert to XML "])), (_l()(), i1.ɵeld(19, 0, null, null, 10, "div", [["class", "row btm-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 9, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "mat-label", [], null, null, null, null, null)), i1.ɵdid(22, 16384, null, 0, i2.MatLabel, [], null, null), (_l()(), i1.ɵted(-1, null, ["Select excel"])), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["class", "display-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "input", [["accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"], ["class", "form-control top-10 width-70"], ["required", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleEXCELFileInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 16777216, null, null, 3, "button", [["class", "float-right cmn-button top-10"], ["color", "success"], ["id", "generate"], ["mat-raised-button", ""], ["ngbTooltip", "Generate"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.convertToPDF() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(27, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"], color: [1, "color"] }, null), i1.ɵdid(28, 212992, null, 0, i8.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i8.NgbTooltipConfig, i1.NgZone, i9.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵted(-1, 0, [" Generate "]))], function (_ck, _v) { var currVal_2 = true; var currVal_3 = "success"; _ck(_v, 16, 0, currVal_2, currVal_3); var currVal_4 = "Convert"; _ck(_v, 17, 0, currVal_4); var currVal_7 = true; var currVal_8 = "success"; _ck(_v, 27, 0, currVal_7, currVal_8); var currVal_9 = "Generate"; _ck(_v, 28, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 16).disabled || null); var currVal_1 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_0, currVal_1); var currVal_5 = (i1.ɵnov(_v, 27).disabled || null); var currVal_6 = (i1.ɵnov(_v, 27)._animationMode === "NoopAnimations"); _ck(_v, 26, 0, currVal_5, currVal_6); }); }
export function View_FileConversionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-conversion", [], null, null, null, View_FileConversionComponent_0, RenderType_FileConversionComponent)), i1.ɵdid(1, 114688, null, 0, i10.FileConversionComponent, [i11.AuthenticationService, i12.NgxUiLoaderService, i13.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileConversionComponentNgFactory = i1.ɵccf("app-file-conversion", i10.FileConversionComponent, View_FileConversionComponent_Host_0, {}, {}, []);
export { FileConversionComponentNgFactory as FileConversionComponentNgFactory };
