import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DxDataGridComponent } from "devextreme-angular";
import { DxTooltipComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Investors } from '../historical-round.model';
import { HistoricalRoundObject, CompanyCapitalDetails } from '../historical-round.model';
import { AuthenticationService } from 'src/app/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { NavbarService } from 'src/app/services/navbar-reload';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../../services/helper.service';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDeleteComponent } from 'src/app/confirm-delete/confirm-delete.component';
import * as events from 'devextreme/events';
import * as _ from 'lodash';
declare var $: any;
import { forEach } from 'lodash';
import { CustomLocaleFormatterPipe } from 'src/app/pipes/locale-formatter.pipe';

interface ShareCapitalFormValue {
  equityAuthorizedShareCapital: string;
  preferenceAuthorizedShareCapital: string;
  equityPaidUpShareCapital: string;
  preferencePaidUpShareCapital: string;
}
@Component({
  selector: 'app-creation',
  templateUrl: './historical-round-creation.component.html',
  styleUrls: ['./historical-round-creation.component.scss'],
  providers: [CustomLocaleFormatterPipe]
})
export class HistoricalRoundCreationComponent implements OnInit {
  
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  @ViewChild("primaryGrid") primaryDataGrid: DxDataGridComponent;
  @ViewChild("splitsGrid") splitsGrid: DxDataGridComponent;
  @ViewChild("secondariesGrid") secondariesDataGrid: DxDataGridComponent;
  @ViewChild("esopGrid") esopGrid: DxDataGridComponent;

  historicalRound = new HistoricalRoundObject();
  showPageSizeSelector = true
  readonly allowedPageSizes = [10, 25, 50, 100, 250, 'all'];
  registerNewCompany: boolean = false;
  rounds: Array<string>;
  secondaryRounds: Array<string>;
  isShowNote: boolean = false;
  roundNameDialog = {
    show: false,
    roundName: null,
    roundIdentifier: null,
    isPricedRound: null,
    roundConversionRatio: null,
    roundVotingRightsRatio: null,
    existingRoundDetails: null,
  }
  conversionDialog = {
    show: false,
    rowId: null,
    rowInfo: null,
    investorName: null,
    buyerName: null,
    investorsArray: null,
    conversionDetails: null
  }
  redemptionDialog = {
    show: false,
    rowId: null,
    rowInfo: null,
    investorName: null,
    buyerName: null,
    investorsArray: null,
    redemptionDetails: null
  }
  toolTipTarget: any;
  toolTipText: any;
  userDetails: any = null;
  usrCompanyCurrency: any = null;
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  roundName: Observable<string>;
  roundNameSubscriber: Subscriber<any>;
  selectedInvestorRow = null;
  showdigPrfle: boolean = false;
  investorProfile = {
    rowInfo: null,
    rowId: null,
    investorsArray: null
  }
  capitalSettingsForm: FormGroup;
  isSubmitted: boolean = false;
  clickEventsubscription: Subscription;
  securityTypeFilterPrim = [];
  parNumberFilterPrim = [];
  premiumNumberFilterPrim = [];
  noOfSharesFilterPrim = [];
  amountFilterPrim = [];
  roundNameFilterPrim = [];
  noOfSharesFilterSec = [];
  ppsFilterSec = [];
  amountFilterSec = [];
  securityTypeFilterSec = [];
  roundNameFilterSec = [];
  allotmentsFilter = [];
  transactionsFilter = [];
  currencySymbol: "";

  constructor( 
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheProviderService,
    private router: Router,
    private utilityService: UtilityService,
    private helperService: HelperService,
    private navbarService: NavbarService,
    private readonly dialog: MatDialog,
    private customLocaleFormater: CustomLocaleFormatterPipe,
    private fb: FormBuilder ) { 
      this.dxCustomizeCsv = this.dxCustomizeCsv.bind(this);
      this.dxCustomizeDecimalCsv = this.dxCustomizeDecimalCsv.bind(this);
      this.dxCustomizeValueColumn = this.dxCustomizeValueColumn.bind(this);
      this.setRoundName = this.setRoundName.bind(this);
      this.addInvestorProfile = this.addInvestorProfile.bind(this);
      this.openRoundDialog = this.openRoundDialog.bind(this);
      this.openConversionDialog = this.openConversionDialog.bind(this);
      this.openRedemptionDialog = this.openRedemptionDialog.bind(this);
      this.clickEventsubscription =  this.navbarService.getClickEvent().subscribe(()=>{
        this.ngOnInit();
      })
    }

  initRoundsArray(toDefault:boolean){
    if(toDefault){
      this.rounds = ["Add Round Name", "Auto Fill", "Incorporation Round", "ESOP Exercise"];
      this.secondaryRounds = ["Auto Fill", "Incorporation Round", "ESOP Exercise"]
      return this.rounds, this.secondaryRounds;
    }
    else{
      return this.rounds = ["Add Round Name"];
    }
  }

  // isRedemptionDone(e){
  //   return e.row.data.redemptionDetails != null;
  // }

  // isConversionDone(e){   
  //   return e.row.data.conversionDetails != null; 
  // }

  
  displayAsTotal(){
    return "Total";
  }

  calculateAmountPAR(newData, value, currRow){
    if(currRow.securityType != "Warrant")
      newData.amount =  +(currRow.numberOfShares * (value + currRow.premium)).toFixed(2);
    newData.par = value;
  }
  
  calculateAmountPremium(newData, value, currRow){
    if(currRow.securityType != "Warrant")
      newData.amount =  +(currRow.numberOfShares * (value + currRow.par)).toFixed(2);
    newData.premium = value;
  }

  calculateAmountNoOfShares(newData, value, currRow){
    if(currRow.securityType != "Warrant")
      newData.amount =  +((currRow.par + currRow.premium) * value).toFixed(2);
    newData.numberOfShares = value;
  }

  calculateAmount4(newData, value, currRow){
    newData.amount =  +(currRow.numberOfShares * value).toFixed(2);
    newData.pricePerShare = value;
  }

  calculateAmount5(newData, value, currRow){
    newData.amount =  +(currRow.pricePerShare * value).toFixed(2);
    newData.numberOfShares = value;
  }

  getInvestorProfile(e) {
    this.showdigPrfle = e.showMdl;
    document.getElementById("overlay").style.display = "none";
    console.log('investor profile after popup: ', this.investorProfile);
    if(this.selectedInvestorRow.buyer) {
      let selectedInvestorRow = this.selectedInvestorRow;
      let selectedRowKey = this.secondariesDataGrid.instance.getSelectedRowKeys();
      this.secondariesDataGrid.instance.deselectRows(selectedRowKey);

      for(let historicIndex = 0; historicIndex < this.historicalRound.investors.length; historicIndex++) {
        let investor = this.historicalRound.investors[historicIndex];
        if(investor != null && investor.investorName == selectedInvestorRow.buyer) {
          let buyer = investor;
          buyer['investorProfile'] = this.investorProfile['rowInfo']['investorProfile'];
          this.historicalRound.investors[historicIndex] = buyer;
          break;
        }
      }
    } else {
      let selectedRowKey = this.primaryDataGrid.instance.getSelectedRowKeys();
      this.primaryDataGrid.instance.deselectRows(selectedRowKey);
    }
  }

  getRoundName(isRoundNameSaved: boolean){
    if(isRoundNameSaved){
      this.roundNameSubscriber.next(this.roundNameDialog);
      this.updateRoundsArray(this.roundNameDialog.roundName);
    } else {
      const visibleRows = this.primaryDataGrid.instance.getVisibleRows();
      if(visibleRows.length > 0) {
        let editRow = _.find(visibleRows, { isEditing: true });
        if(editRow) {
          editRow['cells'][10].value = undefined;
        }
      }
    }
    this.roundNameDialog.show = false;
    this.roundNameDialog.roundName = null;
    this.roundNameDialog.isPricedRound = null;
    this.roundNameDialog.roundConversionRatio = null;
    this.roundNameDialog.roundVotingRightsRatio = null;
    document.getElementById("overlay").style.display = "none";
  }

  setRoundName(newData, value, currRow){
    if(value == "Add Round Name"){
      const promise = new Promise((resolve, reject)=>{
        if(currRow) {
          this.roundNameDialog.roundName = currRow.roundName;
          this.roundNameDialog.roundIdentifier = currRow.roundIdentifier;
          this.roundNameDialog.isPricedRound = currRow.isPricedRound;
          this.roundNameDialog.roundConversionRatio = currRow.roundConversionRatio;
          this.roundNameDialog.roundVotingRightsRatio = currRow.roundVotingRightsRatio;
        }
        this.roundNameDialog.existingRoundDetails = this.historicalRound.historicalRoundPopupDetails;
        this.roundNameDialog.show = true;
        document.getElementById("overlay").style.display = "block";
        this.roundName.subscribe((res:any)=>{
          let obj = {...res};
          resolve(obj);
        });
      });
      return promise.then((res:any)=>{
        if(newData) {
          newData.roundName = res.roundName;
          newData.roundIdentifier = res.roundIdentifier;
          newData.isPricedRound = res.isPricedRound;
          newData.roundConversionRatio = res.roundConversionRatio;
          newData.roundVotingRightsRatio = res.roundVotingRightsRatio;
          newData.updateConversionRatios = _.find(res.existingRoundDetails, { roundName: res.roundName }).updateConversionRatios;
          if(res.isPricedRound == false) {
            if(currRow['buyer']) {
              if(currRow['transactions'] && 
              currRow['transactions'].some((element) => { return element['name'] === 'Non Priced Round' }) ) {}
              else {
                if(currRow['transactions']) {
                  newData['transactions'] = currRow['transactions'];
                  newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' })
                } else {
                  newData['transactions'] = [];
                  newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' })
                }
              }
            } else {
              if(currRow['allotment'] && 
              currRow['allotment'].some((element) => { return element['name'] === 'Non Priced Round' }) ) {}
              else {
                if(currRow['allotment']) {
                  newData['allotment'] = currRow['allotment'];
                  newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' })
                } else {
                  newData['allotment'] = [];
                  newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' })
                }
              }
            }
          }
        }
        if(_.find(this.historicalRound.investors, { roundName: res.roundName })) {
          this.historicalRound.investors.forEach(element => {
            if(element['roundName'] == res.roundName) {
              element['roundIdentifier'] = res.roundIdentifier;
              element['isPricedRound'] = res.isPricedRound;
              element['roundConversionRatio'] = res.roundConversionRatio;
              element['roundVotingRightsRatio'] = res.roundVotingRightsRatio;
              element['updateConversionRatios'] = _.find(res.existingRoundDetails, { roundName: res.roundName }).updateConversionRatios;
            }
          });
        }
        this.historicalRound.historicalRoundPopupDetails = res.existingRoundDetails;
        this.roundNameSubscriber.unsubscribe();
      })
    }
    else{
      newData.roundName = value;
      if(value != 'Auto Fill') {
        let roundSelected = _.find(this.historicalRound.historicalRoundPopupDetails, { roundName: value });
        newData.roundIdentifier = roundSelected.roundIdentifier;
        newData.isPricedRound = roundSelected.isPricedRound;
        newData.roundConversionRatio = roundSelected.roundConversionRatio;
        newData.roundVotingRightsRatio = roundSelected.roundVotingRightsRatio;
        newData.updateConversionRatios = roundSelected.updateConversionRatios;
        if(roundSelected.isPricedRound == false) {
          if(currRow['buyer']) {
            if(currRow['transactions'] && 
            currRow['transactions'].some((element) => { return element['name'] === 'Non Priced Round' }) ) {}
            else {
              if(currRow['transactions']) {
                newData['transactions'] = currRow['transactions'];
                newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' })
              } else {
                newData['transactions'] = [];
                newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' })
              }
            }
          } else {
            if(currRow['allotment'] && 
            currRow['allotment'].some((element) => { return element['name'] === 'Non Priced Round' }) ) {}
            else {
              if(currRow['allotment']) {
                newData['allotment'] = currRow['allotment'];
                newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' })
              } else {
                newData['allotment'] = [];
                newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' })
              }
            }
          }
        }
      }
    }
  }

  openRoundDialog() {
    this.setRoundName(null, "Add Round Name", null);
  }

  getConversionData(e){
    document.getElementById("overlay").style.display = "none";
    if(this.selectedInvestorRow.buyer) {
        let selectedRowKey = this.secondariesDataGrid.instance.getSelectedRowKeys();
        this.secondariesDataGrid.instance.deselectRows(selectedRowKey);
    } else {
        let selectedRowKey = this.primaryDataGrid.instance.getSelectedRowKeys();
        this.primaryDataGrid.instance.deselectRows(selectedRowKey);
    }
    this.selectedInvestorRow = null;
    this.conversionDialog.show = false;
  }

  getRedemptionData(e){
    document.getElementById("overlay").style.display = "none";
    if(this.selectedInvestorRow.buyer) {
        let selectedRowKey = this.secondariesDataGrid.instance.getSelectedRowKeys();
        this.secondariesDataGrid.instance.deselectRows(selectedRowKey);
    } else {
        let selectedRowKey = this.primaryDataGrid.instance.getSelectedRowKeys();
        this.primaryDataGrid.instance.deselectRows(selectedRowKey);
    }
    this.selectedInvestorRow = null;
    this.redemptionDialog.show = false;
  }

  addInvestorProfile(){
    this.showdigPrfle = true;
    if(this.selectedInvestorRow.buyer) {
      if(_.find(this.historicalRound.investors, { investorName: this.selectedInvestorRow.buyer })) {
        let buyer = _.find(this.historicalRound.investors, { investorName: this.selectedInvestorRow.buyer });
        this.selectedInvestorRow['investorProfile'] = buyer['investorProfile'];
      }
      this.investorProfile.rowInfo = this.selectedInvestorRow;
      this.investorProfile.investorsArray = this.historicalRound.secondaries;
    } else {
      this.investorProfile.rowInfo = this.selectedInvestorRow;
      this.investorProfile.investorsArray = this.historicalRound.investors;
    }
    console.log('investor profile before popup: ', this.investorProfile);
    document.getElementById("overlay").style.display = "block";
  }

  openConversionDialog(){
    this.conversionDialog.show = true;
    this.conversionDialog.rowId = this.selectedInvestorRow.__KEY__ != null ? this.selectedInvestorRow.__KEY__ : this.selectedInvestorRow.uuid;
    this.conversionDialog.rowInfo = this.selectedInvestorRow;
    this.conversionDialog.investorName = this.selectedInvestorRow.investorName;
    this.conversionDialog.buyerName = this.selectedInvestorRow.buyer;
    this.conversionDialog.investorsArray = this.historicalRound.investors;
    this.conversionDialog.conversionDetails = this.selectedInvestorRow.conversionDetails;
    document.getElementById("overlay").style.display = "block";
  }

  openRedemptionDialog(){
    this.redemptionDialog.show = true;
    this.redemptionDialog.rowId = this.selectedInvestorRow.__KEY__ != null ? this.selectedInvestorRow.__KEY__ : this.selectedInvestorRow.uuid;
    this.redemptionDialog.rowInfo = this.selectedInvestorRow;
    this.redemptionDialog.investorName = this.selectedInvestorRow.investorName;
    this.redemptionDialog.buyerName = this.selectedInvestorRow.buyer;
    this.redemptionDialog.investorsArray = this.historicalRound.investors;
    this.redemptionDialog.redemptionDetails = this.selectedInvestorRow.redemptionDetails;
    document.getElementById("overlay").style.display = "block";
  }
  
  isConversionBtnVisible(e){
    return !e.row.isEditing;
  }

  isRedemptionBtnVisible(e){
    return !e.row.isEditing;
  }

  addNewInvestor(e){
    const textTrimmed = e.text.trim();
    if(textTrimmed == "" || this.historicalRound.investorsDropdown.indexOf(e.text.trim()) >= 0){
       e.customItem = null;
       return;
    }
    else if(textTrimmed.toLocaleLowerCase() == "company"){
      return e.customItem = e.text;
    }
    else{
      this.historicalRound.investorsDropdown.push(e.text);
      return e.text;
    }
  }

  logEvent(eventName, event){
    switch(eventName){

      case 'Initialized':
        event.component.columnOption("par", {
          editorOptions: {
            format: "#,##,##0.00"
          }
        });
        event.component.columnOption("premium", {
          editorOptions: {
            format: "#,##,##0.00"
          }
        });
        event.component.columnOption("pricePerShare", {
          editorOptions: {
            format: "#,##,##0.00"
          }
        });
        event.component.columnOption("numberOfShares", {
          editorOptions: {
            format: "#,##,##0"
          }
        });
        event.component.columnOption("amount", {
          editorOptions: {
            format: "#,##,##0.00"
          }
        });
        event.component.columnOption("splitNumber", {
          editorOptions: {
            format: "1: tyy",
            mask: "1: tyy",
            maskRules : {
              't': /[1-9]/,
              'y': /[0-9]/
            }
          }
        });
        break;

      case 'ToolbarPreparing':
        let toolbarItems = event.toolbarOptions.items;
        toolbarItems.unshift(
          {
            widget: "dxButton",
            cssClass: "add-round-btn",
            options: {
              icon: "repeat",
              hint: "Add New Round",
              onClick: (function(e) {
                this.openRoundDialog();
              }).bind(this)
            },
            location: "after"
          },
          {
            widget: "dxButton",
            cssClass: "redemption-btn",
            options: {
              icon: "money", 
              hint: "Redemption",
              onClick: (function(e){ 
                this.openRedemptionDialog();
              }).bind(this)
            },
            location: "after"
          },
          {
            widget: "dxButton",
            cssClass: "conversion-btn", 
            options: { 
              icon: "repeat", 
              hint: "Conversion",
              onClick: (function(e){ 
                this.openConversionDialog();
              }).bind(this)
            },
            location: "after"
          },
          {
            widget: "dxButton",
            cssClass: "investor-profile-btn", 
            options: { 
              icon: "repeat", 
              hint: "Investor Profile",
              onClick: (function(e){ 
                this.addInvestorProfile();
              }).bind(this)
            },
            location: "after"
          }
        )
        for(let item=0; item < toolbarItems.length; item++){
          if(toolbarItems[item].name == "addRowButton"){
            let addRowBtn = toolbarItems[item];
            addRowBtn.cssClass = "add-new-investor-row";
            toolbarItems.splice(item, 1);
            toolbarItems.unshift(addRowBtn);
            break;
          }
        }
        break;

      case 'CellPrepared':
        if(event.rowType === "data" && event.column.command === "edit") {  
          let isEditing = event.row.isEditing,  
            cancelLink = event.cellElement.querySelector(".dx-link-cancel"),
            saveLink = event.cellElement.querySelector(".dx-link-save"); 
          if(isEditing){  
            events.on(cancelLink, 'dxclick', args => {  
              $(".add-round-btn").css({"display":"table-cell"});
            });  
            events.on(saveLink, 'dxclick', args => {  
              $(".add-round-btn").css({"display":"table-cell"});
            });
          }  
      }  
        break;

      case 'InitNewRow': 
        $(".add-round-btn").hide();
        break;

      case 'EditingStart':
        $(".add-round-btn").hide();
        break;

      case 'EditCanceled':
        $(".add-round-btn").css({"display":"table-cell"});
        break;

      case 'Saved':
        $(".add-round-btn").css({"display":"table-cell"});
        break;

      case 'EditorPreparing':
        if(event.parentType = "dataRow" && event.dataField == "roundName"){ 
          event.editorOptions.itemTemplate = function(itemData, itemIndex, itemElement) {  
              $('<div>')  
              .appendTo(itemElement)  
              .text(itemData)  
              .attr('title', itemData);  
          }
        }
        break;
        
      case 'RowInserted':
        // this.initRoundsArray(true);
        $(".add-round-btn").css({"display":"table-cell"});
        this.historicalRound.investors.find((investor, idx)=>{
          if(investor.investorName == event.data.investorName.trim()){
            return event.data.investorProfile = investor.investorProfile;
          }
        });
        event.component.option("focusedRowKey",event.key);
        this.updateRoundsArray();
        break;

      case 'RowUpdated':
        // this.initRoundsArray(true);
        this.updateRoundsArray();
        break;

      case 'EditCanceled':
        // this.initRoundsArray(true);
        this.updateRoundsArray();
        break;
      
      case 'SelectionChanged':
        if(event.selectedRowsData.length == 1){
          this.selectedInvestorRow = event.selectedRowsData[0];
          $(".conversion-btn, .redemption-btn, .investor-profile-btn").css({"display":"table-cell"});
        }
        else{
          this.selectedInvestorRow = null;
          $(".conversion-btn, .redemption-btn, .investor-profile-btn").hide();
        }
        break;

      case 'CellHoverChanged':
        if ((event.rowType === "data" && event.row.isEditing == false) && (event.column.dataField === "investorName" || event.column.dataField === "securityType" || event.column.dataField === "date" || event.column.dataField === "roundName") && event.eventType === "mouseover") {
          this.toolTipText = event.text;
          this.toolTipTarget = event.cellElement;
          this.tooltip.instance.show();
        }
        else if ((event.rowType === "data" && event.row.isEditing == false) && (event.column.dataField === "premium" || event.column.dataField === "pricePerShare" || event.column.dataField === "amount" || event.column.dataField === "numberOfShares" || event.column.dataField === "par") && event.eventType === "mouseover") {
          if(event.displayValue == null){
            return this.tooltip.instance.hide();
          }
          else if (event.displayValue === 0) {
            this.toolTipText = event.text;
          }
          else {
            this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, event.displayValue);
          }
          this.toolTipTarget = event.cellElement;
          this.tooltip.instance.show();
        }
        else if (event.rowType === "totalFooter" && (event.column.dataField === "numberOfShares" || event.column.dataField === "amount") && event.eventType === "mouseover") {
          if (event.displayValue === 0) {
            this.toolTipText = event.summaryItems[0].value;
          }
          else {
            this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, 
              this.toolTipText = event.summaryItems[0].value);
          }
          this.toolTipTarget = event.cellElement;
          this.tooltip.instance.show();
        }
        else if ((event.rowType === "data" || event.rowType === "totalFooter") && event.eventType === "mouseout") {
          this.tooltip.instance.hide();
        }

      case 'RowValidating':
        const updatedRowData = {...event.oldData, ...event.newData}
        if(updatedRowData.roundName == "Incorporation Round" && updatedRowData.securityType != "Equity" ){
          event.isValid = false;
          this.toastr.error("Security should be 'Equity' for Incorporation Round.", "Failure!", { tapToDismiss: true, disableTimeOut: true });
        }
        else if((updatedRowData.securityType == "CCD" || updatedRowData.securityType == "Note" || updatedRowData.securityType == "NCDS") && updatedRowData.par == null){
          event.isValid = false;
          this.toastr.error("PAR column cannot be empty for the selected Security.", "Failure!", { tapToDismiss: true, disableTimeOut: true });
        }

        if(updatedRowData.securityType == "Warrant" && (updatedRowData.par != null && updatedRowData.amount != null) && updatedRowData.par < updatedRowData.amount){
          event.isValid = false;
          this.toastr.error("PAR value cannot be lesser than the amount invested", "Failure!", { tapToDismiss: true, disableTimeOut: true });
        }
      }
    }

    onPrimContentReady(e) {
      $(e.element).find("#primRowText").remove();
      $(e.element).find(".dx-page-sizes")
      .before("<div id='primRowText' style='float:left; margin: 7px 7px 7px -20px; font-size: 12px;'>Rows per page: </div>");
    }

    onSecContentReady(e) {
      $(e.element).find("#secRowText").remove();
      $(e.element).find(".dx-page-sizes")
      .before("<div id='secRowText' style='float:left; margin: 7px 7px 7px -20px; font-size: 12px;'>Rows per page: </div>");
    }

    logSecondariesEvent(eventName, event) {
        switch (eventName) {
            case 'ToolbarPreparing':
                let toolbarItems = event.toolbarOptions.items;
                toolbarItems.unshift(
                  {
                    widget: "dxButton",
                    cssClass: "sec-redemption-btn",
                    options: {
                      icon: "money", 
                      hint: "Redemption",
                      onClick: (function(e){ 
                        this.openRedemptionDialog();
                      }).bind(this)
                    },
                    location: "after"
                  },
                  {
                    widget: "dxButton",
                    cssClass: "sec-conversion-btn", 
                    options: { 
                      icon: "repeat", 
                      hint: "Conversion",
                      onClick: (function(e){ 
                        this.openConversionDialog();
                      }).bind(this)
                    },
                    location: "after"
                  },
                  {
                    widget: "dxButton",
                    cssClass: "sec-investor-profile-btn", 
                    options: { 
                      icon: "repeat", 
                      hint: "Investor Profile",
                      onClick: (function(e){ 
                        this.addInvestorProfile();
                      }).bind(this)
                    },
                    location: "after"
                  }
                )
                for(let item=0; item < toolbarItems.length; item++){
                  if(toolbarItems[item].name == "addRowButton"){
                    let addRowBtn = toolbarItems[item];
                    addRowBtn.cssClass = "add-new-investor-row";
                    toolbarItems.splice(item, 1);
                    toolbarItems.unshift(addRowBtn);
                    break;
                  }
                }
                break;

            case 'RowInserted':
                // this.initRoundsArray(true);
                this.historicalRound.investors.find((investor, idx)=>{
                  if(investor.investorName == event.data.investorName.trim()){
                    return event.data.investorProfile = investor.investorProfile;
                  }
                });
                event.component.option("focusedRowKey",event.key);
                this.updateRoundsArray();
                break;

            case 'SelectionChanged':
                if (event.selectedRowsData.length == 1) {
                    this.selectedInvestorRow = event.selectedRowsData[0];
                    $(".sec-conversion-btn, .sec-redemption-btn, .sec-investor-profile-btn").css({ "display": "table-cell" });
                }
                else {
                    this.selectedInvestorRow = null;
                    $(".sec-conversion-btn, .sec-redemption-btn, .sec-investor-profile-btn").hide();
                }
                break;
        }
    }

    logEsopEvent(eventName, event){
      switch(eventName){
  
        case 'Initialized':
          event.component.columnOption("poolSize", {
            editorOptions: {
              format: "#,##,##0"
            }
          });

          break;
  
        case 'CellHoverChanged':
          if ((event.rowType === "data" && event.row.isEditing == false) && (event.column.dataField === "date" || event.column.dataField == "splitNumber") && event.eventType === "mouseover") {
            this.toolTipText = event.text;
            this.toolTipTarget = event.cellElement;
            this.tooltip.instance.show();
          }
          else if ((event.rowType === "data" && event.row.isEditing == false) && ( event.column.dataField === "poolSize") && event.eventType === "mouseover") {
            if(event.displayValue == null){
              return this.tooltip.instance.hide();
            }
            else if (event.displayValue === 0) {
              this.toolTipText = event.text;
            }
            else {
              this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, event.displayValue);
            }
            this.toolTipTarget = event.cellElement;
            this.tooltip.instance.show();
          }       
        }
      }

  /** Show Contextual help **/
  showHelp(section: string) {
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);
    
    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "HistoricRoundComponent",
      section
    );
    this.helper = section;
  }

  hideHelp() {
    this.helper = "";
  }
  /** Show Contextual help **/    

  datePickerChanged(cellData, event){
    let dt = new Date(event.target.value._d);
    cellData.setValue(dt.toISOString().replace("Z", "+0000"));
  }

  addInvestor(){
    if(this.primaryDataGrid.instance.hasEditData()) {
        this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
    } else {
        this.primaryDataGrid.instance.addRow();
    }
  }

  addPoolAllocation(){
    if(this.esopGrid.instance.hasEditData()) {
        this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
    } else {
        this.esopGrid.instance.addRow();
    }
  }

  addSplits(){
    if(this.splitsGrid.instance.hasEditData()) {
      this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
    } else {
      this.splitsGrid.instance.addRow();
    }
  }

  addSecondaries(){
    if(this.secondariesDataGrid.instance.hasEditData()) {
      this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
    } else {
      this.secondariesDataGrid.instance.addRow();
    }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  csvVal(value, e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
    let val = value;
    exitVal = this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if(e) {
      if(e.target.value) {
          let CSVLength = exitVal.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = exitVal;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
            e.target.value = exitVal;
            if(start != 0 || end != 0){
                let difference = valueLength - CSVLength;
                start = start - difference;
                end = end - difference;
            }
            else{
                start = start;
                end = end;
            }
          }
          else{
            e.target.value = exitVal;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
      this.setTotalValues(e)
    }
  }

  showBonusShare(cellData) {
    if(cellData && cellData.data['premium'] == 0 && cellData.data['par'] == 0) {
        return true;
    } else {
        return false;
    }
  }

  showPartlyPaidShare(cellData) {
    if(cellData && (cellData.data['premium'] > 0 || cellData.data['par'] > 0)) {
        let pricePerShare = cellData.data['premium'] + cellData.data['par'];
        let calculatedInvAmt = pricePerShare * cellData.data['numberOfShares'];
        let differenceAmt = calculatedInvAmt - cellData.data['amount'];
        if(differenceAmt >= pricePerShare) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
    
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  dxCustomizeCsv(cellValue) {
    const value = this.getTotalValueTble(cellValue.value);
    if (value === 0) {
      return '0';
    }
    else {
      return value;
    }
  }

  dxCustomizeDecimalCsv(cellInfo) {
    if(cellInfo.value == null){
      return;
    }
    let amount = cellInfo.value;
    let currency = this.usrCompanyCurrency;
    var y = amount.toString();
        y = Math.floor(y);
    if (currency && amount && y.toString().length > 5) {
      if (currency === "INR - ₹") {
        return Math.abs(Number(amount)) >= 1.0e7
          ? (Math.abs(Number(amount)) / 1.0e7).toFixed(2) + "Cr"
          : // Six Zeroes for Millions
          Math.abs(Number(amount)) >= 1.0e5
            ? (Math.abs(Number(amount)) / 1.0e5).toFixed(2) + "L"
            : // Three Zeroes for Thousands
            Math.trunc(Math.abs(Number(amount)));
      } else {
        return Math.abs(Number(amount)) >= 1.0e+9

          ? (Math.abs(Number(amount)) / 1.0e+9).toFixed(2) + "B"
          // Six Zeroes for Millions
          : Math.abs(Number(amount)) > 1.0e+6

            ? (Math.abs(Number(amount)) / 1.0e+6).toFixed(2) + "M"

            : Math.abs(Number(amount)) > 1.0e+3

              ? (Math.abs(Number(amount)) / 1.0e+3).toFixed(2) + "K"

              : Math.trunc(Math.abs(Number(amount)));
      }
    } else {
      if (currency === "INR - ₹") {
        var x = amount.toString();
        var afterPoint = '';
        if (x.indexOf('.') > 0)
          afterPoint = x.substring(x.indexOf('.'), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint.substring(0, 3);
        return res;
      }
      else {
        var x = amount.toString();
        var afterPoint = '';
        if (x.indexOf('.') > 0)
          afterPoint = x.substring(x.indexOf('.'), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree + afterPoint.substring(0, 3);
        return res;
      }
    }
    // return cellInfo.valueText;
  }

  dxCustomizeValueColumn(cellValue){
    if(cellValue.value == null){
      return null;
    }
    else{
      const value = this.getTotalValueTble(cellValue.value);
      if (value === 0) {
        return '0';
      }
      else{
        return value;
      }
    }
  }
  
  updateRoundsArray(roundName?: string){
    if(roundName != null && !this.rounds.includes(roundName)){
      this.rounds.push(roundName);
      this.secondaryRounds.push(roundName);
      return this.rounds, this.secondaryRounds;
    }
    // this.initRoundsArray(true);
    this.historicalRound.investors.forEach((item,i)=>{
      if(!this.rounds.includes(item.roundName)){
        this.rounds.push(item.roundName);
        this.secondaryRounds.push(item.roundName);
      }
    });
  }

  discardHistoricalRound(){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Discard?" , text:"Are you sure you want to Discard ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    const userDetails = JSON.parse(this.cacheService.get("userDetails"));
    if(userDetails != null){
      this.ngxLoader.start();
      this.authService.discardHistoricalRound(userDetails.company.id).subscribe((response)=>{
        if(response["resultCode"]){
          localStorage.removeItem('companyId');
          localStorage.removeItem('companyName');
          this.authService.getUserDetails().subscribe(
            (res) => {
              this.cacheService.set("userDetails", JSON.stringify(res));
              this.navbarService.sendClickEvent();
              setTimeout(() => {
                this.ngxLoader.stop();                
                this.router.navigate(['/landing','onboarding', { newComp: true }]);
              }, 300);
            },
            e => {
              this.ngxLoader.stop();
              this.toastr.error(e.error.reason, "Error", { tapToDismiss: true, disableTimeOut: true });
            }
          );
          this.toastr.success("Historical Round Successfully Discarded.", "Success!", { tapToDismiss: true, disableTimeOut: true });
        }
        else{
          this.ngxLoader.stop();
        }
      },(e)=>{
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true })
      })
    }
    this.ngxLoader.stop();

      }
    });
  }

  fileChangeEvent(event: any): void {
    this.ngxLoader.start();
    let fd = new FormData();
    fd.append('file', event.target.files[0]);
    fd.append('isHistoricalScreen', "true");
    this.authService.importHistoricalRoundDetails(fd).subscribe((response)=>{
      this.ngxLoader.stop();
      $("#uploadImage").val(null);
      this.getHistoricalRoundDetails();
      this.toastr.success("Successfully uploaded.", "Success;", { tapToDismiss: true, disableTimeOut: true })
    },(e)=>{
      $("#uploadImage").val(null);
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true });
      })
  }

  saveForLater(){
    this.ngxLoader.start();

    const cd = this.capitalSettingsForm.value;

    if(this.historicalRound.companyCapitalDetails == null){
      this.historicalRound.companyCapitalDetails = new CompanyCapitalDetails();
    }

    this.historicalRound.companyCapitalDetails.equityAuthorizedShareCapital = cd.equityAuthorizedShareCapital.toString().replace(/,/g, "")/1;

    this.historicalRound.companyCapitalDetails.preferenceAuthorizedShareCapital = cd.preferenceAuthorizedShareCapital.toString().replace(/,/g, "")/1;
    
    this.historicalRound.companyCapitalDetails.equityPaidUpShareCapital = cd.equityPaidUpShareCapital.toString().replace(/,/g, "")/1;
    
    this.historicalRound.companyCapitalDetails.preferencePaidUpShareCapital = cd.preferencePaidUpShareCapital.toString().replace(/,/g, "")/1;

    this.historicalRound.splitDetails.forEach((item)=>{
      item.splitNumber = parseInt(item.splitNumber.toString(),10);
    });
    
    this.authService.saveHistoricalRoundForLater(this.historicalRound).subscribe((response)=>{
      this.ngxLoader.stop();
      this.toastr.success("Successfully saved.", "Success!", { tapToDismiss: true, disableTimeOut: true })
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true })
    })
  }

  saveHistoricalRoundDetails(){
    this.isSubmitted = true;
    if(this.capitalSettingsForm.valid){
      
      if(this.historicalRound.investors.length == 0){
        return this.toastr.error("Please fill investor details!", "Error", { tapToDismiss: true, disableTimeOut: true });
      }

      this.isSubmitted = false;
      const cd = this.capitalSettingsForm.value;

      if(this.historicalRound.companyCapitalDetails == null){
        this.historicalRound.companyCapitalDetails = new CompanyCapitalDetails();
      }

      this.historicalRound.companyCapitalDetails.equityAuthorizedShareCapital = cd.equityAuthorizedShareCapital.toString().replace(/,/g, "")/1;

      this.historicalRound.companyCapitalDetails.preferenceAuthorizedShareCapital = cd.preferenceAuthorizedShareCapital.toString().replace(/,/g, "")/1;
      
      this.historicalRound.companyCapitalDetails.equityPaidUpShareCapital = cd.equityPaidUpShareCapital.toString().replace(/,/g, "")/1;
      
      this.historicalRound.companyCapitalDetails.preferencePaidUpShareCapital = cd.preferencePaidUpShareCapital.toString().replace(/,/g, "")/1;

      this.historicalRound.splitDetails.forEach((item)=>{
        item.splitNumber = parseInt(item.splitNumber.toString(),10);
      });

      this.ngxLoader.start();
      this.authService.historicalRoundDetails(this.historicalRound).subscribe((response)=>{
        this.ngxLoader.stop();
        this.router.navigate(['/landing','onboarding','historical-round-summary']);
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true })
      })
    }
  }

  getHistoricalRoundDetails(){
    this.ngxLoader.start();
    this.authService.getHistoricalRoundDetails().subscribe((response:any)=>{
      this.ngxLoader.stop();
      if(response != null){
        this.historicalRound = response;
        this.historicalRound.investors.forEach(element => {
            element['allotment'] = [];
            if(element['conversionDetails']) {
                element['allotment'].push({ name: 'Converted', identifier: 'C' });
            } 
            if(element['redemptionDetails']) {
                element['allotment'].push({ name: 'Redeemed', identifier: 'R'});
            }
            if(element['isPricedRound'] == false) {
              element['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' });
            }
            if(!element['conversionDetails'] && !element['redemptionDetails']) {
                element['allotment'].push({ name: 'Allotment', identifier: ''});
            }
        });
        this.historicalRound.secondaries.forEach(element => {
            element['transactions'] = [];
            if(element['conversionDetails']) {
                element['transactions'].push({ name: 'Converted', identifier: 'C' });
            } 
            if(element['redemptionDetails']) {
                element['transactions'].push({ name: 'Redeemed', identifier: 'R'});
            }
            if(element['isPricedRound'] == false) {
              element['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' });
            }
            if(element['buyer'] == 'Company' || element['buyer'] == 'company' || element['buyer'] == 'COMPANY') {
                element['transactions'].push({ name: 'Buy-back', identifier: 'B' })
            }
            if(element['transactions'].length == 0) {
                element['transactions'].push({ name: 'Transactions', identifier: '' });
            }
        });

        if(this.historicalRound.historicalRoundPopupDetails.length == 0) {
          this.historicalRound.historicalRoundPopupDetails = [
            {
              isPricedRound: true,
              roundConversionRatio: null,
              roundIdentifier: "FF",
              roundName: "Incorporation Round",
              roundVotingRightsRatio: null,
              updateConversionRatios: []
            },
            {
              isPricedRound: true,
              roundConversionRatio: null,
              roundIdentifier: "EE",
              roundName: "ESOP Exercise",
              roundVotingRightsRatio: null,
              updateConversionRatios: []
            },
          ]
        }

        if(this.historicalRound.companyCapitalDetails != null){
          const cd = this.historicalRound.companyCapitalDetails;
          
          this.capitalSettingsForm.controls["equityAuthorizedShareCapital"].setValue(this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, cd.equityAuthorizedShareCapital));
          this.capitalSettingsForm.controls["preferenceAuthorizedShareCapital"].setValue(this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, cd.preferenceAuthorizedShareCapital));
          this.capitalSettingsForm.controls["equityPaidUpShareCapital"].setValue(this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, cd.equityPaidUpShareCapital));
          this.capitalSettingsForm.controls["preferencePaidUpShareCapital"].setValue(this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, cd.preferencePaidUpShareCapital));
          this.updateAuthorizedTotal()
          this.updatePaidUpTotal()
          this.setHeaderFilters();
        }
        this.updateRoundsArray();
        this.isShowNote = this.historicalRound['notes'] != null ? true : false;
      }
    },(e)=>{
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true });
    })
  }

  calculateRoundFilterExpression(value) {
    var column = this;
        var selector = function (data) {
            var values = column.calculateCellValue(data);
            return values && values.indexOf(value) >= 0;
        };
        return [selector, "=", true];
  }

  calculateCellValue(data) {
    return $.map(data['transactions'], function (o) {
        return o.identifier;
    });
  }

  calculateRoundFilterExpressionPrim(value) {
    var column = this;
    // if (value) {
        var selector = function (data) {
            var values = $.map(data['allotment'], function (o) {
                return o.identifier;
            });
            return values && values.indexOf(value) >= 0;
        };
        return [selector, "=", true];
    // }
  }

  calculateCellValuePrim(data) {
    return $.map(data['allotment'], function (o) {
        return o.identifier;
    });
  }

  setHeaderFilters() {
    this.securityTypeFilterPrim = [];
    this.parNumberFilterPrim = [];
    this.premiumNumberFilterPrim = [];
    this.noOfSharesFilterPrim = [];
    this.amountFilterPrim = [];
    this.roundNameFilterPrim = [];
    this.noOfSharesFilterSec = [];
    this.ppsFilterSec = [];
    this.amountFilterSec = [];
    this.securityTypeFilterSec = [];
    this.roundNameFilterSec = [];
    this.allotmentsFilter = [];
    this.transactionsFilter = [];
    let parValuesArrayPrim = [];
    let premiumValuesArrayPrim = [];
    let noOfSharesArrayPrim = [];
    let amountArrayPrim = [];
    let allotmentsArrayPrim = [];
    this.historicalRound.investors.forEach(element => {
        parValuesArrayPrim.push(element['par']);
        premiumValuesArrayPrim.push(element['premium']);
        noOfSharesArrayPrim.push(element['numberOfShares']);
        amountArrayPrim.push(element['amount']);
        if(element['allotment']) {
            allotmentsArrayPrim.push(element['allotment']);
        }
    });
    let noOfSharesArraySec = [];
    let ppsArraySec = [];
    let amountArraySec = [];
    let transactionsArraySec = [];
    this.historicalRound.secondaries.forEach(element => {
        noOfSharesArraySec.push(element['numberOfShares']);
        ppsArraySec.push(element['pricePerShare']);
        amountArraySec.push(element['amount']);
        if(element['transactions']) {
            transactionsArraySec.push(element['transactions']);
        }
    });
    this.securityTypeFilterPrim = this.createNameFilter(this.historicalRound.investors, 'securityType');
    this.parNumberFilterPrim = this.createNumericalRangeFilter(parValuesArrayPrim, 'par');
    this.premiumNumberFilterPrim = this.createNumericalRangeFilter(premiumValuesArrayPrim, 'premium');
    this.noOfSharesFilterPrim = this.createNumericalRangeFilter(noOfSharesArrayPrim, 'numberOfShares');
    this.amountFilterPrim = this.createNumericalRangeFilter(amountArrayPrim, 'amount');
    this.roundNameFilterPrim = this.createNameFilter(this.historicalRound.investors, 'roundName');
    this.noOfSharesFilterSec = this.createNumericalRangeFilter(noOfSharesArraySec, 'numberOfShares');
    this.ppsFilterSec = this.createNumericalRangeFilter(ppsArraySec, 'pricePerShare');
    this.amountFilterSec = this.createNumericalRangeFilter(amountArraySec, 'amount');
    this.securityTypeFilterSec = this.createNameFilter(this.historicalRound.secondaries, 'securityType');
    this.roundNameFilterSec = this.createNameFilter(this.historicalRound.secondaries, 'roundName');
    this.allotmentsFilter = this.createAllotmentFilter(allotmentsArrayPrim);
    this.transactionsFilter = this.createAllotmentFilter(transactionsArraySec);
  }

  createAllotmentFilter(allotments) {
    let flatAllotments = _.flattenDeep(allotments);
    let uniqueFlatAllot = _.uniqBy(flatAllotments, 'name');
    let filter = [];
    uniqueFlatAllot.forEach(element => {
        let filterObj = {
            text: element['name'] ,
            value: element['identifier']
        }
        filter.push(filterObj);
    });
    return filter;
  }

  createNumericalRangeFilter(investors, dataField) {
    let max = Math.max(...investors);
    let min = Math.min(...investors);
    let filterArray;
    if(max > min) {
        filterArray = this.getIntervals(min, max, 5);
    } else {
        filterArray = [[min, max]];
    }
    let resultArray = []
    filterArray.forEach(element => {
        let filterObj = {
            text: this.utilityService.formatCurrency(this.usrCompanyCurrency,element[0]) + '-' + this.utilityService.formatCurrency(this.usrCompanyCurrency, element[1]),
            value: [
                [dataField, '>=', element[0]],
                [dataField, '<=', element[1]]
            ]
        }
        resultArray.push(filterObj);
    });
    return resultArray;
  }

  createNameFilter(investors, dataField) {
    let resultArray = [];
    let uniqueArray = _.uniqBy(investors, dataField);
    uniqueArray.forEach(element => {
        let filterObj = {
            text: element[dataField] ,
            value: element[dataField]
        }
        resultArray.push(filterObj);
    });
    return resultArray;
  }

  getIntervals(min, max, nInt) {
    let round = this.range(Math.abs(min) > Math.abs(max) ? min : max);
    let max1 = max/round;
    let max2 = Math.ceil(max1)*round;
    
    let min1 = min/round;
    let min2 = Math.floor(min1)*round;

    return this.dividing_into_parts(max2,min2);
  }

  range(d) {
    let i = 1;
    while(Math.floor(d) != 0) {
        d = d/10;
        i = i*10;
    }
    return i/10;
  }

  dividing_into_parts(max0, min0) {
    let diff = (max0 - min0)/5;
    let min = min0;
    let max = min0 + diff;
    let intervals = [];
    while(max <= max0) {
        if(min == max){
            break;
        }
        else if(max < 0) {
            intervals.push([min, (max-1)]);
        }
        else if(max >= 0 && min <= 0){
            intervals.push([min, max]);
        }
        else {
            intervals.push([(min+1), max]);
        }
        min = max;
        max = max + diff;
    }
    return intervals;
  }

  ngOnInit() {
    this.capitalSettingsForm = this.fb.group({
      equityAuthorizedShareCapital: [null, [Validators.required]],
      preferenceAuthorizedShareCapital: [null, [Validators.required]],
      equityPaidUpShareCapital: [null, [Validators.required]],
      preferencePaidUpShareCapital: [null, [Validators.required]],
      authTotal:[null,[]],
      preferenceTotal:[null,[]]
    })
    this.roundName = new Observable((subscriber)=>{
      this.roundNameSubscriber = subscriber;
    })
    this.initRoundsArray(true);
    this.getHistoricalRoundDetails();
    if(this.activatedRoute.snapshot.paramMap.get('newComp') == 'true') {
        this.registerNewCompany = true;
    }
    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    } 
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim()
  }
  setTotalValues(e){
    /**
     * Updates total values in PaidUP and Authorised capital settings
     * @args e : InputEvent
     * @returns void
     */
    const id = e.target['id']
    if(['authorized-equity','authorized-preference'].includes(id)){
      this.updateAuthorizedTotal()
    }
    else if(['paidup-equity','paidup-preference'].includes(id)){
      this.updatePaidUpTotal()
    }
  }
  replaceCommas(input:string){
    if(!input) return 0
    else{
      return parseFloat(input.replace(/,/g, ""))
    }
  }
  updateAuthorizedTotal(){
    const values:ShareCapitalFormValue = this.capitalSettingsForm.value
    const total = this.replaceCommas(values.equityAuthorizedShareCapital) + this.replaceCommas(values.preferenceAuthorizedShareCapital)
    this.capitalSettingsForm.get('authTotal').setValue(this.customLocaleFormater.transform(total, "tooltip-number"))
  }
  updatePaidUpTotal(){
    const values:ShareCapitalFormValue = this.capitalSettingsForm.value
    const total = this.replaceCommas(values.equityPaidUpShareCapital) + this.replaceCommas(values.preferencePaidUpShareCapital)
    this.capitalSettingsForm.get('preferenceTotal').setValue(this.customLocaleFormater.transform(total, "tooltip-number"))
  }
}
