import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { AuthenticationService } from "src/app/authentication.service";
import { HttpResponse } from '@angular/common/http';
import { DocumentEditorContainerComponent } from "@syncfusion/ej2-angular-documenteditor";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-certificate-component",
  templateUrl: "./certificate-component.component.html",
  styleUrls: ["./certificate-component.component.scss"]
})
export class CertificateComponentComponent implements OnInit {
  @Input() showDialogCert: boolean;
  @Input() usedIn: string;
  @Input() investorId: String;
  @Output() visibleChangeCert: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('documenteditor_default') public container: DocumentEditorContainerComponent;
  @ViewChild('document_frame') public documentFrame: ElementRef;
  service: string;
  fileEditingObject = {
    sfdtFormat: '',
    currentFileName: "",
  };
  fileType: string = "";
  isDocxFile = false;
  certificateFile: any = null;

  constructor(private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService, 
    private sanitizer: DomSanitizer) {
    this.service = this.authService.getSFDTformatForNewDocument();
  }

  ngOnInit() {
    if(this.usedIn == 'shareholderDetailsPage') {
        this.ngxLoader.start();
        this.authService.getInvestorShareCertificate(this.investorId).subscribe(
            (res: HttpResponse<any>) => {
                this.fileType = res.headers.get("filetype");
                if (this.fileType == 'text/plain' || this.fileType == 'application/json') {
                    const blb = new Blob([res.body], { type: this.fileType });
                    var reader = new FileReader();
                    reader.onload = () => {
                    this.fileEditingObject.sfdtFormat = reader.result.toString();
                    this.fileEditingObject.currentFileName = res.headers.get('filename');
                    this.isDocxFile = true;
                    this.ngxLoader.stop();
                    }
                    reader.readAsText(blb);
                }
                else {
                    let file = new Blob([res.body], { type: this.fileType });
                    let url = URL.createObjectURL(file);
                    this.certificateFile = this.sanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0&zoom=100');
                    this.isDocxFile = false;
                    this.ngxLoader.stop();
                }
            },
            (e) => {
                this.ngxLoader.stop();
                this.fileEditingObject.sfdtFormat = '';
                this.toastr.error(e.error.reason, "Failure!");
            }
        );
    } else if(this.usedIn == 'termSheetsOverview') {
        this.ngxLoader.start();
        this.authService.viewTermSheet(this.investorId).subscribe(
            (res: HttpResponse<any>) => {
                this.fileType = res.headers.get("filetype");
                if (this.fileType == 'text/plain' || this.fileType == 'application/json') {
                    const blb = new Blob([res.body], { type: this.fileType });
                    var reader = new FileReader();
                    reader.onload = () => {
                    this.fileEditingObject.sfdtFormat = reader.result.toString();
                    this.fileEditingObject.currentFileName = res.headers.get('filename');
                    this.isDocxFile = true;
                    this.ngxLoader.stop();
                    }
                    reader.readAsText(blb);
                }
                else {
                    let file = new Blob([res.body], { type: this.fileType });
                    let url = URL.createObjectURL(file);
                    this.certificateFile = this.sanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0&zoom=100');
                    this.isDocxFile = false;
                    this.ngxLoader.stop();
                }
            },
            (e) => {
                this.ngxLoader.stop();
                this.fileEditingObject.sfdtFormat = '';
                this.toastr.error(e.error.reason, "Failure!");
            }
        );
    }
  }

  onCreate(): void {
    this.container.documentEditor.fitPage('FitPageWidth');
    this.container.documentEditor.isReadOnly;
    this.container.documentEditor.open(this.fileEditingObject.sfdtFormat);
    this.container.documentEditor.documentName = this.fileEditingObject.currentFileName;
    this.container.restrictEditing = true;
    this.container.headers = [{
      'x-user-token': localStorage.getItem('currentUser')
    }];
    // this.container.documentEditor.keyDown = (args: DocumentEditorKeyDownEventArgs) => {
    //   this.onKeyDown(args);
    // }
  }

  close() {
    this.showDialogCert = false;
    this.visibleChangeCert.emit(this.showDialogCert);
  }
}
