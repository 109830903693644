<div class="hrms-login-dialog">
  <div class="header">
      <div class="row">
          <div class="col-md-10 custom-modal-header">
              <label class="dialogue-title">Login</label>
          </div>
          <div class="col-md-2 close-icon">
            <button type="button" class="close" (click)="close()" aria-label="Close" id="close" [mat-dialog-close]="'close'">
                <span class="close">X</span>
            </button>
          </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div [ngClass]="{'login-card':formType==='login','signup-card':formType==='signup' }">
            <div class="form-card">

              <!-- Login form-->
              <form class="form" [formGroup]="loginForm" *ngIf="formType==='login'" (ngSubmit)="login(loginForm)"
                novalidate>
                <div class="form-group margin-top-lg">
                  <mat-label class="required">Client ID</mat-label>
                  <input type="text" class="form-control top-10" id="login-clientId" formControlName="clientId" autocomplete="off" placeholder="Client ID"
                    required />

                  <div class="alert alert-danger"
                    *ngIf="(loginForm.controls.clientId.touched || loginForm.controls.clientId.dirty)">
                    <div *ngIf="loginForm.controls.clientId.errors?.required">
                      Client Id is required
                    </div>
                    <div *ngIf="loginForm.controls.clientId.errors?.pattern">
                      Invalid Client Id
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <mat-label class="required">Client Secret</mat-label>
                  <div class="input-eyePlaceholder">
                    <input type="text" id="login-clientSecret" class="form-control top-10" formControlName="clientSecret"
                      autocomplete="off" placeholder="Client Secret Key"  required/>
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="!loginForm.controls.clientSecret.valid && loginForm.controls.clientSecret.touched">
                    Client Secret Key is required
                  </div>
                </div>

                <div class="form-group margin-top-lg">
                  <mat-label class="required">API Key</mat-label>
                  <input type="text" class="form-control top-10" id="login-apikey" formControlName="apiKey" autocomplete="off" placeholder="API Key"
                    required />

                  <div class="alert alert-danger"
                    *ngIf="(loginForm.controls.apiKey.touched || loginForm.controls.apiKey.dirty)">
                    <div *ngIf="loginForm.controls.apiKey.errors?.required">
                      API Key is required
                    </div>
                    <div *ngIf="loginForm.controls.apiKey.errors?.pattern">
                      Invalid API Key
                    </div>
                  </div>
                </div>

                <div class="form-group margin-top-lg">
                  <mat-label class="required">Tenent Name</mat-label>
                  <input type="text" class="form-control top-10" id="login-tenentName" formControlName="tenentName" autocomplete="off" placeholder="Tenent Name"
                    required />

                  <div class="alert alert-danger"
                    *ngIf="(loginForm.controls.tenentName.touched || loginForm.controls.tenentName.dirty)">
                    <div *ngIf="loginForm.controls.tenentName.errors?.required">
                      Tenent Name is required
                    </div>
                    <div *ngIf="loginForm.controls.tenentName.errors?.pattern">
                      Tenent Name Key
                    </div>
                  </div>
                </div>

                <div class="form-group margin-top-lg">
                  <button mat-raised-button color="success" id="login" [disabled]="(!loginForm.controls.clientId.valid && loginForm.controls.clientId.touched) ||
                      (!loginForm.controls.clientSecret.valid && loginForm.controls.clientSecret.touched) ||
                      (!loginForm.controls.apiKey.valid && loginForm.controls.apiKey.touched) ||
                      (!loginForm.controls.tenentName.valid && loginForm.controls.tenentName.touched)" type="submit"
                    class="width-100 submit-button" [disableRipple]="true" ngbTooltip="Log In">Log In</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
  </div>

</div>