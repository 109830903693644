import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthenticationService } from "src/app/authentication.service";
import { ConfirmDeleteComponent } from "src/app/confirm-delete/confirm-delete.component";
import { UtilityService } from "src/app/services/utilities.service";
import { EmailTemplateEditorComponent } from "./email-template-editor/email-template-editor.component";
import { ViewDocsDialogComponent } from "./view-docs-dialog/view-docs-dialog.component";
interface Settings {
  acceptanceNumber?: number;
  reminderEmailsInterval?: number;
  reminderEmailsStartFrom?: number;
  communicationEmailId?: string;
  emailToBeSent?: boolean;
  faqForEmployee?: boolean;
  grantForSpecificPlan?: boolean;
  grantSignature?: string;
  ppsForEmployee?: number;
  senderNameForEmail?: string;
  showPPSToEmployees?: boolean;
  isEsignEnabled?: boolean;
  approverMailToAdmin?: boolean;
  isAttachmentEnabled?: boolean;
  showEmpPortalValuesForecast?: boolean;
  isCustomizedPlanEnabled?: boolean;
  reminderEmailsToBeSent?: boolean;
  employeeInactivityLogout?: boolean;
  shouldEmployeeBeIntimidated?: boolean;
  showPlanToEmployees?:boolean
  planLevelGrantTemplate?: [
    { planId: number; planLevelGrantTemplate: string; planName: string }
  ];
  listOfPlansWithoutTemplates?: [
    { planId: number; planLevelGrantTemplate: string; planName: string }
  ];
  isEnabledTwoFactorAuthentication?: boolean;
}
@Component({
  selector: "app-site-settings",
  templateUrl: "./site-settings.component.html",
  styleUrls: ["./site-settings.component.scss"],
})
export class SiteSettingsComponent implements OnInit {
  @ViewChild("emailTemplate") emailTempDiv: ElementRef;
  selectedEmail: boolean;
  viewEmailTemp: string;
  emailTemp: Object;
  editEmail: boolean;
  emailBodyFile: string;
  emailTemplateName: string;
  emailTemplateType = "";
  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private readonly utilityService: UtilityService,
    private readonly ngxLoader: NgxUiLoaderService,
    private readonly dialog: MatDialog,
    private readonly sanitizer: DomSanitizer
  ) {}
  initialSettings: Settings = {};
  updatedSettings: Settings = {};
  userCompanyCurrency = "";
  grantSignature;
  listOfPlansWithoutTemplates = [];
  planLevelGrantTemplate = [];
  isAllowAddPlan: boolean;
  selectedPlan;
  emailValidation: boolean = false;

  ngOnInit() {
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (
      userDetails &&
      userDetails["company"] &&
      userDetails["company"]["companyCurrency"]
    ) {
      this.userCompanyCurrency = userDetails["company"]["companyCurrency"];
      this.userCompanyCurrency =
        this.userCompanyCurrency.split("-")[
          this.userCompanyCurrency.split("-").length - 1
        ];
    }
    this.authService.getEmailTemplate().subscribe((res) => {
      this.emailTemp = res;
    });
    this.getSettings();
  }
  getSettings() {
    this.ngxLoader.start();
    this.authService.getAdminSettings().subscribe(
      (res: Settings) => {
        this.ngxLoader.stop();
        this.initialSettings = res;
        if (this.initialSettings) {
          this.initialSettings.showPPSToEmployees =
            this.initialSettings.showPPSToEmployees || false;
          this.initialSettings.faqForEmployee =
            this.initialSettings.faqForEmployee || false;
          this.initialSettings.approverMailToAdmin =
            this.initialSettings.approverMailToAdmin || false;
          this.initialSettings.isAttachmentEnabled =
            this.initialSettings.isAttachmentEnabled || false;
          this.initialSettings.showEmpPortalValuesForecast =
            this.initialSettings.showEmpPortalValuesForecast || false;
          this.initialSettings.isCustomizedPlanEnabled =
            this.initialSettings.isCustomizedPlanEnabled || false;
          this.initialSettings.reminderEmailsToBeSent =
            this.initialSettings.reminderEmailsToBeSent || false;
          this.initialSettings.employeeInactivityLogout =
            this.initialSettings.employeeInactivityLogout || false;
          this.initialSettings.shouldEmployeeBeIntimidated =
            this.initialSettings.shouldEmployeeBeIntimidated || false;
          this.initialSettings.listOfPlansWithoutTemplates = this
            .initialSettings.listOfPlansWithoutTemplates || [
            { planId: null, planLevelGrantTemplate: null, planName: null },
          ];
          this.initialSettings.planLevelGrantTemplate = this.initialSettings
            .planLevelGrantTemplate || [
            { planId: null, planLevelGrantTemplate: null, planName: null },
          ];
          this.grantSignature = this.initialSettings.grantSignature;
          // this.downloadObject("grantSignature");
          this.updatedSettings = JSON.parse(
            JSON.stringify(this.initialSettings)
          );
          if (
            this.updatedSettings.listOfPlansWithoutTemplates &&
            this.updatedSettings.listOfPlansWithoutTemplates.length
          ) {
            this.isAllowAddPlan = true;
            this.updatedSettings.listOfPlansWithoutTemplates.map((item) => {
              item["isAddPlan"] = false;
            });
          }
          this.initialSettings.showPlanToEmployees = res.showPlanToEmployees
        }
      },
      (_) => {
        this.toastr.error("Error while fetching settings", "Failure");
        this.ngxLoader.stop();
      }
    );
  }
  checkValidation() {
    if (this.updatedSettings.reminderEmailsToBeSent) {
      return this.updatedSettings.reminderEmailsStartFrom != null &&
        this.updatedSettings.reminderEmailsInterval != null
        ? false
        : true;
    } else return false;
  }
  saveSettings() {
    // let scroll = document.getElementById("reminder");
    this.emailValidation = this.checkValidation();
    if (this.emailValidation) {
      this.toastr.error("Please Fill Mandatory fields");
      return;
    }
    // let isCompanyLevelTemplateExist = false
    const isCompanyLevelTemplateExist = this.listOfPlansWithoutTemplates.filter(
      (template) => template.planName == "ALL PLANS"
    );
    const planLevelGrantTemplateExists =
      this.updatedSettings.planLevelGrantTemplate.filter(
        (template) => template.planName == "ALL PLANS"
      );
    if (!(isCompanyLevelTemplateExist || planLevelGrantTemplateExists)) {
      this.toastr.error(
        "Error!! Signature requires at least one comany based Grant letter. Upload a Grant letter along with this signature."
      );
      return;
    }
    let settingsChanged = false;
    const settingToSend: Settings = {};
    Object.keys(this.initialSettings).forEach((settingKey) => {
      if (
        this.initialSettings[settingKey] != this.updatedSettings[settingKey]
      ) {
        settingsChanged = true;
        if (settingKey == "planLevelGrantTemplate") {
          settingToSend[settingKey] = JSON.parse(
            JSON.stringify(this.planLevelGrantTemplate)
          );
        } else if (settingKey == "listOfPlansWithoutTemplates") {
          settingToSend[settingKey] = JSON.parse(
            JSON.stringify(this.listOfPlansWithoutTemplates)
          );
        } else {
          settingToSend[settingKey] = this.updatedSettings[settingKey];
        }
      }
    });
    if (!settingsChanged) {
      this.toastr.warning("No Changes done");
      return;
    }
    this.ngxLoader.start();
    this.authService.saveAdminSettings(settingToSend).subscribe(
      (res) => {
        this.ngxLoader.stop();
        this.listOfPlansWithoutTemplates = [];
        this.planLevelGrantTemplate = [];
        this.toastr.success("Changes saved successfully", "Success");
        this.getSettings();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error["resultCode"], "Error");
      }
    );
  }

  downloadPlanLetter(i, objectname, downloadOnly?, planType?) {
    const anchor = document.createElement("a");
    if (
      objectname === "grantTemplate" &&
      planType == "planLevelGrantTemplate"
    ) {
      if (
        !this.updatedSettings.planLevelGrantTemplate[i].planLevelGrantTemplate
      ) {
        this.toastr.error("No Default grant template present", "Error");
        return;
      }
      const grantTemplateBlob = this.utilityService.b64toBlob(
        this.updatedSettings.planLevelGrantTemplate[i].planLevelGrantTemplate,
        "application/msword"
      );
      grantTemplateBlob.then((res) => {
        const blobURL = URL.createObjectURL(res);
        const templateUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          blobURL + "#toolbar=0&zoom=100&embedded=true"
        );
        if (downloadOnly) {
          anchor.href = blobURL;
          anchor.download = "Grant template.docx";
          anchor.click();
        } else {
          const dialogRef = this.dialog.open(ViewDocsDialogComponent, {
            width: "1200px",
            height: "500px",
            disableClose: false,
            data: {
              url: templateUrl,
              header: "Company Level Grant Template",
              type: "docx",
            },
          });
        }
      });
    } else if (
      objectname === "grantTemplate" &&
      planType == "listOfPlansWithoutTemplates"
    ) {
      if (
        !this.updatedSettings.listOfPlansWithoutTemplates[i]
          .planLevelGrantTemplate
      ) {
        this.toastr.error("No Default grant template present", "Error");
        return;
      }
      const grantTemplateBlob = this.utilityService.b64toBlob(
        this.updatedSettings.listOfPlansWithoutTemplates[i]
          .planLevelGrantTemplate,
        "application/msword"
      );
      grantTemplateBlob.then((res) => {
        const blobURL = URL.createObjectURL(res);
        const templateUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          blobURL + "#toolbar=0&zoom=100&embedded=true"
        );
        if (downloadOnly) {
          anchor.href = blobURL;
          anchor.download = "Grant template.docx";
          anchor.click();
        } else {
          const dialogRef = this.dialog.open(ViewDocsDialogComponent, {
            width: "1200px",
            height: "500px",
            disableClose: false,
            data: {
              url: templateUrl,
              header: "Company Level Grant Template",
              type: "docx",
            },
          });
        }
      });
    }
  }

  downloadObject(objectname, downloadOnly?, grantName?) {
    const anchor = document.createElement("a");
    if (objectname == "grantSignature") {
      if (this.grantSignature) {
        const grantSignatureBlob = this.utilityService.b64toBlob(
          this.grantSignature,
          "image/jpeg"
        );
        grantSignatureBlob.then((res) => {
          const blobURL = URL.createObjectURL(res);
          // let objectURL = URL.createObjectURL(blob);
          const imgUrl = this.sanitizer.bypassSecurityTrustUrl(blobURL);
          //this.viewGrantSignature(this.sanitizer.bypassSecurityTrustUrl(blobURL))
          if (downloadOnly) {
            anchor.href = blobURL;
            anchor.download = "Grant Signature.jpeg";
            anchor.click();
          } else {
            //const grantImage = document.getElementById(objectname);
            //grantImage.setAttribute("src", blobURL);
            // grantImage.removeAttribute("hidden");

            const dialogRef = this.dialog.open(ViewDocsDialogComponent, {
              width: "500px",
              height: "450px",
              disableClose: false,
              data: { url: imgUrl, header: "Grant Signature", type: "image" },
            });
          }
        });
      } else {
        this.toastr.error("No Grant signature present");
        return;
      }
    }
  }
  discardSettings() {
    if (this.initialSettings == this.updatedSettings) {
      this.toastr.warning("No changes made to discard");
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: `Are you sure you want to discard settings ?`,
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == "confirm") {
        this.updatedSettings = JSON.parse(JSON.stringify(this.initialSettings));
        this.toastr.success("Changes discarded", "Success");
      }
    });
  }
  deleteItem(itemKey: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: `Are you sure you want to delete ?`,
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == "confirm") {
        this.updatedSettings[itemKey] = "";
        document.getElementById(itemKey)["value"] = "";
      }
    });
  }
  fileChangeEvent(event, uploadedFrom) {
    let file = event.target.files[0];
    const validFileTypes = {
      grantLetter: ["docx"],
      grantSignature: ["jpeg", "png", "jpg"],
    };
    const acceptedExtensions = validFileTypes[uploadedFrom];
    const fileExtenstion = file.name
      .split(".")
      [file.name.split(".").length - 1].toLowerCase();
    if (!acceptedExtensions.includes(fileExtenstion)) {
      this.toastr.error("Invalid format", "Error");
      return;
    }
    if (uploadedFrom == "grantSignature") {
      if (file.size / 1024 > 50) {
        event.target.files = null;
        event.target.value = "";
        this.toastr.error("File size should be less than 50kb");
        return;
      }
      var img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width > 240 || height > 120) {
          this.toastr.error(
            "File height and width should be less than 120px and 240px"
          );
          event.target.files = null;
          event.target.value = "";
          return;
        }
        this.convertFiletoBase64(file, uploadedFrom)
      };
    }
    else{
      this.convertFiletoBase64(file, uploadedFrom)
    }

  }
  convertFiletoBase64(file, uploadedFrom){
    this.utilityService
    .blob2Base64(file)
    .then((res) => {
      const base64Res = res.split(",")[1];
      if (uploadedFrom == "grantLetter") {
        document.getElementById("viewGrantLetter").innerHTML = file.name;
      } else if (uploadedFrom == "grantSignature") {
        this.updatedSettings.grantSignature = base64Res;
        this.grantSignature = this.updatedSettings.grantSignature;
        document.getElementById("viewGrantSignature").innerHTML = file.name;
      }
    })
    .catch((e) => {
      this.toastr.error(e, "Failure");
    });
  }
  uploadGrantLetter(i, event, uploadedFrom, planType) {
    let file = event.target.files[0];
    let planIncluded = false;
    const fileExtenstion = file.name
      .split(".")
      [file.name.split(".").length - 1].toLowerCase();
    this.utilityService
      .blob2Base64(file)
      .then((res) => {
        const base64Res = res.split(",")[1];
        if (
          uploadedFrom == "grantLetter" &&
          planType == "planLevelGrantTemplate"
        ) {
          const acceptedExtensions = ["docx"];
          if (!acceptedExtensions.includes(fileExtenstion)) {
            this.toastr.error("Invalid format", "Error");
            return;
          }
          this.updatedSettings.planLevelGrantTemplate[
            i
          ].planLevelGrantTemplate = base64Res;
          this.planLevelGrantTemplate &&
            this.planLevelGrantTemplate.length &&
            this.planLevelGrantTemplate.map((item, j) => {
              if (
                item.planName ==
                this.updatedSettings.planLevelGrantTemplate[i].planName
              ) {
                this.planLevelGrantTemplate[j].planLevelGrantTemplate =
                  base64Res;
                planIncluded = true;
              }
            });
          if (!planIncluded) {
            this.planLevelGrantTemplate.push(
              this.updatedSettings.planLevelGrantTemplate[i]
            );
          }
          document.getElementById(
            "viewGrantLetter-planLevelGrantTemplate" + i
          ).innerHTML = file.name;
        } else if (
          uploadedFrom == "grantLetter" &&
          planType == "listOfPlansWithoutTemplates"
        ) {
          const acceptedExtensions = ["docx"];
          if (!acceptedExtensions.includes(fileExtenstion)) {
            this.toastr.error("Invalid format", "Error");
            return;
          }
          this.updatedSettings.listOfPlansWithoutTemplates[
            i
          ].planLevelGrantTemplate = base64Res;
          this.listOfPlansWithoutTemplates &&
            this.listOfPlansWithoutTemplates.length &&
            this.listOfPlansWithoutTemplates.map((item, j) => {
              if (
                item.planName ==
                this.updatedSettings.listOfPlansWithoutTemplates[i].planName
              ) {
                this.listOfPlansWithoutTemplates[j].planLevelGrantTemplate =
                  base64Res;
                planIncluded = true;
              }
            });
          if (!planIncluded) {
            this.listOfPlansWithoutTemplates.push(
              this.updatedSettings.listOfPlansWithoutTemplates[i]
            );
          }
          document.getElementById(
            "viewGrantLetter-listOfPlansWithoutTemplates" + i
          ).innerHTML = file.name;
        }
      })
      .catch((e) => {
        this.toastr.error(e, "Failure");
      });
  }

  addPlan() {
    if (!this.updatedSettings.isCustomizedPlanEnabled) {
      this.toastr.error("Cannot override default grant letter", "Error");
      return;
    }
    this.selectedPlan = null;
    for (
      var i = 0;
      i < this.updatedSettings.listOfPlansWithoutTemplates.length;
      i++
    ) {
      if (!this.updatedSettings.listOfPlansWithoutTemplates[i]["isAddPlan"]) {
        if (i > 0) {
          if (
            this.updatedSettings.listOfPlansWithoutTemplates[i - 1]
              .planLevelGrantTemplate === "" ||
            this.updatedSettings.listOfPlansWithoutTemplates[i - 1]
              .planLevelGrantTemplate === null
          ) {
            this.toastr.error("Please fill previous row details", "Error!");
            return;
          }
        }
        this.updatedSettings.listOfPlansWithoutTemplates[i]["isAddPlan"] = true;
        break;
      }
    }
    if (i == this.updatedSettings.listOfPlansWithoutTemplates.length - 1) {
      this.isAllowAddPlan = false;
    }
  }

  changeByplanNames(e, i) {
    this.selectedPlan = e;
    let eleIndex;
    this.updatedSettings.listOfPlansWithoutTemplates.map((item, x) => {
      if (item.planName == this.selectedPlan) {
        eleIndex = x;
      }
    });
    let temp = this.updatedSettings.listOfPlansWithoutTemplates[eleIndex];
    this.updatedSettings.listOfPlansWithoutTemplates[eleIndex] =
      this.updatedSettings.listOfPlansWithoutTemplates[i];
    this.updatedSettings.listOfPlansWithoutTemplates[i] = temp;
    this.updatedSettings.listOfPlansWithoutTemplates[eleIndex]["isAddPlan"] =
      false;
    this.updatedSettings.listOfPlansWithoutTemplates[i]["isAddPlan"] = true;
  }

  deletePlanLetter(index, planType) {
    let planIncluded = false;
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: "Are you sure you want to Delete ?",
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
        if (planType == "planLevelGrantTemplate") {
          this.updatedSettings.planLevelGrantTemplate[
            index
          ].planLevelGrantTemplate = "";
          let removedPlan = this.updatedSettings.planLevelGrantTemplate.splice(
            index,
            1
          );
          removedPlan[0].planLevelGrantTemplate = "";
          this.planLevelGrantTemplate &&
            this.planLevelGrantTemplate.length &&
            this.planLevelGrantTemplate.map((item, j) => {
              if (item.planName == removedPlan[0].planName) {
                this.planLevelGrantTemplate[j].planLevelGrantTemplate = "";
                planIncluded = true;
              }
            });
          if (!planIncluded) {
            this.planLevelGrantTemplate.push(removedPlan[0]);
          }
          // this.updatedSettings.listOfPlansWithoutTemplates.push(removedPlan[0])
          this.isAllowAddPlan = true;
        } else if (planType == "listOfPlansWithoutTemplates") {
          this.updatedSettings.listOfPlansWithoutTemplates[
            index
          ].planLevelGrantTemplate = "";
          let removedPlan =
            this.updatedSettings.listOfPlansWithoutTemplates.splice(index, 1);
          removedPlan[0].planLevelGrantTemplate = "";
          removedPlan[0]["isAddPlan"] = false;
          this.listOfPlansWithoutTemplates &&
            this.listOfPlansWithoutTemplates.length &&
            this.listOfPlansWithoutTemplates.map((item, j) => {
              if (item.planName == removedPlan[0].planName) {
                this.listOfPlansWithoutTemplates[j].planLevelGrantTemplate = "";
                planIncluded = true;
              }
            });
          if (!planIncluded) {
            this.listOfPlansWithoutTemplates.push(removedPlan[0]);
          }
        }
      }
      this.ngxLoader.stop();
    });
  }

  changeByEmailTemplate(e) {
    this.selectedEmail = true;
    this.ngxLoader.start();
    this.authService.viewEmailTemplate(e).subscribe((res) => {
      if (res != null) {
        const emailBody = res["emailBodyFile"];
        if (emailBody) {
          const grantSignatureBlob = atob(emailBody);
          this.emailTempDiv.nativeElement.innerHTML = grantSignatureBlob;
          this.viewEmailTemp = grantSignatureBlob;
        } else {
          this.selectedEmail = false;
          this.viewEmailTemp = "";
          this.emailTempDiv.nativeElement.innerHTML = "Template not available";
        }
      } else {
        this.selectedEmail = false;
        this.emailTempDiv.nativeElement.innerHTML = "Template not found";
        this.toastr.error("File not accessible!", "Error");
      }
      this.ngxLoader.stop();
    });
  }

  editEmailTemp() {
    this.editEmail = true;
    const dialogRef = this.dialog.open(EmailTemplateEditorComponent, {
      width: "1200px",
      height: "600px",
      disableClose: true,
      data: { emailTemplate: this.viewEmailTemp, action: "edit" },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const emailTemplateDto = {
          emailTemplateName: this.emailTemplateType,
          emailBodyFile: btoa(res),
          emailTemplateType: this.emailTemplateType,
        };
        this.viewEmailTemp = res;
        this.emailTempDiv.nativeElement.innerHTML = this.viewEmailTemp;
        this.authService.uploadEmailTemplate(emailTemplateDto).subscribe(
          (res) => {
            this.toastr.success("Email Template saved successfully", "Success");
          },
          (e) => {
            this.toastr.error(e.error["resultCode"], "Error");
          }
        );
      }
    });
  }

  onlyNumber(event: any) {
    if (event.key < 1 && event.target.value < 1) {
      return false;
    }
    return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }
}
