import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/icon";
import * as i2 from "@angular/platform-browser";
var SVGLoader = /** @class */ (function () {
    function SVGLoader(iconRegistry, sanitizer) {
        this.iconRegistry = iconRegistry;
        this.sanitizer = sanitizer;
        this.svgs = [
            { name: "action_upload", url: "../../assets/upload.svg" },
            { name: "add_new_grey", url: "../../assets/action/addnew_grey.svg" },
            { name: "add_new", url: "../../assets/action/addnew.svg" },
            { name: "secondary_new", url: "../../assets/new_logo/secondary-new.svg" },
            { name: "secondary_sel", url: "../../assets/new_logo/secondary-sel.svg" },
            { name: "buyback_sel", url: "../../assets/new_logo/buyback-sel.svg" },
            { name: "buyback", url: "../../assets/new_logo/buyback.svg" },
            {
                name: "esop_overview_sel",
                url: "../../assets/new_logo/esop-overview-sel.svg",
            },
            { name: "esop_overview", url: "../../assets/new_logo/esop-overview.svg" },
            { name: "action_download", url: "../../assets/download.svg" },
            { name: "captable", url: "../../assets/captableIcon.svg" },
            { name: "action_share", url: "../../assets/share.svg" },
        ];
    }
    SVGLoader.prototype.loadSvgs = function () {
        var iconRegistry = this.iconRegistry;
        var sanitizer = this.sanitizer;
        this.svgs.forEach(function (svg) {
            iconRegistry.addSvgIcon(svg.name, sanitizer.bypassSecurityTrustResourceUrl(svg.url));
        });
    };
    SVGLoader.ngInjectableDef = i0.defineInjectable({ factory: function SVGLoader_Factory() { return new SVGLoader(i0.inject(i1.MatIconRegistry), i0.inject(i2.DomSanitizer)); }, token: SVGLoader, providedIn: "root" });
    return SVGLoader;
}());
export { SVGLoader };
