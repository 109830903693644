import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public emailId:string;
  public currentPassword:string;
  public newPassword:string;
  currHide: boolean = true;
  newHide: boolean = true;
  public barLabel: string = "Password strength:";
  public companyName = "";
  public companyId;
  public userType;
  public email;
  public companyList:any;
  passwordStrength = false

  constructor(
    private authService:AuthenticationService, 
    private router:Router,
    private toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    private readonly utilityService: UtilityService
  ) { }

  ngOnInit() {
    if(this.activatedRoute.snapshot.paramMap.get('type')) {
      console.log("user type", this.activatedRoute.snapshot.paramMap.get('type'));
      this.userType = this.activatedRoute.snapshot.paramMap.get('type');
    }
    if(this.activatedRoute.snapshot.paramMap.get('email')) {
      console.log("user type", this.activatedRoute.snapshot.paramMap.get('email'));
      let mail = this.activatedRoute.snapshot.paramMap.get('email');
      this.email = decodeURIComponent(mail);
      this.getCompanies( this.email);
    }
  }

  getCompanies(id){
    let user = {
      "emailId":id
    }
    this.authService.getEmployeeCompanies(user).subscribe(
      (res) => {
        this.companyList = res;
      },
      (e) => { 
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  currpasswordHide() {
    this.currHide = !this.currHide;
  }

  newpasswordHide() {
    this.newHide = !this.newHide;
  }
  checkPasswordStrength(){
    this.passwordStrength = this.utilityService.checkStrength(this.newPassword) > 30
  }
  resetPassword() {
    let userDetails = {
        "emailId" : this.emailId,
        "oldPassword" : this.utilityService.encryptPassword(this.currentPassword),
        "newPassword" : this.utilityService.encryptPassword(this.newPassword)
    };
    if(this.userType == "EMPLOYEE"){
      this.authService.employeeResetPassword(this.userType, this.companyId, userDetails).subscribe(
        (res) => {
          this.toastr.success("Your password has been reset successfully!", "Success!");
          this.router.navigateByUrl('/employeeLogin');
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
      );
    }
    else{
      this.authService.resetPassword(userDetails).subscribe(
        (res) => {
          this.toastr.success("Your password has been reset successfully!", "Success!");
          this.router.navigateByUrl('');
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
      );
    }
  }

  selectCompany(e){ 
    this.companyId = e.id;
  }

}
