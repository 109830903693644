import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { DataService } from '../../services/new-round-notify.service';
import { UtilityService } from "../../services/utilities.service";
import { element } from "@angular/core/src/render3";
import { HelperService } from '../../services/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from '../../services/cache-provider.service';

@Component({
  selector: "app-cap-table-tab",
  templateUrl: "./cap-table-tab.component.html",
  styleUrls: ["./cap-table-tab.component.scss"]
})

export class CapTableTabComponent implements OnInit {
  tranchesCnt = 1;
  showCapTable = false;
  tranches = [];
  hdrLvl1 = [];
  hdrLvl2 = [];
  noOfTranches;
  isRoundNotSaved: boolean = false;
  public isLocked = false;
  public unLocked = "../../../assets/common/unlocked.svg";
  public locked = "../../../assets/common/locked.svg";
  public capTableTranches;
  public roundSize;
  public preMoneyValue;
  hdrTranches = { "tranches": "Tranches " };
  subHdrTranches = {
    "holding": "Holding %",
    "amount": "Amount To be Invested",
    "Shares": "No.of Shares"
  }
  public tmpTranches;
  public errorFlags;
  exchangeRate = 1;
  companyCurrency;
  roundCurrency;
  roundCurrencySymbol;
  newCnt;
  newTranches;
  convertible;
  public isTranchesCompleted: boolean = false;
  public disableripple: boolean = true;
  newRoundInfo;
  isPricedRound;
  isdisabled = false;
  public helpTitle: any;
  public helpMessage: any;
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  public isEmpty: boolean = false;
  public tranchCount:boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private dataService: DataService,
    private utilityService: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) { }

  tranchesCounting(event) {
    if (event > 0 && event <= 15) {
      this.tranchCount = false;
      let tmpPercent = 0;
      this.tranches.forEach(element => {
        tmpPercent = tmpPercent + element['tranchePercentage'];
      });
      if (tmpPercent >= 100) {
        this.toastr.error("Tranches percentage is 100%. Can't add more tranches!", "Error");
        return;
      }
      if (this.tranches.length < this.tranchesCnt) {
        this.newCnt = this.tranchesCnt - this.tranches.length;
        for (let i = 0; i < this.newCnt; i++) {
          let trnch = {
            sequenceNumber: "",
            tranchePercentage: "",
            investmentAmount: "",
            valuation: ""
          };
          this.tranches.push(trnch);
        }
      } else if (this.tranches.length > this.tranchesCnt) {
        this.newTranches = this.tranches.length - this.tranchesCnt;
        this.tranches.splice(-this.newTranches, this.newTranches);
      }
    }
    else {
      this.tranchCount = true;
      this.toastr.error("Presently restricted to up to 15 tranches!", "Error");
    }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  goToSummary() {
    this.isRoundNotSaved = false;
    if(this.tranchesCnt === this.tranches.length){
      this.utilityService.formValidation();
      var isEmp = this.tranchesValidation();
      if (!isEmp) {
        this.tranches.forEach((element, index) => {
          if (typeof element.investmentAmount === "string" && typeof element.valuation === "string") {
            this.tranches[index]['investmentAmount'] = element.investmentAmount.replace(/,/g, "") / 1;
            this.tranches[index]['valuation'] = element.valuation.replace(/,/g, "") / 1;
          }
          else{
            this.tranches[index]['investmentAmount'] = element.investmentAmount;
            this.tranches[index]['valuation'] = element.valuation;
          }
        });

        let tranchesPost = { "numberOfTranches": this.tranchesCnt, "tranches": this.tranches };
        if (this.isPricedRound == true) {
          if (this.tranchesCnt > 0) {
            this.authService.saveTranchesInfo(tranchesPost).subscribe((response) => {
              if (response) {
                this.removeDataFromCache()
                this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "summary"]);
              } else {
                this.toastr.error("Error: Null response!", "Error");
              }
            },
            e => {
              this.toastr.error(e.error.reason, "Error");
            });
          }
          else {
            let trnch = [];
            let tranchesPost1 = { "numberOfTranches": this.tranchesCnt, "tranches": trnch };
            this.authService.saveTranchesInfo(tranchesPost1).subscribe((response) => {
              if (response) {
                this.removeDataFromCache()
                this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "summary"]);
              } else {
                this.toastr.error("Error: Null response!", "Error");
              }
            },
            e => {
              this.toastr.error(e.error.reason, "Error");
            });
          }
        }
        else {
          this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "summary"]);
        }
      }
    }
    else{
      this.toastr.error("Atleast 1 tranche should exist!", "Error");
    }
  }

  skipTranches() {
    this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "summary"]);
  }

  refreshCapTable() {
    if(this.tranchesCnt === this.tranches.length){
    this.utilityService.formValidation();
    var isEmp = this.tranchesValidation();
      if (!isEmp) {
        this.tranches.forEach((element, index) => {
          if (typeof element.investmentAmount === "string" && typeof element.valuation === "string") {
            this.tranches[index]['investmentAmount'] = element.investmentAmount.replace(/,/g, "") / 1;
            this.tranches[index]['valuation'] = element.valuation.replace(/,/g, "") / 1;
          }
          else{
            this.tranches[index]['investmentAmount'] = element.investmentAmount;
            this.tranches[index]['valuation'] = element.valuation;
          }
        });

        let tranchesPost = { "numberOfTranches": this.tranchesCnt, "tranches": this.tranches }
        this.authService.getCapTableByTranches(tranchesPost).subscribe((response) => {
          if (response) {
            this.showCapTable = true;
            this.capTableTranches = response;
            this.tmpTranches = response['investorTranches'];
            this.noOfTranches = response['numberOfTranches'];
            this.formatHdr();
            let tran = response['tranches'];
            tran.forEach(element => {
              let pmv = element['valuation'];
              let inamt = element['investmentAmount'];
              element['valuation'] = this.utilityService.formatCurrency(this.roundCurrency, pmv);
              element['investmentAmount'] = this.utilityService.formatCurrency(this.roundCurrency, inamt);
            });
            this.tranches = tran;
          } else {
            this.toastr.error("Error: Null Response!", "Error");
          }
        },
        e => {
          this.toastr.error(e.error.reason, "Error");
        });
      }
    }
    else{
      this.toastr.error("Atleast 1 tranche should exist!", "Error");
    }
  }

 tranchesValidation(){
  this.isEmpty = false;
  let duptranches = this.tranches;
  for (let i = 0; i < duptranches.length; i++) {
    duptranches[i].sequenceNumber = i + 1;
  }

  duptranches.forEach(element => {
    if(element.tranchePercentage === 0 || element.tranchePercentage === ""){
      this.toastr.error("Error: Tranch Percentage  can't be empty OR zero", "Error");
      this.isEmpty = true;
      return;
    }
    else if(element.investmentAmount === 0 || element.investmentAmount === ""){
      this.toastr.error("Error: Investment Amount can't be empty OR zero", "Error");
      this.isEmpty = true;
      return;
    }
    else if (element.valuation === "" || element.valuation === 0) {
      this.toastr.error("Error: PreMoney Valuation can't be empty OR zero", "Error");
      this.isEmpty = true;
      return ;
    }
  });
  return this.isEmpty;
 }

  formatHdr() {
    this.hdrLvl1 = [];
    this.hdrLvl2 = [];
    for (let i = 0; i < this.tranches.length; i++) {
      this.hdrLvl1.push(this.hdrTranches);
      this.hdrLvl2.push(this.subHdrTranches);
    }
  }

  valuationCSV(e) {
    let rst;
    if(e) {
        // store current positions in variables
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let val = e.target.value
    rst = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, val);

    if(e) {
        if(e.target.value) {
            let CSVLength = rst.length;
            let valueLength = e.target.value.length;
            if(CSVLength > valueLength) {
                e.target.value = rst;
                let difference = CSVLength - valueLength;
                start = start + difference;
                end = end + difference;
            }
            else if(CSVLength < valueLength){
                e.target.value = rst;
                if(start != 0 || end != 0){
                    let difference = valueLength - CSVLength;
                    start = start - difference;
                    end = end - difference;
                }
                else{
                    start = start;
                    end = end;
                }  
            }
            else {
                e.target.value = rst;
            }
        }
    
        // restore from variables...
        e.target.setSelectionRange(start, end);
    }
  }

  calInvAmount(index, event) {
    let percents = + parseFloat(event).toFixed(2);
    let tmpPercent: number = 0;
    let temp = percents / 100;
    this.tranches[index]['tranchePercentage'] = temp;
    this.tranches.forEach(element => {
      if (element.tranchePercentage != "") {
        tmpPercent += element.tranchePercentage;
      }
    });

    let amount = (percents / 100) * this.roundSize;
    if (tmpPercent <= 1) {
      if (this.roundCurrency === this.companyCurrency) {
        this.tranches[index]['investmentAmount'] = this.utilityService.convertToCompanyCurrency(amount);
      }
      else {
        this.tranches[index]['investmentAmount'] = this.utilityService.convertToRoundCurrency(amount);
      }
    }
    else {
      this.tranches[index]['investmentAmount'] = 0;
      this.tranches[index]['tranchePercentage'] = 0;
      this.toastr.error("Sum of all Tranches percentage should be Less than (OR) Equal to 100%", "Error");
      return;
    }
  }

  calTranchPer(index, amount) {
    console.log('amount entered: ', amount);
    if (amount.indexOf(",") > -1) {
        amount = amount.replace(/,/g, "") / 1;
    } else {
        amount = amount / 1;
    }
    let percent = amount/this.roundSize;
    this.tranches[index]['tranchePercentage'] = percent;
    let tmpPercent: number = 0;
    this.tranches.forEach(element => {
        if (element.tranchePercentage != "") {
          tmpPercent += element.tranchePercentage;
        }
    });
    if (tmpPercent > 1) {
        this.tranches[index]['investmentAmount'] = 0;
        this.tranches[index]['tranchePercentage'] = 0;
        this.toastr.error("Sum of all Tranches percentage should be Less than (OR) Equal to 100%", "Error");
    }
  }

  showCurrency(amount) {
    return this.utilityService.calculateCurrency(this.roundCurrency, this.companyCurrency, this.exchangeRate, amount)
  }

  convertCurrencyForm(amount) {
    return this.utilityService.convertCurrencyFormat(amount);
  }

  formatCurrency(value) {
    return this.utilityService.formatCurrencyDeci(this.companyCurrency, value);
  }

  fieldEdited($event) {
    this.isRoundNotSaved = true;
}

  getAllTranches() {
    this.ngxLoader.start();
    this.authService.getAllTranches().subscribe(
      response => {
        if (response) {
          this.isTranchesCompleted = response['isTracheCompleted'];
          this.tranches = response['tranches'];
          this.tranchesCnt = response['numberOfTranches'];
          this.isPricedRound = response['isPricedRound'];
          if (this.isPricedRound == false) {
            this.isdisabled = true;
          }
          if (response['investorTranches'] != null && response['investorTranches'].length > 0) {
            this.tmpTranches = response['investorTranches'];
            this.noOfTranches = response['numberOfTranches'];
            this.showCapTable = true;
            this.formatHdr();
          }

          //For Basic Details
          let basicData = {
            isBasicDetailsFilled: true,
            roundName: response['roundName'],
            roundSize: response['roundSize'],
            roundValuation: response['roundValuation'],
            amountRaised: response['amountRaised'],
            amountLeftToRaise: response['amountLeftToRaise'],
            leadInvestorName: response['leadInvestorName'],
            sharePrice: response['pricePerShare'],
            roundCurrency: response['currencyForRound'],
            isRoundNotSaved: this.isRoundNotSaved
          }
          this.dataService.updateBasicData(basicData);

          this.roundSize = response['roundSize'];
          this.preMoneyValue = response['valuation'];
          this.companyCurrency = response['companyCurrency'];
          this.roundCurrency = response['currencyForRound'];
          this.exchangeRate = response['exchangeRate'];
          this.errorFlags = response['errorFlags'];
          this.dataService.updateData(this.errorFlags);
          this.roundCurrencySymbol = this.roundCurrency.split("-")[1].trim();
          if (this.tranches.length == 0) {
            var initTp;
            let index = 0;
            let trnch = {
              sequenceNumber: "",
              tranchePercentage: 100,
              investmentAmount: "",
              valuation: response['valuation']
            };
            this.tranchesCnt = 1;
            this.tranches.push(trnch);
            this.tranches.forEach(element => {
              let pmv = element['valuation'];
              element['valuation'] = this.utilityService.formatCurrency(this.roundCurrency, pmv);
              initTp = element['tranchePercentage'];
            });
            this.calInvAmount(index,initTp);
          }
          else if (this.tranches.length > 0) {
            this.tranches.forEach(element => {
              let pmv = element['valuation'];
              let inamt = element['investmentAmount'];
              element['valuation'] = this.utilityService.formatCurrency(this.roundCurrency, pmv);
              element['investmentAmount'] = this.utilityService.formatCurrency(this.roundCurrency, inamt);
              this.tranches['investmentAmount'] = element['investmentAmount'];
              this.tranches['tranchePercentage'] = element['tranchePercentage'];
              this.tranches['valuation'] = element['valuation'];
            });
          }
          this.ngxLoader.stop();
          this.getDataFromCache()
        } else {
          this.getDataFromCache()
          this.toastr.error("Error: Null response", "Error");
        }
      },
      e => {
        this.getDataFromCache()
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  lockValue() {
    this.isLocked = !this.isLocked;
    if (this.locked) {
      this.tranches.forEach(element => {
        let pmv = this.preMoneyValue;
        element['valuation'] = this.utilityService.formatCurrency(this.roundCurrency, pmv);
      });
    }
  }

  ngOnInit() {
    this.getAllTranches();
    this.newRoundInfo = JSON.parse(this.cacheService.get('newRoundInfo'));
    if (this.newRoundInfo) {
      if (this.newRoundInfo['isPricedRound'] == false) {
        this.isdisabled = true;
      }
    }
  }

  getDataFromCache() {
    let tranchesDetailData = this.cacheService.get('tranchesDetail')
    if (tranchesDetailData) {
      this.tranches = JSON.parse(tranchesDetailData)
      this.tranchesCnt = this.tranches.length
    }
  }

  removeDataFromCache() {
    let tranchesDetailData = this.cacheService.get('tranchesDetail')
    if (tranchesDetailData) {
      this.cacheService.remove('tranchesDetail')
    }
  }

  goToInstrument() {
    if (this.tranches && this.tranches.length) {
      this.cacheService.set('tranchesDetail', JSON.stringify(this.tranches))
    }

    this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "instrument"]);
  }

  // help section show
  showHelp(section) {
    if(section == this.helper){
        return;
    }
    const targetElement = document.getElementById(section);
    if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
    }
    
    this.helpSections = this.helperService.getHelpContent(
        "CapTableTabComponent",
        section
    );
    this.helper = section;
  }

  // help section hide
  hideHelp() {
    this.helper = "";
  }

  getTotalValueTble(value) {
    return this.utilityService.formatCurrency(this.roundCurrency, value);
  }

  // input field olny number and comma
  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
}

}


