import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../src/app/authentication.service";
import { Router } from "@angular/router";
import { HelperService } from "../services/helper.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CompanyDetailsModel } from "../onboarding/company-details/company-details.model";
import { FounderDetailsModel } from "../onboarding/company-details/founder-details.model";
import { DirectorDetailsModel } from "../onboarding/company-details/director-details.model";
import { UtilityService } from "../../app/services/utilities.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { ConfirmDeleteComponent } from "../confirm-delete/confirm-delete.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-company-profile",
  templateUrl: "./company-profile.component.html",
  styleUrls: ["./company-profile.component.scss"],
})
export class CompanyProfileComponent implements OnInit {
  public shouldShowDialog: boolean;
  public model: CompanyDetailsModel = new CompanyDetailsModel();

  public helper: string = "";
  public helpSections;
  public helperTopPosition = null;

  public currentEditableFounderRow = null;
  public currentEditableDirectorRow = 0;

  public areAllDirectorsSelected = false;
  public areAllFoundersSelected = false;

  public companyNameValid: boolean = false;
  public cinNoValid: boolean = false;
  public companyTypeValid: boolean = false;
  companyCurrencyValid: boolean = false;
  public incorporateDtValid: boolean = false;
  public officeAddressValid: boolean = false;
  public emailValid: boolean = false;
  public businessInvalid: boolean = false;
  public industryInvalid: boolean = false;
  public nicValid: boolean = false;
  public companyWebsitevalid: boolean = false;

  public isValid: boolean = false;
  public industries: any;
  public businessList: any;
  public typeofCompanies: any = [
    "Private Limited Company",
    "Public Limited Company",
    "Unlimited Company",
    "Partnership",
    "Limited Liability Partnership",
  ];
  public countryList;
  public selectedNationality = "India";
  public fName: boolean = false;
  public fEmail: boolean = false;
  public fNationality: boolean = false;

  public dName: boolean = false;
  public dDin: boolean = false;
  public dType: boolean = false;
  public dEmail: boolean = false;
  public dNationality: boolean = false;

  public shouldLogoShowDialog = false;
  public profilePicture = null;
  public croppedImage = null;
  public fileName: any;
  public companyLogoInvalid: boolean = false;
  public isNewbusiness: boolean = false;
  currencyList: any;

  public showDialogauthorized: Boolean = false;
  public showDialogsplitstock: Boolean = false;
  public roundName = "";
  public helpTitle: any;
  public helpMessage: any;
  public isShowHelp: boolean = false;
  public disableripple: boolean = true;
  public equityAuthShareCapital: any;
  public preferenceAuthShareCapital: any;
  public equityPaidupShareCapital: any;
  public preferencePaidupShareCapital: any;
  public usrCompanyCurrency: any;

  public isauthCapital: boolean = false;
  public issplitStock: boolean = false;

  public equityAuth: boolean = false;
  public prefAuth: boolean = false;
  public equityPaidup: boolean = false;
  public prefPaidup: boolean = false;

  public selectedBusiness = [];
  public selectedIndustries = [];

  private oldName: string = "";

  isEsopOnlyCompany = false;
  currencySymbol: "";
  constructor(
    private helperService: HelperService,
    private router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private ngxLoader: NgxUiLoaderService,
    private utility: UtilityService,
    private datePipe: DatePipe,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.shouldShowDialog = false;
    this.currencyList = this.utility.currencyList();
    this.initializeDetails();
    this.fetchCompanyDetails();
    const { getCode, getNames } = require("country-list");
    this.countryList = getNames();
    this.isNewbusiness = false;
    this.loadJsStyles();
  }

  getCapital() {
    this.ngxLoader.start();
    this.authService.getCapital().subscribe(
      (res) => {
        if (res) {
          this.usrCompanyCurrency = res["company"]["companyCurrency"];
          this.equityAuthShareCapital = this.utility.formatCurrency(
            this.usrCompanyCurrency,
            res["equityAuthorizedShareCapital"]
          );
          this.preferenceAuthShareCapital = this.utility.formatCurrency(
            this.usrCompanyCurrency,
            res["preferenceAuthorizedShareCapital"]
          );
          this.equityPaidupShareCapital = this.utility.formatCurrency(
            this.usrCompanyCurrency,
            res["equityPaidUpShareCapital"]
          );
          this.preferencePaidupShareCapital = this.utility.formatCurrency(
            this.usrCompanyCurrency,
            res["preferencePaidUpShareCapital"]
          );
          this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim()
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }

  /** Show Contextual help **/
  show(section) {
    if (section == this.helper) {
      return;
    }
    const targetElement = document.getElementById(section);
    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "ShareCapitalComponent",
      section
    );
    this.helper = section;
  }

  /** Show Contextual help **/

  onKeydownEvent(e) {
    this.utility.restrictMinus(e);
  }

  loadJsStyles() {
    $("input.number").keyup(function (event) {
      // skip for arrow keys
      if (event.which >= 37 && event.which <= 40) return;
      // format number
      $(this).val(function (index, value) {
        let tmpValue = value.toString().replace(/\D/g, "");
        var lastThree = tmpValue.substring(tmpValue.length - 3);
        var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res;
      });
    });
  }

  validation(data) {
    this.utility.formValidation();
    if (
      data.equityAuthorizedShareCapital < 0 ||
      data.equityAuthorizedShareCapital == null ||
      data.equityAuthorizedShareCapital === "" ||
      data.preferenceAuthorizedShareCapital < 0 ||
      data.preferenceAuthorizedShareCapital == null ||
      data.preferenceAuthorizedShareCapital === "" ||
      data.equityPaidUpShareCapital < 0 ||
      data.equityPaidUpShareCapital == null ||
      data.equityPaidUpShareCapital === "" ||
      data.preferencePaidUpShareCapital < 0 ||
      data.preferencePaidUpShareCapital == null ||
      data.preferencePaidUpShareCapital === ""
    ) {
      if (
        data.equityAuthorizedShareCapital < 0 ||
        data.equityAuthorizedShareCapital == null ||
        data.equityAuthorizedShareCapital === ""
      ) {
        this.equityAuth = true;
      }
      if (
        data.preferenceAuthorizedShareCapital < 0 ||
        data.preferenceAuthorizedShareCapital == null ||
        data.preferenceAuthorizedShareCapital === ""
      ) {
        this.prefAuth = true;
      }
      if (
        data.equityPaidUpShareCapital < 0 ||
        data.equityPaidUpShareCapital == null ||
        data.equityPaidUpShareCapital === ""
      ) {
        this.equityPaidup = true;
      }
      if (
        data.preferencePaidUpShareCapital < 0 ||
        data.preferencePaidUpShareCapital == null ||
        data.preferencePaidUpShareCapital === ""
      ) {
        this.prefPaidup = true;
      }
      return false;
    } else {
      return true;
    }
  }

  save() {
    let dt = new Date();
    const date = dt.toISOString().replace("Z", "+0000");
    let capital = {
      equityAuthorizedShareCapital: this.equityAuthShareCapital,
      preferenceAuthorizedShareCapital: this.preferenceAuthShareCapital,
      equityPaidUpShareCapital: this.equityPaidupShareCapital,
      preferencePaidUpShareCapital: this.preferencePaidupShareCapital,
      updatedDate: date,
    };
    let isValid = this.validation(capital);
    if (isValid) {
      this.ngxLoader.start();
      if (capital.equityAuthorizedShareCapital != 0) {
        capital.equityAuthorizedShareCapital =
          capital.equityAuthorizedShareCapital.replace(/,/g, "") / 1;
      }
      if (capital.preferenceAuthorizedShareCapital != 0) {
        capital.preferenceAuthorizedShareCapital =
          capital.preferenceAuthorizedShareCapital.replace(/,/g, "") / 1;
      }
      if (capital.equityPaidUpShareCapital != 0) {
        capital.equityPaidUpShareCapital =
          capital.equityPaidUpShareCapital.replace(/,/g, "") / 1;
      }
      if (capital.preferencePaidUpShareCapital != 0) {
        capital.preferencePaidUpShareCapital =
          capital.preferencePaidUpShareCapital.replace(/,/g, "") / 1;
      }
      this.authService.updateCapital(capital).subscribe(
        (res) => {
          if (res) {
            this.toastr.success("Capital saved successfully", "Success");
            //this.router.navigate(['/landing', 'dashboard','capTable']);
            this.ngxLoader.stop();
          } else {
            this.ngxLoader.stop();
            this.toastr.error("Null response!.", "Failure!");
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
      );
    }
  }

  csvEquityVal(e) {
    let exitVal;
    if (e) {
      // store current positions in variables
      var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let val = this.equityAuthShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if (e) {
      if (e.target.value) {
        let CSVLength = exitVal.length;
        let valueLength = e.target.value.length;
        if (CSVLength > valueLength) {
          e.target.value = exitVal;
          let difference = CSVLength - valueLength;
          start = start + difference;
          end = end + difference;
        } else if (CSVLength < valueLength) {
          e.target.value = exitVal;
          if (start != 0 || end != 0) {
            let difference = valueLength - CSVLength;
            start = start - difference;
            end = end - difference;
          } else {
            start = start;
            end = end;
          }
        } else {
          e.target.value = exitVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  csvPreferenceVal(e) {
    let exitVal;
    if (e) {
      // store current positions in variables
      var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let val = this.preferenceAuthShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if (e) {
      if (e.target.value) {
        let CSVLength = exitVal.length;
        let valueLength = e.target.value.length;
        if (CSVLength > valueLength) {
          e.target.value = exitVal;
          let difference = CSVLength - valueLength;
          start = start + difference;
          end = end + difference;
        } else if (CSVLength < valueLength) {
          e.target.value = exitVal;
          if (start != 0 || end != 0) {
            let difference = valueLength - CSVLength;
            start = start - difference;
            end = end - difference;
          } else {
            start = start;
            end = end;
          }
        } else {
          e.target.value = exitVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  csvPaidUpEquityVal(e) {
    let exitVal;
    if (e) {
      // store current positions in variables
      var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let val = this.equityPaidupShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if (e) {
      if (e.target.value) {
        let CSVLength = exitVal.length;
        let valueLength = e.target.value.length;
        if (CSVLength > valueLength) {
          e.target.value = exitVal;
          let difference = CSVLength - valueLength;
          start = start + difference;
          end = end + difference;
        } else if (CSVLength < valueLength) {
          e.target.value = exitVal;
          if (start != 0 || end != 0) {
            let difference = valueLength - CSVLength;
            start = start - difference;
            end = end - difference;
          } else {
            start = start;
            end = end;
          }
        } else {
          e.target.value = exitVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  csvPaidUpPrefVal(e) {
    let exitVal;
    if (e) {
      // store current positions in variables
      var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let val = this.preferencePaidupShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if (e) {
      if (e.target.value) {
        let CSVLength = exitVal.length;
        let valueLength = e.target.value.length;
        if (CSVLength > valueLength) {
          e.target.value = exitVal;
          let difference = CSVLength - valueLength;
          start = start + difference;
          end = end + difference;
        } else if (CSVLength < valueLength) {
          e.target.value = exitVal;
          if (start != 0 || end != 0) {
            let difference = valueLength - CSVLength;
            start = start - difference;
            end = end - difference;
          } else {
            start = start;
            end = end;
          }
        } else {
          e.target.value = exitVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  cancel() {
    this.router.navigate(["/landing", "dashboard", "capTable"]);
  }

  splitStock() {
    this.issplitStock = true;
    this.showDialogsplitstock = true;
    this.utility.showOverlay();
  }

  getEvents(e) {
    this.showDialogsplitstock = e;
    console.log(this.showDialogsplitstock);
    this.utility.hideOverlay();
  }

  getEventAutorized(e) {
    this.showDialogauthorized = e;
    console.log(this.showDialogauthorized);
    this.utility.hideOverlay();
  }

  authCapital() {
    this.isauthCapital = true;
    this.showDialogauthorized = true;
    this.utility.showOverlay();
  }

  initializeDetails() {
    this.model.nameOfTheCompany = localStorage.getItem("companyName");
    this.model.cinNumber = "";
    this.model.isInForEquitySecurity = "";
    this.model.isInForPreferenceSecurity =  "";
    this.model.typeOfTheCompany = "";
    this.model.dateOfIncorporation = "";
    this.model.corporateOfficeAddress = "";
    this.model.companyEmailId = "";
    this.model.business = [];
    this.model.industry = [];
    this.model.nic = "";
    this.model.dippCertificateNumber = "";
    this.model.doingBusinessAs = "";
    this.model.founders = [];
    this.model.directors = [];
    this.model.companyLogo = "";
    this.model.companyLogoPath = "";
    this.model.companyWebsite = "";
    this.model.subscribersOfMemorandum = "";
  }

  fetchCompanyDetails() {
    this.ngxLoader.start();
    this.authService.getCompanyDetails(false).subscribe(
      (res: any) => {
        if (res) {
          this.isEsopOnlyCompany = JSON.parse(
            sessionStorage.getItem("userDetails")
          ).company["isEsopOnlyCompany"];
          if (!this.isEsopOnlyCompany) {
            this.getCapital();
          }
          this.model = res;
          this.selectedBusiness = this.model["business"];
          this.selectedIndustries = this.model["industry"];
          this.businessList = this.model["categoryDropdownValues"];
          this.industries = this.model["industryDropdownValues"];
          if (this.model["companyLogoPath"] == null) {
            this.model["companyLogoPath"] = "";
          }
          if (this.model["subscribersOfMemorandum"] == null) {
            this.model["subscribersOfMemorandum"] = "";
          }
          if (this.model["companyWebsite"] == null) {
            this.model["companyWebsite"] = "";
          }

          this.model.directors.forEach((element) => {
            if (element["fatherName"] == null) {
              element["fatherName"] = "";
            }
            if (element["motherName"] == null) {
              element["motherName"] = "";
            }
            if (element["spouseName"] == null) {
              element["spouseName"] = "";
            }
            if (element["nominationBy"] == null) {
              element["nominationBy"] = "";
            }
            if (element["gender"] == null) {
              element["gender"] = "";
            }
            if (element["occupation"] == null) {
              element["occupation"] = "";
            }
            if (element["countryOfResidence"] == null) {
              element["countryOfResidence"] = "";
            }
          });

          console.log("Updated Model is", this.model);
          if (
            this.model.dateOfIncorporation != null ||
            this.model.dateOfIncorporation != ""
          ) {
            this.model.dateOfIncorporation = this.datepipe.transform(
              this.model.dateOfIncorporation,
              "yyyy-MM-dd"
            );
          }
          this.model.directors.forEach((a) => {
            a.dateOfBirth = this.datepipe.transform(
              a.dateOfBirth,
              "yyyy-MM-dd"
            );
            a.dateOfAppointement = this.datepipe.transform(
              a.dateOfAppointement,
              "yyyy-MM-dd"
            );
            a.dateOfBoardResoluitonForAppointement = this.datepipe.transform(
              a.dateOfBoardResoluitonForAppointement,
              "yyyy-MM-dd"
            );
            a.isSelected = false;
          });
          this.model.founders.forEach((a) => {
            a.dateOfBirth = this.datepipe.transform(
              a.dateOfBirth,
              "yyyy-MM-dd"
            );
          });
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      },
      (e) => {
        this.initializeDetails();
        if (e.error.reason === "Login Token Expired.") {
          this.ngxLoader.stop();
          localStorage.clear();
          this.router.navigate([""]);
          return;
        } else {
          this.isEsopOnlyCompany = JSON.parse(
            sessionStorage.getItem("userDetails")
          ).company["isEsopOnlyCompany"];
          if (!this.isEsopOnlyCompany) {
            this.getCapital();
          }
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      }
    );
  }

  // Founders
  addFounder() {
    let length = this.model.founders.length;
    if (this.currentEditableFounderRow == null) {
      this.currentEditableFounderRow = 0;
    }
    if (length > 0) {
      if (
        this.model.founders[length - 1].fullName === "" ||
        this.model.founders[length - 1].emailId === "" ||
        this.model.founders[length - 1].nationality === ""
      ) {
        this.toastr.error("Please fill previous row details", "Error!");
        return;
      } else if (
        this.model.founders[length - 1].designation === "" ||
        this.model.founders[length - 1].dateOfBirth === ""
      ) {
        this.toastr.warning(
          "Please fill previous founders profile details",
          "Warning!"
        );
      }
    }
    let newFounder = new FounderDetailsModel();
    newFounder.id = 0;
    newFounder.fullName = "";
    newFounder.emailId = "";
    newFounder.designation = "";
    newFounder.contactNumber = "";
    newFounder.address = "";
    newFounder.dateOfBirth = "";
    newFounder.nationality = this.selectedNationality;
    newFounder.linkedInProfile = "";
    newFounder.twitterHandle = "";
    newFounder.isSelected = false;
    newFounder.shouldShowDialog = false;
    newFounder.isEdit = true;
    this.model.founders.push(newFounder);
    this.updateFounderSelection();
    this.currentEditableFounderRow = this.model.founders.length - 1;
    this.editFounderRow(this.currentEditableFounderRow);
  }

  deleteIndividualFounder(index) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: "Are you sure you want to Delete ?",
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
        this.model.founders.splice(index, 1);
        this.updateFounderSelection();
        this.fName = false;
        this.fEmail = false;
        this.fNationality = false;
      }
      this.ngxLoader.stop();
    });
  }

  editFounderRow(rowIndex) {
    this.disableFoundersInputs();
    this.model.founders[rowIndex].isEdit = true;
  }

  saveFounderRow(rowIndex) {
    if (
      this.model.founders[rowIndex].fullName === "" ||
      this.model.founders[rowIndex].fullName == null ||
      this.model.founders[rowIndex].emailId === "" ||
      !this.validateEmail(this.model.founders[rowIndex].emailId) ||
      this.model.founders[rowIndex].emailId == null ||
      this.model.founders[rowIndex].nationality === "" ||
      this.model.founders[rowIndex].nationality == null
    ) {
      if (
        this.model.founders[rowIndex].fullName === "" ||
        this.model.founders[rowIndex].fullName == null
      ) {
        this.fName = true;
      }
      if (
        this.model.founders[rowIndex].emailId === "" ||
        this.model.founders[rowIndex].emailId == null ||
        !this.validateEmail(this.model.founders[rowIndex].emailId)
      ) {
        this.fEmail = true;
      }
      if (
        this.model.founders[rowIndex].nationality === "" ||
        this.model.founders[rowIndex].nationality == null
      ) {
        this.fNationality = true;
      }
      return;
    } else {
      this.model.founders[rowIndex].isEdit = false;
      this.fName = false;
      this.fEmail = false;
      this.fNationality = false;
    }
  }

  selectFounder() {
    this.updateFounderSelection();
  }

  selectAllFounders() {
    if (this.areAllFoundersSelected) {
      this.model.founders.forEach((founder) => {
        founder.isSelected = true;
      });
    } else {
      this.model.founders.forEach((founder) => {
        founder.isSelected = false;
      });
    }
    this.updateFounderSelection();
  }

  updateFounderSelection() {
    let found = this.model.founders.find(
      (founder) => founder.isSelected == false
    );
    if (found || this.model.founders.length == 0) {
      this.areAllFoundersSelected = false;
    } else {
      this.areAllFoundersSelected = true;
    }
  }

  deleteAllSelectedFounders() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: "Are you sure you want to Delete ?",
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
        if (this.areAllFoundersSelected) {
          this.model.founders = [];
        } else {
          let newArray = this.model.founders.filter(
            (director) => director.isSelected == false
          );
          this.model.founders = newArray;
        }
        this.updateFounderSelection();
      }
      this.ngxLoader.stop();
    });
  }

  openFounderDetailsPopup(index) {
    this.model.founders[index].shouldShowDialog = true;
    this.utility.showOverlay();
  }

  onCloseFounderDetails() {
    this.model.founders.forEach((e) => (e.shouldShowDialog = false));
    this.utility.hideOverlay();
  }

  // Directors
  addDirector() {
    let length = this.model.directors.length;
    if (length > 0) {
      if (
        this.model.directors[length - 1].fullName === "" ||
        this.model.directors[length - 1].fullName === "" ||
        this.model.directors[length - 1].din === "" ||
        this.model.directors[length - 1].emailId === "" ||
        this.model.directors[length - 1].nationality === ""
      ) {
        this.toastr.error("Please fill previous row details", "Error!");
        return;
      } else if (
        this.model.directors[length - 1]
          .dateOfBoardResoluitonForAppointement === "" ||
        this.model.directors[length - 1].dateOfAppointement === ""
      ) {
        this.toastr.warning(
          "Please fill privious director profile details",
          "Warning!"
        );
      }
    }
    let newDirector = new DirectorDetailsModel();
    newDirector.id = 0;
    newDirector.fullName = "";
    newDirector.typeOfDirector = "";
    newDirector.din = "";
    newDirector.emailId = "";
    newDirector.address = "";
    newDirector.contactNumber = "";
    newDirector.dateOfBirth = "";
    newDirector.nationality = this.selectedNationality;
    newDirector.dateOfAppointement = "";
    newDirector.dateOfBoardResoluitonForAppointement = "";
    newDirector.isSelected = false;
    newDirector.shouldShowDialog = false;
    newDirector.isEdit = true;
    this.model.directors.push(newDirector);
    this.updateSelection();
    this.currentEditableDirectorRow = this.model.directors.length - 1;
    this.editDirectorRow(this.currentEditableDirectorRow);
  }

  editDirectorRow(rowIndex) {
    this.disableDirectorsInputs();
    this.model.directors[rowIndex].isEdit = true;
  }

  saveDirectorRow(rowIndex) {
    if (
      this.model.directors[rowIndex].fullName === "" ||
      this.model.directors[rowIndex].fullName == null ||
      this.model.directors[rowIndex].din === "" ||
      this.model.directors[rowIndex].din == null ||
      this.model.directors[rowIndex].nationality === "" ||
      this.model.directors[rowIndex].nationality == null ||
      this.model.directors[rowIndex].typeOfDirector === "" ||
      this.model.directors[rowIndex].typeOfDirector == null ||
      this.model.directors[rowIndex].emailId === "" ||
      this.model.directors[rowIndex].emailId == null ||
      !this.validateEmail(this.model.directors[rowIndex].emailId)
    ) {
      if (
        this.model.directors[rowIndex].fullName === "" ||
        this.model.directors[rowIndex].fullName == null
      ) {
        this.dName = true;
      }
      if (
        this.model.directors[rowIndex].din === "" ||
        this.model.directors[rowIndex].din == null
      ) {
        this.dDin = true;
      }
      if (
        this.model.directors[rowIndex].nationality === "" ||
        this.model.directors[rowIndex].nationality == null
      ) {
        this.dNationality = true;
      }
      if (
        this.model.directors[rowIndex].typeOfDirector === "" ||
        this.model.directors[rowIndex].typeOfDirector == null
      ) {
        this.dType = true;
      }
      if (
        this.model.directors[rowIndex].emailId === "" ||
        this.model.directors[rowIndex].emailId == null ||
        !this.validateEmail(this.model.directors[rowIndex].emailId)
      ) {
        this.dEmail = true;
      }
      return;
    } else {
      this.model.directors[rowIndex].isEdit = false;
      this.dName = false;
      this.dDin = false;
      this.dNationality = false;
      this.dType = false;
      this.dEmail = false;
    }
  }

  deleteIndividualDirector(index) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: "Are you sure you want to Delete ?",
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
        this.model.directors.splice(index, 1);
        this.updateSelection();
        this.dName = false;
        this.dDin = false;
        this.dNationality = false;
        this.dType = false;
        this.dEmail = false;
      }
      this.ngxLoader.stop();
    });
  }

  selectAllDirectors() {
    if (this.areAllDirectorsSelected) {
      this.model.directors.forEach((director) => {
        director.isSelected = true;
      });
    } else {
      this.model.directors.forEach((director) => {
        director.isSelected = false;
      });
    }
    this.updateSelection();
  }

  selectDirector() {
    this.updateSelection();
  }

  updateSelection() {
    let found = this.model.directors.find(
      (director) => director.isSelected == false
    );
    if (found || this.model.directors.length == 0) {
      this.areAllDirectorsSelected = false;
    } else {
      this.areAllDirectorsSelected = true;
    }
  }

  deleteAllSelected() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: "Are you sure you want to Delete ?",
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();

        if (this.areAllDirectorsSelected) {
          this.model.directors = [];
        } else {
          let newArray = this.model.directors.filter(
            (director) => director.isSelected == false
          );
          this.model.directors = newArray;
        }
        this.updateSelection();
        this.ngxLoader.stop();
      }
    });
  }

  openDirectorDetailsPopup(index) {
    this.model.directors[index].shouldShowDialog = true;
    this.utility.showOverlay();
  }

  onCloseDirectorDetails() {
    this.model.directors.forEach((e) => (e.shouldShowDialog = false));
    this.utility.hideOverlay();
  }

  checkValidation() {
    this.utility.formValidation();
    if (
      this.model.nameOfTheCompany === "" ||
      this.model.nameOfTheCompany == null ||
      this.model.cinNumber === "" ||
      this.model.cinNumber == null ||
      this.model.typeOfTheCompany == null ||
      this.model.typeOfTheCompany === "" ||
      this.model.dateOfIncorporation === "" ||
      this.model.dateOfIncorporation == null ||
      this.model.corporateOfficeAddress === "" ||
      this.model.corporateOfficeAddress == null ||
      this.model.companyEmailId === "" ||
      this.model.companyEmailId == null ||
      (this.model.companyEmailId &&
        !this.validateEmail(this.model.companyEmailId))
    ) {
      if (
        this.model.nameOfTheCompany === "" ||
        this.model.nameOfTheCompany == null
      ) {
        this.companyNameValid = true;
      }
      if (this.model.cinNumber === "" || this.model.cinNumber == null) {
        this.cinNoValid = true;
      }
      if (
        this.model.typeOfTheCompany === "" ||
        this.model.typeOfTheCompany == null
      ) {
        this.companyTypeValid = true;
      }
      if (
        this.model.dateOfIncorporation === "" ||
        this.model.dateOfIncorporation == null
      ) {
        this.incorporateDtValid = true;
      }
      if (
        this.model.corporateOfficeAddress === "" ||
        this.model.corporateOfficeAddress == null
      ) {
        this.officeAddressValid = true;
      }
      if (
        this.model.companyEmailId === "" ||
        this.model.companyEmailId == null ||
        (this.model.companyEmailId &&
          !this.validateEmail(this.model.companyEmailId))
      ) {
        this.emailValid = true;
      }
      let company = document.getElementById("Company_Details_Card");
      this.utility.focusSection(company);
      return false;
    } else if (
      this.model.nic === "" ||
      this.model.nic == null ||
      this.model.business.length == 0 ||
      this.model.business.length == null ||
      this.model.industry == null ||
      this.model.industry.length == 0
    ) {
      if (this.model.nic === "" || this.model.nic == null) {
        this.nicValid = true;
      }
      if (
        this.model.business.length == 0 ||
        this.model.business.length == null
      ) {
        this.businessInvalid = true;
      }
      if (this.model.industry == null || this.model.industry.length == 0) {
        this.industryInvalid = true;
      }
      let business = document.getElementById("Business_Details_Card");
      this.utility.focusSection(business);
      return false;
    } else {
      return true;
    }
  }

  formatDate(date) {
    if (date) {
      let newDate = new Date(date);
      return newDate.toISOString().replace("Z", "+0000");
    } else {
      return "";
    }
  }

  transformDate(date) {
    return this.datePipe.transform(date, "shortDate");
  }

  close() {
    this.ngxLoader.start();
    this.fetchCompanyDetails();
    this.ngxLoader.stop();
  }

  saveCompanyDetails() {
    this.save();
    this.isValid = this.checkValidation();
    if (this.isValid == false) {
      this.toastr.error("Please fill all the required fields!");
      return;
    }

    if (this.model.business.length == 0 || this.model.business.length == null) {
      this.toastr.error("Please fill Category details!");
      this.businessInvalid = true;
      return;
    }
    if (this.model.industry.length == 0 || this.model.industry.length == null) {
      this.toastr.error("Please fill Industry details!");
      this.industryInvalid = true;
      return;
    }

    if (this.model.founders.length == 0) {
      this.toastr.error("Please fill founder details!");
      return;
    } else if (this.model.founders.length > 0) {
      let k = 0;
      for (k = 0; k < this.model.founders.length; k++) {
        if (
          this.model.founders[k].fullName == "" ||
          this.model.founders[k].fullName == null ||
          this.model.founders[k].emailId == "" ||
          !this.validateEmail(this.model.founders[k].emailId) ||
          this.model.founders[k].emailId == null ||
          this.model.founders[k].nationality == "" ||
          this.model.founders[k].nationality == null
        ) {
          this.toastr.error("Please fill founder details!", "Error!");
          if (
            this.model.founders[k].fullName === "" ||
            this.model.founders[k].fullName == null
          ) {
            this.fName = true;
          }
          if (
            this.model.founders[k].emailId === "" ||
            this.model.founders[k].emailId == null ||
            !this.validateEmail(this.model.founders[k].emailId)
          ) {
            this.fEmail = true;
          }
          if (
            this.model.founders[k].nationality === "" ||
            this.model.founders[k].nationality == null
          ) {
            this.fNationality = true;
          }
          let founder = document.getElementById("Founders_Card");
          this.utility.focusSection(founder);
          return;
        }
        if (
          this.model.founders[k].designation === "" ||
          this.model.founders[k].designation == null ||
          this.model.founders[k].dateOfBirth === "" ||
          this.model.founders[k].dateOfBirth == null
        ) {
          this.toastr.warning(
            "Please fill founder profile details of " +
              this.model.founders[k].fullName,
            "Warning!"
          );
        }
      }
    }

    if (this.model.directors.length == 0) {
      this.toastr.error("Please fill director fields!");
      return;
    } else if (this.model.directors.length > 0) {
      let m = 0;
      for (m = 0; m < this.model.directors.length; m++) {
        if (
          this.model.directors[m].fullName === "" ||
          this.model.directors[m].fullName == null ||
          this.model.directors[m].din === "" ||
          this.model.directors[m].din == null ||
          this.model.directors[m].nationality === "" ||
          this.model.directors[m].nationality == null ||
          this.model.directors[m].typeOfDirector === "" ||
          this.model.directors[m].typeOfDirector == null ||
          this.model.directors[m].emailId === "" ||
          this.model.directors[m].emailId == null ||
          !this.validateEmail(this.model.directors[m].emailId)
        ) {
          this.toastr.error("Please fill director details!", "Error!");
          if (
            this.model.directors[m].fullName === "" ||
            this.model.directors[m].fullName == null
          ) {
            this.dName = true;
          }
          if (
            this.model.directors[m].din === "" ||
            this.model.directors[m].din == null
          ) {
            this.dDin = true;
          }
          if (
            this.model.directors[m].nationality === "" ||
            this.model.directors[m].nationality == null
          ) {
            this.dNationality = true;
          }
          if (
            this.model.directors[m].typeOfDirector === "" ||
            this.model.directors[m].typeOfDirector == null
          ) {
            this.dType = true;
          }
          if (
            this.model.directors[m].emailId === "" ||
            this.model.directors[m].emailId == null ||
            !this.validateEmail(this.model.directors[m].emailId)
          ) {
            this.dEmail = true;
          }
          let director = document.getElementById("Directors_Card");
          this.utility.focusSection(director);
          return;
        }
        if (
          this.model.directors[m].dateOfAppointement === "" ||
          this.model.directors[m].dateOfAppointement == null ||
          this.model.directors[m].dateOfBoardResoluitonForAppointement === "" ||
          this.model.directors[m].dateOfBoardResoluitonForAppointement == null
        ) {
          this.toastr.warning(
            "Please fill director profile details of " +
              this.model.directors[m].fullName,
            "Warning!"
          );
        }
      }
    }
    this.model.dateOfIncorporation = this.transformDate(
      this.model.dateOfIncorporation
    );
    this.model.founders.forEach((founder) => {
      founder.dateOfBirth = this.transformDate(founder.dateOfBirth);
    });
    this.model.directors.forEach((director) => {
      director.dateOfBirth = this.transformDate(director.dateOfBirth);
      director.dateOfAppointement = this.transformDate(
        director.dateOfAppointement
      );
      director.dateOfBoardResoluitonForAppointement = this.transformDate(
        director.dateOfBoardResoluitonForAppointement
      );
      director.dateOfBoardResoluitonForCessation = this.transformDate(
        director.dateOfBoardResoluitonForCessation
      );
      director.dateOfCessation = this.transformDate(director.dateOfCessation);
    });

    let user = JSON.parse(sessionStorage.getItem("userDetails"));
    let company = user.company;

    if (
      company.nameOfTheCompany.toLowerCase() !=
      this.model.nameOfTheCompany.toLowerCase()
    ) {
      this.oldName = company.nameOfTheCompany;
    }
    const formData = new FormData();
    formData.append("id", this.model.id);
    formData.append("nameOfTheCompany", this.model.nameOfTheCompany);
    formData.append("dippCertificateNumber", this.model.dippCertificateNumber);
    formData.append("doingBusinessAs", this.model.doingBusinessAs);
    formData.append("cinNumber", this.model.cinNumber);
    formData.append("isInForEquitySecurity", this.model.isInForEquitySecurity);
    formData.append("isInForPreferenceSecurity", this.model.isInForPreferenceSecurity);
    formData.append("typeOfTheCompany", this.model.typeOfTheCompany);
    formData.append("dateOfIncorporation", this.model.dateOfIncorporation);
    formData.append(
      "corporateOfficeAddress",
      this.model.corporateOfficeAddress
    );
    formData.append("companyEmailId", this.model.companyEmailId);
    formData.append("companyWebsite", this.model.companyWebsite);
    formData.append("nic", this.model.nic);
    formData.append(
      "subscribersOfMemorandum",
      this.model.subscribersOfMemorandum
    );
    formData.append("oldName", this.oldName);

    this.model.business.forEach((item, index) => {
      formData.append(`business[${index}]`, item);
    });

    this.model.industry.forEach((item, index) => {
      formData.append(`industry[${index}]`, item);
    });

    for (let i = 0; i < this.model.founders.length; i++) {
      Object.entries(this.model.founders[i]).forEach(([key, value]) => {
        if (key === "dateOfBirth") {
          if (value == null || value === "") {
            return;
          }
        } else {
          formData.append(`founders[${i}].${key}`, value);
        }
      });
    }

    for (let i = 0; i < this.model.directors.length; i++) {
      Object.entries(this.model.directors[i]).forEach(([key, value]) => {
        if (key === "dateOfBirth") {
          if (value == null || value === "") {
            return;
          }
        }
        if (key === "dateOfAppointement") {
          if (value == null || value === "") {
            return;
          }
        }
        if (key === "dateOfBoardResoluitonForAppointement") {
          if (value == null || value === "") {
            return;
          }
        }
        if (key === "dateOfBoardResoluitonForCessation") {
          if (value == null || value === "") {
            return;
          }
        }
        if (key === "dateOfCessation") {
          if (value == null || value === "") {
            return;
          }
        } else {
          formData.append(`directors[${i}].${key}`, value);
        }
      });
    }

    if (this.profilePicture) {
      formData.set(
        "companyLogo",
        new File([this.profilePicture], this.fileName, { type: "image/jpeg" })
      );
    } else if (this.model.companyLogoPath) {
      formData.append("companyLogoPath", this.model.companyLogoPath);
    }

    new Response(formData).text().then(console.log);

    this.ngxLoader.start();
    this.authService.saveCompanyDetails(formData).subscribe(
      (res) => {
        if (res) {
          this.fetchCompanyDetails();
          this.ngxLoader.stop();
          this.toastr.success(
            "Company Details Updated Successfully",
            "Success"
          );
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error: " + e.error.reason, "Error");
        this.model.dateOfIncorporation = this.datepipe.transform(
          this.model.dateOfIncorporation,
          "yyyy-MM-dd"
        );
        this.model.founders.forEach((founder) => {
          founder.dateOfBirth = this.datepipe.transform(
            founder.dateOfBirth,
            "yyyy-MM-dd"
          );
        });
        this.model.directors.forEach((director) => {
          director.dateOfBirth = this.datepipe.transform(
            director.dateOfBirth,
            "yyyy-MM-dd"
          );
          director.dateOfAppointement = this.datepipe.transform(
            director.dateOfAppointement,
            "yyyy-MM-dd"
          );
          director.dateOfBoardResoluitonForAppointement =
            this.datepipe.transform(
              director.dateOfBoardResoluitonForAppointement,
              "yyyy-MM-dd"
            );
          director.dateOfBoardResoluitonForCessation = this.datepipe.transform(
            director.dateOfBoardResoluitonForCessation,
            "yyyy-MM-dd"
          );
          director.dateOfCessation = this.datepipe.transform(
            director.dateOfCessation,
            "yyyy-MM-dd"
          );
        });
      }
    );
  }

  showHelp(section) {
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);

    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "CompanyDetailsComponent",
      section
    );
    this.helper = section;
  }

  hideHelp() {
    this.helper = "";
  }

  disableFoundersInputs() {
    if (this.model.founders.length > 0) {
      this.model.founders.forEach((founder) => {
        founder.isEdit = false;
      });
    }
  }

  disableDirectorsInputs() {
    if (this.model.directors.length > 0) {
      this.model.directors.forEach((director) => {
        director.isEdit = false;
      });
    }
  }

  allowAlphaNumericKey(event: any) {
    return event.charCode == 8 || event.charCode == 0 || event.charCode == 101
      ? null
      : (event.charCode >= 48 && event.charCode <= 57) ||
          (event.charCode >= 65 && event.charCode <= 90) ||
          (event.charCode >= 97 && event.charCode <= 122);
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getEvent() {
    this.shouldLogoShowDialog = false;
    this.utility.hideOverlay()
  }

  openUploadImage() {
    this.utility.showOverlay()
    this.shouldLogoShowDialog = true;
  }

  onImageSaved(imageDetails) {
    this.shouldLogoShowDialog = false;
    this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
    this.croppedImage = imageDetails.croppedImage;
    this.fileName = imageDetails.fileName;
    this.companyLogoInvalid = false;
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
}
