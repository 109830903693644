import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from "@angular/forms";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../../authentication.service";
import { ToastrService } from "ngx-toastr";
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {

  constructor(public authService: AuthenticationService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  @Input() openDialog;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();

  descriptions: FormGroup;
  filesSelected = [];
  isSubmitted = false;

  close() {
    this.openDialog = false;
    this.visibleChange.emit({
      showDig: false,
      cancel: true
    });
  }

  uploadDocuments(){
    this.isSubmitted = true;
    if(this.descriptionDocsGetter.valid){
      this.descriptionDocsGetter.value.forEach((item, num)=>{
        const fd = new FormData();
        fd.append("file", item.file);
        fd.append("description", item.description);
        this.authService.uploadIncorporationDocuments(fd).subscribe((response)=>{
          if (response.type === HttpEventType.Response) {
            this.toastr.success(item.file.name + " uploaded.", "Success!");

            if(num == this.descriptionDocsGetter.value.length - 1){
                setTimeout(() => {
                    this.visibleChange.emit({
                        showDig: false,
                        cancel: false
                      });
                  }, 500);
            }
        }
        if (response.type === HttpEventType.UploadProgress) {
          this.descriptionDocsGetter.controls[num]['controls']['totalSize'].setValue(response.total);
          this.descriptionDocsGetter.controls[num]['controls']['loadedSize'].setValue(response.loaded);
          const percentDone = Math.round(100 * response.loaded / response.total);
          console.log('Progress ' + percentDone + '%');
        }
        },(e)=>{
          this.toastr.error(e.error.reason, "Failure!");
        });
      });
    }
  }

  get descriptionDocsGetter() {
    return <FormArray>this.descriptions.get("documents");
  }

  onValueChanged(event){
    this.filesSelected = event.value;
    this.descriptions.controls["documents"] = this.fb.array([], Validators.required);
    event.value.forEach(element => {
      this.descriptionDocsGetter.push(this.fb.group({
        description : ["", [Validators.required, emptySpaceValidator]],
        file : [element, Validators.required],
        totalSize : [0],
        loadedSize: [0]
      }))
    });
  }

  ngOnInit() {
    this.descriptions = this.fb.group({
      documents : this.fb.array([],Validators.required)
    });
  }

}

//To validate input for only empty spaces
function emptySpaceValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const value: string = control.value;
  if (value.length > 0) {
    if (value.trim().length > 0) {
      return null;
    } else {
      return {
        invalid: true,
      };
    }
  }
}
