<div *ngIf="openDialog" class="dialog row file-upload-dialog">
  <div class="col-xs-12 col-sm-12 col-md-12 header btm-20">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6">
        <label class="dialogue-title" style="font-size: 16px;"
          >Upload Files</label
        >
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <button
          type="button" 
          class="close dialogue-title"
          (click)="close()"
          aria-label="Close"
        ><span
            class="close"
            >X</span
          >
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="descriptions">
  <div class="col-xs-12 col-sm-12 col-md-12 btm-20">
    <div id="file-uploader-dropzone btm-20" class="file-uploader-container">
      <dx-file-uploader #docUploader [showFileList]="false" name="files" multiple="true" (onValueChanged)="onValueChanged($event)" selectButtonText="Choose File" uploadMode="useForm" 
      labelText="Drag files to upload or select a file." ngbTooltip="Choose File">
      </dx-file-uploader>
    </div>
    <table class="table-common" formArrayName="documents">
      <thead>
        <tr>
          <th width="25%" class="text-align-left">Files</th>
          <th width="25%" class="text-align-left">Description</th>
          <th width="20%" class="text-align-left">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of filesSelected; let i = index">
          <td class="text-align-left">
            {{file.name}}
          </td>
          <td class="text-align-left" [formGroupName]="i">
            <input class="form-control" type="text" formControlName="description" required/>
            <div class="alert alert-danger" *ngIf="(descriptionDocsGetter.controls[i]['controls']['description'].errors?.required || descriptionDocsGetter.controls[i]['controls']['description'].value.trim().length ==
                  0) && (descriptionDocsGetter.controls[i]['controls']['description'].dirty || descriptionDocsGetter.controls[i]['controls']['description'].touched || isSubmitted)">
                    Description is Required!
                  </div>
          </td>
          <td class="text-align-right">
            <dx-progress-bar #progressBar width="90%" [min]="0" [max]="descriptionDocsGetter.controls[i]['controls']['totalSize'].value" [value]="descriptionDocsGetter.controls[i]['controls']['loadedSize'].value">
            </dx-progress-bar>
          </td>
        </tr>
        <tr *ngIf="filesSelected.length == 0">
          <td colspan="3">
            No files
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-12 clear-both btm-20">
    <button
      mat-raised-button id="close" ngbTooltip="Close"
      [disableRipple]="true"
      color="warn"
      class="col-xs-2 col-sm-2 col-md-2 cmn-button"
      style="float: right;"
      (click)="close()"
    >
      Close
    </button>
    <button appDoubleClick
    (debounceClick)="uploadDocuments()"
      mat-raised-button id="uploadFiles"
      [disableRipple]="true" ngbTooltip="Upload Files"
      color="success"
      class="col-xs-2 col-sm-2 col-md-2 cmn-button"
      style="float: right; margin-right: 20px;"
      
    >
      Upload Files
    </button>
  </div>
  </form>
</div>
