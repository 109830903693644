/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i4 from "ngx-ui-loader";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./app.component";
import * as i7 from "./authentication.service";
import * as i8 from "ngx-toastr";
import * as i9 from "./services/cache-provider.service";
import * as i10 from "@angular/service-worker";
import * as i11 from "./services/svg-loader.service";
import * as i12 from "@angular/common";
import * as i13 from "@angular/material/dialog";
import * as i14 from "./services/broadast-token.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ngx-ui-loader", [], null, null, null, i3.View_ɵb_0, i3.RenderType_ɵb)), i1.ɵdid(3, 770048, null, 0, i4.ɵb, [i5.DomSanitizer, i1.ChangeDetectorRef, i4.NgxUiLoaderService], { fgsColor: [0, "fgsColor"], overlayColor: [1, "overlayColor"], text: [2, "text"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "dummyDiv"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.ngxUIconfig.fgsColor; var currVal_1 = _co.ngxUIconfig.overlayColor; var currVal_2 = _co.ngxUIconfig.text; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "load"]], function (_v, en, $event) { var ad = true; if (("window:load" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i2.Router, i7.AuthenticationService, i4.NgxUiLoaderService, i8.ToastrService, i9.CacheProviderService, i10.SwUpdate, i11.SVGLoader, i12.Location, i13.MatDialog, i14.BROADCAST_SERVICE], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
