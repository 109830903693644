import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor() { }
  private securityConversionDetails = null;
  private securityDetailsPreview = null;
  
  set setConversionDetails(details){
    this.securityConversionDetails = details;
  }

  get getConversionDetails(){
    return this.securityConversionDetails;
  }

}
