import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EmployeesAndEsopDialogsModule } from '../esop-management/dialogs/employees-and-esop-dialogs.module';
import { CommonDialogsModule } from './../dialogues/common-dialogs.module';

import { MatCardModule, MatFormFieldModule, MatDividerModule, MatDatepickerModule, MatIconModule, MatSlideToggleModule, MatButtonModule, MatExpansionModule,  MatInputModule, MatSelectModule, MatChipsModule, MatAutocompleteModule, MatDialogModule, MatButtonToggleModule  } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridModule, DxTooltipModule, DxChartModule } from 'devextreme-angular';
import { MatCheckboxModule } from '@angular/material/checkbox';


import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeesComponent } from './employees.component';
import { EmployeesRoutingModule } from './employees-routing.module';
import { TransactionsComponent } from './transactions/transactions.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { DirectivesModule } from '../custom-directives/directives.module';
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { FaqComponent } from '../employees/faq/faq.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    EmployeeDetailsComponent,
    EmployeesComponent,
    TransactionsComponent,
    KnowledgeBaseComponent,
    EmployeeLoginComponent,
    FaqComponent,
  ],
  imports: [
    CommonModule,
    EmployeesRoutingModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatDividerModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    NgbModule,
    DxDataGridModule,
    DxTooltipModule,
    DxChartModule,
    EmployeesAndEsopDialogsModule,
    CommonDialogsModule,
    MatCheckboxModule,
    MatExpansionModule,
    DirectivesModule,
    PipesModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatDialogModule    
  ],
  exports: [
    EmployeeDetailsComponent,
    EmployeesComponent,
    MatCheckboxModule,
    EmployeeLoginComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmployeesModule { }
