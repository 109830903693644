import { Component, OnInit, Input, Inject, Output, EventEmitter  } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { of  } from 'rxjs/internal/observable/of';
import { asyncScheduler } from 'rxjs';
import { concatMap, repeat, delay, startWith } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilityService } from 'src/app/services/utilities.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-adj-conversion-ratio-popup',
  templateUrl: './adj-conversion-ratio-popup.component.html',
  styleUrls: ['./adj-conversion-ratio-popup.component.scss']
})
export class AdjConversionRatioPopupComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private readonly utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AdjConversionRatioPopupComponent>,
  ) { 
  }

  ngOnInit() {
    if(!this.data['updatedConversionRatio']) {
      this.data['updatedConversionRatio'] = "1:"
    } else {
      this.data['fdbSharesForAdjustConversionRatio'] = JSON.parse(this.data['updatedConversionRatio'].split(':')[1]) * this.data['numberSharesForBonus']
    }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
  }

  calculateNewFdbShares(e){
    if(e.target.value[0] != "1" || e.target.value[1] != ":"){
      return e.target.value = "1:";
    }
    this.data['fdbSharesForAdjustConversionRatio'] = JSON.parse(e.target.value.split(':')[1]) * this.data['numberSharesForBonus']
  }

  goToSecurityDetails(){
    if(JSON.parse(this.data['updatedConversionRatio'].split(':')[1]) < JSON.parse(this.data['ratio'].split(':')[1])) {
      this.toastr.error("Current Conversion Ratio must be greater than New Conversion Ratio")
      return;
    }
    this.dialogRef.close(this.data);
  }

  close(){
    this.dialogRef.close();
  }

}
