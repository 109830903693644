import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DirectorDetailsModel } from '../director-details.model';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../../services/utilities.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-director-details-popup',
  templateUrl: './director-details-popup.component.html',
  styleUrls: ['./director-details-popup.component.scss']
})
export class DirectorDetailsPopupComponent implements OnInit {
  
  @ViewChild("dirForm") dirForm: NgForm;
  @Input() showDialog: boolean;
  @Output() closeDetails: EventEmitter<any> = new EventEmitter<any>();
  @Input() directorDetailsModel: DirectorDetailsModel;

  public countryList;
  public nameValid: boolean = false;
  public dierctorTypeValid: boolean = false;
  public dinNoValid: boolean = false;
  public emailValid: boolean = false;
  public appointmentDtValid: boolean = false;
  public boardresAppDtValid: boolean = false;
  public nationalityValid: boolean = false;
  public isValid: boolean = false;
  public maxLength: number = 9;

  constructor(
    private toastr: ToastrService,
    private utility: UtilityService
  ) { }

  ngOnInit() {
    const { getNames } = require("country-list");
    this.countryList = getNames();
    this.nameValid = false;
    this.dierctorTypeValid = false;
    this.dinNoValid = false;
    this.emailValid = false;
    this.nationalityValid = false;
  }

  close() {
    this.closeDetails.emit(this.directorDetailsModel);
    this.dirForm.reset();
    this.nameValid = false;
    this.dierctorTypeValid = false;
    this.boardresAppDtValid = false;
    this.dinNoValid = false;
    this.emailValid = false;
    this.appointmentDtValid = false;
    this.nationalityValid = false;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkValidation() {
    this.utility.formValidation();
    if (this.directorDetailsModel.fullName === "" ||  this.directorDetailsModel.fullName == null ||
        this.directorDetailsModel.typeOfDirector === "" ||  this.directorDetailsModel.typeOfDirector == null ||
        // this.directorDetailsModel.dateOfBoardResoluitonForAppointement === "" || this.directorDetailsModel.dateOfBoardResoluitonForAppointement == null || 
        this.directorDetailsModel.din === "" || this.directorDetailsModel.din == null ||
        this.directorDetailsModel.emailId === "" || this.directorDetailsModel.emailId == null || !this.validateEmail(this.directorDetailsModel.emailId) || 
        // this.directorDetailsModel.dateOfAppointement === "" || this.directorDetailsModel.dateOfAppointement == null ||
        this.directorDetailsModel.nationality === "" || this.directorDetailsModel.nationality == null) {

      if (this.directorDetailsModel.fullName === "" ||  this.directorDetailsModel.fullName == null) {
        this.nameValid = true;
      }
      if (this.directorDetailsModel.typeOfDirector === "" ||  this.directorDetailsModel.typeOfDirector == null) {
        this.dierctorTypeValid = true;
      }
      // if (this.directorDetailsModel.dateOfBoardResoluitonForAppointement === "" || this.directorDetailsModel.dateOfBoardResoluitonForAppointement == null) {
      //   this.boardresAppDtValid = true;
      // }
      if (this.directorDetailsModel.din === "" || this.directorDetailsModel.din == null) {
        this.dinNoValid = true;
      }
      if (this.directorDetailsModel.emailId === "" || this.directorDetailsModel.emailId == null || !this.validateEmail(this.directorDetailsModel.emailId) ) {
        this.emailValid = true;
      }
      // if (this.directorDetailsModel.dateOfAppointement === "" || this.directorDetailsModel.dateOfAppointement == null) {
      //   this.appointmentDtValid = true;
      // }
      if (this.directorDetailsModel.nationality === "" || this.directorDetailsModel.nationality == null) {
        this.nationalityValid = true;
      }
      return false;
    }
    else {
      return true;
    }
  }

  saveAndExit() {
    this.isValid = this.checkValidation();
    if (this.isValid == true) {
      this.directorDetailsModel.shouldShowDialog = false;
      this.showDialog = false;
      this.closeDetails.emit(this.directorDetailsModel);
    }
    else {
      this.toastr.error("Please fill all the required fields!!", "Error")
    }
  }

  allowAlphaNumericKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 101) ? null : ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
  }
  
  maxNumber(val){
    if (val.length > this.maxLength) {
      this.directorDetailsModel.contactNumber = val.slice(0, this.maxLength)
    }
  }

}