import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var NavbarService = /** @class */ (function () {
    function NavbarService(router) {
        this.router = router;
        this.subject = new Subject();
        this.requireReload = false;
    }
    NavbarService.prototype.sendClickEvent = function () {
        this.subject.next();
    };
    NavbarService.prototype.getClickEvent = function () {
        return this.subject.asObservable();
    };
    NavbarService.prototype.isHistoricalRound = function () {
        if (this.router.url == "/landing/onboarding/create-historical-round" || this.router.url == "/landing/onboarding/historical-round-summary") {
            return true;
        }
        else {
            return false;
        }
    };
    NavbarService.ngInjectableDef = i0.defineInjectable({ factory: function NavbarService_Factory() { return new NavbarService(i0.inject(i1.Router)); }, token: NavbarService, providedIn: "root" });
    return NavbarService;
}());
export { NavbarService };
