import {DirectorDetailsModel} from "./director-details.model";
import {FounderDetailsModel} from "./founder-details.model";

export class CompanyDetailsModel {
    id:string
    cinNumber: string;
    isInForEquitySecurity: string;
    isInForPreferenceSecurity: string;
    nameOfTheCompany: string;
    typeOfTheCompany: string;
    companyCurrency: string;
    dateOfIncorporation: string;
    corporateOfficeAddress: string;
    companyEmailId: string;
    business: [];
    currency: string;
    industry: [];
    nic: string;
    dippCertificateNumber: string;
    doingBusinessAs: string;
    directors: DirectorDetailsModel[];  
    founders: FounderDetailsModel[];
    companyLogo: string;
    companyWebsite: string;
    subscribersOfMemorandum: string;
    companyLogoPath:string
} 



      