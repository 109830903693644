import { Router, } from "@angular/router";
import { AuthenticationService } from "../app/authentication.service";
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        if (window.location.href.includes("xUserToken") &&
            !sessionStorage.getItem("userDetails"))
            return false;
        if (localStorage.getItem("currentUser") &&
            localStorage.getItem("currentUser") != "null") {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigateByUrl("/");
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthenticationService), i0.inject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
