<div class="row" (mouseleave)="hideHelp()">
  <div class="col-md-9 col-sm-12 col-xs-12">
    <div *ngIf="!isAccountConfirm">
      <mat-card *ngIf="!waitingForVerification" class="admin-profile-card" id="Profile_Details_Card" (mouseover)="showHelp('Profile_Details_Card')">
        <mat-card-header>
          <mat-card-title>Profile Details</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row top-20 btm-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the details of the ‘Admin’ who will be in charge of overseeing and maintaining the information of the company on the tool from time to time. 
                Select the role the admin is undertaking in the company as on date. Once this is done, 
                select any avatar or upload the picture of the admin. Finally, upload any ID proof of the admin. 
                A verification link will be sent to the registered email address. Click on the link to complete admin profile registration.  
              </p>
            </div>
          </div>

          <div class="row btm-20">
            <div class="col-md-8 col-lg-8">
              <div *ngIf="croppedImage == null">
                <mat-label>Upload Profile Picture / Select Avatar</mat-label>
                <div class="top-10">
                  <img class="avatar-sizing" src="../../../assets/uploadeq.png" (click)="openUploadPicturePopup()" />
                  <img class="avatar-sizing" src="../../../assets/avatar01.png" (click)="uploadAvatarImage($event)" />
                  <img class="avatar-sizing" src="../../../assets/avatar02.png" (click)="uploadAvatarImage($event)" />
                  <img class="avatar-sizing" src="../../../assets/avatar03.png" (click)="uploadAvatarImage($event)" />
                  <img class="avatar-sizing" src="../../../assets/avatar04.png" (click)="uploadAvatarImage($event)" />
                  <img class="avatar-sizing" src="../../../assets/avatar05.png" (click)="uploadAvatarImage($event)" />
                  <img class="avatar-sizing" src="../../../assets/avatar06.png" (click)="uploadAvatarImage($event)" />
                </div>
              </div>
              <app-upload-image *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog" [croppedImage]="croppedImage" (visibleChange)="getEvent()" (imageSaved)="onImageSaved($event)">
              </app-upload-image>
              <div *ngIf="croppedImage != null">
                <img [src]="croppedImage" class="uploaded-image" (click)="changeCroppedImage()" />
              </div>
            </div>
          </div>
          <div class="row top-20">
            <div class="col-md-4 col-lg-4">
              <mat-label class="required">Designation</mat-label>
              <div class="top-10">
                <select class="form-control" id="role" [(ngModel)]="profile.designation" required #roleName="ngModel"
                  [disabled]="isNonEditable" [ngClass]="roleName.errors?.required && designationValid ? 'is-invalid' : 'is-valid'">
                  <option [disabled]="profile && profile.designation" value="undefined" selected>Select your Designation
                  </option>
                  <option *ngFor="let role of roles">{{role}}</option>
                </select>
                <div class="alert alert-danger" *ngIf="roleName.invalid">
                  <div *ngIf="(roleName.dirty || roleName.touched) && !designationValid" class="alert alert-danger">
                    Designation is required!
                  </div>
                  <div *ngIf="roleName.errors?.required && designationValid" class="alert alert-danger">
                    Designation is required!
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <mat-label class="required">Authenticator</mat-label>
              <div class="top-10">
                <select class="form-control" id="authenticator" [(ngModel)]="profile.authenticatorType"
                  #authenticatorType="ngModel" (ngModelChange)="onAuthenticatorTypeChange($event)" required
                  [disabled]="isNonEditable" [ngClass]="authenticatorType.errors?.required && authenticatorValid ? 'is-invalid' : 'is-valid'">
                  <option [disabled]="profile && profile.authenticatorType" value="undefined" selected>Select
                    Authenticator</option>
                  <option *ngFor="let auths of authenticatorList">{{auths}}</option>
                </select>
                <div *ngIf="authenticatorType.invalid">
                  <div *ngIf="(authenticatorType.dirty || authenticatorType.touched) && !authenticatorValid"
                    class="alert alert-danger">
                    Authenticator is required!
                  </div>
                  <div *ngIf="authenticatorType.errors?.required && authenticatorValid" class="alert alert-danger">
                    Authenticator is required!
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <input hidden type="file" (change)="fileChangeEvent($event)" accept="application/pdf"
                #authenticatorInput />

              <div *ngIf="profile.authenticatorType != '' && !authenticator" class="top-20  upload-card">
                <!-- <div class="upload-blue-icon"> -->
                  <div class="row" (click)="authenticatorInput.value='';authenticatorInput.click()">
                      <div class="col-md-2">
                        <img src="../../../assets/upload-blue-plus.svg" />
                      </div>
                      <div class="col-md-10">
                        <p class="text-13-grey-normal" style="margin: 17px 10px 17px 0px;">
                            Upload {{ profile.authenticatorType }} in pdf or jpeg format
                          below 3mb</p>
                      </div>
                  </div>
                <!-- </div> -->
              </div>
              <div *ngIf="profile.authenticatorType != '' && !authenticator && authFileValid"
                class="alert alert-danger">
                Please upload {{ profile.authenticatorType }} !
              </div>

              <div *ngIf="profile.authenticatorType != '' && authenticator" class="top-20 top-padding">
                <div>{{authenticator.name}}&nbsp;
                  <span *ngIf="!isNonEditable"><img src="../../../assets/trash-bin.svg"
                      (click)="changeAuthType()" /></span>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div *ngIf="!waitingForVerification" (mouseleave)="hideHelp()">
        <button mat-raised-button id="continue_proceedToCompanyDetails" color="success" *ngIf="!isNonEditable" [disableRipple]="true"
          class="top-20 float-right" (click)="proceedToCompanyDetails()" ngbTooltip="Continue">Continue</button>
        <button mat-raised-button id="continue_gotoCompanyDetails" color="success" *ngIf="isNonEditable" [disableRipple]="true"
          class="top-20 float-right" (click)="gotoCompanyDetails()" ngbTooltip="Continue">Continue</button>
      </div>
    </div>
  </div>

  <!-- Helper sections -->
  <div class="col-md-3">
    <div class="helper-container">
      <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
        [hidden]="helper == ''">
        <mat-card-header class="help-hdr">
          <mat-card-title><label class="help-hdr-txt">{{
        helpSections?.name
      }}</label></mat-card-title>
        </mat-card-header>
        <hr />
        <mat-card-content class="card-help-content">
          <div [innerHTML]="helpSections?.description"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="waitingForVerification || isAccountConfirm">
  <mat-card class="company-name-card">
    <mat-card-header>
      <mat-card-title>Account Confirmation</mat-card-title>
    </mat-card-header>
    <mat-divider class="divider-line"></mat-divider>
    <mat-card-content>
      <div class="row top-20">
        <mat-card-title class="card-section-hdr req-center">Welcome to Hissa</mat-card-title>
      </div>

      <div class="row">
        <p class="content-pg-1 req-center">Thank you for signing up for your new account at Hissa,
          <br>
          you will get the confirmation mail once the admin verify your account.
        </p>
      </div>

      <div class="row top-20" *ngIf="isVerificationLinkExpired">
        <p class="req-center"><span class="resendLink" (click)="resendVerMail()">Resend verification E-mail to Admin</span> </p>
      </div>

      <div class="row top-20" *ngIf="registerNewCompany">
        <button mat-raised-button id="gotoDashboard" color="success" *ngIf="registerNewCompany" [disableRipple]="true"
        class="top-20 req-center" (click)="goToDashboard()" ngbTooltip="Go To Dashboard">Go To Dashboard</button>
      </div>

    </mat-card-content>
  </mat-card>
</div>