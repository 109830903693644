<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">Update Nominees</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
    <div style="height:90%;">
      <div class="section-padding">
          <p>Kindly add/confirm the details of the nominee(s) who will be eligible to receive the economic benefits of the options granted to you in accordance with the terms of the ESOP Plan.</p>
      </div>
      <div class="section-padding bottom-padding">
          <div class="row btm-20">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <button mat-raised-button id="ok" color="success" [disableRipple]="true" ngbTooltip="OK"
                  class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="navigate()">
                  OK
                </button>
              </div>
          </div>
      </div>
  </div>
</div>