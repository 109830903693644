import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';
import currencyToSymbolMap from 'currency-symbol-map/map';
import * as $AB from "jquery";
declare var bootbox: any;
declare var $: any;
import { DatePipe } from '@angular/common';
import { DataService } from '../../services/new-round-notify.service';
import { UtilityService } from '../../services/utilities.service';
import { MatTabGroup } from '@angular/material';
import { runInThisContext } from 'vm';
import { HelperService } from '../../services/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from '../../services/cache-provider.service';

@Component({
    selector: 'app-round-tab',
    templateUrl: './round-tab.component.html',
    styleUrls: ['./round-tab.component.scss']
})
export class RoundTabComponent implements OnInit {
    basicRndDetails;
    pricedRound = true;
    bridgeRound = false;
    roundSize = 0;
    roundSizeMin;
    roundSizeMax;
    preMoneyVal = 0;
    preMoneyMin;
    preMoneyMax;
    currentVal;
    valuationCap = 0;
    dilution;
    postMoneyVal;
    discount = 0;
    esop;
    public oldESOP: any;
    roundName;
    roundIdentifier;
    roundDescription;
    currencyList;
    exchangeRate;
    currencyForRound;
    issueDate;
    isValid = false;
    parentComp = false;
    public isExchangeRteEditable: boolean = false;
    public disableripple: boolean = true;
    public errorFlags;
    public helpContext;
    public helpDescription = " ";
    companyCurrency;
    isRoundNotSaved: boolean = false;

    step = 100000;
    thumbLabel = true;
    vertical = false;
    preMoneyValText: any;
    roundSizeText: any;
    valuationCapText: any;
    public isRndSizeErr: boolean = false;
    public rndIconstate = "../../assets/wizard/step-1.svg";
    public isESOP: boolean = false;
    public isDiscntErr: boolean = false;
    public isRndCurrencyErr: boolean = false;
    index = 1;
    helper: string = "";
    helpSections;
    helperTopPosition = null;

    rName: boolean = false;
    ridentifier: boolean = false;
    rDescription: boolean = false;
    rCurrency: boolean = false;
    rissueDate: boolean = false;
    rsCurrency: boolean = false;
    resop: boolean = false;
    rDiscount: boolean = false;
    rRoundSize: boolean = false;
    rexchange: boolean = false;
    public helpTitle: any;
    public helpMessage: any;
    public deciList:number[] =[0,1,2,3,4,5,6,7,8,9];
    public noDecimals:boolean = false;
    public roundMechanis:boolean = false;
    public roundMechanisam;
    public numberOfDecimals;
    public diluteEsop;
    public numberRoundDropdownvalues:[];
    
    rndInfo = {
        'roundName': '',
        'roundIdentifier': '',
        'currencyForRound': '',
        'roundDescription': '',
        'exchangeRate': '',
        'isPricedRound': false,
        'roundSize': 0,
        'preMoneyValuation': 0,
        'esopPoolPercentage': 0,
        'oldEsopPercentage': this.oldESOP,
        'postMoneyValuation': 0,
        'dilutionPercentage': 0,
        'valuationCap': null,
        'discountToNextSeriesValuation': null,
        'issueDate': '',
        "numberOfDecimals" : 0,
        // 'numberRound' : '',
        'isEsopPercentageAutoDilute' : false ,
    };

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthenticationService,
        public datepipe: DatePipe, private dataService: DataService,
        private utility: UtilityService,
        private helperService: HelperService,
        private ngxLoader: NgxUiLoaderService,
        private toastr: ToastrService,
        private cacheService: CacheProviderService
    ) { }

    goToCurrentInvs() {
        this.ngxLoader.start();
        this.isRoundNotSaved = false;
        this.rndInfo.roundName = this.roundName;
        this.rndInfo.roundIdentifier = this.roundIdentifier;
        this.rndInfo.roundDescription = this.roundDescription;
        this.rndInfo.isPricedRound = this.pricedRound;
        this.rndInfo.currencyForRound = this.currencyForRound;
        this.rndInfo.roundSize = this.roundSize;
        this.rndInfo.preMoneyValuation = this.preMoneyVal;
        this.rndInfo.esopPoolPercentage = this.esop;
        this.rndInfo.oldEsopPercentage = this.oldESOP;
        this.rndInfo.postMoneyValuation = this.postMoneyVal;
        this.rndInfo.dilutionPercentage = this.dilution;
        this.rndInfo.valuationCap = this.valuationCap;
        // this.rndInfo.discountToNextSeriesValuation = this.discount;
        this.rndInfo.exchangeRate = this.exchangeRate;
        this.rndInfo.isEsopPercentageAutoDilute = this.diluteEsop;
        this.rndInfo.numberOfDecimals = this.numberOfDecimals/1;
        // this.rndInfo.numberRound  = this.roundMechanisam;

        if (this.issueDate != "") {
            let dt = new Date(this.issueDate);
            this.rndInfo.issueDate = dt.toISOString().replace("Z", "+0000");
        }
        else {
            this.rndInfo.issueDate = this.issueDate;
        }

        this.isValid = this.validatorMethod(this.rndInfo);
        if (this.isValid) {
            this.authService.postRoundData(this.rndInfo).subscribe((response) => {
                if (response) {
                    this.ngxLoader.stop();
                    this.cacheService.set('newRoundName', this.rndInfo.roundName);
                    this.cacheService.set('newRoundInfo', JSON.stringify(this.rndInfo));
                    this.cacheService.set('newRndSavRespns', JSON.stringify(response));
                    this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'currentInvestors']);
                } else {
                    this.ngxLoader.stop();
                    this.toastr.error("Error: Response is null!", "Error");
                }
            }, (e) => {
                this.ngxLoader.stop();
                this.toastr.error("Error: " + e.error.reason, "Error");
            });
        } else {
            if (this.roundName == null) {
                this.rName = true;
            }
            if (this.roundIdentifier == null) {
                this.ridentifier = true;
            }
            if (this.roundDescription == null) {
                this.rDescription = true;
            }
            if (this.currencyForRound == null) {
                this.rsCurrency = true;
            }
            if (this.issueDate == null || this.issueDate === "") {
                this.rissueDate = true;
            }
            if (this.exchangeRate == null) {
                this.rexchange = true;
            }
            if (this.roundSize == null) {
                this.rRoundSize = true;
            }
            if (this.esop == null) {
                this.resop = true;
            }
            // if (this.discount == null) {
            //     this.rDiscount = true;
            // }
            // if (this.roundMechanisam == null || this.roundMechanisam == "") {
            //     this.roundMechanis = true;
            // }
            if (this.numberOfDecimals == null || this.numberOfDecimals == "") {
                this.noDecimals = true;
            }
            
            if(this.roundName == null || this.roundIdentifier == null || this.roundDescription == null
                || this.currencyForRound == null || (this.issueDate == null || this.issueDate === "") || this.exchangeRate == null
                 || (this.numberOfDecimals == null || this.numberOfDecimals == "")) {
                let round = document.getElementById("Round_Tab");
                this.utility.focusSection(round);
            }
            else if(this.roundSize == null || this.esop == null){
                let priceRound = document.getElementById("PricedRound_Tab");
                this.utility.focusSection(priceRound);
            }
            this.ngxLoader.stop();
            return;
        }
    }
    getNameNewRnd(round){
        this.roundName = round.replace(/ \t/g,'').replace(/ +(?= )/g,'').trim();
      }
    // Fields Validator
    validatorMethod(data) {
        this.utility.formValidation();
        if (data.isPricedRound === true) {
            if (!data.roundName ||
                !data.roundIdentifier ||
                !data.currencyForRound ||
                !data.roundDescription ||
                !data.issueDate ||
                !data.exchangeRate ||
                data.numberOfDecimals === "" ||
                // !data.numberRound ||
                data.postMoneyValuation == null ||
                data.preMoneyValuation == null ||
                data.roundSize == null) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (!data.roundName ||
                !data.roundIdentifier ||
                !data.currencyForRound ||
                !data.roundDescription ||
                !data.issueDate ||
                !data.exchangeRate ||
                data.numberOfDecimals === "" ||
                // !data.numberRound ||
                data.postMoneyValuation == null ||
                data.valuationCap == null ||
                data.roundSize == null) {
                return false;
            }
            else {
                return true;
            }
        }
    }


    // Currency Selection
    selectedCurrency(curncy) {
        this.rndInfo.currencyForRound = curncy;
        if(this.companyCurrency === this.currencyForRound){
            this.isExchangeRteEditable = false;
        } 
        else {
            this.isExchangeRteEditable = true;
        }
    }

    // Slider value change event
    updateSliders() {
        $(document).ready(() => {
            var val = (this.roundSize / 1 - this.roundSizeMin) / (this.roundSizeMax - this.roundSizeMin);
            $('#roundSize').css('background-image',
                '-webkit-gradient(linear, left top, right top, '
                + 'color-stop(' + val + ', #1B4C89), '
                + 'color-stop(' + val + ', #C5C5C5)'
                + ')'
            );
            var val1 = (this.preMoneyVal / 1 - this.preMoneyMin) / (this.preMoneyMax - this.preMoneyMin);
            let percentageVal = ((this.currentVal - this.preMoneyMin) * 100) / (this.preMoneyMax - this.preMoneyMin);
            $('.currentValueMark').css('left', percentageVal + '%');
            $('#preMoney').css('background-image',
                '-webkit-gradient(linear, left top, right top, '
                + 'color-stop(' + val1 + ', #1B4C89), '
                + 'color-stop(' + val1 + ', #C5C5C5)'
                + ')'
            );

            let val2 = (this.valuationCap / 1 - this.preMoneyMin) / (this.preMoneyMax - this.preMoneyMin);
            $('#valCap').css('background-image',
                '-webkit-gradient(linear, left top, right top, '
                + 'color-stop(' + val2 + ', #1B4C89), '
                + 'color-stop(' + val2 + ', #C5C5C5)'
                + ')'
            );
        });
    }

    // Round slider change event
    onChangeRoundSize(type, e) {
        if (type === 'ip') {
            let rst;
            if(e) {
                // store current positions in variables
                var start = e.target.selectionStart,
                end = e.target.selectionEnd;
            }
            let val = this.roundSizeText;
            this.roundSize = this.roundSizeText.replace(/,/g, "") / 1;
            rst = this.utility.inputCSVformatCurrencyRound(this.currencyForRound, val);

            if(e) {
                if(e.target.value) {
                    let CSVLength = rst.length;
                    let valueLength = e.target.value.length;
                    if(CSVLength > valueLength) {
                        e.target.value = rst;
                        let difference = CSVLength - valueLength;
                        start = start + difference;
                        end = end + difference;
                    }
                    else if(CSVLength < valueLength){
                        e.target.value = rst;
                        if(start != 0 || end != 0){
                            let difference = valueLength - CSVLength;
                            start = start - difference;
                            end = end - difference;
                        }
                        else{
                            start = start;
                            end = end;
                        }  
                    }
                    else {
                        e.target.value = rst;
                    }
                }
            
                // restore from variables...
                e.target.setSelectionRange(start, end);
            } else {
                this.roundSizeText = rst;
            }
        }
        if (type === 'slider') {
            this.roundSize = this.roundSizeText.replace(/,/g, "") / 1;
            if (this.currencyForRound != null && this.currencyForRound != this.companyCurrency) {
                let rst = this.utility.convertToRoundCurrency(this.roundSize);
                this.roundSizeText = rst;
            }
            else {
                let rst = this.utility.convertToCompanyCurrency(this.roundSize);
                this.roundSizeText = rst;
            }
        }
        var val = (this.roundSize - this.roundSizeMin) / (this.roundSizeMax - this.roundSizeMin);
        $('#roundSize').css('background-image',
            '-webkit-gradient(linear, left top, right top, '
            + 'color-stop(' + val + ', #1B4C89), '
            + 'color-stop(' + val + ', #C5C5C5)'
            + ')'
        );
        if (this.pricedRound) {
            this.postMoneyVal = this.roundSize + this.preMoneyVal;
        }
        if (!this.pricedRound) {
            this.postMoneyVal = this.roundSize + this.valuationCap;
        }
        this.dilution = this.roundSize / this.postMoneyVal;
    }

    val() {
        if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
            this.roundSizeText = this.utility.convertToCompanyCurrency(this.roundSize);
            this.preMoneyValText = this.utility.convertToCompanyCurrency(this.preMoneyVal);
            this.valuationCapText = this.utility.convertToCompanyCurrency(this.valuationCap);
        }
        else {
            this.roundSizeText = this.utility.convertToRoundCurrency(this.roundSize);
            this.preMoneyValText = this.utility.convertToRoundCurrency(this.preMoneyVal);
            this.valuationCapText = this.utility.convertToRoundCurrency(this.valuationCap);
        }

        if (this.preMoneyVal < this.roundSizeMin) {
            this.preMoneyVal = this.roundSizeMin;
        }

        if (this.preMoneyVal > this.roundSizeMax) {
            this.preMoneyVal = this.roundSizeMax;
        }
        if (this.pricedRound) {
            this.postMoneyVal = this.roundSize + this.preMoneyVal;
        }
        if (!this.pricedRound) {
            this.postMoneyVal = this.roundSize + this.valuationCap;
        }
        this.dilution = this.roundSize / this.postMoneyVal;
    }

    // Pre-money value slider change event
    onChangePreMoneyVal(type, e) {
        this.loadJsStyles();
        if (type === 'ip') {
            let pmv;
            if(e) {
                // store current positions in variables
                var start = e.target.selectionStart,
                end = e.target.selectionEnd;
            }
            let val = this.preMoneyValText;
            this.preMoneyVal = this.preMoneyValText.replace(/,/g, "") / 1;
            pmv = this.utility.inputCSVformatCurrencyRound(this.currencyForRound, val);
            
            if(e) {
                if(e.target.value) {
                    let CSVLength = pmv.length;
                    let valueLength = e.target.value.length;
                    if(CSVLength > valueLength) {
                        e.target.value = pmv;
                        let difference = CSVLength - valueLength;
                        start = start + difference;
                        end = end + difference;
                    }
                    else if(CSVLength < valueLength){
                        e.target.value = pmv;
                        if(start != 0 || end != 0){
                            let difference = valueLength - CSVLength;
                            start = start - difference;
                            end = end - difference;
                        }
                        else{
                            start = start;
                            end = end;
                        }
                    }
                    else {
                        e.target.value = pmv;
                    }
                }
            
                // restore from variables...
                e.target.setSelectionRange(start, end);
            } else {
                this.preMoneyValText = pmv;
            }
        } if (type === 'slider') {
            this.preMoneyVal = this.preMoneyValText.replace(/,/g, "") / 1;
            if (this.currencyForRound != null && this.currencyForRound != this.companyCurrency) {
                let pmv = this.utility.convertToRoundCurrency(this.preMoneyVal);
                this.preMoneyValText = pmv;
            }
            else {
                let pmv = this.utility.convertToCompanyCurrency(this.preMoneyVal);
                this.preMoneyValText = pmv;
            }
        }

        let val = (this.preMoneyVal - this.preMoneyMin) / (this.preMoneyMax - this.preMoneyMin);
        let color = '';
        if ($('#preMoney').val() < this.currentVal) {
            color = '#1B4C89';
        } else {
            color = '#1B4C89';
        }

        $('#preMoney').css('background-image',
            '-webkit-gradient(linear, left top, right top, '
            + 'color-stop(' + val + ', ' + color + '), '
            + 'color-stop(' + val + ', #C5C5C5)'
            + ')'
        );

        this.postMoneyVal = this.roundSize + this.preMoneyVal;
        this.dilution = this.roundSize / this.postMoneyVal;
        this.rndIconstate = "../../assets/wizard/inprogress.svg";
    }

    // Valuation cap slider change event
    onChangeValCap(type, e) {
        this.loadJsStyles();
        if (type === 'ip') {
            let vc;
            if(e) {
                // store current positions in variables
                var start = e.target.selectionStart,
                end = e.target.selectionEnd;
            }
            let val = this.valuationCapText;
            this.valuationCap = this.valuationCapText.replace(/,/g, "") / 1;
            vc = this.utility.inputCSVformatCurrencyRound(this.currencyForRound, val);

            if(e) {
                if(e.target.value) {
                    let CSVLength = vc.length;
                    let valueLength = e.target.value.length;
                    if(CSVLength > valueLength) {
                        e.target.value = vc;
                        let difference = CSVLength - valueLength;
                        start = start + difference;
                        end = end + difference;
                    }
                    else if(CSVLength < valueLength){
                        e.target.value = vc;
                        if(start != 0 || end != 0){
                            let difference = valueLength - CSVLength;
                            start = start - difference;
                            end = end - difference;
                        }
                        else{
                            start = start;
                            end = end;
                        }
                    }
                    else {
                        e.target.value = vc;
                    }
                }
                
                // restore from variables...
                e.target.setSelectionRange(start, end);
            } else {
                this.valuationCapText = vc;
            }
        } if (type === 'slider') {
            this.valuationCap = this.valuationCapText.replace(/,/g, "") / 1;
            if (this.currencyForRound != null && this.currencyForRound != this.companyCurrency) {
                let vc = this.utility.convertToRoundCurrency(this.valuationCap);
                this.valuationCapText = vc;
            }
            else {
                let vc = this.utility.convertToCompanyCurrency(this.valuationCap);
                this.valuationCapText = vc;
            }
        }
        let val = (this.valuationCap - this.preMoneyMin) / (this.preMoneyMax - this.preMoneyMin);
        let color = '';
        if ($('#valCap').val() < this.currentVal) {
            color = '#1B4C89';
        } else {
            color = '#1B4C89';
        }

        $('#valCap').css('background-image',
            '-webkit-gradient(linear, left top, right top, '
            + 'color-stop(' + val + ', ' + color + '), '
            + 'color-stop(' + val + ', #C5C5C5)'
            + ')'
        );
        this.postMoneyVal = this.roundSize + this.valuationCap;
        this.dilution = this.roundSize / this.postMoneyVal;
        this.rndIconstate = "../../assets/wizard/inprogress.svg";
    }

    getTotalValue(value) {
        return this.utility.convertedFormatCurrency(this.currencyForRound,  value);
        // if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
        //     return this.utility.convertCurrencyFormat(value);
        // }
        // else {
        //     return this.utility.convertRoundFormat(value);
        // }
    }

    removeDataFromCache() {
        let tranchesDetailData = this.cacheService.get('tranchesDetail')
        if (tranchesDetailData) {
            this.cacheService.remove('tranchesDetail')
        }

        let cachedExistingShareHldrsData = this.cacheService.get('existingShareholdersDetail')
        if (cachedExistingShareHldrsData) {
            this.cacheService.remove('existingShareholdersDetail')
        }

        let cachedConvertibleData = this.cacheService.get('outstandingConvDetail')
        if (cachedConvertibleData) {
            this.cacheService.remove('outstandingConvDetail')
        }

        let cachedInvestorsListDetailData = this.cacheService.get('investorListDetail')
        if (cachedInvestorsListDetailData) {
            this.cacheService.remove('investorListDetail')
        }

        let cachedNewINvestorsDetailData = this.cacheService.get('newInvestorsDetail')
        if (cachedNewINvestorsDetailData) {
            this.cacheService.remove('newInvestorsDetail')
        }
    }

    discardRound() {
        this.ngxLoader.start();
        if(confirm('Are you sure you want to discard the round?')) {
            this.removeDataFromCache();
            let basicData = {
                isBasicDetailsFilled: this.basicRndDetails['isBasicDetailsFilled'],
                roundName: this.basicRndDetails['roundName'],
                roundSize: this.basicRndDetails['roundSize'],
                roundValuation: this.basicRndDetails['roundValuation'],
                amountRaised: this.basicRndDetails['amountRaised'],
                amountLeftToRaise: this.basicRndDetails['amountLeftToRaise'],
                leadInvestorName: this.basicRndDetails['leadInvestorName'],
                sharePrice: this.basicRndDetails['pricePerShare'],
                roundCurrency: this.basicRndDetails['currencyForRound'],
                isRoundNotSaved: false,
            }
            this.dataService.updateBasicData(basicData);
            this.authService.discardRound().subscribe((response) => {
                if (response['resultCode'] == 'SUCCESS') {
                    this.ngxLoader.stop();
                    this.toastr.success("Round Discarded successfully!", "Success");
                    this.router.navigate(['/landing', 'dashboard', 'rounds', 'overview']);
                }
            }, (e) => {
                this.ngxLoader.stop();
                this.toastr.error("Error: " + e.error.reason, "Error");
            })
        } else {
            this.ngxLoader.stop();
            return;
        }
    }

    loadJSstylesPrice() {
        $('[data-toggle="switch"]').bootstrapSwitch({
            onText: "Yes",
            offText: "No",
        });
        $('.alert-status-price').bootstrapSwitch('state', this.pricedRound);
        $("#prcRnd").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
            this.pricedRound = state;
            if (this.pricedRound) {
                this.postMoneyVal = this.roundSize + this.preMoneyVal;
                this.preMoneyValText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound, this.preMoneyVal);
            } if (!this.pricedRound) {
                this.postMoneyVal = this.roundSize + this.valuationCap;
                this.valuationCapText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound, this.valuationCap);
            }
            this.dilution = this.roundSize / this.postMoneyVal;
        })
        $('.alert-status-eosp').bootstrapSwitch('state', this.diluteEsop);
        $("#diluEsop").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
            this.diluteEsop = state;
        })
    }

    loadJSstylesBridge() {
        $('[data-toggle="switch"]').bootstrapSwitch({
            onText: "Yes",
            offText: "No",
        });
        // $('.alert-status-bridge').bootstrapSwitch('state', this.bridgeRound);
        // $("#prcRnd").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        //     this.bridgeRound = state;
        // })
    }

    fieldEdited($event) {
        this.isRoundNotSaved = true;
    }

    getBasicDetails() {
        this.ngxLoader.start();
        this.authService.getBasicRoundData().subscribe((res) => {
            console.log(res['exchangeRate']);
            this.basicRndDetails = res;
            this.roundName = res['roundName'];
            this.companyCurrency = res['companyCurrency'];
            this.currencyForRound = res['currencyForRound'];
            this.roundIdentifier = res['roundIdentifier'];
            this.roundDescription = res['roundDescription'];
            this.esop = res['esopPoolPercentage'];
            this.oldESOP = res['oldEsopPercentage'];
            this.postMoneyVal = res['postMoneyValuation'];
            this.dilution = res['dilutionPercentage'];
            // this.discount = res['discountToNextSeriesValuation'];
            this.issueDate = this.datepipe.transform(res['issueDate'], 'yyyy-MM-dd');
            this.numberRoundDropdownvalues = res['numberRoundDropdownvalues'];
            this.diluteEsop = res['isEsopPercentageAutoDilute'];
            this.numberOfDecimals = res['numberOfDecimals'];
            this.roundMechanisam = res['numberRound']

            //For Basic Details
            let basicData = {
                isBasicDetailsFilled: res['isBasicDetailsFilled'],
                roundName: res['roundName'],
                roundSize: res['roundSize'],
                roundValuation: res['roundValuation'],
                amountRaised: res['amountRaised'],
                amountLeftToRaise: res['amountLeftToRaise'],
                leadInvestorName: res['leadInvestorName'],
                sharePrice: res['pricePerShare'],
                roundCurrency: res['currencyForRound'],
                // isRoundNotSaved: true,
                isRoundNotSaved: this.isRoundNotSaved
            }
            this.dataService.updateBasicData(basicData);
            if (res['exchangeRate'] != null) {
                this.exchangeRate = res['exchangeRate']
            } else {
                this.exchangeRate = 1;
            }
            this.errorFlags = res['errorFlags'];
            this.dataService.updateData(this.errorFlags);
            if (res['isPricedRound'] == null) {
                this.pricedRound = true;
            }
            else {
                this.pricedRound = res['isPricedRound'];
            }
            ;
            this.preMoneyMin = <number>res['minAndMaxValuesForSliders'][0].minValue;
            this.preMoneyMax = <number>res['minAndMaxValuesForSliders'][0].maxValue;
            this.roundSizeMin = <number>res['minAndMaxValuesForSliders'][1].minValue;
            this.roundSizeMax = <number>res['minAndMaxValuesForSliders'][1].maxValue;
            this.currentVal = <number>res['minAndMaxValuesForSliders'][0].actualValue;

            if (res['roundSize'] == 0 || res['roundSize'] == null) {
                this.roundSize = this.roundSizeMin;
                this.roundSizeText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound,  this.roundSize);
                // if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
                //     this.roundSizeText = this.utility.convertToCompanyCurrency(this.roundSize);
                // }
                // else {
                //     this.roundSizeText = this.utility.convertToRoundCurrency(this.roundSize);
                // }
            } else {
                this.roundSize = res['roundSize'];
                this.roundSizeText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound,  this.roundSize);
                // if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
                //     this.roundSizeText = this.utility.convertToCompanyCurrency(this.roundSize);
                // }
                // else {
                //     this.roundSizeText = this.utility.convertToRoundCurrency(this.roundSize);
                // }
            }

            if (res['preMoneyValuation'] == 0 || res['preMoneyValuation'] == null) {
                this.preMoneyVal = this.preMoneyMin;
                this.preMoneyValText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound,  this.preMoneyVal);
                // if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
                //     this.preMoneyValText = this.utility.convertToCompanyCurrency(this.preMoneyVal);
                // }
                // else {
                //     this.preMoneyValText = this.utility.convertToRoundCurrency(this.preMoneyVal);
                // }
            } else {
                this.preMoneyVal = res['preMoneyValuation'];
                this.preMoneyValText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound,  this.preMoneyVal);
                // if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
                //     this.preMoneyValText = this.utility.convertToCompanyCurrency(this.preMoneyVal);
                // }
                // else {
                //     this.preMoneyValText = this.utility.convertToRoundCurrency(this.preMoneyVal);
                // }
            }

            if (res['valuationCap'] == 0 || res['valuationCap'] == null) {
                this.valuationCap = this.preMoneyMin;
                this.valuationCapText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound,  this.valuationCap);
                // if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
                //     this.valuationCapText = this.utility.convertToCompanyCurrency(this.valuationCap);
                // }
                // else {
                //     this.valuationCapText = this.utility.convertToRoundCurrency(this.valuationCap);
                // }
            } else {
                this.valuationCap = res['valuationCap'];
                this.valuationCapText = this.utility.inputCSVformatCurrencyRound(this.currencyForRound,  this.valuationCap);
                // if (this.currencyForRound === null || this.currencyForRound === this.companyCurrency) {
                //     this.valuationCapText = this.utility.convertToCompanyCurrency(this.valuationCap);
                // }
                // else {
                //     this.valuationCapText = this.utility.convertToRoundCurrency(this.valuationCap);
                // }
            }

            if (this.pricedRound) {
                this.postMoneyVal = this.roundSize + this.preMoneyVal;
            } if (!this.pricedRound) {
                this.postMoneyVal = this.roundSize + this.valuationCap;
            }
            this.dilution = this.roundSize / this.postMoneyVal
            let groupTotal = 0;
            this.updateSliders();
            this.loadJSstylesPrice();
            this.loadJSstylesBridge();
            this.selectedCurrency(this.currencyForRound);
            this.ngxLoader.stop();
        }, (e) => {
            if (e.error.reason === "Login Token Expired.") {
                this.cacheService.clear();
                this.router.navigate(['']);
                return;
            } else if(e.error.resultCode == 'PROFORMA_ROUND_EXISTS') {
                this.isRoundNotSaved = false;
                this.toastr.error("Error: " + e.error.reason, "Error");
                this.router.navigate(['/landing', 'dashboard', 'rounds', 'overview']);
            } else {
                this.toastr.error("Error: " + e.error.reason, "Error");
            }
        });
    }

    // getMinMax(type) {
    //     let roundSize = this.roundSize;
    //     if (type === "round") {
    //         if (this.roundSize == null) {
    //             this.isRndSizeErr = true;
    //             return;
    //         } else {
    //             this.roundSizeMin = roundSize - ((roundSize) * (20 / 100));
    //             this.roundSizeMax = roundSize * 20;

    //             if (this.pricedRound) {
    //                 if (this.preMoneyVal < this.roundSizeMin) {
    //                     this.preMoneyVal = this.roundSizeMin;
    //                     this.preMoneyValText = this.utility.convertToCompanyCurrency(this.roundSizeMin);
    //                 }
    //                 if (this.preMoneyVal > this.roundSizeMax) {
    //                     this.preMoneyVal = this.roundSizeMax;
    //                     this.preMoneyValText = this.utility.convertToCompanyCurrency(this.roundSizeMax);
    //                 }
    //             }
    //             if (!this.pricedRound) {
    //                 if (this.valuationCap < this.roundSizeMin) {
    //                     this.valuationCap = this.roundSizeMin;
    //                     this.valuationCapText = this.utility.convertToCompanyCurrency(this.roundSizeMin);
    //                 }
    //                 if (this.valuationCap > this.roundSizeMax) {
    //                     this.valuationCap = this.roundSizeMax;
    //                     this.valuationCapText = this.utility.convertToCompanyCurrency(this.roundSizeMax);
    //                 }
    //             }
    //             this.preMoneyMin = this.roundSizeMin;
    //             this.preMoneyMax = this.roundSizeMax;
    //             this.onChangeRoundSize('ip', null);
    //             this.updateSliders();
    //         }
    //     }

    //     if (type === "premoney") {
    //         if (this.preMoneyVal < this.roundSizeMin) {
    //             this.preMoneyVal = this.roundSizeMin;
    //             this.preMoneyValText = this.utility.convertToCompanyCurrency(this.roundSizeMin);
    //         }
    //         if (this.preMoneyVal > this.roundSizeMax) {
    //             this.preMoneyVal = this.roundSizeMax;
    //             this.preMoneyValText = this.utility.convertToCompanyCurrency(this.roundSizeMax);
    //         }
    //         this.onChangePreMoneyVal('ip', null);
    //     }

    //     else if (type === "valCap") {
    //         if (this.valuationCap < this.roundSizeMin) {
    //             this.valuationCap = this.roundSizeMin;
    //             this.valuationCapText = this.utility.convertToCompanyCurrency(this.roundSizeMin);
    //         }
    //         if (this.valuationCap > this.roundSizeMax) {
    //             this.valuationCap = this.roundSizeMax;
    //             this.valuationCapText = this.utility.convertToCompanyCurrency(this.roundSizeMax);
    //         }
    //         this.onChangeValCap('ip', null);
    //     }
    // }

    getTotalValueTble(value) {
        return this.utility.convertedFormatCurrency(this.currencyForRound,  value);
        // if (this.currencyForRound === this.companyCurrency || this.currencyForRound === null) {
        //     return this.utility.convertCurrencyFormat(value);
        // }
        // else {
        //     return this.utility.convertRoundFormat(value);
        // }
    }

    loadJsStyles() {
        $('input.number').keyup(function (event) {

            // skip for arrow keys
            if (event.which >= 37 && event.which <= 40) return;

            // format number
            $(this).val(function (index, value) {
                let tmpValue = value.toString().replace(/\D/g, "");
                var lastThree = tmpValue.substring(tmpValue.length - 3);
                var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
                if (otherNumbers != '')
                    lastThree = ',' + lastThree;
                var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
                return res;
            });
        });
    }

    ngOnInit() {
        this.loadJsStyles();
        this.loadJSstylesPrice();
        this.loadJSstylesBridge();
        this.getBasicDetails();
        this.currencyList = this.utility.currencyList();
    }

    onKeydownEvent(e) {
        this.utility.restrictMinus(e);
    }

    /** On change Esop **/
    onChangeesop(value) {
        let tmpVal = value.replace(/%$/, '');
        let tmp = tmpVal / 1;
        if (tmp != null && tmp <= 100) {
            this.esop = tmp / 100;
        }
        else {
            this.esop = 0;
            this.toastr.error("Percentage should be within 100", "Error");
        }
        if (this.esop === null) {
            this.isESOP = true;
            return;
        } else {
            this.isESOP = false;
        }
        this.rndIconstate = "../../assets/wizard/inprogress.svg";
    }

    // help section show
    showHelp(section) {
        if(section == this.helper){
            return;
          }
          const targetElement = document.getElementById(section);
          if(targetElement != null){
            this.helperTopPosition = targetElement.offsetTop;
          }
          
            this.helpSections = this.helperService.getHelpContent(
              "RoundTabComponent",
              section
            );
          this.helper = section;
    }

    // help section hide
    hideHelp() {
       this.helper = "";
    }

    // currency update on selection of round currency
    updateCurrency() {
        if (this.currencyForRound != "INR - ₹") {

            let rst = this.utility.calculateRoundCurrency(this.exchangeRate, this.roundSize);
            this.roundSizeText = this.utility.convertToRoundCurrency(rst);
            this.roundSize = rst;

            let pmt = this.utility.calculateRoundCurrency(this.exchangeRate, this.preMoneyVal);
            this.preMoneyValText = this.utility.convertToRoundCurrency(pmt);
            this.preMoneyVal = pmt;

            let vct = this.utility.calculateRoundCurrency(this.exchangeRate, this.valuationCap);
            this.valuationCapText = this.utility.convertToRoundCurrency(vct);
            this.valuationCap = vct;

            //to update post round valaue on change of round currency
            if (this.pricedRound) {
                this.postMoneyVal = this.roundSize + this.preMoneyVal;
            }
            if (!this.pricedRound) {
                this.postMoneyVal = this.roundSize + this.valuationCap;
            }

            // this.getMinMax('round');

        }
        else {
            this.roundSizeText = this.utility.convertToCompanyCurrency(this.roundSize);
            this.preMoneyValText = this.utility.convertToCompanyCurrency(this.preMoneyVal);
            this.valuationCapText = this.utility.convertToCompanyCurrency(this.valuationCap);
        }
    }

    // currency format update
    updateCurrencyFormat() {
        if (this.currencyForRound != "INR - ₹") {
            this.roundSizeText = this.utility.convertToRoundCurrency(this.roundSize);
            this.preMoneyValText = this.utility.convertToRoundCurrency(this.preMoneyVal);
            this.valuationCapText = this.utility.convertToRoundCurrency(this.valuationCap);
        }
        else {
            this.roundSizeText = this.utility.convertToCompanyCurrency(this.roundSize);
            this.preMoneyValText = this.utility.convertToCompanyCurrency(this.preMoneyVal);
            this.valuationCapText = this.utility.convertToCompanyCurrency(this.valuationCap);
        }
    }

    // input field olny number and comma
    onlyNumberKey(event) {
        if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
            return false;
        }
        else {
            return true;
        }
    }

    formatRoundCurrency(value){
        return this.utility.inputCSVformatCurrency1(this.currencyForRound, value);
    }

}