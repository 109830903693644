import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {GenerateDocumentRoutingModule} from './document-generator-routing.module';
import { GrantLetterComponent } from './grant-letter/grant-letter.component';
import { MatButtonModule, MatCardModule, MatInputModule, MatSelectModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { PreviewDocumentComponent } from './preview-document/preview-document.component';
import { DocumentEditorAllModule, DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';

@NgModule({
  declarations: [GrantLetterComponent, PreviewDocumentComponent],
  imports: [
    CommonModule,
    GenerateDocumentRoutingModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    DocumentEditorAllModule,
    DocumentEditorContainerModule,
    MatCardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DocumentGeneratorModule { }