import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContextVariable } from '../task-manager/models/context-variable';
import { UserContext } from '../task-manager/models/user-context';
import { CacheProviderService } from './cache-provider.service';

@Injectable()
export class DocumentAssemblerService {

  private userContext = new BehaviorSubject(new UserContext());

  userContextData = this.userContext.asObservable();

  constructor(private cacheService: CacheProviderService) { 
    if(this.cacheService.get("documentAssemblerContext")) {
      let userContext = JSON.parse(this.cacheService.get("documentAssemblerContext"));
      this.userContext.next(userContext);
    }
  }

  setUserContextVariables(data:ContextVariable) {
    let userContext = this.userContext.getValue();
    if(!userContext.data) {
      userContext.data = new Array<ContextVariable>();
    }

    this.removeIfAlreadyExist(data.name);

    userContext = this.userContext.getValue();

    userContext.data.push(data);

    this.cacheService.set("documentAssemblerContext", JSON.stringify(this.userContext.value));
    this.userContext.next(userContext);
  }

  removeIfAlreadyExist(name:string) {
    let userContext = this.userContext.getValue();
    let variables = userContext.data;

    if(!variables || variables.length == 0) {
      return;
    }

    for(let index = variables.length - 1; index >= 0; index--) {
      let variable = variables[index];
      if(variable.name == name) {
        variables.splice(index);
      }
    }
    userContext.data = variables;
    this.userContext.next(userContext);
  }
}
