<div *ngIf="showDialog" class="dialog">
    <div class="header">
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
          <label class="dialogue-title">Update {{nextGrantStatus != null ? 'Grant' : 'Plan'}} Status</label>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2">
          <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
            <span class="close">X</span>
          </button>
        </div>
      </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <form [formGroup]="statusChangeForm" novalidate>
      <div style="height:90%;">
        <div class="section-padding">
            <div class="row btm-20" *ngIf="planStateDialog != null">
              <div class="col-md-6">
                  <div>
                      <mat-label>Plan Name</mat-label>
                  </div>
                  <div>
                      <span>{{ planStateDialog.details.planName }}</span>
                  </div>
              </div>
            </div>
            <div class="row btm-20">
                <div class="col-md-6" *ngIf="planStateDialog != null">
                    <mat-label require class="required">Plan Status</mat-label>
                    <select class="form-control top-10" formControlName="esopPlanState" (change)="planState($event.target.value)">
                        <option value="null" disabled selected>Select status</option>
                        <option *ngFor="let state of planStateDialog.details.availableStates" [value]="state">
                          {{remove_(state)}}
                        </option>
                    </select>
                    <div class="alert alert-danger" *ngIf="statusChangeForm.get('esopPlanState').errors?.required && (statusChangeForm.get('esopPlanState').dirty || statusChangeForm.get('esopPlanState').touched || isSubmitted) " >
                      Plan Status is required!
                    </div>
                </div>
                <div class="col-md-6" *ngIf="nextGrantStatus != null">
                    <mat-label require class="required">Grant Status</mat-label>
                    <select class="form-control top-10" formControlName="optionHolderState" (change)="grantState($event.target.value)">
                        <option value="null" disabled selected>Select status</option>
                        <option *ngFor="let state of nextGrantStatus.availableStates" [value]="state">
                          {{remove_(state)}}
                        </option>
                    </select>
                    <div class="alert alert-danger" *ngIf="statusChangeForm.get('optionHolderState').errors?.required && (statusChangeForm.get('optionHolderState').dirty || statusChangeForm.get('optionHolderState').touched || isSubmitted) " >
                      Grant Status is required!
                    </div>
                </div>
            </div>
            <div class="row btm-20">
              <div class="col-md-9">
                <div *ngIf="!showNotes && !isGranted && !isRejected && !isOfferedWithdrawed" (click)="showNotes = true;">
                  <mat-label>Add Notes</mat-label>
                  <img class="left-10 cursor-hand" src="../../../../assets/action/addnotes.svg" alt="add Notes" />
                </div>
                <div *ngIf="showNotes && !isGranted && !isRejected && !isOfferedWithdrawed" (click)="showNotes = false;">
                  <mat-label>Remove Notes</mat-label>
                  <img class="left-10 cursor-hand" src="../../../../assets/action/removenotes.svg" alt="remove Notes" />
                </div>
                <div *ngIf="(showNotes && isGranted) || (showNotes && isRejected) || (isOfferedWithdrawed)">
                  <mat-label class="required">Note</mat-label>
                </div>
                <textarea required="(showNotes && isGranted) || (showNotes && isRejected) || (isOfferedWithdrawed)" [ngClass]="statusChangeForm.get('note').errors?.required && (statusChangeForm.get('note').dirty || statusChangeForm.get('note').touched || isSubmitted) ? 'form-control top-10 required error-box' : 'form-control top-10 required no-error-box'" *ngIf="showNotes" formControlName="note" maxlength="10000" style="width:100%" placeholder="Add Notes">
                </textarea>
                <div class="alert alert-danger" *ngIf="statusChangeForm.get('note').errors?.required && (statusChangeForm.get('note').dirty || statusChangeForm.get('note').touched || isSubmitted) " >
                  Note is required!
                </div>
              </div>

              <div class="col-md-3" *ngIf="nextGrantStatus != null && (isGranted || isOffered)">
                  <mat-label>Send Email</mat-label>
                  <div class="top-10">
                      <mat-checkbox class="cursor-hand" [disableRipple]="true" formControlName="mailToBeSent" 
                      class="checkbox"></mat-checkbox>
                  </div>
              </div>
          </div>
        </div>
        <div class="section-padding bottom-padding">
            <div class="row btm-20">
                <div class="col-xs-12 col-sm-12 col-md-12">
                  <button mat-raised-button id="save" color="success" [disableRipple]="true" ngbTooltip="Save"
                    class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="changeStatus()">
                    Save
                  </button>
                  <button mat-raised-button id="cancel" color="warn" [disableRipple]="true" ngbTooltip="Cancel"
                    class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                    Cancel
                  </button>
                </div>
            </div>
        </div>
    </div>
    </form>
</div>