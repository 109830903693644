<div class="row top-20 btm-20 faq-container graybg" style="margin: 0.5em;">
  <div class="whiteboxwrap">

  <div class="col-md-9">
    
    <mat-card>
      <mat-card-title>
        <div class="row">
          <div class="col-md-9">
            FAQs
          </div>
          <mat-card-content class="col-md-3" *ngIf="isUserAdmin">
            <div class="faq-item">
              <mat-label class="mat-label-value">Show FAQ to Employee</mat-label>
              <mat-button-toggle-group class="top-10 example-margin" id="enableFaqToggle"
                [(ngModel)]="enableFaqToggle" (change)="changed()">
                <mat-button-toggle [value]="true">
                  Yes</mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </mat-card-content>
        </div>
      </mat-card-title>
        
      <mat-card style="margin: 2em;" class="faq-card">
        <mat-card-title>
          Employee Stock Option Plans
          <span  style="float: right;" *ngIf="esopFaqs && esopFaqs.length">
            <button mat-icon-button ngbTooltip="Open all Sections" (click)="openEsopAccordion()"><mat-icon><i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i></mat-icon></button>
            <button mat-icon-button ngbTooltip="Close all Sections" (click)="closeEsopAccordions()"><mat-icon><i class="fa fa-minus-circle fa-lg" aria-hidden="true"></i></mat-icon></button>
          </span>
        </mat-card-title>
        <mat-card-content>
          <div class="row top-20 btm-20">
            <div class="row left-20 btm-20 margin-right-20">
              <p class="btm-20">A peek into the concept of ESOPs, their taxation and the common questions which
                arise in
                your mind
              </p>
              <br>
              <p>The idea of earning a share in the profit has always been romanticized, whether you are in
                employment or
                in business. Rewarding employees through employee stock options plans (ESOPs), restricted stock
                units or
                stock appreciation rights (SARs) is one such way in which corporates share their profits with crème
                de la
                crème or with the entire workforce. </p>
              <br>
              <p>Profit sharing through ESOPs is an indirect way of sharing profits, i.e., gains arise from the
                listing of
                shares on the bourses and not directly from the business profits of the employer. </p>
              <br>
              <p>Typically, ESOPs are issued at a time when the company is at a nascent stage or before going for a
                public
                issue so that employees can reap the benefits of listing when the company actually lists on the
                bourses.
              </p>
              <br>
            </div>
          </div>
          <mat-accordion #esopAccordion="matAccordion" multi="true">
            <mat-expansion-panel *ngFor="let faq of esopFaqs; let i = index">
              <mat-expansion-panel-header [ngClass]="esopFaqs[i].isEdit ? 'hidden' : ''">
                <mat-panel-title>{{faq.question}}</mat-panel-title> 
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <input placeholder="Type Question" [ngClass]="!esopFaqs[i].isEdit ? 'hidden' : ''" type="text" [(ngModel)]="esopFaqs[i].question" class="editable" />
              <div [ngClass]="esopFaqs[i].isEdit ? 'hidden' : ''" class="row top-20 btm-20" [innerHTML]="faq.answer">
              </div> 
              <textarea placeholder="Type Answer" rows="4" cols="50" [ngClass]="!esopFaqs[i].isEdit ? 'hidden' : ''" type="text" [(ngModel)]="esopFaqs[i].answer" class="editable"></textarea>
              <img  *ngIf="isUserAdmin" [ngClass]="esopFaqs[i].isEdit ? 'hidden edit-icon' : 'edit-icon'" (click)="editFaq(i)" src="../../assets/edit.svg" />
              <img *ngIf="isUserAdmin" class="delete-icon" src="../../../assets/trash-bin.svg" ngbTooltip="Delete" (click)="deleteFaq(i)"/>
            </mat-expansion-panel>

            <div class="bottom-padding">
              <div class="row btm-20">
                  <div class="col-xs-12 col-sm-12 col-md-12">
                    <button  *ngIf="editMode || editToggle" mat-raised-button color="success" [disableRipple]="true" ngbTooltip="Save"
                      class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="setFaq()">
                      Save
                    </button>
                    <button  *ngIf="editMode || editToggle" mat-raised-button color="warn" [disableRipple]="true" ngbTooltip="Cancel"
                      class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                      Cancel
                    </button>
                    <button *ngIf="isUserAdmin" mat-raised-button color="success" [disableRipple]="true" class="float-right margin-left-lg add-faq"
                    (click)="addFaq()" ngbTooltip="Add FAQ">Add FAQ</button>
                  </div>
              </div>
            </div>

          </mat-accordion>

        </mat-card-content>
      </mat-card>
      
    </mat-card>
  </div>
  </div>
</div>