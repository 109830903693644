import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from "../../authentication.service";
import { UtilityService } from "../../services/utilities.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-new-group',
  templateUrl: './create-new-group.component.html',
  styleUrls: ['./create-new-group.component.scss']
})
export class CreateNewGroupComponent implements OnInit {
  @Input() showdigNewGroup: boolean;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();
  newGroupObj = {
    groupName: "",
    description: ""
  };
  shareHolderType;
  groupNames;
  public groupdNameErr:boolean = false;
  public disableripple: boolean = true;
  public newGroup:boolean = false;

  constructor(
      private authService: AuthenticationService,
      private ngxLoader: NgxUiLoaderService,
      private toastr: ToastrService 
    ) {}

  // Create new Group
  createNewGroup() {
    if (this.newGroupObj.groupName == '') {
      return this.groupdNameErr = true;
    }
    else {
      this.ngxLoader.start();
      this.groupdNameErr = false;
      this.authService.saveNewGroup(this.newGroupObj).subscribe(response => {
        if (response) {
          this.ngxLoader.stop();
          this.toastr.success("Success: " + response['groupName'] + "New Group created successfully");
          this.showdigNewGroup = false;
          this.newGroup = true;
          this.visibleChange.emit({showMdl: this.showdigNewGroup, newGroup:this.newGroup});
        }
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error: " + e.error.reason);
      });
    }
  }

  close() {
    this.showdigNewGroup = false;
    this.visibleChange.emit({showMdl: this.showdigNewGroup, newGroup:this.newGroup});
  }

  ngOnInit() {
  }

}
