import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilityService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-edit-shares-willingness',
  templateUrl: './edit-shares-willingness.component.html',
  styleUrls: ['./edit-shares-willingness.component.scss']
})
export class EditSharesWillingnessComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() shareDistinctiveSets;
  @Input() willingness;
  @Input() eligibility;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private utility: UtilityService,
  ) { }

  ngOnInit() {
    // this.shareDistinctiveSets.forEach(element => {
    //   element['initialNumberofShares'] = element['totalNumberOfShares'];
    // });
    this.updateWillingnessTotal();
  }

  onSharesChange(index, value) {
    value = value.replace(/,/g, "") / 1;
    if(value > this.shareDistinctiveSets[index].initialNumberofShares) {
      this.toastr.error('Entered value is greater than the number of shares present in the set!');
      return;
    } else {
      this.shareDistinctiveSets[index].totalNumberOfShares = value;
    } 
    this.updateWillingnessTotal();
  }

  updateWillingnessTotal() {
    let total = 0;
    this.shareDistinctiveSets.forEach(element => {
      total = total + element['totalNumberOfShares'];
    });
    this.willingness = total;
    if(this.willingness > this.eligibility) {
      this.toastr.error('Willingness cannot exceed the pro-rata Eligibility');
    }
  }

  save() {
    if(this.willingness > this.eligibility) {
      this.toastr.error('Willingness cannot exceed the pro-rata Eligibility');
      return;
    } else {
      // this.shareDistinctiveSets.forEach(element => {
      //   delete element['initialNumberofShares'];
      // });
      this.showDialog = false;
      this.visibleChange.emit({
        'willingness': this.willingness,
        'shareDistSets': this.shareDistinctiveSets
      })
    }
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit(false);
  }

  onKeydownEvent(e) {
    this.utility.restrictMinus(e);
  }

  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
  }

}
