<div *ngIf="exerciseDialog.show" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">{{ exerciseDialog.isApprover ? "Exercise ESOP" : "Request to Exercise ESOP" }}</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button ngbTooltip="Close" type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container-fluid">
    <div class="row top-20">
      <div class="col-xs-6 col-sm-6 col-md-6 to">
        <mat-label>Employee Name</mat-label><br>
        <mat-label class="matLabelCls top-10">{{employeesList.optionHolderName}}</mat-label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label>ESOP Plan</mat-label><br>
        <mat-label class="matLabelCls top-10">{{employeesList.esopPlanName}}</mat-label>
      </div>
    </div>
    <div class="row top-20">
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label>Exercise Price</mat-label><br>
        <mat-label class="matLabelCls top-10">{{employeesList.exercisePrice}}</mat-label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label class="required">Exercise Date</mat-label>
        <div class="input-eyePlaceholder">
            <input [disabled]="exerciseDialog.isApprover"  matInput [matDatepicker]="dp" class="form-control top-10" [(ngModel)]="exercise.dateOfExercise"
              placeholder="Event Completion Date" [min]="this.rowData.grantDate" [max]="maxDateValidation" #dateExerErr="ngModel" placeholder="DD-MMM-YYYY" title="" required />
              <mat-datepicker-toggle matSuffix [for]="dp" >
                <mat-icon matDatepickerToggleIcon></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </div>
        <div *ngIf="dateExerErr.errors">
          <div class="alert alert-danger" *ngIf="(dateExerErr.dirty || dateExerErr.touched) && !exerciseDate">
            Exercise Date is required!
          </div>
          <div class="alert alert-danger" *ngIf="dateExerErr.errors?.required && exerciseDate">
            Exercise Date is required!
          </div>
        </div>
      </div>
    </div>

    <div class="row top-20">
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label>Number of Options That Can be Exercised</mat-label><br>
        <mat-label class="matLabelCls top-20">{{formatCurrency(employeesList.numberOfShares)}}</mat-label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label class="required">Number of Options to Exercise</mat-label>
        <input [disabled]="exerciseDialog.isApprover" class="form-control top-10 number text-align-right" type="text"
          placeholder="Number of Options to Exercise" [(ngModel)]="exercise.numberOfShares" #noOfShrErr="ngModel"
          title="" required (keydown)=onKeydownEvent($event) />
        <div *ngIf="noOfShrErr.errors">
          <div class="alert alert-danger"
            *ngIf="(noOfShrErr.dirty || noOfShrErr.touched) && !numberOfShares">
            Number of Options is required!
          </div>
          <div class="alert alert-danger" *ngIf="noOfShrErr.errors?.required && numberOfShares">
            Number of Options is required!
          </div>
        </div>
      </div>
    </div>
    <div class="row top-20">
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label>Cashed Out</mat-label>
        <div class="top-10">
          <input id="cash-exercise" class="top-10" type="checkbox" data-toggle="switch" data-inverse="false" [(ngModel)]="exercise.isCashExercise"  data-off-color="default" title="" />
        </div>
      </div>
    </div>

    <div class="row top-20">
      <div class="col-md-12 col-sm-12 col-lg-12">
        <div *ngIf="!isShowNote && !exerciseDialog.isApprover" (click)="addNotes(true)">
            <span>
              <mat-label>Add Notes</mat-label><img class="left-10 cursor-hand" src="../../../../assets/action/addnotes.svg" alt="add Notes" />
            </span>
        </div>
        <div *ngIf="isShowNote && !exerciseDialog.isApprover" (click)="addNotes(false)">
            <span>
              <mat-label>Remove Notes</mat-label><img class="left-10 cursor-hand" src="../../../../assets/action/removenotes.svg" alt="remove Notes" />
            </span>
        </div>
          <textarea [disabled]="exerciseDialog.isApprover" class="form-control top-10" *ngIf="isShowNote" [(ngModel)]="exercise.note" placeholder="Add Notes"></textarea>
      </div>
    </div>

    <div class="row float-right top-20">
      <div class="col-xs-12 col-sm-12 col-md-12 btm-20">
        <button mat-raised-button id="cancel" color="warn" class="margin-right-sm" [disableRipple]="true" ngbTooltip="Cancel"
          (click)="close()">Cancel</button>
        <button mat-raised-button color="success" [disableRipple]="true" ngbTooltip='{{ exerciseDialog.isApprover ? "Approve request" : "Submit request"}}'
          (click)="saveExercise()">{{ exerciseDialog.isApprover ? "Approve" : "Submit"}}</button>
      </div>
    </div>
  </div>
</div>
