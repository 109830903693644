import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-share-certificate-email',
  templateUrl: './share-certificate-email.component.html',
  styleUrls: ['./share-certificate-email.component.scss']
})
export class ShareCertificateEmailComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() shareCertificateDialog;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  certificateForm: FormGroup;
  isSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.certificateForm = this.fb.group({
      email: [this.shareCertificateDialog.email, [Validators.required, Validators.email]]
    });
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit(false);
  }

  generate() {
    this.isSubmitted = true;
    if(this.certificateForm.valid){
      this.isSubmitted = false;
      this.shareCertificateDialog.email = this.certificateForm.value.email;
      this.visibleChange.emit(true);
    }
    else{
      this.toastr.error("Please fill mandatory fields.", "Failure!");
    }
  }

}
