import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authentication.service';
import { ViewDocsDialogComponent } from 'src/app/settings/site-settings/view-docs-dialog/view-docs-dialog.component';

interface ShareModelUserMapping {
  id ?: number,
  userId : number
  userEmail : string,
  modelId ?: number,
  modelName ?: string,
  permission ?: string,
  typeOfModel ?: string
}

interface ShareModel {
  eligibleUsers : Array<ShareModelUserMapping>;
  typeOfModel : string,
  modelName : string,
  existingAccess : Array<ShareModelUserMapping>,
  modifiedAccess ?: Array<ShareModelUserMapping>
}

@Component({
  selector: 'app-share-details-popup',
  templateUrl: './share-details-popup.component.html',
  styleUrls: ['./share-details-popup.component.scss']
})
export class ShareDetailsPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShareDetailsPopupComponent>,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private readonly ngxLoader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA)
  public data:{ typeOfModel: string, modelName : string, modelId : number}){}

  public filteredOtherUsers : Observable<string[]>;
  myControl = new FormControl();
  modifiedUserList : Array<ShareModelUserMapping> = [];
  deletedAccessList : Array<ShareModelUserMapping> = [];

  selectedUser : ShareModelUserMapping;
  selectedUserEmail = '';

  public shareModelDetails : ShareModel;
  currentEditedUser : number;

  ngOnInit() {
    this.ngxLoader.start();
    this.authService.getShareModelDetails(this.data.typeOfModel, this.data.modelName).subscribe(
      (res: ShareModel) => {
        this.shareModelDetails = res;
        this.modifiedUserList = JSON.parse(
          JSON.stringify(this.shareModelDetails.existingAccess || [])
        );
        this.ngxLoader.stop();
      },
      (e) => {
        if (e.error['reason'])
          this.toastr.error(e.error.reason, "Failure!");
        else
          this.toastr.error("Error while saving share model", "Failure");
        this.ngxLoader.stop();
      }
    );

    this.filteredOtherUsers = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  close(){
    this.dialogRef.close();
  }

  onUserSelectionChanged(userEmail){
    this.selectedUser = this.shareModelDetails.eligibleUsers.filter(user => {
      return user.userEmail == userEmail
    })[0];
    this.selectedUserEmail = this.selectedUser.userEmail;
    this.addSelectedUsertoList();
  }

  private filter(value: string=''): string[] {
    const filterValue = value.toLowerCase();
    if(value == '')
      return [];
    var usersToShare = this.shareModelDetails.eligibleUsers.filter(option => !this.modifiedUserList.map(value => value.userEmail).includes(option.toString()));
    return usersToShare.filter(option => option.userEmail.toString().includes(filterValue)).map(shareModel => shareModel.userEmail);
  }

  addSelectedUsertoList(){
    //validate email
    if(this.modifiedUserList.map(user => user.userEmail).includes(this.selectedUserEmail)){
      this.toastr.warning("User already given access", "Warning!");
      return;
    }
    this.selectedUser.modelName = this.data.modelName;
    this.selectedUser.permission = "SHARE_MODEL_READ";
    this.selectedUser.typeOfModel = this.data.typeOfModel;
    this.selectedUser.modelId = this.data.modelId;
    
    this.modifiedUserList.push(this.selectedUser);
    this.selectedUserEmail = '';
  }

  setAccess(i, access){
    this.modifiedUserList[i].permission = access;
    this.currentEditedUser = null;
  }

  editAccess(i) {
    this.currentEditedUser = i;
  }

  deleteAccess(i) {
    var deletedAccess = this.modifiedUserList[i];
    this.modifiedUserList.splice(i, 1);
    deletedAccess.permission = null;
    this.deletedAccessList.push(deletedAccess);
    var eligibleUser: ShareModelUserMapping = {
      userId : deletedAccess.userId,
      userEmail : deletedAccess.userEmail
    }
    if(!this.shareModelDetails.eligibleUsers.map(user => user.userEmail).includes(eligibleUser.userEmail))
      this.shareModelDetails.eligibleUsers.push(eligibleUser);
  }

  showAccess(modifiedUser: ShareModelUserMapping){
    if(modifiedUser.permission == "SHARE_MODEL_READ")
      return "Read";
    if(modifiedUser.permission == "SHARE_MODEL_EDIT")
      return "Edit";
    return "Owner";
  }

  giveAccess() {
    this.ngxLoader.start();
    this.shareModelDetails.modifiedAccess = this.modifiedUserList;
    this.deletedAccessList.forEach(e => this.shareModelDetails.modifiedAccess.push(e));

    if(this.shareModelDetails.modifiedAccess.length == 0){
      this.toastr.error("No user access to save", "Failure!");
      this.ngxLoader.stop();
      return;
    }

    this.authService.saveShareModelDetails(this.shareModelDetails).subscribe(
      (res: ShareModel) => {
        this.shareModelDetails = res;
        this.modifiedUserList = JSON.parse(
          JSON.stringify(this.shareModelDetails.existingAccess || [])
        );
        this.deletedAccessList = [];
        this.ngxLoader.stop();
        this.toastr.success("Users Accesses saved Successfully", "Success");
      },
      (e) => {
        if (e.error['reason'])
          this.toastr.error(e.error.reason, "Failure!");
        else
          this.toastr.error("Error while saving share model", "Failure");
        this.ngxLoader.stop();
      }
    );

  }

}
