import { Component, OnInit } from '@angular/core';
import { CompanyDetailsModel } from './company-details.model';
import { FounderDetailsModel } from './founder-details.model';
import { DirectorDetailsModel } from './director-details.model';
import { AuthenticationService } from '../../../../src/app/authentication.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { UtilityService } from '../../services/utilities.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/new-round-notify.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from '../../services/cache-provider.service';
import { filter } from 'rxjs/operators';
import { NavbarService } from 'src/app/services/navbar-reload';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDeleteComponent } from "src/app/confirm-delete/confirm-delete.component";



@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})

export class CompanyDetailsComponent implements OnInit {

  public shouldShowDialog: boolean;
  public areAllDirectorsSelected = false;
  public areAllFoundersSelected = false;

  public helper: string = "";
  public helpSections;
  public helperTopPosition = null;

  public model: CompanyDetailsModel = new CompanyDetailsModel();
  public directorIndex: number;
  public currentEditableFounderRow = 0;
  public currentEditableDirectorRow = 0;
  public errorFlags;
  public isRegistered: boolean = false;
  companyExists: boolean = false;
  public companyNameValid: boolean = false;
  public cinNoValid: boolean = false;
  public companyTypeValid: boolean = false;
  public incorporateDtValid: boolean = false;
  public officeAddressValid: boolean = false;
  public emailValid: boolean = false;
  public businessInvalid: boolean = false;
  public industryInvalid: boolean = false;
  public nicValid: boolean = false;
  public companyWebsitevalid:boolean = false;
  public companyCurrencyValid: boolean = false;

  public isValid: boolean = false;
  public industries: any;
  public businessList: any;
  public typeofCompanies: any = ['Private Limited Company', 'Public Limited Company', 'Unlimited Company', 'Partnership', 'Limited Liability Partnership'];
  public countryList;
  public selectedNationality = "India";
  public fName:boolean = false;
  public fEmail:boolean = false;
  public fNationality:boolean = false;
  registerNewCompany: boolean = false;

  public dName:boolean = false;
  public dDin:boolean = false;
  public dType:boolean = false;
  public dEmail:boolean = false;
  public dNationality:boolean = false;

  public shouldLogoShowDialog = false;
  public profilePicture = null;
  public croppedImage = null;
  public fileName: any;
  public companyLogoInvalid: boolean = false;
  currencyList: any;
  
  constructor(
    private utilityService: UtilityService,
    private helperService: HelperService,
    private router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    public datepipe: DatePipe,
    private cacheService: CacheProviderService,
    private ngxLoader: NgxUiLoaderService,
    private navbarService: NavbarService,
    private readonly dialog: MatDialog
  ) { 
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
        localStorage.setItem('navigationFlag', 'companyDetails');
        // this.previousUrl = event.url;
    });
  }

  ngOnInit() {
    this.shouldShowDialog = false;
    this.currencyList = this.utilityService.currencyList();
    this.initializeDetails();
    this.fetchCompanyDetails();
    const { getCode, getNames } = require("country-list");
    this.countryList = getNames();
    let errorStatus = {
      adminProfileTab: "COMPLETED",
      companyDetailsTab: "INITIAL",
      inCorporationTab: "INITIAL"
    }
    this.errorFlags = errorStatus;
    this.dataService.updateOnboarding(this.errorFlags);
  }

  initializeDetails() {
    this.model.nameOfTheCompany = localStorage.getItem('companyName');
    this.model.cinNumber = "";
    this.model.typeOfTheCompany = "";
    this.model.dateOfIncorporation = '';
    this.model.corporateOfficeAddress = "";
    this.model.companyEmailId = "";
    this.model.companyCurrency = "INR - ₹";
    this.model.business = [];
    this.model.industry = [];
    this.model.nic = "";
    this.model.dippCertificateNumber = "";
    this.model.doingBusinessAs = "";
    this.model.founders = [];
    this.model.directors = [];
    this.model.companyLogo = "";
    this.model.companyLogoPath = "";
    this.model.companyWebsite = "";
    this.model.subscribersOfMemorandum = "";
  }

  fetchCompanyDetails() {
    this.ngxLoader.start();
    if(this.activatedRoute.snapshot.paramMap.get('newComp') == 'true') {
        this.registerNewCompany = true;
    }
    this.authService.getCompanyDetails(this.registerNewCompany).subscribe(
      (res: any) => {
        if (res) {
          this.model = res;
          console.log("Basic details", res); 
          if(this.model['cinNumber']) {
            this.companyExists = true;
          }
          this.businessList = this.model['categoryDropdownValues'];
          this.industries = this.model['industryDropdownValues'];
          if(this.model['companyLogoPath'] == null){
            this.model['companyLogoPath'] = "";
          } 
          if(this.model['subscribersOfMemorandum'] == null){
            this.model['subscribersOfMemorandum'] = "";
          }
          if(this.model['companyWebsite'] == null){
            this.model['companyWebsite'] = "";
          }
          if(this.model['id'] == null){
            this.model['id'] = "";
          }
          if(this.model['dippCertificateNumber'] == null){
            this.model['dippCertificateNumber'] = "";
          }
          if(this.model['doingBusinessAs'] == null){
              this.model['doingBusinessAs'] = "";
          }
          
          this.model.directors.forEach(element => {
            if(element['fatherName'] == null){
              element['fatherName'] = "";
            } 
            if(element['motherName'] == null){
              element['motherName'] = "";
            } 
            if(element['spouseName'] == null){
              element['spouseName'] = "";
            } 
            if(element['nominationBy'] == null){
              element['nominationBy'] = "";
            } 
            if(element['gender'] == null){
              element['gender'] = "";
            }
            if(element['occupation'] == null){
              element['occupation'] = "";
            }
            if(element['countryOfResidence'] == null){
              element['countryOfResidence'] = "";
            }
          });
          if (this.model.typeOfTheCompany == null) {
            this.model.typeOfTheCompany = '';
          }
          if (this.model.nameOfTheCompany == null) {
            this.model.nameOfTheCompany = localStorage.getItem('companyName');
          }
          if (this.model.companyCurrency == null) {
            this.model.companyCurrency = "INR - ₹";
          }
          if (this.model.typeOfTheCompany == null || this.model.typeOfTheCompany === "" ) {
            this.model.typeOfTheCompany = this.typeofCompanies[0];
          }
          if (this.model.dateOfIncorporation != null || this.model.dateOfIncorporation != "") {
            this.model.dateOfIncorporation = this.datepipe.transform(this.model.dateOfIncorporation, 'yyyy-MM-dd');
          }
          this.model.directors.forEach(a => {
            a.dateOfBirth = this.datepipe.transform(a.dateOfBirth, 'yyyy-MM-dd');
            a.dateOfAppointement = this.datepipe.transform(a.dateOfAppointement, 'yyyy-MM-dd');
            a.dateOfBoardResoluitonForAppointement = this.datepipe.transform(a.dateOfBoardResoluitonForAppointement, 'yyyy-MM-dd');
          });
          this.model.founders.forEach(a => {
            a.dateOfBirth = this.datepipe.transform(a.dateOfBirth, 'yyyy-MM-dd');
          });
          if (this.model.directors.length > 0 || this.model.founders.length > 0) {
            this.cacheService.set("CompanyDetails", res);
          }


          //For Stepper Intialization
          let errorStatus = {
            adminProfileTab: "COMPLETED",
            companyDetailsTab: "INPROGRESS",
            inCorporationTab: "INITIAL",
          }
          this.errorFlags = errorStatus;
          this.dataService.updateOnboarding(this.errorFlags);
          this.cacheService.set("AdminProfile", JSON.stringify(res));
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      },
      (e) => {
        this.initializeDetails();
        if (e.error.reason === "Login Token Expired.") {
          this.ngxLoader.stop();
          this.cacheService.clear();
          this.router.navigate(['']);
          return;
        } else {
          this.ngxLoader.stop();
          this.toastr.error(e.error.message, "Failure!");
        }
      }
    );
  }

  // Founders
  addFounder() {
    let length = this.model.founders.length;
    if (length > 0) {
      if (this.model.founders[length - 1].fullName === "" || this.model.founders[length - 1].emailId === "" 
      || this.model.founders[length - 1].nationality === "") {
        this.toastr.error("Please fill previous row details", "Error!");
        return;
      }
      if (this.model.founders[length - 1].designation === "" || this.model.founders[length - 1].dateOfBirth === "") {
        this.toastr.warning("Please fill preious founder profile details", "Warning!");
      }
    }
    let newFounder = new FounderDetailsModel();
    newFounder.id = 0;
    newFounder.emailId = '';
    newFounder.fullName = '';
    newFounder.designation = '';
    newFounder.contactNumber = '';
    newFounder.address = '';
    newFounder.dateOfBirth = '';
    newFounder.nationality = this.selectedNationality;
    newFounder.linkedInProfile = '';
    newFounder.twitterHandle = '';
    newFounder.isSelected = false;
    newFounder.shouldShowDialog = false;
    newFounder.isEdit = true;
    this.model.founders.push(newFounder);
    this.updateFounderSelection();
    this.currentEditableFounderRow = this.model.founders.length - 1;  
    this.editFounderRow(this.currentEditableFounderRow);
  }

  deleteIndividualFounder(index) {
      // this.helperService.useCase="Delete";
      const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
        width: "400px",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result === "confirm") {
          this.ngxLoader.start();
    this.model.founders.splice(index, 1);
    this.updateFounderSelection();
    this.fName = false;
    this.fEmail = false;
    this.fNationality = false;
    this.ngxLoader.stop();

      }
    });
    
  }

  editFounderRow(rowIndex) {
    this.disableFoundersInputs();
    this.model.founders[rowIndex].isEdit = true;
  }

  saveFounderRow(rowIndex) {
    if (this.model.founders[rowIndex].fullName === "" || this.model.founders[rowIndex].fullName == null ||
        this.model.founders[rowIndex].emailId === "" || !this.validateEmail(this.model.founders[rowIndex].emailId) || this.model.founders[rowIndex].emailId == null ||
        this.model.founders[rowIndex].nationality === "" || this.model.founders[rowIndex].nationality == null) {
      if(this.model.founders[rowIndex].fullName === "" || this.model.founders[rowIndex].fullName == null){
        this.fName = true;
      }
      if(this.model.founders[rowIndex].emailId === "" || this.model.founders[rowIndex].emailId == null){
        this.fEmail = true;
      }
      if(this.model.founders[rowIndex].nationality === "" || this.model.founders[rowIndex].nationality == null){
        this.fNationality = true;
      }
      return;
    }
    else{
      this.model.founders[rowIndex].isEdit = false;
      this.fName = false;
      this.fEmail = false;
      this.fNationality = false;
    } 
  }

  selectFounder() {
    this.updateFounderSelection();
  }

  selectAllFounders() {
    if (this.areAllFoundersSelected) {
      this.model.founders.forEach(founder => {
        founder.isSelected = true;
      });
    } else {
      this.model.founders.forEach(founder => {
        founder.isSelected = false;
      });
    }
    this.updateFounderSelection();
  }

  updateFounderSelection() {
    let found = this.model.founders.find(founder => founder.isSelected == false);
    if (found || this.model.founders.length == 0) {
      this.areAllFoundersSelected = false;
    } else {
      this.areAllFoundersSelected = true;
    }
  }

  deleteAllSelectedFounders() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    if (this.areAllFoundersSelected) {
      this.model.founders = [];
    }
    else {
      let newArray = this.model.founders.filter(director => director.isSelected == false);
      this.model.founders = newArray;
    }
    this.updateFounderSelection();
    this.ngxLoader.stop();
       }
    });
  }

  openFounderDetailsPopup(index) {
    this.model.founders[index].shouldShowDialog = true;
  }

  onCloseFounderDetails() {
    this.model.founders.forEach(e => e.shouldShowDialog = false);
  }

// Directors
  addDirector() {
    let length = this.model.directors.length;
    if (length > 0) {
      if (this.model.directors[length - 1].fullName === "" || this.model.directors[length - 1].din === "" || 
          this.model.directors[length - 1].typeOfDirector === "" || this.model.directors[length - 1].emailId === "" ||
          this.model.directors[length - 1].nationality === "" ) {
        this.toastr.error("Please fill previous row details", "Error!");
        return;
      }
      if (this.model.directors[length - 1].dateOfBoardResoluitonForAppointement === "" || this.model.directors[length - 1].dateOfAppointement === "") {
        this.toastr.warning("Please fill previous director profile details", "Warning!");
      }
    }
    let newDirector = new DirectorDetailsModel();
    newDirector.id = 0;
    newDirector.fullName = '';
    newDirector.typeOfDirector = '';
    newDirector.din = '';
    newDirector.emailId = '';
    newDirector.address = '';
    newDirector.contactNumber = '';
    newDirector.dateOfBirth = '';
    newDirector.nationality = this.selectedNationality;
    newDirector.dateOfAppointement = ''
    newDirector.dateOfBoardResoluitonForAppointement = '';
    newDirector.isSelected = false;
    newDirector.shouldShowDialog = false;
    newDirector.isEdit = true;
    this.model.directors.push(newDirector);
    this.updateSelection();
    this.currentEditableDirectorRow = this.model.directors.length - 1;
    this.editDirectorRow(this.currentEditableDirectorRow);
  }

  editDirectorRow(rowIndex) {
    this.disableDirectorsInputs();
    this.model.directors[rowIndex].isEdit = true;
  }

  saveDirectorRow(rowIndex) {
    if (this.model.directors[rowIndex].fullName === "" || this.model.directors[rowIndex].fullName == null ||
        this.model.directors[rowIndex].din === "" || this.model.directors[rowIndex].din == null ||
        this.model.directors[rowIndex].nationality === "" || this.model.directors[rowIndex].nationality == null ||
        this.model.directors[rowIndex].typeOfDirector === "" || this.model.directors[rowIndex].typeOfDirector == null ||
        this.model.directors[rowIndex].emailId === "" || this.model.directors[rowIndex].emailId == null  || 
        !this.validateEmail(this.model.directors[rowIndex].emailId)) {
      if(this.model.directors[rowIndex].fullName === "" || this.model.directors[rowIndex].fullName == null){
        this.dName = true;
      }
      if(this.model.directors[rowIndex].din === "" || this.model.directors[rowIndex].din == null){
        this.dDin = true;
      }
      if(this.model.directors[rowIndex].nationality === "" || this.model.directors[rowIndex].nationality == null){
        this.dNationality = true;
      }
      if(this.model.directors[rowIndex].typeOfDirector === "" || this.model.directors[rowIndex].typeOfDirector == null){
        this.dType = true;
      }
      if(this.model.directors[rowIndex].emailId === "" || this.model.directors[rowIndex].emailId == null || !this.validateEmail(this.model.directors[rowIndex].emailId)){
        this.dEmail = true;
      }
      return;
    }
    else{
      this.model.directors[rowIndex].isEdit = false;
      this.dName = false;
      this.dDin = false;
      this.dNationality = false;
      this.dType = false;
      this.dEmail = false;
    }
  }
  
  deleteIndividualDirector(index) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    this.model.directors.splice(index, 1);
    this.updateSelection();
    this.dName = false;
    this.dDin = false;
    this.dNationality = false;
    this.dType = false;
    this.dEmail = false;
    this.ngxLoader.stop();

  }
});

  }

  selectAllDirectors() {
    if (this.areAllDirectorsSelected) {
      this.model.directors.forEach(director => {
        director.isSelected = true;
      });
    } else {
      this.model.directors.forEach(director => {
        director.isSelected = false;
      });
    }
    this.updateSelection();
  }

  selectDirector() {
    this.updateSelection();
  }

  updateSelection() {
    let found = this.model.directors.find(director => director.isSelected == false);
    if (found || this.model.directors.length == 0) {
      this.areAllDirectorsSelected = false;
    } else {
      this.areAllDirectorsSelected = true;
    }
  }

  deleteAllSelected() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    if (this.areAllDirectorsSelected) {
      this.model.directors = [];
    }
    else {
      let newArray = this.model.directors.filter(director => director.isSelected == false);
      this.model.directors = newArray;
    }
    this.updateSelection();
    this.ngxLoader.stop();

      }
    });
  }

  openDirectorDetailsPopup(index) {
    this.model.directors[index].shouldShowDialog = true;
  }

  onCloseDirectorDetails() {
    this.model.directors.forEach(e => e.shouldShowDialog = false);
  }

  checkValidation() {
    this.utilityService.formValidation(); 
    if (this.model.nameOfTheCompany === "" || this.model.nameOfTheCompany == null 
      || this.model.cinNumber === "" || this.model.companyCurrency === null || this.model.cinNumber == null
      || this.model.typeOfTheCompany === null || this.model.typeOfTheCompany === ""
      || this.model.dateOfIncorporation === "" || this.model.dateOfIncorporation == null 
      || this.model.corporateOfficeAddress === "" || this.model.corporateOfficeAddress == null
      || (this.model.companyEmailId === "" || this.model.companyEmailId == null || (this.model.companyEmailId && !this.validateEmail(this.model.companyEmailId))) ){

      if (this.model.nameOfTheCompany === "" || this.model.nameOfTheCompany == null) {
        this.companyNameValid = true;
      }
      if (this.model.cinNumber === "" || this.model.cinNumber == null) {
        this.cinNoValid = true;
      }
      if (this.model.typeOfTheCompany === "" || this.model.typeOfTheCompany == null) {
        this.companyTypeValid = true;
      }
      if (this.model.dateOfIncorporation === "" || this.model.dateOfIncorporation == null) {
        this.incorporateDtValid = true;
      }
      if (this.model.corporateOfficeAddress === "" || this.model.corporateOfficeAddress == null) {
        this.officeAddressValid = true;
      }
      if (this.model.companyEmailId === "" || this.model.companyEmailId == null) {
        this.emailValid = true;
      }
      if (this.model.companyCurrency === "" || this.model.companyCurrency == null) {
        this.companyCurrencyValid = true;
      }
      let company = document.getElementById("Company_Details_Card");
      this.utilityService.focusSection(company);
      return false;
    }
    else if(this.model.nic === "" || this.model.nic == null || 
          this.model.business == null || this.model.business.length == 0 || 
          this.model.industry == null || this.model.industry.length == 0){
      if (this.model.nic === "" || this.model.nic == null) {
        this.nicValid = true;
      }
      if (this.model.business == null || this.model.business.length == 0) {
        this.businessInvalid = true;
      }
      if (this.model.industry == null || this.model.industry.length == 0) {
        this.industryInvalid = true;
      }
      let business = document.getElementById("Business_Details_Card");
      this.utilityService.focusSection(business);
      return false;
    }
    else {
      return true;
    }
  }

  transformDate(date) {
    return this.datepipe.transform(date, 'shortDate');
  }

  discardNewCompany() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Discard?" , text:"Are you sure you want to Discard ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
      this.ngxLoader.start();
      this.authService.discardNewCompany().subscribe(
          (res) => {
            this.ngxLoader.stop();
            this.router.navigate(["/landing", "onboarding", { newComp : true }]);
          },
          (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.error.reason, "Failure!");
          }
      );
      this.ngxLoader.stop();

      }
    });
  }

  goToDashboard() {
    this.ngxLoader.start();
    this.authService.getUserDetails().subscribe(
        (res) => {
          this.cacheService.set("userDetails", JSON.stringify(res));
          let userDetails = res;
          if(userDetails['company'] && userDetails['incorporationDetails']) {
            this.navbarService.sendClickEvent();
            setTimeout(() => {
              this.router.navigate(['/landing', 'dashboard', 'capTable']);
            }, 300);
          } else if(userDetails['company'] && !userDetails['incorporationDetails']) {
            if(this.registerNewCompany) {
                this.router.navigate(['/landing', 'onboarding', 'create-historical-round', { newComp:  true }])
            } else {
                this.router.navigate(['/landing', 'onboarding', 'create-historical-round'])
            }
          }
        },
        e => {
          this.toastr.error(e.error.reason, "Error");
        }
      );
  }

  proceedToIncorporationDetails() {
    this.isValid = this.checkValidation();
    if (this.isValid == false) {
      this.toastr.error("Please fill all the required fields!");
      return;
    }

    if (this.model.business == null) {
      this.toastr.error("Please fill Category details!");
      this.businessInvalid = true;
      return;
    }
    if (this.model.industry == null) {
      this.toastr.error("Please fill Industry details!");
      this.industryInvalid = true;
      return;
    }

    if (this.model.founders.length == 0) {
      this.toastr.error("Please fill founder details!");
      return;
    }
    else if (this.model.founders.length > 0) {
      let k = 0;
      for (k = 0; k < this.model.founders.length; k++) {
        if (this.model.founders[k].fullName == "" || this.model.founders[k].fullName == null || 
          this.model.founders[k].emailId == "" || !this.validateEmail(this.model.founders[k].emailId) || this.model.founders[k].emailId == null || 
          this.model.founders[k].nationality == "" || this.model.founders[k].nationality == null) {
          this.toastr.error("Please fill founder details!", "Error!");
          if(this.model.founders[k].fullName === "" || this.model.founders[k].fullName == null){
            this.fName = true;
          }
          if(this.model.founders[k].emailId === "" || this.model.founders[k].emailId == null || !this.validateEmail(this.model.founders[k].emailId)){
            this.fEmail = true;
          }
          if(this.model.founders[k].nationality === "" || this.model.founders[k].nationality == null){
            this.fNationality = true;
          }
          let founder = document.getElementById("Founders_Card");
          this.utilityService.focusSection(founder);
          return;
        }
        if (this.model.founders[k].designation === "" || this.model.founders[k].designation == null ||
            this.model.founders[k].dateOfBirth === "" || this.model.founders[k].dateOfBirth == null) {
          this.toastr.warning("Please fill founder profile details of " + this.model.founders[k].fullName, "Warning!");
        }
      }
    }

    if (this.model.directors.length == 0) {
      this.toastr.error("Please fill director fields!");
      return;
    }
    else if (this.model.directors.length > 0) {
      let m = 0;
      for (m = 0; m < this.model.directors.length; m++) {
        if (this.model.directors[m].fullName === "" || this.model.directors[m].fullName == null ||
            this.model.directors[m].din === "" || this.model.directors[m].din == null ||
            this.model.directors[m].nationality === "" || this.model.directors[m].nationality == null ||
            this.model.directors[m].typeOfDirector === "" || this.model.directors[m].typeOfDirector == null ||
            this.model.directors[m].emailId === "" || this.model.directors[m].emailId == null  || 
            !this.validateEmail(this.model.directors[m].emailId)) {
          this.toastr.error("Please fill director details!", "Error!");
          if(this.model.directors[m].fullName === "" || this.model.directors[m].fullName == null){
            this.dName = true;
          }
          if(this.model.directors[m].din === "" || this.model.directors[m].din == null){
            this.dDin = true;
          }
          if(this.model.directors[m].nationality === "" || this.model.directors[m].nationality == null){
            this.dNationality = true;
          }
          if(this.model.directors[m].typeOfDirector === "" || this.model.directors[m].typeOfDirector == null){
            this.dType = true;
          }
          if(this.model.directors[m].emailId === "" || this.model.directors[m].emailId == null || !this.validateEmail(this.model.directors[m].emailId)){
            this.dEmail = true;
          }
          let director = document.getElementById("Directors_Card");
          this.utilityService.focusSection(director);
          return;
        }
        if (this.model.directors[m].dateOfAppointement === "" || this.model.directors[m].dateOfAppointement == null ||
            this.model.directors[m].dateOfBoardResoluitonForAppointement === "" || this.model.directors[m].dateOfBoardResoluitonForAppointement == null){
          this.toastr.warning("Please fill director profile details of " + this.model.directors[m].fullName, "Warning!");
        }
      }
    }
    
    this.model.dateOfIncorporation = this.transformDate(this.model.dateOfIncorporation);
    this.model.founders.forEach(founder => {
      founder.dateOfBirth = this.transformDate(founder.dateOfBirth);
    });
    this.model.directors.forEach(director => {
      director.dateOfBirth = this.transformDate(director.dateOfBirth);
      director.dateOfAppointement = this.transformDate(director.dateOfAppointement);
      director.dateOfBoardResoluitonForAppointement = this.transformDate(director.dateOfBoardResoluitonForAppointement);
      director.dateOfBoardResoluitonForCessation  = this.transformDate(director.dateOfBoardResoluitonForCessation);
      director.dateOfCessation = this.transformDate(director.dateOfCessation);
    });

    const formData = new FormData();
    formData.append('id', this.model.id);
    formData.append('nameOfTheCompany', this.model.nameOfTheCompany);
    formData.append('dippCertificateNumber', this.model.dippCertificateNumber);
    formData.append('doingBusinessAs', this.model.doingBusinessAs);
    formData.append('cinNumber', this.model.cinNumber);
    formData.append('typeOfTheCompany', this.model.typeOfTheCompany);
    formData.append('dateOfIncorporation', this.model.dateOfIncorporation);
    formData.append('corporateOfficeAddress', this.model.corporateOfficeAddress);
    formData.append('companyEmailId', this.model.companyEmailId);
    formData.append('companyWebsite', this.model.companyWebsite);
    formData.append('companyCurrency', this.model.companyCurrency);
    formData.append('nic', this.model.nic);
    formData.append('subscribersOfMemorandum', this.model.subscribersOfMemorandum);

    this.model.business.forEach((item, index) => {
      formData.append(`business[${index}]`, item); 
    });

    this.model.industry.forEach((item, index) => {
      formData.append(`industry[${index}]`, item); 
    });

    for (let i = 0; i < this.model.founders.length; i++) {
      Object.entries(this.model.founders[i]).forEach(([key, value]) => {
        if(key === "dateOfBirth"){
          if(value == null || value === ""){
           return;
          }
        }
        else{
          formData.append(`founders[${i}].${key}`, value);
        }
      })
    }
    
    for (let i = 0; i < this.model.directors.length; i++) {
      Object.entries(this.model.directors[i]).forEach(([key, value]) => {
        if(key === "dateOfBirth"){
          if(value == null || value === ""){
           return;
          }
        } 
        if(key === "dateOfAppointement"){
          if(value == null || value === ""){
            return;
           }
        }
        if(key === "dateOfBoardResoluitonForAppointement"){
          if(value == null || value === ""){
            return;
           }
        }
        if(key === "dateOfBoardResoluitonForCessation"){
          if(value == null || value === ""){
            return;
           }
        }
        if(key === "dateOfCessation"){
          if(value == null || value === ""){
            return;
           }
        }
        else{
          formData.append(`directors[${i}].${key}`, value);
        }
      })
    }
    
    if(this.profilePicture){
        formData.set('companyLogo', (new File([this.profilePicture], this.fileName, { type: 'image/jpeg' })));
    }
    else if(this.model.companyLogoPath){
        formData.append('companyLogoPath', this.model.companyLogoPath);
    }

    this.ngxLoader.start();
    setTimeout(()=>{   
      this.authService.saveCompanyDetails(formData).subscribe(
        (res) => {
          if (res) {
            //For Stepper Initialization
            let errorStatus = {
              adminProfileTab: "COMPLETED",
              companyDetailsTab: "COMPLETED",
              inCorporationTab: "INPROGRESS"
            }
            this.toastr.success("Company Details Saved Successfully.", "Success!");
            this.errorFlags = errorStatus;
            this.dataService.updateOnboarding(this.errorFlags);
            this.cacheService.set("CompanyDetails", JSON.stringify(this.model));
            localStorage.setItem('companyId', res['id']);
            localStorage.setItem('waitingForVerification', 'false');
            this.authService.getUserDetails().subscribe(
                (res) => {
                  this.cacheService.set("userDetails", JSON.stringify(res));
                  if(res['company']) {
                    localStorage.setItem('companyId', res['company']['id']);
                    localStorage.setItem('companyName', res['company']['nameOfTheCompany']);
                  }
                  if(this.registerNewCompany) {
                    this.router.navigate(['/landing', 'onboarding', 'create-historical-round', { newComp: true }]);
                  } else {
                    this.router.navigate(['/landing', 'onboarding', 'create-historical-round']);
                  }
                  this.ngxLoader.stop();
                });
          }
          else{
            this.ngxLoader.stop();
            this.toastr.error("Null response!.", "Failure!");
          }
        },
        (e) => {
          this.ngxLoader.stop();
          if (e.error.resultCode == "USER_NOT_VERIFIED") {
            let errorStatus = {
              adminProfileTab: "COMPLETED",
              companyDetailsTab: "INPROGRESS",
              inCorporationTab: "INITIAL"
            }
            this.errorFlags = errorStatus;
            this.dataService.updateOnboarding(this.errorFlags);
            this.toastr.error(e.error.reason, "Failure!");
            return;
          }
          else {
            let errorStatus = {
              adminProfileTab: "COMPLETED",
              companyDetailsTab: "COMPLETED",
              inCorporationTab: "INPROGRESS"
            }
            this.errorFlags = errorStatus;
            this.dataService.updateOnboarding(this.errorFlags);
            this.toastr.error("Error: " + e.error.reason, "Error");
            // this.router.navigate(['/landing', 'onboarding', 'incorporationDetails']);
          }
          this.model.dateOfIncorporation = this.datepipe.transform(this.model.dateOfIncorporation, 'yyyy-MM-dd');
          this.model.founders.forEach(founder => {
            founder.dateOfBirth = this.datepipe.transform(founder.dateOfBirth, 'yyyy-MM-dd');
          });
          this.model.directors.forEach(director => {
            director.dateOfBirth = this.datepipe.transform(director.dateOfBirth, 'yyyy-MM-dd');
            director.dateOfAppointement = this.datepipe.transform(director.dateOfAppointement, 'yyyy-MM-dd');
            director.dateOfBoardResoluitonForAppointement = this.datepipe.transform(director.dateOfBoardResoluitonForAppointement, 'yyyy-MM-dd');
            director.dateOfBoardResoluitonForCessation  = this.datepipe.transform(director.dateOfBoardResoluitonForCessation, 'yyyy-MM-dd');
            director.dateOfCessation = this.datepipe.transform(director.dateOfCessation, 'yyyy-MM-dd');
          });
        }
      );
    }, 3000);
  }

  formatDate(date) {
    if (date) {
      let newDate = new Date(date);
      return (newDate.toISOString().replace("Z", "+0000"));
    } else {
      return "";
    }

  }

  showHelp(section) {
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);

    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "CompanyDetailsComponent",
      section
    );
    this.helper = section;
  }

  hideHelp() {
    this.helper = ""
  }

  allowAlphaNumericKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 101) ? null : ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  disableFoundersInputs() {
    if(this.model.founders.length > 0) {
      this.model.founders.forEach(founder => {
        founder.isEdit = false;
      });
    }
  }

  disableDirectorsInputs() {
    if(this.model.directors.length > 0) {
      this.model.directors.forEach(director => {
        director.isEdit = false;
      });
    }
  }

  getEvent() {
    this.shouldLogoShowDialog = false;
    document.getElementById("overlay").style.display = "none";
  }

  openUploadImage() {
    this.shouldLogoShowDialog = true;
  }

  onImageSaved(imageDetails) {
    this.shouldLogoShowDialog = false;
    this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
    this.croppedImage = imageDetails.croppedImage;
    this.fileName = imageDetails.fileName;
    this.companyLogoInvalid = false;
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

}