import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilityService } from 'src/app/services/utilities.service';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { AuthenticationService } from 'src/app/authentication.service';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-historical-conversion',
  templateUrl: './conversion.component.html',
  styleUrls: ['./conversion.component.scss']
})
export class ConversionComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() rowData: any;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  conversionDetails: FormGroup;
  isShowNote: boolean = false;
  isSubmitted: boolean = false;
  elementLoaded: boolean = false;
  userDetails: any;
  usrCompanyCurrency = "";
  isFormReset: boolean = false;
  currencySymbol = "";

  constructor(private authService: AuthenticationService, 
    private toastr: ToastrService,
    private fb: FormBuilder,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService) { }

    close() {
      this.showDialog = false;
      this.visibleChange.emit(false);
    }

    resetForm(){
      this.isFormReset = true;
      this.conversionDetails.reset();
      // this.conversionDetails.controls["isUpdateConversionRatio"].setValue(false);
      this.loadJSstyles()
    }

    saveConversion(){
      this.isSubmitted = true;
      if(this.conversionDetails.valid){
        this.isSubmitted = false;
        let dt = new Date(this.conversionDetails.value.conversionDate);
        this.conversionDetails.controls["conversionDate"].setValue(dt.toISOString().replace("Z", "+0000"));
        this.conversionDetails.controls["conversionValue"].setValue(this.conversionDetails.value.conversionValue.toString().replace(/,/g, "") / 1, { emitEvent: false });
        this.conversionDetails.controls["numberOfSharesToConvert"].setValue(this.conversionDetails.value.numberOfSharesToConvert ? this.conversionDetails.value.numberOfSharesToConvert.toString().replace(/,/g, "") / 1 : null, { emitEvent: false });
        this.conversionDetails.controls["numberofSharesPostConversion"].setValue(this.conversionDetails.value.numberofSharesPostConversion.toString().replace(/,/g, "") / 1);
        for(const investor of this.rowData.investorsArray){
          const uniqueKey = investor.__KEY__ != null ? investor.__KEY__ : investor.uuid;
          if(uniqueKey == this.rowData.rowId){
            investor.conversionDetails = this.conversionDetails.value;
            if(investor['allotment'] && 
                investor['allotment'].some((element) => { return element['name'] === 'Converted' }) ) {}
            else {
                if(investor['allotment']) {
                  investor['allotment'].push({ name: 'Converted', identifier: 'C' })
                } else {
                  investor['allotment'] = [];
                  investor['allotment'].push({ name: 'Converted', identifier: 'C' })
                }
            }
            break;
          }
        }
        if(this.rowData.buyerName) {
            this.rowData.rowInfo.conversionDetails = this.conversionDetails.value;
            if(this.rowData.rowInfo['transactions'] && 
                this.rowData.rowInfo['transactions'].some((element) => { return element['name'] == 'Converted'}) ) {} 
            else {
                if(!this.rowData.rowInfo['transactions']) {
                  this.rowData.rowInfo['transactions'] = [];
                }
                this.rowData.rowInfo['transactions'].push({ name: 'Converted', identifier: 'C' });
            }
        }
        this.showDialog = false;
        this.visibleChange.emit(true);
      } else if(this.isFormReset) {
        if(this.rowData.buyerName) {
            this.rowData.rowInfo.conversionDetails = null;
            _.remove(this.rowData.rowInfo['transactions'], (element) => {
                if(element['name'] == 'Converted') {
                    return element['name'] == 'Converted';
                }
              });
        }
        for(const investor of this.rowData.investorsArray){
            const uniqueKey = investor.__KEY__ != null ? investor.__KEY__ : investor.uuid;
            if(uniqueKey == this.rowData.rowId){
              investor.conversionDetails = null;
              _.remove(investor['allotment'], (element) => {
                if(element['name'] == 'Converted') {
                    return element['name'] == 'Converted';
                }
              });
              break;
            }
        }
        this.showDialog = false;
        this.visibleChange.emit(true);
      }
    }
    

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
  }

  formatCurrency(value){
    if(value > 0){
      const valueStr = value.toString();
      return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
    }
  }

  getTotalValueTble(value) {
    if(value > 0){
      const valueStr = value.toString();
      if(valueStr.split(".")[0].length > 5){
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else{
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else{
      return 0;
    }
  }

  csvVal(value, e){
    let tVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
  
    let val = value;
    tVal = this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if(e) {
      if(e.target.value) {
        let CSVLength = tVal.length;
        let valueLength = e.target.value.length;
        if(CSVLength > valueLength) {
            e.target.value = tVal;
            let difference = CSVLength - valueLength;
            start = start + difference;
            end = end + difference;
        }
        else if(CSVLength < valueLength){
          e.target.value = tVal;
          if(start != 0 || end != 0){
              let difference = valueLength - CSVLength;
              start = start - difference;
              end = end - difference;
          }
          else{
              start = start;
              end = end;
          }  
        }
        else{
          e.target.value = tVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    } 
  }

    loadJSstyles(){
      $('[data-toggle="switch"]').bootstrapSwitch({
        onText: "Yes",
        offText: "No",
      });
  
      // $('#updateConversionRatio').bootstrapSwitch('state', this.conversionDetails.value.isUpdateConversionRatio);
      // $("#updateConversionRatio").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
      //   this.conversionDetails.controls["isUpdateConversionRatio"].setValue(state);
      //   if(state){
      //     this.conversionDetails.controls["conversionSecurity"].clearValidators();
      //   }
      //   else{
      //     this.conversionDetails.controls["conversionSecurity"].setValidators([Validators.required]);
      //   }
      //   this.conversionDetails.controls["conversionSecurity"].updateValueAndValidity();
      // });
    }

  ngDoCheck() {
    if (!this.elementLoaded && $('[data-toggle="switch"]').length > 0) {
      this.elementLoaded = true;
      this.loadJSstyles();
    }
  }

  ngOnInit() {
    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    } 
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim()
    if(this.rowData.conversionDetails != null){
      const conversionDetails = this.rowData.conversionDetails;
      const csvAmount = this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, conversionDetails.conversionValue) || 0;
      const csvShares = this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, conversionDetails.numberofSharesPostConversion) || 0;
      this.conversionDetails = this.fb.group({
        conversionValue: [csvAmount, [Validators.required], , {disabled: true}],
        conversionDate: [conversionDetails.conversionDate, [Validators.required]],
        // isUpdateConversionRatio: [conversionDetails.isUpdateConversionRatio, [Validators.required]],
        numberOfSharesToConvert: [this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency,conversionDetails.numberOfSharesToConvert), , , {disabled: true}],
        conversionSecurity: [conversionDetails.conversionSecurity],
        numberofSharesPostConversion: [csvShares, [Validators.required]],
        notes: [conversionDetails.notes]
      });
      this.isShowNote = conversionDetails.notes != null ? true : false
    }
    else{
      this.conversionDetails = this.fb.group({
        conversionValue: [null, [Validators.required]],
        conversionDate: [null, [Validators.required]],
        // isUpdateConversionRatio: [false, [Validators.required]],
        numberOfSharesToConvert: [this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, this.rowData.rowInfo.numberOfShares)],
        conversionSecurity: [null],
        numberofSharesPostConversion: [null, [Validators.required]],
        notes: [null]
      });
    }
    this.conversionDetails.get('conversionValue').valueChanges.subscribe((value) => {
      if(!this.rowData.buyerName) {
        let convValue = value.toString().replace(/,/g, "") / 1;
        let FDBShares = this.rowData.rowInfo.amount/convValue;
        if(!value) {
          this.conversionDetails.controls['numberofSharesPostConversion'].reset();
          this.conversionDetails.controls['numberofSharesPostConversion'].updateValueAndValidity();
        } else {
          let fdb: any = this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, FDBShares);
          this.conversionDetails.controls['numberofSharesPostConversion'].setValue(Math.floor(fdb.toString().replace(/,/g, "") / 1));
          this.conversionDetails.controls['numberofSharesPostConversion'].updateValueAndValidity();
        }
      }
    });
    this.conversionDetails.get('numberOfSharesToConvert').valueChanges.subscribe((value) => {
      if(!this.rowData.buyerName) {
        let numOfSharesToConvert = value.toString().replace(/,/g, "") / 1;
        if(numOfSharesToConvert < this.rowData.rowInfo.numberOfShares) {
          let originalSharePrice = this.rowData.rowInfo.par + this.rowData.rowInfo.premium;
          let updatedInvestmentAmt = originalSharePrice * numOfSharesToConvert;
          let convValue = this.conversionDetails.controls['conversionValue'].value.toString().replace(/,/g, "") / 1;
          let FDBShares = updatedInvestmentAmt/convValue;
          this.conversionDetails.controls['numberofSharesPostConversion'].setValue(this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, FDBShares));
          this.conversionDetails.controls['numberofSharesPostConversion'].updateValueAndValidity();
        }
      }
    });
    // this.conversionDetails.get('isUpdateConversionRatio').valueChanges.subscribe((value) => {
    //     if(value == false) {
    //         this.conversionDetails.controls['conversionSecurity'].setValidators([Validators.required]);
    //         this.conversionDetails.controls['numberOfSharesToConvert'].setValidators([Validators.required]);
    //     } else {
    //         this.conversionDetails.controls['conversionSecurity'].clearValidators();
    //         this.conversionDetails.controls['numberOfSharesToConvert'].clearValidators();
    //     }
    //     this.conversionDetails.controls['conversionSecurity'].updateValueAndValidity();
    //     this.conversionDetails.controls['numberOfSharesToConvert'].updateValueAndValidity();
    // });
    
    this.loadJSstyles();
  }

}

//To validate input for only empty spaces
function emptySpaceValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const value: string = control.value;
  if (value.length > 0) {
    if (value.trim().length > 0) {
      return null;
    } else {
      return {
        invalid: true,
      };
    }
  }
}
