<div class="row" (mouseleave)="hideHelp()">

  <div class="col-md-6 col-lg-8 col-xl-9">
    <div class="whiteboxwrap">

    <mat-card id="BuyBack_Tab">
      <mat-card-header>
        <mat-card-title>Buy-back</mat-card-title>
      </mat-card-header>
      <mat-divider class="divider-line"></mat-divider>
      <mat-card-content>
        <form class="needs-validation" novalidate>
          <div (mouseover)="showHelp('Basic_details')">
            <div class="row">
              <div class="col-md-10">
                <p class="content-pg-1 top-20 btm-20">
                  Enter the basic details of buy-back. These details determine how the buy-back transaction is reflected across cap tables.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="btm-20">
                    <mat-label class="required">Buy-back Name</mat-label>
                    <input type="text" name="buyBackName" [(ngModel)]="buyBackDetails.buyBackName" #buyBackNameErr = "ngModel"
                        class="form-control inputs top-10" placeholder="Buy-back Name" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="getNameNewBuyBack(buyBackDetails.buyBackName)"
                         title="" required />
                    <span *ngIf="buyBackNameErr.errors">
                      <span *ngIf="(buyBackNameErr.dirty || buyBackNameErr.touched) && !bName"
                          class="alert alert-danger">
                          Buy-back Name is required!
                      </span>
                      <span *ngIf="buyBackNameErr.errors?.required && bName" class="alert alert-danger">
                        Buy-back Name is required!
                      </span>
                    </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="btm-20">
                    <mat-label class="required">Buy-back Identifier</mat-label>
                    <input type="text" name="buyBackIdentifier" [(ngModel)]="buyBackDetails.buyBackIdentifier" #buyBackIdentifierErr = "ngModel"
                        class="form-control inputs top-10" placeholder="Buy-back Identifier" [ngModelOptions]="{standalone: true}" title=""
                        required />
                    <span *ngIf="buyBackIdentifierErr.errors">
                        <span class="alert alert-danger"
                            *ngIf="(buyBackIdentifierErr.dirty || buyBackIdentifierErr.touched) && !bIdentifier">
                            Buy-back Identifier is required!
                        </span>
                        <span class="alert alert-danger"
                            *ngIf="buyBackIdentifierErr.errors?.required && bIdentifier">
                            Buy-back Identifier is required!
                        </span>
                    </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="btm-20">
                  <mat-label class="required">Start Date</mat-label><br>
                  <div class="input-eyePlaceholder">
                      <input matInput name="startDate" [matDatepicker]="dp" [(ngModel)]="startDate"  #startDateErr="ngModel" max="9999-12-31"
                          class="form-control top-10" title="" placeholder="dd-mmm-yyyy" [ngModelOptions]="{standalone: true}" required />
                      <mat-datepicker-toggle matSuffix [for]="dp" matRippleDisabled="disabled" matRippleColor="red">
                          <mat-icon matDatepickerToggleIcon matRippleDisabled="disabled"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                  </div>
                  <span name="null" *ngIf="startDateErr.errors">
                      <span class="alert alert-danger"
                          *ngIf="(startDateErr.dirty || startDateErr.touched) && !bissueDate">
                          Start Date is required!
                      </span>
                      <span class="alert alert-danger"
                          *ngIf="startDateErr.errors?.required && bissueDate">
                          Start Date is required!
                      </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="btm-20">
                  <mat-label class="required">Buy-back Description</mat-label>
                  <textarea [(ngModel)]="buyBackDetails.buyBackDescription" #buyBackDescriptionErr="ngModel" class="form-control inputs top-10" [ngModelOptions]="{standalone: true}"
                       rows="2" placeholder="Buy-back Description" title=""
                      required></textarea>
                  <span *ngIf="buyBackDescriptionErr.errors">
                      <span class="alert alert-danger"
                          *ngIf="buyBackDescriptionErr.errors?.required && bDescription">
                          Buy-back Description is required!
                      </span>
                      <span class="alert alert-danger"
                          *ngIf="(buyBackDescriptionErr.dirty || buyBackDescriptionErr.touched) && !bDescription">
                          Buy-back Description is required!
                      </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10">
                <p class="content-pg-1 top-20 btm-20">
                  Enter details of the buy-back transaction in accordance with the terms of the buy-back.
                </p>
              </div>
            </div>
            <mat-card id="BuyBack_Tab">
              <div class="row">
                <div class="col-md-2">
                  <div class="top-10">
                    <mat-label class="required">Security Type</mat-label>
                  </div>
                </div>
                <div class="col-md-4">
                  <select class="form-control cursor-hand"
                    (change)="changeInstrument($event.target.value)" [(ngModel)]="buyBackDetails.securityClass" [ngModelOptions]="{standalone: true}" [value]="buyBackDetails.securityClass" required>
                    <option disabled selected [value]="null">Select Instrument</option>
                    <option [value]="instr" *ngFor="let instr of instruments">{{instr}}</option>
                  </select>
                  <span class="alert alert-danger"
                        *ngIf="bSecurityInstrument">
                        Security Instrument is required!
                  </span>
                </div>
                <div *ngIf="buyBackDetails && buyBackDetails.securityClass != null" class="col-md-4">
                  <select class="form-control cursor-hand" [(ngModel)]="buyBackDetails.className" [ngModelOptions]="{standalone: true}"
                  [value]="buyBackDetails.className"
                      (change)="changeSecurity($event.target.value)" #securityTypeErr required>
                    <option disabled selected [value]="null">Select Security</option>
                    <option [value]="sec" *ngFor="let sec of securityClass">{{sec}}</option>
                  </select>
                  <span class="alert alert-danger"
                        *ngIf="bSecurityType">
                        Security Class is required!
                  </span>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-2">
                  <div class="top-10">
                    <mat-label class="required">Buy-back Amount ({{currencySymbol}})</mat-label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="top-10">
                    <input type="text"  #amountErr="ngModel" [(ngModel)]="buyBackAmount"
                        class="form-control inputs top-10" (input)="onChangeBuyBackAmount('ip', $event)" (keypress)="onlyNumberKey($event)" placeholder="Buy-back Amount" [ngModelOptions]="{standalone: true}" title=""
                        required />
                    <span *ngIf="amountErr && amountErr.errors">
                        <span class="alert alert-danger"
                            *ngIf="(amountErr.dirty || amountErr.touched) && !bBuyBackAmount">
                            Buy-back Amount is required!
                        </span>
                        <span class="alert alert-danger"
                            *ngIf="amountErr.errors?.required && bBuyBackAmount">
                            Buy-back Amount is required!
                        </span>
                    </span>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="top-10 float-right">
                    <mat-label class="required">Buy-back price per share ({{currencySymbol}})</mat-label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="top-0">
                    <input type="text" #buyBackPricePerShareErr="ngModel" [(ngModel)]="pricePerShare" (input)="onChangePricePerShare('ip', $event)" 
                        class="form-control inputs top-10" (keypress)="onlyNumberKey($event)" placeholder="Buy-back Price per share" [ngModelOptions]="{standalone: true}" title=""
                        required />
                    <span *ngIf="buyBackPricePerShareErr.errors">
                        <span class="alert alert-danger"
                            *ngIf="(buyBackPricePerShareErr.dirty || buyBackPricePerShareErr.touched) && !bBuyBackPricePerShare">
                            Buy-back Price per Share is required!
                        </span>
                        <span class="alert alert-danger"
                            *ngIf="buyBackPricePerShareErr.errors?.required && bBuyBackPricePerShare">
                            Buy-back Price per Share is required!
                        </span>
                    </span>
                  </div>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-2">
                  <div class="top-10">
                    <mat-label>No. of Shares to be bought back</mat-label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="btm-20">
                    <input type="text" name="buyBackNoOfShares" #noOfSharesBoughtBackErr [(ngModel)]="noOfSharesBoughtBackText" 
                        class="form-control inputs top-10" placeholder="No. of shares bought back" [ngModelOptions]="{standalone: true}" title=""
                        disabled />
                    <span *ngIf="noOfSharesBoughtBackErr.errors">
                        <span class="alert alert-danger"
                            *ngIf="(noOfSharesBoughtBackErr.dirty || noOfSharesBoughtBackErr.touched) && !bNoOfSharesBoughtBack">
                            Error Calculating number of bought back Shares
                        </span>
                        <span class="alert alert-danger"
                            *ngIf="noOfSharesBoughtBackErr.errors?.required && bNoOfSharesBoughtBack">
                            Error Calculating number of bought back Shares
                        </span>
                    </span>
                  </div>
                </div>
              </div>
            </mat-card>
            <div class="row">
              <div class="col-md-4">
                <div class="top-10">
                  <mat-label>Post buy-back consolidation: {{postBuyBackConsolidation}}%</mat-label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="top-10">
                  <mat-label>Buy-back Amount ({{currencySymbol}}): {{ buyBackDetails.buyBackAmount && getTotalValueTble(buyBackDetails.buyBackAmount)}}</mat-label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="top-10">
                  <mat-label>Total Shares in Security: {{getTotalValueTble(totalSharesInSecurity)}}</mat-label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 top-20">
                <button mat-raised-button id="saveAndContinue" [disableRipple]="disableripple" color="success"
                    class=" pull-right" (click)="goToShareholders()" ngbTooltip="Save and Continue">Save and Continue
                </button>
                <button mat-raised-button id="discard" [disableRipple]="disableripple" color="warn"
                    class=" pull-right margin-right-20" (click)="discardBuyBack()"
                    ngbTooltip="Discard Buy-back">Discard
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    </div>
  </div>
  <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="helper-container">
        <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
          <mat-card-header class="help-hdr">
            <mat-card-title><label class="help-hdr-txt">{{
               helpSections?.name
              }}</label></mat-card-title>
          </mat-card-header>
          <hr />
          <mat-card-content class="card-help-content">
            <div [innerHTML]="helpSections?.description"></div>
          </mat-card-content>
        </mat-card>
    </div>
  </div>
</div>
