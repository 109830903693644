<div *ngIf="showDialog" class="dialog">
    <div class="header">
      <div class="row">
        <div class="col-xs-8 col-sm-4 col-md-10 custom-modal-header">
          <label class="dialogue-title">Separation</label>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2">
          <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
            <span class="close">X</span>
          </button>
        </div>
      </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <div class="container-fluid content-row">
        <div>
            <div class="row top-20 btm-20">
                <div class="col-md-3">
                    <div>
                        <mat-label>Employee Name</mat-label>
                    </div>
                    <div class="btm-20">
                        <mat-label class="mat-label-value">
                            {{ resignationDetails.employeeName }}</mat-label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div>
                        <mat-label>Last Day</mat-label>
                    </div>
                    <div class="input-eyePlaceholder">
                        <input matInput [matDatepicker]="dp" class="form-control top-10" *ngIf="resignationDetails" [(ngModel)]="resignationDetails.lastDay" (change)="onChangeLastDay($event.target.value)" />
                        <mat-datepicker-toggle matSuffix [for]="dp" matRippleDisabled="disabled" matRippleColor="red">
                            <mat-icon matDatepickerToggleIcon matRippleDisabled="disabled"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="row btm-20">
                <div class="col-md-7">
                    <div class="card-lyot-dv">
                        <div class="row">
                            <div class="col-md-4">
                                <div>
                                    <mat-label>Total Granted</mat-label>
                                </div>
                                <div class="card-value top-10" ngbTooltip="{{ resignationDetails.totalGranted }}">{{ resignationDetails.totalGranted }}</div>
                            </div>
                            <div class="col-md-4">
                                <div>
                                    <mat-label>Vested</mat-label>
                                </div>
                                <div class="card-value top-10" ngbTooltip="{{ resignationDetails.totalVested }}">{{ resignationDetails.totalVested }}</div>
                            </div>
                            <div class="col-md-4">
                                <div>
                                    <mat-label>Lapsed</mat-label>
                                </div>
                                <div class="card-value top-10" ngbTooltip="{{ resignationDetails.totalLapsed }}">{{ resignationDetails.totalLapsed }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row btm-20">
                <div class="col-md-12">
                    <p class="content-pg-1" *ngIf="employmentStatus === 'Resigned' ">
                        Specify the last working day of the employee. This is required to calculate the total number of options that will vest for the employee. 
                        All unvested options shall lapse. Vested options can be exercised by the employee based on the terms/timelines set out in the plan. 
                        If you wish to extend the exercise period beyond the said timeline, select <b>‘Yes’</b>. 
                        In case you choose not to do so, enter the number of vested options that can be exercised as per the timelines in the plan. 
                        Additionally,update the contact information of the employee for further correspondence.
                    <p class="content-pg-1" *ngIf="employmentStatus === 'Terminated For Cause' ">
                        Specify the last working day of the employee terminated for reasons related to any fraudulent practices or abandonment of employment. 
                        Under such circumstances, the company may consider  forfeiting vested options along with unvested options on the date of termination.
                        If you allow the employee to have vested options, the same can be exercised by the employee based on the terms/timelines set out in the plan. 
                        If you wish to extend the exercise period beyond the said timeline, select <b>‘Yes’</b>. In case you choose not to do so, enter the number of vested options that can be exercised as per the timelines in the plan. Additionally, 
                        update the contact information of the employee for further correspondence.
                    </p>
                    <p class="content-pg-1" *ngIf="employmentStatus === 'Terminated for Convenience' ">
                        Specify the last working day of the employee terminated due to retirement or separation in other forms. In such a scenario, 
                        all vested options can be exercised upto a specified period as decided by the board. 
                        The unvested shares are forfeited and added back to the pool. If you wish to extend the exercise period beyond the said timeline, 
                        select <b>‘Yes’</b>. In case you choose not to do so, enter the number of vested options that can be exercised as per the timelines in the plan. Additionally, 
                        update the contract information of the employee for further correspondence.
                    </p>
                </div>
            </div>
            <div class="row btm-20" *ngIf="resignationDetails.lastDateToExercise">
                <div class="col-md-12">
                    <div class="card-lyot-dv">
                        <div class="row">
                            <div class="col-md-3">
                                <div>
                                    <mat-label>Total Vested</mat-label>
                                </div>
                                <div class="card-value top-10" ngbTooltip="{{ resignationDetails.totalVested }}">{{ resignationDetails.totalVested }}</div>
                            </div>
                            <div class="col-md-3">
                                <div>
                                    <mat-label>Total Exercised</mat-label>
                                </div>
                                <div class="card-value top-10" ngbTooltip="{{ resignationDetails.totalExercised }}">{{ resignationDetails.totalExercised }}</div>
                            </div>
                            <div class="col-md-3">
                                <div>
                                    <mat-label>Remaining</mat-label>
                                </div>
                                <div class="card-value top-10" ngbTooltip="{{ resignationDetails.remainingToExercise }}">{{ resignationDetails.remainingToExercise }}</div>
                            </div>
                            <div class="col-md-3">
                                <div>
                                    <mat-label>Last Date to Exercise</mat-label>
                                </div>
                                <div class="card-value top-10" ngbTooltip="{{ resignationDetails.lastDateToExercise | date: 'dd-MMM-yyyy' }}">{{ resignationDetails.lastDateToExercise | date: 'dd-MMM-yyyy' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row btm-20" [hidden]="resignationDetails.lastDateToExercise == null">
                <div class="col-md-12">
                    <mat-label>Extend Exercise</mat-label><br>
                    <div class="top-10">
                        <input class="alert-status-price" id="extendExercise" type="checkbox"
                            [(ngModel)]="resignationDetails.isExerciseDateExtended" data-toggle="switch"
                            checked data-off-color="default" title="" />
                    </div>
                </div>
            </div>
            <div class="row btm-20" *ngIf="resignationDetails.lastDateToExercise &&  !resignationDetails.isExerciseDateExtended">
                <div class="col-md-6">
                    <div>
                        <mat-label class="required">Enter Number of Options to Exercise</mat-label>
                    </div>
                    <div>
                        <input type="text" class="form-control text-align-right number top-10" required
                        [(ngModel)]="resignationDetails.numberOfSharesToExercise" #numberOfSharesToExerciseErr="ngModel" placeholder = "Number of options to exercise"
                        (keypress)="onlyNumberKey($event)" (input)="onChangeSharesToExercise($event.target.value)" />
                        <div class="alert alert-danger" *ngIf="phoneErr.errors">
                            <div *ngIf="(numberOfSharesToExerciseErr.dirty) && numberOfSharesToExerciseErr.errors?.required">
                                Number of shares to exercise cannot be empty!
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="content-pg-1 pull-right">
                            {{ forfietedOptions }} options will be Surrendered.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row btm-20">
                <div class="col-md-12">
                    <div>
                        <mat-label>Update Contact Information</mat-label>
                    </div>
                    <div class="alert alert-info alert-labeled alrt-mrgn top-10">
                        <P type="button" class="close-help" data-dismiss="alert">
                            <img src="../../../assets/message-close.png" />
                        </P>
                        <div class="alert-labeled-row">
                            <span class="alert-label alert-label-left alert-labelled-cell">
                                <img src="../../../assets/message-info.svg" />
                            </span>
                            <p class="content-pg-1 alert-body alert-body-right alert-labelled-cell">
                                Please update contact information if it will change after leaving the company.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row btm-20">
                <div class="col-md-6">
                    <mat-label class="required">Email Id</mat-label>
                    <input type="email" class="form-control top-10" [(ngModel)]="resignationDetails.updatedEmailId" #mailErr="ngModel"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" required placeholder="me@example.com"
                        title="" (ngModelChange)="checkEmail($event)" />
                    <div class="alert alert-danger" *ngIf="mailErr.errors">
                        <div *ngIf="(mailErr.dirty || mailErr.touched) && mailErr.errors?.required">
                            E-mail Id is required!
                        </div>
                        <div id="emailValid" [hidden]="!mailErr.errors?.pattern">
                            Invalid E-mail!
                        </div>
                    </div>

                    <div class="top-20">
                        <mat-label class="required">Resident Status</mat-label>
                        <select class="form-control top-10 cursor-hand" [(ngModel)]="resignationDetails.updatedResidentStatus" 
                            #resident (change)="onChangeResidentSelect(resident.value)" #resErr="ngModel" required>
                            <option value=null [selected]="true" disabled>Select Residence</option>
                            <option *ngFor='let resident of countryList; let k = index' [selected]="resident === selectedCountry"
                                value="{{resident}}">{{resident}}
                            </option>
                        </select>
                        <div class="alert alert-danger" *ngIf="resErr.errors">
                            <div *ngIf="(resErr.dirty || resErr.touched) && resErr.errors?.required">
                                Resident Status is required!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-label class="required">Phone Number</mat-label>
                    <input title="" type="number" class="form-control top-10" required
                        [(ngModel)]="resignationDetails.updatedContactNumber" #phoneErr="ngModel" placeholder="Contact Number"
                        (keypress)="maxNumber($event.target.value)" />
                    <div class="alert alert-danger" *ngIf="phoneErr.errors">
                        <div *ngIf="(phoneErr.dirty || phoneErr.touched) && phoneErr.errors?.required">
                            Phone Number is required!
                        </div>
                    </div>

                    <div class="top-20">
                        <mat-label class="required">Nationality</mat-label>
                        <input type="text" class="form-control top-10" required 
                        [(ngModel)]="resignationDetails.nationality" #nationalityErr="ngModel" placeholder="Nationality" />
                        <div class="alert alert-danger" *ngIf="nationalityErr.errors">
                            <div *ngIf="(nationalityErr.dirty || nationalityErr.touched) && nationalityErr.errors?.required">
                                Nationality is required!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row btm-20">
                <div class="col-md-12">
                    <div *ngIf="!showNotes" (click)="addNotes(true)"><span>
                        <mat-label>Add Notes</mat-label><img class="left-10" src="../../../../assets/action/addnotes.svg"
                          alt="add Notes" />
                      </span></div>
                    <div *ngIf="showNotes" (click)="addNotes(false)"><span>
                        <mat-label>Remove Notes</mat-label><img class="left-10" src="../../../../assets/action/removenotes.svg"
                          alt="remove Notes" />
                      </span></div>
                    <textarea class="form-control top-10" *ngIf="showNotes" [(ngModel)]="resignationDetails.notes"
                      maxlength="10000" style="width:100%" placeholder="Add Notes"></textarea>
                </div>
            </div>
            <div class="row btm-20">
                <div class="col-xs-12 col-sm-12 col-md-12">
                  <button mat-raised-button id="save" color="success" [disableRipple]="true" ngbTooltip="Save"
                    class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="saveResignationDetails()">
                    Save
                  </button>
                  <button mat-raised-button id="cancel" color="warn" [disableRipple]="true" ngbTooltip="Cancel"
                    class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                    Cancel
                  </button>
                </div>
            </div>
        </div>
    </div>
</div>