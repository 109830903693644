import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { MatStepper } from '@angular/material';
import { DataService } from '../../services/new-round-notify.service';
import { HelperService } from '../../services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utilities.service';
import { CacheProviderService } from '../../services/cache-provider.service';
import { Proforma } from 'src/app/services/proforma.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-create-new-buy-back',
  templateUrl: './create-new-buy-back.component.html',
  styleUrls: ['./create-new-buy-back.component.scss']
})
export class CreateNewBuyBackComponent implements OnInit {

  amountRaised: number;
  equityShares: number;
  preferenceShares;
  numberOfRounds;
  lastValuation;
  lastRaise;
  lastSharePrice;
  roundHistoryData;
  activePath;
  randomSelectpath;
  isLinear = false;
  helperTopPosition;
  helper: string = "";
  private proforma: Proforma;

  public buyBackTab = "../../assets/wizard/step-1.svg";
  public shareholdersTab = "../../assets/wizard/step-2.svg";
  public reviewTab = "../../assets/wizard/step-3.svg";
  public summaryTab = "../../assets/wizard/step-4.svg";
  public confirmTab = "../../assets/wizard/step-5.svg";

  isShowBuyBack: boolean = false;
  isShowShareholders: boolean = false;
  isShowReview: boolean = false;
  isShowConfirm: boolean = false;
  isShowSummary: boolean = false;
  errorFlags;

  public isBuyBackhelp: boolean = false;
  public helpTitle: any;
  public helpMessage: any;

  public isBasicDtlsFilled: boolean = false;
  public userDetails;
  public usrCompanyCurrency = "";
  public sharePrice = "";
  public roundInfo;
  public roundCurrency;
  public buyBackId: number;
  currencySymbol = "";

  @ViewChild('stepper') stepper: MatStepper;
  totalStepsCount: number;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private dataService: DataService,
    private helperService: HelperService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService,
    private ngxLoader: NgxUiLoaderService,
  ) { 
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activatedRoute.queryParams
          .filter(params => params.buyBackId)
          .subscribe(params => {
            this.buyBackId = params.buyBackId;
          }
        );
          let path = val.url;
          this.activePath = path.substring(path.lastIndexOf('/') + 1);
          this.activePath = this.activePath.split('?')[0];
      }
  });
  }

  ngAfterViewInit() {
    this.totalStepsCount = this.stepper._steps.length;
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }


  onActivate(event) {
    this.flagInit();
  }

  onDeactivate(event) {
    if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === true && event.activatedRoute.routeConfig.path === "buyBack"){
      this.buyBackTab = "../../assets/wizard/completed.svg"
    }
    if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === true && event.activatedRoute.routeConfig.path === "shareholders") {
      this.shareholdersTab = "../../assets/wizard/step-2.svg";
    }
    else if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === true &&
      this.errorFlags.isShareholdersTabValid === true &&
      event.activatedRoute.routeConfig.path === "review") {
      this.reviewTab = "../../assets/wizard/step-3.svg";
    }
    else if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === true &&
      this.errorFlags.isShareholdersTabValid === true &&
      this.errorFlags.isReviewTabValid === true &&
      event.activatedRoute.routeConfig.path === "summary") {
      this.summaryTab = "../../assets/wizard/step-4.svg";
    }
    else if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === true &&
      this.errorFlags.isShareholdersTabValid === true &&
      this.errorFlags.isReviewTabValid === true &&
      this.errorFlags.isSummaryTabValid === true &&
      event.activatedRoute.routeConfig.path === "confirm") {
      this.confirmTab = "../../assets/wizard/step-5.svg";
    }

  }

  //Error flags handle
  erFlgs() {
    if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === false) {
      this.buyBackTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isShareholdersTabValid === false) {
      this.shareholdersTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isReviewTabValid === false) {
      this.reviewTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isConfirmTabValid === false) {
      this.confirmTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isSummaryTabValid === false) {
      this.summaryTab = "../../assets/wizard/warning-red.svg";
    }
  }

  // stepepr flags initiization
  flagInit() {
    this.dataService.getData().subscribe(data => {
      if (data) {
        this.errorFlags = data;
        this.stepperIndicator();
        this.erFlgs();
      }
      else {
      }
    })

  }

  // stepper step handling
  stepperIndicator() {
    if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === true) {
      this.buyBackTab = "../../assets/wizard/completed.svg";
    }
    if (this.errorFlags && this.errorFlags.isShareholdersTabValid === true) {
      this.shareholdersTab = "../../assets/wizard/completed.svg";
    }
    if (this.errorFlags && this.errorFlags.isReviewTabValid === true) {
      this.reviewTab = "../../assets/wizard/completed.svg";
    }
    if (this.errorFlags && this.errorFlags.isSummaryTabValid === true) {
      this.summaryTab = "../../assets/wizard/completed.svg";
    }
    if (this.errorFlags && this.errorFlags.isConfirmTabValid === true) {
      this.confirmTab = "../../assets/wizard/completed.svg";
    }
    this.activeStep();
  }

  //active step indicator
  activeStep() {
    if (this.activePath === "buyBack") {
      this.stepper.selectedIndex = 0;
      this.buyBackTab = "../../assets/wizard/inprogress.svg";
    }
    else if (this.activePath === "shareholders") {
      this.stepper.selectedIndex = 1;
      this.shareholdersTab = "../../assets/wizard/inprogress.svg";
      if (this.errorFlags && this.errorFlags.isReviewTabValid === null){
        this.reviewTab = "../../assets/wizard/step-3.svg";
        this.summaryTab = "../../assets/wizard/step-4.svg";
        this.confirmTab = "../../assets/wizard/step-5.svg";
      }
    }
    else if (this.activePath === "review") {
      this.stepper.selectedIndex = 2;
      this.reviewTab = "../../assets/wizard/inprogress.svg";
      if (this.errorFlags && this.errorFlags.isSummaryTabValid === null){
        this.summaryTab = "../../assets/wizard/step-4.svg";
        this.confirmTab = "../../assets/wizard/step-5.svg";
      }
    }
    else if (this.activePath === "summary") {
      this.stepper.selectedIndex = 3;
      this.summaryTab = "../../assets/wizard/inprogress.svg";
      if (this.errorFlags && this.errorFlags.isConfirmTabValid === null){
        this.confirmTab = "../../assets/wizard/step-5.svg";
      }
    }
    else if (this.activePath === "confirm") {
      this.stepper.selectedIndex = 4;
      this.confirmTab = "../../assets/wizard/inprogress.svg";
    }
  }

  // get round history data
  getRoundHistoryData() {
    this.authService.getRoundHistoryData().subscribe((response) => {
      if (response) {
        this.roundHistoryData = response;
        this.amountRaised = this.roundHistoryData['amountRaised'];
        this.equityShares = this.roundHistoryData['equityShares'];
        this.preferenceShares = this.roundHistoryData['preferenceShares'];
        this.numberOfRounds = this.roundHistoryData['numberOfRounds'];
        this.lastValuation = this.roundHistoryData['lastValuation'];
        this.lastRaise = this.roundHistoryData['lastRaise'];
      }
    })
    let cardData;
    this.authService.getDashboardCards(false).subscribe(
      res => {
        cardData = res;
        cardData.forEach(element => {
          if(element["cardName"] == "Shares"){
            this.lastSharePrice = element["secondSectionValue"]
          }
      });
      },
      e => {
        this.toastr.error(e.error.reason, "Error");
      }
    );
    
  }


  // Randoam step click
  randomStep(name) {
    this.randomSelectpath = name;
    if (this.randomSelectpath === "buyBack") {
      this.ngxLoader.start();
      if (this.errorFlags && this.errorFlags.isbuyBackDetailsTabValid === true) {
        this.buyBackTab = "../../assets/wizard/inprogress.svg";
        this.ngxLoader.stop();
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'buyBack'],
          { queryParams: { 'buyBackId': this.buyBackId } }
          );
      }
      else {
        this.ngxLoader.stop();
        this.toastr.error("Please fill Buy-back-tab form first", "Error");
      }
    }
    else if (this.randomSelectpath === "shareholders") {
      if (this.errorFlags && this.errorFlags.isShareholdersTabValid === true) {
        this.shareholdersTab = "../../assets/wizard/inprogress.svg";
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'shareholders'],
          { queryParams: { 'buyBackId': this.buyBackId } }
          );
      }
      else {
        this.toastr.error("Please fill Buy-back-tab form first", "Error");
      }
    }
    else if (this.randomSelectpath === "review") {
      if (this.errorFlags && this.errorFlags.isReviewTabValid === true) {
        this.reviewTab = "../../assets/wizard/inprogress.svg";
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'review'],
          { queryParams: { 'buyBackId': this.buyBackId } }
        );
      }
      else {
        this.toastr.error("Please fill Shareholders-tab form", "Error");
      }
    }
    else if (this.randomSelectpath === "summary") {
      if (this.errorFlags && this.errorFlags.isSummaryTabValid === true) {
        this.summaryTab = "../../assets/wizard/inprogress.svg";
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'summary'],
          { queryParams: { 'buyBackId': this.buyBackId } }
          );
      }
      else {
        this.toastr.error("Please fill Review-tab", "Error");
      }
    }
    else if (this.randomSelectpath === "confirm") {
      if (this.errorFlags && this.errorFlags.isConfirmTabValid === true) {
        this.confirmTab = "../../assets/wizard/inprogress.svg";
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'confirm'],
          { queryParams: { 'buyBackId': this.buyBackId } }
          );
      }
      else {
        this.toastr.error("Please fill all forms", "Error");
      }
    }
  }

  ngOnInit() {
    this.getRoundHistoryData();
    $('.nav-item').addClass('speech-bubble-white');
    $('.nav-item').removeClass('active');
    $('.nav-link').removeClass('active');
    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
    this.flagInit();
  }

  hideHelp() {
    this.isBuyBackhelp = false;
    this.helper = "";
  }

  showHelp(section) {
    let help = this.helperService.getHelpContent("CreateNewBuyBackComponent", section);
    this.helper = section;
    this.helpTitle = help['name'];
    this.helpMessage = help['description'];
    const targetElement = document.getElementById(section);
    if(targetElement != null){
      this.helperTopPosition = targetElement.offsetTop;
    }
    if (section === 'Create_NewBuyBack') {
      this.isBuyBackhelp = true;
    }
    setTimeout(() => {
      if (document.getElementById('createrndHlp') != null) {
        document.getElementById('createrndHlp').innerHTML = help.description;
      }
      else {
      }
    }, 100);
  }

  formatCompanyCurrency(value){
    return this.utilityService.inputCSVformatCurrency1(this.usrCompanyCurrency, value);
  }

  formatRoundCurrency(value){
    return this.utilityService.inputCSVformatCurrency1(this.roundCurrency, value);
  }

}
