import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-email-template-editor',
  templateUrl: './email-template-editor.component.html',
  styleUrls: ['./email-template-editor.component.scss']
})
export class EmailTemplateEditorComponent implements OnInit {
  @ViewChild('emailTemplatePreview') emailTempPrevDiv: ElementRef
  emailTemplateData: string;
  body: string;
  headerIndex: number;
  header: string;
  footerIndex: number;
  footer: string;

  constructor(public dialogRef: MatDialogRef<EmailTemplateEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { action: string, emailTemplate: string }) { }

  ngOnInit() {
    this.emailTemplateData = this.data.emailTemplate
    this.headerIndex = this.emailTemplateData.indexOf("<!-- body start -->")
    this.header = this.emailTemplateData.substring(0, this.headerIndex)
    this.footerIndex = this.emailTemplateData.indexOf("<!-- body end -->")
    this.footer = this.emailTemplateData.substring(this.footerIndex)
    this.body = this.emailTemplateData.substring(this.headerIndex, this.footerIndex)
    this.setPreview()
  }

  setPreview() {
    if (this.data.action == 'view') {
      setTimeout(() => {
        const emailTempPreview = this.emailTempPrevDiv.nativeElement
        this.emailTemplateData = this.header.concat(this.body, this.footer)
        emailTempPreview.innerHTML = this.emailTemplateData
      }, 250);
    }
  }

  togglePreview() {
    this.data.action = this.data.action == 'edit' ? 'view' : 'edit'
    this.setPreview()
  }

}
