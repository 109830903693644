/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-redirect-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "@angular/common";
import * as i5 from "./confirm-redirect-dialog.component";
var styles_ConfirmRedirectDialogComponent = [i0.styles];
var RenderType_ConfirmRedirectDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmRedirectDialogComponent, data: {} });
export { RenderType_ConfirmRedirectDialogComponent as RenderType_ConfirmRedirectDialogComponent };
function View_ConfirmRedirectDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "usr-instruction col-md-12 col pad-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You will be redirected to an external website "]))], null, null); }
function View_ConfirmRedirectDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "usr-instruction col-md-12 col pad-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please refresh after completion of Esign "]))], null, null); }
function View_ConfirmRedirectDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "col-xs-12 col-sm-12 col-md-12 header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-xs-6 col-sm-6 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["class", "dialogue-title"], ["style", "padding: 20px;font-size: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Redirect"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-xs-6 col-sm-6 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close dialogue-title"], ["style", "float: right;padding: 20px;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-times dialogue-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "mat-divider", [["class", "divider-line mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(10, 49152, null, 0, i3.MatDivider, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "col-xs-12 col-sm-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmRedirectDialogComponent_2)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmRedirectDialogComponent_3)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "cmn-button float-right  margin-right-20"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "cmn-button float-right margin-right-20"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "cmn-button float-right margin-right-20"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Refresh "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.redirected; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.redirected; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 10).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 10).vertical; var currVal_2 = !i1.ɵnov(_v, 10).vertical; var currVal_3 = i1.ɵnov(_v, 10).inset; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.redirected; _ck(_v, 19, 0, currVal_6); var currVal_7 = _co.redirected; _ck(_v, 21, 0, currVal_7); var currVal_8 = !_co.redirected; _ck(_v, 23, 0, currVal_8); }); }
export function View_ConfirmRedirectDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmRedirectDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDialog; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConfirmRedirectDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-redirect-dialog", [], null, null, null, View_ConfirmRedirectDialogComponent_0, RenderType_ConfirmRedirectDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.ConfirmRedirectDialogComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmRedirectDialogComponentNgFactory = i1.ɵccf("app-confirm-redirect-dialog", i5.ConfirmRedirectDialogComponent, View_ConfirmRedirectDialogComponent_Host_0, { showDialog: "showDialog", redirectUrl: "redirectUrl" }, { visibleChange: "visibleChange" }, []);
export { ConfirmRedirectDialogComponentNgFactory as ConfirmRedirectDialogComponentNgFactory };
