import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-close-tab',
  templateUrl: './close-tab.component.html',
  styleUrls: ['./close-tab.component.scss']
})
export class CloseTabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
