<div>
    <nav [style.z-index]="isDialogShown == 'block' ? 0 : 95" class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <div style="padding: 5px;">
                <a class="navbar-brand" id="logo" (click)="logoClick()"><img style="height: 30px !important;" id="img"
                        alt="Brand" src="../../assets/logo/logo.svg"></a>
                <!-- <div>
            <ul class="nav nav-tabs">
              <li  *ngFor="let data of currentTabList" class="nav-item">
                <a class="nav-link" [routerLink]="data.redirectPath"  routerLinkActive="active">{{data.name}}</a>
              </li>
            </ul>
          </div> -->
                <!-- <button mat-icon-button *ngIf="showbackbutton()"  (click)="backClicked()" > <mat-icon class="fa fa-chevron-circle-left fa-lg"></mat-icon> </button> -->

            </div>
            <div class="mx-auto" style="width: 70%;">
            <nav *ngIf="!empLogin && userLoaded" mat-tab-nav-bar backgroundColor="primary" #tabs style="overflow-x: auto;"
                class="navbar-spacing">
                <a mat-tab-link class="align-baseline" *ngFor="let link of currentTabList"
                    [hidden]="link['shouldHide']==true || showTermSheets(link.name)"
                    [routerLink]="link.redirectPath" routerLinkActive="active"
                    (click)="activeLink = link.redirectPath" [active]="activeLink == link.redirectPath">
                    {{link.name}}
                </a>
            </nav>

            </div>
            <!-- <span *ngIf="curCompany != null" class="company-name vertical-alignment">{{ curCompany }}</span> -->
            <div>
                <ul class="navbar-nav">
                    <!-- <span *ngIf="fullAccess">
                    <li *ngIf="incorporationDetails" class="nav-item dropdown navbar-right" style="height: 100%;">
                        <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                            <img src="../../assets/ic_apps.svg" alt="Setting"/></a>
                        <div class="dropdown-menu">
                            <ul class="list-group"> -->
                    <!-- <li class="list-group-item" [routerLink]="['/landing', 'dashboard', 'companyProfile']">Company Profile</li> -->
                    <!-- <li class="list-group-item" [routerLink]="['/landing', 'dashboard', 'usersRoles']">Users and Roles</li> -->
                    <!-- <li class="list-group-item" [routerLink]="['/landing', 'dashboard', 'shareCapital']">Capital Settings</li>
                            </ul>
                        </div>
                    </li>
                </span> -->
                    <!-- <li class="nav-item navbar-right">
                    <a class="nav-link" href="#"><img src="../../assets/notes.svg" alt="Notes"></a>
                </li> -->
                    <li ngbTooltip="You've got some Termsheets" *ngIf="doesTSExist" style="right:15px;"
                        class="nav-item dropdown navbar-right">
                        <a class="nav-link dropdown-toggle" style="padding-right: 3px !important;" href="#"
                            id="navbardrop" data-toggle="dropdown">
                            <mat-icon matBadgeColor="primary" matBadge="{{currencySymbol}}"><img src="../../assets/notification.svg"
                                    alt="Notification"></mat-icon>

                        </a>
                        <div class="dropdown-menu">
                            <ul class="list-group">

                                <li class="list-group-item term-sheet-card">
                                    <div (click)="goToTermSheets()" class="row">
                                        <div class="col-md-3 term-sheet-icon">
                                            <p class="dollar-sign">{{currencySymbol}}{{currencySymbol}}</p>
                                            <p class="card-header-txt">TERM SHEET</p>
                                        </div>
                                        <div class="col-md-9" style="padding: 14px 30px 14px 14px;">
                                            <mat-label class="term-sheet-txt" style="font-size: 18px !important">{{
                                                termSheet?.investorDetails[0].investorName }}</mat-label>
                                            <div>
                                                <mat-label class="term-sheet-txt top-5">{{
                                                    formatCurrency(termSheet?.investorDetails[0].amountCommited) }} at Pre-Money {{
                                                    formatCurrency(termSheet?.valuation) }}</mat-label>
                                            </div>
                                            <div>
                                                <mat-label class="term-sheet-txt top-5"><i>Expires: <mat-label
                                                    class="term-sheet-txt mat-label-value">{{ termSheet?.expiryDate
                                                    | date:'dd-MMM-yyyy' }}</mat-label></i></mat-label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li [matMenuTriggerFor]="mainMenu" class="nav-item dropdown multi-level-dropdown navbar-right">
                        <a href="javascript:void(0)" [matMenuTriggerFor]="mainMenu" id="menu"
                            class="nav-link dropdown-toggle">
                            <div class="row"
                                style="border: 1px solid #F2F2F2;/* outline: yellow; *//* */height: 46px; border-radius: 5px !important;">
                                <div class="col-md-3 col-sm-3" style="margin-right: 10px;margin-top: -9px;">
                                    <img *ngIf="profilePicture" class="profile cursor-hand" src="{{profilePicture}}"
                                        alt="Admin profile" />
                                    <div *ngIf="!profilePicture" id="profileImage">{{profileInitials}}</div>
                                </div>
                                <div class="col-md-8 col-sm-8 top-10" style="padding-left: 5px;">
                                    <div style="display:flex">
                                        <div class="nvbar-options name-overflow" style="margin-top: -9px;">
                                            <p [ngbTooltip]="userName" class="username-display">
                                                {{ userName }}
                                            </p>
                                            <p [ngbTooltip]="curCompany" class="company">
                                                {{curCompany}}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- <div class="nav-usr-role">{{ userRole }}</div> -->
                                </div>
                                <div class="top-10" style="margin-top: 20px !important;margin-left: -9px !important;"><i
                                        class="fa fa-angle-down apps-icon" aria-hidden="true"></i></div>
                            </div>
                        </a>
                        <mat-menu class="mat-menu" [backdropClass]="'mat-menu'" [panelClass]="'mat-menu'" #mainMenu="matMenu">
                    <li *ngIf="incorporationDetails || isEsopOnlyCompany" (click)="gotoUserProfile()" mat-menu-item>
                        Profile</li>
                    <li mat-menu-item
                        *ngIf="incorporationDetails || this.navbarService.isHistoricalRound() || usrCompanies != null"
                        [matMenuTriggerFor]="switchCompanyMenu">Switch Company</li>
                    <li mat-menu-item (click)="addNewComp()"
                        *ngIf="fullAccess && incorporationDetails || this.navbarService.isHistoricalRound()">
                        Add New Company
                    </li>
                    <li mat-menu-item class="list-group-item" (click)="logout()">Logout</li>
                    </mat-menu>
                    <mat-menu class="switch-company-menu" #switchCompanyMenu="matMenu">
                        <li (click)="handleSearchBarClick($event)" id="searchMenuInput" mat-menu-item
                            *ngIf="usrCompanies?.length>5"> <input id="searchInput" autocomplete="off" type="search"
                                class="form-control rounded" #searchInput placeholder="Search..."
                                (input)="filterCompanies(searchInput.value)" (click)="onClearSearchInput(searchInput)">
                        </li>
                        <li mat-menu-item *ngFor="let company of filteredCompanies"
                            (click)="selectedCompany(company.id)">
                            <span style="word-break:normal;">{{company.nameOfTheCompany}}</span>
                        </li>
                    </mat-menu>
                    </li>

                </ul>
            </div>
            <!-- test -->
        </div>
    </nav>
</div>