import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from "../../authentication.service";
import { UtilityService } from "../../services/utilities.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-calc-valuation-popup',
  templateUrl: './calc-valuation-popup.component.html',
  styleUrls: ['./calc-valuation-popup.component.scss']
})
export class CalcValuationPopupComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() securityClassId;
  @Input() companyCurrency;
  @Input() roundCurrency;
  @Input() isValuationSet;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  securityClassDetails;
  discountDetails;
  showNotes;
  notes;
  valForConversion = true;
  selectedMetricId;
  isUpdateConversionRatio = true;
  convertedSecurityClassId;
  securitiesList;
  shouldShowDialog: boolean = false;
  selInstruments = [];
  selectedIndex;
  isCCDOrNote: boolean;

  constructor(private authService: AuthenticationService, private utilityService: UtilityService, private ngxLoader: NgxUiLoaderService, private toastr: ToastrService) { }

  ngOnInit() {
    this.utilityService.showOverlay()
    this.ngxLoader.start();
    this.loadJSStyles();
    this.authService.getValuationDetails(this.securityClassId).subscribe(
        (res) => {
            this.securityClassDetails = res;
            this.isCCDOrNote = this.securityClassDetails.isCCDOrNote;
            this.isUpdateConversionRatio = res['isUpdateConversionRatio'];
            this.securitiesList = res['securityClassList'];
            if(this.securityClassDetails.metricsTable.length > 0) {
              for (const element of this.securityClassDetails.metricsTable) {
                if(element['isSelected']) {
                  this.selectedMetricId = element['id'];
                  break;
                }
              }
              if(!this.selectedMetricId) {
                this.selectedMetricId = this.securityClassDetails.metricsTable[0].id;
              }
              let metricsObj = {
                "id": this.securityClassId,
                "conversionDate": _.find(this.securityClassDetails.metricsTable, { 'id': this.selectedMetricId }).eventTargetDate,
                "selectedId": this.selectedMetricId
              }
              this.getDetails(metricsObj);
            }
            this.formatData();
            this.loadJSStyles();
            this.ngxLoader.stop();
        },
        (e) => {}
    );
  }

  loadJSStyles() {
    $(document).ready(() => {
        $('[data-toggle="switch"]').bootstrapSwitch({
            onText: "Yes",
            offText: "No",
          });
          if(this.isCCDOrNote) {
            $('#allowUpdate').bootstrapSwitch('state', false);
            $('#allowUpdate').bootstrapSwitch('disabled',true);
          }
          $('#allowUpdate').bootstrapSwitch('state', this.isUpdateConversionRatio);
          $("#allowUpdate").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
            this.isUpdateConversionRatio = state;
          })
    });
  }

  instClassChange(instClass) {
    if (instClass == "New Security Class") {
        this.ngxLoader.start();
        this.shouldShowDialog = true;
        this.selInstruments = [];
        this.securitiesList.forEach(element => {
            this.selInstruments.push(element['instrument']);
        });
        this.selectedIndex = 0;
    }
  }

  saveSecurity(event) {
      console.log('response received: ', event);
      this.shouldShowDialog = false;
      this.securitiesList.push(event['security']);
      this.convertedSecurityClassId = event['security']['id']
      this.ngxLoader.stop();
  }

  formatCurrency(value) {
    if (value > 0) {
        if (value.toString().length > 5) {
            return this.utilityService.convertedFormatCurrency(this.companyCurrency, value);
        }
        else {
            return this.utilityService.formatCurrency(this.companyCurrency, value);
        }
    }
    else {
        return 0;
    }
  }

  getCSV(value) {
    return this.utilityService.formatCurrency(this.companyCurrency, value);
  }

  formatData() {
    if(this.securityClassDetails.valuationUsedForConversion) {
        this.securityClassDetails.valuationUsedForConversion = this.utilityService.convertToCompanyCurrency(this.securityClassDetails.valuationUsedForConversion);
        // this.converttoCSV(this.securityClassDetails.valuationUsedForConversion);
    }
    if(this.securityClassDetails.conversionDate) {
    let dt = new Date(this.securityClassDetails.conversionDate);
    this.securityClassDetails.conversionDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
    }
  }

  close() {
    this.utilityService.hideOverlay()
    this.showDialog = false;
    this.visibleChange.emit();
  }

  calculateDiscount() {
    let dt = new Date(this.securityClassDetails.conversionDate);
    let convDate = dt.toISOString().replace("Z", "+0000");
    let obj = {
        "id": this.securityClassId,
        "conversionDate": convDate
    }
    this.getDetails(obj);
  }

  getDetails(obj) {
    this.authService.calculateDetails(obj).subscribe(
        (res) => {
            this.discountDetails = true;
            this.securityClassDetails = res;
            if(this.securityClassDetails.metricsTable.length > 0) {
                this.securityClassDetails.metricsTable.forEach(element => {
                    if(element.id == this.selectedMetricId) {
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });
            }
            this.formatData();
            setTimeout(() => {
              let discount = document.getElementById("discountDetailsDiv");
              this.utilityService.focusSection(discount);
            }, 200);
        },
        (e) => {}
    );
  }

  addNotes(val) {
    if (val) {
      this.showNotes = val;
    } else {
      this.showNotes = val;
    }
  }

  selectedMetric(selectedId) {
    this.selectedMetricId = selectedId; 
    let metricsObj = {
        "id": this.securityClassId,
        "conversionDate": _.find(this.securityClassDetails.metricsTable, { 'id': this.selectedMetricId }).eventTargetDate,
        "selectedId": selectedId
    }
    this.getDetails(metricsObj);
  }

  onValuationchangeMetrics(valuation) {
      let metricsObj = {
        "id": this.securityClassId,
        "conversionDate": _.find(this.securityClassDetails.metricsTable, { 'id': this.selectedMetricId }).eventTargetDate,
        "valuationUsedForConversion": valuation.replace(/,/g, "") / 1
      }
      this.getDetails(metricsObj);
  }

  onValuationChangeDiscount(valuation) {
    if(this.securityClassDetails['conversionDate']) {
        let dt = new Date(this.securityClassDetails.conversionDate);
        let convDate = dt.toISOString().replace("Z", "+0000");
        let discountObj = {
            "id": this.securityClassId,
            "conversionDate": convDate,
            "valuationUsedForConversion": valuation.replace(/,/g, "") / 1
        }
        this.getDetails(discountObj);
    } else {
        this.toastr.error('Please enter the Conversion Date', "Error");
        return;
    }
  }

  saveValuation() {
    let valuationObj = {}
    let dt = new Date(this.securityClassDetails.conversionDate);
    this.securityClassDetails.conversionDate = dt.toISOString().replace("Z", "+0000");
    if(!this.isUpdateConversionRatio) {
        if(!this.convertedSecurityClassId || this.convertedSecurityClassId == null) {
            this.toastr.error('Convert to security class is required');
            return;
        }
    } 
    if(this.isCCDOrNote) {
        if(!this.convertedSecurityClassId || this.convertedSecurityClassId == null) {
            this.toastr.error('Convert to security class is required');
            return;
        }
    }
    if (this.securityClassDetails.discountTable.length > 0) {
        valuationObj = {
            "id" : this.securityClassId ,
            "conversionDate" : this.securityClassDetails.conversionDate,
            "isUpdateConversionRatio": this.isUpdateConversionRatio,
            "convertedSecurityClassId": this.convertedSecurityClassId,
            "valuationUsedForConversion" : this.securityClassDetails.valuationUsedForConversion.replace(/,/g, "") / 1,
            "newConversionRatio" : this.securityClassDetails.newConversionRatio,
            "totalDiscount" : this.securityClassDetails.totalDiscount,
            "discountApplied" : this.securityClassDetails.discountApplied,
            "notes" : this.notes
        }
    } else if (this.securityClassDetails.metricsTable.length > 0) {
        valuationObj = {
            "id" : this.securityClassId ,
            "conversionDate" : this.securityClassDetails.conversionDate,
            "isUpdateConversionRatio": this.isUpdateConversionRatio,
            "convertedSecurityClassId": this.convertedSecurityClassId,
            "selectedId" : this.selectedMetricId,
            "valuationUsedForConversion" : this.securityClassDetails.valuationUsedForConversion ? this.securityClassDetails.valuationUsedForConversion.replace(/,/g, "") / 1 : this.securityClassDetails.valuationUsedForConversion,
            "newConversionRatio" : this.securityClassDetails.newConversionRatio,
            "totalDiscount" : this.securityClassDetails.totalDiscount,
            "discountApplied" : this.securityClassDetails.discountApplied,
            "notes" : this.notes
        }
    }
    this.authService.saveValuation(valuationObj).subscribe(
        (res) => {
            this.close();
        },
        (e) => {
          this.toastr.error(e.error.message, "Error!");
        }
    );

  }

  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  converttoCSV(value) {
    value = value.replace(/,/g, "") / 1;
    let csv = this.utilityService.convertToCompanyCurrency(value);
    this.securityClassDetails.valuationUsedForConversion = csv;
  }

}
