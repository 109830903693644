import { AfterViewChecked, AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuClickEventArgs, MenuOpenEventArgs } from '@syncfusion/ej2-angular-filemanager';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit, AfterViewChecked {
    @Input() path: String;
    contextMenuSettings  = {
        file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', 'Tags', '|', 'Details'],
        folder: ['Open', '|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', 'Download', 'Tags', '|', 'Details'],
        layout: ['SortBy', 'View', 'Refresh', '|', 'Paste', '|', 'NewFolder', 'Upload', '|', 'Details', '|', 'SelectAll'],
        visible: true
    };
    detailsViewSettings = {
        columns: [
            {field: 'name', headerText: 'File Name', minWidth: 120, width: 'auto', customAttributes: { class: 'e-fe-grid-name' },template: '${name}'},
            {field: 'size', headerText: 'File Size',minWidth: 50, width: '110', template: '${size}'},
            {field: '_fm_modified', headerText: 'Date Modified',minWidth: 50, width: '190'},
            {field: 'tags', headerText: 'Tags', minWidth: 50, width: 'auto', template: '${tags}'}
        ]
    };
    ajaxSettings;
    fileId;
    tagsString;
    showTagsPopup = false;
    companyName;
    parentIds:string = "";
    folderName:string = "";

  constructor(private authService: AuthenticationService,
    public route: ActivatedRoute) { }

  ngOnInit() {
    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.companyName = userDetails['company']['nameOfTheCompany'];

    this.route.params.subscribe(params => {
      this.folderName = params.folderName == undefined || params.folderName == 'null' ? "" : decodeURIComponent(params.folderName);
    });

    this.ajaxSettings = {    
      url: this.authService.baseURL + "fileManager/fileOperations/"+this.companyName,
      downloadUrl: this.authService.baseURL + "fileManager/download",
      uploadUrl: this.authService.baseURL + "fileManager/upload",
      getImageUrl: this.authService.baseURL + "fileManager/image",
      allowedExtensions: ".csv,.pdf,.doc,.docx,.xls,.xlsx,.txt"
    };
    
    if(this.path) {
      this.getParentIdsFromPath();
    }
  }
  ngAfterViewChecked(){
    var fileManager = document.getElementById("default-filemanager")['ej2_instances']
    if(fileManager){
      fileManager = fileManager[0]
      fileManager.uploadSettings.allowedExtensions = ".csv,.pdf,.doc,.docx,.xls,.xlsx,.txt"
    }
  }

  beforeSend(args: any) {
    var data = JSON.parse(args.ajaxSettings.data); 

    if(this.path && data["action"] == "read" && data["data"].length == 0) {
      data["path"] = this.companyName + '/' + this.path;
      args.ajaxSettings.url =  this.authService.baseURL + "fileManager/files/path/";
    }
    else if(this.path && data.length > 0 && data[1]["action"] == 'save') {
      data[0]['path'] = this.parentIds;
    } else if(this.folderName) {
      data["path"] = this.folderName;
      args.ajaxSettings.url = this.authService.baseURL + "fileManager/files/path/";
    } else {
      args.ajaxSettings.url = this.authService.baseURL + "fileManager/fileOperations/"+this.companyName;
    }
    
    args.ajaxSettings.data = JSON.stringify(data);
    args.ajaxSettings.beforeSend = function (args) {
      args.httpRequest.setRequestHeader("x-user-token", localStorage.getItem('currentUser'));
      args.httpRequest.setRequestHeader("companyId", localStorage.getItem('companyId'));
    }
  }

  menuOpen(args: MenuOpenEventArgs) {
    for(var i=0;i<args.items.length;i++) {
        if (args.items[i].text === 'Tags') {
            args.items[i].iconCss= 'fa fa-tag';
        }
    }
    var fileManager = document.getElementById("default-filemanager")['ej2_instances'][0];
    var count = fileManager.getSelectedFiles().length; 
    if (count > 1) { 
      fileManager.disableMenuItems(["Tags"]); 
    } 
  }

  menuClick(args: MenuClickEventArgs) {
    if (args.item.text === 'Tags') {
        let fileCategories = args.fileDetails[0]['tags'];
        this.fileId = args.fileDetails[0]['id'];
        this.tagsString = fileCategories.join(',');
        this.showTagsPopup = true;
        document.getElementById("overlay").style.display = "block";
    }
  }

  getTagsString(tags) {
    console.log('tags received: ', tags);
    if(tags != 'false') {
        let tagsArr = tags.split(',');
        let tagsObj = {
            "id": this.fileId,
            "tags": tagsArr
        };
        this.authService.updateCategoryTags(tagsObj).subscribe(
            (res) =>  {
                document 
                .getElementsByClassName("e-grid")[0] 
                .querySelector(".e-focused").lastChild.textContent = tags; 
                var fileManager = document.getElementById("default-filemanager")['ej2_instances'][0];
                fileManager.refreshFiles();
            },
            (e) => {}
        );
    }    
    document.getElementById("overlay").style.display = "none";
    this.showTagsPopup = false;
  }

  getParentIdsFromPath() {
    let path = `${this.companyName}/${this.path}`;
    let obj = {
      path: path
    }
    
    this.authService.getParentIdsFromPath(obj).subscribe((res:any)=> {
      if(res.resultCode) {
        this.parentIds = res.resultCode;
      }
    })
  }

}
