<div class="hrms-login-dialog">
  <div class="header">
      <div class="row">
          <div class="col-md-10 custom-modal-header">
              <label class="dialogue-title">Adjust Conversion Ratio</label>
          </div>
          <div class="col-md-2 close-icon">
            <button type="button" class="close" (click)="close()" aria-label="Close" id="close" [mat-dialog-close]="'close'">
                <span class="close">X</span>
            </button>
          </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div>
            <div class="form-card">

                <div class="popup-item top-margin">
                  <mat-label>Current Conversion Ratio</mat-label>
                  <mat-label-value>{{data.ratio}}</mat-label-value>
                </div>

                <!-- <div class="popup-item top-margin">
                  <mat-label>Issues Shares</mat-label>
                  <mat-label-value>{{data.numberSharesForBonus}}</mat-label-value>
                </div>

                <div class="popup-item top-margin">
                  <mat-label>Current FDB Shares</mat-label>
                  <mat-label-value>{{data.fdbShares}}</mat-label-value>
                </div> -->

                <div class="popup-item top-margin">
                  <mat-label class="required">New Conversion Ratio</mat-label>
                  <input class="form-control input-box" type="text" id="updatedConversionRatio" [(ngModel)]="data.updatedConversionRatio" 
                   (keyup)="calculateNewFdbShares($event)" (keydown)=onKeydownEvent($event) (keypress)="onlyNumberKey($event)" required />

                </div>

                <div class="popup-item top-margin">
                  <mat-label class="required">New FDB Shares</mat-label>
                  <input class="form-control input-box" disabled type="text" id="newFdbShares" [ngModel]="data.fdbSharesForAdjustConversionRatio" 
                    required />
                </div>

                <div class="top-margin">

                    <button mat-raised-button id="next" color="success" class="float-right" [disableRipple]="true" (click)="goToSecurityDetails()"
                      ngbTooltip="Next">
                      Confirm
                    </button>

                    <button mat-raised-button id="back" color="warn" [disableRipple]="true" class="float-right margin-right-20"
                      ngbTooltip="Back"(click)="close()">
                      Cancel
                    </button>

                </div>

            </div>
          </div>
        </div>
      </div>
  </div>

</div>