import { Routes } from "@angular/router";
import { ForgotPasswordComponent } from "./login-signup/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./login-signup/reset-password/reset-password.component";
import { LandingFormComponent } from "./login-signup/landing-form/landing-form.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthGuard } from "./auth.guard";
import { OnboardingComponent } from "./onboarding/onboarding/onboarding.component";
import { LandingPageComponent } from "./login-process/landing-page/landing-page.component";
import { CapTableComponent } from "./dashboard/cap-table/cap-table.component";
import { ShareholderDetailsComponent } from "./shareholders/shareholder-details/shareholder-details.component";
// import { CreateEsopComponent } from "./esop-management/components/create-esop/create-esop.component";
// import { OverviewEsopComponent } from "./esop-management/components/overview-esop/overview-esop.component";
// import { ManageEsopComponent } from "./esop-management/components/manage-esop/manage-esop.component";
// import { ExitModellingComponent } from "./modeling/exit-modelling/exit-modelling.component";
import { DocumentOverviewComponent } from "./documents/document-overview/document-overview.component";
import { CreateRoundDocumentComponent } from "./documents/create-round-document/create-round-document.component";
import { SecondaryComponent } from "./secondary/secondary.component";
import { ShareCapitalComponent } from "./share-capital/share-capital.component";
import { RoundsComponent } from "src/app/rounds/rounds.component";
import { ShareholdersComponent } from "src/app/shareholders/shareholders.component";
import { SecuritiesComponent } from "src/app/securities/securities.component";
import { ModelingComponent } from "src/app/modeling/modeling.component";
import { OptionsComponent } from "src/app/esop-management/options.component";
import { UsersRolesComponent } from "src/app/users-roles/users-roles.component";
import { AccountConfirmComponent } from "src/app/onboarding/account-confirm/account-confirm.component";
import { CompanyProfileComponent } from "src/app/company-profile/company-profile.component";
import { SearchResultsComponent } from './search-results/search-results.component';
// import { EmployeesComponent } from "./employees/employees.component";
// import { EmployeeDetailsComponent } from "./employees/employee-details/employee-details.component";
import { BaseComponent } from "./login-process/landing-page/base/base.component";
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
// import { AddPoolComponent } from "./esop-management/components/add-pool/add-pool.component";
import { FileConversionComponent } from "./file-conversion/file-conversion.component";
import { EmployeesComponent } from "./employees/employees.component";
import { CanLoadGuard } from "./auth-guards/can-load.guard";
import { CanActivateGuard } from "./auth-guards/can-activate.guard";
import { EmployeeLoginComponent } from "./employees/employee-login/employee-login.component";
import { UnauthorizedComponent } from "./error-pages/unauthorized/unauthorized.component";
import { NotFoundComponent } from "./error-pages/not-found/not-found.component";
import { BuyBackComponent } from "./buy-back/buy-back.component";
import { SplitsComponent } from "./splits/splits.component";
import { BonusComponent } from "./bonus/bonus.component";
import { SettingsComponent } from "./settings/settings.component";
import { SiteSettingsComponent } from "./settings/site-settings/site-settings.component";
import { TransactionsComponent } from "./transactions/transactions.component";
import { TermSheetsComponent } from "./term-sheets/term-sheets.component";
import { OtpVerifyComponent } from './login-signup/otp-verify/otp-verify.component';
var ɵ0 = { permissionRequired: ['captable-summary'] }, ɵ1 = { permissionRequired: ['full-access'] }, ɵ2 = { permissionRequired: ['captable-summary'] }, ɵ3 = { permissionRequired: ['captable-summary'] }, ɵ4 = { permissionRequired: ['shareholder-viewer'] }, ɵ5 = { permissionRequired: ['captable-summary'] }, ɵ6 = { permissionRequired: ['captable-summary'] }, ɵ7 = { permissionRequired: ['captable-summary'] }, ɵ8 = { permissionRequired: ['full-access'] }, ɵ9 = { permissionRequired: ['full-access'] }, ɵ10 = { permissionRequired: ['full-access'] }, ɵ11 = { permissionRequired: ['full-access'] }, ɵ12 = { permissionRequired: ['opts-management-view', 'opts-homepage-view'] }, ɵ13 = { permissionRequired: ['emp-view', 'employee-page-viewer'] }, ɵ14 = { permissionRequired: ['captable-summary'] };
var routes = [
    { path: "", component: LandingFormComponent },
    { path: "employeeLogin", component: EmployeeLoginComponent },
    {
        path: "base",
        component: BaseComponent
    },
    {
        path: "forgotPassword",
        component: ForgotPasswordComponent,
    },
    {
        path: "resetPassword",
        component: ResetPasswordComponent,
    },
    {
        path: "verifyOtp",
        component: OtpVerifyComponent,
    },
    {
        path: "landing",
        component: LandingPageComponent,
        children: [
            {
                path: "verification/:code",
                component: AccountConfirmComponent
            },
            {
                path: "onboarding",
                component: OnboardingComponent,
                canActivate: [AuthGuard],
                loadChildren: "./onboarding/onboarding.module#OnboardingModule"
                // children: [
                //   { path: "", component: CompanyNameComponent },
                //   { path: "adminProfile", component: AdminProfileComponent },
                //   { path: "companyDetails", component: CompanyDetailsComponent },
                //   { path: "create-historical-round", component: HistoricalRoundCreationComponent},
                //   { path: "historical-round-summary", component: HistoricalRoundSummaryComponent},
                //   { path: "incorporationDetails", component: IncorporationDetailsComponent },
                // ]
            },
            {
                path: "dashboard",
                component: DashboardComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: "404", component: NotFoundComponent },
                    { path: "401", component: UnauthorizedComponent },
                    { path: 'documents', component: FileManagerComponent },
                    { path: "search-results", component: SearchResultsComponent },
                    { path: "capTable", component: CapTableComponent, canActivate: [CanActivateGuard], data: ɵ0 },
                    { path: "settings", component: SettingsComponent, canActivate: [CanActivateGuard], data: ɵ1, children: [
                            { path: "companyProfile", component: CompanyProfileComponent },
                            { path: "siteSettings", component: SiteSettingsComponent },
                        ] },
                    {
                        path: "rounds",
                        component: RoundsComponent,
                        loadChildren: "./rounds/rounds.module#RoundsModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ2
                        // children: [
                        //   { path: "overview", component: RoundsOverviewComponent },
                        //   {
                        //     path: "round-details/:roundName",
                        //     component: RoundDetailsComponent,
                        //   },
                        //   {
                        //     path: "newRound",
                        //     component: CreateNewRoundComponent,
                        //     canDeactivate: [RoundDeactivateGuard],
                        //     children: [
                        //       { path: "round", component: RoundTabComponent },
                        //       {
                        //         path: "currentInvestors",
                        //         component: CurrentInvestorsTabComponent,
                        //       },
                        //       { path: "newInvestors", component: NewInvestorsTabComponent },
                        //       { path: "instrument", component: InstrumentTabComponent },
                        //       { path: "capTable", component: CapTableTabComponent },
                        //       { path: "summary", component: SummaryComponent },
                        //     ],
                        //   },
                        // ],
                    },
                    {
                        path: "shareholders",
                        component: ShareholdersComponent,
                        loadChildren: "./shareholders/shareholders.module#ShareholdersModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ3
                        // children: [
                        //   { path: "overview", component: ShareholdersOverviewComponent },
                        //   { path: "details/:id", component: ShareholderDetailsComponent },
                        // ],
                    },
                    {
                        path: "shareholder/:id",
                        component: ShareholderDetailsComponent,
                        // loadChildren:"./shareholders/shareholders.module#ShareholdersModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ4
                    },
                    {
                        path: "buy-back",
                        component: BuyBackComponent,
                        loadChildren: "./buy-back/buy-back.module#BuyBackModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ5
                    },
                    {
                        path: "securities",
                        component: SecuritiesComponent,
                        loadChildren: "./securities/securities.module#SecuritiesModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ6
                        // children: [
                        //   { path: "overview", component: SecuritiesOverviewComponent },
                        //   {
                        //     path: "addNewInstrumentclass",
                        //     component: AddNewInstrumentclassComponent,
                        //   },
                        //   { path: "instrumentClass", component: InstrumentClassComponent },
                        // ],
                    },
                    {
                        path: "secondary",
                        component: SecondaryComponent,
                        loadChildren: "./secondary/secondary.module#SecondaryModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ7
                        // children: [
                        //   { path: "overview", component: SecondaryOverviewComponent },
                        //   {
                        //     path: "secondary-management",
                        //     component: SecondaryMgmtComponent,
                        //   },
                        //   { path: "secondary-seller", component: SecondarySellerComponent },
                        //   { path: "secondary-buyer", component: SecondaryBuyerComponent },
                        //   {
                        //     path: "secondary-summary",
                        //     component: SecondarySummaryComponent,
                        //   },
                        // ],
                    },
                    {
                        path: "splits",
                        component: SplitsComponent,
                        loadChildren: "./splits/splits.module#SplitsModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ8
                        // children: [
                        //   { path: "overview", component: SecondaryOverviewComponent },
                        //   {
                        //     path: "secondary-management",
                        //     component: SecondaryMgmtComponent,
                        //   },
                        //   { path: "secondary-seller", component: SecondarySellerComponent },
                        //   { path: "secondary-buyer", component: SecondaryBuyerComponent },
                        //   {
                        //     path: "secondary-summary",
                        //     component: SecondarySummaryComponent,
                        //   },
                        // ],
                    },
                    {
                        path: "bonus",
                        component: BonusComponent,
                        loadChildren: "./bonus/bonus.module#BonusModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ9
                    },
                    {
                        path: "transactions",
                        component: TransactionsComponent,
                        loadChildren: "./transactions/transactions.module#TransactionsModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ10
                    },
                    {
                        path: "term-sheets",
                        component: TermSheetsComponent,
                        loadChildren: "./term-sheets/term-sheets.module#TermSheetsModule",
                        canActivate: [CanActivateGuard],
                        data: ɵ11
                    },
                    // {
                    //   path: "options",
                    //   component: OptionsComponent,
                    //   children: [
                    //     { path: "addPool", component: AddPoolComponent },
                    //     { path: "addPool/:edit", component: AddPoolComponent },
                    //     { path: "createEsop", component: CreateEsopComponent },
                    //     { path: "createEsop/:name", component: CreateEsopComponent },
                    //     { path: "overviewEsop", component: OverviewEsopComponent },
                    //     { path: "manageEsop", component: ManageEsopComponent},
                    //     { path: "employee",
                    //       component: EmployeesComponent,
                    //       children: [
                    //           { path: "employeeDetails/:id/:planName", component: EmployeeDetailsComponent }
                    //       ]
                    //     }
                    //   ],
                    // },
                    {
                        path: "options",
                        component: OptionsComponent,
                        canLoad: [CanLoadGuard],
                        canActivate: [CanActivateGuard],
                        data: ɵ12,
                        loadChildren: "./esop-management/esop.module#EsopModule"
                    },
                    {
                        path: "employees",
                        component: EmployeesComponent,
                        canLoad: [CanLoadGuard],
                        canActivate: [CanActivateGuard],
                        data: ɵ13,
                        loadChildren: "./employees/employees.module#EmployeesModule"
                    },
                    {
                        path: "modeling",
                        component: ModelingComponent,
                        loadChildren: "./modeling/modeling.module#ModelingModule",
                    },
                    { path: "document", component: DocumentOverviewComponent },
                    { path: "newDocument", component: CreateRoundDocumentComponent },
                    { path: "shareCapital", component: ShareCapitalComponent },
                    { path: "usersRoles", component: UsersRolesComponent, canActivate: [CanActivateGuard], data: ɵ14 },
                    // { path: "companyProfile", component: CompanyProfileComponent , canActivate: [CanActivateGuard],  data: {permissionRequired: ['captable-summary', 'opts-management-view','opts-homepage-view']}},
                    // {
                    //   path: "taskManager",
                    //   component: TaskManagerComponent,
                    //   children: [
                    //     { path: "new-process", component: StartNewProcessComponent},
                    //     { path: "overview", component: TaskManagerOverviewComponent },
                    //     { path: "termSheet", component: TermSheetComponent },
                    //     { path: "step/:id", component: StepComponent}
                    //   ]
                    // },
                    { path: "convert-file", component: FileConversionComponent },
                    { path: "userProfile", component: UserProfileComponent },
                    {
                        path: "document-builder", loadChildren: "./build-document/build-document.module#BuildDocumentModule"
                    },
                    {
                        path: "task-manager",
                        loadChildren: "./task-manager/task-manager.module#TaskManagerModule"
                    },
                    {
                        path: "document-generator",
                        loadChildren: "./document-generator/document-generator.module#DocumentGeneratorModule"
                    }
                ],
            }
        ],
    },
    { path: "**", redirectTo: '/landing/dashboard/404' },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
