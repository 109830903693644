<div class="container-fluid graybg ">
  <div class="row top-20">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-9">
            <h5 class="page-hdr">Buy-back Details</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="row top-10">
    <div class="col-md-12">
        <p class="content-pg-1 btm-10">
          The complete summary of a Buy-back transaction is shown below. 
        </p>
        <p class="content-pg-1 btm-20">
          The cap table displays the impact of the Buy-back on the shareholders equity.
          It shows the difference in shares before and after the completion of Buy-back. 
        </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-7">
      <mat-card id="BuyBackDetails">
        <div class="row">
          <div class="col-md-4">
            <mat-label>Buy-back Name</mat-label>
            <p>{{buyBackDetails.buyBackName}}</p>
          </div>
          <div class="col-md-4">
            <mat-label>Buy-back Date</mat-label>
            <p>{{startDate}}</p>
          </div>
          <div class="col-md-4">
            <mat-label>Security Type</mat-label>
            <p>{{buyBackDetails.className}}</p>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-6">
            <mat-label>No of shares</mat-label>
            <p >{{getTotalValueTble(buyBackDetails.numberOfSharesBoughtBackActual)}}</p>
          </div>
          <div class="col-md-6">
            <mat-label>Price per share</mat-label>
            <p>{{getTotalValueTble(buyBackDetails.pricePerShare)}}</p>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-6">
            <mat-label>Dilution</mat-label>
            <p>{{postBuyBackConsolidation}}%</p>
          </div>
          <div class="col-md-6">
            <mat-label>Amount Bought Back</mat-label>
            <p ngbTooltip="{{formatCompanyCurrency(totalBuyBackAmount)}}">{{getTotalValueTble(totalBuyBackAmount)}}</p>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-summary-cap-table [dataSource]="holdersList" [displayCols]="displayCols"></app-summary-cap-table>
    </div>
  </div>
</div>