<div *ngIf="isShowTags" class="dialog">
    <div class="header">
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header">
          <label class="dialogue-title" style="font-size: 16px;">Tags</label>
  
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6">
          <button ngbTooltip="Close" type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
            <span class="close">X</span>
          </button>
        </div>
      </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <div class="row pad-sm">
        <div class="col-xs-12 col-sm-12 col-md-12">
            <input class="form-control top-10" type="text"
            placeholder="Add or Remove Tags" [(ngModel)]="tagsString" />
        </div>
    </div>
    <div class="row float-right pad-sm">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <button mat-raised-button id="save" color="success" [disableRipple]="true" ngbTooltip="Save"
            (click)="saveTags()">Save</button>
        </div>
      </div>
</div>