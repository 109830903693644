import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { Location } from "@angular/common";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from "@angular/router";
import { UtilityService } from "../services/utilities.service";
import { PermissionsService } from "../services/permissions.service";
import { NavbarService } from "../services/navbar-reload";
import * as $ from "jquery";
import { User } from "../user-profile/user-profile";
declare var bootbox: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  // backlabel=this.utilityService.backbutton;
  companyName;
  founders;
  currentTab = "CapTable";
  currentTabList = [];
  isCurrentRouteTransactions = false;
  // myUserPermission = {"admin":false,"capTableViwer":false,"employee":false, "optionsViewer":false}
  navigatingPermissions = {
    adminWithoutProforma: this.permService.authorizeUser([
      "admin-without-proforma",
    ]),
    captableSummary: this.permService.authorizeUser([
      "captable-summary",
      "!strict",
    ]),
    empView: this.permService.authorizeUser(["emp-view", "!strict"]),
    optsHomepageView: this.permService.authorizeUser([
      "opts-management-view",
      "opts-homepage-view",
      "!strict",
    ]),
    esopAdmin: this.permService.authorizeUser(["esop-admin", "!strict"]),
    esopViewer: this.permService.authorizeUser(["esop-admin", "!strict"]),
    shareholder: this.permService.authorizeUser([
      "shareholder-viewer",
      "!strict",
    ]),
    shareholderWithCaptable:
      this.permService.authorizeUser(["captable-summary", "!strict"]) &&
      this.permService.authorizeUser(["shareholder-viewer", "!strict"]),
  };
  currentsubTab = "";
  expand = false;
  expanded = false;
  expandedTransactions = false;
  expandedMenu = false;
  expandedOptions = false;
  expandedSettings = false;
  public deActivatingComp;
  public activatingComp;
  public toComp;

  name: string;
  menu: Array<any> = [];
  breadcrumbList: Array<any> = [];
  canUserSeeFaq =
    JSON.parse(sessionStorage.getItem("userDetails")).company.showFaq ||
    localStorage.getItem("userRole") == "Admin";

  esopoptionscreator = false;
  esoponlycomp = false;

  user =
    localStorage.getItem("userRole") == "Shareholder"
      ? "shareholder"
      : localStorage.getItem("userRole") == "Shareholder With CapTable"
      ? "shareholderWithCaptable"
      : localStorage.getItem("userRole") == "ROLE_ESOP_ADMIN"
      ? "esopAdmin"
      : localStorage.getItem("userRole") == "ROLE_CAPTABLE_ADMIN"
      ? "captableAdmin"
      : ""; //type of user
  userPerm; //component permissions of current user
  navmenu = {
    name: "Home",
    path: "dashboard",
    redirectPath: "./capTable",
    children: [
      {
        name: "Cap Table",
        path: "capTable",
        redirectPath: "./capTable",
      },
      {
        name: "Transaction",
        path: "transaction",
        redirectPath: "./rounds/overview",
        children: [
          {
            name: "Rounds",
            path: "rounds",
            redirectPath: "./rounds/overview",
          },
          {
            name: "Buy-backs",
            path: "buy-back",
            redirectPath: "./buy-back/overview",
          },
          {
            name: "Secondary",
            path: "secondary",
            redirectPath: "./secondary/overview",
          },
          {
            name: "Splits",
            path: "splits",
            redirectPath: "./splits/overview",
          },
          {
            name: "Bonus",
            path: "bonus",
            redirectPath: "./bonus/overview",
          },
          {
            name: "All-Transactions",
            path: "transactions",
            redirectPath: "./transactions/overview",
          },
          {
            name: "Term Sheets",
            path: "term-sheets",
            redirectPath: "./term-sheets/overview",
          },
        ],
      },
      {
        name: "Shareholders",
        path: "shareholders",
        redirectPath: "./shareholders/overview",
      },
      {
        name: "Securities",
        path: "securities",
        redirectPath: "./securities/overview",
      },
      {
        name: "Options",
        path: "options",
        redirectPath: "./options/overviewEsop",
        children: [
          {
            name: "ESOP Overview",
            path: "overviewEsop",
            redirectPath: "./options/overviewEsop",
          },
          {
            name: "Employees Overview",
            path: "overviewEmployees",
            redirectPath: "./options/overviewEmployees",
          },
          {
            name: "ESOP Management",
            path: "manageEsop",
            redirectPath: "./options/manageEsop",
          },
          {
            name: "Vesting Schedules",
            path: "vestingSchedule",
            redirectPath: "./splits/vestingSchedule",
          },
        ],
      },
      {
        name: "Modeling",
        path: "modeling",
        redirectPath: "./modeling/roundModelingTab",
        children: [
          {
            name: "Round Modeling",
            path: "roundModelingTab",
            redirectPath: "./modeling/roundModelingTab",
          },
          {
            name: "Esop Modeling",
            path: "esopModellingTab",
            redirectPath: "./modeling/esopModellingTab",
          },
          {
            name: "Exit Modeling",
            path: "exitModelling",
            redirectPath: "./modeling/exitModelling",
          },
        ],
      },
      {
        name: "Settings",
        path: "settings",
        redirectPath: "./settings",
        children: [
          {
            name: "Company Profile",
            path: "companyProfile",
            redirectPath: "./settings/companyProfile",
          },
          {
            name: "Site Settings",
            path: "siteSettings",
            redirectPath: "./settings/siteSettings",
          },
        ],
      },
      {
        name: "Documents",
        path: "documents",
        redirectPath: "./documents",
      },
    ],
  };

  // access= {
  //   "capTable" : { "adminWithoutProforma" : true,"captableSummary" : true,"empView" : false,"optsHomepageView" : false},
  //   "Transactions" : {"adminWithoutProforma" : false,"captableSummary" : false,"empView" : false,"optsHomepageView" : true},
  //   "Shareholders" : {"adminWithoutProforma" : true,"captableSummary" : false,"empView" : false,"optsHomepageView" : false},
  //   "Securities" : {"adminWithoutProforma" : true,"captableSummary" : false,"empView" : false,"optsHomepageView" : false},
  //   "Options" : {"adminWithoutProforma" : true,"captableSummary" : false,"empView" : false,"optsHomepageView" : true},
  //   "Modeling" : {"adminWithoutProforma" : true,"captableSummary" : false,"empView" : false,"optsHomepageView" : true},
  //   "Settings" : {"adminWithoutProforma" : true,"captableSummary" : false,"empView" : false,"optsHomepageView" : false},
  //   "EmpDashboard" : {"adminWithoutProforma" : false,"captableSummary" : false,"empView" : true,"optsHomepageView" : false},
  //   "FAQ" : {"adminWithoutProforma" : false,"captableSummary" : false,"empView" : true,"optsHomepageView" : false}
  // }

  acessPermission = {
    adminWithoutProforma: {
      capTable: true,
      Transactions: true,
      Shareholders: true,
      individualShareholder: false,
      Securities: true,
      Options: true,
      Modeling: true,
      Settings: true,
      EmpDashboard: false,
      FAQ: false,
      Documents: true,
    },
    captableSummary: {
      capTable: true,
      Transactions: false,
      Shareholders: false,
      individualShareholder: false,
      Securities: false,
      Options: false,
      Modeling: false,
      Settings: false,
      EmpDashboard: false,
      FAQ: false,
      Documents: false,
    },
    empView: {
      capTable: false,
      Transactions: false,
      Shareholders: false,
      individualShareholder: false,
      Securities: false,
      Options: false,
      Modeling: false,
      Settings: false,
      EmpDashboard: true,
      FAQ: false,
      Documents: false,
    },
    optsHomepageView: {
      capTable: false,
      Transactions: false,
      Shareholders: false,
      individualShareholder: false,
      Securities: false,
      Options: true,
      Modeling: true,
      Settings: false,
      EmpDashboard: false,
      FAQ: false,
      Documents: false,
    },
    shareholder: {
      capTable: false,
      Transactions: false,
      Shareholders: false,
      individualShareholder: true,
      Securities: false,
      Options: false,
      Modelling: false,
      Settings: false,
      EmpDashboard: false,
      FAQ: false,
      Documents: false,
    },
    shareholderWithCaptable: {
      capTable: true,
      Transactions: false,
      Shareholders: false,
      individualShareholder: true,
      Securities: false,
      Options: false,
      Modelling: false,
      Settings: false,
      EmpDashboard: false,
      FAQ: false,
      Documents: false,
    },
    esopAdmin: {
      capTable: false,
      Transactions: false,
      Shareholders: false,
      individualShareholder: false,
      Securities: false,
      Options: true,
      Modeling: true,
      Settings: true,
      EmpDashboard: false,
      FAQ: false,
      Documents: false,
    },
    esopViewer: {
      capTable: false,
      Transactions: false,
      Shareholders: false,
      individualShareholder: false,
      Securities: false,
      Options: true,
      Modeling: true,
      Settings: true,
      EmpDashboard: false,
      FAQ: false,
      Documents: false,
    },
  };
  shouldShowBackButton = false;
  useESOPNewUI = JSON.parse(sessionStorage.getItem("userDetails")).useNewEsopUI || false
  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private utilityService: UtilityService,
    private permService: PermissionsService,
    private navbarService: NavbarService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const isUserLoggedIn = localStorage.getItem("currentUser");
        if (!isUserLoggedIn) {
          return;
        }
        if (this.navbarService.requireReload) {
          this.navbarService.requireReload = false;
          this.navigatingPermissions = {
            adminWithoutProforma: this.permService.authorizeUser([
              "admin-without-proforma",
            ]),
            captableSummary: this.permService.authorizeUser([
              "captable-summary",
              "!strict",
            ]),
            empView: this.permService.authorizeUser(["emp-view", "!strict"]),
            optsHomepageView: this.permService.authorizeUser([
              "opts-management-view",
              "opts-homepage-view",
              "!strict",
            ]),
            esopAdmin: this.permService.authorizeUser([
              "esop-admin",
              "!strict",
            ]),
            esopViewer: this.permService.authorizeUser([
              "esop-viewer",
              "!strict",
            ]),
            shareholder: this.permService.authorizeUser([
              "shareholder-viewer",
              "!strict",
            ]),
            shareholderWithCaptable:
              this.permService.authorizeUser(["captable-summary", "!strict"]) &&
              this.permService.authorizeUser(["shareholder-viewer", "!strict"]),
          };
          this.useESOPNewUI = JSON.parse(sessionStorage.getItem("userDetails")).useNewEsopUI || false
        }
        // console.log(this.myUserPermission)
        this.ngOnInit();
      }
    });
  }
  isTransactionsActive() {
    // manually adding active class
    //:TODO Change router so that manually adding active class is avoided
    const isRouteActive =
      this.router.isActive(
        "landing/dashboard/all-transactions/overview",
        false
      ) ||
      this.router.isActive("landing/dashboard/buy-back/overview", false) ||
      this.router.isActive("landing/dashboard/secondary/overview", false) ||
      this.router.isActive("landing/dashboard/splits/overview", false) ||
      this.router.isActive("landing/dashboard/bonus", false) ||
      this.router.isActive("landing/dashboard/rounds/overview", false) ||
      this.router.isActive("landing/dashboard/transactions/overview", false) ||
      this.router.isActive("landing/dashboard/term-sheets/overview", false) ||
      this.router.isActive(
        "/landing/dashboard/secondary/secondary-seller",
        false
      ) ||
      this.router.isActive(
        "/landing/dashboard/secondary/secondary-buyer",
        false
      ) ||
      this.router.isActive(
        "/landing/dashboard/transactions/quick-round",
        false
      ) ||
      this.router.isActive("/landing/dashboard/splits", false) ||
      this.router.isActive("/landing/dashboard/buy-back", false) ||
      this.router.isActive("/landing/dashboard/rounds", false) ||
      this.router.isActive(
        "landing/dashboard/secondary/secondary-summary",
        false
      );
    // this.router.isActive("landing/dashboard/documents", false)

    return isRouteActive;
  }
  isSettingsActive() {
    // manually adding active class
    //:TODO Change router so that manually adding active class is avoided
    const isRouteActive =
      this.router.isActive("landing/dashboard/settings", false) ||
      this.router.isActive("landing/dashboard/employees/faq", false);

    return isRouteActive;
  }
  selectPlan(e) {
    if (
      e.rowType == "data" &&
      e.column.dataField == "planName" &&
      e.value != "Total"
    ) {
      this.router.navigate([
        "/landing/dashboard/options/manageEsop",
        { planName: e.value },
      ]);
      return "/landing/dashboard/options/manageEsop";
    }
  }

  collapseSubmenu() {
    this.expanded = false;
  }
  getRouteTabs() {
    const currentRoute = this.route.toString();
  }
  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this.router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === "string") {
        if (router instanceof NavigationEnd) {
          this.isCurrentRouteTransactions = false;
          if (this.menu.length == 0) {
            this.menu = this.utilityService.getMenu();
          }
        }
        this.showbackbutton();
        target = this.menu;
        this.breadcrumbList.length = 0;
        // Get current routing URL / zone, [0] = first layer, [1] = second layer ...etc
        routerList =
          this.router.parseUrl(routerUrl).root.children["primary"].segments;
        routerList.shift();

        const mainRouterList =
          this.router.parseUrl(routerUrl).root.children["primary"].segments;

        // this.menu.forEach(mainMenu=>{
        //   if(mainMenu.children){
        //     mainMenu.children.forEach(data=>{
        //                 console.log(data, mainRouterList)
        //   if(data.path == routerList[routerList.length -1].path){
        //     this.currentTabList = data.children
        //   }
        //     })
        //   }
        // })
        // this.navmenu.forEach(mainMenu=>{
        //   if(mainMenu.children){
        //     mainMenu.children.forEach(data=>{
        //                 console.log(data, mainRouterList)
        //   if(data.path == routerList[routerList.length -1].path){
        //     this.currentTabList = data.children
        //   }
        //     })
        //   }
        // })
        routerList.forEach((router, index) => {
          // Find the path of this layer in the menu and the current routing
          if (router.path == "employee") {
            target = target.find((page) => page.path === "manageEsop");
          } else {
            target = target
              ? target.find((page) => page.path === router.path)
              : target;
          }
          if (target) {
            // After being saved to the breadcrumbList, the list is directly looped into breadcrumbs.
            this.breadcrumbList.push({
              name: target.name,
              path: target.path,
              // The routing of the second layer must be added to the routing of the previous layer. Using relative positions will cause routing errors.
              redirectPath:
                index === 0
                  ? target.redirectPath
                  : `${target.redirectPath.slice(2)}`,
            });
          }
          // The target of the next layer to be compared is the subpage specified by this layer
          if (index + 1 !== routerList.length) {
            if (target) target = target.children;
          }
        });
      }
    });
    this.sideNavFocus();

    // this.breadcrumbList.forEach(data=>{
    //   if(data.name !='Home'){
    //     this.currentTabList.push(data)
    //   }
    // })
  }

  routerLink(item) {
    if (item.name == "Home") {
      this.permService.navigateBasedOnPermission();
    }
    this.listenRouting();
  }

  ngOnInit() {
    for (let key in this.navigatingPermissions) {
      if (this.navigatingPermissions[key] == true)
        // this.user=key;
        this.user =
          localStorage.getItem("userRole") == "Shareholder"
            ? "shareholder"
            : localStorage.getItem("userRole") == "Shareholder With CapTable"
            ? "shareholderWithCaptable"
            : key;
    }
    this.utilityService.setValue("currentUserRole", this.user);
    this.userPerm = this.acessPermission[this.user];
    if(localStorage.getItem("userRole") == "Admin Viewer") {
      this.userPerm.Modeling = false;
    }
    this.userPerm.FAQ =
      JSON.parse(sessionStorage.getItem("userDetails")).company.showFaq &&
      localStorage.getItem("userRole") == "Employee";
    // console.log('this.user : ', this.user);
    // console.log('this.userPerm : ', this.userPerm)
    this.useESOPNewUI = JSON.parse(sessionStorage.getItem("userDetails")).useNewEsopUI || false
    this.utilityService.dataEmitter.subscribe((dataEmit) => {
      if (dataEmit && dataEmit["userRole"] && !this.esopoptionscreator) {
        this.esopoptionscreator =
          localStorage.getItem("userRole") == "Options Creator" ||
          localStorage.getItem("userRole") == "Options Approver";
      }
      if (dataEmit && dataEmit["showFaq"] != undefined) {
        this.canUserSeeFaq =
          dataEmit["showFaq"] ||
          JSON.parse(sessionStorage.getItem("userDetails")).company.showFaq ||
          localStorage.getItem("userRole") == "Admin";
        this.userPerm.FAQ =
          JSON.parse(sessionStorage.getItem("userDetails")).company.showFaq &&
          localStorage.getItem("userRole").toLowerCase() == "employee";
      }
    });
    // this.myUserPermission.admin = this.navigatingPermissions.adminWithoutProforma
    //       this.myUserPermission.capTableViwer = this.navigatingPermissions.captableSummary
    //       this.myUserPermission.employee = this.navigatingPermissions.empView
    //       this.myUserPermission.optionsViewer = this.navigatingPermissions.optsHomepageView

    this.esoponlycomp = JSON.parse(
      sessionStorage.getItem("userDetails")
    ).company["isEsopOnlyCompany"];
    this.menu = this.utilityService.getMenu();
    this.listenRouting();
    this.companyName = this.authService.companyName;
    this.sideNavFocus();
  }
  sideNavFocus() {
    let curntactvTab = window.location.href.split("/dashboard/");
    if (curntactvTab[1] === "capTable") {
      this.currentTab = "CapTable";
      this.currentsubTab = "";
    } else if (curntactvTab[1].startsWith("rounds")) {
      this.currentTab = "Transactions";
      this.currentsubTab = "Rounds";
    } else if (curntactvTab[1].startsWith("shareholders")) {
      this.currentTab = "Stockholders";
      this.currentsubTab = "";
    } else if (curntactvTab[1].startsWith("securities")) {
      this.currentTab = "Security";
      this.currentsubTab = "";
    } else if (
      curntactvTab[1].startsWith("secondary") ||
      curntactvTab[1] === "secondary-overview"
    ) {
      this.currentTab = "Transactions";
      this.currentsubTab = "Secondaries";
    } else if (curntactvTab[1].startsWith("buy-back")) {
      this.currentTab = "Transactions";
      this.currentsubTab = "Buy-back";
    } else if (curntactvTab[1].startsWith("splits")) {
      this.currentTab = "Transactions";
      this.currentsubTab = "Splits";
    } else if (curntactvTab[1].startsWith("transactions")) {
      this.currentTab = "Transactions";
      this.currentsubTab = "All-Transactions";
    } else if (curntactvTab[1].startsWith("term-sheets")) {
      this.currentTab = "Transactions";
      this.currentsubTab = "Term Sheets";
    } else if (curntactvTab[1].startsWith("options")) {
      this.currentTab = "Options";
      this.currentsubTab = "";
      if (curntactvTab.length > 1) {
        const subTab = curntactvTab[1];
        if (subTab.includes("overviewEsop")) {
          this.currentsubTab = "esopOverview";
        } else if (subTab.includes("overviewEmployees")) {
          this.currentsubTab = "employeesOverview";
        } else if (subTab.includes("manageEsop")) {
          this.currentsubTab = "esopPlan";
        } else if (subTab.includes("vestingSchedule")) {
          this.currentsubTab = "vestingSchedule";
        }
      }
    } else if (curntactvTab[1].startsWith("modeling")) {
      this.currentTab = "Modelling";
      let subtab = curntactvTab[1].split("modeling/");
      if (subtab[1] === "roundModelingTab") {
        this.currentsubTab = "Scenarios";
      } else if (subtab[1] === "esopModellingTab") {
        this.currentsubTab = "EsopModelling";
      } else if (subtab[1] === "exitModelling") {
        this.currentsubTab = "ExitModelling";
      }
    } else if (curntactvTab[1].startsWith("employees")) {
      this.currentTab = "EmpDashboard";
      if (curntactvTab[2] && curntactvTab[2].startsWith("faq")) {
        this.currentTab = "KnowledgeBase";
      }
      if (curntactvTab[curntactvTab.length - 1].endsWith("faq")) {
        this.currentTab = "KnowledgeBase";
      }
    } else if (curntactvTab[1].startsWith("settings")) {
      this.currentTab = "settings";
      let subtab = curntactvTab[1].split("settings/");
      if (subtab.includes("companyProfile")) {
        this.currentsubTab = "companyProfile";
      } else if (subtab.includes("siteSettings")) {
        this.currentsubTab = "siteSettings";
      }
    }
  }
  showbackbutton() {
    if (
      this.router.url.includes(
        "/landing/dashboard/options/optionsBuyback/overview"
      ) ||
      this.router.url.includes(
        "/landing/dashboard/modeling/roundModelingTab"
      ) ||
      this.getEmployeeId() != undefined
    ) {
      this.shouldShowBackButton = false;
      return;
    }
    this.shouldShowBackButton =
      this.router.url
        .split(/[?#]/)
        .shift()
        .match(/\/[^/]+?/g).length > 4 ||
      this.router.url.includes("/landing/dashboard/transactions/quick-round") ||
      this.router.url.includes(
        "/landing/dashboard/secondary/secondary-seller"
      ) ||
      this.router.url.includes(
        "/landing/dashboard/secondary/secondary-buyer"
      ) ||
      this.router.url.includes("/landing/dashboard/splits/new-split") ||
      this.router.url.includes("/landing/dashboard/options/createEsop") ||
      this.router.url.includes(
        "/landing/dashboard/secondary/secondary-summary"
      ) ||
      this.router.url.includes(
        "/landing/dashboard/modeling/roundModelingTab"
      ) ||
      this.router.url.includes("/landing/dashboard/splits/preview") ||
      this.router.url.includes(
        "/landing/dashboard/securities/addNewInstrumentclass"
      ) ||
      this.router.url.includes(
        "/landing/dashboard/bonus/bonus-security-details"
      ) ||
      this.router.url.includes("/landing/dashboard/bonus/preview") ||
      this.router.url.includes("/landing/dashboard/bonus/new-bonus") ||
      this.router.url.includes("/landing/dashboard/shareholders/beneficiaries") ||
      this.router.url.includes("/landing/dashboard/securities/conversion?securityClass") ||
      this.router.url.includes("/landing/dashboard/securities/preview?securityClass");
  }
  //  goback(){
  //    // this.router.navigate([".."],      {relativeTo: this.activatedRoute});
  //    // this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
  //    this.router.navigateByUrl(this.router.url.substr(0, this.router.url.lastIndexOf('/')));
  //  }
  backClicked() {
    this._location.back();
  }

  goTo(subtab) {
    this.currentsubTab = subtab;
  }
  mousein() {
    this.expandedMenu = true;
  }
  mouseout() {
    this.expanded = false;
    this.expandedTransactions = false;
    this.expandedMenu = false;
    this.expandedOptions = false;
    this.expandedSettings = false;
  }
  onActivate(event) {
    this.listenRouting();
    //TODO: Post angular upgrade use viewportscroller to implement this
    document.querySelector("mat-sidenav-content").scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (event.activatedRoute) {
      const activeComponent = event.activatedRoute.component.name;
      this.activatingComp = event.activatedRoute.component.name;
      let activeComp = [];
      activeComp.push(activeComponent);
      //Main Captable
      if (activeComponent === "CapTableComponent") {
        this.currentTab = "CapTable";
      } else if (
        activeComponent === "RoundsOverviewComponent" ||
        activeComponent === "CreateNewRoundComponent"
      ) {
        //Round Component Active
        this.currentTab = "Rounds";
      } else if (
        activeComponent === "BuyBackOverviewComponent" ||
        activeComponent === "CreateNewBuyBackComponent"
      ) {
        //Buy-back Component Active
        this.currentTab = "Buy-back";
      } else if (
        activeComponent === "ShareholdersOverviewComponent" ||
        activeComponent === "ShareholderDetailsComponent"
      ) {
        // Shareholders Active
        this.currentTab = "Stockholders";
      } else if (
        activeComponent === "SecuritiesOverviewComponent" ||
        activeComponent === "AddNewInstrumentclassComponent" ||
        activeComponent === "InstrumentClassComponent"
      ) {
        // Securities Active
        this.currentTab = "Security";
      } else if (
        activeComponent === "ExitModellingComponent" ||
        activeComponent === "RoundModellingComponent"
      ) {
        // Modelling Active
        this.currentTab = "Modelling";
      }
    }
  }

  onDeactivate(event) {
    if (event.activatedRoute) {
      this.deActivatingComp = event.activatedRoute.component.name;
    }
  }
  navigator() {
    this.router.navigate(["/landing", "dashboard", this.toComp]);
  }
  getEmployeeId() {
    const userObj = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userObj) {
      if (userObj.type === "EMPLOYEE") {
        return userObj.employeeId;
      }
    }
    return undefined;
  }

  getShareholderId() {
    const userObj = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userObj) {
      return userObj.shareholderId;
    }
    return undefined;
  }
  navigateTo(comp, permissionRequired?: Array<string>) {
    this.mouseout();
    this.toComp = comp;
    if (this.activatingComp === "RounModelingTabComponent") {
      if (
        comp === "capTable" ||
        comp === "rounds" ||
        comp === "shareholders" ||
        comp === "securities"
      ) {
        bootbox.confirm({
          title: "Confirm",
          message: "Are you sure you want to leave page ?",
          buttons: {
            confirm: {
              label: "Yes",
              className: "mat-success",
            },
            cancel: {
              label: "No",
              className: "mat-warn",
            },
          },
          callback: function (result) {
            if (result === true) {
              $("#navigator").click();
            } else {
              this.currentTab = "Modelling";
              this.currentsubTab = "roundModelingTab";
            }
          },
        });
      } else {
        if (comp === "rounds") {
          this.router.navigate(["/landing", "dashboard", comp, "overview"]);
        } else {
          this.router.navigate(["/landing", "dashboard", comp]);
        }
      }
    } else {
      if (comp === "rounds") {
        this.router.navigate(["/landing", "dashboard", comp, "overview"]);
      } else if (comp === "shareholders") {
        this.router.navigate(["/landing", "dashboard", comp, "overview"]);
      } else if (comp === "securities") {
        this.router.navigate(["/landing", "dashboard", comp, "overview"]);
      } else if (comp === "options") {
        let isHomePageView = this.permService.authorizeUser([
          "opts-homepage-view",
        ]);
        let isManagementPageView = this.permService.authorizeUser([
          "opts-management-view",
        ]);
        if (isHomePageView && isManagementPageView) {
          this.router.navigate(["/landing", "dashboard", comp, "overviewEsop"]);
        } else if (isHomePageView && !isManagementPageView) {
          this.router.navigate(["/landing", "dashboard", comp, "overviewEsop"]);
        } else if (!isHomePageView && isManagementPageView) {
          return this.router.navigate([
            "/landing/dashboard/options/manageEsop",
            { planName: "ALL" },
          ]);
        }
      } else if (comp === "secondary") {
        this.router.navigate(["/landing", "dashboard", comp, "overview"]);
      } else if (comp === "modeling") {
        this.router.navigate([
          "/landing",
          "dashboard",
          comp,
          "roundModelingTab",
        ]);
      } else if (comp === "taskManager") {
        this.router.navigate(["/landing", "dashboard", comp, "overview"]);
      } else if (comp == "employees") {
        let empId = localStorage.getItem("empId");
        if (!empId) empId = this.getEmployeeId();
        this.router.navigate([
          "/landing",
          "dashboard",
          "employees",
          "employeeDetails",
          empId,
        ]);
      } else if (comp == "faqs") {
        this.currentTab = "KnowledgeBase";
        this.router.navigate(["/landing", "dashboard", "employees", "faq"]);
      } else if (comp == "manageEsop") {
        return this.router.navigate([
          "/landing/dashboard/options/" + comp,
          { planName: "ALL" },
        ]);
      } else {
        this.router.navigate(["/landing", "dashboard", comp]);
      }
    }
  }
  goToAllEmployees() {
    this.router.navigate(["/landing/dashboard/options/overviewEmployees"]);
  }
  navigateToOptions(targetUrl?) {
    if(JSON.parse(sessionStorage.getItem("userDetails")).useNewEsopUI || false)
    this.utilityService.navigateToNewOptions(targetUrl);
    return;
  }
}
