import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../services/utilities.service';
import { CacheProviderService } from '../services/cache-provider.service';

@Component({
  selector: 'app-card-overview',
  templateUrl: './card-overview.component.html',
  styleUrls: ['./card-overview.component.scss']
})
export class CardOverviewComponent implements OnInit {

  @Input() mainCardHeader:string
  @Input() primaryHeader?:string
  @Input() secondaryHeader?:string
  @Input() primaryValue?:string
  @Input() secondaryValue?:string
  @Input() svgIcon?:string
  @Input() iconPosition?:string
  @Input() holdingCompanyCurrency?:string
  holdingCompanyCurrencySymbol = ''
  usrCompanyCurrency = ''
  currencySymbol = ''

  constructor(private readonly utilityService:UtilityService, private readonly cacheService:CacheProviderService) { }
  getTotalValueTble(value) {
    if (value > 0 && this.mainCardHeader!="Share Price") {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }

      else {

        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }

    }
    else {
      // console.log("s234567")
      if(value > 0 ){
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
       }
      return 0;

    }
  }
  formatCurrencyForTooltip(val){
    return this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, val);
  }
  ngOnInit() {
    let userDetails = this.cacheService.get("userDetails")
    if (userDetails && typeof userDetails === "string")
    userDetails = JSON.parse(userDetails)
    if (userDetails && userDetails['company']) {
      this.usrCompanyCurrency = userDetails['company']['companyCurrency'];
    }
    else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim()
    if (this.holdingCompanyCurrency) {
      this.holdingCompanyCurrencySymbol = this.holdingCompanyCurrency.split("-")[1].trim()
    }
    if(!['Securities', 'Stakeholders', 'Related Company Details'].includes(this.mainCardHeader)){
      this.mainCardHeader = `${this.mainCardHeader} (${this.currencySymbol})`
    }
  }

}
