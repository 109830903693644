import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { HelperService } from '../../services/helper.service';
import { AuthenticationService } from '../../../../src/app/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/new-round-notify.service';
import { CacheProviderService } from '../../services/cache-provider.service';
import { NavbarService } from 'src/app/services/navbar-reload';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AdminProfileComponent implements OnInit {

  public profile = {
    designation: '',
    authenticatorType: ''
  }
  public profilePicture = null;
  public authenticator = null;
  public croppedImage = null;
  public shouldShowDialog = false;
  public waitingForVerification: boolean = false;
  registerNewCompany: boolean;

  public helper: string = "";
  public helpSections;
  public helperTopPosition = null;

  public isAccountConfirm: boolean = false;
  public authenticatorList: [];
  public roles = [];
  public isNonEditable: boolean = false;

  public errorFlags;
  public designationValid: boolean = false;
  public authenticatorValid: boolean = false;
  public authFileValid: boolean = false;

  public isVerificationLinkExpired: boolean = false;

  constructor(private helperService: HelperService, private authService: AuthenticationService, private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheProviderService,
    private navbarService: NavbarService,
    private dataService: DataService) { 
    let navFlag = localStorage.getItem('navigationFlag');
    if(!navFlag || navFlag != 'companyDetails') {
        this.redirectToNext();
    }
    localStorage.removeItem('navigationFlag');
  }

  ngOnInit() {
    if(this.activatedRoute.snapshot.paramMap.get('newComp') == 'true') {
        this.registerNewCompany = true;
    }
    this.fetchAdminProfileDetails();
  }

  redirectToNext() {
    this.authService.getUserDetails().subscribe(
      (res) => {
        // localStorage.setItem('companyId', res['company']['id']);
        if(res['authenticatorType'] != null && res['isVerified'] == true && res['company'] == null) {
          this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
        }
      },
      e => {
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  fetchAdminProfileDetails() {
    this.ngxLoader.start();
    this.authService.getAdminProfile().subscribe(
      (res: any) => {
        if (res) {
          this.roles = res.designationDropDownValues;
          this.authenticatorList = res.authenticatorDropDownValues;
          if(res['isVerified'] == false) {
              this.waitingForVerification = true;
              this.isVerificationLinkExpired = res['isLinkExpired'];
            localStorage.setItem('waitingForVerification', 'true');
          }

          this.profile.designation = res.designation == null ? '' : res.designation;
          this.profile.authenticatorType = res.authenticatorType == null ? '' : res.authenticatorType;
          let authenticatorFileId = res.authenticatorFileId;
          let profilePictureId = res.profilePictureId;

          if (res.designation != null || res.authenticatorType != null || res.authenticatorFileId != null || res.profilePictureId != null
          ) {
            this.isNonEditable = true;
          }
          else {
            this.isNonEditable = false;
          }

          this.ngxLoader.stop();
          if (profilePictureId != null) {
            this.authService.getImageFile(profilePictureId).subscribe(
              (res: any) => {
                this.createImageFromBlob(res);
                this.profilePicture = res;
              },
              (e) => {
                this.toastr.error(e.error.reason, "Error");
              }
            );
          }
          if (authenticatorFileId) {
            this.authService.getPdfFile(authenticatorFileId).subscribe(
              (res: HttpResponse<any>) => {
                let header = res.headers.get('Content-Disposition');
                let matches = /filename="(.*?)"/g.exec(header);
                let filename = !!matches ? matches[1] : 'file.pdf';
                this.authenticator = res.body;
                this.authenticator.name = filename;
              },
              (e) => {
                this.toastr.error(e.error.reason, "Error");
              }
            );
          }
          this.stepperInitialization();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      }, (e) => {
        this.ngxLoader.stop();
        this.profile.designation = '';
        this.profile.authenticatorType = '';
        this.authenticator = null;
        this.croppedImage = null;
        this.profilePicture = null;
        if (e.error.reason === "Login Token Expired.") {
          this.ngxLoader.stop();
          this.cacheService.clear();
          this.router.navigate(['']);
          return;
        } else {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      }
    );
  }

  goToDashboard() {
    this.authService.getUserDetails().subscribe(
        (res) => {
          this.cacheService.set("userDetails", JSON.stringify(res));
          let userDetails = res;
          if(userDetails['company'] && userDetails['incorporationDetails']) {
            this.navbarService.sendClickEvent();
            setTimeout(() => {
              this.router.navigate(['/landing', 'dashboard', 'capTable']);
            }, 300);
          } else if(userDetails['company'] && !userDetails['incorporationDetails']) {
            if(this.registerNewCompany) {
                this.router.navigate(['/landing', 'onboarding', 'create-historical-round', { newComp:  true }])
            } else {
                this.router.navigate(['/landing', 'onboarding', 'create-historical-round'])
            }
          }
        },
        e => {
          this.toastr.error(e.error.reason, "Error");
        }
      );
  }

  stepperInitialization() {
    let companyDetailsInfo = this.cacheService.get("CompanyDetails");
    let companyDetails = "";

    if (companyDetailsInfo != null) {
      companyDetails = "COMPLETED"
    }
    else {
      companyDetails = "INITIAL"
    }
    //For Stepper Initialization
    if (this.isNonEditable == true) {
        let errorStatus;
        if(this.waitingForVerification == true) {
            errorStatus = {
                adminProfileTab: "INPROGRESS",
                companyDetailsTab: companyDetails,
                inCorporationTab: "INITIAL"
            }
        } else {
            errorStatus = {
                adminProfileTab: "COMPLETED",
                companyDetailsTab: companyDetails,
                inCorporationTab: "INITIAL"
                }
        }
      this.errorFlags = errorStatus;
      this.dataService.updateOnboarding(this.errorFlags);
      this.cacheService.set("AdminProfile", JSON.stringify(this.profile));
    }
    else if (this.isNonEditable == false) {
      let errorStatus = {
        adminProfileTab: "INPROGRESS",
        companyDetailsTab: companyDetails,
        inCorporationTab: "INITIAL"
      }
      this.errorFlags = errorStatus;
      this.dataService.updateOnboarding(this.errorFlags);
    }
  }

  proceedToCompanyDetails() {
    if (this.areFieldsValid()) {
      this.saveAdminProfile();
    } else {
        this.toastr.error("Please fill all the fields", "Failed");
    }
  }

  gotoCompanyDetails() {
    let errorStatus = {
      adminProfileTab: "COMPLETED",
      companyDetailsTab: "INPROGRESS",
      inCorporationTab: "INITIAL"
    }
    this.errorFlags = errorStatus;
    this.dataService.updateOnboarding(this.errorFlags);
    // let waitingForVerification = localStorage.getItem('waitingForVerification');
    // if(waitingForVerification == 'true') {
    //     this.toastr.error('Please complete verification');
    // } else {
    //     this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
    // }
    this.authService.getAdminProfile().subscribe(
      (res) => {
        if(res['isVerified'] == 'false') {
          this.toastr.error('Please complete verification');
        }
        else {
          if(this.registerNewCompany) {
            this.router.navigate(['/landing', 'onboarding', 'companyDetails', { newComp: true }]);
          } else {
            this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
          }
        }
      },
      (e) => {
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.croppedImage = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  createFileFromBlob(file: Blob) {
    var file = new Blob([file], { type: 'application/pdf' });
    this.authenticator = URL.createObjectURL(file);
  }

  onImageSaved(imageDetails) {
    this.shouldShowDialog = false;
    this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
    this.croppedImage = imageDetails.croppedImage;
  }

  async uploadAvatarImage(event) {
    this.croppedImage = event.target.src;
    this.profilePicture = await (fetch(this.croppedImage).then(res => res.blob()));
  }

  fileChangeEvent(fileInput: Event) {
    // let file = fileInput.target.files[0];
    this.authenticator = fileInput.target['files'][0];
    //  let fileName = this.authenticator.name;
  }

  areFieldsValid(): boolean {
    if (this.profile.designation == "" || this.profile.authenticatorType == "" || this.authenticator == null) {
      if (!this.profile.designation) {
        this.designationValid = true;
      }
      if (!this.profile.authenticatorType) {
        this.authenticatorValid = true;
      }
      if (!this.authenticator) {
        this.authFileValid = true;
      }
      return false;
    }
    else {
      return true;
    }
  }

  getEvent() {
    this.shouldShowDialog = false;
    document.getElementById("overlay").style.display = "none";
  }

  saveAdminProfile() {
    const formData: FormData = new FormData();
    if(this.profilePicture != null){
      formData.append('profilePicture', (new File([this.profilePicture], 'profile.jpeg', { type: 'image/jpeg' })));
    }
    formData.append('authenticator', this.authenticator);
    formData.append('designation', this.profile.designation);
    formData.append('authenticatorType', this.profile.authenticatorType);
    // new Response(formData).text().then(console.log)
    this.ngxLoader.start();
    this.authService.saveAdminProfile(formData).subscribe(
      (res) => {
          this.ngxLoader.stop();
          let email = res['emailAddress'];
          this.toastr.success('Verification E-mail sent to the admin' ,'Success', {
            timeOut: 10000,
          });
          this.isAccountConfirm = true;
          localStorage.setItem('waitingForVerification', 'true');
          this.cacheService.set("AdminProfile", JSON.stringify(this.profile));
          //stepper initialization
          let errorStatus = {
            adminProfileTab: "INPROGRESS",
            companyDetailsTab: "INITIAL",
            inCorporationTab: "INITIAL"
          }
          this.errorFlags = errorStatus;
          this.dataService.updateOnboarding(this.errorFlags);
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  resendVerMail() {
    this.authService.resendVerificationMail().subscribe(
        (res) => {
          let email = res['emailAddress'];
          this.toastr.success('Verification E-mail sent to the admin' ,'Success', {
            timeOut: 10000,
          });
        },
        () => {
            this.toastr.error('Please try again', 'Error');
        }
    );
  }

  openUploadPicturePopup() {
    this.shouldShowDialog = true;
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI) {
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  onAuthenticatorTypeChange() {
    this.authenticator = null;
  }


  showHelp(section) {
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);

    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "AdminProfileComponent",
      section
    );
    this.helper = section;
  }

  hideHelp() {
    this.helper = "";
  }

  changeAuthType() {
    this.authenticator = null;
  }

  changeCroppedImage() {
    this.openUploadPicturePopup();
  }

}
