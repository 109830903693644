var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CacheProviderService } from "../../services/cache-provider.service";
import { UtilityService } from "../../services/utilities.service";
import { ToastrService } from "ngx-toastr";
import { SecurityService } from "src/app/securities/security.service";
import { Observable } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material";
import { PermissionsService } from "src/app/services/permissions.service";
var ShareHolderFundDialog = /** @class */ (function () {
    function ShareHolderFundDialog(dialogRef, data, utilityService, ngxLoader) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.utilityService = utilityService;
        this.ngxLoader = ngxLoader;
        this.usrCompanyCurrency = "";
        this.selectedFundDetails = data.selectedFundDetails;
        this.shareHolderFundDetails = data.shareHolderFundDetails;
        this.usrCompanyCurrency = data.usrCompanyCurrency;
        this.shareholderDetails = data.shareholderDetails;
    }
    ShareHolderFundDialog.prototype.close = function () {
        this.dialogRef.close();
    };
    ShareHolderFundDialog.prototype.getTotalValueTble = function (value) {
        if (value > 0) {
            if (value.toString().length > 5) {
                return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
            }
            else {
                return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
            }
        }
        else {
            return 0;
        }
    };
    return ShareHolderFundDialog;
}());
export { ShareHolderFundDialog };
var ShareholderDetailsComponent = /** @class */ (function () {
    function ShareholderDetailsComponent(router, authService, activatedRoute, ngxLoader, cacheService, utilityService, toastr, securityService, permService, dialog) {
        this.router = router;
        this.authService = authService;
        this.activatedRoute = activatedRoute;
        this.ngxLoader = ngxLoader;
        this.cacheService = cacheService;
        this.utilityService = utilityService;
        this.toastr = toastr;
        this.securityService = securityService;
        this.permService = permService;
        this.dialog = dialog;
        this.showBeneficiary = false;
        this.investmentRecordId = null;
        this.showSharehldrfundpg = false;
        this.usrCompanyCurrency = "";
        this.genCredentialsDialog = {
            show: false,
            email: null,
            shareholderId: null,
            shareholderName: null,
            viewCapTableSummary: null,
        };
        this.shareCertificateDialog = {
            show: false,
            email: null,
            shareholderId: null,
            shareholderName: null,
        };
        this.currencySymbol = "";
    }
    ShareholderDetailsComponent.prototype.identifierCSS = function (identifier) {
        switch (identifier) {
            case "FF":
                return identifier;
            case "SF":
                return identifier;
            case "AF":
                return identifier;
            case "ES":
                return identifier;
            case "SA":
                return identifier;
            case "SB":
                return identifier;
            case "SC":
                return identifier;
            case "AC":
                return identifier;
            case "SD":
                return identifier;
            case "SE":
                return identifier;
            case "AF1":
                return "Def1";
            case "AF2":
                return "Def2";
            case "AF3":
                return "Def3";
            case "SP":
                return "Def4";
            case "SG":
                return "Def5";
            case "SH":
                return "Def6";
            case "SA1":
                return "Def7";
            case "SA2":
                return "Def8";
            case "SA3":
                return "Def1";
            case "SB1":
                return "Def2";
            case "SB2":
                return "Def3";
            case "SP1":
                return "Def4";
            default:
                return "defaultClass";
        }
    };
    ShareholderDetailsComponent.prototype.goToSecondary = function (shareholder) {
        var shareholderid = this.activatedRoute.snapshot.paramMap.get("id");
        this.router.navigate([
            "/landing",
            "dashboard",
            "secondary",
            "secondary-seller",
            { shareholder: shareholder, shareholderid: shareholderid },
        ]);
    };
    ShareholderDetailsComponent.prototype.getTotalValueTble = function (value) {
        if (value > 0) {
            if (value.toString().length > 5) {
                return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
            }
            else {
                return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
            }
        }
        else {
            return 0;
        }
    };
    ShareholderDetailsComponent.prototype.formatCurrency = function (value) {
        return this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, value);
    };
    // Navigating to Round details
    ShareholderDetailsComponent.prototype.goToRoundDetails = function (rndName) {
        if (this.permService.authorizeUser(["shareholder-viewer", "!strict"])) {
            return this.toastr.error("You do not have the permission to access this page.", "Failure!");
        }
        if (rndName != "ES") {
            this.router.navigate([
                "/landing",
                "dashboard",
                "rounds",
                "round-details",
                rndName,
            ]);
        }
    };
    ShareholderDetailsComponent.prototype.importCertFile = function (event, investmentRecordId) {
        var _this = this;
        this.ngxLoader.start();
        var fd = new FormData();
        fd.append("file", event.target.files[0]);
        this.authService.uploadShareCertificate(fd, investmentRecordId).subscribe(function (response) {
            _this.ngxLoader.stop();
            $("#uploadImage").val(null);
            _this.toastr.success("Successfully uploaded.", "Success;");
        }, function (e) {
            $("#uploadImage").val(null);
            _this.ngxLoader.stop();
            _this.toastr.error(e.error.reason, "Failure!");
        });
    };
    ShareholderDetailsComponent.prototype.sendShareCertificate = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.utilityService.showOverlay();
            _this.shareCertificateDialog.show = true;
            _this.shareCertificateDialog.shareholderId = _this.shareholderId;
            _this.shareCertificateDialog.shareholderName = _this.shareholderName;
            _this.shareCertificateDialog.email = _this.shareholderEmail;
            _this.shareCertificate.subscribe(function (res) {
                var obj = __assign({}, res);
                resolve(obj);
            });
        });
        return promise.then(function (res) {
            _this.shareCertificateDialog.email = res.shareholderEmail;
            _this.shareCertificateSubscriber.unsubscribe();
        });
    };
    ShareholderDetailsComponent.prototype.getShareCertData = function (e) {
        var _this = this;
        this.shareCertificateSubscriber.next(this.shareCertificateDialog);
        if (e) {
            this.ngxLoader.start();
            var shareCertBody = {
                "profileId": this.shareCertificateDialog.shareholderId,
                "emailId": this.shareCertificateDialog.email
            };
            this.authService.sendShareCertificate(shareCertBody).subscribe(function (res) {
                _this.toastr.success("Share Certificate sent!");
                _this.ngxLoader.stop();
            }, function (e) {
                _this.toastr.error(e.error.reason, "Failure!");
                _this.ngxLoader.stop();
            });
        }
        this.shareCertificateDialog.show = false;
        this.shareCertificateDialog.shareholderId = null;
        this.shareCertificateDialog.shareholderName = null;
        this.shareCertificateDialog.email = null;
        document.getElementById("overlay").style.display = "none";
    };
    ShareholderDetailsComponent.prototype.generateCred = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.utilityService.showOverlay();
            _this.genCredentialsDialog.show = true;
            _this.genCredentialsDialog.shareholderId = _this.shareholderId;
            _this.genCredentialsDialog.shareholderName = _this.shareholderName;
            _this.genCredentialsDialog.email = _this.shareholderEmail;
            _this.genCredentialsDialog.viewCapTableSummary = false;
            _this.genCredentials.subscribe(function (res) {
                var obj = __assign({}, res);
                resolve(obj);
            });
        });
        return promise.then(function (res) {
            _this.genCredentialsDialog.email = res.shareholderEmail;
            _this.genCredentialsDialog.viewCapTableSummary = res.viewCapTableSummary;
            _this.genCredentialsSubscriber.unsubscribe();
        });
    };
    ShareholderDetailsComponent.prototype.getCredData = function (e) {
        var _this = this;
        this.genCredentialsSubscriber.next(this.genCredentialsDialog);
        if (e) {
            this.ngxLoader.start();
            var credentialsObj = {
                id: this.genCredentialsDialog.shareholderId,
                viewCapTableSummary: this.genCredentialsDialog.viewCapTableSummary,
                emailId: this.genCredentialsDialog.email,
            };
            this.authService.generateShareholderCredentials(credentialsObj).subscribe(function (res) {
                _this.toastr.success("Credentials generated successfully!");
                _this.ngxLoader.stop();
            }, function (e) {
                _this.toastr.error(e.error.reason, "Failure!");
                _this.ngxLoader.stop();
            });
        }
        this.genCredentialsDialog.show = false;
        this.genCredentialsDialog.shareholderId = null;
        this.genCredentialsDialog.shareholderName = null;
        this.genCredentialsDialog.viewCapTableSummary = null;
        this.genCredentialsDialog.email = null;
        document.getElementById("overlay").style.display = "none";
    };
    ShareholderDetailsComponent.prototype.navigateTo = function (type, id, profileId, navigate) {
        var _this = this;
        var navigationExtras = {
            queryParams: { id: id, profileId: profileId },
        };
        if (type == "conversion" && navigate) {
            this.ngxLoader.start();
            this.authService.getSecurityDetails(id, profileId).subscribe(function (response) {
                _this.ngxLoader.stop();
                _this.securityService.setConversionDetails = response;
                _this.router.navigate(["/landing", "dashboard", "shareholders", "conversion"], navigationExtras);
            }, function (e) {
                _this.ngxLoader.stop();
                _this.toastr.error(e.error.reason, "Failure!");
            });
        }
        else if (type == "redemption" && navigate) {
            this.router.navigate(["/landing", "dashboard", "shareholders", "redemption"], navigationExtras);
        }
        else if (type == "partly-paid" && navigate) {
            this.router.navigate(["/landing", "dashboard", "shareholders", "partly-paid"], navigationExtras);
        }
    };
    ShareholderDetailsComponent.prototype.openBeneficiaryDialog = function (profileId) {
        this.showBeneficiary = true;
        this.profileId = profileId;
        document.getElementById("overlay").style.display = "block";
    };
    ShareholderDetailsComponent.prototype.getBeneficiary = function (e) {
        this.showBeneficiary = false;
        document.getElementById("overlay").style.display = "none";
        this.ngOnInit();
    };
    ShareholderDetailsComponent.prototype.goToBeneficiaries = function () {
        this.ngxLoader.start();
        this.router.navigate(['/landing', 'dashboard', 'shareholders', 'beneficiaries'], { queryParams: { shrProfile: this.shareholderId } });
    };
    ShareholderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ngxLoader.start();
        if (this.cacheService.get("userDetails")) {
            this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
            this.usrCompanyCurrency = this.userDetails["company"]["companyCurrency"];
        }
        else {
            this.usrCompanyCurrency = "INR - ₹";
        }
        this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
        var shareholderID = this.activatedRoute.snapshot.paramMap.get("id");
        this.shareholderId = shareholderID;
        this.authService.getShareholderProfile(this.shareholderId).subscribe(function (res) {
            _this.shareholderEmail = res["emailId"];
        }, function (e) {
            _this.ngxLoader.stop();
            _this.toastr.error(e.error.reason, "Error");
        });
        this.authService.getShareholderDetails(shareholderID).subscribe(function (res) {
            if (res) {
                _this.shareholderDetails = res;
                _this.genCredentials = new Observable(function (subscriber) {
                    _this.genCredentialsSubscriber = subscriber;
                });
                _this.shareCertificate = new Observable(function (subscriber) {
                    _this.shareCertificateSubscriber = subscriber;
                });
                _this.shareholderName =
                    _this.shareholderDetails.shareholderDetailsDto.name;
                _this.getMytransactions(
                // this.shareholderDetails.shareholderDetailsDto.name
                _this.shareholderId);
                _this.ngxLoader.stop();
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error("Error: Null response!.", "Error");
            }
        }, function (e) {
            if (e.error.reason === "Login Token Expired.") {
                _this.ngxLoader.stop();
                localStorage.clear();
                _this.router.navigate([""]);
                return;
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error(e.error.reason, "Error");
            }
        });
        this.authService.getNwInstrShrHldrGroupInfo().subscribe(function (res) {
            if (res) {
                _this.shareHolderType = res["typeOfShareholder"];
                _this.groupNames = res["groupName"];
                _this.ngxLoader.stop();
            }
        }, function (e) {
            _this.toastr.error(e.error.reason, "Error");
            _this.ngxLoader.stop();
        });
    };
    ShareholderDetailsComponent.prototype.getMytransactions = function (shareholderId) {
        var _this = this;
        this.authService.getMyTransactions(shareholderId).subscribe(function (res) {
            if (res) {
                _this.myTransactions = res;
            }
            else {
                _this.toastr.error("Error: Null response!.", "Error");
            }
        }, function (e) {
            _this.toastr.error(e.error.reason, "Error");
        });
    };
    ShareholderDetailsComponent.prototype.showCertificate = function (investmentRecordId) {
        this.showDialogCert = true;
        this.investmentRecordId = investmentRecordId;
        document.getElementById("overlay").style.display = "block";
    };
    ShareholderDetailsComponent.prototype.getEventCert = function (e) {
        this.showDialogCert = e;
        document.getElementById("overlay").style.display = "none";
    };
    ShareholderDetailsComponent.prototype.openFundModel = function () {
        var _this = this;
        var shareholderID = this.activatedRoute.snapshot.paramMap.get("id");
        this.showSharehldrfundpg = true;
        this.ngxLoader.start();
        this.authService.shareHolderFundDetails(shareholderID).subscribe(function (res) {
            if (res) {
                _this.shareHolderFundDetails = res;
                _this.selectedFundDetails = _this.shareHolderFundDetails;
                _this.ngxLoader.stop();
                _this.dialog.open(ShareHolderFundDialog, {
                    data: {
                        shareHolderFundDetails: _this.shareHolderFundDetails,
                        selectedFundDetails: _this.selectedFundDetails,
                        usrCompanyCurrency: _this.usrCompanyCurrency,
                        shareholderDetails: _this.shareholderDetails,
                    },
                    width: "800px",
                    disableClose: true,
                });
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error("Error: Null response!.", "Error");
            }
        }, function (e) {
            _this.ngxLoader.stop();
            _this.toastr.error(e.error.reason, "Error");
        });
    };
    ShareholderDetailsComponent.prototype.editShareholderProfile = function () {
        this.shareholderId = this.activatedRoute.snapshot.paramMap.get("id");
        this.showdigPrfle = true;
        document.getElementById("overlay").style.display = "block";
    };
    ShareholderDetailsComponent.prototype.getEventProfile = function (e) {
        this.showdigPrfle = e.showMdl;
        document.getElementById("overlay").style.display = "none";
    };
    return ShareholderDetailsComponent;
}());
export { ShareholderDetailsComponent };
