<div class="hrms-emp-list-dialog">
  <div class="header">
      <div class="row">
          <div class="col-md-10 custom-modal-header">
              <label class="dialogue-title">Employee List</label>
          </div>
          <div class="col-md-2 close-icon">
              <button type="button" class="close" (click)="close()" aria-label="Close" id="close" [mat-dialog-close]="'close'">
                  <span class="close">X</span>
              </button>
          </div>
      </div>
  </div>


  <div class="container-fluid content-row hrms-list-container">
    <div class="col-md-12 top-10 btm-10">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 topPadding">
        <div class="row filter_row">
          <div class="employee-mat-form-field" style="margin-top: 6.5px;">
            <mat-form-field class="employee-mat-form-field" floatLabel="always" appearance="outline">
              <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedEmployee">
              <mat-icon class="aggregator-arrow-selector" matSuffix>
                <div class="mat-select-arrow-wrapper">
                  <div class="mat-select-arrow">
                  </div>
                </div>
              </mat-icon>
              <mat-autocomplete [showPanel]="true" #auto="matAutocomplete" (optionSelected)="changeEmployee(myControl.value)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-icon class="column-chooser clear_filter_icon" *ngIf="selectedEmployee" (click)="clearFilter()" ngbTooltip="Clear the Filter" ><i class="fa fa-times" aria-hidden="true"></i></mat-icon>
          </div>
          <div class="coldgridOPerations align-right">
            <button mat-raised-button [disabled]="false" (click)="importAllEmp()" style="height: 43px;max-height: min-content; top: 0.5em; margin-bottom: 20px;" class="import-all-btn"> IMPORT SELECTED</button>
          </div>
        </div>
      </div>
        <!-- DevExtreme Table - Workout  -->
          <div class="col-md-12 btm-20">
            <dx-data-grid id="gridContainer" class="grant-table" [hoverStateEnabled]="true" [dataSource]="filteredDatasource" keyExpr="employeeId" caption="Table 1"
                 [showBorders]="true" [columnAutoWidth]="false" [allowColumnReordering]="false"
                [allowColumnResizing]="false" 
                (onEditingStart)="logEvent('EditingStart', $event)" (onInitNewRow)="logEvent('InitNewRow', $event)"
                (onRowInserted)="logEvent('RowInserted', $event)" (onRowRemoved)="logEvent('RowRemoved', $event)" (onSelectionChanged)="selectionChanged($event)" 
                (onCellClick)="onCellClick($event)">
                <dxo-scrolling [useNative]="false" showScrollbar="never"> </dxo-scrolling>
                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
                <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

                <dxi-column dataField="employeeName" [allowSorting]="true" [allowEditing]="false" [allowFiltering]="true" [minWidth]="100" caption="Name" cellTemplate="employeeNameCellTemplate">
                <dxo-header-filter  [dataSource]="empNameFilter"></dxo-header-filter>                
                </dxi-column>
               
                <div *dxTemplate="let cellData of 'employeeNameCellTemplate'">

                 <div ><span [ngbTooltip]="cellData.data.employmentStatus" [ngStyle]="getStatusColor(cellData.data)"> </span>
                  &nbsp; {{ cellData.data.employeeName }} 
                 </div>
                </div>

                <dxi-column dataField="employeeId" [allowEditing]="false" [allowFiltering]="true" [minWidth]="90"  caption="Emp ID" ></dxi-column>
  
                <dxi-column dataField="designation" [allowEditing]="false" [minWidth]="120" caption="Designation"></dxi-column>
                <dxi-column dataField="contactNumber" [allowFiltering]="true"  [allowEditing]="false" [minWidth]="90" caption="Mobile No."></dxi-column>
                <dxi-column dataField="email" [allowFiltering]="true"  [allowEditing]="false" [minWidth]="90" caption="Email"></dxi-column>
                <dxi-column dataField="joiningDate" [allowEditing]="false" [minWidth]="140" format="dd-MMM-yyyy" dataType="date" caption="Date of Joining"></dxi-column>
                <dxi-column dataField="address" caption="Address" [minWidth]="120" [allowEditing]="false" ></dxi-column>
                
                <dxi-column [fixed]="true" [minWidth]="90"  alignment = 'center' fixedPosition="right" type="button" caption="Actions" cellTemplate="actionButtonCellTemplate">
                </dxi-column>
                <div *dxTemplate="let cellInfo of 'actionButtonCellTemplate'">
                  <button *ngIf="!cellInfo['data']['isImported']" mat-raised-button [disabled]="false" style="height: 43px;max-height: min-content; top: 0.5em; margin: 0 5px;" color="darkblue" class="import-btn"> IMPORT </button>
                  <button *ngIf="cellInfo['data']['isImported']" mat-raised-button [disabled]="false" style="height: 43px;max-height: min-content; top: 0.5em; margin: 0 5px;" color="darkblue" class="imported-btn"> IMPORTED </button>
                </div>
            </dx-data-grid>
      </div>
    </div>
  </div>
</div>