import { Component, Inject, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CacheProviderService } from "../../services/cache-provider.service";
import { UtilityService } from "../../services/utilities.service";
import { ToastrService } from "ngx-toastr";
import { SecurityService } from "src/app/securities/security.service";
import { Observable, Subscriber } from "rxjs";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PermissionsService } from "src/app/services/permissions.service";

@Component({
  selector: "app-shareholder-fund",
  templateUrl: "./shareholder-fund.html",
  styleUrls: ["./shareholder-details.component.scss"],
})
export class ShareHolderFundDialog {
  shareHolderFundDetails: any;
  selectedFundDetails: any;
  usrCompanyCurrency = "";
  shareholderDetails;
  constructor(
    public dialogRef: MatDialogRef<ShareHolderFundDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shareHolderFundDetails: any;
      selectedFundDetails: any;
      usrCompanyCurrency: any;
      shareholderDetails: any;
    },
    private readonly utilityService: UtilityService,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.selectedFundDetails = data.selectedFundDetails;
    this.shareHolderFundDetails = data.shareHolderFundDetails;
    this.usrCompanyCurrency = data.usrCompanyCurrency;
    this.shareholderDetails = data.shareholderDetails;
  }
  close() {
    this.dialogRef.close();
  }
  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(
          this.usrCompanyCurrency,
          value
        );
      } else {
        return this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          value
        );
      }
    } else {
      return 0;
    }
  }
}

@Component({
  selector: "app-shareholder-details",
  templateUrl: "./shareholder-details.component.html",
  styleUrls: ["./shareholder-details.component.scss"],
})
export class ShareholderDetailsComponent implements OnInit {
  shareholderDetails;
  showBeneficiary: boolean = false;
  profileId;
  public showDialogCert: boolean;
  public myTransactions;
  selectedFundDetails;
  shareHolderFundDetails;
  detailsHeader;
  shareAgreement;
  investmentRecordId = null;
  showSharehldrfundpg: boolean = false;
  public userDetails;
  public usrCompanyCurrency = "";
  public showdigPrfle: boolean;
  public shareholderId;
  public shareHolderType;
  public groupNames;
  public shareholderName;
  shareholderEmail;
  genCredentialsDialog = {
    show: false,
    email: null,
    shareholderId: null,
    shareholderName: null,
    viewCapTableSummary: null,
  };
  shareCertificateDialog = {
    show: false,
    email: null,
    shareholderId: null,
    shareholderName: null,
  };
  genCredentials: Observable<string>;
  shareCertificate: Observable<string>;
  genCredentialsSubscriber: Subscriber<any>;
  shareCertificateSubscriber: Subscriber<any>;
  currencySymbol = "";

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private cacheService: CacheProviderService,
    private utilityService: UtilityService,
    private toastr: ToastrService,
    private securityService: SecurityService,
    private permService: PermissionsService,
    private readonly dialog: MatDialog
  ) {}

  identifierCSS(identifier) {
    switch (identifier) {
      case "FF":
        return identifier;
      case "SF":
        return identifier;
      case "AF":
        return identifier;
      case "ES":
        return identifier;
      case "SA":
        return identifier;
      case "SB":
        return identifier;
      case "SC":
        return identifier;
      case "AC":
        return identifier;
      case "SD":
        return identifier;
      case "SE":
        return identifier;
      case "AF1":
        return "Def1";
      case "AF2":
        return "Def2";
      case "AF3":
        return "Def3";
      case "SP":
        return "Def4";
      case "SG":
        return "Def5";
      case "SH":
        return "Def6";
      case "SA1":
        return "Def7";
      case "SA2":
        return "Def8";
      case "SA3":
        return "Def1";
      case "SB1":
        return "Def2";
      case "SB2":
        return "Def3";
      case "SP1":
        return "Def4";
      default:
        return "defaultClass";
    }
  }
  goToSecondary(shareholder) {
    let shareholderid = this.activatedRoute.snapshot.paramMap.get("id");
    this.router.navigate([
      "/landing",
      "dashboard",
      "secondary",
      "secondary-seller",
      { shareholder: shareholder, shareholderid: shareholderid },
    ]);
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(
          this.usrCompanyCurrency,
          value
        );
      } else {
        return this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          value
        );
      }
    } else {
      return 0;
    }
  }

  formatCurrency(value) {
    return this.utilityService.formatCurrencyDeci(
      this.usrCompanyCurrency,
      value
    );
  }

  // Navigating to Round details
  goToRoundDetails(rndName) {
    if (this.permService.authorizeUser(["shareholder-viewer", "!strict"])) {
      return this.toastr.error(
        "You do not have the permission to access this page.",
        "Failure!"
      );
    }
    if (rndName != "ES") {
      this.router.navigate([
        "/landing",
        "dashboard",
        "rounds",
        "round-details",
        rndName,
      ]);
    }
  }

  importCertFile(event: any, investmentRecordId): void {
    this.ngxLoader.start();
    let fd = new FormData();
    fd.append("file", event.target.files[0]);
    this.authService.uploadShareCertificate(fd, investmentRecordId).subscribe(
      (response) => {
        this.ngxLoader.stop();
        $("#uploadImage").val(null);
        this.toastr.success("Successfully uploaded.", "Success;");
      },
      (e) => {
        $("#uploadImage").val(null);
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  sendShareCertificate() {
    const promise = new Promise((resolve, reject) => {
      this.utilityService.showOverlay();
      this.shareCertificateDialog.show = true;
      this.shareCertificateDialog.shareholderId = this.shareholderId;
      this.shareCertificateDialog.shareholderName = this.shareholderName;
      this.shareCertificateDialog.email = this.shareholderEmail;
      this.shareCertificate.subscribe((res: any) => {
        let obj = { ...res };
        resolve(obj);
      });
    });
    return promise.then((res: any) => {
      this.shareCertificateDialog.email = res.shareholderEmail;
      this.shareCertificateSubscriber.unsubscribe();
    });
  }

  getShareCertData(e) {
    this.shareCertificateSubscriber.next(this.shareCertificateDialog);
    if (e) {
      this.ngxLoader.start();
      let shareCertBody = {
        "profileId": this.shareCertificateDialog.shareholderId,
        "emailId" : this.shareCertificateDialog.email
      }
      this.authService.sendShareCertificate(shareCertBody).subscribe(
        (res) => {
          this.toastr.success("Share Certificate sent!");
          this.ngxLoader.stop();
        },
        (e) => {
          this.toastr.error(e.error.reason, "Failure!");
          this.ngxLoader.stop();
        }
      );
    }
    this.shareCertificateDialog.show = false;
    this.shareCertificateDialog.shareholderId = null;
    this.shareCertificateDialog.shareholderName = null;
    this.shareCertificateDialog.email = null;
    document.getElementById("overlay").style.display = "none";
  }

  generateCred() {
    const promise = new Promise((resolve, reject) => {
      this.utilityService.showOverlay();
      this.genCredentialsDialog.show = true;
      this.genCredentialsDialog.shareholderId = this.shareholderId;
      this.genCredentialsDialog.shareholderName = this.shareholderName;
      this.genCredentialsDialog.email = this.shareholderEmail;
      this.genCredentialsDialog.viewCapTableSummary = false;
      this.genCredentials.subscribe((res: any) => {
        let obj = { ...res };
        resolve(obj);
      });
    });
    return promise.then((res: any) => {
      this.genCredentialsDialog.email = res.shareholderEmail;
      this.genCredentialsDialog.viewCapTableSummary = res.viewCapTableSummary;
      this.genCredentialsSubscriber.unsubscribe();
    });
  }

  getCredData(e) {
    this.genCredentialsSubscriber.next(this.genCredentialsDialog);
    if (e) {
      this.ngxLoader.start();
      let credentialsObj = {
        id: this.genCredentialsDialog.shareholderId,
        viewCapTableSummary: this.genCredentialsDialog.viewCapTableSummary,
        emailId: this.genCredentialsDialog.email,
      };
      this.authService.generateShareholderCredentials(credentialsObj).subscribe(
        (res) => {
          this.toastr.success("Credentials generated successfully!");
          this.ngxLoader.stop();
        },
        (e) => {
          this.toastr.error(e.error.reason, "Failure!");
          this.ngxLoader.stop();
        }
      );
    }
    this.genCredentialsDialog.show = false;
    this.genCredentialsDialog.shareholderId = null;
    this.genCredentialsDialog.shareholderName = null;
    this.genCredentialsDialog.viewCapTableSummary = null;
    this.genCredentialsDialog.email = null;
    document.getElementById("overlay").style.display = "none";
  }

  navigateTo(type, id, profileId, navigate: boolean) {
    let navigationExtras: NavigationExtras = {
      queryParams: { id: id, profileId: profileId },
    };
    if (type == "conversion" && navigate) {
      this.ngxLoader.start();
      this.authService.getSecurityDetails(id, profileId).subscribe(
        (response: any) => {
          this.ngxLoader.stop();
          this.securityService.setConversionDetails = response;
          this.router.navigate(
            ["/landing", "dashboard", "shareholders", "conversion"],
            navigationExtras
          );
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      );
    } else if (type == "redemption" && navigate) {
      this.router.navigate(
        ["/landing", "dashboard", "shareholders", "redemption"],
        navigationExtras
      );
    } else if (type == "partly-paid" && navigate) {
      this.router.navigate(
        ["/landing", "dashboard", "shareholders", "partly-paid"],
        navigationExtras
      );
    }
  }

  openBeneficiaryDialog(profileId) {
    this.showBeneficiary = true;
    this.profileId = profileId;
    document.getElementById("overlay").style.display = "block";
  }

  getBeneficiary(e) {
    this.showBeneficiary = false;
    document.getElementById("overlay").style.display = "none";
    this.ngOnInit();
  }

  goToBeneficiaries() {
    this.ngxLoader.start();
    this.router.navigate(['/landing', 'dashboard', 'shareholders', 'beneficiaries'], { queryParams: { shrProfile: this.shareholderId } });
  }

  ngOnInit() {
    this.ngxLoader.start();
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails["company"]["companyCurrency"];
    } else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
    let shareholderID = this.activatedRoute.snapshot.paramMap.get("id");
    this.shareholderId = shareholderID;
    this.authService.getShareholderProfile(this.shareholderId).subscribe(
      (res) => {
        this.shareholderEmail = res["emailId"];
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      }
    );
    this.authService.getShareholderDetails(shareholderID).subscribe(
      (res) => {
        if (res) {
          this.shareholderDetails = res;
          this.genCredentials = new Observable((subscriber) => {
            this.genCredentialsSubscriber = subscriber;
          });
          this.shareCertificate = new Observable((subscriber) => {
            this.shareCertificateSubscriber = subscriber;
          });
          this.shareholderName =
            this.shareholderDetails.shareholderDetailsDto.name;
          this.getMytransactions(
            // this.shareholderDetails.shareholderDetailsDto.name
            this.shareholderId
          );
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Error: Null response!.", "Error");
        }
      },
      (e) => {
        if (e.error.reason === "Login Token Expired.") {
          this.ngxLoader.stop();
          localStorage.clear();
          this.router.navigate([""]);
          return;
        } else {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Error");
        }
      }
    );

    this.authService.getNwInstrShrHldrGroupInfo().subscribe(
      (res) => {
        if (res) {
          this.shareHolderType = res["typeOfShareholder"];
          this.groupNames = res["groupName"];
          this.ngxLoader.stop();
        }
      },
      (e) => {
        this.toastr.error(e.error.reason, "Error");
        this.ngxLoader.stop();
      }
    );
  }

  getMytransactions(shareholderId) {
    this.authService.getMyTransactions(shareholderId).subscribe(
      (res) => {
        if (res) {
          this.myTransactions = res;
        } else {
          this.toastr.error("Error: Null response!.", "Error");
        }
      },
      (e) => {
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  showCertificate(investmentRecordId) {
    this.showDialogCert = true;
    this.investmentRecordId = investmentRecordId;
    document.getElementById("overlay").style.display = "block";
  }

  getEventCert(e) {
    this.showDialogCert = e;
    document.getElementById("overlay").style.display = "none";
  }

  openFundModel() {
    let shareholderID = this.activatedRoute.snapshot.paramMap.get("id");
    this.showSharehldrfundpg = true;
    this.ngxLoader.start();
    this.authService.shareHolderFundDetails(shareholderID).subscribe(
      (res) => {
        if (res) {
          this.shareHolderFundDetails = res;
          this.selectedFundDetails = this.shareHolderFundDetails;
          this.ngxLoader.stop();
          this.dialog.open(ShareHolderFundDialog, {
            data: {
              shareHolderFundDetails: this.shareHolderFundDetails,
              selectedFundDetails: this.selectedFundDetails,
              usrCompanyCurrency: this.usrCompanyCurrency,
              shareholderDetails: this.shareholderDetails,
            },
            width: "800px",
            disableClose: true,
          });
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Error: Null response!.", "Error");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  editShareholderProfile() {
    this.shareholderId = this.activatedRoute.snapshot.paramMap.get("id");
    this.showdigPrfle = true;
    document.getElementById("overlay").style.display = "block";
  }

  getEventProfile(e) {
    this.showdigPrfle = e.showMdl;
    document.getElementById("overlay").style.display = "none";
  }
}
