var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../authentication.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UtilityService } from "../../services/utilities.service";
var ProfileInfoComponent = /** @class */ (function () {
    function ProfileInfoComponent(authService, toastr, fb, ngxLoader, utility) {
        this.authService = authService;
        this.toastr = toastr;
        this.fb = fb;
        this.ngxLoader = ngxLoader;
        this.utility = utility;
        this.visibleChange = new EventEmitter();
        this.parentInit = new EventEmitter();
        this.newGroupErr = false;
        this.profileInfObj = {
            typeOfShareholder: "",
            shareholderSubType: null,
            isRelativeOfDirector: false,
            groupName: {
                groupName: "",
            },
            registeredAddress: "",
            corporateAddress: "",
            residentStatus: "",
            occupation: "",
            nationality: "",
            contactNumber: "",
            emailId: "",
            legalName: "",
            dpId: "",
            clientId: "",
            depositoryName: "",
            isPromoter: false,
            imageBase64String: null,
            imageName: "",
            contenttype: "",
            documentPath: {},
            imageUrl: null,
        };
        this.showNewGrpMdl = false;
        this.mShareholderType = false;
        this.mShareholderSubType = false;
        this.mGroup = false;
        this.mEmail = false;
        this.valid = false;
        this.mNationality = false;
        this.mResidence = false;
        this.mlegalName = false;
        this.selectedResidentType = "India";
        this.selectedCountry = "India";
        this.croppedImage = null;
        this.shouldShowDialog = false;
        this.profilePicValid = false;
        this.profilePicture = null;
        this.content = "image/jpeg";
        this.groupMandatory = false;
        this.subTypeMandatory = false;
        this.selGroupName = null;
        this.disableripple = true;
        this.maxLength = 9;
        this.emailPattern = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$";
    }
    ProfileInfoComponent.prototype.validatorMethod = function (data) {
        var groupname = null;
        if (data.groupName != null) {
            groupname = data.groupName["groupName"];
        }
        if (this.subTypeMandatory) {
            if ((data.emailId && !data.emailId.match(this.emailPattern)) ||
                !data.typeOfShareholder ||
                !data.legalName ||
                !data.residentStatus ||
                !data.nationality ||
                !groupname ||
                groupname == undefined ||
                groupname === "newGroup" ||
                !data.shareholderSubType) {
                return false;
            }
            else {
                return true;
            }
        }
        else if (this.groupMandatory) {
            if ((data.emailId && !data.emailId.match(this.emailPattern)) ||
                !data.typeOfShareholder ||
                !data.legalName ||
                !data.residentStatus ||
                !data.nationality ||
                !groupname ||
                groupname == undefined ||
                groupname === "newGroup") {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if ((data.emailId && !data.emailId.match(this.emailPattern)) ||
                !data.typeOfShareholder ||
                !data.legalName ||
                !data.residentStatus ||
                !data.nationality) {
                return false;
            }
            else {
                return true;
            }
        }
    };
    ProfileInfoComponent.prototype.validatorMethodNogroup = function (data) {
        if (!data.emailId || !data.typeOfShareholder || !data.legalName) {
            return false;
        }
        else {
            return true;
        }
    };
    ProfileInfoComponent.prototype.saveUserProfile = function () {
        var _this = this;
        this.utility.formValidation();
        this.valid = this.validatorMethod(this.profileInfObj);
        var groupname = null;
        if (this.profileInfObj.groupName != null) {
            groupname = this.profileInfObj.groupName["groupName"];
        }
        if (!this.valid) {
            if (this.profileInfObj.typeOfShareholder === "" ||
                this.profileInfObj.typeOfShareholder == null) {
                this.mShareholderType = true;
            }
            if (this.profileInfObj.emailId &&
                !this.profileInfObj.emailId.match(this.emailPattern)) {
                this.mEmail = true;
            }
            if (this.profileInfObj.legalName === "" ||
                this.profileInfObj.legalName == null) {
                this.mlegalName = true;
            }
            if (this.profileInfObj.residentStatus === "" ||
                this.profileInfObj.residentStatus == null) {
                this.mResidence = true;
            }
            if (this.profileInfObj.nationality === "" ||
                this.profileInfObj.nationality == null) {
                this.mNationality = true;
            }
            if (this.profileInfObj.typeOfShareholder === "VC" ||
                this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
                this.profileInfObj.typeOfShareholder === "PE") {
                if (groupname == undefined ||
                    groupname === "" ||
                    groupname === "newGroup") {
                    if (groupname == "newGroup") {
                        this.newGroupErr = true;
                    }
                    this.mGroup = true;
                }
            }
            else {
                this.mGroup = false;
            }
            if (this.profileInfObj.typeOfShareholder === "FinancialInstitution") {
                if (this.profileInfObj.shareholderSubType == undefined ||
                    this.profileInfObj.shareholderSubType === "" ||
                    this.profileInfObj.shareholderSubType == null) {
                    this.mShareholderSubType = true;
                }
            }
            return;
        }
        else {
            this.profileInfObj.legalName = this.profileInfObj.legalName
                .replace(/ \t/g, "")
                .replace(/ +(?= )/g, "")
                .trim();
            if (this.shareholderId) {
                this.ngxLoader.start();
                this.profileInfObj["id"] = +this.shareholderId;
                this.authService
                    .updateShareholderProfile(this.shareholderId, this.profileInfObj)
                    .subscribe(function (res) {
                    if (res) {
                        _this.ngxLoader.stop();
                        _this.toastr.success("Shareholder profile updated sucessfully");
                        _this.showdigPrfle = false;
                        _this.visibleChange.emit({ showMdl: _this.showdigPrfle });
                        _this.parentInit.emit();
                    }
                }, function (e) {
                    _this.toastr.error(e.error.reason, "Error");
                    _this.ngxLoader.stop();
                });
            }
            else if (this.isSecoundaryBuyer) {
                this.ngxLoader.start();
                this.showdigPrfle = false;
                this.isSecoundaryBuyer = false;
                this.visibleChange.emit({
                    showMdl: this.showdigPrfle,
                    profileInfo: this.profileInfObj,
                    isSecoundaryBuyer: this.isSecoundaryBuyer,
                });
                this.ngxLoader.stop();
            }
            else if (this.isNewProfile || this.beneficiaryId) {
                this.ngxLoader.start();
                if (this.profileInfObj.emailId === "" ||
                    this.profileInfObj.emailId == null) {
                    this.toastr.warning("Email not entered", "Warning");
                }
                this.showdigPrfle = false;
                this.visibleChange.emit({ profileInfo: this.profileInfObj });
                return this.ngxLoader.stop();
            }
            else if (this.fromHistoricalRound) {
                this.ngxLoader.start();
                if (this.profileInfObj.emailId === "" ||
                    this.profileInfObj.emailId == null) {
                    this.toastr.warning("Email not entered", "Warning");
                }
                for (var _i = 0, _a = this.investorsArray; _i < _a.length; _i++) {
                    var investor = _a[_i];
                    if (investor.investorName == this.newInvestorProfile.investorName) {
                        investor.investorProfile = this.profileInfObj;
                    }
                }
                this.showdigPrfle = false;
                this.visibleChange.emit({ showMdl: this.showdigPrfle });
                return this.ngxLoader.stop();
            }
            else if (this.newInvestorProfile) {
                this.ngxLoader.start();
                if (this.profileInfObj.emailId === "" ||
                    this.profileInfObj.emailId == null) {
                    this.toastr.warning("Email not entered", "Warning");
                }
                this.showdigPrfle = false;
                this.fromNewInv = false;
                this.visibleChange.emit({
                    showMdl: this.showdigPrfle,
                    profileInfo: this.profileInfObj,
                    fromNewInv: this.fromNewInv,
                    index: this.newInvestorProfileindex,
                });
                this.ngxLoader.stop();
            }
            else {
                this.showdigPrfle = false;
                this.visibleChange.emit({
                    index: this.selectedIndex,
                    profileInfo: this.profileInfObj,
                    showMdl: this.showdigPrfle,
                });
            }
        }
    };
    ProfileInfoComponent.prototype.emailValid = function (email) {
        if (email && !email.match(this.emailPattern)) {
            this.mEmail = true;
        }
        else {
            this.mEmail = false;
        }
    };
    ProfileInfoComponent.prototype.shareHoderTypeChange = function (shrHldrType) {
        var shareholderType = shrHldrType;
        this.mShareholderType = false;
        this.groupMandatory = false;
        this.profileInfObj.typeOfShareholder = shrHldrType;
        this.mGroup = false;
        var groupName = null;
        if (this.profileInfObj.groupName != null) {
            groupName = this.profileInfObj.groupName["groupName"];
        }
        if ((shareholderType === "VC" ||
            shareholderType === "AngelNetwork" ||
            shareholderType === "PE" ||
            shareholderType === "FinancialInstitution") &&
            (groupName === "" || groupName != undefined)) {
            this.groupMandatory = true;
        }
        else {
            this.mGroup = false;
            this.groupMandatory = false;
        }
        if (shareholderType === "FinancialInstitution") {
            if (!this.profileInfObj.shareholderSubType) {
                this.subTypeMandatory = true;
            }
            else {
                this.subTypeMandatory = false;
            }
        }
    };
    ProfileInfoComponent.prototype.onSubTypeSelect = function (shareholderSubType) {
        this.profileInfObj.shareholderSubType = shareholderSubType;
    };
    ProfileInfoComponent.prototype.gropSelectionChange = function (val) {
        if (val == "newGroup") {
            this.profileInfObj.groupName = null;
            this.showNewGrpMdl = true;
        }
        else {
            this.mGroup = false;
            this.newGroupErr = false;
            this.profileInfObj.groupName = { groupName: val };
            this.showNewGrpMdl = false;
        }
    };
    ProfileInfoComponent.prototype.close = function () {
        if (this.fromNewInv) {
            this.showdigPrfle = false;
            this.fromNewInv = false;
            this.visibleChange.emit({
                showMdl: this.showdigPrfle,
                fromNewInv: this.fromNewInv,
            });
        }
        else {
            this.showdigPrfle = false;
            this.visibleChange.emit({ showMdl: this.showdigPrfle });
        }
    };
    ProfileInfoComponent.prototype.getEvent = function () {
        this.shouldShowDialog = false;
        this.utility.hideOverlay();
    };
    ProfileInfoComponent.prototype.getNewGroupInfo = function (e) {
        var _this = this;
        this.showNewGrpMdl = e.showMdl;
        var ng = e.newGroup;
        if (this.isSecoundaryBuyer) {
            this.authService.getBuyerDropdown().subscribe(function (response) {
                _this.shareHolderType = response["shareholderTypes"];
                _this.groupNames = response["groupName"];
                if (ng) {
                    _this.selGroupName = _this.groupNames[_this.groupNames.length - 1];
                    _this.profileInfObj.groupName = { groupName: _this.selGroupName };
                }
                else {
                    _this.selGroupName = null;
                }
            }, function (e) {
                _this.toastr.error("Error: " + e.error.reason);
            });
        }
        else {
            this.authService.getNwInstrShrHldrGroupInfo().subscribe(function (response) {
                _this.shareHolderType = response["shareholderTypes"];
                _this.groupNames = response["groupName"];
                if (ng) {
                    _this.selGroupName = _this.groupNames[_this.groupNames.length - 1];
                    _this.profileInfObj.groupName = { groupName: _this.selGroupName };
                }
                else {
                    _this.selGroupName = null;
                }
            }, function (e) {
                _this.toastr.error("Error: " + e.error.reason);
            });
        }
    };
    ProfileInfoComponent.prototype.getSharholderGroupInfo = function () {
        var _this = this;
        if (this.isSecoundaryBuyer) {
            this.authService.getBuyerDropdown().subscribe(function (res) {
                _this.shareHolderType = res["shareholderTypes"];
                _this.shareholderSubTypesList = res["shareholderSubTypes"];
                _this.groupNames = res["groupName"];
            }, function (e) {
                _this.toastr.error("Error: " + e.error.reason);
            });
        }
        else {
            this.authService.getNwInstrShrHldrGroupInfo().subscribe(function (response) {
                _this.shareHolderType = response["shareholderTypes"];
                _this.shareholderSubTypesList = response["shareholderSubTypes"];
                _this.groupNames = response["groupName"];
            }, function (e) {
                _this.toastr.error("Error: " + e.error.reason);
            });
        }
    };
    ProfileInfoComponent.prototype.legalNameChange = function (legalName) {
        if (legalName != "") {
            this.mlegalName = false;
        }
    };
    ProfileInfoComponent.prototype.onChangeResidentSelect = function (event) {
        this.profileInfObj.residentStatus = event;
    };
    ProfileInfoComponent.prototype.onChangeCountrySelect = function (event) {
        this.profileInfObj.nationality = event;
    };
    ProfileInfoComponent.prototype.openUploadPicturePopup = function () {
        this.utility.showOverlay();
        this.shouldShowDialog = true;
    };
    ProfileInfoComponent.prototype.dataURItoBlob = function (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0) {
            byteString = atob(dataURI.split(",")[1]);
        }
        else {
            byteString = unescape(dataURI.split(",")[1]);
        }
        // separate out the mime component
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    };
    ProfileInfoComponent.prototype.onImageSaved = function (imageDetails) {
        this.shouldShowDialog = false;
        this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
        this.profileInfObj.imageName = imageDetails.fileName;
        this.croppedImage = imageDetails.croppedImage;
        this.profileInfObj.imageBase64String = this.croppedImage;
        this.profileInfObj.contenttype = this.content;
    };
    ProfileInfoComponent.prototype.changeCroppedImage = function () {
        this.profileInfObj.imageBase64String = null;
        this.profileInfObj.imageUrl = null;
        this.profileInfObj.documentPath = null;
    };
    ProfileInfoComponent.prototype.uploadAvatarImage = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var tempImage, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.croppedImage = event.target.src;
                        tempImage = event.target.src;
                        this.profileInfObj.imageName = tempImage.split("/").pop();
                        this.profileInfObj.contenttype = this.content;
                        _a = this;
                        return [4 /*yield*/, fetch(tempImage).then(function (res) { return res.blob(); })];
                    case 1:
                        _a.profilePicture = _b.sent();
                        this.handleFileSelect(this.profilePicture);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileInfoComponent.prototype.handleFileSelect = function (evt) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(evt);
    };
    ProfileInfoComponent.prototype.handleReaderLoaded = function (e) {
        this.croppedImage = "data:image/png;base64," + btoa(e.target.result);
        this.profileInfObj.imageBase64String = this.croppedImage;
    };
    ProfileInfoComponent.prototype.maxNumber = function (val) {
        if (val.length > this.maxLength) {
            this.profileInfObj.contactNumber = val.slice(0, this.maxLength);
        }
    };
    // ngAfterViewInit() {
    //   this.loadJSstyles();
    // }
    ProfileInfoComponent.prototype.ngAfterViewChecked = function () {
        this.loadJSstyles();
    };
    ProfileInfoComponent.prototype.loadJSstyles = function () {
        var _this = this;
        $('[data-toggle="switch"]').bootstrapSwitch({
            onText: "Yes",
            offText: "No",
        });
        $("#promoter").bootstrapSwitch('state', this.profileInfObj.isPromoter);
        $("#promoter").on('switchChange.bootstrapSwitch', function (event, state) {
            _this.profileInfObj.isPromoter = state;
        });
        $("#isRelative").bootstrapSwitch('state', this.profileInfObj.isRelativeOfDirector);
        $("#isRelative").on('switchChange.bootstrapSwitch', function (event, state) {
            _this.profileInfObj.isRelativeOfDirector = state;
        });
    };
    ProfileInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.shareholderId) {
            this.ngxLoader.start();
            var _a = require("country-list"), getCode = _a.getCode, getNames = _a.getNames;
            this.countryList = getNames();
            this.getSharholderGroupInfo();
            this.authService.getShareholderProfile(this.shareholderId).subscribe(function (res) {
                if (res) {
                    _this.name = _this.shareholderName;
                    _this.profileInfObj.typeOfShareholder = res["typeOfShareholder"];
                    _this.profileInfObj.shareholderSubType = res["shareholderSubType"] ? res["shareholderSubType"] : null;
                    _this.profileInfObj.isRelativeOfDirector = res["isRelativeOfDirector"];
                    _this.profileInfObj.registeredAddress = res["registeredAddress"];
                    _this.profileInfObj.dpId = res["dpId"];
                    _this.profileInfObj.clientId = res["clientId"];
                    _this.profileInfObj.depositoryName = res["depositoryName"];
                    _this.profileInfObj.isPromoter = res["isPromoter"];
                    if (res["residentStatus"] == null || res["residentStatus"] === "") {
                        _this.profileInfObj.residentStatus = _this.selectedResidentType;
                    }
                    else {
                        _this.profileInfObj.residentStatus = res["residentStatus"];
                    }
                    if (res["nationality"] == null || res["nationality"] === "") {
                        _this.profileInfObj.nationality = _this.selectedCountry;
                    }
                    else {
                        _this.profileInfObj.nationality = res["nationality"];
                    }
                    _this.profileInfObj.occupation = res["occupation"];
                    _this.profileInfObj.emailId = res["emailId"];
                    _this.profileInfObj.corporateAddress = res["corporateAddress"];
                    _this.profileInfObj.contactNumber = res["contactNumber"];
                    _this.profileInfObj.legalName = res["legalName"];
                    var group = res["groupName"];
                    if (group != undefined) {
                        _this.selGroupName = group["groupName"];
                        _this.profileInfObj.groupName = { groupName: _this.selGroupName };
                    }
                    if (_this.profileInfObj.typeOfShareholder === "VC" ||
                        _this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
                        _this.profileInfObj.typeOfShareholder === "PE" ||
                        _this.profileInfObj.typeOfShareholder === "FinancialInstitution") {
                        _this.groupMandatory = true;
                    }
                    _this.profileInfObj.imageUrl = res["imageUrl"];
                    _this.docPath = res["documentPath"];
                    _this.profileInfObj.documentPath = _this.docPath;
                    // this.loadJSstyles();
                    _this.ngxLoader.stop();
                }
            }, function (e) {
                _this.toastr.error(e.error.reason, "Error");
                _this.ngxLoader.stop();
            });
        }
        else if (this.beneficiaryId) {
            this.name = this.beneficiaryProfile.name;
            this.ngxLoader.start();
            var _b = require("country-list"), getCode = _b.getCode, getNames = _b.getNames;
            this.countryList = getNames();
            this.getSharholderGroupInfo();
            this.profileInfObj = this.beneficiaryProfile;
            var group = this.beneficiaryProfile["groupName"];
            if (group != undefined) {
                this.selGroupName = group["groupName"];
                this.profileInfObj.groupName = { groupName: this.selGroupName };
            }
            if (this.profileInfObj.typeOfShareholder === "VC" ||
                this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
                this.profileInfObj.typeOfShareholder === "PE" ||
                this.profileInfObj.typeOfShareholder === "FinancialInstitution") {
                this.groupMandatory = true;
            }
            this.ngxLoader.stop();
        }
        else if (this.isSecoundaryBuyer) {
            var _c = require("country-list"), getCode = _c.getCode, getNames = _c.getNames;
            this.countryList = getNames();
            this.profileInfObj.residentStatus = this.selectedResidentType;
            this.profileInfObj.nationality = this.selectedCountry;
            this.getSharholderGroupInfo();
        }
        else if (this.isNewProfile) {
            var _d = require("country-list"), getCode = _d.getCode, getNames = _d.getNames;
            this.countryList = getNames();
            this.profileInfObj.residentStatus = this.selectedResidentType;
            this.profileInfObj.nationality = this.selectedCountry;
            this.getSharholderGroupInfo();
        }
        else if (this.fromNewInv) {
            this.name = this.newInvestorProfile["investorName"];
            var _e = require("country-list"), getCode = _e.getCode, getNames = _e.getNames;
            this.countryList = getNames();
            this.getSharholderGroupInfo();
            if (this.newInvestorProfile["residentStatus"]) {
                this.profileInfObj.residentStatus =
                    this.newInvestorProfile["residentStatus"];
            }
            else {
                this.profileInfObj.residentStatus = this.selectedResidentType;
            }
            if (this.newInvestorProfile["nationality"]) {
                this.profileInfObj.nationality = this.newInvestorProfile["nationality"];
            }
            else {
                this.profileInfObj.nationality = this.selectedCountry;
            }
            this.profileInfObj.typeOfShareholder =
                this.newInvestorProfile["typeOfInvestor"];
            this.profileInfObj.registeredAddress =
                this.newInvestorProfile["registeredAddress"];
            this.profileInfObj.residentStatus =
                this.newInvestorProfile["residentStatus"];
            this.profileInfObj.shareholderSubType = this.newInvestorProfile["shareholderSubType"] ? this.newInvestorProfile["shareholderSubType"] : null;
            this.profileInfObj.isRelativeOfDirector = this.newInvestorProfile["isRelativeOfDirector"];
            this.profileInfObj.dpId = this.newInvestorProfile["dpId"];
            this.profileInfObj.clientId = this.newInvestorProfile["clientId"];
            this.profileInfObj.depositoryName = this.newInvestorProfile["depositoryName"];
            this.profileInfObj.isPromoter = this.newInvestorProfile["isPromoter"];
            this.profileInfObj.nationality = this.newInvestorProfile["nationality"];
            this.profileInfObj.occupation = this.newInvestorProfile["occupation"];
            this.profileInfObj.emailId = this.newInvestorProfile["emailId"];
            this.profileInfObj.corporateAddress =
                this.newInvestorProfile["corporateAddress"];
            this.profileInfObj.contactNumber =
                this.newInvestorProfile["contactNumber"];
            if (this.newInvestorProfile["legalName"] == "") {
                this.profileInfObj.legalName = this.newInvestorProfile["investorName"];
            }
            else {
                this.profileInfObj.legalName = this.newInvestorProfile["legalName"];
            }
            var group = this.newInvestorProfile["groupName"];
            if (group != undefined) {
                this.selGroupName = group["groupName"];
                if (this.selGroupName != "") {
                    this.profileInfObj.groupName = { groupName: this.selGroupName };
                }
                else {
                    this.selGroupName = "";
                }
            }
            if (this.profileInfObj.typeOfShareholder === "VC" ||
                this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
                this.profileInfObj.typeOfShareholder === "PE" ||
                this.profileInfObj.typeOfShareholder === "FinancialInstitution") {
                this.groupMandatory = true;
            }
            this.profileInfObj.imageUrl = this.newInvestorProfile["imageUrl"];
            this.docPath = this.newInvestorProfile["documentPath"];
            this.profileInfObj.documentPath = this.docPath;
        }
        else if (this.fromHistoricalRound) {
            if (this.newInvestorProfile["investorName"]) {
                this.name = this.newInvestorProfile["investorName"];
                this.profileInfObj.legalName = this.newInvestorProfile["investorName"];
            }
            else if (this.newInvestorProfile["name"]) {
                this.name = this.newInvestorProfile["name"];
                this.profileInfObj.legalName = this.newInvestorProfile["name"];
            }
            else {
                this.name = this.newInvestorProfile["buyer"];
                this.profileInfObj.legalName = this.newInvestorProfile["buyer"];
            }
            var _f = require("country-list"), getCode = _f.getCode, getNames = _f.getNames;
            this.countryList = getNames();
            this.getSharholderGroupInfo();
            if (this.newInvestorProfile.investorProfile != null) {
                if (this.newInvestorProfile.investorProfile["residentStatus"]) {
                    this.profileInfObj.residentStatus =
                        this.newInvestorProfile.investorProfile["residentStatus"];
                }
                else {
                    this.profileInfObj.residentStatus = this.selectedResidentType;
                }
                if (this.newInvestorProfile.investorProfile["nationality"]) {
                    this.profileInfObj.nationality =
                        this.newInvestorProfile.investorProfile["nationality"];
                }
                else {
                    this.profileInfObj.nationality = this.selectedCountry;
                }
                this.profileInfObj.typeOfShareholder =
                    this.newInvestorProfile.investorProfile["typeOfShareholder"];
                this.profileInfObj.registeredAddress =
                    this.newInvestorProfile.investorProfile["registeredAddress"];
                this.profileInfObj.shareholderSubType = this.newInvestorProfile.investorProfile["shareholderSubType"] ? this.newInvestorProfile.investorProfile["shareholderSubType"] : null;
                this.profileInfObj.isRelativeOfDirector = this.newInvestorProfile.investorProfile["isRelativeOfDirector"];
                this.profileInfObj.dpId = this.newInvestorProfile.investorProfile["dpId"];
                this.profileInfObj.clientId = this.newInvestorProfile.investorProfile["clientId"];
                this.profileInfObj.depositoryName = this.newInvestorProfile.investorProfile["depositoryName"];
                this.profileInfObj.isPromoter = this.newInvestorProfile.investorProfile["isPromoter"];
                this.profileInfObj.occupation =
                    this.newInvestorProfile.investorProfile["occupation"];
                this.profileInfObj.emailId =
                    this.newInvestorProfile.investorProfile["emailId"];
                this.profileInfObj.corporateAddress =
                    this.newInvestorProfile.investorProfile["corporateAddress"];
                this.profileInfObj.contactNumber =
                    this.newInvestorProfile.investorProfile["contactNumber"];
                if (this.newInvestorProfile.investorProfile["legalName"] == "" ||
                    this.newInvestorProfile.investorProfile["legalName"] == null) {
                    this.profileInfObj.legalName =
                        this.newInvestorProfile.investorProfile["investorName"];
                }
                else {
                    this.profileInfObj.legalName =
                        this.newInvestorProfile.investorProfile["legalName"];
                }
                var group = this.newInvestorProfile.investorProfile["groupName"];
                if (group != undefined) {
                    this.selGroupName =
                        group["groupName"] == "" ? null : group["groupName"];
                    if (this.selGroupName != null) {
                        this.profileInfObj.groupName = { groupName: this.selGroupName };
                    }
                    else {
                        this.selGroupName = null;
                    }
                }
                else {
                    this.selGroupName = null;
                }
                this.profileInfObj.imageUrl =
                    this.newInvestorProfile.investorProfile["imageUrl"];
                this.docPath = this.newInvestorProfile.investorProfile["documentPath"];
            }
            else {
                this.profileInfObj.nationality = this.selectedCountry;
                this.profileInfObj.residentStatus = this.selectedResidentType;
                this.profileInfObj.shareholderSubType = null;
            }
            if (this.profileInfObj.typeOfShareholder === "VC" ||
                this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
                this.profileInfObj.typeOfShareholder === "PE" ||
                this.profileInfObj.typeOfShareholder === "FinancialInstitution") {
                this.groupMandatory = true;
            }
            this.profileInfObj.documentPath = this.docPath;
        }
        else {
            var _g = require("country-list"), getCode = _g.getCode, getNames = _g.getNames;
            this.countryList = getNames();
            this.profileInfObj.residentStatus = this.selectedResidentType;
            this.profileInfObj.nationality = this.selectedCountry;
            this.outstandingConv.forEach(function (element, index) {
                if (_this.selectedIndex === index) {
                    _this.name = element["name"];
                    _this.initObj = element["profileDetails"];
                    _this.valid = _this.validatorMethodNogroup(_this.initObj);
                    if (_this.valid) {
                        _this.profileInfObj.typeOfShareholder =
                            element["profileDetails"]["typeOfShareholder"];
                        _this.profileInfObj.registeredAddress =
                            element["profileDetails"]["registeredAddress"];
                        _this.profileInfObj.residentStatus =
                            element["profileDetails"]["residentStatus"];
                        _this.profileInfObj.shareholderSubType = element["profileDetails"]["shareholderSubType"] ? element["profileDetails"]["shareholderSubType"] : null;
                        _this.profileInfObj.isRelativeOfDirector = element["profileDetails"]["isRelativeOfDirector"];
                        _this.profileInfObj.dpId = element["profileDetails"]["dpId"];
                        _this.profileInfObj.clientId = element["profileDetails"]["clientId"];
                        _this.profileInfObj.depositoryName = element["profileDetails"]["depositoryName"];
                        _this.profileInfObj.isPromoter = element["profileDetails"]["isPromoter"];
                        _this.profileInfObj.nationality =
                            element["profileDetails"]["nationality"];
                        _this.profileInfObj.occupation =
                            element["profileDetails"]["occupation"];
                        _this.profileInfObj.emailId = element["profileDetails"]["emailId"];
                        _this.profileInfObj.corporateAddress =
                            element["profileDetails"]["corporateAddress"];
                        _this.profileInfObj.contactNumber =
                            element["profileDetails"]["contactNumber"];
                        _this.profileInfObj.legalName =
                            element["profileDetails"]["legalName"];
                        var group = element["profileDetails"]["groupName"];
                        if (group != undefined) {
                            _this.selGroupName = group["groupName"];
                            _this.profileInfObj.groupName = { groupName: _this.selGroupName };
                        }
                        if (_this.profileInfObj.typeOfShareholder === "VC" ||
                            _this.profileInfObj.typeOfShareholder === "AngelNetwork" ||
                            _this.profileInfObj.typeOfShareholder === "PE" ||
                            _this.profileInfObj.typeOfShareholder === "FinancialInstitution") {
                            _this.groupMandatory = true;
                        }
                        _this.profileInfObj.imageUrl = element["profileDetails"]["imageUrl"];
                        _this.docPath = element["profileDetails"]["documentPath"];
                        _this.profileInfObj.documentPath = _this.docPath;
                    }
                }
            });
        }
    };
    return ProfileInfoComponent;
}());
export { ProfileInfoComponent };
