<!-- <app-navbar></app-navbar> -->
<div class="container-fluid graybg ]">
  <mat-sidenav-container class="example-container">
    <mat-sidenav disableClose="true" mode="side" class="sidenav-container" opened>
      <!-- <div style="height: 6.2%; background-color: white; position: absolute;">
      <img style="zoom: 75%; margin-left: 10%;" src ="../../assets/logo/logo.svg" alt="" srcset="">
    </div> -->
      <mat-nav-list class="list-container">
        <mat-list-item *ngIf="userPerm.EmpDashboard" >
          <a matLine [routerLink]="['/landing','dashboard','employees','employeeDetails',getEmployeeId()]" routerLinkActive="active">
            <span matLine class="fa fa-home fa-1x " style="color: #E85936;" aria-hidden="true">
            </span>
            <span matLine class="style">Dashboard</span>
          </a>
        </mat-list-item>
        <mat-list-item *ngIf="!esoponlycomp && userPerm.capTable">
          <a matLine [routerLink]="['/landing','dashboard','capTable']" routerLinkActive="active">
            <!-- <mat-icon >
            <i matListIcon class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
          </mat-icon> -->
            <span matLine class="fa  fa-bar-chart fa-1x " style="color: #E85936;" aria-hidden="true">
              <!-- <mat-icon svgIcon="captable" ></mat-icon>  -->
            </span>
            <span matLine class="style">Cap Table</span>
          </a>
        </mat-list-item>


        <mat-list-item *ngIf="!esoponlycomp && userPerm.Transactions">
          <a matLine [class.active]="isTransactionsActive()"
            [routerLink]="['/landing','dashboard','transactions']">
            <!-- <mat-icon>
            <i matListIcon class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
          </mat-icon> -->
            <span matLine class="fa  fa-usd fa-1x " style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Transactions</span>
          </a>
        </mat-list-item>


        <mat-list-item *ngIf="!esoponlycomp && userPerm.Shareholders">
          <a matLine [routerLink]="['/landing','dashboard','shareholders']" routerLinkActive="active">
            <!-- <mat-icon>
            <i matListIcon class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
          </mat-icon> -->
            <span matLine class="fa fa-users fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Shareholders</span>

          </a>
        </mat-list-item>

        <mat-list-item *ngIf="!esoponlycomp && userPerm.individualShareholder">
          <a matLine [routerLink]="['/landing','dashboard','shareholder', getShareholderId()]" routerLinkActive="active">

            <span matLine class="fa fa-users fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Shareholder</span>

          </a>
        </mat-list-item>



        <mat-list-item  *ngIf="userPerm.Options">
          <a matLine [routerLink]="['/landing','dashboard','options']" routerLinkActive="active">
            <span matLine class="fa fa-list  fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Options</span>
          </a>
        </mat-list-item>

        <mat-list-item *ngIf="!esoponlycomp && userPerm.Modeling">
          <a matLine [routerLink]="['/landing','dashboard','modeling']" routerLinkActive="active">
            <!-- <mat-icon>
            <i matListIcon class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
          </mat-icon> -->
            <span matLine class="fa fa-area-chart  fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Modeling</span>
          </a>
        </mat-list-item>



        <mat-list-item *ngIf="!esoponlycomp && userPerm.FAQ">
          <a matLine routerLinkActive="active" [routerLink]="['/landing','dashboard','employees','faq']" routerLinkActive="active" >

            <span matLine class="fa fa-pencil-square-o fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">FAQ</span>
          </a>
        </mat-list-item>

        <mat-list-item *ngIf="!esoponlycomp && userPerm.Documents">
          <a matLine routerLinkActive="active" [routerLink]="['/landing','dashboard','documents']" routerLinkActive="active" >

            <span matLine class="fa fa-folder-open-o fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Documents</span>
          </a>
        </mat-list-item>

        <mat-list-item *ngIf="userPerm.Settings">
          <a matLine [routerLink]="['/landing','dashboard','settings']"  [class.active]="isSettingsActive()">
            <!-- <mat-icon>
            <i matListIcon class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
          </mat-icon>  -->
            <span matLine class="fa fa-cog fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Settings</span>
          </a>
        </mat-list-item>
        <mat-list-item *ngIf="userPerm.Settings">
          <a matLine routerLinkActive="active"  [routerLink]="['/landing','dashboard','usersRoles']">
            <span matLine class="fa fa-user-plus fa-1x" style="color: #E85936;" aria-hidden="true"></span>
            <span matLine class="style">Users & Roles</span>
          </a>
        </mat-list-item>

      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>

      <div class="main-body graybg">
        <button mat-icon-button *ngIf="shouldShowBackButton"  (click)="backClicked()" style="color:#77716F; margin-left: 11px; width: 60px;"> <mat-icon style="color:#CD4927;" class="fa fa-chevron-circle-left fa-lg"></mat-icon><u>Back</u> </button>

        <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>
      </div>
      <app-footer-component></app-footer-component>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>












<!-- <div class="container-fluid">

</div>

<div class="container-fluid">
  <div class="row no-margins">
    <div class="col-lg-1 col-md-1 col-sm-1  align-middle side-menu" style="height: 100%!important; background: white;">
      <mat-nav-list>
        <br/>
        <div class="container mb" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.capTable">
         <mat-list-item>
          <mat-icon  (click)="navigateTo('capTable'); currentTab = 'CapTable'" >
            <i class="fa fa-pencil-square-o fa-1x" aria-hidden="true" ></i><br/>
            <span class="cursor-hand " >Cap Table</span>
          </mat-icon>
        </mat-list-item>
        </div>
        <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.Transactions">
        <mat-list-item>
          <mat-icon  (click)="navigateTo('Transactions'); currentTab = 'Transactions'"   >
            <i class="fa  fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >Transactions</span>
          </mat-icon>
        </mat-list-item>
        </div>
        <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.Shareholders">
        <mat-list-item>
          <mat-icon  (click)="navigateTo('shareholders'); currentTab = 'Stockholders'" >
            <i cla ss="fa fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >Shareholders</span>
          </mat-icon>
        </mat-list-item>
        </div>
        <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.Shareholders">
        <mat-list-item >
          <mat-icon  (click)="navigateTo('securities'); currentTab = 'Security'"  >
            <i class="fa fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >Securities</span>
          </mat-icon>
        </mat-list-item>
      </div>
      <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.Options">
        <mat-list-item>
          <mat- icon  (click)="navigateTo('Options'); currentTab = 'Options'"  >
            <i class="fa fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >Options</span>
          </mat-icon>
        </mat-list-item>
      </div>
      <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.Options">
        <mat-list-item>
          <mat-icon  (click)="navigateTo('options'); currentTab = 'esopOverview'" style="margin-top: 10px" >
            <i class="fa fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >ESOP Overview</span>
          </mat-icon>
        </mat-list-item>
      </div>
      <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.Modeling">
        <mat-list-item >
          <mat-icon  (click)="navigateTo('Modeling'); currentTab = 'Modeling'"  >
            <i class="fa fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >Modeling</span>
          </mat-icon>
        </mat-list-item>
      </div>
      <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.Settings">
        <mat-list-item >
          <mat-icon  (click)="navigateTo('Settings'); currentTab = 'settings'"  >
            <i class="fa fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >Settings</span>
          </mat-icon>
        </mat-list-item>
      </div>
      <div class="container" style=" margin: 5px" *ngIf="!esoponlycomp && userPerm.FAQ">
        <mat-list-item >
          <mat-icon  (click)="navigateTo('KnowledgeBase'); currentTab = 'KnowledgeBase'"  >
            <i class="fa fa-pencil-square-o fa-2x " aria-hidden="true" ></i><br/>
            <span class="cursor-hand" >FAQ</span>
          </mat-icon>
        </mat-list-item>
      </div>

      </mat-nav-list>
  </div>
    <div class="col-lg-11 col-md-11 col-sm-11  content-pane" style="padding: 10px;">

    </div>
  </div>
</div> -->
