import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { AuthenticationService } from "../../authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs/internal/observable/of";
import { asyncScheduler } from "rxjs";
import { concatMap, repeat, delay, startWith } from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CacheProviderService } from "../../services/cache-provider.service";
import { PermissionsService } from "src/app/services/permissions.service";
import { UtilityService } from "src/app/services/utilities.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-landing-form",
  templateUrl: "./landing-form.component.html",
  styleUrls: ["./landing-form.component.scss"],
})
export class LandingFormComponent implements OnInit {
  public signupForm: FormGroup;
  public loginForm: FormGroup;
  googleSignInURL = environment.googleSignInURL;
  linkedInSignInURL = environment.linkedInSignInURL;
  googleSignUpURL = environment.googleSignUpURL;
  linkedInSignUpURL = environment.linkedInSignUpURL;
  xUserToken;
  public formType = "login";
  public termsAccepted = false;
  showPrivacyPolicy: boolean = false;
  showTermsOfService: boolean = false;
  src$;
  logHide: boolean = true;
  signHide: boolean = true;
  public barLabel: string = "Password strength:";
  passwordStrength = false;
  otp = { recieved: false, otp: "" };
  counter = 60;
  logpasswordHide() {
    this.logHide = !this.logHide;
  }

  signpasswordHide() {
    this.signHide = !this.signHide;
  }

  isTermsSelected(val) {
    this.termsAccepted = val;
  }

  showServiceTermsPopup() {
    this.showTermsOfService = true;
    document.getElementById("overlay").style.display = "block";
  }

  getEventservice(e) {
    this.showTermsOfService = e.showMdl;
    document.getElementById("overlay").style.display = "none";
  }

  showPrivacyPolicyPopup() {
    this.showPrivacyPolicy = true;
    document.getElementById("overlay").style.display = "block";
  }

  getEventpolicy(e) {
    this.showPrivacyPolicy = e.showMdl;
    document.getElementById("overlay").style.display = "none";
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private cacheService: CacheProviderService,
    private activeRoute: ActivatedRoute,
    private permService: PermissionsService,
    private readonly utilityService: UtilityService
  ) {
    this.activeRoute.queryParams.subscribe((values) => {
      if (values["type"] == "signup") {
        this.formType = "signup";
      }
    });
  }
  handleLoginResponse(res) {
      this.ngxLoader.stop();
      this.xUserToken = res.headers.get("x-user-token");
      localStorage.setItem("currentUser", this.xUserToken);
      // localStorage.setItem("emailId", res.body["emailId"]);
      localStorage.setItem("signintype", "normal");
      const accesstoken = res.headers.get('accesstoken')
      if(accesstoken){
        localStorage.setItem('x-access-token',accesstoken)
      }
      res.body["authorities"] &&
        res.body["authorities"][0] &&
        res.body["authorities"][0]["name"] &&
        localStorage.setItem("userRole", res.body["authorities"][0]["name"]);
      if (res && res.body["company"] && res.body["company"]["integration"]) {
        localStorage.setItem(
          "hrmsIntegrationStatus",
          res.body["company"]["integration"]["status"]
        );
      }
      if (res.body["isApplicationSetPassword"] == true) {
        this.router.navigate(["/resetPassword"]);
      } else if (this.xUserToken) {
        this.redirectToNext();
      }
  }
  login(loginForm: FormGroup) {
    if(this.otp.recieved) return
    localStorage.clear();
    this.ngxLoader.start();
    let password = loginForm.value.password;
    password = this.utilityService.encryptPassword(password);
    this.authService.login(loginForm.value.emailId, password).subscribe(
      (res) => {
        // Login calls return Empty response at times with status code as 200
        if (!res) {
          this.toastr.error(
            "Unable to login due to some technical issues.",
            "Failure"
          );
          return;
        }
        if (res) {
          if (res['body']['reason'] === "OTP Sent") {
            this.loginForm.addControl(
              "otp",
              new FormControl("", Validators.required)
            );
            this.ngxLoader.stop();
            this.toastr.info(
              "Please enter the one time password sent to your registered Email ID"
            );
            this.otp.recieved = true;
            this.otp.otp = "";
            this.startCountdown(60);
            return;
          }
          this.ngxLoader.stop();
          this.handleLoginResponse(res);
        }
      },
      (e) => {
        if (e["error"]["resultCode"] == "ACCOUNT_NOT_EXISTS") {
          this.toastr.error(e.error.reason, "Error");
          this.formType = "signup";
        } else {
          this.toastr.error(e.error.reason, "Error");
        }
        this.ngxLoader.stop();
      }
    );
  }
  verifyOtp() {
    const verificationObject = {
        "otp" : this.loginForm.value['otp'],
        "emailId" : this.loginForm.value['emailId'],
        "password" : this.utilityService.encryptPassword(this.loginForm.value['password'])
    }
    this.authService.verifyOtp(verificationObject).subscribe(res=>{
      if(!res){
        this.toastr.error(
          "Unable to login due to some technical issues.",
          "Failure"
        );
        return;
      }
      else if(res){
        this.handleLoginResponse(res);
        return
      }
    },(e)=>this.toastr.error(e.error))
  }
  resendOtp() {
    if (this.counter != 0) {
      return;
    }
    this.startCountdown(60);
    this.authService
      .resendOtp({"emailId" : this.loginForm.value['emailId']})
      .subscribe(
        (res) => {
          if (!res) {
            this.toastr.error(
              "Unable to login due to some technical issues.",
              "Failure"
            );
            return;
          }
          if (res['body']['reason'] === "OTP Sent") {
            this.ngxLoader.stop();
            this.toastr.info("OTP has been resent")
            return
            return;
          }
        },
        (e) => {
          if(e.status == 307){
            this.toastr.info("OTP has been resent")
            return
          }
          this.toastr.error(e.error.reason, "Error");
        }
      );
  }
  startCountdown(seconds) {
    this.counter = seconds;

    const interval = setInterval(() => {
      this.counter--;

      if (this.counter <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
  redirectToNext() {
    this.authService.getUserDetails().subscribe(
      (res) => {
        this.cacheService.set("userDetails", JSON.stringify(res));
        if (res["company"] != null && res["incorporationDetails"] != null) {
          localStorage.setItem("companyId", res["company"]["id"]);
          this.permService.navigateBasedOnPermission();
        } else if (res["company"] && res["company"]["isEsopOnlyCompany"]) {
          this.permService.navigateBasedOnPermission();
          // this.router.navigate([
          //   "/landing",
          //   "dashboard",
          //   "options",
          //   "overviewEsop",
          // ]);
        } else {
          // onboarding navigation
          if (
            res["companiesAssociatedToUser"].length > 0 &&
            res["isAllowedAccess"] == null
          ) {
            this.router.navigate(["landing/onboarding"], {
              queryParams: {
                company:
                  res["companiesAssociatedToUser"][0]["nameOfTheCompany"],
              },
            });
          } else if (
            res["authenticatorType"] != null &&
            res["isVerified"] == false
          ) {
            this.router.navigate(["/landing", "onboarding", "adminProfile"]);
          } else if (
            res["authenticatorType"] != null &&
            res["isVerified"] == true &&
            res["company"] == null
          ) {
            this.router.navigate(["/landing", "onboarding", "companyDetails"]);
          } else if (
            res["company"] != null &&
            res["incorporationDetails"] == null
          ) {
            this.router.navigate([
              "/landing",
              "onboarding",
              "create-historical-round",
            ]);
          } else {
            this.router.navigate(["landing/onboarding"]);
          }
        }
      },
      (e) => {
        localStorage.clear();
        this.router.navigate([""]);
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  checkPasswordStrength() {
    this.passwordStrength =
      this.utilityService.checkStrength(this.signupForm.value.password) > 30;
  }
  signup(signupForm) {
    if (signupForm.valid) {
      this.ngxLoader.start();
      localStorage.clear();
      let password = signupForm.value["password"];
      signupForm.value["password"] =
        this.utilityService.encryptPassword(password);
      this.authService.signUp(signupForm.value).subscribe(
        (res) => {
          this.ngxLoader.stop();
          this.xUserToken = res.headers.get("x-user-token");
          localStorage.setItem("currentUser", this.xUserToken);
          localStorage.setItem("signintype", "normal");
          this.redirectToNext();
        },
        (e) => {
          if (e["error"]["resultCode"] == "USER_ALREADY_EXISTS") {
            this.termsAccepted = false;
            this.toastr.error(e.error.reason, "Error");
            this.formType = "login";
          } else {
            this.toastr.error(e.error.reason, "Error");
          }
          this.ngxLoader.stop();
        }
      );
    }
  }

  ngOnInit() {
    this.ngxLoader.stop()
    this.loginForm = this.formBuilder.group({
      emailId: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-z0-9A-Z._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,7}$"),
        ],
      ],
      password: ["", Validators.required],
    });
    this.signupForm = this.formBuilder.group({
      fullName: ["", Validators.required],
      emailId: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,7}$"),
        ],
      ],
      password: ["", Validators.required],
    });
    this.src$ = of(
      "../../../assets/equion2.png",
      "../../../assets/equion3.png"
    ).pipe(
      concatMap((url) => of(url).pipe(delay(5000))),
      startWith("../../../assets/equion3.png", asyncScheduler),
      repeat()
    );
  }

  toggleForm(formType) {
    this.termsAccepted = false;
    this.formType = formType;
  }
}
