<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">Beneficiaries</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container">
    <div *ngIf="shareDistinctiveNumberDto" class="row top-20">
      <div class="col-md-12">
        <p class="content-pg-1">Available share distinctive numbers are <mat-label>{{ shareDistinctiveNumberDto.shareStartNumber }} - {{ shareDistinctiveNumberDto.shareEndNumber }}</mat-label></p>
        <p class="content-pg-1">Total number of shares available: <mat-label [ngbTooltip]="tooltipFormat(shareDistinctiveNumberDto.totalNumberOfShares)" placement="right">{{ getTotalValueTble(shareDistinctiveNumberDto.totalNumberOfShares) }}</mat-label></p>
      </div>
    </div>
    <div class="row btm-20 top-10" style="height: 50vh; overflow-y: auto;">
      <div class="col-md-12">
        <dx-data-grid #beneficiariesGrid keyExpr="id" class="gridContainer" [focusedRowEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" 
        [dataSource]="beneficiaryList" [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false"
        [allowColumnReordering]="false" [allowColumnResizing]="false" noDataText="Click on the '+' to add a Beneficiary" 
        [autoNavigateToFocusedRow]="true"
        (onInitialized)="logEvent('Initialized', $event)"
        (onRowInserted)="logEvent('RowInserted', $event)"
        (onRowInserting)="logEvent('RowInserting', $event)"
        (onSaving)="onSave($event)"
        >
          <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="true" [allowDeleting]="true"
            [allowAdding]="true" [useIcons]="true">
          </dxo-editing>
    
          <dxo-header-filter [visible]="false" [allowSearch]="false"> </dxo-header-filter>
    
          <dxi-column [allowSearch]="true" dataField="beneficiaryName" caption="Name" alignment="left">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup [dataSource]="namesList" displayExpr="name" valueExpr="name">
            </dxo-lookup>
          </dxi-column>
    
          <dxi-column [allowSearch]="true" [customizeText]="dxCustomizeCsv" dataField="numberOfShares" dataType="number" caption="No. of Shares" >          
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>
    
          <dxi-column [allowSearch]="true" dataField="shareNumString" [setCellValue]="setShareDistNum" dataType="string" caption="Share Distinctive Numbers" >          
            <!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
          </dxi-column>
    
          <dxi-column [allowEditing]="false" dataField="holdingPercentage" caption="Holding %" cellTemplate="holdingTemplate"></dxi-column>
          <div *dxTemplate="let cellData of 'holdingTemplate'">
            {{ cellData.value | percent:'2.2' }}
          </div>
    
          <dxi-column dataField="dateOfBeneficiaryAddition" caption="Date of Adding Beneficiary" dataType="date" editCellTemplate="dateCell" cellTemplate="displayDateCell">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>
          <div *dxTemplate="let cellData of 'dateCell'">
            <div class="grid-date">
              <input matInput [matDatepicker]="vd3" [ngModel]="cellData.value" (dateChange)="datePickerChanged(cellData, $event)" class="form-control top-10" title="" max="9999-12-31" placeholder="DD-MMM-YYYY" required />
              <mat-datepicker-toggle matSuffix [for]="vd3">
                <mat-icon matDatepickerToggleIcon ></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #vd3></mat-datepicker>
            </div>
          </div>
          <div *dxTemplate="let cellData of 'displayDateCell'">
            <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
          </div>
    
        </dx-data-grid>
      </div>
    </div>
    <div class="row btm-20">
      <div class="col-md-12">
        <button mat-raised-button [disableRipple]="disableripple" color="success" class="float-right"
            (click)='saveBeneficiaries()' ngbTooltip="Save">Save</button>
          <button mat-raised-button id="cancel" [disableRipple]="disableripple" color="warn" class="float-right margin-right-20"
            (click)="close()" ngbTooltip="Cancel">Cancel</button>
      </div>
    </div>
  </div>
</div>