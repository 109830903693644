import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable()
export class DataService {
    private dataObs$ = new Subject();
    private basicdataObs$ = new Subject();
    private applicationData = {}

    getData(): Observable<any> {
        return this.dataObs$;
    }

    updateData(data: any) {
        this.dataObs$.next(data);
    }

    //Onboarding
    getOnboarding(): Observable<any> {
        return this.dataObs$;
    }

    updateOnboarding(data: any) {
        this.dataObs$.next(data);
    }

    //Round Creation
    getBasicData(): Observable<any> {
        return this.basicdataObs$;
    }
    updateBasicData(data: any) {
        this.basicdataObs$.next(data);
    }

    setApplicationData(key:string, data:any){
        this.applicationData[key] = data
        console.log(this.applicationData)
    }

    getApplicationData(key:string){
        return this.applicationData[key]
    }

}