import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';

@Directive({
  selector: '[appShowIf]'
})
export class ShowIfDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permService: PermissionsService
  ) { }

  @Input()
  set appShowIf(perm:Array<string>) {
    let userPermissions = this.permService.getUserPermissions();
    let permissionSet = this.permService.getAllPermissions();
    const fullAccess = permissionSet['full-access'];
    const adminViewer = permissionSet['admin-without-proforma'];
    const isStrict = perm.includes("!strict");
    for(let i=0; i<perm.length; i++){
      const permissionRequired = permissionSet[perm[i]];
      if(userPermissions.includes(permissionRequired) || ((userPermissions.includes(fullAccess) || userPermissions.includes(adminViewer)) && !isStrict)){
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }
    }
    this.viewContainer.clear();
  }

}