import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoundsRoutingModule } from './rounds-routing.module';
import { CapTableTabComponent } from './cap-table-tab/cap-table-tab.component';
import { CreateNewRoundComponent } from './create-new-round/create-new-round.component';
import { CurrentInvestorsTabComponent } from './current-investors-tab/current-investors-tab.component';
import { InstrumentTabComponent } from './instrument-tab/instrument-tab.component';
import { NewInvestorsTabComponent } from './new-investors-tab/new-investors-tab.component';
import { RoundDetailsComponent } from './round-details/round-details.component';
import { RoundTabComponent } from './round-tab/round-tab.component';
import { RoundsOverviewComponent } from './rounds-overview/rounds-overview.component';
import { SummaryComponent } from './summary-tab/summary-tab.component';
import { CalcValuationPopupComponent } from './calc-valuation-popup/calc-valuation-popup.component';
import { DebentureInvPopupComponent } from './debenture-inv-popup/debenture-inv-popup.component';
import { NewSecurityClassComponent } from './new-security-class/new-security-class.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule, MatTabsModule, MatInputModule, MatSlideToggleModule, MatSliderModule, MatCardModule, MatButtonModule, MatButtonToggleModule, MatStepperModule, MatMenuModule, MatDividerModule, MatExpansionModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatIconModule } from '@angular/material';
import { MomentDateModule, MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DxChartModule, DxDataGridModule, DxTreeListModule, DxButtonModule, DxTemplateModule, DxTooltipModule, DxDropDownBoxModule, DxPieChartModule } from 'devextreme-angular';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CommonDialogsModule } from '../dialogues/common-dialogs.module';
import { DirectivesModule } from '../custom-directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    RoundsOverviewComponent,
    RoundDetailsComponent,
    CreateNewRoundComponent,
    RoundTabComponent,
    CurrentInvestorsTabComponent,
    NewInvestorsTabComponent,
    InstrumentTabComponent,
    CapTableTabComponent,
    SummaryComponent,
    CalcValuationPopupComponent,
    DebentureInvPopupComponent,
    NewSecurityClassComponent
  ],
  imports: [
    CommonModule,
    RoundsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DxChartModule,
    DxPieChartModule,
    DxDataGridModule,
    DxTreeListModule,
    DxButtonModule,
    DxTemplateModule,
    DxTooltipModule,
    DxDropDownBoxModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    NgbModule,
    MatIconModule,
    MatStepperModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatCheckboxModule,
    NgxUiLoaderModule,
    MatDatepickerModule,
    CommonDialogsModule,
    DirectivesModule,
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RoundsModule { }
