import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utilities.service';
import { CacheProviderService } from 'src/app/services/cache-provider.service';

@Component({
  selector: 'app-esop-plan-popup',
  templateUrl: './esop-plan-popup.component.html',
  styleUrls: ['./esop-plan-popup.component.scss']
})
export class EsopPlanPopupComponent implements OnInit {

  @Input() showDialog: boolean;
  @Input() isPlan: boolean;
  @Input() selectedSecurity: any;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<boolean>();

  esopList;
  equityList;
  public userDetails;
  public usrCompanyCurrency;
  selectedInstClsData;
  isEquity;
  isOthers;

  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService) { }

  ngOnInit() {
    this.utilityService.showOverlay()
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    } else {
      this.usrCompanyCurrency = "INR - ₹";
    }
   
    if (this.isPlan == true) {
      this.authService.getEsopPlan(this.selectedSecurity.className).subscribe((res) => {
        if (res) {
          this.esopList = res;
          console.log("esop", this.esopList);
        } else {
          this.toastr.error("Null response", "Failure!");
        }
      }, (e) => {
        this.toastr.error(e.error.reason, "Failure!");
      });
    }
    else if(this.isPlan == false){
      this.isEquity = true;
      this.equityList = this.selectedSecurity;
    }
    else{
      this.isOthers = true;
      this.selectedInstClsData = this.selectedSecurity;
    }
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      return 0;
    }
  }


  close() {
    this.showDialog = false;
    this.utilityService.hideOverlay();
    document.getElementById("overlay").style.display = 'none';
    this.visibleChange.emit({ showDialog: false });
  }

}
