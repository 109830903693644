<div class="row" (mouseleave)="hideHelp()">
  <div class="col-md-9 col-sm-12 col-xs-12">
    <form class="needs-validation" novalidate>
      <mat-card id="Incorporation_Details_Card" class="incorporation-details-card btm-20"
        (mouseover)="showHelp('Incorporation_Details_Card')">
        <mat-card-header>
          <mat-card-title>Incorporation Details</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the details of the company’s authorized capital and the par value of stock at which the shares have been issued for subscription. Also,
                select the currency of the company from the drop down. In the table below, provide the names and the number of shares subscribed by the first shareholders of the company.
                The tool will accordingly create the first cap table of the company.
              </p>
            </div>
          </div>

          <div class="row top-20">
            <div class="col-lg-5 col-md-6">
              <mat-label class="required">Authorized Capital</mat-label>
              <input type="text" class="form-control top-10 text-align-right" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="model.authorizedShareCapital" #authorizedShareCapital="ngModel" placeholder="Authorized Capital"
                (keydown)=onKeydownEvent($event) (ngModelChange)="calculateHoldingPercentage()" title="" 
                (input)="authCapitalCSV($event)" required>
              <div class="alert alert-danger" *ngIf="authorizedShareCapital.errors">
                <span class="alert alert-danger"
                  *ngIf="(authorizedShareCapital.dirty || authorizedShareCapital.touched) && !authCapitalValid">
                  Authorized Capital is required!
                </span>
                <span class="alert alert-danger" *ngIf="authorizedShareCapital.errors?.required && authCapitalValid">
                  Authorized Capital is required!
                </span>
              </div>
            </div>
            <div class="col-lg-5 col-md-6">
              <mat-label class="required">Par Value</mat-label>
              <input type="text" class="form-control top-10 text-align-right" [(ngModel)]="model.parValue"
                #parValue="ngModel" (keydown)=onKeydownEvent($event) (ngModelChange)="calculateHoldingPercentage()"
                title="" placeholder="Par Value" [ngModelOptions]="{standalone: true}" (input)="parCSV($event)" required>
              <div class="alert alert-danger" *ngIf="parValue.errors">
                <span class="alert alert-danger" *ngIf="(parValue.dirty || parValue.touched) && !parValueValid">
                  Par Value is required!
                </span>
                <span class="alert alert-danger" *ngIf="parValue.errors?.required && parValueValid">
                  Par Value is required!
                </span>
              </div>
            </div>
          </div>

          <div class="row top-20">
            <div class="col-lg-5 col-md-6">
              <mat-label class="required">Company Currency</mat-label>
              <select class="form-control top-10" [(ngModel)]="model.companyCurrency" #companyCurrency="ngModel"
              [ngModelOptions]="{standalone: true}" (change)="updateCurrencyFormat();" required>
                <option value="null" selected disabled>Select Currency</option>
                <option *ngFor="let currency of currencyList; let i = index" value="{{ currency }}">
                  {{ currency }}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="companyCurrency.errors">
                <span class="alert alert-danger"
                  *ngIf="(companyCurrency.dirty || companyCurrency.touched) && !companyCurrencyValid">
                  Company Currency is required!
                </span>
                <span class="alert alert-danger" *ngIf="companyCurrency.errors?.required && companyCurrencyValid">
                  Company Currency is required!
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="row top-20">
              <input type="checkbox" class="cursor-hand" [disabled]="model.shareholders.length==0" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="areAllShareholdersSelected" (change)="selectAllShareholders()" ngbTooltip="Select All"/>
              <img class="delete-icon cursor-hand" src="../../assets/trash-bin.svg" (click)="deleteAllSelected()" ngbTooltip="Delete All"/>
            </div>
            <div class="row top-20">
              <table class="table-common">
                <thead>
                  <tr>
                    <th width="8%"> </th>
                    <th width="25%" class="text-align-left">First Shareholders</th>
                    <th width="20%" class="text-align-right">Number of Shares</th>
                    <th width="20%" class="text-align-right">Total Investment</th>
                    <th width="13%" class="text-align-right">Holding( % )</th>
                    <th width="14%"><img src="../../assets/purple-plus-round.svg" class="cursor-hand" (click)="addShareholder()" ngbTooltip="Add Shareholder"/>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let shareholder of model.shareholders; let i = index">
                    <td>
                      <mat-checkbox class="cursor-hand" [disableRipple]="true" [(ngModel)]="shareholder.isSelected" [ngModelOptions]="{standalone: true}"
                        class="checkbox" (change)="selectShareholder()" ngbTooltip="Select"></mat-checkbox>
                    </td>
                    <td class="text-align-left">
                      <input type="text" class="form-control table-field-width" [(ngModel)]="shareholder.name" #nameErr="ngModel"
                        [ngClass]="(currentEditableRow == i && sName) ? 'is-invalid' : 'is-valid' " title="" [ngModelOptions]="{standalone: true}"
                        [readonly]="currentEditableRow != i" placeholder="Shareholder Name" required>
                        <div class="alert alert-danger text-align-left span-block" *ngIf="nameErr.errors">
                          <span *ngIf="nameErr.errors?.required && sName">
                              Shareolder Name is required!
                          </span>
                        </div>
                    </td>
                    <td class="text-align-right float-right">
                      <input type="text" class="form-control table-field-width text-align-right"
                        (keypress)="onlyNumberKey($event)" (keydown)=onKeydownEvent($event)
                        [(ngModel)]="shareholder.numberOfShares" (ngModelChange)="calculateHoldingPercentage(i)"
                        title="" [ngClass]="(currentEditableRow == i && nShare) ? 'is-invalid' : 'is-valid' " #noshareErr="ngModel"
                        [readonly]="currentEditableRow != i" (input)="onChangeShare(i,shareholder.numberOfShares)"
                        [ngModelOptions]="{standalone: true}" required>
                        <div class="alert alert-danger text-align-left span-block" *ngIf="noshareErr.errors || nShare">
                          <span *ngIf="noshareErr.errors?.required && nShare">
                            Number of Shares required!
                          </span>
                        </div>
                    </td>
                    <td class="text-align-right" container="body"
                      ngbTooltip="{{formatCurrency(shareholder.totalInvestment)}}">
                      {{ getTotalValueTble(shareholder.totalInvestment)}}
                    </td>
                    <td class="text-align-right" container="body">
                      {{shareholder.holdingPercentage|number:'1.2-2'}}%
                    </td>
                    <td class="actions-column" container="body">
                      <img *ngIf="currentEditableRow != i" class="edit-icon cursor-hand" src="../../assets/edit.svg"
                        (click)="editRow(i)" ngbTooltip="Edit"/>
                      <img *ngIf="currentEditableRow == i" class="edit-icon cursor-hand" src="../../assets/save.svg"
                        (click)="saveRow(i)" ngbTooltip="Save"/>
                      <img class="bin-icon cursor-hand" src="../../assets/trash-bin.svg"
                        (click)="deleteIndividualSelected(i)" ngbTooltip="Delete"/>
                    </td>
                  </tr>
                  <tr *ngIf="model.shareholders.length==0">
                    <td colspan="5" class="text-align-center">
                      <p class="cursor-hand">Click on the '+' to add Shareholder</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card id="Incorporation_Documents_Card" (mouseover)="showHelp('Incorporation_Documents_Card')">
        <mat-card-header>
          <mat-card-title>Incorporation Documents</mat-card-title>
          <button mat-raised-button id="upload" color="success" class="upload-doc-btn" [disableRipple]="true"
          (click)="uploadDialog()" ngbTooltip="Upload Incorporation Documents">Upload</button>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Upload the common documents of the company like the certificate of incorporation, memorandum of association, articles of association and other such documents relating to the incorporation of the company. Once the documents are uploaded, click on ‘Complete Registration’ to finish registering your company on hissa.
              </p>
            </div>
          </div>

          <div class="col-md-12">
            <div class="row top-20">
              <input type="checkbox" class="cursor-hand" [disabled]="incorporationDocs?.length==0" [(ngModel)]="selectAll" 
              [ngModelOptions]="{standalone: true}" (change)="selectAllDocuments($event)" ngbTooltip="Select All"/>
              <img class="delete-icon cursor-hand" src="../../assets/trash-bin.svg" (click)="deleteSelectedDocuments()" ngbTooltip="Delete All"/>
            </div>
            <div class="row top-20">
              <table class="table-common">
                <thead>
                  <tr>
                    <th width="7%"> </th>
                    <th width="20%" class="text-align-left">File Name</th>
                    <th width="25%" class="text-align-left">Description</th>
                    <th width="20%" class="text-align-left">Date Uploaded</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let fileDetails of incorporationDocs; let i = index">
                    <td>
                      <mat-checkbox class="cursor-hand" [disableRipple]="true" [(ngModel)]="fileDetails.isSelected" [ngModelOptions]="{standalone: true}"
                        class="checkbox" (change)="selectDocument($event, fileDetails.id)" ngbTooltip="Select"></mat-checkbox>
                    </td>
                    <td class="text-align-left">
                      {{fileDetails.fileName}}
                    </td>
                    <td class="text-align-left">
                      {{fileDetails.description}}
                    </td>
                    <td class="text-align-left">
                      {{fileDetails.dateUploaded | date: 'dd-MMM-yyyy'}}
                    </td>
                  </tr>
                  <tr *ngIf="incorporationDocs == null || incorporationDocs?.length == 0">
                    <td colspan="4">
                      No files
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="float-right top-20 btm-20">
        <button mat-raised-button id="completeRegistration" color="success" [disableRipple]="true"
          [disabled]="authorizedShareCapital.invalid || companyCurrency.invalid" class=""
          (click)="proceedToDashboard()" ngbTooltip="Complete Registration">Complete Registration</button>
      </div>
    </form>
  </div>

  <!--HELPER SECTION-->
  <div class="col-md-3">
    <div class="helper-container">
      <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
        [hidden]="helper == ''">
        <mat-card-header class="help-hdr">
          <mat-card-title><label class="help-hdr-txt">{{
              helpSections?.name
            }}</label></mat-card-title>
        </mat-card-header>
        <hr />
        <mat-card-content class="card-help-content">
          <div [innerHTML]="helpSections?.description"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<app-upload-documents *ngIf="openUploadDialog"
[openDialog]="openUploadDialog"
(visibleChange)="uploadIncorporationDocuments()">
</app-upload-documents>

<div id="overlay"></div>
