import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from "../../authentication.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utilities.service';
declare var $: any;

@Component({
  selector: 'app-resignation-popup',
  templateUrl: './resignation-popup.component.html',
  styleUrls: ['./resignation-popup.component.scss']
})
export class ResignationPopupComponent implements OnInit {
    @Input() showDialog: boolean;
    @Input() employmentStatus;
    @Input() employeeId;
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    resignationDetails;
    forfietedOptions = 0;
    emailValid;
    maxLength: number = 9;
    showNotes;
    public countryList:[];
    public selectedCountry = "India";

  constructor(
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private readonly utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.resignationDetails = {};
    this.utilityService.showOverlay()
    const { getCode, getNames } = require("country-list");
    this.countryList = getNames();
    this.authService.getResignationDetails(this.employeeId, this.employmentStatus).subscribe(
        (res) => {
            this.resignationDetails = res;
            this.formatData();
            this.loadJSstylesPrice();
        },
        (e) => {}
    );
  }

  onChangeResidentSelect(event){
    this.resignationDetails.updatedResidentStatus = event;
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit();
  }

  formatData() {
    if(this.resignationDetails.lastDay) {
        let dt = new Date(this.resignationDetails.lastDay);
        this.resignationDetails.lastDay = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
    }
    this.forfietedOptions = this .resignationDetails.remainingToExercise;
    this.resignationDetails.isExerciseDateExtended = false;
  }

  loadJSstylesPrice() {
    $('[data-toggle="switch"]').bootstrapSwitch({
        onText: "Yes",
        offText: "No",
    });
    $('.alert-status-price').bootstrapSwitch('state', this.resignationDetails.isExerciseDateExtended);
    $("#extendExercise").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        this.resignationDetails.isExerciseDateExtended = state;
        console.log('on change switch:  ', this.resignationDetails.isExerciseDateExtended);
    })
  }

  checkEmail(data) {
    if (document.getElementById("emailValid") == null) {
      this.emailValid = false;
    }
    else {
      this.emailValid = true;
    }
  }

  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 101) ? null : ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46);
  }

  maxNumber(val){
    if (val.length > this.maxLength) {
      this.resignationDetails.updatedContactNumber = val.slice(0, this.maxLength)
    }
  }

  onChangeSharesToExercise(value) {
      this.forfietedOptions = this.resignationDetails.remainingToExercise - value;
  }

  onChangeLastDay(lastDay) {
    this.ngxLoader.start();
    let dt = new Date(lastDay);
    lastDay = dt.toISOString().replace("Z", "+0000");
      let updateDetailsObj = {
          "id": this.employeeId,
          "lastDay": lastDay,
          "employmentStatus": this.employmentStatus
      }
      this.authService.getUpdatedResignationDetails(updateDetailsObj).subscribe(
          (res) => {
            console.log('resignation popup details: ', res);
            this.resignationDetails = res;
            this.formatData();
            this.loadJSstylesPrice();
            this.ngxLoader.stop();
          },
          (e) => {}
      );
  }

  addNotes(val) {
    if (val) {
      this.showNotes = val;
    } else {
      this.showNotes = val;
    }
  }

  saveResignationDetails() {
      if(!this.resignationDetails.updatedEmailId ||
            !this.resignationDetails.updatedContactNumber ||
            !this.resignationDetails.updatedResidentStatus || 
            !this.resignationDetails.nationality ||
            !this.resignationDetails.lastDay) {
            this.toastr.error('Please fill all the mandatory fields', 'Error');
            return;
        }
        if(this.resignationDetails.lastDateToExercise
          && this.resignationDetails.isExerciseDateExtended == false 
          && !this.resignationDetails.numberOfSharesToExercise && this.resignationDetails.numberOfSharesToExercise !=0) {
            this.toastr.error('Please fill the options to exercise', 'Error');
            return;
        }

        this.ngxLoader.start();
        const employementStatusBefore = this.resignationDetails.employementStatus;
        this.resignationDetails.employmentStatus = this.employmentStatus;
        let dt = new Date(this.resignationDetails.lastDay);
        this.resignationDetails.lastDay = dt.toISOString().replace("Z", "+0000");
        this.authService.saveResignationDetails(this.resignationDetails).subscribe(
            (res) => {
                this.close();
            },
            (e) => {
                this.toastr.error(e.error.reason, 'Error');
                this.ngxLoader.stop();
                this.resignationDetails.employmentStatus = employementStatusBefore;  
                this.resignationDetails.lastDay = null;
                return;
            }
        );
  }
}