<div class="footer-fixed">
    <div class="container-fluid" >
        <div class="row footer-bg">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3" >
                <!-- <img style="padding-top: 0.5rem" alt="Brand" src="../../assets/logo/logo.svg" />  -->
            </div>
            <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6" >
                <nav class="navbar-expand-sm">
                    <div class="d-flex justify-content-center"  >                            
                            <nav class="navbar navbar-expand-sm">
                                <ul class="navbar-nav">
                                    <li class="nav-item footer-sub-nav">
                                        <a class="nav-link"><span class="cursor-hand" (click)="showServiceTermsPopup()">Terms and Conditions</span></a>
                                    </li>
                                    <li class="nav-item footer-sub-nav">
                                        <a class="nav-link"><span class="cursor-hand" (click)="showPrivacyPolicyPopup()">Privacy Policy</span></a>
                                    </li>
                                </ul>
                            </nav>
                    <!-- <p>Terms and Conditions</p>
                    <p> Privacy Policy</p> 
                             <ul class="navbar-nav">
                        <li class="nav-item footer-main-nav">
                            <a class="nav-link" target="blank" href="https://www.rulezero.com/">Company &nbsp; |</a>
                        </li>
                        <li class="nav-item footer-main-nav">
                            <a class="nav-link" target="blank" href="https://www.rulezero.com/#contact">Contact Us &nbsp; |</a>
                        </li> 
                             <li class="nav-item footer-main-nav">
                            <a class="nav-link">About Us &nbsp; |</a>
                        </li> 
                             <li class="nav-item footer-main-nav">
                            <a class="nav-link" target="blank" href="https://www.rulezero.com/#careers">Careers</a>
                        </li>
                    </ul> 
                             </p> -->
                    </div>
                 </nav> 
                <div class="d-flex justify-content-center">
                   
                    
                    <p class="footer-main-content" >
                        
                        Hissa is the future of Equity Management Software &nbsp;&nbsp;  <span  style="color: gray;"> ● Powered by <a   target="blank" href="https://www.rulezero.com/">RuleZero </a> </span>
                      
                          
                        
                        <!-- Managed securely on a single software platform, we digitize and automate transactions, maintain ownership records and compliances. -->
                    </p>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3">
                <nav class="navbar-expand-sm">
                    <!-- <ul class="navbar-nav custom-nav">
                        <li class="nav-item" ngbTooltip="Contact Us">
                            <a class="nav-link"><img src="../../assets/call.svg" /></a>
                        </li>
                        <li class="nav-item" ngbTooltip="Email">
                            <a class="nav-link"><img src="../../assets/message.svg" /></a>
                        </li>
                        <li class="nav-item" ngbTooltip="Linkedin">
                            <a class="nav-link"><img src="../../assets/linkedin.svg" /></a>
                        </li>
                        <li class="nav-item" ngbTooltip="Twitter">
                            <a class="nav-link"><img src="../../assets/twitter.svg" /></a>
                        </li>
                        <li class="nav-item" ngbTooltip="Facebook">
                            <a class="nav-link"><img src="../../assets/facebook.svg" /></a>
                        </li>
                    </ul> -->
                </nav>
            </div>
        </div>
        <!-- <div class="row footer-policy">
            <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7">
                <nav class="navbar navbar-expand-sm">
                    <ul class="navbar-nav">
                        <li class="nav-item footer-sub-nav">
                            <a class="nav-link"><span class="cursor-hand" (click)="showServiceTermsPopup()">Terms and Conditions</span></a>
                        </li>
                        <li class="nav-item footer-sub-nav">
                            <a class="nav-link"><span class="cursor-hand" (click)="showPrivacyPolicyPopup()">Privacy Policy</span></a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-5">
                <nav class="navbar navbar-expand-sm" style="justify-content: flex-end;">
                    <ul class="navbar-nav custom-nav">
                        <li class="nav-item pull-right footer-sub-nav">
                            <a class="nav-link">© 2020 Rulezero Technology Solutions Private Limited</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div> -->
    </div>
</div>
<div id="overlayfooter" ></div>
<!-- Popups -->
<app-privacy-policy (visibleChange)="hidePopup()" [(showDialog)]="showPrivacyPolicy"></app-privacy-policy>
<app-service-terms (visibleChange)="hidePopup()" [(showDialog)]="showTermsOfService"></app-service-terms>
