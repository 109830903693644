<div *ngIf="showDialog" class="dialog">
  <div class="header">
   <div class="row">
     <div class="col-xs-9 col-sm-9 col-md-10 custom-modal-header">
       <label class="dialogue-title">{{plan.esopPlanName}}</label>
     </div>
     <div class="col-xs-6 col-sm-6 col-md-2">
       <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
         <span class="close">X</span>
       </button>
     </div>
   </div>
 </div>
 <mat-divider class="divider-line"></mat-divider>
   <div style="height:90%;">
     <div class="section-padding">
       <div class="row btm-20 ">
           <div class="col-md-12 " >
             <div >
               <div class=" grant-letter-container"> 
                 <iframe #document_frame class="document-frame" [src]="grantLetterFile" *ngIf="isOtherFile"></iframe>
                 <ejs-documenteditorcontainer *ngIf="isDocxFile" [(serviceUrl)]="service" #documenteditor_default [enableToolbar]="false" [isReadOnly]= "true" [enablePrint]="false" (created)="onCreate()" width="100%"  height="100%" [showPropertiesPane]="false" [zoomFactor]=1.25 style="display:block; overflow: hidden;"></ejs-documenteditorcontainer>
               </div>
           </div>
         </div>
     </div>
     </div>
 </div>
</div>