import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services/helper.service';
import { UtilityService } from '../services/utilities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../src/app/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
declare var bootbox: any;

@Component({
  selector: 'app-share-capital',
  templateUrl: './share-capital.component.html',
  styleUrls: ['./share-capital.component.scss']
})
export class ShareCapitalComponent implements OnInit {

  public showDialogauthorized: Boolean = false;
  public showDialogsplitstock: Boolean = false;
  public roundName = "";
  public helpTitle: any;
  public helpMessage: any;
  public isShowHelp: boolean = false;
  public disableripple: boolean = true;
  public equityAuthShareCapital: any;
  public preferenceAuthShareCapital: any;
  public equityPaidupShareCapital: any;
  public preferencePaidupShareCapital: any;
  public usrCompanyCurrency: any;

  public isauthCapital: boolean = false;
  public issplitStock: boolean = false;

  public equityAuth: boolean = false;
  public prefAuth: boolean = false;
  public equityPaidup:boolean = false;
  public prefPaidup:boolean = false;
  helper: string = "";
  helpSections;
  helperTopPosition = null;

  constructor(private utility: UtilityService,
    private authService: AuthenticationService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private router: Router) { }

  splitStock() {
    this.issplitStock = true;
    this.showDialogsplitstock = true;
    document.getElementById("overlay").style.display = "block";
    console.log(this.showDialogsplitstock);
  }
  
  getEvent(e) {
    this.showDialogsplitstock = e;
    document.getElementById("overlay").style.display = "none";
  }

  getEventAutorized(e) {
    this.showDialogauthorized = e;
    console.log(this.showDialogauthorized);
    document.getElementById("overlay").style.display = "none";
  }

  authCapital() {
    this.isauthCapital = true;   
    this.showDialogauthorized = true;
    document.getElementById("overlay").style.display = "block";
  }

  ngOnInit() {
    this.loadJsStyles();
    // let user = JSON.parse(sessionStorage.getItem('userDetails'));
    // this.equityAuthShareCapital = user['company'].authorizedShareCapital;
    // this.preferenceAuthorizedShareCapital = user['company'].preferenceAuthorizedShareCapital;
    this.getCapital();
  }

  getCapital(){
    this.ngxLoader.start();
    this.authService.getCapital().subscribe(
    (res) => {
      if (res) {
        this.usrCompanyCurrency = res['company']['companyCurrency'];
        this.equityAuthShareCapital = this.utility.formatCurrency(this.usrCompanyCurrency, res['equityAuthorizedShareCapital']);
        this.preferenceAuthShareCapital = this.utility.formatCurrency(this.usrCompanyCurrency, res['preferenceAuthorizedShareCapital']);
        this.equityPaidupShareCapital = this.utility.formatCurrency(this.usrCompanyCurrency, res['equityPaidUpShareCapital']);
        this.preferencePaidupShareCapital = this.utility.formatCurrency(this.usrCompanyCurrency, res['preferencePaidUpShareCapital']); 
        this.ngxLoader.stop();
      } else {
        this.ngxLoader.stop();
        this.toastr.error("Null response!.", "Failure!");
      }
    },
    (e) => {
      this.ngxLoader.stop();
      this.toastr.error("Error: " + e.error.reason, "Error");
    });
  }

  /** Show Contextual help **/
  showHelp(section) {
    if(section == this.helper){
        return;
    }
    const targetElement = document.getElementById(section);
    if(targetElement != null){
      this.helperTopPosition = targetElement.offsetTop;
    }
    
      this.helpSections = this.helperService.getHelpContent(
        "ShareCapitalComponent",
        section
      );
    this.helper = section;
  }

  hideHelp() {
    this.helper = "";
  }
  /** Show Contextual help **/

  onKeydownEvent(e) {
    this.utility.restrictMinus(e);
  }

  loadJsStyles() {
    $('input.number').keyup(function (event) {
      // skip for arrow keys
      if (event.which >= 37 && event.which <= 40) return;
      // format number
      $(this).val(function (index, value) {
        let tmpValue = value.toString().replace(/\D/g, "");
        var lastThree = tmpValue.substring(tmpValue.length - 3);
        var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res;
      });
    });
  }

  validation(data){
    this.utility.formValidation();
    if ((data.equityAuthorizedShareCapital < 0 || data.equityAuthorizedShareCapital == null || data.equityAuthorizedShareCapital === "") 
        || (data.preferenceAuthorizedShareCapital < 0 || data.preferenceAuthorizedShareCapital == null || data.preferenceAuthorizedShareCapital === "")
        || (data.equityPaidUpShareCapital < 0 || data.equityPaidUpShareCapital == null || data.equityPaidUpShareCapital === "") 
        || (data.preferencePaidUpShareCapital < 0 || data.preferencePaidUpShareCapital == null || data.preferencePaidUpShareCapital === "") ){
      if (data.equityAuthorizedShareCapital < 0 || data.equityAuthorizedShareCapital == null || data.equityAuthorizedShareCapital === "") {
        this.equityAuth = true;
      }
      if (data.preferenceAuthorizedShareCapital < 0 || data.preferenceAuthorizedShareCapital == null || data.preferenceAuthorizedShareCapital === "") {
        this.prefAuth = true;
      }
      if(data.equityPaidUpShareCapital < 0 || data.equityPaidUpShareCapital == null || data.equityPaidUpShareCapital === ""){
        this.equityPaidup = true;
      }
      if(data.preferencePaidUpShareCapital < 0 || data.preferencePaidUpShareCapital == null || data.preferencePaidUpShareCapital === ""){
        this.prefPaidup = true;
      }
      return false;
    }
    else {
      return true;
    }
  }

  save() {
    let dt = new Date();
    const date = dt.toISOString().replace("Z", "+0000");
    let capital = {
      "equityAuthorizedShareCapital": this.equityAuthShareCapital,
      "preferenceAuthorizedShareCapital" : this.preferenceAuthShareCapital,
      "equityPaidUpShareCapital": this.equityPaidupShareCapital,
      "preferencePaidUpShareCapital": this.preferencePaidupShareCapital,
      "updatedDate" : date
    }
    let isValid = this.validation(capital);
    if(isValid){
      this.ngxLoader.start();
      if(capital.equityAuthorizedShareCapital != 0){
        capital.equityAuthorizedShareCapital = capital.equityAuthorizedShareCapital.replace(/,/g, "")/1;
      }
      if(capital.preferenceAuthorizedShareCapital != 0){
        capital.preferenceAuthorizedShareCapital = capital.preferenceAuthorizedShareCapital.replace(/,/g, "")/1;
      }
      if(capital.equityPaidUpShareCapital != 0){
        capital.equityPaidUpShareCapital = capital.equityPaidUpShareCapital.replace(/,/g, "")/1;
      }
      if(capital.preferencePaidUpShareCapital != 0){
        capital.preferencePaidUpShareCapital = capital.preferencePaidUpShareCapital.replace(/,/g, "")/1;
      }
      this.authService.updateCapital(capital).subscribe(
        (res) => {
          if (res) {
            this.toastr.success("Capital saved successfully","Success");
            this.router.navigate(['/landing', 'dashboard','capTable']);
            this.ngxLoader.stop();
          } else {
            this.ngxLoader.stop();
            this.toastr.error("Null response!.", "Failure!");
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e.error.reason, "Error");
      });
    }
  }

  csvEquityVal(e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
    let val = this.equityAuthShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if(e) {
      if(e.target.value) {
          let CSVLength = exitVal.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = exitVal;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
            e.target.value = exitVal;
            if(start != 0 || end != 0){
                let difference = valueLength - CSVLength;
                start = start - difference;
                end = end - difference;
            }
            else{
                start = start;
                end = end;
            }
          }
          else{
            e.target.value = exitVal;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  csvPreferenceVal(e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
    let val = this.preferenceAuthShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if(e) {
      if(e.target.value) {
          let CSVLength = exitVal.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = exitVal;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
            e.target.value = exitVal;
            if(start != 0 || end != 0){
                let difference = valueLength - CSVLength;
                start = start - difference;
                end = end - difference;
            }
            else{
                start = start;
                end = end;
            }
          }
          else{
            e.target.value = exitVal;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  csvPaidUpEquityVal(e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
    let val = this.equityPaidupShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if(e) {
      if(e.target.value) {
          let CSVLength = exitVal.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = exitVal;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
            e.target.value = exitVal;
            if(start != 0 || end != 0){
                let difference = valueLength - CSVLength;
                start = start - difference;
                end = end - difference;
            }
            else{
                start = start;
                end = end;
            }
          }
          else{
            e.target.value = exitVal;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  csvPaidUpPrefVal(e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
    let val = this.preferencePaidupShareCapital;
    exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if(e) {
      if(e.target.value) {
          let CSVLength = exitVal.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = exitVal;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
            e.target.value = exitVal;
            if(start != 0 || end != 0){
                let difference = valueLength - CSVLength;
                start = start - difference;
                end = end - difference;
            }
            else{
                start = start;
                end = end;
            }
          }
          else{
            e.target.value = exitVal;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  cancel(){
    this.router.navigate(['/landing', 'dashboard','capTable']);
  }


}
