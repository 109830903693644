import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-create-round-document",
  templateUrl: "./create-round-document.component.html",
  styleUrls: ["./create-round-document.component.scss"]
})
export class CreateRoundDocumentComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
  // Navigate to Document Section
  goToDocument() {
    this.router.navigate(["/landing", "dashboard", "document"]);
  }

  ngOnInit() {}

  filterData(e) {}
}
