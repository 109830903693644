<app-navbar></app-navbar>
<div class="loader-container" *ngIf="utilityService.showMatLoader">
  <p>File Uploading ... </p>
  <mat-progress-bar  class="progress-bar" mode="indeterminate"></mat-progress-bar>
</div>

<div class="main-body graybg">
    <router-outlet></router-outlet>
  </div>
  <app-footer-component></app-footer-component>
<!-- <router-outlet></router-outlet>
<app-footer-component></app-footer-component> -->