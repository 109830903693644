import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/app/authentication.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { DxDataGridComponent, DxPieChartComponent, DxTreeListComponent } from "devextreme-angular";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from '../../services/cache-provider.service';
import { UtilityService } from '../../services/utilities.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from "src/app/services/permissions.service";
import { Proforma } from "src/app/services/proforma.service";
import { ColumnList } from "src/app/dialogues/dev-ex-cap-table/columns-list";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { DevExCapTableComponent } from "src/app/dialogues/dev-ex-cap-table/dev-ex-cap-table.component";
import { NavbarService } from "src/app/services/navbar-reload";
import { CustomLocaleFormatterPipe } from "src/app/pipes/locale-formatter.pipe";

@Component({
  selector: "app-cap-table",
  templateUrl: "./cap-table.component.html",
  styleUrls: ["./cap-table.component.scss"],
  providers:[CustomLocaleFormatterPipe]
})

export class CapTableComponent implements OnInit {
  @ViewChild(DxPieChartComponent) chart: DxPieChartComponent;
  @ViewChild('devExCapTable') dataGrid: DevExCapTableComponent;
  completeCapTableData = [];
  capTableData = [];
  incorporationDate;
  dashboardDetails;
  public includeProforma: boolean = true;
  holdersList = null;
  displayCols: ColumnList;
  cardData;
  instrumentChart;
  completeInstrumentArray;
  instrumentArray;
  roundChart;
  completeRndChrt;
  allShareholdersData;
  selectedGrpNme;
  public shouldShowDialog: boolean;
  isPlan: boolean = false;
  selectedSecurity: any;
  expanded = false;
  expandAll = false;
  isOpen = false;
  public userDetails;
  public usrCompanyCurrency = "INR - ₹";
  onBoardingStatus;
  navigationSubscription;
  termSheet = null;
  searchOptions: Observable<string[]>;
  totalSearchOptions = [
    "For_Approval",
    "Approved",
    "Created",
    "Offered",
    "Granted",
    "Rejected",
  ];
  searchFormControl = new FormControl();
  grantDateFilter: {
    fromDate: moment.Moment | string;
    toDate: moment.Moment | string;
  } = { fromDate: undefined, toDate: undefined };
  dashNotes;
  subsidiaryCompanies ;
  iconMap = {
    'Capital': '../../../assets/dash1.svg',
    'Valuation': '../../../assets/dash2.svg',
    'Share Price': '../../../assets/dash3.svg',
    'Securities': '../../../assets/dash4.svg',
    'Related Company Details': '../../../assets/dash6.svg',
    'Stakeholders': '../../../assets/dash5.svg'
  };

  constructor(
    private authService: AuthenticationService,
    private router: Router, private ngxLoader: NgxUiLoaderService,
    private cacheService: CacheProviderService,
    private utilityService: UtilityService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private proforma: Proforma,
    private permService: PermissionsService,
    private customLocaleFormatter: CustomLocaleFormatterPipe
  ) {
    this.navigationSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  customizeTooltip(arg) {
    return {
      text: arg.argumentText + " : " + (arg.value * 100).toFixed(2) + "%",
      fontColor: "#ffffff",
      color: "#000000"
    };
  }
  searchEntered(e) {
    this.dataGrid.treeList.instance.searchByText(e);
  }
  customizeText(arg) {
    if (arg.value * 100 > 3) {
      return arg.percentText;
    }
  }
  expandColumns() {
    this.dataGrid.expandAllItem()
  }
  customizeRoundTextCC(arg) {
    if (arg.value > 10000000) {
      let invAmt =
        Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e7
          ? Math.trunc(
            Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e7
          ) + "Cr"
          : // Six Zeroes for Millions
          Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e5
            ? Math.trunc(
              Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e5
            ) + "L"
            : // Three Zeroes for Thousands
            Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e3
              ? Math.trunc(
                Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e3
              ) + "K"
              : Math.trunc(Math.abs(Number(arg.point.data["roundInvestmentAmount"])));
      return invAmt;
    }
  }

  customizeRoundTextRC(arg) {
    if (arg.value > 10000000) {
      let invAmt =
        Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e+9

          ? (Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e+9).toFixed(2) + "B"
          // Six Zeroes for Millions
          : Math.abs(Number(arg.point.data["roundInvestmentAmount"])) > 1.0e+6

            ? (Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e+6).toFixed(2) + "M"

            : Math.abs(Number(arg.point.data["roundInvestmentAmount"])) > 1.0e+3

              ? (Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e+3).toFixed(2) + "K"

              : Math.trunc(Math.abs(Number(arg.point.data["roundInvestmentAmount"])));
      return invAmt;
    }
  }

  customizeInsText(arg) {
    if (arg.value * 100 > 3) {
      return arg.percentText;
    }
  }

  customizeRoundTooltip = (arg) => {
    return {
      text: arg.argumentText + " : " + this.getAmount(this.usrCompanyCurrency, arg.value),
      fontColor: "#ffffff",
      color: "#000000"
    };
  }

  getAmount(usrCompanyCurrency, amt) {
    let val = this.utilityService.formatCurrency(usrCompanyCurrency, amt);
    return val;
  }

  customizeInsTooltip(arg) {
    return {
      text: arg.argumentText + " : " + (arg.value * 100).toFixed(2) + "%",
      fontColor: "#ffffff",
      color: "#000000"
    };
  }

  customizeLegendText(items) {
    items.forEach(element => {
      if (element['text'].length > 10) {
        element['text'] = element['text'].substring(0, 10) + '...';
      }
    });
    return items;
  }

  stockholderDone() {
    let stockholderTotal = 0;
    if (this.dashboardDetails) {
      this.dashboardDetails.forEach(group => {
        stockholderTotal = stockholderTotal + group["netWorthOfType"];
      });
      let stockholder = this.customLocaleFormatter.transform(stockholderTotal, "number");
      return stockholder;
    }
  }

  roundDone() {
    let roundTotal = 0;
    if (this.roundChart) {
      this.roundChart.forEach(element => {
        roundTotal = roundTotal + element["roundInvestmentAmount"];
      });
      let rounds = this.customLocaleFormatter.transform(roundTotal, "number");
      return rounds;
    }
  }

  numberFormatter(rawNumber: string | number, args = 2) {
    var exp,
      rounded,
      suffixes = ["K", "M", "G", "T", "P", "E"];
    if (typeof rawNumber == "number") {
      return rawNumber;
    }
    let convertedNumber;
    let format = "";
    if (rawNumber.endsWith("Cr")) {
      convertedNumber = rawNumber.replace("Cr", "");
      convertedNumber = parseFloat(convertedNumber);
      format = "Cr";
    } else {
      format = rawNumber[rawNumber.length - 1];
      convertedNumber = rawNumber.replace(format, "");
      convertedNumber = parseFloat(convertedNumber);
    }
    if (convertedNumber < 1000) {
      return rawNumber;
    }
    if (Number.isNaN(convertedNumber)) {
      return null;
    }

    exp = Math.floor(Math.log(convertedNumber) / Math.log(1000));

    convertedNumber =
      (convertedNumber / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    return convertedNumber.toString() + " " + format;
  }

  includeProformaCall(checked) {
    this.includeProforma = checked;
    this.proforma.setData(this.includeProforma);
    this.ngxLoader.start();
    this.capTableData = undefined;
    this.roundChart = undefined;
    this.instrumentArray = undefined;
    this.dataCalls(checked);
    this.ngxLoader.stop();
  }

  dataCalls(proforma) {
    this.authService.getCaptableCards(proforma).subscribe(
      res => {
        // if(!res){
        //   this.toastr.error('Temporary error occurred, please try again later','error')
        //   return
        // }
        this.cardData = res;

        // var result = Object.keys(res).map((key) =>res[key]);
        // const result = JSON.parse(JSON.stringify(res))
        //{cardName: 'Capital', firstSectionName: 'Authorised', firstSectionValue: 988009696, secondSectionName: 'Issued', secondSectionValue: 15732}
        // const capitalCard= result[0]
        // const fundingCard= result[1]
        // const sharesCard= result[2]
        // const securityCard= result[3]
        // const stakeholdersCard= result[4]
        // const capitalCardNew = {
        //   cardName: capitalCard.cardName,
        //   firstSectionName: fundingCard.firstSectionName,
        //   firstSectionValue: fundingCard.firstSectionValue,
        //   secondSectionName: capitalCard.secondSectionName,
        //   secondSectionValue: capitalCard.secondSectionValue
        // }
        // const fundingCardNew = {
        //   // cardName: fundingCard.cardName,
        //   cardName: "Valuation",
        //   firstSectionName: fundingCard.firstSectionName,
        //   firstSectionValue: fundingCard.firstSectionValue,
        //   secondSectionName: fundingCard.secondSectionName,
        //   secondSectionValue: fundingCard.secondSectionValue
        // }
        // const sharesCardNew = {
        //   cardName: sharesCard.cardName,
        //   firstSectionName: sharesCard.firstSectionName,
        //   firstSectionValue: sharesCard.firstSectionValue,
        //   secondSectionName: sharesCard.secondSectionName,
        //   secondSectionValue: sharesCard.secondSectionValue
        // }

        // const securityCardNew = {
        //   cardName: securityCard.cardName,
        //   firstSectionName: securityCard.firstSectionName,
        //   firstSectionValue: securityCard.firstSectionValue,
        //   secondSectionName: securityCard.secondSectionName,
        //   secondSectionValue: securityCard.secondSectionValue+securityCard.firstSectionValue
        // }

        // const stakeholdersCardNew = {
        //   cardName: stakeholdersCard.cardName,
        //   firstSectionName: stakeholdersCard.firstSectionName,
        //   firstSectionValue: stakeholdersCard.firstSectionValue,
        //   secondSectionName: stakeholdersCard.secondSectionName,
        //   secondSectionValue: stakeholdersCard.secondSectionValue
        // }
        // this.cardData=[capitalCardNew,fundingCardNew,sharesCardNew,securityCardNew,stakeholdersCardNew]

        this.ngxLoader.stop();
      },
      e => {
        if (e.error.reason === "Login Token Expired.") {
          this.router.navigate(['']);
          return;
        } else {
          this.toastr.error(e.error.reason, "Error");
        }

      }
    );

    this.authService.getDashboardNotes().subscribe(
      (res) => {
        this.dashNotes = res['roundNotes'];
      },
      (e) => {}
    );

    this.authService.getRoundChart(proforma).subscribe(
      res => {
        this.completeRndChrt = res;
        if (this.completeRndChrt.length > 6) {
          this.getChartViewData(this.completeRndChrt);
        } else {
          this.roundChart = res
        }
      },
      e => { this.toastr.error(e.error.reason, "Error"); }
    );

    this.authService.getInstrumentTypeChart(proforma).subscribe(
      res => {
        this.instrumentChart = res;
        this.formatInsData();
      },
      e => { this.toastr.error(e.error.reason, "Error"); }
    );

    this.authService.getDashboardOverview(proforma).subscribe(
      res => {
        if (res != null) {
          //   this.holdersList = res;
          this.allShareholdersData = res;
          this.authService.capTableData = res;
          this.dashboardDetails = this.authService.capTableData;
          this.formatCapTableData();
        } else {

        }
      },
      e => { this.toastr.error(e.error.reason, "Error"); }
    );
    this.authService.getDevExSource(proforma, null).subscribe(
      (res) => {
        this.holdersList = res;
      },
      (e) => { }
    );
    this.ngxLoader.stop();
  }

  getSubsidiaryCompanies(){
    this.authService.getSubsidiaryCompanies().subscribe(
      res => {
        this.subsidiaryCompanies = res;
      },
      e => { this.toastr.error(e.error.reason, "Error"); }
    );
  }

  // Combinig first five data with cumulative of remaining values - Round Chart
  getChartViewData(chartData) {
    let others = chartData.slice(5);
    let first5 = chartData.slice(0, 5);
    let total = 0;
    for (let i = 0; i < others.length; i++) {
      total += others[i].roundInvestmentAmount;
    }
    first5.push({
      roundName: "Others",
      roundInvestmentAmount: total,
      roundIdentifier: "OTHERS"
    });
    this.roundChart = first5;
  }

  // Combinig first five data with cumulative of remaining values - Securities chart
  getInstrumentChartViewData(chartData) {
    let others = chartData.slice(5);
    let first5 = chartData.slice(0, 5);
    let total = 0;
    for (let i = 0; i < others.length; i++) {
      total += others[i].value;
    }
    first5.push({ instrument: "Others", value: total });
    this.instrumentArray = first5;
  }

  // Combinig first five data with cumulative of remaining values - Stackholders chart
  getStackHoldersViewData(chartData) {
    let others = chartData.slice(5);
    let first5 = chartData.slice(0, 5);
    let total = 0;
    for (let i = 0; i < others.length; i++) {
      let value = others[i].val
      if (typeof (value) == "string") {
        value = parseFloat(value)
      }
      total += value
    }
    first5.push({ shareholder: "Others", val: total.toFixed(10) });
    this.capTableData = first5;
  }

  approveHistoricalRound() {
    this.ngxLoader.start();
    this.authService.historicalFinalApproval(this.userDetails['company']['id']).subscribe(
      (res) => {
        this.getUserDetails(false);
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  discardHistoricalRound() {
    this.ngxLoader.start();
    if (this.userDetails != null) {
      this.ngxLoader.start();
      this.authService.discardHistoricalCaptable(this.userDetails['company']['id']).subscribe(
        (response) => {
          if (response["resultCode"]) {
            this.router.navigate(['/landing','onboarding','historical-round-summary']);
          } else {
            this.ngxLoader.stop();
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
        );
    }
  }

  getUserDetails(isHistoricalRoundDiscarded: boolean) {
    this.authService.getUserDetails().subscribe(
      (res) => {
        this.cacheService.set("userDetails", JSON.stringify(res));
        this.navbarService.requireReload = true;
        this.navbarService.sendClickEvent();
        this.router.navigate(["/landing", "dashboard", "capTable"]).then(() => {window.location.reload()});
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  getTotalValueTble(value, cardName= "Share Price") {
    if (value > 0 && cardName!="Share Price") {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }

      else {

        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }

    }
    else {
      // console.log("s234567")
      if(value > 0 ){

        return value.toFixed(2);
       }
      return 0;

    }
  }

  formatCurrency(value) {
    return this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, value);
  }

  formatInsData() {
    this.completeInstrumentArray = [];
    if (this.instrumentChart != null && this.instrumentChart.length > 0) {
      this.instrumentChart.forEach(element => {
        let obj = {
          instrument: element.instrumentClassName,
          value: element.holdingPercentage.toFixed(10)
        };
        this.completeInstrumentArray.push(obj);
      });
    }

    if (this.completeInstrumentArray.length > 6) {
      this.getInstrumentChartViewData(this.completeInstrumentArray);
    } else {
      this.instrumentArray = this.completeInstrumentArray;
    }
  }

  formatCapTableData() {
    this.completeCapTableData = [];
    if (this.dashboardDetails != null && this.dashboardDetails.length > 0) {
      this.dashboardDetails.forEach(group => {
        let capTableObj = {
          shareholder: group["typeName"],
          val: group["holdingPercentageOfType"].toFixed(10)
        };
        this.completeCapTableData.push(capTableObj);
      });
    }

    if (this.completeCapTableData.length > 6) {
      this.getStackHoldersViewData(this.completeCapTableData);
    } else {
      this.capTableData = this.completeCapTableData;
    }
  }

  displayShareholders() {
    if (this.permService.authorizeUser(['captable-summary', '!strict'])) {
      this.toastr.error("You do not have the permission to access this page.", "Failure!");
    }
    else {
      this.router.navigate(["/landing", "dashboard", "shareholders", "overview"]);
    }
  }

  displayRoundOverview() {
    if (this.permService.authorizeUser(['captable-summary', '!strict'])) {
      this.toastr.error("You do not have the permission to access this page.", "Failure!");
    }
    else {
      this.router.navigate(["/landing", "dashboard", "rounds", "overview"]);
    }
  }

  displaySecurities() {
    if (this.permService.authorizeUser(['captable-summary', '!strict'])) {
      this.toastr.error("You do not have the permission to access this page.", "Failure!");
    }
    else {
      this.router.navigate(["/landing", "dashboard", "securities", "overview"]);
    }
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      768: {
        items: 5
      },
      1200: {
        items: 5
      }
    },
    nav: true
  }

  customsOption: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      1024: {
        items: 3
      },
      1200: {
        items: 3
      }
    },
    nav: true
  }

  goToTermSheets() {
    this.router.navigate(['/landing', 'dashboard', 'term-sheets', 'overview']);
  }

  ngOnInit() {
    this.ngxLoader.start();
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
      this.incorporationDate = this.userDetails['company']['dateOfIncorporation'];
      this.onBoardingStatus = this.userDetails['company']['onBoardingStatus'];
    }
    else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.searchOptions = this.searchFormControl.valueChanges.pipe(
      map((value) => this._filter(value))
    );
    this.displayCols = new ColumnList();
    this.displayCols = {
      shareholder: true,
      roundName: false,
      roundSize: false,
      closingDate: false,
      securityType: false,
      rounds: true,
      common: true,
      preference: true,
      debentures: true,
      warrants: true,
      fdbShares: true,
      holding: true,
      percentageOfGain: false,
      pricePerShare: false,
      netWorth: false,
      currentValue: false,
      noOfShares: false,
      parValue: false,
      capitalValue: false,
      amtInvested: false,
      preMoneyValuation: false,
      postMoneyValuation: false,
      preHolding: false,
      postHolding: false,
      totalInvestment: false,
      dilutionPercentage: false,
      leadInvestor: false,
      document: false,
      preSplitCommon: false,
      postSplitCommon: false,
      preSplitPreference: false,
      postSplitPreference: false,
      preSplitFdbShares: false,
      postSplitFdbShares: false,
      preRoundShares: false,
      postRoundShares: false,
      preHoldingRound: false,
      postHoldingRound: false,
      amtInvestedInRound: false,
      newAntidDilutionShares: false
    }
    if (this.permService.authorizeUser(['captable-summary', 'admin-without-proforma', '!strict'])) {
      this.includeProforma = false;
    }
    else {
      this.includeProforma = true;
    }
    this.proforma.setData(this.includeProforma);
    this.dataCalls(this.includeProforma);
    this.getSubsidiaryCompanies();
    this.ngxLoader.stop();
  }
  exportCaptableReport(reportType) {
    this.dataGrid.exportFile(reportType)
  }
  ngOnDestroy(): void {
    // Destroy navigationSubscription to avoid memory leaks
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return []
    if (this.searchFormControl.value.length < 1) return [];
    return this.totalSearchOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}