<div class="container-fluid graybg">
    <div class="whiteboxwrap">
    <div class="row btm-15" (mouseover)="scrollVisiblity(false)">
        <div class="col-md-6">
            <h5 class="page-hdr">Round Overview</h5>
        </div>
        <div *appHideIf="['admin-without-proforma']" class="col-md-6"> 
            <button mat-raised-button id="createNewRound" [disableRipple]="disableripple" [color]="isCreateRound === false ? 'success' : 'disabled' " class="pull-right margin-left-lg" (click)="navigate()" 
            ngbTooltip="Create New Round" [disabled]="isCreateRound" ><img src="../../assets/CreateRound.svg" alt="Create New Round"/>&nbsp;&nbsp;
                Create New Round
            </button>
            <span class="round-tooltip" *ngIf="isCreateRound"  placement="left" ngbTooltip="Please finish partial round" ></span>
            <button mat-raised-button [disableRipple]="true" color="success" class="pull-right" (click)="navigateToQuickRound()" 
            ngbTooltip="Create Quick Round" ><img src="../../../assets/CreateRound.svg" alt="Create Quick Round"/>&nbsp;&nbsp;
                Create Quick Round
            </button>
        </div>
        <div class="col-md-12">
            <p class="content-pg-1 top-20 btm-20">
                Here is a summary of the series of funding rounds the company has raised so far. 
                Click on the name of the round in the table to view the details of the round.
            </p>
        </div>
    </div>
    <div class="btm-20">
        <dx-chart id="chart" [dataSource]="stackChartData" (onPointClick)="redirectToRoundDetails($event)">
            <dxi-series *ngFor="let stack of countrySources" valueField="{{ stack.val }}" name="{{ stack.name }}"></dxi-series>
            <dxi-series axis="total" type="spline" valueField="amtRaised" name="Amount Raised" color="#008fd8">
            </dxi-series>
            <dxi-series axis="total" type="spline" valueField="postMoney" name="Post Money Valuation" color="#000000">
            </dxi-series>
            <dxo-common-series-settings argumentField="RoundName" type="fullStackedBar">
            </dxo-common-series-settings>
            <dxo-legend [visible]="false" verticalAlignment="top" horizontalAlignment="center" itemTextPosition="right" position="inside">
            </dxo-legend>
            <dxo-title text="Holdings">
                <dxo-font family="roboto"></dxo-font>
            </dxo-title>
            <dxo-export [enabled]="false"></dxo-export>
            <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" format="decimal" opacity="1" [border]="{
                opacity: '0',
                visible: false
            }" paddingLeftRight="6" paddingTopBottom="6" zIndex="1000">
            </dxo-tooltip>
        </dx-chart>
    </div>
    </div>
    <div class="whiteboxwrap">
    <div  (mouseover)="scrollVisiblity(false)">
        <label class="mat-card-title btm-10">Rounds</label>
    </div>
    
    <app-dev-ex-cap-table [dataSource]="overviewCaptable" [pageType]="'RoundOverview'" [displayCols]="columnList"></app-dev-ex-cap-table>
    </div>
</div>