import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plan-rejected',
  templateUrl: './plan-rejected.component.html',
  styleUrls: ['./plan-rejected.component.scss']
})
export class PlanRejectedComponent implements OnInit {
  note:string = '';
  constructor(public dialogRef: MatDialogRef<PlanRejectedComponent>,private toastr: ToastrService ) { }

  ngOnInit() {
  }
  onNoClick(){
    this.dialogRef.close()
  }

  checkValidation(){
    if(this.note=="" || typeof this.note==='undefined'){
      this.toastr.error("Please enter the reason for rejection ");
      return;
    }
    else{
      this.dialogRef.close(this.note)
    }
  }

}
