import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '../../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of  } from 'rxjs/internal/observable/of';
import { asyncScheduler } from 'rxjs';
import { concatMap, repeat, delay, startWith } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from '../../services/cache-provider.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employee-login',
  templateUrl: './employee-login.component.html',
  styleUrls: ['./employee-login.component.scss'],
})
export class EmployeeLoginComponent implements OnInit {
  @ViewChild('userId') userId:ElementRef
  @ViewChild('employeeLoginForm') employeeLoginForm:ElementRef
  
  src$;
  logHide: boolean = true;
  signHide: boolean = true;
  public barLabel: string = "Password strength:"; 
  public companyList:any;
  public emailId;
  public companyName = "";
  public password;
  public companyId;
  public emailErr:boolean = false;
  public loginValid:boolean = false;
  public passwordEr:boolean = false;
  public cidErr:boolean = false;
  xUserToken;
  eid;
  loginSalt = "$2a$04$MPTvwBs45LSKJ69h38aHFe" 
  logpasswordHide() {
    this.logHide = !this.logHide;
  }

  signpasswordHide() {
    this.signHide = !this.signHide;
  }

  constructor(private router: Router, private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private cacheService: CacheProviderService,
    private activeRoute: ActivatedRoute,
    private readonly utilityService: UtilityService
  ) { }

  getCompanies(id){
    let user = {
      "emailId":id  
    }
    this.authService.getEmployeeCompanies(user).subscribe(
      (res) => {
        this.companyList = res;
        if(this.companyList.length ==1){
          this.companyName=this.companyList[0];
          this.companyId = this.companyList[0].id;
        }
        else{
          this.companyName="";
        }
      },
      (e) => { 
        this.companyName="";
        this.companyList =[];
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }
  setInputFocus(): void {
    //console.log(this.elementRef.nativeElement.value);
  }
  emailFocused(event){
    console.log(this.userId);
  }

  selectCompany(e){
    this.companyId = e.id;
  }
  loginValidation(data, id){
    if(data.emailId === "" || data.emailId == null || 
      data.password === "" || data.password == null ||
      id === "" || id == null){
      if(data.emailId === "" || data.emailId == null){
        this.emailErr = true;
      }
      if(data.password === "" || data.password == null){
        this.passwordEr = true;
      }
      if(id === "" || id == null){
        this.cidErr = true;
      }
      this.loginValid = true;
    }
    else{
      this.loginValid = true;
    }
  }

  login() {
    
    this.ngxLoader.start();
    let userType = "EMPLOYEE"
    let userLogin = {
      "emailId": this.emailId,
      "password": this.password
    }
   
    this.loginValidation(userLogin, this.companyId);
    if(this.loginValid){
      userLogin.password = this.utilityService.encryptPassword(userLogin.password)
      this.authService.employeeLogin(userType, this.companyId, userLogin).subscribe(
        (res) => {
          // Login calls return Empty response at times with status code as 200
          if(!res){
            this.toastr.error("Unable to login due to some technical issues.","Failure")
            return
          }
          this.ngxLoader.stop();
          if(res.body["company"] != null && res.body["company"]["data"] != null && res.body["company"]["data"]["newPortalAccess"] == true){
            let token = res.headers.get('AccessToken').split(' ')[1];
            window.location.href = environment.empPortalUrl + "/login?setPassword=" + res.body["isApplicationSetPassword"] + "&token=" + token;
            return;
          }
          this.xUserToken = res.headers.get('x-user-token');
          let user = res.body['type'];
          let emailId = res.body["emailId"];
          this.eid = res.body['employeeId'];
          localStorage.setItem('currentUser', this.xUserToken);
          localStorage.setItem('emailId', res.body["emailId"]);
          localStorage.setItem('signintype', 'normal');
          localStorage.setItem('userType', user);
          localStorage.setItem('companyId', this.companyId);
          localStorage.setItem('empId', this.eid);
          this.utilityService.setValue('empId', this.eid)
          this.ngxLoader.stop();
          if (res.body['isApplicationSetPassword'] == true) {
            this.router.navigate(['/resetPassword', { type : user, email: emailId}]);
          }
          else {
            this.redirectToNext();
          }
        },
        (e) => {
          this.toastr.error(e.error.reason, "Error");
          this.ngxLoader.stop();
        }
      );
    }
  }

  redirectToNext() {
    this.authService.getUserDetails().subscribe(
        (res) => {
            this.cacheService.set("userDetails", JSON.stringify(res));
            this.router.navigate(["/landing", "dashboard", "employees", "employeeDetails", this.eid]);
        },
        (e) => {}
    );
  }

  forgetPassword(){
    this.router.navigate(['/forgotPassword', { isEmployee : true }]);
  }

  ngOnInit() {
    localStorage.clear();
    sessionStorage.clear();

    
    this.src$ = of('../../../assets/equion2.png','../../../assets/equion3.png').pipe(
        concatMap(url => of(url).pipe(delay(5000))),
        startWith('../../../assets/equion3.png' , asyncScheduler),
        repeat()
    ); 
  }
  checkValidation() {
 
     if(!this.companyId ){
    
            this.toastr.error('Please select the company', 'Error');
          return
          }
      if(!this.password){
        this.toastr.error('Please enter the password', 'Error');
          return
      }
          this.login()
  }

}
