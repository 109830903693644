import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule, MatCheckboxModule, MatFormFieldModule, MatButtonToggleModule, MatDividerModule, MatMenuModule, MatDatepickerModule, MatIconModule, MatSlideToggleModule, MatSliderModule, MatTabsModule, MatExpansionModule } from '@angular/material';
import { FileManagerRoutingModule } from './file-manager-routing.module';

import { FileManagerAllModule, DetailsViewService } from '@syncfusion/ej2-angular-filemanager';
import { MatButtonModule } from '@angular/material';

import { FileManagerComponent } from './../file-manager/file-manager.component';
import { TagsEditComponent } from './../file-manager/tags-edit/tags-edit.component';

@NgModule({
  declarations: [
    FileManagerComponent,
    TagsEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FileManagerRoutingModule,
    FileManagerAllModule,
    MatButtonModule,
    MatCardModule, MatCheckboxModule, MatFormFieldModule, MatButtonToggleModule, MatDividerModule, MatMenuModule, MatDatepickerModule, MatIconModule, MatSlideToggleModule, MatSliderModule, MatTabsModule, MatExpansionModule
  ],
  exports:[
    FileManagerComponent,
    TagsEditComponent
  ]
})
export class FileManagerModule { }
