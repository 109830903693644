<div class="row" (mouseleave)="hideHelp()">
  <div class="col-md-6 col-lg-8 col-xl-9">
    <mat-card id="Shareholders_Tab">
      <mat-card-header>
        <mat-card-title>Shareholders</mat-card-title>
      </mat-card-header>
      <mat-divider class="divider-line"></mat-divider>
      <mat-card-content>
        <form class="needs-validation" novalidate>
          <div (mouseover)="showHelp('Shareholders')">
            <div class="row">
              <div class="col-md-12">
                <p class="content-pg-1 top-20 btm-20">
                  The names of the shareholders who are holding the class of security being brought back are listed in the table. 
                  <br><br>
                  Under the ‘Willingness of Shareholder' column, enter the number of shares that the shareholder is willing to sell back to the company.
                </p>
              </div>
            </div> 
            <div class="row">
              <div class="col-md-6">
                <div class="top-10">
                  <mat-label>Type of Instrument: {{instrumentName}} ({{currencySymbol}} {{getTotalValueTble(pps)}} per share)</mat-label>
                </div>
              </div>
            </div>
            <div class="row">
              <dx-data-grid id="gridContainer" class="grant-table" [hoverStateEnabled]="true" [dataSource]="shareHolders" keyExpr="id" caption="Shareholders table"
               [showBorders]="true" [wordWrapEnabled]="true"  [width]="100+'%'" [showBorders]="true"
               [columnAutoWidth]="true" [allowColumnReordering]="false" noDataText="No Shareholders Data available"
               [rowAlternationEnabled]="true" [focusedColumnIndex]="4"
               [allowColumnResizing]="true" (onRowUpdated)="calculateFinalEligibility()">

                <dxo-scrolling [useNative]="true" showScrollbar="true"> </dxo-scrolling>
                <dxo-paging [enabled]="true" [pageSize]="15"></dxo-paging>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
                <!-- <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection> -->
                <dxi-column dataField="name" [allowEditing]="false" [allowFiltering]="true" caption="Shareholder" ></dxi-column>
                <dxi-column dataField="totalShares" [allowSorting]="true" [allowEditing]="false" [allowFiltering]="false" caption="Total Shares"></dxi-column>
                <dxi-column dataField="currentHoldingPercentageLabel" [allowEditing]="false" [allowFiltering]="false" caption="Current Holding Percentage"></dxi-column>
                <dxi-column dataField="proRataEligibility" caption="Eligibility(pro-rata)" [allowFiltering]="false" [allowEditing]="false" style="cursor: pointer;" ></dxi-column>
                <dxi-column dataField="willingness" [allowEditing]="false" cellTemplate="cellTemplate" caption="Willingness of shareholder"></dxi-column>
                <div *dxTemplate="let cell of 'cellTemplate'">
                  <div class="cursor-hand">{{ cell.data.willingness }}<mat-icon> <i (click)="editWillingness(cell.data)" class="fa fa-pencil" aria-hidden="true"></i>
                  </mat-icon>
                </div></div> 
                <!-- <dxi-column dataField="totalBoughtShares" caption="Final Eligibility" [allowEditing]="false" style="cursor: pointer;" ></dxi-column> -->
                <dxo-editing [allowUpdating] ="true" mode="cell" >
                </dxo-editing>
              </dx-data-grid>
              <!-- <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                <div *dxTemplate="let data of 'content'">
                  <div class="tooltip-content">{{toolTipText}}</div>
                </div>
              </dx-tooltip> -->
            </div>
            <div class="row">
              <div class="col-md-4 top-10">
                <div class="top-10">
                  <mat-label>Target:  {{getTotalValueTble(target)}} shares ({{currencySymbol}} {{getTotalValueTble(totalBuyBackAmount)}})</mat-label>
                </div>
              </div>
              <div class="col-md-3 top-10">
                <div class="top-10 float-right">
                  <mat-label>Actual: </mat-label>
                </div>
              </div>
              <div>
                <div class="top-10">
                  <input type="text" [(ngModel)]="actualTargetSharesText" [ngModelOptions]="{standalone: true}" placeholder="0" class="form-control inputs top-10" disabled />
                </div>
              </div>
              <div class="col-md-2 top-10">
                <div class="top-10">
                  <mat-label>{{getTotalValueTble(leftSharesText)}} left</mat-label>
                </div>
              </div>
            </div>
            <div class="row top-20">
              <div class="col-md-12">
                <button mat-raised-button id="saveAndContinue" [disableRipple]="disableripple" color="success"
                  class="pull-right " (click)="goToReview()" ngbTooltip="Save and Continue">Save and Continue
                </button>
                <button mat-raised-button id="previous" [disableRipple]="disableripple" color="success"
                  class="pull-right margin-right-20" (click)="goToBuyBack()"
                    ngbTooltip="Previous">Previous
                </button>
                <button mat-raised-button id="discard" [disableRipple]="disableripple" color="warn"
                  class=" pull-right margin-right-20" (click)="discardBuyBack()"
                  ngbTooltip="Discard Buy-Back">Discard
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="helper-container">
        <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
          <mat-card-header class="help-hdr">
            <mat-card-title><label class="help-hdr-txt">{{
               helpSections?.name
              }}</label></mat-card-title>
          </mat-card-header>
          <hr />
          <mat-card-content class="card-help-content">
            <div [innerHTML]="helpSections?.description"></div>
          </mat-card-content>
        </mat-card>
    </div>
  </div>
</div>

<div id="overlay"></div>

<app-edit-shares-willingness *ngIf="showDialog" [(showDialog)]="showDialog" [(shareDistinctiveSets)]="selectedShareDistinctiveSets" [(willingness)]="selectedWillingness" 
  [eligibility]="selectedEligibility" (visibleChange)="getWillingnessEdit($event)"></app-edit-shares-willingness>
