var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { AuthenticationService } from '../../../../src/app/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/new-round-notify.service';
import { CacheProviderService } from '../../services/cache-provider.service';
import { NavbarService } from 'src/app/services/navbar-reload';
var AdminProfileComponent = /** @class */ (function () {
    function AdminProfileComponent(helperService, authService, router, ngxLoader, toastr, activatedRoute, cacheService, navbarService, dataService) {
        this.helperService = helperService;
        this.authService = authService;
        this.router = router;
        this.ngxLoader = ngxLoader;
        this.toastr = toastr;
        this.activatedRoute = activatedRoute;
        this.cacheService = cacheService;
        this.navbarService = navbarService;
        this.dataService = dataService;
        this.profile = {
            designation: '',
            authenticatorType: ''
        };
        this.profilePicture = null;
        this.authenticator = null;
        this.croppedImage = null;
        this.shouldShowDialog = false;
        this.waitingForVerification = false;
        this.helper = "";
        this.helperTopPosition = null;
        this.isAccountConfirm = false;
        this.roles = [];
        this.isNonEditable = false;
        this.designationValid = false;
        this.authenticatorValid = false;
        this.authFileValid = false;
        this.isVerificationLinkExpired = false;
        var navFlag = localStorage.getItem('navigationFlag');
        if (!navFlag || navFlag != 'companyDetails') {
            this.redirectToNext();
        }
        localStorage.removeItem('navigationFlag');
    }
    AdminProfileComponent.prototype.ngOnInit = function () {
        if (this.activatedRoute.snapshot.paramMap.get('newComp') == 'true') {
            this.registerNewCompany = true;
        }
        this.fetchAdminProfileDetails();
    };
    AdminProfileComponent.prototype.redirectToNext = function () {
        var _this = this;
        this.authService.getUserDetails().subscribe(function (res) {
            // localStorage.setItem('companyId', res['company']['id']);
            if (res['authenticatorType'] != null && res['isVerified'] == true && res['company'] == null) {
                _this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
            }
        }, function (e) {
            _this.toastr.error(e.error.reason, "Error");
        });
    };
    AdminProfileComponent.prototype.fetchAdminProfileDetails = function () {
        var _this = this;
        this.ngxLoader.start();
        this.authService.getAdminProfile().subscribe(function (res) {
            if (res) {
                _this.roles = res.designationDropDownValues;
                _this.authenticatorList = res.authenticatorDropDownValues;
                if (res['isVerified'] == false) {
                    _this.waitingForVerification = true;
                    _this.isVerificationLinkExpired = res['isLinkExpired'];
                    localStorage.setItem('waitingForVerification', 'true');
                }
                _this.profile.designation = res.designation == null ? '' : res.designation;
                _this.profile.authenticatorType = res.authenticatorType == null ? '' : res.authenticatorType;
                var authenticatorFileId = res.authenticatorFileId;
                var profilePictureId = res.profilePictureId;
                if (res.designation != null || res.authenticatorType != null || res.authenticatorFileId != null || res.profilePictureId != null) {
                    _this.isNonEditable = true;
                }
                else {
                    _this.isNonEditable = false;
                }
                _this.ngxLoader.stop();
                if (profilePictureId != null) {
                    _this.authService.getImageFile(profilePictureId).subscribe(function (res) {
                        _this.createImageFromBlob(res);
                        _this.profilePicture = res;
                    }, function (e) {
                        _this.toastr.error(e.error.reason, "Error");
                    });
                }
                if (authenticatorFileId) {
                    _this.authService.getPdfFile(authenticatorFileId).subscribe(function (res) {
                        var header = res.headers.get('Content-Disposition');
                        var matches = /filename="(.*?)"/g.exec(header);
                        var filename = !!matches ? matches[1] : 'file.pdf';
                        _this.authenticator = res.body;
                        _this.authenticator.name = filename;
                    }, function (e) {
                        _this.toastr.error(e.error.reason, "Error");
                    });
                }
                _this.stepperInitialization();
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error("Null response!.", "Failure!");
            }
        }, function (e) {
            _this.ngxLoader.stop();
            _this.profile.designation = '';
            _this.profile.authenticatorType = '';
            _this.authenticator = null;
            _this.croppedImage = null;
            _this.profilePicture = null;
            if (e.error.reason === "Login Token Expired.") {
                _this.ngxLoader.stop();
                _this.cacheService.clear();
                _this.router.navigate(['']);
                return;
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error(e.error.reason, "Failure!");
            }
        });
    };
    AdminProfileComponent.prototype.goToDashboard = function () {
        var _this = this;
        this.authService.getUserDetails().subscribe(function (res) {
            _this.cacheService.set("userDetails", JSON.stringify(res));
            var userDetails = res;
            if (userDetails['company'] && userDetails['incorporationDetails']) {
                _this.navbarService.sendClickEvent();
                setTimeout(function () {
                    _this.router.navigate(['/landing', 'dashboard', 'capTable']);
                }, 300);
            }
            else if (userDetails['company'] && !userDetails['incorporationDetails']) {
                if (_this.registerNewCompany) {
                    _this.router.navigate(['/landing', 'onboarding', 'create-historical-round', { newComp: true }]);
                }
                else {
                    _this.router.navigate(['/landing', 'onboarding', 'create-historical-round']);
                }
            }
        }, function (e) {
            _this.toastr.error(e.error.reason, "Error");
        });
    };
    AdminProfileComponent.prototype.stepperInitialization = function () {
        var companyDetailsInfo = this.cacheService.get("CompanyDetails");
        var companyDetails = "";
        if (companyDetailsInfo != null) {
            companyDetails = "COMPLETED";
        }
        else {
            companyDetails = "INITIAL";
        }
        //For Stepper Initialization
        if (this.isNonEditable == true) {
            var errorStatus = void 0;
            if (this.waitingForVerification == true) {
                errorStatus = {
                    adminProfileTab: "INPROGRESS",
                    companyDetailsTab: companyDetails,
                    inCorporationTab: "INITIAL"
                };
            }
            else {
                errorStatus = {
                    adminProfileTab: "COMPLETED",
                    companyDetailsTab: companyDetails,
                    inCorporationTab: "INITIAL"
                };
            }
            this.errorFlags = errorStatus;
            this.dataService.updateOnboarding(this.errorFlags);
            this.cacheService.set("AdminProfile", JSON.stringify(this.profile));
        }
        else if (this.isNonEditable == false) {
            var errorStatus = {
                adminProfileTab: "INPROGRESS",
                companyDetailsTab: companyDetails,
                inCorporationTab: "INITIAL"
            };
            this.errorFlags = errorStatus;
            this.dataService.updateOnboarding(this.errorFlags);
        }
    };
    AdminProfileComponent.prototype.proceedToCompanyDetails = function () {
        if (this.areFieldsValid()) {
            this.saveAdminProfile();
        }
        else {
            this.toastr.error("Please fill all the fields", "Failed");
        }
    };
    AdminProfileComponent.prototype.gotoCompanyDetails = function () {
        var _this = this;
        var errorStatus = {
            adminProfileTab: "COMPLETED",
            companyDetailsTab: "INPROGRESS",
            inCorporationTab: "INITIAL"
        };
        this.errorFlags = errorStatus;
        this.dataService.updateOnboarding(this.errorFlags);
        // let waitingForVerification = localStorage.getItem('waitingForVerification');
        // if(waitingForVerification == 'true') {
        //     this.toastr.error('Please complete verification');
        // } else {
        //     this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
        // }
        this.authService.getAdminProfile().subscribe(function (res) {
            if (res['isVerified'] == 'false') {
                _this.toastr.error('Please complete verification');
            }
            else {
                if (_this.registerNewCompany) {
                    _this.router.navigate(['/landing', 'onboarding', 'companyDetails', { newComp: true }]);
                }
                else {
                    _this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
                }
            }
        }, function (e) {
            _this.toastr.error(e.error.reason, "Error");
        });
    };
    AdminProfileComponent.prototype.createImageFromBlob = function (image) {
        var _this = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            _this.croppedImage = reader.result;
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    };
    AdminProfileComponent.prototype.createFileFromBlob = function (file) {
        var file = new Blob([file], { type: 'application/pdf' });
        this.authenticator = URL.createObjectURL(file);
    };
    AdminProfileComponent.prototype.onImageSaved = function (imageDetails) {
        this.shouldShowDialog = false;
        this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
        this.croppedImage = imageDetails.croppedImage;
    };
    AdminProfileComponent.prototype.uploadAvatarImage = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.croppedImage = event.target.src;
                        _a = this;
                        return [4 /*yield*/, (fetch(this.croppedImage).then(function (res) { return res.blob(); }))];
                    case 1:
                        _a.profilePicture = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminProfileComponent.prototype.fileChangeEvent = function (fileInput) {
        // let file = fileInput.target.files[0];
        this.authenticator = fileInput.target['files'][0];
        //  let fileName = this.authenticator.name;
    };
    AdminProfileComponent.prototype.areFieldsValid = function () {
        if (this.profile.designation == "" || this.profile.authenticatorType == "" || this.authenticator == null) {
            if (!this.profile.designation) {
                this.designationValid = true;
            }
            if (!this.profile.authenticatorType) {
                this.authenticatorValid = true;
            }
            if (!this.authenticator) {
                this.authFileValid = true;
            }
            return false;
        }
        else {
            return true;
        }
    };
    AdminProfileComponent.prototype.getEvent = function () {
        this.shouldShowDialog = false;
        document.getElementById("overlay").style.display = "none";
    };
    AdminProfileComponent.prototype.saveAdminProfile = function () {
        var _this = this;
        var formData = new FormData();
        if (this.profilePicture != null) {
            formData.append('profilePicture', (new File([this.profilePicture], 'profile.jpeg', { type: 'image/jpeg' })));
        }
        formData.append('authenticator', this.authenticator);
        formData.append('designation', this.profile.designation);
        formData.append('authenticatorType', this.profile.authenticatorType);
        // new Response(formData).text().then(console.log)
        this.ngxLoader.start();
        this.authService.saveAdminProfile(formData).subscribe(function (res) {
            _this.ngxLoader.stop();
            var email = res['emailAddress'];
            _this.toastr.success('Verification E-mail sent to the admin', 'Success', {
                timeOut: 10000,
            });
            _this.isAccountConfirm = true;
            localStorage.setItem('waitingForVerification', 'true');
            _this.cacheService.set("AdminProfile", JSON.stringify(_this.profile));
            //stepper initialization
            var errorStatus = {
                adminProfileTab: "INPROGRESS",
                companyDetailsTab: "INITIAL",
                inCorporationTab: "INITIAL"
            };
            _this.errorFlags = errorStatus;
            _this.dataService.updateOnboarding(_this.errorFlags);
        }, function (e) {
            _this.ngxLoader.stop();
            _this.toastr.error(e.error.reason, "Error");
        });
    };
    AdminProfileComponent.prototype.resendVerMail = function () {
        var _this = this;
        this.authService.resendVerificationMail().subscribe(function (res) {
            var email = res['emailAddress'];
            _this.toastr.success('Verification E-mail sent to the admin', 'Success', {
                timeOut: 10000,
            });
        }, function () {
            _this.toastr.error('Please try again', 'Error');
        });
    };
    AdminProfileComponent.prototype.openUploadPicturePopup = function () {
        this.shouldShowDialog = true;
    };
    AdminProfileComponent.prototype.dataURItoBlob = function (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI) {
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
        }
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    };
    AdminProfileComponent.prototype.onAuthenticatorTypeChange = function () {
        this.authenticator = null;
    };
    AdminProfileComponent.prototype.showHelp = function (section) {
        if (section == this.helper) {
            return;
        }
        var targetElement;
        targetElement = document.getElementById(section);
        if (targetElement != null) {
            this.helperTopPosition = targetElement.offsetTop;
        }
        this.helpSections = this.helperService.getHelpContent("AdminProfileComponent", section);
        this.helper = section;
    };
    AdminProfileComponent.prototype.hideHelp = function () {
        this.helper = "";
    };
    AdminProfileComponent.prototype.changeAuthType = function () {
        this.authenticator = null;
    };
    AdminProfileComponent.prototype.changeCroppedImage = function () {
        this.openUploadPicturePopup();
    };
    return AdminProfileComponent;
}());
export { AdminProfileComponent };
