<div class="container-fluid graybg">
    <div id="overlay"></div>
    <div class="whiteboxwrap">
    <div class="row">
        <div class="col-md-6">
            <h5 class="page-hdr btm-15">Shareholders</h5>
        </div>
        <div class="col-md-6">
            <button *appShowIf="['full-access', '!strict']" mat-raised-button color="success" ngbTooltip="Send Share Certificate to Email" [disableRipple]="true" class="float-right margin-left-lg"
                (click)="sendShareCertificate()">Send Share Certificate
            </button>
        <!-- </div> -->
        <!-- <div class="generate_credentials_btn col-md-3"> -->
            <button *appShowIf="['full-access', '!strict']" mat-raised-button color="success" ngbTooltip="Generate Credentials" [disableRipple]="true" class="float-right"
            (click)="generateCred()"><img src="../../../../assets/esop/AddEmployee.svg"
              alt="Generate Credentials" />&nbsp;&nbsp;Generate Credentials</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <p class="content-pg-1">
                An overview of the equity holding and the secondary nature of transactions undertaken by the
                shareholder so far is displayed below. You can edit/add profile information of the shareholder in this
                section by clicking on the ‘edit’ button.
            </p>
        </div>
    </div>

    <div class="row pt-xs-20">
        <div class="col-md-12">
            <mat-label class="subhdr-text">Shareholder Details</mat-label>
        </div>
    </div>

    <div class="row btm-20 custom-wrapper top-20">
        <div class="col-lg-12 col-md-12 col-sm-12 custom-wrapper-box1 card-lyot-dv">
            <div>
                <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        <span class="parent-container" *ngIf="shareholderDetails">
                            <img *ngIf="shareholderDetails.shareholderDetailsDto.pictureURL == null"
                                class="profile-picture" src="../../../assets/avatar02.png" />
                            <img *ngIf="shareholderDetails.shareholderDetailsDto.pictureURL != null"
                                class="profile-picture" [src]="shareholderDetails.shareholderDetailsDto.pictureURL" alt="Shareholder"/>
                        </span>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <div>
                            <mat-label>Shareholder Name</mat-label>
                        </div>
                        <div class="btm-20">
                            <mat-label class="mat-label-value" *ngIf="shareholderDetails">
                                {{shareholderDetails.shareholderDetailsDto.name}}</mat-label>
                        </div>
                        <div>
                            <mat-label>No of Shares</mat-label>
                        </div>
                        <div>
                            <mat-label class="mat-label-value" *ngIf="shareholderDetails"
                                ngbTooltip="{{formatCurrency(shareholderDetails.shareholderDetailsDto.numberOfShares)}}">{{
                                    getTotalValueTble(shareholderDetails.shareholderDetailsDto.numberOfShares)}}
                            </mat-label>
                        </div>
                    </div>

                    <div class="col-xl-2 col-lg-3 col-md-2 col-sm-2">
                        <div>
                            <mat-label>Gain</mat-label>
                        </div>
                        <div class="btm-20">
                            <mat-label class="mat-label-value" *ngIf="shareholderDetails">
                                {{shareholderDetails.shareholderDetailsDto.percentageOfGain ? (shareholderDetails.shareholderDetailsDto.percentageOfGain | percent) : '-'}}</mat-label>
                        </div>
                        <div>
                            <mat-label>Percentage Holding</mat-label>
                        </div>
                        <div>
                            <mat-label class="mat-label-value" *ngIf="shareholderDetails">{{shareholderDetails.shareholderDetailsDto.percentageOfHolding
                                | percent: '2.2'}}</mat-label>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-2 col-md-3 col-sm-3">
                        <div>
                            <mat-label>Net Worth ({{currencySymbol}})</mat-label>
                        </div>
                        <div class="btm-20">
                            <mat-label class="mat-label-value"
                                ngbTooltip="{{formatCurrency(shareholderDetails.shareholderDetailsDto.netWorth)}}"
                                *ngIf="shareholderDetails">{{ shareholderDetails.shareholderDetailsDto.netWorth ? 
                            getTotalValueTble(shareholderDetails.shareholderDetailsDto.netWorth) : '-'
                            }}</mat-label>
                        </div>
                        <div>
                            <mat-label>Beneficiaries</mat-label>
                        </div>
                        <div>
                            <mat-lable class="mat-label-value">
                                <span class="cursor-hand link-style" (click)="shareholderDetails?.shareholderDetailsDto.numberOfBeneficiaries > 0 ? goToBeneficiaries() : ''">{{ shareholderDetails?.shareholderDetailsDto.numberOfBeneficiaries }}</span>
                            </mat-lable>
                        </div>
                    </div>
                    <div *appHideIf="['admin-without-proforma']" class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                        <img class="cursor-hand" ngbTooltip="Edit Shareholder details"  src="../../assets/edit.svg" (click)="editShareholderProfile()">
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-xs-20">
            <div class="col-md-12">
                <mat-label class="subhdr-text">Transaction Details</mat-label>
            </div>
        </div>
        <div class="top-20 col-lg-12 col-md-12 col-sm-12 custom-wrapper-box2 pl-xs-0 pr-xs-0 pt-xs-20 pt-lg-0 full-width">
            <div style="max-height: 200px !important; min-height: 150px;" class="table-responsive table-common">
                <table class="custom-table">
                    <thead>
                        <tr>
                            <th class="width-10 text-align-left">Buy/Sell</th>
                            <th class="width-20 text-align-right">No of Shares</th>
                            <th class="width-15 text-align-right">Price</th>
                            <th class="width-20 text-align-right">Date</th>
                            <th class="width-20 text-align-right">Share Numbers</th>
                            <th class="width-15 text-align-right">Folio No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of myTransactions" let index=index; >
                            <td class="width-10 text-align-left" placement="right" container="body"
                                ngbTooltip="{{ transaction.transactionString }}">
                                {{ transaction.transactionString }}
                            </td>
                            <td class="width-20 text-align-right" placement="right" container="body"
                                ngbTooltip="{{formatCurrency(transaction.numberOfShares)}}">
                                {{ getTotalValueTble(transaction.numberOfShares)}}</td>
                            <td class="width-15 text-align-right" placement="right" container="body"
                                ngbTooltip="{{formatCurrency(transaction.price)}}">
                                {{ getTotalValueTble(transaction.price) }}
                            </td>
                            <td class="width-20 text-align-right" placement="right" container="body"
                                ngbTooltip="{{ transaction.date | date }}">{{ transaction.date | date:'dd-MMM-yyyy' }}</td>
                            <td class="width-20 text-align-right" >
                                <ng-container *ngIf="transaction.shareNumberList != null else emptyList">
                                    <ng-container *ngTemplateOutlet="itemList">
                                    </ng-container>
                                </ng-container>
                                 
                                <ng-template #itemList> 
                                    <ng-template ngFor let-item [ngForOf]="transaction.shareNumberList">
                                        <div placement="right" container="body" ngbTooltip="{{ item['shareStartNumber'] }} - {{ item['shareEndNumber'] }}">
                                            {{ item['shareStartNumber'] }} <span>-</span>
                                            {{ item['shareEndNumber'] }}
                                        </div>
                                    </ng-template> 
                                </ng-template>
                                 
                                <ng-template #emptyList>
                                    <span>Nil</span>
                                </ng-template>  
                            </td>
                            <td class="width-15 text-align-right" container="body" ngbTooltip="{{ transaction.folioNumber }}">
                                {{ transaction.folioNumber }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-12 text-align-right notes-tbl btm-20">
            <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
        </div>
    </div>

    <div class="row btm-20">
        <div class="col-md-12" *ngIf="shareholderDetails && shareholderDetails.shareholderFundDto">
            <div *ngFor="let investmentType of shareholderDetails.shareholderFundDto;let i = index">
                <mat-card class="mat-elevation-z3 btm-20">
                    <mat-card-header>
                        <mat-card-title>{{investmentType.instrumentName}}</mat-card-title>
                    </mat-card-header>

                    <mat-divider class="divider-line"></mat-divider>

                    <div class="row top-20">
                        <div class="col-md-12 pb-xs-20">
                            <p class="content-pg-1" *ngIf="investmentType.instrumentName === 'Equity'">
                                The table provides the complete details of all investments made by the shareholder across the
                                funding rounds in exchange for equity shares of the company. The corresponding share certificates
                                and other related documents are also linked for further reference. For more information, click on
                                the shareholder’s name in the table.
                            </p>
                            <p class="content-pg-1" *ngIf="investmentType.instrumentName === 'CCPS'">
                                The table provides the complete details of all investments made by the shareholder across the
                                funding rounds in exchange for compulsorily convertible preference shares of the company. The
                                corresponding share certificates and other related d    ocuments are also linked for further reference.
                                For more information, click on the shareholder’s name.
                            </p>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table-common btm-20">
                            <thead>
                                <tr>
                                    <th class=" table-col1 text-align-left padding-left-tdxl">Shareholder Name</th>
                                    <th class=" table-col2 text-align-left">Round</th>
                                    <th class=" table-col3 text-align-right">No of Shares</th>
                                    <th class=" table-col4 text-align-right">Price per Share</th>
                                    <th class=" table-col5 text-align-right">Amount Invested</th>
                                    <th class=" table-col6">Share Certificate</th>
                                    <th class=" table-col7">Current Value</th>
                                    <th class=" padding-right-tdxl"> Title</th>
                                    <th class="table-col-8"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-col1 individual-row">
                                    <td class=" text-align-left padding-left-tdxl cursor-hand">
                                        <span class="link-style" (click)="openFundModel()" data-toggle="modal"
                                            data-target=".add-profile-info">{{ shareholderDetails.shareholderDetailsDto.name }}</span>&nbsp;
                                        <span *ngIf="!investmentType.expanded"
                                            (click)="investmentType.expanded = true"><i class="fa fa-caret-right"
                                                aria-hidden="true"></i></span>
                                        <span *ngIf="investmentType.expanded"
                                            (click)="investmentType.expanded = false"><i class="fa fa-caret-down"
                                                aria-hidden="true"></i></span>
                                    </td>
                                    <td class="roundListGrid">
                                        <span *ngFor="let round of investmentType.roundDetails" class="rnd-identifier cursor-hand"
                                            [ngClass]="identifierCSS(round.roundIdentifier)"
                                            (click)="goToRoundDetails(round.roundName)" placement="right"
                                            ngbTooltip={{round.roundName}}>
                                            {{ round.roundIdentifier }}
                                        </span>
                                    </td>
                                    <td class=" text-align-right" placement="right" container="body"
                                        ngbTooltip="{{formatCurrency(investmentType.numberOfSharesOfGroup)}}">
                                        {{ getTotalValueTble(investmentType.numberOfSharesOfGroup) }}
                                    </td>
                                    <td class=" text-align-right" placement="right" container="body"
                                        ngbTooltip="{{formatCurrency(investmentType.pricePerShareOfGroup)}}">
                                        {{ getTotalValueTble(investmentType.pricePerShareOfGroup) }}
                                    </td>
                                    <td class=" text-align-right" container="body"
                                        ngbTooltip="{{formatCurrency(investmentType.amountInvestedOfGroup)}}">
                                        {{ getTotalValueTble(investmentType.amountInvestedOfGroup) }}
                                    </td>
                                    <td class=""></td>
                                    <td class=""></td>
                                    <td class="padding-right-tdxl"></td>
                                    <td class=""></td>
                                </tr>
                                <tr class="individual-row" [hidden]="!investmentType.expanded"
                                    *ngFor="let individual of investmentType.individualFundDto">
                                    <td class="padding-left-tdxxl">{{ individual.fundName }}</td>
                                    <td class="roundListGrid">
                                        <span class="rnd-identifier cursor-hand" *ngFor="let round of individual.roundDetails"
                                            [ngClass]="identifierCSS(round.roundIdentifier)"
                                            (click)="goToRoundDetails(round.roundName)" placement="right"
                                            ngbTooltip={{round.roundName}}>
                                            {{ round.roundIdentifier }}
                                        </span>
                                    </td>
                                    <td class=" text-align-right" placement="right" container="body" ngbTooltip="{{formatCurrency(individual.numberOfShares)}}">
                                        {{ getTotalValueTble(individual.numberOfShares) }}
                                    </td>
                                    <td class=" text-align-right" placement="right" container="body"
                                        ngbTooltip="{{ formatCurrency(individual.pricePerShare)}}">
                                        {{ getTotalValueTble(individual.pricePerShare) }}
                                    </td>
                                    <td class=" text-align-right" placement="right" container="body"
                                        ngbTooltip="{{ formatCurrency(individual.amountInvested)}}">
                                        {{ getTotalValueTble(individual.amountInvested) }}
                                    </td>
                                    <td class="">
                                        <img (click)="showCertificate(individual.investmentRecordId)" src="../../../assets/share-certificate.svg" />
                                        <input id="uploadImage" hidden type="file" (change)="importCertFile($event, individual.investmentRecordId)" accept="application/pdf, .docx"
                                        #uploadImage />
                                        <img id="importShareCertificates" *appShowIf="['full-access', '!strict']" (click)="uploadImage.click()" class="left-10 cursor-hand" ngbTooltip="Upload Share Certificate"
                                            src="../../../assets/upload.svg" />
                                        <!-- <button mat-raised-button id="importShareCertificates" [disableRipple]="true" color="success" class="import-btn" ngbTooltip="Import Share Certificates Data" (click)="uploadImage.click()">
                                            Import Share Certificates
                                        </button> -->
                                    </td>
                                    <td class=" text-align-right" container="body"
                                    ngbTooltip="{{formatCurrency(individual.netWorth)}}">
                                        {{ getTotalValueTble(individual.netWorth) }}
                                    </td>
                                    <td class="padding-right-tdxl">
                                        <img *appHideIf="['admin-without-proforma']" src="../../../assets/sellred.svg"
                                            (click)="goToSecondary(shareholderDetails.shareholderDetailsDto.name)" />
                                    </td>
                                    <td>
                                        <span *appHideIf="['shareholder-viewer', 'admin-without-proforma']">
                                            <button type="button" [ngClass]="individual.isAllowedToConvert ? '' : 'disabled'" class="conversion-redemption-btn" (click)="navigateTo('conversion', individual.securityClassId, shareholderDetails.shareholderDetailsDto.id, individual.isAllowedToConvert)" ngbTooltip="Conversion" container="body" >
                                                <i class="dx-icon dx-icon-repeat"></i>
                                            </button>
                                            
                                            <button type="button" [ngClass]="individual.isAllowedToRedeem ? '' : 'disabled'" class="conversion-redemption-btn" (click)="navigateTo('redemption', individual.securityClassId, shareholderDetails.shareholderDetailsDto.id, individual.isAllowedToRedeem)" ngbTooltip="Redemption" container="body" >
                                                <i class="dx-icon dx-icon-money"></i>
                                            </button>
                                            <button type="button" [ngClass]="individual.isPartlyPaidShare ? '' : 'disabled'" class="partly-paid-button" (click)="navigateTo('partly-paid', individual.securityClassId, individual.investmentRecordId, individual.isPartlyPaidShare)" ngbTooltip="Partly Paid Share" container="body" >
                                                <div>P</div>
                                            </button>
                                            <img class="add-beneficiary-btn cursor-hand" src="../../../assets/profile-info.svg" (click)="openBeneficiaryDialog(individual.investmentRecordId)" ngbTooltip="Add Beneficiary" />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-align-right notes-tbl">
                        <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
    <app-certificate-component *ngIf="showDialogCert" [(showDialogCert)]="showDialogCert" [investorId]="investmentRecordId" [usedIn]="'shareholderDetailsPage'" (visibleChangeCert)="getEventCert($event)">
    </app-certificate-component>

    <app-profile-info class="dialog-back" *ngIf="showdigPrfle" [(showdigPrfle)]="showdigPrfle" (visibleChange)="getEventProfile($event)"  (parentInit)="ngOnInit()"
            [(shareholderId)]="shareholderId" [(shareHolderType)]="shareHolderType" [(groupNames)]="groupNames" [(shareholderName)]="shareholderName">
    </app-profile-info>

    <app-generate-credentials-dialog *ngIf="genCredentialsDialog.show" [(showDialog)]="genCredentialsDialog.show" [(genCredentialsDialog)]="genCredentialsDialog" (visibleChange)="getCredData($event)"></app-generate-credentials-dialog>

    <app-share-certificate-email *ngIf="shareCertificateDialog.show" [(showDialog)]="shareCertificateDialog.show" [(shareCertificateDialog)]="shareCertificateDialog" (visibleChange)="getShareCertData($event)"></app-share-certificate-email>
    <app-add-beneficiary *ngIf="showBeneficiary" [(showDialog)]="showBeneficiary" [profileId]="profileId" (visibleChange)="getBeneficiary($event)"></app-add-beneficiary>

</div>
