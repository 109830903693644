export class employeesOverviewModel {
    employeeTableDtoList: Array<employeesTableDto>;
  }
  export class employeesTableDto {
    id?: number;
    employeeName?: string;
    employeeDesignation?: string;
    department?: string;
    totalOptionsGranted?: number;
    totalOptionsVested?: number;
    totalOptionsExercised?: number;
    isLoginProvided?: boolean;
  }