import { Component, OnInit } from '@angular/core';
import { CacheProviderService } from "../../services/cache-provider.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { DatePipe } from "@angular/common";
import { DataService } from "../../services/new-round-notify.service";
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from "../../services/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { BuyBackDetails } from '../buy-back-tab/model/buyBackDetails';
import { HelpContent } from '../buy-back-tab/model/helpContent';


@Component({
  selector: 'app-confirm-tab',
  templateUrl: './confirm-tab.component.html',
  styleUrls: ['./confirm-tab.component.scss']
})
export class ConfirmTabComponent implements OnInit {

  compliance = [
    { id: 0, text: "We understand that we cannot re-issue the same instrument within the next 6 months *", isChecked: false },
    { id: 1, text: "We confirm that we are not exceeding the 25% limit on the buyback of the mentioned instrument *", isChecked: false },
    { id: 2, text: "We confirm that we do not exceed the limits for this financial year *", isChecked: false },
  ];

  shareReservesText: any;
  shareReserves: number = 0;
  public usrCompanyCurrency = "";
  public userDetails;
  currencySymbol = "";

  buyBackDetails: BuyBackDetails = new BuyBackDetails();
  buyBackId: number;
  target : number = 0;
  pps: number = 0;
  instrumentName: string = "";
  startDate: any;

  totalBuyBackAmount: number = 0;
  totalWillingNess: number = 0;
  actualTargetShares: number = 0;
  actualTargetSharesText = '';
  postBuyBackConsolidation: number = 0;
  instrumentsFromResponse: any = [];
  totalSharesInSecurity: number = 0;
  public disableripple: boolean = true;
  allCheckboxChecked: boolean = false;
  disableCompletion: boolean = true;
  shareReservesCheck: boolean = true;
  checkBoxes: string = "please check all boxes";
  public helpTitle: string = "";
  public helpMessage: string = "";
  helperTopPosition: number;
  helper: string = "";
  helpSections: HelpContent;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private utility: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) {}

  ngOnInit() {
    this.ngxLoader.start();
    this.activatedRoute.queryParams
      .filter(params => params.buyBackId)
      .subscribe(params => {
        // console.log(params); // { order: "popular" }
        this.buyBackId = params.buyBackId;
      }
    );

    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
        
    this.authService.getProformaBuyBackDetails(this.buyBackId).subscribe((res: BuyBackDetails) => {
      this.buyBackDetails = res;
      this.target = res.numberOfSharesBoughtBackActual;
      this.pps = res.pricePerShare;
      this.instrumentName = res.className;
      this.totalBuyBackAmount = res.buyBackAmountActual;
      this.postBuyBackConsolidation =  Math.round(this.buyBackDetails.postBuybackConsolidation*10000) / 100;
      this.startDate = this.datepipe.transform(this.buyBackDetails.startDate, 'dd-MMM-yyyy');

      if (this.buyBackDetails.state === "CAPTABLE_SUMMARY"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":true,
          "isConfirmTabValid":true
        });
      }
      this.authService.getInstrumentsForBuyBack().subscribe((resInstr:[]) => {
        this.instrumentsFromResponse = resInstr;
        this.instrumentsFromResponse.forEach(element =>{
          if (this.buyBackDetails.className.trim() === element["className"].trim()){
            this.totalSharesInSecurity = element['numberOfShares'];
            // this.calculatePostBuyBackConsoildation();
          }
        });
      }, (e) => {
            this.toastr.error("Error: Unable to get Instrument data", e);
        });
        this.ngxLoader.stop();
      if (this.buyBackDetails.startDate != null && this.buyBackDetails.state != "CAPTABLE_SUMMARY") {
        this.ngxLoader.stop();
        this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page");
        this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      }
    }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page", e);
          this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      });
  }

  onChangeShareReserves(type, e) {
    
    if (type === 'ip') {
      let rst;
      if(e) {
          // store current positions in variables
          var start = e.target.selectionStart,
          end = e.target.selectionEnd;
      }
      let val = this.shareReservesText;
      this.shareReserves = this.shareReservesText.replace(/,/g, "") / 1;
      this.shareReservesCheck = this.shareReserves < this.buyBackDetails.buyBackAmountActual;
      rst = this.utility.inputCSVformatCurrencyRound(this.usrCompanyCurrency, val);
  
      if(e) {
          if(e.target.value) {
              let CSVLength = rst.length;
              let valueLength = e.target.value.length;
              if(CSVLength > valueLength) {
                  e.target.value = rst;
                  let difference = CSVLength - valueLength;
                  start = start + difference;
                  end = end + difference;
              }
              else if(CSVLength < valueLength){
                  e.target.value = rst;
                  if(start != 0 || end != 0){
                      let difference = valueLength - CSVLength;
                      start = start - difference;
                      end = end - difference;
                  }
                  else{
                      start = start;
                      end = end;
                  }  
              }
              else {
                  e.target.value = rst;
              }
          }
      
          // restore from variables...
          e.target.setSelectionRange(start, end);
      } else {
          this.shareReservesText = rst;
      }
    }
  }

  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
  }

  goToSummary() {
    this.ngxLoader.start();
      this.ngxLoader.stop();

    this.dataService.updateData({
      "isbuyBackDetailsTabValid":true,
      "isShareholdersTabValid":true,
      "isReviewTabValid": true,
      "isSummaryTabValid": true,
      "isConfirmTabValid": null
    });
    this.router.navigate(
      ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'summary'],
      { queryParams: { 'buyBackId': this.buyBackId } }
      );
      // this.authService.saveBuyBackDetails(this.buyBackDetails).subscribe((response) => {
      //     if (response) {
      //         this.ngxLoader.stop();
      //         this.cacheService.set('newBuyBackName', this.buyBackDetails.buyBackName);
      //         this.cacheService.set('newBuyBackDetails', JSON.stringify(this.buyBackDetails));
      //         this.cacheService.set('newBuyBackSavRespns', JSON.stringify(response));
      //         this.router.navigate(['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'shareholders']);
      //     } else {
      //         this.ngxLoader.stop();
      //         this.toastr.error("Error: Response is null!", "Error");
      //     }
      // }, (e) => {
      //     this.ngxLoader.stop();
      //     this.toastr.error("Error: " + e.error.reason, "Error");
      // });

  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utility.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utility.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }

  discardBuyBack() {
    this.ngxLoader.start();
    if(confirm('Are you sure you want to discard the Buy-Back?')) {
      if (this.buyBackDetails.state != "COMPLETED"){
        this.authService.discardBuyBackTransaction(this.buyBackDetails.id).subscribe((response) => {
          if (response['resultCode'] == 'SUCCESS') {
              this.ngxLoader.stop();
              this.toastr.success("Buy-Back Discarded successfully!", "Success");
              this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Error discarding Buy-back", "Failure");
          }
      }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error discarding Buy-back" );
      })
      }
    } else {
        this.ngxLoader.stop();
        return;
    }
  }

  formatCompanyCurrency(value){
    return this.utility.inputCSVformatCurrency1(this.usrCompanyCurrency, value);
  }

  completeBuyBack() {
    this.ngxLoader.start();
    if(confirm('Are you sure you want to complete the Buy-Back process? This will record the transactions on the Captable and the Shareholders Details. This cannot be reversed.')) {
      this.dataService.updateData({
        "isbuyBackDetailsTabValid":true,
        "isShareholdersTabValid":true,
        "isReviewTabValid": true,
        "isSummaryTabValid": true,
        "isConfirmTabValid": true
      });
      this.authService.executeBuyBackFinal(this.buyBackId, false, this.shareReserves).subscribe((response) => {
        if (response) {
          this.ngxLoader.stop();
          this.toastr.success("Buy-back process completed");
          this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
        }
        else {
          this.ngxLoader.stop();
          this.toastr.error("Error: Response is null!", "Unable to complete buy-back");
        }
        }, 
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Error:");
      });
    } else {
        this.ngxLoader.stop();
        return;
    }
  }

  setAll() {
    // this.disableCompletion = completed;
    let checkedItems = this.compliance.filter(i => i.isChecked == true);
    this.disableCompletion = !(checkedItems.length == this.compliance.length);
  }

  showHelp(section) {
    if(section == this.helper){
        return;
      }
      const targetElement = document.getElementById(section);
      if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
      }
      
        this.helpSections = this.helperService.getHelpContent(
          "ConfirmTabComponent",
          section
        );
      this.helper = section;
}

// help section hide
  hideHelp() {
    this.helper = "";
  }

  // calculatePostBuyBackConsoildation(){
  //   if ((this.totalSharesInSecurity != 0)){
  //     this.postBuyBackConsolidation = this.buyBackDetails.numberOfSharesBoughtBackActual * 100 / this.totalSharesInSecurity;
  //     this.postBuyBackConsolidation = Math.round(this.postBuyBackConsolidation*100) / 100;
  //   }
  //   else{
  //     this.postBuyBackConsolidation = 0;
  //   }
  // }

}
