import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-confirm-redirect-dialog",
  templateUrl: "./confirm-redirect-dialog.component.html",
  styleUrls: ["./confirm-redirect-dialog.component.scss"]
})
export class ConfirmRedirectDialogComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() redirectUrl: string;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  redirected: boolean = false;

  constructor() {}

  ngOnInit() {
  }

  redirect() {
    window.open(this.redirectUrl,"_blank");
    this.redirected = true;
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit(this.showDialog);
  }
}
