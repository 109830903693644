import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-file-conversion',
  templateUrl: './file-conversion.component.html',
  styleUrls: ['./file-conversion.component.scss']
})
export class FileConversionComponent implements OnInit {
  sourceFile = null;
  XMLfile = null;
  Excelfile: null;

  constructor(private authService: AuthenticationService, private ngxloader: NgxUiLoaderService, private toastr: ToastrService,) { }

  ngOnInit() {
  }

  handleFileInput(fileInput: Event) {
    this.sourceFile = fileInput.target['files'][0];
  }

  handleXMLFileInput(fileInput: Event) {
      this.XMLfile = fileInput.target['files'][0];
  }

  handleEXCELFileInput(fileInput: Event) {
    this.Excelfile = fileInput.target['files'][0];
  }

  convertToXML() {
    if(this.sourceFile == null){
      this.toastr.error("Please select an pdf file", "Error");
      return;
    }
    this.ngxloader.start();
    const formData: FormData = new FormData();
    formData.append('file', (new File([this.sourceFile], 'file.pdf', { type: 'multipart/form-data' })));
    this.authService.PDFtoXML(formData).subscribe(
        (res : HttpResponse<any> ) => {
            let blob = new Blob([res.body], {type: 'application/zip'});
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "file.zip";
            this.ngxloader.stop();
            link.click();
        },
        (e) => {}
    );
  }

  convertToPDF() {
    if(this.Excelfile == null){
      this.toastr.error("Please select an excel file", "Error");
      return;
    }
    this.ngxloader.start();
    const formData: FormData = new FormData();
    formData.append('file', (new File([this.Excelfile], 'file.xlsx', { type: 'multipart/form-data' })));
    this.authService.XMLtoPDF(formData).subscribe(
        (res) => {
            let blob = new Blob([res], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(res);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "Output.pdf";
            this.ngxloader.stop();
            link.click();
        },
        async (e) => {
          var textPromise = e.error.text();
          var text = await e.error.text();
          e.error.text().then(text => {
            let error = JSON.parse(text);
            this.ngxloader.stop();
            this.toastr.error(error.resultCode, "Error");
          });
        }
    );
  }

}
