import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

interface RowData {
  rowData: {
    planName: string
  }
}
@Component({
  selector: 'app-upload-plan-document',
  templateUrl: './upload-plan-document.component.html',
  styleUrls: ['./upload-plan-document.component.scss']
})
export class UploadPlanDocumentComponent implements OnInit {

  // @ViewChild('planDocument') planDocument: ElementRef

  planFile;
  fileName;

  constructor(
    public dialogRef: MatDialogRef<UploadPlanDocumentComponent>,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private readonly utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA)
    public data: RowData
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  fileChangeEvent(event) {
    this.planFile = event.target.files[0];
    const fileExtenstion = this.planFile.name.split('.')[this.planFile.name.split('.').length - 1].toLowerCase();
    const acceptedExtensions = ['pdf']
    if (!(acceptedExtensions.includes(fileExtenstion))) {
      this.planFile = null
      this.toastr.error("Invalid Format: Please upload pdf file", "Error");
      return
    } else {
      this.fileName = this.planFile.name;
    }
  }

  Upload() {
    if (this.planFile != null) {
      this.ngxLoader.start();
      const planDocumentData = new FormData();
      console.log(this.planFile)
      planDocumentData.append('file', this.planFile)
      planDocumentData.append('planName', this.data.rowData.planName)
      const companyId = JSON.parse(sessionStorage.getItem('userDetails'))['company']['id']
      planDocumentData.append('companyId', companyId)
      this.authService.uploadPlanDocument(planDocumentData).subscribe(
        (response) => {
          if (response) {
            this.ngxLoader.stop();
            this.dialogRef.close();
            this.toastr.success("Uploaded successfully");
          } else {
            this.ngxLoader.stop();
            this.dialogRef.close();
            this.toastr.error("Error while uploading Plan document")
          }
        }
      )
    } else {
      this.toastr.error("Choose file before uploading ")
    }
  }
}