<div >
  <div mat-dialog-content class="resolutionDate">Generate SH-6</div>
<div mat-dialog-content class="body" > 
  <div class="date-container">
    <mat-label class="required col-4">Plan</mat-label><br>
    <div class="col-8">
      <select class="form-control input-fields cursor-hand" id="esopManagement-allplans" [(ngModel)]="selectedPlan"
        (change)="changeByplanNames($event.target.value)">
        <option value="All Plans">All Plans</option>
        <option *ngFor="let plans of data.allPlans" value="{{plans.planName}}">
          {{plans.planName}}</option>
      </select>
    </div>
  </div>
  <!-- <div class="date-container" *ngIf="selectedPlan != 'All Plans'">
    <mat-label class="required col-4">From Date</mat-label><br>
    <div class="input-eyePlaceholder col-7 date-box">
    <input disabled matInput [matDatepicker]="picker0" [(ngModel)]="selectedPlanStartDate" #resolDateError="ngModel" placeholder="DD-MMM-YYYY" [ngModelOptions]="{standalone: true}" required>
    </div>
  </div> -->
  <div class="date-container">
    <mat-label class="required col-4">To Date</mat-label><br>
    <div class="input-eyePlaceholder col-7 date-box">
    <input matInput [matDatepicker]="picker" [(ngModel)]="date" #resolDateError="ngModel" placeholder="DD-MMM-YYYY" [ngModelOptions]="{standalone: true}" required>
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon ></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker ngDefaultControl ></mat-datepicker>
    </div>
    <div *ngIf="resolDateError.errors">
      <div *ngIf="(resolDateError.dirty || resolDateError.touched) && !mresDateErr" class="alert alert-danger">Resolution Date is required
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button style="background-color:#CD4927" color="primary" value="export" (click)="exportSh6(date)" [mat-dialog-close]="'ok'" cdkFocusInitial>Generate</button>
    <button mat-raised-button color="secondary" [mat-dialog-close]="'no'">Cancel</button>
</div>
</div>
