import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../authentication.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})

export class BaseComponent implements OnInit {
  bearerToken;
  failureMsg;
  signinType;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthenticationService) { }

  ngOnInit() {
    this.bearerToken = this.route.snapshot.queryParamMap.get('token');
    this.signinType = this.route.snapshot.queryParamMap.get('signintype');

    if(this.bearerToken != null) {
      localStorage.setItem('currentUser', this.bearerToken);
      localStorage.setItem('signintype', this.signinType);
      this.redirectToNext();
    } else {
      this.failureMsg = this.route.snapshot.queryParamMap.get('failure');
    }
  }

  redirectToNext() {
    this.authService.getUserDetails().subscribe(
      (res) => {
        if (res['company'] != null) {
          localStorage.setItem('companyId', res['company']['id']);
          this.authService.getExistingCapTable(false).subscribe(
            (res) => {
              this.authService.capTableData = res;
              this.router.navigate(['/landing', 'dashboard', 'capTable']);
            }
          );
        } else {
          this.router.navigate(['/landing', 'onboarding']);
        }
      },
      e => {
        localStorage.clear();
        this.router.navigate(['']);
      }
    );
  }
}

