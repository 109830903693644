import { Component, OnInit } from '@angular/core';
import { CacheProviderService } from "../../services/cache-provider.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { DatePipe } from "@angular/common";
import { DataService } from "../../services/new-round-notify.service";
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from "../../services/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { BuyBackDetails } from '../buy-back-tab/model/buyBackDetails';
import { ColumnList } from '../summary-cap-table/columns-list';

@Component({
  selector: 'app-buy-back-summary-tab',
  templateUrl: './summary-tab.component.html',
  styleUrls: ['./summary-tab.component.scss']
})
export class BuyBackSummaryTabComponent implements OnInit {

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private utility: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) {}

  public usrCompanyCurrency = "";
  public userDetails;

  buyBackDetails: BuyBackDetails = new BuyBackDetails();
  buyBackId: number;
  target : number = 0;
  pps: number = 0;
  instrumentName: string = "";
  startDate: any;
  capTableData: any;

  totalBuyBackAmount: number = 0;
  totalWillingNess: number = 0;
  actualTargetShares: number = 0;
  actualTargetSharesText = '';
  postBuyBackConsolidation: number = 0;
  instrumentsFromResponse:any = [];
  totalSharesInSecurity: number = 0;
  public disableripple: boolean = true;
  note: String = "";
  totalFDBShares: number = 0;

  displayCols: ColumnList;
  holdersList;
  currencySymbol = "";

  ngOnInit() {
    this.ngxLoader.start();
    this.activatedRoute.queryParams
      .filter(params => params.buyBackId)
      .subscribe(params => {
        // console.log(params); // { order: "popular" }
        this.buyBackId = params.buyBackId;
      }
    );
    this.authService.getTotalFDBShares().subscribe((res:number) => {
      this.totalFDBShares = res;
      
    }, (e) => {
          this.toastr.error("Error: Unable to get total FDB shares.");
        });
    this.authService.getProformaBuyBackDetails(this.buyBackId).subscribe((res: BuyBackDetails) => {
      this.buyBackDetails = res;
      this.target = res.numberOfSharesBoughtBackActual;
      this.pps = res.pricePerShare;
      this.instrumentName = res.className;
      this.totalBuyBackAmount = res.buyBackAmountActual;
      // this.postBuyBackConsolidation = 10;
      this.startDate = this.datepipe.transform(this.buyBackDetails.startDate, 'dd-MMM-yyyy');

      if (this.buyBackDetails.state === "SHAREHOLDER_DETAILS"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":true,
          "isConfirmTabValid":null
        });
      }
      else if (this.buyBackDetails.state === "CAPTABLE_SUMMARY"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":true,
          "isConfirmTabValid":null
        });
      }

      this.authService.getInstrumentsForBuyBack().subscribe((resInstr:[]) => {
        this.instrumentsFromResponse = resInstr;
        this.instrumentsFromResponse.forEach(element =>{
          if (this.buyBackDetails.className.trim() === element["className"].trim()){
            this.totalSharesInSecurity = element['numberOfShares'];
            this.calculatePostBuyBackConsoildation();
          }
        });
      }, (e) => {
            this.toastr.error("Error: Unable to get Instrument data", e);
        });

      if (this.buyBackDetails.startDate != null && (this.buyBackDetails.state === "SHAREHOLDER_DETAILS" || this.buyBackDetails.state === "CAPTABLE_SUMMARY")) {
        this.authService.executeBuyBackTempForSummary(this.buyBackId, true, null).subscribe((response) => {
          if (response) {
            this.note = this.buyBackDetails.buyBackNote;
            this.capTableData = response["shareHolderGroups"];
            for (var record in response["shareHolderGroups"]){
              if (this.capTableData[record].hasOwnProperty("investorGroup")) {
                this.capTableData[record]["name"] = this.capTableData[record]["investorGroup"];
              }
              if (this.capTableData[record].hasOwnProperty("postHoldingFDBShares")) {
                this.capTableData[record]["numberOfDilutedShares"] = this.capTableData[record]["postHoldingFDBShares"];
              }
            }
            this.holdersList = this.capTableData;
            this.ngxLoader.stop();
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Error: Response is null!", "Error");
          }
          }, 
          (e) => {
            this.ngxLoader.stop();
            this.toastr.error(e.error, "Error");
        });
      }
      else{
        this.ngxLoader.stop();
        this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page");
        this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      }

    }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page", e);
          this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      });

      if(this.cacheService.get("userDetails")){
        this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
        this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
      }
      else{
        this.usrCompanyCurrency = "INR - ₹";
      }
      this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();

    this.displayCols = new ColumnList();
    this.displayCols = {
      shareholder: true,
      fdbShares: true,
      preHoldingPercentage: true,
      postHoldingPercentage: true,
      preHoldingShares: true,
      postHoldingShares: true,
      amountReceived: true,
        
    }
    this.ngxLoader.stop();
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utility.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utility.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }

  formatCompanyCurrency(value){
    return this.utility.inputCSVformatCurrency1(this.usrCompanyCurrency, value);
  }

  getInstrumentTypes(){
    this.ngxLoader.start();
    
      this.ngxLoader.stop();
  }

  goToConfirm() {
    this.buyBackDetails.buyBackNote = this.note;
    this.ngxLoader.start();
      this.ngxLoader.stop();

    this.dataService.updateData({
      "isbuyBackDetailsTabValid":true,
      "isShareholdersTabValid":true,
      "isReviewTabValid":true,
      "isSummaryTabValid":true,
      "isConfirmTabValid":null
    });

    this.authService.saveBuyBackNote(this.buyBackId, this.buyBackDetails).subscribe((response) => {
      if (response) {
        this.ngxLoader.stop();
        // this.toastr.success("Buy-back note updated successfully.");
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'confirm'],
          { queryParams: { 'buyBackId': this.buyBackId } }
          );
      }
      else {
        this.ngxLoader.stop();
        this.toastr.error("Unable to update Buy-back note, proceeding to Confirm page", "Error");
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'confirm'],
          { queryParams: { 'buyBackId': this.buyBackId } }
          );
      }
      }, 
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error:", e);
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'confirm'],
          { queryParams: { 'buyBackId': this.buyBackId } }
          );
    });

    
  }

  goToReview() {
    this.ngxLoader.start();
    this.ngxLoader.stop();
    this.router.navigate(
      ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'review'],
      { queryParams: { 'buyBackId': this.buyBackId } }
      );

  }

  discardBuyBack() {
    this.ngxLoader.start();
    if(confirm('Are you sure you want to discard the Buy-Back?')) {
      if (this.buyBackDetails.state != "COMPLETED"){
        this.authService.discardBuyBackTransaction(this.buyBackDetails.id).subscribe((response) => {
          if (response['resultCode'] == 'SUCCESS') {
              this.ngxLoader.stop();
              this.toastr.success("Buy-Back Discarded successfully!", "Success");
              this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Error discarding Buy-back", "Failure");
          }
      }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error discarding Buy-back" );
      })
      }
    } else {
        this.ngxLoader.stop();
        return;
    }
  }

  calculatePostBuyBackConsoildation(){
    if ((this.totalFDBShares != 0)){
      this.postBuyBackConsolidation = this.buyBackDetails.numberOfSharesBoughtBackActual * 100 / this.totalFDBShares;
      this.postBuyBackConsolidation = Math.round(this.postBuyBackConsolidation*100) / 100;
    }
    else{
      this.postBuyBackConsolidation = 0;
    }
  }

}
