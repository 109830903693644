import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utilities.service';

@Component({
  selector: 'app-footer-component',
  templateUrl: './footer-component.component.html',
  styleUrls: ['./footer-component.component.scss']
})
export class FooterComponentComponent implements OnInit {
  showPrivacyPolicy: boolean = false;
  showTermsOfService: boolean = false;
  footerIdentifier = "overlayfooter"
  constructor(private readonly utilityService: UtilityService) { }

  ngOnInit() {
  }

  hidePopup(){
    document.getElementById(this.footerIdentifier).style.display = "none"
    this.showTermsOfService = false;
    this.showPrivacyPolicy = false;
  }
  
  showServiceTermsPopup() {
    this.showTermsOfService = true;
    document.getElementById(this.footerIdentifier).style.display = "block"
  }

  showPrivacyPolicyPopup() {
    this.showPrivacyPolicy = true;
    document.getElementById(this.footerIdentifier).style.display = "block"
    // this.utilityService.showOverlay()
  }

}
