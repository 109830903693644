<div class="container-fluid graybg">
    <div class="whiteboxwrap">

    <div class="row top-20">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <h5 class="page-hdr">Round Details</h5>
                </div>
                <div class="col-md-6">
                  <div class="button-container">
                    <!-- <div class="buttons">
                      <button mat-raised-button id="exportPas-4" [disableRipple]="disableripple"
                        [color]="isCreateRound === false ? 'success' : 'disabled' " (click)="exportPAS4File()"
                        ngbTooltip="Export PAS 5"><img src="../../assets/download_white.svg"
                          alt="Export PAS 5" />&nbsp;&nbsp;
                        Export PAS 5
                      </button>
                    </div> -->
                    <div *appHideIf="['admin-without-proforma']" class="buttons">
                        <button mat-raised-button id="buildDocuments" [disableRipple]="disableripple"
                        ngbTooltip="Build Document" [color]="isCreateRound === false ? 'success' : 'disabled' "  (click)="onBuildDocument()"
                        [disabled]="isCreateRound" *ngIf="currentEnv != 'prod'">&nbsp;&nbsp;
                        Build Document
                        <i class="fa fa-gear resolution-icons"></i>
                      </button>
                      <button mat-raised-button id="createNewRound" [disableRipple]="disableripple"
                        [color]="isCreateRound === false ? 'success' : 'disabled' " (click)="goToNewRound()"
                        ngbTooltip="Create New Round" [disabled]="isCreateRound" class="left-10"><img src="../../assets/CreateRound.svg"
                          alt="Create New Round" />&nbsp;&nbsp;
                        Create New Round
                      </button>
                      <span class="round-tooltip" *ngIf="isCreateRound" placement="left"
                        ngbTooltip="Please finish partial round"></span>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row top-10">
        <div class="col-md-12">
            <p class="content-pg-1 btm-10">
                The complete summary of a funding round is hereunder. 
                You can view the details of all the investors in the selected round as a chart or a list. 
            </p>
            <p class="content-pg-1 btm-20">
                The cap table displays the impact of the funding round on the shareholders equity. 
                Select an investor’s name from the drop down, if you wish to view the details of a particular investor.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
            <mat-label>Select Round</mat-label>
            <div class="top-10">
                <select class="form-control input-fields cursor-hand" [(ngModel)]="selectedRoundName"
                    (change)="getSelectedRounds()">
                    <option *ngFor="let roundlist of roundNameList; let k = index" value="{{ roundlist }}">
                        {{ roundlist }}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="row top-20 btm-20">

        <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
            <div class="card-lyot-dv full-height" *ngIf='roundInformation!=null'>
                <div class="col-md-12 top-20 btm-20">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-label>Round Name</mat-label>
                            <div>
                                <mat-label class="mat-label-value">
                                    {{roundInformation.roundName == null ? '-' : roundInformation.roundName }}
                                </mat-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <mat-label>Round Date</mat-label>
                            <div>
                                <mat-label class="mat-label-value">
                                    {{roundInformation.roundDate == null ? '-' : roundInformation.roundDate | date:'dd-MMM-yyyy' }}
                                </mat-label>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-4">
                            <mat-label>Priced Round</mat-label>
                            <div>
                                <mat-label class="mat-label-value" *ngIf="roundInformation.isPricedRound === true">Yes</mat-label>
                                <mat-label class="mat-label-value" *ngIf="roundInformation.isPricedRound === false">No</mat-label>
                            </div>
                        </div>
                    </div>
                    <div class="row top-20">
                        <div class="col-md-4">
                            <mat-label>Amount Raised ({{currencySymbol}})</mat-label>
                            <div>
                                <mat-label class="mat-label-value" placement="right"
                                    ngbTooltip="{{ roundInformation.amountRaised == null ? '-' :  formatCurrency(roundInformation.amountRaised)}}">
                                    {{ roundInformation.amountRaised == null ? '-' : getTotalValueTble(roundInformation.amountRaised) }}
                                </mat-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <mat-label>Pre Money ({{currencySymbol}})</mat-label>
                            <div>
                                <mat-label class="mat-label-value" placement="right"
                                    ngbTooltip="{{ roundInformation.preMoneyValuation == null ? '-' : formatCurrency(roundInformation.preMoneyValuation)}}">
                                    {{ roundInformation.preMoneyValuation == null ? '-' : getTotalValueTble(roundInformation.preMoneyValuation) }}
                                </mat-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <mat-label>Post Money ({{currencySymbol}})</mat-label>
                            <div>
                                <mat-label class="mat-label-value" placement="right"
                                    ngbTooltip="{{ roundInformation.postMoneyValuation == null ? '-' :  formatCurrency(roundInformation.postMoneyValuation)}}">
                                    {{ roundInformation.postMoneyValuation == null ? '-' : getTotalValueTble(roundInformation.postMoneyValuation) }}
                                </mat-label>
                            </div>
                        </div>
                    </div>
                    <div class="row top-20">
                        <div class="col-md-4">
                            <mat-label>Round Security</mat-label>
                            <div>
                                <mat-label class="mat-label-value">
                                    {{ roundInformation.roundInstrument == null ? '-' : roundInformation.roundInstrument }}
                                </mat-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <mat-label>Dilution</mat-label>
                            <div>
                                <mat-label class="mat-label-value" ngbTooltip="{{ roundInformation.dilutionPercentage | percent: '2.2'}}">
                                    {{ roundInformation.dilutionPercentage == null ? '-' : roundInformation.dilutionPercentage | percent: "2.2" }}
                                </mat-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <mat-label>Number of Shares</mat-label>
                            <div>
                                <mat-label class="mat-label-value" placement="right"
                                    ngbTooltip="{{ roundInformation.numberOfShares == null ? '-' :  formatCurrency(roundInformation.numberOfShares)}}">
                                    {{ roundInformation.numberOfShares == null ? '-' : getTotalValueTble(roundInformation.numberOfShares) }}
                                </mat-label>
                            </div>
                        </div>
                    </div>
                    <div class="row top-20">
                        <div class="col-md-4">
                            <mat-label>Issuance Type</mat-label>
                            <div>
                                <mat-label class="mat-label-value">
                                    {{ roundInformation.issuanceType == null ? '-' : roundInformation.issuanceType }}
                                </mat-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <mat-label>Price per Share ({{currencySymbol}})</mat-label>
                            <div>
                                <mat-label class="mat-label-value">
                                    {{ roundInformation.pricePerShare == null ? '-' : formatCurrency(roundInformation.pricePerShare) }}
                                </mat-label>
                            </div>
                        </div>
                    </div>
                    <div class="row top-20">
                        <div class="col-md-12">
                            <mat-label>Round Description</mat-label>
                            <div class="top-10">
                                <textarea class="form-control" readonly> {{ roundInformation.roundDescription == null ? '-' :  roundInformation.roundDescription }}
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="card-lyot-dv full-height" *ngIf='roundInformation==null'>
                <div class="col-md-12 text-align-center">
                    <span>No Data</span>
                </div>
            </div> -->
        </div>

        <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 ">
            <div class="card-lyot-dv full-height" *ngIf='roundInformation!=null'>

                    <div class="row top-20 btm-20" *ngIf="!isChartView">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-4">
                            <mat-label>Investors</mat-label>
                        </div>
                        <div class="col-md-3 custom-align-right">
                            <mat-label>Number of Shares</mat-label>
                        </div>
                        <div class="col-md-3 custom-align-right">
                            <mat-label>Invested Amount ({{currencySymbol}})</mat-label>
                        </div>
                        <div class="col-md-1 text-align-right">
                            <img class="cursor-hand" ngbTooltip="Chart View" *ngIf="!isChartView" src="../../assets/chart.svg"
                                (click)="InvestorsView()" />
                        </div>
                    </div>

                    <div class="row top-20 btm-20" *ngIf="isChartView">
                        <div class="col-md-2"></div>
                        <div class="col-md-8 text-align-center">
                            <mat-label> Investors </mat-label>
                        </div>
                        <div class="col-md-2 text-align-right">
                            <img class="cursor-hand" ngbTooltip="List View" src="../../assets/list.svg" (click)="InvestorsView()" />
                        </div>
                    </div>

                <!-- Investors List section -->
                <div *ngIf="!isChartView">
                    <div class="margin-15 btm-20">
                        <!-- <div class="row" *ngIf='roundInformation==null'>
                            <span>No Data</span>
                        </div> -->

                        <div class="row" *ngFor="let investors of roundInformation.investors; let i = index">
                            <div class="col-md-1">
                                <img *ngIf="investors.isLeadInvestor"
                                    src="../../../assets/lead-investor-purple.svg" />
                            </div>
                            <div class="col-md-4">
                                <p class="mat-label-value text-wrap" placement="right"
                                    ngbTooltip="{{ investors.investorName }}">{{ investors.investorName }}</p>
                            </div>
                            <div class="col-md-3 custom-align-right">
                                <mat-label class="mat-label-value" placement="right"
                                ngbTooltip="{{ formatCurrency(investors.numberOfShares)}}">
                                    {{ getTotalValueTble(investors.numberOfShares) }}
                                </mat-label>
                            </div>
                            <div class="col-md-3 custom-align-right">
                                <mat-label class="mat-label-value" placement="left"
                                    ngbTooltip="{{ formatCurrency(investors.investmentAmount)}}">
                                    {{ getTotalValueTble(investors.investmentAmount) }}
                                </mat-label>
                            </div>
                            <div class="col-md-1">
                            </div>
                        </div>
                    </div>
                    <div class="row border-top btm-20">
                        <div class="col-md-1 top-20">
                        </div>
                        <div class="col-md-4 top-20">
                            <mat-label>Total</mat-label>
                        </div>
                        <div class="col-md-3 top-20 text-align-right">
                            <mat-label placement="left"
                                ngbTooltip="{{ formatCurrency(totalNoOfShares)}}">
                                {{ getTotalValueTble(totalNoOfShares) }}
                            </mat-label>
                        </div>
                        <div class="col-md-3 top-20 text-align-right">
                            <mat-label placement="left"
                                ngbTooltip="{{ formatCurrency(investorsTotAmt)}}">
                                {{ getTotalValueTble(investorsTotAmt) }}
                            </mat-label>
                        </div>
                        <div class="col-md-1">

                        </div>
                    </div>
                </div>

                <!-- Investors chart section -->
                <div *ngIf="isChartView">
                    <div>
                        <div>
                            <dx-pie-chart id="pie" type="doughnut"
                            resolveLabelOverlapping="shift"
                            [palette]="[
                                '#4d1981',
                                '#F4522B',
                                '#e0a41e',
                                '#ffe82d',
                                '#b80d5e',
                                '#ff1382',
                                '#1e7bcf',
                                '#2598ff',
                                '#7f9c25',
                                '#9dc02e'
                            ]" diameter="0.6" [dataSource]="investorChart">
                                <dxi-series argumentField="shareholder" valueField="amt">
                                    <dxo-label [visible]="true" position="inside" backgroundColor="none"
                                        [customizeText]="customizeText" format="decimal">
                                        <dxo-font color="#ffffff" weight="bold" size="10"></dxo-font>
                                        <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                                    </dxo-label>
                                </dxi-series>
                                <dxo-legend [visible]="true" position="outside" verticalAlignment="bottom"
                                    horizontalAlignment="center" itemTextPosition="right" 
                                    orientation="horizontal" [customizeItems]="customizeLegendText">
                                    <dxo-font family="Roboto" color="#000000" size="14" weight="400"></dxo-font>
                                    <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                                </dxo-legend>
                                <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" format="decimal"
                                    opacity="0.8" [border]="{
                                opacity: '0',
                                visible: false
                                }" paddingLeftRight="6" paddingTopBottom="6">
                                </dxo-tooltip>
                            </dx-pie-chart>
                        </div>
                    </div>
                </div>

            </div>

            <!-- <div class="card-lyot-dv full-height" *ngIf='roundInformation==null'>
                <div class="col-md-12 text-align-center">
                    <span>No Data</span>
                </div>
            </div> -->
        </div>

    </div>

    <!-- Investors Group Table -->
  <app-dev-ex-cap-table class="top-20" [dataSource]="detailsTableInfo" [displayCols]="columnsList" [pageType]="'RoundDetails'" [pageData]="selectedRoundName"></app-dev-ex-cap-table>
</div>
</div>
