import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-update-nominees',
  templateUrl: './update-nominees.component.html',
  styleUrls: ['./update-nominees.component.scss']
})
export class UpdateNomineesComponent implements OnInit {
  @Input() showDialog: boolean
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    document.getElementById("overlay").style.display = "block";
  }

  navigate(){
    this.router.navigate(['/landing', 'dashboard', 'userProfile']);
  }
  close() {
    this.showDialog = false;
    this.visibleChange.emit();
  }
}
