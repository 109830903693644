import { CanDeactivate} from '@angular/router';
import { Injectable } from '@angular/core';
import {RounModelingTabComponent} from '../app/modeling/roun-modeling-tab/roun-modeling-tab.component';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  isRoundNotSaved:  boolean;
}

@Injectable()
export class RoundDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate): boolean {
    if(component.isRoundNotSaved == true){
        if (confirm("You have unsaved changes! If you leave, your changes will be lost.., Please save round")) {
            return true;
        } 
        else {
            return false;
        }
    }
    if(component.isRoundNotSaved == false){
      return true;
    }
  }
}