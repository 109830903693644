import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-service-terms',
  templateUrl: './service-terms.component.html',
  styleUrls: ['./service-terms.component.scss']
})
export class ServiceTermsComponent implements OnInit {
    @Input() showDialog: boolean;
    @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit({showMdl: this.showDialog});
  }

}
