import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splits',
  templateUrl: './splits.component.html',
  styleUrls: ['./splits.component.scss']
})
export class SplitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
