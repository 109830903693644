import { PercentPipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxTooltipComponent, DxTreeListComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utilities.service';
import { ColumnList } from './columns-list';
import { PermissionsService } from 'src/app/services/permissions.service';


@Component({
  selector: 'app-summary-cap-table',
  templateUrl: './summary-cap-table.component.html',
  styleUrls: ['./summary-cap-table.component.scss']
})
export class SummaryCapTableComponent implements OnInit {

  @Input() dataSource = [];
  @Input() displayCols: ColumnList;
  @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  expandAll = false;
  toolTipText: string = '';
  toolTipTarget: any;
  usrCompanyCurrency;
  shouldShowDialog: boolean;
  isPlan: boolean=false;
  selectedSecurity: any;

  constructor(
    private utilityService: UtilityService,
    private pp: PercentPipe,
    private router: Router,
    private toastr: ToastrService,
    private permService: PermissionsService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.usrCompanyCurrency = userDetails['company']['companyCurrency'];
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  showDebt(cellData) {
      if(cellData.value == 0 && this.activeRoute.snapshot.routeConfig.component.name == 'CapTableComponent') {
        return true;
      } else {
        return false;
      }
  }

  expandAllItem() {   
    if(this.expandAll == false) {
      this.treeList.instance.forEachNode((node) => {
          this.treeList.instance.expandRow(node.key);
      });
      this.expandAll = true;
    } else if(this.expandAll == true) {
      this.treeList.instance.forEachNode((node) => {
          this.treeList.instance.collapseRow(node.key);
      });
      this.expandAll = false;
    }
  }

  getTotalValueTble(value) {
    if(value > 0){
      if(value.toString().length > 5){
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else{
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else{
      return 0;
    }
  }

  getEvent(event) {
    this.shouldShowDialog = false;
    this.utilityService.hideOverlay();
  }


  showRoundTooltip(roundIdentifier, rounds) {
    let round = _.find(rounds, {'roundIdentifier': roundIdentifier});
    return round.roundName;
  }

  calculateCellValue(data) {
    return $.map(data['roundDetails'], function (o) {
        return o.roundIdentifier;
    });
  }

  onCellHoverChanged(e: any) {
    if (e.rowType === "data" && (e.column.dataField === "postHoldingPercentage" || e.column.dataField === "preHoldingPercentage") && e.eventType === "mouseover") {
      this.toolTipText = this.pp.transform(e.text, '2.2-2');
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if(e.rowType === "data" && e.column.dataField === "name" && e.eventType === "mouseover"){
      this.toolTipText = e.text;
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && (e.column.dataField === "preHoldingShares" || e.column.dataField === "postHoldingShares" || e.column.dataField === "numberOfDilutedShares") && e.eventType === "mouseover") {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      }
      else if(e.displayValue == null){
        this.toolTipText = "0";
      }
      else {
        this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, e.displayValue);
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && e.eventType === "mouseout") {
      this.tooltip.instance.hide();
    }
  }


}
