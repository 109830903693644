<div class="content">
  <div id="overlay"></div>
  <div class="background-gradient">

    <div class="row content-row-padding">
      <div class="col-xl-2 col-lg-1 "></div>

      <div class="col-xl-8 col-lg-10 col-sm-12 col-xs-12">
        <div class="row">
          <div class="card col-sm-12 col-md-12 col-xl-12 col-xl-12">
            <div class="row">
              <div class="col-md-6 capTbleCard">
                <div class="row header-row">
                  <p class="heading">
                    <img src="../../../assets/logo/logo.svg" />
                  </p>
                  <div class="subheading">
                    <p>Equity Management</p>
                  </div>
                </div>
                <div class="row image-row">
                  <div class="col-md-12 col-sm-12 col-lg-12">
                    <img class="ahu_icon" [attr.src]="src$ | async">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div [ngClass]="{'login-card':formType==='login','signup-card':formType==='signup' }">
                  <div class="form-card">
                    <div class="navigation-headers">
                      <ul class="nav nav-pills login">
                        <li class="nav-item"> <a (click)="toggleForm('login')" class="nav-link"
                            [ngClass]="{'active speech-bubble':formType==='login'}">Log In</a> </li>
                        <li class="nav-item"> <a (click)="toggleForm('signup')" class="nav-link"
                            [ngClass]="{'active speech-bubble':formType==='signup'}">Sign Up</a> </li>
                      </ul>
                    </div>
                    <!-- Login form-->
                    <form class="form" [formGroup]="loginForm" *ngIf="formType==='login'" (ngSubmit)="login(loginForm)"
                      novalidate>
                      <div class="form-group margin-top-lg">
                        <mat-label class="required">Email ID</mat-label>
                        <input type="email" [readOnly]="otp.recieved" class="form-control top-10" id="login-emailId" formControlName="emailId" autocomplete="off" placeholder="Email ID"
                          required />

                        <div class="alert alert-danger"
                          *ngIf="(loginForm.controls.emailId.touched || loginForm.controls.emailId.dirty)">
                          <div *ngIf="loginForm.controls.emailId.errors?.required">
                            Email is required
                          </div>
                          <div *ngIf="loginForm.controls.emailId.errors?.pattern">
                            Invalid Email
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <mat-label class="required">Password</mat-label>
                        <div class="input-eyePlaceholder">
                          <input type="password" id="login-password" class="form-control top-10" formControlName="password"
                            autocomplete="off" [readOnly]="otp.recieved" [type]="logHide ? 'password' : 'text'" placeholder="Enter password"  required/>
                            <i [ngClass]="logHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="logpasswordHide()" class="eye-placeholder"></i>
                        </div>
                        <div class="alert alert-danger"
                          *ngIf="!loginForm.controls.password.valid && loginForm.controls.password.touched">
                          Password is required
                        </div>
                      </div>
                      <div *ngIf="otp.recieved" class="form-group">
                        <mat-label class="required">OTP</mat-label>
                        <input type="text" class="form-control top-10"  id="otp" formControlName="otp" autocomplete="off" placeholder="One Time Password"
                          required />

                        <div class="alert alert-danger"
                          *ngIf="(loginForm.controls?.otp.touched || loginForm.controls?.otp.dirty)">
                          <div *ngIf="loginForm.controls?.otp.errors?.required">
                            OTP is required
                          </div>
                        </div>
                      </div>
                      <div class="form-group margin-top-lg">
                        <button *ngIf="!otp.recieved" mat-raised-button id="log-in" color="success" id="login" [disabled]="(!loginForm.controls.emailId.valid && loginForm.controls.emailId.touched) ||
                            (!loginForm.controls.password.valid && loginForm.controls.password.touched)" type="submit"
                          class="width-100 submit-button" [disableRipple]="true"> Log In </button>
                        <button *ngIf="otp.recieved" mat-raised-button id="log-in" color="success" id="login" [disabled]="!(otp.recieved && loginForm.controls.otp.valid)" type="submit"
                        class="width-100 submit-button" (click)="verifyOtp()" [disableRipple]="true"> Continue </button>
                        <div *ngIf="otp.recieved" class="margin-top-lg resend-otp">
                          <span>If OTP is not received click Resend OTP in {{counter}}s</span>
                          <p (click)="resendOtp()" *ngIf="!counter">
                            <mat-label class="resend-otp-btn"> Resend OTP </mat-label>
                          </p>
                        </div>
                      </div>
                      <div *ngIf="!otp.recieved" class="margin-top-lg">
                        <a href="" [routerLink]="['/forgotPassword']">
                          <mat-label class="forgot-password"> Forgot Password? </mat-label>

                        </a>

                      </div>
                      <div *ngIf="!otp.recieved" style="padding-top: 10px;">
                            <mat-label class="secondary-blue-text"> If you are an employee click here <button [routerLink]="['/employeeLogin']"
                                mat-raised-button class="login-linkedIn">Employee Login</button></mat-label>
                      </div>
                    </form>

                    <!-- Signup form -->
                    <form class="form" [formGroup]="signupForm" *ngIf="formType==='signup'"
                      (ngSubmit)="signup(signupForm)" novalidate>
                     
                      <div class="form-group margin-top-lg">
                        <mat-label class="required margin-top-lg">Name</mat-label>
                        <input type="text" id="signup-name" class="form-control top-10" formControlName="fullName" />
                        <div class="alert alert-danger"
                          *ngIf="!signupForm.controls.fullName.valid && signupForm.controls.fullName.touched">
                          Name is required
                        </div>
                      </div>
                      <div class="form-group">
                        <mat-label class="required">Email ID</mat-label>
                        <input type="email" id="signup-emailId" class="form-control top-10" formControlName="emailId" autocomplete="off" required/>
                        <div class="alert alert-danger"
                          *ngIf="(signupForm.controls.emailId.touched  || signupForm.controls.emailId.dirty)">
                          <div *ngIf="signupForm.controls.emailId.errors?.required">
                            Email is required
                          </div>
                          <div *ngIf="signupForm.controls.emailId.errors?.pattern">
                            Invalid Email
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <mat-label class="required">Password</mat-label>
                        <div class="input-eyePlaceholder">
                          <input type="password" id="signup-password" class="form-control top-10" formControlName="password"
                              [type]="signHide ? 'password' : 'text'" (keyup)="checkPasswordStrength()" placeholder="Enter password" placement="right" [ngbTooltip]="passwordrequirements" autocomplete="off" required/>
                              <i [ngClass]=" signHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="signpasswordHide()" class="eye-placeholder"></i>
                        </div>
                        <app-password-strength *ngIf="signupForm.value.password" [passwordToCheck]="signupForm.value.password" [barLabel]="barLabel"></app-password-strength>
                        <ng-template #passwordrequirements>
                          Your Password must include:
                          <ul style="text-align: start;">
                            <li>
                              Atleast 8 characters
                            </li>
                            <li>
                              Atleast 1 special character
                            </li>
                            <li>
                              Atleast 1 uppercase character
                            </li>
                            <li>
                              Atleast 1 number
                            </li>
                          </ul>
                        </ng-template>
                        <span *ngIf="signupForm.value.password && !passwordStrength" class="alert alert-danger">
                          Password doesn't meet minimum requirements !
                        </span>
                        <div class="alert alert-danger"
                          *ngIf="!signupForm.controls.password.valid && signupForm.controls.password.touched">
                          Password is required
                        </div>
                      </div>
                      <div>
                        <mat-checkbox #terms [disableRipple]="true" [(ngModel)]="termsAccepted" [ngModelOptions]="{standalone: true}" (change)="isTermsSelected(terms.checked)"
                          class="select-all-border cursor-hand"></mat-checkbox>
                          <span>I agree to the <span class="cursor-hand" (click)="showServiceTermsPopup()"><u>Terms of
                            Service</u></span> and <span class="cursor-hand" (click)="showPrivacyPolicyPopup()"><u>Privacy Policy</u></span></span>
                      </div>
                      <div class="margin-top-lg btm-20">
                        <button mat-raised-button id="sign-up" color="success" id="signup" type="submit" [disabled]="!passwordStrength || !signupForm.controls.emailId.valid || !signupForm.controls.emailId.touched ||
                !signupForm.controls.password.valid || !signupForm.controls.password.touched || !termsAccepted"
                          [disableRipple]="true" class="width-100 submit-button">Sign Up</button>
                      </div>
                    </form>
                    <!-- <div class="social-login" *ngIf="formType==='login'">
                      <a [href]="googleSignInURL">
                        <a href="https://www.hissa.com/api/google/signin">
                        <button mat-raised-button color="success" class="login-google" id="loginWithGoogle" [disableRipple]="true">
                          <img src="../../../assets/google.png" />
                          <span class="margin-left-xs">Login with Google</span>
                        </button>
                      </a>
                      <a [href]="linkedInSignInURL">
                        <a href="https://www.hissa.com/api/linkedin/signin">
                        <button mat-raised-button color="success" class="login-linkedIn left-10" id="loginWithLinkedIn" [disableRipple]="true">
                          <img src="../../../assets/linkedin-logo.png" class="linkedIn-logo" />
                          <span class="margin-left-xs">Login with LinkedIn</span>
                        </button>
                      </a>
                    </div>
                    <div class="social-login" *ngIf="formType==='signup'">
                      <a [href]="googleSignUpURL">
                        <a href="https://www.hissa.com/api/google/signup">
                        <button mat-raised-button id="signupWithGoogle" color="success" class="login-google" [disableRipple]="true">
                          <img src="../../../assets/google.png" />
                          <span class="margin-left-xs">Signup with Google</span>
                        </button>
                      </a>
                      <a [href]="linkedInSignUpURL">
                        <a href="https://www.hissa.com/api/linkedin/signup">
                        <button mat-raised-button id="signupWithLinkedIn" color="success" class="login-linkedIn left-10" [disableRipple]="true">
                          <img src="../../../assets/linkedin-logo.png" class="linkedIn-logo" />
                          <span class="margin-left-xs">Signup with LinkedIn</span>
                        </button>
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-2 col-lg-10"></div>

    </div>
  </div>
</div>

<!-- popups -->
<app-privacy-policy [(showDialog)]="showPrivacyPolicy" (visibleChange)="getEventpolicy($event)"></app-privacy-policy>
<app-service-terms [(showDialog)]="showTermsOfService" (visibleChange)="getEventservice($event)"></app-service-terms>
<!-- <app-footer-component></app-footer-component> -->
