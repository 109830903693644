import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthenticationService = /** @class */ (function () {
    // baseURL = "https://dev-api.hissa.com/";
    // use --configuration=test/dev/prod for connecting to backend
    function AuthenticationService(http) {
        this.http = http;
        // baseURL = "http://localhost:8080/" //localhost
        this.baseURL = environment.baseURL;
    }
    AuthenticationService.prototype.getEmailTemplate = function () {
        return this.http.get(this.baseURL + "email-template/dropdown");
    };
    AuthenticationService.prototype.viewEmailTemplate = function (fetchEmailTemplate) {
        return this.http.get(this.baseURL + "email-template/fetch?emailTemplateType=" + fetchEmailTemplate);
    };
    AuthenticationService.prototype.uploadEmailTemplate = function (emailTemplateDto) {
        return this.http.post(this.baseURL + "email-template/upload", emailTemplateDto);
    };
    AuthenticationService.prototype.getEmptyCompany = function () {
        return this.http.get(this.baseURL + "company/get/empty");
    };
    AuthenticationService.prototype.getCapTableDetails = function (companyName) {
        return this.http.get(this.baseURL +
            "capitable/search/findByCompanyName?companyName=" +
            companyName);
    };
    AuthenticationService.prototype.search = function (queryString) {
        return this.http.get(this.baseURL + "company_dump/_search?q=" + queryString + "&pretty");
    };
    AuthenticationService.prototype.updateAllDetails = function () {
        this.companyDetails["id"] = null;
        return this.http.post(this.baseURL + "company/create", this.companyDetails);
    };
    AuthenticationService.prototype.updateIncorporationDetails = function (incorpData) {
        return this.http.post(this.baseURL + "round/incorporation", incorpData);
    };
    AuthenticationService.prototype.signUp = function (userDetails) {
        return this.http.post(this.baseURL + "user", userDetails, {
            observe: "response",
        });
    };
    AuthenticationService.prototype.getRegisteredCompanies = function () {
        return this.http.get(this.baseURL + "company/registered/all");
    };
    //Elasticsearch api added
    AuthenticationService.prototype.elasticSearchCompanies = function (companyName) {
        return this.http.get(this.baseURL + "company_dump/_search?q=" + companyName);
    };
    AuthenticationService.prototype.saveAdminProfile = function (adminProfileDetails) {
        return this.http.post(this.baseURL + "user/uploadProfilePic", adminProfileDetails);
    };
    AuthenticationService.prototype.getAdminProfile = function () {
        return this.http.get(this.baseURL + "user/uploadProfile");
    };
    AuthenticationService.prototype.resendVerificationMail = function () {
        return this.http.post(this.baseURL + "user/authenticator/send", {});
    };
    AuthenticationService.prototype.saveCompanyDetails = function (companyDetails) {
        return this.http.post(this.baseURL + "company/create", companyDetails);
    };
    AuthenticationService.prototype.discardNewCompany = function () {
        return this.http.get(this.baseURL + "company/discard/on-boarding");
    };
    AuthenticationService.prototype.getCompanyDetails = function (newCompany) {
        return this.http.get(this.baseURL + "company?isNewRegistration=" + newCompany);
    };
    AuthenticationService.prototype.getDevExSource = function (includeProforma, date) {
        return this.http.get(this.baseURL + 'dashboard/shareholder/get?includePerforma=' + includeProforma + '&date=' + date);
    };
    AuthenticationService.prototype.getSubsidiaryDevExSource = function (includeProforma, date, companyId) {
        return this.http.get(this.baseURL + 'dashboard/shareholder/get?includePerforma=' + includeProforma + '&date=' + date + "&companyId=" + companyId);
    };
    AuthenticationService.prototype.downloadExportFile = function (includeProforma, date) {
        return this.http.get(this.baseURL + "dashboard/shareholder/get?exportDataIntoFile=true&includePerforma=" + includeProforma + "&date=" + date, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.downloadSubsidiaryExportFile = function (includeProforma, date, companyId) {
        return this.http.get(this.baseURL + "dashboard/shareholder/get?exportDataIntoFile=true&includePerforma=" + includeProforma + "&date=" + date + "&companyId=" + companyId, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.exportRoundBasedReport = function (date, roundName) {
        return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?date=" + date + "&basedOnRound=true&roundName=" + roundName, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.exportSubsidiaryRoundBasedReport = function (date, roundName, companyId) {
        return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?date=" + date + "&basedOnRound=true&roundName=" + roundName + "&companyId=" + companyId, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.exportSecurityBasedReport = function (date) {
        return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?basedOnSecurity=true&date=" + date, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.exportSubsidiarySecurityBasedReport = function (date, subsidiaryCompanyId) {
        return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?basedOnSecurity=true&date=" + date + "&companyId=" + subsidiaryCompanyId, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.getSecuritiesDevExSource = function (isOverview) {
        return this.http.get(this.baseURL + 'dashboard/securities/get?isOverview=' + isOverview);
    };
    AuthenticationService.prototype.getRoundDevEx = function (isOverview, includeProforma) {
        return this.http.get(this.baseURL + 'dashboard/round/get?isOverview=' + isOverview + '&&includePerforma=' + includeProforma);
    };
    AuthenticationService.prototype.saveIncorporationDetails = function (incorporationDetails) {
        return this.http.post(this.baseURL + "round/incorporation", incorporationDetails);
    };
    AuthenticationService.prototype.getImageFile = function (fileId) {
        return this.http.get(this.baseURL + "upload/getFile/" + fileId, {
            responseType: "blob",
        });
    };
    AuthenticationService.prototype.getPdfFile = function (fileId) {
        return this.http.get(this.baseURL + "upload/getFile/" + fileId, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.resetPassword = function (userDetails) {
        return this.http.post(this.baseURL + "password/reset", userDetails, {
            observe: "response",
        });
    };
    AuthenticationService.prototype.getUserDetails = function () {
        return this.http.get(this.baseURL + "user");
    };
    AuthenticationService.prototype.login = function (emailId, password) {
        return this.http.post(this.baseURL + "login", { emailId: emailId, password: password }, { observe: "response" });
    };
    AuthenticationService.prototype.sendEmailForgotPassword = function (emailId) {
        return this.http.get(this.baseURL + "password/forgot?emailId=" + emailId);
    };
    AuthenticationService.prototype.logout = function () {
        return this.http.get(this.baseURL + "exit");
    };
    AuthenticationService.prototype.getRoundFields = function (stepName) {
        return this.http.get(this.baseURL +
            "form_field/search/findByFormId_FormName?formName=ROUNDCREATION_" +
            stepName);
    };
    AuthenticationService.prototype.postRoundValues = function (responseJSON) {
        return this.http.post(this.baseURL + "form_field_value", responseJSON);
    };
    AuthenticationService.prototype.getNumOfShares = function (invAmt, preMonVal, primaryAmt) {
        return this.http.get(this.baseURL +
            "calculate_investor_details?investmentAmount=" +
            invAmt +
            "&preMoneyValuation=" +
            preMonVal +
            "&primaryAmount=" +
            primaryAmt);
    };
    AuthenticationService.prototype.completeRound = function (responseJSON) {
        return this.http.post(this.baseURL + "form_field_value?isComplete=true", responseJSON);
    };
    AuthenticationService.prototype.getRoundDetails = function (includeProforma) {
        //(this.baseURL + 'round/search/findByCompany_Id?includePerforma=' + includeProforma
        return this.http.get(this.baseURL +
            "round/search/findByCompany_Id?includePerforma=" +
            includeProforma +
            "&roundFetchType=All");
    };
    AuthenticationService.prototype.getRoundOverviewChart = function () {
        return this.http.get(this.baseURL + "round/holding?includePerforma=true");
    };
    AuthenticationService.prototype.getExistingCapTable = function (includeProforma) {
        return this.http.get(this.baseURL + "captable/get?includePerforma=" + includeProforma);
    };
    AuthenticationService.prototype.getDashboardCards = function (includeProforma) {
        return this.http.get(this.baseURL + "dashboard/cards?includePerforma=" + includeProforma);
    };
    AuthenticationService.prototype.getCaptableCards = function (includeProforma) {
        return this.http.get(this.baseURL + "dashboard/captableCards?includePerforma=" + includeProforma);
    };
    AuthenticationService.prototype.getDashboardNotes = function () {
        return this.http.get(this.baseURL + "dashboard/notes");
    };
    AuthenticationService.prototype.getRoundChart = function (includeProforma) {
        return this.http.get(this.baseURL +
            "dashboard/roundgraph?includePerforma=" +
            includeProforma +
            "&roundFetchType=all");
    };
    AuthenticationService.prototype.getInstrumentTypeChart = function (includeProforma) {
        return this.http.get(this.baseURL +
            "dashboard/instrumentgraph?includePerforma=" +
            includeProforma);
    };
    AuthenticationService.prototype.getDashboardTable = function (includeProforma) {
        return this.http.get(this.baseURL + "dashboard/capTable?includePerforma=" + includeProforma);
    };
    /* Round Modelling API's */
    // get source captables
    AuthenticationService.prototype.getSourceCapTables = function () {
        return this.http.get(this.baseURL + "round_modeling/source_cap_table");
    };
    //getRoundModelling details by model & round
    AuthenticationService.prototype.getRoundModelInfo = function (model, round) {
        return this.http.get(this.baseURL +
            "round_modeling?capTableName=" +
            model +
            "&roundName=" +
            round);
    };
    AuthenticationService.prototype.getRoundModellingDet = function (includeProforma) {
        return this.http.get(this.baseURL + "round_modeling?templateName=template1");
    };
    AuthenticationService.prototype.getRoundModellingConvertibles = function (initialConfig, mdlName, rndName) {
        return this.http.post(this.baseURL +
            "round_modeling/tables?capTableName=" +
            mdlName +
            "&roundName=" +
            rndName, initialConfig);
    };
    // updateNewConvertibles(newConvertibles) {
    //   return this.http.post(
    //     this.baseURL + "round_modeling/convertible?templateName=template1",
    //     newConvertibles
    //   );
    // }
    AuthenticationService.prototype.updateNewConvertibles = function (newConvertibles, rndName, capTblNme) {
        return this.http.post(this.baseURL +
            "round_modeling/convertible?roundName=" +
            rndName +
            "&capTableName=" +
            capTblNme, newConvertibles);
    };
    // getExistingHolderInvAmt(investorObj) {
    //   return this.http.post(
    //     this.baseURL + "round_modeling/investorDetails?templateName=template1",
    //     investorObj
    //   );
    // }
    AuthenticationService.prototype.getExistingHolderInvAmt = function (investorObj, roundName, capTblNme) {
        return this.http.post(this.baseURL +
            "round_modeling/investorDetails?roundName=" +
            roundName +
            "&capTableName=" +
            capTblNme, investorObj);
    };
    AuthenticationService.prototype.getUpdatedModellingTable = function (investorDetails, roundName, capTblNme) {
        return this.http.post(this.baseURL +
            "round_modeling/captable?roundName=" +
            roundName +
            "&capTableName=" +
            capTblNme, investorDetails);
    };
    AuthenticationService.prototype.getRightToMaintainTable = function (investorDetails, rndName, capTblNme) {
        return this.http.post(this.baseURL +
            "round_modeling/right_to_maintain?roundName=" +
            rndName +
            "&capTableName=" +
            capTblNme, investorDetails);
    };
    AuthenticationService.prototype.clearRoundModelling = function () {
        return this.http.delete(this.baseURL + "round_modeling/clear?templateName=template1");
    };
    // Save Model names
    AuthenticationService.prototype.createNewModel = function (modelInfo) {
        return this.http.post(this.baseURL + "round_modeling/model_name", modelInfo);
    };
    //Delete whole model
    AuthenticationService.prototype.deleteModel = function (modelName) {
        return this.http.delete(this.baseURL + "round_modeling/clear/model?modelId=" + modelName);
    };
    //Create Round -> Convert Model to Round
    AuthenticationService.prototype.createRound = function (rndName, mdlName) {
        return this.http.get(this.baseURL +
            "round_modeling/convert_to_round?roundName=" +
            rndName +
            "&capTableName=" +
            mdlName);
    };
    ///Modelling ends////
    /** Round Modeling new api's **/
    AuthenticationService.prototype.getAllModels = function () {
        return this.http.get(this.baseURL + "round_modeling/model_name/?isOnLand=true");
    };
    AuthenticationService.prototype.getAllModelsTmp = function () {
        return this.http.get(this.baseURL + "round_modeling/model_name/working_copy");
    };
    AuthenticationService.prototype.getAllRoundsByModel = function (modelId) {
        return this.http.get(this.baseURL + "round_modeling/round_details?modelId=" + modelId);
    };
    AuthenticationService.prototype.getAllTmpRoundsByModel = function (modelId) {
        return this.http.get(this.baseURL +
            "round_modeling/round_details/working_copy?modelId=" +
            modelId);
    };
    AuthenticationService.prototype.saveRoundName = function (modelId, roundInfo) {
        return this.http.post(this.baseURL + "round_modeling/round_name?modelId=" + modelId, roundInfo);
    };
    AuthenticationService.prototype.getModelDetailsForRound = function (roundId) {
        return this.http.get(this.baseURL + "round_modeling?roundId=" + roundId);
    };
    AuthenticationService.prototype.saveRoundInformation = function (roundInfo, roundId) {
        roundId;
        return this.http.post(this.baseURL + "round_modeling/tables?roundId=" + roundId, roundInfo);
    };
    AuthenticationService.prototype.saveOutStandingConvertibles = function (newConvertibles, roundId) {
        return this.http.post(this.baseURL + "round_modeling/convertible?roundId=" + roundId, newConvertibles);
    };
    AuthenticationService.prototype.getExistingShareHolderInvAmt = function (investorObj, roundId) {
        return this.http.post(this.baseURL + "round_modeling/investorDetails?roundId=" + roundId, investorObj);
    };
    AuthenticationService.prototype.saveRightToMaintainTable = function (investorDetails, roundId) {
        return this.http.post(this.baseURL + "round_modeling/right_to_maintain?roundId=" + roundId, investorDetails);
    };
    //Round Modelling - Refresh Right to Maintain data
    AuthenticationService.prototype.refreshRightToMaintain = function (roundId) {
        return this.http.get(this.baseURL +
            "round_modeling/right_to_maintain/refresh?roundId=" +
            roundId);
    };
    //Round Modelling - Refresh New Investor
    AuthenticationService.prototype.refreshNewInvestorData = function (roundId) {
        return this.http.get(this.baseURL + "round_modeling/new_investors/refresh?roundId=" + roundId);
    };
    //Save Round Without information
    AuthenticationService.prototype.saveRound = function (roundId) {
        return this.http.post(this.baseURL + "round_modeling/round/save?roundId=" + roundId, "");
    };
    //Save Round specific Round All Information
    AuthenticationService.prototype.saveModellingTable = function (investorDetails, roundId) {
        return this.http.post(this.baseURL + "round_modeling/new_investors?roundId=" + roundId, investorDetails);
    };
    //Save Model
    AuthenticationService.prototype.saveModelInformation = function (modelId, isErrRnd) {
        return this.http.post(this.baseURL +
            "round_modeling/model/save?modelId=" +
            modelId +
            "&canSkipInvalidCheck=" +
            isErrRnd, "");
    };
    AuthenticationService.prototype.convertToRound = function (roundId) {
        return this.http.get(this.baseURL + "round_modeling/convert_to_round?roundId=" + roundId);
    };
    //Round Modelling - delete round
    AuthenticationService.prototype.deleteRound = function (modelName, rndName) {
        return this.http.delete(this.baseURL +
            "round_modeling/clear?roundName=" +
            rndName +
            "&capTableName=" +
            modelName);
    };
    AuthenticationService.prototype.deleteRounds = function (roundId) {
        return this.http.delete(this.baseURL + "round_modeling/clear?roundId=" + roundId);
    };
    //Round Modelling - Get Min and Max values
    AuthenticationService.prototype.getMinMaxValues = function (value) {
        return this.http.get(this.baseURL + "round_modeling/min_max_value?currentValue=" + value);
    };
    //Round Modelling - Update Round Name
    AuthenticationService.prototype.updateRoundName = function (roundInfo, modelId) {
        return this.http.post(this.baseURL + "round_modeling/round_name?modelId=" + modelId, roundInfo);
    };
    /** Round Modeling new api's **/
    /** Round Modeling new api's **/
    //Round Modelling - Update Round Name
    AuthenticationService.prototype.updateRound = function (rndName, description, id) {
        return this.http.put(this.baseURL +
            "round_modeling/round_name?id=" +
            id +
            "&roundName=" +
            rndName +
            "&description=" +
            description, "");
    };
    AuthenticationService.prototype.getDashboardOverview = function (includeProforma) {
        return this.http.get(this.baseURL +
            "dashboard/shareholder/group?includePerforma=" +
            includeProforma);
    };
    AuthenticationService.prototype.getShareholdersOverview = function (includeProforma) {
        return this.http.get(this.baseURL + "dashboard/shareholder?includePerforma=" + includeProforma);
    };
    /** ESOP MODELING */
    AuthenticationService.prototype.createNewEsopModel = function (modelInfo) {
        return this.http.post(this.baseURL + "esop_model", modelInfo);
    };
    AuthenticationService.prototype.getAllEsopModels = function () {
        return this.http.get(this.baseURL + "esop_model");
    };
    AuthenticationService.prototype.getSelectedEsopModel = function (modelId) {
        return this.http.get(this.baseURL + "esop_model/" + modelId);
    };
    AuthenticationService.prototype.getWorkingCopyEsopModel = function () {
        return this.http.get(this.baseURL + "esop_model/working_copy");
    };
    //Delete whole model
    AuthenticationService.prototype.deleteEsopModel = function (modelId) {
        return this.http.delete(this.baseURL + "esop_model?id=" + modelId);
    };
    AuthenticationService.prototype.getSalaryTemplateAndHiringPlanLists = function () {
        return this.http.get(this.baseURL + "esop_model/dropdown");
    };
    //deprecated
    AuthenticationService.prototype.getGradeDetailsForSalaryTemplate = function (templateId) {
        return this.http.get(this.baseURL + "esop_model/get_grade_list?id=" + templateId);
    };
    AuthenticationService.prototype.getSelectedSalaryTemplateDetails = function (templateId) {
        return this.http.get(this.baseURL + "esop_model/salary_template/" + templateId);
    };
    AuthenticationService.prototype.getSelectedHiringPlanDetails = function (planId) {
        return this.http.get(this.baseURL + "esop_model/hiring_plan/" + planId);
    };
    AuthenticationService.prototype.getHiringPlanDetailsWithPeriod = function (planId) {
        return this.http.get(this.baseURL + "esop_model/get_hire_plan_Details/actual?id=" + planId);
    };
    AuthenticationService.prototype.createOrEditSalaryTemplateNameAndDesc = function (templateInfo, isEdit) {
        return this.http.post(this.baseURL + "esop_model/salary_template/create?isNameEdit=" + isEdit, templateInfo);
    };
    AuthenticationService.prototype.postSalaryTemplateGrades = function (gradeDetails, isInvalidEdit) {
        return this.http.post(this.baseURL + "esop_model/salary_template/grade/add?isInvalidEdit=" + isInvalidEdit + "&isGradeEdit=false", gradeDetails);
    };
    //createSalaryTemplate is Deprecated
    AuthenticationService.prototype.createSalaryTemplate = function (template) {
        return this.http.post(this.baseURL + "esop_model/salary_template", template);
    };
    //editSalaryTemplate is Deprecated
    AuthenticationService.prototype.editSalaryTemplate = function (template, isInvalid) {
        return this.http.post(this.baseURL + "esop_model/salary_template?isInvalidEdit=" + isInvalid, template);
    };
    //deprecated
    AuthenticationService.prototype.createHiringPlan = function (plan) {
        return this.http.post(this.baseURL + "esop_model/hiring_plan", plan);
    };
    AuthenticationService.prototype.createOrEditHiringPlanNameAndDesc = function (planInfo, isEdit) {
        return this.http.post(this.baseURL + "esop_model/hiring_plan/create?isNameEdit=" + isEdit, planInfo);
    };
    //deprecated
    AuthenticationService.prototype.editHiringPlan = function (plan, isInvalid) {
        return this.http.post(this.baseURL + "esop_model/hiring_plan?isInvalidEdit=" + isInvalid, plan);
    };
    AuthenticationService.prototype.postHiringPlanGrades = function (gradeDetails, isInvalidEdit) {
        return this.http.post(this.baseURL + "esop_model/hiring_plan/grade/add?isInvalidEdit=" + isInvalidEdit + "&isGradeEdit=false", gradeDetails);
    };
    AuthenticationService.prototype.postSharePriceMultipliers = function (sharePriceMultipliersDetails, isInvalidEdit) {
        return this.http.post(this.baseURL + ("esop_model/" + sharePriceMultipliersDetails.modelId + "/sharePriceMultipliers"), sharePriceMultipliersDetails);
    };
    AuthenticationService.prototype.postExistingEmployeesDetails = function (modelId, existingEmployeesDetails) {
        return this.http.post(this.baseURL + ("esop_model/" + modelId + "/existingEmployeesDetail"), existingEmployeesDetails);
    };
    AuthenticationService.prototype.postAdditionalCompensations = function (modelId, additionalCompensations) {
        return this.http.post(this.baseURL + ("esop_model/" + modelId + "/additionalCompensations"), additionalCompensations);
    };
    AuthenticationService.prototype.getExistingEmployeesDetailsFromRecords = function (modelId, salaryTemplateId) {
        return this.http.get(this.baseURL + ("esop_model/" + modelId + "/salaryTemplate/" + salaryTemplateId + "/existingEmployeesDetail"));
    };
    AuthenticationService.prototype.getCurrentSharePriceAndValuation = function () {
        return this.http.get(this.baseURL + "esop_model/currentSharePriceAndValuation");
    };
    AuthenticationService.prototype.summaryForEsopModeling = function (data) {
        return this.http.post(this.baseURL + "esop_model/summary", data);
    };
    AuthenticationService.prototype.saveEsopModel = function (data) {
        return this.http.post(this.baseURL + "esop_model/save", data);
    };
    AuthenticationService.prototype.discardEsopModel = function (modelId) {
        return this.http.delete(this.baseURL + "esop_model/discard?id=" + modelId);
    };
    /** Securities starts **/
    AuthenticationService.prototype.getSecuritiesOverview = function (includeProforma) {
        return this.http.get(this.baseURL + "dashboard/secuirities?includePerforma=" + includeProforma);
    };
    AuthenticationService.prototype.getAddNewInstrumentClass = function () {
        return this.http.get(this.baseURL + "instrument_class");
    };
    // ** Get Instrument class details **//
    AuthenticationService.prototype.getInstrumentClassData = function () {
        return this.http.get(this.baseURL + "dashboard/securities/instrument");
    };
    // ** Create new instrument class **// -- Old save method API call
    // addNewInstrumentClass(newInstclsData) {
    //     return this.http.post(this.baseURL + 'instrument_class', newInstclsData);
    // }
    // ** Get selected Instrument class information **//
    AuthenticationService.prototype.getInstrumentClassDataByName = function (instClsnme) {
        return this.http.get(this.baseURL + "instrument_class/name?instrumentClassName=" + instClsnme);
    };
    /** Get Instrument Types **/
    AuthenticationService.prototype.getInstrumentTypes = function () {
        return this.http.get(this.baseURL + "instrument_class/dropdown");
    };
    /** Get Instrument template by instrument type **/
    AuthenticationService.prototype.getInstTemplateByType = function (type) {
        return this.http.post(this.baseURL + "instrument_class?instrument=" + type, "");
    };
    /** Get Security template by security type **/
    AuthenticationService.prototype.getSecurityTemplateByType = function (body) {
        return this.http.post(this.baseURL + "instrument_class/mutliple_securities", body);
    };
    /** Get saved Instrument Template values by type and template**/
    AuthenticationService.prototype.getInstTemplateInfo = function (type, template) {
        return this.http.get(this.baseURL +
            "instrument_class?instrument=" +
            type +
            "&templateId=" +
            template);
    };
    /** Save instrument class by type **/
    AuthenticationService.prototype.savenewInstrumentClass = function (instClass) {
        return this.http.post(this.baseURL + "round/instrument/save?templateId=" + instClass.templateId + "&instrumentClassName=" + instClass.instrumentClassName, {});
    };
    AuthenticationService.prototype.addnewInstrumentClass = function (instClass) {
        return this.http.post(this.baseURL + "instrument_class/save", instClass);
    };
    /** Delete Instrument class**/
    AuthenticationService.prototype.deleteInstrumentClass = function (id) {
        return this.http.delete(this.baseURL + "instrument_class/delete?id=" + id);
    };
    AuthenticationService.prototype.getSecurityDetails = function (id, profileId) {
        return this.http.get(this.baseURL + "security/conversion/get?SecurityClassId=" + id + "&ShareholderProfileId=" + profileId);
    };
    AuthenticationService.prototype.getSecuritiesList = function (type, classId) {
        return this.http.get(this.baseURL + "security/conversion/securityClass/list?Security=" + type + "&SecurityClassId=" + classId);
    };
    AuthenticationService.prototype.calculateDiscountParameters = function (conversionDetails) {
        return this.http.post(this.baseURL + "security/conversion/discount", conversionDetails);
    };
    AuthenticationService.prototype.discardConversion = function () {
        return this.http.delete(this.baseURL + "security/conversion/discard");
    };
    AuthenticationService.prototype.getPreviewForSecurity = function (conversionDetails) {
        return this.http.post(this.baseURL + "security/conversion/captable/preview", conversionDetails);
    };
    AuthenticationService.prototype.approveSecurityCapTable = function (capTable) {
        return this.http.post(this.baseURL + "security/conversion/captable/approve", capTable);
    };
    /* Redemption */
    AuthenticationService.prototype.getRedemptionDetails = function (securityId, profileId) {
        return this.http.get(this.baseURL + "security/redemption?SecurityClassId=" + securityId + "&ShareholderProfileId=" + profileId);
    };
    AuthenticationService.prototype.saveRedemptionForLater = function (redemptionDetails) {
        return this.http.post(this.baseURL + "security/redemption", redemptionDetails);
    };
    AuthenticationService.prototype.discardRedemption = function (securityId) {
        return this.http.delete(this.baseURL + "security/redemption?SecurityClassId=", securityId);
    };
    AuthenticationService.prototype.approveRedemption = function (redemptionDetails) {
        return this.http.post(this.baseURL + "security/redemption/approve", redemptionDetails);
    };
    /** Securities ends **/
    AuthenticationService.prototype.getShareholderDetails = function (shareholderID) {
        return this.http.get(this.baseURL + "dashboard/shareholder/" + shareholderID);
    };
    AuthenticationService.prototype.generateShareholderCredentials = function (obj) {
        return this.http.post(this.baseURL + "dashboard/shareholder/generate/credentials", obj);
    };
    AuthenticationService.prototype.sendShareCertificate = function (obj) {
        return this.http.post(this.baseURL + "share-certificate/sendShareCertificateAsMail", obj);
    };
    AuthenticationService.prototype.uploadShareCertificate = function (file, investmentRecordId) {
        return this.http.post(this.baseURL + "share-certificate/uploadShareCertificate?investorId=" + investmentRecordId, file);
    };
    // * Get Round Details By name* //
    AuthenticationService.prototype.getRoundDataByName = function (roundName) {
        // return this.http.get(this.baseURL + 'round/summary?roundName=' + roundName );
        return this.http.get(this.baseURL +
            "round/summary?roundName=" +
            roundName +
            "&roundFetchType=All");
    };
    AuthenticationService.prototype.getRoundDetailsByName = function (roundName) {
        return this.http.get(this.baseURL +
            "round/details?roundName=" +
            roundName +
            "&roundFetchType=All");
    };
    AuthenticationService.prototype.saveCurrencyConverted = function (dataObj) {
        return this.http.post(this.baseURL + "round/investors/update_currency_details", dataObj);
    };
    // ** Create new Round Calls**//
    // Round tab - Basic Details
    AuthenticationService.prototype.getRoundHistoryData = function () {
        return this.http.get(this.baseURL + "round/card");
    };
    // Round tab - Basic Details
    AuthenticationService.prototype.getBasicRoundData = function () {
        return this.http.get(this.baseURL + "round/basicdetails");
    };
    //Current Investor - get Basic details
    AuthenticationService.prototype.getBasicCurrentInvestorData = function () {
        return this.http.get(this.baseURL + "round/currentInvestor/tab");
    };
    // Round Tab - Save Post call
    AuthenticationService.prototype.postRoundData = function (roundInfo) {
        return this.http.post(this.baseURL + "round/convertible/update", roundInfo);
    };
    // Current Investor Tab - Outstanding Convertible save
    AuthenticationService.prototype.saveConvertible = function (outStndnConvertible) {
        return this.http.post(this.baseURL + "round/convertible/add_upload", outStndnConvertible);
    };
    AuthenticationService.prototype.getValuationDetails = function (securityClassId) {
        return this.http.get(this.baseURL + "round/convertible/valuation?securityclassid=" + securityClassId);
    };
    AuthenticationService.prototype.calculateDetails = function (discountObj) {
        return this.http.post(this.baseURL + "round/convertible/discount", discountObj);
    };
    AuthenticationService.prototype.saveValuation = function (valuationObj) {
        return this.http.post(this.baseURL + "round/convertible/discount/save", valuationObj);
    };
    // get existing share holders investment amount - create new round
    AuthenticationService.prototype.getExistingHolderInvAmtRound = function (investorObj) {
        return this.http.post(this.baseURL + "round/investordetails", investorObj);
    };
    //Saving Right to Maintain investors - existing share holder - Current Investor tab
    AuthenticationService.prototype.saveExistingShareHolderInfo = function (exstngShrHldrInfo) {
        return this.http.post(this.baseURL + "round/investor/save?isRightToMaintain=true", exstngShrHldrInfo);
    };
    //   New investors tab
    AuthenticationService.prototype.getRoundNewInvestorDet = function (includeProforma) {
        return this.http.get(this.baseURL + "round/investordetails");
    };
    // Get Shareholders for new investors tab
    AuthenticationService.prototype.getShareHoldersNewInvestor = function () {
        return this.http.get(this.baseURL + "round/newinvestor/tab");
    };
    // get profile shareholder type and group for drop down
    AuthenticationService.prototype.getNwInstrShrHldrGroupInfo = function () {
        return this.http.get(this.baseURL + "round/investor/dropdown");
    };
    AuthenticationService.prototype.getBuyerDropdown = function () {
        return this.http.get(this.baseURL + "secondary_transaction/buyer/dropdown");
    };
    AuthenticationService.prototype.saveNewShareHolderInfo = function (newShrHldrInfo) {
        return this.http.post(this.baseURL + "round/investor/save_upload?isRightToMaintain=false", newShrHldrInfo);
    };
    // Create New Group
    AuthenticationService.prototype.saveNewGroup = function (groupInfo) {
        return this.http.post(this.baseURL + "share_holder_group", groupInfo);
    };
    //** Instrument Tab Section API Calls **/
    //Get Instrument Type List
    AuthenticationService.prototype.getInstrumentTypeList = function () {
        return this.http.get(this.baseURL + "round/instrument_type/tab");
    };
    // Get Investors and Instrument class by Instrument
    AuthenticationService.prototype.getInvInstClsByInst = function (instrument) {
        return this.http.post(this.baseURL + "round/instrument_type/tab", instrument);
    };
    AuthenticationService.prototype.getInvestmentPopup = function (popupObj) {
        return this.http.post(this.baseURL + 'round/investment_amount/popup', popupObj);
    };
    AuthenticationService.prototype.getUpdatedInvestmentAmount = function (invObj) {
        return this.http.post(this.baseURL + 'round/investment_amount/update', invObj);
    };
    AuthenticationService.prototype.saveDebPopup = function (debObj) {
        return this.http.post(this.baseURL + 'round/investment_amount/popup/save', debObj);
    };
    // Assign instrument class to investor
    AuthenticationService.prototype.saveInstClsToInvestor = function (investorList) {
        return this.http.post(this.baseURL + "round/instrument_type/assign", investorList);
    };
    //** Tranches Tab Section **/
    AuthenticationService.prototype.getAllTranches = function () {
        return this.http.get(this.baseURL + "round/tranches");
    };
    //Tranches - Get CapTable data by tranches
    AuthenticationService.prototype.getCapTableByTranches = function (tranches) {
        return this.http.post(this.baseURL + "round/tranches?isValuationChanged=false", tranches);
    };
    //Save Tranches
    AuthenticationService.prototype.saveTranchesInfo = function (tranches) {
        return this.http.post(this.baseURL + "round/tranches/save", tranches);
    };
    // Create new round - Round summary
    AuthenticationService.prototype.getRoundSummary = function (roundName) {
        return this.http.get(this.baseURL +
            "round/summary?roundName=" +
            roundName +
            "&roundFetchType=ALL");
    };
    //Final Round Creation
    AuthenticationService.prototype.CreateNewRound = function (notes) {
        return this.http.post(this.baseURL + "round/submit?notes=" + notes, "");
    };
    AuthenticationService.prototype.addNewEmployee = function (emp) {
        return this.http.post(this.baseURL + "employee/upload", emp);
    };
    AuthenticationService.prototype.getEsopMgmtDefault = function (planName) {
        return this.http.get(this.baseURL + "esop/management/?esopPlan=" + planName);
    };
    AuthenticationService.prototype.getEsopMgmtDropdown = function () {
        return this.http.get(this.baseURL + "esop/management/dropdown");
    };
    AuthenticationService.prototype.getEsopMgmtTableData = function () {
        return this.http.get(this.baseURL + "esop/table/dropdown");
    };
    AuthenticationService.prototype.getVestingTemplates = function () {
        return this.http.get(this.baseURL + "vesting/template/dropdown");
    };
    AuthenticationService.prototype.getVestingTempDispDetails = function (templateId) {
        return this.http.get(this.baseURL + "vesting/display_details?templateId=" + templateId);
    };
    AuthenticationService.prototype.getDataForCreateESOP = function () {
        return this.http.get(this.baseURL + "esop/names/dropdown");
    };
    AuthenticationService.prototype.getGraphDtlsForOptionsAndHolders = function () {
        return this.http.get(this.baseURL + "esop/details");
    };
    AuthenticationService.prototype.getEsopGraphDetailsForPlans = function (id) {
        return this.http.get(this.baseURL + "esop/details?esopPlan=" + id);
    };
    AuthenticationService.prototype.getNextStatusForGrant = function (optionHolderId) {
        return this.http.get(this.baseURL + "employee/grantstate?optionHolderId=" + optionHolderId);
    };
    AuthenticationService.prototype.updateGrantStatus = function (grantStatusObj) {
        return this.http.post(this.baseURL + 'employee/grantstate', grantStatusObj);
    };
    AuthenticationService.prototype.updateBulkGrantStatus = function (grantStatusObj) {
        return this.http.post(this.baseURL + 'employee/bulkgrantstate', grantStatusObj);
    };
    AuthenticationService.prototype.createEsopGrantsInBulk = function (file) {
        return this.http.post(this.baseURL + "usingexcelfile/createOptionHolder/", file, {});
    };
    // getVestingPeriod() {
    //   return this.http.get(this.baseURL + "vesting/interval/dropdown");
    // }
    AuthenticationService.prototype.getDefaultVestingTemplates = function () {
        return this.http.get(this.baseURL + 'vesting/template/import/dropdown');
    };
    AuthenticationService.prototype.getDefaultTemplateDetails = function (templateObj) {
        return this.http.post(this.baseURL + 'vesting/template/import/details', templateObj);
    };
    AuthenticationService.prototype.saveNewTemplate = function (temp) {
        return this.http.post(this.baseURL + "vesting/template", temp);
    };
    AuthenticationService.prototype.getVestingTemplateDetails = function (val) {
        return this.http.get(this.baseURL + "vesting/template?templateId=" + val);
    };
    AuthenticationService.prototype.getVestingGraphData = function (template) {
        return this.http.post(this.baseURL + "vesting/graph", template);
    };
    AuthenticationService.prototype.getPoolDetails = function () {
        return this.http.get(this.baseURL + "esop/get_current_pool_size");
    };
    AuthenticationService.prototype.getNextStatusForPlan = function (planId) {
        return this.http.get(this.baseURL + "esop/planstate?id=" + planId);
    };
    AuthenticationService.prototype.updatePlanStatus = function (planStatusObj) {
        return this.http.post(this.baseURL + 'esop/planstate', planStatusObj);
    };
    AuthenticationService.prototype.editPool = function (poolObj) {
        return this.http.post(this.baseURL + 'esop/add_or_edit_pool_size', poolObj);
    };
    AuthenticationService.prototype.esopPoolTablePreview = function (previewObj) {
        return this.http.post(this.baseURL + 'esop/get_current_pool_size/preview', previewObj);
    };
    AuthenticationService.prototype.saveEsopPlan = function (plan) {
        return this.http.post(this.baseURL + "esop/create", plan);
    };
    AuthenticationService.prototype.editESOPPlan = function (plan) {
        return this.http.put(this.baseURL + "esop/edit", plan);
    };
    AuthenticationService.prototype.addEmployeeToPlan = function (body) {
        return this.http.post(this.baseURL + "employee/add", body);
    };
    AuthenticationService.prototype.getDashboardDate = function () {
        return this.http.get(this.baseURL + "dashboard/esop");
    };
    // All employees with all details
    AuthenticationService.prototype.getAllEmployees = function () {
        return this.http.get(this.baseURL + "employee/get/all");
    };
    AuthenticationService.prototype.getEmployeeList = function () {
        return this.http.get(this.baseURL + "employee/dropdown");
    };
    AuthenticationService.prototype.getEmployeeVestingTable = function (empId) {
        return this.http.get(this.baseURL + 'employee/employee_page/vestingTable?EmployeeID=' + empId);
    };
    AuthenticationService.prototype.getEmployeeDetails = function (empId) {
        return this.http.get(this.baseURL + 'employee/employee_page?EmployeeID=' + empId);
    };
    AuthenticationService.prototype.getCredentials = function (empId) {
        return this.http.get(this.baseURL + 'employee/login/details?id=' + empId, {
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.getEmployeeTemplate = function () {
        return this.http.get(this.baseURL + 'employee/template');
    };
    AuthenticationService.prototype.getGrantTemplate = function () {
        return this.http.get(this.baseURL + 'esop/template');
    };
    AuthenticationService.prototype.getResignationDetails = function (empId, status) {
        return this.http.get(this.baseURL + 'employee/employee_page/resignation?EmployeeID=' + empId + '&EmploymentStatus=' + status);
    };
    AuthenticationService.prototype.getUpdatedResignationDetails = function (updatedObj) {
        return this.http.post(this.baseURL + 'employee/employee_page/resignation/lastday', updatedObj);
    };
    AuthenticationService.prototype.saveResignationDetails = function (resignationObj) {
        return this.http.post(this.baseURL + 'employee/employee_page/resignation', resignationObj);
    };
    AuthenticationService.prototype.getEmpPopupDetails = function (employeeId) {
        return this.http.get(this.baseURL + 'employee/employee_page/details?EmployeeID=' + employeeId);
    };
    AuthenticationService.prototype.saveEmpEditDetails = function (editEmpDetails, empId) {
        return this.http.post(this.baseURL + 'employee/employee_page/details?EmployeeID=' + empId, editEmpDetails);
    };
    AuthenticationService.prototype.saveEmpNominees = function (nomineeList) {
        return this.http.post(this.baseURL + 'employee/update/nominees', nomineeList);
    };
    AuthenticationService.prototype.getEmployeeNominees = function (employeeId) {
        console.log(employeeId);
        return this.http.get(this.baseURL + 'employee/nominees?employeeId=' + employeeId);
    };
    AuthenticationService.prototype.EditEmployeePlan = function (body) {
        return this.http.put(this.baseURL + "employee/add", body);
    };
    AuthenticationService.prototype.deleteEmployeePlan = function (body) {
        return this.http.delete(this.baseURL + "employee/delete/optionholder?id=" + body);
    };
    AuthenticationService.prototype.deleteESOPPlan = function (ids) {
        return this.http.delete(this.baseURL + "esop/delete?id=" + ids);
    };
    AuthenticationService.prototype.doExercise = function (body, isApprover) {
        return this.http.post(this.baseURL + ("esop/exercise" + (isApprover ? "/approve" : "")), body);
    };
    AuthenticationService.prototype.doVesting = function (body, isApprover) {
        return this.http.post(this.baseURL + ("employee/vest" + (isApprover ? "/approve" : "")), body);
    };
    //Get exercise details for optionHolder/Approver
    AuthenticationService.prototype.getExerciseDetails = function (id, isApprover) {
        return this.http.get(this.baseURL + ("esop/exercise/" + (isApprover ? "approve" : "holder") + "?id=") + id);
    };
    //Get details for Employee Vesting (Event)
    AuthenticationService.prototype.getVestingDetails = function (id, isApprover) {
        return this.http.get(this.baseURL + ("employee/vest/" + (isApprover ? "approve" : "") + "?id=") + id);
    };
    //Get numberOfSharesToVest for Option holder based on percentage
    AuthenticationService.prototype.getNoOfSharestoVest = function (id, percentage, evName) {
        return this.http.get(this.baseURL +
            "employee/vest/count?id=" +
            id +
            "&percentage=" +
            percentage +
            "&eventName=" + evName);
    };
    //Get holders and options count when apply for all holders is true
    AuthenticationService.prototype.getOptionsAllHolders = function (id, percentage) {
        return this.http.get(this.baseURL +
            "employee/vest/holder?id=" +
            id +
            "&percentage=" +
            percentage);
    };
    AuthenticationService.prototype.getSecondaryMgmtDetails = function () {
        return this.http.get(this.baseURL + "secondary_transaction/shareholder_names");
    };
    AuthenticationService.prototype.getTotalShareDistinctiveNumbers = function (sellerObj) {
        return this.http.post(this.baseURL + "secondary_transaction/available/share_distinctive_number", sellerObj);
    };
    AuthenticationService.prototype.getFinalDistNumbers = function (distNumbers) {
        return this.http.post(this.baseURL + "secondary_transaction/get/share_distinctive_number", distNumbers);
    };
    AuthenticationService.prototype.getRangeForShares = function (body) {
        return this.http.post(this.baseURL + "secondary_transaction/share_distinctive_number", body);
    };
    /* Secondary Dashboard */
    AuthenticationService.prototype.getSecondaryOverview = function () {
        return this.http.get(this.baseURL + "secondary_transaction/dashboard");
    };
    /* Splits Dashboard */
    AuthenticationService.prototype.getSplitsOverview = function () {
        return this.http.get(this.baseURL + "share_split/dashboard");
    };
    AuthenticationService.prototype.postNewSplitForPreview = function (newSplit) {
        return this.http.post(this.baseURL + "share_split/preview", newSplit);
    };
    AuthenticationService.prototype.getSplitSummary = function (splitId) {
        return this.http.get(this.baseURL + "share_split/summary?splitId=" + splitId);
    };
    AuthenticationService.prototype.getProformaSplitsDetails = function () {
        return this.http.get(this.baseURL + "share_split/details");
    };
    AuthenticationService.prototype.discardSplitDetails = function () {
        return this.http.delete(this.baseURL + "share_split/discard");
    };
    AuthenticationService.prototype.approveSplitDetails = function () {
        return this.http.get(this.baseURL + "share_split/approve");
    };
    /* Save Seller Information*/
    AuthenticationService.prototype.saveSellerInformation = function (sellerInfo) {
        return this.http.post(this.baseURL + "secondary_transaction/buyer_tab", sellerInfo);
    };
    /* Save Buyer Information*/
    AuthenticationService.prototype.saveBuyerInformation = function (buyerInfo) {
        return this.http.post(this.baseURL + "secondary_transaction/buyer_tab/save", buyerInfo);
    };
    AuthenticationService.prototype.createSell = function (body) {
        return this.http.post(this.baseURL + "secondary_transaction/sale", body);
    };
    /* Secondary - get Summary details by Seller and Buyer */
    AuthenticationService.prototype.getSecondarySummary = function (seller, buyer) {
        return this.http.get(this.baseURL +
            "secondary_transaction/summary?sellerName=" +
            seller +
            "&buyerName=" +
            buyer);
    };
    /* Secondary - Sell secondary*/
    AuthenticationService.prototype.saveSecondary = function (secondary) {
        return this.http.post(this.baseURL + "secondary_transaction/sale", secondary);
    };
    AuthenticationService.prototype.getMyTransactions = function (id) {
        return this.http.get(this.baseURL + "secondary_transaction/shareholder_transaction?ShareholderId=" + id);
    };
    AuthenticationService.prototype.getEmployeesForPlan = function (status, planName) {
        return this.http.get(this.baseURL +
            "employee/management/dropdown?employmentStatus=" +
            status +
            "&planName=" +
            planName);
    };
    AuthenticationService.prototype.getEsopPlan = function (id) {
        return this.http.get(this.baseURL + "esop?planName=" + id);
    };
    AuthenticationService.prototype.getPlanData = function (body) {
        return this.http.post(this.baseURL + "esop/management", body);
    };
    //Exit Modelling
    AuthenticationService.prototype.getExitModelingBasicInfo = function () {
        return this.http.get(this.baseURL + "exit_modeling/land");
    };
    AuthenticationService.prototype.getExitModellingInfo = function (exitmod) {
        return this.http.post(this.baseURL + "exit_modeling", exitmod);
    };
    //Split & Reverse Split
    AuthenticationService.prototype.getSplitReverseSplitInfo = function (ratio) {
        return this.http.get(this.baseURL + "share_split?ratio=" + ratio);
    };
    //Create new round - Discard proforma round
    AuthenticationService.prototype.discardRound = function () {
        return this.http.delete(this.baseURL + "round/discard");
    };
    //Round Creation- Refresh Right to Maintain data
    AuthenticationService.prototype.roundCreationrefreshRTM = function () {
        return this.http.get(this.baseURL + "round/right_to_maintain/refresh");
    };
    //Round Creation - Refresh New Investor
    AuthenticationService.prototype.roundCreationrefreshNewInv = function () {
        return this.http.get(this.baseURL + "round/new_investors/refresh");
    };
    //Shareholder Fund Details
    AuthenticationService.prototype.shareHolderFundDetails = function (id) {
        return this.http.get(this.baseURL + "dashboard/shareholder/fund/" + id);
    };
    //Added New
    //Onboarding Api's
    AuthenticationService.prototype.checkCompanyName = function (companyName, body, isNewRegistration) {
        // return this.http.post(
        //   this.baseURL + "user/company?companyName=" + companyName,
        //   body
        // );
        return this.http.get(this.baseURL + 'company/search?companyName=' + companyName + '&isNewRegistration=' + isNewRegistration);
    };
    AuthenticationService.prototype.requestVerification = function (companyName, body) {
        return this.http.post(this.baseURL + "user/access?companyName=" + companyName, body);
    };
    AuthenticationService.prototype.getUsersList = function () {
        return this.http.get(this.baseURL + "user/get");
    };
    AuthenticationService.prototype.addUsers = function (users) {
        return this.http.post(this.baseURL + "user/add", users);
    };
    AuthenticationService.prototype.updateUsers = function (users) {
        return this.http.put(this.baseURL + "user/add", users);
    };
    AuthenticationService.prototype.userVerification = function (code) {
        return this.http.get(this.baseURL + "user/verification?code=" + code);
    };
    AuthenticationService.prototype.addPermissions = function (data) {
        return this.http.post(this.baseURL + "role", data);
    };
    AuthenticationService.prototype.getIncorporationDetails = function () {
        return this.http.get(this.baseURL + "round/incorporation");
    };
    AuthenticationService.prototype.uploadIncorporationDocuments = function (documentsData) {
        return this.http.post(this.baseURL + "company/upload/documents/single", documentsData, {
            reportProgress: true,
            observe: 'events'
        });
    };
    AuthenticationService.prototype.getIncorporationDocuments = function () {
        return this.http.get(this.baseURL + "company/upload/documents");
    };
    AuthenticationService.prototype.deleteSelectedIncorporationDocs = function (filesList) {
        return this.http.post(this.baseURL + "company/delete/documents", filesList);
    };
    /*Task Manager Starts*/
    AuthenticationService.prototype.getAllWorkflowTypes = function () {
        return this.http.get(this.baseURL + "task-manager/types");
    };
    AuthenticationService.prototype.getAllWorkflowsForType = function (workflowTypeId) {
        return this.http.get(this.baseURL + "task-manager/" + workflowTypeId);
    };
    AuthenticationService.prototype.getShareIssuances = function () {
        return this.http.get(this.baseURL + "task-manager/shareIssuances");
    };
    AuthenticationService.prototype.startWorkflow = function (workflowData) {
        return this.http.post(this.baseURL + "task-manager/start-process", workflowData);
    };
    AuthenticationService.prototype.deleteWorkflow = function (workflowInstanceId) {
        return this.http.delete(this.baseURL + "task-manager/delete/" + workflowInstanceId);
    };
    AuthenticationService.prototype.stepsForSelectedWorkflow = function (workflowInstanceId) {
        return this.http.get(this.baseURL + "workflow/steps/" + workflowInstanceId);
    };
    AuthenticationService.prototype.tasksAssociatedWithWorkflow = function (workflowInstanceId) {
        return this.http.get(this.baseURL + "task-manager/details/" + workflowInstanceId);
    };
    AuthenticationService.prototype.getActivityDropdownValues = function () {
        return this.http.get(this.baseURL + "workflow/activities");
    };
    AuthenticationService.prototype.getAllPuposeOfTasks = function () {
        return this.http.get(this.baseURL + "workflow/purpose");
    };
    AuthenticationService.prototype.addNewTask = function (task) {
        return this.http.post(this.baseURL + "workflow/task/add", task);
    };
    AuthenticationService.prototype.editTask = function (task) {
        return this.http.put(this.baseURL + "workflow/task/update", task);
    };
    AuthenticationService.prototype.addNewPurpose = function (purpose) {
        return this.http.post(this.baseURL + "workflow/purpose/add", purpose);
    };
    AuthenticationService.prototype.changeTaskStatus = function (taskDetails) {
        return this.http.put(this.baseURL + "task-manager/task/status", taskDetails);
    };
    AuthenticationService.prototype.changeStepStatus = function (stepDetails) {
        return this.http.put(this.baseURL + "workflow/steps/status", stepDetails);
    };
    AuthenticationService.prototype.getAllExistingWorkflows = function () {
        return this.http.get(this.baseURL + "task-manager/list/user");
    };
    AuthenticationService.prototype.getAllWorkflowTemplates = function () {
        return this.http.get(this.baseURL + "task-manager/templates/");
    };
    AuthenticationService.prototype.scheduleMeeting = function (dateObj) {
        return this.http.post(this.baseURL + "task-manager/task/schedule-meeting", dateObj);
    };
    AuthenticationService.prototype.cancelMeeting = function (taskId) {
        return this.http.post(this.baseURL + "task-manager/task/cancel-meeting", { "taskId": taskId });
    };
    AuthenticationService.prototype.callForMeeting = function (taskId) {
        return this.http.post(this.baseURL + "task-manager/task/call-for-meeting", { "taskId": taskId });
    };
    AuthenticationService.prototype.deleteTask = function (workflowInstanceId, stepInstanceId) {
        return this.http.delete(this.baseURL + "workflow/task/delete/" + workflowInstanceId + "/" + stepInstanceId);
    };
    AuthenticationService.prototype.getTaskDocuments = function (taskId) {
        return this.http.get(this.baseURL + "document/" + taskId);
    };
    AuthenticationService.prototype.setDocumentStatus = function (statusObj) {
        return this.http.put(this.baseURL + "document/status", statusObj);
    };
    AuthenticationService.prototype.uploadMeetingDocuments = function (fileObj) {
        return this.http.post(this.baseURL + "document/supporting/upload", fileObj);
    };
    AuthenticationService.prototype.downloadMeetingDocuments = function (type, documentId) {
        return window.open(this.baseURL + "document/meeting/download/" + type + "/" + documentId);
    };
    AuthenticationService.prototype.downloadSupportingDocuments = function (type, documentId) {
        return window.open(this.baseURL + "document/supporting/download/" + type + "/" + documentId);
    };
    AuthenticationService.prototype.generateDocumentsForTask = function (taskDetails) {
        return this.http.post(this.baseURL + "document/generate", taskDetails);
    };
    AuthenticationService.prototype.downloadDocument = function (documentId, isSignedDoc) {
        var docType = isSignedDoc ? 'Signed' : 'Generated';
        return window.open(this.baseURL + "document/download?documentId=" + documentId + "&type=" + docType);
    };
    AuthenticationService.prototype.getTaskStatuses = function () {
        return this.http.get(this.baseURL + "task-manager/task/statuses");
    };
    AuthenticationService.prototype.getTaskDetails = function (taskId) {
        return this.http.get(this.baseURL + "task-manager/task/details/" + taskId);
    };
    AuthenticationService.prototype.uploadPostMeetingDocument = function (taskId, files) {
        return this.http.post(this.baseURL + "task-manager/task/upload/post-meeting-documents/" + taskId, files);
    };
    AuthenticationService.prototype.uploadRegulatoryFilings = function (taskId, files) {
        return this.http.post(this.baseURL + "task-manager/task/upload/regulatory-filings/" + taskId, files);
    };
    AuthenticationService.prototype.uploadShortNotices = function (taskId, files) {
        return this.http.post(this.baseURL + "task-manager/task/upload/short-notices/" + taskId, files);
    };
    AuthenticationService.prototype.completeTheTask = function (data) {
        return this.http.post(this.baseURL + "task-manager/task/complete", data);
    };
    //Document assembler
    AuthenticationService.prototype.getDocumentTemplates = function (taskId) {
        return this.http.get(this.baseURL + "document-assembler/task/templates/" + taskId);
    };
    AuthenticationService.prototype.startTemplateEditing = function (templateId, taskId) {
        return this.http.post(this.baseURL + "document-assembler/template/edit/" + templateId + "/" + taskId, templateId);
    };
    AuthenticationService.prototype.startEditingUserContextTemplate = function (templateId, userContext, body) {
        return this.http.post(this.baseURL + "document-assembler/template/editing/" + templateId + "/" + userContext, body);
    };
    AuthenticationService.prototype.getQuestionsForDocument = function (documentAssemblerId, templateId, sequenceNum) {
        return this.http.get(this.baseURL + "document-assembler/question/" + templateId + "/" + documentAssemblerId + "/" + sequenceNum);
    };
    AuthenticationService.prototype.getUserContextQuestionsForDocument = function (context, templateId, sequenceNum) {
        return this.http.get(this.baseURL + "document-assembler/question/user-context/" + templateId + "/" + context + "/" + sequenceNum);
    };
    AuthenticationService.prototype.getDocumentContent = function (body) {
        return this.http.post(this.baseURL + "document-assembler/form", body);
    };
    AuthenticationService.prototype.getUserContextDocumentContent = function (templateId, userContext) {
        return this.http.get(this.baseURL + "document-assembler/form/user-context/" + templateId + "/" + userContext);
    };
    AuthenticationService.prototype.saveAnswersForDocument = function (answersObj) {
        return this.http.post(this.baseURL + "document-assembler/template/answer/save", answersObj);
    };
    AuthenticationService.prototype.saveDocumentTemplate = function (answersObj) {
        return this.http.post(this.baseURL + "document-assembler/template/complete", answersObj);
    };
    AuthenticationService.prototype.getCompletedTemplates = function (taskId) {
        return this.http.get(this.baseURL + "document-assembler/template/" + taskId);
    };
    AuthenticationService.prototype.downloadBuiltDocument = function (ids) {
        return this.http.get(this.baseURL + "document-assembler/template/multiple/download/" + ids, {
            observe: "response",
            responseType: "blob",
        });
    };
    AuthenticationService.prototype.downloadBuiltDocumentById = function (documentId) {
        return window.open(this.baseURL + "document-assembler/template/download/" + documentId);
    };
    AuthenticationService.prototype.saveReferencedAnswer = function (data) {
        return this.http.post(this.baseURL + "document-assembler/reference-answer", data);
    };
    AuthenticationService.prototype.uploadSignedDocument = function (documentId, fileDetails) {
        return this.http.post(this.baseURL + "document/upload/signedDocument/" + documentId, fileDetails);
    };
    AuthenticationService.prototype.uploadUnSignedDocument = function (documentId, fileDetails) {
        return this.http.post(this.baseURL + "document/upload/generate-document/" + documentId, fileDetails);
    };
    AuthenticationService.prototype.getSignatories = function () {
        return this.http.get(this.baseURL + "workflow/directors");
    };
    AuthenticationService.prototype.updateDocumentStatus = function (statusInfo) {
        return this.http.put(this.baseURL + "document/status", statusInfo);
    };
    AuthenticationService.prototype.saveDocumentTableRow = function (rowData) {
        return this.http.put(this.baseURL + "document/row/save", rowData);
    };
    AuthenticationService.prototype.deleteDocumentTableRow = function (rowId) {
        return this.http.delete(this.baseURL + "document/delete/" + rowId);
    };
    AuthenticationService.prototype.deleteGeneratedDocument = function (documentId) {
        return this.http.delete(this.baseURL + "document/delete/generate-document/" + documentId);
    };
    AuthenticationService.prototype.deleteSignedDocument = function (documentId) {
        return this.http.delete(this.baseURL + "document/delete/signed-document/" + documentId);
    };
    AuthenticationService.prototype.addNewDocumentRow = function (taskInfo) {
        return this.http.post(this.baseURL + "document/add/generate-document", taskInfo);
    };
    AuthenticationService.prototype.getSFDTformat = function (documentId) {
        return this.http.post(this.baseURL + "documenteditor/import?DocumentId=" + documentId, "", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.getSFDTformatofGrantLetter = function (selectedGrantId) {
        console.log(selectedGrantId);
        return this.http.post(this.baseURL + "documenteditor/grant-letter/import?optionHolderId=" + selectedGrantId, "", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.getSFDTformatForNewDocument = function () {
        return this.baseURL + "documenteditor/wordeditor/";
    };
    AuthenticationService.prototype.getPlanLetter = function (planId) {
        return this.http.get(this.baseURL + "documenteditor/plan-document/" + planId, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.saveEditedDocument = function (fileDetails, fileId) {
        return this.http.post(this.baseURL + "documenteditor/document/upload/" + fileId, fileDetails);
    };
    AuthenticationService.prototype.downlaodDraftDocments = function (workflowInstanceId) {
        return this.http.get(this.baseURL + "document-assembler/workflowInstances/" + workflowInstanceId + "/documents", {
            observe: "response",
            responseType: "blob",
        });
    };
    AuthenticationService.prototype.uploadReviewedDocument = function (documentAssemblerId, file) {
        return this.http.post(this.baseURL + "document-assembler/upload/review-document/" + documentAssemblerId, file);
    };
    //**START NEW PROCESS */
    AuthenticationService.prototype.getAllRounds = function () {
        return this.http.get(this.baseURL + "task-manager/rounds");
    };
    AuthenticationService.prototype.getShareholdersById = function (roundId) {
        return this.http.get(this.baseURL + "task-manager/shareholders/" + roundId);
    };
    // to get list of vesting events
    AuthenticationService.prototype.getVestingEvents = function () {
        return this.http.get(this.baseURL + "esop/vestevents");
    };
    // to edit vesting events
    AuthenticationService.prototype.getVestEventdetails = function (eventName) {
        return this.http.get(this.baseURL + "esop/vesteventpopup?eventName=" + eventName);
    };
    AuthenticationService.prototype.getVestEventdetailsForApproval = function (eventName) {
        return this.http.get(this.baseURL + "esop/vestevents/approve?eventName=" + eventName);
    };
    //To Get number of options get vested and unvested in vesting event popup
    AuthenticationService.prototype.getVestUnvestShares = function (eventname, percentage) {
        return this.http.get(this.baseURL + "employee/vest/eventcount?eventName=" + eventname + "&percentage=" + percentage);
    };
    // upddate vesting events
    AuthenticationService.prototype.updateVestingEvent = function (body, isApprover) {
        return this.http.post(this.baseURL + ("esop/vestevents" + (isApprover ? "/approve" : "")), body);
    };
    // to get vesting templates
    AuthenticationService.prototype.getVestTemplates = function () {
        return this.http.get(this.baseURL + "esop/vestingtemplates");
    };
    // to get shareholder profile details
    AuthenticationService.prototype.getShareholderProfile = function (shareholderID) {
        return this.http.get(this.baseURL + "dashboard/shareholder/profile/" + shareholderID);
    };
    //to save shareholder profile details
    AuthenticationService.prototype.updateShareholderProfile = function (shareholderID, body) {
        return this.http.post(this.baseURL + "dashboard/shareholder/profile/" + shareholderID, body);
    };
    //to get User profile details
    AuthenticationService.prototype.getUserProfile = function () {
        return this.http.get(this.baseURL + "user/profile");
    };
    //to save User Password
    AuthenticationService.prototype.resetUserPassword = function (body) {
        return this.http.get(this.baseURL + "password/reset" + body);
    };
    //File Manager
    AuthenticationService.prototype.updateCategoryTags = function (tags) {
        return this.http.post(this.baseURL + 'fileManager/tag', tags);
    };
    AuthenticationService.prototype.getParentIdsFromPath = function (paths) {
        return this.http.post(this.baseURL + "fileManager/get-ids", paths);
    };
    AuthenticationService.prototype.checkIfPathExists = function (obj) {
        return this.http.post(this.baseURL + 'fileManager/is-exists', obj);
    };
    AuthenticationService.prototype.PDFtoXML = function (PDFfile) {
        return this.http.post(this.baseURL + 'upload/pdftoxml', PDFfile, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.XMLtoPDF = function (files) {
        return this.http.post(this.baseURL + 'upload/generatePDFUsingExcelFile', files, {
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.updateUser = function (user) {
        return this.http.put(this.baseURL + "user/profile", user);
    };
    AuthenticationService.prototype.getCapital = function () {
        return this.http.get(this.baseURL + "capital");
    };
    AuthenticationService.prototype.updateCapital = function (body) {
        return this.http.post(this.baseURL + 'capital', body);
    };
    //delete user access permission
    AuthenticationService.prototype.deleteUserPermission = function (Id) {
        return this.http.delete(this.baseURL + "user/permission/delete?UserId=" + Id);
    };
    // Save Round for later
    AuthenticationService.prototype.saveRoundforLater = function (body) {
        return this.http.post(this.baseURL + 'round/save_for_later', body);
    };
    //Secondary Transaction Summary
    AuthenticationService.prototype.getSecondaryTransactionSummary = function (Id) {
        return this.http.get(this.baseURL + "secondary_transaction/completed_transaction/summary?SecondaryTransactionId=" + Id);
    };
    // Employee
    AuthenticationService.prototype.getEmployeeCompanies = function (body) {
        return this.http.post(this.baseURL + "user/employee/company", body);
    };
    AuthenticationService.prototype.employeeLogin = function (type, id, body) {
        return this.http.post(this.baseURL + "login?userType=" + type + "&companyId=" + id, body, {
            observe: "response",
        });
    };
    AuthenticationService.prototype.employeeForgotPassword = function (type, mailid, companyid) {
        return this.http.get(this.baseURL + "password/forgot?emailId=" + mailid + "&userType=" + type + "&companyId=" + companyid);
    };
    AuthenticationService.prototype.employeeResetPassword = function (type, companyid, userDetails) {
        return this.http.post(this.baseURL + "password/reset?userType=" + type + "&companyId=" + companyid, userDetails, {
            observe: "response",
        });
    };
    AuthenticationService.prototype.hasQuestionnaire = function (templateId) {
        return this.http.get(this.baseURL + "document-assembler/has-questions/" + templateId);
    };
    AuthenticationService.prototype.generateAndDownloadTemplate = function (templateId, taskId, token) {
        return this.http.get(this.baseURL + "document-assembler/template/generate/download/" + templateId + "/" + taskId + "/" + token);
    };
    AuthenticationService.prototype.uploadUserDefinedDocument = function (documentModel) {
        return this.http.post(this.baseURL + "template/user-defined-document/upload", documentModel);
    };
    AuthenticationService.prototype.uploadEmployeesFile = function (file) {
        return this.http.post(this.baseURL + "usingexcelfile/createEmployee/", file, {});
    };
    //HISTORICAL ROUND
    AuthenticationService.prototype.getHistoricalRoundDetails = function () {
        return this.http.get(this.baseURL + "historicalRound");
    };
    AuthenticationService.prototype.importHistoricalRoundDetails = function (excelFile) {
        return this.http.post(this.baseURL + "usingexcelfile/fillHistoricalDataAfterLogin", excelFile);
    };
    AuthenticationService.prototype.saveHistoricalRoundForLater = function (roundDetails) {
        return this.http.post(this.baseURL + "historicalRound", roundDetails);
    };
    AuthenticationService.prototype.historicalRoundDetails = function (roundDetails) {
        return this.http.post(this.baseURL + "historicalRound/get/captable", roundDetails);
    };
    AuthenticationService.prototype.approveHistoricalRound = function (roundDetails) {
        return this.http.post(this.baseURL + "historicalRound/submit", roundDetails);
    };
    AuthenticationService.prototype.publishHistoricalRound = function (legacyCompanyId, isPrivate) {
        return this.http.post(environment.newCaptableBaseUrl + ("/company/publish?legacyCompanyId=" + legacyCompanyId + "&isPrivate=" + isPrivate), {});
    };
    AuthenticationService.prototype.discardHistoricalRound = function (companyId) {
        return this.http.get(this.baseURL + "company/discard/on-boarding?companyId=" + companyId);
    };
    AuthenticationService.prototype.historicalFinalApproval = function (companyId) {
        return this.http.get(this.baseURL + "captable_data/approve?CompanyId=" + companyId);
    };
    AuthenticationService.prototype.discardHistoricalCaptable = function (companyId) {
        return this.http.delete(this.baseURL + "captable_data/discard?CompanyId=" + companyId);
    };
    AuthenticationService.prototype.getRoundPieChart = function (roundDetails) {
        return this.http.post(this.baseURL + "historicalRound/get/pieChart/round", roundDetails);
    };
    AuthenticationService.prototype.getInstrumentPieChart = function (roundDetails) {
        return this.http.post(this.baseURL + "historicalRound/get/pieChart/instrument", roundDetails);
    };
    AuthenticationService.prototype.getShareholderPieChart = function (roundDetails) {
        return this.http.post(this.baseURL + "historicalRound/get/pieChart/shareholder", roundDetails);
    };
    AuthenticationService.prototype.getInstrumentsForBuyBack = function () {
        return this.http.get(this.baseURL + "buyBack/instrumentTypes");
    };
    AuthenticationService.prototype.getProformaBuyBackDetails = function (buyBackId) {
        return this.http.get(this.baseURL + "buyBack/buyBackDetails?buyBackId=" + buyBackId);
    };
    AuthenticationService.prototype.saveBuyBackDetails = function (buyBackDetails) {
        return this.http.post(this.baseURL + "buyBack/buyBackDetails", buyBackDetails);
    };
    AuthenticationService.prototype.getInitialShareholdersData = function (buyBackId, resetSharHolders) {
        return this.http.get(this.baseURL + "buyBack/shareholders?buyBackId=" + buyBackId + "&" + "resetShareHolders=" + resetSharHolders);
    };
    AuthenticationService.prototype.saveShareholdersDataForBuyBack = function (buyBackId, shareholders) {
        return this.http.post(this.baseURL + "buyBack/shareholders?buyBackId=" + buyBackId, shareholders);
    };
    AuthenticationService.prototype.executeBuyBackTempForSummary = function (buyBackId, isProforma, shareholders) {
        return this.http.post(this.baseURL + "buyBack/executeBuyBack?buyBackId=" + buyBackId + "&isProforma=" + isProforma, shareholders);
    };
    AuthenticationService.prototype.executeBuyBackFinal = function (buyBackId, isProforma, shareReserves) {
        return this.http.post(this.baseURL + "buyBack/executeBuyBack?buyBackId=" + buyBackId + "&isProforma=" + isProforma + "&shareReserves=" + shareReserves, null);
    };
    AuthenticationService.prototype.checkForOngoingTransactions = function () {
        return this.http.get(this.baseURL + "buyBack/checkForOngoingTransactions");
    };
    AuthenticationService.prototype.getAllBuyBackTransactionData = function () {
        return this.http.get(this.baseURL + "buyBack/getExistingBuybacks");
    };
    AuthenticationService.prototype.discardBuyBackTransaction = function (buyBackId) {
        return this.http.delete(this.baseURL + "buyBack/discard?buyBackId=" + buyBackId);
    };
    AuthenticationService.prototype.saveBuyBackNote = function (buyBackId, buyBackDetails) {
        return this.http.post(this.baseURL + "buyBack/updateBuyBackNote?buyBackId=" + buyBackId, buyBackDetails);
    };
    //Document Generator
    AuthenticationService.prototype.generateGrantLetter = function (formData) {
        return this.http.post(this.baseURL + 'document-assembler/generate/grant-letter', formData, {
            observe: "response",
            responseType: "blob",
        });
    };
    AuthenticationService.prototype.convertDocxToPdf = function (formData, converter) {
        return this.http.post(this.baseURL + 'upload/docxtopdf/' + converter, formData, {
            observe: "response",
            responseType: "blob",
        });
    };
    AuthenticationService.prototype.getTotalFDBShares = function () {
        return this.http.get(this.baseURL + "buyBack/getTotalFDBShares");
    };
    AuthenticationService.prototype.getAdminSettings = function () {
        return this.http.get(this.baseURL + "AdminSetting/settings");
    };
    AuthenticationService.prototype.saveAdminSettings = function (adminSettings) {
        return this.http.post(this.baseURL + "AdminSetting/save", adminSettings);
    };
    AuthenticationService.prototype.importShareCertificate = function (file) {
        return this.http.post(this.baseURL + "share-certificate/importShareCertificate", file);
    };
    AuthenticationService.prototype.exportShareCertificate = function () {
        return this.http.get(this.baseURL + "share-certificate/exportShareCertificate", {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.getInvestorShareCertificate = function (investorId) {
        return this.http.get(this.baseURL + "share-certificate/getShareCertificateDocument?investorId=" + investorId, {
            observe: "response",
            responseType: "blob"
        });
    };
    //Export file
    AuthenticationService.prototype.exportIndividualRoundReport = function (roundName) {
        return this.http.get(this.baseURL + "report-generation/round?roundName=" + roundName, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportRoundOverview = function () {
        return this.http.get(this.baseURL + "report-generation/round?isOverview=true", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportShareholdersOverview = function () {
        return this.http.get(this.baseURL + "report-generation/shareholder", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportSecuritiesOverview = function () {
        return this.http.get(this.baseURL + "report-generation/security?isOverview=true", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportSecondaryOverviewReport = function () {
        return this.http.get(this.baseURL + "report-generation/secondary", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportBuyBackOverviewReport = function () {
        return this.http.get(this.baseURL + "report-generation/buyback", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportPAS4File = function (roundName) {
        return this.http.get(this.baseURL + "report-generation/round/pas4?roundName=" + roundName, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportSecurityReport = function (securityType) {
        return this.http.get(this.baseURL + "report-generation/security?securityType=" + securityType, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportSh6 = function (requestBody, id) {
        return this.http.get(this.baseURL + "report-generation/exportSH6?date=" + requestBody + "&id=" + id);
    };
    AuthenticationService.prototype.exportSecondaryTransactionReport = function (requestBody) {
        return this.http.post(this.baseURL + "report-generation/generateReport?isSecondaryPage=" + true, requestBody, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportSplitTransactionReport = function (requestBody) {
        return this.http.post(this.baseURL + "report-generation/generateReport?isSplitPage=" + true, requestBody, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportHistoricalTransactionReport = function (requestBody) {
        return this.http.post(this.baseURL + "report-generation/generateReport?isHistoricalPage=" + true, requestBody, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportHistoricalRoundBasedReport = function () {
        return this.http.get(this.baseURL + "report-generation/generateReportBeforeApproval?basedOnRound=true", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportHistoricalSecurityBasedReport = function () {
        return this.http.get(this.baseURL + "report-generation/generateReportBeforeApproval?basedOnSecurity=true", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportQuickRoundReport = function () {
        return this.http.get(this.baseURL + "report-generation/quickRound", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.exportQuickRoundPAS4 = function () {
        return this.http.get(this.baseURL + "report-generation/quickRound/pas4", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.getOngoingTransaction = function () {
        return this.http.get(this.baseURL + "transactionSummary/ongoing/transaction");
    };
    AuthenticationService.prototype.getAllTransactions = function (fromDate, toDate) {
        return this.http.get(this.baseURL + 'transactionSummary?fromDate=' + fromDate + '&toDate=' + toDate);
    };
    AuthenticationService.prototype.generateRoundSummaryReport = function (roundName, body) {
        return this.http.post(this.baseURL + 'report-generation/generateReport?isRoundSummaryPage=true&roundName=' + roundName, body, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.exportProformaRoundPas4 = function () {
        return this.http.get(this.baseURL + "report-generation/roundSummary/pas4", {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.generateRoundModelingReport = function (roundName, body) {
        return this.http.post(this.baseURL + 'report-generation/generateReport?isRoundModelingPage=true&roundName=' + roundName, body, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.exportTransactionsFile = function (fromDate, toDate) {
        return this.http.get(this.baseURL + 'report-generation/allTransactions?fromDate=' + fromDate + '&toDate=' + toDate, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.getFaq = function () {
        return this.http.get(this.baseURL + "company/getFaq");
    };
    AuthenticationService.prototype.setFaq = function (faqArray) {
        return this.http.post(this.baseURL + "company/setFaq", faqArray);
    };
    //document generator
    AuthenticationService.prototype.generateDocument = function (templateId, body) {
        return this.http.post(this.baseURL + "document-assembler/generate/document?templateId=" + templateId, body);
    };
    //company resolutions
    AuthenticationService.prototype.getCompanyResolutionsList = function (companyId) {
        return this.http.get(this.baseURL + "company-resolutions/" + companyId);
    };
    AuthenticationService.prototype.deleteResolution = function (resolutionId) {
        return this.http.delete(this.baseURL + "company-resolutions/" + resolutionId);
    };
    AuthenticationService.prototype.getTemplatesByResolution = function (resolutionId) {
        return this.http.get(this.baseURL + "company-resolutions/templates/" + resolutionId);
    };
    AuthenticationService.prototype.downloadResolutionDocument = function (documentId) {
        return this.http.get(this.baseURL + "company-resolutions/download/" + documentId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.getCompanyResolutionPdfDocument = function (documentId) {
        return this.http.get(this.baseURL + "company-resolutions/pdf-document/" + documentId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.downloadAllCompanyResolutionPdfDocument = function (resolutionId) {
        return this.http.get(this.baseURL + "company-resolutions/templates/download/" + resolutionId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.downloadAllCompanyResolutionDocuments = function () {
        return this.http.get(this.baseURL + "company-resolutions/download-all", {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.downloadAllFilingDocuments = function () {
        return this.http.get(this.baseURL + "company-resolutions/download-all-filings", {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.saveUserAnswersWithFile = function (body) {
        return this.http.post(this.baseURL + "document-assembler/save/answers/file/", body);
    };
    AuthenticationService.prototype.downloadAnswerWithFile = function (answerId) {
        return this.http.get(this.baseURL + "document-assembler/download/answers/file/" + answerId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.getResolutionIdByTemplateId = function (templateId) {
        return this.http.get(this.baseURL + "company-resolutions/get-resolution-id/" + templateId);
    };
    AuthenticationService.prototype.getLatestTermSheet = function () {
        return this.http.get(this.baseURL + "termsheetdetails/latest");
    };
    AuthenticationService.prototype.getAllTermSheets = function () {
        return this.http.get(this.baseURL + "termsheetdetails");
    };
    AuthenticationService.prototype.getTermSheetDetails = function (id) {
        return this.http.get(this.baseURL + "termsheetdetails/details?Id=" + id);
    };
    AuthenticationService.prototype.markTermSheetInterest = function (id, interest) {
        return this.http.post(this.baseURL + "termsheetdetails/save/interest?Id=" + id + "&IsInterested=" + interest, null);
    };
    AuthenticationService.prototype.createTermSheetRound = function (id) {
        return this.http.get(this.baseURL + "termsheetdetails/round/save?Id=" + id);
    };
    AuthenticationService.prototype.createTermSheetModel = function (id) {
        return this.http.get(this.baseURL + "termsheetdetails/round_model/save?Id=" + id);
    };
    AuthenticationService.prototype.downloadTermSheet = function (id) {
        return this.http.get(this.baseURL + "termsheetdetails/document/download?Id=" + id, {
            responseType: "blob",
            observe: "response",
        });
    };
    AuthenticationService.prototype.viewTermSheet = function (id) {
        return this.http.get(this.baseURL + "termsheetdetails/document/view?Id=" + id, {
            observe: "response",
            responseType: "blob"
        });
    };
    AuthenticationService.prototype.getSecurityClass = function (type) {
        return this.http.get(this.baseURL + "quick_round/security/list?security=" + type);
    };
    AuthenticationService.prototype.getQuickRoundDetails = function () {
        return this.http.get(this.baseURL + "quick_round");
    };
    AuthenticationService.prototype.discardQuickRound = function () {
        return this.http.delete(this.baseURL + "quick_round/discard");
    };
    AuthenticationService.prototype.saveQuickRound = function (body) {
        return this.http.post(this.baseURL + "quick_round", body);
    };
    AuthenticationService.prototype.getQuickRoundPreview = function (body) {
        return this.http.post(this.baseURL + "quick_round/preview", body);
    };
    AuthenticationService.prototype.approveQuickRound = function () {
        return this.http.post(this.baseURL + "quick_round/approve", null);
    };
    AuthenticationService.prototype.getHrmsEmpList = function () {
        return this.http.get(this.baseURL + "integration/get");
    };
    AuthenticationService.prototype.importEmp = function (empList) {
        return this.http.post(this.baseURL + "integration/import", empList);
    };
    AuthenticationService.prototype.loginHrms = function (loginObj) {
        return this.http.post(this.baseURL + "integration/login", loginObj);
    };
    AuthenticationService.prototype.syncHrms = function () {
        return this.http.post(this.baseURL + "integration/sync", {});
    };
    AuthenticationService.prototype.disconnectHrms = function () {
        return this.http.post(this.baseURL + "integration/disconnect", {});
    };
    AuthenticationService.prototype.enableDisableLogin = function (empObj) {
        return this.http.post(this.baseURL + "employee/enable-disable/?employeeId=" + empObj['employeeId'] + "&deactivate=" + empObj['deactivate'], empObj);
    };
    AuthenticationService.prototype.saveOptionsBuyBackDetails = function (optionBuyBackDto) {
        return this.http.post(this.baseURL + "optionsBuyBack/details", optionBuyBackDto);
    };
    AuthenticationService.prototype.getExercisableOptions = function (cutOffDate, buyBackPercentage) {
        return this.http.get(this.baseURL + "optionsBuyBack/totalExercisableOptions?buyBackPercentage=" + buyBackPercentage.toString() + "&cutOffDate=" + cutOffDate);
    };
    AuthenticationService.prototype.getOptionBuyBackDetails = function () {
        return this.http.get(this.baseURL + "optionsBuyBack/details");
    };
    AuthenticationService.prototype.startOptionBuyBack = function (optionBuyBackDto) {
        return this.http.post(this.baseURL + "optionsBuyBack/details", optionBuyBackDto);
    };
    AuthenticationService.prototype.getOptionBuyBackOfferDetails = function (buyBackId) {
        return this.http.get(this.baseURL + "optionsBuyBack/offers?buyBackId=" + buyBackId);
    };
    AuthenticationService.prototype.discardOptionBuyBack = function (buyBackId) {
        return this.http.delete(this.baseURL + "optionsBuyBack?buyBackId=" + buyBackId);
    };
    AuthenticationService.prototype.uploadPlanDocument = function (body) {
        return this.http.post(this.baseURL + "esop/upload/plan-document", body);
    };
    AuthenticationService.prototype.verifyOtp = function (reqObj) {
        return this.http.post(this.baseURL + "login/verifyOtp", reqObj, { observe: "response" });
    };
    AuthenticationService.prototype.getPartlyPaidShareDetails = function (investorId) {
        return this.http.get(this.baseURL + "dashboard/partlypaid/get?investorId=" + investorId);
    };
    AuthenticationService.prototype.savePartlyPaidShareDetails = function (body) {
        return this.http.post(this.baseURL + "dashboard/partlypaid/save", body);
    };
    AuthenticationService.prototype.getInstrumentDetails = function (date) {
        return this.http.get(this.baseURL + "bonus/getdetails?date=" + date);
    };
    AuthenticationService.prototype.setInstrumentDetails = function (reqObj) {
        return this.http.post(this.baseURL + "bonus/setdetails", reqObj);
    };
    AuthenticationService.prototype.getBonusSecurityDetails = function (bonusId) {
        return this.http.get(this.baseURL + "bonus/getShareholderDetails?bonusId=" + bonusId);
    };
    AuthenticationService.prototype.getBonusPreview = function (bonusId) {
        return this.http.get(this.baseURL + "bonus/preview?bonusId=" + bonusId);
    };
    AuthenticationService.prototype.setShareholderDetails = function (reqObj) {
        return this.http.post(this.baseURL + "bonus/setShareholderDetails", reqObj);
    };
    AuthenticationService.prototype.discardBonus = function () {
        return this.http.delete(this.baseURL + "bonus/discard");
    };
    AuthenticationService.prototype.approveBonus = function () {
        return this.http.post(this.baseURL + "bonus/approve", {});
    };
    AuthenticationService.prototype.getBonusDetails = function (bonusId) {
        return this.http.get(this.baseURL + "bonus/getBonusDetails?bonusId=" + bonusId);
    };
    AuthenticationService.prototype.getAllBonusDetails = function () {
        return this.http.get(this.baseURL + "bonus/getAllBonusDetails");
    };
    AuthenticationService.prototype.getShareModelDetails = function (typeOfModel, modelName) {
        return this.http.get(this.baseURL + "shareModel/getDetails?typeOfModel=" + typeOfModel + "&modelName=" + modelName);
    };
    AuthenticationService.prototype.saveShareModelDetails = function (body) {
        return this.http.post(this.baseURL + "shareModel/saveDetails", body);
    };
    AuthenticationService.prototype.getOnGoingBonusDetails = function () {
        return this.http.get(this.baseURL + "bonus/getOnGoingBonusDetails");
    };
    AuthenticationService.prototype.downloadFinancialReport = function (vesting_date) {
        return this.http.get(this.baseURL + "report-generation/financial-report/withVesting/" + vesting_date);
    };
    AuthenticationService.prototype.downloadFinancialReportWithOutSchedule = function (vesting_date) {
        return this.http.get(this.baseURL + "report-generation/financial-report/" + vesting_date);
    };
    AuthenticationService.prototype.resendOtp = function (resendObj) {
        return this.http.post(this.baseURL + "login/resendOtp", resendObj);
    };
    AuthenticationService.prototype.getSubsidiaryCompanies = function () {
        return this.http.get(this.baseURL + "company/subsidary/relation/captable");
    };
    //Step plan APIs
    AuthenticationService.prototype.saveStepPlan = function (body) {
        return this.http.post(this.baseURL + "step-plan", body);
    };
    AuthenticationService.prototype.saveStepPlanActivity = function (body) {
        return this.http.post(this.baseURL + "step-plan/step-activity", body);
    };
    AuthenticationService.prototype.getStepPlans = function (body) {
        return this.http.post(this.baseURL + "step-plan/list", body);
    };
    AuthenticationService.prototype.startStepPlan = function (body) {
        return this.http.post(this.baseURL + "step-plan/start", body);
    };
    AuthenticationService.prototype.getStepPlanInstances = function (body) {
        return this.http.post(this.baseURL + "step-plan/step-instance", body);
    };
    AuthenticationService.prototype.getStepPlanActivities = function (instanceId) {
        return this.http.get(this.baseURL + "step-plan/activities/" + instanceId);
    };
    AuthenticationService.prototype.getStepPlanActivityIdByTemplateId = function (templateId) {
        return this.http.get(this.baseURL + "step-plan/step-plan-activity-id/" + templateId);
    };
    AuthenticationService.prototype.getTemplatesByStepPlanActivity = function (stepPlanActivityId) {
        return this.http.get(this.baseURL + "step-plan/step-activity/templates/" + stepPlanActivityId);
    };
    AuthenticationService.prototype.getStepPlanActivityPdfDocument = function (documentId) {
        return this.http.get(this.baseURL + "step-plan/step-activity/pdf-document/" + documentId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.downloadStepPlanActivityDocument = function (documentId) {
        return this.http.get(this.baseURL + "step-plan/step-activity/document/download/" + documentId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.downloadAllStepPlanActivityDocuments = function (stepPlanActivityId) {
        return this.http.get(this.baseURL + "step-plan/step-activity/templates/download/" + stepPlanActivityId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.downloadStepPlanActivityDocuments = function (stepPlanInstanceId) {
        return this.http.get(this.baseURL + "step-plan/step-activity/download-all/" + stepPlanInstanceId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.downloadAllStepActivityFilingDocuments = function (stepPlanInstanceId) {
        return this.http.get(this.baseURL + "step-plan/step-activity/download-all-filings/" + stepPlanInstanceId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.prototype.deleteStepPlan = function (id) {
        return this.http.delete(this.baseURL + "step-plan/" + id);
    };
    AuthenticationService.prototype.deleteStepPlanInstance = function (id) {
        return this.http.delete(this.baseURL + "step-plan/step-plan-instance/" + id);
    };
    AuthenticationService.prototype.updateStepPlanInstance = function (body) {
        return this.http.put(this.baseURL + "step-plan/step-plan-instance/status", body);
    };
    AuthenticationService.prototype.generateStepActivityDocument = function (activityId, body) {
        return this.http.post(this.baseURL + "step-plan/generate/document/" + activityId, body);
    };
    AuthenticationService.prototype.getBeneficiaries = function (id) {
        return this.http.get(this.baseURL + "beneficalShares/get?investorId=" + id);
    };
    AuthenticationService.prototype.saveNewProfile = function (profileInfo) {
        return this.http.post(this.baseURL + "beneficalShares/saveProfileInfo", profileInfo);
    };
    AuthenticationService.prototype.saveBeneficiaries = function (beneficiaries, id) {
        return this.http.post(this.baseURL + "beneficalShares/save?investorId=" + id, beneficiaries);
    };
    AuthenticationService.prototype.getIndividualBeneficiaryDetails = function (id) {
        return this.http.get(this.baseURL + "beneficalShares/getByProfileId?profileId=" + id);
    };
    AuthenticationService.prototype.getBeneficiariesList = function (id) {
        return this.http.get(this.baseURL + "beneficalShares/getListOfBeneficalsForProfileId?profileId=" + id);
    };
    AuthenticationService.prototype.exportBenefiaryDetails = function (profileId) {
        return this.http.get(this.baseURL + "report-generation/generateBeneficialReportBasedOnProfileId?profileId=" + profileId, {
            responseType: "blob",
            observe: "response"
        });
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
