<div class="container-fluid graybg">
    <div id="overlay"></div>
    <div class="whiteboxwrap">
    <div class="row" *ngIf="!isShow">
        <div class="col-md-12">
            <h5 class="page-hdr top-20 btm-20"> On-Boarding </h5>
            <ol class="breadcrumb bread-crumb">
                <li class="breadcrumb-item"
                 *ngFor="let crumb of breadCrumbs; let i = index">
                 <span class="lvp-label">{{crumb.screen}}</span>
                </li>
            </ol>
            <p class="top-20 content-pg-1 btm-20">
                Provide the details of all transactions impacting the cap table of the company under the respective sections below. You can edit and verify the details multiple times here. Once all details are filled, click on ‘Generate Cap Table’.   
            </p>
        </div>
    </div>

    <div class="row" *ngIf="!isShow && activePath!=''">
        <div class="col-xl-8 col-lg-8 col-xs-12 col-sm-12">
            <mat-horizontal-stepper [disableRipple]="true" labelPosition="bottom" #stepper [linear]="isLinear">
                <div >
                    <mat-step disableRipple="true">
                        <ng-template matStepLabel> Admin Profile
                            <mat-icon (click)="randomStep('adminProfile')">
                                <img src="{{adminProfile}}" alt="adminProfile" />
                            </mat-icon>
                        </ng-template>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel> Company Details
                            <mat-icon (click)="randomStep('companyDetails')">
                                <img src="{{companyDetails}}" alt="companyDetails" />
                            </mat-icon>
                        </ng-template>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel> Incorporation Details
                            <mat-icon (click)="randomStep('incorporationDetails')">
                                <img src="{{incorporationDetails}}" alt="incorporationDetails" />
                            </mat-icon>
                        </ng-template>
                    </mat-step>
                </div>
            </mat-horizontal-stepper>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 btm-20">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
    </div>
</div>
</div>