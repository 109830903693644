import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import * as $ from 'jquery';
import { MatStepper } from '@angular/material';
import { DataService } from '../../services/new-round-notify.service';
import { HelperService } from '../../services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utilities.service';
import { CacheProviderService } from '../../services/cache-provider.service';

@Component({
  selector: 'app-create-new-round',
  templateUrl: './create-new-round.component.html',
  styleUrls: ['./create-new-round.component.scss']
})
export class CreateNewRoundComponent implements OnInit, AfterViewInit {
  amountRaised;
  equityShares;
  preferenceShares;
  numberOfRounds;
  lastValuation;
  lastRaise;
  roundHistoryData;
  activePath;
  randomSelectpath;
  isLinear = false;
  helperTopPosition;
  helper: string = "";

  public roundTab = "../../assets/wizard/step-1.svg";
  public currentInvestorTab = "../../assets/wizard/step-2.svg";
  public newInvestorTab = "../../assets/wizard/step-3.svg";
  public instrumentTab = "../../assets/wizard/step-4.svg";
  public tanchesTab = "../../assets/wizard/step-5.svg";
  public summaryTab = "../../assets/wizard/step-6.svg";

  isShowround: boolean = false;
  isShowcureentInv: boolean = false;
  isShowNewInv: boolean = false;
  isShowInstruments: boolean = false;
  isShowTranches: boolean = false;
  isShowSummary: boolean = false;
  errorFlags;

  public isRoundhelp: boolean = false;
  public helpTitle: any;
  public helpMessage: any;

  public roundName = "";
  public roundSize = "";
  public roundValuation = "";
  public raised = "";
  public leftToRaise = "";
  public leadInvestor = "";
  public isBasicDtlsFilled: boolean = false;
  basicDetails;
  public userDetails;
  public usrCompanyCurrency = "INR - ₹";
  public sharePrice = "";
  public roundInfo;
  public roundCurrency;
  public isRoundNotSaved:boolean = false;

  @ViewChild('stepper') stepper: MatStepper;
  totalStepsCount: number;

  constructor(private router: Router,
    private authService: AuthenticationService,
    private dataService: DataService,
    private helperService: HelperService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService
  ) { 
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
          let path = val.url;
          this.activePath = path.substring(path.lastIndexOf('/') + 1);
      }
  });
  }

  ngAfterViewInit() {
    this.totalStepsCount = this.stepper._steps.length;
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }

  getTotalAsPerRound(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.roundCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
        }
        else {
          return this.utilityService.formatCurrency(this.roundCurrency, value);
        }
      }
    }
  }

  onActivate(event) {
    this.flagInit();
    this.getBasicDetails();
  }

  onDeactivate(event) {
    if (this.errorFlags && this.errorFlags.isRoundDetailsTabValid === true && event.activatedRoute.routeConfig.path === "currentInvestors") {
      this.currentInvestorTab = ".../../assets/wizard/completed.svg";
    }
    else if (this.errorFlags && this.errorFlags.isRoundDetailsTabValid === true &&
      this.errorFlags.isCurrentInvestorsTabValid === true &&
      event.activatedRoute.routeConfig.path === "newInvestors") {
      this.newInvestorTab = "../../assets/wizard/completed.svg";
    }
    else if (this.errorFlags && this.errorFlags.isRoundDetailsTabValid === true &&
      this.errorFlags.isCurrentInvestorsTabValid === true &&
      this.errorFlags.isNewInvestorsTabValid === true &&
      event.activatedRoute.routeConfig.path === "instrument") {
      this.instrumentTab = "../../assets/wizard/completed.svg";
    }
    else if (this.errorFlags && this.errorFlags.isRoundDetailsTabValid === true &&
      this.errorFlags.isCurrentInvestorsTabValid === true &&
      this.errorFlags.isNewInvestorsTabValid === true &&
      this.errorFlags.isInstrumentTabValid === true
      && event.activatedRoute.routeConfig.path === "capTable") {
      this.tanchesTab = "../../assets/wizard/completed.svg";
    }
    else if (this.errorFlags && this.errorFlags.isRoundDetailsTabValid === true &&
      this.errorFlags.isCurrentInvestorsTabValid === true &&
      this.errorFlags.isNewInvestorsTabValid === true &&
      this.errorFlags.isInstrumentTabValid === true &&
      this.errorFlags.isTranchesTabValid === true &&
      event.activatedRoute.routeConfig.path === "summary") {
      this.summaryTab = "../../assets/wizard/inprogress.svg";
    }
  }

  //Error flags handle
  erFlgs() {
    if (this.errorFlags && this.errorFlags.isCurrentInvestorsTabValid === false) {
      this.currentInvestorTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isNewInvestorsTabValid === false) {
      this.newInvestorTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isInstrumentTabValid === false) {
      this.instrumentTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isTranchesTabValid === false) {
      this.tanchesTab = "../../assets/wizard/warning-red.svg";
    }
    if (this.errorFlags && this.errorFlags.isSummaryTabValid === false) {
      this.summaryTab = "../../assets/wizard/warning-red.svg";
    }
  }

  // stepepr flags initiization
  flagInit() {
    this.dataService.getData().subscribe(data => {
      if (data) {
        this.errorFlags = data;
        this.stepperIndicator();
        this.erFlgs();
        // this.activeStep();
      }
      else {
      }
    })
  }

  // stepper step handling
  stepperIndicator() {
    if (this.errorFlags && this.errorFlags.isRoundDetailsTabValid === true) {
      this.roundTab = "../../assets/wizard/completed.svg";
      this.isShowround = true;
      this.isShowcureentInv = false;
      this.isShowNewInv = false;
      this.isShowInstruments = false;
      this.isShowTranches = false;
      this.isShowSummary = false;
    }
    if (this.errorFlags && this.errorFlags.isCurrentInvestorsTabValid === true) {
      this.currentInvestorTab = "../../assets/wizard/completed.svg";
      this.isShowround = false;
      this.isShowcureentInv = true;
      this.isShowNewInv = false;
      this.isShowInstruments = false;
      this.isShowTranches = false;
      this.isShowSummary = false;
    }
    if (this.errorFlags && this.errorFlags.isNewInvestorsTabValid === true) {
      this.newInvestorTab = "../../assets/wizard/completed.svg";
      this.isShowround = false;
      this.isShowcureentInv = false;
      this.isShowNewInv = true;
      this.isShowInstruments = false;
      this.isShowTranches = false;
      this.isShowSummary = false;
    }
    if (this.errorFlags && this.errorFlags.isInstrumentTabValid === true) {
      this.instrumentTab = "../../assets/wizard/completed.svg";
      this.isShowround = false;
      this.isShowcureentInv = false;
      this.isShowNewInv = false;
      this.isShowInstruments = true;
      this.isShowTranches = false;
      this.isShowSummary = false;
    }
    if (this.errorFlags && this.errorFlags.isTranchesTabValid === true) {
      this.tanchesTab = "../../assets/wizard/completed.svg";
      this.isShowround = false;
      this.isShowcureentInv = false;
      this.isShowNewInv = false;
      this.isShowInstruments = false;
      this.isShowTranches = true;
      this.isShowSummary = false;
    }
    if (this.errorFlags && this.errorFlags.isSummaryTabValid === true) {
      this.summaryTab = "../../assets/wizard/completed.svg";
      this.isShowround = false;
      this.isShowcureentInv = false;
      this.isShowNewInv = false;
      this.isShowInstruments = false;
      this.isShowTranches = false;
      this.isShowSummary = true;
    }
    this.activeStep();
  }

  //active step indicator
  activeStep() {
    if (this.activePath === "round") {
      this.stepper.selectedIndex = 0;
      this.roundTab = "../../assets/wizard/inprogress.svg";
    }
    else if (this.activePath === "currentInvestors") {
      this.stepper.selectedIndex = 1;
      this.currentInvestorTab = "../../assets/wizard/inprogress.svg";
    }
    else if (this.activePath === "newInvestors") {
      this.stepper.selectedIndex = 2;
      this.newInvestorTab = "../../assets/wizard/inprogress.svg";
    }
    else if (this.activePath === "instrument") {
      this.stepper.selectedIndex = 3;
      this.instrumentTab = "../../assets/wizard/inprogress.svg";
    }
    else if (this.activePath === "capTable") {
      this.stepper.selectedIndex = 4;
      this.tanchesTab = "../../assets/wizard/inprogress.svg";
    }
    else if (this.activePath === "summary") {
      this.stepper.selectedIndex = 5;
      this.summaryTab = "../../assets/wizard/inprogress.svg";
    }
  }

  // get round history data
  getRoundHistoryData() {
    this.authService.getRoundHistoryData().subscribe((response) => {
      if (response) {
        this.roundHistoryData = response;
        this.amountRaised = this.roundHistoryData['amountRaised'];
        this.equityShares = this.roundHistoryData['equityShares'];
        this.preferenceShares = this.roundHistoryData['preferenceShares'];
        this.numberOfRounds = this.roundHistoryData['numberOfRounds'];
        this.lastValuation = this.roundHistoryData['lastValuation'];
        this.lastRaise = this.roundHistoryData['lastRaise'];
      }
    })
  }

  //get Basic Details
  getBasicDetails() {
    this.dataService.getBasicData().subscribe(data => {
      if (data) {
        this.basicDetails = data;
        this.isBasicDtlsFilled = data['isBasicDetailsFilled'];
        this.roundName = data['roundName'];
        this.roundSize = data['roundSize'];
        this.roundValuation = data['roundValuation'];
        this.raised = data['amountRaised'];
        this.leftToRaise = data['amountLeftToRaise'];
        this.leadInvestor = data['leadInvestorName'];
        this.sharePrice = data['sharePrice'];
        this.roundCurrency = data['roundCurrency'];
        this.isRoundNotSaved = data['isRoundNotSaved'];
      }
      else {
        this.isBasicDtlsFilled = false;
        this.roundName = "";
        this.roundSize = "";
        this.roundValuation = "";
        this.raised = "";
        this.leftToRaise = "";
        this.leadInvestor = "";
        this.sharePrice = "";
        this.roundCurrency = "";
      }
    })
  }

  // Randoam step click
  randomStep(name) {
    this.randomSelectpath = name;
    if (this.randomSelectpath === "round") {
      if (this.errorFlags && this.errorFlags.isRoundDetailsTabValid === true) {
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'round']);
      }
      else {
        this.toastr.error("Please fill round-tab form first", "Error");
      }
    }
    else if (this.randomSelectpath === "currentInvestors") {
      if (this.errorFlags && this.errorFlags.isCurrentInvestorsTabValid === true) {
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'currentInvestors']);
      }
      else {
        this.toastr.error("Please fill round-tab form first", "Error");
      }
    }
    else if (this.randomSelectpath === "newInvestors") {
      if (this.errorFlags && this.errorFlags.isNewInvestorsTabValid === true) {
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'newInvestors']);
      }
      else {
        this.toastr.error("Please fill Current investor form", "Error");
      }
    }
    else if (this.randomSelectpath === "instrument") {
      if (this.errorFlags && this.errorFlags.isInstrumentTabValid === true) {
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'instrument']);
      }
      else {
        this.toastr.error("Please fill New investor form", "Error");
      }
    }
    else if (this.randomSelectpath === "capTable") {
      if (this.errorFlags && this.errorFlags.isTranchesTabValid === true) {
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'capTable']);
      }
      else {
        this.toastr.error("Please fill Instruments form", "Error");
      }
    }
    else if (this.randomSelectpath === "summary") {
      if (this.errorFlags && this.errorFlags.isSummaryTabValid === true) {
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'newRound', 'summary']);
      }
      else {
        this.toastr.error("Please fill all forms", "Error");
      }
    }
  }

  ngOnInit() {
    this.getRoundHistoryData();
    $('.nav-item').addClass('speech-bubble-white');
    $('.nav-item').removeClass('active');
    $('.nav-link').removeClass('active');
    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.flagInit();
    // this.isRoundNotSaved = true;
  }

  hideHelp() {
    this.isRoundhelp = false;
    this.helper = "";
  }

  showHelp(section) {
    let help = this.helperService.getHelpContent("CreateNewRoundComponent", section);
    this.helper = section;
    this.helpTitle = help['name'];
    this.helpMessage = help['description'];
    const targetElement = document.getElementById(section);
    if(targetElement != null){
      this.helperTopPosition = targetElement.offsetTop;
    }
    if (section === 'Create_NewRound') {
      this.isRoundhelp = true;
    }
    setTimeout(() => {
      if (document.getElementById('createrndHlp') != null) {
        document.getElementById('createrndHlp').innerHTML = help.description;
      }
      else {
      }
    }, 100);
  }

  formatCompanyCurrency(value){
    return this.utilityService.inputCSVformatCurrency1(this.usrCompanyCurrency, value);
  }

  formatRoundCurrency(value){
    return this.utilityService.inputCSVformatCurrency1(this.roundCurrency, value);
  }
  
}
