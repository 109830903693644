import {ShareholderDetailsModel} from "./shareholder-details.model";

export class IncorporationDetailsModel {
     authorizedShareCapital: number;
     parValue: number;
     companyCurrency: string;
     shareholders: ShareholderDetailsModel[];
} 



      