import { Injectable } from '@angular/core';
import { CanActivate, Route, Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../services/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard implements CanActivate {
    constructor(private permService: PermissionsService, private router: Router) {}
    canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      let permissionRequired = route.data["permissionRequired"] as Array<string>;
      if(this.permService.authorizeUser(permissionRequired)){
        return true;
      }
      else{
        this.router.navigateByUrl("/landing/dashboard/401");
        return false;
      }
    } 
  }
