import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/authentication.service';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { UtilityService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['./add-beneficiary.component.scss']
})
export class AddBeneficiaryComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() profileId;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  beneficiaryList = [];
  namesList = [];
  usrCompanyCurrency: any = null;
  shareDistinctiveNumberDto;

  constructor(
    private authService: AuthenticationService,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService,
    private toastr: ToastrService,
  ) { 
    this.dxCustomizeCsv = this.dxCustomizeCsv.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
    this.authService.getBeneficiaries(this.profileId).subscribe(
      (res) => {
        this.shareDistinctiveNumberDto = res['shareDistinctiveNumberDto'];
        this.beneficiaryList = res['beneficalDetails'];
        this.beneficiaryList.forEach(element => {
          let str = '';
          element['shareDistinctiveNumbers'].forEach(ele => {
            str = str + ele['shareStartNumber'] + '-' + ele['shareEndNumber'] + ', '
          });
          element['shareNumString'] = str;
        });
        this.namesList = res['allInvestorProfiles'];
      },
      (e) => {}
    );
    if(this.cacheService.get("userDetails")){
      let userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = userDetails['company']['companyCurrency'];
    } 
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit(false);
  }

  saveBeneficiaries() {
    this.beneficiaryList.forEach(element => {
      delete element['shareNumString'];
      element['id'] = element['investorProfile']['id'];
    });
    this.authService.saveBeneficiaries(this.beneficiaryList, this.profileId).subscribe(
      (res) => {
        this.showDialog = false;
        this.visibleChange.emit(false);
        this.toastr.success('Beneficiaries Updated Successfully!');
      },
      (e) => {
        this.toastr.error(e.error.reason);
      }
    );
  }

  logEvent(eventName, event){
    switch(eventName){
      case 'Initialized':
        event.component.columnOption("numberOfShares", {
          editorOptions: {
            format: "#,##,##0"
          }
        });
        break;
      
      case 'RowInserted':
        event.component.option("focusedRowKey",event.key);
        break;
      
      case 'RowInserting':
        let profile = this.namesList.find((profile) => {
          if(profile.name == event.data.beneficiaryName){
            return profile;
          }
        });
        let investorProfile = {
          "id": profile.id,
          "typeOfShareholder": profile.typeOfShareholder,
          "shareholderSubType": profile.shareholderSubType,
          "groupName": profile.groupName,
          "registeredAddress": profile.registeredAddress,
          "corporateAddress": profile.corporateAddress,
          "residentStatus": profile.residentStatus,
          "occupation": profile.occupation,
          "nationality": profile.nationality,
          "contactNumber": profile.contactNumber,
          "emailId": profile.emailId,
          "legalName": profile.legalName,
          "imageBase64String": profile.imageBase64String,
          "name": profile.name,
          "imageName": profile.imageName,
          "contenttype": profile.contenttype,
          "file": profile.file,
          "documentPath": profile.documentPath,
          "imageUrl": profile.imageUrl,
          "dpId": profile.dpId,
          "clientId": profile.clientId,
          "depositoryName": profile.depositoryName,
          "isPromoter": profile.isPromoter,
          "isRelativeOfDirector": profile.isRelativeOfDirector
        };
        event.data.investorProfile = investorProfile;
        break;

    }
  }

  onSave(event) {
    console.log('on Save: ', event)
  }

  setShareDistNum(newData, value, currentRowData) { 
    let strArray = [];
    newData['shareDistinctiveNumbers'] = [];
    if(value) {
      strArray = value.split(',');  
      strArray.forEach(element => {
        let numArray = element.split('-');  
        numArray.forEach(ele => {
          ele.trim();
          ele.endsWith(',') ? ele.slice(0, -1) : ele;
          ele.trim();
        });
        newData['shareDistinctiveNumbers'].push({
          "shareStartNumber": numArray[0],
          "shareEndNumber": numArray[1],
          "totalNumberOfShares": parseInt(numArray[1]) - parseInt(numArray[0]) + 1
        })
        newData['shareNumString'] = value;
      })
    }
  }

  datePickerChanged(cellData, event){
    let dt = new Date(event.target.value._d);
    cellData.setValue(dt.toISOString().replace("Z", "+0000"));
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  tooltipFormat(value) {
    return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
  }

  dxCustomizeCsv(cellValue) {
    const value = this.getTotalValueTble(cellValue.value);
    if (value === 0) {
      return '0';
    }
    else {
      return value;
    }
  }

}
