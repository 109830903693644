var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../authentication.service";
import { UtilityService } from "../../services/utilities.service";
import { DataService } from '../../services/new-round-notify.service';
import { HelperService } from '../../services/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from '../../services/cache-provider.service';
import { MatDialog } from '@angular/material';
import { ConfirmDeleteComponent } from "src/app/confirm-delete/confirm-delete.component";
var NewInvestorsTabComponent = /** @class */ (function () {
    function NewInvestorsTabComponent(activatedRoute, authService, router, utilityService, dataService, helperService, ngxLoader, toastr, cacheService, dialog) {
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.router = router;
        this.utilityService = utilityService;
        this.dataService = dataService;
        this.helperService = helperService;
        this.ngxLoader = ngxLoader;
        this.toastr = toastr;
        this.cacheService = cacheService;
        this.dialog = dialog;
        this.outstandingConv = [];
        this.existingShareholderNamesForDropdown = [];
        this.finalPostRoundTotal = 0;
        this.totalAmtToBeInvested = 0;
        this.newInvActualPercentage = 0;
        this.saveInvestorProfile = [];
        this.curConvInvAmt = 0;
        this.exchangeRate = 1;
        this.isValid = false;
        this.newGroupObj = {
            groupName: "",
            description: ""
        };
        this.newInvestors = [];
        this.isInvestmentMet = false;
        this.onLoading = true;
        this.disableripple = true;
        this.expanded = false;
        this.expandAll = false;
        this.isOpen = false;
        this.isNonzero = false;
        this.helper = "";
        this.helperTopPosition = null;
        this.isRoundNotSaved = false;
        this.isCaptablehelp = false;
        this.isNewInvtorhelp = false;
        this.profilePicture = null;
        this.authenticator = null;
        this.croppedImage = null;
        this.shouldShowDialog = false;
        this.profilePicValid = false;
        this.newList = [];
        this.empImg = false;
        this.content = "image/jpeg";
        this.groupdNameErr = false;
        this.remainingAmount = 0;
        this.isindividualValid = false;
        this.maxLength = 9;
        this.investorProfileInfo = {
            isLeadInvestor: false,
            investorName: "",
            investmentPercentage: 0,
            investmentAmount: 0,
            numberOfShares: 0,
            typeOfInvestor: "",
            groupName: {
                groupName: ""
            },
            registeredAddress: "",
            residentStatus: "",
            occupation: "",
            nationality: "",
            contactNumber: "",
            emailId: "",
            legalName: "",
            imageBase64String: null,
            imageUrl: null,
            imageName: "",
            contenttype: "",
            documentPath: ""
        };
        this.mShareholderType = false;
        this.mGroup = false;
        this.mEmail = false;
        this.mlegalName = false;
        this.mNationality = false;
        this.mResidence = false;
        this.mvalid = false;
        this.selectedResidentType = "India";
        this.selectedCountry = "India";
        this.groupMandatory = false;
        this.insName = false;
        this.insAmt = false;
        this.showdigPrfle = false;
        this.fromNewInv = true;
    }
    /** Current Investor Captable increment decrement **/
    NewInvestorsTabComponent.prototype.increment = function (investor, group) {
        var _this = this;
        if ((this.newInvRequiredPercentage).toFixed(4) <= 0) {
            this.toastr.error("Investment Met Already!", "Error");
            this.checkInvestmentMet(this.remainingAmount);
            return;
        }
        var val = investor.postRound + (1 / 1000);
        val = val * 100;
        if ((this.newInvRequiredPercentage * 100 + investor.postRound * 100) < (val)) {
            this.toastr.error("Value greater than the remaining!", "Error");
            return;
        }
        investor.postRound = investor.postRound + 1 / 1000;
        if (group['groupName']) {
            this.shareholdersData.forEach(function (element) {
                if (element.shareholderGroups) {
                    var parentPostTotal_1 = 0;
                    element.shareholderGroups.forEach(function (subelement) {
                        if (subelement['groupName'] == group['groupName']) {
                            var postRoundTotal_1 = 0;
                            subelement["shareholders"].forEach(function (individualElement) {
                                postRoundTotal_1 = postRoundTotal_1 + individualElement["postRound"];
                            });
                            subelement["postRoundPercentageOfGroup"] = postRoundTotal_1;
                        }
                        parentPostTotal_1 = parentPostTotal_1 + subelement['postRoundPercentageOfGroup'];
                    });
                    element["postRoundPercentageOfType"] = parentPostTotal_1;
                }
            });
        }
        else if (group['shareholderGroups'] == null) {
            this.shareholdersData.forEach(function (groupElement) {
                if (groupElement["shareholderType"] == group["shareholderType"]) {
                    var postRoundTotal_2 = 0;
                    groupElement["shareholders"].forEach(function (individualElement) {
                        postRoundTotal_2 = postRoundTotal_2 + individualElement["postRound"];
                    });
                    groupElement["postRoundPercentageOfType"] = postRoundTotal_2;
                }
            });
        }
        var investorObj = {
            name: investor.shareholder,
            rightToMaintainType: null,
            preRoundPercentage: investor.preRound,
            postRoundPercentage: investor.postRound
        };
        this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(function (res) {
            group["shareholders"].forEach(function (element) {
                if (element["shareholder"] == res["name"]) {
                    element["amountToBeInvested"] = res["investmentAmount"];
                    if (group['groupName']) {
                        _this.shareholdersData.forEach(function (element) {
                            if (element.shareholderGroups) {
                                var parentAmountTobeInvestedTotal_1 = 0;
                                element.shareholderGroups.forEach(function (subelement) {
                                    if (subelement['groupName'] == group['groupName']) {
                                        var amountToBeInvestedTotal_1 = 0;
                                        subelement["shareholders"].forEach(function (individualElement) {
                                            amountToBeInvestedTotal_1 = amountToBeInvestedTotal_1 + individualElement["amountToBeInvested"];
                                        });
                                        subelement["amountToBeInvestedOfGroup"] = amountToBeInvestedTotal_1;
                                    }
                                    parentAmountTobeInvestedTotal_1 = parentAmountTobeInvestedTotal_1 + subelement["amountToBeInvestedOfGroup"];
                                });
                                element["amountToBeInvestedOfType"] = parentAmountTobeInvestedTotal_1;
                                _this.totalAmtToBeInvested = parentAmountTobeInvestedTotal_1;
                            }
                        });
                    }
                    else if (group['shareholderGroups'] == null) {
                        var totAmount_1 = 0;
                        _this.shareholdersData.forEach(function (groupElement) {
                            if (groupElement["shareholderType"] == group["shareholderType"]) {
                                var amountToBeInvestedTotal_2 = 0;
                                groupElement["shareholders"].forEach(function (individualElement) {
                                    amountToBeInvestedTotal_2 = amountToBeInvestedTotal_2 + individualElement["amountToBeInvested"];
                                });
                                groupElement["amountToBeInvestedOfType"] = amountToBeInvestedTotal_2;
                            }
                            totAmount_1 = totAmount_1 + groupElement["amountToBeInvestedOfType"];
                        });
                        _this.totalAmtToBeInvested = totAmount_1;
                    }
                }
            });
            var newInvestorTotal = 0;
            var newInvPostRoundTotal = 0;
            if (_this.newInvestors.length > 0) {
                _this.newInvestors.forEach(function (investor) {
                    if (typeof investor.investmentAmount === "string") {
                        var amt = investor.investmentAmount.replace(/,/g, "") / 1;
                        newInvestorTotal += amt;
                        newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                    }
                    else {
                        var amt = investor.investmentAmount;
                        newInvestorTotal += +amt;
                        newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                    }
                });
            }
            _this.remainingAmount = 0;
            _this.remainingAmount = _this.roundSize - (_this.totalAmtToBeInvested + newInvestorTotal);
            var groupTotal = 0;
            _this.shareholdersData.forEach(function (group) {
                groupTotal = groupTotal + group["postRoundPercentageOfType"];
            });
            _this.finalPostRoundTotal = groupTotal + newInvPostRoundTotal;
            _this.newInvRequiredPercentage = 1 - _this.finalPostRoundTotal;
            _this.checkInvestmentMet(_this.remainingAmount);
        }, function (e) {
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    NewInvestorsTabComponent.prototype.decrement = function (investor, group) {
        var _this = this;
        if (investor.postRound - 1 / 1000 < 0) {
            this.toastr.error("Value should not be in negative", "Error");
            investor.postRound = investor.postRound;
            return;
        }
        investor.postRound = investor.postRound - 1 / 1000;
        if (investor.postRound < investor.actualPostRound) {
            investor.postRound = investor.actualPostRound;
            this.toastr.error("The updated shareholder % should not below the current diluted %, the current diluted % is:" + (investor.actualPostRound * 100).toFixed(2), "Error");
            return;
        }
        if (group['groupName']) {
            this.shareholdersData.forEach(function (element) {
                if (element.shareholderGroups) {
                    var parentPostTotal_2 = 0;
                    element.shareholderGroups.forEach(function (subelement) {
                        if (subelement['groupName'] == group['groupName']) {
                            var postRoundTotal_3 = 0;
                            subelement["shareholders"].forEach(function (individualElement) {
                                postRoundTotal_3 = postRoundTotal_3 + individualElement["postRound"];
                            });
                            subelement["postRoundPercentageOfGroup"] = postRoundTotal_3;
                        }
                        parentPostTotal_2 = parentPostTotal_2 + subelement['postRoundPercentageOfGroup'];
                    });
                    element["postRoundPercentageOfType"] = parentPostTotal_2;
                }
            });
        }
        else if (group['shareholderGroups'] == null) {
            this.shareholdersData.forEach(function (groupElement) {
                if (groupElement["shareholderType"] == group["shareholderType"]) {
                    var postRoundTotal_4 = 0;
                    groupElement["shareholders"].forEach(function (individualElement) {
                        postRoundTotal_4 = postRoundTotal_4 + individualElement["postRound"];
                    });
                    groupElement["postRoundPercentageOfType"] = postRoundTotal_4;
                }
            });
        }
        var investorObj = {
            name: investor.shareholder,
            rightToMaintainType: null,
            preRoundPercentage: investor.preRound,
            postRoundPercentage: investor.postRound
        };
        this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(function (res) {
            group["shareholders"].forEach(function (element) {
                if (element["shareholder"] == res["name"]) {
                    element["amountToBeInvested"] = res["investmentAmount"];
                    if (group['groupName']) {
                        _this.shareholdersData.forEach(function (element) {
                            if (element.shareholderGroups) {
                                var parentAmountTobeInvestedTotal_2 = 0;
                                element.shareholderGroups.forEach(function (subelement) {
                                    if (subelement['groupName'] == group['groupName']) {
                                        var amountToBeInvestedTotal_3 = 0;
                                        subelement["shareholders"].forEach(function (individualElement) {
                                            amountToBeInvestedTotal_3 = amountToBeInvestedTotal_3 + individualElement["amountToBeInvested"];
                                        });
                                        subelement["amountToBeInvestedOfGroup"] = amountToBeInvestedTotal_3;
                                    }
                                    parentAmountTobeInvestedTotal_2 = parentAmountTobeInvestedTotal_2 + subelement["amountToBeInvestedOfGroup"];
                                });
                                element["amountToBeInvestedOfType"] = parentAmountTobeInvestedTotal_2;
                                _this.totalAmtToBeInvested = parentAmountTobeInvestedTotal_2;
                            }
                            else {
                            }
                        });
                    }
                    else if (group['shareholderGroups'] == null) {
                        var totAmount_2 = 0;
                        _this.shareholdersData.forEach(function (groupElement) {
                            if (groupElement["shareholderType"] == group["shareholderType"]) {
                                var amountToBeInvestedTotal_4 = 0;
                                groupElement["shareholders"].forEach(function (individualElement) {
                                    amountToBeInvestedTotal_4 = amountToBeInvestedTotal_4 + individualElement["amountToBeInvested"];
                                });
                                groupElement["amountToBeInvestedOfType"] = amountToBeInvestedTotal_4;
                            }
                            totAmount_2 = totAmount_2 + groupElement["amountToBeInvestedOfType"];
                        });
                        _this.totalAmtToBeInvested = totAmount_2;
                    }
                }
            });
            var newInvestorTotal = 0;
            var newInvPostRoundTotal = 0;
            if (_this.newInvestors.length > 0) {
                _this.newInvestors.forEach(function (investor) {
                    if (typeof investor.investmentAmount === "string") {
                        var amt = investor.investmentAmount.replace(/,/g, "") / 1;
                        newInvestorTotal += amt;
                        newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                    }
                    else {
                        var amt = investor.investmentAmount;
                        newInvestorTotal += +amt;
                        newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                    }
                });
            }
            _this.remainingAmount = 0;
            _this.remainingAmount = _this.roundSize - (_this.totalAmtToBeInvested + newInvestorTotal);
            var groupTotal = 0;
            _this.shareholdersData.forEach(function (group) {
                groupTotal = groupTotal + group["postRoundPercentageOfType"];
            });
            _this.finalPostRoundTotal = groupTotal + newInvPostRoundTotal;
            _this.newInvRequiredPercentage = 1 - _this.finalPostRoundTotal;
            _this.checkInvestmentMet(_this.remainingAmount);
        }, function (e) {
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    NewInvestorsTabComponent.prototype.getAmtbyCustVal = function (investor, group, vall) {
        var _this = this;
        var val = vall / 100;
        if (!val) {
            this.toastr.error("Invalid input!", "Error");
            return;
        }
        if (this.newInvRequiredPercentage.toFixed(4) / 1 <= 0) {
            this.toastr.error("Investment Met Already!", "Error");
            return;
        }
        if (val < 0) {
            this.toastr.error("Value should not be in negative!", "Error");
            investor.postRound = investor.postRound;
            return;
        }
        if (val >= investor.actualPostRound && val <= (investor.postRound + this.newInvRequiredPercentage.toFixed(4) / 1)) {
            investor.postRound = val;
        }
        else {
            this.toastr.error("Value should not exceed than required!", "Error");
            investor.postRound = investor.postRound;
            return;
        }
        if (group['groupName']) {
            this.shareholdersData.forEach(function (element) {
                if (element.shareholderGroups) {
                    var parentPostTotal_3 = 0;
                    element.shareholderGroups.forEach(function (subelement) {
                        if (subelement['groupName'] == group['groupName']) {
                            var postRoundTotal_5 = 0;
                            subelement["shareholders"].forEach(function (individualElement) {
                                postRoundTotal_5 = postRoundTotal_5 + individualElement["postRound"];
                            });
                            subelement["postRoundPercentageOfGroup"] = postRoundTotal_5;
                        }
                        parentPostTotal_3 = parentPostTotal_3 + subelement['postRoundPercentageOfGroup'];
                    });
                    element["postRoundPercentageOfType"] = parentPostTotal_3;
                }
            });
        }
        else if (group['shareholderGroups'] == null) {
            this.shareholdersData.forEach(function (groupElement) {
                if (groupElement["shareholderType"] == group["shareholderType"]) {
                    var postRoundTotal_6 = 0;
                    groupElement["shareholders"].forEach(function (individualElement) {
                        postRoundTotal_6 = postRoundTotal_6 + individualElement["postRound"];
                    });
                    groupElement["postRoundPercentageOfType"] = postRoundTotal_6;
                }
            });
        }
        var investorObj = {
            name: investor.shareholder,
            rightToMaintainType: null,
            preRoundPercentage: investor.preRound,
            postRoundPercentage: investor.postRound
        };
        this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(function (res) {
            group["shareholders"].forEach(function (element) {
                if (element["shareholder"] == res["name"]) {
                    element["amountToBeInvested"] = res["investmentAmount"];
                    element["postRound"] = res['postRoundPercentage'];
                    if (group['groupName']) {
                        _this.shareholdersData.forEach(function (element) {
                            if (element.shareholderGroups) {
                                var parentAmountTobeInvestedTotal_3 = 0;
                                element.shareholderGroups.forEach(function (subelement) {
                                    if (subelement['groupName'] == group['groupName']) {
                                        var amountToBeInvestedTotal_5 = 0;
                                        subelement["shareholders"].forEach(function (individualElement) {
                                            amountToBeInvestedTotal_5 = amountToBeInvestedTotal_5 + individualElement["amountToBeInvested"];
                                        });
                                        subelement["amountToBeInvestedOfGroup"] = amountToBeInvestedTotal_5;
                                    }
                                    parentAmountTobeInvestedTotal_3 = parentAmountTobeInvestedTotal_3 + subelement["amountToBeInvestedOfGroup"];
                                });
                                element["amountToBeInvestedOfType"] = parentAmountTobeInvestedTotal_3;
                                _this.totalAmtToBeInvested = parentAmountTobeInvestedTotal_3;
                            }
                        });
                    }
                    else if (group['shareholderGroups'] == null) {
                        var totAmount_3 = 0;
                        _this.shareholdersData.forEach(function (groupElement) {
                            if (groupElement["shareholderType"] == group["shareholderType"]) {
                                var amountToBeInvestedTotal_6 = 0;
                                groupElement["shareholders"].forEach(function (individualElement) {
                                    amountToBeInvestedTotal_6 = amountToBeInvestedTotal_6 + individualElement["amountToBeInvested"];
                                });
                                groupElement["amountToBeInvestedOfType"] = amountToBeInvestedTotal_6;
                            }
                            totAmount_3 = totAmount_3 + groupElement["amountToBeInvestedOfType"];
                        });
                        _this.totalAmtToBeInvested = totAmount_3;
                    }
                }
            });
            var newInvestorTotal = 0;
            var newInvPostRoundTotal = 0;
            if (_this.newInvestors.length > 0) {
                _this.newInvestors.forEach(function (investor) {
                    if (typeof investor.investmentAmount === "string") {
                        var amt = investor.investmentAmount.replace(/,/g, "") / 1;
                        newInvestorTotal += amt;
                        newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                    }
                    else {
                        var amt = investor.investmentAmount;
                        newInvestorTotal += +amt;
                        newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                    }
                });
            }
            _this.remainingAmount = 0;
            _this.remainingAmount = _this.roundSize - (_this.totalAmtToBeInvested + newInvestorTotal);
            var groupTotal = 0;
            _this.shareholdersData.forEach(function (group) {
                groupTotal = groupTotal + group["postRoundPercentageOfType"];
            });
            _this.finalPostRoundTotal = groupTotal + newInvPostRoundTotal;
            _this.newInvRequiredPercentage = 1 - _this.finalPostRoundTotal;
            _this.checkInvestmentMet(_this.remainingAmount);
        }, function (e) {
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    /** Current Investor Captable increment decrement **/
    /** New Investor increment decrement **/
    NewInvestorsTabComponent.prototype.incrementNewInv = function (investor, i) {
        var _this = this;
        if ((this.newInvRequiredPercentage).toFixed(4) <= 0) {
            this.toastr.error("Investment Met Already!", "Error");
            return;
        }
        if ((this.newInvRequiredPercentage * 100) < 0.10) {
            this.toastr.error("Value will be greater than the Required!, Please adjust Investment Amount manually", "Error");
            return;
        }
        var val = investor.investmentPercentage + (1 / 1000);
        if ((val * 100).toFixed(2) > (this.newInvRequiredPercentage * 100).toFixed(2)) {
            if (((val * 100) - (this.newInvActualPercentage * 100)).toFixed(2) > (this.newInvRequiredPercentage * 100).toFixed(2)) {
                this.toastr.error("Entered Value is greater than the Required!", "Error");
                return;
            }
        }
        investor.investmentPercentage = investor.investmentPercentage + (1 / 1000);
        var totalActualPercentage = 0;
        this.newInvestors.forEach(function (element) {
            totalActualPercentage =
                totalActualPercentage + element.investmentPercentage;
        });
        this.newInvActualPercentage = totalActualPercentage;
        this.totalPostPercentage = this.newInvActualPercentage + this.finalPostRoundTotal;
        this.newInvRequiredPercentage = 1 - this.totalPostPercentage;
        var investorObj = {
            name: investor.shareholder,
            rightToMaintainType: null,
            preRoundPercentage: 0,
            postRoundPercentage: investor.investmentPercentage
        };
        this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(function (res) {
            var re = res["investmentAmount"];
            investor.investmentAmount = _this.utilityService.inputCSVformatCurrencyRound(_this.roundCurrency, re);
            investor.numberOfShares = res["numberOfShares"];
            _this.getTotalAmountInvested(_this.newInvestors);
        }, function (e) {
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    NewInvestorsTabComponent.prototype.getNameNewInv = function (investor, i) {
        this.newInvestors[i].investorName = investor.investorName.replace(/ \t/g, '').replace(/ +(?= )/g, '').trim();
    };
    NewInvestorsTabComponent.prototype.getAmtbyCustValNewInv = function (investor, i, val) {
        var _this = this;
        if (val === "") {
            this.toastr.error("Invalid input!", "Error");
            return;
        }
        var numVal = +val;
        if (numVal <= 0) {
            investor.postRound = investor.postRound;
            this.toastr.error("Value should not be in negative! or Zero", "Error");
            return;
        }
        var value = numVal / 100;
        this.newInvestors.forEach(function (element, index) {
            if (i === index) {
                element.investmentPercentage = 0;
            }
        });
        this.getTotalAmountInvested(this.newInvestors);
        if (value > this.newInvRequiredPercentage) {
            if (((value + this.newInvActualPercentage) * 100) > (this.newInvRequiredPercentage * 100)) {
                this.newInvestors[i].postRound = investor.postRound;
                this.toastr.error("Entered Value is greater than the Required!", "Error");
                return;
            }
        }
        if (this.newInvRequiredPercentage <= 0) {
            this.toastr.error("Investment Met Already!", "Error");
            return;
        }
        investor.investmentPercentage = value;
        var investorObj = {
            name: investor.shareholder,
            rightToMaintainType: null,
            preRoundPercentage: 0,
            postRoundPercentage: investor.investmentPercentage
        };
        this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(function (res) {
            var re = res["investmentAmount"];
            investor.investmentAmount = _this.utilityService.inputCSVformatCurrencyRound(_this.roundCurrency, re);
            investor.numberOfShares = res["numberOfShares"];
            _this.getTotalAmountInvested(_this.newInvestors);
        }, function (e) {
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    NewInvestorsTabComponent.prototype.decrementNewInv = function (investor, i) {
        var _this = this;
        if (investor.investmentPercentage - (1 / 1000) < 0) {
            investor.investmentPercentage = investor.investmentPercentage;
            this.toastr.error("Value should not be in negative!", "Error");
            return;
        }
        investor.investmentPercentage = investor.investmentPercentage - 1 / 1000;
        var totalActualPercentage = 0;
        this.newInvestors.forEach(function (element) {
            totalActualPercentage =
                totalActualPercentage + element.investmentPercentage;
        });
        this.newInvActualPercentage = totalActualPercentage;
        this.totalPostPercentage = this.newInvActualPercentage + this.finalPostRoundTotal;
        this.newInvRequiredPercentage = 1 - this.totalPostPercentage;
        var investorObj = {
            name: investor.shareholder,
            rightToMaintainType: null,
            preRoundPercentage: 0,
            postRoundPercentage: investor.investmentPercentage
        };
        this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(function (res) {
            var re = res["investmentAmount"];
            investor.investmentAmount = _this.utilityService.inputCSVformatCurrencyRound(_this.roundCurrency, re);
            investor.numberOfShares = res["numberOfShares"];
            _this.getTotalAmountInvested(_this.newInvestors);
        }, function (e) {
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    /** New Investor increment decrement **/
    /** Is new investors post round editable **/
    NewInvestorsTabComponent.prototype.isNewInvestorEditable = function () {
        if (this.newInvRequiredPercentage) {
            if (this.newInvRequiredPercentage.toFixed(4) <= 0) {
                return true;
            }
            else {
                return false;
            }
        }
    };
    /** Check if Investment met **/
    NewInvestorsTabComponent.prototype.checkInvestmentMet = function (val) {
        if (val > 0 || val < 0) {
            this.isInvestmentMet = false;
            return false;
        }
        else {
            this.isInvestmentMet = true;
            return true;
        }
    };
    /** Edit new investor **/
    NewInvestorsTabComponent.prototype.editNewInvestorRow = function (index) {
        this.editNewInvestor = index;
        this.isindividualValid = false;
    };
    NewInvestorsTabComponent.prototype.amountNewInvCSV = function (investor, i, e) {
        var rst;
        if (e) {
            // store current positions in variables
            var start = e.target.selectionStart, end = e.target.selectionEnd;
        }
        var amt = investor.investmentAmount;
        rst = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, amt);
        if (e) {
            if (e.target.value) {
                var CSVLength = rst.length;
                var valueLength = e.target.value.length;
                if (CSVLength > valueLength) {
                    e.target.value = rst;
                    var difference = CSVLength - valueLength;
                    start = start + difference;
                    end = end + difference;
                }
                else if (CSVLength < valueLength) {
                    e.target.value = rst;
                    if (start != 0 || end != 0) {
                        var difference = valueLength - CSVLength;
                        start = start - difference;
                        end = end - difference;
                    }
                    else {
                        start = start;
                        end = end;
                    }
                }
                else {
                    e.target.value = rst;
                }
            }
            // restore from variables...
            e.target.setSelectionRange(start, end);
        }
    };
    // to calculate remaining amount each time
    NewInvestorsTabComponent.prototype.ramainAmountCheck = function () {
        var totAmt = 0;
        this.newInvestors.forEach(function (investor) {
            if (typeof investor.investmentAmount === "string") {
                var amt = investor.investmentAmount.replace(/,/g, "") / 1;
                totAmt += amt;
            }
            else {
                var amt = investor.investmentAmount;
                totAmt += amt;
            }
        });
        this.totInvMet = +totAmt;
        this.remainingAmount = 0;
        this.remainingAmount = this.roundSize - (this.amountRaisedFromCurrentInvestors + this.totInvMet);
        return this.remainingAmount;
    };
    NewInvestorsTabComponent.prototype.getPercentByAmountNewInv = function (investor, i) {
        var _this = this;
        var investorObj = {
            "name": investor.shareholder,
            "rightToMaintainType": null,
            "preRoundPercentage": 0,
            "postRoundPercentage": null,
            "investmentAmount": investor.investmentAmount.replace(/,/g, "") / 1
        };
        var remainingAmount = this.ramainAmountCheck();
        var remains = Math.sign(remainingAmount);
        if ((remains === 1 || remains === 0) && investorObj.investmentAmount >= this.sharePrice) {
            this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(function (res) {
                if (res['investmentAmount'] > investorObj.investmentAmount || res['investmentAmount'] < investorObj.investmentAmount) {
                    _this.toastr.info("Minor adjustments in investment amount made to make it a multiple of share price");
                }
                var investmentAmount = res['investmentAmount'];
                investor.investmentPercentage = res['postRoundPercentage'];
                investor.numberOfShares = res["numberOfShares"];
                _this.newInvestors[i].investmentAmount = _this.utilityService.inputCSVformatCurrencyRound(_this.roundCurrency, investmentAmount);
                _this.getTotalAmountInvested(_this.newInvestors);
            }, function (e) {
                _this.toastr.error("Error: " + e.error.reason, "Error");
            });
        }
        else if ((remains === 1 || remains === 0) && investorObj.investmentAmount < this.sharePrice) {
            this.newInvestors[i].investmentAmount = 0;
            this.remainingAmount = this.ramainAmountCheck();
            this.toastr.error("Entered amount lesser than One Share Price!", "Error");
        }
        else {
            this.newInvestors[i].investmentAmount = 0;
            this.remainingAmount = this.ramainAmountCheck();
            this.toastr.error("Entered amount exceeded than Remaining investment!", "Error");
        }
    };
    /** Calculate Total Investment of New investor **/
    NewInvestorsTabComponent.prototype.getTotalAmountInvested = function (newInv) {
        var totAmt = 0;
        var newInvPostRoundTotal = 0;
        if (newInv) {
            this.newInvestors.forEach(function (investor) {
                if (typeof investor.investmentAmount === "string") {
                    var amt = investor.investmentAmount.replace(/,/g, "") / 1;
                    totAmt += amt;
                    newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                }
                else {
                    var amt = investor.investmentAmount;
                    totAmt += +amt;
                    newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
                }
            });
        }
        this.totInvMet = +totAmt;
        this.remainingAmount = 0;
        this.newInvRequiredPercentage = 0;
        this.newInvActualPercentage = 0;
        this.remainingAmount = this.roundSize - (this.amountRaisedFromCurrentInvestors + this.totInvMet);
        this.newInvActualPercentage = +newInvPostRoundTotal.toFixed(4);
        this.totalPostPercentage = this.newInvActualPercentage + this.finalPostRoundTotal;
        this.newInvRequiredPercentage = 1 - this.totalPostPercentage;
        this.checkInvestmentMet(this.remainingAmount);
    };
    NewInvestorsTabComponent.prototype.fieldEdited = function ($event) {
        this.isRoundNotSaved = true;
    };
    /** Calculate Total Investment of New investor **/
    NewInvestorsTabComponent.prototype.getBasicDetails = function () {
        var _this = this;
        this.ngxLoader.start();
        this.authService.getShareHoldersNewInvestor().subscribe(function (response) {
            if (response) {
                _this.rightToMaintainData = response['rightToMaintainShareholders'];
                _this.shareholdersData = response['existingShareholders'];
                _this.companyCurrency = response['companyCurrency'];
                _this.roundCurrency = response['currencyForRound'];
                _this.exchangeRate = response['exchangeRate'];
                _this.newInvestors = response['newInvestors'];
                _this.amountLeftToRaise = response['amountLeftToRaise'];
                _this.amountRaisedFromCurrentInvestors = response['amountRaisedFromCurrentInvestors'];
                _this.amountRaisedFromNewInvestors = response['amountRaisedFromNewInvestors'];
                _this.amountRaised = response['amountRaised'];
                _this.roundSize = response['roundSize'];
                _this.sharePrice = response['pricePerShare'];
                //For Basic Details
                var basicData = {
                    isBasicDetailsFilled: true,
                    roundName: response['roundName'],
                    roundSize: response['roundSize'],
                    roundValuation: response['roundValuation'],
                    amountRaised: response['amountRaised'],
                    amountLeftToRaise: response['amountLeftToRaise'],
                    leadInvestorName: response['leadInvestorName'],
                    sharePrice: response['pricePerShare'],
                    roundCurrency: response['currencyForRound'],
                    isRoundNotSaved: _this.isRoundNotSaved,
                };
                _this.dataService.updateBasicData(basicData);
                var groupTotal_1 = 0;
                var grpPreTot_1 = 0;
                var groupAmtTotal_1 = 0;
                _this.shareholdersData.forEach(function (group) {
                    groupTotal_1 = groupTotal_1 + group['postRoundPercentageOfType'];
                    grpPreTot_1 = grpPreTot_1 + group['preRoundPercentageOfType'];
                    groupAmtTotal_1 = groupAmtTotal_1 + group['amountToBeInvestedOfType'];
                });
                _this.clientActualPostRndPercent = groupTotal_1;
                _this.actualPreRndPercent = response['totalPreRoundPercentage'];
                _this.actualPostRndPercent = response['totalPostRoundPercentage'];
                _this.preRoundTotalVal = response['totalPreRoundPercentage'];
                _this.finalPostRoundTotal = response['totalPostRoundPercentage'];
                _this.totalAmtToBeInvested = groupAmtTotal_1;
                _this.errorFlags = response['errorFlags'];
                _this.dataService.updateData(_this.errorFlags);
                _this.isNewInvestorSectionValid = _this.errorFlags['isNewInvestorSectionValid'];
                _this.newInvestors.forEach(function (element) {
                    var re = element.investmentAmount;
                    element['investmentAmount'] = _this.utilityService.inputCSVformatCurrencyRound(_this.roundCurrency, re);
                });
                _this.getTotalAmountInvested(_this.newInvestors);
                _this.ngxLoader.stop();
                _this.getDataFromCache();
            }
            else {
                _this.ngxLoader.stop();
                _this.getDataFromCache();
            }
        }, function (e) {
            _this.ngxLoader.stop();
            _this.getDataFromCache();
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    // getTotalValueTble(value) {
    //   if (this.roundCurrency === this.companyCurrency) {
    //     return this.utilityService.convertCurrencyFormat(value);
    //   }
    //   else {
    //     return this.utilityService.convertRoundFormat(value);
    //   }
    // }
    NewInvestorsTabComponent.prototype.getTotalValueTble = function (value) {
        if (value > 0) {
            if (value.toString().length > 5) {
                return this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
            }
            else {
                return this.utilityService.formatCurrencyDeci(this.roundCurrency, value);
            }
        }
        else {
            return 0;
        }
    };
    NewInvestorsTabComponent.prototype.gropSelectionChange = function (val) {
        if (val === "newGroup") {
            $(".new-group").modal("show");
        }
        else {
            this.mGroup = false;
            this.investorProfileInfo.groupName = { groupName: val };
        }
    };
    // Share holder type change event
    NewInvestorsTabComponent.prototype.shareHoderTypeChange = function (shrHldrType) {
        this.mGroup = false;
        this.groupMandatory = false;
        var groupName = this.investorProfileInfo.groupName['groupName'];
        var shareholderType = shrHldrType;
        this.mShareholderType = false;
        if ((shareholderType === "VC" ||
            shareholderType === "AngelNetwork" ||
            shareholderType === "PE") && groupName === "") {
            this.groupMandatory = true;
            this.mGroup = true;
        }
        else {
            this.mGroup = false;
            this.groupMandatory = false;
        }
    };
    NewInvestorsTabComponent.prototype.emailValid = function (email, errors) {
        if (email != "") {
            this.mEmail = false;
            if (errors != null || errors != undefined) {
                this.mEmail = true;
            }
        }
    };
    NewInvestorsTabComponent.prototype.legalNameChange = function (legalName) {
        if (legalName != "") {
            this.mlegalName = false;
        }
    };
    // Currency Selection
    NewInvestorsTabComponent.prototype.selectedCurrency = function (curncy) {
        this.curncyInv = curncy;
    };
    NewInvestorsTabComponent.prototype.getPercent = function (postVal) {
        return (postVal * 100).toFixed(4);
    };
    // Exchange Rate Calculation
    NewInvestorsTabComponent.prototype.exchangeRateChangeEvent = function (exRte) {
    };
    NewInvestorsTabComponent.prototype.showCurrency = function (amount) {
        return this.utilityService.calculateCurrency(this.roundCurrency, this.companyCurrency, this.exchangeRate, amount);
    };
    // go to instrument
    NewInvestorsTabComponent.prototype.goToInstrument = function () {
        var _this = this;
        this.ngxLoader.start();
        this.isRoundNotSaved = false;
        var investorDetails = [];
        this.validatiorMethod(this.newInvestors);
        if (this.isValid) {
            this.newInvestors.forEach(function (element) {
                if (element.investorName) {
                    element.investorName = element.investorName.replace(/ \t/g, '').replace(/ +(?= )/g, '').trim();
                }
                if (typeof element.investmentAmount === "string") {
                    element.investmentAmount = element.investmentAmount.replace(/,/g, "") / 1;
                }
                else {
                    element.investmentAmount = element.investmentAmount;
                }
                investorDetails.push(element);
            });
        }
        this.shareholdersData.forEach(function (group) {
            if (group['shareholders']) {
                group['shareholders'].forEach(function (holder) {
                    var shmt = holder['amountToBeInvested'];
                    if (holder['amountToBeInvested'] > 0) {
                        var investorObj = {
                            "investorName": holder['shareholder'],
                            "investmentAmount": holder['amountToBeInvested'],
                            "investmentPercentage": null
                        };
                        investorDetails.push(investorObj);
                    }
                });
            }
            if (group['shareholderGroups']) {
                group['shareholderGroups'].forEach(function (element) {
                    element['shareholders'].forEach(function (holder) {
                        if (holder['amountToBeInvested'] > 0) {
                            var investorObj = {
                                "investorName": holder['shareholder'],
                                "investmentAmount": holder['amountToBeInvested'],
                                "investmentPercentage": null
                            };
                            investorDetails.push(investorObj);
                        }
                    });
                });
            }
        });
        if (this.newInvestors.length > 0) {
            if (this.isValid) {
                this.authService.saveNewShareHolderInfo(investorDetails).subscribe(function (response) {
                    if (response) {
                        _this.ngxLoader.stop();
                        _this.removeDataFromCache();
                        _this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "instrument"]);
                    }
                    else {
                        _this.ngxLoader.stop();
                        _this.toastr.error("Null Response", "Error");
                    }
                }, function (e) {
                    _this.ngxLoader.stop();
                    _this.toastr.error("Error: " + e.error.reason, "Error");
                });
            }
            else {
                this.ngxLoader.stop();
                // this.toastr.error( "Please enter profile information ..","Error");
            }
        }
        else if (this.rightToMaintainData.length > 0) {
            this.authService.saveNewShareHolderInfo(investorDetails).subscribe(function (response) {
                if (response) {
                    _this.ngxLoader.stop();
                    _this.removeDataFromCache();
                    _this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "instrument"]);
                }
                else {
                    _this.ngxLoader.stop();
                    _this.toastr.error("Null Response", "Error");
                }
            }, function (e) {
                _this.ngxLoader.stop();
                _this.toastr.error("Error: " + e.error.reason, "Error");
            });
        }
        else {
            this.ngxLoader.stop();
            this.toastr.error("You already skipped existing investors..,Please add New investor", "Error");
        }
    };
    //Skip
    NewInvestorsTabComponent.prototype.skip = function () {
        if (this.rightToMaintainData.length > 0) {
            this.newInvestors = [];
            this.goToInstrument();
        }
        else if (this.rightToMaintainData.length === 0 && this.newInvestors.length === 0) {
            this.toastr.error("You already skipped existing investor investment.. please add new investment...!", "Error");
        }
        else {
            this.toastr.error("Can't skip., Please fill new investor details and click Next...", "Error");
        }
    };
    //Validation Method
    NewInvestorsTabComponent.prototype.validatiorMethod = function (data) {
        var _this = this;
        this.utilityService.formValidation();
        if (data.length >= 1) {
            data.forEach(function (element) {
                if (element.investorName === "" ||
                    element.investmentAmount === 0 ||
                    element.numberOfShares === 0 ||
                    element.typeOfInvestor === "" || element.typeOfInvestor === null ||
                    element.legalName === "" || element.legalName === null) {
                    _this.isValid = false;
                    if (element.investorName === "") {
                        _this.insName = true;
                        _this.toastr.error("Please enter Investor Name..", "Error");
                    }
                    else if (element.investmentAmount === 0) {
                        _this.insAmt = true;
                        _this.toastr.error("Please enter Investment Amount..", "Error");
                    }
                    else if ((element.legalName === "" || element.legalName === null) ||
                        (element.typeOfInvestor === "" || element.typeOfInvestor === null)) {
                        _this.toastr.error("Please enter " + element.investorName + "  profile information ..", "Error");
                    }
                }
                else {
                    _this.isValid = true;
                }
            });
        }
    };
    // add new investor
    NewInvestorsTabComponent.prototype.addNewInvestor = function () {
        if (this.newInvestors.length > 0) {
            if (this.newInvestors[this.newInvestors.length - 1]['investorName'] === "" ||
                this.newInvestors[this.newInvestors.length - 1]['postRound'] === 0 ||
                this.newInvestors[this.newInvestors.length - 1]['amountInvested'] === 0) {
                this.toastr.error("Please fill the previous investor details!", "Failure");
                return;
            }
        }
        var remains = Math.sign(this.remainingAmount);
        if (remains === -1 || remains === 0) {
            this.toastr.error("Investment met, can not add more investors.", "Failure");
            return;
        }
        var newInvestorsObj = {
            isLeadInvestor: false,
            investorName: "",
            investmentPercentage: 0,
            investmentAmount: 0,
            numberOfShares: 0,
            typeOfInvestor: "",
            groupName: {
                groupName: ""
            },
            registeredAddress: "",
            residentStatus: "",
            occupation: "",
            nationality: "",
            contactNumber: "",
            emailId: "",
            legalName: "",
            imageBase64String: null,
            imageName: "",
            contenttype: "",
            documentPath: {},
            imageUrl: null
        };
        newInvestorsObj.residentStatus = this.selectedResidentType;
        newInvestorsObj.nationality = this.selectedCountry;
        this.newInvestors.push(newInvestorsObj);
        this.editNewInvestor = this.newInvestors.length - 1;
        this.getTotalAmountInvested(this.newInvestors);
        this.isindividualValid = false;
    };
    NewInvestorsTabComponent.prototype.getSharholderGroupInfo = function () {
        var _this = this;
        this.authService.getNwInstrShrHldrGroupInfo().subscribe(function (response) {
            _this.shareHolderType = response["typeOfShareholder"];
            _this.groupNames = response["groupName"];
        }, function (e) {
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    // editProfile(i) {
    //   this.selectedUserIndexToEdit = i;
    //   if(this.newInvestors[i].residentStatus == null){
    //     this.newInvestors[i].residentStatus = this.selectedCountry;
    //   }
    //   if(this.newInvestors[i].nationality == null){
    //     this.newInvestors[i].nationality = this.selectedCountry;
    //   }
    //   this.investorProfileInfo = this.newInvestors[i];
    // }
    NewInvestorsTabComponent.prototype.editInvestorProfile = function (i, investor) {
        this.newInvestorProfile = investor;
        this.newInvestorProfileindex = i;
        this.fromNewInv = true;
        this.showdigPrfle = true;
        document.getElementById("overlay").style.display = "block";
    };
    NewInvestorsTabComponent.prototype.getInvestorProfileInfo = function (e) {
        if (e.profileInfo) {
            var index = e.index;
            this.newInvestors[index]['contactNumber'] = e.profileInfo['contactNumber'];
            this.newInvestors[index]['contenttype'] = e.profileInfo['contenttype'];
            this.newInvestors[index]['corporateAddress'] = e.profileInfo['corporateAddress'];
            this.newInvestors[index]['documentPath'] = e.profileInfo['documentPath'];
            this.newInvestors[index]['emailId'] = e.profileInfo['emailId'];
            this.newInvestors[index]['groupName'] = e.profileInfo['groupName'];
            this.newInvestors[index]['imageBase64String'] = e.profileInfo['imageBase64String'];
            this.newInvestors[index]['imageName'] = e.profileInfo['imageName'];
            this.newInvestors[index]['imageUrl'] = e.profileInfo['imageUrl'];
            this.newInvestors[index]['legalName'] = e.profileInfo['legalName'];
            this.newInvestors[index]['nationality'] = e.profileInfo['nationality'];
            this.newInvestors[index]['registeredAddress'] = e.profileInfo['registeredAddress'];
            this.newInvestors[index]['occupation'] = e.profileInfo['occupation'];
            this.newInvestors[index]['residentStatus'] = e.profileInfo['residentStatus'];
            this.newInvestors[index]['typeOfInvestor'] = e.profileInfo['typeOfShareholder'];
            this.newInvestors[index]['shareholderSubType'] = e.profileInfo['shareholderSubType'];
            this.newInvestors[index]['isRelativeOfDirector'] = e.profileInfo['isRelativeOfDirector'];
            this.newInvestors[index]['dpId'] = e.profileInfo['dpId'];
            this.newInvestors[index]['clientId'] = e.profileInfo['clientId'];
            this.newInvestors[index]['depositoryName'] = e.profileInfo['depositoryName'];
            this.newInvestors[index]['isPromoter'] = e.profileInfo['isPromoter'];
            this.showdigPrfle = e.showMdl;
            this.fromNewInv = e.fromNewInv;
            document.getElementById("overlay").style.display = "none";
        }
        else {
            this.showdigPrfle = e.showMdl;
            this.fromNewInv = e.fromNewInv;
            document.getElementById("overlay").style.display = "none";
        }
    };
    NewInvestorsTabComponent.prototype.validatorMethod = function (data) {
        var groupname;
        if (data.groupName != null || data.groupName != undefined) {
            groupname = data.groupName['groupName'];
        }
        if (this.groupMandatory) {
            if (!data.emailId || !data.typeOfInvestor || !data.legalName || !groupname || !data.residentStatus || !data.nationality) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (!data.emailId || !data.typeOfInvestor || !data.legalName || !data.residentStatus || !data.nationality) {
                return false;
            }
            else {
                return true;
            }
        }
    };
    NewInvestorsTabComponent.prototype.saveInvestorsProfile = function () {
        this.mvalid = this.validatorMethod(this.investorProfileInfo);
        var groupname;
        if (this.investorProfileInfo.groupName != null || this.investorProfileInfo.groupName != undefined) {
            groupname = this.investorProfileInfo.groupName['groupName'];
        }
        if (!this.mvalid) {
            if (this.investorProfileInfo.legalName === "") {
                this.mlegalName = true;
            }
            if (this.investorProfileInfo.typeOfInvestor === "") {
                this.mShareholderType = true;
            }
            if (this.investorProfileInfo.emailId === "") {
                this.mEmail = true;
            }
            if (this.investorProfileInfo.residentStatus === "") {
                this.mResidence = true;
            }
            if (this.investorProfileInfo.nationality === "") {
                this.mNationality = true;
            }
            if (this.investorProfileInfo.typeOfInvestor === "VC" ||
                this.investorProfileInfo.typeOfInvestor === "AngelNetwork" ||
                this.investorProfileInfo.typeOfInvestor === "PE") {
                if (groupname === undefined || groupname === "" || groupname === null) {
                    this.toastr.error("Error: Please select group", "Error");
                    this.mGroup = true;
                }
            }
            else {
                this.mGroup = false;
            }
            return;
        }
        else {
            this.mlegalName = false;
            this.mEmail = false;
            this.mGroup = false;
            this.mNationality = false;
            this.mResidence = false;
            this.mShareholderType = false;
            $('#profileModal').modal('hide');
        }
    };
    // Create new Group
    NewInvestorsTabComponent.prototype.createNewGroup = function () {
        var _this = this;
        this.ngxLoader.start();
        if (this.newGroupObj.groupName == '') {
            this.groupdNameErr = true;
            this.ngxLoader.stop();
        }
        else {
            this.authService.saveNewGroup(this.newGroupObj).subscribe(function (response) {
                if (response) {
                    _this.ngxLoader.stop();
                    _this.toastr.success("Success: " + response['groupName'] + "New Group created successfully");
                    _this.getSharholderGroupInfo();
                    _this.gropSelectionChange(response['groupName']);
                    $('#groupModal').modal('hide');
                }
            }, function (e) {
                _this.ngxLoader.stop();
                _this.gropSelectionChange(_this.newGroupObj.groupName);
                _this.toastr.error("Error: " + e.error.reason, "Error");
            });
        }
    };
    //Currency Convertion modal
    NewInvestorsTabComponent.prototype.openCurrencyConv = function (amt) {
        this.curConvInvAmt = amt;
    };
    NewInvestorsTabComponent.prototype.getEvent = function () {
        this.shouldShowDialog = false;
        document.getElementById("overlay").style.display = "none";
    };
    NewInvestorsTabComponent.prototype.leadInvestor = function (index) {
        for (var i = 0; i < this.newInvestors.length; i++) {
            if (i == index) {
                this.newInvestors[i]["isLeadInvestor"] = true;
            }
            else {
                this.newInvestors[i]["isLeadInvestor"] = false;
            }
        }
    };
    /** Delete New investor **/
    NewInvestorsTabComponent.prototype.deleteNewInvestor = function (index) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: { header: "Confirm Delete?", text: "Are you sure you want to Delete ?" },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                _this.newInvestors.splice(index, 1);
                _this.cacheService.set('investorListDetail', _this.newInvestors);
                var newInvPostRoundTotal_1 = 0;
                if (_this.newInvestors.length > 0) {
                    _this.newInvestors.forEach(function (investor) {
                        newInvPostRoundTotal_1 = newInvPostRoundTotal_1 + investor['postRoundPercentage'];
                    });
                    _this.newInvActualPercentage = newInvPostRoundTotal_1;
                    _this.insAmt = false;
                    _this.insName = false;
                }
                else {
                    _this.newInvActualPercentage = newInvPostRoundTotal_1;
                    _this.insAmt = false;
                    _this.insName = false;
                }
                _this.totalPostPercentage = _this.newInvActualPercentage + _this.finalPostRoundTotal;
                _this.newInvRequiredPercentage = 1 - _this.totalPostPercentage;
                _this.getTotalAmountInvested(_this.newInvestors);
                _this.ngxLoader.stop();
            }
        });
    };
    //Refresh function for new investor
    NewInvestorsTabComponent.prototype.refreshNewInvestor = function () {
        var _this = this;
        this.authService.roundCreationrefreshNewInv().subscribe(function (response) {
            if (response) {
                _this.newInvestors = response['newInvestors'];
                _this.amountRaisedFromNewInvestors = response['amountRaisedFromNewInvestors'];
                _this.amountRaisedFromCurrentInvestors = response['amountRaisedFromCurrentInvestors'];
                _this.roundSize = response['roundSize'];
                _this.newInvestors.forEach(function (element) {
                    var re = element.investmentAmount;
                    element['investmentAmount'] = _this.utilityService.inputCSVformatCurrencyRound(_this.roundCurrency, re);
                });
                _this.isNewInvestorSectionValid = true;
                _this.remainingAmount = 0;
                _this.remainingAmount = _this.roundSize - (_this.amountRaisedFromCurrentInvestors + _this.amountRaisedFromNewInvestors);
                _this.checkInvestmentMet(_this.remainingAmount);
            }
            else {
                _this.toastr.error("Error: Response as null", "Error");
            }
        }, function (e) {
        });
    };
    NewInvestorsTabComponent.prototype.onKeydownEvent = function (e) {
        this.utilityService.restrictMinus(e);
    };
    NewInvestorsTabComponent.prototype.ngOnInit = function () {
        var _a = require("country-list"), getCode = _a.getCode, getNames = _a.getNames;
        this.countryList = getNames();
        this.getBasicDetails();
        this.getSharholderGroupInfo();
        this.newRoundInfon = JSON.parse(this.cacheService.get('newRoundInfo'));
    };
    NewInvestorsTabComponent.prototype.getDataFromCache = function () {
        var cachedShareHldrsData = this.cacheService.get('newInvestorsDetail');
        if (cachedShareHldrsData) {
            this.newInvestors = JSON.parse(cachedShareHldrsData);
        }
    };
    NewInvestorsTabComponent.prototype.removeDataFromCache = function () {
        var cachedShareHldrsData = this.cacheService.get('newInvestorsDetail');
        if (cachedShareHldrsData) {
            this.cacheService.remove('newInvestorsDetail');
        }
    };
    NewInvestorsTabComponent.prototype.goToCurrentInvestor = function () {
        if (this.newInvestors && this.newInvestors.length) {
            this.cacheService.set('newInvestorsDetail', JSON.stringify(this.newInvestors));
        }
        this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "currentInvestors"]);
    };
    NewInvestorsTabComponent.prototype.expandAllItem = function (val) {
        var _this = this;
        if (this.isOpen == false) {
            this.shareholdersData.forEach(function (list) {
                list.expandAll = true;
                list.expanded = true;
                if (list.amountToBeInvestedOfType > 0) {
                    list.amountToBeInvestedForFlagShowing = 0;
                }
                if (list.shareholderGroups != null) {
                    list.shareholderGroups.forEach(function (groups) {
                        if (groups.amountToBeInvestedOfGroup > 0) {
                            groups.amountToBeInvestedForFlagShowing = 0;
                        }
                        groups.expandAll = true;
                        groups.expanded = true;
                    });
                }
                _this.isOpen = true;
            });
        }
        else if (this.isOpen == true) {
            this.shareholdersData.forEach(function (list) {
                list.expandAll = false;
                list.expanded = false;
                if (list.amountToBeInvestedOfType > 0) {
                    list.amountToBeInvestedForFlagShowing = list.amountToBeInvestedOfType;
                }
                if (list.shareholderGroups != null) {
                    list.shareholderGroups.forEach(function (groups) {
                        if (groups.amountToBeInvestedOfGroup > 0) {
                            groups.amountToBeInvestedForFlagShowing = groups.amountToBeInvestedOfGroup;
                        }
                        groups.expandAll = false;
                        groups.expanded = false;
                    });
                }
                _this.isOpen = false;
            });
        }
    };
    NewInvestorsTabComponent.prototype.groupExpanded = function (i) {
        this.shareholdersData[i].expanded = true;
        this.shareholdersData[i].expandAll = true;
        this.shareholdersData[i].amountToBeInvestedForFlagShowing = 0;
    };
    NewInvestorsTabComponent.prototype.groupCollapsed = function (i) {
        this.shareholdersData[i].expanded = false;
        this.shareholdersData[i].expandAll = false;
        this.shareholdersData[i].amountToBeInvestedForFlagShowing = this.shareholdersData[i].amountToBeInvestedOfType;
    };
    NewInvestorsTabComponent.prototype.individualGroupExpanded = function (i, j) {
        this.shareholdersData[i].shareholderGroups[j].expanded = true;
        this.shareholdersData[i].shareholderGroups[j].expandAll = true;
        this.shareholdersData[i].shareholderGroups[j].amountToBeInvestedForFlagShowing = 0;
    };
    NewInvestorsTabComponent.prototype.individualGroupCollapsed = function (i, j) {
        this.shareholdersData[i].shareholderGroups[j].expanded = false;
        this.shareholdersData[i].shareholderGroups[j].expandAll = false;
        this.shareholdersData[i].shareholderGroups[j].amountToBeInvestedForFlagShowing = this.shareholdersData[i].shareholderGroups[j].amountToBeInvestedOfGroup;
    };
    // help section show
    NewInvestorsTabComponent.prototype.showHelp = function (section) {
        if (section == this.helper) {
            return;
        }
        var targetElement = document.getElementById(section);
        if (targetElement != null) {
            this.helperTopPosition = targetElement.offsetTop;
        }
        this.helpSections = this.helperService.getHelpContent("NewInvestorsTabComponent", section);
        this.helper = section;
    };
    // help section hide
    NewInvestorsTabComponent.prototype.hideHelp = function () {
        this.helper = "";
    };
    // input field olny number and comma
    NewInvestorsTabComponent.prototype.onlyNumberKey = function (event) {
        if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
            return false;
        }
        else {
            return true;
        }
    };
    NewInvestorsTabComponent.prototype.onChangeCountrySelect = function (event) {
        this.investorProfileInfo.nationality = event;
    };
    NewInvestorsTabComponent.prototype.onChangeResidentSelect = function (event) {
        this.investorProfileInfo.residentStatus = event;
    };
    NewInvestorsTabComponent.prototype.openUploadPicturePopup = function () {
        this.shouldShowDialog = true;
    };
    NewInvestorsTabComponent.prototype.dataURItoBlob = function (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    };
    NewInvestorsTabComponent.prototype.onImageSaved = function (imageDetails) {
        this.shouldShowDialog = false;
        this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
        this.croppedImage = imageDetails.croppedImage;
        this.investorProfileInfo.imageBase64String = this.croppedImage;
        this.investorProfileInfo.imageName = imageDetails.fileName;
        this.investorProfileInfo.contenttype = this.content;
        this.croppedImage = null;
    };
    NewInvestorsTabComponent.prototype.changeCroppedImage = function () {
        this.investorProfileInfo.imageBase64String = null;
        this.investorProfileInfo.imageUrl = null;
    };
    NewInvestorsTabComponent.prototype.uploadAvatarImage = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var tempImage, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.croppedImage = event.target.src;
                        tempImage = event.target.src;
                        this.investorProfileInfo.imageName = tempImage.split('/').pop();
                        this.investorProfileInfo.contenttype = this.content;
                        _a = this;
                        return [4 /*yield*/, (fetch(tempImage).then(function (res) { return res.blob(); }))];
                    case 1:
                        _a.profilePicture = _b.sent();
                        this.handleFileSelect(this.profilePicture);
                        return [2 /*return*/];
                }
            });
        });
    };
    NewInvestorsTabComponent.prototype.handleFileSelect = function (evt) {
        var reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(evt);
    };
    NewInvestorsTabComponent.prototype.handleReaderLoaded = function (e) {
        this.croppedImage = 'data:image/png;base64,' + btoa(e.target.result);
        this.investorProfileInfo.imageBase64String = this.croppedImage;
    };
    NewInvestorsTabComponent.prototype.individualSave = function (index) {
        var _this = this;
        this.utilityService.formValidation();
        this.newInvestors.forEach(function (element, i) {
            if (i === index) {
                if (element.investorName === "" ||
                    element.investmentAmount === 0 ||
                    element.numberOfShares === 0 ||
                    element.typeOfInvestor === "" ||
                    element.groupName === "" ||
                    element.legalName === "") {
                    _this.isindividualValid = false;
                    if (element.investorName === "") {
                        _this.insName = true;
                        _this.toastr.error("Please enter Investor Name..", "Error");
                    }
                    else if (element.investmentAmount === 0) {
                        _this.insAmt = true;
                        _this.toastr.error("Please enter Investment Amount..", "Error");
                    }
                    else if (element.legalName === "" || element.typeOfInvestor === "" || element.groupName === "") {
                        _this.toastr.error("Please enter " + element.investorName + "  profile information ..", "Error");
                        return;
                    }
                }
                else {
                    _this.isindividualValid = true;
                }
            }
        });
    };
    // actual percentage
    NewInvestorsTabComponent.prototype.changeActualPercentage = function (val) {
        var value = val * 100;
        var va = +value.toFixed(2);
        // let res = Math.floor(va);
        return va;
    };
    NewInvestorsTabComponent.prototype.maxNumber = function (val) {
        if (val.length > this.maxLength) {
            this.investorProfileInfo.contactNumber = val.slice(0, this.maxLength);
        }
    };
    NewInvestorsTabComponent.prototype.close = function () {
        this.mlegalName = false;
        this.mEmail = false;
        this.mGroup = false;
        this.mNationality = false;
        this.mResidence = false;
        this.mShareholderType = false;
    };
    return NewInvestorsTabComponent;
}());
export { NewInvestorsTabComponent };
