<div class="connect-terms-dialog">
  <div class="header">
      <div class="row">
          <div class="col-md-10 custom-modal-header">
              <label class="dialogue-title">Keka Integration</label>
          </div>
          <div class="col-md-2 close-icon">
              <button type="button" class="close" (click)="close()" aria-label="Close" id="close" [mat-dialog-close]="'close'">
                  <span class="close">X</span>
              </button>
          </div>
      </div>
  </div>


  <div class="container-fluid content-row">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <p class="text-align-center">Hissa will log into your DarwinBox account to connect your directory and HR systems</p>
        </div>
        <div class="col-md-1"></div>
      </div>
      <mat-divider></mat-divider>
      <div class="row margin-10">
          <div class="col-md-1">
            <mat-icon><i class="fa fa-shopping-bag" aria-hidden="true"></i></mat-icon>
          </div>
          <div class="col-md-11">
            <mat-label>Trusted & Secure</mat-label>
            <p class="content-pg-1 btm-10 grey-text">Your information is handled securely with end-to-end encryption. Your credentials are not shared with Hissa.</p>
          </div>
      </div>
      <div class="row margin-10">
          <div class="col-md-1">
            <mat-icon><i class='fa fa-eye'></i></mat-icon>
          </div>
          <div class="col-md-11">
            <mat-label>Privacy First</mat-label>
            <p class="content-pg-1 btm-10 grey-text">You can review a list of permissions before granting Hissa access to your system.</p>
          </div>
      </div>
      <div class="row margin-10">
          <div class="col-md-1">
            <mat-icon><i class="fa fa-share-alt"></i></mat-icon>
          </div>
          <div class="col-md-11">
            <mat-label>Data portability</mat-label>
            <p class="content-pg-1 btm-10 grey-text">Hissa does not sell your employer and employee data, and will only use it with your permission.</p>
          </div>
      </div>
      <mat-divider></mat-divider>
      <div class="row">
          <div class="col-md-12">
            <p class="content-pg-1 btm-10 section-padding text-align-center"><span class="grey-text">By clicking "Connect" you agree to Hissa's </span><b>End User Privacy Policy</b></p>
          </div>
      </div>
      <div class="col-md-12 connect-box">
        <button mat-raised-button id="connect" class="float-right connect-button btm-20"
        [mat-dialog-close]="'connect'" ngbTooltip="Connect">CONNECT</button>
      </div>
  </div>
</div>