<div class="dialog">
  <div class="header">
   <div class="row">
     <div class="col-xs-9 col-sm-9 col-md-10 custom-modal-header">
       <label class="dialogue-title">{{data.header}}</label>
     </div>
     <div class="col-xs-6 col-sm-6 col-md-2">
       <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
         <span class="close">X</span>
       </button>
     </div>
   </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div style="height: 90%;width:100%">
    <div> 
      <img *ngIf="data.type === 'image'"  width="100%" height="100%" [src]="data.url" alt="Grant Signature">
      <ejs-documenteditorcontainer *ngIf="data.type === 'docx'" serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/" [(ngModel)]="editor" name="DocxFile" ngDefaultControl #documenteditor_default [enableToolbar]="false" [isReadOnly]= "true" [enablePrint]="false" width="100%"  height="100%" [showPropertiesPane]="false" [zoomFactor]=1.25></ejs-documenteditorcontainer>
    </div>
  </div>
</div>