import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { UtilityService } from 'src/app/services/utilities.service';
declare var $: any;

@Component({
  selector: 'app-debenture-inv-popup',
  templateUrl: './debenture-inv-popup.component.html',
  styleUrls: ['./debenture-inv-popup.component.scss']
})
export class DebentureInvPopupComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() invDetails;
  @Input() selectedIndex;
  @Input() invList;
  @Input() companyCurrency;
  @Input() roundCurrency;
  @Input() exchangeRate;
  @Input() nominalEqtyList;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();

  public nomEqtyInstCls;
  public amountRaised;
  public totalRaised;
  public amountLefttoRaised;
  initialAmountLeftToBeRaised;

  constructor(private ngxLoader: NgxUiLoaderService, private utilityService: UtilityService,
                private authService: AuthenticationService, private toastr: ToastrService) { }

  ngOnInit() {
    this.utilityService.showOverlay()
      this.loadJSStyles();
      this.invDetails['investmentAmount'] = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, this.invDetails['investmentAmount']);
      this.totalRaised = this.invDetails['amountRaised'];
      this.amountRaised = this.totalRaised;
      this.amountLefttoRaised = this.invDetails['roundSize'] - this.invDetails['amountRaised'];
      this.initialAmountLeftToBeRaised = this.amountLefttoRaised
  }

  loadJSStyles() {
    $(document).ready(() => {
        $('[data-toggle="switch"]').bootstrapSwitch({
            onText: "Yes",
            offText: "No",
          });
          $('#includeNominalEquity').bootstrapSwitch('state', this.invDetails.includeNominalEquity);
          $("#includeNominalEquity").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
            this.invDetails.includeNominalEquity = state;
            // this.calculateInvestment();
          })
    });
  }

  close() {
    this.utilityService.hideOverlay()
    this.ngxLoader.start();
    this.showDialog = false;
    this.visibleChange.emit({ selectedIndex: this.selectedIndex });
  }

  savesSecurity() {
    this.ngxLoader.start();
    if(typeof this.invDetails['investmentAmount'] === 'string'){
      let amt = this.invDetails['investmentAmount'].replace(/,/g, "");
      this.invDetails['investmentAmount'] = Number(amt);
    }

    console.log('all investors: ', this.invList)

    this.invList.forEach((element, index) => {
        if(index == this.selectedIndex) {
            element['isEditingInvestor'] = true;
            element['investmentAmount'] = this.invDetails['investmentAmount'];
            element['nominalEquity'] = this.invDetails['includeNominalEquity'];
            element['nominalEquityShares'] = this.invDetails['nominalEquityShares'];
            element['nominalEquityClassName'] =  this.invDetails['nominalEquityClassName']
        } else {
            element['isEditingInvestor'] = false;
        }
    });

    this.authService.saveDebPopup(this.invList).subscribe(
        (res) => {
            this.ngxLoader.start();
            this.visibleChange.emit({ selectedIndex: this.selectedIndex, invDetails: this.invDetails });
            this.showDialog = false;
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
    );
  }

  updateInvestor(event) {
    let noOfNominalEquityShares = event.replace(/,/g, "") / 1;
    this.invDetails['nominalEquityShares'] = noOfNominalEquityShares;
  }

  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
      return false;
    }
    else {
      return true;
    }
  }

  csvVal(value, e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
  
    let val = value;
    exitVal = this.utilityService.inputCSVformatCurrency(this.companyCurrency, val);
    if(e) {
      if(e.target.value) {
        let CSVLength = exitVal.length;
        let valueLength = e.target.value.length;
        if(CSVLength > valueLength) {
            e.target.value = exitVal;
            let difference = CSVLength - valueLength;
            start = start + difference;
            end = end + difference;
        }
        else if(CSVLength < valueLength){
          e.target.value = exitVal;
          if(start != 0 || end != 0){
              let difference = valueLength - CSVLength;
              start = start - difference;
              end = end - difference;
          }
          else{
              start = start;
              end = end;
          }  
        }
        else {
          e.target.value = exitVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    } 
  }

  calculateInvestment() {
    if(!this.invDetails['includeNominalEquity']) {
        this.invDetails['includeNominalEquity'] = false;
        this.invDetails['nominalEquityShares'] = null;
    } else if (this.invDetails['includeNominalEquity'] == true && this.invDetails['nominalEquityShares'] == null) {
        this.toastr.error('Please fill Number of Nominal Equity Shares', 'Error');
        return;
    }
    this.invDetails['investmentAmount'] = this.invDetails['investmentAmount'].replace(/,/g, "") / 1;

    if(this.invDetails['investmentAmount'] > this.initialAmountLeftToBeRaised) {
      this.toastr.error("Investment amount cannot be greater than amount left to be raised")
      this.invDetails['investmentAmount'] = 0
      let investAmount = this.invDetails['investmentAmount'];
      this.amountRaised = this.totalRaised + investAmount;
      this.amountLefttoRaised = this.invDetails['roundSize'] - this.amountRaised;
      return;
    }

    let amount = this.invDetails['investmentAmount'];
    this.authService.getUpdatedInvestmentAmount(this.invDetails).subscribe(
        (res) => {
          if(res['investmentAmount'] <= (res['roundSize'] - this.totalRaised)){
            this.invDetails = res;
            if(this.invDetails['investmentAmount'] != amount) {
                this.toastr.info('Minor adjustments in investment amount made to make it a multiple of share price')
            }
            let investAmount = this.invDetails['investmentAmount'];
            this.amountRaised = this.totalRaised + investAmount;
            this.amountLefttoRaised = this.invDetails['roundSize'] - this.amountRaised;
            this.invDetails['investmentAmount'] = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, this.invDetails['investmentAmount']);
          }
          else{
            this.toastr.error('Nominal Shares Investment Amount Exceeding Round Size', 'Error');
            this.invDetails['nominalEquityShares'] = 0;
            this.invDetails['investmentAmount'] = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, amount);
          }
        },
        (e) => {}
    );
  }

  amountNewInvCSV(investor, e){
    let rst;
    if(e) {
        // store current positions in variables
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let amt = investor.investmentAmount;
    rst = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, amt);

    if(e) {
      if(e.target.value) {
          let CSVLength = rst.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = rst;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
              e.target.value = rst;
              if(start != 0 || end != 0){
                  let difference = valueLength - CSVLength;
                  start = start - difference;
                  end = end - difference;
              }
              else{
                  start = start;
                  end = end;
              }  
          }
          else {
              e.target.value = rst;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  selectednomInstCls(cls) {
    if (cls != "") {
      this.nomEqtyInstCls = cls;
      this.invDetails['nominalEquityClassName'] =  this.nomEqtyInstCls;
    }
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, value);
      }
    }
    else {
      let sign = "-";
      if (value.toString().length > 5) {
        return sign + this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, value);
      }
    }
  }

  showCurrency(amount) {
    return this.utilityService.calculateCurrency(this.roundCurrency, this.companyCurrency, this.exchangeRate, amount)
  }

}
