export class User {
    "fullName": string;
    "emailAddress": string;
    "phoneNumber": string;
    "pan": string;
    "countryOfResidence": string;
    "nationality": string;
    "companyName": string;
    "designation": string;
    "linkedInProfile": string;
    "gender": string;
    "profilePic": {};
  }