/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-shares-willingness.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "./edit-shares-willingness.component";
import * as i12 from "ngx-ui-loader";
import * as i13 from "ngx-toastr";
import * as i14 from "../../services/utilities.service";
var styles_EditSharesWillingnessComponent = [i0.styles];
var RenderType_EditSharesWillingnessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditSharesWillingnessComponent, data: {} });
export { RenderType_EditSharesWillingnessComponent as RenderType_EditSharesWillingnessComponent };
function View_EditSharesWillingnessComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["class", "form-control"], ["readonly", ""], ["type", "text"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["class", "form-control right-align"], ["type", "number"]], [[8, "value", 0]], [[null, "change"], [null, "keydown"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onSharesChange(_v.context.index, $event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.onKeydownEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("keypress" === en)) {
        var pd_2 = (_co.onlyNumberKey($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(2, "", _v.context.$implicit.shareStartNumber, " - ", _v.context.$implicit.shareEndNumber, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.totalNumberOfShares; _ck(_v, 4, 0, currVal_1); }); }
function View_EditSharesWillingnessComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-xs-6 col-sm-6 col-md-10 custom-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["class", "dialogue-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit Willingness"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-xs-6 col-sm-6 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close top-close-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "close"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mat-divider", [["class", "divider-line mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(11, 49152, null, 0, i3.MatDivider, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "row top-20 btm-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "content-pg-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["Eligibility(pro-rata): ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditSharesWillingnessComponent_2)), i1.ɵdid(18, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 9, "div", [["class", "row top-20 btm-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 8, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 16777216, null, null, 3, "button", [["class", "float-right"], ["color", "success"], ["mat-raised-button", ""], ["ngbTooltip", "Save"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"], color: [1, "color"] }, null), i1.ɵdid(23, 212992, null, 0, i10.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i10.NgbTooltipConfig, i1.NgZone, i4.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵted(-1, 0, ["Save"])), (_l()(), i1.ɵeld(25, 16777216, null, null, 3, "button", [["class", "float-right margin-right-20"], ["color", "warn"], ["id", "cancel"], ["mat-raised-button", ""], ["ngbTooltip", "Cancel"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(26, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"], color: [1, "color"] }, null), i1.ɵdid(27, 212992, null, 0, i10.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i10.NgbTooltipConfig, i1.NgZone, i4.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.shareDistinctiveSets; _ck(_v, 18, 0, currVal_5); var currVal_8 = _co.disableripple; var currVal_9 = "success"; _ck(_v, 22, 0, currVal_8, currVal_9); var currVal_10 = "Save"; _ck(_v, 23, 0, currVal_10); var currVal_13 = _co.disableripple; var currVal_14 = "warn"; _ck(_v, 26, 0, currVal_13, currVal_14); var currVal_15 = "Cancel"; _ck(_v, 27, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 11).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 11).vertical; var currVal_2 = !i1.ɵnov(_v, 11).vertical; var currVal_3 = i1.ɵnov(_v, 11).inset; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.eligibility; _ck(_v, 16, 0, currVal_4); var currVal_6 = (i1.ɵnov(_v, 22).disabled || null); var currVal_7 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); _ck(_v, 21, 0, currVal_6, currVal_7); var currVal_11 = (i1.ɵnov(_v, 26).disabled || null); var currVal_12 = (i1.ɵnov(_v, 26)._animationMode === "NoopAnimations"); _ck(_v, 25, 0, currVal_11, currVal_12); }); }
export function View_EditSharesWillingnessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditSharesWillingnessComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDialog; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EditSharesWillingnessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-shares-willingness", [], null, null, null, View_EditSharesWillingnessComponent_0, RenderType_EditSharesWillingnessComponent)), i1.ɵdid(1, 114688, null, 0, i11.EditSharesWillingnessComponent, [i12.NgxUiLoaderService, i13.ToastrService, i14.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditSharesWillingnessComponentNgFactory = i1.ɵccf("app-edit-shares-willingness", i11.EditSharesWillingnessComponent, View_EditSharesWillingnessComponent_Host_0, { showDialog: "showDialog", shareDistinctiveSets: "shareDistinctiveSets", willingness: "willingness", eligibility: "eligibility" }, { visibleChange: "visibleChange" }, []);
export { EditSharesWillingnessComponentNgFactory as EditSharesWillingnessComponentNgFactory };
