<div class="row" (mouseleave)="hideHelp()">
    <div id="overlay"></div>
    <div class="col-md-9">
        <mat-card id="Captable_Section">
            <mat-card-header>
                <mat-card-title>New investors</mat-card-title>
            </mat-card-header>
            <mat-divider class="divider-line"></mat-divider>
            <mat-card-content>
                <div>

                    <div (mouseover)="showHelp('Captable_Section')">

                        <div class="row">
                            <div class="col-md-12">
                                <p class="content-pg-1 top-20 btm-10">
                                    The following cap table shows the equity holding of the existing shareholders before and after the new investment as a part of this round. 
                                    The difference between the round size and the total amount raised from current investors is the amount available to the new investors to subscribe.
                                </p>

                                <div *ngIf="this.roundCurrency != this.companyCurrency && this.roundCurrency != null"
                                    class="alert alert-info alert-labeled alrt-mrgn btm-20">
                                    <p type="button" class="close-help" data-dismiss="alert">
                                        <img src="../../../assets/message-close.png" />
                                    </p>
                                    <div class="alert-labeled-row">
                                        <div class="alert-label alert-label-left alert-labelled-cell">
                                            <img src="../../../assets/message-info.svg" />
                                        </div>
                                        <p class="content-pg-1 alert-body alert-body-right alert-labelled-cell">
                                            The Round currency you have selected ( {{this.roundCurrency}} ) is different
                                            from Company currency ( {{this.companyCurrency}} )
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row btm-20 pad-td">
                            <div class="col-md-12">
                                <table class="table-common">
                                    <thead>
                                        <tr>
                                            <th class="table-first-col text-align-left pad-left">
                                                Shareholder
                                                <span ngbTooltip="Expand/Collapse all" class="cursor-hand"
                                                    *ngIf="!expandAll" (click)="expandAllItem(true)"><img
                                                        src="../../assets/expandcollbox.svg" /></span>
                                                <span ngbTooltip="Expand/Collapse all" class="cursor-hand"
                                                    *ngIf="expandAll" (click)="expandAllItem(false)"><img
                                                        src="../../assets/expandcollbox.svg" /></span>
                                            </th>
                                            <th class="text-align-right">Pre Round</th>
                                            <th class="table-third-col text-align-right">Post Round</th>
                                            <th class="text-align-right pad-right">Amount to be
                                                Invested</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-template ngFor let-shrhldrType [ngForOf]="shareholdersData" let-i="index">
                                            <tr>

                                                <td class="text-align-left col-1">

                                                    <span class="cursor-hand"
                                                        *ngIf="!shrhldrType.expanded && !expandAll"
                                                        (click)="groupExpanded(i)">
                                                        <span class="cursor-hand margin-right-sm" *ngIf="(shrhldrType.amountToBeInvestedForFlagShowing > 0)">
                                                            <img class="nonZeroImg" src="../../assets/nonzeroimg.svg" />
                                                            {{ shrhldrType.shareholderType }}
                                                        </span>
    
                                                        <span class="left-20 cursor-hand margin-right-sm"
                                                            *ngIf="(shrhldrType.amountToBeInvestedForFlagShowing == 0)">
                                                            <img class="nonZeroImg" />
                                                            {{ shrhldrType.shareholderType }}
                                                        </span>
                                                        <i class="fa fa-caret-right"
                                                            aria-hidden="true"></i></span>

                                                    <span class="cursor-hand" *ngIf="shrhldrType.expanded || expandAll"
                                                        (click)="groupCollapsed(i)">
                                                        <span class="cursor-hand margin-right-sm" *ngIf="(shrhldrType.amountToBeInvestedForFlagShowing > 0)">
                                                            <img class="nonZeroImg" src="../../assets/nonzeroimg.svg" />
                                                            {{ shrhldrType.shareholderType }}
                                                        </span>
    
                                                        <span class="left-20 cursor-hand margin-right-sm"
                                                            *ngIf="(shrhldrType.amountToBeInvestedForFlagShowing == 0)">
                                                            <img class="nonZeroImg" />
                                                            {{ shrhldrType.shareholderType }}
                                                        </span>
                                                        <i class="fa fa-caret-down"
                                                            aria-hidden="true"></i></span>
                                                </td>
                                                <td class=" text-align-right" placement="right" container="body" ngbTooltip="{{shrhldrType.preRoundPercentageOfType | percent: '2.4'}}">
                                                    {{ shrhldrType.preRoundPercentageOfType | percent: "2.4" }}
                                                </td>
                                                <td class=" text-align-right" placement="right" container="body" ngbTooltip="{{shrhldrType.postRoundPercentageOfType | percent: '2.4'}}">
                                                    {{ shrhldrType.postRoundPercentageOfType | percent: "2.4" }}
                                                </td>
                                                <td class=" text-align-right pad-right" placement="right" container="body"
                                                    ngbTooltip="{{showCurrency(shrhldrType.amountToBeInvestedOfType)}}">
                                                    {{getTotalValueTble(shrhldrType.amountToBeInvestedOfType)}}
                                                </td>
                                            </tr>
                                            <tr class="individual-row" [hidden]="!shrhldrType.expanded && !expandAll"
                                                *ngFor="let individual of shrhldrType.shareholders">

                                                <td class="col-1 text-align-left padding-left-lg" container="body"
                                                    ngbTooltip="{{individual.shareholder}}">
                                                    <span *ngIf="(individual.amountToBeInvestedForFlagShowing > 0)">
                                                        <img class="img-lvl1" src="../../assets/nonzeroimg.svg" />
                                                    </span>

                                                    <mat-label class="name-wrap">{{ individual.shareholder }}
                                                    </mat-label>

                                                </td>
                                                <td class=" text-align-right" placement="right" container="body" ngbTooltip="{{individual.preRound | percent: '2.4'}}">
                                                    {{ individual.preRound | percent: "2.4" }}
                                                </td>
                                                <td class="pull-right" *ngIf="individual.amountToBeInvested >0">
                                                    <div class="post-round-box qty-btn-style ">
                                                        <button class="btn quantity-buttons minus-icon"
                                                            (click)="decrement(individual, shrhldrType)"
                                                            ngbTooltip="Decrement"> - 
                                                            <!-- <i class="fa fa-minus fa-icon" aria-hidden="true"></i> -->
                                                        </button>

                                                        <input class="text-align-center" type="number" #postInput
                                                            [ngModel]="getPercent(individual.postRound)"
                                                            ngbTooltip="Click to edit" (change)="fieldEdited($event)"
                                                            (blur)="getAmtbyCustVal(individual, shrhldrType, postInput.value)"
                                                            title="" />
                                                        <button class="btn quantity-buttons plus-icon"
                                                            (click)="increment(individual, shrhldrType)"
                                                            ngbTooltip="Increment">+
                                                            <!-- <i class="fa fa-plus fa-icon" aria-hidden="true"></i> -->
                                                        </button>
                                                    </div>
                                                </td>
                                                <td class="text-align-right" *ngIf="individual.amountToBeInvested ==0" placement="right" container="body" ngbTooltip="{{individual.postRound | percent: '2.4'}}">
                                                    {{ individual.postRound | percent: "2.4" }}
                                                </td>

                                                <td class=" text-align-right pad-right" placement="right" container="body"
                                                    ngbTooltip="{{showCurrency(individual.amountToBeInvested)}}">
                                                    {{ getTotalValueTble(individual.amountToBeInvested)}}
                                                </td>
                                            </tr>

                                            <ng-template ngFor let-individualgroup
                                                [ngForOf]="shrhldrType.shareholderGroups" let-j="index">
                                                <tr [hidden]="!shrhldrType.expanded && !expandAll">

                                                    <td class="text-align-left col-1 padding-left-lg " container="body"
                                                        ngbTooltip="{{individualgroup.groupName}}">
                                                        <span
                                                            *ngIf="(individualgroup.amountToBeInvestedForFlagShowing > 0)">
                                                            <img class="img-lvl1" src="../../assets/nonzeroimg.svg" />
                                                        </span>

                                                        <span class="cursor-hand name-wrap" *ngIf="!individualgroup.expanded"
                                                            (click)="individualGroupExpanded(i,j)">
                                                            <span class="cursor-hand margin-right-sm">{{ individualgroup.groupName }}</span>
                                                            <i class="fa fa-caret-right"
                                                                aria-hidden="true"></i></span>
                                                        <span class="cursor-hand name-wrap" *ngIf="individualgroup.expanded"
                                                            (click)="individualGroupCollapsed(i,j)">
                                                            <span class="cursor-hand margin-right-sm">{{ individualgroup.groupName }}</span>
                                                            <i class="fa fa-caret-down"
                                                                aria-hidden="true"></i></span>
                                                    </td>
                                                    <td class="text-align-right" placement="right" container="body" ngbTooltip="{{individualgroup.preRoundPercentageOfGroup | percent: '2.4'}}">
                                                        {{ individualgroup.preRoundPercentageOfGroup | percent: "2.2" }}
                                                    </td>
                                                    <td class=" text-align-right" placement="right" container="body" ngbTooltip="{{individualgroup.postRoundPercentageOfGroup | percent: '2.4'}}">
                                                        {{ individualgroup.postRoundPercentageOfGroup | percent: "2.2" }}
                                                    </td>
                                                    <td class=" text-align-right pad-right" placement="right" container="body"
                                                        ngbTooltip="{{showCurrency(individualgroup.amountToBeInvestedOfGroup)}}">
                                                        {{ getTotalValueTble( individualgroup.amountToBeInvestedOfGroup )}}
                                                    </td>
                                                </tr>
                                                <tr [hidden]="(!individualgroup.expanded || !shrhldrType.expanded) && !expandAll"
                                                    *ngFor="let fund of individualgroup.shareholders">

                                                    <td class="text-align-left col-1 padding-left-xl"
                                                        container="body" ngbTooltip="{{fund.shareholder}}">
                                                        <span *ngIf="(fund.amountToBeInvestedForFlagShowing > 0)">
                                                            <img class="img-lvl2" src="../../assets/nonzeroimg.svg" />
                                                        </span>

                                                        <mat-label class="name-wrap">{{ fund.shareholder }}</mat-label>

                                                    </td>
                                                    <td class="text-align-right" placement="right" container="body" ngbTooltip="{{fund.preRound | percent: '2.4'}}">
                                                        {{ fund.preRound | percent: "2.4" }}
                                                    </td>

                                                    <td class=" pull-right" *ngIf="fund.amountToBeInvested>0">
                                                        <div class="post-round-box qty-btn-style">
                                                            <button class="btn quantity-buttons minus-icon"
                                                                (click)="decrement(fund, individualgroup)"
                                                                ngbTooltip="Decrement">-
                                                                <!-- <i class="fa fa-minus fa-icon" aria-hidden="true"></i> -->
                                                            </button>
                                                            <input type="number" class="text-align-center" #postInput
                                                                [ngModel]="getPercent(fund.postRound)" (change)="fieldEdited($event)"
                                                                (blur)="getAmtbyCustVal(fund, individualgroup, postInput.value)"
                                                                title="" />
                                                            <button class="btn quantity-buttons plus-icon"
                                                                (click)="increment(fund, individualgroup)"
                                                                ngbTooltip="Increment">+
                                                                <!-- <i class="fa fa-plus fa-icon" aria-hidden="true"></i> -->
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td class="text-align-right" *ngIf="fund.amountToBeInvested ===0" placement="right" container="body" ngbTooltip="{{fund.postRound | percent: '2.4'}}">
                                                        {{fund.postRound | percent: "2.4" }}
                                                    </td>
                                                    <td class=" text-align-right pad-right" placement="right" container="body"
                                                        ngbTooltip="{{showCurrency(fund.amountToBeInvested)}}">
                                                        {{ getTotalValueTble(fund.amountToBeInvested)}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </ng-template>
                                        <tr>
                                            <td class="text-align-left pad-left">
                                                <mat-label>Total</mat-label>
                                            </td>
                                            <td class=""></td>
                                            <td class="text-align-right ">
                                                <mat-label  container="body" ngbTooltip="{{finalPostRoundTotal | percent: '2.4'}}">{{ finalPostRoundTotal | percent: "2.4" }}</mat-label>
                                            </td>
                                            <td class="text-align-right pad-right">
                                                <mat-label ngbTooltip="{{showCurrency(totalAmtToBeInvested)}}"
                                                    container="body">
                                                    {{ getTotalValueTble(totalAmtToBeInvested)}}
                                                </mat-label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <form class="needs-validation" novalidate>
                        <div id="NewInv_Section" (mouseover)="showHelp('NewInv_Section')">
                            
                            <div class="row btm-20">
                                <div class="col-md-12">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-4 col-sm-4">
                                                    <mat-label class="left-20">New Investor</mat-label>
                                                </div>
                                                <div class="col-lg-1 col-md-2 col-sm-2 text-align-right">
                                                    <mat-label class="new-inv-label"  ngbTooltip="{{newInvActualPercentage | percent: '2.4'}}">
                                                        {{newInvActualPercentage | percent: "2.4"}}</mat-label>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-4 padd-zero1">
                                                    <span *ngIf="!isInvestmentMet"
                                                        class="speech-bubble-red bubble-txt new-inv-label"
                                                        ngbTooltip="{{showCurrency(remainingAmount)}}">{{ newInvRequiredPercentage | percent: "2.4"}}
                                                        Remaining</span>
                                                    <span *ngIf="isInvestmentMet"
                                                        class="speech-bubble-green bubble-txt new-inv-label" ngbTooltip="{{showCurrency(remainingAmount)}}">
                                                        Investment Met</span>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2">
                                                    <div class="text-align-right margin-right-20">
                                                        <mat-label ngbTooltip="{{showCurrency(totInvMet)}}">
                                                            {{getTotalValueTble(totInvMet)}}</mat-label>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                            <div class="row btm-20">
                                <div class="col-md-12">
                                    <p class="content-pg-1">
                                        Enter the details of the new investment made by the new investors coming into the round. 
                                        Ensure to add or edit the profile of the new investor by clicking on the Add/Edit profile button. 
                                    </p>
                                </div>
                            </div>
                            <div class="row btm-20">
                                <div class="col-md-12">
                                    <table class="table-common">
                                        <thead>
                                            <tr>
                                                <th class="width-12">Lead Investor</th>
                                                <th class="text-align-left width-30">Investor Name</th>
                                                <th class="text-align-right width-15">Post Round( % )</th>
                                                <th class="text-align-right width-25">Amount Invested</th>
                                                <th class="text-align-left width-6">Profile</th>
                                                <th class="width-8">
                                                    <img class="left-20 cursor-hand" src="../../../assets/purple-plus-round.svg"
                                                        (click)="addNewInvestor()" ngbTooltip="Add New Investor" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let investor of newInvestors; let i = index" [attr.data-index]="i">
                                                <td class="width-12">
                                                    <img *ngIf="!investor.isLeadInvestor"
                                                        src="../../../assets/leadinvester-disabled.svg"
                                                        (click)="leadInvestor(i)" />
                                                    <img *ngIf="investor.isLeadInvestor"
                                                        src="../../../assets/lead-investor-selected.svg"
                                                        (click)="leadInvestor(i)" />
                                                </td>
                                                <td class="width-25">
                                                    <input [ngClass]="(nsNameErr.errors?.required && insName) ? 'is-invalid' : 'is-valid'"
                                                        [readonly]="editNewInvestor != i || isindividualValid" type="text" class="form-control"
                                                        [(ngModel)]="investor.investorName" title="" #nsNameErr="ngModel"
                                                        (ngModelChange)="getNameNewInv(investor, i)" (change)="fieldEdited($event)"
                                                        placeholder="Enter investor name" [ngModelOptions]="{standalone: true}" required/>
                                                        <div class="alert alert-danger  text-align-left span-block" *ngIf="nsNameErr.errors">
                                                            <span *ngIf="!insName && (nsNameErr.dirty || nsNameErr.touched)">
                                                                Investor Name is required!
                                                            </span>
                                                            <span *ngIf="nsNameErr.errors?.required && insName">
                                                                Investor Name is required!
                                                            </span>
                                                        </div>
                                                </td>
                                                <td class="pull-right">
                                                    <div class="post-round-box qty-btn-style">
                                                        <button class="btn quantity-buttons minus-icon"
                                                            (click)="decrementNewInv(investor, i)" ngbTooltip="Decrement"
                                                            [disabled]="editNewInvestor != i || isindividualValid">-
                                                            <!-- <i class="fa fa-minus fa-icon" aria-hidden="true"></i> -->
                                                        </button>
                                                        <input [ngClass]="editNewInvestor == i ? '' : 'no-border' "
                                                            [readonly]="editNewInvestor != i || isindividualValid" ngbTooltip="Click to edit"
                                                            value="getPercent(investor.investmentPercentage)"
                                                            class="text-align-center" type="number" #postInput
                                                            [ngModel]="getPercent(investor.investmentPercentage)"
                                                            (change)="getAmtbyCustValNewInv(investor, i, postInput.value); fieldEdited($event)"
                                                            (keydown)=onKeydownEvent($event) min="0" title="" [ngModelOptions]="{standalone: true}"/>
                                                        <button class="btn quantity-buttons plus-icon"
                                                            (click)="incrementNewInv(investor, i)" ngbTooltip="Increment"
                                                            [disabled]="editNewInvestor != i || isindividualValid">+
                                                            <!-- <i class="fa fa-plus fa-icon" aria-hidden="true"></i> -->
                                                        </button>
                                                    </div>
                                                </td>
                                                <td class="width-20" id="input_container">
                                                    <div>
                                                        <label *ngIf="this.roundCurrency != null"
                                                            class="CurrencySymbol">{{roundCurrency.substr(roundCurrency.length-2)}}</label>
                                                        <input type="text" class="form-control text-align-right"
                                                            [ngClass]="(amtErr.errors?.required && insAmt) ? 'is-invalid' : 'is-valid' "
                                                            [readonly]="editNewInvestor != i || isindividualValid" id="input"
                                                            (change)="getPercentByAmountNewInv(investor, i); fieldEdited($event)" (input)="amountNewInvCSV(investor, i, $event)"
                                                            [(ngModel)]="investor.investmentAmount" min="0" #amtErr="ngModel"
                                                            (keypress)="onlyNumberKey($event)" title="" [ngModelOptions]="{standalone: true}" required/>
                                                            <div class="alert alert-danger  text-align-left span-block" *ngIf="amtErr.errors">
                                                                <span *ngIf="!insAmt && (amtErr.dirty || amtErr.touched)">
                                                                    Amount is required!
                                                                </span>
                                                                <span *ngIf="amtErr.errors?.required && insAmt">
                                                                    Amount is required!
                                                                </span>
                                                            </div>
                                                    </div>
                                                </td>
                                                <td class="width-6">
                                                    <img class="cursor-hand" src="../../../assets/profile-info.svg"  (click)="editInvestorProfile(i, investor)"
                                                        ngbTooltip="Edit Profile" />

                                                </td>
                                                <td class="width-8">
                                                    <img src="../../assets/save.svg"  class="cursor-hand" *ngIf="editNewInvestor == i && !isindividualValid"
                                                        ngbTooltip="Save New Investor" (click)="individualSave(i)"/>
                                                    <img src="../../assets/edit.svg" (click)="editNewInvestorRow(i)" class="cursor-hand"
                                                        ngbTooltip="Edit New Investor" *ngIf="editNewInvestor != i || isindividualValid"/>
                                                    <img class="icon-size left-10 cursor-hand" src="../../assets/trash-bin.svg"
                                                        (click)="deleteNewInvestor(i)"ngbTooltip="Delete New Investor" />
                                                </td>
                                            </tr>
                                            <tr *ngIf="newInvestors.length == 0">
                                                <td colspan="6" class="text-align-center">
                                                    <p> Click on the '+' to add a New Investor</p>
                                                </td>
                                            </tr>
                                            <tr *ngIf="newInvestors.length > 0">
                                                <td colspan="2" class="text-align-right">
                                                    <mat-label>Total Amount Invested</mat-label>
                                                </td>
                                                <td colspan="1" class="text-align-right">
                                                    <mat-label ngbTooltip="{{showCurrency(totInvMet)}}" container="body">{{getTotalValueTble(totInvMet)}}</mat-label>
                                                </td>
                                                <td colspan="9" class="text-align-left">
                                                    <span *ngIf="isInvestmentMet"
                                                        class="speech-bubble-green bubble-txt new-inv-label">
                                                        Investment Met</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <button mat-raised-button id="saveAndContinue" color="success" [disableRipple]="disableripple"
                                    class=" pull-right" (click)="goToInstrument()" ngbTooltip="Save and Continue">
                                    Save and Continue
                                </button>
                                <button mat-raised-button id="previous" color="success" [disableRipple]="disableripple"
                                    class="pull-right margin-right-20" (click)="goToCurrentInvestor()"
                                    ngbTooltip="Previous">
                                    Previous
                                </button>
                                <!-- <button mat-raised-button color="success" [disableRipple]="disableripple"
                                    class="pull-right margin-right-20" (click)="skip()" ngbTooltip="Skip this step">
                                    Skip
                                </button> -->
                                <button mat-raised-button [disableRipple]="disableripple" color="success"
                                    class=" float-right margin-right-20" (click)="refreshNewInvestor()"
                                    ngbTooltip="Click to refresh latest data"
                                    *ngIf="isNewInvestorSectionValid === false">Refresh
                                    <img src="../../assets/refresh-arrows-symbol-of-interface.svg" alt="refresh">
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-card-content>
        </mat-card>
        <app-profile-info *ngIf="showdigPrfle" [(shareHolderType)]="shareHolderType" [(newInvestorProfile)]="newInvestorProfile"
                [(newInvestorProfileindex)]="newInvestorProfileindex" [(fromNewInv)]="fromNewInv" [(groupNames)]="groupNames" 
                [(showdigPrfle)]="showdigPrfle" (visibleChange)="getInvestorProfileInfo($event)">
        </app-profile-info>
    </div>

    <!-- Helper section -->
    <div class="col-md-3">
        <div class="helper-container">
            <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
              <mat-card-header class="help-hdr">
                <mat-card-title><label class="help-hdr-txt">{{
                    helpSections?.name
                  }}</label></mat-card-title>
              </mat-card-header>
              <hr />
              <mat-card-content class="card-help-content">
                <div [innerHTML]="helpSections?.description"></div>
              </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<!-- Modal Window for add currency convertor -->
<!-- <div class="modal fade currency-convertor" tabindex="-1" role="dialog" aria-labelledby="addProfileInfo"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header add-profile-header dialogue-title">
                Currency Convertor
                <button type="button" class="close top-close-btn" data-dismiss="modal" aria-label="Close">
                    <span class="close">X</span>
                </button>
            </div>
            <div class="modal-body padding-25">
                <div class="row">
                    <div class="col-md-12">
                        <p class="content-pg-1">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                            make a type specimen book. It has survived not only five centuries, but also the leap into
                            electronic
                            typesetting, remaining essentially unchanged. It was popularised in the 1960s.
                        </p>
                    </div>
                </div>
                <div class="row border-bottom btm-20">
                    <div class="col-md-12 text-align-center ">
                        <span>Round Currency in {{newRoundInfon.currencyForRound}}</span>
                    </div>
                </div>
                <div class="row btm-20">
                    <div class="col-md-6 text-align-center ">
                        <span>Choose Currency of investment</span>
                    </div>
                    <div class="col-md-6">
                        <select class="form-control input-fields customSelect " #currencyRnd
                            (change)="selectedCurrency(currencyRnd.value)">
                            <option class="select-optn">
                                Select currency
                            </option>
                            <option class="select-optn" *ngFor="let cur of currencyList; let i = index;"
                                value="{{cur}}">{{cur}}</option>
                        </select>
                    </div>
                </div>
                <div class="row btm-20">
                    <div class="col-md-6 text-align-center ">
                        <span>Investment Amount</span>
                    </div>
                    <div class="col-md-2">
                        <span>{{curncyInv}}</span>
                    </div>
                    <div class="col-md-4">
                        {{curConvInvAmt}}
                    </div>
                </div>
                <div class="row border-bottom btm-20">
                    <div class="col-md-6 text-align-center ">
                        <span>Exchange Rate</span>
                    </div>
                    <div class="col-md-6 ">
                        <input class=" form-control" #exchangeRateVal
                            (change)="exchangeRateChangeEvent(exchangeRateVal.value)" type="number"
                            value="{{exchangeRate}}" title="" />
                    </div>
                </div>
                <div class="row btm-20">
                    <div class="col-md-6 text-align-center ">
                        <span>You will have to remit</span>
                    </div>
                    <div class="col-md-6">
                        <span>{{newRoundInfon.currencyForRound}}</span>{{curConvInvAmt * exchangeRateVal.value}}</div>
                </div>
                <div class="row btm-20">
                    <div class="col-md-12">
                        <textarea placeholder="Comments" class="form-control" style="width: 100%">
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button mat-raised-button color="success" [disableRipple]="disableripple" class=" float-right"
                            ngbTooltip="Save and Exit" data-dismiss="modal">Save and
                            Exit</button>
                        <button mat-raised-button [disableRipple]="disableripple" color="warn"
                            class=" float-right margin-right-20" data-dismiss="modal">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
