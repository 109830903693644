import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  
  public emailId:string;
  public isEmployee:any;
  public companyList:any;
  public companyName = "";
  public companyId;


  constructor(
    private authService:AuthenticationService, 
    private router:Router,
    private toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    if(this.activatedRoute.snapshot.paramMap.get('isEmployee')) {
      console.log("is emp", this.activatedRoute.snapshot.paramMap.get('isEmployee'));
      this.isEmployee = this.activatedRoute.snapshot.paramMap.get('isEmployee');
    }
  }
  switchToEmployee(){
    this.isEmployee = true
    this.location.replaceState('/forgotPassword;isEmployee=true')
  }

  getCompanies(id){
    let user = {
      "emailId":id
    }
    if(this.isEmployee){
    this.authService.getEmployeeCompanies(user).subscribe(
      (res) => {
        this.companyList = res;
      },
      (e) => { 
        this.toastr.error(e.error.reason, "Error");
      }
    );
    }
  }

  selectCompany(e){ 
    this.companyId = e.id
  }

  sendEmail() {
    if(this.isEmployee){
      let userType = "EMPLOYEE"
      const email = encodeURIComponent(this.emailId);
      if( this.emailId != "" && this.companyName != ""){
        this.authService.employeeForgotPassword(userType, email, this.companyId).subscribe(
          (res) => {
            this.router.navigateByUrl('/employeeLogin');
            this.toastr.success("Email sent", "Success!");
          },
          (e) => {
            this.toastr.error("Error: " + e.error.reason, "Error");
          }
        );
      }
    }
    else{
      const email = encodeURIComponent(this.emailId);
      this.authService.sendEmailForgotPassword(email).subscribe(
        (res) => {
          this.router.navigateByUrl('');
          this.toastr.success("Email sent", "Success!");
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
      );
    }
  }

}
