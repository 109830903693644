import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() croppedImage;
  @Output() imageSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public imageChangedEvent: any = '';
  public fileName: any = '';
  public profilePicture: Blob = null;
  base64String: any;


  constructor(private toastr: ToastrService, private readonly utility: UtilityService) {}

  ngOnInit() {
    this.fileName = this.croppedImage;
    this.utility.showOverlay()
  }

  ngAfterViewInit(): void {
    this.getBase64(this.croppedImage);
  }

  getBase64(imgUrl) {
    if(!imgUrl) return
         const self = this;
         var xhr = new XMLHttpRequest();
         xhr.open("get", imgUrl, true);
         xhr.responseType = "blob";
         xhr.onload = function () {
           if (this.status == 200) {
             var blob = this.response;
             console.log("blob", blob)
             let oFileReader = new FileReader();
             oFileReader.onloadend = function (e) {
               let base64 = e.target;
               self.base64String = (<any>base64).result;
             };
             oFileReader.readAsDataURL(blob);
           }
         }
         xhr.send();
       }

  close() {
    this.showDialog = false;
    this.profilePicture = null;
    this.croppedImage = null;
    this.fileName = null;
    this.imageSaved.emit({ croppedImage: this.croppedImage, profilePicture: this.profilePicture,fileName:this.fileName });
    this.visibleChange.emit();
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;   
    let file = event.target.files[0];
    this.fileName = file.name;
    console.log(this.fileName);
  }

  imageCropped(event: ImageCroppedEvent) {
    //  let b: Blob = event.file;
    this.croppedImage = event.base64;
    // fetch(this.croppedImage)
    // .then(res => this.profilePicture = res.blob()).then(console.log);
    this.profilePicture = this.croppedImage;
  }

  loadImageFailed() {
    this.toastr.warning('File Type Not Supported','Warning!');
  }

  saveUploadedImage() {
    this.showDialog = false;
    this.imageSaved.emit({ croppedImage: this.croppedImage, profilePicture: this.profilePicture,fileName:this.fileName });
    this.visibleChange.emit();
  }

}
