import { BrowserModule, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxContextMenuModule, DxPieChartModule } from "devextreme-angular";
import { DxChartModule } from "devextreme-angular";
import { ImageCropperModule } from 'ngx-image-cropper';
import {
  DxDataGridModule,
  DxTemplateModule,
  DxButtonModule,
  DxTooltipModule,
  DxFileUploaderModule,
  DxTagBoxModule,
  DxProgressBarModule,
  DxDropDownBoxModule,
  DxTreeListModule
} from "devextreme-angular";
import { DocumentEditorAllModule, DocumentEditorContainerModule, ToolbarService } from '@syncfusion/ej2-angular-documenteditor';
import { FileManagerModule } from './file-manager/file-manager.module';
import { CommonDialogsModule } from './dialogues/common-dialogs.module';
import { PipesModule } from "./pipes/pipes.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ResetPasswordComponent } from "./login-signup/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./login-signup/forgot-password/forgot-password.component";
import { AuthenticationService } from "../app/authentication.service";
import { TokenInterceptor } from "../app/token.interceptor";
import { AuthGuard } from "../app/auth.guard";
import { DashboardComponent } from "./dashboard/dashboard.component";
// import { CompanyNameComponent } from "./onboarding/company-name/company-name.component";
// import { CompanyDetailsComponent } from "./onboarding/company-details/company-details.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { LandingPageComponent } from "./login-process/landing-page/landing-page.component";
// import { CreateNewRoundComponent } from "./rounds/create-new-round/create-new-round.component";
// import { SummaryComponent } from "./rounds/summary-tab/summary-tab.component";
// import { RoundsOverviewComponent } from "./rounds/rounds-overview/rounds-overview.component";
// import { AdminProfileComponent } from "./onboarding/admin-profile/admin-profile.component";
import { CapTableComponent } from "./dashboard/cap-table/cap-table.component";
// import { IncorporationDetailsComponent } from "./onboarding/incorporation-details/incorporation-details.component";
// import { ShareholdersOverviewComponent } from "./shareholders/shareholders-overview/shareholders-overview.component";
// import { SecuritiesOverviewComponent } from "./securities/securities-overview/securities-overview.component";
// import { RoundTabComponent } from "./rounds/round-tab/round-tab.component";
// import { CurrentInvestorsTabComponent } from "./rounds/current-investors-tab/current-investors-tab.component";
// import { NewInvestorsTabComponent } from "./rounds/new-investors-tab/new-investors-tab.component";
// import { CapTableTabComponent } from "./rounds/cap-table-tab/cap-table-tab.component";
// import { InstrumentTabComponent } from "./rounds/instrument-tab/instrument-tab.component";
// import { ShareholderDetailsComponent } from "./shareholders/shareholder-details/shareholder-details.component";
// import { InstrumentClassComponent } from "./securities/instrument-class/instrument-class.component";
// import { AddNewInstrumentclassComponent } from "./securities/add-new-instrumentclass/add-new-instrumentclass.component";
// import { RoundDetailsComponent } from "./rounds/round-details/round-details.component";
// import { CustomCurrencyPipe } from "./pipes/currency-pipe";
// import { CreateEsopComponent } from "./esop-management/components/create-esop/create-esop.component";
// import { OverviewEsopComponent } from "./esop-management/components/overview-esop/overview-esop.component";
// import { ManageEsopComponent } from "./esop-management/components/manage-esop/manage-esop.component";
// import { VestingTemplateCreationComponent } from "./esop-management/dialogs/vesting-template-creation/vesting-template-creation.component";
import { FooterComponentComponent } from "./footer-component/footer-component.component";
// import { ExitModellingComponent } from "./exit-modelling/exit-modelling.component";
import { DocumentOverviewComponent } from "./documents/document-overview/document-overview.component";
import { CreateRoundDocumentComponent } from "./documents/create-round-document/create-round-document.component";
// import { SecondaryMgmtComponent } from './secondary/secondary-mgmt/secondary-mgmt.component';
// import { AddNewBuyerComponent } from './secondary/Dialogs/add-new-buyer/add-new-buyer.component';
// import { NewGroupComponent } from './secondary/Dialogs/new-group/new-group.component';
// import { CertificateComponentComponent } from './secondary/Dialogs/certificate-component/certificate-component.component';
// import { ConfirmDialogComponent } from './secondary/Dialogs/confirm-dialog/confirm-dialog.component';
// import { AddEmployeeComponent } from './esop-management/dialogs/add-employee/add-employee.component';
// import { SecondarySellerComponent } from './secondary/secondary-seller/secondary-seller.component';
// import { SecondaryBuyerComponent } from './secondary/secondary-buyer/secondary-buyer.component';
// import { SecondarySummaryComponent } from './secondary/secondary-summary/secondary-summary.component';
// import { AddnewCaptableModelComponent } from './modeling/dialogues/addnew-captable-model/addnew-captable-model.component';
import { ShareCapitalComponent } from './share-capital/share-capital.component';
import { AuthorizedCapitalModalComponent } from './share-capital/split-reverse-modals/authorized-capital-modal/authorized-capital-modal.component';
import { SplitstockRatioModalComponent } from './share-capital/split-reverse-modals/splitstock-ratio-modal/splitstock-ratio-modal.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule, MatBadgeModule, MatDialogModule, MatFormFieldControl, MatFormFieldModule, MatIconModule, MatListModule, MatSidenavModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { GestureConfig } from '@angular/material';
import { MatStepperModule } from '@angular/material/stepper';
// import { AddNewRoundComponent } from './modeling/dialogues/add-new-round/add-new-round.component';
import { DatePipe } from '@angular/common';
import { DataService } from "src/app/services/new-round-notify.service";
import { UtilityService } from "src/app/services/utilities.service";
import { HelperService } from "src/app/services/helper.service";
import { PermissionsService } from "src/app/services/permissions.service";
// import { ProfileInfoComponent } from './dialogues/profile-info/profile-info.component';
// import { CurrencyConvertorComponent } from './dialogues/currency-convertor/currency-convertor.component';
// import { RounModelingTabComponent } from './modeling/roun-modeling-tab/roun-modeling-tab.component';
// import { CreateNewGroupComponent } from './dialogues/create-new-group/create-new-group.component';
import { LandingFormComponent } from './login-signup/landing-form/landing-form.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OnboardingComponent } from './onboarding/onboarding/onboarding.component';
// import { DirectorDetailsPopupComponent } from './onboarding/company-details/director-details-popup/director-details-popup.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { RoundsComponent } from './rounds/rounds.component';
import { ShareholdersComponent } from './shareholders/shareholders.component';
import { SecuritiesComponent } from './securities/securities.component';
import { ModelingComponent } from './modeling/modeling.component';
import { OptionsComponent } from './esop-management/options.component';
// import { EsopExerciseComponent } from './esop-management/dialogs/esop-exercise/esop-exercise.component';
import { AccountConfirmComponent } from './onboarding/account-confirm/account-confirm.component';
import { UsersRolesComponent } from './users-roles/users-roles.component';
import { SecondaryComponent } from './secondary/secondary.component';
// import { SecondaryOverviewComponent } from './secondary/secondary-overview/secondary-overview.component';
import { DecimalNumberDirective } from 'src/app/custom-directives/decimal-number.directive';
import { CacheProviderService } from "src/app/services/cache-provider.service";
import { CompanyProfileComponent } from './company-profile/company-profile.component';
// import { UploadImageComponent } from './dialogues/upload-image/upload-image.component';
// import { NewSecurityClassComponent } from './rounds/new-security-class/new-security-class.component';
// import { EsopPlanPopupComponent } from './securities/esop-plan-popup/esop-plan-popup.component';
// import { EsopVestComponent } from './esop-management/dialogs/esop-vest/esop-vest.component';
// import { EsopModelingTabComponent } from './modeling/esop-modeling-tab/esop-modeling-tab.component';
// import { AddNewEsopModelComponent } from './modeling/dialogues/add-new-esop-model/add-new-esop-model.component';
// import { AddNewCompTemplateComponent } from './modeling/dialogues/add-new-comp-template/add-new-comp-template.component';
// import { AddNewHiringPlanComponent } from './modeling/dialogues/add-new-hiring-plan/add-new-hiring-plan.component';
// import { AddNewSalaryTemplateComponent } from './modeling/dialogues/add-new-salary-template/add-new-salary-template.component';
import { TermSheetComponent } from './task-manager/term-sheet/term-sheet.component';
import { AddNewTaskComponent } from './task-manager/add-new-task/add-new-task.component';
import { UploadDocumentComponent } from './task-manager/step/upload-document/upload-document.component';
import { AddNewPurposeComponent } from './task-manager/add-new-purpose/add-new-purpose.component';
import { CanDeactivateGuard } from '../app/canDeactivateGuard';
import { StepComponent } from './task-manager/step/step.component';
// import { UpdateEsopVestComponent } from './esop-management/dialogs/update-esop-vest/update-esop-vest.component';
import { SearchResultsComponent } from './search-results/search-results.component';
// import { CalcValuationPopupComponent } from './rounds/calc-valuation-popup/calc-valuation-popup.component';
// import { EmployeeDetailsComponent } from './employees/employee-details/employee-details.component';
// import { EmployeesComponent } from './employees/employees.component';
// import { ResignationPopupComponent } from './employees/resignation-popup/resignation-popup.component';
import { BaseComponent } from './login-process/landing-page/base/base.component';
// import { UploadDocumentsComponent } from './onboarding/incorporation-details/upload-documents/upload-documents.component';
// import { FounderDetailsPopupComponent } from './onboarding/company-details/founder-details-popup/founder-details-popup.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ServiceTermsComponent } from './login-signup/service-terms/service-terms.component';
import { PrivacyPolicyComponent } from './login-signup/privacy-policy/privacy-policy.component';
// import { FileManagerComponent } from './shared-module/components/file-manager/file-manager.component';
import { FileManagerAllModule, DetailsViewService } from '@syncfusion/ej2-angular-filemanager';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { TagsEditComponent } from './shared-module/components/file-manager/tags-edit/tags-edit.component';
import { NavbarService } from './services/navbar-reload';
// import { AddPoolComponent } from './esop-management/components/add-pool/add-pool.component';
// import { UpdateStatusComponent } from './esop-management/dialogs/update-status/update-status.component';
// import { EditEsopEmpComponent } from './esop-management/dialogs/edit-esop-emp/edit-esop-emp.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { FileConversionComponent } from './file-conversion/file-conversion.component';
import { SplitsComponent } from "./splits/splits.component";
import { BonusComponent } from "./bonus/bonus.component";
// import { HistoricalRoundCreationComponent } from './onboarding/historical-round/creation/historical-round-creation.component';
// import { HistoricalRoundSummaryComponent } from "./onboarding/historical-round/summary/historical-round-summary.component";
// import { RoundNameComponent } from "./onboarding/historical-round/round-name/round-name.component";

import { MatDatepickerModule, MatNativeDateModule, MatProgressBarModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions} from '@angular/material';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS , MomentDateModule, MatMomentDateModule} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DoubleClickDirective } from './custom-directives/double-click.directive';
// import { DebentureInvPopupComponent } from './rounds/debenture-inv-popup/debenture-inv-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmployeesModule } from "./employees/employees.module";
import { DirectivesModule } from "./custom-directives/directives.module";
import { UnauthorizedComponent } from './error-pages/unauthorized/unauthorized.component';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { RoundDeactivateGuard } from '../app/roundDeactivateGuard';
import { BonusDeactivateGuard } from "./bonusDeactivateGuard";
import { ModellingDeactivateGuard } from "./modellingDeactivateGuard";
import { Proforma } from './services/proforma.service';
import { OnboardingModule } from './onboarding/onboarding.module';
import { RoundsModule } from './rounds/rounds.module';
import { BuyBackModule } from './buy-back/buy-back.module';
import { BuyBackComponent } from "./buy-back/buy-back.component";
import { CanShowFaq, ShouldShowPage } from "./employees/employee-route-guard";
import { OnboardingAuthGuard } from "./onboarding/onboarding-routing.module";
import { DocumentGeneratorModule } from "./document-generator/document-generator.module";
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { SVGLoader } from "./services/svg-loader.service";
import { SettingsComponent } from "./settings/settings.component";
import { SiteSettingsComponent } from './settings/site-settings/site-settings.component';
import { TransactionsComponent } from "./transactions/transactions.component";
import { ViewDocsDialogComponent } from './settings/site-settings/view-docs-dialog/view-docs-dialog.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ConnectTermsModalComponent } from './integrations/connect-terms-modal/connect-terms-modal.component';
import { HrmsLoginModalComponent } from './integrations/hrms-login-modal/hrms-login-modal.component';
import { HrmsEmployeeListModalComponent } from './integrations/hrms-employee-list-modal/hrms-employee-list-modal.component';
import { ExportSh6Component } from './export-sh6/export-sh6.component';
import { TermSheetsComponent } from "./term-sheets/term-sheets.component";
import { PlanRejectedComponent } from './plan-rejected/plan-rejected.component';
import { ShareHolderFundDialog } from "./shareholders/shareholder-details/shareholder-details.component";
import { CardOverviewComponent } from './card-overview/card-overview.component';
import { UploadPlanDocumentComponent } from './esop-management/components/overview-esop/upload-plan-document/upload-plan-document.component';
import { OtpVerifyComponent } from './login-signup/otp-verify/otp-verify.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { EmailTemplateEditorComponent } from "./settings/site-settings/email-template-editor/email-template-editor.component";
import { ShareDetailsPopupComponent } from "./modeling/share-details-popup/share-details-popup.component";
import { FinancialReportComponent } from './financial-report/financial-report.component';
import { AdjConversionRatioPopupComponent } from "./bonus/adj-conversion-ratio-popup/adj-conversion-ratio-popup.component";
import { DocumentAssemblerService } from "./services/document-assembler.service";
import { EventEmitterService } from "./services/event-emitter.service";
import { CloseTabComponent } from "./dialogues/close-tab/close-tab.component";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  terminateOnPointerUp: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    // CompanyNameComponent,
    // CompanyDetailsComponent,
    NavbarComponent,
    LandingPageComponent,
    // CreateNewRoundComponent,
    ForgotPasswordComponent,
    // SummaryComponent,
    // RoundsOverviewComponent,
    // AdminProfileComponent,
    CapTableComponent,
    // IncorporationDetailsComponent,
    // ShareholdersOverviewComponent,
    // SecuritiesOverviewComponent,
    // RoundTabComponent,
    // CurrentInvestorsTabComponent,
    // NewInvestorsTabComponent,
    // CapTableTabComponent,
    // InstrumentTabComponent,
    // ShareholderDetailsComponent,
    // InstrumentClassComponent,
    // AddNewInstrumentclassComponent,
    // RoundDetailsComponent,
    // CustomCurrencyPipe,
    // CreateEsopComponent,
    // OverviewEsopComponent,
    // ManageEsopComponent,
    // VestingTemplateCreationComponent,
    FooterComponentComponent,
    // ExitModellingComponent,
    DocumentOverviewComponent,
    CreateRoundDocumentComponent,
    // SecondaryMgmtComponent,
    // AddNewBuyerComponent,
    // NewGroupComponent,
    // CertificateComponentComponent,
    // ConfirmDialogComponent,
    // AddEmployeeComponent,
    // SecondarySellerComponent,
    // SecondaryBuyerComponent,
    // SecondarySummaryComponent,
    // AddnewCaptableModelComponent,
    ShareCapitalComponent,
    AuthorizedCapitalModalComponent,
    SplitstockRatioModalComponent,
    // AddNewRoundComponent,
    // ProfileInfoComponent,
    // CurrencyConvertorComponent,
    // RounModelingTabComponent,
    // CreateNewGroupComponent,
    LandingFormComponent,
    OnboardingComponent,
    // DirectorDetailsPopupComponent,
    RoundsComponent,
    BuyBackComponent,
    ShareholdersComponent,
    SecuritiesComponent,
    ModelingComponent,
    OptionsComponent,
    // EsopExerciseComponent,
    AccountConfirmComponent,
    UsersRolesComponent,
    SecondaryComponent,
    // SecondaryOverviewComponent,
    DecimalNumberDirective,
    CompanyProfileComponent,
    // UploadImageComponent,
    // NewSecurityClassComponent,
    // EsopPlanPopupComponent,
    // EsopVestComponent,
    // EsopModelingTabComponent,
    // AddNewEsopModelComponent,
    // AddNewCompTemplateComponent,
    // AddNewHiringPlanComponent,
    // AddNewSalaryTemplateComponent,
    // TaskManagerComponent,
    TermSheetComponent,
    AddNewTaskComponent,
    UploadDocumentComponent,
    AddNewPurposeComponent,
    // TaskManagerOverviewComponent,
    // StartNewProcessComponent,
    StepComponent,
    // UpdateEsopVestComponent,
    SearchResultsComponent,
    // CalcValuationPopupComponent,
    // EmployeeDetailsComponent,
    // EmployeesComponent,
    // ResignationPopupComponent,
    BaseComponent,
    // UploadDocumentsComponent,
    // FounderDetailsPopupComponent,
    UserProfileComponent,
    ServiceTermsComponent,
    PrivacyPolicyComponent,
    // FileManagerComponent,
    // TagsEditComponent,
    // AddPoolComponent,
    // UpdateStatusComponent,
    // EditEsopEmpComponent,
    PasswordStrengthComponent,
    FileConversionComponent,
    DoubleClickDirective,
    // DebentureInvPopupComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    SplitsComponent,
    BonusComponent,
    ConfirmDeleteComponent,
    SettingsComponent,
    SiteSettingsComponent,
    TransactionsComponent,
    ViewDocsDialogComponent,
    IntegrationsComponent,
    ConnectTermsModalComponent,
    HrmsLoginModalComponent,
    HrmsEmployeeListModalComponent,
    ExportSh6Component,
    TermSheetsComponent,
    ViewDocsDialogComponent,
    PlanRejectedComponent,
    ShareHolderFundDialog,
    UploadPlanDocumentComponent,
    OtpVerifyComponent,    
    CardOverviewComponent,
    UploadPlanDocumentComponent,
    EmailTemplateEditorComponent,
    ShareDetailsPopupComponent,
    FinancialReportComponent,
    AdjConversionRatioPopupComponent
    // HistoricalRoundCreationComponent,
    // HistoricalRoundSummaryComponent,
    // RoundNameComponent
  ],
  imports: [
    BrowserModule,
    EditorModule,
    ImageCropperModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DocumentEditorAllModule,
    DocumentEditorContainerModule,
    AppRoutingModule,
    HttpClientModule,
    DxPieChartModule,
    DxChartModule,
    DxDataGridModule,
    DxTreeListModule,
    DxButtonModule,
    DxTemplateModule,
    DxTooltipModule,
    DxFileUploaderModule,
    DxTagBoxModule,
    DxProgressBarModule,
    DxDropDownBoxModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    NgbModule,
    MatStepperModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    NgbModule,
    CarouselModule,
    NgxUiLoaderModule,
    FileManagerAllModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right', closeButton: true,}),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatMomentDateModule,
    NgSelectModule,
    CommonDialogsModule,
    EmployeesModule,
    PipesModule,
    DirectivesModule,
    OnboardingModule,
    RoundsModule,
    BuyBackModule,
    DocumentGeneratorModule,
    MatDialogModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatBadgeModule,
    MatMenuModule
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    // CustomCurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    DatePipe,
    ToolbarService,
    DataService,
    UtilityService,
    HelperService,
    PermissionsService,
    CacheProviderService,
    CanDeactivateGuard,
    DetailsViewService,
    NavbarService,
    RoundDeactivateGuard,
    BonusDeactivateGuard,
    ModellingDeactivateGuard,
    Proforma,
    DxContextMenuModule,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    CanShowFaq,
    ShouldShowPage,
    OnboardingAuthGuard,
    SVGLoader,
    DocumentAssemblerService,
    EventEmitterService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [
    PipesModule,
    MatSelectModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatSliderModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressBarModule,
    FileManagerModule,
    CommonDialogsModule,
    EmployeesModule
  ],
  entryComponents:[ConfirmDeleteComponent, ViewDocsDialogComponent,ExportSh6Component,FinancialReportComponent, ConnectTermsModalComponent, HrmsLoginModalComponent, HrmsEmployeeListModalComponent, PlanRejectedComponent,ShareHolderFundDialog,UploadPlanDocumentComponent, EmailTemplateEditorComponent, ShareDetailsPopupComponent, AdjConversionRatioPopupComponent, CloseTabComponent]
})
export class AppModule { }

