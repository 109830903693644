import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localeFormatter'
})
export class CustomLocaleFormatterPipe implements PipeTransform {

    transform(number: any, args?: "number" | "currency" | "tooltip-number" | "tooltip-currency"): string {
        if (typeof (number) === "string") {
            if (number.includes(",")) {
                number = number.replace(/,/g, "");
            }
        }
        number = number / 1;
        if (isNaN(number)) return "0"; // will only work value is a number
        if (number === null) return "0";
        if (number === 0) return "0";
        let absoluteNumber = Math.abs(number);
        const rounder = Math.pow(10, 2);
        const isNegative = number < 0; // will also work for Negetive numbers
        let key = '';

        const localeList = {
            'AED': 'ar-AE',
            'AUD': 'en-AU',
            'CNY': 'zh-CN',
            'EUR': 'en-EU',
            'GBP': 'en-GB',
            'INR': 'en-IN',
            'JPY': 'ja-JP',
            'SGD': 'en-SG',
            'USD': 'en-US',
        }
        
        let setCompanyCurrrency = JSON.parse(sessionStorage.getItem('userDetails')).company.companyCurrency;
        let currencyInitial = setCompanyCurrrency.substring(0, 3)
        let local = currencyInitial ? localeList[currencyInitial] : navigator.language
        if (args == "tooltip-number")
            return absoluteNumber.toLocaleString(local)
        else if (args == "tooltip-currency")
            return absoluteNumber.toLocaleString(local, { style: 'currency', currency: currencyInitial })

        const indianSystem = [
            {key: 'Cr', value: Math.pow(10, 7)},
            {key: 'L', value: Math.pow(10, 5)}
        ];

        const nonIndianSystem = [
            {key: 'Q', value: Math.pow(10, 15)},
            {key: 'T', value: Math.pow(10, 12)},
            {key: 'B', value: Math.pow(10, 9)},
            {key: 'M', value: Math.pow(10, 6)},
            {key: 'K', value: 1000}
        ];

        let powers;
        if(setCompanyCurrrency == "INR - ₹")
        powers = indianSystem;
        else
        powers = nonIndianSystem;

        for (let i = 0; i < powers.length; i++) {
            let reduced = absoluteNumber / powers[i].value;
            if (absoluteNumber > 99999)
            reduced = Math.round(reduced * rounder) / rounder;
            if (reduced >= 1) {
                absoluteNumber = reduced;
                key = powers[i].key;
                break;
            }
        }
        let shortenValue;
        if (args == "number")
            shortenValue = absoluteNumber.toLocaleString(local)
        else if (args == "currency")
            shortenValue = absoluteNumber.toLocaleString(local, { style: 'currency', currency: currencyInitial })

        return (isNegative ? '-' : '') + shortenValue + key;
    }
}