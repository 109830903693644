import { Component, OnInit } from '@angular/core';
import { ShareHolder } from '../buy-back-tab/model/shareHolder';
import { CacheProviderService } from "../../services/cache-provider.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { DatePipe } from "@angular/common";
import { DataService } from "../../services/new-round-notify.service";
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from "../../services/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { BuyBackDetails } from '../buy-back-tab/model/buyBackDetails';
import { HelpContent } from '../buy-back-tab/model/helpContent';

@Component({
  selector: 'app-review-tab',
  templateUrl: './review-tab.component.html',
  styleUrls: ['./review-tab.component.scss']
})
export class ReviewTabComponent implements OnInit {

  shareHolders : ShareHolder[];

  buyBackDetails:any;
  buyBackId: number;
  target : number = 0;
  pps: number = 0;
  instrumentName: string = "";

  totalBuyBackAmount: number = this.target*this.pps;
  public usrCompanyCurrency = "";
  public userDetails;
  totalWillingNess: number = 0;
  actualTargetShares: number = 0;
  actualTargetSharesText = '';
  public disableripple: boolean = true;

  public helpTitle: string = "";
  public helpMessage: string = "";
  helperTopPosition: number;
  helper: string = "";
  helpSections: HelpContent;
  currencySymbol = "";

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private utility: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) {}

  ngOnInit() {
    this.ngxLoader.start();
    this.activatedRoute.queryParams
      .filter(params => params.buyBackId)
      .subscribe(params => {
        this.buyBackId = params.buyBackId;
      }
    );
    // this.dataService.updateData({
    //   "isbuyBackDetailsTabValid":true,
    //   "isShareholdersTabValid":true,
    //   "isReviewTabValid":null,
    //   "isSummaryTabValid":null,
    //   "isConfirmTabValid":null
    // });

    this.authService.getProformaBuyBackDetails(this.buyBackId).subscribe((res: BuyBackDetails) => {
      this.buyBackDetails = res;
      this.target = res.numberOfSharesBoughtBackTarget;
      this.pps = res.pricePerShare;
      this.instrumentName = res.className;
      this.totalBuyBackAmount = res.buyBackAmount;

      if (this.buyBackDetails.state === "SHAREHOLDER_DETAILS"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":null,
          "isConfirmTabValid":null
        });
      }
      else if (this.buyBackDetails.state === "CAPTABLE_SUMMARY"){
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":true,
          "isConfirmTabValid":null
        });
      }

      if (this.buyBackDetails.startDate != null && (this.buyBackDetails.state === "SHAREHOLDER_DETAILS" || this.buyBackDetails.state === "CAPTABLE_SUMMARY")) {
        if (this.buyBackDetails.state === "SHAREHOLDER_DETAILS" || this.buyBackDetails.state === "CAPTABLE_SUMMARY"){
          this.authService.getInitialShareholdersData(this.buyBackId, false).subscribe((response:[]) => {
            this.shareHolders = response;
            const sumOfShares: number = this.shareHolders.reduce((acc,shareHolder) => acc + shareHolder.totalShares,0);
            this.shareHolders.forEach(shareHolder=> {
            shareHolder.totalBoughtShares = 0;
            shareHolder.currentHoldingPercentage = shareHolder.totalShares/sumOfShares;
            shareHolder.currentHoldingPercentageLabel = Math.round(shareHolder.currentHoldingPercentage*10000) / 100;
            shareHolder.proRataEligibility = Math.round(shareHolder.currentHoldingPercentage*this.target);
            shareHolder.isParticipating = true;
            })
            const totalWillingNess: number = this.shareHolders.reduce((acc,shareHolder) => acc + shareHolder.willingness,0);
            this.actualTargetShares = Math.min(totalWillingNess, this.target);
            this.actualTargetSharesText = this.getTotalValueTble(this.actualTargetShares)
            this.shareHolders.sort((a, b) => b.currentHoldingPercentage.toString().localeCompare(a.currentHoldingPercentage.toString()));
            this.ngxLoader.stop();
            
          }, (e) => {
                this.ngxLoader.stop();
                this.toastr.error("Error: Unable to get Shareholders data", e);
            });
        }
      }

      else{
        this.ngxLoader.stop();
        this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page");
        this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      }

    }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page", e);
          this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      });

    

    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
    this.ngxLoader.stop();
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utility.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utility.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }

  goToSummary() {
    this.ngxLoader.start();

    this.authService.executeBuyBackTempForSummary(this.buyBackId, true, this.shareHolders).subscribe((response) => {
      if (response) {
        this.dataService.updateData({
          "isbuyBackDetailsTabValid":true,
          "isShareholdersTabValid":true,
          "isReviewTabValid":true,
          "isSummaryTabValid":null,
          "isConfirmTabValid":null
        });
        // this.dataService.setApplicationData("summaryData", response)
        this.ngxLoader.stop();
        this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'summary'],
          { queryParams: { 'buyBackId': this.buyBackId } }
        );
      }
      else {
        this.ngxLoader.stop();
        this.toastr.error("Error: Response is null!", "Error");
      }
    }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error: Unable to proceed with Buy-back request");
    });

  }

  goToShareholders() {
    this.ngxLoader.start();
    this.dataService.updateData({
      "isbuyBackDetailsTabValid":true,
      "isShareholdersTabValid":true,
      "isReviewTabValid":null,
      "isSummaryTabValid":null,
      "isConfirmTabValid":null
    });
    this.ngxLoader.stop();
    this.router.navigate(
      ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'shareholders'],
      { queryParams: { 'buyBackId': this.buyBackId } }
    );
  }

  showHelp(section) {
    if(section == this.helper){
        return;
      }
      const targetElement = document.getElementById(section);
      if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
      }
      
        this.helpSections = this.helperService.getHelpContent(
          "ReviewTabComponent",
          section
        );
      this.helper = section;
}

// help section hide
  hideHelp() {
    this.helper = "";
  }

}
