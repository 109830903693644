// import { HistoricalRoundCreationComponent } from './onboarding/historical-round/creation/historical-round-creation.component';
// import { HistoricalRoundSummaryComponent } from "./onboarding/historical-round/summary/historical-round-summary.component";
// import { RoundNameComponent } from "./onboarding/historical-round/round-name/round-name.component";
import { RippleGlobalOptions } from '@angular/material';
export var MY_FORMATS = {
    parse: {
        dateInput: 'DD-MMM-YYYY',
    },
    display: {
        dateInput: 'DD-MMM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
    },
};
var globalRippleConfig = {
    disabled: true,
    terminateOnPointerUp: true,
    animation: {
        enterDuration: 0,
        exitDuration: 0
    },
};
var ɵ0 = globalRippleConfig, ɵ1 = { useUtc: true };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
