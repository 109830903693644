import { CanDeactivate} from '@angular/router';
import { Injectable } from '@angular/core';
import {RounModelingTabComponent} from '../app/modeling/roun-modeling-tab/roun-modeling-tab.component';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  hasUnsavedData: () =>  boolean;
  hasUnsavedRound: () => boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate): boolean {
    if(component.hasUnsavedData()){
        if (confirm("You have unsaved changes! If you leave, your changes will be lost.., Please save model")) {
            return true;
        } 
        else {
            return false;
        }
    }
    if(component.hasUnsavedRound()){
      if (confirm("You have unsaved changes! If you leave, your changes will be lost.., Please save model")) {
        return true;
      } 
      else {
          return false;
      }
    }
    return true;
  }
}