import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from './utilities.service';

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  private eoc;
  userDetails;
  private permissions = {
    "emp-view": "EMPLOYEES_VIEWER",
    "employee-page-viewer": "EMPLOYEE_PAGE_VIEWER",
    "full-access": "FULL_ACCESS",
    "opts-management-view": "OPTIONS_MANAGEMENT_VIEWER",
    "opts-homepage-view": "OPTIONS_HOME_PAGE_VIEWER",
    "create-plan": "CREATE_PLAN",
    "amend-plan": "AMEND_PLAN",
    "add-employee": "ADD_EMPLOYEE",
    "vesting-event-maker": "VESTING_EVENT_MAKER",
    "add-grant": "ADD_GRANT",
    "edit-grant": "EDIT_GRANT",
    "delete-grant": "DELETE_GRANT",
    "edit-employee": "EDIT_EMPLOYEE",
    "employee-status-change": "EMPLOYEE_STATUS_CHANGE",
    "change-grant-state": "CHANGE_GRANT_STATE",
    "plan-active": "PLAN_ACTIVE",
    "plan-delete": "PLAN_DELETE",
    "create-vesting-schedule-template": "CREATE_VESTING_SCHEDULE_TEMPLATE",
    "edit-vesting-schedule-template": "EDIT_VESTING_SCHEDULE_TEMPLATE",
    "vesting-event-completor": "VESTING_EVENT_COMPLETOR",
    "vest-request": "VEST_REQUEST",
    "vest-approver": "VEST_APPROVER",
    "exercise-approver": "EXERCISE_APPROVER",
    "vesting-schedule-template-viewer": "VESTING_SCHEDULE_TEMPLATE_VIEWER",
    "employees-overview-viewer": "EMPLOYEES_OVERVIEW_VIEWER",
    "admin-without-proforma": "ADMIN_WITHOUT_PROFORMA",
    "captable-summary": "CAPTABLE_SUMMARY",
    "shareholder-viewer": "SHAREHOLDER_VIEWER",
    "esop-admin": "ESOP_ADMIN",
    "esop-viewer": "ESOP_VIEWER",
  };

  getAllPermissions() {
    return this.permissions;
  }

  getUserPermissions(): Array<string> {
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (this.userDetails && this.userDetails["authorities"] != null) {
      return this.userDetails["authorities"][0]["permissions"];
      // return ['CAPTABLE_VIEWER','FULL_ACCESS']
    } else {
      return [];
    }
  }

  authorizeUser(permissionsRequiredToAccess: Array<string>): boolean {
    let permissionSet = this.getAllPermissions();
    let userPermissions = this.getUserPermissions();
    const isStrict = permissionsRequiredToAccess.includes("!strict");
    for (let index = 0; index < permissionsRequiredToAccess.length; index++) {
      const permission = permissionSet[permissionsRequiredToAccess[index]];
      const fullAccess = permissionSet["full-access"];
      const adminViewer = permissionSet["admin-without-proforma"];
      if (
        userPermissions.includes(permission) ||
        ((userPermissions.includes(fullAccess) ||
          userPermissions.includes(adminViewer)) &&
          !isStrict)
      ) {
        return true;
      }
    }
    return false;
  }

  navigateBasedOnPermission() {
    let userPermissions = this.getUserPermissions();
    let isHomePageView = this.authorizeUser(["opts-homepage-view", "!strict"]);
    let isManagementPageView = this.authorizeUser([
      "opts-management-view",
      "!strict",
    ]);
    let esopAdminView = this.authorizeUser(["esop-admin", "!strict"]);
    let esopViewer = this.authorizeUser(["esop-viewer", "!strict"]);
    let isEmployee = this.authorizeUser(["emp-view", "!strict"]);
    let isShareholder = this.authorizeUser(["shareholder-viewer", "!strict"]);
    let capTableView = this.authorizeUser(["captable-summary", "!strict"]);
    const accessNewUi = JSON.parse(sessionStorage.getItem("userDetails")).useNewEsopUI || false
    if ((isHomePageView && isManagementPageView) || esopAdminView || esopViewer) {
      return this.router.navigate([
        "/landing",
        "dashboard",
        "options",
        "overviewEsop",
      ]);
    } else if (isHomePageView && !isManagementPageView) {
      return this.router.navigate([
        "/landing",
        "dashboard",
        "options",
        "overviewEsop",
      ]);
    } else if (!isHomePageView && isManagementPageView) {
      return this.router.navigate([
        "/landing/dashboard/options/manageEsop",
        { planName: "ALL" },
      ]);
    } else if (isEmployee) {
      return this.router.navigate([
        "/landing",
        "dashboard",
        "employees",
        "employeeDetails",
        localStorage.getItem("empId"),
      ]);
    } else if (isShareholder && !capTableView) {
      return this.router.navigate([
        "landing",
        "dashboard",
        "shareholder",
        this.userDetails.shareholderId,
      ]);
    } else if (isShareholder && capTableView) {
      return this.router.navigate(["/landing", "dashboard", "capTable"]);
    }
    for (let i = 0; i < userPermissions.length; i++) {
      for (const [key, value] of Object.entries(this.permissions)) {
        if (value === userPermissions[i]) {
          if (key === "full-access") {
            if (
              JSON.parse(sessionStorage.getItem("userDetails")).company[
                "isEsopOnlyCompany"
              ]
            ) {
              this.eoc = this.router.navigate([
                "/landing",
                "dashboard",
                "options",
                "overviewEsop",
              ]);
              this.utilityService.dataEmitter.emit("companyChanged");
              return;
            }
            this.authService.getExistingCapTable(false).subscribe(
              (res) => {
                this.authService.capTableData = res;
                this.eoc = this.router.navigate([
                  "/landing",
                  "dashboard",
                  "capTable",
                ]);
                return this.eoc;
              },
              (e) => {
                return this.toastr.error(e.error.reason, "Error");
              }
            );
          }
        }
      }
    }
    return this.router.navigate(["/landing", "dashboard", "capTable"]);
  }

  // getUserPermissionsForAction():Array<string>{
  //   return ['CREATE_PLAN','AMEND_PLAN','ADD_EMPLOYEE','VESTING_EVENT_MAKER','ADD_GRANT','EDIT_EMPLOYEE','EMPLOYEE_STATUS_CHANGE'];
  // }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private utilityService: UtilityService
  ) {}
}
