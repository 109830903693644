<div class="container-fluid">
    <div class="centered">
        <div *ngIf="failureMsg == 'signinfailure'">
            <label class="message">
                No account found for this user.
            </label>
            <label class="message">
                Please SignUp to create an account.
            </label>
            <div class="buttons">
                <a [routerLink]="['/']">
                    <button class="cmn-button" ngbTooltip="Go Back">Go Back</button>
                </a>
            </div>
        </div>
        <div *ngIf="failureMsg == 'signupfailure'">
            <label class="message">
                User account already exists.
            </label>
            <label class="message">
                Please login with your credentials.
            </label>
            <div class="buttons">
                <a [routerLink]="['/']">
                    <button class="cmn-button" ngbTooltip="Go Back">Go Back</button>
                </a>
            </div>
        </div>
    </div>
</div>