import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grant-letter',
  templateUrl: './grant-letter.component.html',
  styleUrls: ['./grant-letter.component.scss']
})
export class GrantLetterComponent implements OnInit {

  templates:any;
  inputExcel:any;
  pdfConverter = "";
  resolutionName = "";
  resolutionId:number;
  constructor(
    private toastr: ToastrService,
    private authService: AuthenticationService, 
    private ngxLoader: NgxUiLoaderService,
    private router : Router,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params)=>{
      this.resolutionId = params["resolutionId"];
    });
  }

  onChangeTemplate(event) {
    this.templates = event.target.files;
  }

  onChangeInputExcel(event) {
    this.inputExcel = event.target.files[0];
  }

  onGenerateDocument() {

    if(!this.resolutionName) {
      this.toastr.warning("Please enter resolution name");
      return;
    }

    if(this.templates.length == 0 || !this.templates) {
      this.toastr.warning("Please select template file", "Warning!");
      return;
    }

    if(!this.inputExcel) {
      this.toastr.warning("Please select input excel file", "Warning!");
      return;
    }
 
    let companyId = localStorage.getItem("companyId");

    let formData = new FormData();

    for(let index:number = 0; index < this.templates.length; index++) {
      let template = this.templates[index ];
      formData.append("templateFile", template);
      formData.append("documentName", template.name);
    }
    
    if(this.inputExcel) {
      formData.append("questionnaireFile", this.inputExcel);
      formData.append("questionnaireName", this.inputExcel.name);
    }
    
    if(this.pdfConverter) {
      formData.append("pdfConverter", this.pdfConverter);
    }

    if(companyId != null) {
      formData.append("companyId", companyId);
    }
    
    formData.append("companyResolutions.resolutionName", this.resolutionName);
    
    this.ngxLoader.start();

    this.authService.uploadUserDefinedDocument(formData).subscribe((res:any) => {
      this.toastr.success("Resolution created successfully", "Success");
      let templateId = Number(res.resultCode);
      this.startEditing(templateId);
    },(e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!");
    });
    
  }

  startEditing(templateId) {

    let obj = {};
    obj["context"] = "USERDEFINED";
    obj["data"] = [];
    obj["templateId"] = templateId;

    this.authService.generateDocument(templateId, null).subscribe((response:any) => { 
      this.ngxLoader.stop();

      let documentAssemblerId:number = response.documentAssemblerId;

      if(response && !response.isDownload) {
        this.router.navigate(["/landing/dashboard/task-manager/build-documents/user-context", "USERDEFINED", templateId, documentAssemblerId]);
      }
      else {
        let ids = documentAssemblerId.toString();
        this.downloadBuiltDocument(ids);
      }
    },(e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!");
    });
  }

  downloadBuiltDocument(ids:string) {
    this.authService.downloadBuiltDocument(ids).subscribe( (res:HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    },e => {
      this.ngxLoader.stop();
      this.toastr.error(e.reason, 'Warning');
    });
  }

  onGeneratePdfDocument() {
    if(!this.inputExcel) {
      this.toastr.error("Please select template file", "Failure!");
      return;
    }

    this.ngxLoader.start();
    const formData: FormData = new FormData();
    formData.append('file', this.inputExcel);
    this.authService.convertDocxToPdf(formData, this.pdfConverter).subscribe(
        (res:HttpResponse<any>) => {
            let blob = new Blob([res.body], {type: res.headers.get("fileType")});
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = res.headers.get("fileName");
            this.ngxLoader.stop();
            link.click();
        },
        (e) => {}
    );
  }

  onBack() {
    this.router.navigate(["/landing/dashboard/task-manager/resolution-library"]);
  }
}
