import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyNameComponent } from './company-name/company-name.component';
import { HistoricalRoundCreationComponent } from './historical-round/creation/historical-round-creation.component';
import { HistoricalRoundSummaryComponent } from './historical-round/summary/historical-round-summary.component';
import { IncorporationDetailsComponent } from './incorporation-details/incorporation-details.component';
export class OnboardingAuthGuard implements CanActivate{
  /**
   * Onboarding Auth Guard to restrict employees from accessing onboarding pages 
   */
  private isEmployee = localStorage.getItem("userRole") === 'Employee'
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isEmployee) {
      return false;
    }
  return true;
}
}

const routes: Routes = [
    { path: "", canActivate:[OnboardingAuthGuard],  component: CompanyNameComponent },
    { path: "adminProfile", canActivate:[OnboardingAuthGuard], component: AdminProfileComponent },
    { path: "companyDetails", canActivate:[OnboardingAuthGuard], component: CompanyDetailsComponent },
    { path: "create-historical-round", canActivate:[OnboardingAuthGuard], component: HistoricalRoundCreationComponent},
    { path: "historical-round-summary", canActivate:[OnboardingAuthGuard], component: HistoricalRoundSummaryComponent},
    { path: "incorporationDetails", canActivate:[OnboardingAuthGuard], component: IncorporationDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
