import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
declare var bootbox: any;

@Component({
  selector: 'app-document-overview',
  templateUrl: './document-overview.component.html',
  styleUrls: ['./document-overview.component.scss']
})
export class DocumentOverviewComponent implements OnInit {

    topShareholders;
    shareholdersData;
    allShareholdersData;
    shareholderNames;
    filteredData = false;
    filteredShareholder;
    selectedGrpNme;
    cardData;

    constructor(private authService: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute) { }

    displayDetails(shareHolderID) {
        this.router.navigate(['/landing', 'dashboard', 'details', shareHolderID])
    }

    filterData(shareholderName) {
        if(shareholderName == 'All Shareholders') {
            this.filteredData = false;
        } else {
            this.filteredData = true;
            let flatData = [];
            for (let index = 0; index < this.shareholdersData.length; index++) {
                const element = this.shareholdersData[index];
                flatData.push(element.shareholders);
            }
            let flatDataDeep = _.flattenDeep(flatData);
            this.filteredShareholder = _.find(flatDataDeep, { 'name': shareholderName });
        }
    }

    identifierCSS(identifier) {
        switch (identifier) {
            case 'FF':
                return identifier;
            case 'SF':
                return identifier;
            case 'AF':
                return identifier;
            case 'ES':
                return identifier;
            case 'SA':
                return identifier;
            case 'SB':
                return identifier;
            case 'SC':
                return identifier;
            case 'AC':
                return identifier;
            case 'SD':
                return identifier;
            case 'SE':
                return identifier;
            case 'AF1':
                return 'Def1';
            case 'AF2':
                return 'Def2';
            case 'AF3':
                return 'Def3';
            case 'SP':
                return 'Def4';
            case 'SG':
                return 'Def5';
            case 'SH':
                return 'Def6';
            case 'SA1':
                return 'Def7';
            case 'SA2':
                return 'Def8';
            case 'SA3':
                return 'Def1';
            case 'SB1':
                return 'Def2';
            case 'SB2':
                return 'Def3';
            case 'SP1':
                return 'Def4';
            default:
                // return 'Def' + (Math.floor(Math.random() * 8) + 1);
                return 'defaultClass';
        }
    }

  getTotalValueTble(value) {
    // Nine Zeroes for Billions
    return Math.abs(Number(value)) >= 1.0e7
      ? (Math.abs(Number(value)) / 1.0e7).toFixed(2) + "Cr"
      : // Six Zeroes for Millions
      Math.abs(Number(value)) > 1.0e5
        ? (Math.abs(Number(value)) / 1.0e5).toFixed(2) + "L"
        : // Three Zeroes for Thousands
        Math.trunc(Math.abs(Number(value)));
  }
  filterByGroup(grpNme) {
    if(this.selectedGrpNme===grpNme){
      this.shareholdersData = this.allShareholdersData;
      this.selectedGrpNme = '';
    } else{
    this.shareholdersData.forEach(element => { if ( grpNme === element['groupName']) {
      this.shareholdersData = [element];
      this.selectedGrpNme = grpNme;
    }
    });
  }
  }

  createDocumentSet(){
      this.router.navigate(['/landing', 'dashboard', 'newDocument']);
  }
// Navigating to Round details
    goToRoundDetails(rndName){
        this.router.navigate(['/landing', 'dashboard', 'round-details', rndName]);
    }

    ngOnInit() {
        this.authService.getShareholdersOverview(true).subscribe(
            (res) => {
                if(res){
                    this.topShareholders = res['topShareholders'];
                    this.shareholdersData = res['shareholdersForTable'];
                    this.allShareholdersData = res['shareholdersForTable'];
                    this.shareholderNames = res['namesForTheDropdown'];
                } else{
                }
                
            },
            (e) => { bootbox.alert("Error: " + e.error.reason); }
        );

        this.authService.getDashboardCards(true).subscribe(
            (res) => {
                this.cardData = res;
            },
            (e) => { bootbox.alert("Error: " + e.error.reason); }
        );
    }

}
