import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../authentication.service';
import { HelperService } from '../../services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { NavbarService } from 'src/app/services/navbar-reload';

@Component({
  selector: 'app-company-name',
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.scss']
})

export class CompanyNameComponent implements OnInit {

  public companyName = '';
  queryParamCompany = null;

  public helper: string = "";
  public helpSections;
  public helperTopPosition = null;
  isReqInProg: boolean = false;
  selectedCompany;
  registerNewCompany: boolean = false;

  public isCompanyPresent: boolean = false;
  public isCompanyRegistered: boolean = false;
  public isCompanyNew: boolean = false;
  isGrantedAccess: boolean = false;
  registeredCompanies;
  public isElasticEmpty:boolean = false;

  constructor(private helperService: HelperService,
    private router: Router,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private navbarService: NavbarService,
    private ngxLoader: NgxUiLoaderService) { }

  ngOnInit() {
    this.companyName = localStorage.getItem('companyName');
    this.queryParamCompany = this.activatedRoute.snapshot.queryParamMap.get('company');
    if(this.queryParamCompany) {
        this.proceedToAdminProfile(this.queryParamCompany);
    }
    if(this.activatedRoute.snapshot.paramMap.get('newComp') == 'true') {
        this.registerNewCompany = true;
        let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
        if(userDetails['searchedCompany'] && userDetails['isVerified'] == true && userDetails['isAllowedAccess'] == true && userDetails['isSearchedCompanyMapingFound'] == true) {
            this.isReqInProg = true;
        }
    }
  }

  onElasticSearch(company) {
    const comp = encodeURIComponent(company);
    this.authService.elasticSearchCompanies(comp).subscribe(
      (res) => {
        this.registeredCompanies = res;
      }, e => {
        this.toastr.error(e.error.reason, "Error");
    });
  }

  onDbSearch(elastics, company){
    let dbCompanies;
    this.authService.getRegisteredCompanies().subscribe(
      (result) => {
        dbCompanies = result;
        let isDBCompany = dbCompanies.find(x => x.nameOfTheCompany === company);
        if(isDBCompany){
          this.registeredCompanies = dbCompanies;
        }
        else {
          this.registeredCompanies = elastics;
        }
      },
      (e) => {}
    );
  }

  setcompanyCIN(value) {
      console.log('company selected: ', this.companyName);
      console.log('company details: ', this.registeredCompanies.find(x => x.nameOfTheCompany === this.companyName));
      this.selectedCompany = this.registeredCompanies.find(x => x.nameOfTheCompany === this.companyName);
  }
  
  arrayIsEmpty(array){
    if(!Array.isArray(array)){
        return false;
    }
    if(array.length == 0){
        return true;
    }
    return false;
  }

  proceedToAdminProfile(company) {
    this.ngxLoader.start();
    let udm = {
    }
    this.authService.checkCompanyName(company, udm, this.registerNewCompany).subscribe(
      res => {
        if (res['isCompanyExists'] == true) {
          console.log('query param: ', this.queryParamCompany);
          this.isGrantedAccess = res['isAllowedAccess'];
          this.isCompanyRegistered = true;
          this.isCompanyPresent = true;
          this.isCompanyNew = false;
          this.ngxLoader.stop();
        }
        else if (res['isCompanyExists'] == false) {
          localStorage.setItem('companyName', company);
          if(this.registerNewCompany) {
            this.router.navigate(['/landing', 'onboarding', 'adminProfile', { newComp: true }]);
          } else {
            this.router.navigate(['/landing', 'onboarding', 'adminProfile']);
          }
          this.ngxLoader.stop();
        }
      }, e => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      })
  }

  goToDashboard() {
    this.authService.getUserDetails().subscribe(
        (res) => {
          this.cacheService.set("userDetails", JSON.stringify(res));
          let userDetails = res;
          if(userDetails['company'] && userDetails['incorporationDetails']) {
            this.navbarService.sendClickEvent();
            setTimeout(() => {
              this.router.navigate(['/landing', 'dashboard', 'capTable']);
            }, 300);
          } else if(userDetails['company'] && !userDetails['incorporationDetails']) {
            if(this.registerNewCompany) {
                this.router.navigate(['/landing', 'onboarding', 'create-historical-round', { newComp:  true }])
            } else {
                this.router.navigate(['/landing', 'onboarding', 'create-historical-round'])
            }
          }
        },
        e => {
          this.toastr.error(e.error.reason, "Error");
        }
      );
  }

  proceedToRequest() {
    this.ngxLoader.start();
    let udm = {
    }

    this.authService.requestVerification(this.companyName, udm).subscribe(
      () => {
        this.isCompanyRegistered = false;
        this.isCompanyPresent = true;
        this.isCompanyNew = true;
        this.ngxLoader.stop();
      }, e => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      })
  }

  gotoEmployeeLogin() {
    this.ngxLoader.start();
    this.ngxLoader.stop();
    this.router.navigateByUrl('/employeeLogin');
  }

  showHelp(section) {
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);

    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "CompanyNameComponent",
      section
    );
    this.helper = section;
  }

  hideHelp() {
    this.helper = "";
  }

}