/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tags-edit/tags-edit.component.ngfactory";
import * as i3 from "./tags-edit/tags-edit.component";
import * as i4 from "../../../node_modules/@syncfusion/ej2-angular-filemanager/ej2-angular-filemanager.ngfactory";
import * as i5 from "@syncfusion/ej2-angular-filemanager";
import * as i6 from "@angular/common";
import * as i7 from "./file-manager.component";
import * as i8 from "../authentication.service";
import * as i9 from "@angular/router";
var styles_FileManagerComponent = [i0.styles];
var RenderType_FileManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileManagerComponent, data: {} });
export { RenderType_FileManagerComponent as RenderType_FileManagerComponent };
function View_FileManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tags-edit", [], null, [[null, "isShowTagsChange"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isShowTagsChange" === en)) {
        var pd_0 = ((_co.showTagsPopup = $event) !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = (_co.getTagsString($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TagsEditComponent_0, i2.RenderType_TagsEditComponent)), i1.ɵdid(1, 114688, null, 0, i3.TagsEditComponent, [], { isShowTags: [0, "isShowTags"], tagsString: [1, "tagsString"] }, { visibleChange: "visibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTagsPopup; var currVal_1 = _co.tagsString; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FileManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "ejs-filemanager", [["id", "default-filemanager"]], null, [[null, "beforeSend"], [null, "menuOpen"], [null, "menuClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("beforeSend" === en)) {
        var pd_0 = (_co.beforeSend($event) !== false);
        ad = (pd_0 && ad);
    } if (("menuOpen" === en)) {
        var pd_1 = (_co.menuOpen($event) !== false);
        ad = (pd_1 && ad);
    } if (("menuClick" === en)) {
        var pd_2 = (_co.menuClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_FileManagerComponent_0, i4.RenderType_FileManagerComponent)), i1.ɵdid(4, 6537216, null, 0, i5.FileManagerComponent, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i1.Injector], { ajaxSettings: [0, "ajaxSettings"], allowDragAndDrop: [1, "allowDragAndDrop"], contextMenuSettings: [2, "contextMenuSettings"], detailsViewSettings: [3, "detailsViewSettings"] }, { beforeSend: "beforeSend", menuClick: "menuClick", menuOpen: "menuOpen" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileManagerComponent_1)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ajaxSettings; var currVal_1 = true; var currVal_2 = _co.contextMenuSettings; var currVal_3 = _co.detailsViewSettings; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.showTagsPopup; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_FileManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-manager", [], null, null, null, View_FileManagerComponent_0, RenderType_FileManagerComponent)), i1.ɵdid(1, 8503296, null, 0, i7.FileManagerComponent, [i8.AuthenticationService, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileManagerComponentNgFactory = i1.ɵccf("app-file-manager", i7.FileManagerComponent, View_FileManagerComponent_Host_0, { path: "path" }, {}, []);
export { FileManagerComponentNgFactory as FileManagerComponentNgFactory };
