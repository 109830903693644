<div class="container-fluid graybg">
    <div class="">

    <!-- <div class="row">
        <div class="col-md-12 padding-left-zero">
            <owl-carousel-o [options]="customOptions" *ngIf='cardData!=null && cardData.length>0'>
                <ng-container *ngFor="let card of cardData; let i = index">
                    <ng-template carouselSlide>
                        <div class="margin-right-lg">
                            <div class="card-lyot">
                                <label class="btm-10">
                                    <img src="../../../assets/dash{{ (i % 5) + 1 }}.svg" />
                                </label>
                                <mat-label class="card-hdr">{{ card.cardName }}</mat-label>
                                <span class="bottom-line"></span>
                                <mat-label class="card-lbl btm-10">{{ card.firstSectionName }}</mat-label>
                                <mat-label *ngIf="card.cardName == 'Funding'" class="card-value btm-10"
                                    container="body" ngbTooltip="{{formatCurrency(card.firstSectionValue)}}">
                                    {{ getTotalValueTble(card.firstSectionValue)}}
                                </mat-label>
                                <mat-label *ngIf="card.cardName != 'Funding'" class="card-value btm-10"
                                    container="body" ngbTooltip="{{formatCurrency(card.firstSectionValue)}}">
                                    {{ card.firstSectionValue == null ? "-" : getTotalValueTble(card.firstSectionValue) }}
                                </mat-label>
                                <mat-label class="card-lbl btm-10">{{ card.secondSectionName }}</mat-label>
                                <mat-label *ngIf="card.cardName == 'Funding'" class="card-value" container="body"
                                    ngbTooltip="{{formatCurrency(card.secondSectionValue)}}">
                                    {{ getTotalValueTble(card.secondSectionValue) }}
                                </mat-label>
                                <mat-label *ngIf="card.cardName != 'Funding' && card.cardName != 'Shares' " class="card-value" container="body"
                                    ngbTooltip="{{formatCurrency(card.secondSectionValue)}}">
                                    {{ card.secondSectionValue == null ? "-" : getTotalValueTble(card.secondSectionValue)}}
                                </mat-label>
                                <mat-label *ngIf="card.cardName == 'Shares'" class="card-value" container="body"
                                    ngbTooltip="{{formatCurrency(card.secondSectionValue)}}">
                                    {{ formatCurrency(card.secondSectionValue) }}
                                </mat-label>
                            </div> 
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <div class="row" *ngIf='cardData==null || cardData.length==0'>
                <div class="col-md-12 text-align-center">
                    <span class="txt-nodata">
                        No Data
                    </span>
                </div>
            </div> 
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-12 padding-left-zero">
            <owl-carousel-o id="card-carousel" [options]="customOptions" *ngIf='cardData!=null && cardData.length>0'>
                <ng-container *ngFor="let card of cardData; let i = index" >
                    <ng-template carouselSlide>
                        <app-card-overview
                        [primaryHeader]="card.firstSectionName"
                        [primaryValue]="card.firstSectionValue"
                        [secondaryHeader]="card.secondSectionName"
                        [secondaryValue]="card.secondSectionValue"
                        [mainCardHeader]="card.cardName"
                        [iconPosition]="'right'"
                        [svgIcon]="iconMap[card.cardName]"
                        [holdingCompanyCurrency]="card.parentCompanyCurrency"
                        >
                        </app-card-overview>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="row" *ngIf='cardData==null || cardData.length==0'>
                <div class="col-md-12 text-align-center">
                    <span class="txt-nodata">
                        No Data
                    </span>
                </div>
            </div> -->
        </div>
    </div>
    </div>
    <div class="">
    <!-- <div class="row">
        <div class="col-md-12">
            <span class="mat-card-title" style="display: inline;">Cap Table</span>
            <span *appHideIf="['captable-summary', 'admin-without-proforma']" class="proforma-padding">
                <mat-checkbox [disableRipple]="true" [(ngModel)]="includeProforma"
                    (change)="includeProformaCall($event.checked)">
                    Include In-progress Round
                </mat-checkbox> -->
                <!-- <input type="checkbox" class="checkplace" [(ngModel)]="includeProforma"
                    (change)="includeProformaCall($event.target.checked)" /> -->
            <!-- </span> -->
            <!-- <span class="pl-xs-5">Include In-progress Round</span> -->
        <!-- </div>
    </div> -->

    <div class="row">
        <div class="col-md-12">
            <owl-carousel-o [options]="customsOption" class="row">
                <ng-container>
                    <ng-template carouselSlide>
                        <div class="col-sm-12">
                            <div class="cursor-hand btm-20 top-20 whitegraycard " (click)="displayShareholders()">
                                <span *ngIf='capTableData!=null'>
                                    <dx-pie-chart style="width: 100%;" id="pie" type="doughnut"
                                        resolveLabelOverlapping="shift"
                                        centerTemplate="centerShareholderTemplate"
                                        [title]="{
                                    text: 'Shareholders',
                                    horizontalAlignment: 'center',
                                    font: {
                                        family: 'Roboto',
                                        size: 28,
                                        weight: 500,
                                        color: '#000000'
                                    }
                                    }" [palette]="[
                                    '#4d1981',
                                    '#F4522B',
                                    '#e0a41e',
                                    '#ffe82d',
                                    '#b80d5e',
                                    '#ff1382',
                                    '#1e7bcf',
                                    '#2598ff',
                                    '#7f9c25',
                                    '#9dc02e'
                                    ]" diameter="0.6" [dataSource]="capTableData" sizeGroup="4">
                                        <dxi-series argumentField="shareholder">
                                            <dxo-label [visible]="true" position="inside"
                                                [customizeText]="customizeText" format="decimal" backgroundColor="none">
                                                <dxo-font color="#ffffff" weight="bold"></dxo-font>
                                                <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                        <dxo-legend [visible]="true" position="outside"  verticalAlignment="bottom"
                                            horizontalAlignment="center" itemTextPosition="right"
                                            orientation="horizontal">
                                            <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                                        </dxo-legend>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"
                                            format="decimal" opacity="1" [border]="{
                                        opacity: '0',
                                        visible: false
                                    }" paddingLeftRight="6" paddingTopBottom="6" zIndex="1000">
                                        </dxo-tooltip>
                                        <svg *dxTemplate="let pieChart of 'centerShareholderTemplate'">
                                            <text text-anchor="middle" style="font-size: 20px" x="100" y="120" fill="#000000">
                                                <tspan x="100" dy="20px" style="font-weight: 500">
                                                  {{ stockholderDone() }}
                                                    </tspan>
                                            </text>
                                        </svg>
                                    </dx-pie-chart>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="col-sm-12">
                            <div class=" cursor-hand btm-20 top-20 whitegraycard" (click)="displayRoundOverview()">
                                <span *ngIf='capTableData!=null'>
                                    <dx-pie-chart style="width: 100%;" id="pie" type="doughnut"
                                        resolveLabelOverlapping="shift"
                                        centerTemplate="centerRoundTemplate"
                                        [title]="{
                                    text: 'Rounds',
                                    horizontalAlignment: 'center',
                                    font: {
                                        family: 'Roboto',
                                        size: 28,
                                        weight: 500,
                                        color: '#000000'
                                    }
                                    }" [palette]="[
                                    '#f79663',
                                    '#feca7a',
                                    '#16d8d8',
                                    '#00acb3',
                                    '#f24c88',
                                    '#d84774'
                                    ]" [dataSource]="roundChart" sizeGroup="4">
                                        <dxi-series argumentField="roundIdentifier" valueField="roundInvestmentAmount">
                                            <dxo-label [visible]="true" position="inside" backgroundColor="none"
                                                [customizeText]="this.usrCompanyCurrency === 'INR - ₹' ? customizeRoundTextCC : customizeRoundTextRC "
                                                format="decimal">
                                                <dxo-font color="#ffffff" weight="bold"></dxo-font>
                                                <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                        <dxo-legend [visible]="true" position="outside"  verticalAlignment="bottom"
                                            horizontalAlignment="center" itemTextPosition="right"
                                            orientation="horizontal">
                                            <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                                        </dxo-legend>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeRoundTooltip"
                                            format="decimal" opacity="1" [border]="{
                                        opacity: '0',
                                        visible: false
                                    }" paddingLeftRight="6" paddingTopBottom="6" zIndex="1000">
                                        </dxo-tooltip>
                                        <svg *dxTemplate="let pieChart of 'centerRoundTemplate'">
                                            <text text-anchor="middle" style="font-size: 20px" x="100" y="120" fill="#000000">
                                                <tspan x="100" dy="20px" style="font-weight: 500">
                                                  {{ roundDone() }}
                                                    </tspan>
                                            </text>
                                        </svg>
                                    </dx-pie-chart>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="col-sm-12">
                            <div class=" cursor-hand btm-20 top-20 whitegraycard" (click)="displaySecurities()">
                                <span *ngIf='capTableData!=null'>
                                    <dx-pie-chart style="width: 100%;" id="pie"
                                    resolveLabelOverlapping="shift"
                                    [title]="{
                                    text: 'Securities',
                                    horizontalAlignment: 'center',
                                    font: {
                                        family: 'Roboto',
                                        size: 28,
                                        weight: 500,
                                        color: '#000000'
                                    }
                                    }" [palette]="['#fb9e06', '#9215b3', '#fa3555', '#0aa2f6']"
                                        [dataSource]="instrumentArray" sizeGroup="4">
                                        <dxi-series argumentField="instrument" valueField="value">
                                            <dxo-label [visible]="true" position="inside" backgroundColor="none"
                                                [customizeText]="customizeInsText" format="fixedPoint">
                                                <dxo-font color="#ffffff" weight="bold"></dxo-font>
                                                <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                        <dxo-legend [visible]="true"  position="outside" verticalAlignment="bottom"
                                            horizontalAlignment="center" itemTextPosition="right"
                                            orientation="horizontal" [customizeItems]="customizeLegendText">
                                            <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                                        </dxo-legend>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeInsTooltip"
                                            format="decimal" opacity="1" [border]="{
                                        opacity: '0',
                                        visible: false
                                    }" paddingLeftRight="6" paddingTopBottom="6" zIndex="1000">
                                        </dxo-tooltip>
                                    </dx-pie-chart>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-12">
            <p class="content-pg-1 btm-20">
                The cap table is a summary of all the capital transactions undertaken by the company till date. It
                displays details of the shareholders, series of rounds raised, types of securities issued, total shares of
                the company and the percentage equity holding of the shareholders on a fully diluted basis.
            </p>
        </div>
    </div> -->
    <!-- <div class="whiteboxwrap">
    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 topPadding ">
        <div class="row">

          <div class="col-sm-8 col-md-8 col-xs-8 col-lg-8 bottomPadding">
            <div class="coldgridOPerations">
                <span style="font-weight: 900;padding-left: 20px;font-size: 19px;">Cap Table</span> -->




              <!-- <mat-form-field appearance="outline">
                <mat-label>
                  Search by name, plan, status
                </mat-label>
                <input [disabled]="!holdersList" type="text" [formControl]="searchFormControl"
                  (keyup)="searchEntered($event.target.value)" matInput [matAutocomplete]="auto" />
                <mat-autocomplete (optionSelected)="searchEntered(searchFormControl.value)" #auto="matAutocomplete">
                  <mat-option *ngFor="let option of searchOptions | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
                <span matPrefix><i class="fa fa-search" aria-hidden="true"></i></span>
              </mat-form-field>
              <mat-icon (click)="expandColumns()" class="column-chooser" ngbTooltip="Expand All/Collapse All"><i
                class="fa fa-arrows-alt fa-lg" aria-hidden="true"></i></mat-icon> -->
            <!-- </div>
          </div>
          <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4">
            <div class="coldgridOPerations align-right">

                <mat-icon (click)="expandColumns()" ngbTooltip="Expand All/Collapse All"><img  style="height: 28px; margin-top: 12px; margin-left: -41px;" src="../../assets/expandcollbox.svg"><i
                    class=" fa-lg" aria-hidden="true"></i></mat-icon>


              <mat-form-field appearance="outline">

                <mat-label>Export</mat-label>
                <mat-select>
                  <mat-option (click)="exportCaptableReport('round')" value="export">Export round based report</mat-option>
                  <mat-option (click)="exportCaptableReport('security')"
                    value="exportSelected">Export security based report</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>
                  Search..
                </mat-label>
                <input [disabled]="!holdersList" type="text" [formControl]="searchFormControl"
                  (keyup)="searchEntered($event.target.value)" matInput [matAutocomplete]="auto" />
                <mat-autocomplete (optionSelected)="searchEntered(searchFormControl.value)" #auto="matAutocomplete">
                  <mat-option *ngFor="let option of searchOptions | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
                <span matPrefix><i class="fa fa-search" aria-hidden="true"></i></span>
              </mat-form-field>
            </div>

          </div>
        </div>
      </div>
    <app-dev-ex-cap-table  #devExCapTable overrideDefaultActionToolbar="true" [dataSource]="holdersList" [pageType]="'Dashboard'" [displayCols]="displayCols" [includeProforma]="includeProforma" [incorporationDate]="incorporationDate"></app-dev-ex-cap-table>
    </div> -->
    <div class="whiteboxwrap">
        <span *appHideIf="['captable-summary', 'admin-without-proforma']" class="proforma-padding">
            <mat-checkbox [disableRipple]="true" [(ngModel)]="includeProforma"
                (change)="includeProformaCall($event.checked)">
                Include In-progress Round
            </mat-checkbox> 
            <!-- <input type="checkbox" class="checkplace" [(ngModel)]="includeProforma"
                (change)="includeProformaCall($event.target.checked)" /> -->
         </span>
        <!-- <span class="pl-xs-5">Include In-progress Round</span>  -->
    <app-dev-ex-cap-table [dataSource]="holdersList" [pageType]="'Dashboard'" [displayCols]="displayCols" [includeProforma]="includeProforma" [incorporationDate]="incorporationDate"></app-dev-ex-cap-table>
</div>

    <div *ngFor="let subsidiaryCompany of subsidiaryCompanies; let i=index" >
        <div class="whiteboxwrap">
            <app-dev-ex-cap-table [dataSource]="subsidiaryCompany.capTable" [pageType]="'Dashboard'" [displayCols]="displayCols" [includeProforma]="includeProforma" [incorporationDate]="incorporationDate" [isSubsidiary]="true" [subsidiaryName]="subsidiaryCompany.companyName" [subsidiaryRelation]="subsidiaryCompany.relation" [subsidiaryCurrency]="subsidiaryCompany.companyCurrency" [subsidiaryCompanyId]="subsidiaryCompany.companyId" [subsidiaryCurrentCompanyHoldingPercentage]="subsidiaryCompany.currentCompanyHoldingPercentage" [subsidiaryHasAccess]="subsidiaryCompany.hasAccess"></app-dev-ex-cap-table>
        </div>
    </div>

    <div class="row" *ngIf="dashNotes">
        <div class="col-md-12">
          <mat-label class="margin-left-20">Notes</mat-label>
          <textarea [(ngModel)]="dashNotes" class="form-control inputs top-10" readonly
            rows="4" placeholder="Note" title="">
          </textarea>
        </div>
    </div>

    </div>
    <div class="row" *ngIf="onBoardingStatus === 'SemiApproved'">
        <div class="col-md-12">
          <button mat-raised-button color="success" [disableRipple]="true" ngbTooltip="Approve"
            class="float-right margin-left-lg" (click)="approveHistoricalRound()">
            Approve
          </button>
          <!-- <button mat-raised-button color="success" [disableRipple]="true" ngbTooltip="Back"
            class="float-right margin-left-lg" [routerLink]="['/landing','onboarding','historical-round-summary']">
            Back
          </button> -->
          <button mat-raised-button color="warn" [disableRipple]="true" ngbTooltip="Back" class="float-right"
            (click)="discardHistoricalRound()">
            Back
          </button>
        </div>
      </div>
</div>
