import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/authentication.service";
import { Router } from "@angular/router";
import { DataService } from '../../services/new-round-notify.service';
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from '../../services/helper.service';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from '../../services/cache-provider.service';
import { HttpResponse } from "@angular/common/http";


@Component({
  selector: "app-summary-tab",
  templateUrl: "./summary-tab.component.html",
  styleUrls: ["./summary-tab.component.scss"]
})
export class SummaryComponent implements OnInit {
  selectedRoundName;
  roundInformation;
  invstorNmeLst;
  showOnlyInvestor = false;
  showOnlyInvestorInfo;
  investorsTotAmt;
  investorChart;
  isChartView = false;
  public investor;
  public showCurConvertor: boolean = false;
  public homeCurrency;
  public errorFlags;
  trancheList;
  trancheName;
  trnchesInformation;
  tranchInform;
  isRoundNotSaved: boolean = false;
  exchangeRate = 1;
  companyCurrency;
  roundCurrency;
  roundCurrencySymbol;
  public disableripple: boolean = true;
  expanded = false;
  expandAll = false;
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  selectedinvestorsnme = "all";
  selectedtranch;

  public isInvestmentMet: boolean = false;
  public amountRaised;
  public amountLefttoRaised;

  isOpen = false;
  public showNotes;
  public sideNote;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private dataService: DataService,
    private utilityService: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) { }

  fieldEdited($event) {
    this.isRoundNotSaved = true;
  }

  exportReport() {
    this.authService.generateRoundSummaryReport(this.roundInformation.roundName, this.trnchesInformation.capTable).subscribe(
        (res: HttpResponse<Blob>) => {
            const blob = new Blob([res.body], { type: res.headers.get("fileType") });
            const url= window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = res.headers.get("fileName");
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            window.URL.revokeObjectURL(url);
            link.remove();
        },
        (e) => {}
    );
  }

  exportPas4Report() {
    this.ngxLoader.start();
    this.authService.exportProformaRoundPas4().subscribe((res: HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    }, e => {
      this.ngxLoader.stop();
      this.toastr.error(e.reason, 'Error');
    });
  }

  getSummaryData() {
    this.ngxLoader.start();
    this.selectedRoundName = this.cacheService.get("newRoundName");
    this.authService
      .getRoundSummary(this.selectedRoundName)
      .subscribe(response => {
        if (response) {
          this.roundInformation = response;
          this.homeCurrency = response['companyCurrency'];
          this.roundCurrency = response['currencyForRound'];
          this.exchangeRate = response['exchangeRate'];
          this.companyCurrency = response['companyCurrency'];
          this.investorsTotalAmt(this.roundInformation["investors"]);
          this.getInvestorChartData(this.roundInformation["investors"]);
          this.invstorNmeLst = response["investorsNamesForDropdown"];
          this.errorFlags = response['errorFlags'];
          this.dataService.updateData(this.errorFlags);
          this.trancheList = response['trancheListForDropdown'];
          this.tranchInform = response['summaryTrancheDtos'];

          this.amountRaised = response['amountRaised'];
          this.amountLefttoRaised = response['amountLeftToRaise'];
          this.roundCurrencySymbol = this.roundCurrency.split("-")[1].trim();

          if (this.amountLefttoRaised <= 0) {
            this.isInvestmentMet = true;
          }

          if (this.roundInformation.sideNote != null) {
            this.showNotes = true;
          } else {
            this.showNotes = false;
          }

          //For Basic Details
          let basicData = {
            isBasicDetailsFilled: true,
            roundName: response['roundName'],
            roundSize: response['roundSize'],
            roundValuation: response['roundValuation'],
            amountRaised: response['amountRaised'],
            amountLeftToRaise: response['amountLeftToRaise'],
            leadInvestorName: response['leadInvestorName'],
            sharePrice: response['pricePerShare'],
            roundCurrency: response['currencyForRound'],
            isRoundNotSaved: this.isRoundNotSaved,
          }
          this.dataService.updateBasicData(basicData);

          this.tranchInform.forEach((element, index, array) => {
            if (index === (array.length - 1)) {
              this.trnchesInformation = element;
              this.selectedtranch = this.trnchesInformation['trancheName'];
            }
          });
          this.expandAllItem(true);
          this.ngxLoader.stop();
        } else {
          this.toastr.error("Error: Null response!", "Error");
          this.ngxLoader.stop();
        }
      }, (e) => {
        this.toastr.error(e.error.reason, "Error");
        this.ngxLoader.stop();
      });
  }
  // Investors chart related methods start here

  getInvestorChartData(investorsdetails) {
    this.investorChart = [];
    investorsdetails.forEach(group => {
      let invstrChartObj = {
        shareholder: group["investorName"],
        val: group["investmentAmount"]
      };
      this.investorChart.push(invstrChartObj);
    });
  }

  InvestorsView() {
    this.isChartView = !this.isChartView;
  }

  showCurrency(amount) {
    return this.utilityService.calculateCurrency(this.roundCurrency, this.companyCurrency, this.exchangeRate, amount)
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  customizeTooltip = (arg) => {
    return {
      text: this.getAmount(this.companyCurrency, arg.value),
      fontColor: "#ffffff",
      color: "#000000"
    };
  }

  customizeLegendText(items) {
    items.forEach(element => {
        if(element['text'].length > 10) {
            element['text'] = element['text'].substring(0, 10) + '...';
        }
    });
    return items;
  }

  getAmount(usrCompanyCurrency, amt){
    let val = this.utilityService.formatCurrency(usrCompanyCurrency, amt);
    return val;
  }

  customizeText(arg) {
    return arg.percentText;
  }

  //investor filter
  filterByInvestor(shareholderName) {
    if (shareholderName == 'all') {
      this.showOnlyInvestor = false;
    } else {
      this.showOnlyInvestor = true;
      let flatData = [];
      for (let index = 0; index < this.trnchesInformation.shareholderTypes.length; index++) {
        const element = this.trnchesInformation.shareholderTypes[index];
        if (!element.shareholders) {
          for (let grpIdx = 0; grpIdx < element.shareholderGroups.length; grpIdx++) {
            const grpelement = element.shareholderGroups[grpIdx];
            flatData.push(grpelement.shareholderDetails);
          }
        }
        if (element.shareholders) {
          flatData.push(element.shareholders);
        }
      }
      let flatDataDeep = _.flattenDeep(flatData);
      this.showOnlyInvestorInfo = _.find(flatDataDeep, { 'name': shareholderName });
    }
  }

  //tranche filter
  filterByTranches(tranche) {
    if (this.selectedinvestorsnme) {
      this.tranchInform.forEach(element => {
        if (element.trancheName === tranche) {
          this.trnchesInformation = element;
        }
      });
      this.filterByInvestor(this.selectedinvestorsnme);
    }
    else {
      this.tranchInform.forEach(element => {
        if (element.trancheName === tranche) {
          this.trnchesInformation = element;
        }
      });
    }
  }

  investorsTotalAmt(investorLst) {
    let totalAmt = 0;
    investorLst.forEach(element => {
      totalAmt += element.investmentAmount;
    });
    this.investorsTotAmt = totalAmt;
  }

  identifierCSS(identifier) {
    switch (identifier) {
      case "FF":
        return identifier;
      case "SF":
        return identifier;
      case "AF":
        return identifier;
      case "ES":
        return identifier;
      case "SA":
        return identifier;
      case "SB":
        return identifier;
      case "SC":
        return identifier;
      case "AC":
        return identifier;
      case "SD":
        return identifier;
      case "SE":
        return identifier;
      case "AF1":
        return "Def1";
      case "AF2":
        return "Def2";
      case "AF3":
        return "Def3";
      case "SP":
        return "Def4";
      case "SG":
        return "Def5";
      case "SH":
        return "Def6";
      case "SA1":
        return "Def7";
      case "SA2":
        return "Def8";
      case "SA3":
        return "Def1";
      case "SB1":
        return "Def2";
      case "SB2":
        return "Def3";
      case "SP1":
        return "Def4";
      default:
        // return 'Def' + (Math.floor(Math.random() * 8) + 1);
        return "defaultClass";
    }
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.formatCurrencyDeci(this.roundCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  getCurrencyconvert(value) {
    if ( typeof value === "number") {
      return this.utilityService.formatCurrency(this.roundCurrency,value);
    }
    else {
      return value;
    }
  }

  formatCurrency(value) {
    return this.utilityService.formatCurrencyDeci(this.roundCurrency, value);
  }

  SaveRound() {
    this.ngxLoader.start();
    this.isRoundNotSaved = false;
    //For Basic Details
    let basicData = {
      isBasicDetailsFilled: true,
      roundName: this.roundInformation['roundName'],
      roundSize: this.roundInformation['roundSize'],
      roundValuation: this.roundInformation['roundValuation'],
      amountRaised: this.roundInformation['amountRaised'],
      amountLeftToRaise: this.roundInformation['amountLeftToRaise'],
      leadInvestorName: this.roundInformation['leadInvestorName'],
      sharePrice: this.roundInformation['pricePerShare'],
      roundCurrency: this.roundInformation['currencyForRound'],
      isRoundNotSaved: this.isRoundNotSaved,
    }
    this.dataService.updateBasicData(basicData);
    this.authService.CreateNewRound(this.roundInformation.sideNote).subscribe(respose => {
      if (respose) {
        this.ngxLoader.stop();
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'overview']);
      }
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error("Error: " + e.error.reason, "Error");
    });
  }

  convertCurrency(investor) {
    document.getElementById('overlay').style.display = "block";
    this.investor = investor;
    this.showCurConvertor = true;
  }

  getEvent(e) {
    this.showCurConvertor = e;
    document.getElementById('overlay').style.display = "none";
    this.getSummaryData();
  }

  showRndCurrency(data) {
    if (typeof data.currencyAmount === "string") {
      data.currencyAmount = data.currencyAmount.replace(/,/g, "") / 1;
    }
    let rndId = data.name + "RC";
    let comId = data.name + "CC";
    let roundCurrency;
    let exchangeRte = data['currencyConverter']['exchangeRate'];
    (<HTMLInputElement>document.getElementById(rndId)).disabled = true;
    (<HTMLInputElement>document.getElementById(comId)).disabled = false;
    document.getElementById(rndId).classList.add('active');
    document.getElementById(rndId).classList.remove('inactive');
    document.getElementById(comId).classList.remove('active');
    document.getElementById(comId).classList.add('inactive');
    roundCurrency = data['currencyAmount'] / exchangeRte;
    this.findInvestor(data.name, roundCurrency, rndId);
  }

  showCompCurrency(data) {
    if (typeof data.currencyAmount === "string") {
      data.currencyAmount = data.currencyAmount.replace(/,/g, "") / 1;
    }
    let rndId = data.name + "RC"
    let comId = data.name + "CC";
    let companyCurrency;
    let exchangeRte = data['currencyConverter']['exchangeRate'];
    (<HTMLInputElement>document.getElementById(rndId)).disabled = false;
    (<HTMLInputElement>document.getElementById(comId)).disabled = true;
    document.getElementById(comId).classList.add('active');
    document.getElementById(comId).classList.remove('inactive');
    document.getElementById(rndId).classList.remove('active');
    document.getElementById(rndId).classList.add('inactive');
    companyCurrency = data['currencyAmount'] * exchangeRte;
    this.findInvestor(data.name, companyCurrency, comId);
  }

  findInvestor(invName, currency, id) {
    let comId = invName + "CC";
    let selInvestor = invName;
    this.selectedtranch;
    this.trnchesInformation;
    let shareHolders = this.trnchesInformation['shareholderTypes'];
    shareHolders.forEach(element => {
      if (element['shareholders']) {
        element['shareholders'].forEach(shrHdlr => {
          if (shrHdlr['name'] === selInvestor) {
            if (id === comId) {
              shrHdlr['currencyAmount'] = this.utilityService.convertToCompanyCurrency(currency);
            }
            else {
              shrHdlr['currencyAmount'] = this.utilityService.convertToRoundCurrency(currency);
            }
          }
        });
      } else if (element['shareholderGroups']) {
        element['shareholderGroups'].forEach(group => {
          group['shareholderDetails'].forEach(shareHolder => {
            if (shareHolder['name'] === selInvestor) {
              if (id === comId) {
                shareHolder['currencyAmount'] = this.utilityService.convertToCompanyCurrency(currency);
              }
              else {
                shareHolder['currencyAmount'] = this.utilityService.convertToRoundCurrency(currency);
              }
            }
          });
        });
      }
    });

  }

  ngOnInit() {
    this.getSummaryData();
  }

  goToTranches() {
    this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "capTable"]);
  }

  expandAllItem(init) {
    if (this.isOpen == false) {
      this.trnchesInformation.shareholderTypes.forEach(list => {
          if(init){
            if(list.amountInvested != 0) {
                list.expandAll = true;
                list.expanded = true;
            } else {
                list.expandAll = false;
                list.expanded = false;
            }
          } else {
            list.expandAll = true;
            list.expanded = true;
          }
        if (list.shareholderGroups != null) {
          list.shareholderGroups.forEach(groups => {
            if(init){
                if(list.amountInvested != 0) {
                    groups.expandAll = true;
                    groups.expanded = true;
                } else {
                    groups.expandAll = false;
                    groups.expanded = false;
                }
            } else {
                groups.expandAll = true;
                groups.expanded = true;
            }  
          })
        }
        this.isOpen = true;
      });
    }
    else if (this.isOpen == true) {
      this.trnchesInformation.shareholderTypes.forEach(list => {
        list.expandAll = false;
        list.expanded = false;
        if (list.shareholderGroups != null) {
          list.shareholderGroups.forEach(groups => {
            groups.expandAll = false;
            groups.expanded = false;
          })
        }
        this.isOpen = false;
      });
    }
  }

  // help section show
  showHelp(section) {
    if(section == this.helper){
        return;
      }
      const targetElement = document.getElementById(section);
      if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
      }
      
        this.helpSections = this.helperService.getHelpContent(
          "SummaryComponent",
          section
        );
      this.helper = section;
  }

  // help section hide
  hideHelp() {
    this.helper = "";
  }

  // input field olny number and comma
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  sortLegendItems(items) {
    items.forEach(element => {
      if(element['text'].length > 10) {
          element['text'] = element['text'].substring(0, 10) + '...';
      }
    });
    return items.slice(0, 10);
  }

  Saveforlater(){
    this.ngxLoader.start();
    this.isRoundNotSaved = false;
    let obj = {
      "sideNote" : this.roundInformation.sideNote
    }
    this.authService.saveRoundforLater(obj).subscribe(respose => {
      if (respose) {
        this.ngxLoader.stop();
        this.router.navigate(['/landing', 'dashboard', 'rounds', 'overview']);
      }

    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error("Error: " + e.error.reason, "Error");
    });
  }

  addNotes(val) {
    if (val) {
      this.showNotes = val;
    } else {
      this.roundInformation.sideNote = null;
      this.showNotes = val;
    }
  }
}
