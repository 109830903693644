import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { OptionModel, Vesting } from './option.model';
import { FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpResponse } from '@angular/common/http';
interface EmployeeDetails{
  employeeName?:string
  employeeId?:number
  employeeIdentificationString?:string
}
@Component({
  selector: 'app-edit-esop-emp',
  templateUrl: './edit-esop-emp.component.html',
  styleUrls: ['./edit-esop-emp.component.scss']
})
export class EditEsopEmpComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() editRowData;
  @Input() empList;
  @Input() esopVestingTemplateList;
  @Input() esopPlansTable;
  @Input() employmentStatus;
  @Input() mode;
  @Input() table;
  @Input() empId;
  @Input() selectedPlanName;
  @Input() defaultVestingTemplateName;
  @Input() dataSource;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  empDetails;
  usrCompanyCurrency;
  grantedError: boolean = false;
  gPriceError: boolean = false;
  sharePriceError : boolean =false;
  amountError : boolean = false;
  gDateError: boolean = false;
  empIdError: boolean = false;
  vestingError: boolean = false;
  esopPlanError: boolean = false;
  zeroOptionsError: boolean = false;
  zeroPriceError: boolean = false;
  vestingTypeError: boolean = false;
  vestingDateError: boolean = false;
  planNameDisabled: boolean = false;
  isSubmitted: boolean = false;
  optionsTypeIsAmount : boolean = false;
  searchFormControl = new FormControl();
  searchOptions: Observable<EmployeeDetails[]>;
  employeeNameValue;
  isNumberOfOptionsDecimal : boolean = false;
  cloneGrant: any;
  isOverrideGrantLetter: boolean;
  // created for assigning start date and joining date
  selectedEmpData: any[] = [];
  viewOnly = false;
  showAnswerGrantDialog = false;
  grantId: any;
  isUploaded: boolean = false;
  isViewGrantLetter: boolean = false;
  grantLetterType: string;
  currencySymbol = "";


  constructor(private ngxLoader: NgxUiLoaderService, private utilityService: UtilityService,
    private cacheService: CacheProviderService, private toastr: ToastrService, private authService: AuthenticationService,    private readonly sanitizer: DomSanitizer) { }

  ngOnInit() {
      this.empDetails = new OptionModel;
      this.empDetails.vesting = new Vesting;
      if (this.cacheService.get("userDetails")) {
        let userDetails = JSON.parse(this.cacheService.get("userDetails"));
        this.usrCompanyCurrency = userDetails['company']['companyCurrency'] ||  "INR - ₹";
      }
      else {
        this.usrCompanyCurrency = "INR - ₹";
      }
      this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim()
      this.setEmpDetails();
      if(this.table == 'employee' && this.editRowData == undefined) {
        this.setHolderName(this.empId);
      }
      this.empList.sort((a , b)  =>  {
        return ( b.employeeName > a.employeeName)? -1 : 1;

      });
      this.searchOptions = this.searchFormControl.valueChanges.pipe(startWith(''),
        map((value) => value ? this._filter(value) : this.empList.slice())
      );
    if (this.empDetails['isCloned'] == true) {
      this.cloneGrant = true
    }
    this.grantId = this.empDetails.optionHolderId
    if(this.editRowData && this.empDetails.customizedGrantLetter) {
      this.getGrantLetter()
    } else {
      this.isOverrideGrantLetter = false;
    }
  }

  private _filter(value: string | EmployeeDetails): string[] {
    let filterValue
    if(typeof(value) == "string"){
    filterValue = value.toLowerCase();
  }
    else if(typeof(value) == "object" && value.employeeName){
      filterValue = value.employeeName
    }
    return this.empList.filter((option) =>
    `${option.employeeName.toLowerCase()} ${option.employeeIdentificationString.toLowerCase()}`.includes(filterValue)
    );
  }

  setEmpDetails() {
    // console.log(this.selectedPlanName)
    if(this.editRowData) {
        this.empDetails = this.editRowData['data'];
        // this.empDetails.vestingDatetype = this.empDetails.vestingDateType
        this.empDetails.optionsGranted = this.utilityService.formatCurrency(this.usrCompanyCurrency, this.empDetails.optionsGranted);
        this.empDetails.grantPrice = this.utilityService.formatCurrency(this.usrCompanyCurrency, this.empDetails.grantPrice);
        let dt = new Date(this.empDetails.grantDate);
        this.empDetails.grantDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
        let vdt = new Date(this.empDetails.vestingDate);
        this.empDetails.vestingDate = vdt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
        this.empDetails.optionsExercised = this.utilityService.formatCurrency(this.usrCompanyCurrency, this.empDetails.optionsExercised);
        this.employeeNameValue = this.empList.filter(emp => emp.employeeId == this.empDetails.employeeId)[0]
        if(!this.empDetails.optionType){
          this.empDetails.optionType = "By Options";
        }
        // this.employeeNameValue = this.empList.filter(emp => emp.employeeIdentificationString == this.empDetails.employeeIdentificationString)[0]
        this.empDetails.optionsVested = this.utilityService.formatCurrency(this.usrCompanyCurrency, this.empDetails.optionsVested);
    } else {
        this.empDetails.optionsExercised = 0;
        this.empDetails.optionsVested =  0;
        this.empDetails.optionsGranted;
        this.empDetails.grantPrice = undefined;
        this.empDetails.grantDate = undefined;
        this.empDetails.vestingDate = undefined;
        this.empDetails.vestingDateType = "";
        this.empDetails.employeeId = "";
        this.empDetails.employeeIdentificationString = "";
        this.empDetails.optionType = "By Options";
        this.empDetails.amountForOptions;
        this.empDetails.latestSharePrice;
        if(this.defaultVestingTemplateName.length == 1){
          this.empDetails.vestingId = this.esopVestingTemplateList.filter(template => template.vestingTemplateName == this.defaultVestingTemplateName[0])[0].id;

        }
        else{
          this.empDetails.vestingId = "";
        }

        this.empDetails.statusId = "";
        if(this.selectedPlanName.length == 1){
          this.empDetails.planId = this.esopPlansTable.filter(plan => plan.esopPlanName == this.selectedPlanName)[0].planId;
          this.planNameDisabled = true
        }
        else{
          this.empDetails.planId ="";
        }

    }
    if(!this.empDetails.planId){
      this.empDetails.planId = this.empDetails.planid
    }
    if (this.empDetails.employeeId) {
      this.setHolderName(this.empDetails)
    }
}

  setHolderName(value:EmployeeDetails) {
    this.empDetails.employeeId = value.employeeId;
    this.empDetails.employeeIdentificationString = value.employeeIdentificationString;
    this.empIdError = false;
    // for setting date of joining and vesting start date
    this.selectedEmpData = this.empList.filter((ele) => ele.employeeId == value.employeeId);
  }

  setVestingSchedule(value) {
    this.empDetails.vestingId = value;
    this.vestingError = false;
  }

  viewGrantLetter() {
    if(this.isUploaded) {
      this.downloadObject('grantLetter',true);
    } else {
      this.viewOnly = true;
      this.showAnswerGrantDialog = true;
    }
  }

  close() {
    this.ngxLoader.start();
    this.showDialog = false;
    this.visibleChange.emit();
  }

  checkValidation() {
    this.utilityService.formValidation();
    if(typeof this.empDetails.optionsGranted === 'undefined' || !this.empDetails.optionsGranted ||
        typeof this.empDetails.grantPrice === 'undefined' || !this.empDetails.grantPrice ||
        typeof this.empDetails.grantDate === 'undefined' || !this.empDetails.grantDate ||
        typeof this.empDetails.employeeId === 'undefined' || !this.empDetails.employeeId ||
        // typeof this.empDetails.employeeIdentificationString === 'undefined' || !this.empDetails.employeeIdentificationString ||
        typeof this.empDetails.vestingId === 'undefined' || !this.empDetails.vestingId ||
        typeof this.empDetails.planId === 'undefined' || !this.empDetails.planId ||
        typeof this.empDetails.vestingDateType === 'undefined' || !this.empDetails.vestingDateType ||
        this.empDetails.optionsGranted == 0) {
            this.toastr.error('Please fill all mandatory fields', 'Error');

            if(typeof this.empDetails.optionsGranted === 'undefined' || !this.empDetails.optionsGranted) {
                this.grantedError = true;
            }
            if(typeof this.empDetails.amountForOptions === 'undefined' || !this.empDetails.amountForOptions) {
              this.amountError = true;
            }
            if(typeof this.empDetails.latestSharePrice === 'undefined' || !this.empDetails.latestSharePrice) {
            this.sharePriceError = true;
            }
            if(typeof this.empDetails.grantPrice === 'undefined' || !this.empDetails.grantPrice) {
                this.gPriceError = true;
            }
            if(typeof this.empDetails.grantDate === 'undefined' || !this.empDetails.grantDate) {
                this.gDateError = true;
            }
            if(typeof this.empDetails.employeeId === 'undefined' || !this.empDetails.employeeId) {
                this.empIdError = true;
            }
            // if(typeof this.empDetails.employeeIdentificationString === 'undefined' || !this.empDetails.employeeIdentificationString){
            //   this.empIdError = true;
            // }
            if(typeof this.empDetails.vestingId === 'undefined' || !this.empDetails.vestingId) {
                this.vestingError = true;
            }
            if(typeof this.empDetails.planId === 'undefined' || !this.empDetails.planId) {
                this.esopPlanError = true;
            }
            if(typeof this.empDetails.vestingDateType === 'undefined' || !this.empDetails.vestingDateType) {
                this.vestingTypeError = true;
            }
            if(this.empDetails.optionsGranted === 0) {
                this.zeroOptionsError = true;
            }
            if(this.empDetails.grantPrice === 0) {
                this.zeroPriceError = true;
            }
          } else if(this.empDetails.vestingDateType == 'CustomDate' && typeof this.empDetails.vestingDate === 'undefined' ||
            this.empDetails.vestingDateType == 'CustomDate' && !this.empDetails.vestingDate) {
            this.vestingDateError = true;
        } else {
            this.empDetails.optionsGranted = this.empDetails.optionsGranted.toString().replace(/,/g, "") / 1;
            this.empDetails.grantPrice = this.empDetails.grantPrice.toString().replace(/,/g, "") / 1;
            this.empDetails.optionsExercised = this.empDetails.optionsExercised.toString().replace(/,/g, "") / 1;
            this.empDetails.optionsVested = this.empDetails.optionsVested.toString().replace(/,/g, "") / 1;
            let dt = new Date(this.empDetails.grantDate);
            this.empDetails.grantDate = dt.toISOString().replace("Z", "+0000");
            if(this.empDetails.vestingDateType == "GrantDate" && this.empDetails.vestingDate) {
                let vdt = new Date(this.empDetails.vestingDate);
                this.empDetails.vestingDate = vdt.toISOString().replace("Z", "+0000");
            }
            if(this.empDetails.vestingDateType == "EmployeeJoiningDate" && this.empDetails.dateOfJoin){
              let vdt = new Date(this.empDetails.dateOfJoin);
              this.empDetails.dateOfJoin = vdt.toISOString().replace("z","+0000");
            }
            this.saveOption();
        }
  }
  changeFn($event){
    if($event.target.value != 'CustomDate'){
      this.empDetails.vestingDate = "";
    }
  }

  getGrantLetter() {
    this.ngxLoader.start();
    this.authService.getSFDTformatofGrantLetter(this.grantId).subscribe((res: HttpResponse<any>) => {
      if(res) {
        this.isOverrideGrantLetter = true;
        this.isViewGrantLetter = true;
      }
      this.ngxLoader.stop();
    },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      });
  }

  fileChangeEvent(event, uploadedFrom) {
    let file = event.target.files[0];
    const fileExtenstion = file.name.split('.')[file.name.split('.').length -1].toLowerCase();
    this.utilityService
      .blob2Base64(file)
      .then((res) => {
        // console.log(res.split(",")[1]);
        const base64Res = res.split(",")[1];
        if (uploadedFrom == "grantLetter") {
          const acceptedExtensions = ['docx','pdf']
          if(!(acceptedExtensions.includes(fileExtenstion))){
            this.toastr.error("Invalid format","Error");
            return
          }
          this.empDetails.grantLetter = base64Res;
          this.grantLetterType = fileExtenstion;
          document.getElementById("viewGrantLetter").innerHTML = file.name;
        }
        this.isUploaded = true;
        this.isViewGrantLetter = true;
      })
      .catch((e) => {
        this.toastr.error(e, "Failure");
      });
  }

  downloadObject(objectname, downloadOnly?, grantName?) {
    const anchor = document.createElement("a");
    if (objectname === "grantLetter") {
      if(!this.empDetails.grantLetter){
        this.toastr.error("No Default grant letter present","Error")
        return
      }
      const grantTemplateBlob = this.utilityService.b64toBlob(
        this.empDetails.grantLetter,
        "application/msword"
      );
      grantTemplateBlob.then((res) => {
        const blobURL = URL.createObjectURL(res);
        const templateUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobURL + '#toolbar=0&zoom=100&embedded=true');
        if (downloadOnly) {
          anchor.href = blobURL;
          anchor.download = "Grant template.docx";
          anchor.click();
        }
      });
    }
  }

  saveOption() {
    this.isSubmitted = true;
      if(this.mode == 'edit' && !this.cloneGrant) {
        let editEmpPlanBody = {
            id: this.empDetails.optionHolderId,
            employeeId: this.empDetails.employeeId,
            // employeeIdentificationString: this.empDetails.employeeIdentificationString,
            employeeName: this.empList.filter(emp => emp.employeeId == this.empDetails.employeeId)[0].employeeName,
            planName: this.esopPlansTable.filter(plan => plan.planId == this.empDetails.planId)[0].esopPlanName,
            optionsGranted: this.empDetails.optionsGranted,
            grantPrice: this.empDetails.grantPrice,
            dateOfGrant: this.empDetails.grantDate,
            vestingDateType: this.empDetails.vestingDateType,
            vestingDate: this.empDetails.vestingDate,
            vestingTemplate: this.esopVestingTemplateList.filter(template => template.id == this.empDetails.vestingId)[0].vestingTemplateName,
            isGrantLetterProvided: this.isOverrideGrantLetter,
            grantLetter: this.empDetails.grantLetter,
            grantLetterType: this.grantLetterType,
            optionType : this.empDetails.optionType,
            amountForOptions : this.empDetails.amountForOptions,
            latestSharePrice : this.empDetails.latestSharePrice,
            customizedGrantLetter: this.isOverrideGrantLetter
          };
          this.ngxLoader.start();
          this.authService.EditEmployeePlan(editEmpPlanBody).subscribe(
            (res) => {
                this.isSubmitted = false;
                this.toastr.success('Option Edited successfully!', 'Success!');
                this.ngxLoader.start();
                this.showDialog = false;
                this.visibleChange.emit();
            },
            (e) =>  {
                this.ngxLoader.stop();
                this.isSubmitted = false;
                this.toastr.error(e.error.reason, 'Failure!');
            }
          );
      }
      else if (this.mode == 'edit' && this.cloneGrant) {
        let addEmpPlanBody = {
          employeeId: this.empDetails.employeeId,
          // employeeIdentificationString: this.empDetails.employeeIdentificationString,
          employeeName: this.empList.filter(emp => emp.employeeId == this.empDetails.employeeId)[0].employeeName,
          planName: this.esopPlansTable.filter(plan => plan.planId == this.empDetails.planId)[0].esopPlanName,
          optionsGranted: this.empDetails.optionsGranted,
          grantPrice: this.empDetails.grantPrice,
          dateOfGrant: this.empDetails.grantDate,
          vestingDateType: this.empDetails.vestingDateType,
          vestingDate: this.empDetails.vestingDate,
          vestingTemplate: this.esopVestingTemplateList.filter(template => template.id == this.empDetails.vestingId)[0].vestingTemplateName,
          isGrantLetterProvided: this.isOverrideGrantLetter,
          grantLetter: this.empDetails.grantLetter,
          grantLetterType: this.grantLetterType,
          optionType : this.empDetails.optionType,
          amountForOptions : this.empDetails.amountForOptions,
          latestSharePrice : this.empDetails.latestSharePrice,
          customizedGrantLetter: this.isOverrideGrantLetter

        };
        this.ngxLoader.start();
        this.authService.addEmployeeToPlan(addEmpPlanBody).subscribe(
          (res) => {
            this.isSubmitted = false;
            this.toastr.success("Employee assigned to plan successfully!", "Success!");
            this.ngxLoader.start();
            this.showDialog = false;
            this.visibleChange.emit();
          },
          (e) => {
            this.ngxLoader.stop();
            this.isSubmitted = false;
            this.toastr.error(e.error.reason, 'Failure!');
          }
        );
      }
      else if(this.mode == 'add') {
        let addEmpPlanBody = {
            employeeId: this.empDetails.employeeId,
            employeeName: this.empList.filter(emp => emp.employeeId == this.empDetails.employeeId)[0].employeeName,
            // employeeName: this.empList.filter(emp => emp.employeeId == this.empDetails.employeeId)[0].employeeName,
            planName: this.esopPlansTable.filter(plan => plan.planId == this.empDetails.planId)[0].esopPlanName,
            optionsGranted: this.empDetails.optionsGranted,
            grantPrice: this.empDetails.grantPrice,
            dateOfGrant: this.empDetails.grantDate,
            vestingDateType: this.empDetails.vestingDateType,
            vestingDate: this.empDetails.vestingDate,
            vestingTemplate: this.esopVestingTemplateList.filter(template => template.id == this.empDetails.vestingId)[0].vestingTemplateName,
            isGrantLetterProvided: this.isOverrideGrantLetter,
            grantLetter: this.empDetails.grantLetter,
            grantLetterType: this.grantLetterType,
            optionType : this.empDetails.optionType,
            amountForOptions : this.empDetails.amountForOptions,
            latestSharePrice : this.empDetails.latestSharePrice,
            customizedGrantLetter: this.isOverrideGrantLetter
        };
        this.ngxLoader.start();
        this.authService.addEmployeeToPlan(addEmpPlanBody).subscribe(
            (res) => {
                this.isSubmitted = false;
                this.toastr.success("Employee assigned to plan successfully!", "Success!");
                this.ngxLoader.start();
                this.showDialog = false;
                this.visibleChange.emit();
            },
            (e) => {
              this.ngxLoader.stop();
                this.isSubmitted = false;
                this.toastr.error(e.error.reason, 'Failure!');
            }
        );
      }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
  }

  onlyNumber(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  csvPrice(e){
    let spVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }

    let val = this.empDetails.grantPrice;
    spVal = this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, val);
    if(e) {
      if(e.target.value) {
        let CSVLength = spVal.length;
        let valueLength = e.target.value.length;
        if(CSVLength > valueLength) {
            e.target.value = spVal;
            let difference = CSVLength - valueLength;
            start = start + difference;
            end = end + difference;
        }
        else if(CSVLength < valueLength){
          e.target.value = spVal;
          if(start != 0 || end != 0){
              let difference = valueLength - CSVLength;
              start = start - difference;
              end = end - difference;
          }
          else{
              start = start;
              end = end;
          }
        }
        else{
          e.target.value = spVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
    else{
      this.empDetails.grantPrice = spVal;
    }
  }
  employeeNameDisplayFunction(employee?:EmployeeDetails){
    return employee ? `${employee.employeeName} (${employee.employeeIdentificationString})` : undefined
  }
  changeVestingType(event) {
    if (event === 'GrantDate') {
      // this.empDetails.vestingDateType = event;
      // if (this.editRowData) {
      //   this.empDetails.vestingDate = this.selectedEmpData[0].grantDate;
      // }else{
      //   this.empDetails.vestingDate = this.empDetails.grantDate
      // }
      this.empDetails.vestingDate = ''
    } else if (event === 'EmployeeJoiningDate') {
      // this.empDetails.vestingDateType = event;
      if(this.editRowData){
        this.empDetails.vestingDate = this.selectedEmpData[0].dateOfJoin;
      } else{
        this.empDetails.vestingDate = ''
      }
    } else {
      // this.empDetails.vestingDateType = event;
      this.empDetails.vestingDate = ''
    }
  }
  optionTypeChange(e){
    if(e == "By Amount"){
      this.empDetails.latestSharePrice=null;
      this.empDetails.amountForOptions=null;
      this.empDetails.optionsGranted = null;
      this.optionsTypeIsAmount = true;
      this.isNumberOfOptionsDecimal =false;
    }else {
      this.empDetails.latestSharePrice=null;
      this.empDetails.amountForOptions =null;
      this.empDetails.optionsGranted = null;
      this.optionsTypeIsAmount = false;
      this.isNumberOfOptionsDecimal =false;
    }
  }
  changeAmount(e){
    if(this.empDetails.latestSharePrice && this.empDetails.latestSharePrice != 0){
      this.isNumberOfOptionsDecimal = e % this.empDetails.latestSharePrice != 0 ? true : false;
      this.empDetails.optionsGranted = Math.floor(e / this.empDetails.latestSharePrice);
      this.empDetails.amountForOptions = this.empDetails.latestSharePrice * this.empDetails.optionsGranted;
    }
  }
  changeShareValue(e){
    if(this.empDetails.amountForOptions && this.empDetails.amountForOptions != 0){
      this.isNumberOfOptionsDecimal = this.empDetails.amountForOptions % e != 0 ? true : false;
      this.empDetails.optionsGranted = Math.floor(this.empDetails.amountForOptions / e);
      this.empDetails.amountForOptions = this.empDetails.latestSharePrice * this.empDetails.optionsGranted;
    }
  }
}
