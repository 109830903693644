import { FormBuilder, Validators } from "@angular/forms";
import { OnInit, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../../authentication.service";
import { ToastrService } from "ngx-toastr";
import { HttpEventType } from '@angular/common/http';
var UploadDocumentsComponent = /** @class */ (function () {
    function UploadDocumentsComponent(authService, toastr, fb) {
        this.authService = authService;
        this.toastr = toastr;
        this.fb = fb;
        this.visibleChange = new EventEmitter();
        this.filesSelected = [];
        this.isSubmitted = false;
    }
    UploadDocumentsComponent.prototype.close = function () {
        this.openDialog = false;
        this.visibleChange.emit({
            showDig: false,
            cancel: true
        });
    };
    UploadDocumentsComponent.prototype.uploadDocuments = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.descriptionDocsGetter.valid) {
            this.descriptionDocsGetter.value.forEach(function (item, num) {
                var fd = new FormData();
                fd.append("file", item.file);
                fd.append("description", item.description);
                _this.authService.uploadIncorporationDocuments(fd).subscribe(function (response) {
                    if (response.type === HttpEventType.Response) {
                        _this.toastr.success(item.file.name + " uploaded.", "Success!");
                        if (num == _this.descriptionDocsGetter.value.length - 1) {
                            setTimeout(function () {
                                _this.visibleChange.emit({
                                    showDig: false,
                                    cancel: false
                                });
                            }, 500);
                        }
                    }
                    if (response.type === HttpEventType.UploadProgress) {
                        _this.descriptionDocsGetter.controls[num]['controls']['totalSize'].setValue(response.total);
                        _this.descriptionDocsGetter.controls[num]['controls']['loadedSize'].setValue(response.loaded);
                        var percentDone = Math.round(100 * response.loaded / response.total);
                        console.log('Progress ' + percentDone + '%');
                    }
                }, function (e) {
                    _this.toastr.error(e.error.reason, "Failure!");
                });
            });
        }
    };
    Object.defineProperty(UploadDocumentsComponent.prototype, "descriptionDocsGetter", {
        get: function () {
            return this.descriptions.get("documents");
        },
        enumerable: true,
        configurable: true
    });
    UploadDocumentsComponent.prototype.onValueChanged = function (event) {
        var _this = this;
        this.filesSelected = event.value;
        this.descriptions.controls["documents"] = this.fb.array([], Validators.required);
        event.value.forEach(function (element) {
            _this.descriptionDocsGetter.push(_this.fb.group({
                description: ["", [Validators.required, emptySpaceValidator]],
                file: [element, Validators.required],
                totalSize: [0],
                loadedSize: [0]
            }));
        });
    };
    UploadDocumentsComponent.prototype.ngOnInit = function () {
        this.descriptions = this.fb.group({
            documents: this.fb.array([], Validators.required)
        });
    };
    return UploadDocumentsComponent;
}());
export { UploadDocumentsComponent };
//To validate input for only empty spaces
function emptySpaceValidator(control) {
    var value = control.value;
    if (value.length > 0) {
        if (value.trim().length > 0) {
            return null;
        }
        else {
            return {
                invalid: true,
            };
        }
    }
}
