import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomCurrencyPipe } from './currency-pipe';
import { NumberformatterPipe } from './numberformatter.pipe';
import { CustomLocaleFormatterPipe } from './locale-formatter.pipe';

@NgModule({
  declarations: [
    CustomLocaleFormatterPipe,
    CustomCurrencyPipe,
    NumberformatterPipe
  ],
  imports: [
    CommonModule,
  ],
  exports:[
    CustomLocaleFormatterPipe,
    CustomCurrencyPipe,
    NumberformatterPipe
  ]
})
export class PipesModule { }
