import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { CompanyNameComponent } from './company-name/company-name.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { HistoricalRoundCreationComponent } from './historical-round/creation/historical-round-creation.component';
import { ConfirmRoundApproval, HistoricalRoundSummaryComponent } from './historical-round/summary/historical-round-summary.component';
import { IncorporationDetailsComponent } from './incorporation-details/incorporation-details.component';
import { MatCardModule, MatButtonModule, MatFormFieldModule, MatDividerModule, MatMenuModule, MatDatepickerModule, MatIconModule, MatTabsModule, MatInputModule, MatDialogModule, MatAutocompleteModule, MatSelectModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonDialogsModule } from '../dialogues/common-dialogs.module';
import { DxDataGridModule, DxFileUploaderModule, DxProgressBarModule, DxTemplateModule, DxTooltipModule, DxTreeListModule, DxSelectBoxModule, DxPieChartModule } from 'devextreme-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PipesModule } from '../pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { RoundNameComponent } from './historical-round/round-name/round-name.component';
import { UploadDocumentsComponent } from './incorporation-details/upload-documents/upload-documents.component';
import { ConversionComponent } from './historical-round/dialogs/historical-conversion/conversion.component';
import { RedemptionComponent } from './historical-round/dialogs/historical-redemption/redemption.component';
import { MatExpansionModule } from "@angular/material/expansion";

@NgModule({
  declarations: [
    CompanyNameComponent,
    AdminProfileComponent,
    CompanyDetailsComponent,
    HistoricalRoundCreationComponent,
    HistoricalRoundSummaryComponent,
    IncorporationDetailsComponent,
    RoundNameComponent,
    UploadDocumentsComponent,
    ConversionComponent,
    RedemptionComponent,
    ConfirmRoundApproval
  ],
  imports: [
    CommonModule,
    NgbModule,
    OnboardingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule, 
    MatButtonModule, 
    MatFormFieldModule, 
    MatDividerModule, 
    MatMenuModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule, 
    MatIconModule,
    MatTabsModule,
    CommonDialogsModule,
    DxDataGridModule,
    DxTreeListModule,
    DxTooltipModule,
    DxFileUploaderModule,
    DxProgressBarModule,
    DxSelectBoxModule,
    DxPieChartModule,
    CarouselModule,
    PipesModule,
    NgSelectModule,
    MatExpansionModule,
    MatInputModule,
    MatDialogModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:[ConfirmRoundApproval]
})
export class OnboardingModule { }
