import { OnInit } from "@angular/core";
import { AuthenticationService } from "../../../src/app/authentication.service";
import { Router } from "@angular/router";
import { HelperService } from "../services/helper.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CompanyDetailsModel } from "../onboarding/company-details/company-details.model";
import { FounderDetailsModel } from "../onboarding/company-details/founder-details.model";
import { DirectorDetailsModel } from "../onboarding/company-details/director-details.model";
import { UtilityService } from "../../app/services/utilities.service";
import { ConfirmDeleteComponent } from "../confirm-delete/confirm-delete.component";
import { MatDialog } from "@angular/material";
var CompanyProfileComponent = /** @class */ (function () {
    function CompanyProfileComponent(helperService, router, authService, toastr, datepipe, ngxLoader, utility, datePipe, dialog) {
        this.helperService = helperService;
        this.router = router;
        this.authService = authService;
        this.toastr = toastr;
        this.datepipe = datepipe;
        this.ngxLoader = ngxLoader;
        this.utility = utility;
        this.datePipe = datePipe;
        this.dialog = dialog;
        this.model = new CompanyDetailsModel();
        this.helper = "";
        this.helperTopPosition = null;
        this.currentEditableFounderRow = null;
        this.currentEditableDirectorRow = 0;
        this.areAllDirectorsSelected = false;
        this.areAllFoundersSelected = false;
        this.companyNameValid = false;
        this.cinNoValid = false;
        this.companyTypeValid = false;
        this.companyCurrencyValid = false;
        this.incorporateDtValid = false;
        this.officeAddressValid = false;
        this.emailValid = false;
        this.businessInvalid = false;
        this.industryInvalid = false;
        this.nicValid = false;
        this.companyWebsitevalid = false;
        this.isValid = false;
        this.typeofCompanies = [
            "Private Limited Company",
            "Public Limited Company",
            "Unlimited Company",
            "Partnership",
            "Limited Liability Partnership",
        ];
        this.selectedNationality = "India";
        this.fName = false;
        this.fEmail = false;
        this.fNationality = false;
        this.dName = false;
        this.dDin = false;
        this.dType = false;
        this.dEmail = false;
        this.dNationality = false;
        this.shouldLogoShowDialog = false;
        this.profilePicture = null;
        this.croppedImage = null;
        this.companyLogoInvalid = false;
        this.isNewbusiness = false;
        this.showDialogauthorized = false;
        this.showDialogsplitstock = false;
        this.roundName = "";
        this.isShowHelp = false;
        this.disableripple = true;
        this.isauthCapital = false;
        this.issplitStock = false;
        this.equityAuth = false;
        this.prefAuth = false;
        this.equityPaidup = false;
        this.prefPaidup = false;
        this.selectedBusiness = [];
        this.selectedIndustries = [];
        this.oldName = "";
        this.isEsopOnlyCompany = false;
    }
    CompanyProfileComponent.prototype.ngOnInit = function () {
        this.shouldShowDialog = false;
        this.currencyList = this.utility.currencyList();
        this.initializeDetails();
        this.fetchCompanyDetails();
        var _a = require("country-list"), getCode = _a.getCode, getNames = _a.getNames;
        this.countryList = getNames();
        this.isNewbusiness = false;
        this.loadJsStyles();
    };
    CompanyProfileComponent.prototype.getCapital = function () {
        var _this = this;
        this.ngxLoader.start();
        this.authService.getCapital().subscribe(function (res) {
            if (res) {
                _this.usrCompanyCurrency = res["company"]["companyCurrency"];
                _this.equityAuthShareCapital = _this.utility.formatCurrency(_this.usrCompanyCurrency, res["equityAuthorizedShareCapital"]);
                _this.preferenceAuthShareCapital = _this.utility.formatCurrency(_this.usrCompanyCurrency, res["preferenceAuthorizedShareCapital"]);
                _this.equityPaidupShareCapital = _this.utility.formatCurrency(_this.usrCompanyCurrency, res["equityPaidUpShareCapital"]);
                _this.preferencePaidupShareCapital = _this.utility.formatCurrency(_this.usrCompanyCurrency, res["preferencePaidUpShareCapital"]);
                _this.currencySymbol = _this.usrCompanyCurrency.split("-")[1].trim();
                _this.ngxLoader.stop();
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error("Null response!.", "Failure!");
            }
        }, function (e) {
            _this.ngxLoader.stop();
            _this.toastr.error("Error: " + e.error.reason, "Error");
        });
    };
    /** Show Contextual help **/
    CompanyProfileComponent.prototype.show = function (section) {
        if (section == this.helper) {
            return;
        }
        var targetElement = document.getElementById(section);
        if (targetElement != null) {
            this.helperTopPosition = targetElement.offsetTop;
        }
        this.helpSections = this.helperService.getHelpContent("ShareCapitalComponent", section);
        this.helper = section;
    };
    /** Show Contextual help **/
    CompanyProfileComponent.prototype.onKeydownEvent = function (e) {
        this.utility.restrictMinus(e);
    };
    CompanyProfileComponent.prototype.loadJsStyles = function () {
        $("input.number").keyup(function (event) {
            // skip for arrow keys
            if (event.which >= 37 && event.which <= 40)
                return;
            // format number
            $(this).val(function (index, value) {
                var tmpValue = value.toString().replace(/\D/g, "");
                var lastThree = tmpValue.substring(tmpValue.length - 3);
                var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
                if (otherNumbers != "")
                    lastThree = "," + lastThree;
                var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
                return res;
            });
        });
    };
    CompanyProfileComponent.prototype.validation = function (data) {
        this.utility.formValidation();
        if (data.equityAuthorizedShareCapital < 0 ||
            data.equityAuthorizedShareCapital == null ||
            data.equityAuthorizedShareCapital === "" ||
            data.preferenceAuthorizedShareCapital < 0 ||
            data.preferenceAuthorizedShareCapital == null ||
            data.preferenceAuthorizedShareCapital === "" ||
            data.equityPaidUpShareCapital < 0 ||
            data.equityPaidUpShareCapital == null ||
            data.equityPaidUpShareCapital === "" ||
            data.preferencePaidUpShareCapital < 0 ||
            data.preferencePaidUpShareCapital == null ||
            data.preferencePaidUpShareCapital === "") {
            if (data.equityAuthorizedShareCapital < 0 ||
                data.equityAuthorizedShareCapital == null ||
                data.equityAuthorizedShareCapital === "") {
                this.equityAuth = true;
            }
            if (data.preferenceAuthorizedShareCapital < 0 ||
                data.preferenceAuthorizedShareCapital == null ||
                data.preferenceAuthorizedShareCapital === "") {
                this.prefAuth = true;
            }
            if (data.equityPaidUpShareCapital < 0 ||
                data.equityPaidUpShareCapital == null ||
                data.equityPaidUpShareCapital === "") {
                this.equityPaidup = true;
            }
            if (data.preferencePaidUpShareCapital < 0 ||
                data.preferencePaidUpShareCapital == null ||
                data.preferencePaidUpShareCapital === "") {
                this.prefPaidup = true;
            }
            return false;
        }
        else {
            return true;
        }
    };
    CompanyProfileComponent.prototype.save = function () {
        var _this = this;
        var dt = new Date();
        var date = dt.toISOString().replace("Z", "+0000");
        var capital = {
            equityAuthorizedShareCapital: this.equityAuthShareCapital,
            preferenceAuthorizedShareCapital: this.preferenceAuthShareCapital,
            equityPaidUpShareCapital: this.equityPaidupShareCapital,
            preferencePaidUpShareCapital: this.preferencePaidupShareCapital,
            updatedDate: date,
        };
        var isValid = this.validation(capital);
        if (isValid) {
            this.ngxLoader.start();
            if (capital.equityAuthorizedShareCapital != 0) {
                capital.equityAuthorizedShareCapital =
                    capital.equityAuthorizedShareCapital.replace(/,/g, "") / 1;
            }
            if (capital.preferenceAuthorizedShareCapital != 0) {
                capital.preferenceAuthorizedShareCapital =
                    capital.preferenceAuthorizedShareCapital.replace(/,/g, "") / 1;
            }
            if (capital.equityPaidUpShareCapital != 0) {
                capital.equityPaidUpShareCapital =
                    capital.equityPaidUpShareCapital.replace(/,/g, "") / 1;
            }
            if (capital.preferencePaidUpShareCapital != 0) {
                capital.preferencePaidUpShareCapital =
                    capital.preferencePaidUpShareCapital.replace(/,/g, "") / 1;
            }
            this.authService.updateCapital(capital).subscribe(function (res) {
                if (res) {
                    _this.toastr.success("Capital saved successfully", "Success");
                    //this.router.navigate(['/landing', 'dashboard','capTable']);
                    _this.ngxLoader.stop();
                }
                else {
                    _this.ngxLoader.stop();
                    _this.toastr.error("Null response!.", "Failure!");
                }
            }, function (e) {
                _this.ngxLoader.stop();
                _this.toastr.error("Error: " + e.error.reason, "Error");
            });
        }
    };
    CompanyProfileComponent.prototype.csvEquityVal = function (e) {
        var exitVal;
        if (e) {
            // store current positions in variables
            var start = e.target.selectionStart, end = e.target.selectionEnd;
        }
        var val = this.equityAuthShareCapital;
        exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
        if (e) {
            if (e.target.value) {
                var CSVLength = exitVal.length;
                var valueLength = e.target.value.length;
                if (CSVLength > valueLength) {
                    e.target.value = exitVal;
                    var difference = CSVLength - valueLength;
                    start = start + difference;
                    end = end + difference;
                }
                else if (CSVLength < valueLength) {
                    e.target.value = exitVal;
                    if (start != 0 || end != 0) {
                        var difference = valueLength - CSVLength;
                        start = start - difference;
                        end = end - difference;
                    }
                    else {
                        start = start;
                        end = end;
                    }
                }
                else {
                    e.target.value = exitVal;
                }
            }
            // restore from variables...
            e.target.setSelectionRange(start, end);
        }
    };
    CompanyProfileComponent.prototype.csvPreferenceVal = function (e) {
        var exitVal;
        if (e) {
            // store current positions in variables
            var start = e.target.selectionStart, end = e.target.selectionEnd;
        }
        var val = this.preferenceAuthShareCapital;
        exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
        if (e) {
            if (e.target.value) {
                var CSVLength = exitVal.length;
                var valueLength = e.target.value.length;
                if (CSVLength > valueLength) {
                    e.target.value = exitVal;
                    var difference = CSVLength - valueLength;
                    start = start + difference;
                    end = end + difference;
                }
                else if (CSVLength < valueLength) {
                    e.target.value = exitVal;
                    if (start != 0 || end != 0) {
                        var difference = valueLength - CSVLength;
                        start = start - difference;
                        end = end - difference;
                    }
                    else {
                        start = start;
                        end = end;
                    }
                }
                else {
                    e.target.value = exitVal;
                }
            }
            // restore from variables...
            e.target.setSelectionRange(start, end);
        }
    };
    CompanyProfileComponent.prototype.csvPaidUpEquityVal = function (e) {
        var exitVal;
        if (e) {
            // store current positions in variables
            var start = e.target.selectionStart, end = e.target.selectionEnd;
        }
        var val = this.equityPaidupShareCapital;
        exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
        if (e) {
            if (e.target.value) {
                var CSVLength = exitVal.length;
                var valueLength = e.target.value.length;
                if (CSVLength > valueLength) {
                    e.target.value = exitVal;
                    var difference = CSVLength - valueLength;
                    start = start + difference;
                    end = end + difference;
                }
                else if (CSVLength < valueLength) {
                    e.target.value = exitVal;
                    if (start != 0 || end != 0) {
                        var difference = valueLength - CSVLength;
                        start = start - difference;
                        end = end - difference;
                    }
                    else {
                        start = start;
                        end = end;
                    }
                }
                else {
                    e.target.value = exitVal;
                }
            }
            // restore from variables...
            e.target.setSelectionRange(start, end);
        }
    };
    CompanyProfileComponent.prototype.csvPaidUpPrefVal = function (e) {
        var exitVal;
        if (e) {
            // store current positions in variables
            var start = e.target.selectionStart, end = e.target.selectionEnd;
        }
        var val = this.preferencePaidupShareCapital;
        exitVal = this.utility.inputCSVformatCurrency(this.usrCompanyCurrency, val);
        if (e) {
            if (e.target.value) {
                var CSVLength = exitVal.length;
                var valueLength = e.target.value.length;
                if (CSVLength > valueLength) {
                    e.target.value = exitVal;
                    var difference = CSVLength - valueLength;
                    start = start + difference;
                    end = end + difference;
                }
                else if (CSVLength < valueLength) {
                    e.target.value = exitVal;
                    if (start != 0 || end != 0) {
                        var difference = valueLength - CSVLength;
                        start = start - difference;
                        end = end - difference;
                    }
                    else {
                        start = start;
                        end = end;
                    }
                }
                else {
                    e.target.value = exitVal;
                }
            }
            // restore from variables...
            e.target.setSelectionRange(start, end);
        }
    };
    CompanyProfileComponent.prototype.cancel = function () {
        this.router.navigate(["/landing", "dashboard", "capTable"]);
    };
    CompanyProfileComponent.prototype.splitStock = function () {
        this.issplitStock = true;
        this.showDialogsplitstock = true;
        this.utility.showOverlay();
    };
    CompanyProfileComponent.prototype.getEvents = function (e) {
        this.showDialogsplitstock = e;
        console.log(this.showDialogsplitstock);
        this.utility.hideOverlay();
    };
    CompanyProfileComponent.prototype.getEventAutorized = function (e) {
        this.showDialogauthorized = e;
        console.log(this.showDialogauthorized);
        this.utility.hideOverlay();
    };
    CompanyProfileComponent.prototype.authCapital = function () {
        this.isauthCapital = true;
        this.showDialogauthorized = true;
        this.utility.showOverlay();
    };
    CompanyProfileComponent.prototype.initializeDetails = function () {
        this.model.nameOfTheCompany = localStorage.getItem("companyName");
        this.model.cinNumber = "";
        this.model.isInForEquitySecurity = "";
        this.model.isInForPreferenceSecurity = "";
        this.model.typeOfTheCompany = "";
        this.model.dateOfIncorporation = "";
        this.model.corporateOfficeAddress = "";
        this.model.companyEmailId = "";
        this.model.business = [];
        this.model.industry = [];
        this.model.nic = "";
        this.model.dippCertificateNumber = "";
        this.model.doingBusinessAs = "";
        this.model.founders = [];
        this.model.directors = [];
        this.model.companyLogo = "";
        this.model.companyLogoPath = "";
        this.model.companyWebsite = "";
        this.model.subscribersOfMemorandum = "";
    };
    CompanyProfileComponent.prototype.fetchCompanyDetails = function () {
        var _this = this;
        this.ngxLoader.start();
        this.authService.getCompanyDetails(false).subscribe(function (res) {
            if (res) {
                _this.isEsopOnlyCompany = JSON.parse(sessionStorage.getItem("userDetails")).company["isEsopOnlyCompany"];
                if (!_this.isEsopOnlyCompany) {
                    _this.getCapital();
                }
                _this.model = res;
                _this.selectedBusiness = _this.model["business"];
                _this.selectedIndustries = _this.model["industry"];
                _this.businessList = _this.model["categoryDropdownValues"];
                _this.industries = _this.model["industryDropdownValues"];
                if (_this.model["companyLogoPath"] == null) {
                    _this.model["companyLogoPath"] = "";
                }
                if (_this.model["subscribersOfMemorandum"] == null) {
                    _this.model["subscribersOfMemorandum"] = "";
                }
                if (_this.model["companyWebsite"] == null) {
                    _this.model["companyWebsite"] = "";
                }
                _this.model.directors.forEach(function (element) {
                    if (element["fatherName"] == null) {
                        element["fatherName"] = "";
                    }
                    if (element["motherName"] == null) {
                        element["motherName"] = "";
                    }
                    if (element["spouseName"] == null) {
                        element["spouseName"] = "";
                    }
                    if (element["nominationBy"] == null) {
                        element["nominationBy"] = "";
                    }
                    if (element["gender"] == null) {
                        element["gender"] = "";
                    }
                    if (element["occupation"] == null) {
                        element["occupation"] = "";
                    }
                    if (element["countryOfResidence"] == null) {
                        element["countryOfResidence"] = "";
                    }
                });
                console.log("Updated Model is", _this.model);
                if (_this.model.dateOfIncorporation != null ||
                    _this.model.dateOfIncorporation != "") {
                    _this.model.dateOfIncorporation = _this.datepipe.transform(_this.model.dateOfIncorporation, "yyyy-MM-dd");
                }
                _this.model.directors.forEach(function (a) {
                    a.dateOfBirth = _this.datepipe.transform(a.dateOfBirth, "yyyy-MM-dd");
                    a.dateOfAppointement = _this.datepipe.transform(a.dateOfAppointement, "yyyy-MM-dd");
                    a.dateOfBoardResoluitonForAppointement = _this.datepipe.transform(a.dateOfBoardResoluitonForAppointement, "yyyy-MM-dd");
                    a.isSelected = false;
                });
                _this.model.founders.forEach(function (a) {
                    a.dateOfBirth = _this.datepipe.transform(a.dateOfBirth, "yyyy-MM-dd");
                });
                _this.ngxLoader.stop();
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error("Null response!.", "Failure!");
            }
        }, function (e) {
            _this.initializeDetails();
            if (e.error.reason === "Login Token Expired.") {
                _this.ngxLoader.stop();
                localStorage.clear();
                _this.router.navigate([""]);
                return;
            }
            else {
                _this.isEsopOnlyCompany = JSON.parse(sessionStorage.getItem("userDetails")).company["isEsopOnlyCompany"];
                if (!_this.isEsopOnlyCompany) {
                    _this.getCapital();
                }
                _this.ngxLoader.stop();
                _this.toastr.error(e.error.reason, "Failure!");
            }
        });
    };
    // Founders
    CompanyProfileComponent.prototype.addFounder = function () {
        var length = this.model.founders.length;
        if (this.currentEditableFounderRow == null) {
            this.currentEditableFounderRow = 0;
        }
        if (length > 0) {
            if (this.model.founders[length - 1].fullName === "" ||
                this.model.founders[length - 1].emailId === "" ||
                this.model.founders[length - 1].nationality === "") {
                this.toastr.error("Please fill previous row details", "Error!");
                return;
            }
            else if (this.model.founders[length - 1].designation === "" ||
                this.model.founders[length - 1].dateOfBirth === "") {
                this.toastr.warning("Please fill previous founders profile details", "Warning!");
            }
        }
        var newFounder = new FounderDetailsModel();
        newFounder.id = 0;
        newFounder.fullName = "";
        newFounder.emailId = "";
        newFounder.designation = "";
        newFounder.contactNumber = "";
        newFounder.address = "";
        newFounder.dateOfBirth = "";
        newFounder.nationality = this.selectedNationality;
        newFounder.linkedInProfile = "";
        newFounder.twitterHandle = "";
        newFounder.isSelected = false;
        newFounder.shouldShowDialog = false;
        newFounder.isEdit = true;
        this.model.founders.push(newFounder);
        this.updateFounderSelection();
        this.currentEditableFounderRow = this.model.founders.length - 1;
        this.editFounderRow(this.currentEditableFounderRow);
    };
    CompanyProfileComponent.prototype.deleteIndividualFounder = function (index) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: {
                header: "Confirm Delete?",
                text: "Are you sure you want to Delete ?",
            },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                _this.model.founders.splice(index, 1);
                _this.updateFounderSelection();
                _this.fName = false;
                _this.fEmail = false;
                _this.fNationality = false;
            }
            _this.ngxLoader.stop();
        });
    };
    CompanyProfileComponent.prototype.editFounderRow = function (rowIndex) {
        this.disableFoundersInputs();
        this.model.founders[rowIndex].isEdit = true;
    };
    CompanyProfileComponent.prototype.saveFounderRow = function (rowIndex) {
        if (this.model.founders[rowIndex].fullName === "" ||
            this.model.founders[rowIndex].fullName == null ||
            this.model.founders[rowIndex].emailId === "" ||
            !this.validateEmail(this.model.founders[rowIndex].emailId) ||
            this.model.founders[rowIndex].emailId == null ||
            this.model.founders[rowIndex].nationality === "" ||
            this.model.founders[rowIndex].nationality == null) {
            if (this.model.founders[rowIndex].fullName === "" ||
                this.model.founders[rowIndex].fullName == null) {
                this.fName = true;
            }
            if (this.model.founders[rowIndex].emailId === "" ||
                this.model.founders[rowIndex].emailId == null ||
                !this.validateEmail(this.model.founders[rowIndex].emailId)) {
                this.fEmail = true;
            }
            if (this.model.founders[rowIndex].nationality === "" ||
                this.model.founders[rowIndex].nationality == null) {
                this.fNationality = true;
            }
            return;
        }
        else {
            this.model.founders[rowIndex].isEdit = false;
            this.fName = false;
            this.fEmail = false;
            this.fNationality = false;
        }
    };
    CompanyProfileComponent.prototype.selectFounder = function () {
        this.updateFounderSelection();
    };
    CompanyProfileComponent.prototype.selectAllFounders = function () {
        if (this.areAllFoundersSelected) {
            this.model.founders.forEach(function (founder) {
                founder.isSelected = true;
            });
        }
        else {
            this.model.founders.forEach(function (founder) {
                founder.isSelected = false;
            });
        }
        this.updateFounderSelection();
    };
    CompanyProfileComponent.prototype.updateFounderSelection = function () {
        var found = this.model.founders.find(function (founder) { return founder.isSelected == false; });
        if (found || this.model.founders.length == 0) {
            this.areAllFoundersSelected = false;
        }
        else {
            this.areAllFoundersSelected = true;
        }
    };
    CompanyProfileComponent.prototype.deleteAllSelectedFounders = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: {
                header: "Confirm Delete?",
                text: "Are you sure you want to Delete ?",
            },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                if (_this.areAllFoundersSelected) {
                    _this.model.founders = [];
                }
                else {
                    var newArray = _this.model.founders.filter(function (director) { return director.isSelected == false; });
                    _this.model.founders = newArray;
                }
                _this.updateFounderSelection();
            }
            _this.ngxLoader.stop();
        });
    };
    CompanyProfileComponent.prototype.openFounderDetailsPopup = function (index) {
        this.model.founders[index].shouldShowDialog = true;
        this.utility.showOverlay();
    };
    CompanyProfileComponent.prototype.onCloseFounderDetails = function () {
        this.model.founders.forEach(function (e) { return (e.shouldShowDialog = false); });
        this.utility.hideOverlay();
    };
    // Directors
    CompanyProfileComponent.prototype.addDirector = function () {
        var length = this.model.directors.length;
        if (length > 0) {
            if (this.model.directors[length - 1].fullName === "" ||
                this.model.directors[length - 1].fullName === "" ||
                this.model.directors[length - 1].din === "" ||
                this.model.directors[length - 1].emailId === "" ||
                this.model.directors[length - 1].nationality === "") {
                this.toastr.error("Please fill previous row details", "Error!");
                return;
            }
            else if (this.model.directors[length - 1]
                .dateOfBoardResoluitonForAppointement === "" ||
                this.model.directors[length - 1].dateOfAppointement === "") {
                this.toastr.warning("Please fill privious director profile details", "Warning!");
            }
        }
        var newDirector = new DirectorDetailsModel();
        newDirector.id = 0;
        newDirector.fullName = "";
        newDirector.typeOfDirector = "";
        newDirector.din = "";
        newDirector.emailId = "";
        newDirector.address = "";
        newDirector.contactNumber = "";
        newDirector.dateOfBirth = "";
        newDirector.nationality = this.selectedNationality;
        newDirector.dateOfAppointement = "";
        newDirector.dateOfBoardResoluitonForAppointement = "";
        newDirector.isSelected = false;
        newDirector.shouldShowDialog = false;
        newDirector.isEdit = true;
        this.model.directors.push(newDirector);
        this.updateSelection();
        this.currentEditableDirectorRow = this.model.directors.length - 1;
        this.editDirectorRow(this.currentEditableDirectorRow);
    };
    CompanyProfileComponent.prototype.editDirectorRow = function (rowIndex) {
        this.disableDirectorsInputs();
        this.model.directors[rowIndex].isEdit = true;
    };
    CompanyProfileComponent.prototype.saveDirectorRow = function (rowIndex) {
        if (this.model.directors[rowIndex].fullName === "" ||
            this.model.directors[rowIndex].fullName == null ||
            this.model.directors[rowIndex].din === "" ||
            this.model.directors[rowIndex].din == null ||
            this.model.directors[rowIndex].nationality === "" ||
            this.model.directors[rowIndex].nationality == null ||
            this.model.directors[rowIndex].typeOfDirector === "" ||
            this.model.directors[rowIndex].typeOfDirector == null ||
            this.model.directors[rowIndex].emailId === "" ||
            this.model.directors[rowIndex].emailId == null ||
            !this.validateEmail(this.model.directors[rowIndex].emailId)) {
            if (this.model.directors[rowIndex].fullName === "" ||
                this.model.directors[rowIndex].fullName == null) {
                this.dName = true;
            }
            if (this.model.directors[rowIndex].din === "" ||
                this.model.directors[rowIndex].din == null) {
                this.dDin = true;
            }
            if (this.model.directors[rowIndex].nationality === "" ||
                this.model.directors[rowIndex].nationality == null) {
                this.dNationality = true;
            }
            if (this.model.directors[rowIndex].typeOfDirector === "" ||
                this.model.directors[rowIndex].typeOfDirector == null) {
                this.dType = true;
            }
            if (this.model.directors[rowIndex].emailId === "" ||
                this.model.directors[rowIndex].emailId == null ||
                !this.validateEmail(this.model.directors[rowIndex].emailId)) {
                this.dEmail = true;
            }
            return;
        }
        else {
            this.model.directors[rowIndex].isEdit = false;
            this.dName = false;
            this.dDin = false;
            this.dNationality = false;
            this.dType = false;
            this.dEmail = false;
        }
    };
    CompanyProfileComponent.prototype.deleteIndividualDirector = function (index) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: {
                header: "Confirm Delete?",
                text: "Are you sure you want to Delete ?",
            },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                _this.model.directors.splice(index, 1);
                _this.updateSelection();
                _this.dName = false;
                _this.dDin = false;
                _this.dNationality = false;
                _this.dType = false;
                _this.dEmail = false;
            }
            _this.ngxLoader.stop();
        });
    };
    CompanyProfileComponent.prototype.selectAllDirectors = function () {
        if (this.areAllDirectorsSelected) {
            this.model.directors.forEach(function (director) {
                director.isSelected = true;
            });
        }
        else {
            this.model.directors.forEach(function (director) {
                director.isSelected = false;
            });
        }
        this.updateSelection();
    };
    CompanyProfileComponent.prototype.selectDirector = function () {
        this.updateSelection();
    };
    CompanyProfileComponent.prototype.updateSelection = function () {
        var found = this.model.directors.find(function (director) { return director.isSelected == false; });
        if (found || this.model.directors.length == 0) {
            this.areAllDirectorsSelected = false;
        }
        else {
            this.areAllDirectorsSelected = true;
        }
    };
    CompanyProfileComponent.prototype.deleteAllSelected = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: {
                header: "Confirm Delete?",
                text: "Are you sure you want to Delete ?",
            },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                if (_this.areAllDirectorsSelected) {
                    _this.model.directors = [];
                }
                else {
                    var newArray = _this.model.directors.filter(function (director) { return director.isSelected == false; });
                    _this.model.directors = newArray;
                }
                _this.updateSelection();
                _this.ngxLoader.stop();
            }
        });
    };
    CompanyProfileComponent.prototype.openDirectorDetailsPopup = function (index) {
        this.model.directors[index].shouldShowDialog = true;
        this.utility.showOverlay();
    };
    CompanyProfileComponent.prototype.onCloseDirectorDetails = function () {
        this.model.directors.forEach(function (e) { return (e.shouldShowDialog = false); });
        this.utility.hideOverlay();
    };
    CompanyProfileComponent.prototype.checkValidation = function () {
        this.utility.formValidation();
        if (this.model.nameOfTheCompany === "" ||
            this.model.nameOfTheCompany == null ||
            this.model.cinNumber === "" ||
            this.model.cinNumber == null ||
            this.model.typeOfTheCompany == null ||
            this.model.typeOfTheCompany === "" ||
            this.model.dateOfIncorporation === "" ||
            this.model.dateOfIncorporation == null ||
            this.model.corporateOfficeAddress === "" ||
            this.model.corporateOfficeAddress == null ||
            this.model.companyEmailId === "" ||
            this.model.companyEmailId == null ||
            (this.model.companyEmailId &&
                !this.validateEmail(this.model.companyEmailId))) {
            if (this.model.nameOfTheCompany === "" ||
                this.model.nameOfTheCompany == null) {
                this.companyNameValid = true;
            }
            if (this.model.cinNumber === "" || this.model.cinNumber == null) {
                this.cinNoValid = true;
            }
            if (this.model.typeOfTheCompany === "" ||
                this.model.typeOfTheCompany == null) {
                this.companyTypeValid = true;
            }
            if (this.model.dateOfIncorporation === "" ||
                this.model.dateOfIncorporation == null) {
                this.incorporateDtValid = true;
            }
            if (this.model.corporateOfficeAddress === "" ||
                this.model.corporateOfficeAddress == null) {
                this.officeAddressValid = true;
            }
            if (this.model.companyEmailId === "" ||
                this.model.companyEmailId == null ||
                (this.model.companyEmailId &&
                    !this.validateEmail(this.model.companyEmailId))) {
                this.emailValid = true;
            }
            var company = document.getElementById("Company_Details_Card");
            this.utility.focusSection(company);
            return false;
        }
        else if (this.model.nic === "" ||
            this.model.nic == null ||
            this.model.business.length == 0 ||
            this.model.business.length == null ||
            this.model.industry == null ||
            this.model.industry.length == 0) {
            if (this.model.nic === "" || this.model.nic == null) {
                this.nicValid = true;
            }
            if (this.model.business.length == 0 ||
                this.model.business.length == null) {
                this.businessInvalid = true;
            }
            if (this.model.industry == null || this.model.industry.length == 0) {
                this.industryInvalid = true;
            }
            var business = document.getElementById("Business_Details_Card");
            this.utility.focusSection(business);
            return false;
        }
        else {
            return true;
        }
    };
    CompanyProfileComponent.prototype.formatDate = function (date) {
        if (date) {
            var newDate = new Date(date);
            return newDate.toISOString().replace("Z", "+0000");
        }
        else {
            return "";
        }
    };
    CompanyProfileComponent.prototype.transformDate = function (date) {
        return this.datePipe.transform(date, "shortDate");
    };
    CompanyProfileComponent.prototype.close = function () {
        this.ngxLoader.start();
        this.fetchCompanyDetails();
        this.ngxLoader.stop();
    };
    CompanyProfileComponent.prototype.saveCompanyDetails = function () {
        var _this = this;
        this.save();
        this.isValid = this.checkValidation();
        if (this.isValid == false) {
            this.toastr.error("Please fill all the required fields!");
            return;
        }
        if (this.model.business.length == 0 || this.model.business.length == null) {
            this.toastr.error("Please fill Category details!");
            this.businessInvalid = true;
            return;
        }
        if (this.model.industry.length == 0 || this.model.industry.length == null) {
            this.toastr.error("Please fill Industry details!");
            this.industryInvalid = true;
            return;
        }
        if (this.model.founders.length == 0) {
            this.toastr.error("Please fill founder details!");
            return;
        }
        else if (this.model.founders.length > 0) {
            var k = 0;
            for (k = 0; k < this.model.founders.length; k++) {
                if (this.model.founders[k].fullName == "" ||
                    this.model.founders[k].fullName == null ||
                    this.model.founders[k].emailId == "" ||
                    !this.validateEmail(this.model.founders[k].emailId) ||
                    this.model.founders[k].emailId == null ||
                    this.model.founders[k].nationality == "" ||
                    this.model.founders[k].nationality == null) {
                    this.toastr.error("Please fill founder details!", "Error!");
                    if (this.model.founders[k].fullName === "" ||
                        this.model.founders[k].fullName == null) {
                        this.fName = true;
                    }
                    if (this.model.founders[k].emailId === "" ||
                        this.model.founders[k].emailId == null ||
                        !this.validateEmail(this.model.founders[k].emailId)) {
                        this.fEmail = true;
                    }
                    if (this.model.founders[k].nationality === "" ||
                        this.model.founders[k].nationality == null) {
                        this.fNationality = true;
                    }
                    var founder = document.getElementById("Founders_Card");
                    this.utility.focusSection(founder);
                    return;
                }
                if (this.model.founders[k].designation === "" ||
                    this.model.founders[k].designation == null ||
                    this.model.founders[k].dateOfBirth === "" ||
                    this.model.founders[k].dateOfBirth == null) {
                    this.toastr.warning("Please fill founder profile details of " +
                        this.model.founders[k].fullName, "Warning!");
                }
            }
        }
        if (this.model.directors.length == 0) {
            this.toastr.error("Please fill director fields!");
            return;
        }
        else if (this.model.directors.length > 0) {
            var m = 0;
            for (m = 0; m < this.model.directors.length; m++) {
                if (this.model.directors[m].fullName === "" ||
                    this.model.directors[m].fullName == null ||
                    this.model.directors[m].din === "" ||
                    this.model.directors[m].din == null ||
                    this.model.directors[m].nationality === "" ||
                    this.model.directors[m].nationality == null ||
                    this.model.directors[m].typeOfDirector === "" ||
                    this.model.directors[m].typeOfDirector == null ||
                    this.model.directors[m].emailId === "" ||
                    this.model.directors[m].emailId == null ||
                    !this.validateEmail(this.model.directors[m].emailId)) {
                    this.toastr.error("Please fill director details!", "Error!");
                    if (this.model.directors[m].fullName === "" ||
                        this.model.directors[m].fullName == null) {
                        this.dName = true;
                    }
                    if (this.model.directors[m].din === "" ||
                        this.model.directors[m].din == null) {
                        this.dDin = true;
                    }
                    if (this.model.directors[m].nationality === "" ||
                        this.model.directors[m].nationality == null) {
                        this.dNationality = true;
                    }
                    if (this.model.directors[m].typeOfDirector === "" ||
                        this.model.directors[m].typeOfDirector == null) {
                        this.dType = true;
                    }
                    if (this.model.directors[m].emailId === "" ||
                        this.model.directors[m].emailId == null ||
                        !this.validateEmail(this.model.directors[m].emailId)) {
                        this.dEmail = true;
                    }
                    var director = document.getElementById("Directors_Card");
                    this.utility.focusSection(director);
                    return;
                }
                if (this.model.directors[m].dateOfAppointement === "" ||
                    this.model.directors[m].dateOfAppointement == null ||
                    this.model.directors[m].dateOfBoardResoluitonForAppointement === "" ||
                    this.model.directors[m].dateOfBoardResoluitonForAppointement == null) {
                    this.toastr.warning("Please fill director profile details of " +
                        this.model.directors[m].fullName, "Warning!");
                }
            }
        }
        this.model.dateOfIncorporation = this.transformDate(this.model.dateOfIncorporation);
        this.model.founders.forEach(function (founder) {
            founder.dateOfBirth = _this.transformDate(founder.dateOfBirth);
        });
        this.model.directors.forEach(function (director) {
            director.dateOfBirth = _this.transformDate(director.dateOfBirth);
            director.dateOfAppointement = _this.transformDate(director.dateOfAppointement);
            director.dateOfBoardResoluitonForAppointement = _this.transformDate(director.dateOfBoardResoluitonForAppointement);
            director.dateOfBoardResoluitonForCessation = _this.transformDate(director.dateOfBoardResoluitonForCessation);
            director.dateOfCessation = _this.transformDate(director.dateOfCessation);
        });
        var user = JSON.parse(sessionStorage.getItem("userDetails"));
        var company = user.company;
        if (company.nameOfTheCompany.toLowerCase() !=
            this.model.nameOfTheCompany.toLowerCase()) {
            this.oldName = company.nameOfTheCompany;
        }
        var formData = new FormData();
        formData.append("id", this.model.id);
        formData.append("nameOfTheCompany", this.model.nameOfTheCompany);
        formData.append("dippCertificateNumber", this.model.dippCertificateNumber);
        formData.append("doingBusinessAs", this.model.doingBusinessAs);
        formData.append("cinNumber", this.model.cinNumber);
        formData.append("isInForEquitySecurity", this.model.isInForEquitySecurity);
        formData.append("isInForPreferenceSecurity", this.model.isInForPreferenceSecurity);
        formData.append("typeOfTheCompany", this.model.typeOfTheCompany);
        formData.append("dateOfIncorporation", this.model.dateOfIncorporation);
        formData.append("corporateOfficeAddress", this.model.corporateOfficeAddress);
        formData.append("companyEmailId", this.model.companyEmailId);
        formData.append("companyWebsite", this.model.companyWebsite);
        formData.append("nic", this.model.nic);
        formData.append("subscribersOfMemorandum", this.model.subscribersOfMemorandum);
        formData.append("oldName", this.oldName);
        this.model.business.forEach(function (item, index) {
            formData.append("business[" + index + "]", item);
        });
        this.model.industry.forEach(function (item, index) {
            formData.append("industry[" + index + "]", item);
        });
        var _loop_1 = function (i) {
            Object.entries(this_1.model.founders[i]).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (key === "dateOfBirth") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                else {
                    formData.append("founders[" + i + "]." + key, value);
                }
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.model.founders.length; i++) {
            _loop_1(i);
        }
        var _loop_2 = function (i) {
            Object.entries(this_2.model.directors[i]).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (key === "dateOfBirth") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfAppointement") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfBoardResoluitonForAppointement") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfBoardResoluitonForCessation") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfCessation") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                else {
                    formData.append("directors[" + i + "]." + key, value);
                }
            });
        };
        var this_2 = this;
        for (var i = 0; i < this.model.directors.length; i++) {
            _loop_2(i);
        }
        if (this.profilePicture) {
            formData.set("companyLogo", new File([this.profilePicture], this.fileName, { type: "image/jpeg" }));
        }
        else if (this.model.companyLogoPath) {
            formData.append("companyLogoPath", this.model.companyLogoPath);
        }
        new Response(formData).text().then(console.log);
        this.ngxLoader.start();
        this.authService.saveCompanyDetails(formData).subscribe(function (res) {
            if (res) {
                _this.fetchCompanyDetails();
                _this.ngxLoader.stop();
                _this.toastr.success("Company Details Updated Successfully", "Success");
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error("Null response!.", "Failure!");
            }
        }, function (e) {
            _this.ngxLoader.stop();
            _this.toastr.error("Error: " + e.error.reason, "Error");
            _this.model.dateOfIncorporation = _this.datepipe.transform(_this.model.dateOfIncorporation, "yyyy-MM-dd");
            _this.model.founders.forEach(function (founder) {
                founder.dateOfBirth = _this.datepipe.transform(founder.dateOfBirth, "yyyy-MM-dd");
            });
            _this.model.directors.forEach(function (director) {
                director.dateOfBirth = _this.datepipe.transform(director.dateOfBirth, "yyyy-MM-dd");
                director.dateOfAppointement = _this.datepipe.transform(director.dateOfAppointement, "yyyy-MM-dd");
                director.dateOfBoardResoluitonForAppointement =
                    _this.datepipe.transform(director.dateOfBoardResoluitonForAppointement, "yyyy-MM-dd");
                director.dateOfBoardResoluitonForCessation = _this.datepipe.transform(director.dateOfBoardResoluitonForCessation, "yyyy-MM-dd");
                director.dateOfCessation = _this.datepipe.transform(director.dateOfCessation, "yyyy-MM-dd");
            });
        });
    };
    CompanyProfileComponent.prototype.showHelp = function (section) {
        if (section == this.helper) {
            return;
        }
        var targetElement;
        targetElement = document.getElementById(section);
        if (targetElement != null) {
            this.helperTopPosition = targetElement.offsetTop;
        }
        this.helpSections = this.helperService.getHelpContent("CompanyDetailsComponent", section);
        this.helper = section;
    };
    CompanyProfileComponent.prototype.hideHelp = function () {
        this.helper = "";
    };
    CompanyProfileComponent.prototype.disableFoundersInputs = function () {
        if (this.model.founders.length > 0) {
            this.model.founders.forEach(function (founder) {
                founder.isEdit = false;
            });
        }
    };
    CompanyProfileComponent.prototype.disableDirectorsInputs = function () {
        if (this.model.directors.length > 0) {
            this.model.directors.forEach(function (director) {
                director.isEdit = false;
            });
        }
    };
    CompanyProfileComponent.prototype.allowAlphaNumericKey = function (event) {
        return event.charCode == 8 || event.charCode == 0 || event.charCode == 101
            ? null
            : (event.charCode >= 48 && event.charCode <= 57) ||
                (event.charCode >= 65 && event.charCode <= 90) ||
                (event.charCode >= 97 && event.charCode <= 122);
    };
    CompanyProfileComponent.prototype.validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    CompanyProfileComponent.prototype.getEvent = function () {
        this.shouldLogoShowDialog = false;
        this.utility.hideOverlay();
    };
    CompanyProfileComponent.prototype.openUploadImage = function () {
        this.utility.showOverlay();
        this.shouldLogoShowDialog = true;
    };
    CompanyProfileComponent.prototype.onImageSaved = function (imageDetails) {
        this.shouldLogoShowDialog = false;
        this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
        this.croppedImage = imageDetails.croppedImage;
        this.fileName = imageDetails.fileName;
        this.companyLogoInvalid = false;
    };
    CompanyProfileComponent.prototype.dataURItoBlob = function (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else
            byteString = unescape(dataURI.split(",")[1]);
        // separate out the mime component
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    };
    return CompanyProfileComponent;
}());
export { CompanyProfileComponent };
