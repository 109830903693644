import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { UtilityService } from 'src/app/services/utilities.service';
declare var $: any;

@Component({
  selector: 'app-round-name',
  templateUrl: './round-name.component.html',
  styleUrls: ['./round-name.component.scss']
})
export class RoundNameComponent implements OnInit {
  
  @Input() showDialog: boolean;
  @Input() roundData;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  roundDetails: FormGroup;
  isSubmitted: boolean = false;
  convRatioInvalid: boolean;
  votingRatioInvalid: boolean;
  editConversionTable = [];
  editConversionRow = null;

  constructor(private toastr: ToastrService,
    private utility: UtilityService,
    private fb: FormBuilder) { }

    close() {
      this.showDialog = false;
      this.visibleChange.emit(false);
    }

    saveRoundDetails(){
      this.isSubmitted = true;
      if(this.roundDetails.valid && this.editConversionRow == null){
        this.isSubmitted = false;
        this.roundData.roundName = this.roundDetails.value.roundName;
        this.roundData.roundIdentifier = this.roundDetails.value.roundIdentifier;
        this.roundData.isPricedRound = this.roundDetails.value.isPricedRound;
        this.roundData.roundConversionRatio = this.roundDetails.value.roundConversionRatio;
        this.roundData.roundVotingRightsRatio = this.roundDetails.value.roundVotingRightsRatio;
        this.editConversionTable.forEach(element => {
          element['valuation'] = element['valuation'].toString().replace(/,/g, "")/1;
        });
        if(_.find(this.roundData.existingRoundDetails, { roundName: this.roundDetails.value.roundName })) {
          _.find(this.roundData.existingRoundDetails, { roundName: this.roundDetails.value.roundName }).roundIdentifier = this.roundDetails.value.roundIdentifier;
          _.find(this.roundData.existingRoundDetails, { roundName: this.roundDetails.value.roundName }).isPricedRound = this.roundDetails.value.isPricedRound;
          _.find(this.roundData.existingRoundDetails, { roundName: this.roundDetails.value.roundName }).roundConversionRatio = this.roundDetails.value.roundConversionRatio;
          _.find(this.roundData.existingRoundDetails, { roundName: this.roundDetails.value.roundName }).roundVotingRightsRatio = this.roundDetails.value.roundVotingRightsRatio;
          _.find(this.roundData.existingRoundDetails, { roundName: this.roundDetails.value.roundName }).updateConversionRatios = this.editConversionTable;
        } else {
          let newRoundObject = {
            "roundName": this.roundDetails.value.roundName,
            "roundIdentifier": this.roundDetails.value.roundIdentifier,
            "isPricedRound": this.roundDetails.value.isPricedRound,
            "roundConversionRatio": this.roundDetails.value.roundConversionRatio,
            "roundVotingRightsRatio": this.roundDetails.value.roundVotingRightsRatio,
            "updateConversionRatios": this.editConversionTable
          }
          this.roundData.existingRoundDetails.push(newRoundObject);
        }
        this.visibleChange.emit(true);
      }
      else{
        if(this.editConversionRow != null) {
          this.toastr.error("Please fill all the fields & save the row", "Error!");
        } else {
          this.toastr.error("Please fill mandatory fields", "Error!");
        }
      }
    }

    onRoundChanged(e) {
      let roundNameString = e.target.value;
      let selectedRound = _.find(this.roundData.existingRoundDetails, { roundName: roundNameString });
      if(selectedRound) {
        this.roundDetails.controls['roundName'].setValue(selectedRound.roundName);
        this.roundDetails.controls['roundIdentifier'].setValue(selectedRound.roundIdentifier);
        this.roundDetails.controls['isPricedRound'].setValue(selectedRound.isPricedRound);
        this.roundDetails.controls['roundConversionRatio'].setValue(selectedRound.roundConversionRatio);
        this.roundDetails.controls['roundVotingRightsRatio'].setValue(selectedRound.roundVotingRightsRatio);
        this.editConversionTable = selectedRound['updateConversionRatios'];
        $("#pricedRound").bootstrapSwitch('state', selectedRound.isPricedRound);
        setTimeout(() => {
          this.loadJSstyles();
          this.editConversionTable.forEach((element, index) => {
            this.setToggle(index, element['isValuationAvailable']);
            let target = "#"+index;
            $(target).bootstrapSwitch('disabled',true);
          });
        }, 50);
      } else {
        this.roundDetails.controls['roundName'].setValue(roundNameString);
        this.roundDetails.controls['roundIdentifier'].setValue('');
        this.roundDetails.controls['isPricedRound'].setValue(true);
        this.roundDetails.controls['roundConversionRatio'].setValue('');
        this.roundDetails.controls['roundVotingRightsRatio'].setValue('');
        this.editConversionTable = []; 
        $("#pricedRound").bootstrapSwitch('state', true);
      }
    }

    addNewConversion() {
      let conversionTableObj = {
        "date": "",
        "isValuationAvailable": true,
        "valuation": "",
        "updatedConversionRatio": "",
        "isEditable": true
      }
      if(this.editConversionTable.length > 0) {
        let index = this.editConversionTable.length - 1;
        if(this.editConversionTable[index].date == "") {
          this.toastr.error("Please fill all the fields and save the current row.", "Error");
          return;
        } else {
          if(this.editConversionTable[index].isValuationAvailable == true && this.editConversionTable[index].valuation == "") {
            this.toastr.error("Please fill all the fields and save the current row.", "Error");
            return;
          } else if(this.editConversionTable[index].isValuationAvailable == false && this.editConversionTable[index].updatedConversionRatio == "") {
            this.toastr.error("Please fill all the fields and save the current row.", "Error");
            return;
          } else {
            this.editConversionTable.push(conversionTableObj);
            this.editConversionRow = this.editConversionTable.length - 1;
            setTimeout(() => {
              this.loadJSstyles();
              this.setToggle(this.editConversionRow, conversionTableObj['isValuationAvailable']);
            }, 50);
          }
        }
      } else {
        this.editConversionTable.push(conversionTableObj);
        this.editConversionRow = this.editConversionTable.length - 1;
        setTimeout(() => {
          this.loadJSstyles();
          this.setToggle(this.editConversionRow, conversionTableObj['isValuationAvailable']);
        }, 50);
      }
    }

    setToggle(index, state) {
      let target = "#"+index;
      $(target).bootstrapSwitch('state', state);
      $(target).on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        this.editConversionTable[this.editConversionRow]['isValuationAvailable'] = state;
      });
    }

    saveConversion(index) {
      if(this.editConversionTable[index].date == "") {
        this.toastr.error("Please fill all the fields.", "Error");
      } else {
        if(this.editConversionTable[index].isValuationAvailable == true && this.editConversionTable[index].valuation == "") {
          this.toastr.error("Please fill all the fields.", "Error");
        } else if(this.editConversionTable[index].isValuationAvailable == false && this.editConversionTable[index].updatedConversionRatio == "") {
          this.toastr.error("Please fill all the fields.", "Error");
        } else {
          let dt = new Date(this.editConversionTable[index].date);
          this.editConversionTable[index].date = dt.toISOString().replace("Z", "+0000");
          this.editConversionRow = null;
          let target = "#"+index;
          $(target).bootstrapSwitch('disabled',true);
        }
      }
    }

    editConversion(index) {
      if(this.editConversionRow != null) {
        if(this.editConversionTable[this.editConversionRow].date == "") {
          this.toastr.error("Please fill all the fields and save the current row.", "Error");
          return;
        } else {
          if(this.editConversionTable[this.editConversionRow].isValuationAvailable == true && this.editConversionTable[this.editConversionRow].valuation == "") {
            this.toastr.error("Please fill all the fields and save the current row.", "Error");
            return;
          } else if(this.editConversionTable[this.editConversionRow].isValuationAvailable == false && this.editConversionTable[this.editConversionRow].updatedConversionRatio == "") {
            this.toastr.error("Please fill all the fields and save the current row.", "Error");
            return;
          } else {
            this.toastr.error("Please save the current row.", "Error");
            return;
          }
        }
      } else {
        let dt = new Date(this.editConversionTable[index].date);
        this.editConversionTable[index].date = dt.toISOString().replace("Z", "+0000");
        this.editConversionRow = index;
        let target = "#"+index;
        $(target).bootstrapSwitch('disabled',false);
      }
    }

    removeConversionRow(index) {
      this.editConversionTable.splice(index, 1);
      this.editConversionRow = null;
    }

    ngOnInit() {
      if(this.roundData.roundName && this.roundData.roundName != 'Auto Fill') {
        this.roundDetails = this.fb.group({
            roundName: [this.roundData.roundName, [Validators.required, emptySpaceValidator]],
            roundIdentifier: [this.roundData.roundIdentifier, [Validators.required, emptySpaceValidator]],
            isPricedRound: [this.roundData.isPricedRound],
            roundConversionRatio: [this.roundData.roundConversionRatio],
            roundVotingRightsRatio: [this.roundData.roundVotingRightsRatio]
        });
        this.editConversionTable = _.find(this.roundData.existingRoundDetails, { roundName: this.roundData.roundName }).updateConversionRatios;
        this.editConversionTable.forEach(element => {
          element['isEditable'] = false;
        });
      } else {
        this.roundDetails = this.fb.group({
            roundName: ['', [Validators.required, emptySpaceValidator]],
            roundIdentifier: ['', [Validators.required, emptySpaceValidator]],
            isPricedRound: [true],
            roundConversionRatio: [''],
            roundVotingRightsRatio: ['']
        });
      }
      
    }

    getAmount(usrCompanyCurrency, amt) {
      let val = this.utility.formatCurrency(usrCompanyCurrency, amt);
      return val;
    }

    ngAfterViewInit() {
      this.loadJSstyles();
    }

    loadJSstyles() {
        $('[data-toggle="switch"]').bootstrapSwitch({
            onText: "Yes",
            offText: "No",
        });
        $("#pricedRound").bootstrapSwitch('state', this.roundDetails.controls['isPricedRound'].value);
        $("#pricedRound").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
            this.roundDetails.controls['isPricedRound'].setValue(state);
        });
        // this.editConversionTable.forEach(element => {
        //   $().bootstrapSwitch('state', element['isValuationAvailable']);
        //   $().bootstrapSwitch('disabled', !element['isEditable']);
        // });
    }

    checkRatioVal(value, ratio) {
        let ratioIndex = value.indexOf(":");
        let part1 = parseInt(value.substring(0, ratioIndex));
        let part2 = parseInt(value.substring(ratioIndex + 1, value.length + 1));
        if (
          part1 == value.substring(0, ratioIndex) &&
          part2 == value.substring(ratioIndex + 1, value.length + 1)
        ) {
            if(ratio == 'convRatio') {
                this.convRatioInvalid = false;
            } else if(ratio == 'votingRights') {
                this.votingRatioInvalid = false;
            }
        } else {
            if(ratio == 'convRatio') {
                this.convRatioInvalid = true;
            } else if(ratio == 'votingRights') {
                this.votingRatioInvalid = true;
            }
        }
    }

    onKeydownEvent(e) {
        this.utility.restrictMinus(e);
    }

}

//To validate input for only empty spaces
function emptySpaceValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const value: string = control.value;
  if (value.length > 0) {
    if (value.trim().length > 0) {
      return null;
    } else {
      return {
        invalid: true,
      };
    }
  }
}
