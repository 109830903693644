import { Component, Input, OnInit } from '@angular/core';
import { ShareHolder } from '../buy-back-tab/model/shareHolder';
import { CacheProviderService } from "../../services/cache-provider.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { DatePipe } from "@angular/common";
import { DataService } from "../../services/new-round-notify.service";
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from "../../services/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { BuyBackDetails } from '../buy-back-tab/model/buyBackDetails';
import 'rxjs/add/operator/filter';
import { HelpContent } from '../buy-back-tab/model/helpContent';
import * as _ from 'lodash';
// import { Observable, Subscriber } from 'rxjs';

@Component({
  selector: 'app-shareholders-tab',
  templateUrl: './shareholders-tab.component.html',
  styleUrls: ['./shareholders-tab.component.scss']
})
export class ShareholdersTabComponent implements OnInit {


  shareHolders: ShareHolder[] = [];
  shareHoldersFromResponse: [];
  showDialog: boolean = false;
  selectedShareDistinctiveSets = [];
  selectedWillingness;
  selectedEligibility;
  selectedRow;
  target: number = 0;
  pps: number = 0;
  totalBuyBackAmount: number = 0;
  instrumentName: string = "";
  totalWillingNess: number = this.shareHolders.reduce((acc, shareHolder) => acc + shareHolder.willingness, 0);
  public disableripple: boolean = true;

  actualTargetShares: number = this.totalWillingNess;
  actualTargetSharesText;
  public usrCompanyCurrency = "";
  public userDetails;
  buyBackDetails: any;
  buyBackId: number;
  math = Math;
  leftSharesText: number = 0;

  public helpTitle: string = "";
  public helpMessage: string = "";
  helperTopPosition: number;
  helper: string = "";
  helpSections: HelpContent;
  currencySymbol = "";

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private utility: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.activatedRoute.queryParams
      .filter(params => params.buyBackId)
      .subscribe(params => {
        this.buyBackId = params.buyBackId;
      }
      );
    this.authService.getProformaBuyBackDetails(this.buyBackId).subscribe((res: BuyBackDetails) => {

      this.buyBackDetails = res;
      this.target = res.numberOfSharesBoughtBackTarget;
      this.pps = res.pricePerShare;
      this.instrumentName = res.className;
      this.totalBuyBackAmount = res.buyBackAmount;

      if (this.buyBackDetails.state === "BASIC_DETAILS") {
        this.dataService.updateData({
          "isbuyBackDetailsTabValid": true,
          "isShareholdersTabValid": null,
          "isReviewTabValid": null,
          "isSummaryTabValid": null,
          "isConfirmTabValid": null
        });
      }
      else if (this.buyBackDetails.state === "SHAREHOLDER_DETAILS") {
        this.dataService.updateData({
          "isbuyBackDetailsTabValid": true,
          "isShareholdersTabValid": true,
          "isReviewTabValid": true,
          "isSummaryTabValid": null,
          "isConfirmTabValid": null
        });
      }
      else if (this.buyBackDetails.state === "CAPTABLE_SUMMARY") {
        this.dataService.updateData({
          "isbuyBackDetailsTabValid": true,
          "isShareholdersTabValid": true,
          "isReviewTabValid": true,
          "isSummaryTabValid": true,
          "isConfirmTabValid": null
        });
      }

      if (this.buyBackDetails.startDate != null && (this.buyBackDetails.state === "BASIC_DETAILS" || this.buyBackDetails.state === "SHAREHOLDER_DETAILS" || this.buyBackDetails.state === "CAPTABLE_SUMMARY")) {
        if (this.buyBackDetails.state === "BASIC_DETAILS") {
          this.authService.getInitialShareholdersData(this.buyBackId, true).subscribe((res: []) => {
            this.shareHoldersFromResponse = res;
            this.shareHolders = res;

            const sumOfShares: number = this.shareHolders.reduce((acc, shareHolder) => acc + shareHolder.totalShares, 0);
            this.leftSharesText = this.buyBackDetails.numberOfSharesBoughtBackTarget;
            this.shareHolders.forEach(shareHolder => {
              shareHolder.totalBoughtShares = 0;
              shareHolder.currentHoldingPercentage = shareHolder.totalShares / sumOfShares;
              shareHolder.currentHoldingPercentageLabel = Math.round(shareHolder.currentHoldingPercentage * 10000.0) / 100.0;
              shareHolder.proRataEligibility = Math.round(shareHolder.currentHoldingPercentage * this.target);
              shareHolder.isParticipating = true;
            })
            this.shareHolders.sort((a, b) => b.currentHoldingPercentage.toString().localeCompare(a.currentHoldingPercentage.toString()));
            this.ngxLoader.stop();

          }, (e) => {
            this.ngxLoader.stop();
            this.toastr.error("Error: Unable to get Shareholders data", e);
          });
        }
        else if (this.buyBackDetails.state === "SHAREHOLDER_DETAILS" || this.buyBackDetails.state === "CAPTABLE_SUMMARY") {
          this.authService.getInitialShareholdersData(this.buyBackId, false).subscribe((res: []) => {
            this.shareHoldersFromResponse = res;
            this.shareHolders = res;
            this.leftSharesText = Math.max(this.buyBackDetails.numberOfSharesBoughtBackTarget - this.buyBackDetails.numberOfSharesBoughtBackActual, 0);

            const sumOfShares: number = this.shareHolders.reduce((acc, shareHolder) => acc + shareHolder.totalShares, 0);
            this.shareHolders.forEach(shareHolder => {
              shareHolder.totalBoughtShares = 0;
              shareHolder.currentHoldingPercentageLabel = Math.round(shareHolder.currentHoldingPercentage * 10000) / 100;
              shareHolder.proRataEligibility = Math.round(shareHolder.currentHoldingPercentage * this.target);
              const totalWillingNess: number = this.shareHolders.reduce((acc, shareHolder) => acc + shareHolder.willingness, 0);
              const actualTarget: number = Math.min(totalWillingNess, this.target);
              this.actualTargetShares = actualTarget;
              this.actualTargetSharesText = this.getTotalValueTble(this.actualTargetShares);
            })
            this.shareHolders.sort((a, b) => b.currentHoldingPercentage.toString().localeCompare(a.currentHoldingPercentage.toString()));
            this.ngxLoader.stop();
          }, (e) => {
            this.ngxLoader.stop();
            this.toastr.error("Error: Unable to get Shareholders data", e);
          });
        }

      }
      else {
        this.ngxLoader.stop();
        this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page");
        this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      }

    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page", e);
      this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
    });
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();

  }

  editWillingness(currRow) {
      this.selectedRow = currRow.id;
      this.selectedShareDistinctiveSets = currRow.shareDistinctiveNumbers;
      this.selectedWillingness = currRow.willingness;
      this.selectedEligibility = currRow.proRataEligibility;
      this.showDialog = true;
      document.getElementById("overlay").style.display = "block";
  }

  getWillingnessEdit(event) {
    if(event) {
      _.find(this.shareHolders, { 'id': this.selectedRow })['shareDistinctiveNumbers'] = event.shareDistSets;
      _.find(this.shareHolders, { 'id': this.selectedRow })['willingness'] = event.willingness;
      this.calculateFinalEligibility();
    }
    this.showDialog = false;
    document.getElementById("overlay").style.display = "none";
  }


  goToBuyBack() {
    this.ngxLoader.start();
    // this.dataService.updateData({
    //   "isbuyBackDetailsTabValid":true,
    //   "isShareholdersTabValid":null,
    //   "isReviewTabValid":null,
    //   "isSummaryTabValid":null,
    //   "isConfirmTabValid":null
    // });
    this.ngxLoader.stop();
    this.router.navigate(
      ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'buyBack'],
      { queryParams: { 'buyBackId': this.buyBackId } }
    );
  }


  discardBuyBack() {
    this.ngxLoader.start();
    if(confirm('Are you sure you want to discard the Buy-Back?')) {
      if (this.buyBackDetails.state != "COMPLETED"){
        this.authService.discardBuyBackTransaction(this.buyBackDetails.id).subscribe((response) => {
          if (response['resultCode'] == 'SUCCESS') {
              this.ngxLoader.stop();
              this.toastr.success("Buy-Back Discarded successfully!", "Success");
              this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Error discarding Buy-back", "Failure");
          }
      }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error discarding Buy-back" );
      })
      }
    } else {
        this.ngxLoader.stop();
        return;
    }
  }

  goToReview() {
    this.ngxLoader.start();

    if (this.actualTargetShares == 0){
      this.ngxLoader.stop();
      this.toastr.error("Error: Sum of willingness of Shareholders cannot be 0");
      return;
    }
    
    if (this.actualTargetShares < this.target){
      if (confirm('Total willingness of Shareholders is less than the Target Shares. Are you sure to continue the buy-back?')) {
        this.shareHolders.forEach((s => {
          s.currentEligibility = 0;
          s.proRataEligibility = 0;
          s.currentBoughtShares = 0;
          s.totalBoughtShares = 0;
          s.isParticipating = true;
        } ))
        this.authService.saveShareholdersDataForBuyBack(this.buyBackId, this.shareHolders).subscribe((response) => {
          if (response) {
            this.dataService.updateData({
              "isbuyBackDetailsTabValid":true,
              "isShareholdersTabValid":true,
              "isReviewTabValid":null,
              "isSummaryTabValid":null,
              "isConfirmTabValid":null
            });
            // this.dataService.setApplicationData("shareholdersData", response)
            this.ngxLoader.stop();
            this.router.navigate(
              ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'review'],
              { queryParams: { 'buyBackId': this.buyBackId } }
            );
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Error: Response is null!", "Error");
          }
        }, (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e, "Error");
        });
      }
      else{
        this.ngxLoader.stop();
        return;
      }
    }
    else{
      this.authService.saveShareholdersDataForBuyBack(this.buyBackId, this.shareHolders).subscribe((response) => {
        if (response) {
          this.dataService.updateData({
            "isbuyBackDetailsTabValid":true,
            "isShareholdersTabValid":true,
            "isReviewTabValid":null,
            "isSummaryTabValid":null,
            "isConfirmTabValid":null
          });
          // this.dataService.setApplicationData("shareholdersData", response)
          this.ngxLoader.stop();
          this.router.navigate(
            ['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'review'],
            { queryParams: { 'buyBackId': this.buyBackId } }
          );
        }
        else {
          this.ngxLoader.stop();
          this.toastr.error("Error: Response is null!", "Error");
        }
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error: " + e, "Error");
      });
    }
    
  }

  numberToString(num: number) {
    return num != null ? num.toString() : "0";
    // return num.toString();
  }

  editorPreparing(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode == 46) {
      return false;
    }
    else {
      return true;
    }
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utility.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if (value != undefined || value != null) {
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utility.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }

  showHelp(section) {
    if(section == this.helper){
        return;
      }
      const targetElement = document.getElementById(section);
      if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
      }
      
        this.helpSections = this.helperService.getHelpContent(
          "ShareholdersTabComponent",
          section
        );
      this.helper = section;
}

// help section hide
  hideHelp() {
    this.helper = "";
  }

  calculateFinalEligibility() {
    const shareHolders: ShareHolder[] = this.shareHolders;
    const pps: number = this.pps;
    const target: number = this.target;
    const sumOfShares: number = shareHolders.reduce((acc, shareHolder) => acc + shareHolder.totalShares, 0);
    shareHolders.forEach(shareHolder => {
      shareHolder.isParticipating = true;
      if (shareHolder.willingness > shareHolder.totalShares) {
        this.toastr.error("Error: Shareholder willingness cannot be greater than the current holding!");
        shareHolder.willingness = 0
        return;
      }
      if(shareHolder.willingness > shareHolder.proRataEligibility) {
        this.toastr.error("Error: Shareholder willingness cannot be greater than the pro rata eligibility!")
      }
      if (shareHolder.willingness % 1 != 0) {
        this.toastr.error("Error: Shareholder willingness must be a whole number");
        shareHolder.willingness = 0
        return;
      }
      if (shareHolder.willingness < 0) {
        this.toastr.error("Error: Enter a valid willingness number");
        shareHolder.willingness = 0
        return;
      }
    });
    const totalWillingNess: number = shareHolders.reduce((acc, shareHolder) => acc + shareHolder.willingness, 0);
    const actualTarget: number = Math.min(totalWillingNess, target);
    this.leftSharesText = Math.max(target - actualTarget, 0);
    this.actualTargetShares = actualTarget;
    this.actualTargetSharesText = this.getTotalValueTble(this.actualTargetShares);
    let leftShares: number = this.actualTargetShares;
    // recalculateCurrentEligibilities(shareHolders, leftShares);
    // shareHolders.forEach(shareHolder => {
    //   shareHolder.totalBoughtShares = 0;
    //   shareHolder.currentHoldingPercentage = shareHolder.totalShares / sumOfShares;
    //   shareHolder.proRataEligibility = shareHolder.currentEligibility;
    //   shareHolder.isParticipating = true;
    // })
    // let noOfParticipants: number = shareHolders.filter((s) => s.isParticipating).length;
    // while (Math.floor(leftShares) > noOfParticipants) {
    //   let filteredShareHolders = shareHolders.filter(shareHolder => shareHolder.totalBoughtShares < shareHolder.willingness && shareHolder.isParticipating);
    //   filteredShareHolders.forEach(s => s.currentHoldingPercentage = roundToEightDecimals(s.currentHoldingPercentage));
    //   let totalPercentage = filteredShareHolders.reduce((acc, shareHolder) => acc + shareHolder.currentHoldingPercentage, 0);
    //   recalculateCurrentEligibilities(shareHolders, leftShares);
    //   for (let shareHolder of shareHolders) {
    //     shareHolder.currentBoughtShares = Math.min(shareHolder.currentEligibility, shareHolder.willingness - shareHolder.totalBoughtShares);
    //     shareHolder.totalBoughtShares += shareHolder.currentBoughtShares;
    //     shareHolder.isParticipating = shareHolder.willingness > shareHolder.totalBoughtShares;
    //   }
    //   let totalBoughtSharesInCurrentIteration: number = shareHolders.reduce((acc, shareHolder) => acc + shareHolder.currentBoughtShares, 0);
    //   let totalBoughtShares: number = shareHolders.reduce((acc, shareHolder) => acc + shareHolder.totalBoughtShares, 0);
    //   if (totalBoughtSharesInCurrentIteration === 0) {
    //     let minimumPercentShareHolder = 1;
    //     filteredShareHolders.forEach((shareHolder) => {
    //       if (shareHolder.currentHoldingPercentage < minimumPercentShareHolder) minimumPercentShareHolder = shareHolder.currentHoldingPercentage;
    //     })
    //     shareHolders.forEach(shareHolder => {
    //       if (shareHolder.currentHoldingPercentage === minimumPercentShareHolder) {
    //         shareHolder.isParticipating = false;
    //       }
    //     })
    //   }
    //   leftShares = actualTarget - totalBoughtShares;
    //   noOfParticipants = shareHolders.filter((s) => s.isParticipating).length;
    // }
    // distributeLastLeftShares(shareHolders, leftShares);
    this.shareHolders.sort((a, b) => b.currentHoldingPercentage.toString().localeCompare(a.currentHoldingPercentage.toString()));
  }
}

function recalculateCurrentEligibilities(shareHolders: ShareHolder[], leftShares: number) {
  shareHolders.forEach(s => s.currentHoldingPercentage = roundToEightDecimals(s.currentHoldingPercentage));
  let totalPercentage: number = shareHolders
    .filter(s => s.isParticipating)
    .reduce((acc, shareHolder) => acc + shareHolder.currentHoldingPercentage, 0);
  shareHolders.sort((a, b) => a.currentHoldingPercentage.toString().localeCompare(b.currentHoldingPercentage.toString()));
  let currentEligibilityFloatingRunningTotal: number = 0.0;
  let currentEligibilityIntegerRunningTotal: number = 0.0;
  let currentEligibilityIntegerAllotedTotal: number = 0.0;
  for (let shareholder of shareHolders) {
    if (!shareholder.isParticipating) continue;
    shareholder.currentEligibilityFloating = leftShares * shareholder.currentHoldingPercentage / totalPercentage;
    currentEligibilityFloatingRunningTotal += shareholder.currentEligibilityFloating;
    currentEligibilityIntegerRunningTotal = Math.round(currentEligibilityFloatingRunningTotal);
    shareholder.currentEligibility = currentEligibilityIntegerRunningTotal - currentEligibilityIntegerAllotedTotal;
    currentEligibilityIntegerAllotedTotal += shareholder.currentEligibility;
  }
}

function roundToEightDecimals(num: number): number {
  return Math.round(num * 100000000) / 100000000;
}

function distributeLastLeftShares(shareHolders: ShareHolder[], leftShares: number) {
  shareHolders
    .sort((a, b) => a.currentHoldingPercentage.toString().localeCompare(b.currentHoldingPercentage.toString()))
    .forEach(s => {
      if (s.isParticipating && leftShares > 0 && s.willingness > s.totalBoughtShares) {
        s.currentEligibility = 1;
        s.totalBoughtShares += s.currentEligibility;
        leftShares--;
      }
    })
}


