export class ColumnList {
    shareholder: boolean;
    roundName: boolean;
    roundSize: boolean;
    closingDate: boolean;
    securityType: boolean;
    parValue: boolean;
    capitalValue: boolean;
    rounds: boolean;
    common: boolean;
    preference: boolean;
    debentures: boolean;
    warrants: boolean;
    fdbShares: boolean;
    preHolding: boolean;
    postHolding: boolean;
    preHoldingRound: boolean;
    postHoldingRound: boolean;
    preRoundShares: boolean;
    postRoundShares: boolean;
    holding: boolean;
    noOfShares: boolean;
    pricePerShare: boolean;
    netWorth: boolean;
    currentValue: boolean;
    percentageOfGain: boolean;
    amtInvested: boolean;
    amtInvestedInRound: boolean;
    totalInvestment: boolean;
    postMoneyValuation: boolean;
    preMoneyValuation: boolean;
    dilutionPercentage: boolean;
    leadInvestor: boolean;
    document: boolean;
    preSplitCommon: boolean;
    postSplitCommon: boolean;
    preSplitPreference: boolean;
    postSplitPreference: boolean;
    preSplitFdbShares: boolean;
    postSplitFdbShares: boolean;
    newAntidDilutionShares: boolean;
}