import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CustomeCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        if (value) {
            var currencySymbol = '₹';
            //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!
            const result = value.toString().split('.');

            if (result[0].substring(result[0].length - 2) == 'Cr') {
                var lastThree = result[0].substring(result[0].length - 5);
            } else if (result[0].substring(result[0].length - 1) == 'L') {
                var lastThree = result[0].substring(result[0].length - 4);
            } 
            // else if(result[1]){
            //     if(result[1].substring(result[1].length - 1) == 'L'){
            //         var lastThree = result[1].substring(result[1].length - 4);
            //     }
            // } 
            else  {
                var lastThree = result[0].substring(result[0].length - 3);
            }

            if (result[0].substring(result[0].length - 2) == 'Cr') {
                var otherNumbers = result[0].substring(0, result[0].length - 5);
            } else if (result[0].substring(result[0].length - 1) == 'L') {
                var otherNumbers = result[0].substring(0, result[0].length - 4);
            } else {
                var otherNumbers = result[0].substring(0, result[0].length - 3);
            }

            if (otherNumbers != ''){
                lastThree = ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            } else {
                var output = value;
            }

            // if (result.length > 1) {
            //     var descimalPts = result[1] + '00000000';
            //     output += "." + descimalPts.slice(0, args);
            // }
            // if (args && result.length == 1) {
            //     var descimalPts = '00000000';
            //     if (result[0].substring(result[0].length - 1) == 'L') {
            //         output = result[0].substring(0, result[0].length - 1) +".00"+ result[0].substring(result[0].length - 1);
            //     } else if(result[0].substring(result[0].length - 2) == 'Cr'){
            //         output = result[0].substring(0, result[0].length - 2) +".00"+ result[0].substring(result[0].length - 2);
            //     }else {
            //         output += "." + descimalPts.slice(0, args);
            //     }
            // }
            // return currencySymbol + " " + output;
            return output;
        }
        if (!value) {
            if (value != null) {
                var output = value.toString();
                output += "." + "00";
                return output;
            }

        }
    }
}
