import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "./../../authentication.service";
import { PermissionsService } from "src/app/services/permissions.service";

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CacheProviderService } from './../../services/cache-provider.service';
import { UtilityService } from './../../services/utilities.service';
import { HelperService } from './../../services/helper.service';
import { DxTooltipComponent } from 'devextreme-angular';
import * as Excel from 'exceljs/dist/exceljs';
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from 'file-saver';
import { DxDataGridComponent, DxTemplateHost } from "devextreme-angular";
import * as moment from "moment";
import { trigger } from "devextreme/events";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import {map, startWith} from 'rxjs/operators';
import {
  employeesOverviewModel,
  employeesTableDto
} from "../../esop-management/components/overview-employees/overview-employees-model";

@Component({
  selector: 'app-hrms-employee-list-modal',
  templateUrl: './hrms-employee-list-modal.component.html',
  styleUrls: ['./hrms-employee-list-modal.component.scss']
})
export class HrmsEmployeeListModalComponent implements OnInit {

  @Input() showDialogHrmsEmpList: boolean;
  @Output() visibleChangeHrmsEmpList: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild('fileInput') employeesUpload: ElementRef
  public employeesOverview;
  public employeesTableList: Array<employeesTableDto>;
  public showDialog: Boolean = false;
  public editMode;
  public isEditingStarted: boolean = false
  public editRowData;
  public employeeId;
  public showDialogEmp: boolean;
  public empList = null;
  public empNameFilter = [];
  employeeTableCrud = {
    edit : null,
    add: null,
  }
  public departmentsChart;
  public yearOfJoining;
  events: Array<string> = [];
  dataSource: Array<employeesTableDto>;
  selectedItemKeys: any[] = [];
  bulkOperationList: string[] = [];
  dateFilter: {
    fromDate: moment.Moment | string;
    toDate: moment.Moment | string;
  } = { fromDate: undefined, toDate: undefined };
  dataSourceDuplicate;
  public usrCompanyCurrency;
  public userDetails;
  toolTipText: string = '';
  toolTipTarget: any;
  noOfEmployees;
  helpSections;
  helperTopPosition = null;
  helper: string = "";
  isUserAdmin =false;
  isOptionApprover =false;
  searchOptions: Observable<string[]>;
  searchFormControl = new FormControl();
  totalSearchOptions = [];
  bulkActionValue = new FormControl();
  public selectedEmp;
  selectedEmployeeIds=[]
  plan: any;
  employeeDetails: any;
  myControl = new FormControl();
  selectedEmployee;
  filteredDatasource;
  filteredOptions: Observable<string[]>
  actualOptions = [];

  constructor(
    private router: Router,
    private AuthService: AuthenticationService,
    public permService: PermissionsService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private utilityService: UtilityService,
    public route: ActivatedRoute,
    private helperService: HelperService
  ) {
    this.customizeOptionTooltip = this.customizeOptionTooltip.bind(this);
    this.customizeHolderTooltip = this.customizeHolderTooltip.bind(this);
   }

   columnChooserClicked() {
    this.showColumnChooser();
   }

   close() {
      this.showDialogHrmsEmpList = false;
      this.visibleChangeHrmsEmpList.emit({showMdl: this.showDialogHrmsEmpList});
    }

   showColumnChooser() {
    this.dataGrid.instance.showColumnChooser();
   }

   fileExport(exportSelected = false) {
    this.dataGrid.instance.exportToExcel(exportSelected);
   }

   customizeOptionTooltip(arg) {
    let numberOfOptions = this.utilityService.formatCurrency(this.usrCompanyCurrency, arg.point.data['numberOfOptions']);
    return {
      text: numberOfOptions,
      fontColor: "#ffffff",
      color: "#000000"
    };
  }
 
getStatusColor(employeeData){
  const redColor = {
    "height": "9px", "width": "9px",
    "border-radius": "100%",
    "display": "inline-block", "background-color": "#F7917D" ,
    "margin-right": "-6px ",
  }
  let color;
  if (employeeData.employmentStatus != "Employed"){
    color = redColor;
  }
  // else{
  //   color = greenColor;
  // }
  return color
}

  downloadTemplate(){
    this.AuthService.getEmployeeTemplate().subscribe(res =>{
      this.ngxLoader.start();
      const url = res["s3link"];
      var link = document.createElement("a");
      link.href = url;
      link.download = "Employee_Template.xlsx";
      link.click();
      this.ngxLoader.stop();
    })
  }

  changeEmployee(employee){
    var data = this.dataSource.filter(emp => {
      return emp['employeeName'] == employee
    })
    this.filteredDatasource = data
  }

  clearFilter() {
    this.selectedEmployee = null
    this.filteredDatasource = this.dataSource
  }

  customizeHolderTooltip(arg) {
    let numberOfOptions = this.utilityService.formatCurrency(this.usrCompanyCurrency, arg.point.data['numberOfOptions']);
    return {
      text: numberOfOptions,
      fontColor: "#ffffff",
      color: "#000000"
    };
  }

  customizeHolderText(arg) {
    if (arg.value * 100 > 3) {
      return arg.percentText;
    }
  }

  searchEntered(e) {
    this.dataGrid.instance.searchByText(e);
  }

  dateSelected(source, event) {
    const filteredList = [];
    const dateFormat = "DD-MMM-YYYY";
    this.dataSource = JSON.parse(JSON.stringify(this.dataSourceDuplicate));
    let momentDate = moment(event.value);
    // let formattedDate = (momentDate.format(dateFormat))
    this.dateFilter[source] = momentDate;
    if (this.dateFilter.fromDate && this.dateFilter.toDate) {
      const datasource = this.dataSource;
      datasource.forEach((data) => {
        if (data) {
          if (data["dateOfJoin"]) {
            const dateOfJoin = moment(data["dateOfJoin"]);
            if (
              dateOfJoin.isSameOrAfter(this.dateFilter.fromDate) &&
              dateOfJoin.isSameOrBefore(this.dateFilter.toDate)
            ) {
              filteredList.push(data);
            }
          }
        }
      });
    } else if (this.dateFilter.fromDate && !this.dateFilter.toDate) {
      const datasource = this.dataSource;
      datasource.forEach((data) => {
        if (data) {
          if (data["dateOfJoin"]) {
            const dateOfJoin = moment(data["dateOfJoin"]);
            if (dateOfJoin.isSameOrAfter(this.dateFilter.fromDate)) {
              filteredList.push(data);
            }
          }
        }
      });
    } else if (!this.dateFilter.fromDate && this.dateFilter.toDate) {
      const datasource = this.dataSource;
      datasource.forEach((data) => {
        if (data) {
          if (data["dateOfJoin"]) {
            const dateOfJoin = moment(data["dateOfJoin"]);
            if (dateOfJoin.isSameOrBefore(this.dateFilter.toDate)) {
              filteredList.push(data);
            }
          }
        }
      });
    } else if (!this.dateFilter.fromDate && !this.dateFilter.toDate) {
      this.dataSource = JSON.parse(JSON.stringify(this.dataSourceDuplicate));
    }
    this.dataSource = filteredList;
  }

  clearDate() {
    this.dateFilter.fromDate = undefined;
    this.dateFilter.toDate = undefined;
    this.dataSource = this.dataSourceDuplicate;
  }

  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
    this.selectedEmployeeIds = []
    for(let i = 0; i < data.selectedRowsData.length; i++){
      if(!data.selectedRowsData[i]['isLoginProvided']){
        this.selectedEmployeeIds.push(data.selectedRowsData[i]['id']);
      }
    }
    if (this.selectedEmployeeIds.length) {
      if (!this.bulkOperationList.includes("Generate Credentials")) {
        this.bulkOperationList.push("Generate Credentials");
      }
    }
    if (data.selectedRowsData.length == 1) {
      this.selectedEmp = data.selectedRowsData[0];
    } else {
      this.selectedEmp = "";
    }
    if(this.selectedItemKeys.length < 1 || this.selectedEmployeeIds.length < 1){
      this.bulkActionValue.disable()
    }
    else{
      this.bulkActionValue.enable()
    }
  }

  goToEmpDetails(data) {
    this.ngxLoader.start();
    this.AuthService.getEmployeeDetails(data.id).subscribe(
      () => {
        this.router.navigate([
          "/landing/dashboard/options/employee/employeeDetails",
          data.id,
          { plan: this.plan },
        ]);
        // this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  setIsLoginProvidedFlag(ids: Number[]){
    if(typeof(ids) == 'object'){
      this.dataSource.forEach(data=>{
        if(ids.includes(data.id)){
          data.isLoginProvided = true
        }
      })
    }
  }

  generateCred(data) {
    if(typeof(data) == 'number'){
      data = [data]
    }
    this.ngxLoader.start();
    this.AuthService.getCredentials(data).subscribe(
      (res) => {
        // let blob = new Blob([JSON.stringify(res, null, 2)], {type: 'text/xml'});
        if(this.userDetails["company"]["isDownloadEnabled"]){
          var downloadURL = window.URL.createObjectURL(res);
          var link = document.createElement("a");
          link.href = downloadURL;
          link.download = "Credentials.txt";
          this.ngxLoader.stop();
          this.setIsLoginProvidedFlag(data)
          link.click();
          this.toastr.success("Credentials generated Successfully","Success");
          this.ngxLoader.stop();
        }
        else{
          this.ngxLoader.stop();
          this.setIsLoginProvidedFlag(data)
          this.toastr.success("Credentials generated Successfully","Success");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        var reader = new FileReader();
        reader.onloadend = (e) => {
          this.toastr.error(
            JSON.parse((<any>e.target).result)["reason"],
            "Error"
          );
        };
        reader.readAsText(e.error);
      }
    );
  }

  onCellClick(e) {
    if (e.row && e.columnIndex == 8) {
      if(this.selectedItemKeys.length > 1) {
        this.toastr.error("More than 1 row selected", "Error!");
        return
      }
      let selectedEmp = new Array(e.row.data)
      this.AuthService.importEmp(selectedEmp).subscribe(
        (res) => {
          if (res) {
            this.dataSource.map( ele => {
              if(ele['employeeId'] == e.key) {
                ele['isImported'] = true
              }
            })
            this.toastr.success(res["reason"], "Success");
            this.ngxLoader.stop();
          } else {
            this.ngxLoader.stop();
            this.toastr.error("Null response!.", "Failure!");
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      );
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.searchFormControl.value.length < 1) return [];
    return this.totalSearchOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filters(value))
    );
    this.bulkActionValue.disable()
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
      localStorage.setItem("comCur", this.usrCompanyCurrency);
    }
    else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.employeeTableCrud.add = this.permService.authorizeUser(['add-employee']);
    this.employeeTableCrud.edit = this.permService.authorizeUser(['edit-employee']);
    this.employeesOverview = new employeesOverviewModel();
    this.getEmployeesDashboardData();
    this.isUserAdmin = localStorage.getItem("userRole") == "Admin";
    this.searchOptions = this.searchFormControl.valueChanges.pipe(
      map((value) => this._filter(value))
    );
    this.isOptionApprover = localStorage.getItem("userRole") == "Options Approver";
    if(
      this.permService.authorizeUser(["emp-view", "!strict"]) &&
      !this.bulkOperationList.includes("Generate Credentials")
      ){
        this.bulkOperationList.push("Generate Credentials");
      }
  }

  private _filters(value: string=''): string[] {
    const filterValue = value && value.toLowerCase();

    return this.actualOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  bulkActionChanged(value) {
    if (value == "Generate Credentials") {
      this.generateCred(this.selectedEmployeeIds);
    }
    this.bulkActionValue.reset()
  }

  getDepartmentChartDetails(optionsDtls) {
    this.departmentsChart = [];
    optionsDtls.forEach(element => {
      let optionsData = {
        department: element["department"],
        numberOfEmployees: element["numberOfEmployees"]
      };
      this.departmentsChart.push(optionsData);
    });
    this.departmentsChart.sort(function(a,b){
      return b.numberOfEmployees - a.numberOfEmployees
    })
    this.departmentsChart= this.departmentsChart.slice(0,6)
  }

  getHolderChartDetails(holderDtls) {
    this.yearOfJoining = [];
    holderDtls.forEach(element => {
      let holdersData = {
        yearofJoining: element["yearofJoining"],
        numberOfEmployees: element["numberOfEmployees"],
      };
      this.yearOfJoining.push(holdersData);
    });
    this.yearOfJoining.sort(function(a,b){
      return b.numberOfEmployees - a.numberOfEmployees
    })
    this.yearOfJoining = this.yearOfJoining.slice(0,12)
  }
  getEmployeesDashboardData() {
    this.ngxLoader.start();
    this.AuthService.getHrmsEmpList().subscribe(res => {
          this.dataSource = JSON.parse(JSON.stringify(res))
          this.filteredDatasource = this.dataSource
          this.dataSource.forEach(object => {
            object['isImported'] = false;
          });
          this.actualOptions = this.dataSource.map(a=>a.employeeName).sort((a,b) => a.toLowerCase() > b.toLowerCase() ? 1:-1);
          let alphabetArray = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
          this.empList = new Array()
          this.empList = res;
          alphabetArray.forEach(element => {
              let employeesInAlphabet = [];
              this.empList.forEach(emp => {
                  if(emp.employeeName.startsWith(element)) {
                      employeesInAlphabet.push(emp.employeeId);
                  }
              });
              let valueArray = [];
              employeesInAlphabet.forEach((emp) => {
                  let valueObj = ['employeeId', '=', emp];
                  valueArray.push(valueObj);
                  valueArray.push("or");
              });
              let filterObj = {
                  text: element,
                  value: valueArray
              }
              if (valueArray.length > 0) {
                  this.empNameFilter.push(filterObj);
              }
          });
          this.ngxLoader.stop();
    }, (e) => {
      if (e.error.reason === "Login Token Expired.") {
        this.ngxLoader.stop();
        this.cacheService.clear();
        this.router.navigate(['']);
        return;
      } else {
        this.toastr.error(e.error.reason, "Failure!");
        this.ngxLoader.stop();
      }
    });
  }

  logEvent(eventName, e) {
     if (eventName == "RowRemoved") {
      let optionHolders = [];
      optionHolders.push(e.key);
      this.AuthService.deleteEmployeePlan(e.key).subscribe((response) => {
        if (response) {
          this.toastr.success("Record Deleted Successfully!", "Success!");
          this.getEmployeesDashboardData() 
        }
      }, (e) => {
        this.toastr.error(e.error.reason, "Failure!");
      })
      this.isEditingStarted = false;
    }
    else if (eventName == "RowInserted") {
        this.isEditingStarted = false;
      }
      else if (eventName == "InitNewRow") {
        this.ngxLoader.start();
        this.editMode = false;
        let allRows = e.component.getVisibleRows();  
        let rowIndex = e.component.getRowIndexByKey(e.key);  
        this.editRowData = allRows[rowIndex]; 
        document.getElementById("overlay").style.display = "block";
        this.ngxLoader.stop();
        this.isEditingStarted = true;

      }
      else if (eventName == "EditingStart") {
        this.showDialogEmp = true;
        this.ngxLoader.start();
        this.editMode = true;
        
        let allRows = e.component.getVisibleRows();  
        let rowIndex = e.component.getRowIndexByKey(e.key);  
        this.editRowData = allRows[rowIndex]; 
        this.employeeId= this.editRowData.data.id
        document.getElementById("overlay").style.display = "block";
        this.ngxLoader.stop();
        this.isEditingStarted = true
      }
  }

  hideHelp() {
    this.helper = "";
  }

  /** Show Contextual help **/
  showHelp(section: string) {
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);
    
    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "ManageEmployeesComponent",
      section
    );
    this.helper = section;
  }

  importAllEmp() {
    let selectedEmps = this.dataSource.filter(item => {
      return this.selectedItemKeys.includes(item['employeeId'])
    })
    this.AuthService.importEmp(selectedEmps).subscribe(
      (res) => {
        if (res) {
          this.dataSource.map(item => {
            if(this.selectedItemKeys.includes(item['employeeId'])) {
              item['isImported'] = true
            }
          })
          this.ngxLoader.stop();
          this.toastr.success(res["reason"], "Success");
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  getEventEmp(e) {
    this.showDialogEmp = false;
    this.getEmployeesDashboardData()
    document.getElementById("overlay").style.display = "none";
    
  }

}
