var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from '../authentication.service';
var FileConversionComponent = /** @class */ (function () {
    function FileConversionComponent(authService, ngxloader, toastr) {
        this.authService = authService;
        this.ngxloader = ngxloader;
        this.toastr = toastr;
        this.sourceFile = null;
        this.XMLfile = null;
    }
    FileConversionComponent.prototype.ngOnInit = function () {
    };
    FileConversionComponent.prototype.handleFileInput = function (fileInput) {
        this.sourceFile = fileInput.target['files'][0];
    };
    FileConversionComponent.prototype.handleXMLFileInput = function (fileInput) {
        this.XMLfile = fileInput.target['files'][0];
    };
    FileConversionComponent.prototype.handleEXCELFileInput = function (fileInput) {
        this.Excelfile = fileInput.target['files'][0];
    };
    FileConversionComponent.prototype.convertToXML = function () {
        var _this = this;
        if (this.sourceFile == null) {
            this.toastr.error("Please select an pdf file", "Error");
            return;
        }
        this.ngxloader.start();
        var formData = new FormData();
        formData.append('file', (new File([this.sourceFile], 'file.pdf', { type: 'multipart/form-data' })));
        this.authService.PDFtoXML(formData).subscribe(function (res) {
            var blob = new Blob([res.body], { type: 'application/zip' });
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "file.zip";
            _this.ngxloader.stop();
            link.click();
        }, function (e) { });
    };
    FileConversionComponent.prototype.convertToPDF = function () {
        var _this = this;
        if (this.Excelfile == null) {
            this.toastr.error("Please select an excel file", "Error");
            return;
        }
        this.ngxloader.start();
        var formData = new FormData();
        formData.append('file', (new File([this.Excelfile], 'file.xlsx', { type: 'multipart/form-data' })));
        this.authService.XMLtoPDF(formData).subscribe(function (res) {
            var blob = new Blob([res], { type: 'application/pdf' });
            var downloadURL = window.URL.createObjectURL(res);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "Output.pdf";
            _this.ngxloader.stop();
            link.click();
        }, function (e) { return __awaiter(_this, void 0, void 0, function () {
            var textPromise, text;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        textPromise = e.error.text();
                        return [4 /*yield*/, e.error.text()];
                    case 1:
                        text = _a.sent();
                        e.error.text().then(function (text) {
                            var error = JSON.parse(text);
                            _this.ngxloader.stop();
                            _this.toastr.error(error.resultCode, "Error");
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return FileConversionComponent;
}());
export { FileConversionComponent };
