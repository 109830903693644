import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../authentication.service";
import currencyToSymbolMap from "currency-symbol-map/map";
import { UtilityService } from "../../services/utilities.service";
import * as $AB from "jquery";
import { IfStmt } from "@angular/compiler";
import { DataService } from '../../services/new-round-notify.service';
import { HelperService } from '../../services/helper.service';
declare var $: any;
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from '../../services/cache-provider.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDeleteComponent } from "src/app/confirm-delete/confirm-delete.component";

@Component({
  selector: "app-new-investors-tab",
  templateUrl: "./new-investors-tab.component.html",
  styleUrls: ["./new-investors-tab.component.scss"]
})

export class NewInvestorsTabComponent implements OnInit {
  editNewInvestor: any;
  shareholdersData;
  outstandingConv = [];
  existingShareholderNamesForDropdown = [];
  finalPostRoundTotal = 0;
  totalAmtToBeInvested = 0;
  newInvActualPercentage = 0;
  newInvRequiredPercentage;
  newInvRequiredPercentageCopy;
  shareHolderType;
  groupNames;
  selectedUserIndexToEdit;
  saveInvestorProfile = [];
  totInvMet: any;
  countryList;
  currencyList;
  newRoundInfon;
  curConvInvAmt = 0;
  exchangeRate = 1;
  curncyInv;
  isValid = false;
  investorProfile;
  newGroupObj = {
    groupName: "",
    description: ""
  };
  companyCurrency;
  roundCurrency;
  newInvestors: any = [];
  preRoundTotalVal: any;
  actualPostRndPercent: any;
  actualPreRndPercent: any;
  clientActualPostRndPercent: number;
  totalPostPercentage: any;
  public isInvestmentMet: boolean = false;
  public isNewInvestorSectionValid: boolean;
  onLoading: boolean = true;
  public disableripple: boolean = true;
  rightToMaintainData;
  expanded = false;
  expandAll = false;
  isOpen = false;
  isNonzero: boolean = false;
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  isRoundNotSaved: boolean = false;
  public isCaptablehelp: boolean = false;
  public isNewInvtorhelp: boolean = false;
  public profilePicture = null;
  public authenticator = null;
  public croppedImage = null;
  public shouldShowDialog = false;
  public profilePicValid: boolean = false;
  newList = [];
  public fileName: any;
  public empImg: boolean = false;
  public content = "image/jpeg";
  public docPath: any;
  public groupdNameErr: boolean = false;
  public amountLeftToRaise;
  public remainingAmount:number = 0;
  public amountRaisedFromCurrentInvestors;
  public amountRaisedFromNewInvestors;
  public amountRaised;
  public roundSize;
  public currInvAmount;
  public isindividualValid: boolean = false;
  public sharePrice;
  public maxLength: number = 9;

  investorProfileInfo = {
    isLeadInvestor: false,
    investorName: "",
    investmentPercentage: 0,
    investmentAmount: 0,
    numberOfShares: 0,
    typeOfInvestor: "",
    groupName: {
      groupName: ""
    },
    registeredAddress: "",
    residentStatus: "",
    occupation: "",
    nationality: "",
    contactNumber: "",
    emailId: "",
    legalName: "",
    imageBase64String: null,
    imageUrl: null,
    imageName: "",
    contenttype: "",
    documentPath: ""
  };

  public errorFlags;
  mShareholderType: boolean = false;
  mGroup: boolean = false;
  mEmail: boolean = false;
  mlegalName: boolean = false;
  mNationality: boolean = false;
  mResidence: boolean = false;
  mvalid: boolean = false;
  public selectedResidentType = "India";
  public selectedCountry = "India";
  public groupMandatory: boolean = false;
  public insName:boolean = false;
  public insAmt:boolean = false;

  public showdigPrfle: boolean = false;
  public fromNewInv:boolean = true;
  public newInvestorProfile;
  public newInvestorProfileindex:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private utilityService: UtilityService,
    private dataService: DataService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private readonly dialog: MatDialog
    
  ) { }

/** Current Investor Captable increment decrement **/
  increment(investor, group) {
    if ((this.newInvRequiredPercentage).toFixed(4) <= 0) {
      this.toastr.error("Investment Met Already!", "Error");
      this.checkInvestmentMet(this.remainingAmount); 
      return;
    }
    let val = investor.postRound + (1 / 1000);
    val = val * 100;
    if ((this.newInvRequiredPercentage * 100 + investor.postRound * 100) < (val)) {
      this.toastr.error("Value greater than the remaining!", "Error");
      return;
    }

    investor.postRound = investor.postRound + 1 / 1000;
    if (group['groupName']) {
      this.shareholdersData.forEach(element => {
        if (element.shareholderGroups) {
          let parentPostTotal = 0;
          element.shareholderGroups.forEach(subelement => {
            if (subelement['groupName'] == group['groupName']) {
              let postRoundTotal = 0;
              subelement["shareholders"].forEach(individualElement => {
                postRoundTotal = postRoundTotal + individualElement["postRound"];
              });
              subelement["postRoundPercentageOfGroup"] = postRoundTotal;
            }
            parentPostTotal = parentPostTotal + subelement['postRoundPercentageOfGroup'];
          });
          element["postRoundPercentageOfType"] = parentPostTotal;
        }
      });
    } 
    else if (group['shareholderGroups'] == null) {
      this.shareholdersData.forEach(groupElement => {
        if (groupElement["shareholderType"] == group["shareholderType"]) {
          let postRoundTotal = 0;
          groupElement["shareholders"].forEach(individualElement => {
            postRoundTotal = postRoundTotal + individualElement["postRound"];
          });
          groupElement["postRoundPercentageOfType"] = postRoundTotal;
        }
      });
    }
    let investorObj = {
      name: investor.shareholder,
      rightToMaintainType: null,
      preRoundPercentage: investor.preRound,
      postRoundPercentage: investor.postRound
    };
    this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
      res => {
        group["shareholders"].forEach(element => {
          if (element["shareholder"] == res["name"]) {
            element["amountToBeInvested"] = res["investmentAmount"];
            if (group['groupName']) {
              this.shareholdersData.forEach(element => {
                if (element.shareholderGroups) {
                  let parentAmountTobeInvestedTotal = 0;
                  element.shareholderGroups.forEach(subelement => {
                    if (subelement['groupName'] == group['groupName']) {
                      let amountToBeInvestedTotal = 0;
                      subelement["shareholders"].forEach(individualElement => {
                        amountToBeInvestedTotal = amountToBeInvestedTotal + individualElement["amountToBeInvested"]
                      });
                      subelement["amountToBeInvestedOfGroup"] = amountToBeInvestedTotal;
                    }
                    parentAmountTobeInvestedTotal = parentAmountTobeInvestedTotal + subelement["amountToBeInvestedOfGroup"];
                  });
                  element["amountToBeInvestedOfType"] = parentAmountTobeInvestedTotal;
                  this.totalAmtToBeInvested = parentAmountTobeInvestedTotal;
                }
              });
            } 
            else if (group['shareholderGroups'] == null) {
              let totAmount = 0;
              this.shareholdersData.forEach(groupElement => {
                if (groupElement["shareholderType"] == group["shareholderType"]) {
                  let amountToBeInvestedTotal = 0;
                  groupElement["shareholders"].forEach(individualElement => {
                    amountToBeInvestedTotal = amountToBeInvestedTotal + individualElement["amountToBeInvested"];
                  });
                  groupElement["amountToBeInvestedOfType"] = amountToBeInvestedTotal;
                }
                totAmount = totAmount + groupElement["amountToBeInvestedOfType"];
              });
              this.totalAmtToBeInvested = totAmount;
            }
          }
        });
        let newInvestorTotal = 0;
        let newInvPostRoundTotal = 0;
        if(this.newInvestors.length > 0){
          this.newInvestors.forEach(investor => {
            if (typeof investor.investmentAmount === "string") {
              let amt = investor.investmentAmount.replace(/,/g, "") / 1;
              newInvestorTotal += amt;
              newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
            }
            else {
              let amt = investor.investmentAmount;
              newInvestorTotal += +amt;
              newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
            }
          });
        }
        this.remainingAmount = 0;
        this.remainingAmount = this.roundSize - (this.totalAmtToBeInvested + newInvestorTotal);
        let groupTotal = 0;
        this.shareholdersData.forEach(group => {
          groupTotal = groupTotal + group["postRoundPercentageOfType"];
        });
        this.finalPostRoundTotal = groupTotal + newInvPostRoundTotal;
        this.newInvRequiredPercentage = 1 - this.finalPostRoundTotal;
        this.checkInvestmentMet(this.remainingAmount); 
      },
      e => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }

  decrement(investor, group) {
    if (investor.postRound - 1 / 1000 < 0) {
      this.toastr.error("Value should not be in negative", "Error");
      investor.postRound = investor.postRound;
      return;
    }
    investor.postRound = investor.postRound - 1 / 1000;
    if (investor.postRound < investor.actualPostRound) {
      investor.postRound = investor.actualPostRound;
      this.toastr.error("The updated shareholder % should not below the current diluted %, the current diluted % is:" + (investor.actualPostRound * 100).toFixed(2), "Error");
      return;
    }
    if (group['groupName']) {
      this.shareholdersData.forEach(element => {
        if (element.shareholderGroups) {
          let parentPostTotal = 0;
          element.shareholderGroups.forEach(subelement => {
            if (subelement['groupName'] == group['groupName']) {
              let postRoundTotal = 0;
              subelement["shareholders"].forEach(individualElement => {
                postRoundTotal = postRoundTotal + individualElement["postRound"];
              });
              subelement["postRoundPercentageOfGroup"] = postRoundTotal;
            }
            parentPostTotal = parentPostTotal + subelement['postRoundPercentageOfGroup'];
          });
          element["postRoundPercentageOfType"] = parentPostTotal;
        }
      });
    } 
    else if (group['shareholderGroups'] == null) {
      this.shareholdersData.forEach(groupElement => {
        if (groupElement["shareholderType"] == group["shareholderType"]) {
          let postRoundTotal = 0;
          groupElement["shareholders"].forEach(individualElement => {
            postRoundTotal = postRoundTotal + individualElement["postRound"];
          });
          groupElement["postRoundPercentageOfType"] = postRoundTotal;
        }
      });
    }
    let investorObj = {
      name: investor.shareholder,
      rightToMaintainType: null,
      preRoundPercentage: investor.preRound,
      postRoundPercentage: investor.postRound
    };
    this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
      res => {
        group["shareholders"].forEach(element => {
          if (element["shareholder"] == res["name"]) {
            element["amountToBeInvested"] = res["investmentAmount"];
            if (group['groupName']) {
              this.shareholdersData.forEach(element => {
                if (element.shareholderGroups) {
                  let parentAmountTobeInvestedTotal = 0;
                  element.shareholderGroups.forEach(subelement => {
                    if (subelement['groupName'] == group['groupName']) {
                      let amountToBeInvestedTotal = 0;
                      subelement["shareholders"].forEach(individualElement => {
                        amountToBeInvestedTotal = amountToBeInvestedTotal + individualElement["amountToBeInvested"]
                      });
                      subelement["amountToBeInvestedOfGroup"] = amountToBeInvestedTotal;
                    }
                    parentAmountTobeInvestedTotal = parentAmountTobeInvestedTotal + subelement["amountToBeInvestedOfGroup"];
                  });
                  element["amountToBeInvestedOfType"] = parentAmountTobeInvestedTotal;
                  this.totalAmtToBeInvested = parentAmountTobeInvestedTotal;
                } else {

                }
              });
            } 
            else if (group['shareholderGroups'] == null) {
              let totAmount = 0;
              this.shareholdersData.forEach(groupElement => {
                if (groupElement["shareholderType"] == group["shareholderType"]) {
                  let amountToBeInvestedTotal = 0;
                  groupElement["shareholders"].forEach(individualElement => {
                    amountToBeInvestedTotal = amountToBeInvestedTotal + individualElement["amountToBeInvested"];
                  });
                  groupElement["amountToBeInvestedOfType"] = amountToBeInvestedTotal;
                }
                totAmount = totAmount + groupElement["amountToBeInvestedOfType"];
              });
              this.totalAmtToBeInvested = totAmount;
            }
          }
        });
        let newInvestorTotal = 0;
        let newInvPostRoundTotal = 0;
        if(this.newInvestors.length > 0){
          this.newInvestors.forEach(investor => {
            if (typeof investor.investmentAmount === "string") {
              let amt = investor.investmentAmount.replace(/,/g, "") / 1;
              newInvestorTotal += amt;
              newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
            }
            else {
              let amt = investor.investmentAmount;
              newInvestorTotal += +amt;
              newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
            }
          });
        }
        this.remainingAmount = 0;
        this.remainingAmount = this.roundSize - (this.totalAmtToBeInvested + newInvestorTotal);
        let groupTotal = 0;
        this.shareholdersData.forEach(group => {
          groupTotal = groupTotal + group["postRoundPercentageOfType"];
        });
        this.finalPostRoundTotal = groupTotal + newInvPostRoundTotal;
        this.newInvRequiredPercentage = 1 - this.finalPostRoundTotal;
        this.checkInvestmentMet(this.remainingAmount);
      },
      e => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }

  getAmtbyCustVal(investor, group, vall) {
    let val = vall / 100;
    if (!val) {
      this.toastr.error("Invalid input!", "Error");
      return;
    }

    if (this.newInvRequiredPercentage.toFixed(4) / 1 <= 0) {
      this.toastr.error("Investment Met Already!", "Error");
      return;
    }

    if (val < 0) {
      this.toastr.error("Value should not be in negative!", "Error");
      investor.postRound = investor.postRound;
      return;
    }

    if (val >= investor.actualPostRound && val <= (investor.postRound + this.newInvRequiredPercentage.toFixed(4) / 1)) {
      investor.postRound = val;
    }
    else {
      this.toastr.error("Value should not exceed than required!", "Error");
      investor.postRound = investor.postRound;
      return;
    }

    if (group['groupName']) {
      this.shareholdersData.forEach(element => {
        if (element.shareholderGroups) {
          let parentPostTotal = 0;
          element.shareholderGroups.forEach(subelement => {
            if (subelement['groupName'] == group['groupName']) {
              let postRoundTotal = 0;
              subelement["shareholders"].forEach(individualElement => {
                postRoundTotal = postRoundTotal + individualElement["postRound"];
              });
              subelement["postRoundPercentageOfGroup"] = postRoundTotal;
            }
            parentPostTotal = parentPostTotal + subelement['postRoundPercentageOfGroup'];
          });
          element["postRoundPercentageOfType"] = parentPostTotal;
        }
      });
    } 
    else if (group['shareholderGroups'] == null) {
      this.shareholdersData.forEach(groupElement => {
        if (groupElement["shareholderType"] == group["shareholderType"]) {
          let postRoundTotal = 0;
          groupElement["shareholders"].forEach(individualElement => {
            postRoundTotal = postRoundTotal + individualElement["postRound"];
          });
          groupElement["postRoundPercentageOfType"] = postRoundTotal;
        }
      });
    }
    let investorObj = {
      name: investor.shareholder,
      rightToMaintainType: null,
      preRoundPercentage: investor.preRound,
      postRoundPercentage: investor.postRound
    };
    this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
      res => {
        group["shareholders"].forEach(element => {
          if (element["shareholder"] == res["name"]) {
            element["amountToBeInvested"] = res["investmentAmount"];
            element["postRound"] = res['postRoundPercentage'];
            if (group['groupName']) {
              this.shareholdersData.forEach(element => {
                if (element.shareholderGroups) {
                  let parentAmountTobeInvestedTotal = 0;
                  element.shareholderGroups.forEach(subelement => {
                    if (subelement['groupName'] == group['groupName']) {
                      let amountToBeInvestedTotal = 0;
                      subelement["shareholders"].forEach(individualElement => {
                        amountToBeInvestedTotal = amountToBeInvestedTotal + individualElement["amountToBeInvested"];
                      });
                      subelement["amountToBeInvestedOfGroup"] = amountToBeInvestedTotal;
                    }
                    parentAmountTobeInvestedTotal = parentAmountTobeInvestedTotal + subelement["amountToBeInvestedOfGroup"];
                  });
                  element["amountToBeInvestedOfType"] = parentAmountTobeInvestedTotal;
                  this.totalAmtToBeInvested = parentAmountTobeInvestedTotal;
                }
              });
            } 
            else if (group['shareholderGroups'] == null) {
              let totAmount = 0;
              this.shareholdersData.forEach(groupElement => {
                if (groupElement["shareholderType"] == group["shareholderType"]) {
                  let amountToBeInvestedTotal = 0;
                  groupElement["shareholders"].forEach(individualElement => {
                    amountToBeInvestedTotal = amountToBeInvestedTotal + individualElement["amountToBeInvested"];
                  });
                  groupElement["amountToBeInvestedOfType"] = amountToBeInvestedTotal;
                }
                totAmount = totAmount + groupElement["amountToBeInvestedOfType"];
              });
              this.totalAmtToBeInvested = totAmount;
            }
          }
        });
        let newInvestorTotal = 0;
        let newInvPostRoundTotal = 0;
        if(this.newInvestors.length > 0){
          this.newInvestors.forEach(investor => {
            if (typeof investor.investmentAmount === "string") {
              let amt = investor.investmentAmount.replace(/,/g, "") / 1;
              newInvestorTotal += amt;
              newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
            }
            else {
              let amt = investor.investmentAmount;
              newInvestorTotal += +amt;
              newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
            }
          });
        }
        this.remainingAmount = 0;
        this.remainingAmount = this.roundSize - (this.totalAmtToBeInvested + newInvestorTotal);
        let groupTotal = 0;
        this.shareholdersData.forEach(group => {
          groupTotal = groupTotal + group["postRoundPercentageOfType"];
        });
        this.finalPostRoundTotal = groupTotal + newInvPostRoundTotal;
        this.newInvRequiredPercentage = 1 - this.finalPostRoundTotal;
        this.checkInvestmentMet(this.remainingAmount);
      },
      e => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }
/** Current Investor Captable increment decrement **/

/** New Investor increment decrement **/
  incrementNewInv(investor, i) {
    if ((this.newInvRequiredPercentage).toFixed(4) <= 0) {
      this.toastr.error("Investment Met Already!", "Error");
      return;
    }
    if((this.newInvRequiredPercentage * 100) < 0.10){
      this.toastr.error("Value will be greater than the Required!, Please adjust Investment Amount manually", "Error");
      return;
    }
    let val = investor.investmentPercentage + (1 / 1000);

    if ((val * 100).toFixed(2) > (this.newInvRequiredPercentage * 100).toFixed(2)) {
      if (((val * 100) - (this.newInvActualPercentage * 100)).toFixed(2) > (this.newInvRequiredPercentage * 100).toFixed(2)) {
        this.toastr.error("Entered Value is greater than the Required!", "Error");
        return;
      }
    }
    investor.investmentPercentage = investor.investmentPercentage + (1 / 1000);
    let totalActualPercentage = 0;
    this.newInvestors.forEach(element => {
      totalActualPercentage =
        totalActualPercentage + element.investmentPercentage;
    });

    this.newInvActualPercentage = totalActualPercentage;
    this.totalPostPercentage = this.newInvActualPercentage + this.finalPostRoundTotal;
    this.newInvRequiredPercentage = 1 - this.totalPostPercentage;

    let investorObj = {
      name: investor.shareholder,
      rightToMaintainType: null,
      preRoundPercentage: 0,
      postRoundPercentage: investor.investmentPercentage
    };

    this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
      res => {
        let re = res["investmentAmount"];
        investor.investmentAmount = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, re);
        investor.numberOfShares = res["numberOfShares"];
        this.getTotalAmountInvested(this.newInvestors);
      },
      e => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }
  getNameNewInv(investor,i){
    this.newInvestors[i].investorName = investor.investorName.replace(/ \t/g,'').replace(/ +(?= )/g,'').trim();
  }
  getAmtbyCustValNewInv(investor, i, val) {
    if (val === "") {
      this.toastr.error("Invalid input!", "Error");
      return;
    }
    let numVal = +val;
    if (numVal <= 0) {
      investor.postRound = investor.postRound;
      this.toastr.error("Value should not be in negative! or Zero", "Error");
      return;
    }
    let value = numVal / 100;
    this.newInvestors.forEach((element, index) => {
      if(i === index){
        element.investmentPercentage = 0;
      }
    });
    this.getTotalAmountInvested(this.newInvestors);
    if (value > this.newInvRequiredPercentage) {
      if (((value + this.newInvActualPercentage) * 100) > (this.newInvRequiredPercentage * 100)) {
        this.newInvestors[i].postRound = investor.postRound;
        this.toastr.error("Entered Value is greater than the Required!", "Error");
        return;
      }
    }
    if (this.newInvRequiredPercentage <= 0) {
      this.toastr.error("Investment Met Already!", "Error");
      return;
    }
    investor.investmentPercentage = value;

    let investorObj = {
      name: investor.shareholder,
      rightToMaintainType: null,
      preRoundPercentage: 0,
      postRoundPercentage: investor.investmentPercentage
    };

    this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
      res => {
        let re = res["investmentAmount"];
        investor.investmentAmount = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, re);
        investor.numberOfShares = res["numberOfShares"];
        this.getTotalAmountInvested(this.newInvestors);
      },
      e => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }

  decrementNewInv(investor, i) {
    if (investor.investmentPercentage - (1 / 1000) < 0) {
      investor.investmentPercentage = investor.investmentPercentage;
      this.toastr.error("Value should not be in negative!", "Error");
      return;
    }
    investor.investmentPercentage = investor.investmentPercentage - 1 / 1000;
    let totalActualPercentage = 0;
    this.newInvestors.forEach(element => {
      totalActualPercentage =
        totalActualPercentage + element.investmentPercentage;
    });
    this.newInvActualPercentage = totalActualPercentage;
    this.totalPostPercentage = this.newInvActualPercentage + this.finalPostRoundTotal;
    this.newInvRequiredPercentage = 1 - this.totalPostPercentage;

    let investorObj = {
      name: investor.shareholder,
      rightToMaintainType: null,
      preRoundPercentage: 0,
      postRoundPercentage: investor.investmentPercentage
    };

    this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
      res => {
        let re = res["investmentAmount"];
        investor.investmentAmount = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, re);
        investor.numberOfShares = res["numberOfShares"];
        this.getTotalAmountInvested(this.newInvestors);
      },
      e => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }
/** New Investor increment decrement **/

  /** Is new investors post round editable **/
  isNewInvestorEditable() {
    if (this.newInvRequiredPercentage) {
      if (this.newInvRequiredPercentage.toFixed(4) <= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  /** Check if Investment met **/
  checkInvestmentMet(val) {
    if (val > 0 || val < 0) {
      this.isInvestmentMet = false;
      return false;
    } 
    else {
      this.isInvestmentMet = true;
      return true;
    }
  }

  /** Edit new investor **/
  editNewInvestorRow(index) {
    this.editNewInvestor = index;
    this.isindividualValid = false;
  }

  amountNewInvCSV(investor, i, e){
    let rst;
    if(e) {
        // store current positions in variables
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
    }
    let amt = investor.investmentAmount;
    rst = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, amt);

    if(e) {
      if(e.target.value) {
          let CSVLength = rst.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = rst;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
              e.target.value = rst;
              if(start != 0 || end != 0){
                  let difference = valueLength - CSVLength;
                  start = start - difference;
                  end = end - difference;
              }
              else{
                  start = start;
                  end = end;
              }  
          }
          else {
              e.target.value = rst;
          }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
  }

  // to calculate remaining amount each time
  ramainAmountCheck(){
    let totAmt = 0;
    this.newInvestors.forEach(investor => {
      if (typeof investor.investmentAmount === "string") {
        let amt = investor.investmentAmount.replace(/,/g, "") / 1;
        totAmt += amt;
      }
      else {
        let amt = investor.investmentAmount;
        totAmt += amt;
      }
    });
    this.totInvMet = +totAmt;
    this.remainingAmount = 0;
    this.remainingAmount = this.roundSize - (this.amountRaisedFromCurrentInvestors + this.totInvMet);
    return this.remainingAmount;
  }

  getPercentByAmountNewInv(investor, i) {
    let investorObj = {
      "name": investor.shareholder,
      "rightToMaintainType": null,
      "preRoundPercentage": 0,
      "postRoundPercentage": null,
      "investmentAmount": investor.investmentAmount.replace(/,/g, "") / 1
    };

    let remainingAmount = this.ramainAmountCheck();
    let remains = Math.sign(remainingAmount);

    if((remains === 1 || remains === 0) && investorObj.investmentAmount >= this.sharePrice){
      this.authService.getExistingHolderInvAmtRound(investorObj).subscribe(
        (res) => {
          if(res['investmentAmount'] > investorObj.investmentAmount || res['investmentAmount'] < investorObj.investmentAmount){
            this.toastr.info("Minor adjustments in investment amount made to make it a multiple of share price");
          }
          let investmentAmount = res['investmentAmount'];
          investor.investmentPercentage = res['postRoundPercentage'];
          investor.numberOfShares = res["numberOfShares"];
          this.newInvestors[i].investmentAmount = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency,investmentAmount);
          this.getTotalAmountInvested(this.newInvestors);
        },
        (e) => {
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
      );
    }
    else if((remains === 1 || remains === 0) && investorObj.investmentAmount < this.sharePrice){
      this.newInvestors[i].investmentAmount = 0;
      this.remainingAmount = this.ramainAmountCheck();
      this.toastr.error("Entered amount lesser than One Share Price!", "Error");
    }
    else{
      this.newInvestors[i].investmentAmount = 0;
      this.remainingAmount = this.ramainAmountCheck();
      this.toastr.error("Entered amount exceeded than Remaining investment!", "Error");
    }
  }

  /** Calculate Total Investment of New investor **/
  getTotalAmountInvested(newInv) {
    let totAmt = 0;
    let newInvPostRoundTotal = 0;
    if (newInv) {
      this.newInvestors.forEach(investor => {
        if (typeof investor.investmentAmount === "string") {
          let amt = investor.investmentAmount.replace(/,/g, "") / 1;
          totAmt += amt;
          newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
        }
        else {
          let amt = investor.investmentAmount;
          totAmt += +amt;
          newInvPostRoundTotal = newInvPostRoundTotal + investor['investmentPercentage'];
        }
      });
    }
    this.totInvMet = +totAmt;
    this.remainingAmount = 0;
    this.newInvRequiredPercentage = 0;
    this.newInvActualPercentage = 0;
    this.remainingAmount = this.roundSize - (this.amountRaisedFromCurrentInvestors + this.totInvMet);
    this.newInvActualPercentage = +newInvPostRoundTotal.toFixed(4);
    this.totalPostPercentage = this.newInvActualPercentage + this.finalPostRoundTotal;
    this.newInvRequiredPercentage = 1 - this.totalPostPercentage;
    this.checkInvestmentMet(this.remainingAmount);
  }

  fieldEdited($event) {
    this.isRoundNotSaved = true;
  }

  /** Calculate Total Investment of New investor **/
  getBasicDetails() {
    this.ngxLoader.start();
    this.authService.getShareHoldersNewInvestor().subscribe((response) => {
      if (response) {
        this.rightToMaintainData = response['rightToMaintainShareholders'];
        this.shareholdersData = response['existingShareholders'];
        this.companyCurrency = response['companyCurrency'];
        this.roundCurrency = response['currencyForRound'];
        this.exchangeRate = response['exchangeRate'];
        this.newInvestors = response['newInvestors'];
        this.amountLeftToRaise = response['amountLeftToRaise'];
        this.amountRaisedFromCurrentInvestors = response['amountRaisedFromCurrentInvestors'];
        this.amountRaisedFromNewInvestors = response['amountRaisedFromNewInvestors'];
        this.amountRaised = response['amountRaised'];
        this.roundSize = response['roundSize'];
        this.sharePrice = response['pricePerShare'];
        //For Basic Details
        let basicData = {
          isBasicDetailsFilled: true,
          roundName: response['roundName'],
          roundSize: response['roundSize'],
          roundValuation: response['roundValuation'],
          amountRaised: response['amountRaised'],
          amountLeftToRaise: response['amountLeftToRaise'],
          leadInvestorName: response['leadInvestorName'],
          sharePrice: response['pricePerShare'],
          roundCurrency: response['currencyForRound'],
          isRoundNotSaved: this.isRoundNotSaved,
        }
        this.dataService.updateBasicData(basicData);

        let groupTotal = 0;
        let grpPreTot = 0;
        let groupAmtTotal = 0;
        this.shareholdersData.forEach(group => {
          groupTotal = groupTotal + group['postRoundPercentageOfType'];
          grpPreTot = grpPreTot + group['preRoundPercentageOfType'];
          groupAmtTotal = groupAmtTotal + group['amountToBeInvestedOfType'];
        });
        this.clientActualPostRndPercent = groupTotal;
        this.actualPreRndPercent = response['totalPreRoundPercentage'];
        this.actualPostRndPercent = response['totalPostRoundPercentage'];
        this.preRoundTotalVal = response['totalPreRoundPercentage'];
        this.finalPostRoundTotal = response['totalPostRoundPercentage'];
        this.totalAmtToBeInvested = groupAmtTotal;
        this.errorFlags = response['errorFlags'];
        this.dataService.updateData(this.errorFlags);
        this.isNewInvestorSectionValid = this.errorFlags['isNewInvestorSectionValid'];
        this.newInvestors.forEach(element => {
          let re = element.investmentAmount;
          element['investmentAmount'] = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, re);
        });
        this.getTotalAmountInvested(this.newInvestors);
        this.ngxLoader.stop();
        this.getDataFromCache()
      } else {
        this.ngxLoader.stop();
        this.getDataFromCache()
      }
    }, (e) => {
      this.ngxLoader.stop();
      this.getDataFromCache()
      this.toastr.error("Error: " + e.error.reason, "Error");
    });
  }

  // getTotalValueTble(value) {
  //   if (this.roundCurrency === this.companyCurrency) {
  //     return this.utilityService.convertCurrencyFormat(value);
  //   }
  //   else {
  //     return this.utilityService.convertRoundFormat(value);
  //   }
  // }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.formatCurrencyDeci(this.roundCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  gropSelectionChange(val) {
    if (val === "newGroup") {
      $(".new-group").modal("show");
    } else {
      this.mGroup = false;
      this.investorProfileInfo.groupName = { groupName: val };
    }
  }

  // Share holder type change event
  shareHoderTypeChange(shrHldrType) {
    this.mGroup = false;
    this.groupMandatory = false;
    let groupName = this.investorProfileInfo.groupName['groupName'];
    let shareholderType = shrHldrType;
    this.mShareholderType = false;
    if ((shareholderType === "VC" ||
      shareholderType === "AngelNetwork" ||
      shareholderType === "PE") && groupName === "") {
      this.groupMandatory = true;
      this.mGroup = true;
    }
    else {
      this.mGroup = false;
      this.groupMandatory = false;
    }
  }

  emailValid(email, errors) {
    if (email != "") {
      this.mEmail = false;
      if (errors != null || errors != undefined) {
        this.mEmail = true;
      }
    }
  }

  legalNameChange(legalName) {
    if (legalName != "") {
      this.mlegalName = false;
    }
  }

  // Currency Selection
  selectedCurrency(curncy) {
    this.curncyInv = curncy;
  }

  getPercent(postVal) {
    return (postVal * 100).toFixed(4);
  }

  // Exchange Rate Calculation
  exchangeRateChangeEvent(exRte) {
  }

  showCurrency(amount) {
    return this.utilityService.calculateCurrency(this.roundCurrency, this.companyCurrency, this.exchangeRate, amount)
  }

  // go to instrument
  goToInstrument() {
    this.ngxLoader.start();
    this.isRoundNotSaved = false;
    let investorDetails = [];
    this.validatiorMethod(this.newInvestors);
    if (this.isValid) {
      this.newInvestors.forEach(element => {
        if (element.investorName) {
          element.investorName = element.investorName.replace(/ \t/g,'').replace(/ +(?= )/g,'').trim(); 
        }
        if (typeof element.investmentAmount === "string") {
          element.investmentAmount = element.investmentAmount.replace(/,/g, "") / 1;
        }
        else {
          element.investmentAmount = element.investmentAmount;
        }

        investorDetails.push(element);
        

      });
    }
    this.shareholdersData.forEach(group => {
      if (group['shareholders']) {
        group['shareholders'].forEach(holder => {
          let shmt = holder['amountToBeInvested'];

          if (holder['amountToBeInvested'] > 0) {
            let investorObj = {
              "investorName": holder['shareholder'],
              "investmentAmount": holder['amountToBeInvested'],
              "investmentPercentage": null
            }
            investorDetails.push(investorObj);
          }
        });
      }
      if (group['shareholderGroups']) {
        group['shareholderGroups'].forEach(element => {
          element['shareholders'].forEach(holder => {
            if (holder['amountToBeInvested'] > 0) {
              let investorObj = {
                "investorName": holder['shareholder'],
                "investmentAmount": holder['amountToBeInvested'],
                "investmentPercentage": null
              }
              investorDetails.push(investorObj);
            }
          });
        });
      }
    });

    if (this.newInvestors.length > 0) {
      if (this.isValid) {
        this.authService.saveNewShareHolderInfo(investorDetails).subscribe(response => {
          if (response) {
            this.ngxLoader.stop();
            this.removeDataFromCache()
            this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "instrument"]);
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Null Response", "Error");
          }
        },
          e => {
            this.ngxLoader.stop();
            this.toastr.error("Error: " + e.error.reason, "Error");
          });
      }
      else {
        this.ngxLoader.stop();
        // this.toastr.error( "Please enter profile information ..","Error");
      }
    }
    else if (this.rightToMaintainData.length > 0) {
      this.authService.saveNewShareHolderInfo(investorDetails).subscribe(response => {
        if (response) {
          this.ngxLoader.stop();
          this.removeDataFromCache()
          this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "instrument"]);
        }
        else {
          this.ngxLoader.stop();
          this.toastr.error("Null Response", "Error");
        }
      },
        e => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e.error.reason, "Error");
        });
    }
    else {
      this.ngxLoader.stop();
      this.toastr.error("You already skipped existing investors..,Please add New investor", "Error");
    }
  }

  //Skip
  skip() {
    if (this.rightToMaintainData.length > 0) {
      this.newInvestors = []
      this.goToInstrument();
    }
    else if (this.rightToMaintainData.length === 0 && this.newInvestors.length === 0) {
      this.toastr.error("You already skipped existing investor investment.. please add new investment...!", "Error");
    }
    else {
      this.toastr.error("Can't skip., Please fill new investor details and click Next...", "Error");
    }
  }

  //Validation Method
  validatiorMethod(data) {
    this.utilityService.formValidation();
    if (data.length >= 1) {
      data.forEach(element => {
        if (element.investorName === "" ||
          element.investmentAmount === 0 ||
          element.numberOfShares === 0 ||
          element.typeOfInvestor === "" ||element.typeOfInvestor === null ||
          element.legalName === "" ||element.legalName === null) {
          this.isValid = false;
          if (element.investorName === "") {
            this.insName = true;
            this.toastr.error("Please enter Investor Name..", "Error");
          }
          else if (element.investmentAmount === 0) {
            this.insAmt = true;
            this.toastr.error("Please enter Investment Amount..", "Error");
          }
          else if ((element.legalName === "" ||element.legalName === null )|| 
                  (element.typeOfInvestor === "" || element.typeOfInvestor === null)) {
            this.toastr.error("Please enter " + element.investorName + "  profile information ..", "Error");
          }
        }
        else {
          this.isValid = true;
        }
      });
    }
  }

  // add new investor
  addNewInvestor() {
    if (this.newInvestors.length > 0) {
      if (this.newInvestors[this.newInvestors.length - 1]['investorName'] === "" ||
        this.newInvestors[this.newInvestors.length - 1]['postRound'] === 0 ||
        this.newInvestors[this.newInvestors.length - 1]['amountInvested'] === 0) {
        this.toastr.error("Please fill the previous investor details!", "Failure");
        return;
      }
    }
    let remains = Math.sign(this.remainingAmount);
    if(remains === -1 || remains === 0){
        this.toastr.error("Investment met, can not add more investors.", "Failure");
        return;
    }

    let newInvestorsObj = {
      isLeadInvestor: false,
      investorName: "",
      investmentPercentage: 0,
      investmentAmount: 0,
      numberOfShares: 0,
      typeOfInvestor: "",
      groupName: {
        groupName: ""
      },
      registeredAddress: "",
      residentStatus: "",
      occupation: "",
      nationality: "",
      contactNumber: "",
      emailId: "",
      legalName: "",
      imageBase64String: null,
      imageName: "",
      contenttype: "",
      documentPath: {},
      imageUrl: null
    };

    newInvestorsObj.residentStatus = this.selectedResidentType;
    newInvestorsObj.nationality = this.selectedCountry;
    this.newInvestors.push(newInvestorsObj);
    this.editNewInvestor = this.newInvestors.length - 1;
    this.getTotalAmountInvested(this.newInvestors);
    this.isindividualValid = false;
  }

  getSharholderGroupInfo() {
    this.authService.getNwInstrShrHldrGroupInfo().subscribe(response => {
      this.shareHolderType = response["typeOfShareholder"];
      this.groupNames = response["groupName"];
    }, (e) => {
      this.toastr.error("Error: " + e.error.reason, "Error");
    });
  }

  // editProfile(i) {
  //   this.selectedUserIndexToEdit = i;
  //   if(this.newInvestors[i].residentStatus == null){
  //     this.newInvestors[i].residentStatus = this.selectedCountry;
  //   }
  //   if(this.newInvestors[i].nationality == null){
  //     this.newInvestors[i].nationality = this.selectedCountry;
  //   }
  //   this.investorProfileInfo = this.newInvestors[i];
  // }

  editInvestorProfile(i, investor){
    this.newInvestorProfile = investor;
    this.newInvestorProfileindex = i;
    this.fromNewInv = true;
    this.showdigPrfle = true;
    document.getElementById("overlay").style.display = "block";
  }

  getInvestorProfileInfo(e) {
    if(e.profileInfo){
      let index = e.index;
      this.newInvestors[index]['contactNumber']  = e.profileInfo['contactNumber'];
      this.newInvestors[index]['contenttype']  = e.profileInfo['contenttype'];
      this.newInvestors[index]['corporateAddress']  = e.profileInfo['corporateAddress'];
      this.newInvestors[index]['documentPath']  = e.profileInfo['documentPath'];
      this.newInvestors[index]['emailId']  = e.profileInfo['emailId'];
      this.newInvestors[index]['groupName']  = e.profileInfo['groupName'];
      this.newInvestors[index]['imageBase64String']  = e.profileInfo['imageBase64String'];
      this.newInvestors[index]['imageName']  = e.profileInfo['imageName'];
      this.newInvestors[index]['imageUrl']  = e.profileInfo['imageUrl'];
      this.newInvestors[index]['legalName']  = e.profileInfo['legalName'];
      this.newInvestors[index]['nationality']  = e.profileInfo['nationality'];
      this.newInvestors[index]['registeredAddress']  = e.profileInfo['registeredAddress'];
      this.newInvestors[index]['occupation']  = e.profileInfo['occupation'];
      this.newInvestors[index]['residentStatus']  = e.profileInfo['residentStatus'];
      this.newInvestors[index]['typeOfInvestor']  = e.profileInfo['typeOfShareholder'];
      this.newInvestors[index]['shareholderSubType'] = e.profileInfo['shareholderSubType'];
      this.newInvestors[index]['isRelativeOfDirector'] = e.profileInfo['isRelativeOfDirector'];
      this.newInvestors[index]['dpId'] = e.profileInfo['dpId'];
      this.newInvestors[index]['clientId'] = e.profileInfo['clientId'];
      this.newInvestors[index]['depositoryName'] = e.profileInfo['depositoryName'];
      this.newInvestors[index]['isPromoter'] = e.profileInfo['isPromoter'];
      this.showdigPrfle = e.showMdl;
      this.fromNewInv = e.fromNewInv;
      document.getElementById("overlay").style.display = "none";
    }
    else{
      this.showdigPrfle = e.showMdl;
      this.fromNewInv = e.fromNewInv;
      document.getElementById("overlay").style.display = "none";
    }
  }

  validatorMethod(data) {
    let groupname
    if(data.groupName != null || data.groupName != undefined){
      groupname = data.groupName['groupName'];
    }
    if (this.groupMandatory) {
      if (!data.emailId || !data.typeOfInvestor || !data.legalName || !groupname || !data.residentStatus || !data.nationality) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      if (!data.emailId || !data.typeOfInvestor || !data.legalName || !data.residentStatus || !data.nationality) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  saveInvestorsProfile() {
    this.mvalid = this.validatorMethod(this.investorProfileInfo);
    let groupname;
    if(this.investorProfileInfo.groupName != null || this.investorProfileInfo.groupName != undefined){
      groupname = this.investorProfileInfo.groupName['groupName'];
    }
    if (!this.mvalid) {
      if (this.investorProfileInfo.legalName === "") {
        this.mlegalName = true;
      }
      if (this.investorProfileInfo.typeOfInvestor === "") {
        this.mShareholderType = true;
      }
      if (this.investorProfileInfo.emailId === "") {
        this.mEmail = true;
      }
      if (this.investorProfileInfo.residentStatus === "") {
        this.mResidence = true;
      }
      if (this.investorProfileInfo.nationality === "") {
        this.mNationality = true;
      }
      if (this.investorProfileInfo.typeOfInvestor === "VC" ||
          this.investorProfileInfo.typeOfInvestor === "AngelNetwork" ||
          this.investorProfileInfo.typeOfInvestor === "PE") {
        if (groupname === undefined || groupname === ""  || groupname === null) {
          this.toastr.error("Error: Please select group", "Error");
          this.mGroup = true;
        }
      }
      else {
        this.mGroup = false;
      }
      return;
    }
    else {
      this.mlegalName = false;
      this.mEmail = false;
      this.mGroup = false;
      this.mNationality = false;
      this.mResidence = false;
      this.mShareholderType = false;
      $('#profileModal').modal('hide');
    }
  }

  // Create new Group
  createNewGroup() {
    this.ngxLoader.start();
    if (this.newGroupObj.groupName == '') {
      this.groupdNameErr = true;
      this.ngxLoader.stop();
    }
    else {
      this.authService.saveNewGroup(this.newGroupObj).subscribe(response => {
        if (response) {
          this.ngxLoader.stop();
          this.toastr.success("Success: " + response['groupName'] + "New Group created successfully");
          this.getSharholderGroupInfo();
          this.gropSelectionChange(response['groupName']);
          $('#groupModal').modal('hide');
        }
      }, (e) => {
        this.ngxLoader.stop();
        this.gropSelectionChange(this.newGroupObj.groupName);
        this.toastr.error("Error: " + e.error.reason, "Error");
      });
    }
  }

  //Currency Convertion modal
  openCurrencyConv(amt) {
    this.curConvInvAmt = amt;
  }

  getEvent() {
    this.shouldShowDialog = false;
    document.getElementById("overlay").style.display = "none";
  }

  leadInvestor(index) {
    for (var i = 0; i < this.newInvestors.length; i++) {
      if (i == index) {
        this.newInvestors[i]["isLeadInvestor"] = true;
      } else {
        this.newInvestors[i]["isLeadInvestor"] = false;
      }
    }
  }

  /** Delete New investor **/
  deleteNewInvestor(index) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Delete?" , text:"Are you sure you want to Delete ?"},
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
    this.newInvestors.splice(index, 1);
    this.cacheService.set('investorListDetail', this.newInvestors);
    let newInvPostRoundTotal = 0;
    if (this.newInvestors.length > 0) {
      this.newInvestors.forEach(investor => {
        newInvPostRoundTotal = newInvPostRoundTotal + investor['postRoundPercentage'];
      });
      this.newInvActualPercentage = newInvPostRoundTotal;
      this.insAmt = false;
      this.insName = false;
    } else {
      this.newInvActualPercentage = newInvPostRoundTotal;
      this.insAmt = false;
      this.insName = false;
    }

    this.totalPostPercentage = this.newInvActualPercentage + this.finalPostRoundTotal;
    this.newInvRequiredPercentage = 1 - this.totalPostPercentage;
    this.getTotalAmountInvested(this.newInvestors);
    this.ngxLoader.stop();

      }
    });
  }

  //Refresh function for new investor
  refreshNewInvestor() {
    this.authService.roundCreationrefreshNewInv().subscribe((response) => {
      if (response) {
        this.newInvestors = response['newInvestors'];
        this.amountRaisedFromNewInvestors = response['amountRaisedFromNewInvestors'];
        this.amountRaisedFromCurrentInvestors = response['amountRaisedFromCurrentInvestors'];
        this.roundSize = response['roundSize'];
        this.newInvestors.forEach(element => {
          let re = element.investmentAmount;
          element['investmentAmount'] = this.utilityService.inputCSVformatCurrencyRound(this.roundCurrency, re);
        });
        this.isNewInvestorSectionValid = true;
        this.remainingAmount = 0;
        this.remainingAmount = this.roundSize - ( this.amountRaisedFromCurrentInvestors +  this.amountRaisedFromNewInvestors);
        this.checkInvestmentMet(this.remainingAmount); 
      } else {
        this.toastr.error("Error: Response as null", "Error");
      }
    }, (e) => {
    })
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  ngOnInit() {
    const { getCode, getNames } = require("country-list");
    this.countryList = getNames();
    this.getBasicDetails();
    this.getSharholderGroupInfo();
    this.newRoundInfon = JSON.parse(this.cacheService.get('newRoundInfo'));
  }

  getDataFromCache() {
    let cachedShareHldrsData = this.cacheService.get('newInvestorsDetail')
    if (cachedShareHldrsData) {
      this.newInvestors = JSON.parse(cachedShareHldrsData)
    }
  }

  removeDataFromCache() {
    let cachedShareHldrsData = this.cacheService.get('newInvestorsDetail')
    if (cachedShareHldrsData) {
      this.cacheService.remove('newInvestorsDetail')
    }
  }

  goToCurrentInvestor() {
    if (this.newInvestors && this.newInvestors.length) {
      this.cacheService.set('newInvestorsDetail', JSON.stringify(this.newInvestors))
    }

    this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "currentInvestors"]);
  }

  expandAllItem(val) {
    if (this.isOpen == false) {
      this.shareholdersData.forEach(list => {
        list.expandAll = true;
        list.expanded = true;
        if (list.amountToBeInvestedOfType > 0) {
          list.amountToBeInvestedForFlagShowing = 0;
        }
        if (list.shareholderGroups != null) {
          list.shareholderGroups.forEach(groups => {
            if (groups.amountToBeInvestedOfGroup > 0) {
              groups.amountToBeInvestedForFlagShowing = 0;
            }
            groups.expandAll = true;
            groups.expanded = true;
          })
        }
        this.isOpen = true;
      });
    }
    else if (this.isOpen == true) {
      this.shareholdersData.forEach(list => {
        list.expandAll = false;
        list.expanded = false;
        if (list.amountToBeInvestedOfType > 0) {
          list.amountToBeInvestedForFlagShowing = list.amountToBeInvestedOfType;
        }
        if (list.shareholderGroups != null) {
          list.shareholderGroups.forEach(groups => {
            if (groups.amountToBeInvestedOfGroup > 0) {
              groups.amountToBeInvestedForFlagShowing = groups.amountToBeInvestedOfGroup;
            }
            groups.expandAll = false;
            groups.expanded = false;
          })
        }
        this.isOpen = false;
      });
    }
  }

  groupExpanded(i) {
    this.shareholdersData[i].expanded = true;
    this.shareholdersData[i].expandAll = true;
    this.shareholdersData[i].amountToBeInvestedForFlagShowing = 0;
  }

  groupCollapsed(i) {
    this.shareholdersData[i].expanded = false;
    this.shareholdersData[i].expandAll = false;
    this.shareholdersData[i].amountToBeInvestedForFlagShowing = this.shareholdersData[i].amountToBeInvestedOfType;
  }

  individualGroupExpanded(i, j) {
    this.shareholdersData[i].shareholderGroups[j].expanded = true;
    this.shareholdersData[i].shareholderGroups[j].expandAll = true;
    this.shareholdersData[i].shareholderGroups[j].amountToBeInvestedForFlagShowing = 0;
  }
  individualGroupCollapsed(i, j) {
    this.shareholdersData[i].shareholderGroups[j].expanded = false;
    this.shareholdersData[i].shareholderGroups[j].expandAll = false;
    this.shareholdersData[i].shareholderGroups[j].amountToBeInvestedForFlagShowing = this.shareholdersData[i].shareholderGroups[j].amountToBeInvestedOfGroup;
  }

  // help section show
  showHelp(section) {
    if(section == this.helper){
        return;
    }
    const targetElement = document.getElementById(section);
    if(targetElement != null){
        this.helperTopPosition = targetElement.offsetTop;
    }
    
    this.helpSections = this.helperService.getHelpContent(
        "NewInvestorsTabComponent",
        section
    );
    this.helper = section;
  }

  // help section hide
  hideHelp() {
    this.helper = "";
  }

  // input field olny number and comma
  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
        return false;
    }
    else {
        return true;
    }
  }

  onChangeCountrySelect(event) {
    this.investorProfileInfo.nationality = event;
  }

  onChangeResidentSelect(event) {
    this.investorProfileInfo.residentStatus = event;
  }

  openUploadPicturePopup() {
    this.shouldShowDialog = true;
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });

  }

  onImageSaved(imageDetails) {
    this.shouldShowDialog = false;
    this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
    this.croppedImage = imageDetails.croppedImage;
    this.investorProfileInfo.imageBase64String = this.croppedImage;
    this.investorProfileInfo.imageName = imageDetails.fileName;
    this.investorProfileInfo.contenttype = this.content;
    this.croppedImage = null;
  }

  changeCroppedImage() {
    this.investorProfileInfo.imageBase64String = null;
    this.investorProfileInfo.imageUrl = null;
  }

  async uploadAvatarImage(event) {
    this.croppedImage = event.target.src;
    let tempImage = event.target.src;
    this.investorProfileInfo.imageName = tempImage.split('/').pop();
    this.investorProfileInfo.contenttype = this.content;
    this.profilePicture = await (fetch(tempImage).then(res => res.blob()));
    this.handleFileSelect(this.profilePicture);
  }

  handleFileSelect(evt: any) {
    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(evt);
  }

  handleReaderLoaded(e) {
    this.croppedImage = 'data:image/png;base64,' + btoa(e.target.result);
    this.investorProfileInfo.imageBase64String = this.croppedImage;
  }

  individualSave(index){
    this.utilityService.formValidation();
    this.newInvestors.forEach((element, i )=> {
      if(i === index){
        if (element.investorName === "" ||
          element.investmentAmount === 0 ||
          element.numberOfShares === 0 ||
          element.typeOfInvestor === "" ||
          element.groupName === "" ||
          element.legalName === "") {
          this.isindividualValid = false;
          if (element.investorName === "") {
            this.insName = true;
            this.toastr.error("Please enter Investor Name..", "Error");
          }
          else if (element.investmentAmount === 0) {
            this.insAmt = true;
            this.toastr.error("Please enter Investment Amount..", "Error");
          }
          else if ( element.legalName === "" || element.typeOfInvestor === "" || element.groupName === "") {
            this.toastr.error("Please enter " + element.investorName + "  profile information ..", "Error");
            return;
          }
        }
        else {
          this.isindividualValid = true;
        }
      }
    });
  }

  // actual percentage
  changeActualPercentage(val){
    let value = val*100;
    let va = +value.toFixed(2);
    // let res = Math.floor(va);
    return va;
  }

  maxNumber(val){
    if (val.length > this.maxLength) {
      this.investorProfileInfo.contactNumber = val.slice(0, this.maxLength)
    }
  }
 
  close(){
    this.mlegalName = false;
    this.mEmail = false;
    this.mGroup = false;
    this.mNationality = false;
    this.mResidence = false;
    this.mShareholderType = false;
  }

}