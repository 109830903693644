import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { CustomCurrencyPipe } from "../../pipes/currency-pipe";
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utilities.service';
import { CacheProviderService } from '../../services/cache-provider.service';
import { ColumnList } from "src/app/dialogues/dev-ex-cap-table/columns-list";
import { HttpResponse } from "@angular/common/http";
import { ContextVariable } from "src/app/task-manager/models/context-variable";
import { DocumentAssemblerService } from "src/app/services/document-assembler.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-round-details",
  templateUrl: "./round-details.component.html",
  styleUrls: ["./round-details.component.scss"]
})

export class RoundDetailsComponent implements OnInit {
  selectedRoundName;
  roundInformation;
  detailsTableInfo;
  columnsList: ColumnList;
  roundNameList;
  invstorNmeLst;
  selectedInvestor = "";
  showOnlyInvestor = false;
  showOnlyInvestorInfo;
  investorsTotAmt;
  totalNoOfShares;
  investorChart;
  isChartView = true;
  currencyForRound;
  companyCurrency;
  expanded = false;
  expandAll = false;
  isOpen = false;
  public userDetails;
  public usrCompanyCurrency = "";
  public roundOverview: any;
  public isCreateRound: boolean = false;
  public disableripple: boolean = true;
  public shouldShowDialog: boolean;
  isPlan: boolean = false;
  selectedSecurity: any;
  currencySymbol = "";

  currentEnv:string = environment.name.toLowerCase();

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService,
    private documentAssemblerService: DocumentAssemblerService
  ) {
        this.customizeText = this.customizeText.bind(this);
   }

  ngOnInit() {
    this.ngxLoader.start();
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
    this.route.params.subscribe(params => {
      this.selectedRoundName = decodeURIComponent(params.roundName);
    });
    if (this.selectedRoundName) {
      this.getRoundDetailsByName(this.selectedRoundName);
    }
    this.ngxLoader.stop();
  }

  getSelectedRounds() {
    this.getRoundDetailsByName(this.selectedRoundName);
    this.filterByInvestor('All Investors');
  }

  goToNewRound() {
    this.router.navigate(["/landing", "dashboard", "rounds", "newRound", "round"]);
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  getRoundDetailsByName(rndName) {
    this.ngxLoader.start();
    window.history.pushState(
      "",
      "",
      window.location.href.split("round-details/")[0] +
      "round-details/" +
      rndName
    );
    let isOverview = false;
    let includeProforma = false;
    // this.authService.getRoundDevEx(isOverview, includeProforma).subscribe(
    //     (res: Array<any>) => {
    //         console.log('round details: ', res);
    //         let allRounds = res;
    //         allRounds.forEach(round => {
    //             if(round['name'] == this.selectedRoundName) {
    //                 this.detailsTableInfo = round['shareholders'];
    //             }
    //         });
    //         this.columnsList = new ColumnList();
    //         this.columnsList = {
    //             shareholder: true,
    //             rounds: true,
    //             preHolding: false,
    //             preHoldingRound: true,
    //             postHolding: false,
    //             postHoldingRound: true,
    //             preRoundShares: true,
    //             postRoundShares: true,
    //             amtInvested: false,
    //             amtInvestedInRound: true,
    //             totalInvestment: true,
    //             netWorth: false,
    //             currentValue: false,
    //             common: false,
    //             preference: false,
    //             warrants: false,
    //             fdbShares: false,
    //             debentures: false,
    //             document: false,
    //             roundName: false,
    //             roundSize: false,
    //             closingDate: false,
    //             leadInvestor: false,
    //             dilutionPercentage: false,
    //             holding: false,
    //             preMoneyValuation: false,
    //             postMoneyValuation: false,
    //             percentageOfGain: false,
    //             pricePerShare: false,
    //             noOfShares: false,
    //             parValue: false,
    //             capitalValue: false,
    //             securityType: false,
    //             preSplitCommon: false,
    //             postSplitCommon: false,
    //             preSplitPreference: false,
    //             postSplitPreference: false,
    //             preSplitFdbShares: false,
    //             postSplitFdbShares: false,
    //         }
    //     },
    //     (e) => {}
    // );
    this.authService
      .getRoundDetailsByName(this.selectedRoundName)
      .subscribe(response => {
        if (response != null) {
          this.ngxLoader.stop();
          this.roundInformation = response;
          this.currencyForRound = response['currencyForRound'];
          this.companyCurrency = response['companyCurrency'];
          this.roundNameList = response["roundNamesForDropdown"];
          this.invstorNmeLst = response["investorsNamesForDropdown"];
          this.investorsTotalAmt(this.roundInformation["investors"]);
          this.getInvestorChartData(this.roundInformation["investors"]);
          this.detailsTableInfo = this.roundInformation['capTable']
          this.columnsList = new ColumnList();
            this.columnsList = {
                shareholder: true,
                rounds: true,
                preHolding: false,
                preHoldingRound: true,
                postHolding: false,
                postHoldingRound: true,
                preRoundShares: true,
                postRoundShares: true,
                amtInvested: false,
                amtInvestedInRound: true,
                totalInvestment: true,
                netWorth: false,
                currentValue: false,
                common: false,
                preference: false,
                warrants: false,
                fdbShares: false,
                debentures: false,
                document: false,
                roundName: false,
                roundSize: false,
                closingDate: false,
                leadInvestor: false,
                dilutionPercentage: false,
                holding: false,
                preMoneyValuation: false,
                postMoneyValuation: false,
                percentageOfGain: false,
                pricePerShare: false,
                noOfShares: false,
                parValue: false,
                capitalValue: false,
                securityType: false,
                preSplitCommon: false,
                postSplitCommon: false,
                preSplitPreference: false,
                postSplitPreference: false,
                preSplitFdbShares: false,
                postSplitFdbShares: false,
                newAntidDilutionShares: false
            }
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!");
        }
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error!");
      })
  }

  // Investors chart related methods start here
  getInvestorChartData(investorsdetails) {
    this.investorChart = [];
    investorsdetails.forEach(group => {
      let invstrChartObj = {
        shareholder: group["investorName"],
        amt: group["investmentAmount"],
        shares: group["numberOfShares"]
      };
      this.investorChart.push(invstrChartObj);
    });
    if (this.investorChart.length > 6) {
      this.getStackHoldersViewData(this.investorChart);
    } else {
      this.investorChart = this.investorChart;
    }
  }

  getStackHoldersViewData(chartData) {
    let others = chartData.slice(5);
    let first5 = chartData.slice(0, 5);
    let totalAmt = 0;
    let totalShares = 0;
    for (let i = 0; i < others.length; i++) {
        totalAmt += others[i].amt;
        totalShares += others[i].shares;
    }
    first5.push({ shareholder: "Others", amt: totalAmt, shares: totalShares });
    this.investorChart = first5;
  }

  InvestorsView() {
    this.isChartView = !this.isChartView;
  }

  customizeTooltip = (arg) => {
    return {
      text: 'Amount Invested: ' + this.getAmount(this.usrCompanyCurrency, arg.value) + '\n' + 'Number Of Shares: ' + this.formatCurrency(arg.point.data.shares),
      fontColor: "#ffffff",
      color: "#000000"
    };
  }

  customizeLegendText(items) {
    items.forEach(element => {
        if(element['text'].length > 10) {
            element['text'] = element['text'].substring(0, 10) + '...';
        }
    });
    return items.slice(0, 10);
  }

  getAmount(usrCompanyCurrency, amt){
    let val = this.utilityService.formatCurrency(usrCompanyCurrency, amt);
    return val;
  }

  customizeText(arg) {
    return this.formatCurrency(arg.point.data.shares);
  }

  filterByInvestor(shareholderName) {
    if (shareholderName == 'All Investors') {
      this.showOnlyInvestor = false;
    } else {
      this.showOnlyInvestor = true;
      let flatData = [];
      for (let index = 0; index < this.roundInformation.shareholderTypes.length; index++) {
        const element = this.roundInformation.shareholderTypes[index];
        if (!element.shareholders) {
          for (let grpIdx = 0; grpIdx < element.shareholderGroups.length; grpIdx++) {
            const grpelement = element.shareholderGroups[grpIdx];
            flatData.push(grpelement.shareholderDetails);
          }
        }
        if (element.shareholders) {
          flatData.push(element.shareholders);
        }
      }
      let flatDataDeep = _.flattenDeep(flatData);
      this.showOnlyInvestorInfo = _.find(flatDataDeep, { 'name': shareholderName });
    }
  }

  investorsTotalAmt(investorLst) {
    let totalAmt = 0;
    investorLst.forEach(element => {
      totalAmt += element.investmentAmount;
    });
    this.investorsTotAmt = totalAmt;

    let totalNoOfShares = 0;
    investorLst.forEach(element => {
        totalNoOfShares += element.numberOfShares;
    });
    this.totalNoOfShares = totalNoOfShares;
  }

  identifierCSS(identifier) {
    switch (identifier) {
      case "FF":
        return identifier;
      case "SF":
        return identifier;
      case "AF":
        return identifier;
      case "ES":
        return identifier;
      case "SA":
        return identifier;
      case "SB":
        return identifier;
      case "SC":
        return identifier;
      case "AC":
        return identifier;
      case "SD":
        return identifier;
      case "SE":
        return identifier;
      case "AF1":
        return "Def1";
      case "AF2":
        return "Def2";
      case "AF3":
        return "Def3";
      case "SP":
        return "Def4";
      case "SG":
        return "Def5";
      case "SH":
        return "Def6";
      case "SA1":
        return "Def7";
      case "SA2":
        return "Def8";
      case "SA3":
        return "Def1";
      case "SB1":
        return "Def2";
      case "SB2":
        return "Def3";
      case "SP1":
        return "Def4";
      default:
        // return 'Def' + (Math.floor(Math.random() * 8) + 1);
        return "defaultClass";
    }
  }

  displayShareholderDet(shareholderID, individual, name) {
    if (individual.isPlan == true) {
      this.shouldShowDialog = true;
      this.isPlan = true;
      let selectedInstCls = {
        'amountInvested': individual.amountInvested,
        'className': individual.name,
        'isPlan': individual.isPlan,
        'netWorth': individual.netWorth,
        'numberOfShares': individual.numberOfShares,
        'percentageOfHolding': individual.postHoldingPercentage
      };
      this.selectedSecurity = selectedInstCls;
    } else {
      this.router.navigate(["/landing", "dashboard", "shareholders", "details", shareholderID]);
    }
  }

  getEvent(event) {
    console.log(event);
    this.shouldShowDialog = false;
  }

  expandAllItem(val) {
    if (this.isOpen == false) {
      this.roundInformation.shareholderTypes.forEach(list => {
        list.expandAll = true;
        list.expanded = true;

        if (list.shareholderGroups != null) {
          list.shareholderGroups.forEach(groups => {
            groups.expandAll = true;
            groups.expanded = true;
          })
        }
        this.isOpen = true;
      });
    }
    else if (this.isOpen == true) {
      this.roundInformation.shareholderTypes.forEach(list => {
        list.expandAll = false;
        list.expanded = false;
        if (list.shareholderGroups != null) {
          list.shareholderGroups.forEach(groups => {
            groups.expandAll = false;
            groups.expanded = false;
          })
        }
        this.isOpen = false;
      });
    }
  }

  formatCurrency(value) {
    return this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, value);
  }

  exportPAS4File() {
    this.ngxLoader.start();
    this.authService.exportPAS4File(this.selectedRoundName).subscribe((res: HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    }, e => {
      this.ngxLoader.stop();
      this.toastr.error(e.reason, 'Warning');
    });
  }

  onBuildDocument() {

    let obj = new ContextVariable();
    obj.name = "Round.Name";
    obj.value = this.selectedRoundName;

    this.documentAssemblerService.setUserContextVariables(obj);

    obj = new ContextVariable();
    obj.name = "Company.Id";
    obj.value = localStorage.getItem("companyId");

    this.documentAssemblerService.setUserContextVariables(obj);

    this.router.navigate(["/landing/dashboard/task-manager/step-plan-overview", {type: 'Transaction', roundName: this.selectedRoundName}]);
  }
}
