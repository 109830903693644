<div class="row" (mouseleave)="hideHelp()"> 
    <div class="col-md-9">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Tranches</mat-card-title>
            </mat-card-header>

            <mat-divider class="divider-line"></mat-divider>
            <mat-card-content>
                <form class="needs-validation" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="content-pg-1 top-20 btm-20">
                                The round money can be received in multiple instalments known as tranche. 
                                Enter the details of the number of tranches over which the round money will be received. 
                                Also mention the percentage of the total inflow of money in each tranche.
                            </p>

                            <div *ngIf="this.roundCurrency != this.companyCurrency && this.roundCurrency != null" class="alert alert-info alert-labeled alrt-mrgn top-20 btm-20">
                                <p type="button" class="close-help" data-dismiss="alert">
                                    <img src="../../../assets/message-close.png" />
                                </p>
                                <div class="alert-labeled-row">
                                    <div class="alert-label alert-label-left alert-labelled-cell">
                                        <img src="../../../assets/message-info.svg" />
                                    </div>
                                    <p class="content-pg-1 alert-body alert-body-right alert-labelled-cell">
                                        The Round currency you have selected ( {{this.roundCurrency}} ) is different from Company currency ( {{this.companyCurrency}} )
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <mat-card id="Captable_Tab" (mouseover)="showHelp('Captable_Tab')">
                        <mat-card-content>
                            <div class="row btm-20">
                                <div class="col-md-4">
                                    <mat-label>Number of Tranches</mat-label>
                                    <input type="number" minlength="1" maxlength="2" min="1" class="form-control top-10"
                                        [ngClass]="tranchCount ? 'is-invalid':'is-valid'"
                                        [(ngModel)]="tranchesCnt" [readonly]="isTranchesCompleted" title="" #tranchErr="ngModel"
                                        (change)="tranchesCounting($event.target.value); fieldEdited($event)" [ngModelOptions]="{standalone: true}"
                                        (keydown)=onKeydownEvent($event) [disabled]="isdisabled" required>
                                    <span *ngIf="(tranchErr.dirty || tranchErr.touched) && tranchCount"
                                        class="alert alert-danger">
                                        Number of Tranches should be 1 to 15.
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-12 col-sm-12">
                                    <table class="table-common">
                                        <thead>
                                            <tr>
                                                <th class=" center-align" style="width: 30%;">
                                                    Tranches Percentage(%)
                                                </th>
                                                <th class=" center-align">Investment Amount</th>
                                                <th class=" center-align">Pre Money Valuation</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let convertible of tranches; let i = index;">
                                                <td>
                                                    <input type="text" appDecimalNumber decimals="2" maxlength="7" (blur)="calInvAmount(i,$event.target.value)"
                                                        #tranchPercent class="form-control text-align-right" #tranchpercentErr="ngModel"
                                                        [ngClass]="editOutstanding == i ? '' : 'no-border'"
                                                        [ngModel]="convertible.tranchePercentage | percent: '2.2'"
                                                        title="" [readonly]="isTranchesCompleted" (change)="fieldEdited($event)"
                                                        [disabled]="isdisabled" [ngModelOptions]="{standalone: true}" required/>
                                                </td>
                                                <td style="position: relative;">
                                                    <label *ngIf="this.roundCurrency != null" class="CurrencySymbol">{{roundCurrency.substr(roundCurrency.length-2)}}</label>
                                                    <input type="text" class="form-control text-align-right" (change)="fieldEdited($event)"
                                                        step="0.01" [ngClass]="editOutstanding == i ? '' : 'no-border'" (input)="valuationCSV($event)"
                                                        [(ngModel)]="convertible.investmentAmount" (blur)="calTranchPer(i, $event.target.value)"
                                                        (keypress)="onlyNumberKey($event)" [ngModelOptions]="{standalone: true}" required title="" />
                                                </td>
                                                <td style="position: relative;">
                                                    <label *ngIf="this.roundCurrency != null" class="CurrencySymbol">{{roundCurrency.substr(roundCurrency.length-2)}}</label>
                                                    <input type="text" class="form-control text-align-right" (change)="fieldEdited($event)"
                                                        step="0.01" [ngClass]="editOutstanding == i ? '' : 'no-border'"
                                                        [(ngModel)]="convertible.valuation" (input)="valuationCSV($event)"
                                                        [readonly]="i==0 || isLocked==true || convertible.isTrancheCompleted"
                                                        (keypress)="onlyNumberKey($event)" [ngModelOptions]="{standalone: true}" required title="" />
                                                </td>
                                                <td *ngIf="i==0">
                                                    <input type="image" class="lockIcon" [disabled]="!isPricedRound" *ngIf="isLocked" src="{{locked}}" (click)="lockValue()"
                                                        placement="right" (change)="fieldEdited($event)"
                                                        ngbTooltip="Unlock, For Edit Pre money valuation in subsequent tranches" />
                                                    <input type="image" class="lockIcon" [disabled]="!isPricedRound" *ngIf="!isLocked" src="{{unLocked}}" (click)="lockValue()"
                                                        placement="right" (change)="fieldEdited($event)"
                                                        ngbTooltip="If you lock, Pre money valuation will not be changed for subsequent tranches" />
                                                </td>
                                                <td *ngIf="i!=0"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="content-pg-1 top-20 btm-20">
                                The cap table below displays the amount of investment the investors of the round have to make in each tranche. 
                                The percentage of shareholding and number of shares issued in each tranche are also summarized. 
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 btm-20">
                            <button mat-raised-button id="refreshCapTable" [color]="isdisabled===true? 'disabled' : 'success'"
                                [disabled]="isdisabled" [disableRipple]="disableripple"
                                class="float-right cmncnt-ver-spacing cmncnt-ver-spacing-btm"
                                (click)="refreshCapTable()" ngbTooltip="Refresh Cap Table">
                                Refresh Cap Table
                                <img *ngIf="!isdisabled" src="../../assets/refresh-arrows-symbol-of-interface.svg"
                                    class="refresh-icon" />
                            </button>
                        </div>
                    </div>
                    <div id="Tranches_Tab" class="container-fluid btm-20" *ngIf="showCapTable"
                        (mouseover)="showHelp('Tranches_Tab')">
                        
                        <div class="row zui-wrapper">
                            <div class="zui-scroller">
                                <table class="table-common-scroll zui-table">
                                    <thead>
                                        <tr>
                                            <th class="zui-sticky-col th-Invname"></th>
                                            <th class="center-align tranch-head border-right"
                                                *ngFor="let tmpTranch of hdrLvl1; let i = index;">
                                                {{tmpTranch.tranches}}{{i+1}}</th>
                                        </tr>
                                        <tr id="tranchTable">
                                            <th class="zui-sticky-col th-Invname text-align-left">
                                                Investor Name
                                            </th>
                                            <th
                                                *ngFor="let tmpSubHdr of hdrLvl2; let j = index; let odd = j;"
                                                class="text-align-left border-right" [ngClass]="odd ? 'odd' : 'even'">
                                                <span class="td-width-30 cel-padding text-align-right">{{tmpSubHdr.holding}}</span>
                                                <span class="td-width-40 cel-padding text-align-right">{{tmpSubHdr.amount}} ({{roundCurrencySymbol}})</span>
                                                <span class="td-width-30  text-align-right">{{tmpSubHdr.Shares}}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr id="tranchTableData"
                                            *ngFor="let tranchData of tmpTranches; let i = index; let odd = i;"
                                            [ngClass]="odd ? 'odd' : 'even'">
                                            <td class="zui-sticky-col inv-name text-align-left" container="body"
                                                placement="top" ngbTooltip="{{tranchData.investorName}}">
                                                {{tranchData.investorName}}</td>
                                            <td class="text-align-left"
                                                *ngFor="let data of tranchData.trancheHoldingDtos; let j= index; ">
                                                <span
                                                    class="td-width-30 cel-padding text-align-right">{{data.holdingPercentage | percent:'2.2'}}
                                                </span>
                                                <span class="td-width-40 cel-padding text-align-right"
                                                    ngbTooltip="{{showCurrency(data.amountToBeInvested)}}">{{getTotalValueTble(data.amountToBeInvested)}}
                                                </span>
                                                <span class="td-width-30 text-align-right" ngbTooltip="{{formatCurrency(data.numberOfShares)}}">{{getTotalValueTble(data.numberOfShares)}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button id="saveAndContinue" [color]="isdisabled===true? 'disabled' : 'success'"
                                [disabled]="isdisabled" [disableRipple]="disableripple" class="pull-right "
                                (click)="goToSummary()" ngbTooltip="Save and Continue">
                                Save and Continue
                            </button>
                            <button mat-raised-button id="previous" color="success" [disableRipple]="disableripple"
                                class="pull-right margin-right-20" (click)="goToInstrument()" ngbTooltip="Previous">
                                Previous
                            </button>
                            <button mat-raised-button id="noTranches" color="success" [disableRipple]="disableripple" *ngIf="isdisabled"
                                class="pull-right margin-right-20" (click)="skipTranches()" ngbTooltip="Skip this step">
                                No Tranches
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Helper section -->
    <div class="col-md-3">
        <div class="helper-container">
            <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
              <mat-card-header class="help-hdr">
                <mat-card-title><label class="help-hdr-txt">{{
                    helpSections?.name
                  }}</label></mat-card-title>
              </mat-card-header>
              <hr />
              <mat-card-content class="card-help-content">
                <div [innerHTML]="helpSections?.description"></div>
              </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>