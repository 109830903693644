import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { modelGroupProvider } from "@angular/forms/src/directives/ng_model_group";
import { ResponseContentType, ResponseType } from "@angular/http";
import { ShareHolder } from "./buy-back/buy-back-tab/model/shareHolder";
import { environment } from "src/environments/environment";
import { OptionsBuyBackDetails } from "./esop-management/components/options-buyback/model/options-buyback-details";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  companyName;
  bearerToken;
  companyDetails;
  capTableData;

  // baseURL = "http://localhost:8080/" //localhost
  baseURL = environment.baseURL
  // baseURL = "https://dev-api.hissa.com/";
  // use --configuration=test/dev/prod for connecting to backend

  constructor(private http: HttpClient) { }

  getEmailTemplate() {
    return this.http.get(this.baseURL + "email-template/dropdown");
  }

  viewEmailTemplate(fetchEmailTemplate: any) {
    return this.http.get(this.baseURL + "email-template/fetch?emailTemplateType=" + fetchEmailTemplate)
  }

  uploadEmailTemplate(emailTemplateDto){
    return this.http.post(this.baseURL + "email-template/upload", emailTemplateDto)
  }

  getEmptyCompany() {
    return this.http.get(this.baseURL + "company/get/empty");
  }

  getCapTableDetails(companyName) {
    return this.http.get(
      this.baseURL +
      "capitable/search/findByCompanyName?companyName=" +
      companyName
    );
  }

  search(queryString) {
    return this.http.get(
      this.baseURL + "company_dump/_search?q=" + queryString + "&pretty"
    );
  }

  updateAllDetails() {
    this.companyDetails["id"] = null;
    return this.http.post(this.baseURL + "company/create", this.companyDetails);
  }

  updateIncorporationDetails(incorpData) {
    return this.http.post(this.baseURL + "round/incorporation", incorpData);
  }

  signUp(userDetails) {
    return this.http.post(this.baseURL + "user", userDetails, {
      observe: "response",
    });
  }

  getRegisteredCompanies() {
    return this.http.get(this.baseURL + "company/registered/all");
  }

  //Elasticsearch api added
  elasticSearchCompanies(companyName) {
    return this.http.get(this.baseURL + "company_dump/_search?q=" + companyName);
  }

  saveAdminProfile(adminProfileDetails: FormData) {
    return this.http.post(
      this.baseURL + "user/uploadProfilePic",
      adminProfileDetails
    );
  }

  getAdminProfile() {
    return this.http.get(this.baseURL + "user/uploadProfile");
  }

  resendVerificationMail() {
    return this.http.post(this.baseURL + "user/authenticator/send", {});
  }

  saveCompanyDetails(companyDetails) {
    return this.http.post(this.baseURL + "company/create", companyDetails);
  }

  discardNewCompany() {
    return this.http.get(this.baseURL + "company/discard/on-boarding");
  }

  getCompanyDetails(newCompany) {
    return this.http.get(this.baseURL + "company?isNewRegistration=" + newCompany);
  }

  getDevExSource(includeProforma, date) {
    return this.http.get(this.baseURL + 'dashboard/shareholder/get?includePerforma=' + includeProforma + '&date=' + date);
  }

  getSubsidiaryDevExSource(includeProforma, date, companyId) {
    return this.http.get(this.baseURL + 'dashboard/shareholder/get?includePerforma=' + includeProforma + '&date=' + date +  "&companyId=" + companyId);
  }

  downloadExportFile(includeProforma, date) {
    return this.http.get(this.baseURL + "dashboard/shareholder/get?exportDataIntoFile=true&includePerforma=" + includeProforma + "&date=" + date, {
      responseType: "blob" as "json",
      observe: "response",
    })
  }

  downloadSubsidiaryExportFile(includeProforma, date, companyId) {
    return this.http.get(this.baseURL + "dashboard/shareholder/get?exportDataIntoFile=true&includePerforma=" + includeProforma + "&date=" + date + "&companyId=" + companyId, {
      responseType: "blob" as "json",
      observe: "response",
    })
  }

  exportRoundBasedReport(date, roundName) {
    return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?date=" + date + "&basedOnRound=true&roundName=" + roundName, {
      responseType: "blob" as "json",
      observe: "response",
    })
  }

  exportSubsidiaryRoundBasedReport(date, roundName, companyId) {
    return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?date=" + date + "&basedOnRound=true&roundName=" + roundName + "&companyId=" + companyId, {
      responseType: "blob" as "json",
      observe: "response",
    })
  }

  exportSecurityBasedReport(date) {
    return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?basedOnSecurity=true&date=" + date, {
      responseType: "blob" as "json",
      observe: "response",
    })
  }

  exportSubsidiarySecurityBasedReport(date, subsidiaryCompanyId) {
    return this.http.get(this.baseURL + "report-generation/generateReportAfterApproval?basedOnSecurity=true&date=" + date + "&companyId=" + subsidiaryCompanyId, {
      responseType: "blob" as "json",
      observe: "response",
    })
  }

  getSecuritiesDevExSource(isOverview) {
    return this.http.get(this.baseURL + 'dashboard/securities/get?isOverview=' + isOverview);
  }

  getRoundDevEx(isOverview, includeProforma) {
    return this.http.get(this.baseURL + 'dashboard/round/get?isOverview=' + isOverview + '&&includePerforma=' + includeProforma);
  }

  saveIncorporationDetails(incorporationDetails) {
    return this.http.post(
      this.baseURL + "round/incorporation",
      incorporationDetails
    );
  }

  getImageFile(fileId) {
    return this.http.get(this.baseURL + "upload/getFile/" + fileId, {
      responseType: "blob",
    });
  }

  getPdfFile(fileId) {
    return this.http.get(this.baseURL + "upload/getFile/" + fileId, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  resetPassword(userDetails) {
    return this.http.post(this.baseURL + "password/reset", userDetails, {
      observe: "response",
    });
  }

  getUserDetails() {
    return this.http.get(this.baseURL + "user");
  }

  login(emailId, password) {
    return this.http.post(
      this.baseURL + "login",
      { emailId, password },
      { observe: "response" }
    );
  }

  sendEmailForgotPassword(emailId) {
    return this.http.get(this.baseURL + "password/forgot?emailId=" + emailId);
  }

  logout() {
    return this.http.get(this.baseURL + "exit");
  }

  getRoundFields(stepName) {
    return this.http.get(
      this.baseURL +
      "form_field/search/findByFormId_FormName?formName=ROUNDCREATION_" +
      stepName
    );
  }

  postRoundValues(responseJSON) {
    return this.http.post(this.baseURL + "form_field_value", responseJSON);
  }

  getNumOfShares(invAmt, preMonVal, primaryAmt) {
    return this.http.get(
      this.baseURL +
      "calculate_investor_details?investmentAmount=" +
      invAmt +
      "&preMoneyValuation=" +
      preMonVal +
      "&primaryAmount=" +
      primaryAmt
    );
  }

  completeRound(responseJSON) {
    return this.http.post(
      this.baseURL + "form_field_value?isComplete=true",
      responseJSON
    );
  }

  getRoundDetails(includeProforma) {
    //(this.baseURL + 'round/search/findByCompany_Id?includePerforma=' + includeProforma
    return this.http.get(
      this.baseURL +
      "round/search/findByCompany_Id?includePerforma=" +
      includeProforma +
      "&roundFetchType=All"
    );
  }

  getRoundOverviewChart() {
    return this.http.get(this.baseURL + "round/holding?includePerforma=true");
  }

  getExistingCapTable(includeProforma) {
    return this.http.get(
      this.baseURL + "captable/get?includePerforma=" + includeProforma
    );
  }

  getDashboardCards(includeProforma) {
    return this.http.get(
      this.baseURL + "dashboard/cards?includePerforma=" + includeProforma
    );
  }

  getCaptableCards(includeProforma) {
    return this.http.get(
      this.baseURL + "dashboard/captableCards?includePerforma=" + includeProforma
    );
  }
  getDashboardNotes() {
    return this.http.get(this.baseURL + "dashboard/notes");
  }

  getRoundChart(includeProforma) {
    return this.http.get(
      this.baseURL +
      "dashboard/roundgraph?includePerforma=" +
      includeProforma +
      "&roundFetchType=all"
    );
  }

  getInstrumentTypeChart(includeProforma) {
    return this.http.get(
      this.baseURL +
      "dashboard/instrumentgraph?includePerforma=" +
      includeProforma
    );
  }

  getDashboardTable(includeProforma) {
    return this.http.get(
      this.baseURL + "dashboard/capTable?includePerforma=" + includeProforma
    );
  }

  /* Round Modelling API's */
  // get source captables
  getSourceCapTables() {
    return this.http.get(this.baseURL + "round_modeling/source_cap_table");
  }

  //getRoundModelling details by model & round
  getRoundModelInfo(model, round) {
    return this.http.get(
      this.baseURL +
      "round_modeling?capTableName=" +
      model +
      "&roundName=" +
      round
    );
  }

  getRoundModellingDet(includeProforma) {
    return this.http.get(
      this.baseURL + "round_modeling?templateName=template1"
    );
  }

  getRoundModellingConvertibles(initialConfig, mdlName, rndName) {
    return this.http.post(
      this.baseURL +
      "round_modeling/tables?capTableName=" +
      mdlName +
      "&roundName=" +
      rndName,
      initialConfig
    );
  }

  // updateNewConvertibles(newConvertibles) {
  //   return this.http.post(
  //     this.baseURL + "round_modeling/convertible?templateName=template1",
  //     newConvertibles
  //   );
  // }

  updateNewConvertibles(newConvertibles, rndName, capTblNme) {
    return this.http.post(
      this.baseURL +
      "round_modeling/convertible?roundName=" +
      rndName +
      "&capTableName=" +
      capTblNme,
      newConvertibles
    );
  }

  // getExistingHolderInvAmt(investorObj) {
  //   return this.http.post(
  //     this.baseURL + "round_modeling/investorDetails?templateName=template1",
  //     investorObj
  //   );
  // }

  getExistingHolderInvAmt(investorObj, roundName, capTblNme) {
    return this.http.post(
      this.baseURL +
      "round_modeling/investorDetails?roundName=" +
      roundName +
      "&capTableName=" +
      capTblNme,
      investorObj
    );
  }

  getUpdatedModellingTable(investorDetails, roundName, capTblNme) {
    return this.http.post(
      this.baseURL +
      "round_modeling/captable?roundName=" +
      roundName +
      "&capTableName=" +
      capTblNme,
      investorDetails
    );
  }

  getRightToMaintainTable(investorDetails, rndName, capTblNme) {
    return this.http.post(
      this.baseURL +
      "round_modeling/right_to_maintain?roundName=" +
      rndName +
      "&capTableName=" +
      capTblNme,
      investorDetails
    );
  }

  clearRoundModelling() {
    return this.http.delete(
      this.baseURL + "round_modeling/clear?templateName=template1"
    );
  }

  // Save Model names
  createNewModel(modelInfo) {
    return this.http.post(
      this.baseURL + "round_modeling/model_name",
      modelInfo
    );
  }

  //Delete whole model
  deleteModel(modelName) {
    return this.http.delete(
      this.baseURL + "round_modeling/clear/model?modelId=" + modelName
    );
  }

  //Create Round -> Convert Model to Round
  createRound(rndName, mdlName) {
    return this.http.get(
      this.baseURL +
      "round_modeling/convert_to_round?roundName=" +
      rndName +
      "&capTableName=" +
      mdlName
    );
  }

  ///Modelling ends////

  /** Round Modeling new api's **/
  getAllModels() {
    return this.http.get(
      this.baseURL + "round_modeling/model_name/?isOnLand=true"
    );
  }
  getAllModelsTmp() {
    return this.http.get(
      this.baseURL + "round_modeling/model_name/working_copy"
    );
  }

  getAllRoundsByModel(modelId) {
    return this.http.get(
      this.baseURL + "round_modeling/round_details?modelId=" + modelId
    );
  }

  getAllTmpRoundsByModel(modelId) {
    return this.http.get(
      this.baseURL +
      "round_modeling/round_details/working_copy?modelId=" +
      modelId
    );
  }

  saveRoundName(modelId, roundInfo) {
    return this.http.post(
      this.baseURL + "round_modeling/round_name?modelId=" + modelId,
      roundInfo
    );
  }

  getModelDetailsForRound(roundId) {
    return this.http.get(this.baseURL + "round_modeling?roundId=" + roundId);
  }

  saveRoundInformation(roundInfo, roundId) {
    roundId;
    return this.http.post(
      this.baseURL + "round_modeling/tables?roundId=" + roundId,
      roundInfo
    );
  }

  saveOutStandingConvertibles(newConvertibles, roundId) {
    return this.http.post(
      this.baseURL + "round_modeling/convertible?roundId=" + roundId,
      newConvertibles
    );
  }

  getExistingShareHolderInvAmt(investorObj, roundId) {
    return this.http.post(
      this.baseURL + "round_modeling/investorDetails?roundId=" + roundId,
      investorObj
    );
  }

  saveRightToMaintainTable(investorDetails, roundId) {
    return this.http.post(
      this.baseURL + "round_modeling/right_to_maintain?roundId=" + roundId,
      investorDetails
    );
  }

  //Round Modelling - Refresh Right to Maintain data
  refreshRightToMaintain(roundId) {
    return this.http.get(
      this.baseURL +
      "round_modeling/right_to_maintain/refresh?roundId=" +
      roundId
    );
  }

  //Round Modelling - Refresh New Investor
  refreshNewInvestorData(roundId) {
    return this.http.get(
      this.baseURL + "round_modeling/new_investors/refresh?roundId=" + roundId
    );
  }

  //Save Round Without information
  saveRound(roundId) {
    return this.http.post(
      this.baseURL + "round_modeling/round/save?roundId=" + roundId,
      ""
    );
  }

  //Save Round specific Round All Information
  saveModellingTable(investorDetails, roundId) {
    return this.http.post(
      this.baseURL + "round_modeling/new_investors?roundId=" + roundId,
      investorDetails
    );
  }
  //Save Model
  saveModelInformation(modelId, isErrRnd) {
    return this.http.post(
      this.baseURL +
      "round_modeling/model/save?modelId=" +
      modelId +
      "&canSkipInvalidCheck=" +
      isErrRnd,
      ""
    );
  }

  convertToRound(roundId) {
    return this.http.get(
      this.baseURL + "round_modeling/convert_to_round?roundId=" + roundId
    );
  }

  //Round Modelling - delete round
  deleteRound(modelName, rndName) {
    return this.http.delete(
      this.baseURL +
      "round_modeling/clear?roundName=" +
      rndName +
      "&capTableName=" +
      modelName
    );
  }

  deleteRounds(roundId) {
    return this.http.delete(
      this.baseURL + "round_modeling/clear?roundId=" + roundId
    );
  }

  //Round Modelling - Get Min and Max values
  getMinMaxValues(value) {
    return this.http.get(
      this.baseURL + "round_modeling/min_max_value?currentValue=" + value
    );
  }

  //Round Modelling - Update Round Name
  updateRoundName(roundInfo, modelId) {
    return this.http.post(
      this.baseURL + "round_modeling/round_name?modelId=" + modelId,
      roundInfo
    );
  }

  /** Round Modeling new api's **/

  /** Round Modeling new api's **/
  //Round Modelling - Update Round Name
  updateRound(rndName, description, id) {
    return this.http.put(
      this.baseURL +
      "round_modeling/round_name?id=" +
      id +
      "&roundName=" +
      rndName +
      "&description=" +
      description,
      ""
    );
  }

  getDashboardOverview(includeProforma) {
    return this.http.get(
      this.baseURL +
      "dashboard/shareholder/group?includePerforma=" +
      includeProforma
    );
  }

  getShareholdersOverview(includeProforma) {
    return this.http.get(
      this.baseURL + "dashboard/shareholder?includePerforma=" + includeProforma
    );
  }

  /** ESOP MODELING */

  createNewEsopModel(modelInfo) {
    return this.http.post(this.baseURL + "esop_model", modelInfo);
  }

  getAllEsopModels() {
    return this.http.get(this.baseURL + "esop_model");
  }

  getSelectedEsopModel(modelId) {
    return this.http.get(this.baseURL + "esop_model/" + modelId);
  }

  getWorkingCopyEsopModel() {
    return this.http.get(this.baseURL + "esop_model/working_copy");
  }

  //Delete whole model
  deleteEsopModel(modelId) {
    return this.http.delete(this.baseURL + "esop_model?id=" + modelId);
  }

  getSalaryTemplateAndHiringPlanLists() {
    return this.http.get(this.baseURL + "esop_model/dropdown");
  }

  //deprecated
  getGradeDetailsForSalaryTemplate(templateId) {
    return this.http.get(
      this.baseURL + "esop_model/get_grade_list?id=" + templateId
    );
  }

  getSelectedSalaryTemplateDetails(templateId) {
    return this.http.get(
      this.baseURL + "esop_model/salary_template/" + templateId
    );
  }

  getSelectedHiringPlanDetails(planId) {
    return this.http.get(
      this.baseURL + "esop_model/hiring_plan/" + planId
    );
  }

  getHiringPlanDetailsWithPeriod(planId) {
    return this.http.get(
      this.baseURL + "esop_model/get_hire_plan_Details/actual?id=" + planId
    );
  }

  createOrEditSalaryTemplateNameAndDesc(templateInfo, isEdit) {
    return this.http.post(
      this.baseURL + "esop_model/salary_template/create?isNameEdit=" + isEdit,
      templateInfo
    );
  }

  postSalaryTemplateGrades(gradeDetails, isInvalidEdit: boolean) {
    return this.http.post(
      this.baseURL + "esop_model/salary_template/grade/add?isInvalidEdit=" + isInvalidEdit + "&isGradeEdit=false",
      gradeDetails
    );
  }

  //createSalaryTemplate is Deprecated
  createSalaryTemplate(template) {
    return this.http.post(
      this.baseURL + "esop_model/salary_template",
      template
    );
  }
  //editSalaryTemplate is Deprecated
  editSalaryTemplate(template, isInvalid: boolean) {
    return this.http.post(this.baseURL + "esop_model/salary_template?isInvalidEdit=" + isInvalid, template);
  }

  //deprecated
  createHiringPlan(plan) {
    return this.http.post(this.baseURL + "esop_model/hiring_plan", plan);
  }

  createOrEditHiringPlanNameAndDesc(planInfo, isEdit) {
    return this.http.post(
      this.baseURL + "esop_model/hiring_plan/create?isNameEdit=" + isEdit,
      planInfo
    );
  }

  //deprecated
  editHiringPlan(plan, isInvalid: boolean) {
    return this.http.post(this.baseURL + "esop_model/hiring_plan?isInvalidEdit=" + isInvalid, plan);
  }

  postHiringPlanGrades(gradeDetails, isInvalidEdit: boolean) {
    return this.http.post(
      this.baseURL + "esop_model/hiring_plan/grade/add?isInvalidEdit=" + isInvalidEdit + "&isGradeEdit=false",
      gradeDetails
    );
  }
  postSharePriceMultipliers(sharePriceMultipliersDetails, isInvalidEdit: boolean) {
    return this.http.post(
      this.baseURL + `esop_model/${sharePriceMultipliersDetails.modelId}/sharePriceMultipliers`,
      sharePriceMultipliersDetails
    );
  }

  postExistingEmployeesDetails(modelId, existingEmployeesDetails) {
    return this.http.post(
      this.baseURL + `esop_model/${modelId}/existingEmployeesDetail`,
      existingEmployeesDetails
    );
  }

  postAdditionalCompensations(modelId: any, additionalCompensations: any) {
    return this.http.post(
      this.baseURL + `esop_model/${modelId}/additionalCompensations`,
      additionalCompensations
    );
  }

  getExistingEmployeesDetailsFromRecords(modelId: number, salaryTemplateId: number) {
    return this.http.get(this.baseURL + `esop_model/${modelId}/salaryTemplate/${salaryTemplateId}/existingEmployeesDetail`);
  }

  getCurrentSharePriceAndValuation() {
    return this.http.get(this.baseURL + `esop_model/currentSharePriceAndValuation`);
  }

  summaryForEsopModeling(data) {
    return this.http.post(this.baseURL + "esop_model/summary", data);
  }

  saveEsopModel(data) {
    return this.http.post(this.baseURL + "esop_model/save", data);
  }

  discardEsopModel(modelId) {
    return this.http.delete(this.baseURL + "esop_model/discard?id=" + modelId);
  }

  /** Securities starts **/

  getSecuritiesOverview(includeProforma) {
    return this.http.get(
      this.baseURL + "dashboard/secuirities?includePerforma=" + includeProforma
    );
  }

  getAddNewInstrumentClass() {
    return this.http.get(this.baseURL + "instrument_class");
  }

  // ** Get Instrument class details **//
  getInstrumentClassData() {
    return this.http.get(this.baseURL + "dashboard/securities/instrument");
  }

  // ** Create new instrument class **// -- Old save method API call
  // addNewInstrumentClass(newInstclsData) {
  //     return this.http.post(this.baseURL + 'instrument_class', newInstclsData);
  // }

  // ** Get selected Instrument class information **//
  getInstrumentClassDataByName(instClsnme) {
    return this.http.get(
      this.baseURL + "instrument_class/name?instrumentClassName=" + instClsnme
    );
  }

  /** Get Instrument Types **/
  getInstrumentTypes() {
    return this.http.get(this.baseURL + "instrument_class/dropdown");
  }
  /** Get Instrument template by instrument type **/
  getInstTemplateByType(type) {
    return this.http.post(
      this.baseURL + "instrument_class?instrument=" + type,
      ""
    );
  }

  /** Get Security template by security type **/
  getSecurityTemplateByType(body) {
    return this.http.post(
      this.baseURL + "instrument_class/mutliple_securities",
      body
    );
  }

  /** Get saved Instrument Template values by type and template**/
  getInstTemplateInfo(type, template) {
    return this.http.get(
      this.baseURL +
      "instrument_class?instrument=" +
      type +
      "&templateId=" +
      template
    );
  }

  /** Save instrument class by type **/
  savenewInstrumentClass(instClass) {
    return this.http.post(this.baseURL + "round/instrument/save?templateId="+instClass.templateId+"&instrumentClassName="+instClass.instrumentClassName, {});
  }

  addnewInstrumentClass(instClass) {
    return this.http.post(this.baseURL + "instrument_class/save", instClass);
  }

  /** Delete Instrument class**/
  deleteInstrumentClass(id) {
    return this.http.delete(this.baseURL + "instrument_class/delete?id=" + id);
  }

  getSecurityDetails(id, profileId) {
    return this.http.get(this.baseURL + "security/conversion/get?SecurityClassId=" + id + "&ShareholderProfileId=" + profileId);
  }

  getSecuritiesList(type, classId) {
    return this.http.get(this.baseURL + "security/conversion/securityClass/list?Security=" + type + "&SecurityClassId=" + classId);
  }

  calculateDiscountParameters(conversionDetails) {
    return this.http.post(this.baseURL + "security/conversion/discount", conversionDetails);
  }

  discardConversion() {
    return this.http.delete(this.baseURL + "security/conversion/discard");
  }

  getPreviewForSecurity(conversionDetails) {
    return this.http.post(this.baseURL + "security/conversion/captable/preview", conversionDetails);
  }

  approveSecurityCapTable(capTable) {
    return this.http.post(this.baseURL + "security/conversion/captable/approve", capTable);
  }

  /* Redemption */

  getRedemptionDetails(securityId, profileId) {
    return this.http.get(this.baseURL + "security/redemption?SecurityClassId=" + securityId + "&ShareholderProfileId=" + profileId);
  }

  saveRedemptionForLater(redemptionDetails) {
    return this.http.post(this.baseURL + "security/redemption", redemptionDetails);
  }

  discardRedemption(securityId) {
    return this.http.delete(this.baseURL + "security/redemption?SecurityClassId=", securityId);
  }

  approveRedemption(redemptionDetails) {
    return this.http.post(this.baseURL + "security/redemption/approve", redemptionDetails);
  }

  /** Securities ends **/

  getShareholderDetails(shareholderID) {
    return this.http.get(
      this.baseURL + "dashboard/shareholder/" + shareholderID
    );
  }

  generateShareholderCredentials(obj) {
    return this.http.post(this.baseURL + "dashboard/shareholder/generate/credentials", obj);
  }

  sendShareCertificate(obj) {
    return this.http.post(this.baseURL + "share-certificate/sendShareCertificateAsMail", obj);
  }

  uploadShareCertificate(file, investmentRecordId) {
    return this.http.post(this.baseURL + "share-certificate/uploadShareCertificate?investorId=" + investmentRecordId, file);
  }

  // * Get Round Details By name* //
  getRoundDataByName(roundName) {
    // return this.http.get(this.baseURL + 'round/summary?roundName=' + roundName );
    return this.http.get(
      this.baseURL +
      "round/summary?roundName=" +
      roundName +
      "&roundFetchType=All"
    );
  }

  getRoundDetailsByName(roundName) {
    return this.http.get(
      this.baseURL +
      "round/details?roundName=" +
      roundName +
      "&roundFetchType=All"
    );
  }

  saveCurrencyConverted(dataObj) {
    return this.http.post(
      this.baseURL + "round/investors/update_currency_details",
      dataObj
    );
  }

  // ** Create new Round Calls**//
  // Round tab - Basic Details
  getRoundHistoryData() {
    return this.http.get(this.baseURL + "round/card");
  }
  // Round tab - Basic Details
  getBasicRoundData() {
    return this.http.get(this.baseURL + "round/basicdetails");
  }
  //Current Investor - get Basic details
  getBasicCurrentInvestorData() {
    return this.http.get(this.baseURL + "round/currentInvestor/tab");
  }
  // Round Tab - Save Post call
  postRoundData(roundInfo) {
    return this.http.post(this.baseURL + "round/convertible/update", roundInfo);
  }
  // Current Investor Tab - Outstanding Convertible save
  saveConvertible(outStndnConvertible) {
    return this.http.post(
      this.baseURL + "round/convertible/add_upload",
      outStndnConvertible
    );
  }

  getValuationDetails(securityClassId) {
    return this.http.get(this.baseURL + "round/convertible/valuation?securityclassid=" + securityClassId);
  }

  calculateDetails(discountObj) {
    return this.http.post(this.baseURL + "round/convertible/discount", discountObj);
  }

  saveValuation(valuationObj) {
    return this.http.post(this.baseURL + "round/convertible/discount/save", valuationObj);
  }

  // get existing share holders investment amount - create new round
  getExistingHolderInvAmtRound(investorObj) {
    return this.http.post(this.baseURL + "round/investordetails", investorObj);
  }

  //Saving Right to Maintain investors - existing share holder - Current Investor tab
  saveExistingShareHolderInfo(exstngShrHldrInfo) {
    return this.http.post(
      this.baseURL + "round/investor/save?isRightToMaintain=true",
      exstngShrHldrInfo
    );
  }

  //   New investors tab
  getRoundNewInvestorDet(includeProforma) {
    return this.http.get(this.baseURL + "round/investordetails");
  }

  // Get Shareholders for new investors tab
  getShareHoldersNewInvestor() {
    return this.http.get(this.baseURL + "round/newinvestor/tab");
  }

  // get profile shareholder type and group for drop down
  getNwInstrShrHldrGroupInfo() {
    return this.http.get(this.baseURL + "round/investor/dropdown");
  }

  getBuyerDropdown() {
    return this.http.get(this.baseURL + "secondary_transaction/buyer/dropdown");
  }

  saveNewShareHolderInfo(newShrHldrInfo) {
    return this.http.post(
      this.baseURL + "round/investor/save_upload?isRightToMaintain=false",
      newShrHldrInfo
    );
  }

  // Create New Group
  saveNewGroup(groupInfo) {
    return this.http.post(this.baseURL + "share_holder_group", groupInfo);
  }

  //** Instrument Tab Section API Calls **/

  //Get Instrument Type List
  getInstrumentTypeList() {
    return this.http.get(this.baseURL + "round/instrument_type/tab");
  }
  // Get Investors and Instrument class by Instrument
  getInvInstClsByInst(instrument) {
    return this.http.post(
      this.baseURL + "round/instrument_type/tab",
      instrument
    );
  }
  getInvestmentPopup(popupObj) {
    return this.http.post(this.baseURL + 'round/investment_amount/popup', popupObj);
  }

  getUpdatedInvestmentAmount(invObj) {
    return this.http.post(this.baseURL + 'round/investment_amount/update', invObj);
  }

  saveDebPopup(debObj) {
    return this.http.post(this.baseURL + 'round/investment_amount/popup/save', debObj);
  }

  // Assign instrument class to investor
  saveInstClsToInvestor(investorList) {
    return this.http.post(
      this.baseURL + "round/instrument_type/assign",
      investorList
    );
  }

  //** Tranches Tab Section **/
  getAllTranches() {
    return this.http.get(this.baseURL + "round/tranches");
  }

  //Tranches - Get CapTable data by tranches
  getCapTableByTranches(tranches) {
    return this.http.post(
      this.baseURL + "round/tranches?isValuationChanged=false",
      tranches
    );
  }

  //Save Tranches
  saveTranchesInfo(tranches) {
    return this.http.post(this.baseURL + "round/tranches/save", tranches);
  }

  // Create new round - Round summary
  getRoundSummary(roundName) {
    return this.http.get(
      this.baseURL +
      "round/summary?roundName=" +
      roundName +
      "&roundFetchType=ALL"
    );
  }

  //Final Round Creation
  CreateNewRound(notes) {
    return this.http.post(this.baseURL + "round/submit?notes=" + notes, "");
  }

  addNewEmployee(emp) {
    return this.http.post(this.baseURL + "employee/upload", emp);
  }

  getEsopMgmtDefault(planName) {
    return this.http.get(
      this.baseURL + "esop/management/?esopPlan=" + planName
    );
  }

  getEsopMgmtDropdown() {
    return this.http.get(this.baseURL + "esop/management/dropdown");
  }

  getEsopMgmtTableData() {
    return this.http.get(this.baseURL + "esop/table/dropdown");
  }

  getVestingTemplates() {
    return this.http.get(this.baseURL + "vesting/template/dropdown");
  }

  getVestingTempDispDetails(templateId) {
    return this.http.get(this.baseURL + "vesting/display_details?templateId=" + templateId)
  }

  getDataForCreateESOP() {
    return this.http.get(this.baseURL + "esop/names/dropdown");
  }

  getGraphDtlsForOptionsAndHolders() {
    return this.http.get(this.baseURL + "esop/details");
  }

  getEsopGraphDetailsForPlans(id) {
    return this.http.get(this.baseURL + "esop/details?esopPlan=" + id);
  }

  getNextStatusForGrant(optionHolderId) {
    return this.http.get(this.baseURL + "employee/grantstate?optionHolderId=" + optionHolderId);
  }

  updateGrantStatus(grantStatusObj) {
    return this.http.post(this.baseURL + 'employee/grantstate', grantStatusObj);
  }

  updateBulkGrantStatus(grantStatusObj) {
    return this.http.post(this.baseURL + 'employee/bulkgrantstate', grantStatusObj);
  }

  createEsopGrantsInBulk(file: FormData) {
    return this.http.post(this.baseURL + "usingexcelfile/createOptionHolder/", file, {})
  }
  // getVestingPeriod() {
  //   return this.http.get(this.baseURL + "vesting/interval/dropdown");
  // }

  getDefaultVestingTemplates() {
    return this.http.get(this.baseURL + 'vesting/template/import/dropdown');
  }

  getDefaultTemplateDetails(templateObj) {
    return this.http.post(this.baseURL + 'vesting/template/import/details', templateObj);
  }

  saveNewTemplate(temp) {
    return this.http.post(this.baseURL + "vesting/template", temp);
  }

  getVestingTemplateDetails(val) {
    return this.http.get(this.baseURL + "vesting/template?templateId=" + val);
  }

  getVestingGraphData(template) {
    return this.http.post(this.baseURL + "vesting/graph", template);
  }

  getPoolDetails() {
    return this.http.get(this.baseURL + "esop/get_current_pool_size");
  }

  getNextStatusForPlan(planId) {
    return this.http.get(this.baseURL + "esop/planstate?id=" + planId);
  }

  updatePlanStatus(planStatusObj) {
    return this.http.post(this.baseURL + 'esop/planstate', planStatusObj);
  }

  editPool(poolObj) {
    return this.http.post(this.baseURL + 'esop/add_or_edit_pool_size', poolObj);
  }

  esopPoolTablePreview(previewObj) {
    return this.http.post(this.baseURL + 'esop/get_current_pool_size/preview', previewObj);
  }

  saveEsopPlan(plan) {
    return this.http.post(this.baseURL + "esop/create", plan);
  }
  editESOPPlan(plan) {
    return this.http.put(this.baseURL + "esop/edit", plan);
  }

  addEmployeeToPlan(body) {
    return this.http.post(this.baseURL + "employee/add", body);
  }

  getDashboardDate() {
    return this.http.get(this.baseURL + "dashboard/esop");
  }

  // All employees with all details
  getAllEmployees() {
    return this.http.get(this.baseURL + "employee/get/all");
  }

  getEmployeeList() {
    return this.http.get(this.baseURL + "employee/dropdown");
  }

  getEmployeeVestingTable(empId) {
    return this.http.get(this.baseURL + 'employee/employee_page/vestingTable?EmployeeID=' + empId);
  }

  getEmployeeDetails(empId) {
    return this.http.get(this.baseURL + 'employee/employee_page?EmployeeID=' + empId);
  }

  getCredentials(empId) {
    return this.http.get(this.baseURL + 'employee/login/details?id=' + empId, {
      responseType: "blob"
    });
  }

  getEmployeeTemplate() {
    return this.http.get(this.baseURL +'employee/template');
  }

  getGrantTemplate() {
    return this.http.get(this.baseURL +'esop/template');
  }

  getResignationDetails(empId, status) {
    return this.http.get(this.baseURL + 'employee/employee_page/resignation?EmployeeID=' + empId + '&EmploymentStatus=' + status);
  }

  getUpdatedResignationDetails(updatedObj) {
    return this.http.post(this.baseURL + 'employee/employee_page/resignation/lastday', updatedObj)
  }

  saveResignationDetails(resignationObj) {
    return this.http.post(this.baseURL + 'employee/employee_page/resignation', resignationObj);
  }

  getEmpPopupDetails(employeeId) {
    return this.http.get(this.baseURL + 'employee/employee_page/details?EmployeeID=' + employeeId);
  }

  saveEmpEditDetails(editEmpDetails, empId) {
    return this.http.post(this.baseURL + 'employee/employee_page/details?EmployeeID=' + empId, editEmpDetails);
  }

  saveEmpNominees(nomineeList) {
    return this.http.post(this.baseURL + 'employee/update/nominees', nomineeList);
  }

  getEmployeeNominees(employeeId) {
    console.log(employeeId)
    return this.http.get(this.baseURL + 'employee/nominees?employeeId=' + employeeId);
  }

  EditEmployeePlan(body) {
    return this.http.put(this.baseURL + "employee/add", body);
  }
  deleteEmployeePlan(body) {
    return this.http.delete(
      this.baseURL + "employee/delete/optionholder?id=" + body
    );
  }
  deleteESOPPlan(ids) {
    return this.http.delete(this.baseURL + "esop/delete?id=" + ids);
  }
  doExercise(body, isApprover: boolean) {
    return this.http.post(this.baseURL + `esop/exercise${isApprover ? "/approve" : ""}`, body);
  }
  doVesting(body, isApprover: boolean) {
    return this.http.post(this.baseURL + `employee/vest${isApprover ? "/approve" : ""}`, body);
  }
  //Get exercise details for optionHolder/Approver
  getExerciseDetails(id, isApprover: boolean) {
    return this.http.get(this.baseURL + `esop/exercise/${isApprover ? "approve" : "holder"}?id=` + id);
  }
  //Get details for Employee Vesting (Event)
  getVestingDetails(id, isApprover: boolean) {
    return this.http.get(this.baseURL + `employee/vest/${isApprover ? "approve" : ""}?id=` + id);
  }

  //Get numberOfSharesToVest for Option holder based on percentage
  getNoOfSharestoVest(id, percentage, evName) {
    return this.http.get(
      this.baseURL +
      "employee/vest/count?id=" +
      id +
      "&percentage=" +
      percentage +
      "&eventName=" + evName
    );
  }
  //Get holders and options count when apply for all holders is true
  getOptionsAllHolders(id, percentage) {
    return this.http.get(
      this.baseURL +
      "employee/vest/holder?id=" +
      id +
      "&percentage=" +
      percentage
    );
  }

  getSecondaryMgmtDetails() {
    return this.http.get(
      this.baseURL + "secondary_transaction/shareholder_names"
    );
  }

  getTotalShareDistinctiveNumbers(sellerObj) {
    return this.http.post(this.baseURL + "secondary_transaction/available/share_distinctive_number", sellerObj);
  }

  getFinalDistNumbers(distNumbers) {
    return this.http.post(this.baseURL + "secondary_transaction/get/share_distinctive_number", distNumbers);
  }

  getRangeForShares(body) {
    return this.http.post(
      this.baseURL + "secondary_transaction/share_distinctive_number",
      body
    );
  }

  /* Secondary Dashboard */
  getSecondaryOverview() {
    return this.http.get(this.baseURL + "secondary_transaction/dashboard");
  }

  /* Splits Dashboard */
  getSplitsOverview() {
    return this.http.get(this.baseURL + "share_split/dashboard");
  }

  postNewSplitForPreview(newSplit) {
    return this.http.post(this.baseURL + "share_split/preview", newSplit);
  }

  getSplitSummary(splitId) {
    return this.http.get(this.baseURL + "share_split/summary?splitId=" + splitId);
  }

  getProformaSplitsDetails() {
    return this.http.get(this.baseURL + "share_split/details");
  }

  discardSplitDetails() {
    return this.http.delete(this.baseURL + "share_split/discard");
  }

  approveSplitDetails() {
    return this.http.get(this.baseURL + "share_split/approve");
  }

  /* Save Seller Information*/
  saveSellerInformation(sellerInfo) {
    return this.http.post(
      this.baseURL + "secondary_transaction/buyer_tab",
      sellerInfo
    );
  }
  /* Save Buyer Information*/
  saveBuyerInformation(buyerInfo) {
    return this.http.post(
      this.baseURL + "secondary_transaction/buyer_tab/save",
      buyerInfo
    );
  }

  createSell(body) {
    return this.http.post(this.baseURL + "secondary_transaction/sale", body);
  }
  /* Secondary - get Summary details by Seller and Buyer */
  getSecondarySummary(seller, buyer) {
    return this.http.get(
      this.baseURL +
      "secondary_transaction/summary?sellerName=" +
      seller +
      "&buyerName=" +
      buyer
    );
  }
  /* Secondary - Sell secondary*/
  saveSecondary(secondary) {
    return this.http.post(
      this.baseURL + "secondary_transaction/sale",
      secondary
    );
  }

  getMyTransactions(id) {
    return this.http.get(this.baseURL + "secondary_transaction/shareholder_transaction?ShareholderId=" + id);
  }

  getEmployeesForPlan(status, planName) {
    return this.http.get(
      this.baseURL +
      "employee/management/dropdown?employmentStatus=" +
      status +
      "&planName=" +
      planName
    );
  }

  getEsopPlan(id) {
    return this.http.get(this.baseURL + "esop?planName=" + id);
  }

  getPlanData(body) {
    return this.http.post(this.baseURL + "esop/management", body);
  }

  //Exit Modelling
  getExitModelingBasicInfo() {
    return this.http.get(this.baseURL + "exit_modeling/land");
  }
  getExitModellingInfo(exitmod) {
    return this.http.post(this.baseURL + "exit_modeling", exitmod);
  }

  //Split & Reverse Split
  getSplitReverseSplitInfo(ratio) {
    return this.http.get(this.baseURL + "share_split?ratio=" + ratio);
  }

  //Create new round - Discard proforma round
  discardRound() {
    return this.http.delete(this.baseURL + "round/discard");
  }

  //Round Creation- Refresh Right to Maintain data
  roundCreationrefreshRTM() {
    return this.http.get(this.baseURL + "round/right_to_maintain/refresh");
  }

  //Round Creation - Refresh New Investor
  roundCreationrefreshNewInv() {
    return this.http.get(this.baseURL + "round/new_investors/refresh");
  }

  //Shareholder Fund Details
  shareHolderFundDetails(id) {
    return this.http.get(this.baseURL + "dashboard/shareholder/fund/" + id);
  }

  //Added New
  //Onboarding Api's
  checkCompanyName(companyName, body, isNewRegistration) {
    // return this.http.post(
    //   this.baseURL + "user/company?companyName=" + companyName,
    //   body
    // );
    return this.http.get(this.baseURL + 'company/search?companyName=' + companyName + '&isNewRegistration=' + isNewRegistration);
  }

  requestVerification(companyName, body) {
    return this.http.post(
      this.baseURL + "user/access?companyName=" + companyName,
      body
    );
  }

  getUsersList() {
    return this.http.get(this.baseURL + "user/get");
  }

  addUsers(users) {
    return this.http.post(this.baseURL + "user/add", users);
  }

  updateUsers(users) {
    return this.http.put(this.baseURL + "user/add", users);
  }

  userVerification(code) {
    return this.http.get(this.baseURL + "user/verification?code=" + code);
  }

  addPermissions(data) {
    return this.http.post(this.baseURL + "role", data);
  }

  getIncorporationDetails() {
    return this.http.get(this.baseURL + "round/incorporation");
  }

  uploadIncorporationDocuments(documentsData) {
    return this.http.post(this.baseURL + "company/upload/documents/single", documentsData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getIncorporationDocuments() {
    return this.http.get(this.baseURL + "company/upload/documents");
  }

  deleteSelectedIncorporationDocs(filesList) {
    return this.http.post(this.baseURL + "company/delete/documents", filesList);
  }


  /*Task Manager Starts*/
  getAllWorkflowTypes() {
    return this.http.get(this.baseURL + "task-manager/types");
  }
  getAllWorkflowsForType(workflowTypeId) {
    return this.http.get(this.baseURL + "task-manager/" + workflowTypeId);
  }

  getShareIssuances() {
    return this.http.get(this.baseURL + "task-manager/shareIssuances");
  }

  startWorkflow(workflowData) {
    return this.http.post(this.baseURL + "task-manager/start-process", workflowData);
  }

  deleteWorkflow(workflowInstanceId) {
    return this.http.delete(this.baseURL + "task-manager/delete/" + workflowInstanceId);
  }

  stepsForSelectedWorkflow(workflowInstanceId) {
    return this.http.get(this.baseURL + "workflow/steps/" + workflowInstanceId);
  }

  tasksAssociatedWithWorkflow(workflowInstanceId) {
    return this.http.get(this.baseURL + "task-manager/details/" + workflowInstanceId);
  }

  getActivityDropdownValues() {
    return this.http.get(this.baseURL + "workflow/activities");
  }

  getAllPuposeOfTasks() {
    return this.http.get(this.baseURL + "workflow/purpose");
  }

  addNewTask(task) {
    return this.http.post(this.baseURL + "workflow/task/add", task);
  }

  editTask(task) {
    return this.http.put(this.baseURL + "workflow/task/update", task);
  }

  addNewPurpose(purpose) {
    return this.http.post(this.baseURL + "workflow/purpose/add", purpose);
  }

  changeTaskStatus(taskDetails) {
    return this.http.put(this.baseURL + "task-manager/task/status", taskDetails);
  }

  changeStepStatus(stepDetails) {
    return this.http.put(this.baseURL + "workflow/steps/status", stepDetails);
  }

  getAllExistingWorkflows() {
    return this.http.get(this.baseURL + "task-manager/list/user");
  }

  getAllWorkflowTemplates() {
    return this.http.get(this.baseURL + "task-manager/templates/");
  }

  scheduleMeeting(dateObj) {
    return this.http.post(this.baseURL + "task-manager/task/schedule-meeting", dateObj);
  }

  cancelMeeting(taskId) {
    return this.http.post(this.baseURL + "task-manager/task/cancel-meeting", { "taskId": taskId });
  }

  callForMeeting(taskId) {
    return this.http.post(this.baseURL + "task-manager/task/call-for-meeting", { "taskId": taskId });
  }

  deleteTask(workflowInstanceId, stepInstanceId) {
    return this.http.delete(this.baseURL + "workflow/task/delete/" + workflowInstanceId + "/" + stepInstanceId);
  }

  getTaskDocuments(taskId) {
    return this.http.get(this.baseURL + "document/" + taskId);
  }

  setDocumentStatus(statusObj) {
    return this.http.put(this.baseURL + "document/status", statusObj);
  }

  uploadMeetingDocuments(fileObj) {
    return this.http.post(this.baseURL + "document/supporting/upload", fileObj);
  }

  downloadMeetingDocuments(type, documentId) {
    return window.open(this.baseURL + "document/meeting/download/" + type + "/" + documentId);
  }

  downloadSupportingDocuments(type, documentId) {
    return window.open(this.baseURL + "document/supporting/download/" + type + "/" + documentId);
  }

  generateDocumentsForTask(taskDetails) {
    return this.http.post(this.baseURL + "document/generate", taskDetails);
  }

  downloadDocument(documentId, isSignedDoc: boolean) {
    const docType = isSignedDoc ? 'Signed' : 'Generated';
    return window.open(this.baseURL + "document/download?documentId=" + documentId + "&type=" + docType);
  }

  getTaskStatuses() {
    return this.http.get(this.baseURL + "task-manager/task/statuses");
  }

  getTaskDetails(taskId) {
    return this.http.get(this.baseURL + "task-manager/task/details/" + taskId);
  }

  uploadPostMeetingDocument(taskId, files) {
    return this.http.post(this.baseURL + "task-manager/task/upload/post-meeting-documents/" + taskId, files);
  }

  uploadRegulatoryFilings(taskId, files) {
    return this.http.post(this.baseURL + "task-manager/task/upload/regulatory-filings/" + taskId, files);
  }

  uploadShortNotices(taskId, files) {
    return this.http.post(this.baseURL + "task-manager/task/upload/short-notices/" + taskId, files);
  }

  completeTheTask(data) {
    return this.http.post(this.baseURL + "task-manager/task/complete", data);
  }

  //Document assembler

  getDocumentTemplates(taskId) {
    return this.http.get(this.baseURL + "document-assembler/task/templates/" + taskId);
  }

  startTemplateEditing(templateId, taskId) {
    return this.http.post(this.baseURL + "document-assembler/template/edit/" + templateId + "/" + taskId, templateId);
  }

  startEditingUserContextTemplate(templateId, userContext, body) {
    return this.http.post(this.baseURL + "document-assembler/template/editing/" + templateId + "/" + userContext, body);
  }

  getQuestionsForDocument(documentAssemblerId, templateId, sequenceNum) {
    return this.http.get(this.baseURL + "document-assembler/question/" + templateId + "/" + documentAssemblerId + "/" + sequenceNum);
  }

  getUserContextQuestionsForDocument(context, templateId, sequenceNum) {
    return this.http.get(this.baseURL + "document-assembler/question/user-context/" + templateId + "/" + context + "/" + sequenceNum);
  }

  getDocumentContent(body:any) {
    return this.http.post(this.baseURL + "document-assembler/form", body);
  }

  getUserContextDocumentContent(templateId, userContext) {
    return this.http.get(this.baseURL + "document-assembler/form/user-context/" + templateId + "/" + userContext);
  }

  saveAnswersForDocument(answersObj) {
    return this.http.post(this.baseURL + "document-assembler/template/answer/save", answersObj);
  }
  saveDocumentTemplate(answersObj) {
    return this.http.post(this.baseURL + "document-assembler/template/complete", answersObj);
  }

  getCompletedTemplates(taskId) {
    return this.http.get(this.baseURL + "document-assembler/template/" + taskId);
  }

  downloadBuiltDocument(ids) {
    return this.http.get(this.baseURL + "document-assembler/template/multiple/download/" + ids, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  downloadBuiltDocumentById(documentId) {
    return window.open(this.baseURL + "document-assembler/template/download/" + documentId);
  }

  saveReferencedAnswer(data) {
    return this.http.post(this.baseURL + "document-assembler/reference-answer", data);
  }

  uploadSignedDocument(documentId, fileDetails) {
    return this.http.post(this.baseURL + "document/upload/signedDocument/" + documentId, fileDetails);
  }

  uploadUnSignedDocument(documentId, fileDetails) {
    return this.http.post(this.baseURL + "document/upload/generate-document/" + documentId, fileDetails);
  }

  getSignatories() {
    return this.http.get(this.baseURL + "workflow/directors");
  }

  updateDocumentStatus(statusInfo) {
    return this.http.put(this.baseURL + "document/status", statusInfo);
  }

  saveDocumentTableRow(rowData) {
    return this.http.put(this.baseURL + "document/row/save", rowData);
  }

  deleteDocumentTableRow(rowId) {
    return this.http.delete(this.baseURL + "document/delete/" + rowId);
  }

  deleteGeneratedDocument(documentId) {
    return this.http.delete(this.baseURL + "document/delete/generate-document/" + documentId);
  }

  deleteSignedDocument(documentId) {
    return this.http.delete(this.baseURL + "document/delete/signed-document/" + documentId);
  }

  addNewDocumentRow(taskInfo) {
    return this.http.post(this.baseURL + "document/add/generate-document", taskInfo);
  }

  getSFDTformat(documentId) {
    return this.http.post(this.baseURL + "documenteditor/import?DocumentId=" + documentId, "", {
      observe: "response",
      responseType: "blob"
    });
  }

  getSFDTformatofGrantLetter(selectedGrantId) {
    console.log(selectedGrantId)
    return this.http.post(this.baseURL + "documenteditor/grant-letter/import?optionHolderId=" + selectedGrantId, "", {
      observe: "response",
      responseType: "blob"
    });
  }

  getSFDTformatForNewDocument() {
    return this.baseURL + "documenteditor/wordeditor/";
  }

  getPlanLetter(planId) {
    return this.http.get(this.baseURL + "documenteditor/plan-document/" + planId, {
      observe: "response",
      responseType: "blob"
    });
  }

  saveEditedDocument(fileDetails, fileId) {
    return this.http.post(this.baseURL + "documenteditor/document/upload/" + fileId, fileDetails);
  }

  downlaodDraftDocments(workflowInstanceId) {
    return this.http.get(`${this.baseURL}document-assembler/workflowInstances/${workflowInstanceId}/documents`, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  uploadReviewedDocument(documentAssemblerId, file) {
    return this.http.post(this.baseURL + "document-assembler/upload/review-document/" + documentAssemblerId, file);
  }

  //**START NEW PROCESS */
  getAllRounds() {
    return this.http.get(this.baseURL + "task-manager/rounds");
  }

  getShareholdersById(roundId) {
    return this.http.get(this.baseURL + "task-manager/shareholders/" + roundId);
  }

  // to get list of vesting events
  getVestingEvents() {
    return this.http.get(this.baseURL + "esop/vestevents");
  }

  // to edit vesting events
  getVestEventdetails(eventName) {
    return this.http.get(this.baseURL + "esop/vesteventpopup?eventName=" + eventName);
  }

  getVestEventdetailsForApproval(eventName) {
    return this.http.get(this.baseURL + "esop/vestevents/approve?eventName=" + eventName);
  }

  //To Get number of options get vested and unvested in vesting event popup
  getVestUnvestShares(eventname, percentage) {
    return this.http.get(this.baseURL + "employee/vest/eventcount?eventName=" + eventname + "&percentage=" + percentage);
  }

  // upddate vesting events
  updateVestingEvent(body, isApprover: boolean) {
    return this.http.post(this.baseURL + `esop/vestevents${isApprover ? "/approve" : ""}`, body);
  }

  // to get vesting templates
  getVestTemplates() {
    return this.http.get(this.baseURL + "esop/vestingtemplates");
  }

  // to get shareholder profile details
  getShareholderProfile(shareholderID) {
    return this.http.get(this.baseURL + "dashboard/shareholder/profile/" + shareholderID);
  }

  //to save shareholder profile details
  updateShareholderProfile(shareholderID, body) {
    return this.http.post(this.baseURL + "dashboard/shareholder/profile/" + shareholderID, body);
  }

  //to get User profile details
  getUserProfile() {
    return this.http.get(this.baseURL + "user/profile");
  }

  //to save User Password
  resetUserPassword(body) {
    return this.http.get(this.baseURL + "password/reset" + body);
  }

  //File Manager
  updateCategoryTags(tags) {
    return this.http.post(this.baseURL + 'fileManager/tag', tags);
  }

  getParentIdsFromPath(paths) {
    return this.http.post(this.baseURL + "fileManager/get-ids", paths);
  }

  checkIfPathExists(obj) {
    return this.http.post(this.baseURL + 'fileManager/is-exists', obj);
  }

  PDFtoXML(PDFfile: FormData) {
    return this.http.post(this.baseURL + 'upload/pdftoxml', PDFfile, {
      observe: "response",
      responseType: "blob" as "json"
    });
  }

  XMLtoPDF(files: FormData) {
    return this.http.post(this.baseURL + 'upload/generatePDFUsingExcelFile', files, {
      responseType: "blob"
    });
  }

  updateUser(user) {
    return this.http.put(this.baseURL + "user/profile", user);
  }

  getCapital() {
    return this.http.get(this.baseURL + "capital");
  }

  updateCapital(body) {
    return this.http.post(this.baseURL + 'capital', body);
  }

  //delete user access permission
  deleteUserPermission(Id) {
    return this.http.delete(this.baseURL + "user/permission/delete?UserId=" + Id);
  }

  // Save Round for later
  saveRoundforLater(body) {
    return this.http.post(this.baseURL + 'round/save_for_later', body);
  }

  //Secondary Transaction Summary
  getSecondaryTransactionSummary(Id) {
    return this.http.get(this.baseURL + "secondary_transaction/completed_transaction/summary?SecondaryTransactionId=" + Id);
  }

  // Employee
  getEmployeeCompanies(body) {
    return this.http.post(this.baseURL + "user/employee/company", body);
  }

  employeeLogin(type, id, body) {
    return this.http.post(this.baseURL + "login?userType=" + type + "&companyId=" + id, body, {
      observe: "response",
    });
  }

  employeeForgotPassword(type, mailid, companyid) {
    return this.http.get(this.baseURL + "password/forgot?emailId=" + mailid + "&userType=" + type + "&companyId=" + companyid);
  }

  employeeResetPassword(type, companyid, userDetails) {
    return this.http.post(this.baseURL + "password/reset?userType=" + type + "&companyId=" + companyid, userDetails, {
      observe: "response",
    });
  }

  hasQuestionnaire(templateId) {
    return this.http.get(this.baseURL + "document-assembler/has-questions/" + templateId);
  }

  generateAndDownloadTemplate(templateId, taskId, token) {
    return this.http.get(this.baseURL + "document-assembler/template/generate/download/" + templateId + "/" + taskId + "/" + token);
  }

  uploadUserDefinedDocument(documentModel) {
    return this.http.post(this.baseURL + "template/user-defined-document/upload", documentModel);
  }

  uploadEmployeesFile(file: FormData) {
    return this.http.post(this.baseURL + "usingexcelfile/createEmployee/", file, {})
  }

  //HISTORICAL ROUND
  getHistoricalRoundDetails() {
    return this.http.get(this.baseURL + "historicalRound");
  }

  importHistoricalRoundDetails(excelFile) {
    return this.http.post(this.baseURL + "usingexcelfile/fillHistoricalDataAfterLogin", excelFile);
  }

  saveHistoricalRoundForLater(roundDetails) {
    return this.http.post(this.baseURL + "historicalRound", roundDetails);
  }

  historicalRoundDetails(roundDetails) {
    return this.http.post(this.baseURL + "historicalRound/get/captable", roundDetails);
  }

  approveHistoricalRound(roundDetails) {
    return this.http.post(this.baseURL + "historicalRound/submit", roundDetails);
  }

  publishHistoricalRound(legacyCompanyId, isPrivate) {
    return this.http.post(environment.newCaptableBaseUrl + `/company/publish?legacyCompanyId=${legacyCompanyId}&isPrivate=${isPrivate}`, {});
  }


  discardHistoricalRound(companyId) {
    return this.http.get(this.baseURL + "company/discard/on-boarding?companyId=" + companyId);
  }

  historicalFinalApproval(companyId) {
    return this.http.get(this.baseURL + "captable_data/approve?CompanyId=" + companyId);
  }

  discardHistoricalCaptable(companyId) {
    return this.http.delete(this.baseURL + "captable_data/discard?CompanyId=" + companyId);
  }

  getRoundPieChart(roundDetails) {
    return this.http.post(this.baseURL + "historicalRound/get/pieChart/round", roundDetails);
  }

  getInstrumentPieChart(roundDetails) {
    return this.http.post(this.baseURL + "historicalRound/get/pieChart/instrument", roundDetails);
  }

  getShareholderPieChart(roundDetails) {
    return this.http.post(this.baseURL + "historicalRound/get/pieChart/shareholder", roundDetails);
  }

  getInstrumentsForBuyBack() {
    return this.http.get(this.baseURL + "buyBack/instrumentTypes");
  }

  getProformaBuyBackDetails(buyBackId: number) {
    return this.http.get(this.baseURL + "buyBack/buyBackDetails?buyBackId=" + buyBackId);
  }

  saveBuyBackDetails(buyBackDetails) {
    return this.http.post(this.baseURL + "buyBack/buyBackDetails", buyBackDetails);
  }

  getInitialShareholdersData(buyBackId: number, resetSharHolders: boolean) {
    return this.http.get(this.baseURL + "buyBack/shareholders?buyBackId=" + buyBackId + "&" + "resetShareHolders=" + resetSharHolders);
  }

  saveShareholdersDataForBuyBack(buyBackId: number, shareholders: ShareHolder[]) {
    return this.http.post(this.baseURL + "buyBack/shareholders?buyBackId=" + buyBackId, shareholders)
  }

  executeBuyBackTempForSummary(buyBackId: number, isProforma: boolean, shareholders: ShareHolder[]) {
    return this.http.post(this.baseURL + "buyBack/executeBuyBack?buyBackId=" + buyBackId + "&isProforma=" + isProforma, shareholders);
  }
  executeBuyBackFinal(buyBackId: number, isProforma: boolean, shareReserves: number) {
    return this.http.post(this.baseURL + "buyBack/executeBuyBack?buyBackId=" + buyBackId + "&isProforma=" + isProforma + "&shareReserves=" + shareReserves, null);
  }

  checkForOngoingTransactions() {
    return this.http.get(this.baseURL + "buyBack/checkForOngoingTransactions");
  }

  getAllBuyBackTransactionData() {
    return this.http.get(this.baseURL + "buyBack/getExistingBuybacks")
  }

  discardBuyBackTransaction(buyBackId: number){
    return this.http.delete(this.baseURL + "buyBack/discard?buyBackId=" + buyBackId);
  }

  saveBuyBackNote(buyBackId: number, buyBackDetails){
    return this.http.post(this.baseURL + "buyBack/updateBuyBackNote?buyBackId=" + buyBackId, buyBackDetails);
  }

  //Document Generator
  generateGrantLetter(formData: FormData) {
    return this.http.post(this.baseURL + 'document-assembler/generate/grant-letter', formData, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  convertDocxToPdf(formData: FormData, converter:string) {
    return this.http.post(this.baseURL + 'upload/docxtopdf/' + converter, formData, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }
  getTotalFDBShares() {
    return this.http.get(this.baseURL + "buyBack/getTotalFDBShares");
  }

  getAdminSettings(){
    return this.http.get(this.baseURL + "AdminSetting/settings");
  }
  saveAdminSettings(adminSettings){
    return this.http.post(this.baseURL + "AdminSetting/save", adminSettings);
  }

  importShareCertificate(file) {
    return this.http.post(this.baseURL + "share-certificate/importShareCertificate", file);
  }

  exportShareCertificate() {
    return this.http.get(this.baseURL + "share-certificate/exportShareCertificate", {
        responseType: "blob" as "json",
        observe: "response",
    });
  }

  getInvestorShareCertificate(investorId) {
    return this.http.get(this.baseURL + "share-certificate/getShareCertificateDocument?investorId=" + investorId, {
        observe: "response",
        responseType: "blob"
    });
  }


  //Export file
  exportIndividualRoundReport(roundName: string) {
    return this.http.get(this.baseURL + "report-generation/round?roundName=" + roundName, {
      observe: "response",
      responseType: "blob"
    });
  }

  exportRoundOverview() {
    return this.http.get(this.baseURL + "report-generation/round?isOverview=true", {
        observe: "response",
        responseType: "blob"
    })
  }

  exportShareholdersOverview() {
    return this.http.get(this.baseURL + "report-generation/shareholder", {
        observe: "response",
        responseType: "blob"
    })
  }

  exportSecuritiesOverview() {
    return this.http.get(this.baseURL + "report-generation/security?isOverview=true", {
        observe: "response",
        responseType: "blob"
    });
  }

  exportSecondaryOverviewReport() {
    return this.http.get(this.baseURL + "report-generation/secondary", {
      observe: "response",
      responseType: "blob"
    });
  }

  exportBuyBackOverviewReport() {
    return this.http.get(this.baseURL + "report-generation/buyback", {
      observe: "response",
      responseType: "blob"
    });
  }

  exportPAS4File(roundName:string) {
    return this.http.get(this.baseURL + "report-generation/round/pas4?roundName=" + roundName, {
      observe: "response",
      responseType: "blob"
    });
  }

  exportSecurityReport(securityType:string) {
    return this.http.get(this.baseURL + "report-generation/security?securityType=" + securityType, {
      observe: "response",
      responseType: "blob"
    });
  }
  exportSh6(requestBody:any, id){
    return this.http.get(this.baseURL + "report-generation/exportSH6?date=" + requestBody + "&id=" + id);
  }


  exportSecondaryTransactionReport(requestBody:any) {
    return this.http.post(this.baseURL + "report-generation/generateReport?isSecondaryPage=" + true, requestBody, {
      observe: "response",
      responseType: "blob"
    });
  }
  exportSplitTransactionReport(requestBody:any) {
    return this.http.post(this.baseURL + "report-generation/generateReport?isSplitPage=" + true, requestBody, {
      observe: "response",
      responseType: "blob"
    });
  }

  exportHistoricalTransactionReport(requestBody:any) {
    return this.http.post(this.baseURL + "report-generation/generateReport?isHistoricalPage=" + true, requestBody, {
      observe: "response",
      responseType: "blob"
    });
  }

  exportHistoricalRoundBasedReport() {
    return this.http.get(this.baseURL + "report-generation/generateReportBeforeApproval?basedOnRound=true", {
      observe: "response",
      responseType: "blob"
    });
  }

  exportHistoricalSecurityBasedReport() {
    return this.http.get(this.baseURL + "report-generation/generateReportBeforeApproval?basedOnSecurity=true", {
      observe: "response",
      responseType: "blob"
    });
  }

  exportQuickRoundReport() {
    return this.http.get(this.baseURL + "report-generation/quickRound", {
      observe: "response",
      responseType: "blob"
    });
  }

  exportQuickRoundPAS4() {
    return this.http.get(this.baseURL + "report-generation/quickRound/pas4", {
      observe: "response",
      responseType: "blob"
    });
  }

  getOngoingTransaction() {
    return this.http.get(this.baseURL + "transactionSummary/ongoing/transaction");
  }

  getAllTransactions(fromDate, toDate) {
    return this.http.get(this.baseURL + 'transactionSummary?fromDate=' + fromDate + '&toDate=' + toDate);
  }

  generateRoundSummaryReport(roundName, body){
    return this.http.post(this.baseURL + 'report-generation/generateReport?isRoundSummaryPage=true&roundName=' + roundName, body, {
        responseType: "blob" as "json",
        observe: "response",
    });
  }

  exportProformaRoundPas4() {
    return this.http.get(this.baseURL + "report-generation/roundSummary/pas4", {
      observe: "response",
      responseType: "blob"
    })
  }

  generateRoundModelingReport(roundName, body){
    return this.http.post(this.baseURL + 'report-generation/generateReport?isRoundModelingPage=true&roundName=' + roundName, body, {
        responseType: "blob" as "json",
        observe: "response",
    });
  }

  exportTransactionsFile(fromDate, toDate) {
    return this.http.get(this.baseURL + 'report-generation/allTransactions?fromDate=' + fromDate + '&toDate=' + toDate, {
        observe: "response",
        responseType: "blob"
    });
  }
  getFaq(){
    return this.http.get(this.baseURL + "company/getFaq");
  }

  setFaq(faqArray){
    return this.http.post(this.baseURL + "company/setFaq", faqArray);
  }

  //document generator
  generateDocument(templateId: number, body:any) {
    return this.http.post(this.baseURL + "document-assembler/generate/document?templateId=" + templateId, body);
  }

  //company resolutions
  getCompanyResolutionsList(companyId:number) {
    return this.http.get(this.baseURL +"company-resolutions/" + companyId);
  }

  deleteResolution(resolutionId:number) {
    return this.http.delete(this.baseURL + "company-resolutions/" + resolutionId);
  }

  getTemplatesByResolution(resolutionId:number) {
    return this.http.get(this.baseURL + "company-resolutions/templates/" + resolutionId);
  }

  downloadResolutionDocument(documentId:number) {
    return this.http.get(this.baseURL + "company-resolutions/download/" + documentId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  getCompanyResolutionPdfDocument(documentId: number) {
    return this.http.get(this.baseURL + "company-resolutions/pdf-document/" + documentId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  downloadAllCompanyResolutionPdfDocument(resolutionId: number) {
    return this.http.get(this.baseURL + "company-resolutions/templates/download/" + resolutionId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  downloadAllCompanyResolutionDocuments() {
    return this.http.get(this.baseURL + "company-resolutions/download-all", {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  downloadAllFilingDocuments() {
    return this.http.get(this.baseURL + "company-resolutions/download-all-filings", {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  saveUserAnswersWithFile(body:any) {
    return this.http.post(this.baseURL + "document-assembler/save/answers/file/", body);
  }

  downloadAnswerWithFile(answerId:number) {
    return this.http.get(this.baseURL + "document-assembler/download/answers/file/" + answerId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  getResolutionIdByTemplateId(templateId: number) {
    return this.http.get(this.baseURL + "company-resolutions/get-resolution-id/" + templateId);
  }

  getLatestTermSheet() {
    return this.http.get(this.baseURL + "termsheetdetails/latest");
  }

  getAllTermSheets() {
    return this.http.get(this.baseURL + "termsheetdetails");
  }

  getTermSheetDetails(id) {
    return this.http.get(this.baseURL + "termsheetdetails/details?Id=" + id);
  }

  markTermSheetInterest(id, interest) {
    return this.http.post(this.baseURL + "termsheetdetails/save/interest?Id=" + id + "&IsInterested=" + interest, null);
  }

  createTermSheetRound(id) {
    return this.http.get(this.baseURL + "termsheetdetails/round/save?Id=" + id);
  }

  createTermSheetModel(id) {
    return this.http.get(this.baseURL + "termsheetdetails/round_model/save?Id=" + id);
  }

  downloadTermSheet(id) {
    return this.http.get(this.baseURL + "termsheetdetails/document/download?Id=" + id, {
        responseType: "blob" as "json",
        observe: "response",
    });
  }

  viewTermSheet(id) {
    return this.http.get(this.baseURL + "termsheetdetails/document/view?Id=" + id, {
        observe: "response",
        responseType: "blob"
    });
  }

  getSecurityClass(type) {
    return this.http.get(this.baseURL + "quick_round/security/list?security=" + type);
  }

  getQuickRoundDetails() {
    return this.http.get(this.baseURL + "quick_round");
  }

  discardQuickRound() {
    return this.http.delete(this.baseURL + "quick_round/discard");
  }

  saveQuickRound(body) {
    return this.http.post(this.baseURL + "quick_round", body);
  }

  getQuickRoundPreview(body) {
    return this.http.post(this.baseURL + "quick_round/preview", body);
  }

  approveQuickRound() {
    return this.http.post(this.baseURL + "quick_round/approve", null);
  }

  getHrmsEmpList() {
    return this.http.get(this.baseURL + "integration/get");
  }

  importEmp(empList) {
    return this.http.post(this.baseURL + "integration/import", empList);
  }

  loginHrms(loginObj) {
    return this.http.post(this.baseURL + "integration/login", loginObj);
  }

  syncHrms() {
    return this.http.post(this.baseURL + "integration/sync", {});
  }

  disconnectHrms() {
    return this.http.post(this.baseURL + "integration/disconnect", {});
  }

  enableDisableLogin(empObj) {
    return this.http.post(this.baseURL + "employee/enable-disable/?employeeId=" + empObj['employeeId'] + "&deactivate=" + empObj['deactivate'], empObj);
  }

  saveOptionsBuyBackDetails(optionBuyBackDto: OptionsBuyBackDetails){

    return this.http.post(this.baseURL + "optionsBuyBack/details", optionBuyBackDto);

  }


  getExercisableOptions(cutOffDate: string, buyBackPercentage: number){

    return this.http.get(this.baseURL + "optionsBuyBack/totalExercisableOptions?buyBackPercentage=" + buyBackPercentage.toString() + "&cutOffDate=" + cutOffDate);

  }


  getOptionBuyBackDetails(){

    return this.http.get(this.baseURL + "optionsBuyBack/details");

  }


  startOptionBuyBack(optionBuyBackDto: OptionsBuyBackDetails){

    return this.http.post(this.baseURL + "optionsBuyBack/details", optionBuyBackDto);

  }


  getOptionBuyBackOfferDetails(buyBackId: number){

    return this.http.get(this.baseURL + "optionsBuyBack/offers?buyBackId=" + buyBackId);

  }


  discardOptionBuyBack(buyBackId: number){

    return this.http.delete(this.baseURL + "optionsBuyBack?buyBackId=" + buyBackId);

  }

  uploadPlanDocument(body) {
    return this.http.post(this.baseURL + "esop/upload/plan-document",body);
  }

  verifyOtp(reqObj){
    return this.http.post(this.baseURL + "login/verifyOtp", reqObj, { observe: "response" });
  }

  getPartlyPaidShareDetails(investorId) {
    return this.http.get(this.baseURL + "dashboard/partlypaid/get?investorId=" + investorId);
  }

  savePartlyPaidShareDetails(body) {
    return this.http.post(this.baseURL + "dashboard/partlypaid/save", body);
  }

  getInstrumentDetails(date) {
    return this.http.get(this.baseURL + "bonus/getdetails?date=" + date);
  }

  setInstrumentDetails(reqObj){
    return this.http.post(this.baseURL + "bonus/setdetails", reqObj);
  }

  getBonusSecurityDetails(bonusId) {
    return this.http.get(this.baseURL + "bonus/getShareholderDetails?bonusId=" + bonusId)
  }

  getBonusPreview(bonusId) {
    return this.http.get(this.baseURL + "bonus/preview?bonusId=" + bonusId)
  }

  setShareholderDetails(reqObj) {
    return this.http.post(this.baseURL + "bonus/setShareholderDetails", reqObj)
  }

  discardBonus() {
    return this.http.delete(
      this.baseURL + "bonus/discard"
    );
  }

  approveBonus() {
    return this.http.post(this.baseURL + "bonus/approve", {})
  }

  getBonusDetails(bonusId) {
    return this.http.get(this.baseURL + "bonus/getBonusDetails?bonusId=" + bonusId)
  }

  getAllBonusDetails(){
    return this.http.get(this.baseURL + "bonus/getAllBonusDetails")
  }

  getShareModelDetails(typeOfModel: string, modelName: string){
    return this.http.get(this.baseURL + "shareModel/getDetails?typeOfModel=" + typeOfModel + "&modelName=" + modelName);
  }

  saveShareModelDetails(body){
    return this.http.post(this.baseURL + "shareModel/saveDetails", body);
  }

  getOnGoingBonusDetails(){
    return this.http.get(this.baseURL + "bonus/getOnGoingBonusDetails")
  }

  downloadFinancialReport(vesting_date){
    return this.http.get(this.baseURL + "report-generation/financial-report/withVesting/" + vesting_date);
  }

  downloadFinancialReportWithOutSchedule(vesting_date){
    return this.http.get(this.baseURL + "report-generation/financial-report/" + vesting_date);
  }

  resendOtp(resendObj){
    return this.http.post(this.baseURL + "login/resendOtp", resendObj)
  }

  getSubsidiaryCompanies() {
    return this.http.get(this.baseURL + "company/subsidary/relation/captable");
  }


  //Step plan APIs

  saveStepPlan(body) {
    return this.http.post(this.baseURL + "step-plan", body);
  }

  saveStepPlanActivity(body) {
    return this.http.post(this.baseURL + "step-plan/step-activity", body);
  }

  getStepPlans(body) {
    return this.http.post(this.baseURL + "step-plan/list", body);
  }

  startStepPlan(body) {
    return this.http.post(this.baseURL + "step-plan/start", body);
  }

  getStepPlanInstances(body) {
    return this.http.post(this.baseURL + "step-plan/step-instance", body);
  }

  getStepPlanActivities(instanceId) {
    return this.http.get(this.baseURL + "step-plan/activities/" + instanceId);
  }

  getStepPlanActivityIdByTemplateId(templateId: number) {
    return this.http.get(this.baseURL + "step-plan/step-plan-activity-id/" + templateId);
  }

  getTemplatesByStepPlanActivity(stepPlanActivityId:number) {
    return this.http.get(this.baseURL + "step-plan/step-activity/templates/" + stepPlanActivityId);
  }

  getStepPlanActivityPdfDocument(documentId: number) {
    return this.http.get(this.baseURL + "step-plan/step-activity/pdf-document/" + documentId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  downloadStepPlanActivityDocument(documentId:number) {
    return this.http.get(this.baseURL + "step-plan/step-activity/document/download/" + documentId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  downloadAllStepPlanActivityDocuments(stepPlanActivityId: number) {
    return this.http.get(this.baseURL + "step-plan/step-activity/templates/download/" + stepPlanActivityId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  downloadStepPlanActivityDocuments(stepPlanInstanceId:number) {
    return this.http.get(this.baseURL + "step-plan/step-activity/download-all/" + stepPlanInstanceId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  downloadAllStepActivityFilingDocuments(stepPlanInstanceId:number) {
    return this.http.get(this.baseURL + "step-plan/step-activity/download-all-filings/" + stepPlanInstanceId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }

  deleteStepPlan(id:number) {
    return this.http.delete(this.baseURL + "step-plan/" + id);
  }

  deleteStepPlanInstance(id:number) {
    return this.http.delete(this.baseURL + "step-plan/step-plan-instance/" + id);
  }

  updateStepPlanInstance(body:any) {
    return this.http.put(this.baseURL + "step-plan/step-plan-instance/status" , body);
  }

  generateStepActivityDocument(activityId:number, body:any) {
    return this.http.post(this.baseURL + "step-plan/generate/document/" + activityId, body);
  }

  getBeneficiaries(id) {
    return this.http.get(this.baseURL + "beneficalShares/get?investorId=" + id);
  }

  saveNewProfile(profileInfo) {
    return this.http.post(this.baseURL + "beneficalShares/saveProfileInfo", profileInfo);
  }

  saveBeneficiaries(beneficiaries, id) {
    return this.http.post(this.baseURL + "beneficalShares/save?investorId=" + id, beneficiaries);
  }

  getIndividualBeneficiaryDetails(id) {
    return this.http.get(this.baseURL + "beneficalShares/getByProfileId?profileId=" + id);
  }

  getBeneficiariesList(id) {
    return this.http.get(this.baseURL + "beneficalShares/getListOfBeneficalsForProfileId?profileId=" + id);
  }

  exportBenefiaryDetails(profileId) {
    return this.http.get(this.baseURL + "report-generation/generateBeneficialReportBasedOnProfileId?profileId=" + profileId, {
      responseType: "blob" as "json",
      observe: "response"
    });
  }
}
