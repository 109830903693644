/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tags-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/common";
import * as i11 from "./tags-edit.component";
var styles_TagsEditComponent = [i0.styles];
var RenderType_TagsEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsEditComponent, data: {} });
export { RenderType_TagsEditComponent as RenderType_TagsEditComponent };
function View_TagsEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-xs-6 col-sm-6 col-md-6 custom-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["class", "dialogue-title"], ["style", "font-size: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tags"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-xs-6 col-sm-6 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close top-close-btn"], ["ngbTooltip", "Close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "close"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mat-divider", [["class", "divider-line mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(11, 49152, null, 0, i3.MatDivider, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "row pad-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "col-xs-12 col-sm-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "input", [["class", "form-control top-10"], ["placeholder", "Add or Remove Tags"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.tagsString = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(17, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(19, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "row float-right pad-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "div", [["class", "col-xs-12 col-sm-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["color", "success"], ["id", "save"], ["mat-raised-button", ""], ["ngbTooltip", "Save"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveTags() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(23, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_11 = _co.tagsString; _ck(_v, 17, 0, currVal_11); var currVal_14 = true; var currVal_15 = "success"; _ck(_v, 23, 0, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 11).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 11).vertical; var currVal_2 = !i1.ɵnov(_v, 11).vertical; var currVal_3 = i1.ɵnov(_v, 11).inset; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 19).ngClassValid; var currVal_9 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = (i1.ɵnov(_v, 23).disabled || null); var currVal_13 = (i1.ɵnov(_v, 23)._animationMode === "NoopAnimations"); _ck(_v, 22, 0, currVal_12, currVal_13); }); }
export function View_TagsEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsEditComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isShowTags; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TagsEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tags-edit", [], null, null, null, View_TagsEditComponent_0, RenderType_TagsEditComponent)), i1.ɵdid(1, 114688, null, 0, i11.TagsEditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagsEditComponentNgFactory = i1.ɵccf("app-tags-edit", i11.TagsEditComponent, View_TagsEditComponent_Host_0, { isShowTags: "isShowTags", tagsString: "tagsString" }, { visibleChange: "visibleChange" }, []);
export { TagsEditComponentNgFactory as TagsEditComponentNgFactory };
