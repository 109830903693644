import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../src/app/authentication.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { HelperService } from "../services/helper.service";
import { User } from "./user-profile";
import { NavbarService } from "../services/navbar-reload";
import { UtilityService } from "../services/utilities.service";
import { PermissionsService } from "../services/permissions.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ConfirmDeleteComponent } from "../confirm-delete/confirm-delete.component";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  public userName: any;
  public emailId: any;
  public currentPassword: any;
  public newPassword: any;
  public confirmPassword: any;

  public cPasswordInvalid: boolean = false;
  public ccPasswordInvalid: boolean = false;
  public nPasswordInvalid: boolean = false;

  public helper: string = "";
  public helpSections;
  public helperTopPosition = null;
  public ischangePassword: boolean = false;
  currHide: boolean = true;
  newHide: boolean = true;
  conHide: boolean = true;
  public user: User;
  public countryList = [];
  public residentialStatus = [];
  public maxLength: number = 9;
  public roles = [];

  public shouldShowDialog = false;
  public profilePicture = null;
  public croppedImage = null;
  public fileName: any;

  public uName: boolean = false;
  public uEmail: boolean = false;
  public uContact: boolean = false;
  public uCompany: boolean = false;
  public uDesig: boolean = false;
  public uNation: boolean = false;
  public uCor: boolean = false;
  public barLabel: string = "Password strength:";
  public genders = ["Male", "Female", "Other"];
  inlineHelper = "";
  inlineHelpSections;
  editNominee;
  public nomineeList = [];
  employeeId: String;
  totPercent: number;
  employee: boolean = false;
  passwordStrength = false;

  settings = { isEnabledTwoFactorAuthentication: false };

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private helperService: HelperService,
    private navbarService: NavbarService,
    private utility: UtilityService,
    private permService: PermissionsService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getRoles();
    this.user = new User();
    this.employeeId = sessionStorage.getItem("employeeId");
    const { getCode, getNames } = require("country-list");
    this.countryList = getNames();
    this.residentialStatus = getNames();
    this.getUserdetails();
    if (this.user.nationality == null || this.user.nationality === "") {
      this.user.nationality = "India";
    }
    if (
      this.user.countryOfResidence == null ||
      this.user.countryOfResidence === ""
    ) {
      this.user.countryOfResidence = "India";
    }
    if (localStorage.getItem("userRole") == "Employee") {
      this.employee = true;
      this.authService
        .getEmployeeNominees(
          JSON.parse(sessionStorage.getItem("userDetails"))["employeeId"] / 1
        )
        .subscribe(
          (res: any) => {
            if (res) {
              this.nomineeList = res;
              this.ngxLoader.stop();
            } else {
              this.ngxLoader.stop();
              this.toastr.error("Null response!.", "Failure!");
            }
          },
          (e) => {
            this.ngxLoader.stop();
            this.toastr.error("Error: " + e.error.reason, "Error");
          }
        );
    }
    else{
      this.getSettings();
    }
  }

  editEvent(index) {
    this.editNominee = index;
  }

  saveEvent(index) {
    this.editNominee = null;
  }

  authorizeUser(permission: Array<string>) {
    return this.permService.authorizeUser(permission);
  }

  getRoles() {
    this.authService.getAdminProfile().subscribe(
      (res: any) => {
        if (res) {
          this.roles = res.designationDropDownValues;
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }

  addnewRowEvent() {
    let index = this.nomineeList.length - 1;
    if (this.nomineeList.length > 0) {
      if (
        this.nomineeList[index].percentageAlloted == 0 ||
        this.nomineeList[index].percentageAlloted == "" ||
        this.nomineeList[index].name == 0 ||
        this.nomineeList[index].name == "" ||
        this.nomineeList[index].employeeRelationship == 0 ||
        this.nomineeList[index].employeeRelationship == ""
      ) {
        this.toastr.error(
          "Fill all fields of current row to add new row",
          "Error"
        );
        return;
      } else if (this.totPercent >= 1) {
        this.toastr.error(
          "Can't add new Nominee, Percentage 100% utilized",
          "Error"
        );
        return;
      } else {
        let newNominee = {
          percentageAlloted: "",
          name: "",
          employeeRelationship: "",
        };
        this.nomineeList.push(newNominee);
        this.editNominee = this.nomineeList.length - 1;
      }
    } else if (this.nomineeList.length == 0) {
      let newNominee = {
        percentageAlloted: "",
        name: "",
        employeeRelationship: "",
      };
      this.nomineeList.push(newNominee);
      this.editNominee = this.nomineeList.length - 1;
    }
  }

  onChangeInput(ele, index) {
    let tmpVal = ele.value.replace(/%$/, "");
    let tmp = tmpVal / 1;
    this.nomineeList[index].percentageAlloted = tmp / 100;
  }

  onKeydownEvent(e) {
    this.utility.restrictMinus(e);
  }

  hideInlineHelp() {
    this.inlineHelper = "";
  }

  showInlineHelp(section) {
    this.helper = "";
    if (section == this.inlineHelper) {
      return;
    }
    this.inlineHelpSections = this.helperService.getHelpContent(
      "UserProfileComponent",
      section
    );
    this.inlineHelper = section;
  }

  getUserdetails() {
    this.ngxLoader.start();
    this.authService.getUserProfile().subscribe(
      (res) => {
        if (res) {
          this.user = JSON.parse(JSON.stringify(res));
          if (this.user["profilePic"] == null) {
            this.user.profilePic = "";
          }
          if (this.user["gender"] == null) {
            this.user.gender = "";
          }
          if (this.user["linkedInProfile"] == null) {
            this.user.linkedInProfile = "";
          }
          if (this.user["pan"] == null) {
            this.user.pan = "";
          }
          if (this.user["phoneNumber"] == null) {
            this.user.phoneNumber = "";
          }
          if (this.user["companyName"] == null) {
            this.user.companyName = "";
          }
          if (this.user["designation"] == null) {
            this.user.designation = "";
          }
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response!.", "Failure!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }
  getSettings() {
    this.authService.getAdminSettings().subscribe(
      (res) => {
        this.ngxLoader.stop();
        this.settings.isEnabledTwoFactorAuthentication =
          res["isEnabledTwoFactorAuthentication"];
      },
      (_) => {
        this.toastr.error("Error while fetching settings", "Failure");
        this.ngxLoader.stop();
      }
    );
  }

  onChangeDesignation(event) {
    this.user.designation = event;
  }

  onChangeGender(event) {
    this.user.gender = event;
  }
  checkPasswordStrength() {
    this.passwordStrength = this.utility.checkStrength(this.newPassword) > 30;
  }
  passwordValidation(data) {
    this.utility.formValidation();
    if (data.oldPassword && data.newPassword && this.confirmPassword) {
      if (this.confirmPassword != data.newPassword) {
        this.ccPasswordInvalid = true;
        this.toastr.error(
          "Your new password and confirm password do not match.",
          "Error"
        );
        return false;
      } else {
        this.ccPasswordInvalid = false;
        return true;
      }
    } else if (
      data.oldPassword === "" ||
      data.oldPassword == undefined ||
      data.newPassword === "" ||
      data.newPassword == undefined ||
      this.confirmPassword === "" ||
      this.confirmPassword == undefined
    ) {
      if (data.oldPassword === "" || data.oldPassword == undefined) {
        this.cPasswordInvalid = true;
      }
      if (data.newPassword === "" || data.newPassword == undefined) {
        this.nPasswordInvalid = true;
      }
      if (this.confirmPassword === "" || this.confirmPassword == undefined) {
        this.ccPasswordInvalid = true;
      }
      return false;
    }
  }

  resetPassword() {
    this.ngxLoader.start();

    let userDetails = {
      emailId: this.user.emailAddress,
      oldPassword: this.currentPassword,
      newPassword: this.newPassword,
    };
    let valid = this.passwordValidation(userDetails);
    if (valid) {
      userDetails["newPassword"] = this.utility.encryptPassword(
        userDetails["newPassword"]
      );
      userDetails["oldPassword"] = this.utility.encryptPassword(
        userDetails["oldPassword"]
      );
      this.authService.resetPassword(userDetails).subscribe(
        (res) => {
          this.toastr.success(
            "Your password has been reset successfully!",
            "Success!"
          );
          this.ngxLoader.stop();
          if (this.employee) {
            this.router.navigateByUrl("/employeeLogin");
          } else this.router.navigateByUrl("/");
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error("Error: " + e.error.reason, "Error");
        }
      );
    } else {
      this.ngxLoader.stop();
    }
  }

  changePassword() {
    this.ischangePassword = true;
  }

  currpasswordHide() {
    this.currHide = !this.currHide;
  }

  newpasswordHide() {
    this.newHide = !this.newHide;
  }

  conpasswordHide() {
    this.conHide = !this.conHide;
  }

  onlyNumberKey(event: any) {
    return event.charCode == 8 || event.charCode == 0 || event.charCode == 101
      ? null
      : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  cancel() {
    this.ischangePassword = false;
    this.newPassword = "";
    this.confirmPassword = "";
  }

  removeRowEvent(index) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        header: "Confirm Delete?",
        text: "Are you sure you want to Delete ?",
      },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
        this.nomineeList.splice(index, 1);
        this.ngxLoader.stop();
      }
    });
  }

  close() {
    this.ngxLoader.start();
    this.getUserdetails();
    if (this.authorizeUser(["emp-view", "!strict"])) {
      this.router.navigate([
        "/landing",
        "dashboard",
        "employees",
        "employeeDetails",
        localStorage.getItem("empId"),
      ]);
    } else {
      this.permService.navigateBasedOnPermission();
    }
    this.ngxLoader.stop();
  }

  checkValidation() {
    this.utility.formValidation();
    if (
      this.user.fullName == null ||
      this.user.fullName === "" ||
      this.user.emailAddress == null ||
      this.user.emailAddress === "" ||
      this.user.phoneNumber == null ||
      this.user.phoneNumber === "" ||
      this.user.countryOfResidence == null ||
      this.user.countryOfResidence === "" ||
      this.user.nationality == null ||
      this.user.nationality === "" ||
      this.user.companyName == null ||
      this.user.companyName === ""
    ) {
      if (this.user.fullName == null || this.user.fullName === "") {
        this.uName = true;
      }
      if (this.user.emailAddress == null || this.user.emailAddress === "") {
        this.uEmail = true;
      }
      if (this.user.phoneNumber == null || this.user.phoneNumber === "") {
        this.uContact = true;
      }
      if (
        this.user.countryOfResidence == null ||
        this.user.countryOfResidence === ""
      ) {
        this.uCor = true;
      }
      if (this.user.nationality == null || this.user.nationality === "") {
        this.uNation = true;
      }
      if (this.user.companyName == null || this.user.companyName === "") {
        this.uCompany = true;
      }
      this.toastr.error("Please fill all mandatory fields", "Failure!");
      return;
    } else {
      for (let i = 0; i < this.nomineeList.length; i++) {
        if (
          this.nomineeList[i].name == null ||
          this.nomineeList[i].name === ""
        ) {
          this.toastr.error("Name cannot be empty", "Error");
          return;
        }
        if (
          this.nomineeList[i].employeeRelationship == null ||
          this.nomineeList[i].employeeRelationship === ""
        ) {
          this.toastr.error("Relation cannot be empty", "Error");
          return;
        }
        if (
          this.nomineeList[i].percentageAlloted == null ||
          this.nomineeList[i].percentageAlloted === "" ||
          this.nomineeList[i].percentageAlloted == 0
        ) {
          this.toastr.error("Percentage cannot be empty", "Error");
          return;
        }
      }
      this.totPercent = 0;
      if (this.nomineeList != null && this.nomineeList.length > 0) {
        this.nomineeList.forEach((element) => {
          this.totPercent += element.percentageAlloted / 1;
        });
      }
      if (this.employee === true && this.totPercent !== 1) {
        this.toastr.error(
          "Total Nominees Percentages not adding upto 100",
          "Error"
        );
        return;
      }
      this.updateUserprofile();
    }
  }

  updateUserprofile() {
    if(!this.employee){
          this.authService.saveAdminSettings(this.settings).subscribe((_) => {});
    }
    const formData: FormData = new FormData();
    if (this.profilePicture != null) {
      formData.append(
        "profilePic",
        new File([this.profilePicture], this.fileName, { type: "image/jpeg" })
      );
    }
    formData.append("fullName", this.user.fullName);
    formData.append("emailAddress", this.user.emailAddress);
    formData.append("phoneNumber", this.user.phoneNumber);
    formData.append("pan", this.user.pan);
    formData.append("countryOfResidence", this.user.countryOfResidence);
    formData.append("nationality", this.user.nationality);
    formData.append("companyName", this.user.companyName);
    formData.append("designation", this.user.designation);
    formData.append("linkedInProfile", this.user.linkedInProfile);
    formData.append("gender", this.user.gender);
    new Response(formData).text().then(console.log);
    this.ngxLoader.start();
    let newDate = new Date();
    for (let i = 0; i < this.nomineeList.length; i++) {
      this.nomineeList[i]["employeeId"] = JSON.parse(
        sessionStorage.getItem("userDetails")
      )["employeeId"];
      this.nomineeList[i]["lastUpdate"] = newDate
        .toISOString()
        .replace("Z", "+0000");
    }

    this.authService.updateUser(formData).subscribe(
      (res) => {
        if (res["resultCode"] === "Success") {
          if (this.employee) {
            this.authService.saveEmpNominees(this.nomineeList).subscribe(
              (res) => {
                this.toastr.success(
                  "User profile and nominees updated successfully",
                  "Success!"
                );
                this.ngxLoader.stop();
                this.getUserdetails();
                this.navbarService.sendClickEvent();
                this.permService.navigateBasedOnPermission();
              },
              (e) => {
                this.ngxLoader.stop();
                this.toastr.error(e.error.reason, "Failure!");
              }
            );
          } else {
            this.toastr.success(
              "User profile updated successfully",
              "Success!"
            );
            this.ngxLoader.stop();
            this.getUserdetails();
            this.navbarService.sendClickEvent();
            this.permService.navigateBasedOnPermission();
          }
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Null response", "Failure!");
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  alphaNumeric(event: any) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (regex.test(str)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  maxNumber(val) {
    if (val.length > this.maxLength) {
      this.user.phoneNumber = val.slice(0, this.maxLength);
    }
  }

  getEvent() {
    this.shouldShowDialog = false;
    document.getElementById("overlay").style.display = "none";
  }

  openUploadImage() {
    this.shouldShowDialog = true;
  }

  onImageSaved(imageDetails) {
    this.shouldShowDialog = false;
    this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
    this.croppedImage = imageDetails.croppedImage;
    this.fileName = imageDetails.fileName;
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  // help section
  showHelp(section) {
    console.log("helper");
    if (section == this.helper) {
      return;
    }
    let targetElement;
    targetElement = document.getElementById(section);
    console.log(targetElement, targetElement.offsetTop);
    if (section == "Nominee_Table") {
      this.helperTopPosition = 700;
    } else {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "UserProfileComponent",
      section
    );
    this.helper = section;
  }

  hideHelp() {
    this.helper = "";
  }
}
