/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../login-signup/privacy-policy/privacy-policy.component.ngfactory";
import * as i4 from "../login-signup/privacy-policy/privacy-policy.component";
import * as i5 from "../login-signup/service-terms/service-terms.component.ngfactory";
import * as i6 from "../login-signup/service-terms/service-terms.component";
import * as i7 from "./footer-component.component";
import * as i8 from "../services/utilities.service";
var styles_FooterComponentComponent = [i0.styles];
var RenderType_FooterComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponentComponent, data: {} });
export { RenderType_FooterComponentComponent as RenderType_FooterComponentComponent };
export function View_FooterComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "footer-fixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["class", "row footer-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "col-xl-2 col-lg-3 col-md-3 col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 20, "div", [["class", "col-xl-8 col-lg-6 col-md-6 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "nav", [["class", "navbar-expand-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "nav", [["class", "navbar navbar-expand-sm"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.ɵm, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 8, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "li", [["class", "nav-item footer-sub-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "nav-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showServiceTermsPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms and Conditions"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "li", [["class", "nav-item footer-sub-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["class", "nav-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showPrivacyPolicyPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy Policy"])), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 5, "p", [["class", "footer-main-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hissa is the future of Equity Management Software \u00A0\u00A0 "])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "span", [["style", "color: gray;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u25CF Powered by "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "a", [["href", "https://www.rulezero.com/"], ["target", "blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["RuleZero "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["class", "col-xl-2 col-lg-3 col-md-3 col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "nav", [["class", "navbar-expand-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "div", [["id", "overlayfooter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "app-privacy-policy", [], null, [[null, "visibleChange"], [null, "showDialogChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = (_co.hidePopup() !== false);
        ad = (pd_0 && ad);
    } if (("showDialogChange" === en)) {
        var pd_1 = ((_co.showPrivacyPolicy = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PrivacyPolicyComponent_0, i3.RenderType_PrivacyPolicyComponent)), i1.ɵdid(29, 114688, null, 0, i4.PrivacyPolicyComponent, [], { showDialog: [0, "showDialog"] }, { visibleChange: "visibleChange" }), (_l()(), i1.ɵeld(30, 0, null, null, 1, "app-service-terms", [], null, [[null, "visibleChange"], [null, "showDialogChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = (_co.hidePopup() !== false);
        ad = (pd_0 && ad);
    } if (("showDialogChange" === en)) {
        var pd_1 = ((_co.showTermsOfService = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ServiceTermsComponent_0, i5.RenderType_ServiceTermsComponent)), i1.ɵdid(31, 114688, null, 0, i6.ServiceTermsComponent, [], { showDialog: [0, "showDialog"] }, { visibleChange: "visibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPrivacyPolicy; _ck(_v, 29, 0, currVal_0); var currVal_1 = _co.showTermsOfService; _ck(_v, 31, 0, currVal_1); }, null); }
export function View_FooterComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-component", [], null, null, null, View_FooterComponentComponent_0, RenderType_FooterComponentComponent)), i1.ɵdid(1, 114688, null, 0, i7.FooterComponentComponent, [i8.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentComponentNgFactory = i1.ɵccf("app-footer-component", i7.FooterComponentComponent, View_FooterComponentComponent_Host_0, {}, {}, []);
export { FooterComponentComponentNgFactory as FooterComponentComponentNgFactory };
