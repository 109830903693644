import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class SVGLoader {
  constructor(
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer
  ) {}
  svgs: { name: string; url: string }[] = [
    { name: "action_upload", url: "../../assets/upload.svg" },
    { name: "add_new_grey", url: "../../assets/action/addnew_grey.svg" },
    { name: "add_new", url: "../../assets/action/addnew.svg" },
    { name: "secondary_new", url: "../../assets/new_logo/secondary-new.svg" },
    { name: "secondary_sel", url: "../../assets/new_logo/secondary-sel.svg" },
    { name: "buyback_sel", url: "../../assets/new_logo/buyback-sel.svg" },
    { name: "buyback", url: "../../assets/new_logo/buyback.svg" },
    {
      name: "esop_overview_sel",
      url: "../../assets/new_logo/esop-overview-sel.svg",
    },
    { name: "esop_overview", url: "../../assets/new_logo/esop-overview.svg" },
    { name: "action_download", url: "../../assets/download.svg" },
    { name: "captable", url: "../../assets/captableIcon.svg" },
    { name: "action_share", url: "../../assets/share.svg" },
  ];
  loadSvgs() {
    const iconRegistry = this.iconRegistry;
    const sanitizer = this.sanitizer;
    this.svgs.forEach((svg: { name: string; url: string }) => {
      iconRegistry.addSvgIcon(
        svg.name,
        sanitizer.bypassSecurityTrustResourceUrl(svg.url)
      );
    });
  }
}
