/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./certificate-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@syncfusion/ej2-angular-documenteditor/ej2-angular-documenteditor.ngfactory";
import * as i8 from "@syncfusion/ej2-angular-documenteditor";
import * as i9 from "@angular/common";
import * as i10 from "./certificate-component.component";
import * as i11 from "../../../authentication.service";
import * as i12 from "ngx-ui-loader";
import * as i13 from "ngx-toastr";
import * as i14 from "@angular/platform-browser";
var styles_CertificateComponentComponent = [i0.styles];
var RenderType_CertificateComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CertificateComponentComponent, data: {} });
export { RenderType_CertificateComponentComponent as RenderType_CertificateComponentComponent };
function View_CertificateComponentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "dialogue-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Term Sheet"]))], null, null); }
function View_CertificateComponentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "dialogue-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Share Certificate"]))], null, null); }
function View_CertificateComponentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "img-response"], ["src", "../../../../assets/sharecertificate-01.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "row btm-20 float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "margin-right-20"], ["color", "success"], ["id", "exit"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Exit"]))], function (_ck, _v) { var currVal_3 = "success"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usedIn; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
function View_CertificateComponentComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["document_frame", 1]], null, 0, "iframe", [["class", "document-frame"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.certificateFile; _ck(_v, 0, 0, currVal_0); }); }
function View_CertificateComponentComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "ejs-documenteditorcontainer", [["height", "100%"], ["style", "display:block; overflow: hidden;"], ["width", "100%"]], [[8, "isReadOnly", 0], [8, "enablePrint", 0], [8, "zoomFactor", 0]], [[null, "serviceUrlChange"], [null, "created"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("serviceUrlChange" === en)) {
        var pd_0 = ((_co.service = $event) !== false);
        ad = (pd_0 && ad);
    } if (("created" === en)) {
        var pd_1 = (_co.onCreate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_DocumentEditorContainerComponent_0, i7.RenderType_DocumentEditorContainerComponent)), i1.ɵdid(1, 6537216, [[1, 4], ["documenteditor_default", 4]], 0, i8.DocumentEditorContainerComponent, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i1.Injector], { enableToolbar: [0, "enableToolbar"], height: [1, "height"], serviceUrl: [2, "serviceUrl"], showPropertiesPane: [3, "showPropertiesPane"], width: [4, "width"] }, { created: "created" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = false; var currVal_4 = "100%"; var currVal_5 = _co.service; var currVal_6 = false; var currVal_7 = "100%"; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = false; var currVal_2 = 1.25; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CertificateComponentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", " grant-letter-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CertificateComponentComponent_6)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CertificateComponentComponent_7)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "row top-20 certificate-exit-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["color", "success"], ["id", "exit"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Exit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isDocxFile && _co.certificateFile); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.isDocxFile; _ck(_v, 7, 0, currVal_1); var currVal_4 = "success"; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 10).disabled || null); var currVal_3 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_2, currVal_3); }); }
function View_CertificateComponentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "dialog overflow-scroll"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-xs-6 col-sm-6 col-md-6 custom-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CertificateComponentComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CertificateComponentComponent_3)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "col-xs-6 col-sm-6 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close top-close-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CertificateComponentComponent_4)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CertificateComponentComponent_5)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.usedIn == "termSheetsOverview"); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.usedIn != "termSheetsOverview"); _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.usedIn != "shareholderDetailsPage") && (_co.usedIn != "termSheetsOverview")); _ck(_v, 13, 0, currVal_2); var currVal_3 = ((_co.usedIn == "shareholderDetailsPage") || (_co.usedIn == "termSheetsOverview")); _ck(_v, 15, 0, currVal_3); }, null); }
export function View_CertificateComponentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { container: 0 }), i1.ɵqud(671088640, 2, { documentFrame: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CertificateComponentComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDialogCert; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CertificateComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-certificate-component", [], null, null, null, View_CertificateComponentComponent_0, RenderType_CertificateComponentComponent)), i1.ɵdid(1, 114688, null, 0, i10.CertificateComponentComponent, [i11.AuthenticationService, i12.NgxUiLoaderService, i13.ToastrService, i14.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CertificateComponentComponentNgFactory = i1.ɵccf("app-certificate-component", i10.CertificateComponentComponent, View_CertificateComponentComponent_Host_0, { showDialogCert: "showDialogCert", usedIn: "usedIn", investorId: "investorId" }, { visibleChangeCert: "visibleChangeCert" }, []);
export { CertificateComponentComponentNgFactory as CertificateComponentComponentNgFactory };
