import { Observable, BehaviorSubject } from 'rxjs';

export class Proforma {
       private data = new BehaviorSubject<boolean>(false);
       currentData = this.data.asObservable();

    constructor() { }

    setData(data) {
        this.data.next(data);
    }
}