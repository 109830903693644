<div class="container-fluid custom-fluid-container graybg">
  <div class="row top-20">
    <div class="col-md-12">
      <h5 class="page-hdr btm-15">Users</h5>
      <p class="content-pg-1">
        Users are the registered members of the organization authorized to manage the company’s data on the tool. 
        The user role decides the content accessible by the user. 
      </p>
    </div>
  </div>

  <div class="row top-20 btm-20">
    <div class="col-md-9" (mouseleave)="hideHelp()">
      <!-- User details -->
      <mat-card  id="Users" (mouseover)="showHelp('Users')">
        <mat-card-header>
          <mat-card-title>User Details</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>

          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1"> 
                Users can access and administer information across the different integrated services available on hissa. 
                All members registered with the company are listed below. Review the details. You can add/edit the fields if required.
              </p>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table-common top-20">
              <thead>
                <tr>
                  <th  class="width-15 text-align-left">User Name</th>
                  <th  class="width-15 text-align-left">Role</th>
                  <th  class="width-15 text-align-left">Organisation</th>
                  <th  class="width-15 text-align-left">Designation</th>
                  <th  class="width-15 text-align-left">Email</th>
                  <th  class="width-10 text-align-center">Approve</th>
                  <th  class="width-15"><img class="cursor-hand" src="../../assets/purple-plus-round.svg" (click)="addNewUsers()" ngbTooltip="Add new user"/>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let users of usersList; let i= index">
                  <td class="text-align-left">
                    <mat-label class="matLabelcls" *ngIf="editUsers != i">{{users.userName}}</mat-label>
                    <div *ngIf="editUsers == i">
                      <input title=""  type="text" class="form-control" [ngClass]=" mUser ? 'is-invalid' : 'is-valid' "
                      [(ngModel)]="users.userName"  #nameErr="ngModel" required>
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="nameErr.errors">
                        <span *ngIf="nameErr.errors?.required && mUser">
                            Username is required!
                        </span>
                      </div>
                    </div>
                  </td>

                  <td class="text-align-left">
                    <mat-label class="matLabelcls" *ngIf="editUsers != i">{{users.roleName}}</mat-label>
                    <div *ngIf="editUsers == i">
                      <select class="form-control cursor-hand" [(ngModel)]="users.roleName" #roleErr="ngModel" 
                        [ngClass]=" mrollName ? 'is-invalid' : 'is-valid' " required>
                        <option value="" selected disabled>Select Role</option>
                        <option *ngFor="let role of roleslist">{{role}}</option>
                      </select>
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="roleErr.errors">
                        <span *ngIf="roleErr.errors?.required && mrollName">
                            User role is required!
                        </span>
                      </div>
                    </div>
                  </td>

                  <td class="text-align-left">
                    <mat-label class="matLabelcls" *ngIf="editUsers != i">{{users.organisationName}}</mat-label>
                    <input title="" *ngIf="editUsers==i" type="text" class="form-control"
                      [(ngModel)]="users.organisationName" >
                  </td>

                  <td class="text-align-left">
                    <mat-label class="matLabelcls" *ngIf="editUsers != i">
                      {{users.userDesignation == null ? '-' : users.userDesignation}}</mat-label>
                    <div *ngIf="editUsers == i">
                      <select class="form-control cursor-hand" [(ngModel)]="users.userDesignation" #designationErr="ngModel" 
                        [ngClass]=" mDesignation ? 'is-invalid' : 'is-valid' " required>
                        <option selected default value="" disabled>Select Designation</option>
                        <option *ngFor="let designation of designationlist">{{designation}}</option>
                      </select>
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="designationErr.errors">
                        <span *ngIf="designationErr.errors?.required && mDesignation">
                            Designation is required!
                        </span>
                      </div>
                    </div>
                  </td>

                  <td class="text-align-left">
                    <mat-label class="matLabelcls" *ngIf="editUsers != i">{{users.email}}</mat-label>
                    <div *ngIf="editUsers == i">
                      <input type="email" title="" class="form-control" [(ngModel)]="users.email" #mailErr="ngModel" 
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}" [ngClass]=" mEmail ? 'is-invalid' : 'is-valid'" required> 
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="mailErr.errors">
                        <span *ngIf="mailErr.errors?.required && mEmail">
                            E-mail is required!
                        </span>
                        <span [hidden]="!mailErr.errors?.pattern">
                            Invalid E-mail!
                        </span>
                      </div> 
                    </div>
                  </td>

                  <td>
                    <img class="cursor-hand" *ngIf="(editUsers != i) && (users.isAllowAccess == null)" src="../../../assets/greentick.svg"
                      (click)="permissionGrant(i,users.id,true)" ngbTooltip="Grant Access">&nbsp;
                    <img class="cursor-hand" *ngIf="(editUsers != i) && (users.isAllowAccess == null)" src="../../../assets/exitred.svg"
                      (click)="permissionGrant(i,users.id,false)" ngbTooltip="Decline">
                    <img *ngIf="(editUsers != i) && (users.isAllowAccess == true)" src="../../../assets/greenfilltick.svg">
                    <img *ngIf="(editUsers != i) && (users.isAllowAccess == false)" src="../../../assets/exitredfill.svg">
                    <img *ngIf="(editUsers == i) && (users.isAllowAccess == false)" src="../../../assets/exitredfill.svg">
                    <img *ngIf="(editUsers == i) && (users.isAllowAccess == true)"  src="../../../assets/greenfilltick.svg">
                  </td>

                  <td>
                    <img *ngIf="editUsers != i && users.isAllowAccess" class="cursor-hand" ngbTooltip="Edit" src="../../assets/edit.svg"
                      (click)="activateEditmode(i)" />
                    <img *ngIf="editUsers == i" class="cursor-hand" src="../../assets/save.svg" ngbTooltip="Save" (click)="editUser(i,users.id)"> &nbsp;
                    <img *ngIf="editUsers == i" class="cursor-hand" ngbTooltip="Cancel" src="../../assets/cancel.svg"
                      (click)="deactivateEditmode()" />
                    <img *ngIf="editUsers != i && loggedUserId != users.id" class="cursor-hand" ngbTooltip="Delete User Permission" src="../../assets/trash-bin.svg"
                      (click)="deleteUser(users.id, users.userName)" />
                    <img *ngIf="editUsers != i && loggedUserId == users.id" class="hidden-image" src="../../assets/trash-bin.svg"/>
                  </td>
                </tr>

                <tr *ngFor="let newusers of users; let j= index">
                  <td class="error-message-set">
                    <input type="text" class="form-control" [(ngModel)]="newusers.userName"  #nameErr="ngModel" title="" 
                      [ngClass]=" mUser ? 'is-invalid' : 'is-valid' " placeholder="User Name" required>
                    <div class="alert alert-danger  text-align-left span-block" *ngIf="nameErr.errors">
                      <span *ngIf="nameErr.errors?.required && mUser">
                          Username is required!
                      </span>
                    </div>
                  </td>

                  <td class="error-message-set">
                    <select class="form-control cursor-hand" [(ngModel)]="newusers.roleName" #roleErr="ngModel" 
                      [ngClass]=" mrollName ? 'is-invalid' : 'is-valid' "required>
                      <option value="" selected disabled>Select Role</option>
                      <option *ngFor="let role of roleslist">{{role}}</option>
                    </select>
                    <div class="alert alert-danger  text-align-left span-block" *ngIf="roleErr.errors">
                      <span *ngIf="roleErr.errors?.required && mrollName">
                          User role is required!
                      </span>
                    </div>
                  </td>

                  <td class="error-message-set">
                    <input type="text" class="form-control" [(ngModel)]="newusers.organisationName" name="organisationName" [disabled]="true" title="" />
                  </td>

                  <td class="error-message-set">
                    <select class="form-control cursor-hand" [(ngModel)]="newusers.userDesignation" #designationErr="ngModel" 
                      [ngClass]=" mDesignation ? 'is-invalid' : 'is-valid' "required>
                      <option value="" selected default disabled>Select Designation</option>
                      <option *ngFor="let designation of designationlist">{{designation}}</option>
                    </select>
                    <div class="alert alert-danger  text-align-left span-block" *ngIf="designationErr.errors">
                      <span *ngIf="designationErr.errors?.required && mDesignation">
                          Designation is required!
                      </span>
                    </div>
                  </td>

                  <td class="error-message-set">
                    <input type="email" class="form-control" [(ngModel)]="newusers.email" title="" placeholder="Email"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}" #mailErr="ngModel" [ngClass]=" mEmail ? 'is-invalid' : 'is-valid'" required>
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="mailErr.errors">
                        <span *ngIf="mailErr.errors?.required && mEmail">
                            E-mail is required!
                        </span>
                        <span [hidden]="!mailErr.errors?.pattern">
                            Invalid E-mail!
                        </span>
                      </div>
                  </td>

                  <td>
                    <!-- <img *ngIf="(editUsers == i)" src="../../../assets/greenfilltick.svg"> -->
                  </td>

                  <td>
                    <img class="cursor-hand" src="../../../assets/save.svg" ngbTooltip="Register" (click)="saveNewUser(j)"> &nbsp;
                    <img class="cursor-hand" src="../../../assets/trash-bin.svg" ngbTooltip="Delete" (click)="deleteUsersRow(j)">
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- ----------Permissions------------ -->
      <mat-card class="top-20" id="Permissions" (mouseover)="showHelp('Permissions')">
        <mat-card-header>
          <mat-card-title>Permissions</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>

          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                You can set permissions for each user depending on their role. 
                Select the type of user from the drop down to set the permission handles by checking the appropriate boxes in the table below.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 top-20">
              <mat-label>Role</mat-label>
              <select class="form-control top-10 cursor-hand" required (change)="showRole($event.target.value)"
                [(ngModel)]="roleId">
                <option value="CreateNew">Create new role</option>
                <option *ngFor="let role of roleslist">{{role}}</option>
              </select>
            </div>
            <div class="col-md-4 top-20" *ngIf="isShowRole">
              <mat-label>Role Name</mat-label>
              <input type="text" class="form-control top-10" title="" [(ngModel)]="rolename" #roleNameErr="ngModel"
                placeholder="Role Name" required>
              <div *ngIf="roleNameErr.errors">
                <span *ngIf="(roleNameErr.dirty || roleNameErr.touched) && !mname" class="alert alert-danger">
                  Role Name is requred!</span>
                <span *ngIf="roleNameErr.errors?.required && mname" class="alert alert-danger">
                  Role Name is requred!!!
                </span>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table-common top-20">
              <thead>
                <tr>
                  <th class="text-align-left">Content</th>
                  <th class="text-align-centre">None</th>
                  <th class="text-align-centre">Summary</th>
                  <th class="text-align-centre">Viewer</th>
                  <th class="text-align-centre">Editor</th>
                  <th class="text-align-centre">Full</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-group [ngForOf]="permissionlist" let-j="index" *ngIf="!isShowRole">
                  <tr>
                    <td class="text-align-left">
                      <mat-label>{{group.moduleName}}</mat-label>&nbsp;

                      <span class="cursor-hand" *ngIf="!group.expanded && !expandAll && group.permissions != null"
                        (click)="group.expanded = true"><i class="fa fa-caret-right" aria-hidden="true"></i>
                      </span>

                      <span class="cursor-hand" *ngIf="group.expanded || expandAll && group.permissions != null"
                        (click)="group.expanded = false"><i class="fa fa-caret-down" aria-hidden="true"></i></span>

                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox [disabled]="!isEnabled" *ngIf="group.permissions == null"
                        [disableRipple]="disableripple" [(ngModel)]="group.none"
                        (change)="onChangeModule(j,group.none,'None')">
                        <span style="color: transparent;">.</span>
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox [disabled]="!isEnabled" *ngIf="group.permissions == null"
                        [disableRipple]="disableripple" [(ngModel)]="group.summary"
                        (change)="onChangeModule(j,group.summary,'Summary')">
                        <span style="color: transparent;">.</span>
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox [disabled]="!isEnabled" *ngIf="group.permissions == null"
                        [disableRipple]="disableripple" [(ngModel)]="group.viewer"
                        (change)="onChangeModule(j,group.viewer,'Viewer')">
                        <span style="color: transparent;">.</span>
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox [disabled]="!isEnabled" *ngIf="group.permissions == null"
                        [disableRipple]="disableripple" [(ngModel)]="group.editor"
                        (change)="onChangeModule(j,group.editor,'Editor')">
                        <span style="color: transparent;">.</span>
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox [disabled]="!isEnabled" *ngIf="group.permissions == null"
                        [disableRipple]="disableripple" [(ngModel)]="group.full"
                        (change)="onChangeModule(j,group.full,'Full')">
                        <span style="color: transparent;">.</span>
                      </mat-checkbox>
                    </td>
                    <td></td>
                  </tr>

                  <tr class="individual-row" [hidden]="!group.expanded && !expandAll"
                    *ngFor="let individual of group.permissions;let k=index">

                    <td class="text-align-left padding-left-tdlg">
                      <mat-label>{{ individual.moduleName }}</mat-label>
                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox [disabled]="!isEnabled" [disableRipple]="disableripple"
                        [(ngModel)]="individual.none" (change)="onChangeValue(j,k,individual.none,'None')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disabled]="!isEnabled" [disableRipple]="disableripple"
                        [(ngModel)]="individual.summary" (change)="onChangeValue(j,k,individual.summary,'Summary')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disabled]="!isEnabled" [disableRipple]="disableripple"
                        [(ngModel)]="individual.viewer" (change)="onChangeValue(j,k,individual.viewer,'Viewer')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disabled]="!isEnabled" [disableRipple]="disableripple"
                        [(ngModel)]="individual.editor" (change)="onChangeValue(j,k,individual.editor,'Editor')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disabled]="!isEnabled" [disableRipple]="disableripple"
                        [(ngModel)]="individual.full" (change)="onChangeValue(j,k,individual.full,'Full')">
                      </mat-checkbox>
                    </td>
                    <td></td>
                  </tr>
                </ng-template>

                <ng-template ngFor let-group [ngForOf]="newPermlist" let-m="index" *ngIf="isShowRole">
                  <tr>
                    <td class="text-align-left">
                      <mat-label>{{group.moduleName}}</mat-label>&nbsp;
                      <span class="cursor-hand" *ngIf="!group.expanded && !expandAll && group.permissions != null"
                        (click)="group.expanded = true"><i class="fa fa-caret-right" aria-hidden="true"></i>
                      </span>
                      <span class="cursor-hand" *ngIf="group.expanded || expandAll && group.permissions != null"
                        (click)="group.expanded = false"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox *ngIf="group.permissions == null" [disableRipple]="disableripple"
                        [(ngModel)]="group.none" (change)="changePermGroup(m,group.none,'None')">
                      </mat-checkbox>
                    </td>
                    <td class="text-align-centre">
                      <mat-checkbox *ngIf="group.permissions == null" [disableRipple]="disableripple" [disabled]="group.moduleName === 'Captable'"
                        [(ngModel)]="group.summary" (change)="changePermGroup(m,group.summary,'Summary')">
                      </mat-checkbox>
                    </td>
                    <td class="text-align-centre">
                      <mat-checkbox *ngIf="group.permissions == null" [disableRipple]="disableripple" [disabled]="group.moduleName === 'Captable'"
                        [(ngModel)]="group.viewer" (change)="changePermGroup(m,group.viewer,'Viewer')">
                      </mat-checkbox>
                    </td>
                    <td class="text-align-centre">
                      <mat-checkbox *ngIf="group.permissions == null" [disableRipple]="disableripple" [disabled]="group.moduleName === 'Captable'"
                        [(ngModel)]="group.editor" (change)="changePermGroup(m,group.editor,'Editor')">
                      </mat-checkbox>
                    </td>
                    <td class="text-align-centre">
                      <mat-checkbox *ngIf="group.permissions == null" [disableRipple]="disableripple"
                        [(ngModel)]="group.full" (change)="changePermGroup(m,group.full,'Full')">
                      </mat-checkbox>
                    </td>
                    <td>
                    </td>
                  </tr>

                  <tr class="individual-row" [hidden]="!group.expanded && !expandAll"
                    *ngFor="let individual of group.permissions;let n=index">

                    <td class="text-align-left padding-left-tdlg">
                      <mat-label>{{ individual.moduleName }}</mat-label>
                    </td>

                    <td class="text-align-centre">
                      <mat-checkbox [disableRipple]="disableripple" [(ngModel)]="individual.none"
                        (change)="changePermIndividual(m,n,individual.none,'None')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disableRipple]="disableripple" [(ngModel)]="individual.summary"
                        (change)="changePermIndividual(m,n,individual.summary,'Summary')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disableRipple]="disableripple" [(ngModel)]="individual.viewer"
                        (change)="changePermIndividual(m,n,individual.viewer,'Viewer')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disableRipple]="disableripple" [(ngModel)]="individual.editor"
                        (change)="changePermIndividual(m,n,individual.editor,'Editor')">
                      </mat-checkbox>
                    </td>

                    <td class="text-align-centre" placement="right" container="body">
                      <mat-checkbox [disableRipple]="disableripple" [(ngModel)]="individual.full"
                        (change)="changePermIndividual(m,n,individual.full,'Full')">
                      </mat-checkbox>
                    </td>
                    <td></td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>

          <div class="row  top-20">
            <div class="col-md-12">
              <button mat-raised-button is="save" color="success" [disabled]="!isEnabled" class="float-right left-10"
                [disableRipple]="disableripple" (click)="savePermissions()">
                Save
              </button>
              <button mat-raised-button id="cancel" [disabled]="!isEnabled" color="warn" class="float-right" [disableRipple]="disableripple"
                (click)="close()">
                Cancel
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Helper section -->
    <div class="col-md-3">
      <div class="helper-container">
          <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
            <mat-card-header class="help-hdr">
              <mat-card-title><label class="help-hdr-txt">{{
                  helpSections?.name
                }}</label></mat-card-title>
            </mat-card-header>
            <hr />
            <mat-card-content class="card-help-content">
              <div [innerHTML]="helpSections?.description"></div>
            </mat-card-content>
          </mat-card>
      </div>
    </div>
  </div>
</div>