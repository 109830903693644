
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { AuthenticationService } from "../../authentication.service";
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-angular-documenteditor';
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  @ViewChild("accordion") parentAccordion: MatAccordion;
  @ViewChild("esopAccordion") esopAccordion: MatAccordion;
  @ViewChild("sarAccordion") sarAccordion: MatAccordion;
  xpandStatus;
  esopFaqs = null
  editMode = false;
  originalEsopFaqs = null;
  isUserAdmin = false;
  color = 'accent';
  enableFaqToggle = false;
  editToggle;
 
  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
  ) {}
  ngOnInit() {
    this.isUserAdmin = localStorage.getItem("userRole") == "Admin";
    this.getFaq()
    this.getSettings()
  }
  changed(){
    this.editToggle = true;
  }
  getSettings() {
    this.ngxLoader.start();
    this.authService.getAdminSettings().subscribe(
      (res) => {
        this.ngxLoader.stop();
        this.enableFaqToggle = res['faqForEmployee'] || false
      },
      (_) => {
        this.toastr.error("Error while fetching settings", "Failure");
        this.ngxLoader.stop();
      }
    );
  }
  setFaq(){
    this.ngxLoader.start();
    if(this.editMode) {
      this.authService.setFaq(this.esopFaqs).subscribe(
        (res) => {
          this.ngxLoader.stop();
        },
        (e) => {
          this.editMode = false;
          this.esopFaqs = []
          this.ngxLoader.stop();
          this.getFaq()
          this.toastr.success("FAQs set successfully");
        }
      );
    } 
    if(this.editToggle) {
      this.authService.saveAdminSettings({
        faqForEmployee : this.enableFaqToggle
      }).subscribe(
        (res) => {
          this.ngxLoader.stop()
          this.editToggle = false;
          this.toastr.success("Changes saved successfully", "Success");
        },
        (e) => {
          this.ngxLoader.stop()
          this.editToggle = false;
          this.toastr.error("Error while saving changes", "Error");
        }
      );
    }
  }
  close(){
    this.editMode = false;
    this.editToggle = false;
    this.esopFaqs = []
    this.getFaq()
  }
  getFaq(){
    this.ngxLoader.start();
    this.authService.getFaq().subscribe(
      (res : []) => {
        this.esopFaqs = res || []
        if(this.esopFaqs.length) {
          this.esopFaqs = this.esopFaqs.filter(ele => {
            if(ele.question != '' || ele.answer != '') {
              return ele
            }
          })
        }
        this.originalEsopFaqs = this.esopFaqs
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }
  editFaq(index){
    this.esopFaqs[index].isEdit = !this.esopFaqs[index].isEdit
    this.editMode = true
  }
  addFaq(){
    if(!this.esopFaqs) {
      this.esopFaqs = []
    }
    this.esopFaqs.push({
      question: '',
      answer: '',
      isEdit: false
    })
    this.editMode = true;
  }
  deleteFaq(index) {
    this.esopFaqs.splice(index, 1)
    this.editMode = true;
  }
  openParentAccordions() {
    this.parentAccordion.openAll();
  }
  openEsopAccordion() {
    this.esopAccordion.openAll();
  }
  openSarAccordion() {
    this.sarAccordion.openAll();
  }
  openAllAccordions() {
    this.openParentAccordions();
    this.openEsopAccordion();
    this.openSarAccordion();
  }
  closeParentAccordions() {
    this.parentAccordion.closeAll();
  }
  closeEsopAccordions() {
    this.esopAccordion.closeAll();
  }
  closeSarAccordions() {
    this.sarAccordion.closeAll();
  }
  closeAllAccordions() {
    this.closeEsopAccordions();
    this.closeSarAccordions();
    this.closeParentAccordions();
  }
}