<div *ngIf="showDialogauthorized" class="dialog">

    <div class="col-xs-12 col-sm-12 col-md-12 header">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 top-20 dialogue-title">
               Update Authorized Capital
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 top-20">
                <button type="button" class="close dialogue-title" (click)="close()" aria-label="Close">
                    <span class="close">X</span>
                </button>
            </div>
        </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <div class="col-md-12">
        <div class="row">
            <div class="col-xs-7 col-sm-7 col-md-7">
                <div class="row top-20">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label class="required">New Authorized Capital</mat-label>
                        <input type="text" [(ngModel)]="authCapital" #authCapitalErr="ngModel"
                            class="form-control text-align-right top-10 number" title=""
                            (keydown)=onKeydownEvent($event) required />
                        <div *ngIf="authCapitalErr.errors">
                            <div *ngIf="(authCapitalErr.dirty || authCapitalErr.touched) && !mauthCapital" class="alert alert-danger">
                                Authorized Capital is required!
                            </div>
                            <div *ngIf="authCapitalErr.errors?.required && mauthCapital" class="alert alert-danger">
                                Authorized Capital is required!
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row top-20">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label class="required">Comments</mat-label>
                        <textarea class="form-control top-10" title="" type="text" [(ngModel)]="comments"
                            #commentsErr="ngModel" (ngModelChange)="validateComments(event)"></textarea>                       
                        <div *ngIf="mComments" class="alert alert-danger">
                            Comments is required!
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-5 col-sm-5 col-md-5  top-20">
                <mat-label class="required">Date</mat-label>
                <input class="form-control top-10" type="date" [(ngModel)]="authdate" title="" #dateErr="ngModel"
                (ngModelChange)="validateDate(event)" />               
                <div *ngIf="mauthDate" class="alert alert-danger">
                    Date is required!
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12 top-20">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <textarea class="form-control dynamic-box" type="text" placeholder="Dynamic text based on new authorized Capital"
                    title=""></textarea>
            </div>
        </div>
    </div>

    <div class="top-20 float-right btm-20">
        <div class="col-md-12">
            <button mat-raised-button id="cancel" color="warn" (click)="close()" ngbTooltip="Cancel"
                [disableRipple]="disableripple">
                Cancel
            </button>

            <button mat-raised-button id="save" color="success" class="left-20" (click)="createAuthCapiatal()" ngbTooltip="Save"
                [disableRipple]="disableripple">
                Save
            </button>
        </div>
    </div>

</div>