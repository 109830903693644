<div class="dialog">
  <div class="header">
   <div class="row">
     <div class="col-xs-9 col-sm-9 col-md-10 custom-modal-header">
       <label class="dialogue-title">Share Round Model</label>
     </div>
     <div class="col-xs-6 col-sm-6 col-md-2">
       <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
         <span class="close">X</span>
       </button>
     </div>
   </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="share-model-dialog">
    <div class="row" style="width:100%; justify-content: space-between;">
      <mat-form-field class="user-email-mat-form-field" floatLabel="always" appearance="outline">
        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedUserEmail" placeholder="Enter the user email">
        <mat-autocomplete [showPanel]="true" #auto="matAutocomplete" (optionSelected)="onUserSelectionChanged(myControl.value)">
          <mat-option *ngFor="let option of filteredOtherUsers | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button mat-raised-button color="success" ngbTooltip="Share Model to user" [disableRipple]="true"  class="top-2"
            (click)="addSelectedUsertoList()" hidden>Add User</button>
    </div>
    <div class="users-with-access" id="table-scroll">
      <table class="table-common">
        <tr>
          <th class="text-align-left">User Email</th>
          <th class="text-align-left current-access-col">Current Access</th>
          <th class="text-align-left">Action </th>
        </tr>
        <tr *ngFor="let modifiedUserAccess of modifiedUserList; let i= index">
          <td class="text-align-left">{{modifiedUserAccess.userEmail}}</td>
          <td class="text-align-left current-access-col">
            <select placeholder="Select Access" class="form-control cursor-hand" [disabled]="currentEditedUser != i"
              [(ngModel)]="modifiedUserAccess.permission" required>
              <option value="SHARE_MODEL_READ">Read</option>
              <option value="SHARE_MODEL_EDIT">Edit</option>
            </select>
          </td>
          <td>
            <img *ngIf="currentEditedUser == i" class="cursor-hand" src="../../../assets/save.svg" (click)="setAccess(i, modifiedUserAccess.permission)">
            <img *ngIf="currentEditedUser != i" class="cursor-hand" src="../../assets/edit.svg" (click)="editAccess(i)" /> <br>
            <img class="cursor-hand" src="../../assets/trash-bin.svg" (click)="deleteAccess(i)" />
          </td>
        </tr>
        <tr *ngIf="modifiedUserList.length == 0">
          <td class="text-align-center"></td>
        </tr>
      </table>
    </div>

    <div class="submit-access top-20">
      <button mat-raised-button color="success" ngbTooltip="Give Access to the users" class="float-right" (click)="giveAccess()">Submit Access</button>
    </div>
  </div>
</div>