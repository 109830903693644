import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FounderDetailsModel } from '../founder-details.model';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../../services/utilities.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-founder-details-popup',
  templateUrl: './founder-details-popup.component.html',
  styleUrls: ['./founder-details-popup.component.scss']
})
export class FounderDetailsPopupComponent implements OnInit {
  
  @ViewChild("foundForm") foundForm: NgForm;
  @Input() showDialog: boolean;
  @Output() closeDetails: EventEmitter<any> = new EventEmitter<any>();
  @Input() founderDetailsModel: FounderDetailsModel;
  public countryList;

  public nameValid: boolean = false;
  public designationValid: boolean = false;
  public emailValid: boolean = false;
  public nationalityValid: boolean = false;

  public isValid: boolean = false;
  public maxLength: number = 9;
  public founderDesignations: any = ['Founder', 'CFO', 'Director'];
  public selectedDesignation = "Founder";

  constructor(
    private toastr: ToastrService,
    private utility: UtilityService
  ){ }

  ngOnInit() {
    const { getNames } = require("country-list");
    this.countryList = getNames();
    if(this.founderDetailsModel.designation == "" || this.founderDetailsModel.designation == null){
      this.founderDetailsModel.designation = this.selectedDesignation;
    }
  }

  close() {
    this.closeDetails.emit(this.founderDetailsModel);
    this.foundForm.reset();
    this.nameValid = false;
    this.designationValid = false;
    this.nationalityValid = false;
    this.emailValid = false;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkValidation() {
    this.utility.formValidation();
    if (this.founderDetailsModel.fullName === "" || this.founderDetailsModel.fullName == null ||
        this.founderDetailsModel.designation === "" || this.founderDetailsModel.designation == null ||
        this.founderDetailsModel.nationality === "" || this.founderDetailsModel.nationality == null ||
        this.founderDetailsModel.emailId === "" || this.founderDetailsModel.emailId == null || 
        !this.validateEmail(this.founderDetailsModel.emailId)){

      if (this.founderDetailsModel.fullName === "" || this.founderDetailsModel.fullName == null) {
        this.nameValid = true;
      }
      if (this.founderDetailsModel.designation === "" || this.founderDetailsModel.designation == null) {
        this.designationValid = true;
      }
      if (this.founderDetailsModel.nationality === "" || this.founderDetailsModel.nationality == null) {
        this.nationalityValid = true;
      }
      if (this.founderDetailsModel.emailId === "" || this.founderDetailsModel.emailId == null) {
        this.emailValid = true;
      }
      return false;
    }
    else {
      return true;
    }
  }

  saveAndExit() {
    this.isValid = this.checkValidation();
    if (this.isValid == true) {
      this.founderDetailsModel.shouldShowDialog = false;
      this.showDialog = false;
      this.closeDetails.emit(this.founderDetailsModel);
    }
    else {
      this.toastr.error("Please fill all the required fields!!", "Error")
    }
  }

  maxNumber(val){
    if (val.length > this.maxLength) {
      this.founderDetailsModel.contactNumber = val.slice(0, this.maxLength)
    }
  }

}
