<div class="container-fluid custom-fluid-container graybg">
    <div class="whiteboxwrap">

  <div class="row">
      <div class="col-md-9" id="Create_NewBuyBack">

        <h5 class="page-hdr">Buy-back Creation</h5>

        <p class="content-pg-1 btm-20">
            The details of the last funding round raised by the company is below. The wizard provides the steps that go into completing and locking the buy-back transaction.
        </p>

          <!-- info tile -->
        <div class="row">
            <div class="col-md-12 ">
                <div class="back-card btm-20">
                    <div class="row">
                        <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                            <div class="btm-10">
                                <mat-label>Amount Raised ({{currencySymbol}})</mat-label>
                            </div>
                            <div>
                                <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(amountRaised)}}">{{ getTotalValueTble(amountRaised) }}</mat-label>
                            </div>                                
                        </div>
                        <div class="col-lg-1 col-md-4 col-sm-4 text-align-center">
                            <div class="btm-10">
                                <mat-label>Equity Shares</mat-label>
                            </div>
                            <div>
                                <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(equityShares)}}">{{getTotalValueTble(equityShares)}}</mat-label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                            <div class="btm-10">
                                <mat-label>Preference Shares</mat-label>
                            </div>
                            <div>
                                <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(preferenceShares)}}">{{getTotalValueTble(preferenceShares)}}</mat-label>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-4 col-sm-4 text-align-center">
                            <div class="btm-10">
                                <mat-label>No. of Rounds</mat-label>
                            </div>
                            <div>
                                <mat-label class="mat-label-value">{{numberOfRounds| CustomeCurrency: "2"}}</mat-label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                            <div class="btm-10">
                                <mat-label>Last Valuation (Post) ({{currencySymbol}})</mat-label>
                            </div>
                            <div>
                                <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(lastValuation)}}">{{ getTotalValueTble(lastValuation) }}</mat-label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 text-align-center custom-pad">
                            <div class="btm-10">
                                <mat-label>Last Raise ({{currencySymbol}})</mat-label>
                            </div>
                            <div>
                                <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(lastRaise)}}">{{ getTotalValueTble(lastRaise) }}</mat-label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 text-align-center custom-pad">
                        <div class="btm-10">
                            <mat-label>Last Share price ({{currencySymbol}})</mat-label>
                        </div>
                        <div>
                            <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(lastSharePrice)}}">{{ getTotalValueTble(lastSharePrice) }}</mat-label>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

          <!-- stepper -->
          <div class="row margin-zero">
              <div class="col-md-12 top-20">
                  <mat-horizontal-stepper [disableRipple]="true" labelPosition="bottom" #stepper [linear]="isLinear"
                      class="col-sm-12">
                      <mat-step [stepControl]="" disableRipple="true">
                          <ng-template matStepLabel>Buy-back
                              <mat-icon (click)="randomStep('buyBack')">
                                  <img src="{{buyBackTab}}" alt="Buy-back" />
                              </mat-icon>
                          </ng-template>
                      </mat-step>
                      <mat-step>
                          <ng-template matStepLabel>Shareholders
                              <mat-icon (click)="randomStep('shareholders')">
                                  <img src="{{shareholdersTab}}" alt="Shareholders" />
                              </mat-icon>
                          </ng-template>
                      </mat-step>
                      <mat-step>
                          <ng-template matStepLabel>Review
                              <mat-icon (click)="randomStep('review')">
                                  <img src="{{reviewTab}}" alt="Review" />
                              </mat-icon>
                          </ng-template>
                      </mat-step>
                      <mat-step>
                          <ng-template matStepLabel>Summary
                              <mat-icon (click)="randomStep('summary')">
                                  <img src="{{summaryTab}}" alt="Summary" />
                              </mat-icon>
                          </ng-template>
                      </mat-step>
                      <mat-step>
                          <ng-template matStepLabel>Confirm
                              <mat-icon (click)="randomStep('confirm')">
                                  <img src="{{confirmTab}}" alt="Confirm" />
                              </mat-icon>
                          </ng-template>
                      </mat-step>
                  </mat-horizontal-stepper>
              </div>
          </div>
      </div>
    

      <!-- Helper Section -->
      <div class="col-md-3">
          <div class="helper-container">
              <mat-card id="helperCard" class="card-help help-style" [hidden]="helper == ''">
                  <mat-card-header class="help-hdr">
                    <mat-card-title><label class="help-hdr-txt">{{
                          helpTitle
                      }}</label></mat-card-title>
                  </mat-card-header>
                  <hr />
                  <mat-card-content class="card-help-content">
                    <div [innerHTML]="helpMessage"></div>
                  </mat-card-content>
                </mat-card>
          </div>
      </div>
  </div>
</div>
  <div class="row">
      <div class="col-md-12">
          <!-- Component Section -->
          <div class="row">
              <div class="col-md-12 btm-20">
                  <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                  </router-outlet>
              </div>
          </div>
      </div>
  </div>
</div>