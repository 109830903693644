<div class="row">
  <div class="col-md-6 col-lg-8 col-xl-9">
    <mat-card id="Summary_Tab">
      <mat-card-header>
        <mat-card-title>Buy-back Summary</mat-card-title>
      </mat-card-header>
      <mat-divider class="divider-line"></mat-divider>
      <mat-card-content>
        <form class="needs-validation" novalidate>
          <div>
            <div class="row">
              <div class="col-md-12">
                <p class="content-pg-1 top-20 btm-20">
                  The summary of the entire buy-back round is below. The cap table reflects the resulting change in the shareholding structure of the company post the buy-back round.
                  <br><br>
                  If you are satisfied with how the cap table looks, click on the <b>Save and Continue</b> button.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <mat-card id="BuyBackDetails">
                  <div class="row">
                    <div class="col-md-4">
                      <mat-label>Buy-back Name</mat-label>
                      <p>{{buyBackDetails.buyBackName}}</p>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Buy-back Date</mat-label>
                      <p>{{startDate}}</p>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Security Type</mat-label>
                      <p>{{buyBackDetails.className}}</p>
                    </div>
                  </div>
                  <br><br>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-label>No of shares</mat-label>
                      <p >{{getTotalValueTble(buyBackDetails.numberOfSharesBoughtBackActual)}}</p>
                    </div>
                    <div class="col-md-6">
                      <mat-label>Price per share ({{currencySymbol}})</mat-label>
                      <p>{{getTotalValueTble(buyBackDetails.pricePerShare)}}</p>
                    </div>
                  </div>
                  <br><br>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-label>Dilution</mat-label>
                      <p>{{postBuyBackConsolidation}}%</p>
                    </div>
                    <div class="col-md-6">
                      <mat-label>Amount Bought Back ({{currencySymbol}})</mat-label>
                      <p ngbTooltip="{{formatCompanyCurrency(totalBuyBackAmount)}}">{{getTotalValueTble(totalBuyBackAmount)}}</p>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
          <br><br>
          <div class="row">
            <div class="col-md-12">
              <app-summary-cap-table [dataSource]="holdersList" [displayCols]="displayCols"></app-summary-cap-table>
            </div>
          </div>
          <br><br>
          <div class="row">
            <div class="col-md-8">
              <mat-label class="margin-left-20">Add Notes</mat-label>
              <textarea [(ngModel)]="note" class="form-control inputs top-10" [ngModelOptions]="{standalone: true}"
                #noteErr="ngModel" rows="2" placeholder="Add note" title="">
              </textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 top-20">
              <button mat-raised-button id="saveAndContinue" [disableRipple]="disableripple" color="success"
                  class=" pull-right" (click)="goToConfirm()" ngbTooltip="Save and Continue">Save and Continue
              </button>
              <button mat-raised-button id="previous" [disableRipple]="disableripple" color="warn"
                  class=" pull-right margin-right-20" (click)="goToReview()"
                  ngbTooltip="Previous">Previous
              </button>
              <button mat-raised-button id="discard" [disableRipple]="disableripple" color="warn"
                  class=" pull-right margin-right-20" (click)="discardBuyBack()"
                  ngbTooltip="Discard Buy-Back">Discard
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>