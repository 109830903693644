<div class="row" (mouseleave)="hideHelp()">
    <div class="col-md-9" id="Summary_Tab" (mouseover)="showHelp('Summary_Tab')">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Round Summary</mat-card-title>
            </mat-card-header>
            <mat-divider class="divider-line"></mat-divider>
            <mat-card-content>
                <div>
                    <div id="overlay"></div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="content-pg-1 top-20 btm-10">
                                All the round information is summarized in the tables below.<br><br>
                                The cap table displays the capital structure of the company post the new round. 
                                If the currency of investment is different from the currency of the  company, click on the <b>currency converter</b> icon in the table.<br><br>
                                Once you are satisfied with all the details, click on <b>‘Create Round’</b>.
                            </p>

                            <div *ngIf="this.roundCurrency != this.companyCurrency && this.roundCurrency != null"
                                class="alert alert-info alert-labeled alrt-mrgn top-20 btm-20">
                                <p type="button" class="close-help" data-dismiss="alert">
                                    <img src="../../../assets/message-close.png" />
                                </p>
                                <div class="alert-labeled-row">
                                    <div class="alert-label alert-label-left alert-labelled-cell">
                                        <img src="../../../assets/message-info.svg" />
                                    </div>
                                    <p class="content-pg-1 alert-body alert-body-right alert-labelled-cell">
                                        The Round currency you have selected ( {{this.roundCurrency}} ) is different
                                        from Company currency ( {{this.companyCurrency}} )
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 btm-20">
                            <div class="col-md-12 back-card div-round-details">
                                <div *ngIf="roundInformation">
                                    <div class="row btm-20">
                                        <div class="col-lg-4 col-md-4 col-sm-5">
                                            <mat-label>Round Name</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value">
                                                    {{roundInformation.roundName === "" ? '-' : roundInformation.roundName }}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4">
                                            <mat-label>Round Date</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value">
                                                    {{roundInformation.roundDate == null ? '-' : roundInformation.roundDate | date:'dd-MMM-yyyy' }}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4">
                                            <mat-label>Priced Round</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value" *ngIf="roundInformation.isPricedRound === true">Yes</mat-label>
                                                <mat-label class="mat-label-value" *ngIf="roundInformation.isPricedRound === false">No</mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row btm-20">
                                        <div class="col-lg-4 col-md-4 col-sm-5">
                                            <mat-label>Amount Raised ({{roundCurrencySymbol}})</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value" ngbTooltip="{{formatCurrency(roundInformation.amountRaised)}}">
                                                    {{ roundInformation.amountRaised == null ? '-' : getTotalValueTble(roundInformation.amountRaised) }}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-5">
                                            <mat-label>Pre Money ({{roundCurrencySymbol}})</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value" ngbTooltip="{{formatCurrency(roundInformation.preMoneyValuation)}}">
                                                    {{ roundInformation.preMoneyValuation == null ? '-' : getTotalValueTble(roundInformation.preMoneyValuation)}}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4">
                                            <mat-label>Post Money ({{roundCurrencySymbol}})</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value" ngbTooltip="{{formatCurrency(roundInformation.postMoneyValuation)}}">
                                                    {{ roundInformation.postMoneyValuation == null ? '-' : getTotalValueTble(roundInformation.postMoneyValuation) }}
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row btm-20">
                                        <div class="col-lg-4 col-md-4 col-sm-4">
                                            <mat-label>Round Security</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value">
                                                    {{ roundInformation.roundInstrument == null ? '-' : roundInformation.roundInstrument }}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-3">
                                            <mat-label>Dilution</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value" ngbTooltip="{{ roundInformation.dilutionPercentage | percent: '2.2'}}">
                                                    {{ roundInformation.dilutionPercentage == null ? '-' : roundInformation.dilutionPercentage | percent:'2.2' }}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-3">
                                            <mat-label>No of Shares</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value" ngbTooltip="{{formatCurrency(roundInformation.numberOfShares)}}">
                                                    {{ roundInformation.numberOfShares == null ? '-' : getTotalValueTble(roundInformation.numberOfShares) }}
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row btm-20">
                                        <div class="col-lg-4 col-md-4 col-sm-3">
                                            <mat-label>Issuance Type</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value">
                                                    {{ roundInformation.issuanceType == null ? '-' : roundInformation.issuanceType }}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-3">
                                            <mat-label>Price per Share ({{roundCurrencySymbol}})</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value">
                                                    {{ roundInformation.pricePerShare == null ? '-' : formatCurrency(roundInformation.pricePerShare) }}
                                                </mat-label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-3">
                                            <mat-label>No of Nominal Equity Shares</mat-label>
                                            <div>
                                                <mat-label class="mat-label-value" ngbTooltip="{{formatCurrency(roundInformation.numberOfNominalEquityShares)}}">
                                                    {{ roundInformation.numberOfNominalEquityShares == null ? '-' : getTotalValueTble(roundInformation.numberOfNominalEquityShares) }}
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <mat-label>Round Description</mat-label>
                                            <div class="top-10">
                                                <textarea class="form-control" readonly> {{roundInformation.roundDescription}}
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12  btm-20">
                            <div class="back-card">
                                    <div class="row" *ngIf="!isChartView">
                                        <div class="col-md-1"></div>
                                        <div class="col-md-5">
                                            <mat-label>Investors</mat-label>
                                        </div>
                                        <div class="col-md-4 text-align-right">
                                            <mat-label>Invested Amount ({{roundCurrencySymbol}})</mat-label>
                                        </div>
                                        <div class="col-md-1 text-align-right">
                                            <img class="cursor-hand" ngbTooltip="Chart View" *ngIf="!isChartView" src="../../assets/chart.svg"
                                                (click)="InvestorsView()" />
                                        </div>
                                    </div>
                
                                    <div class="row" *ngIf="isChartView">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-8 text-align-center">
                                            <mat-label> Investors </mat-label>
                                        </div>
                                        <div class="col-md-1 text-align-right">
                                            <img class="cursor-hand" ngbTooltip="List View" src="../../assets/list.svg" (click)="InvestorsView()" />
                                        </div>
                                    </div>
                                <!-- List View -->
                                <div *ngIf="!isChartView" class="content-height">
                                    <div class="row" *ngFor="let investor of roundInformation?.investors; let i = index">
                                        <div class="col-md-1">
                                            <img *ngIf="investor.isLeadInvestor"
                                                src="../../../assets/lead-investor-purple.svg" />
                                        </div>
                                        <div class="col-md-5">
                                            <p class="mat-label-value text-wrap" placement="right"
                                            ngbTooltip="{{ investor.investorName }}">{{ investor.investorName }}</p>
                                        </div>
                                        <div class="col-md-4 text-align-right">
                                            <mat-label class="mat-label-value" placement="left"
                                                ngbTooltip="{{ formatCurrency(investor.investmentAmount)}}">
                                                {{ getTotalValueTble(investor.investmentAmount) }}
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row border-top total-row">
                                        <div class="col-md-1 top-20"> 
                                        </div>
                                        <div class="col-md-5 top-20">
                                            <mat-label>Total</mat-label>
                                        </div>
                                        <div class="col-md-4 top-20 text-align-right">
                                            <mat-label placement="left"
                                                ngbTooltip="{{ formatCurrency(investorsTotAmt)}}">
                                                {{ getTotalValueTble(investorsTotAmt) }}
                                            </mat-label>
                                        </div>
                                        <div class="col-md-2"> 
                                        </div>
                                    </div>
                                </div>
                                <!-- Chart View -->
                                <div *ngIf="isChartView">
                                    <!-- <div class="row"> -->
                                        <div class="col-md-12">
                                            <!-- <span class="graph-content"> -->
                                                <dx-pie-chart style="width: 100%;" id="pie" type="doughnut" 
                                                resolveLabelOverlapping="shift"
                                                [palette]="[
                                                    '#4d1981',
                                                    '#F4522B',
                                                    '#e0a41e',
                                                    '#ffe82d',
                                                    '#b80d5e',
                                                    '#ff1382',
                                                    '#1e7bcf',
                                                    '#2598ff',
                                                    '#7f9c25',
                                                    '#9dc02e'
                                                ]"
                                                [dataSource]="investorChart" diameter="0.6">
                                                    <dxi-series argumentField="shareholder">
                                                        <dxo-label [visible]="true" position="inside" backgroundColor="none"
                                                            [customizeText]="customizeText" format="decimal">
                                                            <dxo-font color="#ffffff" weight="bold" size="10"></dxo-font>
                                                            <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                                                        </dxo-label>
                                                    </dxi-series>
                                                    <dxo-legend [visible]="true" position="outside" verticalAlignment="bottom"  [customizeItems]="sortLegendItems"
                                                        horizontalAlignment="center" itemTextPosition="right" orientation="horizontal">
                                                        <dxo-font family="Roboto" color="#000000" size="14" weight="400"></dxo-font>
                                                        <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                                                    </dxo-legend>
                                                    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"
                                                        format="decimal" opacity="0.8" [border]="{
                                                        opacity: '0',
                                                        visible: false
                                                        }" paddingLeftRight="6" paddingTopBottom="6">
                                                    </dxo-tooltip>
                                                </dx-pie-chart>
                                            <!-- </span> -->
                                        </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row btm-20">
                        <div class="col-lg-4 col-md-5 col-sm-5">
                            <select class="form-control input-fields customSelect cursor-hand" #investors
                                (change)="filterByInvestor(selectedinvestorsnme)" [(ngModel)]="selectedinvestorsnme">
                                <option class="select-optn" value="all" [selected]="true">All Investors</option>
                                <option *ngFor="let investorsnme of invstorNmeLst; let k = index"
                                    [ngValue]="investorsnme">
                                    {{ investorsnme }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-5">
                            <select class="form-control input-fields customSelect cursor-hand" #tranches
                                (change)="filterByTranches(selectedtranch)" [(ngModel)]="selectedtranch">
                                <option *ngFor="let tranch of trancheList; let i = index" [ngValue]="tranch">{{tranch}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-md-2 col-sm-2">
                            <!-- <div><img title="Export" (click)="exportReport()"
                                src="../../../assets/download.svg" style="height: 35px; margin-top: 5px;" /></div> -->
                            <mat-form-field appearance="outline">
                                <mat-label>Export</mat-label>
                                <mat-select>
                                    <mat-option (click)="exportReport()" >Export</mat-option>
                                    <mat-option (click)="exportPas4Report()">Export PAS4 Report</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- trnch filter -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table-common btm-20">
                                    <thead>
                                        <tr>
                                            <th class=" text-align-left">Investor Group
                                                <span ngbTooltip="Expand/Collapse all" class="cursor-hand"
                                                    *ngIf="!expandAll" (click)="expandAllItem(false)"><img
                                                        src="../../assets/expandcollbox.svg" /></span>
                                                <span ngbTooltip="Expand/Collapse all" class="cursor-hand"
                                                    *ngIf="expandAll" (click)="expandAllItem(false)"><img
                                                        src="../../assets/expandcollbox.svg" /></span>
                                            </th>
                                            <th class="text-align-left">Rounds</th>
                                            <th class="text-align-right">Pre Holding %</th>
                                            <th class=" text-align-right">Post Holding %</th>
                                            <th class="text-align-right">
                                                <span *ngIf="roundInformation.roundInstrument == 'CCD'">No of Debentures</span>
                                                <span *ngIf="roundInformation.roundInstrument == 'Note'">No of Notes</span>
                                                <span *ngIf="roundInformation.roundInstrument != 'CCD' && roundInformation.roundInstrument != 'Note'">No of Shares</span>
                                            </th>
                                            <th class="text-align-right">FDB Shares</th>
                                            <th class="text-align-right">Amount Invested</th>
                                            <th class="text-align-right" style="width: 20%;">Currency Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr *ngIf="trnchesInformation==null">
                                            <td colspan="7">
                                                <span class="txt-nodata text-align-center">No Data</span>
                                            </td>
                                        </tr> -->

                                        <!-- template 1  -->
                                        <ng-template *ngIf="!showOnlyInvestor && trnchesInformation!=null" ngFor
                                            let-group [ngForOf]="trnchesInformation.shareholderTypes" let-i="index">

                                            <!-- top  -->
                                            <tr>
                                                <td class="text-align-left">
                                                    <span class="cursor-hand" *ngIf="!group.expanded && !expandAll"
                                                        (click)="group.expanded = true">
                                                        <span class="cursor-hand margin-right-sm">{{ group.investorGroup }}</span>
                                                        <i class="fa fa-caret-right"
                                                            aria-hidden="true"></i></span>
                                                    <span class="cursor-hand" *ngIf="group.expanded || expandAll"
                                                        (click)="group.expanded = false && individualgroup.expanded = false">
                                                        <span class="cursor-hand margin-right-sm">{{ group.investorGroup }}</span>
                                                        <i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                                </td>
                                                <td class="roundListGrids">
                                                    <span *ngFor="
                                                        let round of group.roundDetails;
                                                        let j = index
                                                        " class="rnd-identifier"
                                                        [ngClass]="identifierCSS(round.roundIdentifier)"
                                                        ngbTooltip={{round.roundName}}>
                                                        {{ round.roundIdentifier }}
                                                    </span>
                                                </td>
                                                <td class="text-align-right">
                                                    {{ group.preHoldingPercentage | percent : '2.2' }}
                                                </td>
                                                <td class="text-align-right">
                                                    {{ group.postHoldingPercentage | percent : '2.2' }}
                                                </td>
                                                <td class="text-align-right" container="body"
                                                    ngbTooltip="{{ formatCurrency(group.numberOfShares) }}">
                                                    {{ getTotalValueTble(group.numberOfShares) }}
                                                </td>
                                                <td class="text-align-right" container="body"
                                                    ngbTooltip="{{ formatCurrency(group.numberOfDilutedShares) }}">
                                                    {{ getTotalValueTble(group.numberOfDilutedShares) }}
                                                </td>
                                                <td class="text-align-right" container="body"
                                                    ngbTooltip="{{ showCurrency(group.amountInvested) }}">
                                                    {{ getTotalValueTble(group.amountInvested) }}
                                                </td>
                                                <td id="input_container">
                                                    <input type="text" id="input1"
                                                        class="form-control text-align-right wide-input"
                                                        [(ngModel)]="group.currencyAmount" title="" readonly />
                                                </td>
                                            </tr>

                                            <!-- Group  Expanded-->
                                            <tr class="individual-row" [hidden]="!group.expanded && !expandAll" *ngFor="let individual of group.shareholders;let m=index">
                                                <td class="text-align-left"  (click)="displayShareholderDet(individual.id)" style="padding-left:20px !important;">
                                                    {{ individual.name }}
                                                </td>
                                                <td class="roundListGrids">
                                                    <span *ngFor="let round of individual.roundDetails"
                                                        class="rnd-identifier"
                                                        [ngClass]="identifierCSS(round.roundIdentifier)"
                                                        ngbTooltip={{round.roundName}}>
                                                        {{ round.roundIdentifier }}
                                                    </span>
                                                </td>
                                                <td class="text-align-right">
                                                    {{ individual.preHoldingPercentage | percent : '2.2' }}
                                                </td>
                                                <td class="text-align-right">
                                                    {{ individual.postHoldingPercentage | percent : '2.2' }}
                                                </td>
                                                <td class="text-align-right" container="body" ngbTooltip="{{ formatCurrency(individual.numberOfShares) }}">
                                                    {{ getTotalValueTble(individual.numberOfShares) }}
                                                </td>
                                                <td class="text-align-right" container="body" ngbTooltip="{{ formatCurrency(individual.numberOfDilutedShares) }}">
                                                    {{ getTotalValueTble(individual.numberOfDilutedShares) }}
                                                </td>
                                                <td class="text-align-right" container="body"
                                                    ngbTooltip="{{ showCurrency(individual.amountInvested) }}">
                                                    {{ getTotalValueTble(individual.amountInvested) }}</td>
                                                <td id="input_container">
                                                    <img id="input_img" src="../../../assets/accounting.svg"
                                                        *ngIf="individual.amountInvested !=0"
                                                        ngbTooltip="Currency Converter" class="cursor-hand"
                                                        (click)="convertCurrency(individual)" />
                                                    <input type="text" class="form-control text-align-center"
                                                        [ngModel]="getCurrencyconvert(individual.currencyAmount)"
                                                        [readonly]="individual.amountInvested==0" (change)="fieldEdited($event)"
                                                        (keypress)="onlyNumberKey($event)" />

                                                    <div class="icon-swap input-group-append"
                                                        *ngIf="individual.currencyAmount !=0">
                                                        <span>
                                                            <button id="{{individual.name}}RC" disabled="true"
                                                                (click)="showRndCurrency(individual)"
                                                                class="round-currency-label btn active rc-label">{{individual.currencyConverter.currencyOfInvestment}}</button>
                                                            <img class="round-currency-img"
                                                                src="../../../assets/currency-convert.svg" />
                                                            <button id="{{individual.name}}CC"
                                                                (click)="showCompCurrency(individual)"
                                                                class="round-currency-label btn inactive cc-label">{{companyCurrency}}</button>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>

                                            <!-- template nested -->
                                            <ng-template ngFor let-individualgroup [ngForOf]="group.shareholderGroups"
                                                let-j="index">

                                                <!-- group expanded -->
                                                <tr [hidden]="!group.expanded && !expandAll">
                                                    <td class="text-align-left left-padd-20">
                                                        <span
                                                            *ngIf="!individualgroup.expanded"
                                                            (click)="individualgroup.expanded = true">
                                                            <span class="cursor-hand margin-right-sm">{{ individualgroup.name }}</span>
                                                            <i class="fa fa-caret-right" aria-hidden="true"></i></span>
                                                        <span
                                                            *ngIf="individualgroup.expanded"
                                                            (click)="individualgroup.expanded = false">
                                                            <span class="cursor-hand margin-right-sm">{{ individualgroup.name }}</span>
                                                            <i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                                    </td>
                                                    <td class="roundListGrids">
                                                        <span *ngFor="let round of individualgroup.roundDetails"
                                                            class="rnd-identifier"
                                                            [ngClass]="identifierCSS(round.roundIdentifier)"
                                                            ngbTooltip={{round.roundName}}>
                                                            {{ round.roundIdentifier }}
                                                        </span>
                                                    </td>
                                                    <td class="text-align-right">
                                                        {{ individualgroup.preHoldingPercentage | percent : '2.2' }}
                                                    </td>
                                                    <td class="text-align-right">
                                                        {{ individualgroup.postHoldingPercentage | percent : '2.2' }}
                                                    </td>
                                                    <td class="text-align-right"  container="body" ngbTooltip="{{ formatCurrency(individualgroup.numberOfShares) }}">
                                                        {{getTotalValueTble(individualgroup.numberOfShares)}}
                                                    </td>
                                                    <td class="text-align-right"  container="body" ngbTooltip="{{ formatCurrency(individualgroup.numberOfDilutedShares) }}">
                                                        {{getTotalValueTble(individualgroup.numberOfDilutedShares)}}
                                                    </td>
                                                    <td class="text-align-right" container="body"
                                                        ngbTooltip="{{ showCurrency(individualgroup.amountInvested) }}">
                                                        {{ getTotalValueTble(individualgroup.amountInvested) }}
                                                    </td>
                                                    <td id="input_container">
                                                        <input id="input1" type="text"
                                                            class="form-control text-align-right"
                                                            [(ngModel)]="individualgroup.currencyAmount" title=""
                                                            readonly />
                                                    </td>
                                                </tr>

                                                <!-- Individual group  Expanded-->
                                                <tr [hidden]="(!individualgroup.expanded || !group.expanded) && !expandAll" *ngFor="let fund of individualgroup.shareholderDetails">
                                                    <td (click)="displayShareholderDet(fund.id)" class="text-align-left" style="padding-left:40px !important;">
                                                        {{fund.name}}

                                                    </td>
                                                    <td class="roundListGrids">
                                                        <span *ngFor="let round of fund.roundDetails"
                                                            class="rnd-identifier"
                                                            [ngClass]="identifierCSS(round.roundIdentifier)"
                                                            ngbTooltip={{round.roundName}}>
                                                            {{ round.roundIdentifier }}
                                                        </span>
                                                    </td>
                                                    <td class="text-align-right">
                                                        {{ fund.preHoldingPercentage | percent : '2.2' }}
                                                    </td>
                                                    <td class="text-align-right">
                                                        {{ fund.postHoldingPercentage | percent : '2.2' }}
                                                    </td>
                                                    <td class="text-align-right" container="body" ngbTooltip="{{ formatCurrency(fund.numberOfShares) }}">
                                                        {{getTotalValueTble(fund.numberOfShares)}}
                                                    </td>
                                                    <td class="text-align-right" container="body" ngbTooltip="{{ formatCurrency(fund.numberOfDilutedShares) }}">
                                                        {{getTotalValueTble(fund.numberOfDilutedShares)}}
                                                    </td>
                                                    <td class="text-align-right" container="body"
                                                        ngbTooltip="{{ showCurrency(fund.amountInvested) }}">
                                                        {{ getTotalValueTble(fund.amountInvested) }}</td>
                                                    <td id="input_container">
                                                        <img id="input_img" src="../../../assets/accounting.svg"
                                                            ngbTooltip="Currency Converter" class="cursor-hand"
                                                            *ngIf="fund.currencyAmount !=0"
                                                            (click)="convertCurrency(fund)" />
                                                        <input type="text" class="form-control text-align-center"
                                                            [ngModel]="getCurrencyconvert(fund.currencyAmount)" (change)="fieldEdited($event)"
                                                            (keydown)=onKeydownEvent($event) title="" />
                                                        <div class="icon-swap input-group-append"
                                                            *ngIf="fund.currencyAmount !=0">
                                                            <span>
                                                                <button id="{{fund.name}}RC" disabled="true"
                                                                    (click)="showRndCurrency(fund)"
                                                                    class="round-currency-label btn active rc-label">{{fund.currencyConverter.currencyOfInvestment}}</button>
                                                                <img class="round-currency-img"
                                                                    src="../../../assets/currency-convert.svg" />
                                                                <button id="{{fund.name}}CC"
                                                                    (click)="showCompCurrency(fund)"
                                                                    class="round-currency-label btn inactive cc-label">{{companyCurrency}}</button>
                                                            </span>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </ng-template>

                                        </ng-template>

                                        <!-- Information By Investors -->
                                        <tr *ngIf="showOnlyInvestor" class="individual-row">
                                            <td class="text-align-left left-padd-40"
                                                (click)="displayShareholderDet(showOnlyInvestorInfo.id)">
                                                {{ showOnlyInvestorInfo.name }}
                                            </td>
                                            <td class="roundListGrids">
                                                <span *ngFor="let round of showOnlyInvestorInfo.roundDetails"
                                                    class="rnd-identifier"
                                                    [ngClass]="identifierCSS(round.roundIdentifier)"
                                                    ngbTooltip={{round.roundName}}>
                                                    {{ round.roundIdentifier }}
                                                </span>
                                            </td>
                                            <td class="text-align-right">
                                                {{ showOnlyInvestorInfo.preHoldingPercentage | percent : '2.2' }}
                                            </td>
                                            <td class="text-align-right">
                                                {{ showOnlyInvestorInfo.postHoldingPercentage | percent : '2.2' }}
                                            </td>
                                            <td class="text-align-right" container="body"
                                                ngbTooltip="{{ formatCurrency(showOnlyInvestorInfo.numberOfShares) }}">
                                                {{getTotalValueTble(showOnlyInvestorInfo.numberOfShares)}}
                                            </td>
                                            <td class="text-align-right" container="body"
                                                ngbTooltip="{{ formatCurrency(showOnlyInvestorInfo.numberOfDilutedShares) }}">
                                                {{getTotalValueTble(showOnlyInvestorInfo.numberOfDilutedShares)}}
                                            </td>
                                            <td class="text-align-right" container="body"
                                                ngbTooltip="{{ showCurrency(showOnlyInvestorInfo.amountInvested) }}">
                                                {{ getTotalValueTble(showOnlyInvestorInfo.amountInvested) }}
                                            </td>
                                            <td id="input_container">
                                                <img id="input_img" src="../../../assets/accounting.svg"
                                                    ngbTooltip="Currency Converter" class="cursor-hand"
                                                    *ngIf="showOnlyInvestorInfo.currencyAmount !=0"
                                                    (click)="convertCurrency(showOnlyInvestorInfo)" />
                                                <input id="input1" type="text" class="form-control text-align-center"
                                                    [ngModel]="getCurrencyconvert(showOnlyInvestorInfo.currencyAmount)"
                                                    (keydown)=onKeydownEvent($event) (change)="fieldEdited($event)" title="" />
                                                <div class="icon-swap input-group-append"
                                                    *ngIf="showOnlyInvestorInfo.currencyAmount !=0">
                                                    <span>
                                                        <button id="{{showOnlyInvestorInfo.name}}RC" disabled="true"
                                                            (click)="showRndCurrency(showOnlyInvestorInfo)"
                                                            class="round-currency-label btn active rc-label">{{showOnlyInvestorInfo.currencyConverter.currencyOfInvestment}}</button>
                                                        <img class="round-currency-img"
                                                            src="../../../assets/currency-convert.svg"
                                                            *ngIf="showOnlyInvestorInfo.currencyAmount !=0" />
                                                        <button id="{{showOnlyInvestorInfo.name}}CC"
                                                            (click)="showCompCurrency(showOnlyInvestorInfo)"
                                                            class="round-currency-label btn inactive cc-label">{{companyCurrency}}</button>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="top-10" *ngIf="!showOnlyInvestor && trnchesInformation!=null">                                           
                                            <td colspan="6" class="text-align-right">
                                                <mat-label>Total amount invested  &nbsp;  {{getTotalValueTble(amountRaised)}}</mat-label> &nbsp;
                                                <mat-label *ngIf="!isInvestmentMet"
                                                    class="speech-bubble-red bubble-txt new-inv-label">{{getTotalValueTble(amountLefttoRaised)}}
                                                    Remaining</mat-label>
                                                <mat-label *ngIf="isInvestmentMet"
                                                    class="speech-bubble-green bubble-txt new-inv-label">
                                                    Investment Met</mat-label>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12 text-align-right notes-tbl btm-20">
                                <span>All numbers are in {{roundCurrency}} unless explicitly mentioned</span>
                            </div>
                        </div>
                    </div>

                    <!-- Notes -->
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-lg-12 btm-20">
                            <div *ngIf="!showNotes" (click)="addNotes(true)"><span><mat-label>Add Notes</mat-label><img class="left-10 cursor-hand" src="../../../assets/action/addnotes.svg" alt="add Notes"/></span></div>
                            <div *ngIf="showNotes" (click)="addNotes(false)"><span><mat-label>Remove Notes</mat-label><img class="left-10 cursor-hand" src="../../../assets/action/removenotes.svg" alt="remove Notes"/></span></div>
                            <textarea class="form-control top-10" *ngIf="showNotes" [(ngModel)]="roundInformation.sideNote" (change)="fieldEdited($event)"
                                style="width:100%" placeholder="Add Notes"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button id="createRound" [disableRipple]="disableripple" color="success" class="pull-right"
                                (click)="SaveRound()" ngbTooltip="Create Round">
                                Create Round
                            </button>
                            <button mat-raised-button id="previous" [disableRipple]="disableripple" color="success"
                                class="pull-right margin-right-20" (click)="goToTranches()" ngbTooltip="Previous">
                                Previous
                            </button>
                            <button mat-raised-button id="saveForLater" color="success" [disableRipple]="disableripple"
                                class="pull-right margin-right-20" (click)="Saveforlater()" ngbTooltip="Save for later">
                                Save for later
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <app-currency-convertor *ngIf="showCurConvertor" [(investor)]="investor" [(isDigShow)]="showCurConvertor"
            [(homeCurrency)]="homeCurrency" (visibleChange)="getEvent()"></app-currency-convertor>
    </div>
    <!-- Helper section -->
    <div class="col-md-3">
        <div class="helper-container">
            <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
              <mat-card-header class="help-hdr">
                <mat-card-title><label class="help-hdr-txt">{{
                    helpSections?.name
                  }}</label></mat-card-title>
              </mat-card-header>
              <hr />
              <mat-card-content class="card-help-content">
                <div [innerHTML]="helpSections?.description"></div>
              </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>