<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">Send Share Certificate</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <form [formGroup]="certificateForm" novalidate>
    <div class="row section-padding">
      <div class="col-md-6">
        <mat-label>Shareholder Name</mat-label>
        <div>
          <mat-label class="mat-label-value">
            {{ shareCertificateDialog.shareholderName }}
          </mat-label>
        </div>
      </div>
  
      <div class="col-md-6">
        <mat-label class="required">Email</mat-label>
        <input class="form-control top-10" type="text" formControlName="email" placeholder="Enter Email" required/>
        <div class="alert alert-danger" *ngIf=" (certificateForm?.get('email').errors?.required) && (certificateForm?.get('email').dirty || certificateForm?.get('email').touched || isSubmitted)">
          Email is required!
        </div>
        <div class="alert alert-danger" *ngIf=" (certificateForm?.get('email').errors?.email)" >
          Email is invalid!
        </div>
      </div>
    </div>
    <div class="row btm-20 section-padding">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <button mat-raised-button id="save" color="success" type="button" [disableRipple]="true" ngbTooltip="Save" class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="generate()">
          Save
        </button>
        <button mat-raised-button id="cancel" color="warn" type="button" [disableRipple]="true" ngbTooltip="Cancel" class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
          Cancel
        </button>
      </div>
    </div>
  </form>

</div>