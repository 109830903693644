<div class="container-fluid custom-fluid-container graybg">
    <div class="row cmncnt-ver-spacing">
        <div class="col-md-12">
            <label class="header-level-1"><img src="../../../assets/left-arrow.svg" (click)="goToDocument()" />
        <span class="left-10">Create Round Document Set</span></label
      >
    </div>
  </div>
  <div class="row left-28 right-10">
    <div class="col-md-12">
      <p class="content-pg-1">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s
      </p>
    </div>
  </div>
  <div class="row cmn-border left-40 bg-color right-10">
    <div class="col-md-1 margin-10">
      <label class="lvp-label">Round</label>
        </div>
        <div class="col-md-4 margin-10">
            <select class="form-control " #selShareholder (change)="filterData(selShareholder.value)">
        <option class="select-optn">All Shareholders</option>
        <!-- <option *ngFor="let name of shareholderNames; let i = index">{{ name }}</option> -->
      </select>
        </div>
    </div>
    <div class="row left-28 cmncnt-ver-spacing right-10">
        <div class="col-md-12">
            <p class="content-pg-1">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
            </p>
        </div>
    </div>
    <div class="row left-40 cmn-border right-10">
        <div class="margin-20">
            <div class="row">
                <img class="avatar-sizing" src="../../../assets/person 01.png" />
                <img class="avatar-sizing" src="../../../assets/person 02.png" />
                <img class="avatar-sizing" src="../../../assets/person 03.png" />
                <img class="avatar-sizing" src="../../../assets/person 04.png" />
                <img class="avatar-sizing" src="../../../assets/person 05.png" />
                <img class="avatar-sizing" src="../../../assets/person 06.png" />
            </div>
            <div class="row">
                <label class="lvp-label">Amount Raised 1000</label>
            </div>
        </div>
    </div>
    <div class="row left-28 cmncnt-ver-spacing right-10">
        <div class="col-md-12">
            <label class="lvp-label">
        Deal Documents
      </label>
        </div>
    </div>
    <div class="row left-40 cmn-border right-10">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <p class="content-pg-1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    </p>
                </div>
            </div>
            <div class="row cmncnt-ver-spacing-btm">
                <div class="col-md-12">
                    <table class="table-common">
                        <thead>
                            <tr>
                                <th class=" text-align-left">Document</th>
                                <th class="">File Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-align-left">
                                    <span class="lvp-label">Document Title</span>
                                    <p class="">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </td>
                                <td class="lvp-label">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Document Title.docx</span>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td><img src="../../../assets/purple-plus-round.svg" /></td>
                            </tr>
                            <tr>
                                <td class="text-align-left">
                                    <span class="lvp-label">Term Sheet</span>
                                    <p class="">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </td>
                                <td class="lvp-label">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Document Title.docx</span>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td><img src="../../../assets/purple-plus-round.svg" /></td>
                            </tr>
                            <tr>
                                <td class="text-align-left">
                                    <span class="lvp-label">SSA/SHA</span>
                                    <p class="">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Document Title.docx</span>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td><img src="../../../assets/purple-plus-round.svg" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row left-28 cmncnt-ver-spacing right-10">
        <div class="col-md-12">
            <label class="lvp-label">
        Issue Process and Checklist
      </label>
        </div>
    </div>
    <div class="row left-40 cmn-border right-10">
        <div class="col-md-12">
            <div class="row cmn-border bg-color">
                <div class="col-md-8 ">
                    <div class="row">
                        <div class="col-md-2">
                            <label class="lvp-label">Issue</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control">
                <option class="">Private Placement</option>
              </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 ">
                    <button class="cmn-button float-right" ngbTooltip="Verify Document">Verify Document</button>
                </div>
            </div>
            <div class="row cmncnt-ver-spacing">
                <div class="col-md-12">
                    <p class="content-pg-1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    </p>
                </div>
            </div>
            <div class="row cmncnt-ver-spacing-btm">
                <div class="col-md-12">
                    <table class="table-common">
                        <thead>
                            <tr>
                                <th></th>
                                <th class=" text-align-left">Activity</th>
                                <th class="">Templates</th>
                                <th class="">Files</th>
                                <th class="">Status</th>
                                <th class="">Signed Docs</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="checkbox" /></td>
                                <td class="text-align-left">
                                    <input type="text" class="form-control" />
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <select class="form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td class="lvp-label">
                                    <p>Published</p>
                                </td>
                                <td class="lvp-label">
                                    <p>Signed Documents</p>
                                </td>
                                <td><img src="../../../assets/purple-plus-round.svg" /></td>
                            </tr>
                            <tr>
                                <td><input type="checkbox" /></td>
                                <td class="text-align-left">
                                    <input type="text" class=" form-control" />
                                </td>
                                <td class="lvp-label">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td class="lvp-label">
                                    <p>Published</p>
                                </td>
                                <td class="lvp-label">
                                    <p>Signed Documents</p>
                                </td>
                                <td><img src="../../../assets/purple-plus-round.svg" /></td>
                            </tr>
                            <tr>
                                <td><input type="checkbox" /></td>
                                <td class="text-align-left">
                                    <input type="text" class=" form-control" />
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td class="lvp-label">
                                    <p>Published</p>
                                </td>
                                <td class="lvp-label">
                                    <p>Signed Documents</p>
                                </td>
                                <td><img src="../../../assets/purple-plus-round.svg" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row left-28 cmncnt-ver-spacing right-10">
        <div class="col-md-12">
            <label class="lvp-label">
        Fillings
      </label>
        </div>
    </div>
    <div class="row left-40 cmn-border right-10">
        <div class="col-md-12">
            <div class="row cmn-border bg-color cmncnt-ver-spacing-btm">
                <div class="col-md-12">
                    <p class="content-pg-1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    </p>
                </div>
            </div>
            <div class="row cmncnt-ver-spacing-btm">
                <div class="col-md-12">
                    <table class="table-common">
                        <thead>
                            <tr>
                                <th class=" text-align-left">Document</th>
                                <th class="">File Name</th>
                                <th class="">Signed Files</th>
                                <th class="">ROC Acknowledgement</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-align-left">
                                    <label class="lvp-label">MGT-14</label>
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-align-left">
                                    <label class="lvp-label">PAS3</label>
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-align-left">
                                    <label class="lvp-label">PAS4</label>
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-2">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                                <td class="">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <select class=" form-control">
                        <option class=""
                          >Rights issue template</option
                        >
                      </select>
                                        </div>
                                        <div class="col-md-1">
                                            <img class="img-icon" src="../../../assets/download.svg" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row left-28 cmncnt-ver-spacing right-10">
        <div class="col-md-12">
            <label class="lvp-label">
        Checklist
      </label>
        </div>
    </div>
    <div class="row left-40 cmncnt-ver-spacing cmn-border right-10">
        <div class="col-md-12">
            <div class="row cmncnt-ver-spacing-btm cmncnt-ver-spacing">
                <div class="col-md-1">
                    <input type="checkbox" />
                </div>
            </div>
            <div class="row cmncnt-ver-spacing-btm">
                <div class="col-md-1">
                    <input type="checkbox" selected />
                </div>
            </div>
            <div class="row cmncnt-ver-spacing-btm">
                <div class="col-md-1">
                    <input type="checkbox" selected />
                </div>
            </div>
        </div>
    </div>
</div>
