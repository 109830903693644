import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class NavbarService {
    constructor(private router: Router){}

    private subject = new Subject<any>();
    public requireReload = false;
    sendClickEvent() {
        this.subject.next();
    }
    getClickEvent(): Observable<any>{ 
        return this.subject.asObservable();
    }

    isHistoricalRound(): boolean{
    if(this.router.url == "/landing/onboarding/create-historical-round" || this.router.url == "/landing/onboarding/historical-round-summary"){
        return true;
    }
    else{
        return false;
    }
}

}