/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./account-confirm.component";
import * as i4 from "@angular/router";
import * as i5 from "../../authentication.service";
import * as i6 from "ngx-ui-loader";
import * as i7 from "ngx-toastr";
var styles_AccountConfirmComponent = [i0.styles];
var RenderType_AccountConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountConfirmComponent, data: {} });
export { RenderType_AccountConfirmComponent as RenderType_AccountConfirmComponent };
function View_AccountConfirmComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" with the email address "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" is verified successfully. The admin can now access and manage the "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u2018s data anytime, anywhere. The admin will be notified by an email. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.emailAddress; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.companyName; _ck(_v, 8, 0, currVal_2); }); }
function View_AccountConfirmComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The verification link is expired. "]))], null, null); }
function View_AccountConfirmComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountConfirmComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountConfirmComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.varified; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isLinkExpired; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AccountConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountConfirmComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCompleted; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AccountConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-confirm", [], null, null, null, View_AccountConfirmComponent_0, RenderType_AccountConfirmComponent)), i1.ɵdid(1, 114688, null, 0, i3.AccountConfirmComponent, [i4.Router, i4.ActivatedRoute, i5.AuthenticationService, i6.NgxUiLoaderService, i7.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountConfirmComponentNgFactory = i1.ɵccf("app-account-confirm", i3.AccountConfirmComponent, View_AccountConfirmComponent_Host_0, {}, {}, []);
export { AccountConfirmComponentNgFactory as AccountConfirmComponentNgFactory };
