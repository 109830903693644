export class DirectorDetailsModel
  {
    id:number;
    fullName: string;
    typeOfDirector: string;
    din: string;
    emailId: string;
    address: string;
    contactNumber: string;
    dateOfBirth: string;
    nationality: string;
    dateOfAppointement: string;
    dateOfBoardResoluitonForAppointement: string;
    isSelected: boolean;
    shouldShowDialog: boolean;
    isEdit:boolean = false;
    dateOfBoardResoluitonForCessation:string;
    dateOfCessation:string;
}