import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable()
export class EventEmitterService {    
    
  invokeSwitchCompanyFunction = new EventEmitter();    
  subsVar: Subscription;    
    
  constructor() { }    
    
  onSwitchCompanyButtonClick(companyId) {    
    this.invokeSwitchCompanyFunction.emit(companyId);    
  }    
}    