<div id="overlay" [ngClass]="{'display-on': showDialog}"></div>

<div *ngIf="showDialog" class="dialog">

  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">Director Details</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container-fluid content-row">
    <form #dirForm = "ngForm" class="row top-20 needs-validation" novalidate>
      <div class="col-md-6">
        <div>
          <mat-label class="required">Name</mat-label>
          <input type="text" class="form-control top-10" [(ngModel)]="directorDetailsModel.fullName" #nameErr="ngModel"
            title="" placeholder="Enter director name" [ngModelOptions]="{standalone: true}" required>
          <div *ngIf="nameErr.errors">
            <span class="alert alert-danger" *ngIf="(nameErr.dirty || nameErr.touched) && !nameValid">
              Name is required!
            </span>
            <span class="alert alert-danger" *ngIf="nameErr.errors?.required && nameValid">
              Name is required!
            </span>
          </div>
        </div>

        <div class="top-20">
          <mat-label class="required">Type of Director</mat-label>
          <select class="form-control top-10 cursor-hand" id="typeOfTheCompany" [(ngModel)]="directorDetailsModel.typeOfDirector"
            #typeDirErr="ngModel" title="" [ngModelOptions]="{standalone: true}" required>
            <option [disabled]="directorDetailsModel && directorDetailsModel.typeOfDirector" value="" selected>Select
              type of the Director</option>
            <option value="Executive director">Executive director</option>
            <option value="Non-Executive Director">Non-Executive Director</option>
            <option value="Managing director">Managing director</option>
            <option value="Independent director">Independent director</option>
            <option value="Residential director">Residential director</option>
          </select>
          <div *ngIf="typeDirErr.errors">
            <span class="alert alert-danger" *ngIf="(typeDirErr.dirty || typeDirErr.touched) && !dierctorTypeValid">
              Type of Director is required!
            </span>
            <span class="alert alert-danger" *ngIf="typeDirErr.errors?.required  && dierctorTypeValid">
              Type of Director is required!
            </span>
          </div>
        </div>

        <div class="top-20">
          <mat-label>Address</mat-label>
          <textarea class="form-control top-10" [(ngModel)]="directorDetailsModel.address" title="" rows="3" 
          [ngModelOptions]="{standalone: true}" placeholder="Address"></textarea>
        </div>

        <div class="top-20">
          <mat-label>Date of Birth</mat-label>
          <div class="input-eyePlaceholder">
            <input matInput [matDatepicker]="dp1" max="9999-12-31" class="form-control top-10" [(ngModel)]="directorDetailsModel.dateOfBirth"
                placeholder="DD-MMM-YYYY" title="" [ngModelOptions]="{standalone: true}">
            <mat-datepicker-toggle matSuffix [for]="dp1">
              <mat-icon matDatepickerToggleIcon></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp1></mat-datepicker>
          </div>
        </div>

        <div class="top-20">
          <mat-label>Date of appointment</mat-label>
          <div class="input-eyePlaceholder">
            <input matInput [matDatepicker]="dp2" class="form-control top-10" [(ngModel)]="directorDetailsModel.dateOfAppointement"
              #appointDtErr="ngModel" placeholder="DD-MMM-YYYY" title="" max="9999-12-31" [ngModelOptions]="{standalone: true}">
            <mat-datepicker-toggle matSuffix [for]="dp2">
              <mat-icon matDatepickerToggleIcon></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp2></mat-datepicker>
          </div>
          <div *ngIf="appointDtErr.errors">
            <span class="alert alert-danger"
              *ngIf="(appointDtErr.dirty || appointDtErr.touched) && !appointmentDtValid">
              Date of appointment is required!
            </span>
            <span class="alert alert-danger" *ngIf="appointDtErr.errors?.required  && appointmentDtValid">
              Date of appointment is required!
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div>
          <mat-label class="required">Email ID</mat-label>
          <input type="email" class="form-control top-10" [(ngModel)]="directorDetailsModel.emailId" [ngModelOptions]="{standalone: true}"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" title="" #directorEmailId="ngModel" placeholder="Enter email" required>
          <div class="alert alert-danger" *ngIf="directorEmailId.errors">
            <div [hidden]="!directorEmailId.errors?.pattern">
              Invalid Email!
            </div>
            <div *ngIf="directorEmailId.errors?.required && emailValid">
              Email is required!
            </div>
          </div>
        </div>

        <div class="top-20">
          <mat-label class="required">DIN Number</mat-label>
          <input type="text" class="form-control top-10" [(ngModel)]="directorDetailsModel.din" #dinNoErr="ngModel"
            (keypress)="allowAlphaNumericKey($event)" placeholder="Enter DIN number" [ngModelOptions]="{standalone: true}" required>
          <div *ngIf="dinNoErr.errors">
            <span class="alert alert-danger" *ngIf="(dinNoErr.dirty || dinNoErr.touched) && !dinNoValid">
              DIN Number is required!
            </span>
            <span class="alert alert-danger" *ngIf="dinNoErr.errors?.required  && dinNoValid">
              DIN Number is required!
            </span>
          </div>
        </div>

        <div class="top-20">
          <mat-label>Contact Number</mat-label>
          <input type="number" class="form-control top-10" [(ngModel)]="directorDetailsModel.contactNumber" title="" 
          (keypress)="maxNumber($event.target.value)" placeholder="Contact number" [ngModelOptions]="{standalone: true}">
        </div>

        <div class="top-20">
          <mat-label class="required">Nationality</mat-label>
          <select class="form-control top-10 cursor-hand" [(ngModel)]='directorDetailsModel.nationality' #nationalityErr="ngModel"
            title="" [ngModelOptions]="{standalone: true}" required>
            <option [disabled]="directorDetailsModel && directorDetailsModel.nationality" value="" [selected]="true">
              Select Country</option>
            <option *ngFor='let countryLst of countryList; let k = index'>{{countryLst}}
            </option>
          </select>
          <div *ngIf="nationalityErr.errors">
            <span class="alert alert-danger" *ngIf="(nationalityErr.dirty || nationalityErr.touched) && !nationalityValid">
              Nationality is required!
            </span>
            <span class="alert alert-danger" *ngIf="nationalityErr.errors?.required  && nationalityValid">
              Nationality is required!
            </span>
          </div>
        </div>

        <div class="top-20">
          <mat-label>Date of board resolution for appointment</mat-label>
          <div class="input-eyePlaceholder">
              <input matInput [matDatepicker]="dp" class="form-control top-10"
              [(ngModel)]="directorDetailsModel.dateOfBoardResoluitonForAppointement" #boardResAppErr="ngModel" title=""
              placeholder="DD-MMM-YYYY" max="9999-12-31" [ngModelOptions]="{standalone: true}">
              <mat-datepicker-toggle matSuffix [for]="dp">
                <mat-icon matDatepickerToggleIcon></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #dp></mat-datepicker>
          </div>
          <div *ngIf="boardResAppErr.errors">
            <span class="alert alert-danger"
              *ngIf="(boardResAppErr.dirty || boardResAppErr.touched) && !boardresAppDtValid">
              Date of board resolution for appointment is required!
            </span>
            <span class="alert alert-danger" *ngIf="boardResAppErr.errors?.required  && boardresAppDtValid">
              Date of board resolution for appointment is required!
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-12 btm-20">
        <button mat-raised-button id="saveAndExit" color="success" class="float-right left-20" [disableRipple]="true"
          (click)="saveAndExit();" ngbTooltip="Save and Exit">
          Save and Exit
        </button>
        <button mat-raised-button id="cancel" color="warn" class="float-right" [disableRipple]="true" (click)="close();"
          ngbTooltip="Cancel">
          Cancel
        </button>
      </div>
    </form>
  </div>

</div>