import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-angular-documenteditor';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss']
})
export class PreviewDocumentComponent implements OnInit {

  documentList:Array<any>;
  selectedDocumentIndex:number = 0;
  isWordFile:boolean = false;
  isPdfFile:boolean = false;
  fileType:string = "";
  pdfFile:any = null;
  wordFile:any = null;

  stepPlanActivityId:number;
  stepPlanInstanceId:number;

  selectedDocument:any;

  resolutionName:string;

  @ViewChild('documenteditor_default') public container: DocumentEditorContainerComponent;
  @ViewChild('document_frame_plan') public documentFrame: ElementRef;
  fileEditingObject = {
    sfdtFormat : '',
    currentFileName:"",
  }

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((params)=>{
      this.stepPlanActivityId = params["id"];
      this.stepPlanInstanceId = params["stepPlanInstanceId"];
    });
   }

  ngOnInit() {
    this.ngxLoader.start();
    this.getDocumentList()
      .then((res) => {
        this.onChangeDocument(0, null)
      }).then((res) => {
        this.getDocument()
      }).then((res) => {
        this.ngxLoader.stop();
      });
  }

  getDocumentList():Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.getTemplatesByStepPlanActivity(this.stepPlanActivityId).subscribe((res:any) => {
        this.documentList = res;
        if(this.documentList && this.documentList.length == 0) {
          this.toastr.warning("No document has been built yet");
          resolve(false);
        }
        this.resolutionName = this.documentList[0].name;
        resolve(true);
      }, e => {
        this.toastr.warning(e.error.reason, "Failure");
        resolve(false);
      })
    })
  }

  onChangeDocument(index:number, event:any):Promise<boolean> {
    return new Promise((resolve) => {
      if(this.documentList.length == 0 ) {
        resolve(false);
      }
      this.selectedDocumentIndex = index;
      this.selectedDocument = this.documentList[this.selectedDocumentIndex];
      this.getDocument();
      if(event) {
        event.preventDefault();
        event.stopPropagation();
      }
      resolve(true);
    })
  }

  remove_(str){
    return str.replace(/_/g, ' ');
  }

  onCreate(): void {
    this.container.documentEditor.fitPage('FitPageWidth');
    this.container.documentEditor.isReadOnly;
    this.container.documentEditor.open(this.fileEditingObject.sfdtFormat);
    this.container.documentEditor.documentName = this.fileEditingObject.currentFileName;  
    this.container.restrictEditing = true;
    this.container.headers = [{
      'x-user-token': localStorage.getItem('currentUser')
    }];
    
  }

  getDocument():Promise<boolean> {
    this.ngxLoader.start();
    return new Promise((resolve, reject) => {

      if(!this.selectedDocument) {
        resolve(false);
      }

      if(this.selectedDocument.documentAssemblerId && this.selectedDocument.documentAssemblerId > 0) {
        this.getWordDocument().then((res => {
          this.ngxLoader.stop();
          resolve(true);
        }));
      }

      else {
        this.getPdfDocument().then((res => {
          this.ngxLoader.stop();
          resolve(true);
        }));
      }
    })
    
  }

  getWordDocument():Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.getSFDTformat(this.selectedDocument.documentAssemblerId).subscribe((res: any)=>{
      
        this.fileType = res.headers.get("fileType");
        if(this.fileType == 'text/plain' || this.fileType == 'application/json') {
          const blb  = new Blob([res.body], {type: this.fileType});
          let reader = new FileReader();
          let self = this;
          reader.onload = function() {
            self.fileEditingObject.sfdtFormat = reader.result.toString();
            self.fileEditingObject.currentFileName = "Grant-Letter.docx"
            self.isWordFile = true;
            self.isPdfFile = false;
            self.container.documentEditor.open(self.fileEditingObject.sfdtFormat);
            self.container.documentEditor.documentName = self.fileEditingObject.currentFileName;  
            self.container.restrictEditing = true;
            resolve(true);
          }
          reader.readAsText(blb);
        }
        else {
          let file = new Blob([res.body], { type: this.fileType });
          let url = URL.createObjectURL(file);            
          this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(url + '#zoom=100');
          this.isWordFile = false;
          this.isPdfFile = true;
          resolve(true);
        }
        
        },
        (e)=>{
          this.fileEditingObject.sfdtFormat = '';
          this.toastr.error(e.error.reason, "Failure!");
          resolve(false);
        });
    })
  }

  getPdfDocument() {

    return new Promise((resolve, reject) => {
      this.authService.getStepPlanActivityPdfDocument(this.selectedDocument.pdfDocumentId).subscribe((res: any)=>{
      
        this.fileType = res.headers.get("fileType");
        let file = new Blob([res.body], { type: this.fileType });
          let url = URL.createObjectURL(file);            
          this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(url + '#zoom=100');
          this.isWordFile = false;
          this.isPdfFile = true;
          resolve(true);
        
        },
        (e)=>{
          this.fileEditingObject.sfdtFormat = '';
          this.toastr.error(e.error.reason, "Failure!");
          resolve(false);
        });
    })
  }

  downloadResolutionDocument(document) {
    
    if(document.documentAssemblerId) {
      this.ngxLoader.start();
      let documentAssemblerId:number = document.documentAssemblerId;
      this.authService.downloadStepPlanActivityDocument(documentAssemblerId).subscribe((res:HttpResponse<any>) => {
        this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      }, e=> {
        this.ngxLoader.stop();
        this.toastr.warning(e.error.reason, "Failure");
      })
    }

    else {
      this.ngxLoader.start();
      let pdfDocumentId:number = document.pdfDocumentId;
      this.authService.getStepPlanActivityPdfDocument(pdfDocumentId).subscribe((res:HttpResponse<any>) => {
        this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      }, e=> {
        this.ngxLoader.stop();
        this.toastr.warning(e.error.reason, "Failure");
      })
    }
  }

  downloadAllDocuments() {
    this.ngxLoader.start();
    this.authService.downloadAllStepPlanActivityDocuments(this.stepPlanActivityId).subscribe((res:HttpResponse<any>) => {
      this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
      this.ngxLoader.stop();
    }, e=> {
      this.ngxLoader.stop();
      this.toastr.warning(e.error.reason, "Failure");
    })
  }

  onBack() {
    this.router.navigate(["/landing/dashboard/task-manager/activities", this.stepPlanInstanceId, '']);
  }

  onRebuild() {

    let documentAssemblerId = 0;
    let templateId = 0;

    this.documentList.forEach((document:any) => {
      let template = document;

      if(template.documentAssemblerId && template.templateId && template.type != "Sub") {
        documentAssemblerId = template.documentAssemblerId;
        templateId = template.templateId;
      }

    })

    this.router.navigate(["/landing/dashboard/task-manager/build-documents/user-context", "USERDEFINED", this.stepPlanActivityId, templateId, documentAssemblerId, this.stepPlanInstanceId]);
  }
}
