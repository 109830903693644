
import { Component, ElementRef, EventEmitter, Input, OnInit, Output,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentEditorContainerComponent, DocumentEditor  } from '@syncfusion/ej2-angular-documenteditor';
import { AuthenticationService } from 'src/app/authentication.service';
import { HttpClient, HttpResponse  } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { readyException } from 'jquery';

@Component({
  selector: 'app-view-plan-template',
  templateUrl: './view-plan-template.component.html',
  styleUrls: ['./view-plan-template.component.scss']
})
export class ViewPlanTemplateComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() plan;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('documenteditor_default') public container: DocumentEditorContainerComponent;
  @ViewChild('document_frame_plan') public documentFrame: ElementRef;
  statusChangeForm: FormGroup;
  service: string;
  fileEditingObject = {
    sfdtFormat : '',
    currentFileName:"",
  }

  isDocxFile = false;
  isOtherFile = false;
  grantLetterFile:any = null;
  fileType:string = "";
  url: string;
  constructor(private authService: AuthenticationService, 
    private toastr: ToastrService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    private http:HttpClient) {
       this.service = this.authService.getSFDTformatForNewDocument();
    }

  ngOnInit() {
    this.url = this.authService.baseURL;
    console.log("view plan letter")
    this.getPlanLetter();
  }

  remove_(str){
    return str.replace(/_/g, ' ');
  }

  onCreate(): void {
    this.container.documentEditor.fitPage('FitPageWidth');
    this.container.documentEditor.isReadOnly;
    this.container.documentEditor.open(this.fileEditingObject.sfdtFormat);
    this.container.documentEditor.documentName = this.fileEditingObject.currentFileName;  
    this.container.restrictEditing = true;
    this.container.headers = [{
      'x-user-token': localStorage.getItem('currentUser')
    }];
    
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit();
  }

  getPlanLetter() {
    this.ngxLoader.start();
    this.authService.getPlanLetter(this.plan.planId).subscribe((res: any)=>{
      
      this.fileType = res.headers.get("fileType");
      if(this.fileType == 'text/plain' || this.fileType == 'application/json') {
        const blb  = new Blob([res.body], {type: this.fileType});
        var reader = new FileReader();
        var self = this;
        reader.onload = function() {
          self.fileEditingObject.sfdtFormat = reader.result.toString();
          self.fileEditingObject.currentFileName = "Grant-Letter.docx"
          self.isDocxFile = true;
          self.ngxLoader.stop();
        }
        reader.readAsText(blb);
      }
      else {
        let file = new Blob([res.body], { type: this.fileType });
        let url = URL.createObjectURL(file);            
        this.grantLetterFile = this.sanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0');
        this.isOtherFile = true;
        this.ngxLoader.stop();
      }
      
      },
      (e)=>{
        this.ngxLoader.stop();
        this.fileEditingObject.sfdtFormat = '';
        this.toastr.error(e.error.reason, "Failure!");
      });
  }
}

