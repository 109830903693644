<div id="overlay" *ngIf="showDialog" [ngClass]="{'display-on': showDialog}"></div>
<div *ngIf="showDialog" class="dialog">
    <div class="header">
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
            <label class="dialogue-title">Calculate Valuation</label>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2">
            <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
              <span class="close">X</span>
            </button>
          </div>
        </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <div *ngIf="securityClassDetails" class="container-fluid content-row">
        <div class="row top-20">
            <p class="col-md-12 content-pg-1 btm-20">
                Calculate the valuation at which the convertible converts to the equity of the company in the new round. 
                The conversion ratio will change depending on the valuation applied. 
            </p>
        </div>
        <div>
            <div class="back-card">
                <div class="row">
                    <div [ngClass]="securityClassDetails?.discountTable?.length == 0 ? 'col-md-4' : 'col-md-3'">
                        <div class="btm-10 text-align-center">
                            <mat-label>Original Conversion Ratio</mat-label>
                        </div>
                        <div class="text-align-center">
                            <mat-label class="mat-label-value">{{ securityClassDetails.originalConversionRatio }}</mat-label>
                        </div>
                    </div>
                    <div [ngClass]="securityClassDetails?.discountTable?.length == 0 ? 'col-md-3' : 'col-md-2'">
                        <div class="btm-10 text-align-center">
                            <mat-label>Base Valuation</mat-label>
                        </div>
                        <div class="text-align-center">
                            <mat-label class="mat-label-value" placement="top" container="body" ngbTooltip="{{ getCSV(securityClassDetails?.baseValuation) }}">{{ formatCurrency(securityClassDetails?.baseValuation) }}</mat-label>
                        </div>
                    </div>
                    <div [ngClass]="securityClassDetails?.discountTable?.length == 0 ? 'col-md-3' : 'col-md-3'">
                        <div class="btm-10 text-align-center">
                            <mat-label>Current Valuation (Pre)</mat-label>
                        </div>
                        <div class="text-align-center">
                            <mat-label class="mat-label-value" placement="top" container="body" ngbTooltip="{{ getCSV(securityClassDetails?.currentValuation) }}">{{ formatCurrency(securityClassDetails?.currentValuation) }}</mat-label>
                        </div>
                    </div>
                    <div *ngIf="securityClassDetails?.discountTable?.length > 0" class="col-md-2">
                        <div class="btm-10 text-align-center">
                            <mat-label>Discount Cap</mat-label>
                        </div>
                        <div class="text-align-center">
                            <mat-label class="mat-label-value">{{ securityClassDetails.discountCap | percent: '2.2' }}</mat-label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="btm-10 text-align-center">
                            <mat-label>Valuation Cap</mat-label>
                        </div>
                        <div class="text-align-center">
                            <mat-label class="mat-label-value" placement="top" container="body" ngbTooltip="{{ getCSV(securityClassDetails?.valuationCap) }}">{{ formatCurrency(securityClassDetails?.valuationCap) }}</mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="securityClassDetails?.discountTable?.length > 0" class="top-20">
            <mat-label>Discount</mat-label>
            <p class="content-pg-1 top-10">
                The terms of discount on the basis of which the conversion to equity will happen is displayed below. 
                These terms are set at the time of issuing the convertible. 
            </p>
            <table class="table-common top-10">
                <thead>
                    <tr>
                        <th class="text-align-right width-30">Discount</th>
                        <th class="text-align-left width-30">Interval</th>
                        <th class="text-align-left width-40">Expiry</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of securityClassDetails?.discountTable; let i = index;">
                        <td class="text-align-right">{{ item.percentage | percent: '2.2' }}</td>
                        <td class="text-align-left">{{ item.discountInterval }}</td>
                        <td class="text-align-left">
                            <span *ngIf="item.expiryDate">{{ item.expiryDate | date: 'shortDate' }}</span>
                            <span *ngIf="item.expiryMonths">{{ item.expiryMonths }} Months</span>
                            <span *ngIf="item.expiry">{{ item.expiry }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row top-20">
                <div class="col-md-4">
                    <mat-label class="required">Update Conversion Ratio</mat-label>
                    <div>
                        <label class="top-10">
                            <input [readonly]="isCCDOrNote" id="allowUpdate" type="checkbox" title=""  data-toggle="switch"
                                    checked data-inverse="false" data-off-color="default">
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <mat-label>Convert to</mat-label>
                    <select [disabled]="isUpdateConversionRatio == true" class="form-control cursor-hand top-10" [(ngModel)]="convertedSecurityClassId" #instClass
                        (ngModelChange)="instClassChange(instClass.value)" [ngModelOptions]="{standalone: true}">
                        <option disabled selected [value]=undefined >Select Security Class</option>
                        <option value="New Security Class">New Security Class</option>
                        <option *ngFor="let class of securitiesList" value="{{ class.id }}">
                            {{ class.className }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row top-20">
                <div class="col-md-3">
                    <mat-label>Conversion Date</mat-label>
                    <div class="input-eyePlaceholder">
                        <input matInput [matDatepicker]="dp" [(ngModel)]="securityClassDetails.conversionDate" #convDate="ngModel" max="9999-12-31"
                            class="form-control top-10" title="" placeholder="dd-mmm-yyyy" [ngModelOptions]="{standalone: true}" required />
                        <mat-datepicker-toggle matSuffix [for]="dp" matRippleDisabled="disabled" matRippleColor="red">
                            <mat-icon matDatepickerToggleIcon matRippleDisabled="disabled"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </div>
                    <div *ngIf="convDate.errors" class="alert alert-danger">
                        <div *ngIf="(convDate.dirty || convDate.touched) ">
                          Conversion Date is required!
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <button mat-raised-button id="calculate" [disableRipple]="disableripple" class="left-50 top-35" color="success" (click)="calculateDiscount()" ngbTooltip="Calculate">Calculate</button>
                </div>
            </div>
            <div>
                <p class="content-pg-1 top-10 btm-20">Discount applied will be the lesser of the calculated value and the valuation cap.</p>
            </div>
            <div class="top-20 btm-20" *ngIf="discountDetails" id="discountDetailsDiv">
                <div class="row">
                    <div class="col-md-4">
                        <mat-label>Total Discount:</mat-label>
                        <mat-label class="mat-label-value left-20">{{ securityClassDetails.totalDiscount | percent: '2.2' }}</mat-label>
                    </div>
                    <div class="col-md-4">
                        <mat-label>Discount Applied:</mat-label>
                        <mat-label class="mat-label-value left-20">{{ securityClassDetails.discountApplied | percent: '2.2' }}</mat-label>
                    </div>
                </div>
                <div class="row top-20">
                    <div class="col-md-4">
                        <mat-label>Valuation used for conversion</mat-label>
                        <input type="text" class="form-control top-10" (keypress)="onlyNumberKey($event)" (keydown)="onKeydownEvent($event)" [readonly]="valForConversion" (input)="converttoCSV($event.target.value)" [(ngModel)]="securityClassDetails.valuationUsedForConversion" (change)="onValuationChangeDiscount($event.target.value)"/> 
                    </div>
                    <div class="col-md-1 left-0">
                        <img *ngIf="valForConversion" class="top-43" (click)="valForConversion = !valForConversion" src="../../../assets/common/unlocked.svg" />
                        <img *ngIf="!valForConversion" class="top-43" (click)="valForConversion = !valForConversion" src="../../../assets/common/locked.svg" />
                    </div>
                    <div class="col-md-4">
                        <div class="btm-10">
                            <mat-label>New Conversion Ratio</mat-label>
                        </div>
                        <div>
                            <mat-label class="mat-label-value">{{ securityClassDetails.newConversionRatio}}</mat-label>
                        </div>
                    </div>
                </div>
                <div class="top-20">
                    <div *ngIf="!showNotes" (click)="addNotes(true)"><span>
                        <mat-label>Add Notes</mat-label><img class="left-10" src="../../../../assets/action/addnotes.svg"
                          alt="add Notes" />
                      </span></div>
                    <div *ngIf="showNotes" (click)="addNotes(false)"><span>
                        <mat-label>Remove Notes</mat-label><img class="left-10" src="../../../../assets/action/removenotes.svg"
                          alt="remove Notes" />
                      </span></div>
                    <textarea class="form-control top-10" *ngIf="showNotes" [(ngModel)]="notes"
                      maxlength="10000" style="width:100%" placeholder="Add Notes"></textarea>
                </div>
            </div>
            <div class="row btm-20" *ngIf="discountDetails">
                <div class="col-xs-12 col-sm-12 col-md-12">
                  <button mat-raised-button id="save" color="success" [disableRipple]="true" ngbTooltip="Save"
                    class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="saveValuation()">
                    Save
                  </button>
                  <button mat-raised-button id="cancel" color="warn" [disableRipple]="true" ngbTooltip="Cancel"
                    class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                    Cancel
                  </button>
                </div>
            </div>
        </div>
        <div *ngIf="securityClassDetails?.metricsTable?.length > 0" class="btm-20"> 
            <mat-label>Operational Metrics</mat-label>
            <p class="content-pg-1 top-10">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s
            </p>
            <table class="table-common top-10">
                <thead>
                    <tr>
                        <th class="text-align-center">Select</th>
                        <th class="text-align-right">Valuation</th>
                        <th class="text-align-left">Event</th>
                        <th class="text-align-left">Target Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of securityClassDetails?.metricsTable; let i = index">
                        <td class="text-align-center"><input type="radio" class="height-20" name="radiogroup" [checked]="item.isSelected" [value]="item.id" (change)="selectedMetric(item.id)"></td>
                        <td class="text-align-right" placement="right" container="body" ngbTooltip="{{ getCSV(item.valuation) }}">{{ formatCurrency(item.valuation) }}</td>
                        <td class="text-align-left">{{ item.eventName }}</td>
                        <td class="text-align-left">{{ item.eventTargetDate | date: 'shortDate' }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="row top-20">
                <div class="col-md-4">
                    <mat-label class="required">Update Conversion Ratio</mat-label>
                    <div>
                        <label class="top-10">
                            <input id="allowUpdate" type="checkbox" title=""  data-toggle="switch"
                                    checked data-inverse="false" data-off-color="default">
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <mat-label>Convert to</mat-label>
                    <select [disabled]="isUpdateConversionRatio == true" class="form-control cursor-hand top-10" [(ngModel)]="convertedSecurityClassId" #instClass
                        (ngModelChange)="instClassChange(instClass.value)" [ngModelOptions]="{standalone: true}">
                        <option disabled selected [value]=undefined >Select Security Class</option>
                        <option value="New Security Class">New Security Class</option>
                        <option *ngFor="let class of securitiesList" value="{{ class.id }}">
                            {{ class.className }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row top-20" *ngIf="selectedMetricId || isValuationSet">
                <div class="col-md-4">
                    <mat-label>Valuation used for conversion</mat-label>
                    <input type="text" class="form-control top-10" (keypress)="onlyNumberKey($event)" (keydown)="onKeydownEvent($event)" (input)="converttoCSV($event.target.value)" [readonly]="valForConversion" [(ngModel)]="securityClassDetails.valuationUsedForConversion" (change)="onValuationchangeMetrics($event.target.value)"/> 
                </div>
                <div class="col-md-1 left-0">
                    <img *ngIf="valForConversion" class="top-43" (click)="valForConversion = !valForConversion" src="../../../assets/common/unlocked.svg" />
                    <img *ngIf="!valForConversion" class="top-43" (click)="valForConversion = !valForConversion" src="../../../assets/common/locked.svg" />
                </div>
                <div class="col-md-4">
                    <div class="btm-10">
                        <mat-label>New Conversion Ratio</mat-label>
                    </div>
                    <div>
                        <mat-label class="mat-label-value">{{ securityClassDetails?.newConversionRatio }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="top-20">
                <div *ngIf="!showNotes" (click)="addNotes(true)"><span>
                    <mat-label>Add Notes</mat-label><img class="left-10" src="../../../../assets/action/addnotes.svg"
                      alt="add Notes" />
                  </span></div>
                <div *ngIf="showNotes" (click)="addNotes(false)"><span>
                    <mat-label>Remove Notes</mat-label><img class="left-10" src="../../../../assets/action/removenotes.svg"
                      alt="remove Notes" />
                  </span></div>
                <textarea class="form-control top-10" *ngIf="showNotes" [(ngModel)]="notes"
                  maxlength="10000" style="width:100%" placeholder="Add Notes"></textarea>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12  btm-20">
                  <button mat-raised-button id="save" color="success" [disableRipple]="true" ngbTooltip="Save"
                    class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="saveValuation()">
                    Save
                  </button>
                  <button mat-raised-button id="cancel" color="warn" [disableRipple]="true" ngbTooltip="Cancel"
                    class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                    Cancel
                  </button>
                </div>
            </div>
        </div>
    </div>

    <app-new-security-class *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog"
        [(selSecurity)]="selInstruments" [selectedIndex]="selectedIndex"
        (saveSecurity)="saveSecurity($event)">
    </app-new-security-class>

</div>