import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../authentication.service';
import { UtilityService } from '../../../services/utilities.service';
declare var bootbox: any;

@Component({
  selector: 'app-authorized-capital-modal',
  templateUrl: './authorized-capital-modal.component.html',
  styleUrls: ['./authorized-capital-modal.component.scss']
})
export class AuthorizedCapitalModalComponent implements OnInit {

  @Input() showDialogauthorized: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public disableripple: boolean = true;
  public authCapital: any;
  public comments: any;
  public authdate: any;
  public isValid: boolean = false;

  mauthCapital: boolean = false;
  mComments: boolean = false;
  mauthDate: boolean = false;

  public authorizedCapitalObj = {
    'authorizedCapital': '',
    'date': '',
    'comments': ''
  }

  constructor(private utility: UtilityService) {
  }

  ngOnInit() {
    this.loadJsStyles();
  }

  close() {
    this.showDialogauthorized = false;
    this.authCapital="";
    this.comments="";
    this.authdate="";
    this.mauthCapital =false; 
    this.mauthDate = false; 
    this.mComments = false; 
    this.visibleChange.emit(this.showDialogauthorized);
  }

  onKeydownEvent(e) {
    this.utility.restrictMinus(e);
    this.loadJsStyles();
  }

  createAuthCapiatal() {
    let data = {
      autorizedCapital: this.authCapital,
      comments: this.comments,
      date: this.authdate
    }
    this.isValid = this.validatorMethod(data);

    if (this.isValid) {
      if (this.authCapital == undefined || this.authCapital == null || this.authCapital == "") {
        this.mauthCapital = true;
      }
      if (this.comments == undefined || this.comments == null || this.comments == "") {
        this.mComments = true;
      }
      if (this.authdate == undefined || this.authdate == null || this.authdate == "") {
        this.mauthDate = true;
      }
      return;
    }
    else {

    }

  }

  validatorMethod(data) {

    if (data.autorizedCapital == undefined || data.autorizedCapital == null || data.autorizedCapital == "" ||
      data.comments == undefined || data.comments == null || data.comments == "" ||
      data.date == undefined || data.date == null || data.date == "") {
      return true;
    }
    else {
      return false;
    }

  }

  validateComments(value) {
    if (value != undefined || value != "" || value != null) {
      this.mComments = false;
    }
    else{
      this.mComments = true;
    }
  }

  validateDate(value) {
    if (value != undefined || value != "" || value != null) {
      this.mauthDate = false;
    }
    else{
      this.mauthDate = true;
    }
  }

  /** Load js Styles **/
  loadJsStyles() {
    $('input.number').keyup(function (event) {
      // skip for arrow keys
      if (event.which >= 37 && event.which <= 40) return;

      // format number
      $(this).val(function (index, value) {
        let tmpValue = value.toString().replace(/\D/g, "");
        var lastThree = tmpValue.substring(tmpValue.length - 3);
        var otherNumbers = tmpValue.substring(0, tmpValue.length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res;
      });
    });
  }

}
