<div id="overlay"></div>

<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header">
        <label class="dialogue-title">Calculate Investment</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <button type="button" class="close" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="content-row btm-20">
    <div class="row section-padding">
        <div class="col-md-6">
            <mat-label>Investors Name</mat-label>
            <input type="text" class="form-control top-10" readonly [value]="invDetails.investorName" />
        </div>
        <div class="col-md-6">
            <mat-label>Security Class</mat-label>
            <input type="text" class="form-control top-10" readonly [value]="invDetails.className" />
        </div>
    </div>
    <div class="row section-padding">
        <div class="col-md-2">
            <mat-label>Nominal Equity</mat-label>
            <div>
                <label class="top-10">
                    <input id="includeNominalEquity" type="checkbox" title=""  data-toggle="switch"
                            checked data-inverse="false" data-off-color="default">
                </label>
            </div>
        </div>
        <div class="col-md-6">
            <mat-label [ngClass]="invDetails.includeNominalEquity ? 'required' : '' ">No. of Nominal Equity Shares</mat-label>
            <input class="form-control text-align-right top-10" type="text" [readonly]="!invDetails.includeNominalEquity"
                [(ngModel)]="invDetails.nominalEquityShares" placeholder="Enter nominal equity shares"
                (keypress)="onlyNumberKey($event)" (change)="calculateInvestment()"
                (blur)="updateInvestor($event.target.value)" 
                (input)="csvVal($event.target.value, $event)" [required]="invDetails.includeNominalEquity"/>
        </div>
        <div class="col-md-4">
          <mat-label [ngClass]="invDetails.includeNominalEquity ? 'required' : '' ">Class</mat-label>
          <select class="form-control top-10 cursor-hand" [(ngModel)]="nomEqtyInstCls"
              (change)="selectednomInstCls(nomEqtyInstCls)" placeholder="Select Class"
              [required]="invDetails.includeNominalEquity" [disabled]="!invDetails.includeNominalEquity">
              <option disabled selected [value]=undefined >Select</option>
              <option *ngFor="let nomEqtyInstCls of nominalEqtyList"
                  [value]="nomEqtyInstCls">
                  {{ nomEqtyInstCls }}
              </option>
          </select>
        </div>
    </div>
    <div class="row section-padding">
        <div class="col-md-6">
          <div>
            <mat-label>Investment Amount</mat-label>
            <input type="text" class="form-control text-align-right top-10"
                 id="input"
                (change)="calculateInvestment()" (input)="amountNewInvCSV(invDetails, $event)"
                [(ngModel)]="invDetails.investmentAmount" min="0"
                (keypress)="onlyNumberKey($event)" title=""/>
          </div>
          <div class="top-20">
            <mat-label ngbTooltip="{{showCurrency(amountRaised)}}"
                container="body">Total amount invested &nbsp;
                {{getTotalValueTble(amountRaised)}}</mat-label> &nbsp;
            <mat-label *ngIf="!isInvestmentMet"
                class="speech-bubble-red bubble-txt new-inv-label"
                ngbTooltip="{{showCurrency(amountLefttoRaised)}}">
                {{getTotalValueTble(amountLefttoRaised)}}
                Remaining</mat-label>
            <mat-label class="speech-bubble-green bubble-txt new-inv-label"
                *ngIf="isInvestmentMet"
                class="speech-bubble-green bubble-txt new-inv-label">
                Investment Met</mat-label>
          </div>
        </div>
        <div class="col-md-6">

        </div>
    </div>
    <div class="row section-padding float-right ">
        <div class="col-md-12">
          <button mat-raised-button id="cancel" [disableRipple]="disableripple" color="warn" class="margin-right-20"
            (click)="close()" ngbTooltip="Cancel">
            Cancel
          </button>
          <button mat-raised-button id="save" [disableRipple]="disableripple" color="success" (click)="savesSecurity()" class=""
            ngbTooltip="Save">
            Save
          </button>
        </div>
      </div>
  </div>
  </div>
