import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { ColumnList } from '../buy-back-overview-table/column-list';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { BuyBackDetails } from '../buy-back-tab/model/buyBackDetails';
import { OnGoingTransaction } from './model/OngoingTransaction';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-buy-back-overview',
    templateUrl: './buy-back-overview.component.html',
    styleUrls: ['./buy-back-overview.component.scss']
})
export class BuyBackOverviewComponent implements OnInit {

    buyBackOverview;
    dataSource = [];
    columnList: ColumnList;
    roundDataArray = [];
    chartScroll = false;
    countriesInfo;
    disableBuyBack = false;
    public partialTransaction: String;
    public toolTipDataLoaded: Boolean = false;
    countrySources = [];
    buyBackStateMapping = {
        "BASIC_DETAILS": "buyBack",
        "SHAREHOLDER_DETAILS": "review",
        "CAPTABLE_SUMMARY": "summary"
    }

    public disableripple: boolean = true;
    public userDetails;
    public usrCompanyCurrency = "INR - ₹";
    displayCols: ColumnList;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private ngxLoader: NgxUiLoaderService,
        private toastr: ToastrService,
        private utilityService: UtilityService,
        private cacheService: CacheProviderService,
        public datepipe: DatePipe,
    ) { }

    customizeTooltip = (arg) => {
        if (arg.percentText) {
            return {
                text: arg.argumentText + "\n" + arg.seriesName + ": " + arg.percentText,
                fontColor: "#ffffff",
                color: "#000000"
            };
        }
        else {
            return {
                text: arg.argumentText + "\n" + arg.seriesName + ": " + this.getAmount(this.usrCompanyCurrency, arg.value),
                fontColor: "#ffffff",
                color: "#000000"
            };
        }
    }

    getAmount(usrCompanyCurrency, amt) {
        let val = this.utilityService.convertedFormatCurrency(usrCompanyCurrency, amt);
        return val;
    }


    navigate() {
        if (this.disableBuyBack === false) {
            this.router.navigateByUrl('/buyBack', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/landing', 'dashboard', 'buy-back', 'newBuyBack', 'buyBack']);
            });
        }
        else {
            this.toastr.error("Buy-back already is in progress, please complete!", "Error");
        }

    }

    formatData() {
        let roundObj = {
            'roundName': '',
            'primaryAmt': 0,
            'postVal': 0
        };
        this.buyBackOverview.forEach(round => {
            roundObj = {
                'roundName': round.roundName,
                'primaryAmt': round.roundSize,
                'postVal': round.postMoneyValuation
            };
            this.roundDataArray.push(roundObj);
        });
    }

    redirectToBuybackDetails(event) {
        if (event.data.state === "BASIC_DETAILS" || event.data.state === "SHAREHOLDER_DETAILS" || event.data.state === "CAPTABLE_SUMMARY"){
            this.router.navigate(
                ['/landing', 'dashboard', 'buy-back', 'newBuyBack', this.buyBackStateMapping[event.data.state] ],
                { queryParams: { 'buyBackId': event.data.id } }
            );
        }
        else if (event.data.state === "COMPLETED"){
            this.router.navigate(
                ['/landing', 'dashboard', 'buy-back', 'details'],
                { queryParams: { 'buyBackId': event.data.id } }
            );
        }
    }

    formatCurrency(value) {
        return this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, value);
    }

    ngOnInit() {
        this.ngxLoader.start();
        if (this.cacheService.get("userDetails")) {
            this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
            this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
        }
        else {
            this.usrCompanyCurrency = "INR - ₹";
        }

        this.authService.checkForOngoingTransactions().subscribe((response: OnGoingTransaction) => {
            if (response) {
                this.disableBuyBack = response.isTransactionOngoing;
                // let onGoingTransaction: OnGoingTransaction = response;
                if (response["isBuybackOngoing"] == true){
                    this.partialTransaction = "Buy-Back";
                    this.toolTipDataLoaded = true;
                }
                else if (response["isRoundOngoing"] == true){
                    this.partialTransaction = "Round";
                    this.toolTipDataLoaded = true;
                }
                else if (response["isSplitOngoing"] == true){
                    this.partialTransaction = "Split";
                    this.toolTipDataLoaded = true;
                }
                
                this.ngxLoader.stop();
            }
            else {
                this.ngxLoader.stop();
                this.toastr.error("Error: Unable to check Ongoing Transaction data");
            }
        }, (e) => {
            this.ngxLoader.stop();
            this.toastr.error("Error: " + e.error.reason);
        });

        this.authService.getAllBuyBackTransactionData().subscribe((res: BuyBackDetails[]) => {
            let completedBuyBacks = [];
            let inCompleteBuyBacks = [];
            let resData = [];
            if (res) {   
                completedBuyBacks = res.filter(b => b.state === "COMPLETED");
                completedBuyBacks.sort((a, b) => (b.id) - (a.id));
                inCompleteBuyBacks = res.filter(b => b.state != "COMPLETED");
                resData = inCompleteBuyBacks.concat(completedBuyBacks)           
                resData.forEach(element => {
                    let dataObj = {};
                    dataObj["id"] = element.id;
                    dataObj["buyBackName"] = element.buyBackName;
                    dataObj["startDate"] = this.datepipe.transform(element.startDate, 'dd-MMM-yyyy'); //(element.startDate.substring(0, 10)).split("-").reverse().join("/");
                    dataObj["pricePerShare"] = element.pricePerShare != null? element.pricePerShare: 0;
                    dataObj["buyBackAmountActual"] = element.buyBackAmountActual != null? element.buyBackAmountActual: 0;
                    dataObj["postBuybackConsolidation"] = element.postBuybackConsolidation != null? element.postBuybackConsolidation: 0;
                    dataObj["numberOfSharesBoughtBackActual"] = element.numberOfSharesBoughtBackActual != null? element.numberOfSharesBoughtBackActual: 0;
                    dataObj["className"] = element.className;
                    dataObj["state"] = element.state;
                    this.dataSource.push(dataObj);
                });
                

                // res.sort((a,b) => (a.state === "COMPLETED" < b.state != "COMPLETED")?1:-1)
                this.ngxLoader.stop();
            }
            else {
                this.ngxLoader.stop();
                this.toastr.error("Error: Unable to get Exisiting Buy-back data");
            }
        }, (e) => {
            this.ngxLoader.stop();
            this.toastr.error("Error: " + e.error.reason);
        });

        this.displayCols = new ColumnList();
        this.displayCols = {
            buyBackName: true,
            startDate: true,
            pricePerShare: true,
            buyBackAmountActual: true,
            postBuybackConsolidation: true,
            className: true,
            state: true,
        }


    }

    getTotalValueTble(value) {
        if (value >= 0) {
          if (value.toString().length > 5) {
            return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
          }
          else {
            return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
          }
        }
        else {
          if(value != undefined || value != null){
            let sign = "-";
            if (value.toString().length > 5) {
              return sign + this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
            }
            else {
              return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
            }
          }
        }
      }

}
