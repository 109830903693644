import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoundDeactivateGuard } from '../roundDeactivateGuard';
import { CapTableTabComponent } from './cap-table-tab/cap-table-tab.component';
import { CreateNewRoundComponent } from './create-new-round/create-new-round.component';
import { CurrentInvestorsTabComponent } from './current-investors-tab/current-investors-tab.component';
import { InstrumentTabComponent } from './instrument-tab/instrument-tab.component';
import { NewInvestorsTabComponent } from './new-investors-tab/new-investors-tab.component';
import { RoundDetailsComponent } from './round-details/round-details.component';
import { RoundTabComponent } from './round-tab/round-tab.component';
import { RoundsOverviewComponent } from './rounds-overview/rounds-overview.component';
import { SummaryComponent } from './summary-tab/summary-tab.component';

const routes: Routes = [
    { path: "overview", component: RoundsOverviewComponent },
    { path: "round-details/:roundName", component: RoundDetailsComponent },
    {
        path: "newRound",
        component: CreateNewRoundComponent,
        children: [
            { path: "round", component: RoundTabComponent, canDeactivate: [RoundDeactivateGuard] },
            { path: "currentInvestors", component: CurrentInvestorsTabComponent, canDeactivate: [RoundDeactivateGuard] },
            { path: "newInvestors", component: NewInvestorsTabComponent, canDeactivate: [RoundDeactivateGuard] },
            { path: "instrument", component: InstrumentTabComponent, canDeactivate: [RoundDeactivateGuard] },
            { path: "capTable", component: CapTableTabComponent, canDeactivate: [RoundDeactivateGuard] },
            { path: "summary", component: SummaryComponent, canDeactivate: [RoundDeactivateGuard] },
        ],
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoundsRoutingModule { }
