import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../app/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      window.location.href.includes("xUserToken") &&
      !sessionStorage.getItem("userDetails")
    )
      return false;
    if (
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") != "null"
    ) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigateByUrl("/");
  }
}
