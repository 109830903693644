<div *ngIf="showDialogCert" class="dialog overflow-scroll">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header">
        <label class="dialogue-title" *ngIf="usedIn == 'termSheetsOverview'">Term Sheet</label>
        <label class="dialogue-title" *ngIf="usedIn != 'termSheetsOverview'">Share Certificate</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span>X</span>
        </button>
      </div>
    </div>
  </div> 

  <div *ngIf="usedIn != 'shareholderDetailsPage' && usedIn != 'termSheetsOverview'" class="container">
    <div class="row">
      {{ usedIn }}
      <img src="../../../../assets/sharecertificate-01.png" class="img-response" />
    </div>
    <div class="row btm-20 float-right">
      <button mat-raised-button id="exit" color="success" class="margin-right-20" (click)="close()">Exit</button>
    </div>
  </div>

  <div *ngIf="usedIn == 'shareholderDetailsPage' || usedIn == 'termSheetsOverview'" class="container">
    <div class="row">
        <div class="col-md-12">
            <div class=" grant-letter-container"> 
                <iframe #document_frame class="document-frame" [src]="certificateFile" *ngIf="!isDocxFile && certificateFile"></iframe>
                <ejs-documenteditorcontainer *ngIf="isDocxFile" [(serviceUrl)]="service" #documenteditor_default [enableToolbar]="false" [isReadOnly]= "true" [enablePrint]="false" (created)="onCreate()" width="100%"  height="100%" [showPropertiesPane]="false" [zoomFactor]=1.25 style="display:block; overflow: hidden;"></ejs-documenteditorcontainer>
            </div>
        </div>
    </div>
    <div class="row top-20 certificate-exit-button">
      <button mat-raised-button id="exit" color="success" (click)="close()">Exit</button>
    </div>
  </div>

</div>