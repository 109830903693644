<div class="row" (mouseleave)="hideHelp()">
    <div id="overlay"></div>
    <div class="col-md-9">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Current investors</mat-card-title>
            </mat-card-header>
            <mat-divider class="divider-line"></mat-divider>
            <mat-card-content>
                <form class="needs-validation" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="content-pg-1 top-20">
                                Enter the details of the existing investors making new investments in the round.
                            </p>

                            <div *ngIf="this.roundCurrency != this.companyCurrency && this.roundCurrency != null"
                                class="alert alert-info alert-labeled alrt-mrgn top-20 btm-20">
                                <p type="button" class="close-help" data-dismiss="alert">
                                    <img src="../../../assets/message-close.png" />
                                </p>
                                <div class="alert-labeled-row">
                                    <div class="alert-label alert-label-left alert-labelled-cell">
                                        <img src="../../../assets/message-info.svg" />
                                    </div>
                                    <p class="content-pg-1 alert-body alert-body-right alert-labelled-cell">
                                        The Round currency you have selected ( {{this.roundCurrency}} ) is different
                                        from Company currency ( {{this.companyCurrency}} )
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div id="Convertible_Section" *ngIf="outstandingConv.length > 0" class="back-card top-20">
                        <div (mouseover)="showHelp('Convertible_Section')">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="form-title btm-10">Outstanding Convertibles</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ">
                                    <p class="content-pg-1 btm-10">
                                        This is the outstanding convertibles issued in the previous round of financing that convert to equity in this round. 
                                        Click on the ‘action’ button to work out the valuation at which the conversion will take place.  
                                    </p>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="table-responsive btm-20">
                                        <table class="table-common">
                                            <thead>
                                                <tr>
                                                    <th class="text-align-left">Convertible Security</th>
                                                    <th class="text-align-left">Round</th>
                                                    <th class="text-align-right">Valuation Applied</th>
                                                    <th class="text-align-right">Conversion Ratio</th>
                                                    <th class="text-align-right">Amount invested</th>
                                                    <th class="text-align-right">Number of Shares</th>
                                                    <th class="text-align-right">Holding</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-template ngFor let-group [ngForOf]="outstandingConv" let-i="index">
                                                    <tr>
                                                        <td class="text-align-left">
                                                            <span class="cursor-hand" *ngIf="!group.expanded"
                                                                (click)="group.expanded = true">
                                                                <span class="cursor-hand margin-right-sm">{{ group.convertibleSecurity }}</span>
                                                                <i class="fa fa-caret-right" *ngIf="group.isValuationSet" 
                                                                    aria-hidden="true"></i></span>
                                                            <span class="cursor-hand" *ngIf="group.expanded"
                                                                (click)="group.expanded = false">
                                                                <span class="cursor-hand margin-right-sm">{{ group.convertibleSecurity }}</span>
                                                                <i class="fa fa-caret-down" aria-hidden="true" *ngIf="group.isValuationSet"></i></span>
                                                        </td>
                                                        <td><span [ngClass]="identifierCSS(group.roundDetails.roundIdentifier)" ngbTooltip="{{ group.roundDetails.roundName }}" class="rnd-identifier">{{ group.roundDetails.roundIdentifier }}</span></td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="group.valuationApplied != null" ngbTooltip="{{showCurrency(group.valuationApplied)}}">{{ formatCurrency(group.valuationApplied) }}</span>
                                                            <span *ngIf="group.valuationApplied == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="group.conversionRatio != null">{{ group.conversionRatio }}</span>
                                                            <span *ngIf="group.conversionRatio == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="group.amountInvested != null" ngbTooltip="{{showCurrency(group.amountInvested)}}">{{ formatCurrency(group.amountInvested) }}</span>
                                                            <span *ngIf="group.amountInvested == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="group.numberOfShares != null" ngbTooltip="{{ formatNoOfShares(group.numberOfShares) }}">{{ formatCurrency(group.numberOfShares) }}</span>
                                                            <span *ngIf="group.numberOfShares == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="group.holdingPercentage != null">{{ group.holdingPercentage | percent: '2.2' }}</span>
                                                            <span *ngIf="group.holdingPercentage == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td><img *ngIf="group.isCommonClass == false" src="../../assets/action/edit-plan.svg" (click)="openValuationPopup(group.id, group.isValuationSet)"></td>
                                                    </tr>
                                                    <tr class="individual-row" [hidden]="!group.expanded || !group.isValuationSet" *ngFor="let individual of group.investorDetails">
                                                        <td class="text-align-left padding-left-50">{{ individual.investorName }}</td>
                                                        <td><span [ngClass]="identifierCSS(individual.roundDetails.roundIdentifier)" ngbTooltip="{{ individual.roundDetails.roundName }}" class="rnd-identifier">{{ individual.roundDetails.roundIdentifier }}</span></td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="individual.valuationApplied != null" ngbTooltip="{{showCurrency(individual.valuationApplied)}}">{{ formatCurrency(individual.valuationApplied) }}</span>
                                                            <span *ngIf="individual.valuationApplied == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="individual.conversionRatio != null">{{ individual.conversionRatio }}</span>
                                                            <span *ngIf="individual.conversionRatio == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="individual.amountInvested != null" ngbTooltip="{{showCurrency(individual.amountInvested)}}">{{ formatCurrency(individual.amountInvested) }}</span>
                                                            <span *ngIf="individual.amountInvested == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="individual.numberOfShares != null" ngbTooltip="{{ formatNoOfShares(individual.numberOfShares) }}">{{ formatCurrency(individual.numberOfShares) }}</span>
                                                            <span *ngIf="individual.numberOfShares == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span *ngIf="individual.percentageOfHolding != null">{{ individual.percentageOfHolding | percent: '2.2' }}</span>
                                                            <span *ngIf="individual.percentageOfHolding == null" style="color: #E30000;">Not Set</span>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </ng-template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-12">
                                    <button mat-raised-button [disableRipple]="disableripple" color="success"
                                        class="pull-right" (click)="saveOutStandingConv()"
                                        ngbTooltip="Save Outstanding">Save Convertible</button>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div id="ExistingInv_Section" class="back-card top-20">
                        <div (mouseover)="showHelp('ExistingInv_Section')">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="form-title btm-10">Investment by Existing Shareholders</label>
                                    <p class="content-pg-1 btm-20">
                                        From the drop down, select a shareholder’s name and the type of right the shareholder wants to exercise. 
                                        This can be - the right to maintain capital or make a pro-rata investment. 
                                        The tool will accordingly calculate the amount of investment that needs to be made. You also have the option to specify the investment amount. 
                                        For this, select <b>‘None’</b> under <b>‘Investor Rights’</b>. 
                                    </p>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <table class="table-common css-serial">
                                        <thead>
                                            <tr>
                                                <th class="center-align text-align-left width-40 padd-left">
                                                    Existing Shareholders
                                                </th>
                                                <th class="center-align text-align-left width-20">Investor Rights</th>
                                                <th class="center-align text-align-right width-30">Investment Amount</th>
                                                <th class="width-4">
                                                    <div></div>
                                                </th>
                                                <th class="width-5 padd-right">
                                                    <img src="../../assets/purple-plus-round.svg"  class="float-right cursor-hand"
                                                        (click)="addExistingShareholder()" ngbTooltip="Add Existing Investor" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let holder of existingShareholders; let i = index;">
                                                <td class="width-40 padd-left">
                                                    <select class="form-control" [(ngModel)]="holder.name" #esErr="ngModel"
                                                        [ngClass]="(esErr.errors?.required && esName) ? 'is-invalid' : 'is-valid' "
                                                        [ngModelOptions]="{standalone: true}" (change)="fieldEdited($event)"
                                                        [disabled]="isDisableExistingInv != i || isindividualValid" required>
                                                        <option value="" selected disabled>Select Existing Shareholder</option>
                                                        <option *ngFor="let holder of existingShareholderNamesForDropdown" value="{{holder}}">
                                                            {{ holder }}
                                                        </option>
                                                    </select>
                                                    <div class="alert alert-danger  text-align-left span-block" *ngIf="esErr.errors">
                                                        <span *ngIf="!esName && (esErr.dirty || esErr.touched)">
                                                            Existing Shareholder is required!
                                                        </span>
                                                        <span *ngIf="esErr.errors?.required && esName">
                                                            Existing Shareholder is required!
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="width-20">
                                                    <select class="form-control" #rightErr="ngModel"
                                                        (ngModelChange)="getExistingInvAmount(i ,right.value)"
                                                        [(ngModel)]="holder.rightToMaintainType" #right 
                                                        [ngClass]="(rightErr.errors?.required && esRight) ? 'is-invalid' : 'is-valid' "
                                                        [ngModelOptions]="{standalone: true}" (change)="fieldEdited($event)"
                                                        [disabled]="isDisableExistingInv != i || isindividualValid" required>
                                                        <option value="" selected disabled>Select Right</option>
                                                        <option *ngFor="let right of rights" value="{{right.value}}">{{right.name}}</option>
                                                    </select>
                                                    <div class="alert alert-danger  text-align-left span-block" *ngIf="rightErr.errors">
                                                        <span *ngIf="!esRight && (rightErr.dirty || rightErr.touched)">
                                                            Right is required!
                                                        </span>
                                                        <span *ngIf="rightErr.errors?.required && esRight">
                                                            Right is required!
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="width-30" style="position: relative;">
                                                    <div>
                                                        <label *ngIf="this.roundCurrency != null"
                                                            class="CurrencySymbol">{{roundCurrency.substr(roundCurrency.length-2)}}</label>
                                                        <input class="form-control text-align-right" type="text" title=""
                                                            [readonly]="isDisableExistingInv != i || isindividualValid"
                                                            [(ngModel)]="holder.investmentAmount" min="0"  [ngModelOptions]="{standalone: true}"
                                                            (input)="changeExistingAmout(i)"
                                                            placeholder="Investment Amount"
                                                            [ngClass]="(iaErr.errors?.required && esIA) ? 'is-invalid' : 'is-valid' "
                                                            (input)="onChangeval(i,holder.investmentAmount, $event)" (change)="exInvAmountchange(i,holder.investmentAmount); fieldEdited($event)"
                                                            (keypress)="onlyNumberKey($event)" #iaErr="ngModel" required/>
                                                    </div>
                                                    <div class="alert alert-danger  text-align-left span-block" *ngIf="iaErr.errors">
                                                        <span *ngIf="!esIA && (iaErr.dirty || iaErr.touched)">
                                                            Investment Amount is required!
                                                        </span>
                                                        <span *ngIf="iaErr.errors?.required && esIA">
                                                            Investment Amount is required!
                                                        </span>
                                                    </div>
                                                </td>

                                                <td class="width-4">
                                                    <img src="../../assets/save.svg"   class="cursor-hand"
                                                        *ngIf="isDisableExistingInv == i && !isindividualValid"
                                                        (click)="individualSave(i)"
                                                        ngbTooltip="Save Shareholder details" />
                                                    <img class="icon-size cursor-hand" src="../../assets/edit.svg"
                                                        (click)="editExistingInvestorRow(i)"
                                                        *ngIf="isDisableExistingInv != i || isindividualValid"
                                                        ngbTooltip="Edit Shareholder details" />
                                                </td>

                                                <td class="width-5 padd-right">
                                                    <img class="icon-size cursor-hand" src="../../assets/trash-bin.svg"
                                                        (click)="deleteRTMRow(i)" ngbTooltip="Delete" />
                                                </td>

                                            </tr>
                                            <tr class="top-10" *ngIf="existingShareholders.length != 0">
                                                <td colspan="5" class="text-align-right" style="padding-right: 30px;">
                                                    <mat-label ngbTooltip="{{showCurrency(amountRaised)}}"
                                                        container="body">Total amount invested &nbsp;
                                                        {{getTotalValueTble(amountRaised)}}</mat-label> &nbsp;
                                                    <mat-label *ngIf="!isInvestmentMet"
                                                        class="speech-bubble-red bubble-txt new-inv-label"
                                                        ngbTooltip="{{showCurrency(amountLefttoRaised)}}">
                                                        {{getTotalValueTble(amountLefttoRaised)}}
                                                        Remaining</mat-label>
                                                    <mat-label class="speech-bubble-green bubble-txt new-inv-label"
                                                        *ngIf="isInvestmentMet"
                                                        class="speech-bubble-green bubble-txt new-inv-label">
                                                        Investment Met</mat-label>
                                                </td>
                                            </tr>
                                            <tr *ngIf="existingShareholders.length == 0">
                                                <td colspan="5" class="text-align-center">
                                                    <p>
                                                        Click on the '+' to add an Existing Shareholder
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row top-20">
                        <div class="col-md-12">
                            <button mat-raised-button id="saveAndContinue" [disableRipple]="disableripple" color="success"
                                class="pull-right " (click)="goToNewInvs()" ngbTooltip="Save and Continue">Save and Continue</button>
                            <button mat-raised-button id="previous" [disableRipple]="disableripple" color="success"
                                class="pull-right margin-right-20" (click)="goToRound()"
                                ngbTooltip="Previous">Previous</button>
                            <!-- <button mat-raised-button [disableRipple]="disableripple" color="success"
                                class="pull-right margin-right-20" (click)="skip()"
                                ngbTooltip="Skip this step">Skip</button> -->
                            <button mat-raised-button [disableRipple]="disableripple" color="success"
                                class="float-right margin-right-20" *ngIf="isRightToMaintainSectionValid===false"
                                (click)="refreshRights()" ngbTooltip="Click to refresh latest data">Refresh
                                <img src="../../assets/refresh-arrows-symbol-of-interface.svg" alt="refresh">
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>

        <!-- <app-profile-info class="dialog-back" *ngIf="isProfileInfo" [(selectedIndex)]="convIndex"
            [(shareHolderType)]="shareHolderType" [(outstandingConv)]="outstandingConv" [(groupNames)]="groupNames"
            [(showdigPrfle)]="isProfileInfo" (visibleChange)="getProfileInfo($event)">
        </app-profile-info> -->
    </div>
    <app-calc-valuation-popup *ngIf="showDialog" [companyCurrency]="companyCurrency" [roundCurrency]="roundCurrency" [isValuationSet]="securityIsValuationSet" [(showDialog)]="showDialog" [securityClassId]="securityId" (visibleChange)="getEvent()"></app-calc-valuation-popup>
    <!-- Helper Section -->
    <div class="col-md-3">
        <div class="helper-container">
            <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
                [hidden]="helper == ''">
                <mat-card-header class="help-hdr">
                    <mat-card-title><label class="help-hdr-txt">{{
                    helpSections?.name
                  }}</label></mat-card-title>
                </mat-card-header>
                <hr />
                <mat-card-content class="card-help-content">
                    <div [innerHTML]="helpSections?.description"></div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>