import { TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';
var ShowIfDirective = /** @class */ (function () {
    function ShowIfDirective(templateRef, viewContainer, permService) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.permService = permService;
    }
    Object.defineProperty(ShowIfDirective.prototype, "appShowIf", {
        set: function (perm) {
            var userPermissions = this.permService.getUserPermissions();
            var permissionSet = this.permService.getAllPermissions();
            var fullAccess = permissionSet['full-access'];
            var adminViewer = permissionSet['admin-without-proforma'];
            var isStrict = perm.includes("!strict");
            for (var i = 0; i < perm.length; i++) {
                var permissionRequired = permissionSet[perm[i]];
                if (userPermissions.includes(permissionRequired) || ((userPermissions.includes(fullAccess) || userPermissions.includes(adminViewer)) && !isStrict)) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    return;
                }
            }
            this.viewContainer.clear();
        },
        enumerable: true,
        configurable: true
    });
    return ShowIfDirective;
}());
export { ShowIfDirective };
