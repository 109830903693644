import { OnInit } from '@angular/core';
import { CompanyDetailsModel } from './company-details.model';
import { FounderDetailsModel } from './founder-details.model';
import { DirectorDetailsModel } from './director-details.model';
import { AuthenticationService } from '../../../../src/app/authentication.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { UtilityService } from '../../services/utilities.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/new-round-notify.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from '../../services/cache-provider.service';
import { filter } from 'rxjs/operators';
import { NavbarService } from 'src/app/services/navbar-reload';
import { MatDialog } from '@angular/material';
import { ConfirmDeleteComponent } from "src/app/confirm-delete/confirm-delete.component";
var CompanyDetailsComponent = /** @class */ (function () {
    function CompanyDetailsComponent(utilityService, helperService, router, authService, toastr, activatedRoute, dataService, datepipe, cacheService, ngxLoader, navbarService, dialog) {
        this.utilityService = utilityService;
        this.helperService = helperService;
        this.router = router;
        this.authService = authService;
        this.toastr = toastr;
        this.activatedRoute = activatedRoute;
        this.dataService = dataService;
        this.datepipe = datepipe;
        this.cacheService = cacheService;
        this.ngxLoader = ngxLoader;
        this.navbarService = navbarService;
        this.dialog = dialog;
        this.areAllDirectorsSelected = false;
        this.areAllFoundersSelected = false;
        this.helper = "";
        this.helperTopPosition = null;
        this.model = new CompanyDetailsModel();
        this.currentEditableFounderRow = 0;
        this.currentEditableDirectorRow = 0;
        this.isRegistered = false;
        this.companyExists = false;
        this.companyNameValid = false;
        this.cinNoValid = false;
        this.companyTypeValid = false;
        this.incorporateDtValid = false;
        this.officeAddressValid = false;
        this.emailValid = false;
        this.businessInvalid = false;
        this.industryInvalid = false;
        this.nicValid = false;
        this.companyWebsitevalid = false;
        this.companyCurrencyValid = false;
        this.isValid = false;
        this.typeofCompanies = ['Private Limited Company', 'Public Limited Company', 'Unlimited Company', 'Partnership', 'Limited Liability Partnership'];
        this.selectedNationality = "India";
        this.fName = false;
        this.fEmail = false;
        this.fNationality = false;
        this.registerNewCompany = false;
        this.dName = false;
        this.dDin = false;
        this.dType = false;
        this.dEmail = false;
        this.dNationality = false;
        this.shouldLogoShowDialog = false;
        this.profilePicture = null;
        this.croppedImage = null;
        this.companyLogoInvalid = false;
        router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            localStorage.setItem('navigationFlag', 'companyDetails');
            // this.previousUrl = event.url;
        });
    }
    CompanyDetailsComponent.prototype.ngOnInit = function () {
        this.shouldShowDialog = false;
        this.currencyList = this.utilityService.currencyList();
        this.initializeDetails();
        this.fetchCompanyDetails();
        var _a = require("country-list"), getCode = _a.getCode, getNames = _a.getNames;
        this.countryList = getNames();
        var errorStatus = {
            adminProfileTab: "COMPLETED",
            companyDetailsTab: "INITIAL",
            inCorporationTab: "INITIAL"
        };
        this.errorFlags = errorStatus;
        this.dataService.updateOnboarding(this.errorFlags);
    };
    CompanyDetailsComponent.prototype.initializeDetails = function () {
        this.model.nameOfTheCompany = localStorage.getItem('companyName');
        this.model.cinNumber = "";
        this.model.typeOfTheCompany = "";
        this.model.dateOfIncorporation = '';
        this.model.corporateOfficeAddress = "";
        this.model.companyEmailId = "";
        this.model.companyCurrency = "INR - ₹";
        this.model.business = [];
        this.model.industry = [];
        this.model.nic = "";
        this.model.dippCertificateNumber = "";
        this.model.doingBusinessAs = "";
        this.model.founders = [];
        this.model.directors = [];
        this.model.companyLogo = "";
        this.model.companyLogoPath = "";
        this.model.companyWebsite = "";
        this.model.subscribersOfMemorandum = "";
    };
    CompanyDetailsComponent.prototype.fetchCompanyDetails = function () {
        var _this = this;
        this.ngxLoader.start();
        if (this.activatedRoute.snapshot.paramMap.get('newComp') == 'true') {
            this.registerNewCompany = true;
        }
        this.authService.getCompanyDetails(this.registerNewCompany).subscribe(function (res) {
            if (res) {
                _this.model = res;
                console.log("Basic details", res);
                if (_this.model['cinNumber']) {
                    _this.companyExists = true;
                }
                _this.businessList = _this.model['categoryDropdownValues'];
                _this.industries = _this.model['industryDropdownValues'];
                if (_this.model['companyLogoPath'] == null) {
                    _this.model['companyLogoPath'] = "";
                }
                if (_this.model['subscribersOfMemorandum'] == null) {
                    _this.model['subscribersOfMemorandum'] = "";
                }
                if (_this.model['companyWebsite'] == null) {
                    _this.model['companyWebsite'] = "";
                }
                if (_this.model['id'] == null) {
                    _this.model['id'] = "";
                }
                if (_this.model['dippCertificateNumber'] == null) {
                    _this.model['dippCertificateNumber'] = "";
                }
                if (_this.model['doingBusinessAs'] == null) {
                    _this.model['doingBusinessAs'] = "";
                }
                _this.model.directors.forEach(function (element) {
                    if (element['fatherName'] == null) {
                        element['fatherName'] = "";
                    }
                    if (element['motherName'] == null) {
                        element['motherName'] = "";
                    }
                    if (element['spouseName'] == null) {
                        element['spouseName'] = "";
                    }
                    if (element['nominationBy'] == null) {
                        element['nominationBy'] = "";
                    }
                    if (element['gender'] == null) {
                        element['gender'] = "";
                    }
                    if (element['occupation'] == null) {
                        element['occupation'] = "";
                    }
                    if (element['countryOfResidence'] == null) {
                        element['countryOfResidence'] = "";
                    }
                });
                if (_this.model.typeOfTheCompany == null) {
                    _this.model.typeOfTheCompany = '';
                }
                if (_this.model.nameOfTheCompany == null) {
                    _this.model.nameOfTheCompany = localStorage.getItem('companyName');
                }
                if (_this.model.companyCurrency == null) {
                    _this.model.companyCurrency = "INR - ₹";
                }
                if (_this.model.typeOfTheCompany == null || _this.model.typeOfTheCompany === "") {
                    _this.model.typeOfTheCompany = _this.typeofCompanies[0];
                }
                if (_this.model.dateOfIncorporation != null || _this.model.dateOfIncorporation != "") {
                    _this.model.dateOfIncorporation = _this.datepipe.transform(_this.model.dateOfIncorporation, 'yyyy-MM-dd');
                }
                _this.model.directors.forEach(function (a) {
                    a.dateOfBirth = _this.datepipe.transform(a.dateOfBirth, 'yyyy-MM-dd');
                    a.dateOfAppointement = _this.datepipe.transform(a.dateOfAppointement, 'yyyy-MM-dd');
                    a.dateOfBoardResoluitonForAppointement = _this.datepipe.transform(a.dateOfBoardResoluitonForAppointement, 'yyyy-MM-dd');
                });
                _this.model.founders.forEach(function (a) {
                    a.dateOfBirth = _this.datepipe.transform(a.dateOfBirth, 'yyyy-MM-dd');
                });
                if (_this.model.directors.length > 0 || _this.model.founders.length > 0) {
                    _this.cacheService.set("CompanyDetails", res);
                }
                //For Stepper Intialization
                var errorStatus = {
                    adminProfileTab: "COMPLETED",
                    companyDetailsTab: "INPROGRESS",
                    inCorporationTab: "INITIAL",
                };
                _this.errorFlags = errorStatus;
                _this.dataService.updateOnboarding(_this.errorFlags);
                _this.cacheService.set("AdminProfile", JSON.stringify(res));
                _this.ngxLoader.stop();
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error("Null response!.", "Failure!");
            }
        }, function (e) {
            _this.initializeDetails();
            if (e.error.reason === "Login Token Expired.") {
                _this.ngxLoader.stop();
                _this.cacheService.clear();
                _this.router.navigate(['']);
                return;
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error(e.error.message, "Failure!");
            }
        });
    };
    // Founders
    CompanyDetailsComponent.prototype.addFounder = function () {
        var length = this.model.founders.length;
        if (length > 0) {
            if (this.model.founders[length - 1].fullName === "" || this.model.founders[length - 1].emailId === ""
                || this.model.founders[length - 1].nationality === "") {
                this.toastr.error("Please fill previous row details", "Error!");
                return;
            }
            if (this.model.founders[length - 1].designation === "" || this.model.founders[length - 1].dateOfBirth === "") {
                this.toastr.warning("Please fill preious founder profile details", "Warning!");
            }
        }
        var newFounder = new FounderDetailsModel();
        newFounder.id = 0;
        newFounder.emailId = '';
        newFounder.fullName = '';
        newFounder.designation = '';
        newFounder.contactNumber = '';
        newFounder.address = '';
        newFounder.dateOfBirth = '';
        newFounder.nationality = this.selectedNationality;
        newFounder.linkedInProfile = '';
        newFounder.twitterHandle = '';
        newFounder.isSelected = false;
        newFounder.shouldShowDialog = false;
        newFounder.isEdit = true;
        this.model.founders.push(newFounder);
        this.updateFounderSelection();
        this.currentEditableFounderRow = this.model.founders.length - 1;
        this.editFounderRow(this.currentEditableFounderRow);
    };
    CompanyDetailsComponent.prototype.deleteIndividualFounder = function (index) {
        var _this = this;
        // this.helperService.useCase="Delete";
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: { header: "Confirm Delete?", text: "Are you sure you want to Delete ?" },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                _this.model.founders.splice(index, 1);
                _this.updateFounderSelection();
                _this.fName = false;
                _this.fEmail = false;
                _this.fNationality = false;
                _this.ngxLoader.stop();
            }
        });
    };
    CompanyDetailsComponent.prototype.editFounderRow = function (rowIndex) {
        this.disableFoundersInputs();
        this.model.founders[rowIndex].isEdit = true;
    };
    CompanyDetailsComponent.prototype.saveFounderRow = function (rowIndex) {
        if (this.model.founders[rowIndex].fullName === "" || this.model.founders[rowIndex].fullName == null ||
            this.model.founders[rowIndex].emailId === "" || !this.validateEmail(this.model.founders[rowIndex].emailId) || this.model.founders[rowIndex].emailId == null ||
            this.model.founders[rowIndex].nationality === "" || this.model.founders[rowIndex].nationality == null) {
            if (this.model.founders[rowIndex].fullName === "" || this.model.founders[rowIndex].fullName == null) {
                this.fName = true;
            }
            if (this.model.founders[rowIndex].emailId === "" || this.model.founders[rowIndex].emailId == null) {
                this.fEmail = true;
            }
            if (this.model.founders[rowIndex].nationality === "" || this.model.founders[rowIndex].nationality == null) {
                this.fNationality = true;
            }
            return;
        }
        else {
            this.model.founders[rowIndex].isEdit = false;
            this.fName = false;
            this.fEmail = false;
            this.fNationality = false;
        }
    };
    CompanyDetailsComponent.prototype.selectFounder = function () {
        this.updateFounderSelection();
    };
    CompanyDetailsComponent.prototype.selectAllFounders = function () {
        if (this.areAllFoundersSelected) {
            this.model.founders.forEach(function (founder) {
                founder.isSelected = true;
            });
        }
        else {
            this.model.founders.forEach(function (founder) {
                founder.isSelected = false;
            });
        }
        this.updateFounderSelection();
    };
    CompanyDetailsComponent.prototype.updateFounderSelection = function () {
        var found = this.model.founders.find(function (founder) { return founder.isSelected == false; });
        if (found || this.model.founders.length == 0) {
            this.areAllFoundersSelected = false;
        }
        else {
            this.areAllFoundersSelected = true;
        }
    };
    CompanyDetailsComponent.prototype.deleteAllSelectedFounders = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: { header: "Confirm Delete?", text: "Are you sure you want to Delete ?" },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                if (_this.areAllFoundersSelected) {
                    _this.model.founders = [];
                }
                else {
                    var newArray = _this.model.founders.filter(function (director) { return director.isSelected == false; });
                    _this.model.founders = newArray;
                }
                _this.updateFounderSelection();
                _this.ngxLoader.stop();
            }
        });
    };
    CompanyDetailsComponent.prototype.openFounderDetailsPopup = function (index) {
        this.model.founders[index].shouldShowDialog = true;
    };
    CompanyDetailsComponent.prototype.onCloseFounderDetails = function () {
        this.model.founders.forEach(function (e) { return e.shouldShowDialog = false; });
    };
    // Directors
    CompanyDetailsComponent.prototype.addDirector = function () {
        var length = this.model.directors.length;
        if (length > 0) {
            if (this.model.directors[length - 1].fullName === "" || this.model.directors[length - 1].din === "" ||
                this.model.directors[length - 1].typeOfDirector === "" || this.model.directors[length - 1].emailId === "" ||
                this.model.directors[length - 1].nationality === "") {
                this.toastr.error("Please fill previous row details", "Error!");
                return;
            }
            if (this.model.directors[length - 1].dateOfBoardResoluitonForAppointement === "" || this.model.directors[length - 1].dateOfAppointement === "") {
                this.toastr.warning("Please fill previous director profile details", "Warning!");
            }
        }
        var newDirector = new DirectorDetailsModel();
        newDirector.id = 0;
        newDirector.fullName = '';
        newDirector.typeOfDirector = '';
        newDirector.din = '';
        newDirector.emailId = '';
        newDirector.address = '';
        newDirector.contactNumber = '';
        newDirector.dateOfBirth = '';
        newDirector.nationality = this.selectedNationality;
        newDirector.dateOfAppointement = '';
        newDirector.dateOfBoardResoluitonForAppointement = '';
        newDirector.isSelected = false;
        newDirector.shouldShowDialog = false;
        newDirector.isEdit = true;
        this.model.directors.push(newDirector);
        this.updateSelection();
        this.currentEditableDirectorRow = this.model.directors.length - 1;
        this.editDirectorRow(this.currentEditableDirectorRow);
    };
    CompanyDetailsComponent.prototype.editDirectorRow = function (rowIndex) {
        this.disableDirectorsInputs();
        this.model.directors[rowIndex].isEdit = true;
    };
    CompanyDetailsComponent.prototype.saveDirectorRow = function (rowIndex) {
        if (this.model.directors[rowIndex].fullName === "" || this.model.directors[rowIndex].fullName == null ||
            this.model.directors[rowIndex].din === "" || this.model.directors[rowIndex].din == null ||
            this.model.directors[rowIndex].nationality === "" || this.model.directors[rowIndex].nationality == null ||
            this.model.directors[rowIndex].typeOfDirector === "" || this.model.directors[rowIndex].typeOfDirector == null ||
            this.model.directors[rowIndex].emailId === "" || this.model.directors[rowIndex].emailId == null ||
            !this.validateEmail(this.model.directors[rowIndex].emailId)) {
            if (this.model.directors[rowIndex].fullName === "" || this.model.directors[rowIndex].fullName == null) {
                this.dName = true;
            }
            if (this.model.directors[rowIndex].din === "" || this.model.directors[rowIndex].din == null) {
                this.dDin = true;
            }
            if (this.model.directors[rowIndex].nationality === "" || this.model.directors[rowIndex].nationality == null) {
                this.dNationality = true;
            }
            if (this.model.directors[rowIndex].typeOfDirector === "" || this.model.directors[rowIndex].typeOfDirector == null) {
                this.dType = true;
            }
            if (this.model.directors[rowIndex].emailId === "" || this.model.directors[rowIndex].emailId == null || !this.validateEmail(this.model.directors[rowIndex].emailId)) {
                this.dEmail = true;
            }
            return;
        }
        else {
            this.model.directors[rowIndex].isEdit = false;
            this.dName = false;
            this.dDin = false;
            this.dNationality = false;
            this.dType = false;
            this.dEmail = false;
        }
    };
    CompanyDetailsComponent.prototype.deleteIndividualDirector = function (index) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: { header: "Confirm Delete?", text: "Are you sure you want to Delete ?" },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                _this.model.directors.splice(index, 1);
                _this.updateSelection();
                _this.dName = false;
                _this.dDin = false;
                _this.dNationality = false;
                _this.dType = false;
                _this.dEmail = false;
                _this.ngxLoader.stop();
            }
        });
    };
    CompanyDetailsComponent.prototype.selectAllDirectors = function () {
        if (this.areAllDirectorsSelected) {
            this.model.directors.forEach(function (director) {
                director.isSelected = true;
            });
        }
        else {
            this.model.directors.forEach(function (director) {
                director.isSelected = false;
            });
        }
        this.updateSelection();
    };
    CompanyDetailsComponent.prototype.selectDirector = function () {
        this.updateSelection();
    };
    CompanyDetailsComponent.prototype.updateSelection = function () {
        var found = this.model.directors.find(function (director) { return director.isSelected == false; });
        if (found || this.model.directors.length == 0) {
            this.areAllDirectorsSelected = false;
        }
        else {
            this.areAllDirectorsSelected = true;
        }
    };
    CompanyDetailsComponent.prototype.deleteAllSelected = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: { header: "Confirm Delete?", text: "Are you sure you want to Delete ?" },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                if (_this.areAllDirectorsSelected) {
                    _this.model.directors = [];
                }
                else {
                    var newArray = _this.model.directors.filter(function (director) { return director.isSelected == false; });
                    _this.model.directors = newArray;
                }
                _this.updateSelection();
                _this.ngxLoader.stop();
            }
        });
    };
    CompanyDetailsComponent.prototype.openDirectorDetailsPopup = function (index) {
        this.model.directors[index].shouldShowDialog = true;
    };
    CompanyDetailsComponent.prototype.onCloseDirectorDetails = function () {
        this.model.directors.forEach(function (e) { return e.shouldShowDialog = false; });
    };
    CompanyDetailsComponent.prototype.checkValidation = function () {
        this.utilityService.formValidation();
        if (this.model.nameOfTheCompany === "" || this.model.nameOfTheCompany == null
            || this.model.cinNumber === "" || this.model.companyCurrency === null || this.model.cinNumber == null
            || this.model.typeOfTheCompany === null || this.model.typeOfTheCompany === ""
            || this.model.dateOfIncorporation === "" || this.model.dateOfIncorporation == null
            || this.model.corporateOfficeAddress === "" || this.model.corporateOfficeAddress == null
            || (this.model.companyEmailId === "" || this.model.companyEmailId == null || (this.model.companyEmailId && !this.validateEmail(this.model.companyEmailId)))) {
            if (this.model.nameOfTheCompany === "" || this.model.nameOfTheCompany == null) {
                this.companyNameValid = true;
            }
            if (this.model.cinNumber === "" || this.model.cinNumber == null) {
                this.cinNoValid = true;
            }
            if (this.model.typeOfTheCompany === "" || this.model.typeOfTheCompany == null) {
                this.companyTypeValid = true;
            }
            if (this.model.dateOfIncorporation === "" || this.model.dateOfIncorporation == null) {
                this.incorporateDtValid = true;
            }
            if (this.model.corporateOfficeAddress === "" || this.model.corporateOfficeAddress == null) {
                this.officeAddressValid = true;
            }
            if (this.model.companyEmailId === "" || this.model.companyEmailId == null) {
                this.emailValid = true;
            }
            if (this.model.companyCurrency === "" || this.model.companyCurrency == null) {
                this.companyCurrencyValid = true;
            }
            var company = document.getElementById("Company_Details_Card");
            this.utilityService.focusSection(company);
            return false;
        }
        else if (this.model.nic === "" || this.model.nic == null ||
            this.model.business == null || this.model.business.length == 0 ||
            this.model.industry == null || this.model.industry.length == 0) {
            if (this.model.nic === "" || this.model.nic == null) {
                this.nicValid = true;
            }
            if (this.model.business == null || this.model.business.length == 0) {
                this.businessInvalid = true;
            }
            if (this.model.industry == null || this.model.industry.length == 0) {
                this.industryInvalid = true;
            }
            var business = document.getElementById("Business_Details_Card");
            this.utilityService.focusSection(business);
            return false;
        }
        else {
            return true;
        }
    };
    CompanyDetailsComponent.prototype.transformDate = function (date) {
        return this.datepipe.transform(date, 'shortDate');
    };
    CompanyDetailsComponent.prototype.discardNewCompany = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: { header: "Confirm Discard?", text: "Are you sure you want to Discard ?" },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                _this.ngxLoader.start();
                _this.authService.discardNewCompany().subscribe(function (res) {
                    _this.ngxLoader.stop();
                    _this.router.navigate(["/landing", "onboarding", { newComp: true }]);
                }, function (e) {
                    _this.ngxLoader.stop();
                    _this.toastr.error(e.error.reason, "Failure!");
                });
                _this.ngxLoader.stop();
            }
        });
    };
    CompanyDetailsComponent.prototype.goToDashboard = function () {
        var _this = this;
        this.ngxLoader.start();
        this.authService.getUserDetails().subscribe(function (res) {
            _this.cacheService.set("userDetails", JSON.stringify(res));
            var userDetails = res;
            if (userDetails['company'] && userDetails['incorporationDetails']) {
                _this.navbarService.sendClickEvent();
                setTimeout(function () {
                    _this.router.navigate(['/landing', 'dashboard', 'capTable']);
                }, 300);
            }
            else if (userDetails['company'] && !userDetails['incorporationDetails']) {
                if (_this.registerNewCompany) {
                    _this.router.navigate(['/landing', 'onboarding', 'create-historical-round', { newComp: true }]);
                }
                else {
                    _this.router.navigate(['/landing', 'onboarding', 'create-historical-round']);
                }
            }
        }, function (e) {
            _this.toastr.error(e.error.reason, "Error");
        });
    };
    CompanyDetailsComponent.prototype.proceedToIncorporationDetails = function () {
        var _this = this;
        this.isValid = this.checkValidation();
        if (this.isValid == false) {
            this.toastr.error("Please fill all the required fields!");
            return;
        }
        if (this.model.business == null) {
            this.toastr.error("Please fill Category details!");
            this.businessInvalid = true;
            return;
        }
        if (this.model.industry == null) {
            this.toastr.error("Please fill Industry details!");
            this.industryInvalid = true;
            return;
        }
        if (this.model.founders.length == 0) {
            this.toastr.error("Please fill founder details!");
            return;
        }
        else if (this.model.founders.length > 0) {
            var k = 0;
            for (k = 0; k < this.model.founders.length; k++) {
                if (this.model.founders[k].fullName == "" || this.model.founders[k].fullName == null ||
                    this.model.founders[k].emailId == "" || !this.validateEmail(this.model.founders[k].emailId) || this.model.founders[k].emailId == null ||
                    this.model.founders[k].nationality == "" || this.model.founders[k].nationality == null) {
                    this.toastr.error("Please fill founder details!", "Error!");
                    if (this.model.founders[k].fullName === "" || this.model.founders[k].fullName == null) {
                        this.fName = true;
                    }
                    if (this.model.founders[k].emailId === "" || this.model.founders[k].emailId == null || !this.validateEmail(this.model.founders[k].emailId)) {
                        this.fEmail = true;
                    }
                    if (this.model.founders[k].nationality === "" || this.model.founders[k].nationality == null) {
                        this.fNationality = true;
                    }
                    var founder = document.getElementById("Founders_Card");
                    this.utilityService.focusSection(founder);
                    return;
                }
                if (this.model.founders[k].designation === "" || this.model.founders[k].designation == null ||
                    this.model.founders[k].dateOfBirth === "" || this.model.founders[k].dateOfBirth == null) {
                    this.toastr.warning("Please fill founder profile details of " + this.model.founders[k].fullName, "Warning!");
                }
            }
        }
        if (this.model.directors.length == 0) {
            this.toastr.error("Please fill director fields!");
            return;
        }
        else if (this.model.directors.length > 0) {
            var m = 0;
            for (m = 0; m < this.model.directors.length; m++) {
                if (this.model.directors[m].fullName === "" || this.model.directors[m].fullName == null ||
                    this.model.directors[m].din === "" || this.model.directors[m].din == null ||
                    this.model.directors[m].nationality === "" || this.model.directors[m].nationality == null ||
                    this.model.directors[m].typeOfDirector === "" || this.model.directors[m].typeOfDirector == null ||
                    this.model.directors[m].emailId === "" || this.model.directors[m].emailId == null ||
                    !this.validateEmail(this.model.directors[m].emailId)) {
                    this.toastr.error("Please fill director details!", "Error!");
                    if (this.model.directors[m].fullName === "" || this.model.directors[m].fullName == null) {
                        this.dName = true;
                    }
                    if (this.model.directors[m].din === "" || this.model.directors[m].din == null) {
                        this.dDin = true;
                    }
                    if (this.model.directors[m].nationality === "" || this.model.directors[m].nationality == null) {
                        this.dNationality = true;
                    }
                    if (this.model.directors[m].typeOfDirector === "" || this.model.directors[m].typeOfDirector == null) {
                        this.dType = true;
                    }
                    if (this.model.directors[m].emailId === "" || this.model.directors[m].emailId == null || !this.validateEmail(this.model.directors[m].emailId)) {
                        this.dEmail = true;
                    }
                    var director = document.getElementById("Directors_Card");
                    this.utilityService.focusSection(director);
                    return;
                }
                if (this.model.directors[m].dateOfAppointement === "" || this.model.directors[m].dateOfAppointement == null ||
                    this.model.directors[m].dateOfBoardResoluitonForAppointement === "" || this.model.directors[m].dateOfBoardResoluitonForAppointement == null) {
                    this.toastr.warning("Please fill director profile details of " + this.model.directors[m].fullName, "Warning!");
                }
            }
        }
        this.model.dateOfIncorporation = this.transformDate(this.model.dateOfIncorporation);
        this.model.founders.forEach(function (founder) {
            founder.dateOfBirth = _this.transformDate(founder.dateOfBirth);
        });
        this.model.directors.forEach(function (director) {
            director.dateOfBirth = _this.transformDate(director.dateOfBirth);
            director.dateOfAppointement = _this.transformDate(director.dateOfAppointement);
            director.dateOfBoardResoluitonForAppointement = _this.transformDate(director.dateOfBoardResoluitonForAppointement);
            director.dateOfBoardResoluitonForCessation = _this.transformDate(director.dateOfBoardResoluitonForCessation);
            director.dateOfCessation = _this.transformDate(director.dateOfCessation);
        });
        var formData = new FormData();
        formData.append('id', this.model.id);
        formData.append('nameOfTheCompany', this.model.nameOfTheCompany);
        formData.append('dippCertificateNumber', this.model.dippCertificateNumber);
        formData.append('doingBusinessAs', this.model.doingBusinessAs);
        formData.append('cinNumber', this.model.cinNumber);
        formData.append('typeOfTheCompany', this.model.typeOfTheCompany);
        formData.append('dateOfIncorporation', this.model.dateOfIncorporation);
        formData.append('corporateOfficeAddress', this.model.corporateOfficeAddress);
        formData.append('companyEmailId', this.model.companyEmailId);
        formData.append('companyWebsite', this.model.companyWebsite);
        formData.append('companyCurrency', this.model.companyCurrency);
        formData.append('nic', this.model.nic);
        formData.append('subscribersOfMemorandum', this.model.subscribersOfMemorandum);
        this.model.business.forEach(function (item, index) {
            formData.append("business[" + index + "]", item);
        });
        this.model.industry.forEach(function (item, index) {
            formData.append("industry[" + index + "]", item);
        });
        var _loop_1 = function (i) {
            Object.entries(this_1.model.founders[i]).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (key === "dateOfBirth") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                else {
                    formData.append("founders[" + i + "]." + key, value);
                }
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.model.founders.length; i++) {
            _loop_1(i);
        }
        var _loop_2 = function (i) {
            Object.entries(this_2.model.directors[i]).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (key === "dateOfBirth") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfAppointement") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfBoardResoluitonForAppointement") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfBoardResoluitonForCessation") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                if (key === "dateOfCessation") {
                    if (value == null || value === "") {
                        return;
                    }
                }
                else {
                    formData.append("directors[" + i + "]." + key, value);
                }
            });
        };
        var this_2 = this;
        for (var i = 0; i < this.model.directors.length; i++) {
            _loop_2(i);
        }
        if (this.profilePicture) {
            formData.set('companyLogo', (new File([this.profilePicture], this.fileName, { type: 'image/jpeg' })));
        }
        else if (this.model.companyLogoPath) {
            formData.append('companyLogoPath', this.model.companyLogoPath);
        }
        this.ngxLoader.start();
        setTimeout(function () {
            _this.authService.saveCompanyDetails(formData).subscribe(function (res) {
                if (res) {
                    //For Stepper Initialization
                    var errorStatus = {
                        adminProfileTab: "COMPLETED",
                        companyDetailsTab: "COMPLETED",
                        inCorporationTab: "INPROGRESS"
                    };
                    _this.toastr.success("Company Details Saved Successfully.", "Success!");
                    _this.errorFlags = errorStatus;
                    _this.dataService.updateOnboarding(_this.errorFlags);
                    _this.cacheService.set("CompanyDetails", JSON.stringify(_this.model));
                    localStorage.setItem('companyId', res['id']);
                    localStorage.setItem('waitingForVerification', 'false');
                    _this.authService.getUserDetails().subscribe(function (res) {
                        _this.cacheService.set("userDetails", JSON.stringify(res));
                        if (res['company']) {
                            localStorage.setItem('companyId', res['company']['id']);
                            localStorage.setItem('companyName', res['company']['nameOfTheCompany']);
                        }
                        if (_this.registerNewCompany) {
                            _this.router.navigate(['/landing', 'onboarding', 'create-historical-round', { newComp: true }]);
                        }
                        else {
                            _this.router.navigate(['/landing', 'onboarding', 'create-historical-round']);
                        }
                        _this.ngxLoader.stop();
                    });
                }
                else {
                    _this.ngxLoader.stop();
                    _this.toastr.error("Null response!.", "Failure!");
                }
            }, function (e) {
                _this.ngxLoader.stop();
                if (e.error.resultCode == "USER_NOT_VERIFIED") {
                    var errorStatus = {
                        adminProfileTab: "COMPLETED",
                        companyDetailsTab: "INPROGRESS",
                        inCorporationTab: "INITIAL"
                    };
                    _this.errorFlags = errorStatus;
                    _this.dataService.updateOnboarding(_this.errorFlags);
                    _this.toastr.error(e.error.reason, "Failure!");
                    return;
                }
                else {
                    var errorStatus = {
                        adminProfileTab: "COMPLETED",
                        companyDetailsTab: "COMPLETED",
                        inCorporationTab: "INPROGRESS"
                    };
                    _this.errorFlags = errorStatus;
                    _this.dataService.updateOnboarding(_this.errorFlags);
                    _this.toastr.error("Error: " + e.error.reason, "Error");
                    // this.router.navigate(['/landing', 'onboarding', 'incorporationDetails']);
                }
                _this.model.dateOfIncorporation = _this.datepipe.transform(_this.model.dateOfIncorporation, 'yyyy-MM-dd');
                _this.model.founders.forEach(function (founder) {
                    founder.dateOfBirth = _this.datepipe.transform(founder.dateOfBirth, 'yyyy-MM-dd');
                });
                _this.model.directors.forEach(function (director) {
                    director.dateOfBirth = _this.datepipe.transform(director.dateOfBirth, 'yyyy-MM-dd');
                    director.dateOfAppointement = _this.datepipe.transform(director.dateOfAppointement, 'yyyy-MM-dd');
                    director.dateOfBoardResoluitonForAppointement = _this.datepipe.transform(director.dateOfBoardResoluitonForAppointement, 'yyyy-MM-dd');
                    director.dateOfBoardResoluitonForCessation = _this.datepipe.transform(director.dateOfBoardResoluitonForCessation, 'yyyy-MM-dd');
                    director.dateOfCessation = _this.datepipe.transform(director.dateOfCessation, 'yyyy-MM-dd');
                });
            });
        }, 3000);
    };
    CompanyDetailsComponent.prototype.formatDate = function (date) {
        if (date) {
            var newDate = new Date(date);
            return (newDate.toISOString().replace("Z", "+0000"));
        }
        else {
            return "";
        }
    };
    CompanyDetailsComponent.prototype.showHelp = function (section) {
        if (section == this.helper) {
            return;
        }
        var targetElement;
        targetElement = document.getElementById(section);
        if (targetElement != null) {
            this.helperTopPosition = targetElement.offsetTop;
        }
        this.helpSections = this.helperService.getHelpContent("CompanyDetailsComponent", section);
        this.helper = section;
    };
    CompanyDetailsComponent.prototype.hideHelp = function () {
        this.helper = "";
    };
    CompanyDetailsComponent.prototype.allowAlphaNumericKey = function (event) {
        return (event.charCode == 8 || event.charCode == 0 || event.charCode == 101) ? null : ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
    };
    CompanyDetailsComponent.prototype.validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    CompanyDetailsComponent.prototype.disableFoundersInputs = function () {
        if (this.model.founders.length > 0) {
            this.model.founders.forEach(function (founder) {
                founder.isEdit = false;
            });
        }
    };
    CompanyDetailsComponent.prototype.disableDirectorsInputs = function () {
        if (this.model.directors.length > 0) {
            this.model.directors.forEach(function (director) {
                director.isEdit = false;
            });
        }
    };
    CompanyDetailsComponent.prototype.getEvent = function () {
        this.shouldLogoShowDialog = false;
        document.getElementById("overlay").style.display = "none";
    };
    CompanyDetailsComponent.prototype.openUploadImage = function () {
        this.shouldLogoShowDialog = true;
    };
    CompanyDetailsComponent.prototype.onImageSaved = function (imageDetails) {
        this.shouldLogoShowDialog = false;
        this.profilePicture = this.dataURItoBlob(imageDetails.profilePicture);
        this.croppedImage = imageDetails.croppedImage;
        this.fileName = imageDetails.fileName;
        this.companyLogoInvalid = false;
    };
    CompanyDetailsComponent.prototype.dataURItoBlob = function (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    };
    return CompanyDetailsComponent;
}());
export { CompanyDetailsComponent };
