import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shareholders',
  templateUrl: './shareholders.component.html',
  styleUrls: ['./shareholders.component.scss']
})
export class ShareholdersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
