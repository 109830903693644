<div class="row" (mouseleave)="hideHelp()">
    <div class="col-md-9" (mouseover)="showHelp('Instrument_Tab')">
        <mat-card id="Instrument_Tab">
            <mat-card-header>
                <mat-card-title>Security Class</mat-card-title>
            </mat-card-header>
            <mat-divider class="divider-line"></mat-divider>
            <mat-card-content>
                <form class="needs-validation" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="content-pg-1 top-20 btm-10">
                                You now have an understanding on how the cap table looks pre and post money. You also know how
                                many shares must be issued to each investor of the round. The shares can be issued as equity,
                                preference or hybrid securities. You can choose to issue this either as a standalone security or a
                                combination of securities.
                            </p>
                            <p class="content-pg-1 btm-20">
                                You can create a new class of security or apply the features of an existing security class to the
                                security of the round. Accordingly, select the suitable option from the security drop down in the
                                table below.
                            </p>
                        </div>
                    </div>
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row btm-20">

                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <mat-label>Equity Shares</mat-label><br>
                                            <label class="top-10">
                                                <input class="alert-status-equityshares" id="equityShares" [ngModelOptions]="{standalone: true}"
                                                    type="checkbox" [(ngModel)]="equityshares" data-toggle="switch"
                                                    data-off-color="default" required/>
                                            </label>
                                        </div>

                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <mat-label>Preference Share</mat-label><br>
                                            <label class="top-10">
                                                <input class="alert-status-preference" id="preferenceShares" [ngModelOptions]="{standalone: true}"
                                                    type="checkbox" [(ngModel)]="prefshares" data-toggle="switch" data-radio-all-off="true"
                                                    data-inverse="false" data-off-color="default" required/>
                                            </label>
                                        </div>

                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <mat-label>Warrants</mat-label><br>
                                            <label class="top-10">
                                                <input class="alert-status-warrants" id="warrantShares" type="radio" [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="warrents" data-toggle="switch" data-inverse="false" name="switches" data-radio-all-off="true"
                                                    data-off-color="default" required/>
                                            </label>
                                        </div>

                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <mat-label>Debentures</mat-label><br>
                                            <label class="top-10">
                                                <input class="alert-status-debentures" id="debenturesShares" [ngModelOptions]="{standalone: true}"
                                                    type="checkbox" [(ngModel)]="debentures" data-toggle="switch" data-radio-all-off="true"
                                                    data-inverse="false" data-off-color="default" required/>
                                            </label>
                                        </div>

                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <mat-label>Notes</mat-label><br>
                                            <label class="top-10">
                                                <input class="alert-status-notes" id="notesShares" type="radio" [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="notes" data-toggle="switch" data-inverse="false" name="switches" data-radio-all-off="true"
                                                    data-off-color="default" required/>
                                            </label>
                                        </div>

                                    </div>
                                    <div class="row">

                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <mat-label>Nominal Equity</mat-label><br>
                                            <label class="top-10">
                                                <input class="alert-status-nominal-equity" id="nominalShares" [ngModelOptions]="{standalone: true}"
                                                    type="checkbox" [(ngModel)]="nominaleqty" data-toggle="switch"
                                                    data-inverse="false" data-off-color="default" required/>
                                            </label>
                                        </div>

                                        <div class="col-lg-3 col-md-4 col-sm-4" *ngIf="nominaleqty">
                                            <mat-label>No of nominal equity shares</mat-label><br>
                                            <label class="top-10" style="width:100px !important"
                                                *ngIf="nominaleqty">
                                                <input type="text" class="form-control text-align-right" [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="nomEqtyShre" (keypress)="onlyNumberKey($event)"
                                                    (input)="onChangeNominal($event)" required/>
                                            </label>
                                        </div>

                                        <div class="col-lg-3 col-md-4 col-sm-4" *ngIf="nominaleqty">
                                            <mat-label>Class</mat-label>
                                            <div class="top-10">
                                                <select class="form-control cursor-hand" [(ngModel)]="nomEqtyInstCls" [ngModelOptions]="{standalone: true}"
                                                    (change)="selectednomInstCls(nomEqtyInstCls); fieldEdited($event)" placeholder="Select" required>
                                                    <option disabled selected [value]="null">Select</option>
                                                    <option *ngFor="let nomEqtyInstCls of nominalEqtyList"
                                                        [value]="nomEqtyInstCls">
                                                        {{ nomEqtyInstCls }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <div class="row btm-20">
                        <div class="col-md-12">
                            <table class="table-common top-20">
                                <thead>
                                    <tr>
                                        <th class="table-col1 text-align-left" style="padding-left: 20px;">
                                            Investors Name
                                        </th>
                                        <th class="table-col1 text-align-right">Holding %</th>
                                        <th class="table-col1 text-align-right">Amount</th>
                                        <!-- <th class="table-col1 text-align-right">No of Shares</th> -->
                                        <th class="table-col1 text-align-right">
                                            <span *ngIf="debentures">No of Debentures</span>
                                            <span *ngIf="notes">No of Notes</span>
                                            <span *ngIf="!debentures && !notes">No of Shares</span>
                                        </th>
                                        <th class="table-col1 text-align-right">FDB Shares</th>
                                        <th class="table-col1 text-align-right">
                                            Security
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-template ngFor let-group [ngForOf]="investorList" let-i="index">
                                        <tr>
                                            <td class="text-align-left" style="padding-left: 20px;">
                                                <span>
                                                    {{ group.investorName }}
                                                </span>
                                                <span class="cursor-hand" [hidden]="!nominaleqty"
                                                    *ngIf="!group.expanded && !group.nominalEquity"
                                                    (click)="group.expanded = true"><i class="fa fa-caret-right"
                                                        aria-hidden="true"></i></span>
                                                <span class="cursor-hand" [hidden]="!nominaleqty && !group.expanded"
                                                    *ngIf="(group.expanded || group.nominalEquity)"
                                                    (click)="group.expanded = false"><i class="fa fa-caret-down"
                                                        aria-hidden="true"></i></span>
                                            </td>
                                            <td class=" text-align-right" container="body">
                                                {{ group.holdingPercentage | percent: "2.2" }}
                                            </td>
                                            <td class=" text-align-right" container="body"
                                                ngbTooltip="{{showCurrency(group.investmentAmount)}}">
                                                {{ getTotalValueTble(group.investmentAmount) }}
                                            </td>
                                            <td class=" text-align-right" container="body"
                                                ngbTooltip="{{formatCurrency(group.numberOfShares)}}">
                                                {{ getTotalValueTble(group.numberOfShares)}}
                                            </td>
                                            <td class=" text-align-right" container="body"
                                                ngbTooltip="{{formatCurrency(group.numberOfDilutedShares)}}">
                                                {{ getTotalValueTble(group.numberOfDilutedShares    )}}
                                            </td>
                                            <td class=" text-align-right" container="body"
                                                ngbTooltip="{{ group.netWorth | CustomeCurrency: '2' }}">
                                                <select class="form-control cursor-hand" #instClass [(ngModel)]="group.className" (change)="fieldEdited($event)"
                                                    (ngModelChange)="instClassChange(i, instClass.value)" [ngModelOptions]="{standalone: true}" required>
                                                    <option selected value="">Select</option>
                                                    <option value="New Security Class">New Security Class</option>
                                                    <option *ngFor="let instCls of instClsListTble; let k=index" value="{{ instCls }}">
                                                        {{ instCls }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <img *ngIf="group.isEditAllowed" src="../../../assets/edit.svg" class="cursor-hand" (click)="instClassChange(i, instClass.value)" />
                                            </td>
                                        </tr>
                                        <tr class="individual-row" [hidden]="(!group.expanded && !group.nominalEquity)" *ngIf="nominaleqty">
                                            <td>
                                                <p></p>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <input class="form-control text-align-right" type="text"
                                                    [(ngModel)]="group.nominalEquityShares" [ngModelOptions]="{standalone: true}"
                                                    (keypress)="onlyNumberKey($event)"
                                                    (blur)="updateInvestor(i,$event.target.value)" 
                                                    (input)="csvVal($event.target.value, $event)" required/>
                                            </td>
                                            <td class=" text-align-right" style="padding-right: 30px;">
                                                Nominal Equity
                                            </td>
                                            <td></td>
                                        </tr>
                                    </ng-template>
                                    <tr class="border-top">
                                        <td class="lvp-label table-col1 text-align-left" style="padding-left: 20px;">
                                            <mat-label>Total</mat-label>
                                        </td>
                                        <td class=" text-align-right">
                                            <mat-label>{{ tblTotValues["TotalHoldingPercentage"] | percent: "2.2" }}
                                            </mat-label>
                                        </td>
                                        <td class=" text-align-right" container="body"
                                            ngbTooltip="{{showCurrency(tblTotValues.TotalAmount)}}">
                                            <mat-label>{{ getTotalValueTble(tblTotValues["TotalAmount"]) }}</mat-label>
                                        </td>
                                        <td class=" text-align-right" container="body"
                                            ngbTooltip="{{ formatCurrency(tblTotValues['TotalNoOfShares'])}}">
                                            <mat-label>{{ getTotalValueTble(tblTotValues["TotalNoOfShares"]) }}
                                            </mat-label>
                                        </td>
                                        <td class=" text-align-right" container="body"
                                            ngbTooltip="{{ formatCurrency(tblTotValues['TotalNoOfDilutedShares'])}}">
                                            <mat-label>{{ getTotalValueTble(tblTotValues["TotalNoOfDilutedShares"]) }}
                                            </mat-label>
                                        </td>
                                        <td style="padding-right: 30px;"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button id="saveAndContinue" [disableRipple]="disableripple" color="success" class="pull-right"
                                (click)="goToTranches()" ngbTooltip="Save and Continue">
                                Save and Continue
                            </button>
                            <button mat-raised-button id="previous" [disableRipple]="disableripple" color="success"
                                class="pull-right margin-right-20" (click)="goToNewInvestor()" ngbTooltip="Previous">
                                Previous
                            </button>
                        </div>
                    </div>
                </form>
                <app-new-security-class *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog"
                    [(selSecurity)]="selectedSecurity" [(selectedIndex)]="selectedIndex"
                    (saveSecurity)="saveSecurity($event)">
                </app-new-security-class>

                <app-debenture-inv-popup *ngIf="showInvestmentDialog" [(showDialog)]="showInvestmentDialog" [invDetails]="invDetails" [invList]="investorList" 
                    [nominalEqtyList]="nominalEqtyList" [roundCurrency]="roundCurrency" [companyCurrency]="companyCurrency" [exchangeRate]="exchangeRate"
                    (visibleChange)="savePopup($event)" [(selectedIndex)]="selectedIndex"></app-debenture-inv-popup>

            </mat-card-content>
        </mat-card>
    </div>
    <!-- Helper section -->
    <div class="col-md-3">
        <div class="helper-container">
            <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
              <mat-card-header class="help-hdr">
                <mat-card-title><label class="help-hdr-txt">{{
                    helpSections?.name
                  }}</label></mat-card-title>
              </mat-card-header>
              <hr />
              <mat-card-content class="card-help-content">
                <div [innerHTML]="helpSections?.description"></div>
              </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>