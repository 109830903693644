<div id="overlay" *ngIf="showDialog" [ngClass]="{'display-on': showDialog}"></div>

<div *ngIf="showDialog && isPlan" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header" *ngIf="esopList">
        <label class="dialogue-title">{{esopList.planName}}</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header" *ngIf="!esopList">
        <label class="dialogue-title">Esop Plan</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="content-row">
        <div class="col-xs-12 col-sm-12 col-md-12  btm-20">

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <mat-card class="top-20">
                <mat-card-header>
                  <mat-card-title>Plan</mat-card-title>
                </mat-card-header>
                <mat-divider class="divider-line"></mat-divider>
                <mat-card-content class="top-20">

                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 btm-20">
                      <p class="content-pg-1">
                        The summary of the ESOP plan and the corresponding vesting and exercise
                         terms applicable to the employees covered under the plan are displayed below. 
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <mat-label>Plan Name</mat-label>
                    </div>

                    <div class="col-md-4">
                      <mat-label>Plan Description</mat-label>
                    </div>

                    <div class="col-md-4">
                      <mat-label>Plan Start Date</mat-label>
                    </div>
                  </div>

                  <div class="row top-10" *ngIf="esopList">
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">{{esopList.planName}}</mat-label>
                    </div>

                    <div class="col-md-4">
                      <mat-label class="matLabelcls">{{esopList.planDescription}}</mat-label>
                    </div>

                    <div class="col-md-4">
                      <mat-label class="matLabelcls">{{esopList.planStartDate | date:'dd-MMM-yyyy'}}</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-4">
                      <mat-label>Pool Size</mat-label>
                    </div>

                    <div class="col-md-4">
                      <mat-label>Plan Term</mat-label>
                    </div>
                  </div>

                  <div class="row top-10" *ngIf="esopList">
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">{{getTotalValueTble(esopList.poolSize)}}</mat-label>
                    </div>

                    <div class="col-md-4">
                      <mat-label class="matLabelcls">{{esopList.planTerm}}</mat-label>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <mat-card class="top-20">
                <mat-card-header>
                  <mat-card-title>Vesting</mat-card-title>
                </mat-card-header>
                <mat-divider class="divider-line"></mat-divider>
                <mat-card-content class="top-20">

                  <div class="row">
                    <div class="col-md-6">
                      <mat-label>Cliff</mat-label>
                    </div>

                    <div class="col-md-6">
                      <mat-label>Period</mat-label>
                    </div>
                  </div>

                  <div class="row top-10" *ngIf="esopList">
                    <div class="col-md-6">
                      <mat-label class="matLabelcls">{{esopList.vesting.cliffPeriod}} Months</mat-label>
                    </div>

                    <div class="col-md-6">
                      <mat-label class="matLabelcls">{{ esopList.vesting.vestingPeriod }} Months</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-6">
                      <mat-label>Schedule</mat-label>
                    </div>
                  </div>

                  <div class="row top-10" *ngIf="esopList">
                    <div class="col-md-6">
                      <mat-label class="matLabelcls">{{ esopList.schedule }}</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                      <div class="note-content-style">
                        <p class="content-pg-1">
                          Notes: Employees have to fulfil all the vesting conditions set under the ESOP plan to become 
                          eligible to exercise their options.
                        </p>
                      </div>
                    </div>
                  </div>

                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <div class="row top-20">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Exercise</mat-card-title>
                </mat-card-header>
                <mat-divider class="divider-line"></mat-divider>
                <mat-card-content class="top-20">

                  <div class="row">
                    <div class="col-md-6">
                      <mat-label>Exercise</mat-label>
                    </div>

                    <div class="col-md-6">
                      <mat-label>Exercise %</mat-label>
                    </div>
                  </div>

                  <div class="row top-10" *ngIf="esopList">
                    <div class="col-md-6">
                      <mat-label class="matLabelcls">{{esopList.excerise.isExercise == true ? 'Yes':'No'}}
                      </mat-label>
                    </div>

                    <div class="col-md-6">
                      <mat-label class="matLabelcls">{{ esopList.excerise.exercisePercentage | percent:'2.2' }}
                      </mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-6">
                      <mat-label>Frequency</mat-label>
                    </div>
                  </div>

                  <div class="row top-10" *ngIf="esopList">
                    <div class="col-md-6">
                      <mat-label class="matLabelcls">{{ esopList.excerise.frequencyOfExercise }}</mat-label>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class="row top-20 btm-20">
            <div class="col-sm-12 col-xs-12 col-xl-12 col-md-12">
              <button mat-raised-button id="close" color="success" class="float-right" [disableRipple]="true" (click)="close()"
                ngbTooltip="Close">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showDialog && isEquity" class="dialogCls">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header" *ngIf="equityList">
        <label class="dialogue-title">{{equityList.className}}</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header" *ngIf="!equityList">
        <label class="dialogue-title">Common</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6  ">
        <button ngbTooltip="Close" type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="">
        <div class="col-xs-12 col-sm-12 col-md-12  btm-20">

           <mat-card class="top-20">
            <mat-card-header>
              <mat-card-title>Features</mat-card-title>
            </mat-card-header>
            <mat-divider class="divider-line"></mat-divider>
            <mat-card-content class="top-20">

              <div class="row top-20">
                <div class="col-xs-12 col-sm-12 col-md-12">
                  <p class="content-pg-1 btm-20">
                    The features of the common stock of equity are hereunder. 
                    All shareholders holding common equity shares enjoy the same rights regarding payment of dividend,
                    voting rights etc., agreed upon at the time of investment. 
                  </p>
                </div>
              </div>


              <div class="row">
                <div class="col-md-6">
                  <mat-label>Name</mat-label>
                </div>

                <div class="col-md-6">
                  <mat-label>Par Value</mat-label>
                </div>
              </div>

              <div class="row top-10" *ngIf="equityList">
                <div class="col-md-6">
                  <mat-label class="matLabelcls">{{equityList.className}}</mat-label>
                </div>
                <div class="col-md-6">
                  <mat-label class="matLabelcls">
                    {{ getTotalValueTble(equityList.parValue == null ? '-' : equityList.parValue) }}
                  </mat-label>
                </div>
              </div>

              <div class="row top-20">
                <div class="col-md-6">
                  <mat-label>Description</mat-label>
                </div>
              </div>

              <div class="row top-10" *ngIf="equityList">
                <div class="col-md-6">
                  <mat-label class="matLabelcls">{{equityList.sideNote == null ? '-': equityList.sideNote}}
                  </mat-label>
                </div>
              </div>
            </mat-card-content>
          </mat-card> 

          <div class="row top-20 btm-20">
            <div class="col-sm-12 col-xs-12 col-xl-12 col-md-12">
              <button mat-raised-button id="close" color="success" class="float-right" [disableRipple]="true" (click)="close()"
                ngbTooltip="Close">
                Close
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showDialog && isOthers" class="dialog">

  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header" *ngIf="selectedInstClsData">
        <label class="dialogue-title">{{ selectedInstClsData.className }}</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button ngbTooltip="Close" type="button" class="close top-close-btn" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>


  <!-- <div class="content-row"> -->
  <!-- Features Section starts here!!! -->
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="content-row">
        <div class="col-xs-12 col-sm-12 col-md-12  btm-20">

          <div class="row" *ngIf="selectedInstClsData">
            <div class="col-md-12">
              <mat-card class="top-20">
                <mat-card-header>
                  <mat-card-title>Features</mat-card-title>
                </mat-card-header>

                <mat-divider class="divider-line"></mat-divider>
                <mat-card-content>
                  <div class="row top-20">
                    <div class="col-md-12">
                      <p class="content-pg-1 btm-20">
                        The features of the CCPS class of security are hereunder. 
                        The holders of this class of security enjoy all such rights regarding payment of dividend, voting rights, 
                        liquidation preference, conversion to equity etc., 
                        as agreed upon at the time of investment. 
                      </p>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <mat-label>Voting Rights</mat-label>
                        </div>
                        <div class="row top-10 btm-20">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.votingRights == null ? '-' : selectedInstClsData.votingRights }}
                          </mat-label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row">
                          <mat-label>
                            Dividend pari passu to equity holders
                          </mat-label>
                        </div>
                        <div class="row top-10 btm-20">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.dividendPariPassuToEquityHolders == null ? '-' : selectedInstClsData.dividendPariPassuToEquityHolders
                                            }}
                          </mat-label>
                        </div>
                      </div>


                      <div class="col-md-6">
                        <div class="row">
                          <mat-label>Dividend Type</mat-label>
                        </div>
                        <div class="row top-10 btm-20">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.dividendType == null ? '-' : selectedInstClsData.dividendType }}
                          </mat-label>
                        </div>
                      </div>

                      <div class="col-md-6">
                      </div>

                      <div class="col-md-6">
                        <div class="row">
                          <mat-label>Dividend Rate</mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.dividendRate == null ? '-' : selectedInstClsData.dividendRate }}
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <!-- Features Section Ends here!!! -->
          <!-- Liquidation Section starts here!!! -->
          <div class="row top-20" *ngIf="selectedInstClsData">
            <div class="col-md-12">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Liquidation Preference</mat-card-title>
                </mat-card-header>

                <mat-divider class="divider-line"></mat-divider>

                <mat-card-content>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>Rank </mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.rankNumber == null ? '-' : selectedInstClsData.rankNumber }}
                          </mat-label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>Investment Multiple </mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.amount == null ? '-' : selectedInstClsData.amount }}
                          </mat-label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>Participation</mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.participation == null ? '-' : selectedInstClsData.participation == false ? 'No' : 'Yes' }}
                          </mat-label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>Participation Cap</mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.participationCap == null ? '-' : selectedInstClsData.participationCap == false ? 'No' : 'Yes' }}
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-12">
                      <div class="tab-content-style bg-color-beige">
                        <p class="content-pg-1">
                          Notes: ‘Rank’ and ‘Participation’ set direction on when and how much of the proceeds of the company the shareholders will be entitled to receive on liquidation. Accordingly, 
                          the proceeds will be distributed to the shareholders.
                        </p>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <!-- Liquidation Section ends here!!! -->
          <!-- Conversion Section starts here!!! -->
          <div class="row top-20" *ngIf="selectedInstClsData">
            <div class="col-md-12">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Conversion</mat-card-title>
                </mat-card-header>

                <mat-divider class="divider-line"></mat-divider>

                <mat-card-content>
                  <div class="col-md-12">
                    <div class="row ">
                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>
                            Conversion period - Max (years)
                          </mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.conversionPeriod == null ? '-' : selectedInstClsData.conversionPeriod }}
                          </mat-label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>
                            Conversion ratio/fomula
                          </mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.conversionRatio == null ? '-' : selectedInstClsData.conversionRatio }}
                          </mat-label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>Trigger</mat-label>
                        </div>
                        <div class="row top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.triggerType == null ? '-' : selectedInstClsData.triggerType }}
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <!-- Conversion Section ends here!!! -->
          <!-- Adjustments to ration/formula section starts here!!! -->
          <div class="row top-20" *ngIf="selectedInstClsData">
            <div class="col-md-12">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Anti-Dilution</mat-card-title>
                </mat-card-header>

                <mat-divider class="divider-line"></mat-divider>

                <mat-card-content>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row top-20">
                          <mat-label>Anti-dilution</mat-label>
                        </div>
                        <div class="row  top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.antiDilution == null ? '-' : selectedInstClsData.antiDilution }}
                          </mat-label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <!-- <div class="row  top-20">
                          <mat-label>
                            Stock restucturing
                          </mat-label>
                        </div>
                        <div class="row  top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.stockRestucturing == null ? '-' : selectedInstClsData.stockRestucturing }}
                          </mat-label>
                        </div> -->
                      </div>

                      <div class="col-md-6">
                        <!-- <div class="row  top-20">
                          <mat-label>
                            Valuation adjustment based on convertible
                          </mat-label>
                        </div>
                        <div class="row  top-10">
                          <mat-label class="matLabelcls">
                            {{ selectedInstClsData.valuationAdjustmentBasedOnConvertible == null ? '-' : selectedInstClsData.valuationAdjustmentBasedOnConvertible
                                            == true ? 'Yes' : 'No'}}
                          </mat-label>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <!-- Adjustments to ration/formula section ends here!!! -->
          <div class="row top-20 btm-20">
            <div class="col-sm-12 col-xs-12 col-xl-12 col-md-12">
              <button mat-raised-button id="close" [disableRipple]="true" color="success" class="float-right"
                aria-label="Close" ngbTooltip="Close" (click)="close()">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>