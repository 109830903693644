import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilityService } from 'src/app/services/utilities.service';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { AuthenticationService } from 'src/app/authentication.service';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-historical-redemption',
  templateUrl: './redemption.component.html',
  styleUrls: ['./redemption.component.scss']
})
export class RedemptionComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() rowData: any;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  redemptionDetails: FormGroup;
  isShowNote: boolean = false;
  isSubmitted: boolean = false;
  userDetails: any;
  usrCompanyCurrency = "";
  isFormReset: boolean = false;
  currencySymbol = "";

  constructor(private authService: AuthenticationService, 
    private toastr: ToastrService,
    private fb: FormBuilder,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService) { }

    close() {
      this.showDialog = false;
      this.visibleChange.emit(false);
    }

    resetForm(){
      this.isFormReset = true;
      this.redemptionDetails.reset();
    }

    onKeydownEvent(e) {
      this.utilityService.restrictMinus(e);
    }
  
    onlyNumberKey(event) {
      if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
          return false;
      }
      else {
          return true;
      }
    }

    formatCurrency(value){
      if(value > 0){
        const valueStr = value.toString();
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
  
    getTotalValueTble(value) {
      if(value > 0){
        const valueStr = value.toString();
        if(valueStr.split(".")[0].length > 5){
          return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else{
          return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
      else{
        return 0;
      }
    }
  
    csvVal(value, e){
      let tVal;
      if(e) {
        // store current positions in variables
        var start = e.target.selectionStart,
        end = e.target.selectionEnd;
      }
    
      let val = value;
      tVal = this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, val);
      if(e) {
        if(e.target.value) {
          let CSVLength = tVal.length;
          let valueLength = e.target.value.length;
          if(CSVLength > valueLength) {
              e.target.value = tVal;
              let difference = CSVLength - valueLength;
              start = start + difference;
              end = end + difference;
          }
          else if(CSVLength < valueLength){
            e.target.value = tVal;
            if(start != 0 || end != 0){
                let difference = valueLength - CSVLength;
                start = start - difference;
                end = end - difference;
            }
            else{
                start = start;
                end = end;
            }  
          }
          else{
            e.target.value = tVal;
          }
        }
        // restore from variables...
        e.target.setSelectionRange(start, end);
      } 
    }

    saveRedemption(){
      this.isSubmitted = true;
      if(this.redemptionDetails.valid){
        this.isSubmitted = false;
        let dt = new Date(this.redemptionDetails.value.redeemedDate);
        this.redemptionDetails.controls["redeemedDate"].setValue(dt.toISOString().replace("Z", "+0000"));
        this.redemptionDetails.controls["redeemedAmount"].setValue(this.redemptionDetails.value.redeemedAmount.toString().replace(/,/g, "") / 1);
        this.redemptionDetails.controls["amountLeft"].setValue(this.redemptionDetails.value.amountLeft ? this.redemptionDetails.value.amountLeft.toString().replace(/,/g, "") / 1 : null);
        for(const investor of this.rowData.investorsArray){
          const uniqueKey = investor.__KEY__ != null ? investor.__KEY__ : investor.uuid;
          if(uniqueKey == this.rowData.rowId){
            investor.redemptionDetails = this.redemptionDetails.value;
            if(investor['allotment'] && 
                investor['allotment'].some((element) => { return element['name'] == 'Redeemed'}) ) {}
            else {
                if(investor['allotment']) {
                  investor['allotment'].push({ name: 'Redeemed', identifier: 'R'});
                } else {
                  investor['allotment'] = [];
                  investor['allotment'].push({ name: 'Redeemed', identifier: 'R'});
                }
            }
            break;
          }
        }
        if(this.rowData.buyerName) {
            this.rowData.rowInfo.redemptionDetails = this.redemptionDetails.value;
            if(this.rowData.rowInfo['transactions'] && 
                this.rowData.rowInfo['transactions'].some((element) => { return element['name'] == 'Redeemed'}) ) {}
            else {
                this.rowData.rowInfo['transactions'].push({ name: 'Redeemed', identifier: 'R'});
            }
        }
        this.showDialog = false;
        this.visibleChange.emit(true);
      } else if(this.isFormReset) {
        for(const investor of this.rowData.investorsArray){
            const uniqueKey = investor.__KEY__ != null ? investor.__KEY__ : investor.uuid;
            if(uniqueKey == this.rowData.rowId){
              investor.redemptionDetails = null;
              _.remove(investor['allotment'], (element) => {
                if(element['name'] == 'Redeemed') {
                    return element['name'] == 'Redeemed';
                }
              });
              break;
            }
          }
          if(this.rowData.buyerName) {
              this.rowData.rowInfo.redemptionDetails = null;
              _.remove(this.rowData.rowInfo['transactions'], (element) => {
                if(element['name'] == 'Redeemed') {
                    return element['name'] == 'Redeemed';
                }
              });
          }
          this.showDialog = false;
          this.visibleChange.emit(true);
      }
    }
  ngOnInit() {
    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    } 
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }
    if(!this.usrCompanyCurrency) {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim()
    if(this.rowData.redemptionDetails != null){
      const redemptionDetails = this.rowData.redemptionDetails;
      const csvRedeemedAmount = this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, redemptionDetails.redeemedAmount) || 0;
      const csvAmountLeft = this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, redemptionDetails.amountLeft) || 0;
      this.redemptionDetails = this.fb.group({
        redeemedAmount: [csvRedeemedAmount, [Validators.required]],
        amountLeft: [csvAmountLeft, [Validators.required]],
        redeemedDate: [redemptionDetails.redeemedDate, [Validators.required]],
        notes: [redemptionDetails.notes]
      });
      this.isShowNote = redemptionDetails.notes != null ? true : false
    }
    else{
      this.redemptionDetails = this.fb.group({
        redeemedAmount: [null, [Validators.required]],
        amountLeft: [0, [Validators.required]],
        redeemedDate: [null, [Validators.required]],
        notes: [null]
      });
    }
    this.redemptionDetails.controls['redeemedAmount'].valueChanges.subscribe(value => {
        console.log(value);
        let totalAmt = this.rowData.rowInfo.amount;
        let redeemedAmt = value.toString().replace(/,/g, "") / 1;
        let remAmt = totalAmt - redeemedAmt;
        let remainingAmt = remAmt == 0 ? '0' : this.utilityService.formatCurrencyDeci(this.usrCompanyCurrency, remAmt);
        this.redemptionDetails.controls['amountLeft'].setValue(remainingAmt);
    });
  }

}
