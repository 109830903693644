import { Injectable } from '@angular/core';
import { HistoricalRoundObject } from './historical-round.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HistoricalRoundService {

  constructor(private toastr: ToastrService,
     private ngxLoader: NgxUiLoaderService,
     private authService: AuthenticationService) { }

  roundDetails: HistoricalRoundObject = new HistoricalRoundObject();
  historicalRoundSummary = null;

  setRoundDetails(roundDetails: HistoricalRoundObject){
    return this.roundDetails = roundDetails;
  }

  setRoundSummary(summary){
    return this.historicalRoundSummary = summary;
  }
  getRoundSummary(){
    return this.historicalRoundSummary;
  }

  resetRoundData(){
    this.roundDetails = new HistoricalRoundObject();
    this.historicalRoundSummary = {};
  }
}
