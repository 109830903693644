import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-view-docs-dialog',
  templateUrl: './view-docs-dialog.component.html',
  styleUrls: ['./view-docs-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewDocsDialogComponent implements OnInit{

  editor : any

  constructor(
    public dialogRef: MatDialogRef<ViewDocsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data:{url?:string, header? : string , type?:string}
  ) { }

  ngOnInit() {
    this.editor = this.data.url;
  }

  close(){
    this.dialogRef.close();
  }

}
