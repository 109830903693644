import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-securities',
  templateUrl: './securities.component.html',
  styleUrls: ['./securities.component.scss']
})
export class SecuritiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
