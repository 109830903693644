import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { of  } from 'rxjs/internal/observable/of';
import { asyncScheduler } from 'rxjs';
import { concatMap, repeat, delay, startWith } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilityService } from 'src/app/services/utilities.service';
import { environment } from "src/environments/environment";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HrmsEmployeeListModalComponent } from '../hrms-employee-list-modal/hrms-employee-list-modal.component';
@Component({
  selector: 'app-hrms-login-modal',
  templateUrl: './hrms-login-modal.component.html',
  styleUrls: ['./hrms-login-modal.component.scss']
})
export class HrmsLoginModalComponent implements OnInit {

  @Input() showDialogHrmsLogin: boolean;
  @Output() visibleChangeHrmsLogin: EventEmitter<any> = new EventEmitter<any>();

  public loginForm: FormGroup;
  public formType = 'login';
  src$;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private readonly utilityService: UtilityService,
    private readonly dialog: MatDialog,
    public dialogRef1: MatDialogRef<HrmsLoginModalComponent>,
  ) { 
  }

  login(loginForm: FormGroup) {
    this.ngxLoader.start();
    let loginObj = {
      "clientId" : loginForm.value.clientId,
      "clientSecret" : loginForm.value.clientSecret,
      "apiKey" : loginForm.value.apiKey,
      "productName" : "keka",
      "tenentName" : loginForm.value.tenentName
      }
      this.authService.loginHrms(loginObj).subscribe(
      (res) => {
        localStorage.setItem("hrmsIntegrationStatus", "Active")
        const dialogRef = this.dialog.open( HrmsEmployeeListModalComponent, {
          data : {},
          width: "400px",
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.dialogRef1.close();
          console.log("result", result)
          if (result && result === "login") {
            console.log("login")
          }
        });
        this.ngxLoader.stop();
      },
      (e) => {
        console.log("e", e)
        if (e.error['reason']) {
          this.toastr.error(e.error.reason, "Failure!");
        }
        this.ngxLoader.stop();
      }
    );
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      clientId: ['', [Validators.required]],
      clientSecret: ['', Validators.required],
      apiKey: ['', Validators.required],
      tenentName: ['', Validators.required]
    });
  }

  close() {
    this.showDialogHrmsLogin = false;
    this.visibleChangeHrmsLogin.emit({showMdl: this.showDialogHrmsLogin});
  }

}


  
