import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { permissionRead } from '@syncfusion/ej2-angular-filemanager';
import { PermissionsService } from '../services/permissions.service';

@Directive({
  selector: '[appHideIf]'
})
export class HideIfDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permService: PermissionsService
  ) { }

  @Input()
  set appHideIf(perm:Array<string>) {
      let userPermissions = this.permService.getUserPermissions();
      let permissionSet = this.permService.getAllPermissions();
      for(let i=0; i<perm.length; i++){
        const permissionRequired = permissionSet[perm[i]];
        if(userPermissions.includes(permissionRequired)){
          this.viewContainer.clear();
          return;
        }
      }
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
