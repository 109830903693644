<mat-card style="height: 100% !important; white-space: nowrap;">
  <mat-card-title>
    {{ mainCardHeader }}
  </mat-card-title>
  <mat-card-content>
    <div *ngIf="primaryValue || primaryHeader">
      <p *ngIf="primaryValue" [ngbTooltip]="(mainCardHeader=='Securities' || mainCardHeader=='Stakeholders' || mainCardHeader=='Related Company Details') ? (primaryValue | localeFormatter: 'tooltip-number') : (primaryValue | localeFormatter: 'tooltip-currency')" placement="top-start" id="pv">
        {{(primaryValue | localeFormatter: 'number')}}
      </p>
      <p *ngIf="primaryHeader" id="ph">
        {{primaryHeader}}
      </p>
    </div>
    <div id="ss" class="ss-class">

      <span *ngIf="secondaryHeader" id="sh" >
        {{secondaryHeader}} : 
        <span ngbTooltip="{{(mainCardHeader=='Securities' || mainCardHeader=='Stakeholders' || mainCardHeader=='Related Company Details') ? (secondaryHeader=='Holding Company Share Price') ? this.holdingCompanyCurrencySymbol+secondaryValue : (secondaryValue | localeFormatter: 'tooltip-number') : (secondaryValue | localeFormatter: 'tooltip-currency')}}" *ngIf="secondaryValue" id="sv">
          {{(mainCardHeader=='Securities' || mainCardHeader=='Stakeholders' || mainCardHeader=='Related Company Details') ? (secondaryHeader=='Holding Company Share Price') ? this.holdingCompanyCurrencySymbol+secondaryValue : (secondaryValue | localeFormatter: 'number') : (secondaryValue | localeFormatter: 'currency') || '-'}}
          </span>
      </span>
      <!-- <p *ngIf="secondaryValue" id="sv">
        {{secondaryValue}}
      </p> -->
      <!-- <mat-card-actions> -->
    <mat-icon [ngStyle]="{'float':iconPosition=='left'?'left':'right'}"  >
      <img [src]="svgIcon" alt="">
    </mat-icon>
  <!-- </mat-card-actions> -->
    </div>

  </mat-card-content>


</mat-card>
