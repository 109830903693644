
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrantLetterComponent } from './grant-letter/grant-letter.component';
import { PreviewDocumentComponent } from './preview-document/preview-document.component';

const routes: Routes = [
  { path: "grant-letter/:resolutionId", component: GrantLetterComponent },
  { path: "grant-letter", component: GrantLetterComponent },
  {path: 'preview-document/:id/:stepPlanInstanceId', component: PreviewDocumentComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GenerateDocumentRoutingModule { }
