import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuyBackOverviewComponent } from './buy-back-overview/buy-back-overview.component';
import { BuyBackTabComponent } from './buy-back-tab/buy-back-tab.component';
import { ConfirmTabComponent } from './confirm-tab/confirm-tab.component';
import { CreateNewBuyBackComponent } from './create-new-buy-back/create-new-buy-back.component';
import { ReviewTabComponent } from './review-tab/review-tab.component';
import { ShareholdersTabComponent } from './shareholders-tab/shareholders-tab.component';
import { BuyBackSummaryTabComponent } from './summary-tab/summary-tab.component';
import { BuyBackDetailsComponent } from './buy-back-details/buy-back-details.component';

const routes: Routes = [
  { path: "overview", component: BuyBackOverviewComponent },
  {
    path: "newBuyBack",
    component: CreateNewBuyBackComponent,
    children: [
      { path: "buyBack", component: BuyBackTabComponent },
      { path: "shareholders", component: ShareholdersTabComponent},
      { path: "review", component: ReviewTabComponent},
      { path: "summary", component: BuyBackSummaryTabComponent},
      { path: "confirm", component: ConfirmTabComponent}
    ],
  },
  {path: "details", component: BuyBackDetailsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuyBackRoutingModule { }
