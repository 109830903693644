import { state } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.scss']
})
export class UpdateStatusComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() planStateDialog;
  @Input() nextGrantStatus;
  @Input() bulkStateChangeIds;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  statusChangeForm: FormGroup;
  planStatus;
  showNotes: boolean = false;
  isSubmitted: boolean = false;
  isGranted: boolean = false;
  isOffered: boolean = false;
  isRejected : boolean = false;
  isOfferedWithdrawed: boolean = false;

  constructor(private authService: AuthenticationService, 
    private toastr: ToastrService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService) { }

  ngOnInit() {
    if(this.nextGrantStatus != null){

      if(this.nextGrantStatus && this.nextGrantStatus.availableStates.includes("SIGNED_BY_HOLDER")){
        this.nextGrantStatus.availableStates.splice(this.nextGrantStatus.availableStates.indexOf("SIGNED_BY_HOLDER"),1)
      }
      this.isGranted = this.nextGrantStatus.availableStates.includes("WITHDRAW") && this.nextGrantStatus.availableStates.length == 1
      this.isOffered = this.nextGrantStatus.availableStates.includes("WITHDRAW") && this.nextGrantStatus.availableStates.length == 2

      this.statusChangeForm = this.fb.group({
        optionHolderIds: [this.bulkStateChangeIds, Validators.required],
        optionHolderState: [null, Validators.required],
        note:this.isGranted ? [null, Validators.required] : [null],
        dateOfTransaction:[null, Validators.required],
        email:this.isGranted ? [true] : [false],
        mailToBeSent: this.isGranted ? [true] : (this.isOffered ? [false] : null),
      });

      if(this.isGranted) {
        this.showNotes = true
      }
    }
    else if(this.planStateDialog != null){
      this.statusChangeForm = this.fb.group({
        esopPlanId: [this.planStateDialog.details['esopPlanId'], Validators.required],
        esopPlanState: [null, Validators.required],
        note:[null],
        dateOfTransaction:[null, Validators.required]
      });
    } 
  }

  remove_(str){
    return str.replace(/_/g, ' ');
  }

  changeStatus() {
    const date = new Date;
    this.isSubmitted = true;
    this.statusChangeForm.get('dateOfTransaction').setValue(date.toISOString().replace("Z", "+0000"));
    if(this.statusChangeForm.valid){
      this.ngxLoader.start();
      if(this.planStateDialog != null){
        this.authService.updatePlanStatus(this.statusChangeForm.value).subscribe((response)=>{
          this.showDialog = false;
          this.visibleChange.emit();
          this.ngxLoader.stop();
          this.toastr.success("Status updated successfully.","Success!");
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, 'Error');
        });
      }
      else if(this.nextGrantStatus != null){
        this.authService.updateBulkGrantStatus(this.statusChangeForm.value).subscribe((response)=>{
          this.showDialog = false;
          this.visibleChange.emit();
          this.ngxLoader.stop();
          this.toastr.success("Status updated successfully.","Success!");
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, 'Error');
        })
      }
      }
    // if(this.planStatus == 'Active') {
    //     this.authService.activatePlan(this.plan['esopPlanId']).subscribe(
    //         (res) => {
    //             this.showDialog = false;
    //             this.visibleChange.emit();
    //         },
    //         (e) => {
    //             this.toastr.error(e.error.reason, 'Error');
    //         }
    //     );
    // } else {
    //     this.invStatus = true;
    // }
    
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit();
  }

  planState(e){
    if(e == "REJECTED"){
      this.isRejected = true;
      this.showNotes=true;
    }else{
      this.isRejected = false;
      this.showNotes = false;
    }
  }

  grantState(e) {
    if(this.isOffered && e=='WITHDRAW') {
      this.isOfferedWithdrawed = true;
      this.showNotes = true
    } else if(this.isOffered && e=='GRANTED') {
      this.isOfferedWithdrawed = false;
      this.showNotes = false
    }
  }
}
