import { AuthenticationService } from "src/app/authentication.service";
import { ToastrService } from "ngx-toastr";
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationStart,
  ResolveStart,
  NavigationCancel,
} from "@angular/router";
import { Component, HostListener, Inject } from "@angular/core";
import { NgxUiLoaderConfig, NgxUiLoaderService } from "ngx-ui-loader";
import { CacheProviderService } from "./services/cache-provider.service";
import { SwUpdate } from "@angular/service-worker";
import { SVGLoader } from "./services/svg-loader.service";
import { Location } from "@angular/common";
import { BROADCAST_SERVICE } from "./services/broadast-token.service";
import {
  BroadcastMessage,
  BroadCastService,
} from "./services/broadast.service";
import { MatDialog } from "@angular/material";
import { CloseTabComponent } from "./dialogues/close-tab/close-tab.component";
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @HostListener("window:load") onFocus() {
    this.broadCastService.messagesOfType("ping").subscribe((message) => {
      this.handleBroadcast(message);
    });
    this.broadCastService.publish({
      type: "ping",
      payload: "ping",
      sessionId: sessionStorage.getItem("sessionId"),
    });
  }
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private readonly updates: SwUpdate,
    private readonly svgLoader: SVGLoader,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    @Inject(BROADCAST_SERVICE) private broadCastService: BroadCastService
  ) {
    // set a unique sessionId
    sessionStorage.setItem(
      "sessionId",
      (((1 + Math.random()) * 0x10000) | 0).toString(16)
    );
    updates.available.subscribe((event) => {
      console.log("current version is", event.current);
      console.log("available version is", event.available);
    });
    updates.activated.subscribe((event) => {
      console.log("old version was", event.previous);
      console.log("new version is", event.current);
    });

    updates.available.subscribe((event) => {
      updates.activateUpdate().then(() => this.updateApp());
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationCancel) {
        if (!event.url.includes("xUserToken")) console.warn(event);
      }
      if (event instanceof NavigationEnd) {
        gtag("set", { user_id: localStorage.getItem("emailId") });
        gtag("config", "UA-178784472-2", {
          page_path: event.urlAfterRedirects,
        });
      }
      if (event instanceof NavigationStart) {
        if (event.url == "/verifyOtp" && event.restoredState != null) {
          this.router.navigate(["/"]);
        }
      }
      if (event instanceof RouteConfigLoadStart) {
        this.ngxLoader.start();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.ngxLoader.stop();
      }
    });
    this.svgLoader.loadSvgs();
    const searchParams = new URLSearchParams(window.location.search);
    let navigatedBackFromNewEsopUI = false;
    const href = window.location.href;
    const pathname = window.location.pathname;
    if (href.includes("accessToken")) {
      let currentUser = searchParams.get("accessToken");
      if (currentUser) {
        localStorage.setItem("x-access-token", currentUser);
        localStorage.setItem("signintype", "normal");
        navigatedBackFromNewEsopUI = true;
      }
    }
    if (href.includes("xUserToken")) {
      let currentUser = searchParams.get("xUserToken");
      if (currentUser) {
        localStorage.setItem("currentUser", currentUser);
        localStorage.setItem("signintype", "normal");
        navigatedBackFromNewEsopUI = true;
      }
    }
    if (href.includes("companyId")) {
      const companyId = searchParams.get("companyId");
      if (companyId) {
        localStorage.setItem("companyId", companyId);
      }
    }
    if (navigatedBackFromNewEsopUI) {
      this.authService.getUserDetails().subscribe((data) => {
        this.cacheService.set("userDetails", JSON.stringify(data));
        this.router.navigateByUrl(pathname);
        this.location.replaceState(window.location.pathname);
      });
    }
  }

  title = "equion";
  ngxUIconfig: NgxUiLoaderConfig = {
    bgsOpacity: 0.2,
    fgsColor: "#333333",
    overlayColor: "rgba(40, 40, 40, 0.2)",
  };
  updateApp() {
    document.location.reload();
    console.log("The app is updating right now");
  }
  handleBroadcast(message: BroadcastMessage) {
    const currentSessionId = sessionStorage.getItem("sessionId");
    if (message.sessionId !== currentSessionId) {
      console.log("recieved message", message);
      switch (message.payload) {
        case "ping": {
          this.broadCastService.publish({ type: "ping", payload: "pong" });
          break;
        }
        case "pong": {
          const dialogRef = this.dialog.open(CloseTabComponent, {
            width: "400px",
            disableClose: true,
          });
          break;
        }
      }
    }
  }
  ngOnInit() {
    let currentUser = localStorage.getItem("currentUser");
    let userDetails = this.cacheService.get("userDetails");
    const href = window.location.href;
    if (href.includes("#") && href.endsWith("employeeLogin")) {
      this.router.navigate(["/employeeLogin"]);
      return;
    }
    if (currentUser != null && !userDetails) {
      this.ngxLoader.start();
      this.authService.getUserDetails().subscribe(
        (response) => {
          this.cacheService.set("userDetails", JSON.stringify(response));
          if (response["company"]) {
            localStorage.setItem("companyId", response["company"]["id"]);
            if (response["company"]["integration"]) {
              localStorage.setItem(
                "hrmsIntegrationStatus",
                response["company"]["integration"]["status"]
              );
            }
          }
          gtag("set", { user_id: response["emailId"] });
          this.ngxLoader.stop();
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
      );
    }
  }
}
