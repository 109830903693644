<section class="content">
  <div class="background-gradient">
    <div class="row header-row">
      <div class="col-md-2 col-lg-3"></div>
      <div class="col-md-8 col-lg-6">
        <p class="heading">
          <img src="../../../assets/logo/logo.svg" />
        </p>
        <p class="subheading">Equity Management</p>
      </div>
      <div class="col-md-2 col-lg-3"></div>
    </div>
    <div class="row content-row">
      <div class="col-md-2 col-lg-3"></div>
      <div class="col-md-8 col-lg-6">
        <mat-card class="card">
          <mat-card-header>
            <mat-card-title class="text-align-center">Authenticate Your Account</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="form-card text-align-center">
              <mat-label>Enter the OTP sent to your registered email address.</mat-label>
              <form class="form">
                <div class="form-group margin-top-lg text-align-left">
                  <mat-label class="required">Enter OTP</mat-label>
                  <input type="text" pattern="[0-9]*" class="form-control top-10" [(ngModel)]="otp" [ngModelOptions]="{standalone: true}"
                  placeholder="OTP" required>
                </div>
                <div class="form-group margin-top-lg">
                  <button mat-raised-button id="send" color="success" type="submit" (click)="submitOtp()" [disableRipple]="true"
                  
                   class="width-100 submit-button">Submit</button>
                </div>
                <div class="margin-top-lg resend-otp">
                  <span *ngIf="counter" >If OTP is not received click Resend OTP in {{counter}}s</span>
                  <p (click)="resendOtp()" *ngIf="!counter">
                    <mat-label class="resend-otp-btn"> Resend OTP </mat-label>
                  </p>
                </div>
              </form>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-md-2 col-lg-3"></div>
  </div>
</section>
<app-footer-component></app-footer-component>