import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { Location } from "@angular/common";
import {
  Router,
  ActivatedRoute,
  UrlSegment,
  NavigationEnd,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CacheProviderService } from "../services/cache-provider.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NavbarService } from "../services/navbar-reload";
import { Subscription } from "rxjs";
import { PermissionsService } from "../services/permissions.service";

import { UtilityService } from "../services/utilities.service";
import { BROADCAST_SERVICE } from "../services/broadast-token.service";
import { BroadCastService } from "../services/broadast.service";
import { EventEmitterService } from "src/app/services/event-emitter.service";

interface ChildNavigation {
  name: string;
  path: string;
  redirectPath: string;
  shouldHide?: boolean;
  activeLink?: string;
}
interface NavigationMenu {
  name: string,
  path: string,
  redirectPath: string,
  children: Array<ChildNavigation>
}
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  clickEventsubscription: Subscription;
  @ViewChild("tabs", undefined) tabs;
  @ViewChild("dropdown_menu") dropdown_menu: ElementRef;
  userName;
  userRole;
  profilePicture;
  userLoaded = false;
  profileInitials = "";
  public usrCompanies;
  public usrCompanyCurrency = "INR - ₹";
  currencySymbol = "";
  public curCompany;
  public incorporationDetails: any = null;
  empLogin: boolean = false;
  filteredCompanies: any;
  fullAccess: boolean = false;
  companyAccess: boolean = false;
  doesTSExist: boolean = false;
  currentTabList = [];
  activeLink = "";
  userAccessRole = "";
  isEsopOnlyCompany = false;
  termSheet = null;
  isDialogShown = "none";
  subscription = new Subscription();
  accesscomp: any = {
    capTable: [{ name: "capTable", path: "" }],

    // "Transactions" : [ { "name":" "  "path":""  } { "name":""   "path":"" }    ]

    // "Shareholders":[ { "name":""  "path":""  } { "name":""   "path":"" }    ]

    // "Securities" :[ { "name":""  "path":""  } { "name":""   "path":"" }    ]

    // "Options" : [ { "name":""  "path":""  } { "name":""   "path":"" }    ]

    // "Modeling" :[ { "name":""  "path":""  } { "name":""   "path":"" }    ]

    // "Settings" : [ { "name":""  "path":""  } { "name":""   "path":"" }    ]

    // "EmpDashboard" :[ { "name":""  "path":""  } { "name":""   "path":"" }    ]

    // "FAQ" :[ { "name":""  "path":""  } { "name":""   "path":"" }    ]
  };

  @HostListener("body:click") bodyClicked() {
    const overlay = document.getElementById("overlay");
    const dialog = document.querySelector("dialog");
    let dialogDisplay = "";
    if (dialog) {
      dialogDisplay = "block";
      if (dialogDisplay != this.isDialogShown) {
        this.isDialogShown = dialogDisplay;
      }
    } else if (overlay) {
      dialogDisplay = overlay.style.display;
      if (dialogDisplay != this.isDialogShown) {
        this.isDialogShown = dialogDisplay;
      }
    }
  }
  constructor(
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    public router: Router,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    public navbarService: NavbarService,
    private permService: PermissionsService,
    private readonly utilityService: UtilityService,
    @Inject(BROADCAST_SERVICE) private broadCastService: BroadCastService,
    private eventEmitterService: EventEmitterService
  ) {
    let routerUrl = "";
    const sessionId = sessionStorage.getItem("sessionId");
    this.utilityService.dataEmitter.subscribe((emittedData) => {
      if (emittedData && emittedData["currentUserRole"]) {
        this.userAccessRole = emittedData["currentUserRole"];
        this.userLoaded = true;
        this.populateTabList();
      }
    });
    this.router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl) {
        const routerList =
          this.router.parseUrl(routerUrl).root.children["primary"].segments;

        if (router instanceof NavigationEnd) {
          this.populateTabList(routerList);
        }
      }
    });
    this.clickEventsubscription = this.navbarService
      .getClickEvent()
      .subscribe(() => {
        this.ngOnInit();
      });
  }

  ngOnInit() {
    let currentUser = localStorage.getItem("currentUser");
    this.fullAccess = this.permService.authorizeUser(["full-access"]);
    this.companyAccess = this.permService.authorizeUser([
      "opts-management-view",
      "opts-homepage-view",
      "captable-summary",
      "full-access",
      "!strict",
    ]);
    if (currentUser != null && currentUser != "undefined") {
      this.authService.getUserDetails().subscribe(
        (res) => {
          this.isEsopOnlyCompany = res["company"]["isEsopOnlyCompany"];
          this.cacheService.set("userDetails", JSON.stringify(res));
          if (res["company"]) {
            localStorage.setItem("companyId", res["company"]["id"]);
            localStorage.setItem("userRole", res["authorities"][0]["name"]);
            this.utilityService.setValue(
              "userRole",
              res["authorities"][0]["name"]
            );
            this.utilityService.setValue("showFaq", res["company"]["showFaq"]);
          }
          this.userName = res["fullName"];
          this.usrCompanyCurrency = res["company"]["companyCurrency"];
          this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
          if (res["authorities"] != null) {
            this.userRole = res["authorities"][0]["name"];
            const allPermissions = this.permService.getAllPermissions();
            if (
              res["authorities"][0]["permissions"].includes(
                allPermissions["emp-view"]
              )
            ) {
              this.empLogin = true;
            }
          }
          this.incorporationDetails = res["incorporationDetails"];
          if (res["pathToProfilePicture"] != null) {
            this.profilePicture = res["pathToProfilePicture"]["fileUrl"];
          } else {
            this.profilePicture = null;
          }
          if (!this.profilePicture) {
            const intials = this.userName
              .split(" ")
              .map((name) => name[0])
              .join("")
              .toUpperCase();
            this.profileInitials = intials;
          }
          if (res["authorities"] != null) {
            this.userRole = res["authorities"][0].name;
          }
          if (res["company"]) {
            this.curCompany = res["company"]["nameOfTheCompany"];
          }
          if (res["companiesAssociatedToUser"].length > 0) {
            this.usrCompanies = res["companiesAssociatedToUser"];
            this.assignCompaniesCopy();
          }
          this.getTermSheet();
        },
        (e) => {
          if (e.error.reason === "Login Token Expired.") {
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate([""]);
            return;
          } else {
            this.toastr.error(e.error.reason, "Failure!");
          }
        }
      );
    }
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.invokeSwitchCompanyFunction.subscribe(
          (companyId: string) => {
            this.selectedCompany(companyId);
          }
        );
    }
  }

  formatCurrency(value) {
    return this.utilityService.convertedFormatCurrency(
      this.usrCompanyCurrency,
      value
    );
  }

  getTermSheet() {
    this.authService.getLatestTermSheet().subscribe(
      (res) => {
        if (res) {
          this.doesTSExist = true;
          this.termSheet = res;
          this.termSheet["investorDetails"][0]["amountCommited"] =
            this.utilityService.getTotalValueTble(
              this.termSheet["investorDetails"][0]["amountCommited"]
            );
          this.termSheet["valuation"] = this.utilityService.getTotalValueTble(
            this.termSheet["valuation"]
          );
        } else {
          this.termSheet = res;
          this.doesTSExist = false;
        }
      },
      (e) => {}
    );
  }

  showTermSheets(name) {
    if (name == "Term Sheets" && this.doesTSExist) {
      return false;
    } else if (name == "Term Sheets" && !this.doesTSExist) {
      return true;
    } else if (name != "Term Sheets") {
      return false;
    }
  }

  addNewComp() {
    this.incorporationDetails = null;
    this.authService.getUserDetails().subscribe(
      (res) => {
        this.cacheService.set("userDetails", JSON.stringify(res));
        if (
          res["searchedCompany"] &&
          res["isVerified"] == false &&
          res["isAllowedAccess"] == false
        ) {
          this.router.navigate([
            "/landing",
            "onboarding",
            "adminProfile",
            { newComp: true },
          ]);
        } else if (
          res["searchedCompany"] &&
          res["isVerified"] == true &&
          res["isAllowedAccess"] == true &&
          res["isSearchedCompanyMapingFound"] == null
        ) {
          this.router.navigate([
            "/landing",
            "onboarding",
            "companyDetails",
            { newComp: true },
          ]);
        } else if (
          res["searchedCompany"] &&
          res["isVerified"] == true &&
          res["isAllowedAccess"] == true &&
          res["isSearchedCompanyMapingFound"] == true
        ) {
          this.router.navigate(["/landing", "onboarding", { newComp: true }]);
        } else {
          this.router.navigate(["/landing", "onboarding", { newComp: true }]);
        }
      },
      (e) => {
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }
  //  showbackbutton(){
  //    return (this.router.url.split(/[?#]/).shift().match(/\/[^/]+?/g).length>4)
  //  }
  //   goback(){
  //     // this.router.navigate([".."],      {relativeTo: this.activatedRoute});
  //     // this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
  //     this.router.navigateByUrl(this.router.url.substr(0, this.router.url.lastIndexOf('/')));
  //   }
  //   backClicked() {
  //     this._location.back();
  //   }

  logout() {
    this.authService.logout().subscribe(
      () => {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("signintype");
        localStorage.clear();
        sessionStorage.clear();
        if (this.empLogin) {
          this.router.navigate(["/employeeLogin"]);
        } else {
          this.router.navigate(["/"]);
        }
      },
      () => {
        localStorage.clear();
        sessionStorage.clear();
        if (this.empLogin) {
          this.router.navigate(["/employeeLogin"]);
        } else {
          this.router.navigate(["/"]);
        }
      }
    );
  }

  gotoUserProfile() {
    this.router.navigate(["/landing", "dashboard", "userProfile"]);
  }

  logoClick() {
    if (this.incorporationDetails != null) {
      this.permService.navigateBasedOnPermission();
    } else {
      return;
    }
  }

  selectedCompany(companyId) {
    const currentCompany = localStorage.getItem("companyId");
    localStorage.setItem("companyId", companyId);
    sessionStorage.removeItem("sellerTabInfo");
    this.ngxLoader.start();

    this.authService.getUserDetails().subscribe(
      (res) => {
        this.isEsopOnlyCompany = res["company"]["isEsopOnlyCompany"];
        this.cacheService.set("userDetails", JSON.stringify(res));
        this.userName = res["fullName"];
        this.userRole = "Admin";
        this.incorporationDetails = res["incorporationDetails"];
        if (res["authorities"] != null) {
          this.userRole = res["authorities"][0].name;
          localStorage.setItem("userRole", res["authorities"][0]["name"]);
        }
        if (res["company"]) {
          this.curCompany = res["company"]["nameOfTheCompany"];
        }
        if (res["companiesAssociatedToUser"].length > 0) {
          this.usrCompanies = res["companiesAssociatedToUser"];
        }
        if (
          (res["company"] != null && res["incorporationDetails"] != null) ||
          res["company"]["isEsopOnlyCompany"]
        ) {
          localStorage.setItem("companyId", res["company"]["id"]);
          this.permService.navigateBasedOnPermission();
          this.navbarService.requireReload = true;
          this.navbarService.sendClickEvent();
          this.ngxLoader.stop();
        } else {
          // onboarding navigation
          if (
            res["companiesAssociatedToUser"].length > 0 &&
            res["isAllowedAccess"] == null
          ) {
            this.router.navigate(["landing/onboarding"], {
              queryParams: {
                company:
                  res["companiesAssociatedToUser"][0]["nameOfTheCompany"],
              },
            });
          } else if (
            res["authenticatorType"] != null &&
            res["isVerified"] == false
          ) {
            this.router.navigate(["/landing", "onboarding", "adminProfile"]);
          } else if (
            res["authenticatorType"] != null &&
            res["isVerified"] == true &&
            res["company"] == null
          ) {
            this.router.navigate(["/landing", "onboarding", "companyDetails"]);
          } else if (
            res["company"] != null &&
            res["incorporationDetails"] == null
          ) {
            this.router.navigate([
              "/landing",
              "onboarding",
              "create-historical-round",
            ]);
          } else {
            this.router.navigate(["landing/onboarding"]);
          }
          this.navbarService.sendClickEvent();
          this.ngxLoader.stop();
        }
      },
      (e) => {
        if (e.error.reason === "Login Token Expired.") {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate([""]);
          return;
        } else {
          this.toastr.error(e.error.reason, "Failure!");
        }
      }
    );
  }
  assignCompaniesCopy() {
    this.filteredCompanies = Object.assign([], this.usrCompanies);
  }
  filterCompanies(value) {
    if (!value) {
      this.assignCompaniesCopy();
    }
    const regex = new RegExp(`^${value.toLowerCase()}| ${value.toLowerCase()}`);
    this.filteredCompanies = Object.assign([], this.usrCompanies).filter(
      (company) => company.nameOfTheCompany.toLowerCase().match(regex)
    );
  }
  onClearSearchInput(searchInput) {
    searchInput.value = "";
    this.assignCompaniesCopy();
  }

  populateTabList(routerUrl?: Array<UrlSegment>) {
    this.currentTabList = [];
    const navMenu = this.utilityService.getNavMenu() as Array<NavigationMenu>;
    const currentRoute = this.activatedRoute["_routerState"].snapshot.url.split(
      "/"
    ) as Array<string>;
    document.querySelector("mat-sidenav-content").scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    navMenu.forEach((navmenuItem) => {
      if (
        this.userAccessRole == "optsHomepageView" &&
        navmenuItem.name == "Modeling"
      ) {
        const modelingChildren = navmenuItem.children.filter(
          (tabItem) => tabItem.name == "ESOP Modeling"
        );
        navmenuItem.children = modelingChildren;
      }
      if (
        navmenuItem.path.toLowerCase() ==
          currentRoute[currentRoute.length - 1].toLowerCase() ||
        currentRoute
          .join("/")
          .toLowerCase()
          .includes(navmenuItem.redirectPath.toLowerCase().replace(".", ""))
      ) {
        this.currentTabList = navmenuItem.children || [];
        this.activeLink = navmenuItem.redirectPath;
        if (this.currentTabList.length > 0) {
          const secondlink = this.currentTabList[0].redirectPath.replace(
            ".",
            "/landing"
          );
          if (currentRoute.length + 1 == secondlink.split("/").length) {
            this.router.navigateByUrl(secondlink);
            return;
          }
        }
      }
      if (navmenuItem.children) {
        navmenuItem.children.forEach((menuChild) => {
          if (
            menuChild.path.toLowerCase() ==
              currentRoute[currentRoute.length - 1].toLowerCase() ||
            currentRoute
              .join("/")
              .toLowerCase()
              .includes(menuChild.redirectPath.toLowerCase().replace(".", ""))
          ) {
            this.currentTabList = navmenuItem.children || [];
            if (menuChild.activeLink) {
              this.activeLink = menuChild.activeLink;
            } else this.activeLink = menuChild.redirectPath;
          }
        });
      }
    });

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  goToTermSheets() {
    this.router.navigate(["/landing", "dashboard", "term-sheets", "overview"]);
  }
  handleSearchBarClick(e: PointerEvent) {
    e.stopPropagation();
  }
}
