
<div class="row" (mouseleave)="hideHelp()">
  <div class="col-md-12">
    <div class="row btm-20">
      <div class="col-md-12">
        <form [formGroup]="capitalSettingsForm" novalidate>
          <mat-card>
            <div class="row btm-20">
              <div class="col-md-12">
                <div class="btm-10">
                  <mat-label style="font-size: 18px !important">
                    Share Capital Settings
                  </mat-label>
                </div>
                <p class="content-pg-1">
                  Enter the total authorized and paid up capital of the company as on date. Also, provide the break up of equity and preference share capital.  
                </p>
              </div>
            </div>
            <div class="row btm-20">
              <div class="col-md-12">
                <div class="row btm-10">
                  <div class="col-md-12">
                    <div>
                        <mat-label>Authorized Capital ({{currencySymbol}}):</mat-label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <mat-label class="required">Equity</mat-label>

                <input id="authorized-equity" type="text" formControlName="equityAuthorizedShareCapital" (input)="csvVal($event.target.value, $event)" class="form-control text-align-right top-10" placeholder="Equity Authorized Capital" (keydown)=onKeydownEvent($event) required />

                <div class="alert alert-danger" *ngIf="capitalSettingsForm.get('equityAuthorizedShareCapital').errors?.required && (capitalSettingsForm.get('equityAuthorizedShareCapital').dirty || capitalSettingsForm.get('equityAuthorizedShareCapital').touched || isSubmitted)">
                  Equity Authorized Share Capital is required!
                </div>
              </div>
              <div class="col-md-4">
                <mat-label class="required">Preference</mat-label>
                <input id="authorized-preference" type="text" formControlName="preferenceAuthorizedShareCapital" title="" (input)="csvVal($event.target.value, $event)" class="form-control text-align-right top-10" placeholder="Preference Authorized Capital" (keydown)=onKeydownEvent($event) required/>

                <div class="alert alert-danger" *ngIf="capitalSettingsForm.get('preferenceAuthorizedShareCapital').errors?.required && (capitalSettingsForm.get('preferenceAuthorizedShareCapital').dirty || capitalSettingsForm.get('preferenceAuthorizedShareCapital').touched || isSubmitted)">
                  Preference Authorized Share Capital is required!
                </div>
              </div>
              <div class="col-md-4">
                <mat-label>Total</mat-label>
                  <input class="form-control text-align-right top-10" type="text" formControlName="authTotal" readonly>                
              </div>
            </div>
  
            <div class="row btm-20">
              <div class="col-md-12">
                <div class="row btm-10">
                  <div class="col-md-12">
                    <mat-label>Paid Up Capital ({{currencySymbol}}):</mat-label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <mat-label class="required">Equity</mat-label>

                  <input type="text" id="paidup-equity" formControlName="equityPaidUpShareCapital" (input)="csvVal($event.target.value, $event)" class="form-control text-align-right top-10" placeholder="Equity Paid Up Capital" (keydown)=onKeydownEvent($event) required/>
                  
                  <div class="alert alert-danger" *ngIf="capitalSettingsForm.get('equityPaidUpShareCapital').errors?.required && (capitalSettingsForm.get('equityPaidUpShareCapital').dirty || capitalSettingsForm.get('equityPaidUpShareCapital').touched || isSubmitted)">
                    Equity Paid Up Share Capital is required!
                  </div>
                </div>

                <div class="col-md-4">
                  <mat-label class="required">Preference</mat-label>
                    <input id="paidup-preference" type="text" formControlName="preferencePaidUpShareCapital" (input)="csvVal($event.target.value, $event)" class="form-control text-align-right top-10" placeholder="Preference Paid Up Capital" (keydown)=onKeydownEvent($event) required/>

                    <div class="alert alert-danger" *ngIf="capitalSettingsForm.get('preferencePaidUpShareCapital').errors?.required && (capitalSettingsForm.get('preferencePaidUpShareCapital').dirty || capitalSettingsForm.get('preferencePaidUpShareCapital').touched || isSubmitted)">
                      Preference Paid Up Share Capital is required!
                    </div>
                </div>
                <div class="col-md-4">
                  <mat-label>Total</mat-label>
                    <input class="form-control text-align-right top-10" type="text" formControlName="preferenceTotal" readonly>                
                </div>
            </div>
          </mat-card>
        </form>
      </div>
    </div>

    <div class="btm-20">
      <mat-card id="PrimaryTransactions" (mouseover)="showHelp('PrimaryTransactions')">
        <mat-card-header>
          <mat-card-title>Historical Round</mat-card-title>          
          <input id="uploadImage" hidden type="file" (change)="fileChangeEvent($event)" accept=".xlsx, .xls"
          #uploadImage />
          <button mat-raised-button id="import" [disableRipple]="true" color="success" class="import-btn" ngbTooltip="Import" (click)="uploadImage.click()">
            Import
          </button>
        </mat-card-header>
        <mat-card-content>
          <p class="content-pg-1">
            Enter the details of each round raised by the company till date starting with the incorporation round. These are mainly the terms of the funding rounds. To add details of a new round, click on ‘+’. From the dropdown under ‘Round’, select  ‘Add New Round’ and enter the name of the round in the Round name window. 
          </p>
          <div class="row top-20 btm-20">
            <div class="col-md-12">
              
            </div>
          </div>
          <div class="row btm-20">
            <div class="col-md-12 btm-20">
              <div class="row">
                <div class="col-md-6">
                  <mat-label style="font-size: 18px !important">
                    Primary Transactions
                  </mat-label>
                </div>
                <!-- <div class="col-md-6">
                  <img class="cursor-hand float-right" (click)="addInvestor()" title="Add Investor" src="../../../../assets/action/addnew.svg"/>

                  <a *ngIf="selectedInvestorRow != null"  class="dx-link dx-icon-money dx-link-icon primaryAction-btns" title="Redemption" (click)="openRedemptionDialog()"></a>

                  <a *ngIf="selectedInvestorRow != null" class="dx-link dx-icon-repeat dx-link-icon primaryAction-btns" title="Conversion" (click)="openConversionDialog()"></a>
                </div> -->
              </div>
            </div>
            <div class="col-md-12">
              <dx-data-grid id="primaryGrid" #primaryGrid class="gridContainer" keyExpr="uuid" [focusedRowEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" 
                [dataSource]="historicalRound.investors" [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false"
                [allowColumnReordering]="false" [allowColumnResizing]="false" noDataText="Click on the '+' to add an Investor" 
                [autoNavigateToFocusedRow]="true"
                (onContentReady)="onPrimContentReady($event)"
                (onRowInserted)="logEvent('RowInserted', $event)" 
                (onRowValidating)="logEvent('RowValidating', $event)"
                (onInitialized)="logEvent('Initialized', $event)"
                (onCellHoverChanged)="logEvent('CellHoverChanged',$event)"
                (onEditingStart)="logEvent('EditingStart',$event)"
                (onInitNewRow)="logEvent('InitNewRow',$event)"
                (onCellPrepared)="logEvent('CellPrepared', $event)"
                (onSaved)="logEvent('Saved', $event)"
                (onEditCanceled)="logEvent('EditCanceled',$event)"
                (onEditingStart)="logEvent('EditingStart',$event)"
                (onSelectionChanged)="logEvent('SelectionChanged',$event)"
                (onToolbarPreparing)="logEvent('ToolbarPreparing',$event)"
                (onEditorPreparing)="logEvent('EditorPreparing',$event)">
        
                <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="true" [allowDeleting]="true"
                  [allowAdding]="true" [useIcons]="true">
                </dxo-editing>

                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>

                <dxo-header-filter [visible]="true" [allowSearch]="false"> </dxo-header-filter>

                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>

                <dxo-pager
                    [visible]="true"
                    [allowedPageSizes]="[10, 20, 30]"
                    displayMode="full"
                    [showPageSizeSelector]="true"
                    [showInfo]="false"
                    [showNavigationButtons]="false"
                    >
                </dxo-pager>
                
                <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

                <dxi-column [allowSearch]="true" [allowEditing]="false" dataField="allotment" caption="Allotments" cellTemplate="primAllotments" [minWidth]="50"
                    [calculateCellValue]="calculateCellValuePrim" [calculateFilterExpression]="calculateRoundFilterExpressionPrim">
                    <dxo-header-filter [dataSource]="allotmentsFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'primAllotments'">
                    <span *ngFor="let allot of cellData.value">
                        <span [ngClass]="allot == 'C' ? 'conv-flag' : allot == 'R' ? 'redem-flag' : allot == 'NP' ? 'np-flag' : ''">{{ allot }}</span>
                    </span>
                </div>

                <!-- <dxi-column [allowSearch]="true" caption="Allotments" cellTemplate="primFlagsTemplate" [minWidth]="50"></dxi-column>
                <div *dxTemplate="let cellData of 'primFlagsTemplate'">
                    <span *ngIf="cellData.data.conversionDetails" class="conv-flag">C</span>
                    <span *ngIf="cellData.data.redemptionDetails" class="redem-flag">R</span>
                </div> -->
        
                <dxi-column [columnAutoWidth]="true" [minWidth]="100" [allowSearch]="true" dataField="investorName" caption="Investor Name" alignment="left" editCellTemplate="investorDropdown">          
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <div *dxTemplate="let cellData of 'investorDropdown'">
                  <div>
                    <dx-select-box [dataSource]="historicalRound.investorsDropdown" [searchEnabled]="true"
                        [acceptCustomValue]="true"
                        [value]="cellData.value"
                        [noDataText]="'Investor will be added.'"
                        (onCustomItemCreating)="addNewInvestor($event)"
                        (onValueChanged)="cellData.setValue($event.value)"
                        itemTemplate="list-item"
                        >
                        <div *dxTemplate="let data of 'list-item'; let index = index">
                          <div title="{{data}}">{{ data }}</div>
                        </div>
                      </dx-select-box>
                  </div>
                </div>
        
                <dxi-column [allowSearch]="true" dataField="securityType" caption="Security" alignment="left" editCellTemplate="securitiesDropdown">
                  <!-- <dxo-lookup [dataSource]="historicalRound.securitiesDropdown">
                  </dxo-lookup> -->

                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="securityTypeFilterPrim"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'securitiesDropdown'">
                  <div>
                    <dx-select-box [dataSource]="historicalRound.securitiesDropdown" [searchEnabled]="true"
                        [acceptCustomValue]="false"
                        [value]="cellData.value"
                        (onValueChanged)="cellData.setValue($event.value)"
                        itemTemplate="list-item"
                        >
                        <div *dxTemplate="let data of 'list-item'; let index = index">
                          <div title="{{data}}">{{ data }}</div>
                        </div>
                      </dx-select-box>
                  </div>
                </div>

                <dxi-column  [allowSearch]="true" [customizeText]="dxCustomizeDecimalCsv" dataField="par" dataType="number" caption="PAR" [setCellValue]="calculateAmountPAR">
                    <dxo-header-filter [dataSource]="parNumberFilterPrim"></dxo-header-filter>
                    
                </dxi-column>
        
                <dxi-column  [allowSearch]="true" [customizeText]="dxCustomizeDecimalCsv" dataField="premium" dataType="number" caption="Premium" [setCellValue]="calculateAmountPremium"> 
                  <!-- [setCellValue]="setSharePrice" -->
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="premiumNumberFilterPrim"></dxo-header-filter>
                </dxi-column>
        
                <dxi-column [allowSearch]="true" [customizeText]="dxCustomizeCsv" dataField="numberOfShares" dataType="number" caption="No. of Shares" [setCellValue]="calculateAmountNoOfShares">          
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="noOfSharesFilterPrim"></dxo-header-filter>
                </dxi-column>

                <dxi-column [allowSearch]="true" cellTemplate="sharesMarkerTemplate" [minWidth]="105">
                </dxi-column>
                <div *dxTemplate="let cellData of 'sharesMarkerTemplate'">
                    <mat-label *ngIf="showBonusShare(cellData)"
                        class="speech-bubble-green bubble-txt custom-bubble">
                        Bonus</mat-label>
                    <mat-label *ngIf="showPartlyPaidShare(cellData)"
                        class="speech-bubble-red bubble-txt custom-bubble">
                        Partly Paid
                    </mat-label>
                </div>
        
                <dxi-column [allowSearch]="true" dataField="amount" dataType="number" [customizeText]="dxCustomizeDecimalCsv" caption="Amount">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="amountFilterPrim"></dxo-header-filter>
                </dxi-column>
        
                <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="150" editCellTemplate="dateCell" cellTemplate="displayDateCell">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <div *dxTemplate="let cellData of 'dateCell'">
                  <div class="grid-date">
                    <input matInput [matDatepicker]="vd1" [ngModel]="cellData.value" (dateChange)="datePickerChanged(cellData, $event)" class="form-control top-10" title="" max="9999-12-31" placeholder="DD-MMM-YYYY" required />
                    <mat-datepicker-toggle matSuffix [for]="vd1">
                      <mat-icon matDatepickerToggleIcon ></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #vd1></mat-datepicker>
                  </div>
                </div>
                <div *dxTemplate="let cellData of 'displayDateCell'">
                  <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                </div>
        
                <dxi-column [allowSearch]="true" dataField="roundName" caption="Round" [minWidth]="120" [setCellValue]="setRoundName" alignment="left" editCellTemplate="rounds">
                  <!-- <dxo-lookup [dataSource]="rounds">
                  </dxo-lookup> -->
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="roundNameFilterPrim"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'rounds'">
                  <div>
                    <dx-select-box [dataSource]="rounds" [searchEnabled]="true"
                        [acceptCustomValue]="false"
                        [value]="cellData.value"
                        (onValueChanged)="cellData.setValue($event.value)"
                        itemTemplate="list-item"
                        >
                        <div *dxTemplate="let data of 'list-item'; let index = index">
                          <div title="{{data}}">{{ data }}</div>
                        </div>
                      </dx-select-box>
                  </div>
                </div>

                <dxi-column type="buttons" [width]="115">
                  <dxi-button name="edit"></dxi-button>
                  <dxi-button name="delete"></dxi-button>
                  <!-- <dxi-button [visible]="isRedemptionDone" cssClass="redemption-done" hint="Redempted" icon="money"></dxi-button>
                  <dxi-button [visible]="isConversionDone" cssClass="conversion-done" hint="Converted" icon="repeat"></dxi-button> -->
                </dxi-column>
                
                <dxo-summary style="background-color: #F3F3F3;">
                  <dxi-total-item column="investorName" [customizeText]="displayAsTotal">
                  </dxi-total-item>
                  <dxi-total-item column="numberOfShares" summaryType="sum" [customizeText]="dxCustomizeCsv">
                  </dxi-total-item>
                  <dxi-total-item column="amount" summaryType="sum" [customizeText]="dxCustomizeDecimalCsv">
                  </dxi-total-item>
                </dxo-summary>
              </dx-data-grid>
              <div class="col-md-12 text-align-right notes-tbl btm-20">
                <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
              </div>
        
              <dx-tooltip [(target)]="toolTipTarget" position="top" [arrowLength]="1" opacity="0.8">
                <div *dxTemplate="let data of 'content'">
                  <div class="tooltip-content">{{toolTipText}}</div>
                </div>
              </dx-tooltip>

              <div class="top-20">
                <span class="conv-flag">C</span><span class="right-100"> - Converted</span>
                <span class="redem-flag">R</span><span class="right-100"> - Redeemed</span>
                <span class="np-flag">NP</span><span class="right-100"> - Non Priced Round</span>
                <!-- <span class="buy-back-flag">B</span><span class="right-100"> - Buy back</span> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row top-20">
                <div class="col-md-6">
                  <mat-label style="font-size: 18px !important">
                    Secondaries
                  </mat-label>
                </div>
                <!-- <div class="col-md-6">
                  <img class="cursor-hand float-right" (click)="addSecondaries()" title="Add Secondaries" src="../../../../assets/action/addnew.svg"/>
                </div> -->
              </div>
            </div>
            <div class="col-md-12">
              <dx-data-grid id="secondariesGrid" #secondariesGrid class="gridContainer" keyExpr="uuid" [focusedRowEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" [dataSource]="historicalRound.secondaries" [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false" [allowColumnReordering]="false" [allowColumnResizing]="false" noDataText="Click on the '+' to add a Secondary" 
              (onInitialized)="logEvent('Initialized', $event)"
              (onContentReady)="onSecContentReady($event)"
              (onRowInserted)="logSecondariesEvent('RowInserted', $event)"
              (onSelectionChanged)="logSecondariesEvent('SelectionChanged',$event)"
              (onToolbarPreparing)="logSecondariesEvent('ToolbarPreparing',$event)"
              (onCellHoverChanged)="logEvent('CellHoverChanged',$event)">
          
                <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="true" [allowDeleting]="true"
                  [allowAdding]="true" [useIcons]="true">
                </dxo-editing>

                <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

                <dxo-header-filter [visible]="true" [allowSearch]="false"> </dxo-header-filter>
                
                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>

                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>

                <dxo-pager
                    [visible]="true"
                    [allowedPageSizes]="[10, 20, 30]"
                    displayMode="full"
                    [showPageSizeSelector]="true"
                    [showInfo]="false"
                    [showNavigationButtons]="false"
                    >
                </dxo-pager>
          
                <dxi-column [allowSearch]="true" [allowEditing]="false" dataField="transactions" caption="Transfers" cellTemplate="secTransactions" [minWidth]="50"
                    [calculateCellValue]="calculateCellValue" [calculateFilterExpression]="calculateRoundFilterExpression">
                    <dxo-header-filter [dataSource]="transactionsFilter"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'secTransactions'">
                    <span *ngFor="let transaction of cellData.value">
                        <span [ngClass]="transaction == 'C' ? 'conv-flag' : transaction == 'R' ? 'redem-flag' : transaction == 'B' ? 'buy-back-flag' : transaction == 'NP' ? 'np-flag' : ''">{{ transaction }}</span>
                    </span>
                </div>

                <dxi-column [allowSearch]="true" dataField="buyer" caption="Buyer" alignment="left"  editCellTemplate="buyerDropdown">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <div *dxTemplate="let cellData of 'buyerDropdown'">
                  <div>
                    <dx-select-box [dataSource]="historicalRound.investorsDropdown" [searchEnabled]="true"
                        [acceptCustomValue]="true"
                        [value]="cellData.value"
                        [noDataText]="'Buyer will be added.'"
                        (onCustomItemCreating)="addNewInvestor($event)"
                        (onValueChanged)="cellData.setValue($event.value)"
                        itemTemplate="list-item"
                        >
                        <div *dxTemplate="let data of 'list-item'; let index = index">
                          <div title="{{data}}">{{ data }}</div>
                        </div>
                      </dx-select-box>
                  </div>
                </div>
          
                <dxi-column [allowSearch]="true" dataField="seller" caption="Seller" alignment="left" editCellTemplate="investorDropdown">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <div *dxTemplate="let cellData of 'investorDropdown'">
                  <div>
                    <dx-select-box [dataSource]="historicalRound.investorsDropdown" [searchEnabled]="true"
                        [acceptCustomValue]="false"
                        [value]="cellData.value"
                        (onValueChanged)="cellData.setValue($event.value)">
                      </dx-select-box>
                  </div>
                </div>
          
                <dxi-column [allowSearch]="true" dataField="numberOfShares" dataType="number" [customizeText]="dxCustomizeCsv" caption="No. of Shares" [setCellValue]="calculateAmount5">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="noOfSharesFilterSec"></dxo-header-filter>
                </dxi-column>
          
                <dxi-column dataField="pricePerShare" dataType="number" [customizeText]="dxCustomizeDecimalCsv" caption="Price Per Share" [setCellValue]="calculateAmount4">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="ppsFilterSec"></dxo-header-filter>
                </dxi-column>
          
                <dxi-column dataField="amount" dataType="number" [customizeText]="dxCustomizeDecimalCsv" caption="Amount">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxo-header-filter [dataSource]="amountFilterSec"></dxo-header-filter>
                </dxi-column>
          
                <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="160" editCellTemplate="dateCell" cellTemplate="displayDateCell">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <div *dxTemplate="let cellData of 'dateCell'">
                  <div class="grid-date">
                    <input matInput [matDatepicker]="vd2" [ngModel]="cellData.value" (dateChange)="datePickerChanged(cellData, $event)" class="form-control top-10" title="" max="9999-12-31" placeholder="DD-MMM-YYYY" required />
                    <mat-datepicker-toggle matSuffix [for]="vd2">
                      <mat-icon matDatepickerToggleIcon ></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #vd2></mat-datepicker>
                  </div>
                </div>
                <div *dxTemplate="let cellData of 'displayDateCell'">
                  <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                </div>

                <dxi-column [allowSearch]="true" dataField="securityType" caption="Security" alignment="left" editCellTemplate="securitiesDropdown">
                    <!-- <dxo-lookup [dataSource]="historicalRound.securitiesDropdown">
                    </dxo-lookup> -->
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-header-filter [dataSource]="securityTypeFilterSec"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'securitiesDropdown'">
                  <div>
                    <dx-select-box [dataSource]="historicalRound.securitiesDropdown" [searchEnabled]="true"
                        [acceptCustomValue]="false"
                        [value]="cellData.value"
                        (onValueChanged)="cellData.setValue($event.value)"
                        itemTemplate="list-item"
                        >
                        <div *dxTemplate="let data of 'list-item'; let index = index">
                          <div title="{{data}}">{{ data }}</div>
                        </div>
                      </dx-select-box>
                  </div>
                </div>

                <dxi-column [allowSearch]="true" dataField="roundName" caption="Round" [minWidth]="120" [setCellValue]="setRoundName" alignment="left" editCellTemplate="secondaryRounds">
                    <!-- <dxo-lookup [dataSource]="secondaryRounds">
                    </dxo-lookup> -->
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-header-filter [dataSource]="roundNameFilterSec"></dxo-header-filter>
                </dxi-column>
                <div *dxTemplate="let cellData of 'secondaryRounds'">
                  <div>
                    <dx-select-box [dataSource]="secondaryRounds" [searchEnabled]="true"
                        [acceptCustomValue]="false"
                        [value]="cellData.value"
                        (onValueChanged)="cellData.setValue($event.value)"
                        itemTemplate="list-item"
                        >
                        <div *dxTemplate="let data of 'list-item'; let index = index">
                          <div title="{{data}}">{{ data }}</div>
                        </div>
                      </dx-select-box>
                  </div>
                </div>
                
                <dxo-summary style="background-color: #F3F3F3;">
                  <dxi-total-item column="buyer" [customizeText]="displayAsTotal">
                  </dxi-total-item>
                  <dxi-total-item column="numberOfShares" summaryType="sum" [customizeText]="dxCustomizeCsv">
                  </dxi-total-item>
                  <dxi-total-item column="amount" summaryType="sum" [customizeText]="dxCustomizeDecimalCsv">
                  </dxi-total-item>
                </dxo-summary>
              </dx-data-grid>
              <div class="col-md-12 text-align-right notes-tbl btm-20">
                <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
              </div>
          
              <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                <div *dxTemplate="let data of 'content'">
                  <div class="tooltip-content">{{toolTipText}}</div>
                </div>
              </dx-tooltip>

              <div class="top-20">
                <span class="conv-flag">C</span><span class="right-100"> - Converted</span>
                <span class="redem-flag">R</span><span class="right-100"> - Redeemed</span>
                <span class="buy-back-flag">B</span><span class="right-100"> - Buy back</span>
                <span class="np-flag">NP</span><span class="right-100"> - Non Priced Round</span>
              </div>

            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-expansion-panel [expanded]="historicalRound.esopPool.length > 0" id="ESOP">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <b>ESOP</b> 
        </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row btm-20" id="ESOP">
          <div class="col-md-12" >
            <mat-card (mouseover)="showHelp('ESOP')">
              <mat-divider class="divider-line"></mat-divider>
              <mat-card-content>
                <div class="row top-20 btm-20">
                  <div class="col-md-12">
                    <p class="content-pg-1">
                      Enter the details of the ESOP pool created by the company till date. Once all the details are filled, click on ‘Save for Later’ if you wish to save the information and log back in later. If you wish to proceed, click on ‘Generate Cap Table’. 
                      Shares allocated- Specify the number of shares locked/allocated under the ESOP pool created. 
                      Date- This is the date when the ESOP pool was created.
                    </p>
                  </div>
                </div>
                <div class="row btm-20">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8">
                        <mat-label style="font-size: 18px !important">
                          ESOP Pool
                        </mat-label>
                      </div>
                      <!-- <div class="col-md-4">
                        <img class="cursor-hand float-right" (click)="addPoolAllocation()" title="Add Pool Allocation" src="../../../../assets/action/addnew.svg"/>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-12">
                    <dx-data-grid #esopGrid class="gridContainer" [hoverStateEnabled]="true" [dataSource]="historicalRound.esopPool" [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false" 
                      [allowColumnReordering]="false" [allowColumnResizing]="false" noDataText="Click on the '+' to update pool" 
                      (onCellHoverChanged)="logEsopEvent('CellHoverChanged',$event)"
                      (onInitialized)="logEsopEvent('Initialized', $event)"
                      >
              
                      <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                      </dxo-editing>
    
                      <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    
                      <dxi-column [allowSearch]="true" [customizeText]="dxCustomizeCsv" dataField="poolSize" dataType="number"  alignment="left" caption="Shares Allotted">          
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dxi-column>       
                      <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="150" editCellTemplate="dateCellEsop" cellTemplate="displayDateCellEsop">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dxi-column>
                      <div *dxTemplate="let cellData of 'dateCellEsop'">
                        <div class="grid-date">
                          <input matInput [matDatepicker]="vd1" [ngModel]="cellData.value" (dateChange)="datePickerChanged(cellData, $event)" class="form-control top-10" title="" max="9999-12-31" placeholder="DD-MMM-YYYY" required />
                          <mat-datepicker-toggle matSuffix [for]="vd1">
                            <mat-icon matDatepickerToggleIcon ></mat-icon>
                          </mat-datepicker-toggle>
                          <mat-datepicker #vd1></mat-datepicker>
                        </div>
                      </div>
                      <div *dxTemplate="let cellData of 'displayDateCellEsop'">
                        <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                    </dx-data-grid>
              
                    <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                      <div *dxTemplate="let data of 'content'">
                        <div class="tooltip-content">{{toolTipText}}</div>
                      </div>
                    </dx-tooltip>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
    </mat-expansion-panel>
    <div class="accordian-border top-20">
    <mat-expansion-panel [expanded]="historicalRound.splitDetails.length > 0" id="split ones">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <b>Splits</b>
        </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="accordian-content-body">    <div class="row btm-20">
          <div class="col-md-12">
            <mat-card>
              <mat-card-content>
                <div class="col-md-12 splitheaderspce" >
                  <dx-data-grid #splitsGrid class="gridContainer" [hoverStateEnabled]="true" [dataSource]="historicalRound.splitDetails" [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false" [allowColumnReordering]="false" [allowColumnResizing]="false" noDataText="Click on the '+' to add a Split" 
                  (onInitialized)="logEvent('Initialized', $event)"
                  (onEditorPreparing)="logEvent('EditorPreparing', $event)"
                  (onCellHoverChanged)="logEvent('CellHoverChanged',$event)"
                  (onRowInserted)="logEvent('RowInserted',$event)">
              
                    <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="true" [allowDeleting]="true"
                      [allowAdding]="true">
                    </dxo-editing>
                    
                    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
              
                    <dxi-column dataField="splitNumber" dataType="text" caption="Split Ratio" alignment="left" cellTemplate="splitRatio">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    
                    <div *dxTemplate="let cellData of 'splitRatio'">
                      <div>
                        <span>1:{{cellData.value}}</span>
                      </div>
                    </div>
              
                    <dxi-column [allowSearch]="true" dataField="securityType" caption="Security" alignment="left" editCellTemplate="securitiesDropdown">
                      <!-- <dxo-lookup [dataSource]="historicalRound.securitiesDropdown">
                      </dxo-lookup> -->
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <div *dxTemplate="let cellData of 'securitiesDropdown'">
                      <div>
                        <dx-select-box [dataSource]="historicalRound.securitiesDropdown" [searchEnabled]="true"
                            [acceptCustomValue]="false"
                            [value]="cellData.value"
                            (onValueChanged)="cellData.setValue($event.value)"
                            itemTemplate="list-item"
                            >
                            <div *dxTemplate="let data of 'list-item'; let index = index">
                              <div title="{{data}}">{{ data }}</div>
                            </div>
                          </dx-select-box>
                      </div>
                    </div>
              
                    <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="160" editCellTemplate="dateCell" cellTemplate="displayDateCell">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <div *dxTemplate="let cellData of 'dateCell'">
                      <div class="grid-date">
                        <input matInput [matDatepicker]="vd3" [ngModel]="cellData.value" (dateChange)="datePickerChanged(cellData, $event)" class="form-control top-10" title="" max="9999-12-31" placeholder="DD-MMM-YYYY" required />
                        <mat-datepicker-toggle matSuffix [for]="vd3">
                          <mat-icon matDatepickerToggleIcon ></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #vd3></mat-datepicker>
                      </div>
                    </div>
                    <div *dxTemplate="let cellData of 'displayDateCell'">
                      <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                    </div>
                  </dx-data-grid>
              
                  <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                    <div *dxTemplate="let data of 'content'">
                      <div class="tooltip-content">{{toolTipText}}</div>
                    </div>
                  </dx-tooltip>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          </div>
        </div>
        </mat-expansion-panel>
    </div>
    <div class="row top-20">
        <div class="col-md-12">
            <div *ngIf="!isShowNote" (click)="isShowNote = !isShowNote;">
                <span>
                  <mat-label>Add Notes</mat-label><img class="left-10 cursor-hand" src="../../../../../assets/action/addnotes.svg" alt="add Notes" />
                </span>
            </div>
            <div *ngIf="isShowNote" (click)="isShowNote = !isShowNote;">
                <span>
                  <mat-label>Remove Notes</mat-label><img class="left-10 cursor-hand" src="../../../../../assets/action/removenotes.svg" alt="remove Notes" />
                </span>
            </div>
              <textarea class="form-control top-10" *ngIf="isShowNote" [(ngModel)]="historicalRound.notes" placeholder="Add Notes"></textarea>
        </div>
    </div>
    <div class="row top-20">
      <div class="col-md-12">
        <button mat-raised-button id="generateCapTable" color="success" [disableRipple]="true" ngbTooltip="Generate Cap Table" class="float-right margin-left-lg" (click)="saveHistoricalRoundDetails()">
          Generate Cap Table
        </button>
        <button mat-raised-button id="saveForLater" color="success" [disableRipple]="true" ngbTooltip="Save for Later" class="float-right margin-left-lg" (click)="saveForLater()">
          Save for Later
        </button>
        <button mat-raised-button id="discard" color="warn" [disableRipple]="true" ngbTooltip="Discard" class="float-right" (click)="discardHistoricalRound()">
          Discard
        </button>
      </div>
    </div>
  </div>
  <!-- Helper sections -->
  <!-- <div class="col-md-3">
    <div class="helper-container">
      <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
        [hidden]="helper == ''">
        <mat-card-header class="help-hdr">
          <mat-card-title><label class="help-hdr-txt">{{
                  helpSections?.name
                }}</label></mat-card-title>
        </mat-card-header>
        <hr />
        <mat-card-content class="card-help-content">
          <div [innerHTML]="helpSections?.description"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div> -->
</div>

<div id="overlay"></div>

<app-round-name *ngIf="roundNameDialog.show" [(showDialog)]="roundNameDialog.show" [(roundData)]="roundNameDialog" (visibleChange)="getRoundName($event)"></app-round-name>
<app-historical-conversion *ngIf="conversionDialog.show"  [(showDialog)]="conversionDialog.show" [(rowData)]="conversionDialog" (visibleChange)="getConversionData($event)"></app-historical-conversion>
<app-historical-redemption *ngIf="redemptionDialog.show"  [(showDialog)]="redemptionDialog.show" [(rowData)]="redemptionDialog" (visibleChange)="getRedemptionData($event)"></app-historical-redemption>

<app-profile-info *ngIf="showdigPrfle" [(newInvestorProfile)]="investorProfile.rowInfo"
[fromHistoricalRound]="true" [investorsArray]="investorProfile.investorsArray" [(showdigPrfle)]="showdigPrfle" (visibleChange)="getInvestorProfile($event)">
</app-profile-info>


