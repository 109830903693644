import * as i0 from "@angular/core";
var SecurityService = /** @class */ (function () {
    function SecurityService() {
        this.securityConversionDetails = null;
        this.securityDetailsPreview = null;
    }
    Object.defineProperty(SecurityService.prototype, "setConversionDetails", {
        set: function (details) {
            this.securityConversionDetails = details;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityService.prototype, "getConversionDetails", {
        get: function () {
            return this.securityConversionDetails;
        },
        enumerable: true,
        configurable: true
    });
    SecurityService.ngInjectableDef = i0.defineInjectable({ factory: function SecurityService_Factory() { return new SecurityService(); }, token: SecurityService, providedIn: "root" });
    return SecurityService;
}());
export { SecurityService };
