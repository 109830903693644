export class OptionModel {
    "employeeId": number;
    "employeeIdentifier": string;
    "employeeName": string;
    "employmentStatus": string;
    "esopPlanName": string;
    "grantDate": Date;
    "grantIdentifier": string;
    "grantPrice": string;
    "holdingPercentage": string;
    "imageURL": string;
    "optionHolderId": number;
    "optionHolderState": string;
    "optionHolding": string;
    "optionId": string;
    "optionsExercised": string;
    "optionsGranted": string;
    "optionsVested": string;
    "planId": string;
    "statusId": string;
    "vesting": Vesting;
    "vestingDatetype": string;
    "vestingDate": Date;
    "customizedGrantLetter": boolean;
}

export class Vesting {
    "cliffPeriod": number;
    "company": Company;
    "id": number;
    "isAlreadyInUse": boolean;
    "isDefault": boolean;
    "vestingInterval": number;
    "vestingPeriod": number;
    "vestingTemplateName": string;
    "vestingTriggerType": string;
    "vestingType": string;
}

export class Company {
    "id": string;
    "nameOfTheCompany": string;
    "cinNumber": string;
    "typeOfTheCompany": string;
    "dateOfIncorporation": Date;
    "corporateOfficeAddress": string;
    "companyEmailId": string;
    "business": string;
    "industry": string;
    "nic": string;
    "subscribersOfMemorandum": string;
    "createdAt": Date;
    "updatedAt": Date;
}