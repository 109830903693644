<section class="content">
  <div class="background-gradient">
    <div class="row header-row">
      <div class="col-md-2 col-lg-3"></div>
      <div class="col-md-8 col-lg-6">
        <p class="heading">
          <img src="../../../assets/logo/logo.svg" />
        </p>
        <p class="subheading text-align-center">Equity Management</p>
      </div>
      <div class="col-md-2 col-lg-3"></div>
    </div>
    <div class="row content-row-padding">
      <div class="col-md-2 col-lg-3"></div>
      <div class="col-md-8 col-lg-6">
        <mat-card class="card">
          <mat-card-header>
            <mat-card-title class="text-align-center">Reset Password</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="form-card text-align-center">
              <mat-label>Enter details to reset the password</mat-label>
              <form class="form" novalidate>
                <div class="form-group margin-top-lg text-align-left">
                  <mat-label class="required">Email ID</mat-label>
                  <input type="email" class="form-control top-10" [(ngModel)]="emailId" name="emailId" #email="ngModel"
                    autocomplete="off" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" placeholder="Email ID"
                    required>
                  <div class="alert alert-danger" *ngIf="email.errors?.required && (email.dirty || email.touched)">
                    Email ID is required
                  </div>
                  <div class="alert alert-danger" [hidden]="!email.errors?.pattern">
                    Invalid E-mail!
                  </div>
                </div>
                <div class="form-group margin-top-lg text-align-left" [hidden]="!userType">
                  <mat-label class="required">Associated Companies</mat-label>
                  <select class="form-control top-10" [(ngModel)]="companyName" name="companyName"
                    #companyNameErr="ngModel" (ngModelChange)="selectCompany($event)"
                    [ngModelOptions]="{standalone: true}" [disabled]="!companyList" required>
                    <option disabled [selected]="true" value="">Select Company</option>
                    <option *ngFor='let company of companyList' [ngValue]="company">{{company.nameOfTheCompany}}
                    </option>
                  </select>
                  <div class="alert alert-danger">
                    <div *ngIf="companyNameErr.invalid && (companyNameErr.touched || companyNameErr.dirty)">
                      Choose associated company
                    </div>
                  </div>
                </div>

                <div class="form-group margin-top-lg text-align-left">
                  <mat-label class="required">Current Password</mat-label>
                  <div class="input-eyePlaceholder">
                    <input type="password" class="form-control top-10" [(ngModel)]="currentPassword"
                      name="currentPassword" #cPassword="ngModel" autocomplete="off"
                      [type]="currHide ? 'password' : 'text'" placeholder="Enter Current password" required>
                    <i [ngClass]=" currHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="currpasswordHide()"
                      class="eye-placeholder"></i>
                  </div>
                  <div class="alert alert-danger" *ngIf="cPassword.invalid && (cPassword.dirty || cPassword.touched)">
                    Current Password is required
                  </div>
                </div>
                <div class="form-group margin-top-lg text-align-left">
                  <mat-label class="required">New Password</mat-label>
                  <div class="input-eyePlaceholder">
                    <input type="password" class="form-control top-10" [(ngModel)]="newPassword" name="newPassword"
                      #nPassword="ngModel" autocomplete="off" [type]="newHide ? 'password' : 'text'"
                      placeholder="Enter New password" (keyup)="checkPasswordStrength()" placement="right"
                      [ngbTooltip]="passwordrequirements" required>
                    <i [ngClass]=" newHide ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'" (click)="newpasswordHide()"
                      class="eye-placeholder"></i>
                  </div>
                  <app-password-strength *ngIf="newPassword" [passwordToCheck]="newPassword" [barLabel]="barLabel">
                  </app-password-strength>
                  <div class="alert alert-danger" *ngIf="nPassword.invalid && (nPassword.dirty || nPassword.touched)">
                    New Password is required
                  </div>
                  <ng-template #passwordrequirements>
                    Your Password must include:
                    <ul style="text-align: start;">
                      <li>
                        Atleast 8 characters
                      </li>
                      <li>
                        Atleast 1 special character
                      </li>
                      <li>
                        Atleast 1 uppercase character
                      </li>
                      <li>
                        Atleast 1 number
                      </li>
                    </ul>
                  </ng-template>
                  <span *ngIf="(nPassword.dirty || nPassword.touched) && newPassword && !passwordStrength"
                    class="alert alert-danger">
                    New Password doesn't meet the minimum requirements !
                  </span>
                </div>
                <div class="form-group margin-top-lg">
                  <button *ngIf="!userType" mat-raised-button id="reset" color="success" type="submit" (click)="resetPassword()"
                    [disableRipple]="true" [disabled]="email.invalid ||
                              nPassword.invalid ||
                              cPassword.invalid || !passwordStrength" class="width-100 submit-button">Reset</button>
                  <button *ngIf="userType" mat-raised-button id="reset" color="success" type="submit" (click)="resetPassword()"
                    [disableRipple]="true" [disabled]="email?.invalid ||
                              nPassword.invalid ||
                              cPassword.invalid ||
                              companyNameErr.invalid || !passwordStrength"
                    class="width-100 submit-button">Reset</button>
                </div>
              </form>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-md-2 col-lg-3"></div>
  </div>
</section>
<app-footer-component></app-footer-component>