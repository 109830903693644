import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from '../authentication.service';
import { HttpResponse } from '@angular/common/http';
import { UtilityService } from '../services/utilities.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

interface ReportDto {
  base64File: string;
  fileType: string;
  fileName: string;
}
@Component({
  selector: "app-export-sh6",
  templateUrl: "./export-sh6.component.html",
  styleUrls: ["./export-sh6.component.scss"],
})
export class ExportSh6Component implements OnInit {
  mresDateErr: boolean = false;
  constructor(
    public datepipe: DatePipe,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    public dialogRef: MatDialogRef<ExportSh6Component>,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {}
  date = new Date();
  selectedPlan;
  selectedPlanId;
  // selectedPlanStartDate;
  ngOnInit() {
    this.selectedPlan = this.data.selectedPlan;
    if (this.selectedPlan == "All Plans") {
      this.selectedPlanId = "ALL";
      // this.selectedPlanStartDate = null;
    } else {
      this.data.allPlans.filter((item) => {
        if (item.planName == this.selectedPlan) {
          this.selectedPlanId = item.planId;
          // this.selectedPlanStartDate = item.planStartDate;
        }
      });
    }
  }
  exportSh6(date) {
    if (typeof date === "undefined" || !date) {
      this.mresDateErr = true;
      this.toastr.error("Enter valid Date!", "Error");
      return;
    }
    this.ngxLoader.start();
    let latest_date = this.datepipe.transform(date, "yyy-MM-dd");

    this.authService.exportSh6(latest_date, this.selectedPlanId).subscribe(
      (res: ReportDto) => {
        const blobSH6Report = this.utilityService.b64toBlob(
          res.base64File,
          res.fileType
        );
        this.utilityService.downloadBlobFile(blobSH6Report, res.fileName);
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, "Warning");
      }
    );
  }

  changeByplanNames(plan) {
    if (plan == "All Plans") {
      this.selectedPlanId = "ALL";
      // this.selectedPlanStartDate = null;
    } else {
      this.data.allPlans.filter((item) => {
        if (item.planName == plan) {
          this.selectedPlanId = item.planId;
          // this.selectedPlanStartDate = item.planStartDate;
        }
      });
    }
  }
}
