<div *ngIf="showDialog" class="dialog">
    <div class="header">
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 custom-modal-header">
          <label class="dialogue-title" *ngIf="this.editRowData && !cloneGrant">Edit Grant</label>
          <label class="dialogue-title" *ngIf="!this.editRowData">New Grant</label>
          <label class="dialogue-title" *ngIf="this.cloneGrant">Clone Grant</label>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6">
          <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
            <span class="close">X</span>
          </button>
        </div>
      </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <form class="container-fluid content-row needs-validation" novalidate>
        <div class="row top-20 btm-20">
            <div class="autoComplete col-md-6">
                <mat-label class="required" >Name of Holder</mat-label>
                <mat-form-field [matAutocompleteDisabled]="(mode == 'edit' || table == 'employee') && !cloneGrant" [disabled]="(mode == 'edit' || table == 'employee') && !cloneGrant" style="top: 7px !important;"  floatLabel="always" appearance="outline">
                    <input id="employeeName" [(ngModel)]="employeeNameValue" [readonly]="(mode == 'edit' || table == 'employee') && !cloneGrant" type="text" [formControl]="searchFormControl" matInput  [matAutocomplete]="auto" />
                    <mat-autocomplete [displayWith]="employeeNameDisplayFunction" (optionSelected)="setHolderName(searchFormControl.value)" #auto="matAutocomplete">
                      <mat-option *ngFor="let emp of searchOptions | async" [value]="emp">
                        {{ emp.employeeName }} ( {{ emp.employeeIdentificationString }} )
                      </mat-option>
                    </mat-autocomplete>
                    <span matPrefix><i class="fa fa-search" aria-hidden="true"></i></span>
                  </mat-form-field>
                <div *ngIf="empIdError" class="alert alert-danger">
                    Employee Name is required
                </div>
            </div>
            <div class="col-md-6">
                <mat-label>How do you want to grant options?</mat-label>
                <select class="form-control top-10" [(ngModel)]="empDetails.optionType" (change)="optionTypeChange($event.target.value)" 
                        [ngModelOptions]="{standalone: true}" >
                    <option value="By Options">By Options</option>
                    <option value="By Amount">By Amount</option>
                </select>
            </div>
        </div>
        <div *ngIf="empDetails.optionType == 'By Amount'" class="row btm-20">
            <div class="col-md-6">
                <mat-label class="required">Amount for which Options to be Granted ({{currencySymbol}})</mat-label>
                <input type="number" class="form-control top-10 text-align-left" (keypress)="onlyNumber($event)" (change)="changeAmount($event.target.value)" 
                    placeholder="Enter total amount" [(ngModel)]="empDetails.amountForOptions" [ngModelOptions]="{standalone: true}" #amountErr="ngModel" required/>
                <div *ngIf="isNumberOfOptionsDecimal" class="mat-hint" style="font-size: 12px;">
                    Amount will be auto-rounded to an exact multiple of latest share price
                </div>
                <div *ngIf="amountErr.errors?.required && amountError" class="alert alert-danger">
                    Amount is required!
                </div>
            </div>
            <div class="col-md-6">
                <mat-label class="required">Latest Share price ({{currencySymbol}})</mat-label>
                <input type="number" class="form-control top-10 text-align-left" (keypress)="onlyNumber($event)" (change)="changeShareValue($event.target.value)"
                     placeholder="Enter the price of each Share" [(ngModel)]="empDetails.latestSharePrice" [ngModelOptions]="{standalone: true}" #sharePriceErr="ngModel"  required/>
                <div *ngIf="sharePriceErr.errors?.required && sharePriceError" class="alert alert-danger">
                    Latest share price is required!
                </div>
            </div>
        </div>
        <div class="row btm-20">
            <div class="col-md-6">
                <mat-label class="required">Number of Options to be Granted</mat-label>
                <input class="form-control top-10" title="" [(ngModel)]="empDetails.optionsGranted" [disabled]="empDetails.optionType == 'By Amount'" type="text"
                    (keypress)="onlyNumber($event)" (keydown)="onKeydownEvent($event)" placeholder="Enter count of options" #grantedErr="ngModel"
                    [ngModelOptions]="{standalone: true}" required />
                <div *ngIf="grantedErr.errors?.required && grantedError" class="alert alert-danger">
                    Number of Options is required!
                </div>
                <div *ngIf="isNumberOfOptionsDecimal" class="mat-hint" style="font-size: 12px;" >
                    Options will be rounded down to closest whole number
                </div>
                <div *ngIf="zeroOptionsError" class="alert alert-danger">
                    Number of options should be greater than 0
                </div>
            </div>
            <div class="col-md-6">
                <mat-label class="required">Grant Price ({{currencySymbol}})</mat-label>
                <input class="form-control top-10" title="" [(ngModel)]="empDetails.grantPrice" #grantPriceErr="ngModel"
                    type="text" (keydown)="onKeydownEvent($event)" (input)="csvPrice($event)" (keypress)="onlyNumberKey($event)" placeholder="Grant Price"
                    [ngModelOptions]="{standalone: true}" required />
                    <div *ngIf="grantPriceErr.errors?.required && gPriceError" class="alert alert-danger">
                        Grant Price is required
                    </div>
            </div>
        </div>
        <div class="row btm-20">
            <div class="col-md-6">
                <mat-label class="required">Grant Date</mat-label>
                <div class="input-eyePlaceholder">
                    <input matInput [matDatepicker]="dp" class="form-control top-10" title="" [max]="9999-12-31" [min]= "selectedEmpData[0]?.dateOfJoin"  #gDate="ngModel"
                        [(ngModel)]="empDetails.grantDate" placeholder="DD-MMM-YYYY" [ngModelOptions]="{standalone: true}" required/>
                    <mat-datepicker-toggle matSuffix [for]="dp">
                        <mat-icon matDatepickerToggleIcon ></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </div>
                <div *ngIf="gDate.errors?.required && gDateError" class="alert alert-danger">
                    Grant Date is required
                </div>
            </div>
            <div class="col-md-6">
                <mat-label class="required">Vested</mat-label>
                <input type="text" class="form-control top-10" readonly [value]="empDetails.optionsVested" />
            </div>
        </div>
        <div class="row btm-20">
            <div class="col-md-6">
                <mat-label class="required">Exercised</mat-label>
                <input type="text" class="form-control top-10" readonly [value]="empDetails.optionsExercised" />
            </div>
            <div class="col-md-6">
                <mat-label class="required">Vesting Schedule</mat-label>
                <select class="form-control top-10" [value]="empDetails['vestingId']" (change)="setVestingSchedule($event.target.value)" [disabled]="mode == 'edit' && !cloneGrant"
                    required>
                    <option [disabled]="empDetails['vestingId']!=''" value="">Select Vesting Schedule</option>
                    <option *ngFor="let schedule of esopVestingTemplateList" [value]="schedule.id">{{ schedule.vestingTemplateName }}</option>
                </select>
                <div *ngIf="vestingError" class="alert alert-danger">
                    Vesting Schedule is required
                </div>
            </div>
        </div>
        <div class="row btm-20">
            <div class="col-md-6">
                <mat-label class="required">Plan Name</mat-label>
                <select class="form-control top-10" [(ngModel)]="empDetails.planId" [disabled]="mode == 'edit' || planNameDisabled" #planNameErr="ngModel"
                    [ngModelOptions]="{standalone: true}" required>
                    <option [disabled]="empDetails['vestingId']!=''" value="">Select Plan Name</option>
                    <option *ngFor="let plan of esopPlansTable" [value]="plan.planId">{{ plan.esopPlanName }}</option>
                </select>
                <div *ngIf="planNameErr.errors?.required && esopPlanError" class="alert alert-danger">
                    Plan Name is required
                </div>
            </div>
            <div class="col-md-6">
                <mat-label class="required">Vesting Start Date</mat-label>
<select class="form-control top-10" [(ngModel)]="empDetails.vestingDateType" #stDate="ngModel" (ngModelChange)="changeVestingType($event)" [ngModelOptions]="{standalone: true}" required>
                   <option [disabled]="empDetails.vestingDateType != ''" value="">Select Vesting Start Date</option>
                    <option value="GrantDate">Grant Date</option>
                    <option value="EmployeeJoiningDate">Employee Joining Date</option>
                    <option value="CustomDate">Custom Date</option>
                </select>
                <div *ngIf="stDate.errors?.required && vestingTypeError" class="alert alert-danger">
                    Vesting Start Date is required
                </div>
            </div>
        </div>
        <div class="row btm-20">
            <div class="col-md-6">
                <mat-label>Vesting Date</mat-label>
                <div class="input-eyePlaceholder">
                    <input matInput [matDatepicker]="vd" class="form-control top-10" title="" [max]="9999-12-31" [min]= "selectedEmpData[0]?.dateOfJoin" #vestDate="ngModel"
                        [(ngModel)]="empDetails.vestingDate" placeholder="DD-MMM-YYYY" [disabled]="empDetails.vestingDateType != 'CustomDate'"
                        [ngModelOptions]="{standalone: true}" required />
                    <mat-datepicker-toggle matSuffix [for]="vd">
                        <mat-icon matDatepickerToggleIcon ></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #vd></mat-datepicker>
                </div>
                <div *ngIf="vestDate.errors?.required vestingDateError" class="alert alert-danger">
                    Vesting Date is required
                </div>
            </div>
            <div class="col-md-6">
              <mat-label class="setting-item">Override Grant Letter</mat-label>
              <div>
                <mat-button-toggle-group class="top-10" id="isOverrideGrantLetter"
                    [(ngModel)]="isOverrideGrantLetter" [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle [value]="true">
                    Yes</mat-button-toggle>
                    <mat-button-toggle [value]="false">
                    No</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
        </div>
            <div class="row btm-20">
                <div class="col-md-6" *ngIf="isOverrideGrantLetter">
                <mat-label class="setting-item">Override Grant Letter</mat-label>
                <div>
                  <table>
                    <tr>
                      <td>
                        <button mat-raised-button id="viewGrantLetter" (click)="viewGrantLetter()" [hidden]="!isViewGrantLetter">Grant Letter.docx</button>
                        <button (click)=grantLetter.click() class="cursor-hand" ngbTooltip="Upload" style="padding-left: 10px;" mat-icon-button><mat-icon [svgIcon]="'action_upload'"></mat-icon></button>
                        <button (click)="downloadObject('grantLetter',true)" class="cursor-hand" ngbTooltip="Download" style="padding-left: 10px;" [disabled]="!empDetails.grantLetter" mat-icon-button><mat-icon [svgIcon]="'action_download'" style="zoom:120%"></mat-icon></button>
                      </td>
                    </tr>
                  </table>
                </div>
                <input id="grantLetter" type="file" hidden #grantLetter (change)="fileChangeEvent($event, 'grantLetter')" accept=".docx,application/pdf">
            </div>
            </div>

        <app-answer-grant *ngIf="showAnswerGrantDialog" [(showDialog)]="showAnswerGrantDialog" [grantId]="grantId"
        [viewOnly]="viewOnly"></app-answer-grant>
        <!-- <div class="row btm-20">
            <div class="col-md-6">
                <mat-label class="required">Holding %</mat-label>
                <input type="text" class="form-control top-10" readonly [value]="empDetails.holdingPercentage | percent: '2.2'" />
            </div>
            <div class="col-md"></div>
        </div> -->
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 btm-20">
                <button [disabled]="isSubmitted" mat-raised-button id="save" color="success" [disableRipple]="true" ngbTooltip="Save"
                class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="checkValidation()">
                Save
                </button>
                <button mat-raised-button id="cancel" color="warn" [disableRipple]="true" ngbTooltip="Cancel"
                class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="close()">
                Cancel
                </button>
            </div>
        </div>
    </form>
</div>
