<div *ngIf="showDialog" class="dialog">
    <div class="header">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
                <label class="dialogue-title">Privacy Policy</label>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span class="close">X</span>
                </button>
            </div>
        </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <div class="container-fluid content-row">
        <div class="row top-20">
            <div class="col-md-12">
                <p class="content-pg-1 btm-10">
                    Last updated on: March 4, 2021
                </p>
                <p class="text-align-center btm-20"><b><u>Privacy Policy</u></b></p>
                <p class="content-pg-1 btm-10">This privacy policy (the “<b>Privacy Policy</b>”) is an integral part of the Company’s terms of use / service and must be read along with the same. All capitalised terms used but not defined herein shall have the meaning ascribed to it under the Terms of Use.
                </p>
                <p class="content-pg-1 btm-10">For the purposes of this Privacy Policy, a person accessing the website <a href="https://www.hissa.com">www.hissa.com</a>  and/or <a href="https://www.rulezero.com/hissa">www.rulezero.com/hissa</a> (“<b>Website</b>”) or availing the Services shall be referred to as “You”, “Your” or “Yourself” or “User”. </p>
                <p class="content-pg-1 btm-10">The Platform adheres strictly to the current information technology and data protection laws, rules and regulations in India. The Company has adopted this Privacy Policy to set out the manner in which the Company uses and handles information. This Privacy Policy also specifies the manner in which sensitive personal data or personal information is collected, received, stored, processed, disclosed, transferred, dealt with and handled by the Companyˀ.
                </p>
                <p class="content-pg-1 btm-20">Please read the Privacy Policy carefully before using the Platform or creating an Account or accessing any material or information on the Website. Your accessing the Website or using the Services, shall constitute acceptance by you of the terms set forth herein.  We may amend the Privacy Policy from time to time. Please check regularly so that you are aware of the changes.
                </p>
                <p class="content-pg-1 btm-20">Information that is collected is used in accordance with this Privacy Policy. </p>
                <ol class="content-pg-1">
                    <li class="btm-10"><b>Information:</b>
                        <ol>
                            <li>The information that We collect includes the information that is provided to Us: (i) when an Account is created; and (ii) when the Services are availed.  Information about Users may be provided by other Users, given the nature of the Accounts and how they operate. Account Holders / Controllers / Users represent that they are authorised to provide the information that is provided.</li>
                            <li>We collect information about Users from public sources including other websites and the websites maintained by governmental agencies.</li>
                            <li>We  also collect information about Users which may be in the form of preferences, preferred formats, templates and language, likes, dislikes, interests, demographics,  etc. Further, We may also collect information about Your preferences including settings such as time zone and language, Your searches and the results You selected from the searches.We may receive and record  logs details relating to Your access to or use of the Website and the Services, including but not limited to, Your location, information about Your internet connection and mobile device, Internet Protocol address, cookie information, web beacon information and details of the web pages You visit, etc. </li>
                            <li>Account Holders / authorised persons are responsible for the accuracy of all information uploaded on the Platform. </li>
                        </ol>
                    </li>
                    <li class="btm-10"><b>Collection and use of information</b>
                        <ol>
                            <li>The information gathered by Us as specified in Section 2 above, may be used, among other things, for the following purposes:</li>
                            <p>(i) to allow and facilitate Your use of the Platform and to Accounts;</p>
                            <p>(ii) to provide, maintain, develop, protect and improve the Platform;</p>
                            <p>(iii) to develop more products and services;</p>
                            <p>(iv) to render services as chosen by You;</p>
                            <p>(iv) to prepare reports including industry reports, which reports may be shared and disclosed to third parties. The reports shall not carry any information directly attributable to a User unless authorised by the User or such information is already in the public domain. </p>
                            <p>(v) to make public filings as applicable;</p>
                            <p>(vii) to carry out Our obligations to you and enforce Our rights;</p>
                            <p>(vii) for internal purposes such as auditing, data analysis and research  conducted either indirectly/directly by Us; </p>
                            <p>(viii)  for sharing with any third party (include cross border data sharing), including any service providers, marketing agents and any of Our associated entities, in the course of using the Platform or providing services as chosen by You on the Platform; and</p>
                            <p>(ix) for any other purposes akin or pursuant to the above. </p>
                            <p>Sensitive personal information, as defined in law, if any collected will be used strictly in accordance with and in conformity with applicable law and consents provided. Please note that the nature of the Platform is such that information about Holders is available to all persons who have access to an Account. Each Holder expressly agrees and consents to the same. To the extent a Holder is not a User, the Account Holder / Controller shall ensure that their consent is available for such access and shall indemnify the Company from any losses, claims and expenses in this regard.</p>
                        </ol>
                    </li>
                   <li class="btm-10"><b>Transparency and Choice</b>
                        <ol>
                            <li><i>Your Acknowledgement.</i> All information disclosed / provided by You is considered as disclosed willingly and without restrictions. No liability pertaining to the authenticity/genuineness/ misrepresentation/ fraud/ negligence, etc. of the information disclosed shall lie on the Company nor will the Company in any way be responsible to verify any information obtained from You.</li>
                            <li><i>Rectification:</i> If there is a change in any of the information provided by You, or if You have provided any incorrect information, you may update Your Account or send Us a request by contacting us at notice@rulezero.com to rectify or update Your Account.</li>
                        </ol>
                    </li>
                    <li class="btm-10"><b>Collection of information by third-party sites and advertisers</b>
                        <ol>
                            <li>You hereby acknowledge and agree that when You use the Platform, there may be certain links which may direct You to other websites or applications not operated/maintained by Company (the “Other Site”). This Privacy Policy does not apply to information that You provide to, or that is collected by, any Other Site through the Platform, and any Other Site that You access or use in connection with the use of the Platform. The manner in which Your information is collected, received, stored, processed, disclosed, transferred, dealt with and handled by such Other Site(s) is governed by the terms and conditions and privacy policy of the respective Other Site(s). The Company urges You to acquaint yourself with the terms and conditions and privacy policy of every such Other Site(s).</li>
                            <li>We  hereby expressly disclaim all liabilities with respect to the manner in which the Other Site(s) collects and/or uses Your information. On the Platform, there may be third parties who advertise their products and/or services. Such third parties may place or recognize a unique “cookie” on Your browser and may use information about Your visits to Our Platform and other websites in order to provide advertisements about goods and services of interest to You. We shall, in no event, be liable for any unauthorized or unlawful disclosures of Your personal information or any other information made by advertisers, who are not subject to Our control.</li>
                        </ol>
                    </li>
                    <li class="btm-10"><b>Disclosure to third parties</b>
                        <ol>
                            <li>We  may need to disclose Your information, if required to do so by law and if such action is necessary inter-alia to: (i) comply with a legal obligation; (ii) protect and defend Our rights or property or a Customer, including to enforce Our agreements, policies, and Terms of Use and; (iii) protect Our personal safety and the safety of Our members and employees, the Users, or any person, in an emergency; (iv) protect Ourselves  from incurring any legal liability, and (v) as required in relation to collection and use of information as set forth above. We  shall be under no obligation to inform You or seek Your approval or consent.</li>
                            <li>Any content or personal information that You share or upload on the publicly viewable portion of the Platform (on discussion boards, in messages and chat areas, etc.) will be publicly available, and can be viewed by others.</li>
                            <li>We may disclose or transfer Your information (personal or otherwise) to any third party if We sell, transfer or divest all or a portion of Our business or assets including in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction, or proceeding. Such third parties shall be contractually bound to not disclose further, any personal information disclosed to them.</li>
                        </ol>
                    </li>
                    <li class="btm-10"><b>Security</b>
                        <p>We shall take reasonable steps to help protect Your rights of privacy and Your information (personal or otherwise) in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration, or destruction of such information, in compliance with the applicable laws. You expressly consent to the sharing of Your information with third party service providers, as required.  You shall not share Your user name, password, or other security information for Your account with anyone.</p>
                    </li>
                    <li class="btm-10"><b>Public posts</b>
                       <p>You may provide Your feedback, reviews, comments, etc. on the Platform on Your use of the Platform (the “Posts”). Your Posts shall have to comply with the conditions relating to Posts as applicable. We retain the unconditional right to remove /  delete any Post or such part of the Post if, in our sole opinion, such Post / portion of the Post does not comply with applicable conditions. All Posts shall be publicly accessible and visible to all Users of the Platform. Company reserves the right to use, reproduce and share Your Posts for any purpose. If You delete Your Posts from the Platform, copies of such Posts may remain viewable in archived pages, or such Posts may have been copied or stored by other Users.</p>
                    </li>
                    <li class="btm-10"><b>User discretion</b>
                        <ol>
                            <li>You may opt out of receiving promotional emails from Us  by following the instructions in those emails. If You opt out, We may still send You non-promotional emails, such as emails with respect to the Platform and Your Account and related transactions.</li>
                            <li>Most web browsers are set to accept cookies by default. If You prefer, You can usually choose to set Your browser to remove or reject cookies. If You choose to remove or reject cookies, this may affect certain features of the Platform or usage of the Platform.</li>
                            <li>Many websites give you the option of opting out of providing consents and asking for information to be deleted. This may not be possible in relation to individual Holders given the nature of an Account. Please note the same.  </li>
                        </ol>
                    </li>
                    <li class="btm-10"><b>Amendments</b>
                        <p>We retain an unconditional right to modify or amend this Privacy Policy without any requirement to notify You of the same. You can determine when this Privacy Policy was last modified by referring to the “Last Updated” legend above. It shall be Your responsibility to check this Privacy Policy periodically for changes. Your acceptance of the amended Privacy Policy shall signify Your consent to such changes and agreement to be legally bound by the same.
                        </p>
                    </li>
                    <li class="btm-10"><b>Complaints</b>
                        <p>In case of any dissatisfaction in relation to the Platform, You shall first file a formal complaint with the customer service team, prior to pursuing any other recourse. The complaints can be lodged at notice@rulezero.com and upon lodging a complaint You agree to provide complete support to the customer service team with such reasonable information as may be sought by them from You. Our decision on the complaints shall be final and You agree to be bound by the same. Responses to your complaints are not guaranteed.</p>
                    </li>
                    <li><b>Grievances</b>
                        <p>In the event You have any grievances relating to the Privacy Policy, please inform within 24 hours of occurrence of the instance from which the grievance has arisen, by writing to us at notice@rulezero.com.
                        </p>
                    </li>
                </ol>
            </div>
            <div class="col-md-12 btm-20">
            <button mat-raised-button id="ok" [disableRipple]="disableripple" color="success" class="float-right"
                (click)='close()' ngbTooltip="OK">OK</button>
            </div>
        </div>
    </div>
</div>