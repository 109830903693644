<div id="overlay" *ngIf="showDialog" [ngClass]="{'display-on': showDialog}"></div>

<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">New Security</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <form class="content-row needs-validation" novalidate>
    <div class="col-xs-12 col-sm-12 col-md-12">
      <div class="top-20 flex-class" >
        <div>
            <p class="content-pg-1 btm-10">
                Enter the name of the security you wish to issue in this round. Select from the existing class of
                securities in the drop down to import and apply its features to the security of the round. You can
                also customize and set entirely new features to the security class by clicking on <b>‘Advanced’</b>. Doing so
                will direct you to the securities section of the tool.
            </p>
          <p class="content-pg-1 ">
            Click on ‘+’ to add notes on the security class created.
          </p>
        </div>
        <div>
          <button mat-raised-button id="advanced" [disableRipple]="disableripple" color="success" class=""
            (click)="goToAddnewInstrument()" ngbTooltip="Advanced">
            Advanced
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-5">

          <div class="top-20">
            <mat-label class="required">Security Name</mat-label>
            <input type="text" [(ngModel)]="securityName" #secNameErr="ngModel" class="form-control top-10"
              placeholder="Enter Name" title="" [ngModelOptions]="{standalone: true}" required />
            <div *ngIf="secNameErr.errors">
              <div class="alert alert-danger" *ngIf="(secNameErr.dirty || secNameErr.touched) && !errSecurityName">
                Name is required!
              </div>
              <div class="alert alert-danger" *ngIf="secNameErr.errors?.required && errSecurityName">
                Name is required!
              </div>
            </div>
          </div>

          <div class="top-20">
            <mat-label class="required">Import from</mat-label>
            <select class="form-control top-10" #template [(ngModel)]="securityType" [ngModelOptions]="{standalone: true}"
              (change)='templateChangeEvent(template.value)' #secTypeErr="ngModel" required>
              <option class="select-optn" value="">Security Templates
              </option>
              <option *ngFor="let insTemplate of instTemplateList;" value="{{insTemplate.id}}">{{insTemplate.className}}</option>
            </select>
            <div *ngIf="secTypeErr.errors">
              <div class="alert alert-danger" *ngIf="(secTypeErr.dirty || secTypeErr.touched) && !errSecurityType">
                Template is required!
              </div>
              <div class="alert alert-danger" *ngIf="secTypeErr.errors?.required && errSecurityType">
                Template is required!
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-7 top-20">
          <mat-card *ngIf="isShow">
            <mat-card-content>

              <div class="row" *ngIf='instType=="Equity"'>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <mat-label>Security Class</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Par Value</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">{{selectedInstClsData.className}}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ getTotalValueTble(selectedInstClsData.parValue == null ? '-' : selectedInstClsData.parValue)}}
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row" *ngIf='instType=="CCPS"'>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <mat-label>Security Class</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Voting Rights</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Dividend</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">{{selectedInstClsData.className}}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.votingRights == null ? '-' : selectedInstClsData.votingRights ==  true ? 'Yes':'No' }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.dividendRate == null ? '-' : selectedInstClsData.dividendRate }}
                      </mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label>Liquidation Preference</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Participation</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Seniority</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.rankNumber == null ? '-' : selectedInstClsData.rankNumber }}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.participation == null ? '-' : selectedInstClsData.participation == false ? 'No' : 'Yes'  }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{selectedInstClsData.pariPassu  == null ? '-' : selectedInstClsData.pariPassu == false ? selectedInstClsData.rankNumber : 'Pari Passu' }}
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf='instType=="CCD"'>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <mat-label>Security Class</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Voting Rights</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Interest</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">{{selectedInstClsData.className}}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.votingRights == null ? '-' : selectedInstClsData.votingRights ==  true ? 'Yes':'No' }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.dividendRate == null ? '-' : selectedInstClsData.dividendRate }}
                      </mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label>Liquidation Preference</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Participation</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Seniority</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.rankNumber == null ? '-' : selectedInstClsData.rankNumber }}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.participation == null ? '-' : selectedInstClsData.participation == false ? 'No' : 'Yes'  }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{selectedInstClsData.pariPassu  == null ? '-' : selectedInstClsData.pariPassu == false ? selectedInstClsData.rankNumber : 'Pari Passu' }}
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf='instType=="RPS"'>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <mat-label>Security Class</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Redemption Trigger</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Amount</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">{{selectedInstClsData.className}}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.triggerType == null ? '-' : selectedInstClsData.triggerType }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{ getTotalValueTble(selectedInstClsData.amount == null ? '-' : selectedInstClsData.amount) }}
                      </mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label>Liquidation Preference</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Participation</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Seniority</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.rankNumber == null ? '-' : selectedInstClsData.rankNumber }}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.participation == null ? '-' : selectedInstClsData.participation == false ? 'No' : 'Yes'  }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{selectedInstClsData.pariPassu  == null ? '-' : selectedInstClsData.pariPassu == false ? selectedInstClsData.rankNumber : 'Pari Passu' }}
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf='instType=="Note"'>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <mat-label>Security Class</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Note Period</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Amount</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">{{selectedInstClsData.className}}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{selectedInstClsData.notePeriod == null ? '-' : selectedInstClsData.notePeriod}}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{getTotalValueTble(selectedInstClsData.noteAmount == null ? '-' : selectedInstClsData.noteAmount)}}
                      </mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label>Liquidation Preference</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Participation</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Seniority</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.rankNumber == null ? '-' : selectedInstClsData.rankNumber }}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.participation == null ? '-' : selectedInstClsData.participation == false ? 'No' : 'Yes'  }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{selectedInstClsData.pariPassu  == null ? '-' : selectedInstClsData.pariPassu == false ? selectedInstClsData.rankNumber : 'Pari Passu' }}
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf='instType=="Warrant"'>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <mat-label>Security Class</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Exercise Period</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Exercise Price</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">{{selectedInstClsData.className}}</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.exercisePeriod == null ? '-' : selectedInstClsData.exercisePeriod }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{ getTotalValueTble(selectedInstClsData.exercisePrice == null ? '-' : selectedInstClsData.exercisePrice) }}
                      </mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label>Upfront Amount</mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Lock-In</mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label>Exercise Ratio</mat-label>
                    </div>
                  </div>

                  <div class="row top-20">
                    <div class="col-md-5">
                      <mat-label class="matLabelcls">
                        {{ getTotalValueTble(selectedInstClsData.upfrontAmount == null ? '-' : selectedInstClsData.upfrontAmount) }}
                      </mat-label>
                    </div>
                    <div class="col-md-4">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.lockInPeriod == null ? '-' : selectedInstClsData.lockInPeriod }}
                      </mat-label>
                    </div>
                    <div class="col-md-3">
                      <mat-label class="matLabelcls">
                        {{ selectedInstClsData.conversionRatio == null ? '-' : selectedInstClsData.conversionRatio }}
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row top-20">
                <div class="col-md-12">
                  <div class="tab-content-style" *ngIf="!descriptions">
                    <p class="content-pg-1">
                      {{ selectedInstClsData.sideNote}}
                    </p>
                  </div>
                </div>
              </div>

            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div class="row top-20">
        <div class="col-md-12 col-sm-12 col-lg-12">
          <div *ngIf="!isShowNote" (click)="addNotes(true)">
            <span>
              <mat-label>Add Notes</mat-label><img class="left-10" src="../../../assets/action/addnotes.svg"
                alt="add Notes" />
            </span>
          </div>
          <div *ngIf="isShowNote" (click)="addNotes(false)">
            <span>
              <mat-label>Remove Notes</mat-label><img class="left-10" src="../../../assets/action/removenotes.svg"
                alt="remove Notes" />
            </span>
          </div>
          <textarea class="form-control top-10" *ngIf="isShowNote" [(ngModel)]="sideNote" [ngModelOptions]="{standalone: true}"
            placeholder="Add Notes"></textarea>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row top-20 btm-20 float-right ">
        <div class="col-md-12">
          <button mat-raised-button id="cancel" [disableRipple]="disableripple" color="warn" class="margin-right-20"
            (click)="close()" ngbTooltip="Cancel">
            Cancel
          </button>
          <button mat-raised-button id="save" [disableRipple]="disableripple" color="success" (click)="savesSecurity()" class=""
            ngbTooltip="Save">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>