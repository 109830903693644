import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { DataService } from '../../services/new-round-notify.service';
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from '../../services/helper.service';
import * as $AB from "jquery";
declare var $: any;
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from "src/app/services/cache-provider.service";
import { Console } from "console";
import { stat } from "fs";

@Component({
  selector: "app-instrument-tab",
  templateUrl: "./instrument-tab.component.html",
  styleUrls: ["./instrument-tab.component.scss"]
})

export class InstrumentTabComponent implements OnInit {
  isRoundNotSaved: boolean = false;
  pricedRound = false;
  securitiesData;
  selInstTypes = [];
  instTypesList;
  instTypesListValues;
  nominalEqtyList;
  prefshares = false;
  warrents = false;
  debentures = false;
  notes = false;
  nominaleqty = false;
  equityshares = false;
  nomEqtyShre;
  nomEqtyInstCls;
  nominalEquityShares;
  investorList;
  instClsListTble;
  instInvestorList = [];
  public errorFlags;
  exchangeRate = 1;
  companyCurrency;
  roundCurrency;
  instClasss;
  instInvestorListObj = {
    investorName: "",
    instrumentType: {
      includeNominalEquity: false,
      nominalEquityShares: null,
      nominalEquityClass: null,
      instrumentClass: { className: "" }
    },
    investorIdentifier: null,
    rightToMaintainType: "",
    investmentAmount: 0,
    investmentPercentage: null,
    issueDate: null,
    annualInterest: null,
    pricePerShare: 0,
    discountToValuation: null,
    equitySharesToBeAssigned: 0.0,
    valuationApplied: null,
    maturityDate: null,
    isLeadInvestor: null,
    participatedRoundName: "",
    participatedRoundIdentifier: "",
    numberOfShares: 0,
    isRelatedToRound: false,
    shareDistinctiveNumber: null,
    typeOfInvestor: null,
    groupName: null,
    registeredAddress: null,
    corporateAddress: null,
    residentStatus: null,
    occupation: null,
    nationality: null,
    contactNumber: null,
    emailId: null,
    comment: null,
  };
  tblTotValues = {
    TotalHoldingPercentage: 0,
    TotalAmount: 0,
    TotalNoOfShares: 0,
    TotalNoOfDilutedShares:0
  };
  public disableripple: boolean = true;
  public helpTitle: any;
  public helpMessage: any;
  shouldShowDialog: boolean = false;
  showInvestmentDialog: boolean = false;
  invDetails;
  selectedSecurity;
  selectedIndex
  helper: string = "";
  helpSections;
  helperTopPosition = null;
  public basicDetails:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private dataService: DataService,
    private utilityService: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) { }

  updateInvestor(i, event) {
    let selctedInv = i;
    let selectedVal = event.replace(/,/g, "") / 1;
    this.investorList.forEach((investor, index) => {
      if (selctedInv === index) {
        let nomi = this.utilityService.inputCSVformatCurrencyRound(this.companyCurrency, selectedVal);
        investor['nominalEquityShares'] = nomi;
        investor['nominalEquityClassName'] = this.nomEqtyInstCls;
        investor['nominalEquity'] = this.nominaleqty;
      }
    });
  }

  goToTranches() {
    this.ngxLoader.start();
    this.isRoundNotSaved = false;
    this.utilityService.formValidation();
    if (this.investorList.className != "") {
      if (this.nominaleqty) {
        if (!this.nomEqtyInstCls) {
          this.ngxLoader.stop();
          this.toastr.error("Please choose Nominal equity class", "Error");
          return;
        }
        let totalNominal: number = 0;
        this.investorList.forEach(element => {
          if (element.nominalEquityShares) {
            if (typeof element.nominalEquityShares === "string") {
              let el = element.nominalEquityShares.replace(/,/g, "") / 1;
              element.nominalEquityShares = el;
              totalNominal += Number(el);
            }
            else {
              let el = element.nominalEquityShares;
              element.nominalEquityShares = el;
              totalNominal += Number(el);
            }
          } else {
            element.nominalEquityShares = 0;
          }
          element['instrumentValues'] = {
            "debentures": this.debentures,
            "equityShares": this.equityshares,
            "notes": this.notes,
            "preferenceShares": this.prefshares,
            "rps": false,
            "warrants": this.warrents
          }
          element['nominalEquity'] = this.nominaleqty
        });
        if (this.nomEqtyShre) {
          let checkNomi = this.nomEqtyShre.replace(/,/g, "") / 1;
          if (checkNomi === totalNominal) {
            this.authService.saveInstClsToInvestor(this.investorList).subscribe(
              response => {
                this.ngxLoader.stop();
                this.removeDataFromCache()
                this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "capTable"]);
              },
              e => {
                this.ngxLoader.stop();
                this.toastr.error("Error: " + e.error.reason, "Error");
              }
            );
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Please make sure nominal equity allotment should be equal to total nominal equity shares!", "Error");
          }
        } else {
          this.ngxLoader.stop();
          this.toastr.error("Please make sure nominal equity allotment should be equal to total nominal equity shares!", "Error");
        }
      }
      else {
        this.investorList.forEach(element => {
            element['nominalEquityShares'] = 0;
            element['nominalEquityClassName'] = null;
            element['nominalEquity'] = false;
            element['instrumentValues'] = {
              "debentures": this.debentures,
              "equityShares": this.equityshares,
              "notes": this.notes,
              "preferenceShares": this.prefshares,
              "rps": false,
              "warrants": this.warrents
            }
        });
        this.authService.saveInstClsToInvestor(this.investorList).subscribe(
          response => {
            this.ngxLoader.stop();
            this.removeDataFromCache()
            this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "capTable"]);
          },
          e => {
            this.ngxLoader.stop();
            this.toastr.error("Error: " + e.error.reason, "Error");
          }
        );
      }
    }
    else {
      this.ngxLoader.stop();
      this.toastr.error("Please assign Security class or Create Security class!", "Error");
    }
  }

  goToAddnewInstrument() {
    this.router.navigate(["/landing", "dashboard", "securities", "addNewInstrumentclass", { previousUrl: 'Instrument' }]);
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utilityService.convertedFormatCurrency(this.roundCurrency, value);
      }
      else {
        return this.utilityService.formatCurrency(this.roundCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  instClassChange(index, instCls) {
    if (instCls == "New Security Class") {
      this.shouldShowDialog = true;
      this.selectedSecurity = this.selInstTypes;
      this.selectedIndex = index;
      this.utilityService.showOverlay()
      this.updateBasicdata();
    }
    else if(instCls == null ){
      this.shouldShowDialog = false;
      this.investorList[index]["className"] = "";
    } 
    else if(instCls == "") {
      return
    }
    else {
      this.ngxLoader.start();
      let popupObj = {
          "investorName": this.investorList[index]['investorName'],
          "className": instCls
      }
      this.authService.getInvestmentPopup(popupObj).subscribe(
          (res) => {
            if(res['enablePopup'] == true) {
                this.ngxLoader.stop();
                this.investorList[index]["editClass"] = true;
                this.showInvestmentDialog = true;
                this.utilityService.showOverlay()
                this.invDetails = res;
                this.selectedIndex = index;
            } else {
                this.ngxLoader.stop();
                this.shouldShowDialog = false;
                this.investorList[index]["editClass"] = false;
                this.investorList[index]["className"] = instCls;
                this.investorList[index]["investmentAmount"] = res['investmentAmount'];
                this.investorList[index]["numberOfShares"] = res['numberOfShares'];
            }
          },
          (e) => {
            this.ngxLoader.stop();
          }
        );
      
    }
  }

  selectednomInstCls(cls) {
    if (cls != "") {
      this.nomEqtyInstCls = cls;
      this.investorList.forEach(element => {
          if(element['nominalEquity'] == true) {
              element['nominalEquityClassName'] = cls;
          }
      });
    }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  CalculateTotal(list) {
    this.tblTotValues["TotalHoldingPercentage"] = 0;
    this.tblTotValues["TotalAmount"] = 0;
    this.tblTotValues["TotalNoOfShares"] = 0;
    this.tblTotValues["TotalNoOfDilutedShares"] = 0;
    list.forEach(element => {
      this.tblTotValues["TotalHoldingPercentage"] += element["holdingPercentage"];
      this.tblTotValues["TotalAmount"] += element["investmentAmount"];
      this.tblTotValues["TotalNoOfShares"] += element["numberOfShares"];
      this.tblTotValues["TotalNoOfDilutedShares"] += element["numberOfDilutedShares"];
    });
  }

  getInvestorsListByInstType(instType) {
    this.selInstTypes = [];
    instType.forEach(button => {
        if (button === 'preference') {
            if (this.prefshares === true) {
              this.selInstTypes.push(this.instTypesList[0]);
              this.selInstTypes.push(this.instTypesList[5]);
            }
            else {
              //this.selInstTypes.splice(this.selInstTypes['Preference Shares'], 1);
              this.selInstTypes.forEach((element) => {
                if (element == "CCPS") {
                  let removeIndex = this.selInstTypes.map(function (item) { return item }).indexOf('CCPS');
                  this.selInstTypes.splice(removeIndex, 1);
                }
              });
              this.selInstTypes.forEach((element) => {
                if (element == "RPS") {
                  let removeItem = this.selInstTypes.map(function (item) { return item }).indexOf('RPS');
                  this.selInstTypes.splice(removeItem, 1);
                }
              });
            }
          }
          if (button === 'debentures') {
            if (this.debentures === true) {
              this.selInstTypes.push(this.instTypesList[1]);
            }
            else {
              //this.selInstTypes.splice(this.selInstTypes['debentures'], 1);
              this.selInstTypes.forEach((element) => {
                if (element == "CCD") {
                  let removeIndex = this.selInstTypes.map(function (item) { return item }).indexOf('CCD');
                  this.selInstTypes.splice(removeIndex, 1);
                }
              });
            }
          }
          if (button === 'warrents') {
            if (this.warrents === true) {
              this.selInstTypes.push(this.instTypesList[2]);
            }
            else {
              //this.selInstTypes.splice(this.selInstTypes['warrents'], 1);
              this.selInstTypes.forEach((element) => {
                if (element == "Warrant") {
                  let removeIndex = this.selInstTypes.map(function (item) { return item }).indexOf('Warrant');
                  this.selInstTypes.splice(removeIndex, 1);
                }
              });
            }
          }
          if (button === 'notes') {
            if (this.notes === true) {
              this.selInstTypes.push(this.instTypesList[3]);
            }
            else {
              //this.selInstTypes.splice(this.selInstTypes['notes'], 1);
              this.selInstTypes.forEach((element) => {
                if (element == "Note") {
                  let removeIndex = this.selInstTypes.map(function (item) { return item }).indexOf('Note');
                  this.selInstTypes.splice(removeIndex, 1);
                }
              });
            }
          }
          if (button === 'equity') {
            if (this.equityshares === true) {
              this.selInstTypes.push(this.instTypesList[4]);
            }
            else {
              this.selInstTypes.forEach((element) => {
                if (element == "Equity") {
                  let removeIndex = this.selInstTypes.map(function (item) { return item }).indexOf('Equity');
                  this.selInstTypes.splice(removeIndex, 1);
                }
              });
            }
          }
    });
    this.authService.getInvInstClsByInst(this.selInstTypes)
      .subscribe(response => {
        if (response) {
          this.instClsListTble = response;
        }
      }, e => {
        this.toastr.error("Error: " + e.error.reason, "Error");
      });
  }

  showCurrency(amount) {
    return this.utilityService.calculateCurrency(this.roundCurrency, this.companyCurrency, this.exchangeRate, amount)
  }

  savePopup(event) {
    console.log('received event: ', event);
    if(event.invDetails) {
      this.investorList.forEach((element, index) => {
        if(index == event.selectedIndex) {
            element['isEditingInvestor'] = true;
            element['investmentAmount'] = event.invDetails['investmentAmount'];
            element['nominalEquity'] = event.invDetails['includeNominalEquity'];
            element['nominalEquityShares'] = event.invDetails['nominalEquityShares'];
            element['nominalEquityClassName'] =  event.invDetails['nominalEquityClassName']
        } else {
            element['isEditingInvestor'] = false;
        }
      });
    }
    console.log('investor list: ', this.investorList);
    // if(event['invDetails']) {
    //     let index = event['selectedIndex'];
    //     this.investorList[index]['nominalEquity'] = event['invDetails']['includeNominalEquity'];
    //     this.investorList[index]['nominalEquityShares'] = event['invDetails']['nominalEquityShares'];
    //     this.investorList[index]['investmentAmount'] = event['invDetails']['investmentAmount'];
    //     this.investorList[index]['numberOfShares'] = event['invDetails']['numberOfShares'];
    // }
    // this.getDetails();
    this.updateDetails();
    this.loadJsStyles();
    this.utilityService.hideOverlay()
    this.showInvestmentDialog = false;
    this.ngxLoader.stop();
  }

  updateDetails() {
    this.ngxLoader.start();
    this.authService.getInstrumentTypeList().subscribe(
      (res) => {
        this.basicDetails = res;
        this.nominalEqtyList = res["nominalEquityClassForDropdown"];
        this.nominaleqty = res['nominalEquity'];
        this.nomEqtyInstCls = res['nominalEquityClass'];
        this.investorList.forEach((investor, index) => {
          if (!investor['isExistingInvestor']) {
            let nomi = investor['nominalEquityShares'];
            investor['nominalEquityShares'] = this.utilityService.formatCurrencyDeci(this.companyCurrency, nomi);;
          }
        });
        if (res['noOfNominalEquityShares']) {
          let totNomi = res['noOfNominalEquityShares'];
          this.nomEqtyShre = this.utilityService.formatCurrencyDeci(this.companyCurrency, totNomi);
        }
        else {
          this.nomEqtyShre = res['noOfNominalEquityShares'];
        }
        this.updateBasicdata();
        this.CalculateTotal(this.investorList);
        this.ngxLoader.stop();
        this.loadJsStyles();
      },
      (e) => {
        this.ngxLoader.stop();
        this.getDataFromCache();
        this.toastr.error("Error: " + e.error.reason, "Error");
      }
    );
  }

  saveSecurity(event) {
    this.shouldShowDialog = false;
    let selIndex = event['selectedIndex'];
    let instClass = null;
    this.utilityService.hideOverlay();
    this.instClassChange(selIndex, instClass);

    let index = event['selectedIndex']
    if(event["className"] != null || event["className"] === ""){
      this.investorList[index]["className"] = event["className"];
    }
    document.getElementById('overlay').style.display = 'none';
    this.authService.getInvInstClsByInst(this.selInstTypes)
      .subscribe(response => {
        if (response) {
          this.utilityService.hideOverlay();
          this.instClsListTble = response;
        }
      }, e => {
        this.utilityService.hideOverlay();
        this.toastr.error("Error: " + e.error.reason, "Error");
      });
    this.updateBasicdata();
  }

  updateBasicdata(){
    //For Basic Details
    let basicData = {
      isBasicDetailsFilled: true,
      roundName: this.basicDetails['roundName'],
      roundSize: this.basicDetails['roundSize'],
      roundValuation: this.basicDetails['roundValuation'],
      amountRaised: this.basicDetails['amountRaised'],
      amountLeftToRaise: this.basicDetails['amountLeftToRaise'],
      leadInvestorName: this.basicDetails['leadInvestorName'],
      sharePrice: this.basicDetails['pricePerShare'],
      roundCurrency: this.basicDetails['currencyForRound'],
      isRoundNotSaved: this.isRoundNotSaved,
    }
    this.dataService.updateBasicData(basicData);
    this.utilityService.hideOverlay();
  }

  fieldEdited($event) {
    this.isRoundNotSaved = true;
  }

  getDetails() {
    this.ngxLoader.start();
    this.authService.getInstrumentTypeList().subscribe(response => {
      if (response != null) {
        this.basicDetails = response;
        this.errorFlags = response['errorFlags'];
        this.dataService.updateData(this.errorFlags);
        this.companyCurrency = response['companyCurrency'];
        this.roundCurrency = response['currencyForRound'];
        this.exchangeRate = response['exchangeRate'];
        this.instTypesList = response["instrumentListToBeDisplayed"];
        this.nominalEqtyList = response["nominalEquityClassForDropdown"];
        this.instTypesListValues = response["instrumentValues"];
        let invList = response["investors"];
        invList.forEach(element => {
          if(element.className === null){
            element.className = "";
          }
        });
        this.investorList = invList;
        this.updateBasicdata();
        this.investorList.forEach((investor, index) => {
          if (!investor['isExistingInvestor']) {
            let nomi = investor['nominalEquityShares'];
            investor['nominalEquityShares'] = this.utilityService.formatCurrencyDeci(this.companyCurrency, nomi);;
          }
        });
        this.nominaleqty = response['nominalEquity'];
        this.nomEqtyInstCls = response['nominalEquityClass'];
        if (response['noOfNominalEquityShares']) {
          let totNomi = response['noOfNominalEquityShares'];
          this.nomEqtyShre = this.utilityService.formatCurrencyDeci(this.companyCurrency, totNomi);
        }
        else {
          this.nomEqtyShre = response['noOfNominalEquityShares'];
        }
        this.instClsListTble = response['instrumentClasses'];
        if (this.instTypesListValues) {
          this.prefshares = this.instTypesListValues['preferenceShares'];
          $('.alert-status-preference').bootstrapSwitch('state', this.prefshares);
          this.warrents = this.instTypesListValues['warrants'];
          $('.alert-status-warrants').bootstrapSwitch('state', this.warrents);
          this.debentures = this.instTypesListValues['debentures'];
          $('.alert-status-debentures').bootstrapSwitch('state', this.debentures);
          this.notes = this.instTypesListValues['notes'];
          $('.alert-status-notes').bootstrapSwitch('state', this.notes);
          this.equityshares = this.instTypesListValues['equityShares'];
          $('.alert-status-equityshares').bootstrapSwitch('state', this.equityshares);
        }
        this.CalculateTotal(this.investorList);     
        // this.loadJsStyles();
        this.ngxLoader.stop();
        this.loadJsStyles();
      } else {
        this.getDataFromCache();
        // this.loadJsStyles();
        this.ngxLoader.stop();
      }
      console.log("Investo list", this.investorList);
    }, (e) => {
      //get data from cache
      this.ngxLoader.stop();
      this.getDataFromCache();
      this.toastr.error("Error: " + e.error.reason, "Error");
    });
  }

  loadJsStyles() {

    $('[data-toggle="switch"]').bootstrapSwitch({
      onText: "Yes",
      offText: "No",
    });
    $('.alert-status-equityshares').bootstrapSwitch('state', this.equityshares);
    $("#equityShares").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        if(state == true) {
            this.equityshares = state;
            this.prefshares = $('.alert-status-preference').bootstrapSwitch('state');
            this.warrents = !state;
            this.debentures = !state;
            this.notes = !state;
            this.nominaleqty = false;
            if($('.alert-status-preference').bootstrapSwitch('state')) {
              $('.alert-status-debentures').bootstrapSwitch('state', false);
              this.getInvestorsListByInstType(['equity', 'preference']);
            } else {
              $('.alert-status-nominal-equity').bootstrapSwitch('state', false);
              $('.alert-status-nominal-equity').bootstrapSwitch('disabled',true);
              $('.alert-status-warrants').bootstrapSwitch('state', false);
              $('.alert-status-debentures').bootstrapSwitch('state', false);
              $('.alert-status-notes').bootstrapSwitch('state', false);
              this.getInvestorsListByInstType(['equity']);
            }
        }
    });
    $('.alert-status-preference').bootstrapSwitch('state', this.prefshares);
    $("#preferenceShares").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        if(state == true) {
            this.equityshares = $('.alert-status-equityshares').bootstrapSwitch('state');
            this.prefshares = state;
            this.warrents = !state;
            this.debentures = $('.alert-status-debentures').bootstrapSwitch('state');
            this.notes = !state;
            $('.alert-status-nominal-equity').bootstrapSwitch('disabled',false);
            $('.alert-status-warrants').bootstrapSwitch('state', false);
            $('.alert-status-notes').bootstrapSwitch('state', false);
            if($('.alert-status-equityshares').bootstrapSwitch('state')) {
                $('.alert-status-debentures').bootstrapSwitch('state', false);
              this.getInvestorsListByInstType(['preference', 'equity']);
            } else if($('.alert-status-debentures').bootstrapSwitch('state')) {
                $('.alert-status-equityshares').bootstrapSwitch('state', false);
                this.getInvestorsListByInstType(['preference', 'debentures']);
            } else {
              this.getInvestorsListByInstType(['preference']);
            }
        }
    });
    $('.alert-status-warrants').bootstrapSwitch('state', this.warrents);
    $("#warrantShares").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        if(state == true) {
            this.equityshares = !state;
            this.prefshares = !state;
            this.warrents = state;
            this.debentures = !state;
            this.notes = !state;
            $('.alert-status-nominal-equity').bootstrapSwitch('disabled',false);
            $('.alert-status-equityshares').bootstrapSwitch('state', false);
            $('.alert-status-debentures').bootstrapSwitch('state', false);
            $('.alert-status-preference').bootstrapSwitch('state', false);
            this.getInvestorsListByInstType(['warrents']);
        }
    });
    $('.alert-status-debentures').bootstrapSwitch('state', this.debentures);
    $("#debenturesShares").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        if(state == true) {
            this.equityshares = !state;
            this.prefshares = $('.alert-status-preference').bootstrapSwitch('state');
            this.warrents = !state;
            this.debentures = state;
            this.notes = !state;
            $('.alert-status-nominal-equity').bootstrapSwitch('disabled',false);
            $('.alert-status-equityshares').bootstrapSwitch('state', false);
            $('.alert-status-warrants').bootstrapSwitch('state', false);
            $('.alert-status-notes').bootstrapSwitch('state', false);
            if($('.alert-status-preference').bootstrapSwitch('state')) {
                this.getInvestorsListByInstType(['debentures', 'preference']);
            } else {
                this.getInvestorsListByInstType(['debentures']);
            }
        }
    });
    $('.alert-status-notes').bootstrapSwitch('state', this.notes);
    $("#notesShares").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        if(state == true) {
            this.equityshares = !state;
            this.prefshares = !state;
            this.warrents = !state;
            this.debentures = !state;
            this.notes = state;
            $('.alert-status-nominal-equity').bootstrapSwitch('disabled',false);
            $('.alert-status-equityshares').bootstrapSwitch('state', false);
            $('.alert-status-debentures').bootstrapSwitch('state', false);
            $('.alert-status-preference').bootstrapSwitch('state', false);
            this.getInvestorsListByInstType(['notes']);
        }
    });
    $('.alert-status-nominal-equity').bootstrapSwitch('state', this.nominaleqty);
    $("#nominalShares").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
      this.nominaleqty = state;
    });

    if(this.prefshares == true) {
      this.equityshares = $('.alert-status-equityshares').bootstrapSwitch('state');
      this.prefshares = true;
      this.warrents = false;
      this.debentures = $('.alert-status-debentures').bootstrapSwitch('state');
      this.notes = false;
      $('.alert-status-nominal-equity').bootstrapSwitch('disabled',false);
      $('.alert-status-warrants').bootstrapSwitch('state', false);
      $('.alert-status-notes').bootstrapSwitch('state', false);
      if($('.alert-status-equityshares').bootstrapSwitch('state')) {
          $('.alert-status-debentures').bootstrapSwitch('state', false);
        this.getInvestorsListByInstType(['preference', 'equity']);
      } else if($('.alert-status-debentures').bootstrapSwitch('state')) {
          $('.alert-status-equityshares').bootstrapSwitch('state', false);
          this.getInvestorsListByInstType(['preference', 'debentures']);
      } else {
        this.getInvestorsListByInstType(['preference']);
      }
    }

    if(this.equityshares) {
      this.equityshares = true;
      this.prefshares = $('.alert-status-preference').bootstrapSwitch('state');
      this.warrents = false;
      this.debentures = false;
      this.notes = false;
      this.nominaleqty = false;
      if($('.alert-status-preference').bootstrapSwitch('state')) {
        $('.alert-status-debentures').bootstrapSwitch('state', false);
        this.getInvestorsListByInstType(['equity', 'preference']);
      } else {
        $('.alert-status-nominal-equity').bootstrapSwitch('state', false);
        $('.alert-status-nominal-equity').bootstrapSwitch('disabled',true);
        $('.alert-status-warrants').bootstrapSwitch('state', false);
        $('.alert-status-debentures').bootstrapSwitch('state', false);
        $('.alert-status-notes').bootstrapSwitch('state', false);
        this.getInvestorsListByInstType(['equity']);
      }
    }
  }

  ngOnInit() {
    this.getDetails();
    
  }

  getDataFromCache() {
    let cachedInvestorListData = this.cacheService.get('investorListDetail')
    if (cachedInvestorListData) {
      this.investorList = JSON.parse(cachedInvestorListData)
    }

    let boolValues = this.cacheService.get('boolValues')
    if (boolValues) {
      let temp = JSON.parse(boolValues)

      this.equityshares = temp.equityshares
      this.prefshares = temp.prefshares;
      this.warrents = temp.warrents;
      this.debentures = temp.debentures;
      this.notes = temp.notes;
      this.nominaleqty = temp.nominaleqty;
    }
  }

  removeDataFromCache() {
    let cachedShareHldrsData = this.cacheService.get('investorListDetail')
    if (cachedShareHldrsData) {
      this.cacheService.remove('investorListDetail')
    }
  }

  goToNewInvestor() {
    if (this.investorList && this.investorList.length > 0) {
      this.cacheService.set('investorListDetail', JSON.stringify(this.investorList))
    }

    let temp: any = {}

    temp.equityshares = this.equityshares;
    temp.prefshares = this.prefshares;
    temp.warrents = this.warrents;
    temp.debentures = this.debentures;
    temp.notes = this.notes;
    temp.nominaleqty = this.nominaleqty;
    this.cacheService.set('boolValues', JSON.stringify(temp))


    this.router.navigate(["/landing", "dashboard", 'rounds', "newRound", "newInvestors"]);
  }

  showInsruemntHelp() {
    var inst = document.getElementById('instrument-help').id;
    inst = "#" + inst;
    $(inst).show();
  }

  hideInsruemntHelp() {
    var inst = document.getElementById('instrument-help').id;
    inst = "#" + inst;
    $(inst).hide();
  }

  // help section show
  showHelp(section) {
    if (section == this.helper) {
      return;
    }
    const targetElement = document.getElementById(section);
    if (targetElement != null) {
      this.helperTopPosition = targetElement.offsetTop;
    }

    this.helpSections = this.helperService.getHelpContent(
      "InstrumentTabComponent",
      section
    );
    this.helper = section;
  }

  // help section hide
  hideHelp() {
    this.helper = "";
  }

  // input field olny number and comma
  onlyNumberKey(event) {
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
      return false;
    }
    else {
      return true;
    }
  }

  onChangeNominal(e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
  
    let val = this.nomEqtyShre;
    exitVal = this.utilityService.inputCSVformatCurrency(this.companyCurrency, val);
    if(e) {
      if(e.target.value) {
        let CSVLength = exitVal.length;
        let valueLength = e.target.value.length;
        if(CSVLength > valueLength) {
            e.target.value = exitVal;
            let difference = CSVLength - valueLength;
            start = start + difference;
            end = end + difference;
        }
        else if(CSVLength < valueLength){
          e.target.value = exitVal;
          if(start != 0 || end != 0){
              let difference = valueLength - CSVLength;
              start = start - difference;
              end = end - difference;
          }
          else{
              start = start;
              end = end;
          }  
        }
        else {
          e.target.value = exitVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    }
    else{
      this.nomEqtyShre = exitVal;
    }
  }

  formatCurrency(value) {
    return this.utilityService.formatCurrencyDeci(this.companyCurrency, value);
  }

  csvVal(value, e){
    let exitVal;
    if(e) {
      // store current positions in variables
      var start = e.target.selectionStart,
      end = e.target.selectionEnd;
    }
  
    let val = value;
    exitVal = this.utilityService.inputCSVformatCurrency(this.companyCurrency, val);
    if(e) {
      if(e.target.value) {
        let CSVLength = exitVal.length;
        let valueLength = e.target.value.length;
        if(CSVLength > valueLength) {
            e.target.value = exitVal;
            let difference = CSVLength - valueLength;
            start = start + difference;
            end = end + difference;
        }
        else if(CSVLength < valueLength){
          e.target.value = exitVal;
          if(start != 0 || end != 0){
              let difference = valueLength - CSVLength;
              start = start - difference;
              end = end - difference;
          }
          else{
              start = start;
              end = end;
          }  
        }
        else {
          e.target.value = exitVal;
        }
      }
      // restore from variables...
      e.target.setSelectionRange(start, end);
    } 
  }

}
