<div *ngIf="showdigNewGroup" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-lg-8 col-sm-10 col-md-10 custom-modal-header">
        <label class="dialogue-title">Create New Group</label>
      </div>
      <div class="col-lg-4 col-sm-2 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container-fluid top-20 btm-20">
    <div class="row btm-20">
      <div class="col-md-12">
        <mat-label class="required">Group Name</mat-label>
        <input type="text" [(ngModel)]="newGroupObj.groupName" #gNameErr="ngModel" class="form-control top-10" 
          [ngClass]="(gNameErr.errors?.required && groupdNameErr) ? 'is-invalid' : 'is-valid'" required/>
        <div class="alert alert-danger" *ngIf="gNameErr.errors">
          <span *ngIf="gNameErr.errors?.required && groupdNameErr">
              Group Name is required!
          </span>
        </div>
      </div>
    </div>
    <div class="row btm-20">
      <div class="col-md-12">
        <mat-label>Description</mat-label>
        <textarea class="form-control top-10" #groupDescription="ngModel" [(ngModel)]="newGroupObj.description"></textarea>
      </div>
    </div>
    <div class="row btm-20">
      <div class="col-md-12">
        <button mat-raised-button id="saveAndExit" color="success" [disableRipple]="true" class=" float-right" (click)="createNewGroup()" ngbTooltip="Save and Exit">Save and Exit</button>
        <button mat-raised-button id="cancel" color="warn" [disableRipple]="true" class=" float-right margin-right-20" (click)="close()" ngbTooltip="Cancel">Cancel</button>
      </div>
    </div>
  </div>
</div>