<div class="row" (mouseleave)="hideHelp()">
  <div class="col-md-6 col-lg-8 col-xl-9">
    <mat-card id="Confirm_Tab">
      <mat-card-header>
        <mat-card-title>Buy-back Confirm</mat-card-title>
      </mat-card-header>
      <mat-divider class="divider-line"></mat-divider>
      <mat-card-content>
        <form class="needs-validation" novalidate>
          <div (mouseover)="showHelp('Confirm')">
            <div class="row">
              <div class="col-md-12">
                <p class="content-pg-1 top-20 btm-20">
                  The amount payable to the shareholders towards the shares being bought back is drawn from the company’s share reserves.
                  <br><br>
                  Enter the total amount of share reserves that the company has as on date in the <b>‘Confirmation’</b> section. Also, check the boxes if the company is in compliance with the rules of buyback under the Companies Act.
                  <br><br>
                  Once this is done, click on <b>‘Complete Buy-back’</b> to complete the transaction. 
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <mat-card id="BuyBackDetails">
                  <div class="row">
                    <div class="col-md-4">
                      <mat-label>Buy-back Name</mat-label>
                      <p>{{buyBackDetails.buyBackName}}</p>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Buy-back Date</mat-label>
                      <p>{{startDate}}</p>
                    </div>
                    <div class="col-md-4">
                      <mat-label>Security Type</mat-label>
                      <p>{{buyBackDetails.className}}</p>
                    </div>
                  </div>
                  <br><br>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-label>No of shares</mat-label>
                      <p >{{getTotalValueTble(buyBackDetails.numberOfSharesBoughtBackActual)}}</p>
                    </div>
                    <div class="col-md-6">
                      <mat-label>Price per share ({{currencySymbol}})</mat-label>
                      <p>{{getTotalValueTble(buyBackDetails.pricePerShare)}}</p>
                    </div>
                  </div>
                  <br><br>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-label>Dilution</mat-label>
                      <p>{{postBuyBackConsolidation}}%</p>
                    </div>
                    <div class="col-md-6">
                      <mat-label>Amount Bought Back ({{currencySymbol}})</mat-label>
                      <p ngbTooltip="{{formatCompanyCurrency(totalBuyBackAmount)}}">{{getTotalValueTble(totalBuyBackAmount)}}</p>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <br><br>
            <mat-card id="Confirmation_Tab">
              <div class="row">
                <div class="col-md-3 top-10">
                  <mat-label>Confirmation</mat-label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 top-10">
                  <mat-label class="required">Share reserves available</mat-label>
                </div>
                <div class="col-md-3">
                  <input type="text" (keypress)="onlyNumberKey($event)" (input)="onChangeShareReserves('ip', $event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="shareReservesText" #shareReservesErr="ngModel"
                        class="form-control inputs" placeholder="Share Reserves" [ngModelOptions]="{standalone: true}" title=""
                        required />
                </div>
              </div>
              <div class="row" *ngIf=shareReservesCheck>
                <div class="col-md-12">
                  <p>*Share reserves should be greater than the Buy-back amount to Proceed</p>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-12">
                  <mat-checkbox class="margin-left-20" *ngFor="let i of compliance" [(ngModel)]="i.isChecked" [ngModelOptions]="{standalone: true}"
                    (change)="setAll()">
                    {{i.text}}
                  </mat-checkbox>
                  
                </div>
              </div>
            </mat-card>
            <div class="row">
              <div class="col-md-12 top-20">
                <div>
              <button mat-raised-button id="completeBuy-back" [disableRipple]="disableripple" color="success" [disabled]="disableCompletion || shareReservesCheck" [color]="disableCompletion === false && shareReservesCheck === false ? 'success' : 'disabled' "
                    class=" pull-right" (click)="completeBuyBack()" ngbTooltip="Complete Buy-back">
                    Complete Buy-back
                </button>
                <span class="round-tooltip" *ngIf="disableCompletion"  placement="right" [ngbTooltip]="checkBoxes" ></span>
              </div>
                <button mat-raised-button id="previous" [disableRipple]="disableripple" color="warn"
                    class=" pull-right margin-right-20" (click)="goToSummary()"
                    ngbTooltip="Previous">Previous
                </button>
                <button mat-raised-button id="discard" [disableRipple]="disableripple" color="warn"
                    class=" pull-right margin-right-20" (click)="discardBuyBack()"
                    ngbTooltip="Discard Buy-Back">Discard
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="helper-container">
        <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
          <mat-card-header class="help-hdr">
            <mat-card-title><label class="help-hdr-txt">{{
               helpSections?.name
              }}</label></mat-card-title>
          </mat-card-header>
          <hr />
          <mat-card-content class="card-help-content">
            <div [innerHTML]="helpSections?.description"></div>
          </mat-card-content>
        </mat-card>
    </div>
  </div>
</div>