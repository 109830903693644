import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HideIfDirective } from "./hide-if.directive";
import { ShowIfDirective } from "./show-if.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    HideIfDirective,
    ShowIfDirective
  ],
  exports: [
    HideIfDirective,
    ShowIfDirective
  ]
})

export class DirectivesModule {}