import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/permissions.service";
import * as i2 from "@angular/router";
var CanActivateGuard = /** @class */ (function () {
    function CanActivateGuard(permService, router) {
        this.permService = permService;
        this.router = router;
    }
    CanActivateGuard.prototype.canActivate = function (route, state) {
        var permissionRequired = route.data["permissionRequired"];
        if (this.permService.authorizeUser(permissionRequired)) {
            return true;
        }
        else {
            this.router.navigateByUrl("/landing/dashboard/401");
            return false;
        }
    };
    CanActivateGuard.ngInjectableDef = i0.defineInjectable({ factory: function CanActivateGuard_Factory() { return new CanActivateGuard(i0.inject(i1.PermissionsService), i0.inject(i2.Router)); }, token: CanActivateGuard, providedIn: "root" });
    return CanActivateGuard;
}());
export { CanActivateGuard };
