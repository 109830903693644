<div class="financial-report">
    <div mat-dialog-content class="resolutionDate">Download Financial Report</div>
    <!-- <div 
        mat-dialog-title>Download Financial Report
    </div> -->
    <div mat-dialog-content class="body" >
        <div>
            <div >
                <div class="date-container toggle">
                    <mat-label class="col-7">Include Vesting Schedule : </mat-label>
                    <mat-button-toggle-group [(ngModel)]="withVestingSchedule">
                        <mat-button-toggle [value]="true">
                            Yes</mat-button-toggle>
                        <mat-button-toggle [value]="false">
                            No</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <div class="date-container">         
                <mat-label class="required col-4">Vesting Date </mat-label>
                <div class="input-eyePlaceholder col-7 date-box">
                    <input matInput [matDatepicker]="picker" #vDateError="ngModel" [(ngModel)]="vestingDate"
                        placeholder="DD-MMM-YYYY" [ngModelOptions]="{standalone: true}" required>
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker ngDefaultControl></mat-datepicker>
            
                </div>
            
            
            </div>
            <div *ngIf="vDateError.errors">
                <div align="end" *ngIf="(vDateError.dirty || vDateError.touched) && !vestingDate" class="alert alert-danger" style="margin-right: 50px;" >Vesting Date is required
                </div>
              </div>
        </div>

    </div>
    <div mat-dialog-actions align="end" class="buttons">
        <button mat-raised-button id="confirm" class="enabled" [disabled]="vDateError.errors && vestingDate==undefined" [color]="(vDateError.errors && vestingDate==undefined) ? 'basic' : 'primary'"
        (click)="checkValidation(withVestingSchedule,vestingDate)" cdkFocusInitial>Download</button>
        <button mat-raised-button id="cancel" color="secondary" [mat-dialog-close]="'no'">Cancel</button>
        
    </div>
</div>