import { Component, OnInit } from '@angular/core';
import { ShareHolder } from '../buy-back-tab/model/shareHolder';
import { CacheProviderService } from "../../services/cache-provider.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/authentication.service";
import { DatePipe } from "@angular/common";
import { DataService } from "../../services/new-round-notify.service";
import { UtilityService } from "../../services/utilities.service";
import { HelperService } from "../../services/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { BuyBackDetails } from '../buy-back-tab/model/buyBackDetails';
import { ColumnList } from '../summary-cap-table/columns-list';


@Component({
  selector: 'app-buy-back-details',
  templateUrl: './buy-back-details.component.html',
  styleUrls: ['./buy-back-details.component.scss']
})
export class BuyBackDetailsComponent implements OnInit {

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private utility: UtilityService,
    private helperService: HelperService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService
  ) {}

  public usrCompanyCurrency = "INR - ₹";
  public userDetails;

  buyBackDetails: BuyBackDetails;
  buyBackId: number;
  target : number = 0;
  pps: number = 0;
  instrumentName: string = "";
  startDate: any;
  capTableData: any;

  totalBuyBackAmount: number = 0;
  totalWillingNess: number = 0;
  actualTargetShares: number = 0;
  actualTargetSharesText = '';
  postBuyBackConsolidation: number = 0;
  instrumentsFromResponse:any = [];
  totalSharesInSecurity: number = 0;
  public disableripple: boolean = true;

  displayCols: ColumnList;
  holdersList;

  ngOnInit() {
    this.ngxLoader.start();
    this.activatedRoute.queryParams
      .filter(params => params.buyBackId)
      .subscribe(params => {
        // console.log(params); // { order: "popular" }
        this.buyBackId = params.buyBackId;
      }
    );

    this.displayCols = new ColumnList();
    this.displayCols = {
      shareholder: true,
      fdbShares: true,
      preHoldingPercentage: true,
      postHoldingPercentage: true,
      preHoldingShares: true,
      postHoldingShares: true,
      amountReceived: true,
        
    }

    this.authService.getProformaBuyBackDetails(this.buyBackId).subscribe((res: BuyBackDetails) => {
      this.buyBackDetails = res;
      if(res.state != "COMPLETED"){
        this.toastr.error("Buy-back process is not completed for this Buy-back Id. Routing to overview page", "Error");
        this.ngxLoader.stop();
        this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
      }
      this.target = res.numberOfSharesBoughtBackActual;
      this.pps = res.pricePerShare;
      this.instrumentName = res.className;
      this.totalBuyBackAmount = res.buyBackAmountActual;
      // this.postBuyBackConsolidation = res.postBuybackConsolidation;
      this.postBuyBackConsolidation = Math.round(res.postBuybackConsolidation*10000) / 100;
      this.startDate = this.datepipe.transform(this.buyBackDetails.startDate, 'dd-MMM-yyyy');
      if(res && res.buyBackSummary && typeof(res.buyBackSummary) === 'string'){
        this.capTableData = JSON.parse(res.buyBackSummary);
        for (var record in this.capTableData){
          if (this.capTableData[record].hasOwnProperty("investorGroup")) {
            this.capTableData[record]["name"] = this.capTableData[record]["investorGroup"];
          }
          if (this.capTableData[record].hasOwnProperty("postHoldingFDBShares")) {
            this.capTableData[record]["numberOfDilutedShares"] = this.capTableData[record]["postHoldingFDBShares"];
          }
        }
        this.holdersList = this.capTableData;
      }
      else{
        this.holdersList = res.buyBackSummary;
      }
      // this.holdersList = JSON.stringify(res.buyBackSummary)
      this.ngxLoader.stop();
    
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error("Error: Unable to get Buy-back details, Routing to Overview page", e);
      this.router.navigate(['/landing', 'dashboard', 'buy-back', 'overview']);
    });

    if(this.cacheService.get("userDetails")){
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    }
    else{
      this.usrCompanyCurrency = "INR - ₹";
    }

    this.ngxLoader.stop();

    
  }

  getTotalValueTble(value) {
    if (value >= 0) {
      if (value.toString().length > 5) {
        return this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utility.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      if(value != undefined || value != null){
        let sign = "-";
        if (value.toString().length > 5) {
          return sign + this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
        }
        else {
          return this.utility.formatCurrency(this.usrCompanyCurrency, value);
        }
      }
    }
  }

  formatCompanyCurrency(value){
    return this.utility.inputCSVformatCurrency1(this.usrCompanyCurrency, value);
  }

  calculatePostBuyBackConsoildation(){
    if ((this.totalSharesInSecurity != 0)){
      this.postBuyBackConsolidation = this.buyBackDetails.numberOfSharesBoughtBackActual * 100 / this.totalSharesInSecurity;
      this.postBuyBackConsolidation = Math.round(this.postBuyBackConsolidation*100) / 100;
    }
    else{
      this.postBuyBackConsolidation = 0;
    }
  }

}

