<div *ngIf="showDialogsplitstock" class="dialog">
    <div class="col-xs-12 col-sm-12 col-md-12 header">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 dialogue-title top-20">
               Update Split Ratio 
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6  top-20">
                <button type="button" class="close  dialogue-title" (click)="close()" aria-label="Close">
                    <span class="close">X</span>
                </button>
            </div>
        </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
    <div class="col-md-12">
        <div class="row">
            <div class="col-xs-7 col-sm-7 col-md-7">
                <div class="row top-20">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label class="required">Split Ratio</mat-label>
                        <input id="demo_vertical" name="ratio" class="form-control text-align-right top-10" type="text"
                            [(ngModel)]="splitShareObj.splitRatio" #splitErr="ngModel" placeholder="Convertion Ratio"
                            (blur)="checkRatioVal(splitShareObj.splitRatio)" (keydown)=onKeydownEvent($event)
                            title="" />
                        <div class="alert alert-danger" *ngIf="ratioInvalid">
                            Correct ratio required.
                        </div>
                        <div *ngIf="mSplitshare" class="alert alert-danger">
                            Split Capital is required!
                        </div>
                    </div>
                </div>

                <div class="row top-20">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label class="required">Comments</mat-label>
                        <textarea class="form-control top-10" type="text" title=""
                            [(ngModel)]="splitShareObj.comments" (ngModelChange)="validateComments(event)"></textarea>
                        <div class="alert alert-danger" *ngIf="mComments">
                            Comments is required!
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-5 col-sm-5 col-md-5 top-20">
                <mat-label class="required">Date</mat-label>
                <input class="form-control top-10" type="date" title="" [(ngModel)]="splitShareObj.date" (ngModelChange)="validateDate(event)" />
                <div class="alert alert-danger" *ngIf="mDate">
                    Date is required!
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12 top-20">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <textarea class="form-control dynamic-box" type="text" title=""
                    placeholder="Dynamic text based on new Split Ratio"></textarea>
            </div>
        </div>
    </div>

    <div class="top-20 float-right btm-20">
        <div class="col-md-12">
            <button mat-raised-button id="cancel" color="warn" (click)="close()" [disableRipple]="disableripple"
                ngbTooltip="Cancel">
                Cancel
            </button>
            <button mat-raised-button id="save" color="success" class="left-20" [disableRipple]="disableripple"
                (click)="splitShares()" ngbTooltip="Save">
                Save
            </button>
        </div>
    </div>
</div>