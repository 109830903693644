import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule, MatTabsModule, MatInputModule, MatSlideToggleModule, MatSliderModule, MatCardModule, MatButtonModule, MatButtonToggleModule, MatStepperModule, MatMenuModule, MatDividerModule, MatExpansionModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatIconModule, MatAutocompleteModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxChartModule, DxDataGridModule, DxTreeListModule, DxButtonModule, DxTemplateModule, DxTooltipModule, DxDropDownBoxModule, DxPieChartModule } from 'devextreme-angular';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CommonDialogsModule } from '../dialogues/common-dialogs.module';
import { DirectivesModule } from '../custom-directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';


import { BuyBackRoutingModule } from './buy-back-routing.module';
import { BuyBackOverviewComponent } from './buy-back-overview/buy-back-overview.component';
import { CreateNewBuyBackComponent } from './create-new-buy-back/create-new-buy-back.component';
import { BuyBackTabComponent } from './buy-back-tab/buy-back-tab.component';
import { ShareholdersTabComponent } from './shareholders-tab/shareholders-tab.component';
import { ReviewTabComponent } from './review-tab/review-tab.component';
import { BuyBackSummaryTabComponent } from './summary-tab/summary-tab.component';
import { ConfirmTabComponent } from './confirm-tab/confirm-tab.component';
import { SummaryCapTableComponent } from './summary-cap-table/summary-cap-table.component';
import { BuyBackOverviewTableComponent } from './buy-back-overview-table/buy-back-overview-table.component';
import { BuyBackDetailsComponent } from './buy-back-details/buy-back-details.component';
import { EditSharesWillingnessComponent } from './edit-shares-willingness/edit-shares-willingness.component';

@NgModule({
  declarations: [
    BuyBackOverviewComponent,
    CreateNewBuyBackComponent,
    BuyBackTabComponent,
    ShareholdersTabComponent,
    ReviewTabComponent,
    BuyBackSummaryTabComponent,
    ConfirmTabComponent,
    SummaryCapTableComponent,
    BuyBackOverviewTableComponent,
    BuyBackDetailsComponent,
    EditSharesWillingnessComponent
  ],
  imports: [
    CommonModule,
    BuyBackRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DxChartModule,
    DxPieChartModule,
    DxDataGridModule,
    DxTreeListModule,
    DxButtonModule,
    DxTemplateModule,
    DxTooltipModule,
    DxDropDownBoxModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    NgbModule,
    MatIconModule,
    MatStepperModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatCheckboxModule,
    NgxUiLoaderModule,
    MatDatepickerModule,
    CommonDialogsModule,
    DirectivesModule,
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BuyBackModule { }
