export class FounderDetailsModel { 
    id:number;
    fullName: string;
    emailId: string;
    designation: string;
    contactNumber: string;
    address: string;
    dateOfBirth: string;
    nationality: string;
    linkedInProfile: string;
    twitterHandle:  string;
    isSelected: boolean;
    shouldShowDialog: boolean;
    isEdit:boolean = false;
} 

      