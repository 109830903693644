<div id="overlay" [ngClass]="{'display-on': showDialog}"></div>

<div *ngIf="showDialog" class="dialog">

  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">Founder Details</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container-fluid content-row">
    <form #foundForm = "ngForm" class="row top-20 needs-validation" novalidate>
      <div class="col-md-6">
        <div>
          <mat-label class="required">Name</mat-label>
          <input type="text" class="form-control top-10" [(ngModel)]="founderDetailsModel.fullName" #nameErr="ngModel"
            title="" placeholder="Enter founder name" [ngModelOptions]="{standalone: true}" required>
          <div *ngIf="nameErr.errors">
            <span class="alert alert-danger" *ngIf="(nameErr.dirty || nameErr.touched) && !nameValid">
              Name is required!
            </span>
            <span class="alert alert-danger" *ngIf="nameErr.errors?.required && nameValid">
              Name is required!!
            </span>
          </div>
        </div>

        <div class="top-20">
          <mat-label class="required">Designation</mat-label>
          <select class="form-control top-10 cursor-hand" [(ngModel)]="founderDetailsModel.designation"
            #typeDirErr="ngModel" title="" [ngModelOptions]="{standalone: true}" required>
            <option *ngFor='let designation of founderDesignations; let k = index'>{{designation}}</option>
          </select>
          <div *ngIf="typeDirErr.errors">
            <span class="alert alert-danger" *ngIf="(typeDirErr.dirty || typeDirErr.touched) && !designationValid">
              Designation is required!
            </span>
            <span class="alert alert-danger" *ngIf="typeDirErr.errors?.required  && designationValid">
              Designation is required!!
            </span>
          </div>
        </div>

        <div class="top-20">
          <mat-label>Address</mat-label>
          <textarea class="form-control top-10" [(ngModel)]="founderDetailsModel.address" title="" rows="3" 
          [ngModelOptions]="{standalone: true}" placeholder="Address"></textarea>
        </div>

        <div class="top-20">
          <mat-label>Linkedin Profile</mat-label>
          <input type="text" class="form-control top-10" [(ngModel)]="founderDetailsModel.linkedInProfile"
            title="" [ngModelOptions]="{standalone: true}" placeholder="Linkedin profile">
        </div>
      </div>

      <div class="col-md-6">
        <div>
          <mat-label class="required">Email ID</mat-label>
          <input type="email" class="form-control top-10" [(ngModel)]="founderDetailsModel.emailId" [ngModelOptions]="{standalone: true}"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" title="" #directorEmailId="ngModel" placeholder="Enter email" required>
          <div class="alert alert-danger" *ngIf="directorEmailId.errors">
            <div [hidden]="!directorEmailId.errors?.pattern">
              Invalid Email!
            </div>
            <div *ngIf="directorEmailId.errors?.required && emailValid">
              Email is required!
            </div>
          </div>
        </div>

        <div class="top-20">
          <mat-label>Contact Number</mat-label>
          <input type="number" class="form-control top-10" [(ngModel)]="founderDetailsModel.contactNumber" title="" 
          (keypress)="maxNumber($event.target.value)" placeholder="Contact number" [ngModelOptions]="{standalone: true}">
        </div>

        <div class="top-20">
          <mat-label>Date of Birth</mat-label>
          <div class="input-eyePlaceholder">
          <input matInput [matDatepicker]="dp" max="9999-12-31" class="form-control top-10" [(ngModel)]="founderDetailsModel.dateOfBirth"
            placeholder="DD-MMM-YYYY" title="" [ngModelOptions]="{standalone: true}">
            <mat-datepicker-toggle matSuffix [for]="dp">
              <mat-icon matDatepickerToggleIcon></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
          </div>
        </div>

        <div class="top-20">
          <mat-label class="required">Nationality</mat-label>
          <select class="form-control top-10 cursor-hand" [(ngModel)]='founderDetailsModel.nationality' #nationalityErr="ngModel"
            title="" [ngModelOptions]="{standalone: true}" required>
            <option [disabled]="founderDetailsModel && founderDetailsModel.nationality" value="" [selected]="true">
              Select Country</option>
            <option *ngFor='let countryLst of countryList; let k = index'>{{countryLst}}
            </option>
          </select>
          <div *ngIf="nationalityErr.errors">
            <span class="alert alert-danger" *ngIf="(typeDirErr.dirty || typeDirErr.touched) && !nationalityValid">
              Nationality is required!
            </span>
            <span class="alert alert-danger" *ngIf="typeDirErr.errors?.required  && nationalityValid">
              Nationality is required!!
            </span>
          </div>
        </div>

        <div class="top-20">
          <mat-label>Twitter handle</mat-label>
          <input type="text" class="form-control top-10" [(ngModel)]="founderDetailsModel.twitterHandle" [ngModelOptions]="{standalone: true}"
            title="" placeholder="Twitter profile">
        </div>
      </div>

      <div class="col-md-12 top-20 btm-20">
        <button mat-raised-button id="saveAndContinue" color="success" class="float-right left-20" [disableRipple]="true"
          (click)="saveAndExit()" ngbTooltip="Save and Exit">
          Save and Exit
        </button>
        <button mat-raised-button id="cancel" color="warn" class="float-right" [disableRipple]="true" (click)="close()"
          ngbTooltip="Cancel">
          Cancel
        </button>
      </div>
    </form>
  </div>

</div>