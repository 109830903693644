<div class="row" *ngIf="dataSource?.length > 0 && displayCols != undefined">
  <div class="col-md-12">
      <div class="btm-20">
          <div id="treeListGrid">
              <img class="table-action-toolbar" title="Expand All/Collapse All" (click)="expandAllItem()" 
                  src="../../assets/expandcollbox.svg" style="height: 24px; margin-top: -3px;" />
          </div>
          <dx-tree-list
              [dataSource]="dataSource"
              itemsExpr="shareholders"
              dataStructure="tree"
              keyExpr="name"
              [showRowLines]="true"
              [showBorders]="true"
              [columnAutoWidth]="true"
              [autoExpandAll]="expandAll"
              [wordWrapEnabled]="true"
              (onCellHoverChanged)="onCellHoverChanged($event)"
              >
              <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
              <!-- <dxo-header-filter [visible]="true" [allowSearch]="false"> </dxo-header-filter> -->

              <dxi-column [width]="200" dataField="name" caption="Shareholder" cellTemplate="namesTemplate" [visible]="displayCols.shareholder"></dxi-column>
              <div *dxTemplate="let cell of 'namesTemplate'">
               {{cell.data.name}}
              </div>
              
              <dxi-column dataField="preHoldingPercentage" caption="Pre Holding %" cellTemplate="postHoldingTemplate" [visible]="displayCols.preHoldingPercentage">
              </dxi-column>
              <div *dxTemplate="let cellData of 'postHoldingTemplate'">
                {{ cellData.value | percent:'2.2' }}
              </div>

              <dxi-column dataField="postHoldingPercentage" caption="Post Holding %" cellTemplate="postHoldingTemplate" [visible]="displayCols.postHoldingPercentage">
              </dxi-column>
              <div *dxTemplate="let cellData of 'postHoldingTemplate'">
                {{ cellData.value | percent:'2.2' }}
              </div>

              <dxi-column dataField="preHoldingShares" caption="Pre Holding Shares" cellTemplate="preHoldingShareTemplate" [visible]="displayCols.preHoldingShares">
              </dxi-column>
              <div *dxTemplate="let cellData of 'preHoldingShareTemplate'">
                  {{ getTotalValueTble(cellData.value) }}
              </div>

              <dxi-column dataField="postHoldingShares" caption="Post Holding Shares" cellTemplate="postHoldingShareTemplate" [visible]="displayCols.postHoldingShares">
                  <!-- <dxo-header-filter [dataSource]="warrantsFilter"></dxo-header-filter> -->
              </dxi-column>
              <div *dxTemplate="let cellData of 'postHoldingShareTemplate'">
                  {{ getTotalValueTble(cellData.value) }}
              </div>

              <dxi-column dataField="numberOfDilutedShares" caption="FDB Shares" cellTemplate="fdbTemplate" [visible]="displayCols.fdbShares"> 
                  <!-- <dxo-header-filter [dataSource]="fdbFilter"></dxo-header-filter> -->
              </dxi-column>
              <div *dxTemplate="let cellData of 'fdbTemplate'">
                  {{ getTotalValueTble(cellData.value) }}
              </div>
          </dx-tree-list>
          <dx-tooltip [(target)]="toolTipTarget" position="right" opacity="0.8">
              <div *dxTemplate="let data of 'content'">
                <div class="tooltip-content">{{toolTipText}}</div>
              </div>
          </dx-tooltip>
      </div>
  </div>  
  <div class="col-md-12 text-align-right notes-tbl btm-20">
      <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
  </div>
  <app-esop-plan-popup *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog" [(isPlan)]="isPlan"
          [(selectedSecurity)]="selectedSecurity" (visibleChange)="getEvent($event)"></app-esop-plan-popup>
</div>