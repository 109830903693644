/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../navbar/navbar.component.ngfactory";
import * as i6 from "../../navbar/navbar.component";
import * as i7 from "../../authentication.service";
import * as i8 from "ngx-ui-loader";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "ngx-toastr";
import * as i12 from "../../services/cache-provider.service";
import * as i13 from "../../services/navbar-reload";
import * as i14 from "../../services/permissions.service";
import * as i15 from "../../services/utilities.service";
import * as i16 from "../../services/broadast-token.service";
import * as i17 from "../../services/event-emitter.service";
import * as i18 from "../../footer-component/footer-component.component.ngfactory";
import * as i19 from "../../footer-component/footer-component.component";
import * as i20 from "./landing-page.component";
var styles_LandingPageComponent = [i0.styles];
var RenderType_LandingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingPageComponent, data: {} });
export { RenderType_LandingPageComponent as RenderType_LandingPageComponent };
function View_LandingPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loader-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File Uploading ... "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "progress-bar mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(4, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).value; var currVal_1 = i1.ɵnov(_v, 4).mode; var currVal_2 = i1.ɵnov(_v, 4)._isNoopAnimation; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LandingPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, [["body", "click"]], function (_v, en, $event) { var ad = true; if (("body:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).bodyClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NavbarComponent_0, i5.RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i6.NavbarComponent, [i7.AuthenticationService, i8.NgxUiLoaderService, i9.Router, i10.Location, i9.ActivatedRoute, i11.ToastrService, i12.CacheProviderService, i13.NavbarService, i14.PermissionsService, i15.UtilityService, i16.BROADCAST_SERVICE, i17.EventEmitterService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "main-body graybg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer-component", [], null, null, null, i18.View_FooterComponentComponent_0, i18.RenderType_FooterComponentComponent)), i1.ɵdid(8, 114688, null, 0, i19.FooterComponentComponent, [i15.UtilityService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.utilityService.showMatLoader; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
export function View_LandingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing-page", [], null, null, null, View_LandingPageComponent_0, RenderType_LandingPageComponent)), i1.ɵdid(1, 114688, null, 0, i20.LandingPageComponent, [i15.UtilityService, i17.EventEmitterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingPageComponentNgFactory = i1.ɵccf("app-landing-page", i20.LandingPageComponent, View_LandingPageComponent_Host_0, {}, {}, []);
export { LandingPageComponentNgFactory as LandingPageComponentNgFactory };
