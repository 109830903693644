var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { DxDataGridComponent } from "devextreme-angular";
import { DxTooltipComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { HistoricalRoundObject, CompanyCapitalDetails } from '../historical-round.model';
import { AuthenticationService } from 'src/app/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { NavbarService } from 'src/app/services/navbar-reload';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../../services/helper.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfirmDeleteComponent } from 'src/app/confirm-delete/confirm-delete.component';
import * as events from 'devextreme/events';
import * as _ from 'lodash';
import { CustomLocaleFormatterPipe } from 'src/app/pipes/locale-formatter.pipe';
var HistoricalRoundCreationComponent = /** @class */ (function () {
    function HistoricalRoundCreationComponent(toastr, authService, ngxLoader, activatedRoute, cacheService, router, utilityService, helperService, navbarService, dialog, customLocaleFormater, fb) {
        var _this = this;
        this.toastr = toastr;
        this.authService = authService;
        this.ngxLoader = ngxLoader;
        this.activatedRoute = activatedRoute;
        this.cacheService = cacheService;
        this.router = router;
        this.utilityService = utilityService;
        this.helperService = helperService;
        this.navbarService = navbarService;
        this.dialog = dialog;
        this.customLocaleFormater = customLocaleFormater;
        this.fb = fb;
        this.historicalRound = new HistoricalRoundObject();
        this.showPageSizeSelector = true;
        this.allowedPageSizes = [10, 25, 50, 100, 250, 'all'];
        this.registerNewCompany = false;
        this.isShowNote = false;
        this.roundNameDialog = {
            show: false,
            roundName: null,
            roundIdentifier: null,
            isPricedRound: null,
            roundConversionRatio: null,
            roundVotingRightsRatio: null,
            existingRoundDetails: null,
        };
        this.conversionDialog = {
            show: false,
            rowId: null,
            rowInfo: null,
            investorName: null,
            buyerName: null,
            investorsArray: null,
            conversionDetails: null
        };
        this.redemptionDialog = {
            show: false,
            rowId: null,
            rowInfo: null,
            investorName: null,
            buyerName: null,
            investorsArray: null,
            redemptionDetails: null
        };
        this.userDetails = null;
        this.usrCompanyCurrency = null;
        this.helper = "";
        this.helperTopPosition = null;
        this.selectedInvestorRow = null;
        this.showdigPrfle = false;
        this.investorProfile = {
            rowInfo: null,
            rowId: null,
            investorsArray: null
        };
        this.isSubmitted = false;
        this.securityTypeFilterPrim = [];
        this.parNumberFilterPrim = [];
        this.premiumNumberFilterPrim = [];
        this.noOfSharesFilterPrim = [];
        this.amountFilterPrim = [];
        this.roundNameFilterPrim = [];
        this.noOfSharesFilterSec = [];
        this.ppsFilterSec = [];
        this.amountFilterSec = [];
        this.securityTypeFilterSec = [];
        this.roundNameFilterSec = [];
        this.allotmentsFilter = [];
        this.transactionsFilter = [];
        this.dxCustomizeCsv = this.dxCustomizeCsv.bind(this);
        this.dxCustomizeDecimalCsv = this.dxCustomizeDecimalCsv.bind(this);
        this.dxCustomizeValueColumn = this.dxCustomizeValueColumn.bind(this);
        this.setRoundName = this.setRoundName.bind(this);
        this.addInvestorProfile = this.addInvestorProfile.bind(this);
        this.openRoundDialog = this.openRoundDialog.bind(this);
        this.openConversionDialog = this.openConversionDialog.bind(this);
        this.openRedemptionDialog = this.openRedemptionDialog.bind(this);
        this.clickEventsubscription = this.navbarService.getClickEvent().subscribe(function () {
            _this.ngOnInit();
        });
    }
    HistoricalRoundCreationComponent.prototype.initRoundsArray = function (toDefault) {
        if (toDefault) {
            this.rounds = ["Add Round Name", "Auto Fill", "Incorporation Round", "ESOP Exercise"];
            this.secondaryRounds = ["Auto Fill", "Incorporation Round", "ESOP Exercise"];
            return this.rounds, this.secondaryRounds;
        }
        else {
            return this.rounds = ["Add Round Name"];
        }
    };
    // isRedemptionDone(e){
    //   return e.row.data.redemptionDetails != null;
    // }
    // isConversionDone(e){   
    //   return e.row.data.conversionDetails != null; 
    // }
    HistoricalRoundCreationComponent.prototype.displayAsTotal = function () {
        return "Total";
    };
    HistoricalRoundCreationComponent.prototype.calculateAmountPAR = function (newData, value, currRow) {
        if (currRow.securityType != "Warrant")
            newData.amount = +(currRow.numberOfShares * (value + currRow.premium)).toFixed(2);
        newData.par = value;
    };
    HistoricalRoundCreationComponent.prototype.calculateAmountPremium = function (newData, value, currRow) {
        if (currRow.securityType != "Warrant")
            newData.amount = +(currRow.numberOfShares * (value + currRow.par)).toFixed(2);
        newData.premium = value;
    };
    HistoricalRoundCreationComponent.prototype.calculateAmountNoOfShares = function (newData, value, currRow) {
        if (currRow.securityType != "Warrant")
            newData.amount = +((currRow.par + currRow.premium) * value).toFixed(2);
        newData.numberOfShares = value;
    };
    HistoricalRoundCreationComponent.prototype.calculateAmount4 = function (newData, value, currRow) {
        newData.amount = +(currRow.numberOfShares * value).toFixed(2);
        newData.pricePerShare = value;
    };
    HistoricalRoundCreationComponent.prototype.calculateAmount5 = function (newData, value, currRow) {
        newData.amount = +(currRow.pricePerShare * value).toFixed(2);
        newData.numberOfShares = value;
    };
    HistoricalRoundCreationComponent.prototype.getInvestorProfile = function (e) {
        this.showdigPrfle = e.showMdl;
        document.getElementById("overlay").style.display = "none";
        console.log('investor profile after popup: ', this.investorProfile);
        if (this.selectedInvestorRow.buyer) {
            var selectedInvestorRow = this.selectedInvestorRow;
            var selectedRowKey = this.secondariesDataGrid.instance.getSelectedRowKeys();
            this.secondariesDataGrid.instance.deselectRows(selectedRowKey);
            for (var historicIndex = 0; historicIndex < this.historicalRound.investors.length; historicIndex++) {
                var investor = this.historicalRound.investors[historicIndex];
                if (investor != null && investor.investorName == selectedInvestorRow.buyer) {
                    var buyer = investor;
                    buyer['investorProfile'] = this.investorProfile['rowInfo']['investorProfile'];
                    this.historicalRound.investors[historicIndex] = buyer;
                    break;
                }
            }
        }
        else {
            var selectedRowKey = this.primaryDataGrid.instance.getSelectedRowKeys();
            this.primaryDataGrid.instance.deselectRows(selectedRowKey);
        }
    };
    HistoricalRoundCreationComponent.prototype.getRoundName = function (isRoundNameSaved) {
        if (isRoundNameSaved) {
            this.roundNameSubscriber.next(this.roundNameDialog);
            this.updateRoundsArray(this.roundNameDialog.roundName);
        }
        else {
            var visibleRows = this.primaryDataGrid.instance.getVisibleRows();
            if (visibleRows.length > 0) {
                var editRow = _.find(visibleRows, { isEditing: true });
                if (editRow) {
                    editRow['cells'][10].value = undefined;
                }
            }
        }
        this.roundNameDialog.show = false;
        this.roundNameDialog.roundName = null;
        this.roundNameDialog.isPricedRound = null;
        this.roundNameDialog.roundConversionRatio = null;
        this.roundNameDialog.roundVotingRightsRatio = null;
        document.getElementById("overlay").style.display = "none";
    };
    HistoricalRoundCreationComponent.prototype.setRoundName = function (newData, value, currRow) {
        var _this = this;
        if (value == "Add Round Name") {
            var promise = new Promise(function (resolve, reject) {
                if (currRow) {
                    _this.roundNameDialog.roundName = currRow.roundName;
                    _this.roundNameDialog.roundIdentifier = currRow.roundIdentifier;
                    _this.roundNameDialog.isPricedRound = currRow.isPricedRound;
                    _this.roundNameDialog.roundConversionRatio = currRow.roundConversionRatio;
                    _this.roundNameDialog.roundVotingRightsRatio = currRow.roundVotingRightsRatio;
                }
                _this.roundNameDialog.existingRoundDetails = _this.historicalRound.historicalRoundPopupDetails;
                _this.roundNameDialog.show = true;
                document.getElementById("overlay").style.display = "block";
                _this.roundName.subscribe(function (res) {
                    var obj = __assign({}, res);
                    resolve(obj);
                });
            });
            return promise.then(function (res) {
                if (newData) {
                    newData.roundName = res.roundName;
                    newData.roundIdentifier = res.roundIdentifier;
                    newData.isPricedRound = res.isPricedRound;
                    newData.roundConversionRatio = res.roundConversionRatio;
                    newData.roundVotingRightsRatio = res.roundVotingRightsRatio;
                    newData.updateConversionRatios = _.find(res.existingRoundDetails, { roundName: res.roundName }).updateConversionRatios;
                    if (res.isPricedRound == false) {
                        if (currRow['buyer']) {
                            if (currRow['transactions'] &&
                                currRow['transactions'].some(function (element) { return element['name'] === 'Non Priced Round'; })) { }
                            else {
                                if (currRow['transactions']) {
                                    newData['transactions'] = currRow['transactions'];
                                    newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' });
                                }
                                else {
                                    newData['transactions'] = [];
                                    newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' });
                                }
                            }
                        }
                        else {
                            if (currRow['allotment'] &&
                                currRow['allotment'].some(function (element) { return element['name'] === 'Non Priced Round'; })) { }
                            else {
                                if (currRow['allotment']) {
                                    newData['allotment'] = currRow['allotment'];
                                    newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' });
                                }
                                else {
                                    newData['allotment'] = [];
                                    newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' });
                                }
                            }
                        }
                    }
                }
                if (_.find(_this.historicalRound.investors, { roundName: res.roundName })) {
                    _this.historicalRound.investors.forEach(function (element) {
                        if (element['roundName'] == res.roundName) {
                            element['roundIdentifier'] = res.roundIdentifier;
                            element['isPricedRound'] = res.isPricedRound;
                            element['roundConversionRatio'] = res.roundConversionRatio;
                            element['roundVotingRightsRatio'] = res.roundVotingRightsRatio;
                            element['updateConversionRatios'] = _.find(res.existingRoundDetails, { roundName: res.roundName }).updateConversionRatios;
                        }
                    });
                }
                _this.historicalRound.historicalRoundPopupDetails = res.existingRoundDetails;
                _this.roundNameSubscriber.unsubscribe();
            });
        }
        else {
            newData.roundName = value;
            if (value != 'Auto Fill') {
                var roundSelected = _.find(this.historicalRound.historicalRoundPopupDetails, { roundName: value });
                newData.roundIdentifier = roundSelected.roundIdentifier;
                newData.isPricedRound = roundSelected.isPricedRound;
                newData.roundConversionRatio = roundSelected.roundConversionRatio;
                newData.roundVotingRightsRatio = roundSelected.roundVotingRightsRatio;
                newData.updateConversionRatios = roundSelected.updateConversionRatios;
                if (roundSelected.isPricedRound == false) {
                    if (currRow['buyer']) {
                        if (currRow['transactions'] &&
                            currRow['transactions'].some(function (element) { return element['name'] === 'Non Priced Round'; })) { }
                        else {
                            if (currRow['transactions']) {
                                newData['transactions'] = currRow['transactions'];
                                newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' });
                            }
                            else {
                                newData['transactions'] = [];
                                newData['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' });
                            }
                        }
                    }
                    else {
                        if (currRow['allotment'] &&
                            currRow['allotment'].some(function (element) { return element['name'] === 'Non Priced Round'; })) { }
                        else {
                            if (currRow['allotment']) {
                                newData['allotment'] = currRow['allotment'];
                                newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' });
                            }
                            else {
                                newData['allotment'] = [];
                                newData['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' });
                            }
                        }
                    }
                }
            }
        }
    };
    HistoricalRoundCreationComponent.prototype.openRoundDialog = function () {
        this.setRoundName(null, "Add Round Name", null);
    };
    HistoricalRoundCreationComponent.prototype.getConversionData = function (e) {
        document.getElementById("overlay").style.display = "none";
        if (this.selectedInvestorRow.buyer) {
            var selectedRowKey = this.secondariesDataGrid.instance.getSelectedRowKeys();
            this.secondariesDataGrid.instance.deselectRows(selectedRowKey);
        }
        else {
            var selectedRowKey = this.primaryDataGrid.instance.getSelectedRowKeys();
            this.primaryDataGrid.instance.deselectRows(selectedRowKey);
        }
        this.selectedInvestorRow = null;
        this.conversionDialog.show = false;
    };
    HistoricalRoundCreationComponent.prototype.getRedemptionData = function (e) {
        document.getElementById("overlay").style.display = "none";
        if (this.selectedInvestorRow.buyer) {
            var selectedRowKey = this.secondariesDataGrid.instance.getSelectedRowKeys();
            this.secondariesDataGrid.instance.deselectRows(selectedRowKey);
        }
        else {
            var selectedRowKey = this.primaryDataGrid.instance.getSelectedRowKeys();
            this.primaryDataGrid.instance.deselectRows(selectedRowKey);
        }
        this.selectedInvestorRow = null;
        this.redemptionDialog.show = false;
    };
    HistoricalRoundCreationComponent.prototype.addInvestorProfile = function () {
        this.showdigPrfle = true;
        if (this.selectedInvestorRow.buyer) {
            if (_.find(this.historicalRound.investors, { investorName: this.selectedInvestorRow.buyer })) {
                var buyer = _.find(this.historicalRound.investors, { investorName: this.selectedInvestorRow.buyer });
                this.selectedInvestorRow['investorProfile'] = buyer['investorProfile'];
            }
            this.investorProfile.rowInfo = this.selectedInvestorRow;
            this.investorProfile.investorsArray = this.historicalRound.secondaries;
        }
        else {
            this.investorProfile.rowInfo = this.selectedInvestorRow;
            this.investorProfile.investorsArray = this.historicalRound.investors;
        }
        console.log('investor profile before popup: ', this.investorProfile);
        document.getElementById("overlay").style.display = "block";
    };
    HistoricalRoundCreationComponent.prototype.openConversionDialog = function () {
        this.conversionDialog.show = true;
        this.conversionDialog.rowId = this.selectedInvestorRow.__KEY__ != null ? this.selectedInvestorRow.__KEY__ : this.selectedInvestorRow.uuid;
        this.conversionDialog.rowInfo = this.selectedInvestorRow;
        this.conversionDialog.investorName = this.selectedInvestorRow.investorName;
        this.conversionDialog.buyerName = this.selectedInvestorRow.buyer;
        this.conversionDialog.investorsArray = this.historicalRound.investors;
        this.conversionDialog.conversionDetails = this.selectedInvestorRow.conversionDetails;
        document.getElementById("overlay").style.display = "block";
    };
    HistoricalRoundCreationComponent.prototype.openRedemptionDialog = function () {
        this.redemptionDialog.show = true;
        this.redemptionDialog.rowId = this.selectedInvestorRow.__KEY__ != null ? this.selectedInvestorRow.__KEY__ : this.selectedInvestorRow.uuid;
        this.redemptionDialog.rowInfo = this.selectedInvestorRow;
        this.redemptionDialog.investorName = this.selectedInvestorRow.investorName;
        this.redemptionDialog.buyerName = this.selectedInvestorRow.buyer;
        this.redemptionDialog.investorsArray = this.historicalRound.investors;
        this.redemptionDialog.redemptionDetails = this.selectedInvestorRow.redemptionDetails;
        document.getElementById("overlay").style.display = "block";
    };
    HistoricalRoundCreationComponent.prototype.isConversionBtnVisible = function (e) {
        return !e.row.isEditing;
    };
    HistoricalRoundCreationComponent.prototype.isRedemptionBtnVisible = function (e) {
        return !e.row.isEditing;
    };
    HistoricalRoundCreationComponent.prototype.addNewInvestor = function (e) {
        var textTrimmed = e.text.trim();
        if (textTrimmed == "" || this.historicalRound.investorsDropdown.indexOf(e.text.trim()) >= 0) {
            e.customItem = null;
            return;
        }
        else if (textTrimmed.toLocaleLowerCase() == "company") {
            return e.customItem = e.text;
        }
        else {
            this.historicalRound.investorsDropdown.push(e.text);
            return e.text;
        }
    };
    HistoricalRoundCreationComponent.prototype.logEvent = function (eventName, event) {
        switch (eventName) {
            case 'Initialized':
                event.component.columnOption("par", {
                    editorOptions: {
                        format: "#,##,##0.00"
                    }
                });
                event.component.columnOption("premium", {
                    editorOptions: {
                        format: "#,##,##0.00"
                    }
                });
                event.component.columnOption("pricePerShare", {
                    editorOptions: {
                        format: "#,##,##0.00"
                    }
                });
                event.component.columnOption("numberOfShares", {
                    editorOptions: {
                        format: "#,##,##0"
                    }
                });
                event.component.columnOption("amount", {
                    editorOptions: {
                        format: "#,##,##0.00"
                    }
                });
                event.component.columnOption("splitNumber", {
                    editorOptions: {
                        format: "1: tyy",
                        mask: "1: tyy",
                        maskRules: {
                            't': /[1-9]/,
                            'y': /[0-9]/
                        }
                    }
                });
                break;
            case 'ToolbarPreparing':
                var toolbarItems = event.toolbarOptions.items;
                toolbarItems.unshift({
                    widget: "dxButton",
                    cssClass: "add-round-btn",
                    options: {
                        icon: "repeat",
                        hint: "Add New Round",
                        onClick: (function (e) {
                            this.openRoundDialog();
                        }).bind(this)
                    },
                    location: "after"
                }, {
                    widget: "dxButton",
                    cssClass: "redemption-btn",
                    options: {
                        icon: "money",
                        hint: "Redemption",
                        onClick: (function (e) {
                            this.openRedemptionDialog();
                        }).bind(this)
                    },
                    location: "after"
                }, {
                    widget: "dxButton",
                    cssClass: "conversion-btn",
                    options: {
                        icon: "repeat",
                        hint: "Conversion",
                        onClick: (function (e) {
                            this.openConversionDialog();
                        }).bind(this)
                    },
                    location: "after"
                }, {
                    widget: "dxButton",
                    cssClass: "investor-profile-btn",
                    options: {
                        icon: "repeat",
                        hint: "Investor Profile",
                        onClick: (function (e) {
                            this.addInvestorProfile();
                        }).bind(this)
                    },
                    location: "after"
                });
                for (var item = 0; item < toolbarItems.length; item++) {
                    if (toolbarItems[item].name == "addRowButton") {
                        var addRowBtn = toolbarItems[item];
                        addRowBtn.cssClass = "add-new-investor-row";
                        toolbarItems.splice(item, 1);
                        toolbarItems.unshift(addRowBtn);
                        break;
                    }
                }
                break;
            case 'CellPrepared':
                if (event.rowType === "data" && event.column.command === "edit") {
                    var isEditing = event.row.isEditing, cancelLink = event.cellElement.querySelector(".dx-link-cancel"), saveLink = event.cellElement.querySelector(".dx-link-save");
                    if (isEditing) {
                        events.on(cancelLink, 'dxclick', function (args) {
                            $(".add-round-btn").css({ "display": "table-cell" });
                        });
                        events.on(saveLink, 'dxclick', function (args) {
                            $(".add-round-btn").css({ "display": "table-cell" });
                        });
                    }
                }
                break;
            case 'InitNewRow':
                $(".add-round-btn").hide();
                break;
            case 'EditingStart':
                $(".add-round-btn").hide();
                break;
            case 'EditCanceled':
                $(".add-round-btn").css({ "display": "table-cell" });
                break;
            case 'Saved':
                $(".add-round-btn").css({ "display": "table-cell" });
                break;
            case 'EditorPreparing':
                if (event.parentType = "dataRow" && event.dataField == "roundName") {
                    event.editorOptions.itemTemplate = function (itemData, itemIndex, itemElement) {
                        $('<div>')
                            .appendTo(itemElement)
                            .text(itemData)
                            .attr('title', itemData);
                    };
                }
                break;
            case 'RowInserted':
                // this.initRoundsArray(true);
                $(".add-round-btn").css({ "display": "table-cell" });
                this.historicalRound.investors.find(function (investor, idx) {
                    if (investor.investorName == event.data.investorName.trim()) {
                        return event.data.investorProfile = investor.investorProfile;
                    }
                });
                event.component.option("focusedRowKey", event.key);
                this.updateRoundsArray();
                break;
            case 'RowUpdated':
                // this.initRoundsArray(true);
                this.updateRoundsArray();
                break;
            case 'EditCanceled':
                // this.initRoundsArray(true);
                this.updateRoundsArray();
                break;
            case 'SelectionChanged':
                if (event.selectedRowsData.length == 1) {
                    this.selectedInvestorRow = event.selectedRowsData[0];
                    $(".conversion-btn, .redemption-btn, .investor-profile-btn").css({ "display": "table-cell" });
                }
                else {
                    this.selectedInvestorRow = null;
                    $(".conversion-btn, .redemption-btn, .investor-profile-btn").hide();
                }
                break;
            case 'CellHoverChanged':
                if ((event.rowType === "data" && event.row.isEditing == false) && (event.column.dataField === "investorName" || event.column.dataField === "securityType" || event.column.dataField === "date" || event.column.dataField === "roundName") && event.eventType === "mouseover") {
                    this.toolTipText = event.text;
                    this.toolTipTarget = event.cellElement;
                    this.tooltip.instance.show();
                }
                else if ((event.rowType === "data" && event.row.isEditing == false) && (event.column.dataField === "premium" || event.column.dataField === "pricePerShare" || event.column.dataField === "amount" || event.column.dataField === "numberOfShares" || event.column.dataField === "par") && event.eventType === "mouseover") {
                    if (event.displayValue == null) {
                        return this.tooltip.instance.hide();
                    }
                    else if (event.displayValue === 0) {
                        this.toolTipText = event.text;
                    }
                    else {
                        this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, event.displayValue);
                    }
                    this.toolTipTarget = event.cellElement;
                    this.tooltip.instance.show();
                }
                else if (event.rowType === "totalFooter" && (event.column.dataField === "numberOfShares" || event.column.dataField === "amount") && event.eventType === "mouseover") {
                    if (event.displayValue === 0) {
                        this.toolTipText = event.summaryItems[0].value;
                    }
                    else {
                        this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, this.toolTipText = event.summaryItems[0].value);
                    }
                    this.toolTipTarget = event.cellElement;
                    this.tooltip.instance.show();
                }
                else if ((event.rowType === "data" || event.rowType === "totalFooter") && event.eventType === "mouseout") {
                    this.tooltip.instance.hide();
                }
            case 'RowValidating':
                var updatedRowData = __assign({}, event.oldData, event.newData);
                if (updatedRowData.roundName == "Incorporation Round" && updatedRowData.securityType != "Equity") {
                    event.isValid = false;
                    this.toastr.error("Security should be 'Equity' for Incorporation Round.", "Failure!", { tapToDismiss: true, disableTimeOut: true });
                }
                else if ((updatedRowData.securityType == "CCD" || updatedRowData.securityType == "Note" || updatedRowData.securityType == "NCDS") && updatedRowData.par == null) {
                    event.isValid = false;
                    this.toastr.error("PAR column cannot be empty for the selected Security.", "Failure!", { tapToDismiss: true, disableTimeOut: true });
                }
                if (updatedRowData.securityType == "Warrant" && (updatedRowData.par != null && updatedRowData.amount != null) && updatedRowData.par < updatedRowData.amount) {
                    event.isValid = false;
                    this.toastr.error("PAR value cannot be lesser than the amount invested", "Failure!", { tapToDismiss: true, disableTimeOut: true });
                }
        }
    };
    HistoricalRoundCreationComponent.prototype.onPrimContentReady = function (e) {
        $(e.element).find("#primRowText").remove();
        $(e.element).find(".dx-page-sizes")
            .before("<div id='primRowText' style='float:left; margin: 7px 7px 7px -20px; font-size: 12px;'>Rows per page: </div>");
    };
    HistoricalRoundCreationComponent.prototype.onSecContentReady = function (e) {
        $(e.element).find("#secRowText").remove();
        $(e.element).find(".dx-page-sizes")
            .before("<div id='secRowText' style='float:left; margin: 7px 7px 7px -20px; font-size: 12px;'>Rows per page: </div>");
    };
    HistoricalRoundCreationComponent.prototype.logSecondariesEvent = function (eventName, event) {
        switch (eventName) {
            case 'ToolbarPreparing':
                var toolbarItems = event.toolbarOptions.items;
                toolbarItems.unshift({
                    widget: "dxButton",
                    cssClass: "sec-redemption-btn",
                    options: {
                        icon: "money",
                        hint: "Redemption",
                        onClick: (function (e) {
                            this.openRedemptionDialog();
                        }).bind(this)
                    },
                    location: "after"
                }, {
                    widget: "dxButton",
                    cssClass: "sec-conversion-btn",
                    options: {
                        icon: "repeat",
                        hint: "Conversion",
                        onClick: (function (e) {
                            this.openConversionDialog();
                        }).bind(this)
                    },
                    location: "after"
                }, {
                    widget: "dxButton",
                    cssClass: "sec-investor-profile-btn",
                    options: {
                        icon: "repeat",
                        hint: "Investor Profile",
                        onClick: (function (e) {
                            this.addInvestorProfile();
                        }).bind(this)
                    },
                    location: "after"
                });
                for (var item = 0; item < toolbarItems.length; item++) {
                    if (toolbarItems[item].name == "addRowButton") {
                        var addRowBtn = toolbarItems[item];
                        addRowBtn.cssClass = "add-new-investor-row";
                        toolbarItems.splice(item, 1);
                        toolbarItems.unshift(addRowBtn);
                        break;
                    }
                }
                break;
            case 'RowInserted':
                // this.initRoundsArray(true);
                this.historicalRound.investors.find(function (investor, idx) {
                    if (investor.investorName == event.data.investorName.trim()) {
                        return event.data.investorProfile = investor.investorProfile;
                    }
                });
                event.component.option("focusedRowKey", event.key);
                this.updateRoundsArray();
                break;
            case 'SelectionChanged':
                if (event.selectedRowsData.length == 1) {
                    this.selectedInvestorRow = event.selectedRowsData[0];
                    $(".sec-conversion-btn, .sec-redemption-btn, .sec-investor-profile-btn").css({ "display": "table-cell" });
                }
                else {
                    this.selectedInvestorRow = null;
                    $(".sec-conversion-btn, .sec-redemption-btn, .sec-investor-profile-btn").hide();
                }
                break;
        }
    };
    HistoricalRoundCreationComponent.prototype.logEsopEvent = function (eventName, event) {
        switch (eventName) {
            case 'Initialized':
                event.component.columnOption("poolSize", {
                    editorOptions: {
                        format: "#,##,##0"
                    }
                });
                break;
            case 'CellHoverChanged':
                if ((event.rowType === "data" && event.row.isEditing == false) && (event.column.dataField === "date" || event.column.dataField == "splitNumber") && event.eventType === "mouseover") {
                    this.toolTipText = event.text;
                    this.toolTipTarget = event.cellElement;
                    this.tooltip.instance.show();
                }
                else if ((event.rowType === "data" && event.row.isEditing == false) && (event.column.dataField === "poolSize") && event.eventType === "mouseover") {
                    if (event.displayValue == null) {
                        return this.tooltip.instance.hide();
                    }
                    else if (event.displayValue === 0) {
                        this.toolTipText = event.text;
                    }
                    else {
                        this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, event.displayValue);
                    }
                    this.toolTipTarget = event.cellElement;
                    this.tooltip.instance.show();
                }
        }
    };
    /** Show Contextual help **/
    HistoricalRoundCreationComponent.prototype.showHelp = function (section) {
        if (section == this.helper) {
            return;
        }
        var targetElement;
        targetElement = document.getElementById(section);
        if (targetElement != null) {
            this.helperTopPosition = targetElement.offsetTop;
        }
        this.helpSections = this.helperService.getHelpContent("HistoricRoundComponent", section);
        this.helper = section;
    };
    HistoricalRoundCreationComponent.prototype.hideHelp = function () {
        this.helper = "";
    };
    /** Show Contextual help **/
    HistoricalRoundCreationComponent.prototype.datePickerChanged = function (cellData, event) {
        var dt = new Date(event.target.value._d);
        cellData.setValue(dt.toISOString().replace("Z", "+0000"));
    };
    HistoricalRoundCreationComponent.prototype.addInvestor = function () {
        if (this.primaryDataGrid.instance.hasEditData()) {
            this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
        }
        else {
            this.primaryDataGrid.instance.addRow();
        }
    };
    HistoricalRoundCreationComponent.prototype.addPoolAllocation = function () {
        if (this.esopGrid.instance.hasEditData()) {
            this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
        }
        else {
            this.esopGrid.instance.addRow();
        }
    };
    HistoricalRoundCreationComponent.prototype.addSplits = function () {
        if (this.splitsGrid.instance.hasEditData()) {
            this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
        }
        else {
            this.splitsGrid.instance.addRow();
        }
    };
    HistoricalRoundCreationComponent.prototype.addSecondaries = function () {
        if (this.secondariesDataGrid.instance.hasEditData()) {
            this.toastr.error('Please save the current row!', "Failure!", { tapToDismiss: true, disableTimeOut: true });
        }
        else {
            this.secondariesDataGrid.instance.addRow();
        }
    };
    HistoricalRoundCreationComponent.prototype.onKeydownEvent = function (e) {
        this.utilityService.restrictMinus(e);
    };
    HistoricalRoundCreationComponent.prototype.csvVal = function (value, e) {
        var exitVal;
        if (e) {
            // store current positions in variables
            var start = e.target.selectionStart, end = e.target.selectionEnd;
        }
        var val = value;
        exitVal = this.utilityService.inputCSVformatCurrency(this.usrCompanyCurrency, val);
        if (e) {
            if (e.target.value) {
                var CSVLength = exitVal.length;
                var valueLength = e.target.value.length;
                if (CSVLength > valueLength) {
                    e.target.value = exitVal;
                    var difference = CSVLength - valueLength;
                    start = start + difference;
                    end = end + difference;
                }
                else if (CSVLength < valueLength) {
                    e.target.value = exitVal;
                    if (start != 0 || end != 0) {
                        var difference = valueLength - CSVLength;
                        start = start - difference;
                        end = end - difference;
                    }
                    else {
                        start = start;
                        end = end;
                    }
                }
                else {
                    e.target.value = exitVal;
                }
            }
            // restore from variables...
            e.target.setSelectionRange(start, end);
            this.setTotalValues(e);
        }
    };
    HistoricalRoundCreationComponent.prototype.showBonusShare = function (cellData) {
        if (cellData && cellData.data['premium'] == 0 && cellData.data['par'] == 0) {
            return true;
        }
        else {
            return false;
        }
    };
    HistoricalRoundCreationComponent.prototype.showPartlyPaidShare = function (cellData) {
        if (cellData && (cellData.data['premium'] > 0 || cellData.data['par'] > 0)) {
            var pricePerShare = cellData.data['premium'] + cellData.data['par'];
            var calculatedInvAmt = pricePerShare * cellData.data['numberOfShares'];
            var differenceAmt = calculatedInvAmt - cellData.data['amount'];
            if (differenceAmt >= pricePerShare) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    HistoricalRoundCreationComponent.prototype.getTotalValueTble = function (value) {
        if (value > 0) {
            if (value.toString().length > 5) {
                return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
            }
            else {
                return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
            }
        }
        else {
            return 0;
        }
    };
    HistoricalRoundCreationComponent.prototype.dxCustomizeCsv = function (cellValue) {
        var value = this.getTotalValueTble(cellValue.value);
        if (value === 0) {
            return '0';
        }
        else {
            return value;
        }
    };
    HistoricalRoundCreationComponent.prototype.dxCustomizeDecimalCsv = function (cellInfo) {
        if (cellInfo.value == null) {
            return;
        }
        var amount = cellInfo.value;
        var currency = this.usrCompanyCurrency;
        var y = amount.toString();
        y = Math.floor(y);
        if (currency && amount && y.toString().length > 5) {
            if (currency === "INR - ₹") {
                return Math.abs(Number(amount)) >= 1.0e7
                    ? (Math.abs(Number(amount)) / 1.0e7).toFixed(2) + "Cr"
                    : // Six Zeroes for Millions
                        Math.abs(Number(amount)) >= 1.0e5
                            ? (Math.abs(Number(amount)) / 1.0e5).toFixed(2) + "L"
                            : // Three Zeroes for Thousands
                                Math.trunc(Math.abs(Number(amount)));
            }
            else {
                return Math.abs(Number(amount)) >= 1.0e+9
                    ? (Math.abs(Number(amount)) / 1.0e+9).toFixed(2) + "B"
                    // Six Zeroes for Millions
                    : Math.abs(Number(amount)) > 1.0e+6
                        ? (Math.abs(Number(amount)) / 1.0e+6).toFixed(2) + "M"
                        : Math.abs(Number(amount)) > 1.0e+3
                            ? (Math.abs(Number(amount)) / 1.0e+3).toFixed(2) + "K"
                            : Math.trunc(Math.abs(Number(amount)));
            }
        }
        else {
            if (currency === "INR - ₹") {
                var x = amount.toString();
                var afterPoint = '';
                if (x.indexOf('.') > 0)
                    afterPoint = x.substring(x.indexOf('.'), x.length);
                x = Math.floor(x);
                x = x.toString();
                var lastThree = x.substring(x.length - 3);
                var otherNumbers = x.substring(0, x.length - 3);
                if (otherNumbers != '')
                    lastThree = ',' + lastThree;
                var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint.substring(0, 3);
                return res;
            }
            else {
                var x = amount.toString();
                var afterPoint = '';
                if (x.indexOf('.') > 0)
                    afterPoint = x.substring(x.indexOf('.'), x.length);
                x = Math.floor(x);
                x = x.toString();
                var lastThree = x.substring(x.length - 3);
                var otherNumbers = x.substring(0, x.length - 3);
                if (otherNumbers != '')
                    lastThree = ',' + lastThree;
                var res = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree + afterPoint.substring(0, 3);
                return res;
            }
        }
        // return cellInfo.valueText;
    };
    HistoricalRoundCreationComponent.prototype.dxCustomizeValueColumn = function (cellValue) {
        if (cellValue.value == null) {
            return null;
        }
        else {
            var value = this.getTotalValueTble(cellValue.value);
            if (value === 0) {
                return '0';
            }
            else {
                return value;
            }
        }
    };
    HistoricalRoundCreationComponent.prototype.updateRoundsArray = function (roundName) {
        var _this = this;
        if (roundName != null && !this.rounds.includes(roundName)) {
            this.rounds.push(roundName);
            this.secondaryRounds.push(roundName);
            return this.rounds, this.secondaryRounds;
        }
        // this.initRoundsArray(true);
        this.historicalRound.investors.forEach(function (item, i) {
            if (!_this.rounds.includes(item.roundName)) {
                _this.rounds.push(item.roundName);
                _this.secondaryRounds.push(item.roundName);
            }
        });
    };
    HistoricalRoundCreationComponent.prototype.discardHistoricalRound = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            data: { header: "Confirm Discard?", text: "Are you sure you want to Discard ?" },
            width: "400px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result === "confirm") {
                _this.ngxLoader.start();
                var userDetails = JSON.parse(_this.cacheService.get("userDetails"));
                if (userDetails != null) {
                    _this.ngxLoader.start();
                    _this.authService.discardHistoricalRound(userDetails.company.id).subscribe(function (response) {
                        if (response["resultCode"]) {
                            localStorage.removeItem('companyId');
                            localStorage.removeItem('companyName');
                            _this.authService.getUserDetails().subscribe(function (res) {
                                _this.cacheService.set("userDetails", JSON.stringify(res));
                                _this.navbarService.sendClickEvent();
                                setTimeout(function () {
                                    _this.ngxLoader.stop();
                                    _this.router.navigate(['/landing', 'onboarding', { newComp: true }]);
                                }, 300);
                            }, function (e) {
                                _this.ngxLoader.stop();
                                _this.toastr.error(e.error.reason, "Error", { tapToDismiss: true, disableTimeOut: true });
                            });
                            _this.toastr.success("Historical Round Successfully Discarded.", "Success!", { tapToDismiss: true, disableTimeOut: true });
                        }
                        else {
                            _this.ngxLoader.stop();
                        }
                    }, function (e) {
                        _this.ngxLoader.stop();
                        _this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true });
                    });
                }
                _this.ngxLoader.stop();
            }
        });
    };
    HistoricalRoundCreationComponent.prototype.fileChangeEvent = function (event) {
        var _this = this;
        this.ngxLoader.start();
        var fd = new FormData();
        fd.append('file', event.target.files[0]);
        fd.append('isHistoricalScreen', "true");
        this.authService.importHistoricalRoundDetails(fd).subscribe(function (response) {
            _this.ngxLoader.stop();
            $("#uploadImage").val(null);
            _this.getHistoricalRoundDetails();
            _this.toastr.success("Successfully uploaded.", "Success;", { tapToDismiss: true, disableTimeOut: true });
        }, function (e) {
            $("#uploadImage").val(null);
            _this.ngxLoader.stop();
            _this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true });
        });
    };
    HistoricalRoundCreationComponent.prototype.saveForLater = function () {
        var _this = this;
        this.ngxLoader.start();
        var cd = this.capitalSettingsForm.value;
        if (this.historicalRound.companyCapitalDetails == null) {
            this.historicalRound.companyCapitalDetails = new CompanyCapitalDetails();
        }
        this.historicalRound.companyCapitalDetails.equityAuthorizedShareCapital = cd.equityAuthorizedShareCapital.toString().replace(/,/g, "") / 1;
        this.historicalRound.companyCapitalDetails.preferenceAuthorizedShareCapital = cd.preferenceAuthorizedShareCapital.toString().replace(/,/g, "") / 1;
        this.historicalRound.companyCapitalDetails.equityPaidUpShareCapital = cd.equityPaidUpShareCapital.toString().replace(/,/g, "") / 1;
        this.historicalRound.companyCapitalDetails.preferencePaidUpShareCapital = cd.preferencePaidUpShareCapital.toString().replace(/,/g, "") / 1;
        this.historicalRound.splitDetails.forEach(function (item) {
            item.splitNumber = parseInt(item.splitNumber.toString(), 10);
        });
        this.authService.saveHistoricalRoundForLater(this.historicalRound).subscribe(function (response) {
            _this.ngxLoader.stop();
            _this.toastr.success("Successfully saved.", "Success!", { tapToDismiss: true, disableTimeOut: true });
        }, function (e) {
            _this.ngxLoader.stop();
            _this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true });
        });
    };
    HistoricalRoundCreationComponent.prototype.saveHistoricalRoundDetails = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.capitalSettingsForm.valid) {
            if (this.historicalRound.investors.length == 0) {
                return this.toastr.error("Please fill investor details!", "Error", { tapToDismiss: true, disableTimeOut: true });
            }
            this.isSubmitted = false;
            var cd = this.capitalSettingsForm.value;
            if (this.historicalRound.companyCapitalDetails == null) {
                this.historicalRound.companyCapitalDetails = new CompanyCapitalDetails();
            }
            this.historicalRound.companyCapitalDetails.equityAuthorizedShareCapital = cd.equityAuthorizedShareCapital.toString().replace(/,/g, "") / 1;
            this.historicalRound.companyCapitalDetails.preferenceAuthorizedShareCapital = cd.preferenceAuthorizedShareCapital.toString().replace(/,/g, "") / 1;
            this.historicalRound.companyCapitalDetails.equityPaidUpShareCapital = cd.equityPaidUpShareCapital.toString().replace(/,/g, "") / 1;
            this.historicalRound.companyCapitalDetails.preferencePaidUpShareCapital = cd.preferencePaidUpShareCapital.toString().replace(/,/g, "") / 1;
            this.historicalRound.splitDetails.forEach(function (item) {
                item.splitNumber = parseInt(item.splitNumber.toString(), 10);
            });
            this.ngxLoader.start();
            this.authService.historicalRoundDetails(this.historicalRound).subscribe(function (response) {
                _this.ngxLoader.stop();
                _this.router.navigate(['/landing', 'onboarding', 'historical-round-summary']);
            }, function (e) {
                _this.ngxLoader.stop();
                _this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true });
            });
        }
    };
    HistoricalRoundCreationComponent.prototype.getHistoricalRoundDetails = function () {
        var _this = this;
        this.ngxLoader.start();
        this.authService.getHistoricalRoundDetails().subscribe(function (response) {
            _this.ngxLoader.stop();
            if (response != null) {
                _this.historicalRound = response;
                _this.historicalRound.investors.forEach(function (element) {
                    element['allotment'] = [];
                    if (element['conversionDetails']) {
                        element['allotment'].push({ name: 'Converted', identifier: 'C' });
                    }
                    if (element['redemptionDetails']) {
                        element['allotment'].push({ name: 'Redeemed', identifier: 'R' });
                    }
                    if (element['isPricedRound'] == false) {
                        element['allotment'].push({ name: 'Non Priced Round', identifier: 'NP' });
                    }
                    if (!element['conversionDetails'] && !element['redemptionDetails']) {
                        element['allotment'].push({ name: 'Allotment', identifier: '' });
                    }
                });
                _this.historicalRound.secondaries.forEach(function (element) {
                    element['transactions'] = [];
                    if (element['conversionDetails']) {
                        element['transactions'].push({ name: 'Converted', identifier: 'C' });
                    }
                    if (element['redemptionDetails']) {
                        element['transactions'].push({ name: 'Redeemed', identifier: 'R' });
                    }
                    if (element['isPricedRound'] == false) {
                        element['transactions'].push({ name: 'Non Priced Round', identifier: 'NP' });
                    }
                    if (element['buyer'] == 'Company' || element['buyer'] == 'company' || element['buyer'] == 'COMPANY') {
                        element['transactions'].push({ name: 'Buy-back', identifier: 'B' });
                    }
                    if (element['transactions'].length == 0) {
                        element['transactions'].push({ name: 'Transactions', identifier: '' });
                    }
                });
                if (_this.historicalRound.historicalRoundPopupDetails.length == 0) {
                    _this.historicalRound.historicalRoundPopupDetails = [
                        {
                            isPricedRound: true,
                            roundConversionRatio: null,
                            roundIdentifier: "FF",
                            roundName: "Incorporation Round",
                            roundVotingRightsRatio: null,
                            updateConversionRatios: []
                        },
                        {
                            isPricedRound: true,
                            roundConversionRatio: null,
                            roundIdentifier: "EE",
                            roundName: "ESOP Exercise",
                            roundVotingRightsRatio: null,
                            updateConversionRatios: []
                        },
                    ];
                }
                if (_this.historicalRound.companyCapitalDetails != null) {
                    var cd = _this.historicalRound.companyCapitalDetails;
                    _this.capitalSettingsForm.controls["equityAuthorizedShareCapital"].setValue(_this.utilityService.inputCSVformatCurrency(_this.usrCompanyCurrency, cd.equityAuthorizedShareCapital));
                    _this.capitalSettingsForm.controls["preferenceAuthorizedShareCapital"].setValue(_this.utilityService.inputCSVformatCurrency(_this.usrCompanyCurrency, cd.preferenceAuthorizedShareCapital));
                    _this.capitalSettingsForm.controls["equityPaidUpShareCapital"].setValue(_this.utilityService.inputCSVformatCurrency(_this.usrCompanyCurrency, cd.equityPaidUpShareCapital));
                    _this.capitalSettingsForm.controls["preferencePaidUpShareCapital"].setValue(_this.utilityService.inputCSVformatCurrency(_this.usrCompanyCurrency, cd.preferencePaidUpShareCapital));
                    _this.updateAuthorizedTotal();
                    _this.updatePaidUpTotal();
                    _this.setHeaderFilters();
                }
                _this.updateRoundsArray();
                _this.isShowNote = _this.historicalRound['notes'] != null ? true : false;
            }
        }, function (e) {
            _this.ngxLoader.stop();
            _this.toastr.error(e.error.reason, "Failure!", { tapToDismiss: true, disableTimeOut: true });
        });
    };
    HistoricalRoundCreationComponent.prototype.calculateRoundFilterExpression = function (value) {
        var column = this;
        var selector = function (data) {
            var values = column.calculateCellValue(data);
            return values && values.indexOf(value) >= 0;
        };
        return [selector, "=", true];
    };
    HistoricalRoundCreationComponent.prototype.calculateCellValue = function (data) {
        return $.map(data['transactions'], function (o) {
            return o.identifier;
        });
    };
    HistoricalRoundCreationComponent.prototype.calculateRoundFilterExpressionPrim = function (value) {
        var column = this;
        // if (value) {
        var selector = function (data) {
            var values = $.map(data['allotment'], function (o) {
                return o.identifier;
            });
            return values && values.indexOf(value) >= 0;
        };
        return [selector, "=", true];
        // }
    };
    HistoricalRoundCreationComponent.prototype.calculateCellValuePrim = function (data) {
        return $.map(data['allotment'], function (o) {
            return o.identifier;
        });
    };
    HistoricalRoundCreationComponent.prototype.setHeaderFilters = function () {
        this.securityTypeFilterPrim = [];
        this.parNumberFilterPrim = [];
        this.premiumNumberFilterPrim = [];
        this.noOfSharesFilterPrim = [];
        this.amountFilterPrim = [];
        this.roundNameFilterPrim = [];
        this.noOfSharesFilterSec = [];
        this.ppsFilterSec = [];
        this.amountFilterSec = [];
        this.securityTypeFilterSec = [];
        this.roundNameFilterSec = [];
        this.allotmentsFilter = [];
        this.transactionsFilter = [];
        var parValuesArrayPrim = [];
        var premiumValuesArrayPrim = [];
        var noOfSharesArrayPrim = [];
        var amountArrayPrim = [];
        var allotmentsArrayPrim = [];
        this.historicalRound.investors.forEach(function (element) {
            parValuesArrayPrim.push(element['par']);
            premiumValuesArrayPrim.push(element['premium']);
            noOfSharesArrayPrim.push(element['numberOfShares']);
            amountArrayPrim.push(element['amount']);
            if (element['allotment']) {
                allotmentsArrayPrim.push(element['allotment']);
            }
        });
        var noOfSharesArraySec = [];
        var ppsArraySec = [];
        var amountArraySec = [];
        var transactionsArraySec = [];
        this.historicalRound.secondaries.forEach(function (element) {
            noOfSharesArraySec.push(element['numberOfShares']);
            ppsArraySec.push(element['pricePerShare']);
            amountArraySec.push(element['amount']);
            if (element['transactions']) {
                transactionsArraySec.push(element['transactions']);
            }
        });
        this.securityTypeFilterPrim = this.createNameFilter(this.historicalRound.investors, 'securityType');
        this.parNumberFilterPrim = this.createNumericalRangeFilter(parValuesArrayPrim, 'par');
        this.premiumNumberFilterPrim = this.createNumericalRangeFilter(premiumValuesArrayPrim, 'premium');
        this.noOfSharesFilterPrim = this.createNumericalRangeFilter(noOfSharesArrayPrim, 'numberOfShares');
        this.amountFilterPrim = this.createNumericalRangeFilter(amountArrayPrim, 'amount');
        this.roundNameFilterPrim = this.createNameFilter(this.historicalRound.investors, 'roundName');
        this.noOfSharesFilterSec = this.createNumericalRangeFilter(noOfSharesArraySec, 'numberOfShares');
        this.ppsFilterSec = this.createNumericalRangeFilter(ppsArraySec, 'pricePerShare');
        this.amountFilterSec = this.createNumericalRangeFilter(amountArraySec, 'amount');
        this.securityTypeFilterSec = this.createNameFilter(this.historicalRound.secondaries, 'securityType');
        this.roundNameFilterSec = this.createNameFilter(this.historicalRound.secondaries, 'roundName');
        this.allotmentsFilter = this.createAllotmentFilter(allotmentsArrayPrim);
        this.transactionsFilter = this.createAllotmentFilter(transactionsArraySec);
    };
    HistoricalRoundCreationComponent.prototype.createAllotmentFilter = function (allotments) {
        var flatAllotments = _.flattenDeep(allotments);
        var uniqueFlatAllot = _.uniqBy(flatAllotments, 'name');
        var filter = [];
        uniqueFlatAllot.forEach(function (element) {
            var filterObj = {
                text: element['name'],
                value: element['identifier']
            };
            filter.push(filterObj);
        });
        return filter;
    };
    HistoricalRoundCreationComponent.prototype.createNumericalRangeFilter = function (investors, dataField) {
        var _this = this;
        var max = Math.max.apply(Math, investors);
        var min = Math.min.apply(Math, investors);
        var filterArray;
        if (max > min) {
            filterArray = this.getIntervals(min, max, 5);
        }
        else {
            filterArray = [[min, max]];
        }
        var resultArray = [];
        filterArray.forEach(function (element) {
            var filterObj = {
                text: _this.utilityService.formatCurrency(_this.usrCompanyCurrency, element[0]) + '-' + _this.utilityService.formatCurrency(_this.usrCompanyCurrency, element[1]),
                value: [
                    [dataField, '>=', element[0]],
                    [dataField, '<=', element[1]]
                ]
            };
            resultArray.push(filterObj);
        });
        return resultArray;
    };
    HistoricalRoundCreationComponent.prototype.createNameFilter = function (investors, dataField) {
        var resultArray = [];
        var uniqueArray = _.uniqBy(investors, dataField);
        uniqueArray.forEach(function (element) {
            var filterObj = {
                text: element[dataField],
                value: element[dataField]
            };
            resultArray.push(filterObj);
        });
        return resultArray;
    };
    HistoricalRoundCreationComponent.prototype.getIntervals = function (min, max, nInt) {
        var round = this.range(Math.abs(min) > Math.abs(max) ? min : max);
        var max1 = max / round;
        var max2 = Math.ceil(max1) * round;
        var min1 = min / round;
        var min2 = Math.floor(min1) * round;
        return this.dividing_into_parts(max2, min2);
    };
    HistoricalRoundCreationComponent.prototype.range = function (d) {
        var i = 1;
        while (Math.floor(d) != 0) {
            d = d / 10;
            i = i * 10;
        }
        return i / 10;
    };
    HistoricalRoundCreationComponent.prototype.dividing_into_parts = function (max0, min0) {
        var diff = (max0 - min0) / 5;
        var min = min0;
        var max = min0 + diff;
        var intervals = [];
        while (max <= max0) {
            if (min == max) {
                break;
            }
            else if (max < 0) {
                intervals.push([min, (max - 1)]);
            }
            else if (max >= 0 && min <= 0) {
                intervals.push([min, max]);
            }
            else {
                intervals.push([(min + 1), max]);
            }
            min = max;
            max = max + diff;
        }
        return intervals;
    };
    HistoricalRoundCreationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.capitalSettingsForm = this.fb.group({
            equityAuthorizedShareCapital: [null, [Validators.required]],
            preferenceAuthorizedShareCapital: [null, [Validators.required]],
            equityPaidUpShareCapital: [null, [Validators.required]],
            preferencePaidUpShareCapital: [null, [Validators.required]],
            authTotal: [null, []],
            preferenceTotal: [null, []]
        });
        this.roundName = new Observable(function (subscriber) {
            _this.roundNameSubscriber = subscriber;
        });
        this.initRoundsArray(true);
        this.getHistoricalRoundDetails();
        if (this.activatedRoute.snapshot.paramMap.get('newComp') == 'true') {
            this.registerNewCompany = true;
        }
        if (this.cacheService.get("userDetails")) {
            this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
            this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
        }
        else {
            this.usrCompanyCurrency = "INR - ₹";
        }
        this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim();
    };
    HistoricalRoundCreationComponent.prototype.setTotalValues = function (e) {
        /**
         * Updates total values in PaidUP and Authorised capital settings
         * @args e : InputEvent
         * @returns void
         */
        var id = e.target['id'];
        if (['authorized-equity', 'authorized-preference'].includes(id)) {
            this.updateAuthorizedTotal();
        }
        else if (['paidup-equity', 'paidup-preference'].includes(id)) {
            this.updatePaidUpTotal();
        }
    };
    HistoricalRoundCreationComponent.prototype.replaceCommas = function (input) {
        if (!input)
            return 0;
        else {
            return parseFloat(input.replace(/,/g, ""));
        }
    };
    HistoricalRoundCreationComponent.prototype.updateAuthorizedTotal = function () {
        var values = this.capitalSettingsForm.value;
        var total = this.replaceCommas(values.equityAuthorizedShareCapital) + this.replaceCommas(values.preferenceAuthorizedShareCapital);
        this.capitalSettingsForm.get('authTotal').setValue(this.customLocaleFormater.transform(total, "tooltip-number"));
    };
    HistoricalRoundCreationComponent.prototype.updatePaidUpTotal = function () {
        var values = this.capitalSettingsForm.value;
        var total = this.replaceCommas(values.equityPaidUpShareCapital) + this.replaceCommas(values.preferencePaidUpShareCapital);
        this.capitalSettingsForm.get('preferenceTotal').setValue(this.customLocaleFormater.transform(total, "tooltip-number"));
    };
    return HistoricalRoundCreationComponent;
}());
export { HistoricalRoundCreationComponent };
