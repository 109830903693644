<div class="historical-summary">
  <div *ngIf="historicalRoundSummary?.companyCapitalDetails != null" class="row btm-20">
    <div class="col-md-9">
      <mat-card>
        <div class="row btm-20">
          <div class="col-md-12">
            <div class="btm-10">
              <mat-label style="font-size: 18px !important">
                Share Capital Settings
              </mat-label>
            </div>
            <p class="content-pg-1">
              The summary of the company's authorized and paid up capital is below. Note that the amounts have to be in
              accordance to the authorized capital mentioned in the company's memorandum and its financial statements.
            </p>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12">
            <div class="row btm-10">
              <div class="col-md-12">
                <div>
                  <mat-label>Authorized Capital ({{currencySymbol}}):</mat-label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <mat-label>Equity</mat-label>
            <input type="text"
              [value]="getAmount(usrCompanyCurrency, historicalRoundSummary?.companyCapitalDetails.equityAuthorizedShareCapital)"
              class="form-control text-align-right top-10" readonly />
          </div>
          <div class="col-md-4">
            <mat-label>Preference</mat-label>
            <input type="text"
              [value]="getAmount(usrCompanyCurrency, historicalRoundSummary?.companyCapitalDetails.preferenceAuthorizedShareCapital)"
              class="form-control text-align-right top-10" readonly />
          </div>
          <div class="col-md-4">
            <mat-label>Total</mat-label>
            <input type="text"
              [value]="getAmount(usrCompanyCurrency, historicalRoundSummary?.companyCapitalDetails.equityAuthorizedShareCapital + historicalRoundSummary?.companyCapitalDetails.preferenceAuthorizedShareCapital)"
              class="form-control text-align-right top-10" readonly />
          </div>
        </div>

        <div class="row btm-20">
          <div class="col-md-12">
            <div class="row btm-10">
              <div class="col-md-12">
                <mat-label>Paid Up Capital ({{currencySymbol}}):</mat-label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <mat-label>Equity</mat-label>

            <input type="text"
              [value]="getAmount(usrCompanyCurrency, historicalRoundSummary?.companyCapitalDetails.equityPaidUpShareCapital)"
              class="form-control text-align-right top-10" readonly />
          </div>

          <div class="col-md-4">
            <mat-label>Preference</mat-label>
            <input type="text"
              [value]="getAmount(usrCompanyCurrency, historicalRoundSummary?.companyCapitalDetails.preferencePaidUpShareCapital)"
              class="form-control text-align-right top-10" readonly />
          </div>
          <div class="col-md-4">
            <mat-label>Total</mat-label>
            <input type="text"
              [value]="getAmount(usrCompanyCurrency, historicalRoundSummary?.companyCapitalDetails.equityPaidUpShareCapital + historicalRoundSummary?.companyCapitalDetails.preferencePaidUpShareCapital)"
              class="form-control text-align-right top-10" readonly />
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Historical Round Summary</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row top-20 btm-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                The summary of all rounds raised by the company till date are tabulated below in separate tabs. Click on
                the name of the round to view the details of the round.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <mat-tab-group animationDuration="0ms"
                    (selectedTabChange)="tabChanged($event, historicalRoundDetails.investors, historicalRoundSummary.tabs)">
                    <mat-tab *ngFor="let tab of historicalRoundSummary?.tabs; let i=index;" id="{{i}}"
                      label="{{tab.roundName}}">
                      <ng-template mat-tab-label>
                        <div class="text-align-left" ngbTooltip="{{tab.roundName}}" placement="right">
                          <mat-label>{{tab.roundName}}</mat-label>
                        </div>
                      </ng-template>
                    </mat-tab>
                  </mat-tab-group>
                </div>
                <div class="col-md-12">
                  <div class="tab-content" *ngIf="activeTabType == 'Round'">
                    <div class="btm-20">
                      <p class="content-pg-1">
                        Based on the details of funding provided for all rounds, Hissa will create the cap table of the
                        company as on date. If the cap table looks correct, click on ‘Approve’. You will be directed to
                        your company’s dashboard page on Hissa.
                        Note that, once the cap table is generated, you will not be able to edit the cap table.

                      </p>
                    </div>
                    <div class="row btm-20">
                      <div class="col-md-3">
                        <mat-label>Paid Up Capital ({{currencySymbol}})</mat-label>
                        <input type="text"
                          [value]="getAmount(usrCompanyCurrency, activeTabData?.paidUpCapital)"
                          class="form-control text-align-right top-10" readonly />
                      </div>
                      <div class="col-md-3">
                        <mat-label>Valuation ({{currencySymbol}})</mat-label>
                        <input type="text"
                          [value]="getAmount(usrCompanyCurrency, activeTabData?.preMoneyValuation)"
                          class="form-control text-align-right top-10" readonly />
                      </div>
                      <div class="col-md-3">
                        <mat-label>Priced Round</mat-label><br>
                        <label class="top-10">
                          <input class="priced-round" [value]="activeTabData?.isPricedRound" type="checkbox" data-toggle="switch"
                              data-inverse="false" data-off-color="default"/>
                      </label>
                      </div>
                    </div>
                    <div class="btm-20">
                      <dx-data-grid class="gridContainer" [hoverStateEnabled]="true" [dataSource]="investorsArray"
                        [showBorders]="true" [showColumnLines]="false" [columnAutoWidth]="false"
                        [allowColumnReordering]="false" [allowColumnResizing]="false"
                        noDataText="No investor Data to show"
                        (onCellHoverChanged)="logEvent('CellHoverChanged',$event)">

                        <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="false" [allowDeleting]="false"
                          [allowAdding]="false">
                        </dxo-editing>

                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>

                        <dxi-column dataField="investorName" caption="Investor Name" alignment="left">
                        </dxi-column>

                        <dxi-column dataField="securityType" caption="Security">
                        </dxi-column>

                        <dxi-column [customizeText]="dxCustomizeValueColumn" dataField="par" dataType="number"
                          caption="PAR">
                        </dxi-column>

                        <dxi-column dataField="premium" dataType="number" caption="Premium"
                          [customizeText]="dxCustomizeDecimalCsv">
                        </dxi-column>

                        <dxi-column dataField="numberOfShares" dataType="number" caption="No. of Shares"
                          [customizeText]="dxCustomizeCsv">
                        </dxi-column>

                        <dxi-column dataField="amount" dataType="number" caption="Amount"
                          [customizeText]="dxCustomizeDecimalCsv">
                        </dxi-column>

                        <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="160"
                          cellTemplate="displayDateCell">
                        </dxi-column>
                        <div *dxTemplate="let cellData of 'displayDateCell'">
                          <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                        </div>

                        <dxi-column dataField="roundName" caption="Round">
                        </dxi-column>

                        <dxo-summary style="background-color: #F3F3F3;">
                          <dxi-total-item column="investorName" [customizeText]="displayAsTotal">
                          </dxi-total-item>
                          <dxi-total-item column="numberOfShares" summaryType="sum" [customizeText]="dxCustomizeCsv">
                          </dxi-total-item>
                          <dxi-total-item column="amount" summaryType="sum" [customizeText]="dxCustomizeDecimalCsv">
                          </dxi-total-item>
                        </dxo-summary>
                      </dx-data-grid>

                      <div class="col-md-12 text-align-right notes-tbl btm-20">
                        <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
                      </div>
                      <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                        <div *dxTemplate="let data of 'content'">
                          <div class="tooltip-content">{{toolTipText}}</div>
                        </div>
                      </dx-tooltip>
                    </div>
                    <div class="row" *ngIf="warningNotes != null">
                      <div class="col-md-12">
                        <mat-label>Amount Mismatch warnings</mat-label>
                        <textarea [value]="warningNotes" class="form-control top-10" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content" *ngIf="activeTabType == 'ESOP'">
                    <div class="btm-20">
                      <!-- <p class="content-pg-1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                      </p> -->
                    </div>
                    <div class="row">
                      <div class="col-md-7">
                        <dx-data-grid #esopGrid class="gridContainer" [hoverStateEnabled]="true"
                          [dataSource]="investorsArray" [showBorders]="true" [showColumnLines]="false"
                          [columnAutoWidth]="false" [allowColumnReordering]="false" [allowColumnResizing]="false"
                          noDataText="No ESOP Data to show" (onCellHoverChanged)="logEvent('CellHoverChanged',$event)">

                          <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="false" [allowDeleting]="false"
                            [allowAdding]="false">
                          </dxo-editing>
                          <dxi-column [customizeText]="dxCustomizeCsv" dataField="poolSize" dataType="number"
                            caption="Shares Allotted">
                          </dxi-column>
                          <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="150"
                            cellTemplate="displayDateCellEsop">
                          </dxi-column>
                          <div *dxTemplate="let cellData of 'displayDateCellEsop'">
                            <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                          </div>
                        </dx-data-grid>

                        <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                          <div *dxTemplate="let data of 'content'">
                            <div class="tooltip-content">{{toolTipText}}</div>
                          </div>
                        </dx-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content" *ngIf="activeTabType == 'Secondaries'">
                    <div class="btm-20">
                      <!-- <p class="content-pg-1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                      </p> -->
                    </div>
                    <dx-data-grid #secondariesGrid class="gridContainer" [hoverStateEnabled]="true"
                      [dataSource]="investorsArray" [showBorders]="true" [showColumnLines]="false"
                      [columnAutoWidth]="false" [allowColumnReordering]="false" [allowColumnResizing]="false"
                      noDataText="No Secondary data to show" (onCellHoverChanged)="logEvent('CellHoverChanged',$event)">

                      <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="false" [allowDeleting]="false"
                        [allowAdding]="false">
                      </dxo-editing>

                      <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">
                      </dxo-search-panel>

                      <dxi-column dataField="buyerName" caption="Buyer" alignment="left">
                      </dxi-column>

                      <dxi-column dataField="sellerName" caption="Seller" alignment="left">
                      </dxi-column>

                      <dxi-column dataField="numberOfShares" dataType="number" [customizeText]="dxCustomizeCsv"
                        caption="No. of Shares">
                      </dxi-column>

                      <dxi-column dataField="pricePerShare" dataType="number" [customizeText]="dxCustomizeDecimalCsv"
                        caption="Price per Share">
                      </dxi-column>

                      <dxi-column dataField="amount" dataType="number" [customizeText]="dxCustomizeDecimalCsv"
                        caption="Amount">
                      </dxi-column>

                      <dxi-column dataField="securityType" caption="Security">
                      </dxi-column>

                      <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="160"
                        cellTemplate="displayDateCell">
                      </dxi-column>
                      <div *dxTemplate="let cellData of 'displayDateCell'">
                        <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                      </div>

                      <dxo-summary style="background-color: #F3F3F3;">
                        <dxi-total-item column="buyer" [customizeText]="displayAsTotal">
                        </dxi-total-item>
                        <dxi-total-item column="numberOfShares" summaryType="sum" [customizeText]="dxCustomizeCsv">
                        </dxi-total-item>
                        <dxi-total-item column="amount" summaryType="sum" [customizeText]="dxCustomizeDecimalCsv">
                        </dxi-total-item>
                      </dxo-summary>
                    </dx-data-grid>

                    <div class="col-md-12 text-align-right notes-tbl btm-20">
                      <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
                    </div>
                    <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                      <div *dxTemplate="let data of 'content'">
                        <div class="tooltip-content">{{toolTipText}}</div>
                      </div>
                    </dx-tooltip>
                  </div>
                  <div class="tab-content" *ngIf="activeTabType == 'SPLIT'">
                    <div class="btm-20">
                      <!-- <p class="content-pg-1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                      </p> -->
                    </div>
                    <dx-data-grid #splitsGrid class="gridContainer" [hoverStateEnabled]="true"
                      [dataSource]="investorsArray" [showBorders]="true" [showColumnLines]="false"
                      [columnAutoWidth]="false" [allowColumnReordering]="false" [allowColumnResizing]="false"
                      (onCellHoverChanged)="logEvent('CellHoverChanged',$event)">

                      <dxo-editing style="padding: 10px;" mode="row" [allowUpdating]="false" [allowDeleting]="false"
                        [allowAdding]="false">
                      </dxo-editing>

                      <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">
                      </dxo-search-panel>

                      <dxi-column dataField="splitNumber" dataType="number" caption="Split Ratio" alignment="right"
                        cellTemplate="splitNumberTemplate">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dxi-column>

                      <div *dxTemplate="let cellData of 'splitNumberTemplate'">
                        <span>1:{{cellData.value}}</span>
                      </div>

                      <dxi-column dataField="securityType" caption="Security">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dxi-column>

                      <dxi-column dataField="date" caption="Date" dataType="date" [minWidth]="160"
                        cellTemplate="displayDateCell">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dxi-column>
                      <div *dxTemplate="let cellData of 'displayDateCell'">
                        <span>{{cellData.value | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                    </dx-data-grid>

                    <dx-tooltip [(target)]="toolTipTarget" position="right" [arrowLength]="1" opacity="0.8">
                      <div *dxTemplate="let data of 'content'">
                        <div class="tooltip-content">{{toolTipText}}</div>
                      </div>
                    </dx-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-12">
      <owl-carousel-o [options]="customsOption" class="row">
        <ng-container>
          <ng-template carouselSlide>
            <div class="col-md-12">
              <div class="cursor-hand btm-20 top-20 ">
                <span *ngIf='capTableData != null'>
                  <dx-pie-chart style="width: 100%;" id="pie" type="doughnut" resolveLabelOverlapping="shift"
                    centerTemplate="centerShareholderTemplate" [title]="{
                                text: 'Shareholders',
                                horizontalAlignment: 'center',
                                font: {
                                    family: 'Roboto',
                                    size: 28,
                                    weight: 500,
                                    color: '#000000'
                                }
                                }" [palette]="[
                                '#4d1981',
                                '#F4522B',
                                '#e0a41e',
                                '#ffe82d',
                                '#b80d5e',
                                '#ff1382',
                                '#1e7bcf',
                                '#2598ff',
                                '#7f9c25',
                                '#9dc02e'
                                ]" diameter="0.6" [dataSource]="capTableData" sizeGroup="4">
                    <dxi-series argumentField="shareholder">
                      <dxo-label [visible]="true" position="inside" [customizeText]="customizeText" format="decimal"
                        backgroundColor="none">
                        <dxo-font color="#ffffff" weight="bold"></dxo-font>
                        <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                      </dxo-label>
                    </dxi-series>
                    <dxo-legend [visible]="true" position="outside" verticalAlignment="bottom"
                      horizontalAlignment="center" itemTextPosition="right" orientation="horizontal">
                      <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" format="decimal" opacity="1"
                      [border]="{
                                    opacity: '0',
                                    visible: false
                                }" paddingLeftRight="6" paddingTopBottom="6" zIndex="1000">
                    </dxo-tooltip>
                    <div [ngbTooltip]="stockholderDone(true)">
                      <svg [ngbTooltip]="stockholderDone(true)"
                        *dxTemplate="let pieChart of 'centerShareholderTemplate'">
                        <text text-anchor="middle" style="font-size: 28px" x="100" y="120" fill="#000000">
                          <tspan x="100" dy="20px" style="font-weight: 500">
                            {{ stockholderDone() }}
                          </tspan>
                        </text>
                      </svg>
                    </div>

                  </dx-pie-chart>
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="col-md-12">
              <div class=" cursor-hand btm-20 top-20">
                <span *ngIf='capTableData!=null'>
                  <dx-pie-chart style="width: 100%;" id="pie" type="doughnut" resolveLabelOverlapping="shift"
                    centerTemplate="centerRoundTemplate" [title]="{
                                text: 'Rounds',
                                horizontalAlignment: 'center',
                                font: {
                                    family: 'Roboto',
                                    size: 28,
                                    weight: 500,
                                    color: '#000000'
                                }
                                }" [palette]="[
                                '#f79663',
                                '#feca7a',
                                '#16d8d8',
                                '#00acb3',
                                '#f24c88',
                                '#d84774'
                                ]" [dataSource]="roundChart" sizeGroup="4">
                    <dxi-series argumentField="roundIdentifier" valueField="roundInvestmentAmount">
                      <dxo-label [visible]="true" position="inside" backgroundColor="none"
                        [customizeText]="this.usrCompanyCurrency === 'INR - ₹' ? customizeRoundTextCC : customizeRoundTextRC "
                        format="decimal">
                        <dxo-font color="#ffffff" weight="bold"></dxo-font>
                        <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                      </dxo-label>
                    </dxi-series>
                    <dxo-legend [visible]="true" position="outside" verticalAlignment="bottom"
                      horizontalAlignment="center" itemTextPosition="right" orientation="horizontal">
                      <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeRoundTooltip" format="decimal"
                      opacity="1" [border]="{
                                    opacity: '0',
                                    visible: false
                                }" paddingLeftRight="6" paddingTopBottom="6" zIndex="1000">
                    </dxo-tooltip>
                    <svg *dxTemplate="let pieChart of 'centerRoundTemplate'">
                      <text text-anchor="middle" style="font-size: 28px" x="100" y="120" fill="#000000">
                        <tspan x="100" dy="20px" style="font-weight: 500">
                          {{ roundDone() }}
                        </tspan>
                      </text>
                    </svg>
                  </dx-pie-chart>
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="col-md-12">
              <div class=" cursor-hand btm-20 top-20">
                <span *ngIf='capTableData != null'>
                  <dx-pie-chart style="width: 100%;" id="pie" resolveLabelOverlapping="shift" [title]="{
                                text: 'Securities',
                                horizontalAlignment: 'center',
                                font: {
                                    family: 'Roboto',
                                    size: 28,
                                    weight: 500,
                                    color: '#000000'
                                }
                                }" [palette]="['#fb9e06', '#9215b3', '#fa3555', '#0aa2f6']"
                    [dataSource]="instrumentArray" sizeGroup="4">
                    <dxi-series argumentField="instrument" valueField="value">
                      <dxo-label [visible]="true" position="inside" backgroundColor="none"
                        [customizeText]="customizeText" format="fixedPoint">
                        <dxo-font color="#ffffff" weight="bold"></dxo-font>
                        <dxo-connector [visible]="false" color="#929292"></dxo-connector>
                      </dxo-label>
                    </dxi-series>
                    <dxo-legend [visible]="true" position="outside" verticalAlignment="bottom"
                      horizontalAlignment="center" itemTextPosition="right" orientation="horizontal">
                      <dxo-margin [bottom]="10" [top]="30"></dxo-margin>
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" format="decimal" opacity="1"
                      [border]="{
                                    opacity: '0',
                                    visible: false
                                }" paddingLeftRight="6" paddingTopBottom="6" zIndex="1000">
                    </dxo-tooltip>
                  </dx-pie-chart>
                </span>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <mat-label style="font-size: 18px !important">
            Cap Table
          </mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="coldgridOPerations">
            <mat-form-field appearance="outline" >
              <mat-label>
                Search..
              </mat-label>
              <input [disabled]="!historicalRoundSummary?.capTable" type="text" [formControl]="searchFormControl"
                (keyup)="searchEntered($event.target.value)" matInput [matAutocomplete]="auto" />
              <mat-autocomplete (optionSelected)="searchEntered(searchFormControl.value)" #auto="matAutocomplete">
                <mat-option *ngFor="let option of searchOptions | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
              <span matPrefix><i class="fa fa-search" aria-hidden="true"></i></span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-8 align-right">
          <div class="coldgridOPerations">
            <mat-icon (click)="expandAllItem()" ngbTooltip="Expand All/Collapse All"><img  style="height: 28px;margin-top: 15px;margin-left: -18px;" src="../../assets/expandcollbox.svg"><i
                class=" fa-lg" aria-hidden="true"></i></mat-icon>

            <mat-form-field appearance="outline">
              <mat-label>Export</mat-label>
              <mat-select>
                  <mat-option (click)="exportFile('round')" >Export Round Based Report</mat-option>
                  <mat-option (click)="exportFile('security')" >Export Securities Based Report</mat-option>
                  <mat-option (click)="exportFile()">Export Summary Report</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row btm-20">
        <div class="col-md-12">
          <!-- <div>
            <img class="table-action-toolbar" title="Export Round Based Report" (click)="exportFile('round')" *ngIf="historicalRoundSummary?.capTable && historicalRoundSummary?.capTable.length > 0"
                src="../../../assets/download.svg" style="height: 35px; margin-top: 10px;" />
            <img class="table-action-toolbar" title="Export Securities Based Report" (click)="exportFile('security')" *ngIf="historicalRoundSummary?.capTable && historicalRoundSummary?.capTable.length > 0"
                src="../../../assets/download.svg" style="height: 35px; margin-top: 10px;" />
          </div> -->
          <dx-tree-list [dataSource]="historicalRoundSummary?.capTable" [wordWrapEnabled]="true"
            itemsExpr="shareholders" dataStructure="tree" [showRowLines]="true" [showBorders]="true"
            [columnAutoWidth]="true" (onCellHoverChanged)="onCellHoverChanged($event)">
            <dxo-search-panel [visible]="false" [width]="240" placeholder="Search..."></dxo-search-panel>
            <!-- <dxo-header-filter [visible]="true" [allowSearch]="false"> </dxo-header-filter> -->
            <dxi-column [width]="200" dataField="name" caption="Shareholder"></dxi-column>
            <div *dxTemplate="let cell of 'namesTemplate'">
              <span>{{ cell.data.name }}</span>
            </div>
            <dxi-column [width]="200" cssClass="round-identifier" dataField="roundDetails" caption="Rounds"
              cellTemplate="roundsTemplate" [calculateCellValue]="calculateCellValue"
              [calculateFilterExpression]="calculateRoundFilterExpression">
              <!-- <dxo-header-filter [dataSource]="roundsFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cellData of 'roundsTemplate'">
              <span *ngFor="let round of cellData.value" class="datalist-rnd-identifier" data-container="body"
                [ngClass]="identifierCSS(round)"
                [ngStyle]="round != 'ES' ? {'cursor': 'pointer'} : {'cursor': 'context-menu'}"
                [ngbTooltip]="showRoundTooltip(round, cellData.data.roundDetails)">
                {{ round }}
              </span>
            </div>

            <dxi-column dataField="holdingPercentage" caption="Holding % (FDB)" cellTemplate="holdingPerTemplate">
              <!-- <dxo-header-filter [dataSource]="holdingFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cellData of 'holdingPerTemplate'">
              {{cellData.value | percent:'2.2'}}
            </div>

            <dxi-column dataField="totalDilutedShares" caption="Total No. Of Shares (FDB)" cellTemplate="noOfSharesTemplate">
              <!-- <dxo-header-filter [dataSource]="noOfSharesFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cellData of 'noOfSharesTemplate'">
              {{ getTotalValueTble(cellData.value) }}
            </div>

            <!-- <dxi-column dataField="pricePerShare" caption="Price per Share" cellTemplate="ppShareTemplate">
            </dxi-column>
            <div *dxTemplate="let cellData of 'ppShareTemplate'">
                {{ getTotalValueTble(cellData.value) }}
            </div> -->

            <dxi-column alignment="right" dataField="preferenceSharesFDB" caption="Preference Shares (FDB)" cellTemplate="preferenceTemplate">
              <!-- <dxo-header-filter [dataSource]="preferenceFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cellData of 'preferenceTemplate'">
              {{ getTotalValueTble(cellData.value) }}
            </div>

            <dxi-column dataField="preferenceShares" caption="Preference Shares (Paid Up)" cellTemplate="preferenceTemplate">
              <!-- <dxo-header-filter [dataSource]="preferenceFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cellData of 'preferenceTemplate'">
              {{ getTotalValueTble(cellData.value) }}
            </div>

            <dxi-column dataField="commonShares" caption="Equity (Paid Up)" cellTemplate="commonTemplate">
              <!-- <dxo-header-filter [dataSource]="commonFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cellData of 'commonTemplate'">
              {{ getTotalValueTble(cellData.value) }}
            </div>

            <dxi-column dataField="amountInvested" caption="Amount Invested" cellTemplate="amtInvestedTemplate">
            </dxi-column>
            <div *dxTemplate="let cellData of 'amtInvestedTemplate'">
              {{ getTotalValueTble(cellData.value) }}
            </div>
          </dx-tree-list>
          <div class="col-md-12 text-align-right notes-tbl btm-20">
            <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
          </div>
          <dx-tooltip [(target)]="toolTipTarget" position="right" opacity="0.8">
            <div *dxTemplate="let data of 'content'">
              <div class="tooltip-content">{{toolTipText}}</div>
            </div>
          </dx-tooltip>
        </div>
      </div>
    </div>
  </div>
  <div class="row btm-20 justify-end">
    <div class="col-md-10"></div>
    <div class="col-md-2">
      <mat-label class="required">Select Company Type</mat-label>
        <div class="top-10">
          <select class="form-control input-fields cursor-hand" [(ngModel)]="companyType"
          required>
            <option value="" disabled selected>Select Company Type</option>
              <option value="public">Public</option>
              <option value="private">Private</option>
          </select>
          <span class="alert alert-danger" *ngIf="(companyType === null || companyType == '' )">
            Company Type is required!
          </span> 
        </div>
      <textarea class="form-control top-10" [(ngModel)]="historicalRoundSummary.warningNotes" *ngIf="isShowNote"
        placeholder="Add Notes"></textarea>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-12">
      <div *ngIf="!isShowNote" (click)="isShowNote = !isShowNote;">
        <span>
          <mat-label>Add Notes</mat-label><img class="left-10 cursor-hand" src="../../../../assets/action/addnotes.svg"
            alt="add Notes" />
        </span>
      </div>
      <div *ngIf="isShowNote" (click)="isShowNote = !isShowNote;">
        <span>
          <mat-label>Remove Notes</mat-label><img class="left-10 cursor-hand"
            src="../../../../assets/action/removenotes.svg" alt="remove Notes" />
        </span>
      </div>
      <textarea class="form-control top-10" [(ngModel)]="historicalRoundSummary.warningNotes" *ngIf="isShowNote"
        placeholder="Add Notes"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <button mat-raised-button color="success" [disableRipple]="true" ngbTooltip="Approve"
        class="float-right margin-left-lg" (click)="approveHistoricalRound()">
        Approve
      </button>
      <button mat-raised-button color="success" [disableRipple]="true" ngbTooltip="Back"
        class="float-right margin-left-lg" [routerLink]="['/landing','onboarding','create-historical-round']">
        Back
      </button>
      <button mat-raised-button color="warn" [disableRipple]="true" ngbTooltip="Discard" class="float-right margin-left-lg"
        (click)="discardHistoricalRound()">
        Discard
      </button>
      <button mat-raised-button color="warn" [disableRipple]="true" ngbTooltip="Publish" class="float-right" *ngIf="!isProduction"
        (click)="publishHistoricalRound()">
        Publish
      </button>
    </div>
  </div>
</div>