<div *ngIf="showDialog" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">Edit Willingness</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container">
    <div class="row top-20 btm-20">
      <div class="col-md-12">
        <p class="content-pg-1">Eligibility(pro-rata): {{ eligibility }}</p>
      </div>
    </div>

    <div class="row" *ngFor="let set of shareDistinctiveSets; let i=index;">
      <div class="col-md-8">
        <input readonly class="form-control" type="text" value="{{ set.shareStartNumber }} - {{ set.shareEndNumber }}" />
      </div>
      <div class="col-md-4">
        <input class="form-control right-align" type="number" [value]="set.totalNumberOfShares" (change)="onSharesChange(i, $event.target.value)"
          (keydown)=onKeydownEvent($event) (keypress)="onlyNumberKey($event)" />
      </div>
    </div>


    <div class="row top-20 btm-20">
      <div class="col-md-12">
        <button mat-raised-button [disableRipple]="disableripple" color="success" class="float-right"
            (click)='save()' ngbTooltip="Save">Save</button>
          <button mat-raised-button id="cancel" [disableRipple]="disableripple" color="warn" class="float-right margin-right-20"
            (click)="close()" ngbTooltip="Cancel">Cancel</button>
      </div>
    </div>
  </div>
</div>