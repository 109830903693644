<div class="container-fluid custom-fluid-container graybg">
    <div class="whiteboxwrap">

    <div class="row" (mouseleave)="hideHelp()">
        <div class="col-md-9" id="Create_NewRound" (mouseover)="showHelp('Create_NewRound')">

            <h5 class="page-hdr">Round Creation</h5>

            <p class="content-pg-1 btm-20">
                Here is an overview of the total capital amount the company has raised so far across all its funding rounds in exchange for shares. 
                The details of the capital raised in the previous round and the value of the company thereafter are also displayed.
                <span *ngIf="isBasicDtlsFilled"> The second table summarizes the round currently in progress.</span><br><br>
                The wizard provides the steps involved in creating a new round. 
                Follow the steps to create a new round and understand the pre and post money shareholding value of the investors after the round and its impact on the company and other stakeholders. 
            </p>

            <!-- info tile -->
            <div class="row">
                <div class="col-md-12 ">
                    <div class="back-card btm-20">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Amount Raised</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(amountRaised)}}">{{ getTotalValueTble(amountRaised) }}</mat-label>
                                </div>                                
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Equity Shares</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(equityShares)}}">{{getTotalValueTble(equityShares)}}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Preference Shares</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(preferenceShares)}}">{{getTotalValueTble(preferenceShares)}}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Number of Rounds</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value">{{numberOfRounds| CustomeCurrency: "2"}}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Last Valuation (Post)</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(lastValuation)}}">{{ getTotalValueTble(lastValuation) }}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center custom-pad">
                                <div class="btm-10">
                                    <mat-label>Last Raise</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatCompanyCurrency(lastRaise)}}">{{ getTotalValueTble(lastRaise) }}</mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- currently running round details -->
            <div class="row" *ngIf="isBasicDtlsFilled">
                <div class="col-md-12 ">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card-title class="round-font">Currently Running -
                                {{roundName == null ? '-' : roundName}}</mat-card-title>
                        </div>
                    </div>
                    <div class="back-card">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Round Size</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatRoundCurrency(roundSize)}}">
                                        {{ getTotalAsPerRound(roundSize == null ? '-' : roundSize) }}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Pre-Money Valuation</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatRoundCurrency(roundValuation)}}">
                                        {{ getTotalAsPerRound(roundValuation == null ? '-':roundValuation)}}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-6 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Share Price</mat-label>
                                </div>
                                <div>
                                    <label>{{roundCurrency.substr(roundCurrency.length-2)}}</label>
                                    <mat-label class="mat-label-value" ngbTooltip="{{ formatRoundCurrency(sharePrice) }}">
                                        {{ formatRoundCurrency(sharePrice) }}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Raised</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value" ngbTooltip="{{formatRoundCurrency(raised)}}">{{getTotalAsPerRound(raised == null ? '-' : raised)}}
                                    </mat-label> 
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-4 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Left to Raise</mat-label>
                                </div>
                                <div>
                                    <mat-label class="rcTile-red" ngbTooltip="{{formatRoundCurrency(leftToRaise)}}">
                                        {{getTotalAsPerRound(leftToRaise == null ? '-':leftToRaise)}}</mat-label>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-6 text-align-center">
                                <div class="btm-10">
                                    <mat-label>Lead Investor</mat-label>
                                </div>
                                <div>
                                    <mat-label class="mat-label-value text-wrap">
                                        {{ leadInvestor == null ?'-': leadInvestor}}</mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- stepper -->
            <div class="row margin-zero">
                <div class="col-md-12 top-20">
                    <mat-horizontal-stepper [disableRipple]="true" labelPosition="bottom" #stepper [linear]="isLinear"
                        class="col-sm-12">
                        <mat-step disableRipple="true">
                            <ng-template matStepLabel>Round
                                <mat-icon (click)="randomStep('round')">
                                    <img src="{{roundTab}}" alt="round" />
                                </mat-icon>
                            </ng-template>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Current Investors
                                <mat-icon (click)="randomStep('currentInvestors')">
                                    <img src="{{currentInvestorTab}}" alt="Current Investors" />
                                </mat-icon>
                            </ng-template>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>New Investors
                                <mat-icon (click)="randomStep('newInvestors')">
                                    <img src="{{newInvestorTab}}" alt="New Investors" />
                                </mat-icon>
                            </ng-template>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Security
                                <mat-icon (click)="randomStep('instrument')">
                                    <img src="{{instrumentTab}}" alt="Instrument" />
                                </mat-icon>
                            </ng-template>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Tranches
                                <mat-icon (click)="randomStep('capTable')">
                                    <img src="{{tanchesTab}}" alt="Tranches" />
                                </mat-icon>
                            </ng-template>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Summary
                                <mat-icon (click)="randomStep('summary')">
                                    <img src="{{summaryTab}}" alt="Summary" />
                                </mat-icon>
                            </ng-template>
                        </mat-step>
                    </mat-horizontal-stepper>
                </div>
            </div>
        </div>
        <!-- Helper Section -->
        <div class="col-md-3">
            <div class="helper-container">
                <mat-card id="helperCard" class="card-help help-style" [hidden]="helper == ''">
                    <mat-card-header class="help-hdr">
                      <mat-card-title><label class="help-hdr-txt">{{
                            helpTitle
                        }}</label></mat-card-title>
                    </mat-card-header>
                    <hr />
                    <mat-card-content class="card-help-content">
                      <div [innerHTML]="helpMessage"></div>
                    </mat-card-content>
                  </mat-card>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <!-- Component Section -->
            <div class="row">
                <div class="col-md-12 btm-20">
                    <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                    </router-outlet>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
