import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from './utilities.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../authentication.service";
import * as i3 from "ngx-toastr";
import * as i4 from "./utilities.service";
var PermissionsService = /** @class */ (function () {
    // getUserPermissionsForAction():Array<string>{
    //   return ['CREATE_PLAN','AMEND_PLAN','ADD_EMPLOYEE','VESTING_EVENT_MAKER','ADD_GRANT','EDIT_EMPLOYEE','EMPLOYEE_STATUS_CHANGE'];
    // }
    function PermissionsService(router, activatedRoute, authService, toastr, utilityService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.toastr = toastr;
        this.utilityService = utilityService;
        this.permissions = {
            "emp-view": "EMPLOYEES_VIEWER",
            "employee-page-viewer": "EMPLOYEE_PAGE_VIEWER",
            "full-access": "FULL_ACCESS",
            "opts-management-view": "OPTIONS_MANAGEMENT_VIEWER",
            "opts-homepage-view": "OPTIONS_HOME_PAGE_VIEWER",
            "create-plan": "CREATE_PLAN",
            "amend-plan": "AMEND_PLAN",
            "add-employee": "ADD_EMPLOYEE",
            "vesting-event-maker": "VESTING_EVENT_MAKER",
            "add-grant": "ADD_GRANT",
            "edit-grant": "EDIT_GRANT",
            "delete-grant": "DELETE_GRANT",
            "edit-employee": "EDIT_EMPLOYEE",
            "employee-status-change": "EMPLOYEE_STATUS_CHANGE",
            "change-grant-state": "CHANGE_GRANT_STATE",
            "plan-active": "PLAN_ACTIVE",
            "plan-delete": "PLAN_DELETE",
            "create-vesting-schedule-template": "CREATE_VESTING_SCHEDULE_TEMPLATE",
            "edit-vesting-schedule-template": "EDIT_VESTING_SCHEDULE_TEMPLATE",
            "vesting-event-completor": "VESTING_EVENT_COMPLETOR",
            "vest-request": "VEST_REQUEST",
            "vest-approver": "VEST_APPROVER",
            "exercise-approver": "EXERCISE_APPROVER",
            "vesting-schedule-template-viewer": "VESTING_SCHEDULE_TEMPLATE_VIEWER",
            "employees-overview-viewer": "EMPLOYEES_OVERVIEW_VIEWER",
            "admin-without-proforma": "ADMIN_WITHOUT_PROFORMA",
            "captable-summary": "CAPTABLE_SUMMARY",
            "shareholder-viewer": "SHAREHOLDER_VIEWER",
            "esop-admin": "ESOP_ADMIN",
            "esop-viewer": "ESOP_VIEWER",
        };
    }
    PermissionsService.prototype.getAllPermissions = function () {
        return this.permissions;
    };
    PermissionsService.prototype.getUserPermissions = function () {
        this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        if (this.userDetails && this.userDetails["authorities"] != null) {
            return this.userDetails["authorities"][0]["permissions"];
            // return ['CAPTABLE_VIEWER','FULL_ACCESS']
        }
        else {
            return [];
        }
    };
    PermissionsService.prototype.authorizeUser = function (permissionsRequiredToAccess) {
        var permissionSet = this.getAllPermissions();
        var userPermissions = this.getUserPermissions();
        var isStrict = permissionsRequiredToAccess.includes("!strict");
        for (var index = 0; index < permissionsRequiredToAccess.length; index++) {
            var permission = permissionSet[permissionsRequiredToAccess[index]];
            var fullAccess = permissionSet["full-access"];
            var adminViewer = permissionSet["admin-without-proforma"];
            if (userPermissions.includes(permission) ||
                ((userPermissions.includes(fullAccess) ||
                    userPermissions.includes(adminViewer)) &&
                    !isStrict)) {
                return true;
            }
        }
        return false;
    };
    PermissionsService.prototype.navigateBasedOnPermission = function () {
        var _this = this;
        var userPermissions = this.getUserPermissions();
        var isHomePageView = this.authorizeUser(["opts-homepage-view", "!strict"]);
        var isManagementPageView = this.authorizeUser([
            "opts-management-view",
            "!strict",
        ]);
        var esopAdminView = this.authorizeUser(["esop-admin", "!strict"]);
        var esopViewer = this.authorizeUser(["esop-viewer", "!strict"]);
        var isEmployee = this.authorizeUser(["emp-view", "!strict"]);
        var isShareholder = this.authorizeUser(["shareholder-viewer", "!strict"]);
        var capTableView = this.authorizeUser(["captable-summary", "!strict"]);
        var accessNewUi = JSON.parse(sessionStorage.getItem("userDetails")).useNewEsopUI || false;
        if ((isHomePageView && isManagementPageView) || esopAdminView || esopViewer) {
            return this.router.navigate([
                "/landing",
                "dashboard",
                "options",
                "overviewEsop",
            ]);
        }
        else if (isHomePageView && !isManagementPageView) {
            return this.router.navigate([
                "/landing",
                "dashboard",
                "options",
                "overviewEsop",
            ]);
        }
        else if (!isHomePageView && isManagementPageView) {
            return this.router.navigate([
                "/landing/dashboard/options/manageEsop",
                { planName: "ALL" },
            ]);
        }
        else if (isEmployee) {
            return this.router.navigate([
                "/landing",
                "dashboard",
                "employees",
                "employeeDetails",
                localStorage.getItem("empId"),
            ]);
        }
        else if (isShareholder && !capTableView) {
            return this.router.navigate([
                "landing",
                "dashboard",
                "shareholder",
                this.userDetails.shareholderId,
            ]);
        }
        else if (isShareholder && capTableView) {
            return this.router.navigate(["/landing", "dashboard", "capTable"]);
        }
        for (var i = 0; i < userPermissions.length; i++) {
            for (var _i = 0, _a = Object.entries(this.permissions); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (value === userPermissions[i]) {
                    if (key === "full-access") {
                        if (JSON.parse(sessionStorage.getItem("userDetails")).company["isEsopOnlyCompany"]) {
                            this.eoc = this.router.navigate([
                                "/landing",
                                "dashboard",
                                "options",
                                "overviewEsop",
                            ]);
                            this.utilityService.dataEmitter.emit("companyChanged");
                            return;
                        }
                        this.authService.getExistingCapTable(false).subscribe(function (res) {
                            _this.authService.capTableData = res;
                            _this.eoc = _this.router.navigate([
                                "/landing",
                                "dashboard",
                                "capTable",
                            ]);
                            return _this.eoc;
                        }, function (e) {
                            return _this.toastr.error(e.error.reason, "Error");
                        });
                    }
                }
            }
        }
        return this.router.navigate(["/landing", "dashboard", "capTable"]);
    };
    PermissionsService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionsService_Factory() { return new PermissionsService(i0.inject(i1.Router), i0.inject(i1.ActivatedRoute), i0.inject(i2.AuthenticationService), i0.inject(i3.ToastrService), i0.inject(i4.UtilityService)); }, token: PermissionsService, providedIn: "root" });
    return PermissionsService;
}());
export { PermissionsService };
