import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../../../authentication.service";
import { UtilityService } from '../../../services/utilities.service';
declare var bootbox: any;

@Component({
  selector: 'app-splitstock-ratio-modal',
  templateUrl: './splitstock-ratio-modal.component.html',
  styleUrls: ['./splitstock-ratio-modal.component.scss']
})
export class SplitstockRatioModalComponent implements OnInit {
  @Input() showDialogsplitstock: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public disableripple: boolean = true;
  public isValid: boolean = false;

  public splitShareObj = {
    'splitRatio': '',
    'date': '',
    'comments': ''
  }
  public ratioInvalid: boolean;
  public mSplitshare:boolean=false;
  public mComments:boolean=false;
  public mDate:boolean=false;

  constructor(private router: Router,
    public route: ActivatedRoute,
    private authService: AuthenticationService,
    private utility: UtilityService) { }

  checkRatioVal(val) {  
    this.mSplitshare = false; 
    let ratioIndex = val.indexOf(":");
    let part1 = parseInt(val.substring(0, ratioIndex));
    let part2 = parseInt(val.substring(ratioIndex + 1, val.length + 1));
    if (
      part1 == val.substring(0, ratioIndex) &&
      part2 == val.substring(ratioIndex + 1, val.length + 1)
    ) {
      this.ratioInvalid = false;
    } else {
      this.ratioInvalid = true;
    }
  }

  splitShares() {
  
    this.isValid = this.validatorMethod(this.splitShareObj);

    if (this.isValid) {
      if (this.splitShareObj.splitRatio == undefined || this.splitShareObj.splitRatio == null || this.splitShareObj.splitRatio == "") {
        this.mSplitshare = true;
      }
      if (this.splitShareObj.comments == undefined || this.splitShareObj.comments == null || this.splitShareObj.comments == "") {
        this.mComments = true;
      }
      if (this.splitShareObj.date == undefined || this.splitShareObj.date == null || this.splitShareObj.date == "") {
        this.mDate = true;
      }
      return;
    }
    else {    
      this.authService.getSplitReverseSplitInfo(this.splitShareObj.splitRatio).subscribe((response) => {
        if (response) {        
          this.showDialogsplitstock = false;
          this.visibleChange.emit(this.showDialogsplitstock);
          this.router.navigate(["/landing", "dashboard", "capTable"]);
        } else {
          bootbox.alert("Error: Null response!.");
        }
      }, (e) => {
        bootbox.alert("Error: " + e.error.reason);
      })
    }
  }

  validatorMethod(data) {
    if (data.autorizedCapital == undefined || data.autorizedCapital == null || data.autorizedCapital == "" ||
      data.comments == undefined || data.comments == null || data.comments == "" ||
      data.date == undefined || data.date == null || data.date == "") {
      return true;
    }
    else {
      return false;
    }
  }

  validateComments(value) {
    if (value != undefined || value != "" || value != null) {
      this.mComments = false;
    }
    else{
      this.mComments = true;
    }
  }

  validateDate(value) {
    if (value != undefined || value != "" || value != null) {
      this.mDate = false;
    }
    else{
      this.mDate = true;
    }
  }


  close() {
    this.splitShareObj = {
      'splitRatio': '',
      'date': '',
      'comments': ''
    } 
    this.mSplitshare =false; 
    this.mDate = false; 
    this.mComments = false; 
    this.showDialogsplitstock = false;
    this.visibleChange.emit(this.showDialogsplitstock);
  }

  onKeydownEvent(e) {
    this.utility.restrictMinus(e);
  }

  ngOnInit() {
  }

}
