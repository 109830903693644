export class Employee {
  "employeeName": string;
  "address": string;
  "contactNumber": string;
  "email": string;
  "residentialStatus": string;
  "nationality": string;
  "manager": string;
  "employeeDesignation": string;
  "department": string;
  "legalName": string;
  "employeeId": string;
  "dateOfJoin": Date;
  "lastDay": Date;
  "grade":string;
  "employmentStatus":string;
  "imageURL"?:string
}
