<div *ngIf="vestDialog.show" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">{{ vestDialog.isApprover ? "Approve" : ""}} Vest ESOP</label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="container-fluid">
    <div class="row top-20">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <mat-label>Employee Name</mat-label><br>
        <mat-label class="matLabelCls">{{vestDialog.details.employeeName}}</mat-label>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <mat-label>ESOP Plan</mat-label><br>
        <mat-label class="matLabelCls">{{vestDialog.details.planName}}</mat-label>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <mat-label>Grant Price</mat-label><br>
        <mat-label class="matLabelCls">{{vestDialog.details.grantPrice}}</mat-label>
      </div>
    </div>
    <div class="row top-20">
      <div class="col-md-4 co-sm-4 col-lg-4">
        <mat-label>Trigger Event</mat-label><br>
        <mat-label class="matLabelCls">{{vestDialog.details.eventName}}</mat-label>
      </div>
      <div class="col-md-4 co-sm-4 col-lg-4">
        <mat-label>Event Completion Date</mat-label><br>
        <mat-label class="matLabelCls">{{vestDialog.details.eventDate | date:'dd-MMM-yyyy'}}</mat-label>
      </div>
      <div class="col-md-4 co-sm-4 col-lg-4">
        <mat-label>Number of Options That Can Be Vested</mat-label><br>
        <mat-label class="matLabelCls">{{vestDialog.details.numberOfSharesToVest}}</mat-label>
      </div>
    </div>
    <div class="row top-20">
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label class="required">% of Options to Vest</mat-label>
        <input [disabled]="vestDialog.isApprover" type="text" #sharesToVest 
          class="form-control top-10 text-align-right" placeholder="Percentage of options to vest" title="" required
          (keypress)="onlyNumberKey($event)" (keydown)=onKeydownEvent($event)
          (blur)="vesting.percentageOfSharesToVest = onChangeInput($event.target)"
          (ngModel)="vesting.percentageOfSharesToVest"
          [ngModel]="vesting.percentageOfSharesToVest | percent: '2.2'" />
        <div class="text-align-right" *ngIf="!vestDialog.isApprover" >
          <mat-label> You will Vest {{vestedShares}} Options </mat-label>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <mat-label>Return Unvested Options to the pool</mat-label><br>
        <label class="top-10">
          <input [disabled]="vestDialog.isApprover" class="alert-status-allHolders" id="allHolders" type="checkbox" [(ngModel)]="isApplytoAll"
            data-toggle="switch" checked data-inverse="false" data-off-color="default" title="" />
        </label>
        <div class="text-align-left" *ngIf="noOfHolders>0">
          <mat-label>{{noOfHolders}} Holders vesting total of {{totalOptions}} shares</mat-label>
        </div>
      </div>
    </div>
    <div class="row" [hidden]="!isApplytoAll" >
      <div class="col-md-12">
        <div  class="alert alert-info alert-labeled alrt-mrgn top-20 btm-20">
          <P type="button" class="close-help" data-dismiss="alert">
              <img src="../../../assets/message-close.png" />
          </P>
          <div class="alert-labeled-row">
              <div class="alert-label alert-label-left alert-labelled-cell">
                  <img src="../../../assets/message-info.svg" />
              </div>
              <p class="content-pg-1 alert-body alert-body-right alert-labelled-cell">
                {{ vestedShares }} Options will vest and {{ unvestedShares }} Options will lapse and return to the pool 
              </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="!isShowNotes && !vestDialog.isApprover" (click)="addNotes()">
          <span>
            <mat-label>Add Notes</mat-label><img class="left-10 cursor-hand" src="../../../../assets/action/addnotes.svg" />
          </span>
        </div>
        <div *ngIf="isShowNotes && !vestDialog.isApprover" (click)="removeNotes()">
          <span>
            <mat-label>Remove Notes</mat-label><img class="left-10 cursor-hand" src="../../../../assets/action/removenotes.svg" />
          </span>
        </div>
        <textarea [disabled]="vestDialog.isApprover" *ngIf="isShowNotes" [(ngModel)]="vesting.note" class="form-control top-10"
          placeholder="Add Notes"></textarea>
      </div>
    </div>
    <div class="row float-right top-20">
      <div class="col-xs-12 col-sm-12 col-md-12 btm-20">
        <button mat-raised-button id="cancel" color="warn" class="margin-right-sm" [disableRipple]="true" ngbTooltip="Cancel"
          (click)="close()">Cancel</button>
        <button mat-raised-button color="success" [disableRipple]="true" ngbTooltip='{{ vestDialog.isApprover ? "Approve" : "Submit" }}'
          (click)="saveExercise()">{{ vestDialog.isApprover ? "Approve" : "Submit"}}</button>
      </div>
    </div>
  </div>
</div>