<div class="container-fluid custom-fluid-container graybg" >
    <div id="overlay"></div>
    <div class="row top-20">
        <div class="col-md-12">
            <h5 class="page-hdr btm-15">Share Capital Settings</h5>
            <p class="content-pg-1 btm-20">
                Enter the total authorized and paid up capital of the company as on date. Also, provide the break up of equity and preference share capital.   
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-9">
            <mat-card  id="Share_Capital" (mouseleave)="hideHelp()" (mouseover)="showHelp('Share_Capital')">
                <form class="needs-validation" novalidate>
                    <div class="row btm-20 top-20">
                        <div class="col-md-2">
                            <mat-label class="top-10">Authorized Capital</mat-label>
                        </div>
                        <div class="col-md-5">
                            <mat-label>Equity</mat-label>
                            <input type="text" [(ngModel)]="equityAuthShareCapital" #ecapitalErr="ngModel" title="" (input)="csvEquityVal($event)"
                                class="form-control text-align-right top-10" placeholder="Equity Authorized Capital" (keydown)=onKeydownEvent($event) 
                                [ngModelOptions]="{standalone: true}" required />
                            <div *ngIf="ecapitalErr.errors">
                                <div  *ngIf="(ecapitalErr.dirty || ecapitalErr.touched) && !equityAuth"  class="alert alert-danger">
                                    Authorized Equity Capital is required!
                                </div>
                                <div  *ngIf="ecapitalErr.errors?.required && equityAuth"  class="alert alert-danger">
                                    Authorized Equity Capital is required!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <mat-label>Preference</mat-label>
                            <input type="text" [(ngModel)]="preferenceAuthShareCapital" #pcapitalErr="ngModel" title="" (input)="csvPreferenceVal($event)"
                                class="form-control text-align-right top-10" placeholder="Preference Authorized Capital" (keydown)=onKeydownEvent($event) 
                                [ngModelOptions]="{standalone: true}" required />
                            <div *ngIf="pcapitalErr.errors">
                                <div  *ngIf="(pcapitalErr.dirty || pcapitalErr.touched) && !prefAuth"  class="alert alert-danger">
                                    Authorized Preference Capital is required!
                                </div>
                                <div  *ngIf="pcapitalErr.errors?.required && prefAuth"  class="alert alert-danger">
                                    Authorized Preference Capital is required!
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row btm-20">
                        <div class="col-md-2">
                            <mat-label class="top-10">Paid Up Capital</mat-label>
                        </div>
                        <div class="col-md-5">
                            <mat-label>Equity</mat-label>
                            <input type="text" [(ngModel)]="equityPaidupShareCapital" title="" (input)="csvPaidUpEquityVal($event)"
                                class="form-control text-align-right top-10" placeholder="Equity Paid Up Capital"  (keydown)=onKeydownEvent($event) 
                                [ngModelOptions]="{standalone: true}" #pecapitalErr="ngModel" required />
                            <div *ngIf="pecapitalErr.errors">
                                <div  *ngIf="(pecapitalErr.dirty || pecapitalErr.touched) && !equityPaidup"  class="alert alert-danger">
                                    Paid Up Equity Capital is required!
                                </div>
                                <div  *ngIf="pecapitalErr.errors?.required && equityPaidup"  class="alert alert-danger">
                                    Paid Up Equity Capital is required!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <mat-label>Preference</mat-label>
                            <input type="text" [(ngModel)]="preferencePaidupShareCapital" #capitalErr="ngModel" title="" (input)="csvPaidUpPrefVal($event)"
                                class="form-control text-align-right top-10" placeholder="Preference Paid Up Capital" (keydown)=onKeydownEvent($event) 
                                [ngModelOptions]="{standalone: true}" #ppcapitalErr="ngModel" required />
                            <div *ngIf="ppcapitalErr.errors">
                                <div  *ngIf="(ppcapitalErr.dirty || ppcapitalErr.touched) && !prefPaidup"  class="alert alert-danger">
                                    Paid Up Preference Capital is required!
                                </div>
                                <div  *ngIf="ppcapitalErr.errors?.required && prefPaidup"  class="alert alert-danger">
                                    Paid Up Preference Capital is required!
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button id="save" [disableRipple]="disableripple"  color="success" class="left-20 float-right" ngbTooltip="Save" (click)=save()>Save</button> 
                            <button mat-raised-button id="cancel" [disableRipple]="disableripple"  color="warn"  class="float-right" ngbTooltip="Cancel" (click)=cancel()>Cancel</button>              
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>

        <!-- Helper section -->
        <div class="col-md-3">
            <div class="helper-container">
                <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}" [hidden]="helper == ''">
                <mat-card-header class="help-hdr">
                    <mat-card-title><label class="help-hdr-txt">{{
                        helpSections?.name
                    }}</label></mat-card-title>
                </mat-card-header>
                <hr />
                <mat-card-content class="card-help-content">
                    <div [innerHTML]="helpSections?.description"></div>
                </mat-card-content>
                </mat-card>
            </div>  
      </div>
    </div>
    <!-- Authorized Dialog -->
    <app-authorized-capital-modal *ngIf="isauthCapital"  [(showDialogauthorized)]="showDialogauthorized" (visibleChange)="getEventAutorized($event)">
    </app-authorized-capital-modal>

    <div id="overlay-authorized-capital">

    </div>


    <!-- Splitratio Dialog -->
    <app-splitstock-ratio-modal *ngIf="issplitStock" [(showDialogsplitstock)]="showDialogsplitstock" (visibleChange)="getEvent($event)">
    </app-splitstock-ratio-modal>

    <div id="overlay-splitstock-ratio">

    </div>
</div>
