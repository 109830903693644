<h1 mat-dialog-title>Edit Email Template</h1>
<div mat-dialog-content>
  <div *ngIf="data.action=='view'" #emailTemplatePreview>

  </div>
  <div style="overflow:hidden" *ngIf="data.action=='edit'">
    <editor (onSaveContent)="setPreview()" [(ngModel)]="body" [init]="{
          height: 400,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
        }"></editor>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button id="editChanges" (click)="togglePreview()" [color]="data.action=='view'?'warn':'success'">{{data.action=='view'?'Edit':'Preview'}}</button>
  <button mat-raised-button id="saveChanges" color="success" [mat-dialog-close]="emailTemplateData" *ngIf="data.action=='view'">Save</button>
  <button mat-raised-button id="discardChanges" color="warn" [mat-dialog-close]="undefined">Discard</button>
</div>