import { Component, OnInit, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { DxDataGridComponent } from "devextreme-angular";
import { DxTreeListComponent, DxTooltipComponent, DxPieChartComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Investors } from '../historical-round.model';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HistoricalRoundObject, CompanyCapitalDetails } from '../historical-round.model';
import { AuthenticationService } from 'src/app/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HistoricalRoundService } from '../historical-round.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utilities.service';
import { CacheProviderService } from 'src/app/services/cache-provider.service';
import { PercentPipe } from "@angular/common";
import { NavbarService } from 'src/app/services/navbar-reload';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDeleteComponent } from "src/app/confirm-delete/confirm-delete.component";
import { HttpResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;
interface captableSummary{
  roundDetails: [{isOptionsType: boolean}]
  shareholders:[],
  totalShares:number
}
@Component({
  selector: 'round-confirm-approval',
  templateUrl: 'confirm-approve.html',
  styleUrls: ["./historical-round-summary.component.scss"],
})
export class ConfirmRoundApproval {
  constructor(
    public dialogRef: MatDialogRef<ConfirmRoundApproval>,
    @Inject(MAT_DIALOG_DATA) public data) {}
}
@Component({
  selector: "app-summary",
  templateUrl: "./historical-round-summary.component.html",
  styleUrls: ["./historical-round-summary.component.scss"],
  providers: [PercentPipe],
})
export class HistoricalRoundSummaryComponent implements OnInit {
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;

  constructor(
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private historicalRoundService: HistoricalRoundService,
    private router: Router,
    private utilityService: UtilityService,
    private cacheService: CacheProviderService,
    private pp: PercentPipe,
    private navbarService: NavbarService,
    public dialog: MatDialog
  ) {
    this.dxCustomizeCsv = this.dxCustomizeCsv.bind(this);
    this.dxCustomizeDecimalCsv = this.dxCustomizeDecimalCsv.bind(this);
    this.dxCustomizeValueColumn = this.dxCustomizeValueColumn.bind(this);
    this.expandAllItem = this.expandAllItem.bind(this);
    // this.clickEventsubscription = this.navbarService
    //   .getClickEvent()
    //   .subscribe(() => {
    //     this.ngOnInit();
    //   });
  }

  @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;

  clickEventsubscription: Subscription;
  historicalRoundSummary = null;
  elementLoaded: boolean = false;
  historicalRoundDetails = new HistoricalRoundObject();
  capTableData = null;
  investorsArray = [];
  activeTabType: string = null;
  activeTabData;
  toolTipTarget: any;
  toolTipText: any;
  userDetails: any = null;
  usrCompanyCurrency: any = null;
  currencySymbol: "";
  expandAll: boolean = false;
  instrumentChart = null;
  instrumentArray = null;
  roundChart = null;
  isShowNote = false;
  warningNotes = null;
  summaryDetails = {
    total_auth : 0,
    total_paid_up:0,
    amt_raised:0,
    total_shares:"",
    shareholderCount:0
  }
  customsOption: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
    nav: true,
  };
  searchOptions: Observable<string[]>;
  totalSearchOptions = [
    "For_Approval",
    "Approved",
    "Created",
    "Offered",
    "Granted",
    "Rejected",
  ];
  searchFormControl = new FormControl();
  isProduction = environment.production;
  companyType = "";

  identifierCSS(identifier) {
    switch (identifier) {
      case "FF":
        return identifier;
      case "SF":
        return identifier;
      case "AF":
        return identifier;
      case "ES":
        return identifier;
      case "SA":
        return identifier;
      case "SB":
        return identifier;
      case "SC":
        return identifier;
      case "AC":
        return identifier;
      case "SD":
        return identifier;
      case "SE":
        return identifier;
      case "AF1":
        return "Def1";
      case "AF2":
        return "Def2";
      case "AF3":
        return "Def3";
      case "SP":
        return "Def4";
      case "SG":
        return "Def5";
      case "SH":
        return "Def6";
      case "SA1":
        return "Def7";
      case "SA2":
        return "Def8";
      case "SA3":
        return "Def1";
      case "SB1":
        return "Def2";
      case "SB2":
        return "Def3";
      case "SP1":
        return "Def4";
      default:
        return "defaultClass";
    }
  }

  searchEntered(e) {
    this.treeList.instance.searchByText(e);
  }

  expandAllItem() {
    if (this.expandAll) {
      this.treeList.instance.forEachNode((node) => {
        this.treeList.instance.collapseRow(node.key);
      });
      this.expandAll = false;
    } else {
      this.treeList.instance.forEachNode((node) => {
        this.treeList.instance.expandRow(node.key);
      });
      this.expandAll = true;
    }
  }

  displayAsTotal() {
    return "Total";
  }
  numberFormatter(rawNumber: string | number, args = 2) {
    var exp,
      rounded,
      suffixes = ["K", "M", "G", "T", "P", "E"];
    if (typeof rawNumber == "number") {
      return rawNumber;
    }
    let convertedNumber;
    let format = "";
    if (rawNumber.endsWith("Cr")) {
      convertedNumber = rawNumber.replace("Cr", "");
      convertedNumber = parseFloat(convertedNumber);
      format = "Cr";
    } else {
      format = rawNumber[rawNumber.length - 1];
      convertedNumber = rawNumber.replace(format, "");
      convertedNumber = parseFloat(convertedNumber);
    }
    if (convertedNumber < 1000) {
      return rawNumber;
    }
    if (Number.isNaN(convertedNumber)) {
      return null;
    }

    exp = Math.floor(Math.log(convertedNumber) / Math.log(1000));

    convertedNumber =
      (convertedNumber / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    return convertedNumber.toString() + " " + format;
  }
  stockholderDone(showRaw?) {
    let stockholderTotal = 0;
    if (this.historicalRoundSummary.capTable) {
      for (let index = 0; index < this.historicalRoundSummary.capTable.length-1; index++) {
        const group = this.historicalRoundSummary.capTable[index];
        stockholderTotal = stockholderTotal + group["netWorth"];
      }
      let stockHolder = this.utilityService.convertedFormatCurrency(
        this.usrCompanyCurrency,
        stockholderTotal
      );
      if(!stockHolder) {
        stockHolder = 0;
      }
      if (showRaw) {
        return stockHolder;
      } else return this.numberFormatter(stockHolder);
    }
  }

  roundDone(showRaw?) {
    let roundTotal = 0;
    if (this.roundChart) {
      this.roundChart.forEach((element) => {
        roundTotal = roundTotal + element["roundInvestmentAmount"];
      });
      let rounds = this.utilityService.convertedFormatCurrency(
        this.usrCompanyCurrency,
        roundTotal
      );
      if(!rounds) {
        rounds = 0;
      }
      this.summaryDetails.amt_raised = roundTotal;
      if (showRaw) return rounds;
      else return this.numberFormatter(rounds);
    }
  }

  getAmount(usrCompanyCurrency, amt) {
    let val = this.utilityService.formatCurrency(usrCompanyCurrency, amt);
    return val;
  }

  customizeRoundTooltip = (arg) => {
    return {
      text:
        arg.argumentText +
        " : " +
        this.getAmount(this.usrCompanyCurrency, arg.value),
      fontColor: "#ffffff",
      color: "#000000",
    };
  };

  customizeRoundTextCC(arg) {
    if (arg.value > 10000000) {
      let invAmt =
        Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e7
          ? Math.trunc(
              Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e7
            ) + "Cr"
          : // Six Zeroes for Millions
          Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e5
          ? Math.trunc(
              Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e5
            ) + "L"
          : // Three Zeroes for Thousands
          Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e3
          ? Math.trunc(
              Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e3
            ) + "K"
          : Math.trunc(
              Math.abs(Number(arg.point.data["roundInvestmentAmount"]))
            );
      return invAmt;
    }
  }

  customizeRoundTextRC(arg) {
    if (arg.value > 10000000) {
      let invAmt =
        Math.abs(Number(arg.point.data["roundInvestmentAmount"])) >= 1.0e9
          ? (
              Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e9
            ).toFixed(2) + "B"
          : // Six Zeroes for Millions
          Math.abs(Number(arg.point.data["roundInvestmentAmount"])) > 1.0e6
          ? (
              Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e6
            ).toFixed(2) + "M"
          : Math.abs(Number(arg.point.data["roundInvestmentAmount"])) > 1.0e3
          ? (
              Math.abs(Number(arg.point.data["roundInvestmentAmount"])) / 1.0e3
            ).toFixed(2) + "K"
          : Math.trunc(
              Math.abs(Number(arg.point.data["roundInvestmentAmount"]))
            );
      return invAmt;
    }
  }

  customizeText(arg) {
    if (arg.value * 100 > 3) {
      return arg.percentText;
    }
  }

  customizeTooltip(arg) {
    return {
      text: arg.argumentText + " : " + (arg.value * 100).toFixed(2) + "%",
      fontColor: "#ffffff",
      color: "#000000",
    };
  }

  dxCustomizeCsv(cellValue) {
    const value = this.getTotalValueTble(cellValue.value);
    if (value === 0) {
      return "0";
    } else {
      return value;
    }
  }

  dxCustomizeDecimalCsv(cellInfo) {
    if (cellInfo.value == null) {
      return;
    }
    let amount = cellInfo.value;
    let currency = this.usrCompanyCurrency;
    var y = amount.toString();
    y = Math.floor(y);
    if (currency && amount && y.toString().length > 5) {
      if (currency === "INR - ₹") {
        return Math.abs(Number(amount)) >= 1.0e7
          ? (Math.abs(Number(amount)) / 1.0e7).toFixed(2) + "Cr"
          : // Six Zeroes for Millions
          Math.abs(Number(amount)) >= 1.0e5
          ? (Math.abs(Number(amount)) / 1.0e5).toFixed(2) + "L"
          : // Three Zeroes for Thousands
            Math.trunc(Math.abs(Number(amount)));
      } else {
        return Math.abs(Number(amount)) >= 1.0e9
          ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + "B"
          : // Six Zeroes for Millions
          Math.abs(Number(amount)) > 1.0e6
          ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + "M"
          : Math.abs(Number(amount)) > 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + "K"
          : Math.trunc(Math.abs(Number(amount)));
      }
    } else {
      if (currency === "INR - ₹") {
        var x = amount.toString();
        var afterPoint = "";
        if (x.indexOf(".") > 0)
          afterPoint = x.substring(x.indexOf("."), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          lastThree +
          afterPoint.substring(0, 3);
        return res;
      } else {
        var x = amount.toString();
        var afterPoint = "";
        if (x.indexOf(".") > 0)
          afterPoint = x.substring(x.indexOf("."), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res =
          otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          lastThree +
          afterPoint.substring(0, 3);
        return res;
      }
    }
    // return cellInfo.valueText;
  }

  dxCustomizeValueColumn(cellValue) {
    if (cellValue.value == null) {
      return null;
    } else {
      const value = this.getTotalValueTble(cellValue.value);
      if (value === 0) {
        return "0";
      } else {
        return value;
      }
    }
  }

  getTotalValueTble(value) {
    if (value > 0) {
      const valueStr = value.toString();
      if (valueStr.split(".")[0].length > 5) {
        return this.utilityService.convertedFormatCurrency(
          this.usrCompanyCurrency,
          value
        );
      } else {
        return this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          value
        );
      }
    } else {
      return 0;
    }
  }

  calculateCellValue(data) {
    return $.map(data["roundDetails"], function (o) {
      return o.roundIdentifier;
    });
  }

  calculateRoundFilterExpression(value) {
    var column = this;
    console.log(value);
    if (value) {
      var selector = function (data) {
        var values = column.calculateCellValue(data);
        return values && values.indexOf(value) >= 0;
      };
      return [selector, "=", true];
    }
  }

  showRoundTooltip(roundIdentifier, rounds) {
    let round = _.find(rounds, { roundIdentifier: roundIdentifier });
    return round.roundName;
  }

  logEvent(eventName, event) {
    switch (eventName) {
      case "CellHoverChanged":
        if (
          event.rowType === "data" &&
          (event.column.dataField === "investorName" ||
            event.column.dataField === "securityType" ||
            event.column.dataField === "date" ||
            event.column.dataField === "roundName") &&
          event.eventType === "mouseover"
        ) {
          this.toolTipText = event.text;
          this.toolTipTarget = event.cellElement;
          this.tooltip.instance.show();
        } else if (
          event.rowType === "data" &&
          (event.column.dataField === "pricePerShare" ||
            event.column.dataField === "par" ||
            event.column.dataField === "premium" ||
            event.column.dataField === "amount" ||
            event.column.dataField === "numberOfShares" ||
            event.column.dataField === "value") &&
          event.eventType === "mouseover"
        ) {
          if (event.displayValue == null) {
            return this.tooltip.instance.hide();
          } else if (event.displayValue === 0) {
            this.toolTipText = event.text;
          } else {
            this.toolTipText = this.utilityService.formatCurrency(
              this.usrCompanyCurrency,
              event.displayValue
            );
          }
          this.toolTipTarget = event.cellElement;
          this.tooltip.instance.show();
        } else if (
          event.rowType === "data" &&
          event.column.dataField === "splitNumber" &&
          event.eventType === "mouseover"
        ) {
          if (event.displayValue == null) {
            return this.tooltip.instance.hide();
          } else if (event.displayValue === 0) {
            this.toolTipText = event.text;
          } else {
            this.toolTipText = "1:" + event.text;
          }
          this.toolTipTarget = event.cellElement;
          this.tooltip.instance.show();
        } else if (
          event.rowType === "totalFooter" &&
          (event.column.dataField === "numberOfShares" ||
            event.column.dataField === "amount") &&
          event.eventType === "mouseover"
        ) {
          if (event.displayValue === 0) {
            this.toolTipText = event.summaryItems[0].value;
          } else {
            this.toolTipText = this.utilityService.formatCurrency(
              this.usrCompanyCurrency,
              (this.toolTipText = event.summaryItems[0].value)
            );
          }
          this.toolTipTarget = event.cellElement;
          this.tooltip.instance.show();
        } else if (
          (event.rowType === "data" || event.rowType === "totalFooter") &&
          event.eventType === "mouseout"
        ) {
          this.tooltip.instance.hide();
        }
    }
  }

  onCellHoverChanged(e: any) {
    if (
      e.rowType === "data" &&
      e.column.dataField === "holdingPercentage" &&
      e.eventType === "mouseover"
    ) {
      this.toolTipText = this.pp.transform(e.text, "2.2-2");
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      e.column.dataField === "totalShares" &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (
      e.rowType === "data" &&
      (e.column.dataField === "pricePerShare" ||
        e.column.dataField === "amountInvested" ||
        e.column.dataField === "commonShares" ||
        e.column.dataField === "totalDilutedShares" || 
        e.column.dataField === "preferenceSharesFDB" || 
        e.column.dataField === "preferenceShares") &&
      e.eventType === "mouseover"
    ) {
      if (e.displayValue === 0) {
        this.toolTipText = e.text;
      } else if (e.displayValue == null) {
        this.toolTipText = 0;
      } else {
        this.toolTipText = this.utilityService.formatCurrency(
          this.usrCompanyCurrency,
          e.displayValue
        );
      }
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && e.eventType === "mouseout") {
      this.tooltip.instance.hide();
    }
  }

  // onToolbarPreparing(e) {
  //   let toolbarItems = e.toolbarOptions.items;
  //   toolbarItems.unshift({
  //     widget: "dxButton",
  //     cssClass: "expand-all-btn",
  //     options: {
  //       icon: "repeat",
  //       hint: "Expand All/Collapse All",
  //       onClick: function (e) {
  //         this.expandAllItem();
  //       }.bind(this),
  //     },
  //     location: "after",
  //   });
  // }

  approveHistoricalRound() {
    const dialogRef = this.dialog.open(ConfirmRoundApproval, {
      data : { total_auth: this.summaryDetails.total_auth, total_paid_up: this.summaryDetails.total_paid_up, amt_raised: this.summaryDetails.amt_raised, total_shares: this.summaryDetails.total_shares, shareholderCount: this.summaryDetails.shareholderCount },
      width: "400px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === "confirm") {
        this.ngxLoader.start();
        this.authService
          .approveHistoricalRound(this.historicalRoundDetails)
          .subscribe(
            (response) => {
              this.historicalRoundService.resetRoundData();
              this.getUserDetails(false);
              this.ngxLoader.stop();
            },
            (e) => {
              this.ngxLoader.stop();
              this.toastr.error(e.error.reason, "Failure!");
            }
          );
      }
    });
  }

  tabChanged(event, investors: Array<any>, tabs: Array<any>) {
    this.warningNotes = null;
    this.activeTabType = tabs[event.index].tabType;
    let pointNum = 1;
    this.activeTabData = tabs[event.index];
    this.investorsArray = tabs[event.index].investorData;
    this.investorsArray.forEach((investor, idx) => {
      if (investor.warningNotes != null) {
        this.warningNotes =
          this.warningNotes + pointNum + ". " + investor.warningNotes + "\n";
        pointNum++;
      }
    });
    this.loadJSStyles();
  }

  getUserDetails(isHistoricalRoundDiscarded: boolean) {
    this.ngxLoader.start();
    this.authService.getUserDetails().subscribe(
      (res) => {
        this.cacheService.set("userDetails", JSON.stringify(res));
        this.navbarService.sendClickEvent();
        setTimeout(() => {
          if (isHistoricalRoundDiscarded) {
            this.toastr.success(
              "Historical Round Successfully Discarded.",
              "Success!"
            );
            // if(this.cacheService.get("isInitialApproval")) {
            //   this.cacheService.remove("isInitialApproval");
            // }
            this.router.navigate(["/landing", "onboarding", { newComp: true }])
          } else {
            this.router.navigate(["/landing", "dashboard", "capTable"]).then(() => {window.location.reload()});
          }
        }, 300);
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Error");
      }
    );
  }

  getHistoricalRoundDetails() {
    this.ngxLoader.start();
    this.authService.getHistoricalRoundDetails().subscribe(
      (response: any) => {
        this.historicalRoundDetails = response;
        this.investorsArray = this.historicalRoundDetails.investors;
        this.roundPieChart(this.historicalRoundDetails);
        this.instrumentPieChart(this.historicalRoundDetails);
        this.shareholderPieChart(this.historicalRoundDetails);

        if (this.historicalRoundDetails.companyCapitalDetails == null) {
          this.historicalRoundDetails.companyCapitalDetails =
            new CompanyCapitalDetails();
        }
        this.summaryDetails.total_auth = this.historicalRoundDetails.companyCapitalDetails.equityAuthorizedShareCapital + this.historicalRoundDetails.companyCapitalDetails.preferenceAuthorizedShareCapital
        this.summaryDetails.total_paid_up = this.historicalRoundDetails.companyCapitalDetails.equityPaidUpShareCapital + this.historicalRoundDetails.companyCapitalDetails.preferencePaidUpShareCapital
        if (this.historicalRoundSummary == null) {
          this.getHistoricalRoundSummary(this.historicalRoundDetails);
        } else {
          this.isShowNote =
            this.historicalRoundSummary.warningNotes != null &&
            this.historicalRoundSummary.warningNotes != "";
          this.formatCapTableData();
          this.ngxLoader.stop();
        }
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  getHistoricalRoundSummary(roundDetails) {
    this.authService.historicalRoundDetails(roundDetails).subscribe(
      (response) => {
        this.historicalRoundSummary = response;
        this.isShowNote =
          this.historicalRoundSummary.warningNotes != null &&
          this.historicalRoundSummary.warningNotes != "";
        this.addShareholders()
        this.formatCapTableData();
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  shareholderPieChart(roundDetails) {
    this.authService.getShareholderPieChart(roundDetails).subscribe(
      (response) => {
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  instrumentPieChart(roundDetails) {
    this.ngxLoader.start();
    this.authService.getInstrumentPieChart(roundDetails).subscribe(
      (response) => {
        this.instrumentChart = response;
        this.formatInsData();
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  roundPieChart(roundDetails) {
    this.ngxLoader.start();
    this.authService.getRoundPieChart(roundDetails).subscribe(
      (response: any) => {
        response = response.filter(item => {
            if(item.roundInvestmentAmount != 0) {
              return item
            }
        })
        if (response.length > 6) {
          this.getChartViewData(response);
        } else {
          this.roundChart = response;
        }
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure!");
      }
    );
  }

  formatInsData() {
    let completeInstrumentArray = [];
    if (this.instrumentChart != null && this.instrumentChart.length > 0) {
      this.instrumentChart.forEach((element) => {
        let obj = {
          instrument: element.instrumentClassName,
          value: element.holdingPercentage.toFixed(10),
        };
        completeInstrumentArray.push(obj);
      });
    }
    if (completeInstrumentArray.length > 6) {
      this.getInstrumentChartViewData(completeInstrumentArray);
    } else {
      this.instrumentArray = completeInstrumentArray;
    }
  }

  getChartViewData(chartData) {
    let others = chartData.slice(5);
    let first5 = chartData.slice(0, 5);
    let total = 0;
    for (let i = 0; i < others.length; i++) {
      total += others[i].roundInvestmentAmount;
    }
    first5.push({
      roundName: "Others",
      roundInvestmentAmount: total,
      roundIdentifier: "OTHERS",
    });
    this.roundChart = first5;
  }

  getInstrumentChartViewData(chartData) {
    let others = chartData.slice(5);
    let first5 = chartData.slice(0, 5);
    let total = 0;
    for (let i = 0; i < others.length; i++) {
      total += others[i].value;
    }
    first5.push({ instrument: "Others", value: total });
    this.instrumentArray = first5;
  }

  formatCapTableData() {
    let completeCapTableData = [];
    if (
      this.historicalRoundSummary.capTable != null &&
      this.historicalRoundSummary.capTable.length > 0
    ) {
      for (let index = 0; index < this.historicalRoundSummary.capTable.length-1; index++) {
        const group = this.historicalRoundSummary.capTable[index];
        let capTableObj = {
          shareholder: group["name"],
          val: group["holdingPercentage"].toFixed(10),
        };
        completeCapTableData.push(capTableObj);
      }
    }

    if (completeCapTableData.length > 6) {
      this.getStackHoldersViewData(completeCapTableData);
    } else {
      this.capTableData = completeCapTableData;
    }
  }

  getStackHoldersViewData(chartData) {
    let others = chartData.slice(5);
    let first5 = chartData.slice(0, 5);
    let total = 0;
    for (let i = 0; i < others.length; i++) {
      total += Number(others[i].val);
    }
    first5.push({ shareholder: "Others", val: Number(total).toFixed(10) });
    this.capTableData = first5;
  }

  discardHistoricalRound() { 
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : { header :"Confirm Discard?" , text:"Are you sure you want to Discard ?"},
    width: "400px",
    disableClose: true,
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result && result === "confirm") {
      this.ngxLoader.start();
    
    const userDetails = JSON.parse(this.cacheService.get("userDetails"));
    if (userDetails != null) {
      this.ngxLoader.start();
      this.authService.discardHistoricalRound(userDetails.company.id).subscribe(
        (response) => {
          if (response["resultCode"]) {
            localStorage.removeItem('companyId');
            localStorage.removeItem('companyName');
            this.getUserDetails(true);
          } else {
            this.ngxLoader.stop();
          }
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.error.reason, "Failure!");
        }
        );
    }
  }
})
  }
  publishHistoricalRound() { 
    if(this.companyType === null || this.companyType === "") {
      this.toastr.error("Please Select Company Type", "Failure!");
      return;
    }

    this.ngxLoader.start();
    const userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.ngxLoader.start();
        this.authService
          .publishHistoricalRound(userDetails.company.id, this.companyType === "private")
          .subscribe(
            (response) => {
              console.log('publish successful. companyId:', response)
              this.ngxLoader.stop();
              const companyId = response["data"]["companyId"];
              const jwtToken = response["data"]["token"];
              const url = environment.newCaptableUrl + "/landing/dashboard/" + companyId + "/" + ((jwtToken !== undefined && jwtToken !== null) ? jwtToken.split(" ")[1] : "");
              window.open(url);
            },
            (e) => {
              this.ngxLoader.stop();
              this.toastr.error(e.error.reason, "Failure!");
            }
          );
          
  }
  
  ngOnInit() {
    this.utilityService.hideOverlay();
    this.getHistoricalRoundDetails();

    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails["company"]["companyCurrency"];
    } else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.currencySymbol = this.usrCompanyCurrency.split("-")[1].trim()
    this.searchOptions = this.searchFormControl.valueChanges.pipe(
      map((value) => this._filter(value))
    );
    // this.loadJSStyles();
  }

  loadJSStyles() {
    $('[data-toggle="switch"]').bootstrapSwitch({
      onText: "Yes",
      offText: "No",
    });
    $('.priced-round').bootstrapSwitch('disabled',false);
    $('.priced-round').bootstrapSwitch('state', this.activeTabData.isPricedRound);
    $('.priced-round').bootstrapSwitch('disabled',true);
  }

  ngDoCheck() {
    if (!this.elementLoaded && $('[data-toggle="switch"]').length > 0) {
      this.elementLoaded = true;
      this.loadJSStyles();
    }
  }

  addShareholders(){
    const capTable:Array<captableSummary> = this.historicalRoundSummary.capTable
    let total_shareHolders = 0
    let no_of_shares = 0
    for (let index = 0; index < capTable.length-1; index++) {
      const shareholder = capTable[index];
      if(shareholder.roundDetails && !shareholder.roundDetails[0].isOptionsType){
        total_shareHolders += shareholder.shareholders.length
      }
      no_of_shares+=shareholder.totalShares
    }
    this.summaryDetails.shareholderCount = total_shareHolders
    this.summaryDetails.total_shares = no_of_shares.toLocaleString('en-IN')
  }

  exportFile(exportType?) {
    if(exportType == 'round') {
      this.authService.exportHistoricalRoundBasedReport().subscribe(
        (res) => {
          this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
          this.ngxLoader.stop();
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.reason, 'Warning');
        }
      );
    } else if(exportType == 'security') {
      this.authService.exportHistoricalSecurityBasedReport().subscribe(
        (res) => {
          this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
          this.ngxLoader.stop();
        },
        (e) => {
          this.ngxLoader.stop();
          this.toastr.error(e.reason, 'Warning');
        }
      );
    } else {
      this.authService.exportHistoricalTransactionReport(this.historicalRoundSummary.capTable).subscribe((res: HttpResponse<any>) => {
        this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      }, e => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Warning');
      });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return []
    if (this.searchFormControl.value.length < 1) return [];
    return this.totalSearchOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

}
