<div class="row" (mouseleave)="hideHelp()">
  <div class="col-md-9 col-sm-12 col-xs-12">
    <form class="needs-validation" novalidate>
      <mat-card id="Company_Details_Card" (mouseover)="showHelp('Company_Details_Card')">
        <mat-card-header>
          <mat-card-title>Company Details</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1"> 
                Enter the basic company details here. All information must be in line with the memorandum, 
                articles and certificate of incorporation of the company.
              </p>
            </div>
          </div>

          <div class="row top-20">
            <div class="col-md-12">
              <div class="row">
                <div class=" col-lg-5 col-md-6">
                  <mat-label class="required">Name of the Company</mat-label>
                  <input type="text" class="form-control top-10" [(ngModel)]="model.nameOfTheCompany" required title=""
                    #companyName="ngModel" placeholder="Enter company name" [ngModelOptions]="{standalone: true}" [readonly]="registerNewCompany"
                    onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 96 && event.charCode <= 105) || (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 46 || event.charCode == 32);">
                  <div class="alert alert-danger" *ngIf="companyName.invalid">
                    <span class="alert alert-danger" *ngIf="(companyName.dirty || companyName.touched) && !companyNameValid">
                      Name of the Company is required!
                    </span>
                    <span class="alert alert-danger" *ngIf="companyName.errors?.required && companyNameValid">
                      Name of the Company is required!
                    </span>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6">
                  <mat-label class="required">CIN Number</mat-label>
                  <input type="text" class="form-control top-10" (keypress)="allowAlphaNumericKey($event)" [readonly]="companyExists"
                    [(ngModel)]="model.cinNumber" #cinNumber="ngModel" title="" placeholder="CIN number" [ngModelOptions]="{standalone: true}" required>
                  <div class="alert alert-danger" *ngIf="cinNumber.invalid">
                    <span class="alert alert-danger" *ngIf="(cinNumber.dirty || cinNumber.touched) && !cinNoValid">
                      CIN Number is required!
                    </span>
                    <span class="alert alert-danger" *ngIf="cinNumber.errors?.required && cinNoValid">
                      CIN Number is required!
                    </span>
                  </div>
                </div>
              </div>

              <div class="row top-20">
                <div class="col-lg-5 col-md-6">
                  <mat-label class="required">Type of Company</mat-label>
                  <select class="form-control top-10 cursor-hand" id="typeOfTheCompany" [(ngModel)]="model.typeOfTheCompany"
                    title="" [ngModelOptions]="{standalone: true}" required #typeOfTheCompany="ngModel">
                    <option *ngFor="let company of typeofCompanies">{{company}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="typeOfTheCompany.invalid">
                    <span class="alert alert-danger"
                      *ngIf="(typeOfTheCompany.dirty || typeOfTheCompany.touched) && !companyTypeValid">
                      Type of Company is required!
                    </span>
                    <span class="alert alert-danger" *ngIf="typeOfTheCompany.errors?.required && companyTypeValid">
                      Type of Company is required!
                    </span>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <mat-label class="required">Date of Incorporation</mat-label>
                  <div class="input-eyePlaceholder">
                    <input matInput [matDatepicker]="dp" class="form-control top-10" value="model.dateOfIncorporation"
                      [(ngModel)]="model.dateOfIncorporation"  #dateOfIncorporation="ngModel" max="9999-12-31"
                      placeholder="DD-MMM-YYYY" title="" [ngModelOptions]="{standalone: true}" required/>
                    <mat-datepicker-toggle matSuffix [for]="dp">
                      <mat-icon matDatepickerToggleIcon></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </div>
                  <div class="alert alert-danger" *ngIf="dateOfIncorporation.invalid">
                    <span class="alert alert-danger"
                      *ngIf="(dateOfIncorporation.dirty || dateOfIncorporation.touched) && !incorporateDtValid">
                      Date of Incorporation is required!
                    </span>
                    <span class="alert alert-danger" *ngIf="dateOfIncorporation.errors?.required && incorporateDtValid">
                      Date of Incorporation is required!
                    </span>
                  </div>
                </div>
              </div>

              <div class="row top-20">
                <div class="col-lg-5 col-md-6">
                  <mat-label class="required">Corporate office address</mat-label>
                  <textarea class="form-control top-10" [(ngModel)]="model.corporateOfficeAddress"
                    #corporateOfficeAddress="ngModel" placeholder="Address" [ngModelOptions]="{standalone: true}" required></textarea>
                  <div class="alert alert-danger" *ngIf="corporateOfficeAddress.invalid">
                    <span class="alert alert-danger"
                      *ngIf="(corporateOfficeAddress.dirty || corporateOfficeAddress.touched) && !officeAddressValid">
                      Corporate office address is required!
                    </span>
                    <span class="alert alert-danger"
                      *ngIf="corporateOfficeAddress.errors?.required && officeAddressValid">
                      Corporate office address is required!
                    </span>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6">
                  <mat-label class="required">Company Email Id</mat-label>
                  <input type="email" class="form-control top-10" [(ngModel)]="model.companyEmailId"
                    #companyEmailId="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" placeholder="Enter Company email id" 
                    [ngModelOptions]="{standalone: true}" required/>
                  <div class="alert alert-danger" *ngIf="companyEmailId.errors">
                    <div [hidden]="!companyEmailId.errors?.pattern">
                      Invalid Email
                    </div>
                    <div *ngIf="companyEmailId.errors?.required && emailValid">
                      Valid Company Email Id is required!
                    </div>
                  </div>
                </div>
              </div>

              <div class="row top-20">
                <div class="col-lg-5 col-md-6">
                  <mat-label>DIPP Certificate Number</mat-label>
                  <input type="text" class="form-control top-10" [(ngModel)]="model.dippCertificateNumber"  title="" (keypress)="allowAlphaNumericKey($event)" 
                  [ngModelOptions]="{standalone: true}" placeholder="DIPP number" >
                </div>
                <div class="col-lg-5 col-md-6">
                  <mat-label>Company Website</mat-label>
                  <input type="text" class="form-control top-10" [(ngModel)]="model.companyWebsite" title=""  [ngModelOptions]="{standalone: true}"
                        placeholder="Company Website" #companyWebErr="ngModel" 
                        pattern="^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$"/>
                  <div *ngIf="companyWebErr.errors">
                    <span class="alert alert-danger" *ngIf="companyWebErr.errors?.required && companyWebsitevalid">
                      Company Website is required!
                    </span>
                    <span class="alert alert-danger" [hidden]="!companyWebErr.errors?.pattern">
                      Company Website URL format is wrong!
                    </span>
                  </div>
                </div>
              </div>

              <div class="row top-20">
                <div class="col-xs-5 col-sm-5 col-md-4">
                  <mat-label>Upload Company Logo</mat-label>
                  <div>
                    <img class="profile-image top-10" *ngIf="model.companyLogoPath" [src]="model.companyLogoPath" />
                    <input type="text" title="" #fileErr="ngModel" [(ngModel)]="fileName" class="form-control top-10" placeholder="Upload Photo" 
                          [ngModelOptions]="{standalone: true}" required  [ngClass]="companyLogoInvalid ? 'is-invalid' : 'is-valid'"/>
                    <div *ngIf="companyLogoInvalid">
                      <span class="alert alert-danger">
                        Company Logo is required!
                      </span>
                    </div>
                  </div>
    
                  <app-upload-image *ngIf="shouldLogoShowDialog" [(showDialog)]="shouldLogoShowDialog" [croppedImage]="croppedImage"
                    (visibleChange)="getEvent()" (imageSaved)="onImageSaved($event)">
                  </app-upload-image>
                </div>
    
                <div class="col-xs-1 col-sm-1 col-md-1">
                  <mat-label></mat-label>
                  <img (click)="openUploadImage()" class="top-40 cursor-hand" ngbTooltip="Upload"
                    src="../../../assets/upload.svg" />
                </div>
                <div class="col-lg-5 col-md-6">
                  <mat-label class="required">Company Currency</mat-label>
                  <select class="form-control top-10" [(ngModel)]="model.companyCurrency" #companyCurrency="ngModel"
                  [ngModelOptions]="{standalone: true}" required>
                    <option value="null" selected disabled>Select Currency</option>
                    <option *ngFor="let currency of currencyList; let i = index" value="{{ currency }}">
                      {{ currency }}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="companyCurrency.errors">
                    <span class="alert alert-danger"
                      *ngIf="(companyCurrency.dirty || companyCurrency.touched) && !companyCurrencyValid">
                      Company Currency is required!
                    </span>
                    <span class="alert alert-danger" *ngIf="companyCurrency.errors?.required && companyCurrencyValid">
                      Company Currency is required!
                    </span>
                  </div>
                </div>
              </div>

              <div class="row top-20">
                <div class="col-lg-5 col-md-6">
                    <mat-label>Doing Business As</mat-label>
                    <input type="text" class="form-control top-10" [(ngModel)]="model.doingBusinessAs" title="" (keypress)="allowAlphaNumericKey($event)" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="col-lg-5 col-md-6"></div>
              </div>

            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card id="Business_Details_Card" class="top-20" (mouseover)="showHelp('Business_Details_Card')">
        <mat-card-header>
          <mat-card-title>Business Details</mat-card-title>
        </mat-card-header>

        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>
          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the nature of the business the company is undertaking and select the corresponding industry from the drop down. 
                The industry must be listed on the ‘National Industrial Classification’ standard provided by the government.
              </p>
            </div>
          </div>

          <div class="row top-20">
            <div class="col-lg-5 col-md-6">
              <mat-label class="required">Category</mat-label>
              <div class="top-10">
                <ng-select [items]="businessList"   [ngModelOptions]="{standalone: true}" class="custom" #businessErr="ngModel"
                    [multiple]="true"
                    [clearable]="false"
                    [selectableGroup]="true"
                    [closeOnSelect]="false"
                    bindValue="item"
                    [(ngModel)]="model.business"
                    [selectOnTab]="true"
                    [addTag]="true" 
                    typeToSearchText="Search Category"
                    addTagText="Create New Category"
                    placeholder="Select Category"
                    [ngClass]="businessInvalid ? 'is-Binvalid' : ''" required>

                    <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                      <div>
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" style="vertical-align: middle;" />
                          <label class="form-check-label ml-2">{{item}}</label>
                      </div>
                    </ng-template>
                </ng-select>
              </div>
              <span class="alert alert-danger" *ngIf="(model.business == null || model.business.length == 0 ) && businessInvalid">
                Category is required!
              </span> 
              <!-- <span class="alert alert-danger" *ngIf="businessErr.invalid && !businessInvalid">
                Category is required!
              </span>  -->
            </div>
            <div class="col-lg-5 col-md-6">
              <mat-label class="required">Industry</mat-label>
              <div class="top-10">
                <ng-select [items]="industries" [ngModelOptions]="{standalone: true}" class="custom" #industryErr="ngModel"
                    [multiple]="true"
                    [clearable]="false"
                    [selectableGroup]="true"
                    [closeOnSelect]="false"
                    bindValue="item"
                    [selectOnTab]="true"
                    [addTag]="true" 
                    typeToSearchText="Search Indusrties"
                    addTagText="Create New Industry"
                    [(ngModel)]="model.industry"
                    placeholder="Select Industry"
                    [ngClass]="industryInvalid ? 'is-Iinvalid' : ''"  required >
                    
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                      <div >
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" style="vertical-align: middle;"/>
                          <label class="form-check-label ml-2">{{item}}</label>
                      </div>
                    </ng-template>
                </ng-select>
              </div>
              <span class="alert alert-danger" *ngIf="(model.industry == null || model.industry.length == 0 ) && industryInvalid">
                Industry is required!
              </span> 
              <!-- <span class="alert alert-danger" *ngIf="industryErr.invalid && !industryInvalid">
                Industry is required!
              </span>  -->
            </div>
          </div>

          <div class="row top-20">
            <div class="col-lg-5 col-md-6">
              <mat-label class="required">NIC Code</mat-label>
              <input type="text" class="form-control top-10" [(ngModel)]="model.nic" #nic="ngModel" placeholder="NIC Code" 
                (keypress)="allowAlphaNumericKey($event)" [ngModelOptions]="{standalone: true}" required>
              <div class="alert alert-danger" *ngIf="nic.invalid" >
                <span class="alert alert-danger" *ngIf="(nic.dirty || nic.touched) && !nicValid">
                  NIC is required!
                </span>
                <span class="alert alert-danger" *ngIf="nic.errors?.required && nicValid">
                  NIC is required!
                </span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card id="Founders_Card" class="top-20" (mouseover)="showHelp('Founders_Card')">
        <mat-card-header>
          <mat-card-title>Founders</mat-card-title>
        </mat-card-header>

        <mat-divider class="divider-line"></mat-divider>

        <mat-card-content>
          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the names of all the founders of the company and their corresponding email ID.  
                Click on the profile button to update the founder profile.  
              </p>
            </div>
          </div>
          <div class="top-20">
            <input type="checkbox" [(ngModel)]="areAllFoundersSelected" (change)="selectAllFounders()" 
                [ngModelOptions]="{standalone: true}"  ngbTooltip="Select All">
            <img class="delete-icon cursor-hand" src="../../assets/trash-bin.svg" (click)="deleteAllSelectedFounders()" ngbTooltip="Delete All"/>
          </div>
          <div class="row top-20">
            <div class="col-lg-10 col-sm-12 col-xs-12">
              <table class="table-common">
                <thead>
                  <tr>
                    <th width="5%"></th>
                    <th width="32%" class="text-align-left">Founders</th>
                    <th width="27%" class="text-align-left">Email ID</th>
                    <th width="23%" class="text-align-left">Nationality</th>
                    <th width="13%"><img src="../../assets/purple-plus-round.svg" class="cursor-hand" (click)="addFounder()" ngbTooltip="Add Founder"/> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let founder of model.founders; let i= index">
                    <td>
                      <mat-checkbox [disableRipple]="true" [(ngModel)]="founder.isSelected" class="checkbox" ngbTooltip="Select"
                        (change)="selectFounder()" [ngModelOptions]="{standalone: true}">
                        <span style="color: transparent;">.</span>
                      </mat-checkbox>
                    </td>
                    <td>
                      <input type="text" class="form-control" [(ngModel)]="founder.fullName"
                        [ngClass]="(founder.isEdit && fName) ? 'is-invalid' : 'is-valid'" #fnameErr="ngModel" [ngModelOptions]="{standalone: true}" required
                        [readonly]="(founder.id > 0 && !founder.isEdit) || ((founder.id == 0 && !founder.isEdit))" placeholder="Enter Founder name">
                        <div class="alert alert-danger  text-align-left span-block" *ngIf="fnameErr.errors">
                          <span *ngIf="fnameErr.errors?.required && fName">
                              Founder Name is required!
                          </span>
                        </div>
                    </td>
                    <td>
                      <input type="email" class="form-control" [(ngModel)]="founder.emailId"
                        [ngClass]="(founder.isEdit && fEmail) ? 'is-invalid' : 'is-valid' "
                        [readonly]="(founder.id > 0 && !founder.isEdit) || ((founder.id == 0 && !founder.isEdit))" placeholder="Email id"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" title="" #mailErr="ngModel"
                        [ngModelOptions]="{standalone: true}" required>
                        <div class="alert alert-danger  text-align-left span-block" *ngIf="mailErr.errors">
                          <span *ngIf="mailErr.errors?.required && fEmail">
                              Email is required!
                          </span>
                          <span [hidden]="mailErr.errors?.required && !mailErr.errors?.pattern">
                              Invalid Email!
                          </span>
                        </div>
                    </td>
                    <td>
                      <select class="form-control" [(ngModel)]='founder.nationality' #nationalityErr="ngModel"
                        title="" [ngClass]="(founder.isEdit && fNationality) ? 'is-invalid' : 'is-valid' " [disabled]="(founder.id > 0 && !founder.isEdit) || ((founder.id == 0 && !founder.isEdit))"
                        #nationalityErr="ngModel" [ngModelOptions]="{standalone: true}" required >
                        <option   *ngFor='let country of countryList; let k = index'>{{country}}
                        </option>
                      </select> 
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="nationalityErr.errors">
                        <span  *ngIf="nationalityErr.errors?.required  && fNationality">
                          Nationality is required!
                        </span>
                      </div>
                    </td>
                    <td>
                      <img  src="../../assets/edit.svg" class="cursor-hand" *ngIf="(founder.id > 0 && !founder.isEdit) || ((founder.id == 0 && !founder.isEdit))"  (click)="editFounderRow(i)" ngbTooltip="Edit"/>
                      <img  src="../../assets/save.svg" class="cursor-hand" *ngIf="(founder.id > 0 && founder.isEdit) || ((founder.id == 0 && founder.isEdit))"  (click)="saveFounderRow(i)" ngbTooltip="Save"/>&nbsp;
                      <img  src="../../../assets/converti-profile.svg" class="cursor-hand" (click)="openFounderDetailsPopup(i)" ngbTooltip="Profile details"/>&nbsp;
                      <img  src="../../assets/trash-bin.svg" class="cursor-hand" (click)="deleteIndividualFounder(i)" ngbTooltip="Delete"/>
                    </td>
                    <app-founder-details-popup [showDialog]="founder.shouldShowDialog"
                      [founderDetailsModel]="founder"
                      (closeDetails)="onCloseFounderDetails($event)">
                    </app-founder-details-popup>
                  </tr>
                  <tr *ngIf="model.founders.length==0">
                    <td colspan="6" class="text-align-center">
                      <p>Click on the '+' to add Founder</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card id="Directors_Card" class="company-details-card top-20" (mouseover)="showHelp('Directors_Card')">
        <mat-card-header>
          <mat-card-title>Directors</mat-card-title>
        </mat-card-header>
        <mat-divider class="divider-line"></mat-divider>
        <mat-card-content>

          <div class="row top-20">
            <div class="col-md-12">
              <p class="content-pg-1">
                Enter the names and director identification number of the directors appointed by the company. 
                Click on the profile button to update the director profile. 
              </p>
            </div>
          </div>

          <div class="top-20">
            <input type="checkbox" [(ngModel)]="areAllDirectorsSelected" (change)="selectAllDirectors()" 
              [ngModelOptions]="{standalone: true}" ngbTooltip="Select All">
            <img class="delete-icon cursor-hand" src="../../assets/trash-bin.svg" (click)="deleteAllSelected()" ngbTooltip="Delete All"/>
          </div>

          <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <table class="table-common top-20">
                <thead>
                  <tr>
                    <th width="5%"></th>
                    <th width="15%" class="text-align-left">Directors</th>
                    <th width="15%" class="text-align-left">DIN Number</th>
                    <th width="20%" class="text-align-left">Type of Director</th>
                    <th width="20%" class="text-align-left">Email ID</th>
                    <th width="15%" class="text-align-left">Nationality</th>
                    <th width="10%"><img src="../../assets/purple-plus-round.svg" class="cursor-hand" (click)="addDirector()" ngbTooltip="Add Director"/>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let director of model.directors; let i = index">
                    <td>
                      <mat-checkbox [disableRipple]="true" [(ngModel)]="director.isSelected" class="checkbox"
                        (change)="selectDirector()" [ngModelOptions]="{standalone: true}" ngbTooltip="Select">
                        <span style="color: transparent;">.</span>
                    </mat-checkbox>
                    </td>

                    <td class="text-align-left">
                      <input type="text" class="form-control" [(ngModel)]="director.fullName" [ngModelOptions]="{standalone: true}"
                        [ngClass]="(director.isEdit && dName) ? 'is-invalid' : 'is-valid' " #dnameErr="ngModel" required
                        [readonly]="(director.id > 0 && !director.isEdit) || ((director.id == 0 && !director.isEdit))" placeholder="Enter director name">
                        <div class="alert alert-danger  text-align-left span-block" *ngIf="dnameErr.errors">
                          <span *ngIf="dnameErr.errors?.required && dName">
                              Director Name is required!
                          </span>
                        </div> 
                    </td>

                    <td class="text-align-right">
                      <input type="text" class="form-control" [(ngModel)]="director.din" [ngModelOptions]="{standalone: true}"
                        [ngClass]="(director.isEdit && dDin) ? 'is-invalid' : 'is-valid' " #dinErr="ngModel" required
                        [readonly]="(director.id > 0 && !director.isEdit) || ((director.id == 0 && !director.isEdit))" (keypress)="allowAlphaNumericKey($event)" placeholder="Enter DIN number">
                        <div class="alert alert-danger  text-align-left span-block" *ngIf="dinErr.errors">
                          <span *ngIf="dinErr.errors?.required && dDin">
                              DIN is required!
                          </span>
                        </div> 
                    </td>

                    <td class="text-align-right">
                      <select class="form-control" id="typeOfTheCompany" [(ngModel)]="director.typeOfDirector"
                        #typeDirErr="ngModel" title="" [ngClass]="(director.isEdit && dType) ? 'is-invalid' : 'is-valid' " [ngModelOptions]="{standalone: true}"
                        [disabled]="(director.id > 0 && !director.isEdit) || ((director.id == 0 && !director.isEdit))"  #dTypeErr="ngModel" required>
                        <option [disabled]="director && director.typeOfDirector" value="" selected>Select type of the Director</option>
                        <option value="Executive director">Executive director</option>
                        <option value="Non-Executive Director">Non-Executive Director</option>
                        <option value="Managing director">Managing director</option>
                        <option value="Independent director">Independent director</option>
                        <option value="Residential director">Residential director</option>
                      </select>
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="dTypeErr.errors">
                        <span *ngIf="dTypeErr.errors?.required && dType">
                            Director Type is required!
                        </span>
                      </div> 
                    </td>

                    <td class="text-align-left">
                      <input type="email" class="form-control" [(ngModel)]="director.emailId" placeholder="Enter email"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" title="" [ngModelOptions]="{standalone: true}"
                      [ngClass]="(director.isEdit && dEmail) ? 'is-invalid' : 'is-valid' " 
                      [readonly]="(director.id > 0 && !director.isEdit) || ((director.id == 0 && !director.isEdit))" #directorEmailId="ngModel" required>
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="directorEmailId.errors">
                        <span *ngIf="directorEmailId.errors?.required && dEmail">
                            Email is required!
                        </span>
                        <span [hidden]="!directorEmailId.errors?.pattern">
                            Invalid Email!
                        </span>
                      </div>
                    </td>

                    <td class="text-align-left">
                      <select class="form-control" [(ngModel)]='director.nationality' #nationalityErr="ngModel"
                        title="" [ngClass]="(director.isEdit && dNationality) ? 'is-invalid' : 'is-valid' "  [ngModelOptions]="{standalone: true}"
                        [disabled]="(director.id > 0 && !director.isEdit) || ((director.id == 0 && !director.isEdit))" #dNationalityErr="ngModel" required>
                        <option *ngFor='let countryLst of countryList; let k = index'>{{countryLst}}
                        </option>
                      </select>
                      <div class="alert alert-danger  text-align-left span-block" *ngIf="dNationalityErr.errors">
                        <span  *ngIf="dNationalityErr.errors?.required  && dNationality">
                          Nationality is required!
                        </span>
                      </div>
                    </td>

                    <td class="actions-column">
                      <img  src="../../assets/edit.svg" class="cursor-hand" *ngIf="(director.id > 0 && !director.isEdit) || ((director.id == 0 && !director.isEdit))" (click)="editDirectorRow(i)" ngbTooltip="Edit"/>
                      <img  src="../../assets/save.svg" class="cursor-hand" *ngIf="(director.id > 0 && director.isEdit) || ((director.id == 0 && director.isEdit))"   (click)="saveDirectorRow(i)" ngbTooltip="Save"/>&nbsp;
                      <img  src="../../../assets/converti-profile.svg" class="cursor-hand" (click)="openDirectorDetailsPopup(i)" ngbTooltip="Profile details"/>&nbsp;
                      <img  src="../../assets/trash-bin.svg" class="cursor-hand" (click)="deleteIndividualDirector(i)" ngbTooltip="Delete"/>
                    </td>

                    <app-director-details-popup [showDialog]="director.shouldShowDialog"
                      [directorDetailsModel]="director"
                      (closeDetails)="onCloseDirectorDetails($event)">
                    </app-director-details-popup>
                  </tr>

                  <tr *ngIf="model.directors.length==0">
                    <td colspan="7" class="text-align-center">
                      <p>Click on the '+' to add Director</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div>
        <button mat-raised-button id="continue" color="success" [disableRipple]="true" class="top-20 float-right"
          (click)="proceedToIncorporationDetails()" ngbTooltip="Continue">Continue</button>
      <button *ngIf="registerNewCompany" mat-raised-button id="discard" color="warn" [disableRipple]="true" class="top-20 right-20 float-right"
          (click)="discardNewCompany()" ngbTooltip="Discard">Discard</button>
      <button *ngIf="registerNewCompany" mat-raised-button id="gotoDashboard" color="success" [disableRipple]="true" class="top-20 right-20 float-right"
          (click)="goToDashboard()" ngbTooltip="Go to Dashboard">Go to Dashboard</button>
      </div>
    </form>
  </div>

  <!-- Helper sections -->
  <div class="col-md-3">
    <div class="helper-container">
      <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
        [hidden]="helper == ''">
        <mat-card-header class="help-hdr">
          <mat-card-title><label class="help-hdr-txt">{{
        helpSections?.name
      }}</label></mat-card-title>
        </mat-card-header>
        <hr />
        <mat-card-content class="card-help-content">
          <div [innerHTML]="helpSections?.description"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>
