<div *ngIf="showDialog" class="dialog">
  <div class="col-xs-12 col-sm-12 col-md-12 header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6">
        <label class="dialogue-title" style="padding: 20px;font-size: 16px;"
          >Redirect</label
        >
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6">
        <button
          type="button"
          class="close dialogue-title"
          (click)="close()"
          aria-label="Close"
          style="float: right;padding: 20px;"
        >
          <i class="fa fa-times dialogue-title"></i>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  <div class="col-xs-12 col-sm-12 col-md-12">
    <div class="row">
      <div *ngIf="!redirected" class="usr-instruction col-md-12 col pad-20">
        You will be redirected to an external website
      </div>
      <div *ngIf="redirected" class="usr-instruction col-md-12 col pad-20">
        Please refresh after completion of Esign
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <button class="cmn-button float-right  margin-right-20" (click)="redirect()" [hidden] = "redirected">
        OK
      </button>
      <button class="cmn-button float-right margin-right-20" (click)="close()" [hidden] = "redirected">
        Cancel
      </button>
      <button class="cmn-button float-right margin-right-20" (click)="close()" [hidden] = "!redirected">
        Refresh
      </button>
    </div>
  </div>
</div>
