<div class="row" *ngIf="dataSource?.length > 0 && displayCols != undefined">
    <!-- <div class="row btm-20 top-20"> -->
        <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4">
            <div class="coldgridOPerations">
                <mat-form-field appearance="outline">
                    <mat-label>
                        Search..
                    </mat-label>
                    <input [disabled]="!dataSource" type="text" [formControl]="searchFormControl"
                        (keyup)="searchEntered($event.target.value)" matInput [matAutocomplete]="auto" />
                    <mat-autocomplete (optionSelected)="searchEntered(searchFormControl.value)" #auto="matAutocomplete">
                        <mat-option *ngFor="let option of searchOptions | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                    <span matPrefix><i class="fa fa-search" aria-hidden="true"></i></span>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-8 col-md-8 col-xs-8 col-lg-8 align-right">
            <div class="coldgridOPerations align-right">
                <!-- <mat-icon (click)="expandColumns()" ngbTooltip="Expand All/Collapse All"><img  style="height: 28px;margin-top: 15px;margin-left: -18px;" src="../../assets/expandcollbox.svg"><i
                    class=" fa-lg" aria-hidden="true"></i></mat-icon> -->
                <mat-form-field appearance="outline">
                    <mat-label>Export</mat-label>
                    <mat-select>
                        <mat-option (click)="exportFile()">Export
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
  <div class="col-md-12">
      <div class="btm-20">
          <dx-tree-list
              [dataSource]="dataSource"
              itemsExpr="id"
              dataStructure="tree"
              [showRowLines]="true"
              [showBorders]="true"
              [columnAutoWidth]="false"
              [autoExpandAll]="expandAll"
              [wordWrapEnabled]="true"
              noDataText="Click on the 'Create Buy-Back' button to add a Buy-back"
              (onCellHoverChanged)="onCellHoverChanged($event)"
            >
            <dxo-header-filter [visible]="true" [allowSearch]="false"> </dxo-header-filter>
            <dxo-search-panel [visible]="false" [width]="240" placeholder="Search..."></dxo-search-panel>
            <dxi-column dataField="buyBackName" [minWidth]="230" caption="Buy-back Name" cellTemplate="buyBackNameTemplate" [visible]="displayCols.buyBackName"></dxi-column>
            <div *dxTemplate="let cell of 'buyBackNameTemplate'">
                <span data-toggle="tooltip" data-placement="top" container="body" >
                    <img
                        *ngIf="cell.data.state === 'BASIC_DETAILS'"
                        src="../../../assets/overview/PROFORMA.svg"
                        ngbTooltip ="Basic details"
                    />
                    <img
                        *ngIf="cell.data.state === 'SHAREHOLDER_DETAILS'"
                        src="../../../assets/overview/PROFORMA.svg"
                        ngbTooltip="Under Review"
                    />
                    <img
                        *ngIf="cell.data.state === 'CAPTABLE_SUMMARY'"
                        src="../../../assets/overview/PROFORMA.svg"
                        ngbTooltip ="Summary generated"
                    />
                    <img
                        *ngIf="cell.data.state === 'COMPLETED'"
                        src="../../../assets/overview/COMPLETED.svg"
                        ngbTooltip="Completed"
                    />
                </span>
                <span (click)="displayBuyBackDetails(cell.data)" class="link-style cursor-hand">{{ cell.data.buyBackName }}</span>
            </div>
            <dxi-column dataField="className" caption="Security Class" cellTemplate="classNameTemplate" [visible]="displayCols.className"> 
                <!-- <dxo-header-filter [dataSource]="fdbFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cell of 'classNameTemplate'">
                <span>{{ cell.data.className }}</span>
            </div>
              
            <dxi-column dataField="startDate" caption="Date" cellTemplate="dateTemplate" [visible]="displayCols.startDate">
            </dxi-column>
            <div *dxTemplate="let cell of 'dateTemplate'">
                <span>{{ cell.data.startDate }}</span>
            </div>

            <dxi-column dataField="numberOfSharesBoughtBackActual" caption="No of shares" cellTemplate="numberOfSharesTemplate" [visible]="displayCols.pricePerShare">
            </dxi-column>
            <div *dxTemplate="let cell of 'numberOfSharesTemplate'">
                <span  [ngClass]="{'cursor-hand' : cell.data.numberOfSharesBoughtBackActual != 0}">{{ getTotalValueTble(cell.data.numberOfSharesBoughtBackActual) }}</span>
            </div>

            <dxi-column dataField="pricePerShare" caption="Price per share" cellTemplate="pricePerShareTemplate" [visible]="displayCols.pricePerShare">
            </dxi-column>
            <div *dxTemplate="let cell of 'pricePerShareTemplate'">
                <span  [ngClass]="{'cursor-hand' : cell.data.pricePerShare != 0}">{{ getTotalValueTble(cell.data.pricePerShare) }}</span>
            </div>

            <dxi-column dataField="buyBackAmountActual" caption="Amount Released" cellTemplate="amountTemplate" [visible]="displayCols.buyBackAmountActual">
            <dxo-header-filter [dataSource]="debentureFilter"></dxo-header-filter>
            </dxi-column>
            <div *dxTemplate="let cell of 'amountTemplate'">
                <span  [ngClass]="{'cursor-hand' : cell.data.buyBackAmountActual != 0}">{{ getTotalValueTble(cell.data.buyBackAmountActual) }}</span>
            </div>
            <dxi-column dataField="postBuybackConsolidation" caption="Dilution (fortification)" cellTemplate="dilutionTemplate" [visible]="displayCols.postBuybackConsolidation">
                <!-- <dxo-header-filter [dataSource]="warrantsFilter"></dxo-header-filter> -->
            </dxi-column>
            <div *dxTemplate="let cell of 'dilutionTemplate'">
                <span  [ngClass]="{'cursor-hand' : cell.data.postBuybackConsolidation != 0}">{{ cell.data.postBuybackConsolidation | percent:'2.2' }}</span>
            </div>
          </dx-tree-list>
          <dx-tooltip [(target)]="toolTipTarget" position="right" opacity="0.8">
              <div *dxTemplate="let data of 'content'">
                <div class="tooltip-content">{{toolTipText}}</div>
              </div>
          </dx-tooltip>
      </div>
  </div>  
  <div class="col-md-12 text-align-right notes-tbl btm-20">
      <span>All numbers are in {{usrCompanyCurrency}} unless explicitly mentioned</span>
  </div>
</div>