<div class="row" (mouseleave)="hideHelp()">
  <div class="col-md-9 top-20">
    <div *ngIf="!isCompanyPresent && !isReqInProg">
      <mat-card class="company-name-card" id="Company_Name_Card" (mouseover)="showHelp('Company_Name_Card')">

        <mat-card-header>
          <mat-card-title>Company Details</mat-card-title>
        </mat-card-header>

        <mat-divider class="divider-line"></mat-divider>

        <mat-card-content>
          <p class="top-20 content-pg-1 btm-20"> 
            Enter the full name of your company. This is the name appearing in the certificate of incorporation.
          </p>
          <div class="row">
            <div class="col-md-6">
                <mat-label class="top-20 required">Company Name</mat-label>
                <input list="regCompanies" class="form-control search-field company-name-field top-10" [(ngModel)]="companyName" #cName="ngModel"
                  required placeholder="Search"  (input)="onElasticSearch($event.target.value)" (change)="setcompanyCIN($event)"
                  [ngClass]="cName.invalid && (cName.dirty || cName.touched) ? 'is-invalid' : 'is-valid'"
                  onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 96 && event.charCode <= 105) || (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 46 || event.charCode == 32);"/>
                <datalist id="regCompanies">
                  <option *ngFor="let company of registeredCompanies" [value]="company.nameOfTheCompany">{{ company.nameOfTheCompany }}</option>
                </datalist>
                  <div class="alert alert-danger" *ngIf="cName.invalid && (cName.dirty || cName.touched)">
                  Company Name is required
                </div>
            </div>
            <div class="col-md-4">
                <mat-label class="top-20">CIN Number</mat-label>
                <input type="text" class="form-control top-10" readonly [value]="selectedCompany?.cin" />
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <button mat-raised-button id="continue" color="success" [disabled]="cName.invalid" [disableRipple]="true"
        class="top-20 float-right" (click)="proceedToAdminProfile(companyName)" ngbTooltip="Continue">Continue</button>
      <button mat-raised-button id="gotoDashboard" color="warn" *ngIf="registerNewCompany" [disableRipple]="true"
        class="top-20 float-right right-20" (click)="goToDashboard()" ngbTooltip="Go To Dashboard">Go To Dashboard</button>
    </div>
  </div>

  <!-- Helper sections -->
  <div class="col-md-3 top-20">
    <div class="helper-container">
      <mat-card id="helperCard" class="card-help help-style" [ngStyle]="{'top.px': helperTopPosition}"
        [hidden]="helper == ''">
        <mat-card-header class="help-hdr">
          <mat-card-title><label class="help-hdr-txt">{{helpSections?.name}}</label></mat-card-title>
        </mat-card-header>
        <hr />
        <mat-card-content class="card-help-content">
          <div [innerHTML]="helpSections?.description"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="isCompanyNew || isGrantedAccess == null || isReqInProg">
  <mat-card class="company-name-card">
    <mat-card-header>
      <mat-card-title>Request Access</mat-card-title>
    </mat-card-header>
    <mat-divider class="divider-line"></mat-divider>
    <mat-card-content>
      <p class="top-20 content-pg-1"> 
        The link to request access is sent. Once the access is granted, you can use the tool to plan, build and manage the equity of the company more accurately and efficiently. Extensive compliances can also be tracked and followed in real-time. 
      </p>

      <div class="row req-center top-20">
        <mat-card-title class="card-section-hdr">Welcome to Hissa</mat-card-title>
      </div>

      <div class="row newreqaccess-center">
        <p class="content-pg-1">Thank you for signing up for your new account at Hissa,
          <br>
          you will get the confirmation mail once request Granted.
        </p>
      </div>
      <div class="row top-20" *ngIf="registerNewCompany">
        <button mat-raised-button color="success" *ngIf="registerNewCompany" [disableRipple]="true"
        class="top-20 margin-auto" (click)="goToDashboard()" ngbTooltip="Go To Dashboard">Go To Dashboard</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="wrapper" *ngIf="isCompanyRegistered && queryParamCompany == null">
  <mat-card class="company-name-card">
    <mat-card-header>
      <mat-card-title>Request Access</mat-card-title>
    </mat-card-header>
    <mat-divider class="divider-line"></mat-divider>
    <mat-card-content>
      <div Class="top-20">
        <p class="content-pg-1">
          This company is already registered with Hissa. If you are an <b>Administrator</b> looking to access your cap-table please select <b>‘Request Admin Access’</b>.
          If you are an <b>Employee</b> of this company looking to access your ESOPs, please select <b> Login as Employee</b>.
        </p>
      </div>
      <div class="reqaccess-center">
        <div class="row top-20">
          <span class="margin-right-20 img-request">
            <img src="../../../assets/requestaccess.svg" style="padding: 1vw; padding-left: 2vw;" (click)="proceedToRequest()" />
            <div class="top-10">
              <mat-label>Request Admin Access</mat-label>
            </div>
          </span>
          <span class="img-request">
            <img src="../../../assets/employee-profile.svg" style="padding: 1vw; padding-left: 1.5vw;" (click)="gotoEmployeeLogin()" />
            <div class="top-10">
              <mat-label>Login as Employee</mat-label>
            </div>
          </span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>