import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-generate-credentials-dialog',
  templateUrl: './generate-credentials-dialog.component.html',
  styleUrls: ['./generate-credentials-dialog.component.scss']
})
export class GenerateCredentialsDialogComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() genCredentialsDialog;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  credentialsForm: FormGroup;
  isSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.credentialsForm = this.fb.group({
      email: [this.genCredentialsDialog.email, [Validators.required, Validators.email]],
      viewCapTableSummary: [this.genCredentialsDialog.viewCapTableSummary, [Validators.required]]
    });
  }

  close() {
    this.showDialog = false;
    this.visibleChange.emit(false);
  }

  ngAfterViewInit() {
    this.loadJSstyles();
  }

  loadJSstyles() {
    $('[data-toggle="switch"]').bootstrapSwitch({
        onText: "Yes",
        offText: "No",
    });
    $("#viewCapTableSummary").bootstrapSwitch('state', this.credentialsForm.controls['viewCapTableSummary'].value);
    $("#viewCapTableSummary").on('switchChange.bootstrapSwitch', (event: any, state: any) => {
        this.credentialsForm.controls['viewCapTableSummary'].setValue(state);
    });
  }

  generate() {
    this.isSubmitted = true;
    if(this.credentialsForm.valid){
      this.isSubmitted = false;
      this.genCredentialsDialog.email = this.credentialsForm.value.email;
      this.genCredentialsDialog.viewCapTableSummary = this.credentialsForm.value.viewCapTableSummary;
      this.visibleChange.emit(true);
    }
    else{
      this.toastr.error("Please fill mandatory fields.", "Failure!");
    }
  }

}
