import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from "../../authentication.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utilities.service';
import { CacheProviderService } from '../../services/cache-provider.service';

@Component({
  selector: 'app-new-security-class',
  templateUrl: './new-security-class.component.html',
  styleUrls: ['./new-security-class.component.scss']
})
export class NewSecurityClassComponent implements OnInit {


  @Input() showDialog: boolean;
  @Input() selSecurity: any;
  @Input() selectedIndex: any;


  @Output() saveSecurity: EventEmitter<any> = new EventEmitter<any>();

  disableripple: boolean = true;
  instTemplateList: any;
  selectedInstClsData;
  instType = "";
  instTemplate = "";
  isShowNote: boolean = false;
  descriptions: boolean = false;
  sideNote: string = "";
  securityName: string = "";
  securityType: string = "";
  public userDetails;
  public usrCompanyCurrency;
  errSecurityName: boolean = false;
  errSecurityType: boolean = false;
  isShow: boolean = false;


  constructor(private authService: AuthenticationService,
    private utility: UtilityService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private router: Router) {

  }

  close() {
    this.showDialog = false;
    this.utility.hideOverlay();
    document.getElementById('overlay').style.display = 'none';
    this.saveSecurity.emit({ selectedIndex: this.selectedIndex });
  }

  ngOnInit() {
    console.log('selSecurity:', this.selSecurity);
    console.log('selectedIndex:', this.selectedIndex);
    if (this.cacheService.get("userDetails")) {
      this.userDetails = JSON.parse(this.cacheService.get("userDetails"));
      this.usrCompanyCurrency = this.userDetails['company']['companyCurrency'];
    } else {
      this.usrCompanyCurrency = "INR - ₹";
    }
    this.ngxLoader.start();
    this.authService.getSecurityTemplateByType(this.selSecurity).subscribe(response => {
      this.utility.showOverlay();
      if (response) {
        this.instTemplateList = response;
        this.ngxLoader.stop();
      }
      else {
        this.ngxLoader.stop();
        this.toastr.error("Null response!.", "Failure!");
      }
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!");
    });
  }

  templateChangeEvent(value) {
    this.ngxLoader.start();
    this.instTemplate = value;
    this.authService.getInstTemplateInfo(this.instType, this.instTemplate).subscribe(response => {
      if (response) {
        this.isShow = true;
        this.selectedInstClsData = response;

        this.instType = this.selectedInstClsData.instrument;
        if ((this.selectedInstClsData.sideNote == null) || (this.selectedInstClsData.sideNote == "")) {
          this.descriptions = true;
        }
        else {
          this.descriptions = false;
        }
        this.ngxLoader.stop();
      }
      else {
        this.ngxLoader.stop();
        this.toastr.error("Null response!.", "Failure!");
      }
    }, (e) => {
      this.ngxLoader.stop();
      this.toastr.error(e.error.reason, "Failure!");
    });
  }

  addNotes(data) {
    if (data) {
      this.isShowNote = true;
    } else {
      this.isShowNote = false;
      this.sideNote = "";
    }
  }

  getTotalValueTble(value) {
    if (value > 0) {
      if (value.toString().length > 5) {
        return this.utility.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else {
        return this.utility.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else {
      return 0;
    }
  }

  savesSecurity() {
    this.utility.formValidation();
    let newInstCreateData;
    if (this.securityName == "" || this.securityType == "") {
      if (this.securityName == "") {
        this.errSecurityName = true;
      }
      if (this.securityType == "") {
        this.errSecurityType = true;
      }
      return;
    }

    if (this.instType === 'CCPS') {
      newInstCreateData = {
        templateId: this.securityType,
        instrumentClassName: this.securityName,
        
      };
    } else if (this.instType === 'CCD') {
      newInstCreateData = {
        templateId: this.securityType,
        instrumentClassName: this.securityName,
        
      };
    } else if (this.instType === 'Note') {
      newInstCreateData = {
        templateId: this.securityType,
        instrumentClassName: this.securityName,
        
      }
    } else if (this.instType === 'RPS') {
      newInstCreateData = {
        templateId: this.securityType,
        instrumentClassName: this.securityName,
        
      }
    } else if (this.instType == "Warrant") {
      newInstCreateData = {
        templateId: this.securityType,
        instrumentClassName: this.securityName,
        
      };
    } else if (this.instType == "Equity") {
      newInstCreateData = {
        templateId: this.securityType,
        instrumentClassName: this.securityName,
        
      };
    }
    this.ngxLoader.start();
    this.authService.savenewInstrumentClass(newInstCreateData)
      .subscribe(response => {
        if (response) {
          this.showDialog = false;
        //   this.toastr.success("Security Class Saved Successfully!", "Success");
          this.ngxLoader.stop();
          this.showDialog = false;
          this.saveSecurity.emit({ selectedIndex: this.selectedIndex, className: response["className"], security: response });
        } else {
          this.toastr.error("Security Class Save Failed!", "Failure");
          this.ngxLoader.stop();
        }
      }, (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.error.reason, "Failure");
      });
  }

  goToAddnewInstrument() {
    this.router.navigate(["/landing", "dashboard", "securities", "addNewInstrumentclass", { previousUrl: 'Instrument' }]);
  }

}
