<div *ngIf="showdigPrfle" class="dialog">
  <div class="header">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-10 custom-modal-header">
        <label class="dialogue-title">
          Profile Information: {{name}}
        </label>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-2">
        <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
          <span class="close">X</span>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="divider-line"></mat-divider>
  
  <form class="container-fluid content-row needs-validation" novalidate>
    <div class="row top-20">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 ">
              <div *ngIf="(profileInfObj.imageBase64String === null && profileInfObj.imageUrl === null)">
                  <mat-label>Upload Profile Picture / Select Avatar</mat-label>
                  <div class="top-10">
                    <img class="avatar-sizing cursor-hand" src="../../../assets/uploadeq.png" (click)="openUploadPicturePopup()" />
                    <img class="avatar-sizing cursor-hand" src="../../../assets/avatar01.png" (click)="uploadAvatarImage($event)" />
                    <img class="avatar-sizing cursor-hand" src="../../../assets/avatar02.png" (click)="uploadAvatarImage($event)" />
                    <img class="avatar-sizing cursor-hand" src="../../../assets/avatar03.png" (click)="uploadAvatarImage($event)" />
                    <img class="avatar-sizing cursor-hand" src="../../../assets/avatar04.png" (click)="uploadAvatarImage($event)" />
                    <img class="avatar-sizing cursor-hand" src="../../../assets/avatar05.png" (click)="uploadAvatarImage($event)" />
                  </div>
              </div>
              <app-upload-image *ngIf="shouldShowDialog" [(showDialog)]="shouldShowDialog" [croppedImage]="croppedImage" (visibleChange)="getEvent()" (imageSaved)="onImageSaved($event)">
              </app-upload-image>
              <div *ngIf="profileInfObj.imageBase64String != null">
                <img [src]="profileInfObj.imageBase64String" alt="Investor profile" class="uploaded-image cursor-hand" (click)="changeCroppedImage()" /> <span class="profile_name">{{name}}</span>
              </div>
              <div *ngIf="profileInfObj.imageUrl != null">
                <img [src]="profileInfObj.imageUrl" alt="Investor profile" class="uploaded-image cursor-hand" (click)="changeCroppedImage()" /> <span class="profile_name">{{name}}</span>
              </div>
          </div>
        </div>
        <div class="row btm-20 top-10">
          <div class="col-md-12 ">
            <mat-label class="required">Legal Name</mat-label>
            <div class="top-10">
              <input type="text" class="form-control" [(ngModel)]='profileInfObj.legalName' [ngModelOptions]="{standalone: true}"
                placeholder="Enter Investor legal name here" (ngModelChange)="legalNameChange(profileInfObj.legalName)"
                required #lnameErr="ngModel"/>
              <div class="alert alert-danger" *ngIf="lnameErr.errors">
                <span class="alert alert-danger" *ngIf="(lnameErr.dirty || lnameErr.touched) && !mlegalName">
                  Investor Legal Name required!
                </span>
                <span class="alert alert-danger" *ngIf="lnameErr.errors?.required && mlegalName">
                  Investor Legal Name required!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12 ">
            <mat-label>Registered Address</mat-label>
            <div class="top-10">
              <textarea [(ngModel)]='profileInfObj.registeredAddress' class="form-control" rows="3" [ngModelOptions]="{standalone: true}"
                placeholder="Enter address here"> </textarea>
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12 ">
            <mat-label class="required">
              Country of Residence
            </mat-label>
            <div class="top-10">
              <select class="form-control cursor-hand" [(ngModel)]='profileInfObj.residentStatus'  [ngModelOptions]="{standalone: true}"
              (change)="onChangeResidentSelect(resident.value)" #resident #residenceyErr="ngModel" required>
                <option value=null [selected]="true" disabled>Select Residence</option>
                <option *ngFor='let resident of countryList; let k = index' [selected]="resident === selectedCountry"
                  value="{{resident}}">{{resident}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="residenceyErr.errors">
                <span class="alert alert-danger" *ngIf="(residenceyErr.dirty || residenceyErr.touched) && !mResidence">
                  Residence is required!
                </span>
                <span class="alert alert-danger" *ngIf="residenceyErr.errors?.required && mResidence">
                  Residence is required!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12 ">
            <mat-label class="required">
              Nationality
            </mat-label>
            <div class="top-10">
              <select class="form-control cursor-hand" [(ngModel)]='profileInfObj.nationality' #nationality [ngModelOptions]="{standalone: true}"
                (change)="onChangeCountrySelect(nationality.value)" #nationalityErr="ngModel" required>
                <option value=null [selected]="true" disabled>Select Nationality </option>
                <option *ngFor='let country of countryList; let k = index' [selected]="country === selectedCountry"
                  value="{{country}}">{{country}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="nationalityErr.errors">
                <span class="alert alert-danger" *ngIf="(nationalityErr.dirty || nationalityErr.touched) && !mNationality">
                  Nationality is required!
                </span>
                <span class="alert alert-danger" *ngIf="nationalityErr.errors?.required && mNationality">
                  Nationality is required!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12">
            <mat-label>
              Depository Name
            </mat-label>
            <div class="top-10">
              <input type="text" [(ngModel)]="profileInfObj.depositoryName" class="form-control" [ngModelOptions]="{standalone:true}" placeholder="Depository Name" />
            </div>
          </div>
        </div>
        <div class="row btm-10">
          <div class="col-md-12">
            <mat-label>
              Is Promoter
            </mat-label>
            <div class="top-10">
              <input type="checkbox" id="promoter" [(ngModel)]="profileInfObj.isPromoter" [ngModelOptions]="{standalone: true}" data-toggle="switch" checked
                data-inverse="false" data-off-color="default" title="" />
            </div>
          </div>
        </div>
      </div> 
      <div class="col-md-6">
        <div class="row btm-20">
          <div class="col-md-12">
            <mat-label class="required">Type of shareholder</mat-label>
            <div class="top-10">
              <select class="form-control cursor-hand" [(ngModel)]='profileInfObj.typeOfShareholder' #shareholder [ngModelOptions]="{standalone: true}"
                (change)='shareHoderTypeChange(shareholder.value)'  #sTypeErr="ngModel" required>
                <option class="select-optn" value="" [selected]="true" disabled>Select Shareholder Type</option>
                <option *ngFor='let shrhldrtype of shareHolderType' [value]="shrhldrtype.shareholderType">{{shrhldrtype.shareholderTypeValue}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="sTypeErr.errors">
                <span class="alert alert-danger" *ngIf="(sTypeErr.dirty || sTypeErr.touched) && !mShareholderType">
                  Type of shareholder is required!
                </span>
                <span class="alert alert-danger" *ngIf="sTypeErr.errors?.required && mShareholderType">
                  Type of shareholder is required!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="profileInfObj.typeOfShareholder === 'FinancialInstitution'" class="row btm-20">
          <div class="col-md-12">
            <mat-label class="required">Shareholder Sub Type</mat-label>
            <div class="top-10">
              <select class="form-control cursor-hand" [(ngModel)]="profileInfObj.shareholderSubType" #shareholderSubType [ngModelOptions]="{standalone: true}" 
                (change)="onSubTypeSelect(shareholderSubType.value)" #subTypeErr="ngModel" [required]="profileInfObj.typeOfShareholder === 'FinancialInstitution'">
                <option class="select-optn" value="" [selected]="true" disabled>Select Shareholder Sub Type</option>
                <option *ngFor='let type of shareholderSubTypesList' [value]="type.shareholderSubType">{{type.shareholderSubTypeValue}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="subTypeErr.errors">
                <span class="alert alert-danger" *ngIf="(subTypeErr.dirty || subTypeErr.touched) && !mShareholderSubType">
                  Shareholder Sub Type is required!
                </span>
                <span class="alert alert-danger" *ngIf="subTypeErr.errors?.required && mShareholderSubType">
                  Shareholder Sub Type is required!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="profileInfObj.typeOfShareholder === 'Founders' || profileInfObj.typeOfShareholder === 'Angels'" class="row btm-20">
          <div class="col-md-12">
            <mat-label class="required">Is Relative of Director</mat-label>
            <div class="top-10">
              <input type="checkbox" id="isRelative" [(ngModel)]="profileInfObj.isRelativeOfDirector" [ngModelOptions]="{standalone: true}" data-toggle="switch" checked
                data-inverse="false" data-off-color="default" title="" />
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12">
            <div>
              <mat-label [ngClass]="{'required':groupMandatory === true}">
                Group Name
              </mat-label>
              <div class="top-10">
                <select class="form-control cursor-hand" [(ngModel)]='selGroupName' #groupname [ngModelOptions]="{standalone: true}"
                  (change)="gropSelectionChange(selGroupName)" #groupErr="ngModel"
                  [ngClass]="mGroup ? 'is-invalid' : 'is-valid'"
                  [required]="profileInfObj.typeOfShareholder === 'VC' || 
                              profileInfObj.typeOfShareholder === 'AngelNetwork' || 
                              profileInfObj.typeOfShareholder ===  'PE' ||
                              profileInfObj.typeOfShareholder === 'FinancialInstitution' ">
                  <option class="select-optn" value="null" [selected]="true" *ngIf="!fromNewInv" disabled>Select Shareholder Group</option>
                  <option class="select-optn" value="" [selected]="true" *ngIf="fromNewInv">Select Shareholder Group</option>
                  <option class="select-optn" value="newGroup">Create new group</option>
                  <option class="white-handle" *ngFor='let groups of groupNames' value="{{groups}}">{{groups}}</option>
                </select>
                <div class="alert alert-danger" *ngIf="groupErr.errors || newGroupErr">
                  <span class="alert alert-danger" *ngIf="(groupErr.dirty || groupErr.touched) && !mGroup">
                    Shareholder groupname is required!
                  </span>
                  <span class="alert alert-danger" *ngIf="groupErr.errors?.required && mGroup">
                    Shareholder groupname is required!
                  </span>
                  <span class="alert alert-danger" *ngIf="newGroupErr">
                    Shareholder groupname is required!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12 ">
              <mat-label>
                  Email
              </mat-label>
              <div class="top-10">
                  <input type="email" class="form-control" [(ngModel)]='profileInfObj.emailId'
                      (ngModelChange)="emailValid(profileInfObj.emailId)" #mailErr="ngModel"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$" title=""
                      placeholder="ex: yourname@gmail.com" [ngModelOptions]="{standalone: true}"/>
                  <div class="alert alert-danger" *ngIf="mailErr.errors">
                      <div [hidden]="!mailErr.errors?.pattern">
                          Invalid E-mail!
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12 ">
            <mat-label>
              Contact number
            </mat-label>
            <div class="top-10">
              <input type="number"  (keypress)="maxNumber($event.target.value)"  [ngModelOptions]="{standalone: true}"
              pattern="^[0-9]{0,10}$"  class="form-control text-align-right" [(ngModel)]='profileInfObj.contactNumber' placeholder="9988776655"/>
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12">
            <mat-label>
              DP ID
            </mat-label>
            <div class="top-10">
              <input type="text" [(ngModel)]='profileInfObj.dpId' class="form-control" [ngModelOptions]="{standalone: true}"
                placeholder="DP ID" /> 
            </div>
          </div>
        </div>
        <div class="row btm-20">
          <div class="col-md-12">
            <mat-label>
              Client ID
            </mat-label>
            <div class="top-10">
              <input type="text" [(ngModel)]="profileInfObj.clientId" class="form-control" [ngModelOptions]="{standalone: true}" placeholder="Client ID" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row btm-20">
      <div class="col-md-12">
        <button mat-raised-button id="saveAndExit" [disableRipple]="disableripple" color="success" class="float-right"
          (click)='saveUserProfile()' ngbTooltip="Save and Exit">Save and Exit</button>
        <button mat-raised-button id="cancel" [disableRipple]="disableripple" color="warn" class="float-right margin-right-20"
          (click)="close()" ngbTooltip="Cancel">Cancel</button>
      </div>
    </div>
  </form>
</div>
<app-create-new-group *ngIf="showNewGrpMdl" [(showdigNewGroup)]="showNewGrpMdl"
  (visibleChange)="getNewGroupInfo($event)"></app-create-new-group>