<div class="container-fluid custom-fluid-container graybg">
    <div class="row cmncnt-ver-spacing">
        <div class="col-md-12">
            <label class="header-level-1">Documents</label>
            <button class="cmn-button float-right" (click)="createDocumentSet()" ngbTooltip="Create Document Set">
        Create Document Set
      </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p class="content-pg-1">
                Top 3 shareholders of the Company followed by list of all shareholders . Click on a shareholder to find the break up of investment and details of each investment.
            </p>
        </div>
    </div>
    <div class="row-carousel">
        <div class="row">
            <div class="col-md-12">
                <div class="row" *ngIf="cardData != null && cardData.length > 0">
                    <div class="card-lyot col-md-2 col-sm-2" *ngFor="let card of cardData; let i = index">
                        <label>
              <img src="../../../assets/dash{{ (i % 5) + 1 }}.svg" />
            </label>
                        <p class="card-hdr bottom-0">{{ card.cardName }}</p>
                        <span class="bottom-line"></span>
                        <p class="card-lbl bottom-0">{{ card.firstSectionName }}</p>
                        <p *ngIf="card.cardName == 'Funding'" class="card-value bottom-0" ngbTooltip="{{ card.firstSectionValue | CustomeCurrency: '2' }}">
                            {{ getTotalValueTble(card.firstSectionValue) }}
                        </p>
                        <p *ngIf="card.cardName != 'Funding'" class="card-value bottom-0" ngbTooltip="{{ card.firstSectionValue | CustomeCurrency: '2' }}">
                            {{ card.firstSectionValue == null ? "-" : (card.firstSectionValue | CustomeCurrency) }}
                        </p>
                        <p class="card-lbl bottom-0">{{ card.secondSectionName }}</p>
                        <p *ngIf="card.cardName == 'Funding'" class="card-value bottom-0" ngbTooltip="{{ card.secondSectionValue | CustomeCurrency: '2' }}">
                            {{ getTotalValueTble(card.secondSectionValue) }}
                        </p>
                        <p *ngIf="card.cardName != 'Funding'" class="card-value bottom-0" ngbTooltip="{{ card.secondSectionValue | CustomeCurrency: '2' }}">
                            {{ card.secondSectionValue == null ? "-" : (card.secondSectionValue | CustomeCurrency) }}
                        </p>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="cardData == null || cardData.length == 0">
                    <div class="col-md-12" style="text-align: center">
                        <span class="txt-nodata">
                            No Data
                        </span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-1 cmncnt-ver-spacing">
            <p class="select-optn">Transactions</p>
        </div>
        <div class="col-md-4">
            <select class="form-control cmncnt-ver-spacing " #selShareholder (change)="filterData(selShareholder.value)">
        <option class="select-optn">All Shareholders</option>
        <option *ngFor="let name of shareholderNames; let i = index">{{
          name
        }}</option>
      </select>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p class="content-pg-1">
                Top 3 shareholders of the Company followed by list of all shareholders . Click on a shareholder to find the break up of investment and details of each investment.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table-common  bottom-50">
                <thead>
                    <tr>
                        <th class=" table-col1 text-align-left" style="padding-left: 30px;">
                            File Name
                        </th>
                        <th class=" table-col2 text-align-left">Owner</th>
                        <th class=" table-col3 text-align-right">Last Modified</th>
                        <th class=" table-col4 text-align-right">Round</th>
                        <th class=" table-col5 text-align-right" style="padding-right: 30px;">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr *ngIf="shareholdersData == null">
                        <td colspan="7">
                            <span class="txt-nodata" style="text-align: center">No Data</span>
                        </td>
                    </tr> -->
                    <ng-template *ngIf="filteredData == false" ngFor let-group [ngForOf]="shareholdersData" let-i="index">
                        <tr>
                            <td class="text-align-left" style="padding-left: 30px;">
                                <span (click)="filterByGroup(group.groupName)">{{
                  group.groupName
                }}</span
                >&nbsp;
              </td>
              <td class="roundListGrid">
                <span
                  *ngFor="let round of group.roundDetails; let j = index"
                  class="rnd-identifier"
                  [ngClass]="identifierCSS(round.roundIdentifier)"
                  (click)="goToRoundDetails(round.roundName)"
                >
                  {{ round.roundIdentifier }}
                </span>
                            </td>
                            <td class="text-align-right">
                                {{ group.percentageOfHoldingOfGroup | percent: "2.2" }}
                            </td>
                            <td class="text-align-right" ngbTooltip="{{ group.numberOfSharesOfGroup | CustomeCurrency }}">
                                {{ getTotalValueTble(group.numberOfSharesOfGroup) | CustomeCurrency }}
                            </td>
                            <td class="text-align-right" style="padding-right: 30px;">1</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
            <div class="text-align-right notes-tbl" style="margin-top: 20px;">
                <span>All numbers are in Rupees unless explicitly mentioned</span>
            </div>
        </div>
    </div>
</div>
