export class BuyBackDetails {
    id :number;
    buyBackName : string;
    buyBackIdentifier : string;
    startDate : String;
    buyBackDescription : string;
    securityClass : string;
    className : string;
    amount : number;
    pricePerShare : number;
    noOfSharesToBeBought : number;
    instrumentClassName: string;
    buyBackAmount: number;
    numberOfSharesBoughtBackTarget: number;
    state: string;
    numberOfSharesBoughtBackActual: number;
    buyBackAmountActual: number;
    postBuybackConsolidation: number;
    buyBackNote: String;
    buyBackSummary: String;
  }