export class ShareholderDetailsModel { 
    name: string;
    numberOfShares: number;
    totalInvestment: number;
    holdingPercentage: number;
    isSelected: boolean;
    isTotalInvestmentValid: boolean;
} 



      