import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PercentPipe } from '@angular/common';

import { MatCardModule, MatButtonModule, MatFormFieldModule, MatDividerModule, MatMenuModule, MatDatepickerModule, MatIconModule, MatAutocompleteModule, MatSelectModule, MatInputModule} from '@angular/material';
import {  MatDialogModule, MatFormFieldControl, MatListModule, MatSidenavModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FounderDetailsPopupComponent } from './../onboarding/company-details/founder-details-popup/founder-details-popup.component';
import { DirectorDetailsPopupComponent } from './../onboarding/company-details/director-details-popup/director-details-popup.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { CreateNewGroupComponent } from './create-new-group/create-new-group.component';
import { DevExCapTableComponent } from './dev-ex-cap-table/dev-ex-cap-table.component'
import { DevExBonusCaptableComponent } from './dev-ex-bonus-captable/dev-ex-bonus-captable.component';
import { DxDataGridModule, DxTooltipModule, DxTreeListModule, NestedOptionHost } from 'devextreme-angular';
import { EsopPlanPopupComponent } from './esop-plan-popup/esop-plan-popup.component';
import { CurrencyConvertorComponent } from './currency-convertor/currency-convertor.component';
import { ConversionComponent } from '../securities/conversion/conversion.component';
import { RedemptionComponent } from '../securities/redemption/redemption.component';
import { PipesModule } from '../pipes/pipes.module';
import { SecurityService } from '../securities/security.service';
import { ShareholderDetailsComponent } from '../shareholders/shareholder-details/shareholder-details.component';
import { DirectivesModule } from '../custom-directives/directives.module';
import { SecondaryDialogsModule } from '../secondary/Dialogs/secondary-dialogs.module';
import { GenerateCredentialsDialogComponent } from '../shareholders/generate-credentials-dialog/generate-credentials-dialog.component';
import { CloseTabComponent } from './close-tab/close-tab.component';
import { ShareCertificateEmailComponent } from '../shareholders/share-certificate-email/share-certificate-email.component';
import { AddBeneficiaryComponent } from '../shareholders/add-beneficiary/add-beneficiary.component';
 
@NgModule({
  declarations: [
    UploadImageComponent,
    ProfileInfoComponent,
    CreateNewGroupComponent,
    DevExCapTableComponent,
    DevExBonusCaptableComponent,
    EsopPlanPopupComponent,
    CurrencyConvertorComponent,
    FounderDetailsPopupComponent,
    DirectorDetailsPopupComponent,
    ConversionComponent,
    RedemptionComponent,
    ShareholderDetailsComponent,
    GenerateCredentialsDialogComponent,
    ShareCertificateEmailComponent,
    AddBeneficiaryComponent,
    DevExBonusCaptableComponent,
    CloseTabComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatIconModule,
    NgbModule,
    PipesModule,
    ImageCropperModule,
    DxDataGridModule,
    DxTreeListModule,
    DxTooltipModule,
    DirectivesModule,
    SecondaryDialogsModule
  ],
  exports:[
    CommonModule,
    UploadImageComponent,
    ProfileInfoComponent,
    CreateNewGroupComponent,
    DevExCapTableComponent,
    DevExBonusCaptableComponent,
    EsopPlanPopupComponent,
    CurrencyConvertorComponent,
    FounderDetailsPopupComponent,
    DirectorDetailsPopupComponent,
    ConversionComponent,
    RedemptionComponent,
  ],
  providers: [
    PercentPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonDialogsModule { }
