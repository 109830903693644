import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';
import { UtilityService } from "../../services/utilities.service";
declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-currency-convertor',
  templateUrl: './currency-convertor.component.html',
  styleUrls: ['./currency-convertor.component.scss']
})

export class CurrencyConvertorComponent implements OnInit {
  @Input() isDigShow: boolean;
  @Input() investor: any;
  @Input() homeCurrency: any;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();

  public currencyList;
  public investmentCurrency;
  public currecyConObj = {
    companyCurrency: "",
    currencyAmount: 0,
    currencyOfInvestment: "",
    exchangeRate: 0,
    investmentAmount: 0,
    investorName: "",
  }
  public isExchangeRteEditable: boolean = false;

  constructor(private authService: AuthenticationService, private utilityService: UtilityService) { }

  close() {
    this.isDigShow = false;
    this.visibleChange.emit(this.isDigShow);
  }

  // Currency Selection
  selectedCurrency(curncy) {
    this.investmentCurrency = curncy;
    this.currecyConObj.currencyOfInvestment = curncy;
    if (this.currecyConObj.companyCurrency == this.currecyConObj.currencyOfInvestment) {
      this.isExchangeRteEditable = true;
      this.currecyConObj.exchangeRate = 1;
    } else {
      this.isExchangeRteEditable = false;
    }
  }

  onKeydownEvent(e) {
    this.utilityService.restrictMinus(e);
  }

  convertAmt(amt){
    return this.utilityService.convertCurrencyFormat(amt);
  }

  getAmt(amount){
    if(this.currecyConObj.currencyOfInvestment === this.currecyConObj.companyCurrency ){
      return this.utilityService.convertToCompanyCurrency(amount);
    }
    else {
      return this.utilityService.convertToRoundCurrency(amount);
    }
  }

  //Save converted Currency
  saveCurrencyInvestment() {
    this.currecyConObj['currencyAmount'] = (this.currecyConObj.investmentAmount) / (this.currecyConObj.exchangeRate);
    this.authService.saveCurrencyConverted(this.currecyConObj).subscribe((response) => {
      if (response) {
        this.close();
      } else {
        bootbox.alert("Error: Null response!");
      }
    }, (e) => {
      bootbox.alert(e.error.reason)
    })
    console.log("Currency", this.currecyConObj)
  }

  ngOnInit() {
    console.log(this.investor);
    this.currecyConObj = this.investor.currencyConverter;
    this.currencyList = this.utilityService.currencyList();
    if (this.currecyConObj.companyCurrency == this.currecyConObj.currencyOfInvestment) {
      this.isExchangeRteEditable = true;
    } else {
      this.isExchangeRteEditable = false;
    }
  }

  getCurrencyconvert(value) {
    if(this.currecyConObj.currencyOfInvestment === this.currecyConObj.companyCurrency ){
      return this.utilityService.convertToCompanyCurrency(value);
    }
    else {
      return this.utilityService.convertToRoundCurrency(value);
    }
  }

}
