<div *ngIf="showDialog" class="dialog">
     <div class="header">
      <div class="row">
        <div class="col-xs-9 col-sm-9 col-md-10 custom-modal-header">
          <label class="dialogue-title"> {{!viewOnly? "Grant Response": "View Grant"}} </label>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2">
          <button type="button" class="close top-close-btn" (click)="close()" aria-label="Close">
            <span class="close">X</span>
          </button>
        </div>
      </div>
    </div>
    <mat-divider class="divider-line"></mat-divider>
      <div style="height:90%;">
        <div class="section-padding">
          <div class="row btm-20 ">
              <div class="col-md-12 " >
                <div >
                  <h5 class="page-hdr">Grant Letter</h5>
                  <div class=" grant-letter-container"> 
                    <iframe #document_frame class="document-frame" [src]="grantLetterFile" *ngIf="isOtherFile"></iframe>
                    <ejs-documenteditorcontainer *ngIf="isDocxFile" [(serviceUrl)]="service" #documenteditor_default [enableToolbar]="false" [isReadOnly]= "true" [enablePrint]="false" (created)="onCreate()" width="100%"  height="100%" [showPropertiesPane]="false" [zoomFactor]=1.25 style="display:block; overflow: hidden;"></ejs-documenteditorcontainer>
                  </div>
              </div>
            </div>
        </div>
        <div *ngIf="!viewOnly">
        <mat-checkbox  [disableRipple]="true" (change)="isTermsSelected($event.checked)">
              I have read the grant letter and I am aware that my decision will be locked.
        </mat-checkbox>
        </div>
        </div>
        <div class="section-padding bottom-padding" *ngIf="!viewOnly">
            <div class="row btm-20">
                <div class="col-xs-12 col-sm-12 col-md-12">
                  <button mat-raised-button id="accept" color="success" [disableRipple]="true" ngbTooltip="Accept" [disabled]= "!termsAccepted || showSignButton" [hidden]= "showSignButton"
                    class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="changeStatus()">
                    ACCEPT
                  </button>
                  <button mat-raised-button id="e-sign" color="success" [disableRipple]="true" ngbTooltip="E-Sign" [disabled]=!termsAccepted [hidden]= "!showSignButton"
                    class="col-xs-2 col-sm-2 col-md-2 float-right margin-left-lg" (click)="signGrant()">
                    E-SIGN
                  </button>
                  <button mat-raised-button id="reject" color="warn" [disableRipple]="true" ngbTooltip="Reject" [disabled]= "!termsAccepted"
                    class="col-xs-2 col-sm-2 col-md-2 float-right" (click)="confirmReject()" triggers="hover">
                    REJECT
                  </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-confirm-redirect-dialog *ngIf="showRedirectDialog" [(showDialog)]="showRedirectDialog" [redirectUrl]="redirectUrl" (visibleChange)= close()></app-confirm-redirect-dialog>