import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ForgotPasswordComponent } from "./login-signup/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./login-signup/reset-password/reset-password.component";
import { LandingFormComponent } from "./login-signup/landing-form/landing-form.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthGuard } from "./auth.guard";
import { CompanyNameComponent } from "./onboarding/company-name/company-name.component";
import { OnboardingComponent } from "./onboarding/onboarding/onboarding.component";
import { CompanyDetailsComponent } from "./onboarding/company-details/company-details.component";
import { LandingPageComponent } from "./login-process/landing-page/landing-page.component";
import { CreateNewRoundComponent } from "./rounds/create-new-round/create-new-round.component";
import { SummaryComponent } from "./rounds/summary-tab/summary-tab.component";
import { RoundsOverviewComponent } from "./rounds/rounds-overview/rounds-overview.component";
import { AdminProfileComponent } from "./onboarding/admin-profile/admin-profile.component";
import { CapTableComponent } from "./dashboard/cap-table/cap-table.component";
import { IncorporationDetailsComponent } from "./onboarding/incorporation-details/incorporation-details.component";
// import { RounModelingTabComponent } from "./modeling/roun-modeling-tab/roun-modeling-tab.component";
// import { EsopModelingTabComponent } from "./modeling/esop-modeling-tab/esop-modeling-tab.component";
import { ShareholdersOverviewComponent } from "./shareholders/shareholders-overview/shareholders-overview.component";
// import { SecuritiesOverviewComponent } from "./securities/securities-overview/securities-overview.component";
import { RoundTabComponent } from "./rounds/round-tab/round-tab.component";
import { CurrentInvestorsTabComponent } from "./rounds/current-investors-tab/current-investors-tab.component";
import { NewInvestorsTabComponent } from "./rounds/new-investors-tab/new-investors-tab.component";
import { CapTableTabComponent } from "./rounds/cap-table-tab/cap-table-tab.component";
import { InstrumentTabComponent } from "./rounds/instrument-tab/instrument-tab.component";
import { ShareholderDetailsComponent } from "./shareholders/shareholder-details/shareholder-details.component";
// import { AddNewInstrumentclassComponent } from "./securities/add-new-instrumentclass/add-new-instrumentclass.component";
// import { InstrumentClassComponent } from "./securities/instrument-class/instrument-class.component";
import { RoundDetailsComponent } from "./rounds/round-details/round-details.component";
// import { CreateEsopComponent } from "./esop-management/components/create-esop/create-esop.component";
// import { OverviewEsopComponent } from "./esop-management/components/overview-esop/overview-esop.component";
// import { ManageEsopComponent } from "./esop-management/components/manage-esop/manage-esop.component";
// import { ExitModellingComponent } from "./modeling/exit-modelling/exit-modelling.component";
import { DocumentOverviewComponent } from "./documents/document-overview/document-overview.component";
import { CreateRoundDocumentComponent } from "./documents/create-round-document/create-round-document.component";
import { SecondaryComponent } from "./secondary/secondary.component";
import { SecondaryMgmtComponent } from "./secondary/secondary-mgmt/secondary-mgmt.component";
import { SecondarySellerComponent } from "./secondary/secondary-seller/secondary-seller.component";
import { SecondaryBuyerComponent } from "./secondary/secondary-buyer/secondary-buyer.component";
import { SecondarySummaryComponent } from "./secondary/secondary-summary/secondary-summary.component";
import { ShareCapitalComponent } from "./share-capital/share-capital.component";
import { RoundsComponent } from "src/app/rounds/rounds.component";
import { ShareholdersComponent } from "src/app/shareholders/shareholders.component";
import { SecuritiesComponent } from "src/app/securities/securities.component";
import { ModelingComponent } from "src/app/modeling/modeling.component";
import { OptionsComponent } from "src/app/esop-management/options.component";
import { UsersRolesComponent } from "src/app/users-roles/users-roles.component";
import { AccountConfirmComponent } from "src/app/onboarding/account-confirm/account-confirm.component";
import { SecondaryOverviewComponent } from "src/app/secondary/secondary-overview/secondary-overview.component";
import { CompanyProfileComponent } from "src/app/company-profile/company-profile.component";
// import { TaskManagerComponent } from "./task-manager/task-manager.component";
// import { TaskManagerOverviewComponent } from './task-manager/task-manager-overview/task-manager-overview.component';
// import { StartNewProcessComponent } from './task-manager/start-new-process/start-new-process.component';
// import { TermSheetComponent } from "./task-manager/term-sheet/term-sheet.component";
// import { StepComponent } from './task-manager/step/step.component';
import { CanDeactivateGuard } from '../app/canDeactivateGuard';
import { SearchResultsComponent } from './search-results/search-results.component';
// import { EmployeesComponent } from "./employees/employees.component";
// import { EmployeeDetailsComponent } from "./employees/employee-details/employee-details.component";
import { BaseComponent } from "./login-process/landing-page/base/base.component";
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
// import { AddPoolComponent } from "./esop-management/components/add-pool/add-pool.component";
import { FileConversionComponent } from "./file-conversion/file-conversion.component";
import { EmployeesComponent } from "./employees/employees.component";
import { CanLoadGuard  } from "./auth-guards/can-load.guard";
import { CanActivateGuard  } from "./auth-guards/can-activate.guard";
import { EmployeeLoginComponent } from "./employees/employee-login/employee-login.component";
import { UnauthorizedComponent } from "./error-pages/unauthorized/unauthorized.component";
import { NotFoundComponent } from "./error-pages/not-found/not-found.component";
import { HistoricalRoundCreationComponent } from './onboarding/historical-round/creation/historical-round-creation.component';
import { HistoricalRoundSummaryComponent } from "./onboarding/historical-round/summary/historical-round-summary.component";
import { RoundDeactivateGuard } from '../app/roundDeactivateGuard';
import { BuyBackModule } from "./buy-back/buy-back.module";
import { BuyBackComponent } from "./buy-back/buy-back.component";
import { SplitsComponent } from "./splits/splits.component";
import { BonusComponent } from "./bonus/bonus.component";
import { SettingsComponent } from "./settings/settings.component";
import { SiteSettingsComponent } from "./settings/site-settings/site-settings.component";
import { TransactionsComponent } from "./transactions/transactions.component";
import { IntegrationsComponent } from "src/app/integrations/integrations.component";
import { TermSheetsComponent } from "./term-sheets/term-sheets.component";
import {OtpVerifyComponent} from './login-signup/otp-verify/otp-verify.component';

const routes: Routes = [
  { path: "", component: LandingFormComponent },
  { path: "employeeLogin", component: EmployeeLoginComponent },
  {
    path: "base",
    component: BaseComponent
  },
  {
    path: "forgotPassword",
    component: ForgotPasswordComponent,
  },
  {
    path: "resetPassword",
    component: ResetPasswordComponent,
  },
  {
    path: "verifyOtp",
    component: OtpVerifyComponent,
  },
  {
    path: "landing",
    component: LandingPageComponent,
    children: [
      {
        path: "verification/:code",
        component: AccountConfirmComponent
      },
      {
        path: "onboarding",
        component: OnboardingComponent,
        canActivate: [AuthGuard],
        loadChildren: "./onboarding/onboarding.module#OnboardingModule"
        // children: [
        //   { path: "", component: CompanyNameComponent },
        //   { path: "adminProfile", component: AdminProfileComponent },
        //   { path: "companyDetails", component: CompanyDetailsComponent },
        //   { path: "create-historical-round", component: HistoricalRoundCreationComponent},
        //   { path: "historical-round-summary", component: HistoricalRoundSummaryComponent},
        //   { path: "incorporationDetails", component: IncorporationDetailsComponent },
        // ]
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "404", component: NotFoundComponent },
          { path: "401", component: UnauthorizedComponent },
          { path: 'documents', component: FileManagerComponent },
          { path: "search-results", component: SearchResultsComponent },
          { path: "capTable", component: CapTableComponent,canActivate: [CanActivateGuard], data: {permissionRequired: ['captable-summary']} },
          { path: "settings", component: SettingsComponent, canActivate: [CanActivateGuard], data: {permissionRequired: ['full-access']}, children : [
            {path : "companyProfile", component: CompanyProfileComponent},
            {path : "siteSettings", component: SiteSettingsComponent},
            // {path : "integrations", component: IntegrationsComponent},
          ]},
          {
            path: "rounds",
            component: RoundsComponent,
            loadChildren: "./rounds/rounds.module#RoundsModule",
            canActivate: [CanActivateGuard],
             data: {permissionRequired: ['captable-summary']}
            // children: [
            //   { path: "overview", component: RoundsOverviewComponent },
            //   {
            //     path: "round-details/:roundName",
            //     component: RoundDetailsComponent,
            //   },
            //   {
            //     path: "newRound",
            //     component: CreateNewRoundComponent,
            //     canDeactivate: [RoundDeactivateGuard],
            //     children: [
            //       { path: "round", component: RoundTabComponent },
            //       {
            //         path: "currentInvestors",
            //         component: CurrentInvestorsTabComponent,
            //       },
            //       { path: "newInvestors", component: NewInvestorsTabComponent },
            //       { path: "instrument", component: InstrumentTabComponent },
            //       { path: "capTable", component: CapTableTabComponent },
            //       { path: "summary", component: SummaryComponent },
            //     ],
            //   },
            // ],
          },
          {
            path: "shareholders",
            component: ShareholdersComponent,
            loadChildren:"./shareholders/shareholders.module#ShareholdersModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['captable-summary']}
            // children: [
            //   { path: "overview", component: ShareholdersOverviewComponent },
            //   { path: "details/:id", component: ShareholderDetailsComponent },
            // ],
          },
          {
            path: "shareholder/:id",
            component: ShareholderDetailsComponent,
            // loadChildren:"./shareholders/shareholders.module#ShareholdersModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['shareholder-viewer']}
          },
          {
            path: "buy-back",
            component: BuyBackComponent,
            loadChildren: "./buy-back/buy-back.module#BuyBackModule",
            canActivate: [CanActivateGuard],
             data: {permissionRequired: ['captable-summary']}
          },
          {
            path: "securities",
            component: SecuritiesComponent,
            loadChildren:"./securities/securities.module#SecuritiesModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['captable-summary']}
            // children: [
            //   { path: "overview", component: SecuritiesOverviewComponent },
            //   {
            //     path: "addNewInstrumentclass",
            //     component: AddNewInstrumentclassComponent,
            //   },
            //   { path: "instrumentClass", component: InstrumentClassComponent },
            // ],
          },
          {
            path: "secondary",
            component: SecondaryComponent,
            loadChildren:"./secondary/secondary.module#SecondaryModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['captable-summary']}
            // children: [
            //   { path: "overview", component: SecondaryOverviewComponent },
            //   {
            //     path: "secondary-management",
            //     component: SecondaryMgmtComponent,
            //   },
            //   { path: "secondary-seller", component: SecondarySellerComponent },
            //   { path: "secondary-buyer", component: SecondaryBuyerComponent },
            //   {
            //     path: "secondary-summary",
            //     component: SecondarySummaryComponent,
            //   },
            // ],
          },
          {
            path: "splits",
            component: SplitsComponent,
            loadChildren:"./splits/splits.module#SplitsModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['full-access']}
            // children: [
            //   { path: "overview", component: SecondaryOverviewComponent },
            //   {
            //     path: "secondary-management",
            //     component: SecondaryMgmtComponent,
            //   },
            //   { path: "secondary-seller", component: SecondarySellerComponent },
            //   { path: "secondary-buyer", component: SecondaryBuyerComponent },
            //   {
            //     path: "secondary-summary",
            //     component: SecondarySummaryComponent,
            //   },
            // ],
          },
          {
            path: "bonus",
            component: BonusComponent,
            loadChildren:"./bonus/bonus.module#BonusModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['full-access']}
          },
          {
            path: "transactions",
            component: TransactionsComponent,
            loadChildren:"./transactions/transactions.module#TransactionsModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['full-access']}
          },
          {
            path: "term-sheets",
            component: TermSheetsComponent,
            loadChildren:"./term-sheets/term-sheets.module#TermSheetsModule",
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['full-access']}
          },
          // {
          //   path: "options",
          //   component: OptionsComponent,
          //   children: [
          //     { path: "addPool", component: AddPoolComponent },
          //     { path: "addPool/:edit", component: AddPoolComponent },
          //     { path: "createEsop", component: CreateEsopComponent },
          //     { path: "createEsop/:name", component: CreateEsopComponent },
          //     { path: "overviewEsop", component: OverviewEsopComponent },
          //     { path: "manageEsop", component: ManageEsopComponent},
          //     { path: "employee",
          //       component: EmployeesComponent,
          //       children: [
          //           { path: "employeeDetails/:id/:planName", component: EmployeeDetailsComponent }
          //       ]
          //     }
          //   ],
          // },
          {
            path: "options",
            component: OptionsComponent,
            canLoad: [CanLoadGuard],
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['opts-management-view','opts-homepage-view']},
            loadChildren:"./esop-management/esop.module#EsopModule"
          },
          {
            path: "employees",
            component: EmployeesComponent,
            canLoad: [CanLoadGuard],
            canActivate: [CanActivateGuard],
            data: {permissionRequired: ['emp-view', 'employee-page-viewer']},
            loadChildren:"./employees/employees.module#EmployeesModule"
          },
          {
            path: "modeling",
            component: ModelingComponent,
            loadChildren: "./modeling/modeling.module#ModelingModule",
          //  // canActivate: [CanActivateGuard],
          //  // data: {permissionRequired: ['captable-summary','opts-management-view','opts-homepage-view']}
            // children: [
            //   { path: "roundModelingTab", component: RounModelingTabComponent,
            //   canDeactivate: [CanDeactivateGuard]},
            //   { path: "esopModellingTab", component: EsopModelingTabComponent },
            //   { path: "exitModelling", component: ExitModellingComponent },
            // ],
          },
          { path: "document", component: DocumentOverviewComponent },
          { path: "newDocument", component: CreateRoundDocumentComponent },
          { path: "shareCapital", component: ShareCapitalComponent },
          { path: "usersRoles", component: UsersRolesComponent,canActivate: [CanActivateGuard], data: {permissionRequired: ['captable-summary']} },
          // { path: "companyProfile", component: CompanyProfileComponent , canActivate: [CanActivateGuard],  data: {permissionRequired: ['captable-summary', 'opts-management-view','opts-homepage-view']}},
          // {
          //   path: "taskManager",
          //   component: TaskManagerComponent,
          //   children: [
          //     { path: "new-process", component: StartNewProcessComponent},
          //     { path: "overview", component: TaskManagerOverviewComponent },
          //     { path: "termSheet", component: TermSheetComponent },
          //     { path: "step/:id", component: StepComponent}
          //   ]
          // },
          { path: "convert-file", component: FileConversionComponent },
          { path: "userProfile", component: UserProfileComponent },
          {
            path: "document-builder", loadChildren: "./build-document/build-document.module#BuildDocumentModule"
          },
          {
            path: "task-manager",
            loadChildren: "./task-manager/task-manager.module#TaskManagerModule"
          },
          {
            path: "document-generator",
            loadChildren: "./document-generator/document-generator.module#DocumentGeneratorModule"
          }
        ],
      }
    ],
  },
  { path: "**", redirectTo: '/landing/dashboard/404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
