<div class="container-fluid graybg">
  <div class="row">
    <div class="col-md-12">
      <h5 class="page-hdr btm-15">Document Generator</h5>
      <p class="content-pg-1 btm-20">The Document Generator allows you to create board and share holder resolutions, ESOP grant letters and any other legal agreements. Upload a template in the hissa format to create list of questions and tags to assemble a complete document.</p>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-4">
      <mat-label class="required">Document Name</mat-label>
      <div class="display-grid">
        <input class="form-control top-10 width-70" type="text" name="Document Name" placeholder="Please enter document name" [(ngModel)]="resolutionName"/>
      </div>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-4">
      <mat-label class="required">Select Word Template</mat-label>
      <div class="display-grid">
        <input class="form-control top-10 width-70" type="file" multiple
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          (change)="onChangeTemplate($event)">
      </div>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-4">
      <mat-label class="required">Select Input Excel</mat-label>
      <div class="display-grid">
        <input class="form-control top-10 width-70" type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          (change)="onChangeInputExcel($event)">
      </div>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-4">
      <mat-label>Select Converter</mat-label>
      <mat-select class="form-control  top-10" [(ngModel)]="pdfConverter" name="pdfConverter"
        placeholder="Select Library Name">
        <mat-option value="ADOBE">Adobe</mat-option>
        <mat-option value="DOCX4J">Docx4j</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row btm-20">
    <div class="col-md-4">
      <button mat-raised-button id="submit" color="success" class="float-right cmn-button left-10 top-10"
        (click)="onGenerateDocument()" [disableRipple]="true" ngbTooltip="Convert"> Submit </button>
        <button mat-raised-button id="back" color="warn" class="float-right cmn-button top-10" (click)="onBack()"
          [disableRipple]="true" ngbTooltip="Back"> Back
        </button>
    </div>
  </div>
</div>
