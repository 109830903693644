import { PercentPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxTooltipComponent, DxTreeListComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/authentication.service';
import { UtilityService } from 'src/app/services/utilities.service';
import { ColumnList } from './column-list';
import { PermissionsService } from 'src/app/services/permissions.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-buy-back-overview-table',
  templateUrl: './buy-back-overview-table.component.html',
  styleUrls: ['./buy-back-overview-table.component.scss']
})
export class BuyBackOverviewTableComponent implements OnInit {

  @Input() dataSource = [];
  @Input() displayCols: ColumnList;
  @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  expandAll = false;
  toolTipText: string = '';
  toolTipTarget: any;
  usrCompanyCurrency;
  shouldShowDialog: boolean;
  isPlan: boolean=false;
  selectedSecurity: any;
  searchOptions: Observable<string[]>;
  totalSearchOptions = [
    "For_Approval",
    "Approved",
    "Created",
    "Offered",
    "Granted",
    "Rejected",
  ];
  searchFormControl = new FormControl();


  buyBackStateMapping = {
    "BASIC_DETAILS": "buyBack",
    "SHAREHOLDER_DETAILS": "review",
    "CAPTABLE_SUMMARY": "summary"
  }

  noOfSharesFilter = [];
  ppShareFilter = [];
  netWorthFilter = [];
  perGainFilter = [];
  dilutionPerFilter = [];
  postMoneyFilter = [];
  preHoldingFilter = [];
  amountInvestedFilter = [];
  totalInvFilter = [];
  holdingFilter = [];
  roundsFilter = [];
  commonFilter = [];
  preferenceFilter = [];
  debentureFilter = [];
  warrantsFilter = [];
  fdbFilter = [];

  constructor(
    private utilityService: UtilityService,
    private pp: PercentPipe,
    private router: Router,
    private authService: AuthenticationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private permService: PermissionsService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.usrCompanyCurrency = userDetails['company']['companyCurrency'];
    this.searchOptions = this.searchFormControl.valueChanges.pipe(
      map((value) => this._filter(value))
    );
    // console.log('data source received: ', this.dataSource);
  }

  searchEntered(e) {
    this.treeList.instance.searchByText(e);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return []
    if (this.searchFormControl.value.length < 1) return [];
    return this.totalSearchOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  exportFile() {
    this.ngxLoader.start();
    this.authService.exportBuyBackOverviewReport().subscribe(
      (res: HttpResponse<any>) => {
        this.utilityService.downloadFile(res.body, res.headers.get("fileName"), res.headers.get("fileType"));
        this.ngxLoader.stop();
      },
      (e) => {
        this.ngxLoader.stop();
        this.toastr.error(e.reason, 'Error');
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes: ', changes);
    // console.log('data source received: ', this.dataSource);
    // console.log('col list received: ', this.displayCols);
    // this.setHeaderFilters();
  }

  getTotalValueTble(value) {
    if(value > 0){
      if(value.toString().length > 5){
        return this.utilityService.convertedFormatCurrency(this.usrCompanyCurrency, value);
      }
      else{
        return this.utilityService.formatCurrency(this.usrCompanyCurrency, value);
      }
    }
    else{
      return 0;
    }
  }

  displayBuyBackDetails(data) {
    if (data.state === "BASIC_DETAILS" || data.state === "SHAREHOLDER_DETAILS" || data.state === "CAPTABLE_SUMMARY"){
        this.router.navigate(
            ['/landing', 'dashboard', 'buy-back', 'newBuyBack', this.buyBackStateMapping[data.state] ],
            { queryParams: { 'buyBackId': data.id } }
        );
    }
    else if (data.state === "COMPLETED"){
      this.router.navigate(
          ['/landing', 'dashboard', 'buy-back', 'details'],
          { queryParams: { 'buyBackId': data.id } }
      );
  }
  }

  onCellHoverChanged(e: any) {
    if (e.rowType === "data" && (e.column.dataField === "postBuybackConsolidation") && e.eventType === "mouseover") {
      this.toolTipTarget = e.cellElement;
      this.toolTipText = this.pp.transform(e.text, '2.2-2');
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && (e.column.dataField === "pricePerShare" || e.column.dataField === "numberOfSharesBoughtBackActual" || e.column.dataField === "buyBackAmountActual") && e.eventType === "mouseover") {
        if (e.displayValue === 0) {
            this.toolTipText = e.text;
        }
        else if(e.displayValue == null){
          this.toolTipText = "0";
        }
        else {
            this.toolTipText = this.utilityService.formatCurrency(this.usrCompanyCurrency, e.displayValue);
        }
        this.toolTipTarget = e.cellElement;
        this.tooltip.instance.show();
    }

    if(e.rowType === "data" && (e.column.dataField === "buyBackName" || e.column.dataField === "className" || e.column.dataField === "startDate") && e.eventType === "mouseover"){
      this.toolTipText = e.text;
      this.toolTipTarget = e.cellElement;
      this.tooltip.instance.show();
    }
    if (e.rowType === "data" && e.eventType === "mouseout") {
      this.tooltip.instance.hide();
    }
  }

}
