import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-connect-terms-modal',
  templateUrl: './connect-terms-modal.component.html',
  styleUrls: ['./connect-terms-modal.component.scss']
})
export class ConnectTermsModalComponent implements OnInit {

  @Input() showDialogConnectTerms: boolean;
  @Output() visibleChangeConnectTerms: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.showDialogConnectTerms = false;
    this.visibleChangeConnectTerms.emit({showMdl: this.showDialogConnectTerms});
  }

}

