import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { MatStepper } from '@angular/material';
import { DataService } from '../../services/new-round-notify.service';
import { ToastrService } from 'ngx-toastr';
import { CacheProviderService } from '../../services/cache-provider.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  public breadCrumbs = [{ screen: "On-boarding", link: "['']" }];
  public adminProfile = "../../assets/wizard/step-1.svg";
  public companyDetails = "../../assets/wizard/step-2.svg";
  public incorporationDetails = "../../assets/wizard/step-3.svg";

  errorFlags;

  public activePath = "";
  randomSelectpath = "adminProfile";

  isLinear = false;
  isShow: boolean = false;

  public activatedPath = "";

  @ViewChild('stepper') stepper: MatStepper;

  constructor(private router: Router, private dataService: DataService,
    private toastr: ToastrService,
    private cacheService: CacheProviderService,
    private route: ActivatedRoute) { }

  ngOnInit() {

  }

  randomStep(stepName) {
    let adminProfileString = this.cacheService.get("AdminProfile");
    let adminProfileInfo = JSON.parse(adminProfileString);
    let companyDetailsInfo = this.cacheService.get("CompanyDetails");

    this.randomSelectpath = stepName;

    if (this.randomSelectpath === "adminProfile") {
      if (adminProfileInfo != null) {
        this.router.navigate(['/landing', 'onboarding', 'adminProfile']);
      }
      else if (adminProfileInfo == null) {
        this.toastr.error("please fill Admin profile details", "Error");
      }
    }
    else if (this.randomSelectpath === "companyDetails") {
      let waitingForVerification = localStorage.getItem('waitingForVerification');
      if (adminProfileInfo != null) {
        if(adminProfileInfo['id'] != null  && waitingForVerification != 'true') {
            this.router.navigate(['/landing', 'onboarding', 'companyDetails']);
        }
      }
      else if (adminProfileInfo == null || adminProfileInfo['id'] == null) {
        this.toastr.error("Please fill Admin profile details", "Error");
      } else if(waitingForVerification == 'true') {
            this.toastr.error("Please complete verification", "Error");
      }
    }
    else if (this.randomSelectpath === "incorporationDetails") {
      if (adminProfileInfo != null && companyDetailsInfo != null) {
        this.router.navigate(['/landing', 'onboarding', 'incorporationDetails']);
      }
      else if (adminProfileInfo == null || companyDetailsInfo == null) {
        this.toastr.error("please fill Admin profile and Company details first", "Error");
      }
    }
  }

  onActivate(event) {
    this.activatedPath = this.route.firstChild.routeConfig.path;
    // this.activePath = event.activatedRoute.routeConfig.path;
    this.setBreadCrumb();
    this.flagInit();
    if (this.activePath == "verification") {
      this.isShow = true;
    }
    else {
      this.isShow = false;
    }
  }

  // stepepr flags initiization
  flagInit() {
    this.dataService.getOnboarding().subscribe(data => {
      if (data === null) {
        this.adminProfile = "../../assets/wizard/inprogress.svg";
        this.companyDetails = "../../assets/wizard/step-2.svg";
        this.incorporationDetails = "../../assets/wizard/step-3.svg";
      }
      else {
        this.errorFlags = data;
        this.stepperIndicator();
      }
    })
  }

  // stepper step handling
  stepperIndicator() {
    if (this.errorFlags.adminProfileTab) {
      if (this.errorFlags.adminProfileTab == "INITIAL") {
        this.adminProfile = "../../assets/wizard/step-1.svg";
      }
      else if (this.errorFlags.adminProfileTab == "INPROGRESS") {
        this.adminProfile = "../../assets/wizard/inprogress.svg";
      }
      else if (this.errorFlags.adminProfileTab == "COMPLETED") {
        this.adminProfile = "../../assets/wizard/completed.svg";
      }
    }
    if (this.errorFlags.companyDetailsTab) {
      if (this.errorFlags.companyDetailsTab == "INITIAL") {
        this.companyDetails = "../../assets/wizard/step-2.svg";
      }
      else if (this.errorFlags.companyDetailsTab == "INPROGRESS") {
        this.companyDetails = "../../assets/wizard/inprogress.svg";
      }
      else if (this.errorFlags.companyDetailsTab == "COMPLETED") {
        this.companyDetails = "../../assets/wizard/completed.svg";
      }
    }
    if (this.errorFlags.inCorporationTab) {

      if (this.errorFlags.inCorporationTab == "INITIAL") {
        this.incorporationDetails = "../../assets/wizard/step-3.svg";
      }
      else if (this.errorFlags.inCorporationTab == "INPROGRESS") {
        this.incorporationDetails = "../../assets/wizard/inprogress.svg";

      }
      else if (this.errorFlags.inCorporationTab == "COMPLETED") {
        this.incorporationDetails = "../../assets/wizard/completed.svg";
      }
    }
  }

  setBreadCrumb() {
    this.breadCrumbs = [];
    this.breadCrumbs = [{ screen: "On-Boarding", link: "['']" }];
   // could have created an array of paths and check the activated path in array, if present set the respective value
    if(this.activatedPath == "adminProfile") {
      this.breadCrumbs.push({ screen: "Admin Profile", link: "['/landing', 'onboarding', 'adminProfile']" });
    }
    else if(this.activatedPath == "companyDetails") {
      this.breadCrumbs.push({ screen: "Company Details", link: "['/landing', 'onboarding', 'companyDetails']" });
    }
    else if(this.activatedPath == "create-historical-round") {
      this.breadCrumbs.push({ screen: "Create Historical Round", link: "['/landing', 'onboarding', 'create-historical-round']" });
    }
    else if(this.activatedPath == "historical-round-summary") {
      this.breadCrumbs.push({ screen: "Historical Round Summary", link: "['/landing', 'onboarding', 'historical-round-summary']" });
    }
    else if(this.activatedPath == "incorporationDetails") {
      this.breadCrumbs.push({ screen: "Incorporation Details", link: "['/landing', 'onboarding', 'incorporationDetails']" });
    }
    console.log("onboard breadcrumbs",this.breadCrumbs);
  }
}
