import { TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';
var HideIfDirective = /** @class */ (function () {
    function HideIfDirective(templateRef, viewContainer, permService) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.permService = permService;
    }
    Object.defineProperty(HideIfDirective.prototype, "appHideIf", {
        set: function (perm) {
            var userPermissions = this.permService.getUserPermissions();
            var permissionSet = this.permService.getAllPermissions();
            for (var i = 0; i < perm.length; i++) {
                var permissionRequired = permissionSet[perm[i]];
                if (userPermissions.includes(permissionRequired)) {
                    this.viewContainer.clear();
                    return;
                }
            }
            this.viewContainer.createEmbeddedView(this.templateRef);
        },
        enumerable: true,
        configurable: true
    });
    return HideIfDirective;
}());
export { HideIfDirective };
